export const data = [
  {
    casecoords: [
      {
        lat: 13.05052114731681,
        lng: 80.26284083736233,
      },
      {
        lat: 13.038963780966771,
        lng: 80.25889192068522,
      },
      {
        lat: 13.047557987063742,
        lng: 80.26058737671498,
      },
      {
        lat: 13.044892119128152,
        lng: 80.26679858148988,
      },
      {
        lat: 13.039748285761192,
        lng: 80.26444130638455,
      },
      {
        lat: 13.0503264444543,
        lng: 80.26514346515889,
      },
      {
        lat: 13.053298231158935,
        lng: 80.26399717589086,
      },
      {
        lat: 13.045621201456855,
        lng: 80.2661769171583,
      },
      {
        lat: 13.049995826242936,
        lng: 80.26971143463521,
      },
      {
        lat: 13.048360792004376,
        lng: 80.25922618099723,
      },
      {
        lat: 13.051301276270136,
        lng: 80.26953581687151,
      },
      {
        lat: 13.03905645190854,
        lng: 80.25784498824324,
      },
      {
        lat: 13.040586894827292,
        lng: 80.26418155169081,
      },
      {
        lat: 13.040681057591318,
        lng: 80.26315911124286,
      },
      {
        lat: 13.045689806564894,
        lng: 80.26630860636277,
      },
      {
        lat: 13.040128740471227,
        lng: 80.26260488502817,
      },
      {
        lat: 13.051163705642514,
        lng: 80.26808654121704,
      },
      {
        lat: 13.048963254017762,
        lng: 80.26859055275219,
      },
      {
        lat: 13.044687450064261,
        lng: 80.26074129200246,
      },
      {
        lat: 13.04099744443827,
        lng: 80.26319653478095,
      },
      {
        lat: 13.044911888275326,
        lng: 80.26308887093262,
      },
      {
        lat: 13.039528067894523,
        lng: 80.26113957316714,
      },
      {
        lat: 13.052041460401476,
        lng: 80.26680698443589,
      },
      {
        lat: 13.048228961066657,
        lng: 80.2705118047988,
      },
      {
        lat: 13.047968898417217,
        lng: 80.27034557422904,
      },
      {
        lat: 13.047014687174704,
        lng: 80.26303619217119,
      },
      {
        lat: 13.05062184331006,
        lng: 80.26751684383508,
      },
      {
        lat: 13.047964749893257,
        lng: 80.25942696525335,
      },
      {
        lat: 13.04156863159607,
        lng: 80.26229760578529,
      },
      {
        lat: 13.041408018762723,
        lng: 80.26057889119156,
      },
      {
        lat: 13.045109015038717,
        lng: 80.26725223193623,
      },
      {
        lat: 13.044555480438424,
        lng: 80.26295744587684,
      },
      {
        lat: 13.050982273856695,
        lng: 80.26376034533119,
      },
      {
        lat: 13.046636370571889,
        lng: 80.26320812445245,
      },
      {
        lat: 13.05373504761013,
        lng: 80.2666060474926,
      },
      {
        lat: 13.051265135673011,
        lng: 80.26998730252659,
      },
      {
        lat: 13.053636895015709,
        lng: 80.26450458719826,
      },
      {
        lat: 13.042939628069707,
        lng: 80.26147135521978,
      },
      {
        lat: 13.049770311934308,
        lng: 80.2703999775148,
      },
      {
        lat: 13.046454312646153,
        lng: 80.26240921619754,
      },
      {
        lat: 13.044593783857147,
        lng: 80.26964299086045,
      },
      {
        lat: 13.039017109793061,
        lng: 80.25904308223646,
      },
      {
        lat: 13.05062853219573,
        lng: 80.26452294634282,
      },
      {
        lat: 13.04158290111071,
        lng: 80.26023421332438,
      },
      {
        lat: 13.049350866955313,
        lng: 80.26641888993582,
      },
      {
        lat: 13.053418500487853,
        lng: 80.26489493390021,
      },
      {
        lat: 13.044867326406315,
        lng: 80.26674557996785,
      },
      {
        lat: 13.039393593329518,
        lng: 80.2617343836333,
      },
      {
        lat: 13.044410958346667,
        lng: 80.27002248824286,
      },
      {
        lat: 13.04496888345235,
        lng: 80.26611366721662,
      },
      {
        lat: 13.053620051481696,
        lng: 80.2676038599677,
      },
      {
        lat: 13.051432843630002,
        lng: 80.26903838539724,
      },
      {
        lat: 13.043738628969034,
        lng: 80.26119444283228,
      },
      {
        lat: 13.04078684789591,
        lng: 80.26468374610674,
      },
      {
        lat: 13.046456243419499,
        lng: 80.26429847944299,
      },
      {
        lat: 13.047214966152925,
        lng: 80.26550468676572,
      },
      {
        lat: 13.04843213164144,
        lng: 80.2694495654629,
      },
      {
        lat: 13.05111671221438,
        lng: 80.26869869638207,
      },
      {
        lat: 13.050232644193756,
        lng: 80.26456417964182,
      },
      {
        lat: 13.047345746947151,
        lng: 80.26461716626365,
      },
      {
        lat: 13.050932249389055,
        lng: 80.2654693772759,
      },
      {
        lat: 13.046960387352785,
        lng: 80.26308169206588,
      },
      {
        lat: 13.052747203300537,
        lng: 80.27044926755303,
      },
      {
        lat: 13.040547695722193,
        lng: 80.26477775874837,
      },
      {
        lat: 13.044162332826852,
        lng: 80.26236495441171,
      },
      {
        lat: 13.049461331520758,
        lng: 80.27079126383701,
      },
      {
        lat: 13.04482792010523,
        lng: 80.26898234637659,
      },
      {
        lat: 13.045703229185477,
        lng: 80.26115996820448,
      },
      {
        lat: 13.042173474693412,
        lng: 80.26216855792816,
      },
      {
        lat: 13.04778878913529,
        lng: 80.25974256992845,
      },
      {
        lat: 13.039993864619165,
        lng: 80.26330127125827,
      },
      {
        lat: 13.045694786350559,
        lng: 80.26025208382701,
      },
      {
        lat: 13.045929584021325,
        lng: 80.26343457407137,
      },
      {
        lat: 13.05123991604882,
        lng: 80.26605999677426,
      },
      {
        lat: 13.053267779710032,
        lng: 80.26986464367388,
      },
      {
        lat: 13.05239953142161,
        lng: 80.26467174916235,
      },
      {
        lat: 13.041937481623963,
        lng: 80.26076836817623,
      },
      {
        lat: 13.048436356089384,
        lng: 80.2595734881254,
      },
      {
        lat: 13.042155673326203,
        lng: 80.26162021470894,
      },
      {
        lat: 13.04543392185035,
        lng: 80.26186393198631,
      },
      {
        lat: 13.051036103842357,
        lng: 80.26281298238246,
      },
      {
        lat: 13.04087112400451,
        lng: 80.25960565686856,
      },
      {
        lat: 13.050382967646424,
        lng: 80.26780591049055,
      },
      {
        lat: 13.048094768029882,
        lng: 80.26267539340742,
      },
      {
        lat: 13.040672728183472,
        lng: 80.2642490634963,
      },
      {
        lat: 13.050494193344472,
        lng: 80.26862924083218,
      },
      {
        lat: 13.04370027271828,
        lng: 80.26193495594319,
      },
      {
        lat: 13.052294563296673,
        lng: 80.26610106268208,
      },
      {
        lat: 13.041734476554735,
        lng: 80.26376184183009,
      },
      {
        lat: 13.050479515048998,
        lng: 80.2628945416456,
      },
      {
        lat: 13.047198993500254,
        lng: 80.2639621677117,
      },
      {
        lat: 13.040938957024489,
        lng: 80.25902975739689,
      },
      {
        lat: 13.049947853355418,
        lng: 80.2662583759699,
      },
      {
        lat: 13.05198691991204,
        lng: 80.26934145123188,
      },
      {
        lat: 13.048717183904099,
        lng: 80.26220388713409,
      },
      {
        lat: 13.041794681385717,
        lng: 80.26322824580777,
      },
      {
        lat: 13.042373418295433,
        lng: 80.25966768628427,
      },
      {
        lat: 13.05080086376387,
        lng: 80.26689204470034,
      },
      {
        lat: 13.04162937794268,
        lng: 80.26001645352594,
      },
      {
        lat: 13.044613079764085,
        lng: 80.26890307417337,
      },
      {
        lat: 13.046653327281414,
        lng: 80.26153583611918,
      },
      {
        lat: 13.046068288622124,
        lng: 80.26645474000678,
      },
      {
        lat: 13.052056989032481,
        lng: 80.27060113827625,
      },
      {
        lat: 13.045630669096845,
        lng: 80.26308072411662,
      },
      {
        lat: 13.044425298394897,
        lng: 80.25985385186452,
      },
      {
        lat: 13.048069894555457,
        lng: 80.26215431916343,
      },
      {
        lat: 13.049152882752539,
        lng: 80.26811264606414,
      },
      {
        lat: 13.039701982726427,
        lng: 80.26132976694892,
      },
      {
        lat: 13.046849743487824,
        lng: 80.26306227248544,
      },
      {
        lat: 13.049855708158669,
        lng: 80.2669313083888,
      },
      {
        lat: 13.052533735615764,
        lng: 80.26532201286638,
      },
      {
        lat: 13.04763963820478,
        lng: 80.266871144066,
      },
      {
        lat: 13.050009862408993,
        lng: 80.26787301357014,
      },
      {
        lat: 13.0425040199302,
        lng: 80.2598011178719,
      },
      {
        lat: 13.050080543594994,
        lng: 80.26529329578459,
      },
      {
        lat: 13.048150270211964,
        lng: 80.26285956324918,
      },
      {
        lat: 13.04593532244197,
        lng: 80.26374777657992,
      },
      {
        lat: 13.04503019140549,
        lng: 80.25986557005419,
      },
      {
        lat: 13.041451297666379,
        lng: 80.26117150720512,
      },
      {
        lat: 13.050508827806194,
        lng: 80.26336758434378,
      },
      {
        lat: 13.051087242366817,
        lng: 80.26919508431894,
      },
      {
        lat: 13.052727670266261,
        lng: 80.26995277202809,
      },
      {
        lat: 13.048094019619853,
        lng: 80.25853931968017,
      },
      {
        lat: 13.04862866253053,
        lng: 80.25912818564328,
      },
      {
        lat: 13.044794975305404,
        lng: 80.26951565654242,
      },
      {
        lat: 13.050643496289926,
        lng: 80.26349528822881,
      },
      {
        lat: 13.048531629063943,
        lng: 80.25945145493299,
      },
      {
        lat: 13.050047378429324,
        lng: 80.26388184875161,
      },
      {
        lat: 13.04608408024773,
        lng: 80.26933882993487,
      },
      {
        lat: 13.045716701746755,
        lng: 80.26232712803451,
      },
      {
        lat: 13.051628292935833,
        lng: 80.27051780377727,
      },
      {
        lat: 13.043806802953082,
        lng: 80.26237781627229,
      },
      {
        lat: 13.041793739830464,
        lng: 80.26220694261832,
      },
      {
        lat: 13.050935741029779,
        lng: 80.26989807031238,
      },
      {
        lat: 13.047248177555963,
        lng: 80.26068632216916,
      },
      {
        lat: 13.05158795509845,
        lng: 80.26723938292693,
      },
      {
        lat: 13.04719960023893,
        lng: 80.26907318974183,
      },
      {
        lat: 13.043473086615629,
        lng: 80.26042805449845,
      },
      {
        lat: 13.051538365253178,
        lng: 80.26694424607662,
      },
      {
        lat: 13.05333635633798,
        lng: 80.26758665050373,
      },
      {
        lat: 13.052432223952708,
        lng: 80.26689613722999,
      },
      {
        lat: 13.045447649395486,
        lng: 80.26120041944668,
      },
      {
        lat: 13.041099965630611,
        lng: 80.26342344382621,
      },
      {
        lat: 13.047360093640684,
        lng: 80.26788781436386,
      },
      {
        lat: 13.04338365422993,
        lng: 80.25980790955829,
      },
      {
        lat: 13.045972284646913,
        lng: 80.26540678451278,
      },
      {
        lat: 13.050199438424029,
        lng: 80.2645381310112,
      },
      {
        lat: 13.044536690487803,
        lng: 80.26237184687335,
      },
      {
        lat: 13.052256285668646,
        lng: 80.26760745087483,
      },
      {
        lat: 13.04685006367494,
        lng: 80.26299777980266,
      },
      {
        lat: 13.048756069719069,
        lng: 80.2592338945839,
      },
      {
        lat: 13.051024812953264,
        lng: 80.26360579409517,
      },
      {
        lat: 13.045315283378491,
        lng: 80.26760141839603,
      },
      {
        lat: 13.049792510064306,
        lng: 80.27041689913995,
      },
      {
        lat: 13.045530541120424,
        lng: 80.26321261662734,
      },
      {
        lat: 13.04672846021696,
        lng: 80.26301374845195,
      },
      {
        lat: 13.04737211762469,
        lng: 80.26659507303971,
      },
      {
        lat: 13.045834374673873,
        lng: 80.26486362615917,
      },
      {
        lat: 13.045320658726407,
        lng: 80.27037535402452,
      },
    ],
    cases: 159,
    coords: [
      {
        lat: 13.047525928518052,
        lng: 80.27089867031938,
      },
      {
        lat: 13.047591755375635,
        lng: 80.27062167407598,
      },
      {
        lat: 13.045203190846609,
        lng: 80.27050695715906,
      },
      {
        lat: 13.044290918151525,
        lng: 80.27053395395347,
      },
      {
        lat: 13.044212330044015,
        lng: 80.27038041111325,
      },
      {
        lat: 13.044365637419821,
        lng: 80.27005142173557,
      },
      {
        lat: 13.044754074049104,
        lng: 80.26688687128348,
      },
      {
        lat: 13.044752266604966,
        lng: 80.26339793167058,
      },
      {
        lat: 13.043412097108286,
        lng: 80.26329361790859,
      },
      {
        lat: 13.043023025410394,
        lng: 80.26316197876544,
      },
      {
        lat: 13.042751132448235,
        lng: 80.2631450038722,
      },
      {
        lat: 13.042518533539742,
        lng: 80.2632048003989,
      },
      {
        lat: 13.042306871271023,
        lng: 80.26351500765513,
      },
      {
        lat: 13.042077086063824,
        lng: 80.26403740422437,
      },
      {
        lat: 13.04163364554944,
        lng: 80.26454233301988,
      },
      {
        lat: 13.039094954828416,
        lng: 80.2660466427371,
      },
      {
        lat: 13.039352031941839,
        lng: 80.26564806288553,
      },
      {
        lat: 13.03970746558702,
        lng: 80.26501080813235,
      },
      {
        lat: 13.039720796095997,
        lng: 80.26392058392422,
      },
      {
        lat: 13.039633479671355,
        lng: 80.26269247006773,
      },
      {
        lat: 13.03922703441792,
        lng: 80.26150669749556,
      },
      {
        lat: 13.039102930987218,
        lng: 80.26079422036065,
      },
      {
        lat: 13.038859666572678,
        lng: 80.26018185275151,
      },
      {
        lat: 13.038733272739842,
        lng: 80.25909281106674,
      },
      {
        lat: 13.038942110836999,
        lng: 80.25732704518694,
      },
      {
        lat: 13.040144690497865,
        lng: 80.25812949593298,
      },
      {
        lat: 13.041205375962727,
        lng: 80.25857638383937,
      },
      {
        lat: 13.042167343253396,
        lng: 80.2592024890868,
      },
      {
        lat: 13.042787569050569,
        lng: 80.2594350824398,
      },
      {
        lat: 13.043886488091056,
        lng: 80.25960416386658,
      },
      {
        lat: 13.044865763957517,
        lng: 80.25979407807176,
      },
      {
        lat: 13.045284695887581,
        lng: 80.25975089212801,
      },
      {
        lat: 13.045544063403247,
        lng: 80.25972887682639,
      },
      {
        lat: 13.046085290551854,
        lng: 80.2601008802149,
      },
      {
        lat: 13.046807127662285,
        lng: 80.26062991671104,
      },
      {
        lat: 13.047066856820384,
        lng: 80.26066735896985,
      },
      {
        lat: 13.047225697949921,
        lng: 80.26052727320676,
      },
      {
        lat: 13.047573778198913,
        lng: 80.25868104805643,
      },
      {
        lat: 13.047691974803042,
        lng: 80.25842238503597,
      },
      {
        lat: 13.048050904324908,
        lng: 80.25835988670114,
      },
      {
        lat: 13.048850414061883,
        lng: 80.25853150210645,
      },
      {
        lat: 13.049010822308624,
        lng: 80.25864906577232,
      },
      {
        lat: 13.049051828476463,
        lng: 80.25882710059005,
      },
      {
        lat: 13.048287588889334,
        lng: 80.26117235298156,
      },
      {
        lat: 13.048268833744038,
        lng: 80.26137071378179,
      },
      {
        lat: 13.048389802940209,
        lng: 80.26156789205876,
      },
      {
        lat: 13.048990068117078,
        lng: 80.26180065434373,
      },
      {
        lat: 13.04975025935096,
        lng: 80.26207170354711,
      },
      {
        lat: 13.050789899268054,
        lng: 80.26234038770367,
      },
      {
        lat: 13.05145221616031,
        lng: 80.26292938855593,
      },
      {
        lat: 13.05195460699556,
        lng: 80.26348010248998,
      },
      {
        lat: 13.05259431122306,
        lng: 80.2636332501637,
      },
      {
        lat: 13.053433380557975,
        lng: 80.26374507014457,
      },
      {
        lat: 13.053653670665442,
        lng: 80.26386212701479,
      },
      {
        lat: 13.053883359670388,
        lng: 80.26387104060593,
      },
      {
        lat: 13.053885727246453,
        lng: 80.26392030000478,
      },
      {
        lat: 13.053985096362023,
        lng: 80.26409032585481,
      },
      {
        lat: 13.053940088296754,
        lng: 80.26477417411644,
      },
      {
        lat: 13.053266835398645,
        lng: 80.27129722137096,
      },
      {
        lat: 13.051471695950845,
        lng: 80.27123820566382,
      },
      {
        lat: 13.051283291678736,
        lng: 80.27113809216044,
      },
      {
        lat: 13.05106167244566,
        lng: 80.27119082179028,
      },
      {
        lat: 13.05089095819211,
        lng: 80.27119226658925,
      },
      {
        lat: 13.05056629181362,
        lng: 80.27114416031625,
      },
      {
        lat: 13.050173236635006,
        lng: 80.27107968149922,
      },
      {
        lat: 13.049814221208205,
        lng: 80.27099796325855,
      },
      {
        lat: 13.048021721545547,
        lng: 80.27101313364805,
      },
      {
        lat: 13.047748063245445,
        lng: 80.2709306930081,
      },
      {
        lat: 13.047525928518052,
        lng: 80.27089867031938,
      },
    ],
    properties: {
      Ward_No: 119,
      Zone_Name: "TEYNAMPET",
      fillColor: "#de4307",
      zname: "TEYNAMPET(119)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.159058165617308,
        lng: 80.28645385156678,
      },
      {
        lat: 13.154946069184923,
        lng: 80.27004869194104,
      },
      {
        lat: 13.143801315661557,
        lng: 80.27159640312493,
      },
      {
        lat: 13.164176503645942,
        lng: 80.28286389691324,
      },
      {
        lat: 13.174217598029994,
        lng: 80.29027121113437,
      },
      {
        lat: 13.150073658889816,
        lng: 80.26870986308619,
      },
      {
        lat: 13.150419584628587,
        lng: 80.2835428185589,
      },
      {
        lat: 13.173979984848861,
        lng: 80.2873759245179,
      },
      {
        lat: 13.170787161808361,
        lng: 80.29855647504898,
      },
      {
        lat: 13.154659205494717,
        lng: 80.28006746827147,
      },
      {
        lat: 13.14481070562623,
        lng: 80.27405618357938,
      },
      {
        lat: 13.168444400638164,
        lng: 80.28741052321406,
      },
      {
        lat: 13.159382284841152,
        lng: 80.27500403502415,
      },
      {
        lat: 13.151227051852493,
        lng: 80.2779928345937,
      },
      {
        lat: 13.16904653602368,
        lng: 80.28871382976486,
      },
      {
        lat: 13.157849325344518,
        lng: 80.28816567683393,
      },
      {
        lat: 13.17073962147165,
        lng: 80.29360804894337,
      },
      {
        lat: 13.155551864666377,
        lng: 80.28221925917398,
      },
      {
        lat: 13.148088405276399,
        lng: 80.28391860217698,
      },
      {
        lat: 13.144148397998451,
        lng: 80.26761251449302,
      },
      {
        lat: 13.153637706081112,
        lng: 80.28665318857807,
      },
      {
        lat: 13.150828704871603,
        lng: 80.28394395962158,
      },
      {
        lat: 13.158879240818903,
        lng: 80.26871279162118,
      },
      {
        lat: 13.147569199025519,
        lng: 80.26946664421531,
      },
    ],
    cases: 24,
    coords: [
      {
        lat: 13.155581475332156,
        lng: 80.29467170653596,
      },
      {
        lat: 13.151369851324647,
        lng: 80.29291461040287,
      },
      {
        lat: 13.147785197352395,
        lng: 80.29112004827557,
      },
      {
        lat: 13.145636902273912,
        lng: 80.29045389236005,
      },
      {
        lat: 13.144802753182615,
        lng: 80.28984598299421,
      },
      {
        lat: 13.145929870838545,
        lng: 80.28754272613992,
      },
      {
        lat: 13.14624832677422,
        lng: 80.28675547702768,
      },
      {
        lat: 13.146207424002032,
        lng: 80.28593560771868,
      },
      {
        lat: 13.14594973477047,
        lng: 80.28490360387421,
      },
      {
        lat: 13.145585387471806,
        lng: 80.2840508104175,
      },
      {
        lat: 13.144499069328365,
        lng: 80.28259788096325,
      },
      {
        lat: 13.142358876899246,
        lng: 80.27912116279226,
      },
      {
        lat: 13.142102272143994,
        lng: 80.2782674574826,
      },
      {
        lat: 13.141488665491462,
        lng: 80.26595871993632,
      },
      {
        lat: 13.14148476137674,
        lng: 80.26531684521113,
      },
      {
        lat: 13.14166281401587,
        lng: 80.26506570750726,
      },
      {
        lat: 13.142022389618935,
        lng: 80.26513398741082,
      },
      {
        lat: 13.144971039700776,
        lng: 80.26571527844477,
      },
      {
        lat: 13.145437273371522,
        lng: 80.26560434796048,
      },
      {
        lat: 13.145433454562102,
        lng: 80.26650750987704,
      },
      {
        lat: 13.145556804299137,
        lng: 80.2666039804294,
      },
      {
        lat: 13.145826127567512,
        lng: 80.26647980796031,
      },
      {
        lat: 13.146145146583219,
        lng: 80.26645272939639,
      },
      {
        lat: 13.146293047650259,
        lng: 80.2665489921643,
      },
      {
        lat: 13.146294085554187,
        lng: 80.26671963374316,
      },
      {
        lat: 13.146296902721994,
        lng: 80.26718280374297,
      },
      {
        lat: 13.146494202992608,
        lng: 80.26732740567931,
      },
      {
        lat: 13.147135354736267,
        lng: 80.26778517328785,
      },
      {
        lat: 13.147872784263315,
        lng: 80.26792520397022,
      },
      {
        lat: 13.148143145435448,
        lng: 80.26797167307998,
      },
      {
        lat: 13.148316042648228,
        lng: 80.26814086016896,
      },
      {
        lat: 13.148439837201407,
        lng: 80.26831046282648,
      },
      {
        lat: 13.148956008215967,
        lng: 80.26840360883045,
      },
      {
        lat: 13.149250031086105,
        lng: 80.2683036059455,
      },
      {
        lat: 13.149323091987574,
        lng: 80.26820547311911,
      },
      {
        lat: 13.149311230228374,
        lng: 80.26625528364652,
      },
      {
        lat: 13.149457945119485,
        lng: 80.2661565274672,
      },
      {
        lat: 13.14975315416555,
        lng: 80.26625154352965,
      },
      {
        lat: 13.15290328625612,
        lng: 80.2674681929317,
      },
      {
        lat: 13.15386197429564,
        lng: 80.2676551082924,
      },
      {
        lat: 13.155605415258155,
        lng: 80.26768911034593,
      },
      {
        lat: 13.156760513936355,
        lng: 80.2678743634324,
      },
      {
        lat: 13.161355467687727,
        lng: 80.26846931935236,
      },
      {
        lat: 13.161340109221106,
        lng: 80.26998092397778,
      },
      {
        lat: 13.159962096407616,
        lng: 80.27754995967177,
      },
      {
        lat: 13.160355362500933,
        lng: 80.27761976722876,
      },
      {
        lat: 13.16033843235122,
        lng: 80.27887297174905,
      },
      {
        lat: 13.160686006040883,
        lng: 80.27950387434794,
      },
      {
        lat: 13.16372479842549,
        lng: 80.28259862003864,
      },
      {
        lat: 13.168599306212318,
        lng: 80.28475144190953,
      },
      {
        lat: 13.173017983405282,
        lng: 80.28663995673709,
      },
      {
        lat: 13.17348549657616,
        lng: 80.28680665041477,
      },
      {
        lat: 13.174296135276654,
        lng: 80.28687292563897,
      },
      {
        lat: 13.17459015814685,
        lng: 80.28677292275418,
      },
      {
        lat: 13.175105587801461,
        lng: 80.28674418191613,
      },
      {
        lat: 13.174928853351762,
        lng: 80.29059818107457,
      },
      {
        lat: 13.17323122191449,
        lng: 80.29028516007722,
      },
      {
        lat: 13.170826824926337,
        lng: 80.3015536423158,
      },
      {
        lat: 13.16983247285234,
        lng: 80.30112643593591,
      },
      {
        lat: 13.163508987075154,
        lng: 80.29826512054937,
      },
      {
        lat: 13.158385326213164,
        lng: 80.2960193764063,
      },
      {
        lat: 13.155581475332156,
        lng: 80.29467170653596,
      },
    ],
    properties: {
      Ward_No: 7,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#8bc24c",
      zname: "THIRUVOTTIYUR(7)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.139805906575113,
        lng: 80.21361715512148,
      },
      {
        lat: 13.143095798618099,
        lng: 80.21878725992862,
      },
      {
        lat: 13.136301404649439,
        lng: 80.21410586878577,
      },
      {
        lat: 13.144935162261277,
        lng: 80.21139027985717,
      },
      {
        lat: 13.1329397010846,
        lng: 80.21717017235487,
      },
      {
        lat: 13.152407171064116,
        lng: 80.22370749642387,
      },
      {
        lat: 13.140221891017092,
        lng: 80.20657863422824,
      },
      {
        lat: 13.136122053869354,
        lng: 80.2190550915206,
      },
      {
        lat: 13.14999351323824,
        lng: 80.21786404643984,
      },
      {
        lat: 13.134731858971515,
        lng: 80.21100287915421,
      },
      {
        lat: 13.145939938450635,
        lng: 80.22118080215975,
      },
      {
        lat: 13.141242073229732,
        lng: 80.20658712632928,
      },
      {
        lat: 13.14227970065654,
        lng: 80.2189462414565,
      },
      {
        lat: 13.136088806930491,
        lng: 80.21665253777384,
      },
      {
        lat: 13.135256617742169,
        lng: 80.21857078353375,
      },
      {
        lat: 13.139393100649658,
        lng: 80.21702299952348,
      },
      {
        lat: 13.13341294001996,
        lng: 80.21712363963822,
      },
      {
        lat: 13.150054741491195,
        lng: 80.22149819373313,
      },
      {
        lat: 13.135099370880674,
        lng: 80.21765335299544,
      },
      {
        lat: 13.138087174679885,
        lng: 80.21271454608217,
      },
      {
        lat: 13.140660128078363,
        lng: 80.21980103322494,
      },
      {
        lat: 13.14675037324446,
        lng: 80.21266300281478,
      },
      {
        lat: 13.14213730475863,
        lng: 80.20744304053524,
      },
      {
        lat: 13.148862542988265,
        lng: 80.21722395800543,
      },
      {
        lat: 13.147823585240552,
        lng: 80.22896675669762,
      },
      {
        lat: 13.149797461158531,
        lng: 80.22232609004568,
      },
      {
        lat: 13.153199278155467,
        lng: 80.22052386754038,
      },
      {
        lat: 13.147993410550352,
        lng: 80.22929270906809,
      },
      {
        lat: 13.143540513726471,
        lng: 80.22442845448113,
      },
      {
        lat: 13.136733889713303,
        lng: 80.2114486089302,
      },
      {
        lat: 13.146222481209373,
        lng: 80.21235906976855,
      },
      {
        lat: 13.141514543412848,
        lng: 80.22098817417314,
      },
      {
        lat: 13.141681315272082,
        lng: 80.21976524405149,
      },
      {
        lat: 13.141540397996819,
        lng: 80.21354912735612,
      },
      {
        lat: 13.13700522424521,
        lng: 80.21016988825208,
      },
      {
        lat: 13.145650990523917,
        lng: 80.21118226447996,
      },
      {
        lat: 13.13951723538107,
        lng: 80.21935348130064,
      },
      {
        lat: 13.148285947574458,
        lng: 80.22233852765777,
      },
      {
        lat: 13.155696913186707,
        lng: 80.22491627090392,
      },
      {
        lat: 13.145014331489067,
        lng: 80.21769195093377,
      },
      {
        lat: 13.137086493027942,
        lng: 80.21854759486371,
      },
      {
        lat: 13.14138107663074,
        lng: 80.22288513048633,
      },
      {
        lat: 13.138099743763986,
        lng: 80.2164918022765,
      },
      {
        lat: 13.1530593303676,
        lng: 80.22428107425593,
      },
      {
        lat: 13.13353891742689,
        lng: 80.21660610995478,
      },
      {
        lat: 13.134702178415923,
        lng: 80.2104892418378,
      },
      {
        lat: 13.151974163993962,
        lng: 80.22126840950222,
      },
      {
        lat: 13.146327912138675,
        lng: 80.2170054241331,
      },
      {
        lat: 13.140177635825529,
        lng: 80.21632108634344,
      },
      {
        lat: 13.146723152398732,
        lng: 80.21215374483143,
      },
      {
        lat: 13.1475088352872,
        lng: 80.20998846764316,
      },
      {
        lat: 13.144562785342735,
        lng: 80.21810566256507,
      },
      {
        lat: 13.133339789774476,
        lng: 80.21717899197756,
      },
      {
        lat: 13.142278378631158,
        lng: 80.21100512444852,
      },
      {
        lat: 13.148444042756607,
        lng: 80.22839142855122,
      },
      {
        lat: 13.133867721709718,
        lng: 80.21273138767592,
      },
      {
        lat: 13.152110013152413,
        lng: 80.21779840224089,
      },
      {
        lat: 13.14854013326452,
        lng: 80.2274892248581,
      },
      {
        lat: 13.13187860689638,
        lng: 80.21925130234031,
      },
      {
        lat: 13.144691990583894,
        lng: 80.22245280869934,
      },
      {
        lat: 13.139564152248953,
        lng: 80.21354163424216,
      },
      {
        lat: 13.13313883320203,
        lng: 80.21291717397743,
      },
      {
        lat: 13.152569504647424,
        lng: 80.22593525375787,
      },
      {
        lat: 13.131193333003274,
        lng: 80.21517528654724,
      },
      {
        lat: 13.143587887192828,
        lng: 80.21714679351703,
      },
      {
        lat: 13.13702328113921,
        lng: 80.21230738999405,
      },
      {
        lat: 13.158306962073649,
        lng: 80.22303877985597,
      },
      {
        lat: 13.130608405591955,
        lng: 80.2182353857899,
      },
      {
        lat: 13.138050598709878,
        lng: 80.2188264283104,
      },
      {
        lat: 13.143140360026663,
        lng: 80.20825092570897,
      },
      {
        lat: 13.141026531840449,
        lng: 80.21857404086305,
      },
      {
        lat: 13.137489679357776,
        lng: 80.20884743543655,
      },
      {
        lat: 13.138889578099388,
        lng: 80.21236816542815,
      },
      {
        lat: 13.143690249687552,
        lng: 80.20978977666046,
      },
      {
        lat: 13.144896301912897,
        lng: 80.22358367785725,
      },
      {
        lat: 13.150799289731511,
        lng: 80.21955010457391,
      },
      {
        lat: 13.139560458513866,
        lng: 80.21312023688894,
      },
      {
        lat: 13.135832578841615,
        lng: 80.22126540964648,
      },
      {
        lat: 13.13943365162121,
        lng: 80.21707825814967,
      },
      {
        lat: 13.138603392646763,
        lng: 80.21509408623126,
      },
      {
        lat: 13.15917387142952,
        lng: 80.2237079111289,
      },
      {
        lat: 13.147349988901544,
        lng: 80.22181029466539,
      },
      {
        lat: 13.140394506823755,
        lng: 80.20903567851967,
      },
      {
        lat: 13.140325998767187,
        lng: 80.22203096123344,
      },
      {
        lat: 13.139730919161298,
        lng: 80.21137900864362,
      },
      {
        lat: 13.1571697728115,
        lng: 80.21908989853203,
      },
      {
        lat: 13.159156124506667,
        lng: 80.22300908998706,
      },
      {
        lat: 13.147196378039968,
        lng: 80.22516165159779,
      },
      {
        lat: 13.139149437490094,
        lng: 80.21182966059754,
      },
      {
        lat: 13.147410410407376,
        lng: 80.21528912994378,
      },
      {
        lat: 13.14738793026958,
        lng: 80.22187741093789,
      },
      {
        lat: 13.151494719708122,
        lng: 80.22642762511745,
      },
      {
        lat: 13.137223234998713,
        lng: 80.21594791089551,
      },
      {
        lat: 13.142890843264556,
        lng: 80.22390913640592,
      },
      {
        lat: 13.137585891125504,
        lng: 80.20918789864565,
      },
      {
        lat: 13.158663846571502,
        lng: 80.22025860411742,
      },
      {
        lat: 13.14785716741305,
        lng: 80.22467357969516,
      },
      {
        lat: 13.154601761375655,
        lng: 80.22276733453084,
      },
      {
        lat: 13.145850483971634,
        lng: 80.22152222060505,
      },
      {
        lat: 13.13965743111414,
        lng: 80.2179511801989,
      },
      {
        lat: 13.136347298183745,
        lng: 80.22206294325194,
      },
      {
        lat: 13.150610427395664,
        lng: 80.22387357827388,
      },
      {
        lat: 13.144098884424027,
        lng: 80.22621135016155,
      },
      {
        lat: 13.139052837688125,
        lng: 80.21505920298651,
      },
      {
        lat: 13.140033231115911,
        lng: 80.2182881900992,
      },
      {
        lat: 13.154760737952243,
        lng: 80.21784313774879,
      },
      {
        lat: 13.14192116368589,
        lng: 80.22024336034526,
      },
      {
        lat: 13.136624315790364,
        lng: 80.2148295083393,
      },
      {
        lat: 13.144942743533742,
        lng: 80.21980913537618,
      },
      {
        lat: 13.152389393180423,
        lng: 80.22031626859342,
      },
      {
        lat: 13.150081928279134,
        lng: 80.21882307902395,
      },
      {
        lat: 13.148037456483612,
        lng: 80.21322443047154,
      },
      {
        lat: 13.144425085476446,
        lng: 80.21470830434313,
      },
      {
        lat: 13.141613444981482,
        lng: 80.21916204811316,
      },
      {
        lat: 13.134443093298003,
        lng: 80.220356147894,
      },
      {
        lat: 13.148006874518538,
        lng: 80.22845484802755,
      },
      {
        lat: 13.136477896033183,
        lng: 80.21885943213738,
      },
      {
        lat: 13.134899825187357,
        lng: 80.21844765299275,
      },
      {
        lat: 13.132833594019903,
        lng: 80.21734090179245,
      },
      {
        lat: 13.141746161976032,
        lng: 80.22375055172928,
      },
      {
        lat: 13.144267325103902,
        lng: 80.21895828788821,
      },
      {
        lat: 13.156431467617049,
        lng: 80.22440396769251,
      },
      {
        lat: 13.14190136363655,
        lng: 80.20755985769193,
      },
      {
        lat: 13.146703583147636,
        lng: 80.22391912780344,
      },
      {
        lat: 13.143784790104844,
        lng: 80.20936075115003,
      },
      {
        lat: 13.13235005550409,
        lng: 80.21392128920428,
      },
      {
        lat: 13.13768166286696,
        lng: 80.21852251635998,
      },
      {
        lat: 13.153083604570794,
        lng: 80.22007241747816,
      },
      {
        lat: 13.148461550654226,
        lng: 80.22543634741487,
      },
      {
        lat: 13.14734954834866,
        lng: 80.22080763255988,
      },
      {
        lat: 13.156530986801677,
        lng: 80.22228003346439,
      },
      {
        lat: 13.1412837616032,
        lng: 80.21678613889037,
      },
      {
        lat: 13.142533142761044,
        lng: 80.21372654780696,
      },
      {
        lat: 13.14156782113683,
        lng: 80.2217525648827,
      },
      {
        lat: 13.147135790159215,
        lng: 80.2174595411539,
      },
      {
        lat: 13.140303118247386,
        lng: 80.21970962334443,
      },
      {
        lat: 13.138193754079579,
        lng: 80.21536153163571,
      },
      {
        lat: 13.145065476263017,
        lng: 80.2120606539954,
      },
      {
        lat: 13.147560962422753,
        lng: 80.22781505258067,
      },
      {
        lat: 13.148580312682324,
        lng: 80.22833828578207,
      },
      {
        lat: 13.14195202277594,
        lng: 80.21172031159442,
      },
      {
        lat: 13.144570909949573,
        lng: 80.21431701095764,
      },
      {
        lat: 13.142729410062863,
        lng: 80.22191192958499,
      },
    ],
    cases: 143,
    coords: [
      {
        lat: 13.147190492324865,
        lng: 80.22978099794547,
      },
      {
        lat: 13.144946416805777,
        lng: 80.22841864925493,
      },
      {
        lat: 13.142232529850192,
        lng: 80.22433984100726,
      },
      {
        lat: 13.141723093801353,
        lng: 80.22387980043135,
      },
      {
        lat: 13.141019042397309,
        lng: 80.22346010294096,
      },
      {
        lat: 13.137968545231024,
        lng: 80.22170590383918,
      },
      {
        lat: 13.137041263774957,
        lng: 80.22302941584769,
      },
      {
        lat: 13.135714160812373,
        lng: 80.22269238341528,
      },
      {
        lat: 13.133840534468144,
        lng: 80.22220519233011,
      },
      {
        lat: 13.133566096021964,
        lng: 80.22193664293573,
      },
      {
        lat: 13.133445184630967,
        lng: 80.22127983414781,
      },
      {
        lat: 13.133207833518043,
        lng: 80.22070140283019,
      },
      {
        lat: 13.130001093546124,
        lng: 80.22061531002015,
      },
      {
        lat: 13.130008723319326,
        lng: 80.22025426834715,
      },
      {
        lat: 13.130082534899264,
        lng: 80.21881945188164,
      },
      {
        lat: 13.130325402866282,
        lng: 80.21803883517565,
      },
      {
        lat: 13.130974463045545,
        lng: 80.21618938115245,
      },
      {
        lat: 13.130969229350434,
        lng: 80.21532891037926,
      },
      {
        lat: 13.130716890887328,
        lng: 80.2145524847337,
      },
      {
        lat: 13.13026045942402,
        lng: 80.21414657855199,
      },
      {
        lat: 13.13101502829346,
        lng: 80.21418854110502,
      },
      {
        lat: 13.131973195043287,
        lng: 80.2143597420073,
      },
      {
        lat: 13.13224428377395,
        lng: 80.21439330973635,
      },
      {
        lat: 13.132337691170479,
        lng: 80.21193597061487,
      },
      {
        lat: 13.133936677528682,
        lng: 80.21057761210807,
      },
      {
        lat: 13.136326400688604,
        lng: 80.20858497598414,
      },
      {
        lat: 13.136302780725309,
        lng: 80.20767069429404,
      },
      {
        lat: 13.13640905688296,
        lng: 80.20732910563062,
      },
      {
        lat: 13.136417735996483,
        lng: 80.20578696517876,
      },
      {
        lat: 13.137212507918278,
        lng: 80.2058161008412,
      },
      {
        lat: 13.138495828464825,
        lng: 80.20600248089428,
      },
      {
        lat: 13.13901940259849,
        lng: 80.20598011873894,
      },
      {
        lat: 13.139810575629408,
        lng: 80.20541756147661,
      },
      {
        lat: 13.141231523662873,
        lng: 80.20542465085352,
      },
      {
        lat: 13.142292891730357,
        lng: 80.20611545200943,
      },
      {
        lat: 13.144412587779888,
        lng: 80.20699723448675,
      },
      {
        lat: 13.145119305133942,
        lng: 80.20731615297106,
      },
      {
        lat: 13.145471979791868,
        lng: 80.20736315275022,
      },
      {
        lat: 13.146326369044571,
        lng: 80.2071309913287,
      },
      {
        lat: 13.147934166213272,
        lng: 80.20661753856076,
      },
      {
        lat: 13.1481346088443,
        lng: 80.20646588849351,
      },
      {
        lat: 13.148284712811257,
        lng: 80.20631466445566,
      },
      {
        lat: 13.148536254127944,
        lng: 80.20628754331811,
      },
      {
        lat: 13.148662404796958,
        lng: 80.20633646022857,
      },
      {
        lat: 13.148790531521474,
        lng: 80.20671026003164,
      },
      {
        lat: 13.148651979879,
        lng: 80.20876079944142,
      },
      {
        lat: 13.14862702592792,
        lng: 80.21293472109014,
      },
      {
        lat: 13.14848026605489,
        lng: 80.2136357469463,
      },
      {
        lat: 13.1485153156645,
        lng: 80.21525994839433,
      },
      {
        lat: 13.149126827843816,
        lng: 80.21647939463918,
      },
      {
        lat: 13.149480262523094,
        lng: 80.21665134937722,
      },
      {
        lat: 13.150765114494044,
        lng: 80.21684041356721,
      },
      {
        lat: 13.160489046094927,
        lng: 80.217946234358,
      },
      {
        lat: 13.160635279892162,
        lng: 80.22012377091114,
      },
      {
        lat: 13.160509123991934,
        lng: 80.22124723741489,
      },
      {
        lat: 13.16060326687392,
        lng: 80.22214876127423,
      },
      {
        lat: 13.16060969180096,
        lng: 80.22320508225225,
      },
      {
        lat: 13.151243785143373,
        lng: 80.22771321148551,
      },
      {
        lat: 13.147190492324865,
        lng: 80.22978099794547,
      },
    ],
    properties: {
      Ward_No: 26,
      Zone_Name: "MADHAVARAM",
      fillColor: "#de4307",
      zname: "MADHAVARAM(26)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.997040031528993,
        lng: 80.23422043082442,
      },
      {
        lat: 12.983284270542152,
        lng: 80.23310975294191,
      },
      {
        lat: 12.988917925281628,
        lng: 80.23663380216128,
      },
      {
        lat: 12.994159714448505,
        lng: 80.23321860261733,
      },
      {
        lat: 12.999614606702897,
        lng: 80.24059191262612,
      },
      {
        lat: 12.994879513829837,
        lng: 80.22238437112904,
      },
      {
        lat: 13.003608662880659,
        lng: 80.21923041513412,
      },
      {
        lat: 12.987567159071821,
        lng: 80.23047682634845,
      },
      {
        lat: 13.003313510361831,
        lng: 80.22068647075207,
      },
      {
        lat: 13.00462538255717,
        lng: 80.22734564255846,
      },
      {
        lat: 13.00160342611368,
        lng: 80.21399285051305,
      },
      {
        lat: 12.988919759351422,
        lng: 80.23839960241231,
      },
      {
        lat: 12.999370868707253,
        lng: 80.20792245487958,
      },
      {
        lat: 12.987704787798284,
        lng: 80.24027885640487,
      },
      {
        lat: 12.99007627530647,
        lng: 80.22036493161771,
      },
      {
        lat: 13.008533945650367,
        lng: 80.22390012022379,
      },
      {
        lat: 12.992945386960391,
        lng: 80.22182126530916,
      },
      {
        lat: 13.00956893593541,
        lng: 80.22171718979354,
      },
      {
        lat: 12.983060290309574,
        lng: 80.23553512975394,
      },
      {
        lat: 13.005999504625631,
        lng: 80.2281014026723,
      },
      {
        lat: 13.00918423479117,
        lng: 80.21960030984985,
      },
      {
        lat: 12.984705269247053,
        lng: 80.23303092311215,
      },
      {
        lat: 13.00901179874514,
        lng: 80.22305040084159,
      },
      {
        lat: 13.00232770562462,
        lng: 80.20969371279895,
      },
      {
        lat: 13.001757792263584,
        lng: 80.21133662144263,
      },
      {
        lat: 12.988599792725328,
        lng: 80.22770965332793,
      },
      {
        lat: 13.009283268758356,
        lng: 80.21939065605554,
      },
      {
        lat: 12.995867045024085,
        lng: 80.23215916482285,
      },
    ],
    cases: 28,
    coords: [
      {
        lat: 13.006844039216102,
        lng: 80.24042803249186,
      },
      {
        lat: 13.006593606214853,
        lng: 80.24129876571611,
      },
      {
        lat: 13.00647575199929,
        lng: 80.24351680965563,
      },
      {
        lat: 13.004238715957364,
        lng: 80.24283150396894,
      },
      {
        lat: 13.004192208821019,
        lng: 80.24382304777491,
      },
      {
        lat: 13.001537428093267,
        lng: 80.24355860397148,
      },
      {
        lat: 13.000434026880976,
        lng: 80.24354185944193,
      },
      {
        lat: 12.999488231750801,
        lng: 80.24352378105122,
      },
      {
        lat: 12.99864639687965,
        lng: 80.24332224251735,
      },
      {
        lat: 12.997673382244304,
        lng: 80.2431478970918,
      },
      {
        lat: 12.99544584373754,
        lng: 80.24314784544157,
      },
      {
        lat: 12.99578812339184,
        lng: 80.2412719710791,
      },
      {
        lat: 12.995197755604542,
        lng: 80.24125352458434,
      },
      {
        lat: 12.994679212909343,
        lng: 80.24139857072828,
      },
      {
        lat: 12.993499475402059,
        lng: 80.24152576980511,
      },
      {
        lat: 12.992436071963242,
        lng: 80.24137066915088,
      },
      {
        lat: 12.987189739213344,
        lng: 80.24057112472913,
      },
      {
        lat: 12.986647159766683,
        lng: 80.24064604551094,
      },
      {
        lat: 12.986200727325825,
        lng: 80.24100146774136,
      },
      {
        lat: 12.985775622596286,
        lng: 80.24098162258247,
      },
      {
        lat: 12.985466994516218,
        lng: 80.24070291941659,
      },
      {
        lat: 12.984445248432797,
        lng: 80.23963319191758,
      },
      {
        lat: 12.984346392386858,
        lng: 80.23890729771774,
      },
      {
        lat: 12.983986554020769,
        lng: 80.23797262590489,
      },
      {
        lat: 12.983817298693383,
        lng: 80.23731765630393,
      },
      {
        lat: 12.983980137873065,
        lng: 80.23691774833604,
      },
      {
        lat: 12.984119795787644,
        lng: 80.23658836534837,
      },
      {
        lat: 12.983976288184444,
        lng: 80.23628482179467,
      },
      {
        lat: 12.98359683307975,
        lng: 80.23600671805609,
      },
      {
        lat: 12.982649906290636,
        lng: 80.2355927593949,
      },
      {
        lat: 12.982670378515545,
        lng: 80.23507684166312,
      },
      {
        lat: 12.9828297571816,
        lng: 80.23460825913133,
      },
      {
        lat: 12.983182569325797,
        lng: 80.23242426123069,
      },
      {
        lat: 12.983482475011568,
        lng: 80.23157355173326,
      },
      {
        lat: 12.984909915990361,
        lng: 80.23218750214087,
      },
      {
        lat: 12.986335883077553,
        lng: 80.23255913036,
      },
      {
        lat: 12.986641315855984,
        lng: 80.23261712906887,
      },
      {
        lat: 12.986762850281085,
        lng: 80.23253532227082,
      },
      {
        lat: 12.986843463816852,
        lng: 80.23241347268645,
      },
      {
        lat: 12.986981896782531,
        lng: 80.23176607532642,
      },
      {
        lat: 12.987094219386536,
        lng: 80.23016975485142,
      },
      {
        lat: 12.98729877262255,
        lng: 80.22701767305557,
      },
      {
        lat: 12.98744329559198,
        lng: 80.22402765575265,
      },
      {
        lat: 12.98723512924132,
        lng: 80.22324182986632,
      },
      {
        lat: 12.987171537070353,
        lng: 80.22281828240452,
      },
      {
        lat: 12.987331658723347,
        lng: 80.22239284159448,
      },
      {
        lat: 12.9875339295085,
        lng: 80.22220937872774,
      },
      {
        lat: 12.987716968091796,
        lng: 80.2222078296248,
      },
      {
        lat: 12.98794006779452,
        lng: 80.22210496869822,
      },
      {
        lat: 12.988261907816279,
        lng: 80.22151660278236,
      },
      {
        lat: 12.98846270471011,
        lng: 80.22109081772706,
      },
      {
        lat: 12.988786018623243,
        lng: 80.22074477399957,
      },
      {
        lat: 12.98876445276004,
        lng: 80.22054301096524,
      },
      {
        lat: 12.988621352471672,
        lng: 80.22042305472891,
      },
      {
        lat: 12.988234323563285,
        lng: 80.22032535747901,
      },
      {
        lat: 12.988172205283751,
        lng: 80.22014413220552,
      },
      {
        lat: 12.988272542318501,
        lng: 80.21992114292009,
      },
      {
        lat: 12.988451650524787,
        lng: 80.21927340131495,
      },
      {
        lat: 12.988550759316722,
        lng: 80.21884847687262,
      },
      {
        lat: 12.988488641037128,
        lng: 80.21866725159911,
      },
      {
        lat: 12.98818308543448,
        lng: 80.21858905937474,
      },
      {
        lat: 12.9874702861271,
        lng: 80.2184335355386,
      },
      {
        lat: 12.987728779626513,
        lng: 80.21746200919229,
      },
      {
        lat: 12.988371108602989,
        lng: 80.21606314868772,
      },
      {
        lat: 12.98879463672665,
        lng: 80.2154739221591,
      },
      {
        lat: 12.989180068919374,
        lng: 80.21530910370491,
      },
      {
        lat: 12.990747908052121,
        lng: 80.21559875300353,
      },
      {
        lat: 12.991870284725222,
        lng: 80.21621528524935,
      },
      {
        lat: 12.99209387572502,
        lng: 80.21619319838571,
      },
      {
        lat: 12.992496697755437,
        lng: 80.21554356343248,
      },
      {
        lat: 12.99267764832595,
        lng: 80.21519872456265,
      },
      {
        lat: 12.992901239325741,
        lng: 80.21517663769885,
      },
      {
        lat: 12.99400254143293,
        lng: 80.21567218097306,
      },
      {
        lat: 12.994566713359108,
        lng: 80.21479904036696,
      },
      {
        lat: 12.994727326309397,
        lng: 80.21445437361967,
      },
      {
        lat: 12.99491061054125,
        lng: 80.21449321154793,
      },
      {
        lat: 12.994992575144103,
        lng: 80.21459349063616,
      },
      {
        lat: 12.995175982200243,
        lng: 80.21465252208034,
      },
      {
        lat: 12.995419051050458,
        lng: 80.21448890848404,
      },
      {
        lat: 12.995537392044307,
        lng: 80.21388207027807,
      },
      {
        lat: 12.995736100925305,
        lng: 80.2131129954563,
      },
      {
        lat: 12.995733767263959,
        lng: 80.21272931865826,
      },
      {
        lat: 12.995488487576793,
        lng: 80.21252944897181,
      },
      {
        lat: 12.995406522973761,
        lng: 80.21242916988359,
      },
      {
        lat: 12.995405049082388,
        lng: 80.21218684769542,
      },
      {
        lat: 12.995862619935687,
        lng: 80.21050682707427,
      },
      {
        lat: 12.995942742174266,
        lng: 80.21030420342714,
      },
      {
        lat: 12.996186916443076,
        lng: 80.21032233147216,
      },
      {
        lat: 12.996329771082708,
        lng: 80.21040190067706,
      },
      {
        lat: 12.996410630267038,
        lng: 80.2103204381241,
      },
      {
        lat: 12.996469923588268,
        lng: 80.2100372125366,
      },
      {
        lat: 12.996704026266004,
        lng: 80.20839947229497,
      },
      {
        lat: 12.996764799418562,
        lng: 80.20708004250041,
      },
      {
        lat: 13.000513030945573,
        lng: 80.20790979816013,
      },
      {
        lat: 13.001382134433657,
        lng: 80.20831221179168,
      },
      {
        lat: 13.004403861488615,
        lng: 80.20980278372672,
      },
      {
        lat: 13.007283894794195,
        lng: 80.21174530084055,
      },
      {
        lat: 13.00886301432629,
        lng: 80.21353492029296,
      },
      {
        lat: 13.010069981365694,
        lng: 80.21520475864466,
      },
      {
        lat: 13.012229734592113,
        lng: 80.2212237286175,
      },
      {
        lat: 13.011107894699336,
        lng: 80.22374643959891,
      },
      {
        lat: 13.010711709049652,
        lng: 80.22495176575808,
      },
      {
        lat: 13.009154122164412,
        lng: 80.22820663314722,
      },
      {
        lat: 13.008515122595902,
        lng: 80.2317086902925,
      },
      {
        lat: 13.007766078633601,
        lng: 80.23521167877217,
      },
      {
        lat: 13.007033556988848,
        lng: 80.23539999543475,
      },
      {
        lat: 13.006165385940443,
        lng: 80.23741063155335,
      },
      {
        lat: 13.005989510606918,
        lng: 80.23865051659759,
      },
      {
        lat: 13.006102434878521,
        lng: 80.23912306545931,
      },
      {
        lat: 13.006433897233798,
        lng: 80.23933880077782,
      },
      {
        lat: 13.006765138059754,
        lng: 80.23951811454282,
      },
      {
        lat: 13.006844039216102,
        lng: 80.24042803249186,
      },
    ],
    properties: {
      Ward_No: 174,
      Zone_Name: "ADYAR",
      fillColor: "#8bc24c",
      zname: "ADYAR(174)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.043319371120534,
        lng: 80.21560298426996,
      },
      {
        lat: 13.033502008317603,
        lng: 80.21429297750203,
      },
      {
        lat: 13.040391704978406,
        lng: 80.21239053287266,
      },
      {
        lat: 13.043424982722645,
        lng: 80.21720339990759,
      },
      {
        lat: 13.034941125036445,
        lng: 80.21204375746488,
      },
      {
        lat: 13.039218579518158,
        lng: 80.21168583755413,
      },
      {
        lat: 13.043972838453378,
        lng: 80.21685926299295,
      },
      {
        lat: 13.041248593849911,
        lng: 80.21414066076481,
      },
      {
        lat: 13.04035175254271,
        lng: 80.20949027543428,
      },
      {
        lat: 13.042748946856264,
        lng: 80.20966829896096,
      },
      {
        lat: 13.03432342644637,
        lng: 80.2150445473114,
      },
      {
        lat: 13.038874646806928,
        lng: 80.21313409821504,
      },
      {
        lat: 13.041978333573681,
        lng: 80.21208045595844,
      },
      {
        lat: 13.033877000122718,
        lng: 80.21333865918655,
      },
      {
        lat: 13.040402013393194,
        lng: 80.21115389075844,
      },
      {
        lat: 13.043955847407416,
        lng: 80.21403913716469,
      },
      {
        lat: 13.047180011716513,
        lng: 80.21380794741121,
      },
      {
        lat: 13.041489778341932,
        lng: 80.21555025311719,
      },
      {
        lat: 13.039264964726009,
        lng: 80.20875675638497,
      },
      {
        lat: 13.033857618790151,
        lng: 80.21313526234488,
      },
      {
        lat: 13.036700312096242,
        lng: 80.21369374123579,
      },
      {
        lat: 13.039132442819088,
        lng: 80.214286968919,
      },
      {
        lat: 13.034386994152431,
        lng: 80.21396471064726,
      },
      {
        lat: 13.032255887936074,
        lng: 80.2136394159409,
      },
      {
        lat: 13.032613600693326,
        lng: 80.21102476731959,
      },
      {
        lat: 13.031327814421141,
        lng: 80.21422110676562,
      },
      {
        lat: 13.043413595565017,
        lng: 80.21098564806641,
      },
      {
        lat: 13.037939613647948,
        lng: 80.21172874134676,
      },
      {
        lat: 13.032582933601894,
        lng: 80.21280757496864,
      },
      {
        lat: 13.046546227286,
        lng: 80.21163564626265,
      },
      {
        lat: 13.041068102943589,
        lng: 80.21622802695595,
      },
      {
        lat: 13.038577522472389,
        lng: 80.2139508031335,
      },
      {
        lat: 13.036799307088552,
        lng: 80.21244050273816,
      },
      {
        lat: 13.037389686492578,
        lng: 80.20853522815707,
      },
      {
        lat: 13.046468898088635,
        lng: 80.21126978197991,
      },
      {
        lat: 13.04655884885778,
        lng: 80.21456437766119,
      },
      {
        lat: 13.03251832471609,
        lng: 80.21137005873926,
      },
      {
        lat: 13.03886206583812,
        lng: 80.21464795549211,
      },
      {
        lat: 13.037359455668664,
        lng: 80.21252696922915,
      },
      {
        lat: 13.035919218494838,
        lng: 80.20990341497699,
      },
      {
        lat: 13.032433486010499,
        lng: 80.21513923793113,
      },
      {
        lat: 13.039368463262045,
        lng: 80.21119362251672,
      },
      {
        lat: 13.031618859541156,
        lng: 80.21604169125257,
      },
      {
        lat: 13.036621099475585,
        lng: 80.21269574557542,
      },
      {
        lat: 13.046014477166294,
        lng: 80.21309759136312,
      },
      {
        lat: 13.04245658473978,
        lng: 80.20998147656262,
      },
      {
        lat: 13.037756258987576,
        lng: 80.21426083975612,
      },
      {
        lat: 13.035567029824042,
        lng: 80.21175869181813,
      },
      {
        lat: 13.032640616963372,
        lng: 80.21173604705925,
      },
      {
        lat: 13.03138656801296,
        lng: 80.21562984152683,
      },
      {
        lat: 13.03052434583567,
        lng: 80.21547887086287,
      },
      {
        lat: 13.03931581983337,
        lng: 80.21533865648729,
      },
      {
        lat: 13.03651393392676,
        lng: 80.21088205881055,
      },
      {
        lat: 13.036148594440611,
        lng: 80.20916052055344,
      },
      {
        lat: 13.04067731830277,
        lng: 80.2143532912754,
      },
      {
        lat: 13.041764430844218,
        lng: 80.21192464979835,
      },
      {
        lat: 13.033282454930593,
        lng: 80.21588487681699,
      },
      {
        lat: 13.032026377489558,
        lng: 80.20967406788104,
      },
      {
        lat: 13.040324101677623,
        lng: 80.21388249689899,
      },
      {
        lat: 13.038050335466277,
        lng: 80.2132923896739,
      },
      {
        lat: 13.039874230192245,
        lng: 80.21675527303478,
      },
      {
        lat: 13.034128344566044,
        lng: 80.21153531492061,
      },
    ],
    cases: 62,
    coords: [
      {
        lat: 13.035645316366093,
        lng: 80.20802987213108,
      },
      {
        lat: 13.037079743366066,
        lng: 80.20835022457987,
      },
      {
        lat: 13.041566503487628,
        lng: 80.20902629779194,
      },
      {
        lat: 13.044828904267753,
        lng: 80.20940331321657,
      },
      {
        lat: 13.046455111825246,
        lng: 80.20950206760521,
      },
      {
        lat: 13.046980699022354,
        lng: 80.20954108837691,
      },
      {
        lat: 13.047083058122821,
        lng: 80.21197448341776,
      },
      {
        lat: 13.04727336759631,
        lng: 80.21447233754047,
      },
      {
        lat: 13.0446331487156,
        lng: 80.21488124014618,
      },
      {
        lat: 13.044601560500478,
        lng: 80.2156772904191,
      },
      {
        lat: 13.044352833046224,
        lng: 80.21671029608245,
      },
      {
        lat: 13.044029159183813,
        lng: 80.21740030249714,
      },
      {
        lat: 13.043884326357945,
        lng: 80.21754621606087,
      },
      {
        lat: 13.043738943532913,
        lng: 80.21760170440082,
      },
      {
        lat: 13.043574246606896,
        lng: 80.21747649644321,
      },
      {
        lat: 13.042714003771495,
        lng: 80.2167965098225,
      },
      {
        lat: 13.042458236330447,
        lng: 80.21667207261841,
      },
      {
        lat: 13.041744366318134,
        lng: 80.21609936306108,
      },
      {
        lat: 13.04152634708063,
        lng: 80.21619163809325,
      },
      {
        lat: 13.040891823468591,
        lng: 80.21668532957666,
      },
      {
        lat: 13.040146365241496,
        lng: 80.21690867029206,
      },
      {
        lat: 13.039107501369203,
        lng: 80.21680894661185,
      },
      {
        lat: 13.037812980055438,
        lng: 80.21660287077239,
      },
      {
        lat: 13.035990249753496,
        lng: 80.21640126530252,
      },
      {
        lat: 13.03407666893589,
        lng: 80.21623660067557,
      },
      {
        lat: 13.033129645577604,
        lng: 80.21626270155562,
      },
      {
        lat: 13.03276481351771,
        lng: 80.2161753593451,
      },
      {
        lat: 13.031197740657017,
        lng: 80.21608010603457,
      },
      {
        lat: 13.031310435269171,
        lng: 80.21663981751949,
      },
      {
        lat: 13.031365373773918,
        lng: 80.21710858296436,
      },
      {
        lat: 13.030593408070384,
        lng: 80.21707643403633,
      },
      {
        lat: 13.030469175657348,
        lng: 80.21628692482258,
      },
      {
        lat: 13.030460280434182,
        lng: 80.21482446294915,
      },
      {
        lat: 13.03051410244621,
        lng: 80.21385557067397,
      },
      {
        lat: 13.031145906587968,
        lng: 80.20940820920877,
      },
      {
        lat: 13.032226702059654,
        lng: 80.20960878681319,
      },
      {
        lat: 13.034223734776532,
        lng: 80.20996472924128,
      },
      {
        lat: 13.034974988609685,
        lng: 80.21000497666452,
      },
      {
        lat: 13.035303112964261,
        lng: 80.20993229144428,
      },
      {
        lat: 13.035643650326756,
        lng: 80.20804188747843,
      },
      {
        lat: 13.035645316366093,
        lng: 80.20802987213108,
      },
    ],
    properties: {
      Ward_No: 132,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#f6d04d",
      zname: "KODAMBAKKAM(132)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.123641438682103,
        lng: 80.26638977202737,
      },
      {
        lat: 13.13940397434844,
        lng: 80.2769614104688,
      },
      {
        lat: 13.12302415398551,
        lng: 80.26574099444927,
      },
      {
        lat: 13.138468418159666,
        lng: 80.26115802157194,
      },
      {
        lat: 13.137192795755805,
        lng: 80.26420568233853,
      },
      {
        lat: 13.133116229654178,
        lng: 80.26779708037756,
      },
      {
        lat: 13.121261420682465,
        lng: 80.26251790005847,
      },
      {
        lat: 13.133205914863762,
        lng: 80.27005453123532,
      },
      {
        lat: 13.139058067844198,
        lng: 80.27690419392252,
      },
      {
        lat: 13.138429321493541,
        lng: 80.27596220950954,
      },
      {
        lat: 13.13647923284284,
        lng: 80.2685886038693,
      },
      {
        lat: 13.132783565332018,
        lng: 80.2591602415992,
      },
      {
        lat: 13.121681520879156,
        lng: 80.26677791967367,
      },
      {
        lat: 13.123817575506948,
        lng: 80.2664171257505,
      },
      {
        lat: 13.140513783556102,
        lng: 80.2612326925361,
      },
      {
        lat: 13.122482320887585,
        lng: 80.26100524432965,
      },
      {
        lat: 13.139204761671294,
        lng: 80.26986013898085,
      },
      {
        lat: 13.13292116076685,
        lng: 80.27527300662729,
      },
      {
        lat: 13.12213876519509,
        lng: 80.26974835873467,
      },
      {
        lat: 13.127896155448678,
        lng: 80.2619300617155,
      },
      {
        lat: 13.134882411243414,
        lng: 80.27374911726942,
      },
      {
        lat: 13.143473378196548,
        lng: 80.26141493906619,
      },
      {
        lat: 13.124020146098832,
        lng: 80.26548064188248,
      },
      {
        lat: 13.132323009338972,
        lng: 80.2731076613085,
      },
      {
        lat: 13.134323719604739,
        lng: 80.27187838180016,
      },
      {
        lat: 13.11998350638417,
        lng: 80.26572977177727,
      },
      {
        lat: 13.13021634821788,
        lng: 80.26852450293994,
      },
      {
        lat: 13.136083204777115,
        lng: 80.27314110654419,
      },
      {
        lat: 13.134263423504256,
        lng: 80.26598413149385,
      },
      {
        lat: 13.139247608901703,
        lng: 80.2682031446456,
      },
      {
        lat: 13.129711618500524,
        lng: 80.26604511194104,
      },
      {
        lat: 13.12997200779729,
        lng: 80.2639760758818,
      },
      {
        lat: 13.133558660229564,
        lng: 80.26527697866209,
      },
      {
        lat: 13.140827909991923,
        lng: 80.26323350646231,
      },
      {
        lat: 13.133712290947566,
        lng: 80.27417549278228,
      },
      {
        lat: 13.136952798546856,
        lng: 80.26354677273653,
      },
      {
        lat: 13.122435100353098,
        lng: 80.26576878343396,
      },
      {
        lat: 13.140376081504023,
        lng: 80.26264635265609,
      },
      {
        lat: 13.138461186067545,
        lng: 80.27153760036498,
      },
      {
        lat: 13.143638789888705,
        lng: 80.26118359327343,
      },
      {
        lat: 13.133843435855033,
        lng: 80.26259648750595,
      },
      {
        lat: 13.137689900561545,
        lng: 80.26521385166775,
      },
      {
        lat: 13.13431566218938,
        lng: 80.27085968333566,
      },
      {
        lat: 13.12706571645762,
        lng: 80.26586831371891,
      },
      {
        lat: 13.129692553769933,
        lng: 80.26002704641584,
      },
      {
        lat: 13.136312415502324,
        lng: 80.27610425821818,
      },
      {
        lat: 13.124289775887563,
        lng: 80.26070748097256,
      },
      {
        lat: 13.127211034846635,
        lng: 80.26139225453423,
      },
      {
        lat: 13.135861854518273,
        lng: 80.27102250956916,
      },
      {
        lat: 13.13840439742695,
        lng: 80.27254266377989,
      },
      {
        lat: 13.132957402723767,
        lng: 80.27326368141597,
      },
      {
        lat: 13.133471455809598,
        lng: 80.26883700580976,
      },
      {
        lat: 13.13502995955732,
        lng: 80.27139445393226,
      },
      {
        lat: 13.131245441420843,
        lng: 80.26273646695512,
      },
      {
        lat: 13.140154891888603,
        lng: 80.27388826753732,
      },
      {
        lat: 13.122187843179299,
        lng: 80.26266558830022,
      },
      {
        lat: 13.132221860558962,
        lng: 80.26798284505897,
      },
      {
        lat: 13.121043480908952,
        lng: 80.2608598625418,
      },
      {
        lat: 13.131858079815487,
        lng: 80.27347505928677,
      },
      {
        lat: 13.12704496122916,
        lng: 80.26287640168324,
      },
      {
        lat: 13.12034723642983,
        lng: 80.26196126386036,
      },
      {
        lat: 13.137438504140583,
        lng: 80.26166828353617,
      },
      {
        lat: 13.121894029898975,
        lng: 80.2666680799005,
      },
      {
        lat: 13.122065904460781,
        lng: 80.25953967953082,
      },
      {
        lat: 13.122778497454307,
        lng: 80.26059049700224,
      },
      {
        lat: 13.136456956992117,
        lng: 80.27349743357959,
      },
      {
        lat: 13.122364050416063,
        lng: 80.26887317506295,
      },
      {
        lat: 13.119988858270043,
        lng: 80.26013412626534,
      },
      {
        lat: 13.125979520731127,
        lng: 80.26664196687976,
      },
      {
        lat: 13.127372213889817,
        lng: 80.26748403177882,
      },
      {
        lat: 13.123388817788507,
        lng: 80.2605982004358,
      },
      {
        lat: 13.138002585458766,
        lng: 80.2619228496248,
      },
      {
        lat: 13.120508406570229,
        lng: 80.2595900912676,
      },
      {
        lat: 13.137514037067962,
        lng: 80.26284034632498,
      },
      {
        lat: 13.123993904247632,
        lng: 80.26414381464443,
      },
      {
        lat: 13.131636040689163,
        lng: 80.26691443554519,
      },
      {
        lat: 13.12840666261582,
        lng: 80.26868178797545,
      },
      {
        lat: 13.132246232170337,
        lng: 80.27515395269864,
      },
      {
        lat: 13.134840091649217,
        lng: 80.2745454554975,
      },
      {
        lat: 13.121259285923244,
        lng: 80.26082807237879,
      },
      {
        lat: 13.137467575683223,
        lng: 80.26577524662333,
      },
      {
        lat: 13.12171910261311,
        lng: 80.27244209490759,
      },
      {
        lat: 13.142711921520126,
        lng: 80.2638355839357,
      },
      {
        lat: 13.123778892730368,
        lng: 80.26700004905325,
      },
      {
        lat: 13.1407940418549,
        lng: 80.27546706894152,
      },
      {
        lat: 13.127770646578883,
        lng: 80.25941467340277,
      },
      {
        lat: 13.137420473486237,
        lng: 80.27216622185264,
      },
      {
        lat: 13.123474133847349,
        lng: 80.27223685663799,
      },
      {
        lat: 13.129861600660469,
        lng: 80.26195342751099,
      },
      {
        lat: 13.140123617469111,
        lng: 80.26559025143091,
      },
      {
        lat: 13.136730806566455,
        lng: 80.27396653799694,
      },
      {
        lat: 13.125307363071938,
        lng: 80.27202472307536,
      },
      {
        lat: 13.143105196131222,
        lng: 80.26317724739982,
      },
      {
        lat: 13.124975983693188,
        lng: 80.26520379619649,
      },
      {
        lat: 13.13760182425154,
        lng: 80.27664469866721,
      },
      {
        lat: 13.137997508203007,
        lng: 80.26852676390519,
      },
      {
        lat: 13.139376192768573,
        lng: 80.27639097847268,
      },
      {
        lat: 13.122862703190965,
        lng: 80.27071931128516,
      },
      {
        lat: 13.134060186826572,
        lng: 80.27296038123922,
      },
      {
        lat: 13.136379381063657,
        lng: 80.27310057786377,
      },
      {
        lat: 13.136394536221772,
        lng: 80.26321751742313,
      },
      {
        lat: 13.128381861496301,
        lng: 80.26193649600346,
      },
      {
        lat: 13.132480445142255,
        lng: 80.26436174020374,
      },
      {
        lat: 13.138644255708849,
        lng: 80.2739448166465,
      },
      {
        lat: 13.141179879845636,
        lng: 80.26815356407631,
      },
      {
        lat: 13.129069179398392,
        lng: 80.25923458581909,
      },
      {
        lat: 13.142232851016336,
        lng: 80.26501295196876,
      },
      {
        lat: 13.137385259360878,
        lng: 80.26163464947604,
      },
      {
        lat: 13.120111488928053,
        lng: 80.26718601702233,
      },
      {
        lat: 13.137126174367813,
        lng: 80.26331268164904,
      },
      {
        lat: 13.133019798384922,
        lng: 80.26518974899899,
      },
      {
        lat: 13.138326056687315,
        lng: 80.26007062643319,
      },
      {
        lat: 13.123043196951542,
        lng: 80.26787805464748,
      },
      {
        lat: 13.145034714164657,
        lng: 80.26111469646723,
      },
      {
        lat: 13.132280738978206,
        lng: 80.2671046691336,
      },
      {
        lat: 13.133379777291193,
        lng: 80.26439033360425,
      },
      {
        lat: 13.134387581897787,
        lng: 80.26210097046523,
      },
      {
        lat: 13.133902062436967,
        lng: 80.26438088345432,
      },
      {
        lat: 13.135139007450762,
        lng: 80.27567183426801,
      },
      {
        lat: 13.137142581512173,
        lng: 80.25857007521059,
      },
    ],
    cases: 120,
    coords: [
      {
        lat: 13.142182161357912,
        lng: 80.27853324307141,
      },
      {
        lat: 13.138466189402122,
        lng: 80.27710453370345,
      },
      {
        lat: 13.135083335763245,
        lng: 80.27612726905357,
      },
      {
        lat: 13.13058972424653,
        lng: 80.27474815139973,
      },
      {
        lat: 13.130748613864448,
        lng: 80.2739812281785,
      },
      {
        lat: 13.130710575807226,
        lng: 80.27156881787066,
      },
      {
        lat: 13.126959120354199,
        lng: 80.26941982866128,
      },
      {
        lat: 13.126038682289403,
        lng: 80.27174755340741,
      },
      {
        lat: 13.126273306992253,
        lng: 80.27190796315666,
      },
      {
        lat: 13.12516073863823,
        lng: 80.27337893806032,
      },
      {
        lat: 13.120162006502735,
        lng: 80.2721635430654,
      },
      {
        lat: 13.11952463214585,
        lng: 80.26820372217989,
      },
      {
        lat: 13.119462381154385,
        lng: 80.26500375394076,
      },
      {
        lat: 13.119588067365958,
        lng: 80.26456368426274,
      },
      {
        lat: 13.119599106420921,
        lng: 80.26168880985247,
      },
      {
        lat: 13.11815929556676,
        lng: 80.26139735921645,
      },
      {
        lat: 13.118149914803814,
        lng: 80.2612771980759,
      },
      {
        lat: 13.11799526238287,
        lng: 80.26112586427195,
      },
      {
        lat: 13.11807922048068,
        lng: 80.26088770956333,
      },
      {
        lat: 13.118180052720765,
        lng: 80.26061549145172,
      },
      {
        lat: 13.118275933599525,
        lng: 80.25952922101793,
      },
      {
        lat: 13.122149466766983,
        lng: 80.2593379265912,
      },
      {
        lat: 13.126125885534185,
        lng: 80.25910246540774,
      },
      {
        lat: 13.131381964976079,
        lng: 80.25881158608918,
      },
      {
        lat: 13.136039161651825,
        lng: 80.25868612534721,
      },
      {
        lat: 13.136322572372238,
        lng: 80.25808654083072,
      },
      {
        lat: 13.145911134066889,
        lng: 80.26043366727747,
      },
      {
        lat: 13.145874009286098,
        lng: 80.2606436973303,
      },
      {
        lat: 13.145542196276585,
        lng: 80.26513985991701,
      },
      {
        lat: 13.145437273371522,
        lng: 80.26560434796048,
      },
      {
        lat: 13.144971039700776,
        lng: 80.26571527844477,
      },
      {
        lat: 13.142022389618935,
        lng: 80.26513398741082,
      },
      {
        lat: 13.14166281401587,
        lng: 80.26506570750726,
      },
      {
        lat: 13.14148476137674,
        lng: 80.26531684521113,
      },
      {
        lat: 13.141488665491462,
        lng: 80.26595871993632,
      },
      {
        lat: 13.142102272143994,
        lng: 80.2782674574826,
      },
      {
        lat: 13.142182161357912,
        lng: 80.27853324307141,
      },
    ],
    properties: {
      Ward_No: 37,
      Zone_Name: "TONDIARPET",
      fillColor: "#de4307",
      zname: "TONDIARPET(37)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.1098550348956,
        lng: 80.24959153556355,
      },
      {
        lat: 13.1051166427963,
        lng: 80.24859466181378,
      },
      {
        lat: 13.10560541360695,
        lng: 80.24798513666484,
      },
      {
        lat: 13.104920104909173,
        lng: 80.25544730575479,
      },
      {
        lat: 13.107241788781378,
        lng: 80.25640008120627,
      },
      {
        lat: 13.107066928017245,
        lng: 80.25519265141929,
      },
      {
        lat: 13.10548607915172,
        lng: 80.25429091764762,
      },
      {
        lat: 13.113864246401098,
        lng: 80.24786063127429,
      },
      {
        lat: 13.108115074249778,
        lng: 80.25286732737224,
      },
      {
        lat: 13.112073402153117,
        lng: 80.24681057409154,
      },
      {
        lat: 13.108390897698301,
        lng: 80.24806403584894,
      },
      {
        lat: 13.103482668524988,
        lng: 80.2591773604909,
      },
      {
        lat: 13.112595346940289,
        lng: 80.24717377546416,
      },
      {
        lat: 13.110366169526763,
        lng: 80.24714458028507,
      },
      {
        lat: 13.105677674994105,
        lng: 80.24784167901245,
      },
      {
        lat: 13.107177300284084,
        lng: 80.24751460626713,
      },
      {
        lat: 13.106763878862273,
        lng: 80.2603204822929,
      },
      {
        lat: 13.106842980926418,
        lng: 80.2503792047762,
      },
      {
        lat: 13.108136797728086,
        lng: 80.25382100206656,
      },
      {
        lat: 13.104855568549274,
        lng: 80.25539998230389,
      },
      {
        lat: 13.105448933903888,
        lng: 80.2525481007086,
      },
      {
        lat: 13.112472960273024,
        lng: 80.24931423536155,
      },
      {
        lat: 13.110920802002266,
        lng: 80.2476399446209,
      },
      {
        lat: 13.10697141349943,
        lng: 80.2475584924605,
      },
      {
        lat: 13.105057527474376,
        lng: 80.25821508985157,
      },
      {
        lat: 13.105498319599606,
        lng: 80.25345795641617,
      },
      {
        lat: 13.10883249400885,
        lng: 80.25672320729863,
      },
      {
        lat: 13.110363788539955,
        lng: 80.25004976578535,
      },
      {
        lat: 13.106223802325774,
        lng: 80.26070633952081,
      },
      {
        lat: 13.1070844231192,
        lng: 80.25026874322027,
      },
      {
        lat: 13.103380206952519,
        lng: 80.26022278087241,
      },
      {
        lat: 13.1126108533983,
        lng: 80.24534385572052,
      },
      {
        lat: 13.11133029100315,
        lng: 80.2447817397206,
      },
      {
        lat: 13.111835661461988,
        lng: 80.24692400486438,
      },
      {
        lat: 13.10631173979316,
        lng: 80.25532095620939,
      },
      {
        lat: 13.106307467091161,
        lng: 80.25915186343508,
      },
      {
        lat: 13.109652558994652,
        lng: 80.25165047740357,
      },
      {
        lat: 13.108773627819911,
        lng: 80.25750363817426,
      },
      {
        lat: 13.11488316744218,
        lng: 80.25218775708879,
      },
      {
        lat: 13.108324897824897,
        lng: 80.25924711368492,
      },
      {
        lat: 13.108811149329084,
        lng: 80.25387047448689,
      },
      {
        lat: 13.113810647070219,
        lng: 80.25220931996274,
      },
      {
        lat: 13.110057848280567,
        lng: 80.25135651051028,
      },
      {
        lat: 13.107454297050783,
        lng: 80.24692364935115,
      },
      {
        lat: 13.109566786222302,
        lng: 80.25217532365791,
      },
      {
        lat: 13.104684177564447,
        lng: 80.26053385323681,
      },
      {
        lat: 13.111894491339337,
        lng: 80.24846237777818,
      },
      {
        lat: 13.108602795925584,
        lng: 80.24917982468412,
      },
      {
        lat: 13.11018568807111,
        lng: 80.2491014839262,
      },
      {
        lat: 13.10460030322924,
        lng: 80.25949491270944,
      },
      {
        lat: 13.111753927406234,
        lng: 80.24904443040549,
      },
      {
        lat: 13.108491380328001,
        lng: 80.25008098983287,
      },
      {
        lat: 13.108520113184616,
        lng: 80.24762511618407,
      },
      {
        lat: 13.104875188032947,
        lng: 80.26074405920916,
      },
      {
        lat: 13.111602290749495,
        lng: 80.24997515204082,
      },
      {
        lat: 13.110701051496196,
        lng: 80.25044793284052,
      },
      {
        lat: 13.104184044927798,
        lng: 80.25881858132296,
      },
      {
        lat: 13.107496945573972,
        lng: 80.25182544883887,
      },
      {
        lat: 13.105702792040667,
        lng: 80.25918255397433,
      },
      {
        lat: 13.11106753354151,
        lng: 80.24747559746471,
      },
      {
        lat: 13.107579701606214,
        lng: 80.25017918772801,
      },
      {
        lat: 13.110114801550207,
        lng: 80.24757256689286,
      },
      {
        lat: 13.111270737782677,
        lng: 80.25185904468455,
      },
      {
        lat: 13.109237908480845,
        lng: 80.24970971570349,
      },
      {
        lat: 13.109246474671078,
        lng: 80.25005545120263,
      },
      {
        lat: 13.112797660586178,
        lng: 80.24676558442334,
      },
      {
        lat: 13.112212973439304,
        lng: 80.2448816084376,
      },
      {
        lat: 13.112795118896821,
        lng: 80.24885331827112,
      },
      {
        lat: 13.113549887570624,
        lng: 80.25025194763765,
      },
      {
        lat: 13.114572536865323,
        lng: 80.24977105526196,
      },
      {
        lat: 13.105603730480425,
        lng: 80.24895294469394,
      },
      {
        lat: 13.113761298967937,
        lng: 80.24924519032537,
      },
      {
        lat: 13.110091871551187,
        lng: 80.24951264495924,
      },
      {
        lat: 13.104329083982252,
        lng: 80.25690042403359,
      },
      {
        lat: 13.10932193542136,
        lng: 80.24976566114137,
      },
      {
        lat: 13.106177328844657,
        lng: 80.25823572072413,
      },
      {
        lat: 13.11091560984967,
        lng: 80.25221053448188,
      },
      {
        lat: 13.107177158733993,
        lng: 80.25930193739048,
      },
      {
        lat: 13.105855970251495,
        lng: 80.25774458928781,
      },
      {
        lat: 13.10705587368861,
        lng: 80.24752742492073,
      },
      {
        lat: 13.114676796572656,
        lng: 80.2490136330364,
      },
      {
        lat: 13.110135540801444,
        lng: 80.24652391169313,
      },
      {
        lat: 13.109597553466958,
        lng: 80.2492892855462,
      },
      {
        lat: 13.105873780417244,
        lng: 80.25171538885273,
      },
      {
        lat: 13.109464681070643,
        lng: 80.25065184753872,
      },
      {
        lat: 13.106970457772814,
        lng: 80.25164707994944,
      },
      {
        lat: 13.104641956744777,
        lng: 80.25634039684454,
      },
      {
        lat: 13.112000890966625,
        lng: 80.2441209226837,
      },
      {
        lat: 13.10865450085223,
        lng: 80.2451259896239,
      },
      {
        lat: 13.106498847574885,
        lng: 80.2546465184894,
      },
      {
        lat: 13.114184480745186,
        lng: 80.24882710966978,
      },
    ],
    cases: 91,
    coords: [
      {
        lat: 13.109313062909381,
        lng: 80.25977481437833,
      },
      {
        lat: 13.107950415674368,
        lng: 80.26051966179803,
      },
      {
        lat: 13.107195049178753,
        lng: 80.2607138514147,
      },
      {
        lat: 13.105891213886737,
        lng: 80.2609126828652,
      },
      {
        lat: 13.10432237211733,
        lng: 80.26107619778342,
      },
      {
        lat: 13.103792130724454,
        lng: 80.26096800730126,
      },
      {
        lat: 13.103279659859668,
        lng: 80.26067186966833,
      },
      {
        lat: 13.10260515701822,
        lng: 80.26061155178473,
      },
      {
        lat: 13.102393327775006,
        lng: 80.26032133867204,
      },
      {
        lat: 13.102371307596187,
        lng: 80.26015466453244,
      },
      {
        lat: 13.103536315542492,
        lng: 80.25828848168335,
      },
      {
        lat: 13.103805959634863,
        lng: 80.2577230454211,
      },
      {
        lat: 13.10388731694316,
        lng: 80.25728434805535,
      },
      {
        lat: 13.103821763833935,
        lng: 80.25686775159329,
      },
      {
        lat: 13.103944751221965,
        lng: 80.25636612921313,
      },
      {
        lat: 13.105039258646903,
        lng: 80.25326994682581,
      },
      {
        lat: 13.103148918711943,
        lng: 80.25277671511499,
      },
      {
        lat: 13.103532530914286,
        lng: 80.25075893356042,
      },
      {
        lat: 13.103657548009929,
        lng: 80.25059101500642,
      },
      {
        lat: 13.103846215363898,
        lng: 80.25052684557728,
      },
      {
        lat: 13.104141051053073,
        lng: 80.25064949568308,
      },
      {
        lat: 13.104414247133715,
        lng: 80.25066804111647,
      },
      {
        lat: 13.104497507292903,
        lng: 80.25054219108765,
      },
      {
        lat: 13.104640358295294,
        lng: 80.2498526825317,
      },
      {
        lat: 13.104844449278334,
        lng: 80.24887064980749,
      },
      {
        lat: 13.104840643576546,
        lng: 80.24824495513367,
      },
      {
        lat: 13.10475218229142,
        lng: 80.24751568910824,
      },
      {
        lat: 13.104854671781455,
        lng: 80.24709767045806,
      },
      {
        lat: 13.105269957724134,
        lng: 80.24630156840092,
      },
      {
        lat: 13.105374223208392,
        lng: 80.24617554059857,
      },
      {
        lat: 13.105542900091327,
        lng: 80.24627840085617,
      },
      {
        lat: 13.105859629102431,
        lng: 80.24654686861211,
      },
      {
        lat: 13.10609068767626,
        lng: 80.24654491310345,
      },
      {
        lat: 13.107415672283155,
        lng: 80.24680484773094,
      },
      {
        lat: 13.107709112548285,
        lng: 80.24669807645611,
      },
      {
        lat: 13.107938141414726,
        lng: 80.24636241712129,
      },
      {
        lat: 13.107955341037787,
        lng: 80.24573654467396,
      },
      {
        lat: 13.108054532252996,
        lng: 80.24477625730647,
      },
      {
        lat: 13.10840743650455,
        lng: 80.24408497101551,
      },
      {
        lat: 13.108625809406005,
        lng: 80.24199736659381,
      },
      {
        lat: 13.109744419610617,
        lng: 80.2419192950358,
      },
      {
        lat: 13.10966156818185,
        lng: 80.24215714859635,
      },
      {
        lat: 13.109578122835035,
        lng: 80.24229735620874,
      },
      {
        lat: 13.109621287880936,
        lng: 80.24246439256282,
      },
      {
        lat: 13.10970787250869,
        lng: 80.24284031353493,
      },
      {
        lat: 13.109685289528432,
        lng: 80.24374726371292,
      },
      {
        lat: 13.1096866470554,
        lng: 80.24397045445204,
      },
      {
        lat: 13.109841270536302,
        lng: 80.24398309597277,
      },
      {
        lat: 13.110386889417063,
        lng: 80.24360182449843,
      },
      {
        lat: 13.110794054919559,
        lng: 80.24355652813345,
      },
      {
        lat: 13.11297155993732,
        lng: 80.24352414920452,
      },
      {
        lat: 13.113381843112881,
        lng: 80.24366131621477,
      },
      {
        lat: 13.113160565260626,
        lng: 80.24381764680463,
      },
      {
        lat: 13.113051872276808,
        lng: 80.24421574405086,
      },
      {
        lat: 13.113089929946469,
        lng: 80.24681914013729,
      },
      {
        lat: 13.112961430887479,
        lng: 80.24761458235652,
      },
      {
        lat: 13.112830784582103,
        lng: 80.24805699621584,
      },
      {
        lat: 13.112787683156581,
        lng: 80.24827801507736,
      },
      {
        lat: 13.113055149100887,
        lng: 80.24840814389349,
      },
      {
        lat: 13.113343494743527,
        lng: 80.24831744191643,
      },
      {
        lat: 13.113518852909253,
        lng: 80.24791878046547,
      },
      {
        lat: 13.113851776209172,
        lng: 80.24784976662434,
      },
      {
        lat: 13.114318969292997,
        lng: 80.24793407428143,
      },
      {
        lat: 13.115296859494597,
        lng: 80.24794786355127,
      },
      {
        lat: 13.11543072666962,
        lng: 80.24803499223172,
      },
      {
        lat: 13.11545469303471,
        lng: 80.248321639706,
      },
      {
        lat: 13.115486040559253,
        lng: 80.24982182216758,
      },
      {
        lat: 13.115576806308814,
        lng: 80.25012996970959,
      },
      {
        lat: 13.115470663180082,
        lng: 80.25094728813315,
      },
      {
        lat: 13.115319002973472,
        lng: 80.25158846851318,
      },
      {
        lat: 13.115011656473296,
        lng: 80.25220890109847,
      },
      {
        lat: 13.114724518656946,
        lng: 80.25249818152814,
      },
      {
        lat: 13.114325466986724,
        lng: 80.25265601666383,
      },
      {
        lat: 13.113181276178505,
        lng: 80.25270321144147,
      },
      {
        lat: 13.11264688110151,
        lng: 80.2525312108994,
      },
      {
        lat: 13.110069026538275,
        lng: 80.25253096254268,
      },
      {
        lat: 13.109556853188467,
        lng: 80.25235877393222,
      },
      {
        lat: 13.109068243594805,
        lng: 80.25240703997855,
      },
      {
        lat: 13.108801717070818,
        lng: 80.25243136106987,
      },
      {
        lat: 13.10873572290365,
        lng: 80.25254224663709,
      },
      {
        lat: 13.108829306914098,
        lng: 80.25331374390146,
      },
      {
        lat: 13.109112206192167,
        lng: 80.25598126405474,
      },
      {
        lat: 13.109318777679764,
        lng: 80.25706072079305,
      },
      {
        lat: 13.109542873792734,
        lng: 80.25736773992546,
      },
      {
        lat: 13.109657337501517,
        lng: 80.2579184063967,
      },
      {
        lat: 13.109751860932343,
        lng: 80.25884435356858,
      },
      {
        lat: 13.109822686404051,
        lng: 80.2595277818113,
      },
      {
        lat: 13.10977931657289,
        lng: 80.25970467212778,
      },
      {
        lat: 13.109535280181898,
        lng: 80.2597729336959,
      },
      {
        lat: 13.109313062909381,
        lng: 80.25977481437833,
      },
    ],
    properties: {
      Ward_No: 70,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#f6d04d",
      zname: "THIRU-VI-KA-NAGAR(70)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.079070072869,
        lng: 80.2236335874163,
      },
      {
        lat: 13.081575492394236,
        lng: 80.21825265719811,
      },
      {
        lat: 13.088552226597516,
        lng: 80.21823183157719,
      },
      {
        lat: 13.092503231610465,
        lng: 80.22046329540794,
      },
      {
        lat: 13.090218376262973,
        lng: 80.21858571878141,
      },
      {
        lat: 13.076745920356437,
        lng: 80.22087113017062,
      },
      {
        lat: 13.085064599268026,
        lng: 80.21695668396795,
      },
      {
        lat: 13.082070719195029,
        lng: 80.22521367920098,
      },
      {
        lat: 13.09269573049388,
        lng: 80.21601942373677,
      },
      {
        lat: 13.090658540067006,
        lng: 80.21691451460009,
      },
      {
        lat: 13.07476437101031,
        lng: 80.22146370876312,
      },
      {
        lat: 13.091458903898541,
        lng: 80.21732247450554,
      },
      {
        lat: 13.085381027944786,
        lng: 80.2184457092555,
      },
      {
        lat: 13.079321148506509,
        lng: 80.22222855376967,
      },
      {
        lat: 13.090461520662393,
        lng: 80.21957339042201,
      },
      {
        lat: 13.08114260884888,
        lng: 80.21894731811781,
      },
      {
        lat: 13.084473045663765,
        lng: 80.22354123427935,
      },
      {
        lat: 13.082669935223457,
        lng: 80.2210374014161,
      },
      {
        lat: 13.093926437731872,
        lng: 80.22397848717884,
      },
      {
        lat: 13.084168042640213,
        lng: 80.21986850164048,
      },
      {
        lat: 13.080835656219763,
        lng: 80.22481255246171,
      },
      {
        lat: 13.093582074559203,
        lng: 80.21971252558257,
      },
      {
        lat: 13.08775687496692,
        lng: 80.22138381756758,
      },
      {
        lat: 13.090869196099499,
        lng: 80.22122719247984,
      },
      {
        lat: 13.092565771612838,
        lng: 80.22299024149888,
      },
      {
        lat: 13.079894905423687,
        lng: 80.21991871870881,
      },
      {
        lat: 13.095440169345476,
        lng: 80.22215407294111,
      },
      {
        lat: 13.090488420704578,
        lng: 80.22281010308708,
      },
      {
        lat: 13.084337048934428,
        lng: 80.21837263359191,
      },
      {
        lat: 13.094608419519446,
        lng: 80.22044688888906,
      },
      {
        lat: 13.090144562981573,
        lng: 80.21822200598645,
      },
      {
        lat: 13.08178259001814,
        lng: 80.21838603138568,
      },
      {
        lat: 13.090114457164466,
        lng: 80.2229520462867,
      },
      {
        lat: 13.082941274939762,
        lng: 80.22495323130894,
      },
      {
        lat: 13.093895870523356,
        lng: 80.22488552699342,
      },
      {
        lat: 13.08052950841652,
        lng: 80.224129628959,
      },
      {
        lat: 13.08613326207486,
        lng: 80.22159448141366,
      },
      {
        lat: 13.084599992461778,
        lng: 80.22043621344059,
      },
      {
        lat: 13.087159902551845,
        lng: 80.22194189906315,
      },
      {
        lat: 13.078677222974916,
        lng: 80.22158326433983,
      },
      {
        lat: 13.090831594635333,
        lng: 80.22106950409194,
      },
      {
        lat: 13.078347870830681,
        lng: 80.21859858641005,
      },
      {
        lat: 13.087999049075451,
        lng: 80.21962815739022,
      },
      {
        lat: 13.087271413863563,
        lng: 80.22349314307523,
      },
      {
        lat: 13.091627910667304,
        lng: 80.21640946293644,
      },
      {
        lat: 13.090587157946095,
        lng: 80.21656097407921,
      },
      {
        lat: 13.07709584858432,
        lng: 80.2209201445698,
      },
      {
        lat: 13.080307527424656,
        lng: 80.2245987148603,
      },
      {
        lat: 13.084821311785673,
        lng: 80.21765227137344,
      },
      {
        lat: 13.09159339819598,
        lng: 80.21863082197932,
      },
      {
        lat: 13.089397787664073,
        lng: 80.22335413212383,
      },
      {
        lat: 13.079541910438056,
        lng: 80.22403781333662,
      },
      {
        lat: 13.09184108305947,
        lng: 80.22226910439936,
      },
      {
        lat: 13.07873645483382,
        lng: 80.22394727645066,
      },
      {
        lat: 13.082389041898297,
        lng: 80.21874674372998,
      },
      {
        lat: 13.076452443873041,
        lng: 80.21925924106996,
      },
    ],
    cases: 56,
    coords: [
      {
        lat: 13.093730364054135,
        lng: 80.22515887050591,
      },
      {
        lat: 13.092932673232378,
        lng: 80.2244217891609,
      },
      {
        lat: 13.092389280811206,
        lng: 80.22391056616932,
      },
      {
        lat: 13.092014003101635,
        lng: 80.22370741349685,
      },
      {
        lat: 13.090933367277344,
        lng: 80.22369592632364,
      },
      {
        lat: 13.090246780364884,
        lng: 80.22355730696526,
      },
      {
        lat: 13.08848018507877,
        lng: 80.22351035949575,
      },
      {
        lat: 13.08567664539079,
        lng: 80.22380231394762,
      },
      {
        lat: 13.085199856769671,
        lng: 80.22399204500387,
      },
      {
        lat: 13.083712298868628,
        lng: 80.22540767005815,
      },
      {
        lat: 13.081613740824942,
        lng: 80.22544606357353,
      },
      {
        lat: 13.081155848896424,
        lng: 80.22532614158465,
      },
      {
        lat: 13.080012750447217,
        lng: 80.22529455017425,
      },
      {
        lat: 13.079866544192068,
        lng: 80.22517199030885,
      },
      {
        lat: 13.079739485609672,
        lng: 80.22478104102305,
      },
      {
        lat: 13.079509033763477,
        lng: 80.22447349827924,
      },
      {
        lat: 13.079091946983736,
        lng: 80.22422943369891,
      },
      {
        lat: 13.078281689723225,
        lng: 80.2242569239907,
      },
      {
        lat: 13.077867112746645,
        lng: 80.22442549565922,
      },
      {
        lat: 13.077658694846965,
        lng: 80.22432409518147,
      },
      {
        lat: 13.077490454645137,
        lng: 80.2239953930499,
      },
      {
        lat: 13.07753100881376,
        lng: 80.22382998683338,
      },
      {
        lat: 13.07775719399448,
        lng: 80.22343604795417,
      },
      {
        lat: 13.077856948043605,
        lng: 80.22275431885136,
      },
      {
        lat: 13.078040194742865,
        lng: 80.22213378175199,
      },
      {
        lat: 13.07799763273166,
        lng: 80.22196907896927,
      },
      {
        lat: 13.077810370347475,
        lng: 80.22192939807043,
      },
      {
        lat: 13.077623107963063,
        lng: 80.22188971717163,
      },
      {
        lat: 13.077497806242972,
        lng: 80.22178761326003,
      },
      {
        lat: 13.074301665847393,
        lng: 80.22182657998484,
      },
      {
        lat: 13.074395381455929,
        lng: 80.22054033780189,
      },
      {
        lat: 13.074797026578219,
        lng: 80.21818709527335,
      },
      {
        lat: 13.075919919694606,
        lng: 80.21819823766786,
      },
      {
        lat: 13.076398512207065,
        lng: 80.21825612440573,
      },
      {
        lat: 13.076898022279536,
        lng: 80.21833447984308,
      },
      {
        lat: 13.077417068660225,
        lng: 80.2182062126381,
      },
      {
        lat: 13.078559623652891,
        lng: 80.21803137705743,
      },
      {
        lat: 13.078932874955381,
        lng: 80.21786305229367,
      },
      {
        lat: 13.079286715518002,
        lng: 80.21792199483966,
      },
      {
        lat: 13.079474220147867,
        lng: 80.21798234512988,
      },
      {
        lat: 13.07968176335895,
        lng: 80.21791865144732,
      },
      {
        lat: 13.080119148593313,
        lng: 80.2180388241237,
      },
      {
        lat: 13.081429420771043,
        lng: 80.21808967214088,
      },
      {
        lat: 13.081949597266824,
        lng: 80.21814720694287,
      },
      {
        lat: 13.084195509067715,
        lng: 80.21819013639931,
      },
      {
        lat: 13.08450638439534,
        lng: 80.21802233953954,
      },
      {
        lat: 13.084670962371428,
        lng: 80.21773190645115,
      },
      {
        lat: 13.0846244190808,
        lng: 80.21560816129752,
      },
      {
        lat: 13.086659236266126,
        lng: 80.215590940112,
      },
      {
        lat: 13.087115039520706,
        lng: 80.21569342480697,
      },
      {
        lat: 13.089686143386333,
        lng: 80.21580459273322,
      },
      {
        lat: 13.09347344415392,
        lng: 80.2158857879687,
      },
      {
        lat: 13.093595796658404,
        lng: 80.21631281631261,
      },
      {
        lat: 13.093839633832102,
        lng: 80.21702419239651,
      },
      {
        lat: 13.094032967900857,
        lng: 80.21730793205668,
      },
      {
        lat: 13.094204666396815,
        lng: 80.21797235601969,
      },
      {
        lat: 13.094422673461306,
        lng: 80.2183747934895,
      },
      {
        lat: 13.095982881789162,
        lng: 80.21893234083483,
      },
      {
        lat: 13.096438940459956,
        lng: 80.21909495036495,
      },
      {
        lat: 13.096749420022155,
        lng: 80.21894963474753,
      },
      {
        lat: 13.095894908529528,
        lng: 80.22149477968605,
      },
      {
        lat: 13.095221201738255,
        lng: 80.22332200093878,
      },
      {
        lat: 13.093774544403113,
        lng: 80.225111525069,
      },
      {
        lat: 13.093730364054135,
        lng: 80.22515887050591,
      },
    ],
    properties: {
      Ward_No: 101,
      Zone_Name: "ANNANAGAR",
      fillColor: "#8bc24c",
      zname: "ANNANAGAR(101)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.08873596068835,
        lng: 80.29483393116392,
      },
      {
        lat: 13.082094157891985,
        lng: 80.28298868284121,
      },
      {
        lat: 13.08737713851157,
        lng: 80.29218027891197,
      },
      {
        lat: 13.084757901985057,
        lng: 80.28913792746081,
      },
      {
        lat: 13.095817374157372,
        lng: 80.29757674125045,
      },
      {
        lat: 13.089727565084203,
        lng: 80.28741444144471,
      },
      {
        lat: 13.080112594876258,
        lng: 80.28156879674286,
      },
      {
        lat: 13.082323746784407,
        lng: 80.29587382901768,
      },
      {
        lat: 13.098480506887647,
        lng: 80.2926427926793,
      },
      {
        lat: 13.076715863918531,
        lng: 80.28179644587847,
      },
      {
        lat: 13.091516689900732,
        lng: 80.28612177920279,
      },
      {
        lat: 13.08222057849702,
        lng: 80.29025602944031,
      },
      {
        lat: 13.080731537226821,
        lng: 80.28556969090067,
      },
      {
        lat: 13.071283054517671,
        lng: 80.28829076597275,
      },
      {
        lat: 13.080442447220465,
        lng: 80.29290157766256,
      },
      {
        lat: 13.091903753664159,
        lng: 80.29614183718175,
      },
      {
        lat: 13.091737246183998,
        lng: 80.29078125775975,
      },
      {
        lat: 13.081955871890882,
        lng: 80.2834999245934,
      },
      {
        lat: 13.071702244121713,
        lng: 80.28524914103367,
      },
      {
        lat: 13.072179577379709,
        lng: 80.27592251164889,
      },
    ],
    cases: 20,
    coords: [
      {
        lat: 13.071956559269594,
        lng: 80.274592886616,
      },
      {
        lat: 13.075775120331924,
        lng: 80.27878066666692,
      },
      {
        lat: 13.078594312695987,
        lng: 80.28175833654535,
      },
      {
        lat: 13.078982896339436,
        lng: 80.28181012179564,
      },
      {
        lat: 13.079287781393438,
        lng: 80.28178000450718,
      },
      {
        lat: 13.079620231014923,
        lng: 80.28172211696518,
      },
      {
        lat: 13.080586833168928,
        lng: 80.2810530491456,
      },
      {
        lat: 13.080974746887778,
        lng: 80.28099469219656,
      },
      {
        lat: 13.081391732504741,
        lng: 80.28115638494292,
      },
      {
        lat: 13.081976785225388,
        lng: 80.28159202496666,
      },
      {
        lat: 13.083034455141725,
        lng: 80.28221642387948,
      },
      {
        lat: 13.084732506834932,
        lng: 80.28325379132161,
      },
      {
        lat: 13.085072688374526,
        lng: 80.28401524917976,
      },
      {
        lat: 13.085371726689937,
        lng: 80.2845026778942,
      },
      {
        lat: 13.08558978973856,
        lng: 80.28465761942695,
      },
      {
        lat: 13.086637054583937,
        lng: 80.28484473997428,
      },
      {
        lat: 13.088668606741612,
        lng: 80.28532333877348,
      },
      {
        lat: 13.089671180300375,
        lng: 80.28536078805051,
      },
      {
        lat: 13.09412284692675,
        lng: 80.28616377424073,
      },
      {
        lat: 13.096868893295403,
        lng: 80.28651311208766,
      },
      {
        lat: 13.096213170921004,
        lng: 80.29052387889138,
      },
      {
        lat: 13.09870252968384,
        lng: 80.2910849644734,
      },
      {
        lat: 13.099046790135388,
        lng: 80.28984788512575,
      },
      {
        lat: 13.10256285448763,
        lng: 80.29056845163113,
      },
      {
        lat: 13.102568056960768,
        lng: 80.29426573777654,
      },
      {
        lat: 13.10250195244369,
        lng: 80.29496488164115,
      },
      {
        lat: 13.102575421590476,
        lng: 80.29547655508296,
      },
      {
        lat: 13.102460573688143,
        lng: 80.29587339156684,
      },
      {
        lat: 13.102180678518366,
        lng: 80.2976916588518,
      },
      {
        lat: 13.102029800053224,
        lng: 80.29759637263844,
      },
      {
        lat: 13.101490002858183,
        lng: 80.29742263336904,
      },
      {
        lat: 13.101059466473844,
        lng: 80.29749760019531,
      },
      {
        lat: 13.10034487006833,
        lng: 80.29810989423405,
      },
      {
        lat: 13.099847927702895,
        lng: 80.29907696163629,
      },
      {
        lat: 13.098400172331498,
        lng: 80.30315463022153,
      },
      {
        lat: 13.097899976537297,
        lng: 80.30358680201931,
      },
      {
        lat: 13.097146429416783,
        lng: 80.30370016411189,
      },
      {
        lat: 13.09660771669811,
        lng: 80.30370472337732,
      },
      {
        lat: 13.095671995928203,
        lng: 80.30339168874148,
      },
      {
        lat: 13.093079451125119,
        lng: 80.30230812229999,
      },
      {
        lat: 13.090486255636252,
        lng: 80.30111757673761,
      },
      {
        lat: 13.085331874724897,
        lng: 80.29809430693658,
      },
      {
        lat: 13.079565000675247,
        lng: 80.29475526694054,
      },
      {
        lat: 13.07394568746529,
        lng: 80.29205688586565,
      },
      {
        lat: 13.071207316984443,
        lng: 80.29061793815866,
      },
      {
        lat: 13.069730714543242,
        lng: 80.28995286571882,
      },
      {
        lat: 13.068219933083089,
        lng: 80.28957337488544,
      },
      {
        lat: 13.067357125151924,
        lng: 80.28943803088232,
      },
      {
        lat: 13.066639058422044,
        lng: 80.28947976960997,
      },
      {
        lat: 13.066586131365586,
        lng: 80.28946897037059,
      },
      {
        lat: 13.06640278101801,
        lng: 80.28891810138609,
      },
      {
        lat: 13.066250753407859,
        lng: 80.2884019712973,
      },
      {
        lat: 13.066543569506367,
        lng: 80.28758640967347,
      },
      {
        lat: 13.067300401351723,
        lng: 80.28656365010272,
      },
      {
        lat: 13.067758685146389,
        lng: 80.28541406303695,
      },
      {
        lat: 13.06795845083497,
        lng: 80.28459928892292,
      },
      {
        lat: 13.068010909344318,
        lng: 80.28404446987659,
      },
      {
        lat: 13.067952381709137,
        lng: 80.28360146515027,
      },
      {
        lat: 13.067991814076178,
        lng: 80.28090502063606,
      },
      {
        lat: 13.068027235980674,
        lng: 80.28060905414394,
      },
      {
        lat: 13.068405773100611,
        lng: 80.27959701408582,
      },
      {
        lat: 13.06963775100447,
        lng: 80.27794226481146,
      },
      {
        lat: 13.070519210044612,
        lng: 80.27651657644398,
      },
      {
        lat: 13.070806879906604,
        lng: 80.27616472324021,
      },
      {
        lat: 13.071586840887582,
        lng: 80.27506875842217,
      },
      {
        lat: 13.0716261149985,
        lng: 80.27471900745589,
      },
      {
        lat: 13.071770387466769,
        lng: 80.27461501627023,
      },
      {
        lat: 13.071956559269594,
        lng: 80.274592886616,
      },
    ],
    properties: {
      Ward_No: 60,
      Zone_Name: "ROYAPURAM",
      fillColor: "#8bc24c",
      zname: "ROYAPURAM(60)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.186772108608709,
        lng: 80.27172988419804,
      },
      {
        lat: 13.192355960893549,
        lng: 80.27288552866443,
      },
      {
        lat: 13.189743380418188,
        lng: 80.29896300763464,
      },
      {
        lat: 13.197708804208881,
        lng: 80.28934037017409,
      },
      {
        lat: 13.19328221833785,
        lng: 80.29039117421202,
      },
      {
        lat: 13.191124334680358,
        lng: 80.27539940367473,
      },
      {
        lat: 13.183611726980832,
        lng: 80.289261612078,
      },
      {
        lat: 13.191573193526095,
        lng: 80.2772034094841,
      },
      {
        lat: 13.199924538187203,
        lng: 80.28640163943399,
      },
      {
        lat: 13.202186864347807,
        lng: 80.25467235659335,
      },
      {
        lat: 13.189117824939709,
        lng: 80.27578861814888,
      },
      {
        lat: 13.190270046006336,
        lng: 80.29580465087888,
      },
      {
        lat: 13.191390239491984,
        lng: 80.28138338301385,
      },
      {
        lat: 13.192085350053622,
        lng: 80.28426022690141,
      },
      {
        lat: 13.187331988266898,
        lng: 80.29235305965128,
      },
      {
        lat: 13.202000101921834,
        lng: 80.25401144468188,
      },
      {
        lat: 13.193015964153577,
        lng: 80.27274998033681,
      },
      {
        lat: 13.201606896045957,
        lng: 80.25708779775006,
      },
      {
        lat: 13.195739146633803,
        lng: 80.25843737613965,
      },
      {
        lat: 13.198967164998926,
        lng: 80.27754009536004,
      },
      {
        lat: 13.191854409946902,
        lng: 80.26987221146602,
      },
      {
        lat: 13.193118146757147,
        lng: 80.28803180819646,
      },
      {
        lat: 13.203755627438285,
        lng: 80.25817514658759,
      },
      {
        lat: 13.192650765170905,
        lng: 80.28469050194927,
      },
      {
        lat: 13.185951998573403,
        lng: 80.2778556994422,
      },
      {
        lat: 13.198364549604108,
        lng: 80.28923753480716,
      },
      {
        lat: 13.199706554448104,
        lng: 80.2760249864172,
      },
      {
        lat: 13.183128302769447,
        lng: 80.285565985491,
      },
      {
        lat: 13.196334705211957,
        lng: 80.28129435045693,
      },
      {
        lat: 13.185630218492681,
        lng: 80.28412653925274,
      },
      {
        lat: 13.20257581604396,
        lng: 80.26968430957706,
      },
      {
        lat: 13.196752395968922,
        lng: 80.25004453674335,
      },
      {
        lat: 13.185411211731886,
        lng: 80.28623611862861,
      },
      {
        lat: 13.202835020289495,
        lng: 80.27789803155457,
      },
      {
        lat: 13.194831441183993,
        lng: 80.25791098962046,
      },
      {
        lat: 13.202224899298646,
        lng: 80.27322336900333,
      },
      {
        lat: 13.196388132029336,
        lng: 80.25311308388562,
      },
      {
        lat: 13.192783357998438,
        lng: 80.29991430713284,
      },
      {
        lat: 13.197326287497761,
        lng: 80.2964048617035,
      },
      {
        lat: 13.186480254865417,
        lng: 80.28059608925804,
      },
      {
        lat: 13.198759002183806,
        lng: 80.28343397425672,
      },
      {
        lat: 13.197053435139525,
        lng: 80.26219838572844,
      },
      {
        lat: 13.197562596026984,
        lng: 80.27250495809614,
      },
      {
        lat: 13.188850694084197,
        lng: 80.29249560604114,
      },
      {
        lat: 13.190555312978915,
        lng: 80.27492111455618,
      },
      {
        lat: 13.19263006007131,
        lng: 80.3016699415411,
      },
      {
        lat: 13.19111916164861,
        lng: 80.27003825320841,
      },
      {
        lat: 13.18376096013823,
        lng: 80.27389874419455,
      },
      {
        lat: 13.186703704542213,
        lng: 80.28094148672365,
      },
      {
        lat: 13.182241233851888,
        lng: 80.2947193611565,
      },
      {
        lat: 13.183538163756841,
        lng: 80.29080171467164,
      },
      {
        lat: 13.186663997404214,
        lng: 80.29489897752059,
      },
      {
        lat: 13.189959730103856,
        lng: 80.27416380170645,
      },
      {
        lat: 13.19980086973362,
        lng: 80.25339824523893,
      },
      {
        lat: 13.200539446576885,
        lng: 80.28093798452528,
      },
      {
        lat: 13.200993713618672,
        lng: 80.25195694374224,
      },
      {
        lat: 13.192034586597686,
        lng: 80.25831451774859,
      },
      {
        lat: 13.198757041884006,
        lng: 80.26271415238067,
      },
      {
        lat: 13.18726344489379,
        lng: 80.27180582647208,
      },
      {
        lat: 13.200756684355934,
        lng: 80.26943322584442,
      },
      {
        lat: 13.192431776799355,
        lng: 80.26768919259143,
      },
      {
        lat: 13.188237798230322,
        lng: 80.28682281125776,
      },
      {
        lat: 13.205735580562889,
        lng: 80.25024859999917,
      },
      {
        lat: 13.188869442974033,
        lng: 80.28383593464466,
      },
      {
        lat: 13.185624696690068,
        lng: 80.29633658838698,
      },
      {
        lat: 13.19961456928276,
        lng: 80.2625253140211,
      },
      {
        lat: 13.197920982724586,
        lng: 80.26783744125507,
      },
      {
        lat: 13.20374168198387,
        lng: 80.25285111579369,
      },
      {
        lat: 13.194668506255775,
        lng: 80.2893519295895,
      },
      {
        lat: 13.190272774796153,
        lng: 80.27299269134815,
      },
      {
        lat: 13.197511080998352,
        lng: 80.28463305419463,
      },
      {
        lat: 13.196668593775573,
        lng: 80.28857550491172,
      },
      {
        lat: 13.19366130783429,
        lng: 80.29710055350213,
      },
      {
        lat: 13.194424624145528,
        lng: 80.26909484604121,
      },
      {
        lat: 13.194492496595617,
        lng: 80.28849429922143,
      },
      {
        lat: 13.199194931313224,
        lng: 80.2876743350796,
      },
      {
        lat: 13.201037871116585,
        lng: 80.28140562711684,
      },
      {
        lat: 13.205077509891206,
        lng: 80.25552323612776,
      },
      {
        lat: 13.18977597886542,
        lng: 80.2991483564576,
      },
      {
        lat: 13.19449429519158,
        lng: 80.28627300642425,
      },
      {
        lat: 13.192041636668016,
        lng: 80.28092494523086,
      },
      {
        lat: 13.19571139462691,
        lng: 80.269032701876,
      },
      {
        lat: 13.19583282138332,
        lng: 80.29552762243279,
      },
      {
        lat: 13.195110926324805,
        lng: 80.27361845291493,
      },
      {
        lat: 13.195835854046038,
        lng: 80.29429309053083,
      },
      {
        lat: 13.201970607216142,
        lng: 80.25434429651435,
      },
      {
        lat: 13.1970971087227,
        lng: 80.28951319395715,
      },
      {
        lat: 13.194334383637779,
        lng: 80.29652094580048,
      },
      {
        lat: 13.194774997256435,
        lng: 80.28540017911247,
      },
      {
        lat: 13.203482230272435,
        lng: 80.27098779869401,
      },
      {
        lat: 13.189546344267505,
        lng: 80.28884085500599,
      },
      {
        lat: 13.205129713392108,
        lng: 80.25797974752953,
      },
      {
        lat: 13.188603950149195,
        lng: 80.2847652929079,
      },
      {
        lat: 13.201361585263584,
        lng: 80.28352291056568,
      },
      {
        lat: 13.191050835717926,
        lng: 80.29317344443575,
      },
      {
        lat: 13.20115867851338,
        lng: 80.2589442666096,
      },
      {
        lat: 13.196781335840466,
        lng: 80.24995961351236,
      },
      {
        lat: 13.194287967990087,
        lng: 80.26355119328912,
      },
      {
        lat: 13.18970600355735,
        lng: 80.28279428939531,
      },
      {
        lat: 13.192056724569769,
        lng: 80.30006441246478,
      },
      {
        lat: 13.199492002124273,
        lng: 80.26818681072913,
      },
      {
        lat: 13.196632490887056,
        lng: 80.2733642110877,
      },
      {
        lat: 13.181785750597218,
        lng: 80.2893662791219,
      },
      {
        lat: 13.201694359111423,
        lng: 80.27337011290214,
      },
      {
        lat: 13.1969046173854,
        lng: 80.27693917829937,
      },
      {
        lat: 13.196817955724075,
        lng: 80.30351806730975,
      },
      {
        lat: 13.184661154466575,
        lng: 80.29337129134524,
      },
      {
        lat: 13.189892999144378,
        lng: 80.29391334089567,
      },
      {
        lat: 13.205488858862939,
        lng: 80.25270375333615,
      },
      {
        lat: 13.187465699539667,
        lng: 80.27429802398676,
      },
      {
        lat: 13.197342131399623,
        lng: 80.28195616710865,
      },
      {
        lat: 13.19540043282562,
        lng: 80.28440854762025,
      },
      {
        lat: 13.199128636548947,
        lng: 80.29494331817232,
      },
      {
        lat: 13.203448799828367,
        lng: 80.25207394327536,
      },
      {
        lat: 13.195739296451432,
        lng: 80.25985406314692,
      },
      {
        lat: 13.203975079321207,
        lng: 80.27594679207444,
      },
      {
        lat: 13.19348771653868,
        lng: 80.2564156022079,
      },
      {
        lat: 13.199385964955383,
        lng: 80.2666250581695,
      },
      {
        lat: 13.195132402562166,
        lng: 80.29447760591428,
      },
      {
        lat: 13.194136153129268,
        lng: 80.3004843279732,
      },
      {
        lat: 13.19511477996482,
        lng: 80.26514845978231,
      },
      {
        lat: 13.198478552048375,
        lng: 80.27791778549347,
      },
      {
        lat: 13.191677379896278,
        lng: 80.29408464504179,
      },
      {
        lat: 13.201273719634171,
        lng: 80.28368449000777,
      },
      {
        lat: 13.194733159471348,
        lng: 80.2737178860046,
      },
      {
        lat: 13.194127238490331,
        lng: 80.26570691857093,
      },
      {
        lat: 13.19296360854907,
        lng: 80.26820868209862,
      },
      {
        lat: 13.189959622209583,
        lng: 80.27224292572673,
      },
      {
        lat: 13.187983849874687,
        lng: 80.28787190930487,
      },
      {
        lat: 13.204302621710623,
        lng: 80.25652795012948,
      },
      {
        lat: 13.196082552518504,
        lng: 80.26743781899442,
      },
      {
        lat: 13.200476658462668,
        lng: 80.25149308753225,
      },
      {
        lat: 13.196160152746895,
        lng: 80.296519019923,
      },
      {
        lat: 13.197058880984063,
        lng: 80.25982159885525,
      },
    ],
    cases: 134,
    coords: [
      {
        lat: 13.203619440321107,
        lng: 80.27004366725002,
      },
      {
        lat: 13.203743394292063,
        lng: 80.27074572649231,
      },
      {
        lat: 13.204150668906506,
        lng: 80.27191337832956,
      },
      {
        lat: 13.204329329188834,
        lng: 80.27358486443559,
      },
      {
        lat: 13.204447860164015,
        lng: 80.2757587588781,
      },
      {
        lat: 13.204372139429527,
        lng: 80.27716053567654,
      },
      {
        lat: 13.203363062980575,
        lng: 80.28093332159952,
      },
      {
        lat: 13.2023067291366,
        lng: 80.28476696441139,
      },
      {
        lat: 13.201302746415204,
        lng: 80.28916634000461,
      },
      {
        lat: 13.198453223215951,
        lng: 80.30173582376406,
      },
      {
        lat: 13.19793310189945,
        lng: 80.30368805906156,
      },
      {
        lat: 13.19797190086369,
        lng: 80.30418127872886,
      },
      {
        lat: 13.197729797821129,
        lng: 80.30474403604488,
      },
      {
        lat: 13.197061574123813,
        lng: 80.30387206095753,
      },
      {
        lat: 13.195437627826832,
        lng: 80.30330071787769,
      },
      {
        lat: 13.193271773009478,
        lng: 80.30244141763093,
      },
      {
        lat: 13.191957980319287,
        lng: 80.3024037793526,
      },
      {
        lat: 13.18983322147414,
        lng: 80.30022768564334,
      },
      {
        lat: 13.187692659346569,
        lng: 80.29949006445416,
      },
      {
        lat: 13.187883732193601,
        lng: 80.29861081691736,
      },
      {
        lat: 13.185825303957653,
        lng: 80.29724840785212,
      },
      {
        lat: 13.183408894594146,
        lng: 80.29556235492232,
      },
      {
        lat: 13.180501606906258,
        lng: 80.29390483504629,
      },
      {
        lat: 13.181563627964344,
        lng: 80.29089727622419,
      },
      {
        lat: 13.182001338465065,
        lng: 80.28616411885187,
      },
      {
        lat: 13.182811946593297,
        lng: 80.28313363001905,
      },
      {
        lat: 13.18364407033633,
        lng: 80.28062881527084,
      },
      {
        lat: 13.183746626613548,
        lng: 80.28007580817716,
      },
      {
        lat: 13.183745027500473,
        lng: 80.27981289831412,
      },
      {
        lat: 13.182890995908302,
        lng: 80.27871584793854,
      },
      {
        lat: 13.182730365261724,
        lng: 80.27842799166098,
      },
      {
        lat: 13.182796048700252,
        lng: 80.27616629455287,
      },
      {
        lat: 13.182837492293622,
        lng: 80.27427289534822,
      },
      {
        lat: 13.182833494510932,
        lng: 80.27361562069048,
      },
      {
        lat: 13.183017725357448,
        lng: 80.27343001511933,
      },
      {
        lat: 13.18351921971398,
        lng: 80.27316284744583,
      },
      {
        lat: 13.183726730938162,
        lng: 80.27245119805336,
      },
      {
        lat: 13.183830886328293,
        lng: 80.27216110082271,
      },
      {
        lat: 13.184095192631133,
        lng: 80.27207998691094,
      },
      {
        lat: 13.184360298490404,
        lng: 80.27213032793065,
      },
      {
        lat: 13.186060366109531,
        lng: 80.27300987936655,
      },
      {
        lat: 13.186272035027708,
        lng: 80.27298179561791,
      },
      {
        lat: 13.186429307536658,
        lng: 80.27271754118296,
      },
      {
        lat: 13.186605862640358,
        lng: 80.27126996826925,
      },
      {
        lat: 13.186786575438106,
        lng: 80.27050596099946,
      },
      {
        lat: 13.187099361431507,
        lng: 80.26968825128041,
      },
      {
        lat: 13.187227916578454,
        lng: 80.2690561471326,
      },
      {
        lat: 13.187438945851396,
        lng: 80.26892289943875,
      },
      {
        lat: 13.188287220636493,
        lng: 80.2690734743073,
      },
      {
        lat: 13.189718664347819,
        lng: 80.26932428302486,
      },
      {
        lat: 13.191863431244977,
        lng: 80.26930613130641,
      },
      {
        lat: 13.192153416594811,
        lng: 80.26909333836763,
      },
      {
        lat: 13.192256612517212,
        lng: 80.26864549521942,
      },
      {
        lat: 13.192278773515609,
        lng: 80.26793541449375,
      },
      {
        lat: 13.192455328619305,
        lng: 80.26648784157999,
      },
      {
        lat: 13.192496932123808,
        lng: 80.26462073336167,
      },
      {
        lat: 13.19271844886946,
        lng: 80.26185816294202,
      },
      {
        lat: 13.192715090732,
        lng: 80.26130605222951,
      },
      {
        lat: 13.192313274249157,
        lng: 80.26054697505612,
      },
      {
        lat: 13.191876983597234,
        lng: 80.2584735726626,
      },
      {
        lat: 13.191739343052241,
        lng: 80.25491269152192,
      },
      {
        lat: 13.194259519707336,
        lng: 80.25433451563676,
      },
      {
        lat: 13.194515420392046,
        lng: 80.25414673422385,
      },
      {
        lat: 13.194839303328916,
        lng: 80.25361034809939,
      },
      {
        lat: 13.196566941611104,
        lng: 80.24951218215321,
      },
      {
        lat: 13.1967290947528,
        lng: 80.24927879023544,
      },
      {
        lat: 13.196916025376922,
        lng: 80.24927720819308,
      },
      {
        lat: 13.19866367741348,
        lng: 80.24951799136112,
      },
      {
        lat: 13.204157789673463,
        lng: 80.25024671560753,
      },
      {
        lat: 13.204889709316078,
        lng: 80.2502405211818,
      },
      {
        lat: 13.20544966897358,
        lng: 80.2500419765122,
      },
      {
        lat: 13.205742584172505,
        lng: 80.25006372319298,
      },
      {
        lat: 13.2060130174946,
        lng: 80.25040059422088,
      },
      {
        lat: 13.206162200919163,
        lng: 80.25085961990928,
      },
      {
        lat: 13.205905912418725,
        lng: 80.25284829620007,
      },
      {
        lat: 13.205891239662186,
        lng: 80.2544473164625,
      },
      {
        lat: 13.20599398591415,
        lng: 80.25529434633229,
      },
      {
        lat: 13.205948874818588,
        lng: 80.25590037057493,
      },
      {
        lat: 13.205683156437052,
        lng: 80.25633868198642,
      },
      {
        lat: 13.20539525086246,
        lng: 80.25714056664759,
      },
      {
        lat: 13.205211122932912,
        lng: 80.25895905233709,
      },
      {
        lat: 13.204955865825726,
        lng: 80.26111729978649,
      },
      {
        lat: 13.204358604193017,
        lng: 80.26320576461417,
      },
      {
        lat: 13.203977087563784,
        lng: 80.26466253538212,
      },
      {
        lat: 13.203762815979175,
        lng: 80.26553647395431,
      },
      {
        lat: 13.203669499608313,
        lng: 80.2662398089636,
      },
      {
        lat: 13.203723303875522,
        lng: 80.26706302734382,
      },
      {
        lat: 13.203755362975357,
        lng: 80.26832249232724,
      },
      {
        lat: 13.203619440321107,
        lng: 80.27004366725002,
      },
    ],
    properties: {
      Ward_No: 16,
      Zone_Name: "MANALI",
      fillColor: "#de4307",
      zname: "MANALI(16)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.85778055029097,
        lng: 80.22376671040651,
      },
      {
        lat: 12.866905905528277,
        lng: 80.24003001474203,
      },
      {
        lat: 12.861321118845728,
        lng: 80.21890644273793,
      },
      {
        lat: 12.868469938236919,
        lng: 80.22782675342079,
      },
      {
        lat: 12.860407567946403,
        lng: 80.22395605618695,
      },
      {
        lat: 12.868803228556498,
        lng: 80.22659945658877,
      },
      {
        lat: 12.867212902152954,
        lng: 80.22228337105685,
      },
      {
        lat: 12.87660116993187,
        lng: 80.21435171228701,
      },
      {
        lat: 12.867456591327286,
        lng: 80.23012272978943,
      },
      {
        lat: 12.866368552182678,
        lng: 80.23289837995986,
      },
      {
        lat: 12.874779342620652,
        lng: 80.21046360713255,
      },
      {
        lat: 12.875783414458684,
        lng: 80.21081243951237,
      },
      {
        lat: 12.86866930354259,
        lng: 80.21550391516095,
      },
      {
        lat: 12.865977569185429,
        lng: 80.22572499333252,
      },
      {
        lat: 12.868139516940936,
        lng: 80.2391561781799,
      },
      {
        lat: 12.867751074140727,
        lng: 80.22052484810952,
      },
      {
        lat: 12.852943407485146,
        lng: 80.22346317596997,
      },
      {
        lat: 12.872477019392008,
        lng: 80.2143164227805,
      },
      {
        lat: 12.875229741046134,
        lng: 80.2242095240305,
      },
      {
        lat: 12.864603590069649,
        lng: 80.2243507342543,
      },
      {
        lat: 12.86902961717682,
        lng: 80.22049676520395,
      },
      {
        lat: 12.87591795776115,
        lng: 80.21673149229831,
      },
      {
        lat: 12.867297982085658,
        lng: 80.22133603371552,
      },
      {
        lat: 12.859776843368904,
        lng: 80.22015450405443,
      },
      {
        lat: 12.866246226738793,
        lng: 80.23018165347908,
      },
      {
        lat: 12.87279665746494,
        lng: 80.2245872984374,
      },
      {
        lat: 12.869114017451306,
        lng: 80.22054575958876,
      },
      {
        lat: 12.86682902868157,
        lng: 80.21850717935396,
      },
      {
        lat: 12.87421024043976,
        lng: 80.21829036694764,
      },
      {
        lat: 12.875751199263517,
        lng: 80.2294520708894,
      },
      {
        lat: 12.872782693408087,
        lng: 80.20887345557699,
      },
      {
        lat: 12.86186412422404,
        lng: 80.2193126073339,
      },
      {
        lat: 12.873549658876025,
        lng: 80.2213429564086,
      },
      {
        lat: 12.854144765259495,
        lng: 80.22320658797229,
      },
      {
        lat: 12.85670732205411,
        lng: 80.22606129159607,
      },
      {
        lat: 12.866981706022614,
        lng: 80.2325088625062,
      },
      {
        lat: 12.873576040041973,
        lng: 80.23288491764765,
      },
      {
        lat: 12.85944390333116,
        lng: 80.22159807929984,
      },
      {
        lat: 12.85755028837176,
        lng: 80.21932322214019,
      },
      {
        lat: 12.855513557650136,
        lng: 80.22456802478632,
      },
      {
        lat: 12.859663839877651,
        lng: 80.23506624986615,
      },
      {
        lat: 12.85553504275338,
        lng: 80.21973170095936,
      },
      {
        lat: 12.86801456628337,
        lng: 80.22577064457788,
      },
      {
        lat: 12.860950937171832,
        lng: 80.22064563715892,
      },
      {
        lat: 12.854169478836608,
        lng: 80.22281708079248,
      },
      {
        lat: 12.873597913671652,
        lng: 80.22896222918914,
      },
      {
        lat: 12.856813112073002,
        lng: 80.22227010811503,
      },
      {
        lat: 12.868377813192865,
        lng: 80.21116900151446,
      },
      {
        lat: 12.856415411655172,
        lng: 80.23204719134613,
      },
      {
        lat: 12.85996197685712,
        lng: 80.21812688168471,
      },
      {
        lat: 12.871345610494782,
        lng: 80.23273973559367,
      },
      {
        lat: 12.875975582015682,
        lng: 80.23087135009814,
      },
      {
        lat: 12.859717022706526,
        lng: 80.23434796559711,
      },
      {
        lat: 12.857347853928427,
        lng: 80.22118519676837,
      },
      {
        lat: 12.873940949019362,
        lng: 80.22923841438116,
      },
      {
        lat: 12.859236269648763,
        lng: 80.217300644454,
      },
      {
        lat: 12.856191585419564,
        lng: 80.2257705838547,
      },
      {
        lat: 12.875543025697207,
        lng: 80.23222883019744,
      },
      {
        lat: 12.853414527879123,
        lng: 80.23163425576247,
      },
      {
        lat: 12.860747105777932,
        lng: 80.22439670568988,
      },
      {
        lat: 12.8724827917106,
        lng: 80.2292474706222,
      },
      {
        lat: 12.859162981902742,
        lng: 80.23003140341946,
      },
      {
        lat: 12.856893935281295,
        lng: 80.23448938855972,
      },
      {
        lat: 12.86119947015383,
        lng: 80.23127912319546,
      },
      {
        lat: 12.852796958409577,
        lng: 80.22203207071712,
      },
      {
        lat: 12.855791377904168,
        lng: 80.23333779179421,
      },
      {
        lat: 12.864868874493752,
        lng: 80.21916373614236,
      },
      {
        lat: 12.859769062170182,
        lng: 80.23534308009332,
      },
      {
        lat: 12.863183315802964,
        lng: 80.2341827523418,
      },
      {
        lat: 12.862152354424772,
        lng: 80.2297495839516,
      },
      {
        lat: 12.864807135794996,
        lng: 80.23019037969797,
      },
      {
        lat: 12.86214574701542,
        lng: 80.23081863104845,
      },
      {
        lat: 12.867677859590858,
        lng: 80.21807940688127,
      },
      {
        lat: 12.855583952152191,
        lng: 80.21931575848303,
      },
      {
        lat: 12.872281274005719,
        lng: 80.22083300375216,
      },
      {
        lat: 12.859712612932404,
        lng: 80.23524632713149,
      },
      {
        lat: 12.87071114576639,
        lng: 80.23810776125622,
      },
      {
        lat: 12.873771276884824,
        lng: 80.21937392517106,
      },
      {
        lat: 12.860886243535829,
        lng: 80.22971439521253,
      },
      {
        lat: 12.86640135297038,
        lng: 80.21262358741971,
      },
      {
        lat: 12.864787137985585,
        lng: 80.2124516494542,
      },
      {
        lat: 12.85980978012145,
        lng: 80.22423386672502,
      },
      {
        lat: 12.874084781114952,
        lng: 80.23687873669819,
      },
      {
        lat: 12.868107266676983,
        lng: 80.2293040423464,
      },
      {
        lat: 12.875729988983732,
        lng: 80.22811943064117,
      },
      {
        lat: 12.865785813019821,
        lng: 80.2223394579353,
      },
      {
        lat: 12.871227217168558,
        lng: 80.21618063490648,
      },
      {
        lat: 12.876326031471631,
        lng: 80.21520216555982,
      },
      {
        lat: 12.875264771287803,
        lng: 80.21319786337457,
      },
      {
        lat: 12.868600686543168,
        lng: 80.21573814240728,
      },
      {
        lat: 12.872141984618535,
        lng: 80.22496566171549,
      },
      {
        lat: 12.868349371358375,
        lng: 80.22091136969722,
      },
      {
        lat: 12.864285874548617,
        lng: 80.22595197126454,
      },
      {
        lat: 12.866614274028148,
        lng: 80.224512675635,
      },
      {
        lat: 12.877904251282082,
        lng: 80.21020206376427,
      },
      {
        lat: 12.860848317072575,
        lng: 80.23229369922377,
      },
      {
        lat: 12.87630619587958,
        lng: 80.2222620392879,
      },
      {
        lat: 12.873557832609842,
        lng: 80.22148310145232,
      },
      {
        lat: 12.864138628786653,
        lng: 80.21404185018072,
      },
      {
        lat: 12.865531996827126,
        lng: 80.21479819176389,
      },
      {
        lat: 12.854714470014734,
        lng: 80.2273964502002,
      },
      {
        lat: 12.870051163125403,
        lng: 80.23734237448055,
      },
      {
        lat: 12.85831902769003,
        lng: 80.22550696786968,
      },
      {
        lat: 12.8662038862493,
        lng: 80.2162044258553,
      },
      {
        lat: 12.865119292748147,
        lng: 80.21943923047402,
      },
      {
        lat: 12.87612856040442,
        lng: 80.22143349485061,
      },
      {
        lat: 12.863317531034031,
        lng: 80.21660576172344,
      },
      {
        lat: 12.87213284773964,
        lng: 80.21298714953886,
      },
      {
        lat: 12.860393438039111,
        lng: 80.21690528913477,
      },
      {
        lat: 12.874692874807891,
        lng: 80.23143958790709,
      },
      {
        lat: 12.870378632179394,
        lng: 80.23032668809532,
      },
      {
        lat: 12.871171142667782,
        lng: 80.21657251990312,
      },
      {
        lat: 12.857685714849014,
        lng: 80.22691859737206,
      },
      {
        lat: 12.852862695146946,
        lng: 80.22986799784671,
      },
      {
        lat: 12.877686128683935,
        lng: 80.21352505444541,
      },
      {
        lat: 12.871620786934683,
        lng: 80.21275259484257,
      },
      {
        lat: 12.865420610922678,
        lng: 80.22631015619808,
      },
      {
        lat: 12.871752583211999,
        lng: 80.22720533060328,
      },
      {
        lat: 12.859112091607122,
        lng: 80.23546793804697,
      },
      {
        lat: 12.872629777737583,
        lng: 80.22095877437386,
      },
      {
        lat: 12.877062361825836,
        lng: 80.2167469678909,
      },
      {
        lat: 12.86897508788837,
        lng: 80.21707589785751,
      },
      {
        lat: 12.873755310263073,
        lng: 80.23236180214468,
      },
      {
        lat: 12.873047345613559,
        lng: 80.21452857456428,
      },
      {
        lat: 12.858115624133406,
        lng: 80.22518349036447,
      },
      {
        lat: 12.86505455111005,
        lng: 80.23040491950138,
      },
      {
        lat: 12.869155570580745,
        lng: 80.22589796720806,
      },
      {
        lat: 12.8627653438248,
        lng: 80.22095351845459,
      },
      {
        lat: 12.867935509977329,
        lng: 80.22333537236325,
      },
      {
        lat: 12.87645661157726,
        lng: 80.2105448205554,
      },
      {
        lat: 12.875376909093454,
        lng: 80.22900699010547,
      },
      {
        lat: 12.876873406024314,
        lng: 80.22122356363417,
      },
      {
        lat: 12.877340015337662,
        lng: 80.21577683251333,
      },
      {
        lat: 12.867910436079013,
        lng: 80.21432612668625,
      },
      {
        lat: 12.868171079294921,
        lng: 80.23279532756715,
      },
      {
        lat: 12.873269417158907,
        lng: 80.21014016264571,
      },
      {
        lat: 12.876275125488617,
        lng: 80.21883382443362,
      },
      {
        lat: 12.872529333170704,
        lng: 80.22969946599909,
      },
      {
        lat: 12.868019867252606,
        lng: 80.2261748832596,
      },
      {
        lat: 12.875020575824395,
        lng: 80.22680919988397,
      },
      {
        lat: 12.874870815341188,
        lng: 80.23150997125049,
      },
      {
        lat: 12.873290237256247,
        lng: 80.23990125439596,
      },
      {
        lat: 12.87293785911551,
        lng: 80.2091510434583,
      },
      {
        lat: 12.870728553073434,
        lng: 80.21829071105766,
      },
      {
        lat: 12.86471397566568,
        lng: 80.22430692217253,
      },
      {
        lat: 12.869336995020571,
        lng: 80.23555806023339,
      },
      {
        lat: 12.858903480967944,
        lng: 80.21857644742967,
      },
      {
        lat: 12.867645914499931,
        lng: 80.21634060851548,
      },
      {
        lat: 12.865640277609772,
        lng: 80.2296136194262,
      },
      {
        lat: 12.869156671977413,
        lng: 80.21303917568176,
      },
    ],
    cases: 150,
    coords: [
      {
        lat: 12.874255535073766,
        lng: 80.2404889529225,
      },
      {
        lat: 12.872558836094337,
        lng: 80.24063851691032,
      },
      {
        lat: 12.872211659729436,
        lng: 80.24245213536551,
      },
      {
        lat: 12.872085145613585,
        lng: 80.2430675440161,
      },
      {
        lat: 12.87146724212063,
        lng: 80.24320210779447,
      },
      {
        lat: 12.86987265616048,
        lng: 80.24337727106497,
      },
      {
        lat: 12.867755101458192,
        lng: 80.24323352460358,
      },
      {
        lat: 12.867428098503373,
        lng: 80.24300995708792,
      },
      {
        lat: 12.867033807075538,
        lng: 80.24243128972483,
      },
      {
        lat: 12.866865094256612,
        lng: 80.2414627103289,
      },
      {
        lat: 12.86678541655621,
        lng: 80.23907070009672,
      },
      {
        lat: 12.867130233068318,
        lng: 80.23686909871137,
      },
      {
        lat: 12.867290293093637,
        lng: 80.23641507402888,
      },
      {
        lat: 12.867257140492905,
        lng: 80.23631835388233,
      },
      {
        lat: 12.86663727045617,
        lng: 80.23612959855193,
      },
      {
        lat: 12.865919515852722,
        lng: 80.23590933806886,
      },
      {
        lat: 12.865161135781563,
        lng: 80.23436374482624,
      },
      {
        lat: 12.863597932532429,
        lng: 80.23434464104544,
      },
      {
        lat: 12.861907821858532,
        lng: 80.23487628209364,
      },
      {
        lat: 12.858986915791178,
        lng: 80.23600777409439,
      },
      {
        lat: 12.8566397704621,
        lng: 80.23469282650849,
      },
      {
        lat: 12.854264603191194,
        lng: 80.23319124238772,
      },
      {
        lat: 12.852889227905823,
        lng: 80.23250878026113,
      },
      {
        lat: 12.851972527539083,
        lng: 80.23208939866667,
      },
      {
        lat: 12.85223407465624,
        lng: 80.2308858542487,
      },
      {
        lat: 12.852437791842702,
        lng: 80.22901539321776,
      },
      {
        lat: 12.852157897157399,
        lng: 80.22720241760301,
      },
      {
        lat: 12.852278197989111,
        lng: 80.22487882643726,
      },
      {
        lat: 12.852366255314239,
        lng: 80.22167453218042,
      },
      {
        lat: 12.852468763379052,
        lng: 80.22084608113506,
      },
      {
        lat: 12.852489478767257,
        lng: 80.21983144863067,
      },
      {
        lat: 12.853081119211177,
        lng: 80.2198531376658,
      },
      {
        lat: 12.853512096923518,
        lng: 80.21998297139808,
      },
      {
        lat: 12.853861444327745,
        lng: 80.21995331853876,
      },
      {
        lat: 12.854236215788283,
        lng: 80.21968318433363,
      },
      {
        lat: 12.854576145856814,
        lng: 80.21810522915648,
      },
      {
        lat: 12.854975042430613,
        lng: 80.21738105466537,
      },
      {
        lat: 12.855377511097489,
        lng: 80.21724416725998,
      },
      {
        lat: 12.855966878391026,
        lng: 80.21689212814952,
      },
      {
        lat: 12.859471650485588,
        lng: 80.21403266473362,
      },
      {
        lat: 12.860595315395752,
        lng: 80.21311548264794,
      },
      {
        lat: 12.863519163325252,
        lng: 80.21199619145462,
      },
      {
        lat: 12.865424452295208,
        lng: 80.21139274918237,
      },
      {
        lat: 12.87223872242612,
        lng: 80.2089324164934,
      },
      {
        lat: 12.873794826359656,
        lng: 80.20838532194806,
      },
      {
        lat: 12.875029767198884,
        lng: 80.20808121166469,
      },
      {
        lat: 12.875786129570056,
        lng: 80.20866212769157,
      },
      {
        lat: 12.878159023691161,
        lng: 80.20978998366657,
      },
      {
        lat: 12.87921080036462,
        lng: 80.21031500704191,
      },
      {
        lat: 12.879419431754016,
        lng: 80.21049184843952,
      },
      {
        lat: 12.879005186767474,
        lng: 80.21367347573005,
      },
      {
        lat: 12.876807871674826,
        lng: 80.22383961832463,
      },
      {
        lat: 12.876432277540912,
        lng: 80.22694046905693,
      },
      {
        lat: 12.87594477932918,
        lng: 80.22933015836098,
      },
      {
        lat: 12.875805030081791,
        lng: 80.22993663332147,
      },
      {
        lat: 12.875952358717523,
        lng: 80.2305762840924,
      },
      {
        lat: 12.876139226436742,
        lng: 80.2318208924602,
      },
      {
        lat: 12.875861243819463,
        lng: 80.23328306752727,
      },
      {
        lat: 12.874741733961832,
        lng: 80.23788564206453,
      },
      {
        lat: 12.874255535073766,
        lng: 80.2404889529225,
      },
    ],
    properties: {
      Ward_No: 200,
      Zone_Name: "SOZHINGANALLUR",
      fillColor: "#de4307",
      zname: "SOZHINGANALLUR(200)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.092692442492252,
        lng: 80.16633484569174,
      },
      {
        lat: 13.087616652059152,
        lng: 80.15591638054555,
      },
      {
        lat: 13.090589860510606,
        lng: 80.17374436190546,
      },
      {
        lat: 13.09862258317902,
        lng: 80.180608808343,
      },
      {
        lat: 13.091079535063207,
        lng: 80.151063520096,
      },
      {
        lat: 13.09355112501547,
        lng: 80.17185176260503,
      },
      {
        lat: 13.08842374231229,
        lng: 80.17726120904025,
      },
      {
        lat: 13.09107833388974,
        lng: 80.16407891537996,
      },
      {
        lat: 13.095057606712968,
        lng: 80.15533460336651,
      },
      {
        lat: 13.087401833683927,
        lng: 80.15032205310392,
      },
      {
        lat: 13.093822333465761,
        lng: 80.16858301901081,
      },
      {
        lat: 13.09311931327606,
        lng: 80.16594755554057,
      },
      {
        lat: 13.09897261603731,
        lng: 80.1746629405205,
      },
      {
        lat: 13.099928647656316,
        lng: 80.16071810562246,
      },
      {
        lat: 13.09729946447588,
        lng: 80.1659036148709,
      },
      {
        lat: 13.093513032090035,
        lng: 80.15893418529873,
      },
      {
        lat: 13.094549589278344,
        lng: 80.169681245589,
      },
      {
        lat: 13.085575655694116,
        lng: 80.15725575139776,
      },
      {
        lat: 13.088020993588891,
        lng: 80.15312724510392,
      },
      {
        lat: 13.097164224619101,
        lng: 80.16062147489939,
      },
      {
        lat: 13.095580737558395,
        lng: 80.15201137449336,
      },
      {
        lat: 13.08636895005221,
        lng: 80.15316608004944,
      },
      {
        lat: 13.089046304654131,
        lng: 80.14622010735206,
      },
      {
        lat: 13.097219704936217,
        lng: 80.16304170506754,
      },
      {
        lat: 13.097348719768988,
        lng: 80.14816134297321,
      },
      {
        lat: 13.088875830409187,
        lng: 80.14780238268584,
      },
      {
        lat: 13.09429654469989,
        lng: 80.14605124090811,
      },
      {
        lat: 13.0921997194579,
        lng: 80.16128318312181,
      },
      {
        lat: 13.094463313732087,
        lng: 80.16777507860186,
      },
      {
        lat: 13.097444711880703,
        lng: 80.16710868860554,
      },
      {
        lat: 13.098522047208926,
        lng: 80.15324381114661,
      },
      {
        lat: 13.102404643520863,
        lng: 80.15874944788004,
      },
      {
        lat: 13.089908959361876,
        lng: 80.15650855968906,
      },
      {
        lat: 13.100201372539134,
        lng: 80.15491593882759,
      },
      {
        lat: 13.09535254644719,
        lng: 80.1718577152932,
      },
      {
        lat: 13.099623700782201,
        lng: 80.1503114206518,
      },
      {
        lat: 13.094141689481413,
        lng: 80.15060224017549,
      },
      {
        lat: 13.087040429330312,
        lng: 80.1590453279367,
      },
    ],
    cases: 38,
    coords: [
      {
        lat: 13.09260019183814,
        lng: 80.17743191318766,
      },
      {
        lat: 13.088147317092137,
        lng: 80.17738334698676,
      },
      {
        lat: 13.088223492988702,
        lng: 80.17631560340314,
      },
      {
        lat: 13.088378877977226,
        lng: 80.17386233981338,
      },
      {
        lat: 13.088664943223387,
        lng: 80.17189413245032,
      },
      {
        lat: 13.088607327327098,
        lng: 80.1694215340549,
      },
      {
        lat: 13.088771068666057,
        lng: 80.16834213641741,
      },
      {
        lat: 13.08889886701911,
        lng: 80.16485336942274,
      },
      {
        lat: 13.089124413107786,
        lng: 80.16343524891887,
      },
      {
        lat: 13.0891134855723,
        lng: 80.1616386549812,
      },
      {
        lat: 13.085343974142937,
        lng: 80.16139576999896,
      },
      {
        lat: 13.079748251222318,
        lng: 80.16108089943424,
      },
      {
        lat: 13.07974168815826,
        lng: 80.16103122258716,
      },
      {
        lat: 13.079902784438092,
        lng: 80.16072941524577,
      },
      {
        lat: 13.080203670076083,
        lng: 80.16044953590315,
      },
      {
        lat: 13.080548715746954,
        lng: 80.15977639459103,
      },
      {
        lat: 13.080635770748874,
        lng: 80.15878188170673,
      },
      {
        lat: 13.08122854601328,
        lng: 80.15674309052021,
      },
      {
        lat: 13.081920042981576,
        lng: 80.15562790672443,
      },
      {
        lat: 13.082031637846837,
        lng: 80.1548411858049,
      },
      {
        lat: 13.082306577994643,
        lng: 80.15412241566924,
      },
      {
        lat: 13.082724399879458,
        lng: 80.15393399095458,
      },
      {
        lat: 13.08309482879018,
        lng: 80.15360730090455,
      },
      {
        lat: 13.083300928478831,
        lng: 80.15305089089061,
      },
      {
        lat: 13.083761235549472,
        lng: 80.15250224600304,
      },
      {
        lat: 13.08422649895127,
        lng: 80.15143826239918,
      },
      {
        lat: 13.084473568227763,
        lng: 80.15123895353481,
      },
      {
        lat: 13.084971455182698,
        lng: 80.15145660987284,
      },
      {
        lat: 13.085491320228261,
        lng: 80.1512056878041,
      },
      {
        lat: 13.085934155731335,
        lng: 80.15053632971905,
      },
      {
        lat: 13.086353813654501,
        lng: 80.15013834233953,
      },
      {
        lat: 13.086548980136504,
        lng: 80.14956968926805,
      },
      {
        lat: 13.086389523351064,
        lng: 80.14784538257966,
      },
      {
        lat: 13.08628766693152,
        lng: 80.14742715667944,
      },
      {
        lat: 13.086732001795262,
        lng: 80.14700430822097,
      },
      {
        lat: 13.087074330303082,
        lng: 80.14613858289982,
      },
      {
        lat: 13.087421156893871,
        lng: 80.14601238645845,
      },
      {
        lat: 13.087744206160682,
        lng: 80.14605895687211,
      },
      {
        lat: 13.087894516844662,
        lng: 80.14627955483806,
      },
      {
        lat: 13.088118258605217,
        lng: 80.14632696571675,
      },
      {
        lat: 13.088464635387625,
        lng: 80.14612681638735,
      },
      {
        lat: 13.089182665637315,
        lng: 80.14580026050028,
      },
      {
        lat: 13.089578396300697,
        lng: 80.14555038901281,
      },
      {
        lat: 13.089750835011449,
        lng: 80.14532705953482,
      },
      {
        lat: 13.089972327730834,
        lng: 80.14500470597369,
      },
      {
        lat: 13.089995205437996,
        lng: 80.14468403334284,
      },
      {
        lat: 13.090167794085014,
        lng: 80.14448535482752,
      },
      {
        lat: 13.090392285526017,
        lng: 80.1446560205194,
      },
      {
        lat: 13.090640704227372,
        lng: 80.1446785703192,
      },
      {
        lat: 13.09108668838775,
        lng: 80.14452688244991,
      },
      {
        lat: 13.09160850260287,
        lng: 80.14459642289576,
      },
      {
        lat: 13.09213136637056,
        lng: 80.14483852008011,
      },
      {
        lat: 13.092628803517234,
        lng: 80.14498222353019,
      },
      {
        lat: 13.093174395055874,
        lng: 80.1448789971211,
      },
      {
        lat: 13.094707963828203,
        lng: 80.14392923332981,
      },
      {
        lat: 13.095549828587947,
        lng: 80.14355232493601,
      },
      {
        lat: 13.096214906490681,
        lng: 80.14268386810286,
      },
      {
        lat: 13.096780676887164,
        lng: 80.14181625173508,
      },
      {
        lat: 13.097274815439865,
        lng: 80.14141763400667,
      },
      {
        lat: 13.097965619835632,
        lng: 80.14069687283323,
      },
      {
        lat: 13.097611487506692,
        lng: 80.14291344706989,
      },
      {
        lat: 13.097424821999084,
        lng: 80.14479464192209,
      },
      {
        lat: 13.097630363453634,
        lng: 80.1460168377554,
      },
      {
        lat: 13.097811767216536,
        lng: 80.14688954204807,
      },
      {
        lat: 13.098211551143473,
        lng: 80.1474762702759,
      },
      {
        lat: 13.098832373818231,
        lng: 80.14821411971842,
      },
      {
        lat: 13.099848593756036,
        lng: 80.14881748687739,
      },
      {
        lat: 13.100688328311525,
        lng: 80.14935677971101,
      },
      {
        lat: 13.102034444756157,
        lng: 80.1499136428924,
      },
      {
        lat: 13.102805754112905,
        lng: 80.15006010702429,
      },
      {
        lat: 13.103203012384965,
        lng: 80.15023159283639,
      },
      {
        lat: 13.103380029484148,
        lng: 80.15038308661782,
      },
      {
        lat: 13.10327103879588,
        lng: 80.15055885694613,
      },
      {
        lat: 13.103095749776406,
        lng: 80.15069147639674,
      },
      {
        lat: 13.10124856861056,
        lng: 80.15099123742694,
      },
      {
        lat: 13.100875713888543,
        lng: 80.15121295288297,
      },
      {
        lat: 13.10076752077573,
        lng: 80.15151985239531,
      },
      {
        lat: 13.100815452794675,
        lng: 80.15578136458832,
      },
      {
        lat: 13.101190301454752,
        lng: 80.15588747209196,
      },
      {
        lat: 13.104098787640842,
        lng: 80.15636554456592,
      },
      {
        lat: 13.103689621164001,
        lng: 80.15785521469977,
      },
      {
        lat: 13.103516459011793,
        lng: 80.15833751197388,
      },
      {
        lat: 13.103079166967136,
        lng: 80.15882204464779,
      },
      {
        lat: 13.102027432849562,
        lng: 80.15961776135018,
      },
      {
        lat: 13.101766759116705,
        lng: 80.16018822321352,
      },
      {
        lat: 13.101689390509355,
        lng: 80.1604254225738,
      },
      {
        lat: 13.10173170137189,
        lng: 80.16339014311733,
      },
      {
        lat: 13.101230825675593,
        lng: 80.1648648683088,
      },
      {
        lat: 13.10080336776656,
        lng: 80.16643539746767,
      },
      {
        lat: 13.100467007037643,
        lng: 80.16701679610398,
      },
      {
        lat: 13.10042285129863,
        lng: 80.16774035971551,
      },
      {
        lat: 13.100427982854809,
        lng: 80.16858403784757,
      },
      {
        lat: 13.100237724620733,
        lng: 80.16923651897137,
      },
      {
        lat: 13.100047173154934,
        lng: 80.16984078991622,
      },
      {
        lat: 13.100048346082058,
        lng: 80.17003363063218,
      },
      {
        lat: 13.10038895867198,
        lng: 80.17015127959115,
      },
      {
        lat: 13.101554406466146,
        lng: 80.17016552243467,
      },
      {
        lat: 13.103429461793164,
        lng: 80.1710898002382,
      },
      {
        lat: 13.103576443983348,
        lng: 80.1713055132629,
      },
      {
        lat: 13.103604972952143,
        lng: 80.17200435539456,
      },
      {
        lat: 13.10353434086681,
        lng: 80.1723665481272,
      },
      {
        lat: 13.10346341554975,
        lng: 80.17268053068096,
      },
      {
        lat: 13.103221964013901,
        lng: 80.17289953112103,
      },
      {
        lat: 13.102711851516647,
        lng: 80.17285563567461,
      },
      {
        lat: 13.101545084179287,
        lng: 80.17262444702585,
      },
      {
        lat: 13.101327030055876,
        lng: 80.1726986114654,
      },
      {
        lat: 13.10123241409454,
        lng: 80.17310921984057,
      },
      {
        lat: 13.101140583834963,
        lng: 80.17397782491601,
      },
      {
        lat: 13.100999173048633,
        lng: 80.17467810529182,
      },
      {
        lat: 13.099861001898976,
        lng: 80.1771526102064,
      },
      {
        lat: 13.099265070393676,
        lng: 80.17896562850444,
      },
      {
        lat: 13.099076278318506,
        lng: 80.17985916052311,
      },
      {
        lat: 13.098739624357812,
        lng: 80.18039234898023,
      },
      {
        lat: 13.098597187260019,
        lng: 80.18092389372987,
      },
      {
        lat: 13.098574376310976,
        lng: 80.18116515008819,
      },
      {
        lat: 13.098295750332902,
        lng: 80.18100037506156,
      },
      {
        lat: 13.098049538894317,
        lng: 80.18082858069452,
      },
      {
        lat: 13.097815868032956,
        lng: 80.17983945303659,
      },
      {
        lat: 13.097789050729203,
        lng: 80.17830955255671,
      },
      {
        lat: 13.096895884328271,
        lng: 80.17829972384374,
      },
      {
        lat: 13.096851767538622,
        lng: 80.17680474539725,
      },
      {
        lat: 13.094732831621291,
        lng: 80.17680529068979,
      },
      {
        lat: 13.094295902822395,
        lng: 80.1769480910261,
      },
      {
        lat: 13.093631859880816,
        lng: 80.17717975254769,
      },
      {
        lat: 13.09260019183814,
        lng: 80.17743191318766,
      },
    ],
    properties: {
      Ward_No: 86,
      Zone_Name: "AMBATTUR",
      fillColor: "#8bc24c",
      zname: "AMBATTUR(86)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.04840751104156,
        lng: 80.16723337556658,
      },
      {
        lat: 13.052311850653515,
        lng: 80.17041569851715,
      },
      {
        lat: 13.062227456715345,
        lng: 80.16783980457383,
      },
      {
        lat: 13.06227421606182,
        lng: 80.16746099984111,
      },
      {
        lat: 13.060685530012076,
        lng: 80.16879509639084,
      },
      {
        lat: 13.050197238409357,
        lng: 80.1702938666009,
      },
      {
        lat: 13.053546576152135,
        lng: 80.17414665382725,
      },
      {
        lat: 13.06259472990153,
        lng: 80.16962703138374,
      },
      {
        lat: 13.049904071972051,
        lng: 80.17412141699991,
      },
      {
        lat: 13.042274147679093,
        lng: 80.16794709704673,
      },
      {
        lat: 13.062300752240082,
        lng: 80.17171739672175,
      },
      {
        lat: 13.045976792946185,
        lng: 80.16795195271563,
      },
      {
        lat: 13.045474615591626,
        lng: 80.16747455403505,
      },
      {
        lat: 13.04114684531705,
        lng: 80.16670752652746,
      },
      {
        lat: 13.05585903985292,
        lng: 80.17186460482102,
      },
      {
        lat: 13.050575797767648,
        lng: 80.17404008842571,
      },
      {
        lat: 13.056437936128937,
        lng: 80.16795867350027,
      },
      {
        lat: 13.048926523333128,
        lng: 80.17095068925929,
      },
      {
        lat: 13.056247241292416,
        lng: 80.17362169337724,
      },
      {
        lat: 13.054908934021489,
        lng: 80.16743506339508,
      },
      {
        lat: 13.051241113365148,
        lng: 80.16803343601379,
      },
      {
        lat: 13.054316608906081,
        lng: 80.1686860853093,
      },
      {
        lat: 13.061005439676999,
        lng: 80.16786471830632,
      },
      {
        lat: 13.045631923582128,
        lng: 80.16922356188721,
      },
      {
        lat: 13.056149842202043,
        lng: 80.17555799953386,
      },
      {
        lat: 13.053474295457733,
        lng: 80.16866105470466,
      },
      {
        lat: 13.063847806435835,
        lng: 80.1687667654398,
      },
      {
        lat: 13.05550150358661,
        lng: 80.17395215957885,
      },
      {
        lat: 13.063815567177196,
        lng: 80.17052938503906,
      },
      {
        lat: 13.039206420907856,
        lng: 80.16908103221633,
      },
      {
        lat: 13.041259026636272,
        lng: 80.16773618754226,
      },
      {
        lat: 13.041084554050586,
        lng: 80.16594778340684,
      },
      {
        lat: 13.05907368877297,
        lng: 80.17190889834127,
      },
      {
        lat: 13.054688044776778,
        lng: 80.17055981056623,
      },
      {
        lat: 13.048760005047615,
        lng: 80.16679224400146,
      },
      {
        lat: 13.060458706611193,
        lng: 80.17125687614592,
      },
      {
        lat: 13.0390792861496,
        lng: 80.16882386322585,
      },
      {
        lat: 13.051686391016007,
        lng: 80.17444066176779,
      },
      {
        lat: 13.042476412494004,
        lng: 80.16841140248675,
      },
      {
        lat: 13.054699277368261,
        lng: 80.17114173107704,
      },
      {
        lat: 13.053583249528481,
        lng: 80.17032777999306,
      },
      {
        lat: 13.053050707000303,
        lng: 80.16721874611797,
      },
      {
        lat: 13.03921788742605,
        lng: 80.16746128751835,
      },
      {
        lat: 13.054203251497407,
        lng: 80.17255331725471,
      },
      {
        lat: 13.057311621322008,
        lng: 80.17024862942668,
      },
      {
        lat: 13.042144158863707,
        lng: 80.16853560862131,
      },
      {
        lat: 13.049432983528462,
        lng: 80.1721369373137,
      },
      {
        lat: 13.058209279406725,
        lng: 80.16877694840473,
      },
      {
        lat: 13.057805286329423,
        lng: 80.16804950272146,
      },
      {
        lat: 13.06089075487008,
        lng: 80.16725619408057,
      },
      {
        lat: 13.049676704145991,
        lng: 80.16964661647997,
      },
      {
        lat: 13.053921065136354,
        lng: 80.16913548501228,
      },
      {
        lat: 13.05867143720822,
        lng: 80.16851423138083,
      },
      {
        lat: 13.04530413905435,
        lng: 80.16995043908138,
      },
      {
        lat: 13.065301821725201,
        lng: 80.17360653560378,
      },
      {
        lat: 13.0511518502267,
        lng: 80.16817282489005,
      },
      {
        lat: 13.057749231464111,
        lng: 80.16935321600594,
      },
      {
        lat: 13.062602626708168,
        lng: 80.1688945784855,
      },
      {
        lat: 13.046841395961927,
        lng: 80.16832332286134,
      },
      {
        lat: 13.040599959774207,
        lng: 80.16700944434045,
      },
      {
        lat: 13.056961910172598,
        lng: 80.17380082738431,
      },
      {
        lat: 13.055467271351338,
        lng: 80.17563964627605,
      },
      {
        lat: 13.042078035231528,
        lng: 80.16826162198471,
      },
      {
        lat: 13.059497052860449,
        lng: 80.16743642531276,
      },
      {
        lat: 13.064028276301341,
        lng: 80.1745876816298,
      },
      {
        lat: 13.04755290734328,
        lng: 80.17022981498685,
      },
      {
        lat: 13.055193967921733,
        lng: 80.17680623674471,
      },
      {
        lat: 13.061873636519906,
        lng: 80.17471028883958,
      },
      {
        lat: 13.05544891176361,
        lng: 80.17717760737744,
      },
      {
        lat: 13.046774436020854,
        lng: 80.16774219687869,
      },
    ],
    cases: 70,
    coords: [
      {
        lat: 13.056577192384427,
        lng: 80.1783614254933,
      },
      {
        lat: 13.055040907155854,
        lng: 80.17839726709754,
      },
      {
        lat: 13.054680573253338,
        lng: 80.17828668986257,
      },
      {
        lat: 13.05405858221091,
        lng: 80.1781620946949,
      },
      {
        lat: 13.052871595352052,
        lng: 80.17812090883844,
      },
      {
        lat: 13.052872021945777,
        lng: 80.17811071775317,
      },
      {
        lat: 13.052850229692408,
        lng: 80.17790683668771,
      },
      {
        lat: 13.05276529472453,
        lng: 80.17745861141674,
      },
      {
        lat: 13.052823721120586,
        lng: 80.17692754664175,
      },
      {
        lat: 13.05310473462073,
        lng: 80.17582321465743,
      },
      {
        lat: 13.053039978428904,
        lng: 80.17531359895852,
      },
      {
        lat: 13.05297509812338,
        lng: 80.17478357776017,
      },
      {
        lat: 13.051597800948628,
        lng: 80.17473401453367,
      },
      {
        lat: 13.051247439051844,
        lng: 80.17457372733811,
      },
      {
        lat: 13.050156864604835,
        lng: 80.17435848510365,
      },
      {
        lat: 13.049580440421888,
        lng: 80.17420011113182,
      },
      {
        lat: 13.049413673327276,
        lng: 80.17381379807686,
      },
      {
        lat: 13.04949314729478,
        lng: 80.17336418137056,
      },
      {
        lat: 13.049696052077499,
        lng: 80.17293392658705,
      },
      {
        lat: 13.049652964025343,
        lng: 80.17260778645394,
      },
      {
        lat: 13.049240204095911,
        lng: 80.17232558804982,
      },
      {
        lat: 13.048561024328313,
        lng: 80.17216808372513,
      },
      {
        lat: 13.048272067554747,
        lng: 80.17196646374231,
      },
      {
        lat: 13.0479018992229,
        lng: 80.1719287834731,
      },
      {
        lat: 13.04738712839068,
        lng: 80.17176988771298,
      },
      {
        lat: 13.04565934520544,
        lng: 80.17153963179145,
      },
      {
        lat: 13.044979420755975,
        lng: 80.17125969446988,
      },
      {
        lat: 13.044711139213218,
        lng: 80.17107830605708,
      },
      {
        lat: 13.044544992686824,
        lng: 80.17079402049973,
      },
      {
        lat: 13.044318433946081,
        lng: 80.17071431172559,
      },
      {
        lat: 13.043514830454166,
        lng: 80.17037420148213,
      },
      {
        lat: 13.042794051998193,
        lng: 80.17013542301838,
      },
      {
        lat: 13.0414361889174,
        lng: 80.16990203636708,
      },
      {
        lat: 13.040222183655228,
        lng: 80.16966743220968,
      },
      {
        lat: 13.039606643056718,
        lng: 80.16983589408855,
      },
      {
        lat: 13.039400263091974,
        lng: 80.16969479488645,
      },
      {
        lat: 13.039069583516556,
        lng: 80.16939149526506,
      },
      {
        lat: 13.038922126402438,
        lng: 80.168800953286,
      },
      {
        lat: 13.03862584692413,
        lng: 80.16739540883339,
      },
      {
        lat: 13.038390600228333,
        lng: 80.16588731509489,
      },
      {
        lat: 13.03835912711076,
        lng: 80.16409180506948,
      },
      {
        lat: 13.038288698053778,
        lng: 80.16366148154147,
      },
      {
        lat: 13.039745792756612,
        lng: 80.16338579791503,
      },
      {
        lat: 13.040684462944807,
        lng: 80.16353304201908,
      },
      {
        lat: 13.042509707414034,
        lng: 80.16382797112863,
      },
      {
        lat: 13.042822912097796,
        lng: 80.1639287792718,
      },
      {
        lat: 13.043740568268342,
        lng: 80.16490387222768,
      },
      {
        lat: 13.045018019162127,
        lng: 80.16507411386178,
      },
      {
        lat: 13.046764018597631,
        lng: 80.16518866061034,
      },
      {
        lat: 13.047781461887999,
        lng: 80.16543869691282,
      },
      {
        lat: 13.048983128578993,
        lng: 80.16599755071182,
      },
      {
        lat: 13.049427831514016,
        lng: 80.1663041637022,
      },
      {
        lat: 13.04950628999542,
        lng: 80.16635522912554,
      },
      {
        lat: 13.04974276661307,
        lng: 80.16668946910825,
      },
      {
        lat: 13.054639939177086,
        lng: 80.16667388778585,
      },
      {
        lat: 13.059041728693513,
        lng: 80.16658490486171,
      },
      {
        lat: 13.059586847990689,
        lng: 80.1662699147491,
      },
      {
        lat: 13.060053194185173,
        lng: 80.1658521324379,
      },
      {
        lat: 13.06028149065715,
        lng: 80.16484147626868,
      },
      {
        lat: 13.060278659068207,
        lng: 80.16437593529295,
      },
      {
        lat: 13.06045989356905,
        lng: 80.16419334842607,
      },
      {
        lat: 13.060798045032712,
        lng: 80.16411289240587,
      },
      {
        lat: 13.061271493292821,
        lng: 80.16412195765932,
      },
      {
        lat: 13.061464232309984,
        lng: 80.16517490794345,
      },
      {
        lat: 13.061691535688755,
        lng: 80.16578353139134,
      },
      {
        lat: 13.0623096714622,
        lng: 80.16631484140488,
      },
      {
        lat: 13.063774146600444,
        lng: 80.16813408869788,
      },
      {
        lat: 13.06423995910184,
        lng: 80.16813014640591,
      },
      {
        lat: 13.064366750440312,
        lng: 80.1684531861094,
      },
      {
        lat: 13.064383244055232,
        lng: 80.17116489806067,
      },
      {
        lat: 13.064846563936378,
        lng: 80.17197642872631,
      },
      {
        lat: 13.065404568159163,
        lng: 80.1726544100762,
      },
      {
        lat: 13.065462670571675,
        lng: 80.17278666631805,
      },
      {
        lat: 13.065349003225693,
        lng: 80.17293934028856,
      },
      {
        lat: 13.065311498576971,
        lng: 80.1730534416809,
      },
      {
        lat: 13.065340746838107,
        lng: 80.17472202586283,
      },
      {
        lat: 13.0643286499315,
        lng: 80.17474955549818,
      },
      {
        lat: 13.063775605327187,
        lng: 80.17488698403561,
      },
      {
        lat: 13.063394676501213,
        lng: 80.17506088390618,
      },
      {
        lat: 13.062266720789363,
        lng: 80.17488079012001,
      },
      {
        lat: 13.060908662226515,
        lng: 80.17455093177966,
      },
      {
        lat: 13.06031546015424,
        lng: 80.17436631223458,
      },
      {
        lat: 13.05979957417876,
        lng: 80.17433275031783,
      },
      {
        lat: 13.059680139834729,
        lng: 80.17353727325651,
      },
      {
        lat: 13.05958061121447,
        lng: 80.17287437570528,
      },
      {
        lat: 13.057536973037008,
        lng: 80.17287270754841,
      },
      {
        lat: 13.057506850146012,
        lng: 80.17420044226162,
      },
      {
        lat: 13.056533180607849,
        lng: 80.17426557466969,
      },
      {
        lat: 13.056449338222818,
        lng: 80.17618164792542,
      },
      {
        lat: 13.056584756732237,
        lng: 80.17646496179516,
      },
      {
        lat: 13.056577192384427,
        lng: 80.1783614254933,
      },
    ],
    properties: {
      Ward_No: 147,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#f6d04d",
      zname: "VALASARAVAKKAM(147)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.012312881841485,
        lng: 80.17180381259223,
      },
      {
        lat: 13.017694818306508,
        lng: 80.18229186107403,
      },
      {
        lat: 13.013299287177752,
        lng: 80.17789898603138,
      },
      {
        lat: 13.01030560420449,
        lng: 80.17259105867743,
      },
      {
        lat: 13.016900973983553,
        lng: 80.18112913107134,
      },
      {
        lat: 13.002723522016115,
        lng: 80.16880394467954,
      },
      {
        lat: 13.009814147737776,
        lng: 80.17446285212606,
      },
      {
        lat: 13.017926885426313,
        lng: 80.17518560366676,
      },
      {
        lat: 13.024139542706376,
        lng: 80.18454068942904,
      },
      {
        lat: 13.00749469544266,
        lng: 80.16155993715644,
      },
      {
        lat: 13.024107835148877,
        lng: 80.1823009229331,
      },
      {
        lat: 13.006098694646763,
        lng: 80.17325316154088,
      },
      {
        lat: 13.014246539688866,
        lng: 80.1817769143603,
      },
      {
        lat: 13.002827562892824,
        lng: 80.17087541992741,
      },
      {
        lat: 13.004284538942613,
        lng: 80.17043999342955,
      },
      {
        lat: 13.003656398364734,
        lng: 80.17617932408436,
      },
      {
        lat: 13.010284210958538,
        lng: 80.17952170026794,
      },
      {
        lat: 13.012117991972382,
        lng: 80.17141653220831,
      },
      {
        lat: 13.01069629621638,
        lng: 80.18080252482437,
      },
      {
        lat: 13.006016168985441,
        lng: 80.17882531732208,
      },
      {
        lat: 13.020753096126619,
        lng: 80.17698132947409,
      },
      {
        lat: 13.00751232760869,
        lng: 80.16697319591601,
      },
      {
        lat: 13.014261638632604,
        lng: 80.17236501318565,
      },
      {
        lat: 13.015828178650928,
        lng: 80.18136320135687,
      },
      {
        lat: 13.002942279637923,
        lng: 80.17915652474981,
      },
      {
        lat: 13.013972256493233,
        lng: 80.18167876303293,
      },
      {
        lat: 13.00437685266917,
        lng: 80.1716620247734,
      },
      {
        lat: 13.01216398111502,
        lng: 80.16565732442102,
      },
      {
        lat: 13.010824860196582,
        lng: 80.16826294806644,
      },
      {
        lat: 13.009221319142462,
        lng: 80.16965365250692,
      },
      {
        lat: 13.017513900382694,
        lng: 80.17677780854086,
      },
      {
        lat: 13.013404832466684,
        lng: 80.1690272123786,
      },
      {
        lat: 13.019680007687146,
        lng: 80.18758341072686,
      },
      {
        lat: 13.009589467737216,
        lng: 80.17319950315375,
      },
      {
        lat: 13.006297557615802,
        lng: 80.1792909767439,
      },
      {
        lat: 13.007489272465534,
        lng: 80.17088059189044,
      },
      {
        lat: 13.004491270474858,
        lng: 80.16486720565776,
      },
      {
        lat: 13.012819611362488,
        lng: 80.1708307338773,
      },
      {
        lat: 13.006469089669332,
        lng: 80.17304232606685,
      },
      {
        lat: 13.023354790984953,
        lng: 80.18081990973359,
      },
      {
        lat: 13.014079305954368,
        lng: 80.1793260415663,
      },
      {
        lat: 13.003125268442858,
        lng: 80.16437790904054,
      },
      {
        lat: 13.00790133607099,
        lng: 80.1641358050574,
      },
      {
        lat: 13.018401465802258,
        lng: 80.18304616268367,
      },
      {
        lat: 13.002438357900992,
        lng: 80.17917290147004,
      },
      {
        lat: 13.005736459623561,
        lng: 80.17428629015795,
      },
      {
        lat: 13.015482562524301,
        lng: 80.17123564121718,
      },
      {
        lat: 13.013767459144496,
        lng: 80.16315599556583,
      },
      {
        lat: 13.011777016684329,
        lng: 80.17162198446506,
      },
      {
        lat: 13.020723273023407,
        lng: 80.18575457411082,
      },
      {
        lat: 13.014133648452647,
        lng: 80.16749470266679,
      },
      {
        lat: 13.019870276841782,
        lng: 80.18318589446282,
      },
      {
        lat: 13.02169230590183,
        lng: 80.18773100527295,
      },
      {
        lat: 13.010185851579418,
        lng: 80.17839126497928,
      },
      {
        lat: 13.01293125918837,
        lng: 80.18081996373245,
      },
      {
        lat: 13.011862948702069,
        lng: 80.16292468388968,
      },
      {
        lat: 13.009809493797096,
        lng: 80.17802256257255,
      },
      {
        lat: 13.00352715742077,
        lng: 80.17860977934802,
      },
      {
        lat: 13.006547913978702,
        lng: 80.17149452803963,
      },
      {
        lat: 13.003204172524907,
        lng: 80.16426009584617,
      },
      {
        lat: 13.011183755612661,
        lng: 80.16927427708818,
      },
      {
        lat: 13.003572655870279,
        lng: 80.17670884710799,
      },
      {
        lat: 13.011003585806634,
        lng: 80.17632888945792,
      },
      {
        lat: 13.005327213520626,
        lng: 80.16798240840858,
      },
      {
        lat: 13.004544332787496,
        lng: 80.16996544905771,
      },
      {
        lat: 13.009606905422878,
        lng: 80.16639082438705,
      },
      {
        lat: 13.005110721170492,
        lng: 80.17456250585789,
      },
      {
        lat: 13.024108631679306,
        lng: 80.1791264295818,
      },
      {
        lat: 13.012292836130047,
        lng: 80.18174308098935,
      },
      {
        lat: 13.013696981217542,
        lng: 80.16787911387942,
      },
      {
        lat: 13.01008970044153,
        lng: 80.17811216747371,
      },
      {
        lat: 13.021146703598731,
        lng: 80.18764236831788,
      },
      {
        lat: 13.01062007967442,
        lng: 80.16718083404393,
      },
      {
        lat: 13.008030035485586,
        lng: 80.1774300191053,
      },
      {
        lat: 13.008189374174142,
        lng: 80.16855183916559,
      },
      {
        lat: 13.006813087452898,
        lng: 80.17865411446788,
      },
      {
        lat: 13.013716358973412,
        lng: 80.1620631946218,
      },
      {
        lat: 13.009334886950766,
        lng: 80.16389234503676,
      },
      {
        lat: 13.009576861301644,
        lng: 80.17932760497419,
      },
      {
        lat: 13.00891906555744,
        lng: 80.1700510177238,
      },
      {
        lat: 13.01388145037803,
        lng: 80.18083743166957,
      },
      {
        lat: 13.001289714562947,
        lng: 80.1774783493605,
      },
      {
        lat: 13.010585959718135,
        lng: 80.16492797036427,
      },
      {
        lat: 13.013497173390958,
        lng: 80.16506099618296,
      },
      {
        lat: 13.02264721759277,
        lng: 80.17679753272218,
      },
      {
        lat: 13.021556896997687,
        lng: 80.18328135288243,
      },
      {
        lat: 13.01844129505572,
        lng: 80.18239711553517,
      },
      {
        lat: 13.002091685836117,
        lng: 80.17246575565076,
      },
      {
        lat: 13.007076511678038,
        lng: 80.16194123396583,
      },
      {
        lat: 13.023140291901763,
        lng: 80.18082441062829,
      },
      {
        lat: 13.008605421699015,
        lng: 80.16472272295795,
      },
      {
        lat: 13.021928398727535,
        lng: 80.17937632172938,
      },
      {
        lat: 13.014492331694361,
        lng: 80.18125627194814,
      },
      {
        lat: 13.01118279053408,
        lng: 80.16407542495068,
      },
      {
        lat: 13.01131180828482,
        lng: 80.16695123115045,
      },
      {
        lat: 13.024201025871115,
        lng: 80.17905449505707,
      },
      {
        lat: 13.020901023531758,
        lng: 80.18819704786411,
      },
      {
        lat: 13.013401202670458,
        lng: 80.17291401511905,
      },
      {
        lat: 13.00854662743297,
        lng: 80.16667250517705,
      },
      {
        lat: 13.022805646783086,
        lng: 80.18285423547496,
      },
      {
        lat: 13.00282696645077,
        lng: 80.1729476848128,
      },
      {
        lat: 13.005717621039844,
        lng: 80.17122556442422,
      },
      {
        lat: 13.01512002529827,
        lng: 80.18033282386116,
      },
      {
        lat: 13.005768384542538,
        lng: 80.17687935321118,
      },
      {
        lat: 13.008590140821052,
        lng: 80.16981463653633,
      },
      {
        lat: 13.020405699083236,
        lng: 80.17867846230496,
      },
      {
        lat: 13.005768565136277,
        lng: 80.18043795274332,
      },
      {
        lat: 13.010904824263768,
        lng: 80.17969842524288,
      },
      {
        lat: 13.013666595216984,
        lng: 80.17727016297465,
      },
      {
        lat: 13.00490314001845,
        lng: 80.18000011222169,
      },
      {
        lat: 13.008447374112317,
        lng: 80.16743177651789,
      },
      {
        lat: 13.014467133809173,
        lng: 80.17466530702147,
      },
      {
        lat: 13.013185029629321,
        lng: 80.17497892146328,
      },
      {
        lat: 13.001789018671142,
        lng: 80.17045507130534,
      },
      {
        lat: 13.013151631472972,
        lng: 80.17390158589937,
      },
      {
        lat: 13.01586540371381,
        lng: 80.17564216846682,
      },
      {
        lat: 13.006015835931779,
        lng: 80.17337063671029,
      },
      {
        lat: 13.023497991505218,
        lng: 80.1790907093115,
      },
      {
        lat: 13.020099342705748,
        lng: 80.1825068400247,
      },
      {
        lat: 13.003575830919281,
        lng: 80.16980085031365,
      },
      {
        lat: 13.011505028665697,
        lng: 80.16758054121047,
      },
      {
        lat: 13.008469979117775,
        lng: 80.1679141894826,
      },
      {
        lat: 13.021754290782226,
        lng: 80.17777761972258,
      },
      {
        lat: 13.01851551075984,
        lng: 80.1781328909463,
      },
      {
        lat: 13.008973793990446,
        lng: 80.1767576832906,
      },
      {
        lat: 13.003919012193757,
        lng: 80.17354545993034,
      },
      {
        lat: 13.021381274390954,
        lng: 80.18160776528288,
      },
      {
        lat: 13.02283078428682,
        lng: 80.18330903845518,
      },
      {
        lat: 13.015107287629174,
        lng: 80.18186482260114,
      },
      {
        lat: 13.018658306278224,
        lng: 80.18356135459051,
      },
      {
        lat: 13.006723106334535,
        lng: 80.17745606656693,
      },
      {
        lat: 13.020586357710302,
        lng: 80.1859769497788,
      },
      {
        lat: 13.01307381614118,
        lng: 80.16767403387769,
      },
      {
        lat: 13.003238196532209,
        lng: 80.17619174854542,
      },
      {
        lat: 13.012566414600515,
        lng: 80.17878365162034,
      },
      {
        lat: 13.00587656776431,
        lng: 80.1695136972122,
      },
      {
        lat: 13.002164208164706,
        lng: 80.17021433660214,
      },
      {
        lat: 13.018598040013035,
        lng: 80.17572857108375,
      },
      {
        lat: 13.014855171779796,
        lng: 80.17198514206402,
      },
      {
        lat: 13.00287585064244,
        lng: 80.17312458548314,
      },
      {
        lat: 13.010389657039465,
        lng: 80.16271021842918,
      },
      {
        lat: 13.004207582617422,
        lng: 80.16425601673194,
      },
      {
        lat: 13.008313345683684,
        lng: 80.1765495325335,
      },
      {
        lat: 13.01408986941645,
        lng: 80.16590698103823,
      },
      {
        lat: 13.007242553553612,
        lng: 80.16222872137435,
      },
      {
        lat: 13.010651519547523,
        lng: 80.1786339485609,
      },
      {
        lat: 13.011307559141942,
        lng: 80.17737844278923,
      },
      {
        lat: 13.012090923072057,
        lng: 80.1687405675099,
      },
      {
        lat: 13.004478040383159,
        lng: 80.17567841640034,
      },
      {
        lat: 13.004321865363556,
        lng: 80.16547933260745,
      },
      {
        lat: 13.014712782343617,
        lng: 80.17823088152007,
      },
      {
        lat: 13.008882589171842,
        lng: 80.17043972578321,
      },
      {
        lat: 13.014810679601435,
        lng: 80.18026873945226,
      },
      {
        lat: 13.004305360736236,
        lng: 80.17523588847656,
      },
      {
        lat: 13.017934063954344,
        lng: 80.17941881860197,
      },
      {
        lat: 13.005842778583158,
        lng: 80.17956022742563,
      },
      {
        lat: 13.013975574815895,
        lng: 80.17760231566433,
      },
      {
        lat: 13.008599145213466,
        lng: 80.17337933588038,
      },
      {
        lat: 13.004281059804747,
        lng: 80.17236991314448,
      },
      {
        lat: 13.00429280782035,
        lng: 80.17252952732544,
      },
      {
        lat: 13.015862495164216,
        lng: 80.17313606430068,
      },
    ],
    cases: 161,
    coords: [
      {
        lat: 13.023060861609679,
        lng: 80.18954132584541,
      },
      {
        lat: 13.022576004102177,
        lng: 80.18941067349249,
      },
      {
        lat: 13.02197606149005,
        lng: 80.1891681563705,
      },
      {
        lat: 13.021059273348966,
        lng: 80.18876325773304,
      },
      {
        lat: 13.019639370677673,
        lng: 80.18763633961436,
      },
      {
        lat: 13.019188836463679,
        lng: 80.18735954539824,
      },
      {
        lat: 13.018988152847696,
        lng: 80.187163168162,
      },
      {
        lat: 13.018500256069734,
        lng: 80.18620993160341,
      },
      {
        lat: 13.018265422439303,
        lng: 80.18586528663278,
      },
      {
        lat: 13.0179987478144,
        lng: 80.1857519994263,
      },
      {
        lat: 13.018047613620205,
        lng: 80.185586522801,
      },
      {
        lat: 13.018809371401884,
        lng: 80.18510139296956,
      },
      {
        lat: 13.019073937791246,
        lng: 80.1848680655898,
      },
      {
        lat: 13.019089456719831,
        lng: 80.18468636488161,
      },
      {
        lat: 13.018414788979948,
        lng: 80.18309096307502,
      },
      {
        lat: 13.0178651466883,
        lng: 80.18240977545577,
      },
      {
        lat: 13.017148681104254,
        lng: 80.18196960127219,
      },
      {
        lat: 13.016672208144172,
        lng: 80.18186863665288,
      },
      {
        lat: 13.01302510259583,
        lng: 80.18205699875324,
      },
      {
        lat: 13.011675787689734,
        lng: 80.18188467337163,
      },
      {
        lat: 13.010246368809485,
        lng: 80.18158177951345,
      },
      {
        lat: 13.00900087907954,
        lng: 80.18109358403181,
      },
      {
        lat: 13.006430753541947,
        lng: 80.18014411218533,
      },
      {
        lat: 13.005031323930721,
        lng: 80.18202910453596,
      },
      {
        lat: 13.004048688866504,
        lng: 80.18104345793094,
      },
      {
        lat: 13.003820201519119,
        lng: 80.18065250390796,
      },
      {
        lat: 13.000878841739645,
        lng: 80.17755842104432,
      },
      {
        lat: 13.001336145522,
        lng: 80.1752369672189,
      },
      {
        lat: 13.001430789058714,
        lng: 80.17437670795297,
      },
      {
        lat: 13.001403287291826,
        lng: 80.17259191198652,
      },
      {
        lat: 13.001257761720927,
        lng: 80.17056019423046,
      },
      {
        lat: 13.001305284538072,
        lng: 80.17016311898345,
      },
      {
        lat: 13.001469926439935,
        lng: 80.16986422078944,
      },
      {
        lat: 13.002130906632443,
        lng: 80.16906528064447,
      },
      {
        lat: 13.004022319929271,
        lng: 80.16804106245678,
      },
      {
        lat: 13.004951934327716,
        lng: 80.16761999379624,
      },
      {
        lat: 13.005116978327088,
        lng: 80.16738720437405,
      },
      {
        lat: 13.005163797473584,
        lng: 80.16687443877636,
      },
      {
        lat: 13.005127692522915,
        lng: 80.16641195911808,
      },
      {
        lat: 13.004877966192433,
        lng: 80.1660969741006,
      },
      {
        lat: 13.003889521743034,
        lng: 80.16527680472583,
      },
      {
        lat: 13.002741120259165,
        lng: 80.16416084298126,
      },
      {
        lat: 13.003948810484353,
        lng: 80.16365742269213,
      },
      {
        lat: 13.006090237848513,
        lng: 80.16354682436958,
      },
      {
        lat: 13.006306791245997,
        lng: 80.16342169179865,
      },
      {
        lat: 13.006751068607832,
        lng: 80.15990388679816,
      },
      {
        lat: 13.00687242974609,
        lng: 80.1594404853495,
      },
      {
        lat: 13.007057377376475,
        lng: 80.15922314539894,
      },
      {
        lat: 13.007274680689573,
        lng: 80.15922130630467,
      },
      {
        lat: 13.0084582643036,
        lng: 80.15985861340228,
      },
      {
        lat: 13.009979387495624,
        lng: 80.1598457397422,
      },
      {
        lat: 13.010166959830649,
        lng: 80.16005992695978,
      },
      {
        lat: 13.01035584451799,
        lng: 80.16048987776156,
      },
      {
        lat: 13.010699570899778,
        lng: 80.16085686818606,
      },
      {
        lat: 13.011475654161046,
        lng: 80.16085029999219,
      },
      {
        lat: 13.011513259253151,
        lng: 80.1619288551851,
      },
      {
        lat: 13.011699894193494,
        lng: 80.16198892555687,
      },
      {
        lat: 13.012473915186822,
        lng: 80.16164330030223,
      },
      {
        lat: 13.01275311768192,
        lng: 80.16161011238322,
      },
      {
        lat: 13.012817641568617,
        lng: 80.16201029072678,
      },
      {
        lat: 13.013004089030225,
        lng: 80.16203953772948,
      },
      {
        lat: 13.013562306541683,
        lng: 80.1619423385223,
      },
      {
        lat: 13.014026456666992,
        lng: 80.16169181065275,
      },
      {
        lat: 13.014261119052469,
        lng: 80.16157985958128,
      },
      {
        lat: 13.014470496774743,
        lng: 80.16174326003592,
      },
      {
        lat: 13.014528384239883,
        lng: 80.16185635864696,
      },
      {
        lat: 13.014529535658275,
        lng: 80.16204566311545,
      },
      {
        lat: 13.014415718139471,
        lng: 80.16214128348913,
      },
      {
        lat: 13.014225753610207,
        lng: 80.16225647973587,
      },
      {
        lat: 13.01413146219672,
        lng: 80.16242766054039,
      },
      {
        lat: 13.014095633957515,
        lng: 80.16280659219008,
      },
      {
        lat: 13.014288016465223,
        lng: 80.16308893532674,
      },
      {
        lat: 13.014536674452275,
        lng: 80.16321935081876,
      },
      {
        lat: 13.014899955951115,
        lng: 80.16338665906505,
      },
      {
        lat: 13.015034335852073,
        lng: 80.16353697314352,
      },
      {
        lat: 13.015074654622984,
        lng: 80.16389632892015,
      },
      {
        lat: 13.014904561624665,
        lng: 80.1641438769383,
      },
      {
        lat: 13.014867581967078,
        lng: 80.1643335041197,
      },
      {
        lat: 13.014907900737992,
        lng: 80.16469285989629,
      },
      {
        lat: 13.015156904150727,
        lng: 80.16488006672883,
      },
      {
        lat: 13.015291168909673,
        lng: 80.16501145036041,
      },
      {
        lat: 13.01537641212506,
        lng: 80.16648737978691,
      },
      {
        lat: 13.015282120711571,
        lng: 80.16665856059136,
      },
      {
        lat: 13.015130402400292,
        lng: 80.16679236457189,
      },
      {
        lat: 13.0149987859037,
        lng: 80.16709638121729,
      },
      {
        lat: 13.014905415624868,
        lng: 80.1674190055964,
      },
      {
        lat: 13.014964454508169,
        lng: 80.16772140867597,
      },
      {
        lat: 13.015194392382837,
        lng: 80.16790877686506,
      },
      {
        lat: 13.015385508330318,
        lng: 80.16798288508659,
      },
      {
        lat: 13.015444316930116,
        lng: 80.16824742727233,
      },
      {
        lat: 13.015332341680617,
        lng: 80.16864593479522,
      },
      {
        lat: 13.015409524967462,
        lng: 80.1687967329437,
      },
      {
        lat: 13.015638771990922,
        lng: 80.16887051845183,
      },
      {
        lat: 13.01591006491305,
        lng: 80.16958761643909,
      },
      {
        lat: 13.016142420766332,
        lng: 80.17017252401169,
      },
      {
        lat: 13.01601494937576,
        lng: 80.1711580367431,
      },
      {
        lat: 13.01581075526582,
        lng: 80.1720684731133,
      },
      {
        lat: 13.015793416855493,
        lng: 80.17235259117247,
      },
      {
        lat: 13.015965467264847,
        lng: 80.1724268607505,
      },
      {
        lat: 13.016652056917188,
        lng: 80.17245891280676,
      },
      {
        lat: 13.016770595251547,
        lng: 80.17313944075302,
      },
      {
        lat: 13.016887751883619,
        lng: 80.17359280333743,
      },
      {
        lat: 13.017099660496754,
        lng: 80.17395070690466,
      },
      {
        lat: 13.017368190014755,
        lng: 80.1742134741681,
      },
      {
        lat: 13.017427228898235,
        lng: 80.17451587724757,
      },
      {
        lat: 13.017675426317933,
        lng: 80.17457057095237,
      },
      {
        lat: 13.018487012683435,
        lng: 80.17507060814717,
      },
      {
        lat: 13.018842124571977,
        lng: 80.17533503038838,
      },
      {
        lat: 13.019164079470132,
        lng: 80.17568330438483,
      },
      {
        lat: 13.019889306063058,
        lng: 80.17591116579348,
      },
      {
        lat: 13.023126560207237,
        lng: 80.17676962214662,
      },
      {
        lat: 13.023515134056622,
        lng: 80.17700033273469,
      },
      {
        lat: 13.023864246879151,
        lng: 80.17708691556763,
      },
      {
        lat: 13.024268740171546,
        lng: 80.17728371158734,
      },
      {
        lat: 13.02442665199068,
        lng: 80.17746034789555,
      },
      {
        lat: 13.024361198362717,
        lng: 80.17775010757627,
      },
      {
        lat: 13.024207075080627,
        lng: 80.1781963438536,
      },
      {
        lat: 13.024324508261705,
        lng: 80.17908521377001,
      },
      {
        lat: 13.024327484969202,
        lng: 80.17957461365827,
      },
      {
        lat: 13.02446556338388,
        lng: 80.18017410311894,
      },
      {
        lat: 13.024535752682871,
        lng: 80.18066293416986,
      },
      {
        lat: 13.024406469085632,
        lng: 80.18150939892521,
      },
      {
        lat: 13.023993984298933,
        lng: 80.18266688689098,
      },
      {
        lat: 13.02393048346629,
        lng: 80.18291265794707,
      },
      {
        lat: 13.024048804227105,
        lng: 80.18313107613324,
      },
      {
        lat: 13.024362102055015,
        lng: 80.18334784417787,
      },
      {
        lat: 13.024715572814417,
        lng: 80.18375787772761,
      },
      {
        lat: 13.024794976662935,
        lng: 80.18398953231097,
      },
      {
        lat: 13.024705871394225,
        lng: 80.18430005523014,
      },
      {
        lat: 13.024292118351934,
        lng: 80.18466495386086,
      },
      {
        lat: 13.023904048247083,
        lng: 80.184978006997,
      },
      {
        lat: 13.023866387354373,
        lng: 80.1851977452963,
      },
      {
        lat: 13.024062463438225,
        lng: 80.18537678431926,
      },
      {
        lat: 13.024258147016242,
        lng: 80.18549129149778,
      },
      {
        lat: 13.024285085972933,
        lng: 80.18564594790548,
      },
      {
        lat: 13.024142887802748,
        lng: 80.18577622169818,
      },
      {
        lat: 13.023662415378228,
        lng: 80.18585773026038,
      },
      {
        lat: 13.023105129645062,
        lng: 80.1861334944125,
      },
      {
        lat: 13.022912586115634,
        lng: 80.18653524198945,
      },
      {
        lat: 13.022824501362635,
        lng: 80.18701354770417,
      },
      {
        lat: 13.02237300892642,
        lng: 80.18758527826529,
      },
      {
        lat: 13.022192678856007,
        lng: 80.18785785214388,
      },
      {
        lat: 13.022260418754172,
        lng: 80.18830902500771,
      },
      {
        lat: 13.022485474826494,
        lng: 80.1889782860294,
      },
      {
        lat: 13.023060861609679,
        lng: 80.18954132584541,
      },
    ],
    properties: {
      Ward_No: 157,
      Zone_Name: "ALANDUR",
      fillColor: "#de4307",
      zname: "ALANDUR(157)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.000631248145611,
        lng: 80.19257124209194,
      },
      {
        lat: 13.01295346072516,
        lng: 80.1976076039398,
      },
      {
        lat: 13.001955298042352,
        lng: 80.19231390784374,
      },
      {
        lat: 12.999344929188883,
        lng: 80.18337508318389,
      },
      {
        lat: 13.006310926334846,
        lng: 80.20373326250059,
      },
      {
        lat: 13.023827011107345,
        lng: 80.19746650236257,
      },
      {
        lat: 12.993327186917734,
        lng: 80.1828251652842,
      },
      {
        lat: 12.993150219896739,
        lng: 80.18132955976212,
      },
      {
        lat: 12.992229302670564,
        lng: 80.18060621381248,
      },
      {
        lat: 12.98999131037975,
        lng: 80.18356044363752,
      },
      {
        lat: 13.005014119477387,
        lng: 80.19489524558193,
      },
      {
        lat: 13.02623773149305,
        lng: 80.19531474680946,
      },
      {
        lat: 13.009607996628787,
        lng: 80.20316898895075,
      },
      {
        lat: 13.007210328465721,
        lng: 80.19804419957681,
      },
      {
        lat: 13.00964718638751,
        lng: 80.1975312160161,
      },
      {
        lat: 13.021015446969251,
        lng: 80.19860310376507,
      },
      {
        lat: 12.998223235257639,
        lng: 80.18912629899573,
      },
      {
        lat: 13.000189202713617,
        lng: 80.20038926157176,
      },
      {
        lat: 13.004268065270535,
        lng: 80.19370494537173,
      },
      {
        lat: 12.997841321354436,
        lng: 80.18137961644625,
      },
      {
        lat: 13.01796052533106,
        lng: 80.19516480311218,
      },
      {
        lat: 12.999640817750683,
        lng: 80.18238250797305,
      },
      {
        lat: 12.998190398922215,
        lng: 80.19776011241714,
      },
      {
        lat: 13.002044192576026,
        lng: 80.19316763634234,
      },
      {
        lat: 12.991768981425688,
        lng: 80.18135277214473,
      },
      {
        lat: 13.002923094242336,
        lng: 80.19875498148176,
      },
      {
        lat: 13.010764941247917,
        lng: 80.19859076060602,
      },
      {
        lat: 13.02684584507005,
        lng: 80.19998630800143,
      },
      {
        lat: 13.00232504402145,
        lng: 80.18115648696548,
      },
      {
        lat: 12.997388514786728,
        lng: 80.18729073616434,
      },
      {
        lat: 13.004060752610316,
        lng: 80.20121319080687,
      },
      {
        lat: 13.021317798927841,
        lng: 80.19525855393826,
      },
      {
        lat: 13.001605711397472,
        lng: 80.19454255752893,
      },
      {
        lat: 12.99582917721263,
        lng: 80.1780055976539,
      },
      {
        lat: 12.992239757731506,
        lng: 80.180527955669,
      },
      {
        lat: 13.007761084567301,
        lng: 80.19472726747985,
      },
      {
        lat: 13.005813362774264,
        lng: 80.19469959599628,
      },
      {
        lat: 13.010526272620293,
        lng: 80.19497563013448,
      },
      {
        lat: 12.992060659882753,
        lng: 80.18262116492552,
      },
      {
        lat: 13.004393940193093,
        lng: 80.19501374532076,
      },
      {
        lat: 13.01306964113217,
        lng: 80.19516308330745,
      },
      {
        lat: 13.003228879665274,
        lng: 80.18848105197786,
      },
    ],
    cases: 42,
    coords: [
      {
        lat: 13.007071971951465,
        lng: 80.20404343570054,
      },
      {
        lat: 13.006424154721694,
        lng: 80.20404197571823,
      },
      {
        lat: 13.006235173928905,
        lng: 80.20378306574304,
      },
      {
        lat: 13.005570232771463,
        lng: 80.20230006834883,
      },
      {
        lat: 13.00540044430941,
        lng: 80.20211542719218,
      },
      {
        lat: 13.004670164365903,
        lng: 80.20221464680083,
      },
      {
        lat: 13.004652782813277,
        lng: 80.20243808765004,
      },
      {
        lat: 13.003510117699832,
        lng: 80.20252218956944,
      },
      {
        lat: 13.003512267998822,
        lng: 80.20287571979975,
      },
      {
        lat: 13.003400735570807,
        lng: 80.20302552622408,
      },
      {
        lat: 13.003138041143078,
        lng: 80.20297192603898,
      },
      {
        lat: 13.002986992317231,
        lng: 80.20278712628392,
      },
      {
        lat: 13.002907620000778,
        lng: 80.2020620933625,
      },
      {
        lat: 13.000977437472537,
        lng: 80.2020784289978,
      },
      {
        lat: 13.000937694727671,
        lng: 80.20170660911005,
      },
      {
        lat: 13.000412645393167,
        lng: 80.20165522930273,
      },
      {
        lat: 13.000296020151682,
        lng: 80.2009677272866,
      },
      {
        lat: 13.000275243390115,
        lng: 80.20063296250893,
      },
      {
        lat: 13.000124873606055,
        lng: 80.2005598038793,
      },
      {
        lat: 12.998982434839863,
        lng: 80.20068111950711,
      },
      {
        lat: 12.998833083618319,
        lng: 80.20077542256531,
      },
      {
        lat: 12.998607755289594,
        lng: 80.20070289832934,
      },
      {
        lat: 12.998587997090711,
        lng: 80.20053559523977,
      },
      {
        lat: 12.998696926525266,
        lng: 80.1999578311682,
      },
      {
        lat: 12.99867501802733,
        lng: 80.19943699784835,
      },
      {
        lat: 12.998503433213466,
        lng: 80.19871421172174,
      },
      {
        lat: 12.997118413600107,
        lng: 80.19869787008973,
      },
      {
        lat: 12.997166233210157,
        lng: 80.19726623135837,
      },
      {
        lat: 12.996747861240488,
        lng: 80.19353733832773,
      },
      {
        lat: 12.99616674325481,
        lng: 80.19093235914643,
      },
      {
        lat: 12.99564744088989,
        lng: 80.18919682258144,
      },
      {
        lat: 12.994304096296663,
        lng: 80.18673861138251,
      },
      {
        lat: 12.994044957163405,
        lng: 80.18595502900294,
      },
      {
        lat: 12.993619146303752,
        lng: 80.18564993521652,
      },
      {
        lat: 12.992940170217983,
        lng: 80.18554342791856,
      },
      {
        lat: 12.991326151207895,
        lng: 80.18505194636067,
      },
      {
        lat: 12.9906195956313,
        lng: 80.18505792612375,
      },
      {
        lat: 12.989970759282112,
        lng: 80.18525986127918,
      },
      {
        lat: 12.989575429525333,
        lng: 80.18531933388184,
      },
      {
        lat: 12.989376228499363,
        lng: 80.18509651247425,
      },
      {
        lat: 12.988920619269065,
        lng: 80.18453910016962,
      },
      {
        lat: 12.988608198667897,
        lng: 80.18428917355642,
      },
      {
        lat: 12.988207236370144,
        lng: 80.18342260122667,
      },
      {
        lat: 12.98731762349341,
        lng: 80.18121312068821,
      },
      {
        lat: 12.9874209434513,
        lng: 80.17961263176994,
      },
      {
        lat: 12.987503340557792,
        lng: 80.17921904665123,
      },
      {
        lat: 12.988038274599484,
        lng: 80.17887775841933,
      },
      {
        lat: 12.988912355317773,
        lng: 80.1785335999014,
      },
      {
        lat: 12.989024380111788,
        lng: 80.17836427133338,
      },
      {
        lat: 12.988993557642797,
        lng: 80.17794358100905,
      },
      {
        lat: 12.989272595529336,
        lng: 80.17735188778312,
      },
      {
        lat: 12.991373828489039,
        lng: 80.17430325598734,
      },
      {
        lat: 12.99330453517675,
        lng: 80.17574621334978,
      },
      {
        lat: 12.995321735364175,
        lng: 80.1774690728171,
      },
      {
        lat: 12.999638686804927,
        lng: 80.17625387402569,
      },
      {
        lat: 13.003820201519119,
        lng: 80.18065250390796,
      },
      {
        lat: 13.004048688866504,
        lng: 80.18104345793094,
      },
      {
        lat: 13.005186175794497,
        lng: 80.18218443098375,
      },
      {
        lat: 13.00558372443109,
        lng: 80.18248976396067,
      },
      {
        lat: 13.007003833589838,
        lng: 80.18362834510825,
      },
      {
        lat: 13.007491801436654,
        lng: 80.18485900544613,
      },
      {
        lat: 13.007692879976197,
        lng: 80.1853905084978,
      },
      {
        lat: 13.00809008724668,
        lng: 80.18563971753944,
      },
      {
        lat: 13.00834683681712,
        lng: 80.18603043237184,
      },
      {
        lat: 13.00832079347381,
        lng: 80.18639547714184,
      },
      {
        lat: 13.00818050645684,
        lng: 80.18656504490032,
      },
      {
        lat: 13.008182042604362,
        lng: 80.18681760260928,
      },
      {
        lat: 13.009408412595057,
        lng: 80.18863134531425,
      },
      {
        lat: 13.009552625322305,
        lng: 80.18910720281174,
      },
      {
        lat: 13.009499173171166,
        lng: 80.18961279661046,
      },
      {
        lat: 13.009060632246728,
        lng: 80.19186158107118,
      },
      {
        lat: 13.008674348692141,
        lng: 80.19340833795947,
      },
      {
        lat: 13.008760159459566,
        lng: 80.19357599219379,
      },
      {
        lat: 13.009100159551792,
        lng: 80.19371343174576,
      },
      {
        lat: 13.010713154463192,
        lng: 80.1940365414977,
      },
      {
        lat: 13.012184838259442,
        lng: 80.19436084720245,
      },
      {
        lat: 13.013795955657319,
        lng: 80.19437527531034,
      },
      {
        lat: 13.014950951775951,
        lng: 80.19374810521047,
      },
      {
        lat: 13.01608041660022,
        lng: 80.19357016578368,
      },
      {
        lat: 13.018258314606951,
        lng: 80.19383236778985,
      },
      {
        lat: 13.024140612032507,
        lng: 80.19439997944944,
      },
      {
        lat: 13.026976147301845,
        lng: 80.1947599814362,
      },
      {
        lat: 13.02705390692634,
        lng: 80.19468164157192,
      },
      {
        lat: 13.027287658263978,
        lng: 80.19452429974481,
      },
      {
        lat: 13.027724430982731,
        lng: 80.19339904859817,
      },
      {
        lat: 13.028389192247147,
        lng: 80.19413100689445,
      },
      {
        lat: 13.028892872278886,
        lng: 80.19551995899074,
      },
      {
        lat: 13.02914820142486,
        lng: 80.19678808222092,
      },
      {
        lat: 13.029167516252055,
        lng: 80.1999636291221,
      },
      {
        lat: 13.028964420145938,
        lng: 80.20049804778074,
      },
      {
        lat: 13.028675549728483,
        lng: 80.20050049256598,
      },
      {
        lat: 13.028303895682317,
        lng: 80.2004626610626,
      },
      {
        lat: 13.02743578908832,
        lng: 80.20022414662606,
      },
      {
        lat: 13.017107524178712,
        lng: 80.19842661964371,
      },
      {
        lat: 13.015868261985933,
        lng: 80.19823222330128,
      },
      {
        lat: 13.015001650733387,
        lng: 80.19823955765703,
      },
      {
        lat: 13.01471402643365,
        lng: 80.19844687643588,
      },
      {
        lat: 13.014429642040625,
        lng: 80.19918686759816,
      },
      {
        lat: 13.013704278046118,
        lng: 80.20206139009949,
      },
      {
        lat: 13.013459167066792,
        lng: 80.20247323361706,
      },
      {
        lat: 13.013089257585788,
        lng: 80.20272222570468,
      },
      {
        lat: 13.012844146606456,
        lng: 80.20313406922223,
      },
      {
        lat: 13.012682317702986,
        lng: 80.20366813862566,
      },
      {
        lat: 13.012518993458057,
        lng: 80.20395635923703,
      },
      {
        lat: 13.012147339411845,
        lng: 80.20391852773362,
      },
      {
        lat: 13.0110723983583,
        lng: 80.20359980997463,
      },
      {
        lat: 13.007317581377947,
        lng: 80.2037135417804,
      },
      {
        lat: 13.007071971951465,
        lng: 80.20404343570054,
      },
    ],
    properties: {
      Ward_No: 0,
      Zone_Name: "St. Thomas Mount",
      fillColor: "#8bc24c",
      zname: "St. Thomas Mount(0)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.948565284370016,
        lng: 80.2210007950523,
      },
      {
        lat: 12.922178919124184,
        lng: 80.22739671943187,
      },
      {
        lat: 12.935749010381834,
        lng: 80.21760096130406,
      },
      {
        lat: 12.945172281317134,
        lng: 80.21894294140385,
      },
      {
        lat: 12.931997955745109,
        lng: 80.20592804081403,
      },
      {
        lat: 12.93439500030712,
        lng: 80.21433951331574,
      },
      {
        lat: 12.960120314771707,
        lng: 80.21742026544548,
      },
      {
        lat: 12.936744465787092,
        lng: 80.21343314348049,
      },
      {
        lat: 12.965672478235726,
        lng: 80.2243761608481,
      },
      {
        lat: 12.949879770371275,
        lng: 80.22422959002299,
      },
      {
        lat: 12.938698675525181,
        lng: 80.21930272792207,
      },
      {
        lat: 12.939890525546545,
        lng: 80.21251464971279,
      },
      {
        lat: 12.937816040508022,
        lng: 80.21628357530405,
      },
      {
        lat: 12.936308180528139,
        lng: 80.21410441514368,
      },
      {
        lat: 12.935099947724826,
        lng: 80.21533862809264,
      },
      {
        lat: 12.924129873708537,
        lng: 80.21798454317208,
      },
      {
        lat: 12.927400278101276,
        lng: 80.21464948277763,
      },
      {
        lat: 12.951494146462679,
        lng: 80.23287244727851,
      },
      {
        lat: 12.934387497764195,
        lng: 80.22289487870034,
      },
      {
        lat: 12.937796592479751,
        lng: 80.22264586872207,
      },
      {
        lat: 12.965999258513845,
        lng: 80.22469315950728,
      },
      {
        lat: 12.947877846048984,
        lng: 80.22437305082217,
      },
      {
        lat: 12.928461193047259,
        lng: 80.22660397921427,
      },
      {
        lat: 12.969888950363663,
        lng: 80.22916791208888,
      },
      {
        lat: 12.959379691529405,
        lng: 80.21828279016324,
      },
      {
        lat: 12.937569079029652,
        lng: 80.22328105816085,
      },
      {
        lat: 12.949179365442676,
        lng: 80.22977684609153,
      },
      {
        lat: 12.95554290738228,
        lng: 80.2283978018324,
      },
      {
        lat: 12.936250351179886,
        lng: 80.20580193021041,
      },
      {
        lat: 12.96644634466608,
        lng: 80.22055521567027,
      },
      {
        lat: 12.938876328799603,
        lng: 80.22993839495096,
      },
      {
        lat: 12.945062202375391,
        lng: 80.21271986708783,
      },
      {
        lat: 12.933767370145997,
        lng: 80.22280484069785,
      },
      {
        lat: 12.931387232478265,
        lng: 80.21543451125045,
      },
      {
        lat: 12.959034766745246,
        lng: 80.22161142230851,
      },
      {
        lat: 12.955988811246854,
        lng: 80.21613792225267,
      },
      {
        lat: 12.953056822372885,
        lng: 80.21409539464817,
      },
      {
        lat: 12.936275804413103,
        lng: 80.22114829122448,
      },
      {
        lat: 12.929088426234548,
        lng: 80.21218297225208,
      },
      {
        lat: 12.961195809944037,
        lng: 80.21899990924273,
      },
      {
        lat: 12.928115862452405,
        lng: 80.21055168084963,
      },
      {
        lat: 12.94306512760593,
        lng: 80.20931150918959,
      },
      {
        lat: 12.949383406680672,
        lng: 80.22860208831526,
      },
      {
        lat: 12.94065065227856,
        lng: 80.22026707965755,
      },
      {
        lat: 12.950643400362894,
        lng: 80.21702800051352,
      },
      {
        lat: 12.947753346466493,
        lng: 80.23131904247148,
      },
      {
        lat: 12.92516965635432,
        lng: 80.21564589936214,
      },
      {
        lat: 12.93019770994998,
        lng: 80.21288164893771,
      },
      {
        lat: 12.94647286080796,
        lng: 80.22141885970558,
      },
    ],
    cases: 49,
    coords: [
      {
        lat: 12.938816282019356,
        lng: 80.20541015827985,
      },
      {
        lat: 12.941044326882313,
        lng: 80.20617911469212,
      },
      {
        lat: 12.942018500846313,
        lng: 80.20674205317633,
      },
      {
        lat: 12.94256146971086,
        lng: 80.20734434000931,
      },
      {
        lat: 12.942924462193567,
        lng: 80.20791245108083,
      },
      {
        lat: 12.943286151939514,
        lng: 80.2082663794887,
      },
      {
        lat: 12.944654927782604,
        lng: 80.20868318256207,
      },
      {
        lat: 12.945880981832184,
        lng: 80.20927968826804,
      },
      {
        lat: 12.950998520791126,
        lng: 80.2112712172556,
      },
      {
        lat: 12.954132845584153,
        lng: 80.21231565909791,
      },
      {
        lat: 12.95777375314446,
        lng: 80.21389129782172,
      },
      {
        lat: 12.961558250989338,
        lng: 80.21543002235555,
      },
      {
        lat: 12.96408052581002,
        lng: 80.21633684834276,
      },
      {
        lat: 12.965595280288143,
        lng: 80.21710940544288,
      },
      {
        lat: 12.967738154324746,
        lng: 80.21762851364103,
      },
      {
        lat: 12.967457409938708,
        lng: 80.21826987581316,
      },
      {
        lat: 12.967259049080663,
        lng: 80.21958281564744,
      },
      {
        lat: 12.967352050875897,
        lng: 80.22130305868379,
      },
      {
        lat: 12.967567358937446,
        lng: 80.22277640516269,
      },
      {
        lat: 12.968237852343956,
        lng: 80.22445078382977,
      },
      {
        lat: 12.969275513772137,
        lng: 80.22542544762328,
      },
      {
        lat: 12.971081179238169,
        lng: 80.22714348901526,
      },
      {
        lat: 12.972680301829852,
        lng: 80.22863855073325,
      },
      {
        lat: 12.968341932249341,
        lng: 80.22970597922551,
      },
      {
        lat: 12.968237810041854,
        lng: 80.23088561876357,
      },
      {
        lat: 12.964636921284391,
        lng: 80.2305108958448,
      },
      {
        lat: 12.961853514239461,
        lng: 80.23035027155217,
      },
      {
        lat: 12.961148668238229,
        lng: 80.23035623684667,
      },
      {
        lat: 12.96085301117245,
        lng: 80.23054292005735,
      },
      {
        lat: 12.959816083065997,
        lng: 80.2308463854299,
      },
      {
        lat: 12.955890505509435,
        lng: 80.2319846945402,
      },
      {
        lat: 12.9547429580479,
        lng: 80.23239960470612,
      },
      {
        lat: 12.953282538605352,
        lng: 80.23314528483841,
      },
      {
        lat: 12.953044994726996,
        lng: 80.23326558887393,
      },
      {
        lat: 12.952629113073169,
        lng: 80.23344654904841,
      },
      {
        lat: 12.948487488766405,
        lng: 80.23318586658873,
      },
      {
        lat: 12.945537720790618,
        lng: 80.23300381736978,
      },
      {
        lat: 12.943421334925121,
        lng: 80.23272599479198,
      },
      {
        lat: 12.942524969506739,
        lng: 80.23226040640438,
      },
      {
        lat: 12.941121035408958,
        lng: 80.231592099832,
      },
      {
        lat: 12.940433330285028,
        lng: 80.23115431890842,
      },
      {
        lat: 12.940163665218366,
        lng: 80.23089044046358,
      },
      {
        lat: 12.939655377071718,
        lng: 80.23056943472764,
      },
      {
        lat: 12.938221480189075,
        lng: 80.22987180832814,
      },
      {
        lat: 12.936701292289383,
        lng: 80.22967766020228,
      },
      {
        lat: 12.932975549636623,
        lng: 80.22923601756818,
      },
      {
        lat: 12.928217979497441,
        lng: 80.22809334571681,
      },
      {
        lat: 12.924132683422181,
        lng: 80.22729986515596,
      },
      {
        lat: 12.923865176758797,
        lng: 80.22739084936443,
      },
      {
        lat: 12.923778705874925,
        lng: 80.2278647557503,
      },
      {
        lat: 12.921522915444063,
        lng: 80.2275064930741,
      },
      {
        lat: 12.92260644418384,
        lng: 80.21805629350645,
      },
      {
        lat: 12.923012581798867,
        lng: 80.21794748660862,
      },
      {
        lat: 12.923648851305558,
        lng: 80.21787185526144,
      },
      {
        lat: 12.923677707902561,
        lng: 80.21680035293298,
      },
      {
        lat: 12.924296825441147,
        lng: 80.21681267478915,
      },
      {
        lat: 12.924355673017239,
        lng: 80.21561302562516,
      },
      {
        lat: 12.924859297276155,
        lng: 80.21550862606952,
      },
      {
        lat: 12.926744322785474,
        lng: 80.21038567271046,
      },
      {
        lat: 12.926944798025453,
        lng: 80.21018370153077,
      },
      {
        lat: 12.92825742870768,
        lng: 80.21043962507139,
      },
      {
        lat: 12.928427130579848,
        lng: 80.21070522151486,
      },
      {
        lat: 12.928395545158924,
        lng: 80.21103927967353,
      },
      {
        lat: 12.928330141171674,
        lng: 80.21134024496335,
      },
      {
        lat: 12.928633087177207,
        lng: 80.21140443922128,
      },
      {
        lat: 12.92960895328735,
        lng: 80.21156307563493,
      },
      {
        lat: 12.931021415563254,
        lng: 80.2116512588533,
      },
      {
        lat: 12.932182852302754,
        lng: 80.20363044907887,
      },
      {
        lat: 12.938816282019356,
        lng: 80.20541015827985,
      },
    ],
    properties: {
      Ward_No: 189,
      Zone_Name: "PERUNGUDI",
      fillColor: "#8bc24c",
      zname: "PERUNGUDI(189)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.183811305252355,
        lng: 80.24529909082082,
      },
      {
        lat: 13.174260124367802,
        lng: 80.22357963425081,
      },
      {
        lat: 13.184708657993925,
        lng: 80.22819022386219,
      },
      {
        lat: 13.18920629283188,
        lng: 80.24501096905027,
      },
      {
        lat: 13.182292454432874,
        lng: 80.22686224028875,
      },
      {
        lat: 13.196394351238661,
        lng: 80.24808375853156,
      },
      {
        lat: 13.188054242365626,
        lng: 80.24425984375667,
      },
      {
        lat: 13.184111164864662,
        lng: 80.22158998608981,
      },
      {
        lat: 13.18914787414829,
        lng: 80.2321777444747,
      },
      {
        lat: 13.176933523564198,
        lng: 80.2229524220819,
      },
      {
        lat: 13.181271522687162,
        lng: 80.23300558814505,
      },
      {
        lat: 13.182193465259248,
        lng: 80.24796163039461,
      },
      {
        lat: 13.191783378759805,
        lng: 80.25185305722495,
      },
      {
        lat: 13.193403803711425,
        lng: 80.246550039459,
      },
      {
        lat: 13.189290796071337,
        lng: 80.2364937715929,
      },
      {
        lat: 13.18662095731949,
        lng: 80.24497141853881,
      },
      {
        lat: 13.18820684538007,
        lng: 80.24353838115846,
      },
      {
        lat: 13.187187740620415,
        lng: 80.24402460335052,
      },
      {
        lat: 13.183779421411135,
        lng: 80.22537253840696,
      },
      {
        lat: 13.176952938773969,
        lng: 80.23080879912959,
      },
      {
        lat: 13.194363770529252,
        lng: 80.24094966852535,
      },
      {
        lat: 13.186340156331736,
        lng: 80.24422552966942,
      },
      {
        lat: 13.192476206671287,
        lng: 80.24179743836896,
      },
      {
        lat: 13.196331807636918,
        lng: 80.2453302493466,
      },
      {
        lat: 13.177120242628803,
        lng: 80.22503782790537,
      },
      {
        lat: 13.182590248309825,
        lng: 80.24280651720916,
      },
      {
        lat: 13.1840414412453,
        lng: 80.24548193287528,
      },
      {
        lat: 13.173092336106771,
        lng: 80.21388592277947,
      },
      {
        lat: 13.177136898920448,
        lng: 80.21489061696198,
      },
      {
        lat: 13.181616236633788,
        lng: 80.23369912428322,
      },
      {
        lat: 13.196600197863823,
        lng: 80.22938156862621,
      },
      {
        lat: 13.19773187333738,
        lng: 80.23947197056482,
      },
      {
        lat: 13.182075545300066,
        lng: 80.23991831701399,
      },
      {
        lat: 13.191068470738573,
        lng: 80.2353564335451,
      },
      {
        lat: 13.18538294687648,
        lng: 80.23701389142391,
      },
      {
        lat: 13.176933048719697,
        lng: 80.21945843199495,
      },
      {
        lat: 13.182280807095069,
        lng: 80.23446089089754,
      },
      {
        lat: 13.17127772278178,
        lng: 80.2225433414347,
      },
      {
        lat: 13.18572706602709,
        lng: 80.24279418409955,
      },
      {
        lat: 13.196485874667484,
        lng: 80.24172418961713,
      },
      {
        lat: 13.175215463702832,
        lng: 80.21832635024943,
      },
    ],
    cases: 41,
    coords: [
      {
        lat: 13.19866367741348,
        lng: 80.24951799136112,
      },
      {
        lat: 13.196916025376922,
        lng: 80.24927720819308,
      },
      {
        lat: 13.1967290947528,
        lng: 80.24927879023544,
      },
      {
        lat: 13.196566941611104,
        lng: 80.24951218215321,
      },
      {
        lat: 13.194839303328916,
        lng: 80.25361034809939,
      },
      {
        lat: 13.194515420392046,
        lng: 80.25414673422385,
      },
      {
        lat: 13.194259519707336,
        lng: 80.25433451563676,
      },
      {
        lat: 13.191739343052241,
        lng: 80.25491269152192,
      },
      {
        lat: 13.191421328127579,
        lng: 80.25257198527126,
      },
      {
        lat: 13.19113429976514,
        lng: 80.25148392247098,
      },
      {
        lat: 13.190614313699848,
        lng: 80.25051384103872,
      },
      {
        lat: 13.190120516271547,
        lng: 80.2500075771124,
      },
      {
        lat: 13.18881059074807,
        lng: 80.24978664377814,
      },
      {
        lat: 13.187149746957875,
        lng: 80.24949907448432,
      },
      {
        lat: 13.185908650379139,
        lng: 80.24906874101656,
      },
      {
        lat: 13.185160363420788,
        lng: 80.24898226613377,
      },
      {
        lat: 13.184436712829589,
        lng: 80.24910440036322,
      },
      {
        lat: 13.18268593161346,
        lng: 80.24939764116687,
      },
      {
        lat: 13.182071351312628,
        lng: 80.24824274465136,
      },
      {
        lat: 13.181975910384157,
        lng: 80.24791872498966,
      },
      {
        lat: 13.181834865723419,
        lng: 80.24778070694313,
      },
      {
        lat: 13.181622875385738,
        lng: 80.24750407758397,
      },
      {
        lat: 13.181544732821086,
        lng: 80.24618222735535,
      },
      {
        lat: 13.181658177690391,
        lng: 80.24562442026523,
      },
      {
        lat: 13.181818355215789,
        lng: 80.2450662176646,
      },
      {
        lat: 13.18187843500287,
        lng: 80.24341837022124,
      },
      {
        lat: 13.181829444499582,
        lng: 80.24304755352286,
      },
      {
        lat: 13.181641102720963,
        lng: 80.24281712793467,
      },
      {
        lat: 13.181172082775086,
        lng: 80.24254267388368,
      },
      {
        lat: 13.180585666727538,
        lng: 80.24217640555707,
      },
      {
        lat: 13.179856653749072,
        lng: 80.24141691078998,
      },
      {
        lat: 13.179311466855006,
        lng: 80.24014541719342,
      },
      {
        lat: 13.178693076436854,
        lng: 80.23836410007502,
      },
      {
        lat: 13.178454191884999,
        lng: 80.23750764939457,
      },
      {
        lat: 13.177724896675636,
        lng: 80.23670175310157,
      },
      {
        lat: 13.177605807188334,
        lng: 80.23633152966903,
      },
      {
        lat: 13.177671672708858,
        lng: 80.2356349135113,
      },
      {
        lat: 13.178456814241807,
        lng: 80.23409693946913,
      },
      {
        lat: 13.178642474826942,
        lng: 80.2338865505591,
      },
      {
        lat: 13.17901591272884,
        lng: 80.23381378418524,
      },
      {
        lat: 13.179249293778202,
        lng: 80.23376540510614,
      },
      {
        lat: 13.179341771282003,
        lng: 80.23360220874365,
      },
      {
        lat: 13.179315723760526,
        lng: 80.23316159200057,
      },
      {
        lat: 13.179196210926875,
        lng: 80.23272176627889,
      },
      {
        lat: 13.17889117862354,
        lng: 80.23251553023026,
      },
      {
        lat: 13.178518446298893,
        lng: 80.2327043004194,
      },
      {
        lat: 13.178143879473339,
        lng: 80.23259146068878,
      },
      {
        lat: 13.177861225690014,
        lng: 80.23222262154329,
      },
      {
        lat: 13.177368980531657,
        lng: 80.23197156601074,
      },
      {
        lat: 13.177156990193978,
        lng: 80.23169493665158,
      },
      {
        lat: 13.176921069066742,
        lng: 80.23132570199557,
      },
      {
        lat: 13.176710066537213,
        lng: 80.23121147797784,
      },
      {
        lat: 13.176334653018962,
        lng: 80.23095943366889,
      },
      {
        lat: 13.175578040249073,
        lng: 80.22950411376526,
      },
      {
        lat: 13.175366191027017,
        lng: 80.22925068516926,
      },
      {
        lat: 13.173940845017874,
        lng: 80.22926274824216,
      },
      {
        lat: 13.173706335044967,
        lng: 80.22912552121676,
      },
      {
        lat: 13.173541500709721,
        lng: 80.22891809863631,
      },
      {
        lat: 13.173307272967714,
        lng: 80.22882727313696,
      },
      {
        lat: 13.173120624574432,
        lng: 80.2288752567054,
      },
      {
        lat: 13.172629367224278,
        lng: 80.22878660651426,
      },
      {
        lat: 13.17220736216539,
        lng: 80.22855815847885,
      },
      {
        lat: 13.171393776602175,
        lng: 80.22679208406842,
      },
      {
        lat: 13.171417519605674,
        lng: 80.22667033283781,
      },
      {
        lat: 13.17131855550771,
        lng: 80.22650099999443,
      },
      {
        lat: 13.171317372667959,
        lng: 80.22630652954534,
      },
      {
        lat: 13.171462639933067,
        lng: 80.22603788947869,
      },
      {
        lat: 13.17177736103153,
        lng: 80.22545178453333,
      },
      {
        lat: 13.171799773340311,
        lng: 80.22511125404755,
      },
      {
        lat: 13.171527363324643,
        lng: 80.22460304831736,
      },
      {
        lat: 13.171082838086177,
        lng: 80.2239747489555,
      },
      {
        lat: 13.170859688337273,
        lng: 80.22351474643787,
      },
      {
        lat: 13.170735650541186,
        lng: 80.2232483855698,
      },
      {
        lat: 13.170587574031714,
        lng: 80.22305515831998,
      },
      {
        lat: 13.17029275170744,
        lng: 80.22288748307552,
      },
      {
        lat: 13.170096449916336,
        lng: 80.22281621425597,
      },
      {
        lat: 13.169997781528137,
        lng: 80.22269549902488,
      },
      {
        lat: 13.169964723661737,
        lng: 80.22128579546927,
      },
      {
        lat: 13.169740243218044,
        lng: 80.22060701369634,
      },
      {
        lat: 13.169444681618922,
        lng: 80.22031779442115,
      },
      {
        lat: 13.169150894279431,
        lng: 80.22032028081956,
      },
      {
        lat: 13.168906071496645,
        lng: 80.22032235281829,
      },
      {
        lat: 13.168807107398509,
        lng: 80.22015301997492,
      },
      {
        lat: 13.168683069602478,
        lng: 80.21988665910685,
      },
      {
        lat: 13.168681886762727,
        lng: 80.21969218865775,
      },
      {
        lat: 13.168948382579618,
        lng: 80.21922804214266,
      },
      {
        lat: 13.169676197454578,
        lng: 80.21812792987062,
      },
      {
        lat: 13.169944319675961,
        lng: 80.2179311802231,
      },
      {
        lat: 13.171192028738938,
        lng: 80.21777476019273,
      },
      {
        lat: 13.171925462102733,
        lng: 80.21759838255376,
      },
      {
        lat: 13.172193584324118,
        lng: 80.21740163290615,
      },
      {
        lat: 13.172290330597546,
        lng: 80.21720633365753,
      },
      {
        lat: 13.172337816604443,
        lng: 80.21696283119667,
      },
      {
        lat: 13.17228752135313,
        lng: 80.21674446634124,
      },
      {
        lat: 13.172115997550202,
        lng: 80.21672160793413,
      },
      {
        lat: 13.172041220020478,
        lng: 80.21650345027862,
      },
      {
        lat: 13.172161265732333,
        lng: 80.21611347338111,
      },
      {
        lat: 13.172302836623214,
        lng: 80.21523711316117,
      },
      {
        lat: 13.17227761507018,
        lng: 80.21511577633045,
      },
      {
        lat: 13.172324361802232,
        lng: 80.21475072983863,
      },
      {
        lat: 13.172952473304381,
        lng: 80.21335974069254,
      },
      {
        lat: 13.174000848296977,
        lng: 80.21062084856949,
      },
      {
        lat: 13.174287095032861,
        lng: 80.20937861305842,
      },
      {
        lat: 13.174578368837873,
        lng: 80.20896287695581,
      },
      {
        lat: 13.174699597389482,
        lng: 80.20876737050735,
      },
      {
        lat: 13.174972085252334,
        lng: 80.20931003480477,
      },
      {
        lat: 13.175122742095814,
        lng: 80.21001130500552,
      },
      {
        lat: 13.175149644229416,
        lng: 80.21042291419558,
      },
      {
        lat: 13.17534570685207,
        lng: 80.21056660905619,
      },
      {
        lat: 13.178568658091745,
        lng: 80.21095116925382,
      },
      {
        lat: 13.179325122397515,
        lng: 80.21096899279843,
      },
      {
        lat: 13.179691082218856,
        lng: 80.2109658955856,
      },
      {
        lat: 13.179984734127414,
        lng: 80.21110876452278,
      },
      {
        lat: 13.180356292940493,
        lng: 80.21202619645682,
      },
      {
        lat: 13.180482552462534,
        lng: 80.21272767313839,
      },
      {
        lat: 13.180436409973726,
        lng: 80.2131641262223,
      },
      {
        lat: 13.180242262795671,
        lng: 80.21333534922788,
      },
      {
        lat: 13.179827213647684,
        lng: 80.2132904105,
      },
      {
        lat: 13.179143351938476,
        lng: 80.21317506970779,
      },
      {
        lat: 13.178799726652079,
        lng: 80.21283881812248,
      },
      {
        lat: 13.178286646192847,
        lng: 80.2127220319644,
      },
      {
        lat: 13.178018422999136,
        lng: 80.21274852770509,
      },
      {
        lat: 13.17782398113713,
        lng: 80.21287130180801,
      },
      {
        lat: 13.177558704781267,
        lng: 80.21338228657355,
      },
      {
        lat: 13.17707458924213,
        lng: 80.21401625192279,
      },
      {
        lat: 13.177027857385754,
        lng: 80.21435580720183,
      },
      {
        lat: 13.177324014106315,
        lng: 80.21491049180992,
      },
      {
        lat: 13.178236439563745,
        lng: 80.21650166579973,
      },
      {
        lat: 13.178580654217713,
        lng: 80.21693481518986,
      },
      {
        lat: 13.178801408845581,
        lng: 80.21712675247211,
      },
      {
        lat: 13.178802734922614,
        lng: 80.2173447725332,
      },
      {
        lat: 13.178392990082918,
        lng: 80.21817191404976,
      },
      {
        lat: 13.177961205391894,
        lng: 80.21938685326708,
      },
      {
        lat: 13.177748407226206,
        lng: 80.2205030363516,
      },
      {
        lat: 13.177268859285563,
        lng: 80.22188795968923,
      },
      {
        lat: 13.177031221772763,
        lng: 80.22293167590088,
      },
      {
        lat: 13.177033579243037,
        lng: 80.22331926712064,
      },
      {
        lat: 13.177083552621047,
        lng: 80.22351264976875,
      },
      {
        lat: 13.17732811520278,
        lng: 80.22360748276519,
      },
      {
        lat: 13.178938338416563,
        lng: 80.22359385502855,
      },
      {
        lat: 13.17942672687052,
        lng: 80.22366239876517,
      },
      {
        lat: 13.179988012605222,
        lng: 80.22368187415644,
      },
      {
        lat: 13.180231396451816,
        lng: 80.2235829115431,
      },
      {
        lat: 13.180376306961497,
        lng: 80.22333942814562,
      },
      {
        lat: 13.180728563986678,
        lng: 80.2210834569678,
      },
      {
        lat: 13.180797777719889,
        lng: 80.22042877734187,
      },
      {
        lat: 13.180820406938613,
        lng: 80.22013787744616,
      },
      {
        lat: 13.180966054157755,
        lng: 80.22001551630474,
      },
      {
        lat: 13.182088036259191,
        lng: 80.21995756928277,
      },
      {
        lat: 13.182627576826345,
        lng: 80.2204132912775,
      },
      {
        lat: 13.183730465914694,
        lng: 80.22122763098078,
      },
      {
        lat: 13.184196225149982,
        lng: 80.22158707461303,
      },
      {
        lat: 13.18454058714578,
        lng: 80.22204444845458,
      },
      {
        lat: 13.18456837333074,
        lng: 80.22260140435212,
      },
      {
        lat: 13.184666699325936,
        lng: 80.22272170068497,
      },
      {
        lat: 13.184911114565782,
        lng: 80.22279230922994,
      },
      {
        lat: 13.185033985224303,
        lng: 80.22293662353312,
      },
      {
        lat: 13.185084400627987,
        lng: 80.22320267953502,
      },
      {
        lat: 13.185135405399068,
        lng: 80.22356563334185,
      },
      {
        lat: 13.18533235207324,
        lng: 80.22385467490973,
      },
      {
        lat: 13.185505196109604,
        lng: 80.22419237186106,
      },
      {
        lat: 13.185436424402072,
        lng: 80.22491972484079,
      },
      {
        lat: 13.184862676039385,
        lng: 80.22686263648006,
      },
      {
        lat: 13.184768770300957,
        lng: 80.22746907368442,
      },
      {
        lat: 13.184624301817127,
        lng: 80.22778523043559,
      },
      {
        lat: 13.184699114542244,
        lng: 80.22805107995663,
      },
      {
        lat: 13.185066989808176,
        lng: 80.22836290060981,
      },
      {
        lat: 13.185118289263212,
        lng: 80.2287743033191,
      },
      {
        lat: 13.184633879040124,
        lng: 80.2293598197659,
      },
      {
        lat: 13.184318629306313,
        lng: 80.22967742188312,
      },
      {
        lat: 13.184271160740476,
        lng: 80.22989585490609,
      },
      {
        lat: 13.18456702277719,
        lng: 80.23040209061172,
      },
      {
        lat: 13.184741782258323,
        lng: 80.23105470542907,
      },
      {
        lat: 13.184890376315138,
        lng: 80.23141683331248,
      },
      {
        lat: 13.185085849570394,
        lng: 80.23146363036791,
      },
      {
        lat: 13.185719885243548,
        lng: 80.23140981296325,
      },
      {
        lat: 13.18659907286601,
        lng: 80.23154772635972,
      },
      {
        lat: 13.188184898758298,
        lng: 80.23153430510393,
      },
      {
        lat: 13.188770434472366,
        lng: 80.23152934956335,
      },
      {
        lat: 13.188990599732671,
        lng: 80.23162438904059,
      },
      {
        lat: 13.189309385671958,
        lng: 80.231888173753,
      },
      {
        lat: 13.189480166921994,
        lng: 80.23188672838702,
      },
      {
        lat: 13.189626256166585,
        lng: 80.23183704059934,
      },
      {
        lat: 13.189795269313741,
        lng: 80.23154490181854,
      },
      {
        lat: 13.190134032317863,
        lng: 80.23108174651318,
      },
      {
        lat: 13.191107125678583,
        lng: 80.23061322270527,
      },
      {
        lat: 13.191618438035277,
        lng: 80.2304393154487,
      },
      {
        lat: 13.192154884422639,
        lng: 80.23038632396731,
      },
      {
        lat: 13.192741451529951,
        lng: 80.23055093958531,
      },
      {
        lat: 13.19330244258087,
        lng: 80.23052196607432,
      },
      {
        lat: 13.194641643104761,
        lng: 80.23007456950498,
      },
      {
        lat: 13.195687486404271,
        lng: 80.22953275290095,
      },
      {
        lat: 13.195880602189078,
        lng: 80.22919195873675,
      },
      {
        lat: 13.196001262719212,
        lng: 80.22897290627124,
      },
      {
        lat: 13.196171012575839,
        lng: 80.22880188974665,
      },
      {
        lat: 13.197415423310115,
        lng: 80.2288155836741,
      },
      {
        lat: 13.197635293886808,
        lng: 80.22886217424886,
      },
      {
        lat: 13.1976854146067,
        lng: 80.22907978134825,
      },
      {
        lat: 13.197566964204952,
        lng: 80.22966220058218,
      },
      {
        lat: 13.196909771378243,
        lng: 80.23393148546359,
      },
      {
        lat: 13.196424980562337,
        lng: 80.23846579394278,
      },
      {
        lat: 13.196426453981262,
        lng: 80.23870803845504,
      },
      {
        lat: 13.19672025323154,
        lng: 80.23887513184334,
      },
      {
        lat: 13.197623838175376,
        lng: 80.2390128387591,
      },
      {
        lat: 13.199453784623785,
        lng: 80.23902157714598,
      },
      {
        lat: 13.198926387524486,
        lng: 80.24457372553861,
      },
      {
        lat: 13.19866367741348,
        lng: 80.24951799136112,
      },
    ],
    properties: {
      Ward_No: 17,
      Zone_Name: "MANALI",
      fillColor: "#8bc24c",
      zname: "MANALI(17)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.179432944393529,
        lng: 80.24127928896904,
      },
      {
        lat: 13.171031579007392,
        lng: 80.238935407586,
      },
      {
        lat: 13.17531200229083,
        lng: 80.24976787244793,
      },
      {
        lat: 13.168829911203954,
        lng: 80.24883901533813,
      },
      {
        lat: 13.180811314645688,
        lng: 80.24516847521467,
      },
      {
        lat: 13.166386884744664,
        lng: 80.23850267384695,
      },
      {
        lat: 13.17452455514985,
        lng: 80.23532367444186,
      },
      {
        lat: 13.175944065808945,
        lng: 80.23642346330791,
      },
      {
        lat: 13.181542060638089,
        lng: 80.2504008350118,
      },
      {
        lat: 13.171829375221042,
        lng: 80.23860737733628,
      },
      {
        lat: 13.167639791508133,
        lng: 80.24682937580367,
      },
      {
        lat: 13.1680629058095,
        lng: 80.23631541879634,
      },
      {
        lat: 13.174262495456963,
        lng: 80.2514166885227,
      },
      {
        lat: 13.174826917816942,
        lng: 80.23679450019307,
      },
      {
        lat: 13.168492582406039,
        lng: 80.23825551143834,
      },
      {
        lat: 13.169284631546793,
        lng: 80.2359979578611,
      },
      {
        lat: 13.174086407889275,
        lng: 80.23719189844782,
      },
      {
        lat: 13.16928334744174,
        lng: 80.23773581497682,
      },
      {
        lat: 13.168775296592424,
        lng: 80.24993813763207,
      },
      {
        lat: 13.17043429900356,
        lng: 80.23553495529949,
      },
      {
        lat: 13.16367994845652,
        lng: 80.22603444699295,
      },
      {
        lat: 13.167736561125531,
        lng: 80.24946041630953,
      },
      {
        lat: 13.172072260577936,
        lng: 80.23224387400347,
      },
      {
        lat: 13.170549933077082,
        lng: 80.22712354146773,
      },
      {
        lat: 13.179340564491792,
        lng: 80.25298986019784,
      },
      {
        lat: 13.180547134265176,
        lng: 80.24843269598067,
      },
      {
        lat: 13.178881254633541,
        lng: 80.23333623289473,
      },
      {
        lat: 13.166511574396033,
        lng: 80.23948495027375,
      },
      {
        lat: 13.168341121941461,
        lng: 80.23269539815979,
      },
      {
        lat: 13.180004528585314,
        lng: 80.24975137590157,
      },
      {
        lat: 13.165845627401657,
        lng: 80.22664608284511,
      },
      {
        lat: 13.177844840276277,
        lng: 80.2499306592796,
      },
      {
        lat: 13.1729418368655,
        lng: 80.24788271137953,
      },
      {
        lat: 13.171278514550119,
        lng: 80.24476224026644,
      },
      {
        lat: 13.167926531359148,
        lng: 80.24443837727094,
      },
      {
        lat: 13.181266989418017,
        lng: 80.25285829431682,
      },
      {
        lat: 13.175082255622721,
        lng: 80.24521202257141,
      },
      {
        lat: 13.168365520020632,
        lng: 80.23768822755687,
      },
      {
        lat: 13.1730216747763,
        lng: 80.25195207878915,
      },
      {
        lat: 13.173573408381023,
        lng: 80.23785887095772,
      },
      {
        lat: 13.174408833223186,
        lng: 80.24329322796848,
      },
      {
        lat: 13.176043686441535,
        lng: 80.23294575797289,
      },
      {
        lat: 13.172380334630201,
        lng: 80.23111208777928,
      },
      {
        lat: 13.169465647641939,
        lng: 80.23109263278216,
      },
      {
        lat: 13.17125621070349,
        lng: 80.23880708520294,
      },
      {
        lat: 13.170079474177223,
        lng: 80.23773433041801,
      },
      {
        lat: 13.173631304255533,
        lng: 80.23864613188121,
      },
      {
        lat: 13.17950662713158,
        lng: 80.24578314929147,
      },
      {
        lat: 13.167075952518784,
        lng: 80.24495346570872,
      },
      {
        lat: 13.171908343818442,
        lng: 80.24321775712791,
      },
      {
        lat: 13.180853268227033,
        lng: 80.24767001234187,
      },
      {
        lat: 13.167027328344458,
        lng: 80.24517329793723,
      },
      {
        lat: 13.171138429535667,
        lng: 80.24567364382501,
      },
      {
        lat: 13.170908402964335,
        lng: 80.25019861529906,
      },
      {
        lat: 13.1770051879031,
        lng: 80.24355547432624,
      },
    ],
    cases: 55,
    coords: [
      {
        lat: 13.166984926354033,
        lng: 80.25064596061134,
      },
      {
        lat: 13.167101512259775,
        lng: 80.24968715765043,
      },
      {
        lat: 13.166974751427569,
        lng: 80.24494776925414,
      },
      {
        lat: 13.16670101071718,
        lng: 80.2442207842688,
      },
      {
        lat: 13.166325200954699,
        lng: 80.24281398151122,
      },
      {
        lat: 13.166145692983426,
        lng: 80.24147844757312,
      },
      {
        lat: 13.165996729344082,
        lng: 80.24113936748645,
      },
      {
        lat: 13.16550264812937,
        lng: 80.2404142473,
      },
      {
        lat: 13.165277871975576,
        lng: 80.23968684791494,
      },
      {
        lat: 13.16525013688801,
        lng: 80.23915226137987,
      },
      {
        lat: 13.165389933519252,
        lng: 80.23798419548629,
      },
      {
        lat: 13.166777821131644,
        lng: 80.23269716696323,
      },
      {
        lat: 13.16680023344032,
        lng: 80.23235663647768,
      },
      {
        lat: 13.166652009075818,
        lng: 80.23213910042155,
      },
      {
        lat: 13.16640714193643,
        lng: 80.23213387977843,
      },
      {
        lat: 13.166185322882187,
        lng: 80.23189265651607,
      },
      {
        lat: 13.16593902154954,
        lng: 80.23165164045342,
      },
      {
        lat: 13.16478658021047,
        lng: 80.2313696731737,
      },
      {
        lat: 13.164196344141985,
        lng: 80.23093708746016,
      },
      {
        lat: 13.164069497101542,
        lng: 80.23020885927559,
      },
      {
        lat: 13.16377038698315,
        lng: 80.22933622865332,
      },
      {
        lat: 13.16352364208565,
        lng: 80.22902228617217,
      },
      {
        lat: 13.163301675176443,
        lng: 80.22875675410366,
      },
      {
        lat: 13.163519945711414,
        lng: 80.22841456601891,
      },
      {
        lat: 13.163568023138193,
        lng: 80.22826829878254,
      },
      {
        lat: 13.1634929498987,
        lng: 80.22800152351475,
      },
      {
        lat: 13.163535852401544,
        lng: 80.22700444806351,
      },
      {
        lat: 13.163625649491411,
        lng: 80.22566663492668,
      },
      {
        lat: 13.163747025897992,
        lng: 80.2254954372845,
      },
      {
        lat: 13.16406544337069,
        lng: 80.2255170524924,
      },
      {
        lat: 13.164604792767932,
        lng: 80.22563403812585,
      },
      {
        lat: 13.1651192163219,
        lng: 80.22567830454086,
      },
      {
        lat: 13.165389112802922,
        lng: 80.22577326056684,
      },
      {
        lat: 13.168547474557107,
        lng: 80.22577084058962,
      },
      {
        lat: 13.168768258626562,
        lng: 80.22584190220927,
      },
      {
        lat: 13.169409529220985,
        lng: 80.2266143968088,
      },
      {
        lat: 13.169606570287103,
        lng: 80.22680720965896,
      },
      {
        lat: 13.170366703753798,
        lng: 80.22699525691203,
      },
      {
        lat: 13.1711740275175,
        lng: 80.22689118409181,
      },
      {
        lat: 13.171393776602175,
        lng: 80.22679208406842,
      },
      {
        lat: 13.17220736216539,
        lng: 80.22855815847885,
      },
      {
        lat: 13.172629367224278,
        lng: 80.22878660651426,
      },
      {
        lat: 13.173120624574432,
        lng: 80.2288752567054,
      },
      {
        lat: 13.173307272967714,
        lng: 80.22882727313696,
      },
      {
        lat: 13.173541500709721,
        lng: 80.22891809863631,
      },
      {
        lat: 13.173706335044967,
        lng: 80.22912552121676,
      },
      {
        lat: 13.173940845017874,
        lng: 80.22926274824216,
      },
      {
        lat: 13.175366191027017,
        lng: 80.22925068516926,
      },
      {
        lat: 13.175578040249073,
        lng: 80.22950411376526,
      },
      {
        lat: 13.176334653018962,
        lng: 80.23095943366889,
      },
      {
        lat: 13.176710066537213,
        lng: 80.23121147797784,
      },
      {
        lat: 13.176921069066742,
        lng: 80.23132570199557,
      },
      {
        lat: 13.177156990193978,
        lng: 80.23169493665158,
      },
      {
        lat: 13.177368980531657,
        lng: 80.23197156601074,
      },
      {
        lat: 13.177861225690014,
        lng: 80.23222262154329,
      },
      {
        lat: 13.178143879473339,
        lng: 80.23259146068878,
      },
      {
        lat: 13.178518446298893,
        lng: 80.2327043004194,
      },
      {
        lat: 13.17889117862354,
        lng: 80.23251553023026,
      },
      {
        lat: 13.179196210926875,
        lng: 80.23272176627889,
      },
      {
        lat: 13.179315723760526,
        lng: 80.23316159200057,
      },
      {
        lat: 13.179341771282003,
        lng: 80.23360220874365,
      },
      {
        lat: 13.179249293778202,
        lng: 80.23376540510614,
      },
      {
        lat: 13.17901591272884,
        lng: 80.23381378418524,
      },
      {
        lat: 13.178642474826942,
        lng: 80.2338865505591,
      },
      {
        lat: 13.178456814241807,
        lng: 80.23409693946913,
      },
      {
        lat: 13.177671672708858,
        lng: 80.2356349135113,
      },
      {
        lat: 13.177605807188334,
        lng: 80.23633152966903,
      },
      {
        lat: 13.177724896675636,
        lng: 80.23670175310157,
      },
      {
        lat: 13.178454191884999,
        lng: 80.23750764939457,
      },
      {
        lat: 13.178693076436854,
        lng: 80.23836410007502,
      },
      {
        lat: 13.179311466855006,
        lng: 80.24014541719342,
      },
      {
        lat: 13.179856653749072,
        lng: 80.24141691078998,
      },
      {
        lat: 13.180585666727538,
        lng: 80.24217640555707,
      },
      {
        lat: 13.181172082775086,
        lng: 80.24254267388368,
      },
      {
        lat: 13.181641102720963,
        lng: 80.24281712793467,
      },
      {
        lat: 13.181829444499582,
        lng: 80.24304755352286,
      },
      {
        lat: 13.18187843500287,
        lng: 80.24341837022124,
      },
      {
        lat: 13.181818355215789,
        lng: 80.2450662176646,
      },
      {
        lat: 13.181658177690391,
        lng: 80.24562442026523,
      },
      {
        lat: 13.181544732821086,
        lng: 80.24618222735535,
      },
      {
        lat: 13.181622875385738,
        lng: 80.24750407758397,
      },
      {
        lat: 13.181834865723419,
        lng: 80.24778070694313,
      },
      {
        lat: 13.181975910384157,
        lng: 80.24791872498966,
      },
      {
        lat: 13.182071351312628,
        lng: 80.24824274465136,
      },
      {
        lat: 13.18268593161346,
        lng: 80.24939764116687,
      },
      {
        lat: 13.18238621932058,
        lng: 80.24951611456163,
      },
      {
        lat: 13.182296430594077,
        lng: 80.2496453501896,
      },
      {
        lat: 13.182233925557485,
        lng: 80.25000561120899,
      },
      {
        lat: 13.18239708072061,
        lng: 80.25130183518226,
      },
      {
        lat: 13.18237409470691,
        lng: 80.25177738989223,
      },
      {
        lat: 13.182207377703197,
        lng: 80.25202290473426,
      },
      {
        lat: 13.181010574521324,
        lng: 80.25309938208711,
      },
      {
        lat: 13.180727175798916,
        lng: 80.25330734172009,
      },
      {
        lat: 13.180197006213188,
        lng: 80.2533632189717,
      },
      {
        lat: 13.178525193362509,
        lng: 80.2529277029285,
      },
      {
        lat: 13.177567034529053,
        lng: 80.25282018392491,
      },
      {
        lat: 13.17393752592899,
        lng: 80.25281235868076,
      },
      {
        lat: 13.170600502876649,
        lng: 80.25188934120236,
      },
      {
        lat: 13.166984926354033,
        lng: 80.25064596061134,
      },
    ],
    properties: {
      Ward_No: 19,
      Zone_Name: "MANALI",
      fillColor: "#8bc24c",
      zname: "MANALI(19)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.17177042841775,
        lng: 80.25362369813932,
      },
      {
        lat: 13.169340598630686,
        lng: 80.2525442362852,
      },
      {
        lat: 13.171517843277416,
        lng: 80.25461755523382,
      },
      {
        lat: 13.167181875289625,
        lng: 80.25369665244378,
      },
      {
        lat: 13.166389033502588,
        lng: 80.2544943457902,
      },
      {
        lat: 13.16542275690299,
        lng: 80.25319213722213,
      },
      {
        lat: 13.170168111402624,
        lng: 80.25582812103568,
      },
      {
        lat: 13.166814842552201,
        lng: 80.25513889663418,
      },
      {
        lat: 13.169300839901554,
        lng: 80.25681383659078,
      },
      {
        lat: 13.16516943366717,
        lng: 80.25507554633128,
      },
      {
        lat: 13.166027064496337,
        lng: 80.25187469329187,
      },
      {
        lat: 13.169653012996102,
        lng: 80.25572864273899,
      },
      {
        lat: 13.167624360551676,
        lng: 80.25793610086079,
      },
      {
        lat: 13.168472672166695,
        lng: 80.25153711292987,
      },
      {
        lat: 13.170914697835665,
        lng: 80.252931862984,
      },
      {
        lat: 13.170416849469763,
        lng: 80.25514407306991,
      },
      {
        lat: 13.172236132420153,
        lng: 80.25633533755065,
      },
      {
        lat: 13.172394519037072,
        lng: 80.25259365114196,
      },
      {
        lat: 13.168447145120712,
        lng: 80.25173135745398,
      },
      {
        lat: 13.170825448674137,
        lng: 80.25695502157481,
      },
      {
        lat: 13.170253068630517,
        lng: 80.25475416888064,
      },
      {
        lat: 13.170010200449736,
        lng: 80.2532739613858,
      },
      {
        lat: 13.17161648968388,
        lng: 80.25557397580525,
      },
      {
        lat: 13.17127027474151,
        lng: 80.25391226351091,
      },
      {
        lat: 13.168478064292042,
        lng: 80.25687969300198,
      },
      {
        lat: 13.167347978292554,
        lng: 80.2575035729727,
      },
      {
        lat: 13.166571680973293,
        lng: 80.25372022642907,
      },
      {
        lat: 13.166599539991482,
        lng: 80.25359549295727,
      },
      {
        lat: 13.16654903183932,
        lng: 80.25570003374449,
      },
      {
        lat: 13.167513059227975,
        lng: 80.25706038669723,
      },
      {
        lat: 13.168073181843777,
        lng: 80.2550096565467,
      },
      {
        lat: 13.16685747408617,
        lng: 80.25471425326256,
      },
      {
        lat: 13.168359165158906,
        lng: 80.25554699560432,
      },
      {
        lat: 13.170138702713054,
        lng: 80.25407652291491,
      },
      {
        lat: 13.169494919647857,
        lng: 80.25388144727829,
      },
      {
        lat: 13.165532721030631,
        lng: 80.25371175757634,
      },
      {
        lat: 13.169345127733303,
        lng: 80.25666801671514,
      },
      {
        lat: 13.16890008040687,
        lng: 80.25641954464069,
      },
      {
        lat: 13.168770267617033,
        lng: 80.257624896971,
      },
      {
        lat: 13.168501157647045,
        lng: 80.25708917044723,
      },
      {
        lat: 13.16786118730235,
        lng: 80.25164784429482,
      },
      {
        lat: 13.166387122975467,
        lng: 80.25288836171465,
      },
      {
        lat: 13.167169105260411,
        lng: 80.25749813799618,
      },
      {
        lat: 13.167430313100107,
        lng: 80.25498563047127,
      },
      {
        lat: 13.169804906584776,
        lng: 80.25505279421866,
      },
      {
        lat: 13.166532967244787,
        lng: 80.25277918163773,
      },
      {
        lat: 13.166320838208069,
        lng: 80.25736391064537,
      },
      {
        lat: 13.167697681338913,
        lng: 80.25234912817028,
      },
      {
        lat: 13.168220312673352,
        lng: 80.25281203612963,
      },
      {
        lat: 13.167508369468585,
        lng: 80.25145497100573,
      },
      {
        lat: 13.167649298515897,
        lng: 80.25235689684376,
      },
      {
        lat: 13.172382730782925,
        lng: 80.25258223993833,
      },
      {
        lat: 13.170580646275067,
        lng: 80.25377907934056,
      },
      {
        lat: 13.167471093975553,
        lng: 80.25581315898715,
      },
      {
        lat: 13.17279270173558,
        lng: 80.25328430320785,
      },
      {
        lat: 13.172065347068749,
        lng: 80.25526218786106,
      },
      {
        lat: 13.167236837041647,
        lng: 80.25181516084741,
      },
      {
        lat: 13.168671179196954,
        lng: 80.25592949934668,
      },
      {
        lat: 13.17013272266942,
        lng: 80.25271857538559,
      },
      {
        lat: 13.170979351765068,
        lng: 80.25407306946093,
      },
      {
        lat: 13.16726472055583,
        lng: 80.25689966640512,
      },
      {
        lat: 13.16806410726771,
        lng: 80.253145376737,
      },
      {
        lat: 13.167272231380256,
        lng: 80.25491494642144,
      },
      {
        lat: 13.16819914894409,
        lng: 80.25469583555137,
      },
      {
        lat: 13.166018604122238,
        lng: 80.25492561062593,
      },
      {
        lat: 13.166706082910803,
        lng: 80.2574366858541,
      },
      {
        lat: 13.166146722040033,
        lng: 80.25502300442295,
      },
      {
        lat: 13.167068544116454,
        lng: 80.25198280322405,
      },
      {
        lat: 13.16853141092333,
        lng: 80.25446458487372,
      },
      {
        lat: 13.169137281960271,
        lng: 80.25414341215482,
      },
      {
        lat: 13.16781077863679,
        lng: 80.25824910609114,
      },
      {
        lat: 13.168920117674055,
        lng: 80.25779304822346,
      },
      {
        lat: 13.169653536216844,
        lng: 80.25415124377032,
      },
      {
        lat: 13.171699987144601,
        lng: 80.25400764204383,
      },
      {
        lat: 13.167264891459094,
        lng: 80.25336671679153,
      },
      {
        lat: 13.16609435303752,
        lng: 80.25592750278312,
      },
      {
        lat: 13.166209711489174,
        lng: 80.25171036715962,
      },
      {
        lat: 13.164670264232463,
        lng: 80.25517778941364,
      },
      {
        lat: 13.166817653932988,
        lng: 80.25269248722978,
      },
      {
        lat: 13.166584228351896,
        lng: 80.25835866757733,
      },
      {
        lat: 13.16720675425756,
        lng: 80.25317980821514,
      },
      {
        lat: 13.166101661760893,
        lng: 80.25298366187528,
      },
      {
        lat: 13.166066635813475,
        lng: 80.25762229015258,
      },
      {
        lat: 13.16492120841609,
        lng: 80.25482983582604,
      },
      {
        lat: 13.166994437979648,
        lng: 80.25400923491945,
      },
      {
        lat: 13.170987249770612,
        lng: 80.25314385392461,
      },
      {
        lat: 13.171528486199087,
        lng: 80.254507524416,
      },
      {
        lat: 13.172795197476661,
        lng: 80.25358129382965,
      },
      {
        lat: 13.168273014796046,
        lng: 80.25398706754729,
      },
      {
        lat: 13.166383819874582,
        lng: 80.25376234045991,
      },
      {
        lat: 13.171879037649393,
        lng: 80.25517045103511,
      },
      {
        lat: 13.169733131874509,
        lng: 80.25298575573554,
      },
      {
        lat: 13.167590933353159,
        lng: 80.25721767115635,
      },
      {
        lat: 13.167182837263155,
        lng: 80.25255149488245,
      },
      {
        lat: 13.166542206535343,
        lng: 80.25689308404395,
      },
    ],
    cases: 95,
    coords: [
      {
        lat: 13.164246591788272,
        lng: 80.255622490629,
      },
      {
        lat: 13.166423312503102,
        lng: 80.25089381426969,
      },
      {
        lat: 13.166789215982638,
        lng: 80.25067192701636,
      },
      {
        lat: 13.166984926354033,
        lng: 80.25064596061134,
      },
      {
        lat: 13.170600502876649,
        lng: 80.25188934120236,
      },
      {
        lat: 13.17393752592899,
        lng: 80.25281235868076,
      },
      {
        lat: 13.17389617508774,
        lng: 80.25308973979193,
      },
      {
        lat: 13.173678905077463,
        lng: 80.25329044266816,
      },
      {
        lat: 13.173043322488947,
        lng: 80.2535670000375,
      },
      {
        lat: 13.172736338804524,
        lng: 80.2539854047991,
      },
      {
        lat: 13.172432433850059,
        lng: 80.25490998293994,
      },
      {
        lat: 13.172433533396465,
        lng: 80.25509075914684,
      },
      {
        lat: 13.172634136145863,
        lng: 80.25514329704755,
      },
      {
        lat: 13.173454643814603,
        lng: 80.25533521694217,
      },
      {
        lat: 13.173546116762841,
        lng: 80.2554067569879,
      },
      {
        lat: 13.173528679819471,
        lng: 80.25553345442015,
      },
      {
        lat: 13.173383906448917,
        lng: 80.25567930808494,
      },
      {
        lat: 13.173383576584996,
        lng: 80.2556250752227,
      },
      {
        lat: 13.17301867438355,
        lng: 80.25550161362597,
      },
      {
        lat: 13.172617468884868,
        lng: 80.25539653782445,
      },
      {
        lat: 13.172508229129177,
        lng: 80.25539746234884,
      },
      {
        lat: 13.172436392217135,
        lng: 80.25556077728474,
      },
      {
        lat: 13.17245954680195,
        lng: 80.25637411612827,
      },
      {
        lat: 13.172334079603825,
        lng: 80.25670059191242,
      },
      {
        lat: 13.172098163149018,
        lng: 80.25682913839341,
      },
      {
        lat: 13.171388764464863,
        lng: 80.25694361352603,
      },
      {
        lat: 13.170697352497452,
        lng: 80.25702177932988,
      },
      {
        lat: 13.170516605693637,
        lng: 80.25724025165204,
      },
      {
        lat: 13.170352965969379,
        lng: 80.25727779368009,
      },
      {
        lat: 13.170115290240316,
        lng: 80.25711709822997,
      },
      {
        lat: 13.170042683645727,
        lng: 80.25715386982101,
      },
      {
        lat: 13.169989273268989,
        lng: 80.25735318591065,
      },
      {
        lat: 13.169807316964127,
        lng: 80.2573728044054,
      },
      {
        lat: 13.169697417480585,
        lng: 80.25726526320567,
      },
      {
        lat: 13.169514471583948,
        lng: 80.25712218311406,
      },
      {
        lat: 13.16913224239378,
        lng: 80.25714349657008,
      },
      {
        lat: 13.169023992229912,
        lng: 80.25730711968068,
      },
      {
        lat: 13.16913587089694,
        lng: 80.25774005805286,
      },
      {
        lat: 13.168936697557877,
        lng: 80.25792252922118,
      },
      {
        lat: 13.168264261898914,
        lng: 80.2581270842823,
      },
      {
        lat: 13.168188700698021,
        lng: 80.25803407362338,
      },
      {
        lat: 13.168102058711273,
        lng: 80.25781828774345,
      },
      {
        lat: 13.16810821845043,
        lng: 80.25727223078985,
      },
      {
        lat: 13.168079204191603,
        lng: 80.25717833758266,
      },
      {
        lat: 13.167937052936587,
        lng: 80.25718895452187,
      },
      {
        lat: 13.167834255342383,
        lng: 80.25743458530673,
      },
      {
        lat: 13.167931465754036,
        lng: 80.25782914539217,
      },
      {
        lat: 13.167943351059177,
        lng: 80.25822442760584,
      },
      {
        lat: 13.167821191540678,
        lng: 80.25840432512233,
      },
      {
        lat: 13.167641404294306,
        lng: 80.25846232996572,
      },
      {
        lat: 13.165953748788384,
        lng: 80.25845778527952,
      },
      {
        lat: 13.16595527081891,
        lng: 80.25714924360028,
      },
      {
        lat: 13.165585414179509,
        lng: 80.2571335460405,
      },
      {
        lat: 13.16559105861772,
        lng: 80.25650276856184,
      },
      {
        lat: 13.165903688318824,
        lng: 80.25646246719069,
      },
      {
        lat: 13.166236367012324,
        lng: 80.25660085978829,
      },
      {
        lat: 13.166824162190162,
        lng: 80.25659588512528,
      },
      {
        lat: 13.166890297139226,
        lng: 80.25655766990296,
      },
      {
        lat: 13.166889552815649,
        lng: 80.25643529581124,
      },
      {
        lat: 13.166699139888713,
        lng: 80.25630511305782,
      },
      {
        lat: 13.165959598376944,
        lng: 80.25630195811313,
      },
      {
        lat: 13.165845201756063,
        lng: 80.25619937364279,
      },
      {
        lat: 13.165787287749904,
        lng: 80.25603041401168,
      },
      {
        lat: 13.165653872738512,
        lng: 80.25591857662259,
      },
      {
        lat: 13.164246591788272,
        lng: 80.255622490629,
      },
    ],
    properties: {
      Ward_No: 20,
      Zone_Name: "MANALI",
      fillColor: "#f6d04d",
      zname: "MANALI(20)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.16627634867591,
        lng: 80.2824211583267,
      },
      {
        lat: 13.163184721713082,
        lng: 80.27192183556906,
      },
      {
        lat: 13.163502085569457,
        lng: 80.26945918347045,
      },
      {
        lat: 13.164103427677249,
        lng: 80.26538175612758,
      },
      {
        lat: 13.163971435797203,
        lng: 80.26016302181338,
      },
      {
        lat: 13.1644239766168,
        lng: 80.28280856507556,
      },
      {
        lat: 13.167380717135368,
        lng: 80.28013278140708,
      },
      {
        lat: 13.16186620855715,
        lng: 80.271689135065,
      },
      {
        lat: 13.166504010588799,
        lng: 80.28201186469974,
      },
      {
        lat: 13.167971784975435,
        lng: 80.2737651860544,
      },
      {
        lat: 13.163756733755553,
        lng: 80.27528792756611,
      },
      {
        lat: 13.165599023459356,
        lng: 80.27816974835461,
      },
      {
        lat: 13.166063625986702,
        lng: 80.26271939505101,
      },
      {
        lat: 13.165817615862176,
        lng: 80.26953248651039,
      },
      {
        lat: 13.163688393565515,
        lng: 80.27374370575316,
      },
      {
        lat: 13.165633803496291,
        lng: 80.27784336105053,
      },
      {
        lat: 13.163588775065978,
        lng: 80.27662213364772,
      },
      {
        lat: 13.166052235792256,
        lng: 80.27376597969142,
      },
      {
        lat: 13.161309146744628,
        lng: 80.27108349245087,
      },
      {
        lat: 13.162868220815716,
        lng: 80.27150960373613,
      },
      {
        lat: 13.161783447730844,
        lng: 80.27608272470974,
      },
      {
        lat: 13.166506518176941,
        lng: 80.27384704621764,
      },
      {
        lat: 13.167568695382828,
        lng: 80.2708994037282,
      },
      {
        lat: 13.16258294295912,
        lng: 80.26231749533702,
      },
      {
        lat: 13.166539686484837,
        lng: 80.27162903204292,
      },
      {
        lat: 13.162220210668151,
        lng: 80.27725812972189,
      },
      {
        lat: 13.166498979623462,
        lng: 80.26014435351229,
      },
      {
        lat: 13.16418110358036,
        lng: 80.26144961539161,
      },
      {
        lat: 13.168356295034483,
        lng: 80.27972714981586,
      },
      {
        lat: 13.164841408108,
        lng: 80.2746555206074,
      },
      {
        lat: 13.160613153145663,
        lng: 80.2786324071412,
      },
      {
        lat: 13.164731144735754,
        lng: 80.28115709176053,
      },
      {
        lat: 13.163214872626547,
        lng: 80.2680874377818,
      },
      {
        lat: 13.166564338641564,
        lng: 80.27931732174928,
      },
      {
        lat: 13.164102426356237,
        lng: 80.27536287938823,
      },
      {
        lat: 13.161826989584355,
        lng: 80.27323180436456,
      },
      {
        lat: 13.167112674161189,
        lng: 80.2750303907586,
      },
      {
        lat: 13.166593141716817,
        lng: 80.28348118574637,
      },
      {
        lat: 13.164006998381742,
        lng: 80.27349261707761,
      },
      {
        lat: 13.160848845679846,
        lng: 80.27365758258017,
      },
      {
        lat: 13.167204823676085,
        lng: 80.27263256703641,
      },
      {
        lat: 13.16666467511048,
        lng: 80.26323822847667,
      },
      {
        lat: 13.166204124228104,
        lng: 80.271062235382,
      },
      {
        lat: 13.163971924080672,
        lng: 80.26559938469997,
      },
      {
        lat: 13.165634327882286,
        lng: 80.2674088167456,
      },
      {
        lat: 13.165789369426564,
        lng: 80.26878565525081,
      },
      {
        lat: 13.163168146830177,
        lng: 80.27029957428955,
      },
      {
        lat: 13.161133567201867,
        lng: 80.27507616151736,
      },
      {
        lat: 13.161790692685182,
        lng: 80.27708093449237,
      },
      {
        lat: 13.162337263995651,
        lng: 80.27346689202997,
      },
      {
        lat: 13.164693356586358,
        lng: 80.27506429845353,
      },
      {
        lat: 13.161667905440158,
        lng: 80.26778384128868,
      },
      {
        lat: 13.16319120780671,
        lng: 80.27013639202806,
      },
      {
        lat: 13.165945701788655,
        lng: 80.26113579822804,
      },
      {
        lat: 13.1641654105905,
        lng: 80.26863954666523,
      },
      {
        lat: 13.168273649804894,
        lng: 80.27323823717775,
      },
      {
        lat: 13.16238407297164,
        lng: 80.26472026356791,
      },
      {
        lat: 13.163760927206729,
        lng: 80.27184318676669,
      },
      {
        lat: 13.161253065560912,
        lng: 80.27878980036137,
      },
      {
        lat: 13.168132669951666,
        lng: 80.2776380976951,
      },
      {
        lat: 13.162388998913555,
        lng: 80.27294546153044,
      },
      {
        lat: 13.165686386520349,
        lng: 80.28210573494,
      },
      {
        lat: 13.166246969258648,
        lng: 80.27843733281019,
      },
      {
        lat: 13.166051068159812,
        lng: 80.26241922615883,
      },
      {
        lat: 13.163589339253779,
        lng: 80.27399007262939,
      },
      {
        lat: 13.163209120210558,
        lng: 80.27036997826153,
      },
      {
        lat: 13.166050328432181,
        lng: 80.26732952686642,
      },
      {
        lat: 13.166204120842492,
        lng: 80.28148850334865,
      },
      {
        lat: 13.16424797775473,
        lng: 80.27530969290902,
      },
      {
        lat: 13.167354048507834,
        lng: 80.27725676282535,
      },
      {
        lat: 13.165013995608568,
        lng: 80.2589564384675,
      },
      {
        lat: 13.163964078598873,
        lng: 80.27305133228879,
      },
      {
        lat: 13.164001050634344,
        lng: 80.26606703233205,
      },
      {
        lat: 13.166529195383447,
        lng: 80.27962299345891,
      },
      {
        lat: 13.162592063020206,
        lng: 80.26731932402647,
      },
      {
        lat: 13.161638500701137,
        lng: 80.27463141191927,
      },
      {
        lat: 13.161083644366004,
        lng: 80.27332651610446,
      },
      {
        lat: 13.163609882989357,
        lng: 80.27510802580677,
      },
      {
        lat: 13.163832956579283,
        lng: 80.27323196737825,
      },
      {
        lat: 13.165397911189595,
        lng: 80.26053423329479,
      },
      {
        lat: 13.166041118450199,
        lng: 80.27882777484385,
      },
      {
        lat: 13.166497175781808,
        lng: 80.26945683813454,
      },
      {
        lat: 13.165179666304766,
        lng: 80.25734774388559,
      },
      {
        lat: 13.167911880986809,
        lng: 80.27237655906754,
      },
      {
        lat: 13.163087215763165,
        lng: 80.26282917451626,
      },
      {
        lat: 13.166246663390085,
        lng: 80.25872275421902,
      },
      {
        lat: 13.16210699559548,
        lng: 80.26636617175173,
      },
      {
        lat: 13.166337769371474,
        lng: 80.27826996159355,
      },
      {
        lat: 13.167642460765,
        lng: 80.27609927998434,
      },
      {
        lat: 13.164579399088332,
        lng: 80.27817795712252,
      },
      {
        lat: 13.167910435175239,
        lng: 80.27726870631834,
      },
      {
        lat: 13.16531225853085,
        lng: 80.25899449197905,
      },
      {
        lat: 13.160734574376265,
        lng: 80.27418802670036,
      },
      {
        lat: 13.16528765794902,
        lng: 80.27470504701239,
      },
      {
        lat: 13.165555274811622,
        lng: 80.26765962622194,
      },
      {
        lat: 13.164397515317935,
        lng: 80.26707525062443,
      },
      {
        lat: 13.167947301987173,
        lng: 80.274136653544,
      },
      {
        lat: 13.162478790683945,
        lng: 80.264649213048,
      },
      {
        lat: 13.165077005563388,
        lng: 80.26997797774426,
      },
      {
        lat: 13.167079005191681,
        lng: 80.27073294124357,
      },
      {
        lat: 13.168598995718801,
        lng: 80.274690272458,
      },
      {
        lat: 13.165088098772333,
        lng: 80.27766010632305,
      },
      {
        lat: 13.16427857794932,
        lng: 80.2643591195264,
      },
      {
        lat: 13.168528256093307,
        lng: 80.27872637290884,
      },
      {
        lat: 13.167142714758699,
        lng: 80.27048688996611,
      },
      {
        lat: 13.166322936862985,
        lng: 80.27621806273456,
      },
      {
        lat: 13.165692354112837,
        lng: 80.26485532811718,
      },
      {
        lat: 13.166019889503497,
        lng: 80.26802144097034,
      },
      {
        lat: 13.16308250472694,
        lng: 80.27450625857612,
      },
      {
        lat: 13.164794241891498,
        lng: 80.27005652020956,
      },
      {
        lat: 13.162169990048957,
        lng: 80.27883829041387,
      },
      {
        lat: 13.162479604122765,
        lng: 80.26956443984234,
      },
      {
        lat: 13.16801599111931,
        lng: 80.27694837128143,
      },
      {
        lat: 13.163852410390533,
        lng: 80.25745020903578,
      },
      {
        lat: 13.16437279157627,
        lng: 80.25851326293333,
      },
      {
        lat: 13.163964284963066,
        lng: 80.26164859719401,
      },
      {
        lat: 13.168666265878453,
        lng: 80.28043606623973,
      },
      {
        lat: 13.164655142970869,
        lng: 80.2759777618537,
      },
      {
        lat: 13.163571729889195,
        lng: 80.28165283754286,
      },
      {
        lat: 13.160406891030489,
        lng: 80.27641004973249,
      },
      {
        lat: 13.167578467309479,
        lng: 80.28145256543752,
      },
      {
        lat: 13.16521921667746,
        lng: 80.26640214489514,
      },
      {
        lat: 13.1667996972701,
        lng: 80.25983123909974,
      },
      {
        lat: 13.16620025188182,
        lng: 80.27559477704607,
      },
      {
        lat: 13.161662198499746,
        lng: 80.26963248530598,
      },
      {
        lat: 13.167251861922116,
        lng: 80.26033484696653,
      },
      {
        lat: 13.166966487135555,
        lng: 80.27256755179195,
      },
      {
        lat: 13.162073716128464,
        lng: 80.27783292454127,
      },
      {
        lat: 13.167174038073341,
        lng: 80.27210401397764,
      },
      {
        lat: 13.163058501619545,
        lng: 80.27194673629856,
      },
      {
        lat: 13.172147341594853,
        lng: 80.25692028685916,
      },
      {
        lat: 13.164797102755623,
        lng: 80.27977514662116,
      },
      {
        lat: 13.16612248820125,
        lng: 80.25911699904259,
      },
      {
        lat: 13.163832919133894,
        lng: 80.28107730521577,
      },
    ],
    cases: 134,
    coords: [
      {
        lat: 13.16841436815807,
        lng: 80.2846697641884,
      },
      {
        lat: 13.16372479842549,
        lng: 80.28259862003864,
      },
      {
        lat: 13.160686006040883,
        lng: 80.27950387434794,
      },
      {
        lat: 13.16033843235122,
        lng: 80.27887297174905,
      },
      {
        lat: 13.160355362500933,
        lng: 80.27761976722876,
      },
      {
        lat: 13.159962096407616,
        lng: 80.27754995967177,
      },
      {
        lat: 13.161340109221106,
        lng: 80.26998092397778,
      },
      {
        lat: 13.161355467687727,
        lng: 80.26846931935236,
      },
      {
        lat: 13.161950633264867,
        lng: 80.26425357920556,
      },
      {
        lat: 13.163486893096552,
        lng: 80.25920839554466,
      },
      {
        lat: 13.16386190193769,
        lng: 80.25645818525392,
      },
      {
        lat: 13.164246591788272,
        lng: 80.255622490629,
      },
      {
        lat: 13.165653872738512,
        lng: 80.25591857662259,
      },
      {
        lat: 13.165787287749904,
        lng: 80.25603041401168,
      },
      {
        lat: 13.165845201756063,
        lng: 80.25619937364279,
      },
      {
        lat: 13.165959598376944,
        lng: 80.25630195811313,
      },
      {
        lat: 13.166699139888713,
        lng: 80.25630511305782,
      },
      {
        lat: 13.166889552815649,
        lng: 80.25643529581124,
      },
      {
        lat: 13.166890297139226,
        lng: 80.25655766990296,
      },
      {
        lat: 13.166824162190162,
        lng: 80.25659588512528,
      },
      {
        lat: 13.166236367012324,
        lng: 80.25660085978829,
      },
      {
        lat: 13.165903688318824,
        lng: 80.25646246719069,
      },
      {
        lat: 13.16559105861772,
        lng: 80.25650276856184,
      },
      {
        lat: 13.165585414179509,
        lng: 80.2571335460405,
      },
      {
        lat: 13.16595527081891,
        lng: 80.25714924360028,
      },
      {
        lat: 13.165953748788384,
        lng: 80.25845778527952,
      },
      {
        lat: 13.167641404294306,
        lng: 80.25846232996572,
      },
      {
        lat: 13.167821191540678,
        lng: 80.25840432512233,
      },
      {
        lat: 13.167943351059177,
        lng: 80.25822442760584,
      },
      {
        lat: 13.167931465754036,
        lng: 80.25782914539217,
      },
      {
        lat: 13.167834255342383,
        lng: 80.25743458530673,
      },
      {
        lat: 13.167937052936587,
        lng: 80.25718895452187,
      },
      {
        lat: 13.168079204191603,
        lng: 80.25717833758266,
      },
      {
        lat: 13.16810821845043,
        lng: 80.25727223078985,
      },
      {
        lat: 13.168102058711273,
        lng: 80.25781828774345,
      },
      {
        lat: 13.168188700698021,
        lng: 80.25803407362338,
      },
      {
        lat: 13.168264261898914,
        lng: 80.2581270842823,
      },
      {
        lat: 13.168936697557877,
        lng: 80.25792252922118,
      },
      {
        lat: 13.16913587089694,
        lng: 80.25774005805286,
      },
      {
        lat: 13.169023992229912,
        lng: 80.25730711968068,
      },
      {
        lat: 13.16913224239378,
        lng: 80.25714349657008,
      },
      {
        lat: 13.169514471583948,
        lng: 80.25712218311406,
      },
      {
        lat: 13.169697417480585,
        lng: 80.25726526320567,
      },
      {
        lat: 13.169807316964127,
        lng: 80.2573728044054,
      },
      {
        lat: 13.169989273268989,
        lng: 80.25735318591065,
      },
      {
        lat: 13.170042683645727,
        lng: 80.25715386982101,
      },
      {
        lat: 13.170115290240316,
        lng: 80.25711709822997,
      },
      {
        lat: 13.170352965969379,
        lng: 80.25727779368009,
      },
      {
        lat: 13.170516605693637,
        lng: 80.25724025165204,
      },
      {
        lat: 13.170697352497452,
        lng: 80.25702177932988,
      },
      {
        lat: 13.171388764464863,
        lng: 80.25694361352603,
      },
      {
        lat: 13.172098163149018,
        lng: 80.25682913839341,
      },
      {
        lat: 13.172736254228024,
        lng: 80.25677334885565,
      },
      {
        lat: 13.173379280244907,
        lng: 80.2566791513901,
      },
      {
        lat: 13.174508003596971,
        lng: 80.25707787341194,
      },
      {
        lat: 13.174490990471519,
        lng: 80.25722002599,
      },
      {
        lat: 13.174276684453524,
        lng: 80.2572573418652,
      },
      {
        lat: 13.172719996842922,
        lng: 80.25703975255503,
      },
      {
        lat: 13.172112184668766,
        lng: 80.25704489662706,
      },
      {
        lat: 13.171111945967464,
        lng: 80.25719537049818,
      },
      {
        lat: 13.16959522256753,
        lng: 80.25766973484366,
      },
      {
        lat: 13.168900509396833,
        lng: 80.2580838890819,
      },
      {
        lat: 13.16825899486066,
        lng: 80.25842658879041,
      },
      {
        lat: 13.167903185694094,
        lng: 80.2587136172786,
      },
      {
        lat: 13.167673809883158,
        lng: 80.25921258861558,
      },
      {
        lat: 13.16760705293646,
        lng: 80.25999420084989,
      },
      {
        lat: 13.167595329993485,
        lng: 80.26100611127823,
      },
      {
        lat: 13.16748871679903,
        lng: 80.26111352001693,
      },
      {
        lat: 13.16727397892941,
        lng: 80.26107983525125,
      },
      {
        lat: 13.166594119626014,
        lng: 80.26099683370727,
      },
      {
        lat: 13.166362260668025,
        lng: 80.26108755135931,
      },
      {
        lat: 13.16632747867693,
        lng: 80.26124760539366,
      },
      {
        lat: 13.166524555643711,
        lng: 80.26131694177596,
      },
      {
        lat: 13.166989137262888,
        lng: 80.26127750775343,
      },
      {
        lat: 13.167132583743856,
        lng: 80.26134729802423,
      },
      {
        lat: 13.16722358733066,
        lng: 80.26161279394641,
      },
      {
        lat: 13.167182156653311,
        lng: 80.26361901593908,
      },
      {
        lat: 13.166484744409923,
        lng: 80.26358941617183,
      },
      {
        lat: 13.166018975198876,
        lng: 80.26343359843204,
      },
      {
        lat: 13.165107364900468,
        lng: 80.2634590647004,
      },
      {
        lat: 13.165036721289027,
        lng: 80.26360167116702,
      },
      {
        lat: 13.165287428741902,
        lng: 80.26367055366059,
      },
      {
        lat: 13.165663273995223,
        lng: 80.26373837708049,
      },
      {
        lat: 13.165860242999209,
        lng: 80.26378996330253,
      },
      {
        lat: 13.16591516903985,
        lng: 80.26400251133636,
      },
      {
        lat: 13.165739207789082,
        lng: 80.26446552846409,
      },
      {
        lat: 13.16572435392178,
        lng: 80.26496268424644,
      },
      {
        lat: 13.165799639938246,
        lng: 80.26558333466875,
      },
      {
        lat: 13.166433343943774,
        lng: 80.26909519823847,
      },
      {
        lat: 13.169065035707003,
        lng: 80.27230586114592,
      },
      {
        lat: 13.169046955952156,
        lng: 80.27840658189717,
      },
      {
        lat: 13.16841436815807,
        lng: 80.2846697641884,
      },
    ],
    properties: {
      Ward_No: 21,
      Zone_Name: "MANALI",
      fillColor: "#de4307",
      zname: "MANALI(21)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.181841751840134,
        lng: 80.27236475583678,
      },
      {
        lat: 13.177138787029019,
        lng: 80.27305558563026,
      },
      {
        lat: 13.182572867785485,
        lng: 80.25899205328732,
      },
      {
        lat: 13.178519107571049,
        lng: 80.27969506305091,
      },
      {
        lat: 13.173334257596535,
        lng: 80.27224729244183,
      },
      {
        lat: 13.184835986329038,
        lng: 80.26117312732217,
      },
      {
        lat: 13.184238119825197,
        lng: 80.26405105967157,
      },
      {
        lat: 13.17108798567727,
        lng: 80.28523935913772,
      },
      {
        lat: 13.172791580429836,
        lng: 80.2775992324063,
      },
      {
        lat: 13.182059515267254,
        lng: 80.2662944056918,
      },
      {
        lat: 13.180618835001656,
        lng: 80.2742638638908,
      },
      {
        lat: 13.184227183615297,
        lng: 80.26004800571707,
      },
      {
        lat: 13.184815712337288,
        lng: 80.25175168855134,
      },
      {
        lat: 13.168217266004731,
        lng: 80.26751242427163,
      },
      {
        lat: 13.185426340671286,
        lng: 80.26906067156365,
      },
      {
        lat: 13.190779055771614,
        lng: 80.26249450672213,
      },
      {
        lat: 13.189174099366648,
        lng: 80.26725840909872,
      },
      {
        lat: 13.168013196424402,
        lng: 80.2635573822851,
      },
      {
        lat: 13.173169651734089,
        lng: 80.26848404903134,
      },
      {
        lat: 13.171769357618349,
        lng: 80.2651588006994,
      },
      {
        lat: 13.173385890753853,
        lng: 80.27027942788408,
      },
      {
        lat: 13.174966742041606,
        lng: 80.27789546873318,
      },
      {
        lat: 13.178473176463497,
        lng: 80.2853918674447,
      },
      {
        lat: 13.188959151240152,
        lng: 80.25226059857937,
      },
      {
        lat: 13.17168595074287,
        lng: 80.27500263837672,
      },
      {
        lat: 13.184803173011408,
        lng: 80.27030420239336,
      },
      {
        lat: 13.177491149826462,
        lng: 80.25522540603596,
      },
      {
        lat: 13.182890235186242,
        lng: 80.27090194852663,
      },
      {
        lat: 13.17181930221161,
        lng: 80.25903924264442,
      },
      {
        lat: 13.17884395143931,
        lng: 80.25654496154043,
      },
      {
        lat: 13.180889848111844,
        lng: 80.28082614313546,
      },
      {
        lat: 13.184029969114787,
        lng: 80.26365749689317,
      },
      {
        lat: 13.191511868250744,
        lng: 80.25572183508987,
      },
      {
        lat: 13.189983853623284,
        lng: 80.25235377934466,
      },
      {
        lat: 13.17440527093956,
        lng: 80.26810098512772,
      },
      {
        lat: 13.185621006376719,
        lng: 80.25720171261821,
      },
      {
        lat: 13.170855401670764,
        lng: 80.25757656832316,
      },
      {
        lat: 13.179699211568076,
        lng: 80.26806049397467,
      },
      {
        lat: 13.17422733797096,
        lng: 80.26202687088502,
      },
      {
        lat: 13.188626641863134,
        lng: 80.25472602085502,
      },
      {
        lat: 13.18693721547999,
        lng: 80.25706514204059,
      },
      {
        lat: 13.173509941534677,
        lng: 80.25722707964506,
      },
      {
        lat: 13.171298063845057,
        lng: 80.2677164187322,
      },
      {
        lat: 13.174096889279665,
        lng: 80.27910921749331,
      },
      {
        lat: 13.169246293653492,
        lng: 80.26909092764261,
      },
      {
        lat: 13.173858167677967,
        lng: 80.28566118967224,
      },
      {
        lat: 13.176602674907793,
        lng: 80.27342802645127,
      },
      {
        lat: 13.177636894560582,
        lng: 80.28120150644429,
      },
      {
        lat: 13.178640647236763,
        lng: 80.25337022743486,
      },
      {
        lat: 13.180956175811085,
        lng: 80.28048819803342,
      },
      {
        lat: 13.169308696492907,
        lng: 80.26605934659538,
      },
      {
        lat: 13.181574509693709,
        lng: 80.2841547815422,
      },
      {
        lat: 13.17250561013496,
        lng: 80.25827515651228,
      },
      {
        lat: 13.18066186572686,
        lng: 80.26445212474322,
      },
      {
        lat: 13.179251806334888,
        lng: 80.27811299508171,
      },
      {
        lat: 13.178287640986293,
        lng: 80.27224259680784,
      },
      {
        lat: 13.180024971006537,
        lng: 80.27460862307902,
      },
      {
        lat: 13.173318453138306,
        lng: 80.25839650641383,
      },
      {
        lat: 13.174738339010071,
        lng: 80.27506242826291,
      },
      {
        lat: 13.190654549015338,
        lng: 80.2612351503564,
      },
      {
        lat: 13.191980031510036,
        lng: 80.26307278666499,
      },
      {
        lat: 13.180514806775022,
        lng: 80.25604320614931,
      },
      {
        lat: 13.182195091644388,
        lng: 80.27902183086843,
      },
      {
        lat: 13.177911741731407,
        lng: 80.27941417958688,
      },
      {
        lat: 13.186202159249598,
        lng: 80.25396848447237,
      },
      {
        lat: 13.170324101929586,
        lng: 80.27380857615515,
      },
      {
        lat: 13.169370054173255,
        lng: 80.27643944340198,
      },
      {
        lat: 13.174880199908381,
        lng: 80.26478154402294,
      },
      {
        lat: 13.186310184510317,
        lng: 80.26061684598419,
      },
      {
        lat: 13.186878405812738,
        lng: 80.25043413359599,
      },
      {
        lat: 13.17757620584788,
        lng: 80.280070558051,
      },
      {
        lat: 13.177521073621683,
        lng: 80.27865316692662,
      },
      {
        lat: 13.178813832637138,
        lng: 80.27955780781554,
      },
      {
        lat: 13.17163434243249,
        lng: 80.25874683321763,
      },
      {
        lat: 13.171205976011132,
        lng: 80.26418191592765,
      },
      {
        lat: 13.17324656868255,
        lng: 80.2669034675974,
      },
      {
        lat: 13.189604134486823,
        lng: 80.26269364685668,
      },
      {
        lat: 13.173329686209822,
        lng: 80.25512728799242,
      },
      {
        lat: 13.18739660421475,
        lng: 80.257608008807,
      },
      {
        lat: 13.178660561480022,
        lng: 80.26408243254764,
      },
      {
        lat: 13.171786290596254,
        lng: 80.27945613596968,
      },
      {
        lat: 13.18326497279492,
        lng: 80.25108611070527,
      },
      {
        lat: 13.177771246014672,
        lng: 80.2591493930815,
      },
      {
        lat: 13.185550915515762,
        lng: 80.2506828908503,
      },
      {
        lat: 13.17146916973401,
        lng: 80.26884405159142,
      },
      {
        lat: 13.182816067998916,
        lng: 80.25480629461693,
      },
      {
        lat: 13.174643779542528,
        lng: 80.28470023197296,
      },
      {
        lat: 13.170223652042905,
        lng: 80.28513522024478,
      },
      {
        lat: 13.17441608282567,
        lng: 80.28115625008428,
      },
      {
        lat: 13.172806135868665,
        lng: 80.28504345193475,
      },
      {
        lat: 13.180827467897096,
        lng: 80.28242816629037,
      },
      {
        lat: 13.172782349907015,
        lng: 80.27788080933804,
      },
      {
        lat: 13.17869331139329,
        lng: 80.28627097115196,
      },
      {
        lat: 13.179209150271836,
        lng: 80.26633849417166,
      },
      {
        lat: 13.17398946685447,
        lng: 80.27708824676786,
      },
      {
        lat: 13.168316668908833,
        lng: 80.25885746975851,
      },
      {
        lat: 13.18342123621571,
        lng: 80.2618998616499,
      },
      {
        lat: 13.183648991996673,
        lng: 80.26962568746781,
      },
      {
        lat: 13.1841579989792,
        lng: 80.25940982668152,
      },
      {
        lat: 13.174888622793404,
        lng: 80.26525084880996,
      },
      {
        lat: 13.181384268986237,
        lng: 80.27504710574618,
      },
      {
        lat: 13.175486959548271,
        lng: 80.25444713069068,
      },
      {
        lat: 13.175243848783264,
        lng: 80.27260046983366,
      },
      {
        lat: 13.171336028245461,
        lng: 80.25796811504256,
      },
      {
        lat: 13.177526753107998,
        lng: 80.27985122604804,
      },
      {
        lat: 13.182379382829286,
        lng: 80.26449405195699,
      },
      {
        lat: 13.182346357439094,
        lng: 80.26105861684367,
      },
      {
        lat: 13.172262927540844,
        lng: 80.26785759022347,
      },
      {
        lat: 13.169075734328535,
        lng: 80.2630724369288,
      },
      {
        lat: 13.19199102957345,
        lng: 80.26516087657176,
      },
      {
        lat: 13.169278150541826,
        lng: 80.26048599040907,
      },
      {
        lat: 13.186445270712348,
        lng: 80.2502780189148,
      },
      {
        lat: 13.17842949958894,
        lng: 80.27580092767107,
      },
      {
        lat: 13.182696730607875,
        lng: 80.25164652498722,
      },
      {
        lat: 13.17173475393964,
        lng: 80.2813825807656,
      },
      {
        lat: 13.168771617005945,
        lng: 80.28238410696997,
      },
      {
        lat: 13.179395048195364,
        lng: 80.25413396333802,
      },
      {
        lat: 13.179333014584836,
        lng: 80.26860384131916,
      },
      {
        lat: 13.182327550985423,
        lng: 80.26481120076629,
      },
      {
        lat: 13.173219378956276,
        lng: 80.25759856143702,
      },
      {
        lat: 13.182924345708399,
        lng: 80.27310267982396,
      },
      {
        lat: 13.17473234834365,
        lng: 80.27446759934543,
      },
      {
        lat: 13.179962351623969,
        lng: 80.27375794405667,
      },
      {
        lat: 13.172757414504254,
        lng: 80.26282061551636,
      },
      {
        lat: 13.191219009780077,
        lng: 80.2531917431754,
      },
      {
        lat: 13.177453668158943,
        lng: 80.270068729475,
      },
      {
        lat: 13.16927765221754,
        lng: 80.26630967751832,
      },
      {
        lat: 13.183144657534362,
        lng: 80.26050581620149,
      },
      {
        lat: 13.176209153227243,
        lng: 80.27647341792367,
      },
      {
        lat: 13.183524864928575,
        lng: 80.25468144163962,
      },
      {
        lat: 13.189441563795283,
        lng: 80.25517493681123,
      },
      {
        lat: 13.171416371997315,
        lng: 80.26653505700372,
      },
      {
        lat: 13.188017446309651,
        lng: 80.25173322704319,
      },
      {
        lat: 13.17923318516532,
        lng: 80.26805755337828,
      },
      {
        lat: 13.168505635419926,
        lng: 80.26573340376093,
      },
      {
        lat: 13.177046303626067,
        lng: 80.26942562937747,
      },
      {
        lat: 13.191051457879091,
        lng: 80.26181682848832,
      },
      {
        lat: 13.18059371781601,
        lng: 80.25742391691338,
      },
      {
        lat: 13.19111514049399,
        lng: 80.26292002645519,
      },
      {
        lat: 13.173629607424798,
        lng: 80.25528208586726,
      },
      {
        lat: 13.17337034646569,
        lng: 80.27574403828773,
      },
      {
        lat: 13.188338610284553,
        lng: 80.25802012156996,
      },
      {
        lat: 13.18095691263748,
        lng: 80.28562000217285,
      },
      {
        lat: 13.18421170281096,
        lng: 80.26550731952649,
      },
      {
        lat: 13.18277224425722,
        lng: 80.2707024262747,
      },
      {
        lat: 13.182298807289467,
        lng: 80.2634509998617,
      },
      {
        lat: 13.190808500924213,
        lng: 80.26150357544728,
      },
      {
        lat: 13.174313438240478,
        lng: 80.25444296085547,
      },
      {
        lat: 13.19082719272141,
        lng: 80.26629955852317,
      },
      {
        lat: 13.183110174091333,
        lng: 80.26756387784376,
      },
      {
        lat: 13.170384401422556,
        lng: 80.26221191132467,
      },
      {
        lat: 13.172445276395727,
        lng: 80.27019511731031,
      },
      {
        lat: 13.186484903778755,
        lng: 80.26433581262063,
      },
      {
        lat: 13.176820551316593,
        lng: 80.26415890170071,
      },
      {
        lat: 13.178615375966748,
        lng: 80.27343631050833,
      },
      {
        lat: 13.176624446356659,
        lng: 80.25562220068961,
      },
      {
        lat: 13.190257918983493,
        lng: 80.25214437646933,
      },
      {
        lat: 13.181702367758025,
        lng: 80.28022497468712,
      },
      {
        lat: 13.168947112708207,
        lng: 80.25908643032999,
      },
      {
        lat: 13.181299417297632,
        lng: 80.25442535369274,
      },
      {
        lat: 13.172457073879121,
        lng: 80.26658132693319,
      },
      {
        lat: 13.174593020536536,
        lng: 80.27436982002679,
      },
      {
        lat: 13.175645359907048,
        lng: 80.26519893877716,
      },
      {
        lat: 13.182159695209359,
        lng: 80.25571466727574,
      },
      {
        lat: 13.168896382181844,
        lng: 80.26470732958894,
      },
      {
        lat: 13.18533650276556,
        lng: 80.260510396767,
      },
      {
        lat: 13.186694434626988,
        lng: 80.25878646797061,
      },
    ],
    cases: 167,
    coords: [
      {
        lat: 13.182001338465065,
        lng: 80.28616411885187,
      },
      {
        lat: 13.179890576495763,
        lng: 80.2862892487202,
      },
      {
        lat: 13.175989287403594,
        lng: 80.28671232431397,
      },
      {
        lat: 13.175105587801461,
        lng: 80.28674418191613,
      },
      {
        lat: 13.17459015814685,
        lng: 80.28677292275418,
      },
      {
        lat: 13.174296135276654,
        lng: 80.28687292563897,
      },
      {
        lat: 13.17348549657616,
        lng: 80.28680665041477,
      },
      {
        lat: 13.173017983405282,
        lng: 80.28663995673709,
      },
      {
        lat: 13.168599306212318,
        lng: 80.28475144190953,
      },
      {
        lat: 13.16841436815807,
        lng: 80.2846697641884,
      },
      {
        lat: 13.169046955952156,
        lng: 80.27840658189717,
      },
      {
        lat: 13.169065035707003,
        lng: 80.27230586114592,
      },
      {
        lat: 13.166433343943774,
        lng: 80.26909519823847,
      },
      {
        lat: 13.165799639938246,
        lng: 80.26558333466875,
      },
      {
        lat: 13.16572435392178,
        lng: 80.26496268424644,
      },
      {
        lat: 13.165739207789082,
        lng: 80.26446552846409,
      },
      {
        lat: 13.16591516903985,
        lng: 80.26400251133636,
      },
      {
        lat: 13.165860242999209,
        lng: 80.26378996330253,
      },
      {
        lat: 13.165663273995223,
        lng: 80.26373837708049,
      },
      {
        lat: 13.165287428741902,
        lng: 80.26367055366059,
      },
      {
        lat: 13.165036721289027,
        lng: 80.26360167116702,
      },
      {
        lat: 13.165107364900468,
        lng: 80.2634590647004,
      },
      {
        lat: 13.166018975198876,
        lng: 80.26343359843204,
      },
      {
        lat: 13.166484744409923,
        lng: 80.26358941617183,
      },
      {
        lat: 13.167182156653311,
        lng: 80.26361901593908,
      },
      {
        lat: 13.16722358733066,
        lng: 80.26161279394641,
      },
      {
        lat: 13.167132583743856,
        lng: 80.26134729802423,
      },
      {
        lat: 13.166989137262888,
        lng: 80.26127750775343,
      },
      {
        lat: 13.166524555643711,
        lng: 80.26131694177596,
      },
      {
        lat: 13.16632747867693,
        lng: 80.26124760539366,
      },
      {
        lat: 13.166362260668025,
        lng: 80.26108755135931,
      },
      {
        lat: 13.166594119626014,
        lng: 80.26099683370727,
      },
      {
        lat: 13.16727397892941,
        lng: 80.26107983525125,
      },
      {
        lat: 13.16748871679903,
        lng: 80.26111352001693,
      },
      {
        lat: 13.167595329993485,
        lng: 80.26100611127823,
      },
      {
        lat: 13.16760705293646,
        lng: 80.25999420084989,
      },
      {
        lat: 13.167673809883158,
        lng: 80.25921258861558,
      },
      {
        lat: 13.167903185694094,
        lng: 80.2587136172786,
      },
      {
        lat: 13.16825899486066,
        lng: 80.25842658879041,
      },
      {
        lat: 13.168900509396833,
        lng: 80.2580838890819,
      },
      {
        lat: 13.16959522256753,
        lng: 80.25766973484366,
      },
      {
        lat: 13.171111945967464,
        lng: 80.25719537049818,
      },
      {
        lat: 13.172112184668766,
        lng: 80.25704489662706,
      },
      {
        lat: 13.172719996842922,
        lng: 80.25703975255503,
      },
      {
        lat: 13.174276684453524,
        lng: 80.2572573418652,
      },
      {
        lat: 13.174490990471519,
        lng: 80.25722002599,
      },
      {
        lat: 13.174508003596971,
        lng: 80.25707787341194,
      },
      {
        lat: 13.173379280244907,
        lng: 80.2566791513901,
      },
      {
        lat: 13.172736254228024,
        lng: 80.25677334885565,
      },
      {
        lat: 13.172098163149018,
        lng: 80.25682913839341,
      },
      {
        lat: 13.172334079603825,
        lng: 80.25670059191242,
      },
      {
        lat: 13.17245954680195,
        lng: 80.25637411612827,
      },
      {
        lat: 13.172436392217135,
        lng: 80.25556077728474,
      },
      {
        lat: 13.172508229129177,
        lng: 80.25539746234884,
      },
      {
        lat: 13.172617468884868,
        lng: 80.25539653782445,
      },
      {
        lat: 13.17301867438355,
        lng: 80.25550161362597,
      },
      {
        lat: 13.173383576584996,
        lng: 80.2556250752227,
      },
      {
        lat: 13.173383906448917,
        lng: 80.25567930808494,
      },
      {
        lat: 13.173528679819471,
        lng: 80.25553345442015,
      },
      {
        lat: 13.173546116762841,
        lng: 80.2554067569879,
      },
      {
        lat: 13.173454643814603,
        lng: 80.25533521694217,
      },
      {
        lat: 13.172634136145863,
        lng: 80.25514329704755,
      },
      {
        lat: 13.172433533396465,
        lng: 80.25509075914684,
      },
      {
        lat: 13.172432433850059,
        lng: 80.25490998293994,
      },
      {
        lat: 13.172736338804524,
        lng: 80.2539854047991,
      },
      {
        lat: 13.173043322488947,
        lng: 80.2535670000375,
      },
      {
        lat: 13.173678905077463,
        lng: 80.25329044266816,
      },
      {
        lat: 13.17389617508774,
        lng: 80.25308973979193,
      },
      {
        lat: 13.17393752592899,
        lng: 80.25281235868076,
      },
      {
        lat: 13.177567034529053,
        lng: 80.25282018392491,
      },
      {
        lat: 13.178525193362509,
        lng: 80.2529277029285,
      },
      {
        lat: 13.180197006213188,
        lng: 80.2533632189717,
      },
      {
        lat: 13.180727175798916,
        lng: 80.25330734172009,
      },
      {
        lat: 13.181010574521324,
        lng: 80.25309938208711,
      },
      {
        lat: 13.182207377703197,
        lng: 80.25202290473426,
      },
      {
        lat: 13.18237409470691,
        lng: 80.25177738989223,
      },
      {
        lat: 13.18239708072061,
        lng: 80.25130183518226,
      },
      {
        lat: 13.182233925557485,
        lng: 80.25000561120899,
      },
      {
        lat: 13.182296430594077,
        lng: 80.2496453501896,
      },
      {
        lat: 13.18238621932058,
        lng: 80.24951611456163,
      },
      {
        lat: 13.18268593161346,
        lng: 80.24939764116687,
      },
      {
        lat: 13.184436712829589,
        lng: 80.24910440036322,
      },
      {
        lat: 13.185160363420788,
        lng: 80.24898226613377,
      },
      {
        lat: 13.185908650379139,
        lng: 80.24906874101656,
      },
      {
        lat: 13.187149746957875,
        lng: 80.24949907448432,
      },
      {
        lat: 13.18881059074807,
        lng: 80.24978664377814,
      },
      {
        lat: 13.190120516271547,
        lng: 80.2500075771124,
      },
      {
        lat: 13.190614313699848,
        lng: 80.25051384103872,
      },
      {
        lat: 13.19113429976514,
        lng: 80.25148392247098,
      },
      {
        lat: 13.191421328127579,
        lng: 80.25257198527126,
      },
      {
        lat: 13.191739343052241,
        lng: 80.25491269152192,
      },
      {
        lat: 13.191876983597234,
        lng: 80.2584735726626,
      },
      {
        lat: 13.192313274249157,
        lng: 80.26054697505612,
      },
      {
        lat: 13.192715090732,
        lng: 80.26130605222951,
      },
      {
        lat: 13.19271844886946,
        lng: 80.26185816294202,
      },
      {
        lat: 13.192496932123808,
        lng: 80.26462073336167,
      },
      {
        lat: 13.192455328619305,
        lng: 80.26648784157999,
      },
      {
        lat: 13.192278773515609,
        lng: 80.26793541449375,
      },
      {
        lat: 13.192256612517212,
        lng: 80.26864549521942,
      },
      {
        lat: 13.192153416594811,
        lng: 80.26909333836763,
      },
      {
        lat: 13.191863431244977,
        lng: 80.26930613130641,
      },
      {
        lat: 13.189718664347819,
        lng: 80.26932428302486,
      },
      {
        lat: 13.188287220636493,
        lng: 80.2690734743073,
      },
      {
        lat: 13.187438945851396,
        lng: 80.26892289943875,
      },
      {
        lat: 13.187227916578454,
        lng: 80.2690561471326,
      },
      {
        lat: 13.187099361431507,
        lng: 80.26968825128041,
      },
      {
        lat: 13.186786575438106,
        lng: 80.27050596099946,
      },
      {
        lat: 13.186605862640358,
        lng: 80.27126996826925,
      },
      {
        lat: 13.186429307536658,
        lng: 80.27271754118296,
      },
      {
        lat: 13.186272035027708,
        lng: 80.27298179561791,
      },
      {
        lat: 13.186060366109531,
        lng: 80.27300987936655,
      },
      {
        lat: 13.184360298490404,
        lng: 80.27213032793065,
      },
      {
        lat: 13.184095192631133,
        lng: 80.27207998691094,
      },
      {
        lat: 13.183830886328293,
        lng: 80.27216110082271,
      },
      {
        lat: 13.183726730938162,
        lng: 80.27245119805336,
      },
      {
        lat: 13.18351921971398,
        lng: 80.27316284744583,
      },
      {
        lat: 13.183017725357448,
        lng: 80.27343001511933,
      },
      {
        lat: 13.182833494510932,
        lng: 80.27361562069048,
      },
      {
        lat: 13.182837492293622,
        lng: 80.27427289534822,
      },
      {
        lat: 13.182796048700252,
        lng: 80.27616629455287,
      },
      {
        lat: 13.182730365261724,
        lng: 80.27842799166098,
      },
      {
        lat: 13.182890995908302,
        lng: 80.27871584793854,
      },
      {
        lat: 13.183745027500473,
        lng: 80.27981289831412,
      },
      {
        lat: 13.183746626613548,
        lng: 80.28007580817716,
      },
      {
        lat: 13.18364407033633,
        lng: 80.28062881527084,
      },
      {
        lat: 13.182811946593297,
        lng: 80.28313363001905,
      },
      {
        lat: 13.182001338465065,
        lng: 80.28616411885187,
      },
    ],
    properties: {
      Ward_No: 18,
      Zone_Name: "MANALI",
      fillColor: "#de4307",
      zname: "MANALI(18)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.21521712936499,
        lng: 80.27419350266229,
      },
      {
        lat: 13.211878014314344,
        lng: 80.28795518561854,
      },
      {
        lat: 13.217789494513047,
        lng: 80.29290488523817,
      },
      {
        lat: 13.201448025893132,
        lng: 80.29856632982793,
      },
      {
        lat: 13.203120332146685,
        lng: 80.30303554694478,
      },
      {
        lat: 13.212408065787677,
        lng: 80.27348998488031,
      },
      {
        lat: 13.226272081236273,
        lng: 80.28366491612542,
      },
      {
        lat: 13.213147804980338,
        lng: 80.28819849643476,
      },
      {
        lat: 13.216983103348264,
        lng: 80.28247440346772,
      },
      {
        lat: 13.213121688119559,
        lng: 80.28445573644936,
      },
      {
        lat: 13.210715693992329,
        lng: 80.28384087333201,
      },
      {
        lat: 13.210978627334624,
        lng: 80.28669388093562,
      },
      {
        lat: 13.21113720845625,
        lng: 80.27967923208548,
      },
      {
        lat: 13.20971078043411,
        lng: 80.27965946713186,
      },
      {
        lat: 13.216968823622283,
        lng: 80.28384367706239,
      },
      {
        lat: 13.213320153439177,
        lng: 80.30908539591104,
      },
      {
        lat: 13.21693392140342,
        lng: 80.2795409610021,
      },
      {
        lat: 13.222849790832836,
        lng: 80.28567057394446,
      },
      {
        lat: 13.213291599268079,
        lng: 80.27502833162023,
      },
      {
        lat: 13.199379021418771,
        lng: 80.3033874217636,
      },
      {
        lat: 13.213685117712846,
        lng: 80.29087571770367,
      },
      {
        lat: 13.222139948252325,
        lng: 80.28149085855756,
      },
      {
        lat: 13.224549499868338,
        lng: 80.27557207028046,
      },
      {
        lat: 13.212457268130798,
        lng: 80.28342309249776,
      },
      {
        lat: 13.22367446474612,
        lng: 80.2671830903583,
      },
      {
        lat: 13.206682761657463,
        lng: 80.29564164579762,
      },
      {
        lat: 13.21017722267347,
        lng: 80.30011457563856,
      },
      {
        lat: 13.214581224088965,
        lng: 80.2732520980049,
      },
      {
        lat: 13.206450051598399,
        lng: 80.30296379106687,
      },
      {
        lat: 13.219361429885383,
        lng: 80.27409400001892,
      },
      {
        lat: 13.212428879210398,
        lng: 80.27436047065949,
      },
      {
        lat: 13.207336651028644,
        lng: 80.30552060050722,
      },
      {
        lat: 13.215928189805712,
        lng: 80.28922860515432,
      },
      {
        lat: 13.202272846768698,
        lng: 80.29889827924957,
      },
      {
        lat: 13.202272394469448,
        lng: 80.29328731150068,
      },
      {
        lat: 13.206551599621616,
        lng: 80.29311878911493,
      },
      {
        lat: 13.206723784649093,
        lng: 80.29836654954846,
      },
      {
        lat: 13.221959932467074,
        lng: 80.2828880358049,
      },
      {
        lat: 13.207627690579939,
        lng: 80.28968761127999,
      },
      {
        lat: 13.206208821324495,
        lng: 80.28975427986987,
      },
      {
        lat: 13.218957579076474,
        lng: 80.28217055848903,
      },
      {
        lat: 13.205034757678177,
        lng: 80.29377493766604,
      },
      {
        lat: 13.20954855729096,
        lng: 80.29840497934313,
      },
      {
        lat: 13.215926535228402,
        lng: 80.29196672659978,
      },
      {
        lat: 13.204807834819798,
        lng: 80.30057809274703,
      },
      {
        lat: 13.205819793340428,
        lng: 80.28356603449011,
      },
      {
        lat: 13.210410309954971,
        lng: 80.29020310285638,
      },
      {
        lat: 13.219234179610455,
        lng: 80.29117053611627,
      },
      {
        lat: 13.209749671624559,
        lng: 80.27525614978236,
      },
      {
        lat: 13.221379748557087,
        lng: 80.28797658796844,
      },
      {
        lat: 13.221738784214782,
        lng: 80.28214262249833,
      },
      {
        lat: 13.222632549180288,
        lng: 80.27240836377956,
      },
      {
        lat: 13.204680491587625,
        lng: 80.29788492979047,
      },
      {
        lat: 13.218357704111304,
        lng: 80.2716415733185,
      },
      {
        lat: 13.201888779373325,
        lng: 80.29993374833302,
      },
      {
        lat: 13.223007140291509,
        lng: 80.28248502433027,
      },
      {
        lat: 13.214839820581851,
        lng: 80.29569577276037,
      },
      {
        lat: 13.21006869265505,
        lng: 80.28465268083588,
      },
      {
        lat: 13.214778455495283,
        lng: 80.30932819126366,
      },
      {
        lat: 13.204020494162755,
        lng: 80.2903795425536,
      },
      {
        lat: 13.214869964073921,
        lng: 80.27512977639219,
      },
      {
        lat: 13.2101661905713,
        lng: 80.3028455967142,
      },
      {
        lat: 13.213502888851096,
        lng: 80.282076680863,
      },
      {
        lat: 13.214599822568832,
        lng: 80.28219722721177,
      },
      {
        lat: 13.223532055850729,
        lng: 80.27723811184413,
      },
      {
        lat: 13.21876233009752,
        lng: 80.29034803585704,
      },
      {
        lat: 13.218623946120218,
        lng: 80.29559485002065,
      },
      {
        lat: 13.20978251197336,
        lng: 80.29737911763756,
      },
      {
        lat: 13.214211056049411,
        lng: 80.27393951212724,
      },
      {
        lat: 13.211676257752565,
        lng: 80.29374708946939,
      },
      {
        lat: 13.202219195101057,
        lng: 80.30081867456236,
      },
      {
        lat: 13.202918029534219,
        lng: 80.28499092463558,
      },
      {
        lat: 13.208527466859215,
        lng: 80.29162552339479,
      },
      {
        lat: 13.21986808462035,
        lng: 80.27598443164041,
      },
      {
        lat: 13.20447659792353,
        lng: 80.29505744655005,
      },
      {
        lat: 13.210723264525521,
        lng: 80.30012228703265,
      },
      {
        lat: 13.224195979658731,
        lng: 80.26755703525382,
      },
      {
        lat: 13.20601121719073,
        lng: 80.27318353386165,
      },
      {
        lat: 13.203697144434468,
        lng: 80.29766610090167,
      },
      {
        lat: 13.208446297323448,
        lng: 80.29878707696591,
      },
      {
        lat: 13.208220723230008,
        lng: 80.27525083972742,
      },
      {
        lat: 13.200319640283448,
        lng: 80.30241539179393,
      },
      {
        lat: 13.211716212973228,
        lng: 80.29825865153411,
      },
      {
        lat: 13.210413725194059,
        lng: 80.27503355014396,
      },
      {
        lat: 13.214880435815806,
        lng: 80.27422978664855,
      },
      {
        lat: 13.208722547633107,
        lng: 80.29332015292461,
      },
      {
        lat: 13.222978676996966,
        lng: 80.28232268315969,
      },
      {
        lat: 13.214939109360426,
        lng: 80.28154084657884,
      },
      {
        lat: 13.21755513847091,
        lng: 80.29209506281747,
      },
      {
        lat: 13.219892095341288,
        lng: 80.27985198693987,
      },
      {
        lat: 13.223731791327362,
        lng: 80.26839093550515,
      },
      {
        lat: 13.217042028730654,
        lng: 80.29496531901998,
      },
      {
        lat: 13.207330290897755,
        lng: 80.27925216703336,
      },
      {
        lat: 13.207275006304828,
        lng: 80.28131769234332,
      },
      {
        lat: 13.20482477690729,
        lng: 80.28702425812892,
      },
      {
        lat: 13.214924763519276,
        lng: 80.28192527254366,
      },
      {
        lat: 13.221691008163413,
        lng: 80.28139508726012,
      },
      {
        lat: 13.208797930063188,
        lng: 80.29908227460184,
      },
      {
        lat: 13.206087153179642,
        lng: 80.28535873762783,
      },
      {
        lat: 13.208648164678047,
        lng: 80.30785080195234,
      },
      {
        lat: 13.213786904523488,
        lng: 80.28212775334381,
      },
      {
        lat: 13.204567568250983,
        lng: 80.28869233152771,
      },
      {
        lat: 13.212144582908625,
        lng: 80.29860997761142,
      },
      {
        lat: 13.216276683051397,
        lng: 80.29417759304334,
      },
      {
        lat: 13.219687732073744,
        lng: 80.27205445607845,
      },
      {
        lat: 13.202783620077943,
        lng: 80.29105221359396,
      },
      {
        lat: 13.214273864728291,
        lng: 80.29027737507657,
      },
      {
        lat: 13.201681193379285,
        lng: 80.30450181811014,
      },
      {
        lat: 13.206574636837793,
        lng: 80.2791910681154,
      },
      {
        lat: 13.216534679826394,
        lng: 80.29329127541575,
      },
      {
        lat: 13.21219021509328,
        lng: 80.29014399216575,
      },
      {
        lat: 13.208249584417716,
        lng: 80.27618710250921,
      },
      {
        lat: 13.216369099801248,
        lng: 80.28098803284145,
      },
      {
        lat: 13.211540214928803,
        lng: 80.3074508435884,
      },
      {
        lat: 13.222646177882538,
        lng: 80.27886097133921,
      },
      {
        lat: 13.209456400999064,
        lng: 80.30060044683079,
      },
      {
        lat: 13.216117302619654,
        lng: 80.27242139123204,
      },
      {
        lat: 13.223527121415371,
        lng: 80.2695103616443,
      },
      {
        lat: 13.21454840220638,
        lng: 80.28415269060405,
      },
      {
        lat: 13.204439911832553,
        lng: 80.29792715759855,
      },
      {
        lat: 13.210301841835399,
        lng: 80.28304151221097,
      },
      {
        lat: 13.223706894592084,
        lng: 80.27596562483834,
      },
      {
        lat: 13.20269949687234,
        lng: 80.30559709951407,
      },
      {
        lat: 13.217560450430177,
        lng: 80.27888086815825,
      },
      {
        lat: 13.209494204640379,
        lng: 80.30394115192868,
      },
      {
        lat: 13.214818199000462,
        lng: 80.27915969010031,
      },
      {
        lat: 13.22161228755699,
        lng: 80.27457186293167,
      },
      {
        lat: 13.214454281114357,
        lng: 80.2925431572534,
      },
      {
        lat: 13.217609777354134,
        lng: 80.28882129823866,
      },
      {
        lat: 13.216479761895862,
        lng: 80.2929345499651,
      },
      {
        lat: 13.219825719223257,
        lng: 80.27154519962762,
      },
      {
        lat: 13.2093054372594,
        lng: 80.29092245821688,
      },
      {
        lat: 13.220544049004058,
        lng: 80.27510443420465,
      },
      {
        lat: 13.214568085895676,
        lng: 80.28606910241041,
      },
    ],
    cases: 134,
    coords: [
      {
        lat: 13.19797190086369,
        lng: 80.30418127872886,
      },
      {
        lat: 13.19793310189945,
        lng: 80.30368805906156,
      },
      {
        lat: 13.198453223215951,
        lng: 80.30173582376406,
      },
      {
        lat: 13.201302746415204,
        lng: 80.28916634000461,
      },
      {
        lat: 13.2023067291366,
        lng: 80.28476696441139,
      },
      {
        lat: 13.203363062980575,
        lng: 80.28093332159952,
      },
      {
        lat: 13.204372139429527,
        lng: 80.27716053567654,
      },
      {
        lat: 13.204447860164015,
        lng: 80.2757587588781,
      },
      {
        lat: 13.204329329188834,
        lng: 80.27358486443559,
      },
      {
        lat: 13.204150668906506,
        lng: 80.27191337832956,
      },
      {
        lat: 13.203743394292063,
        lng: 80.27074572649231,
      },
      {
        lat: 13.203619440321107,
        lng: 80.27004366725002,
      },
      {
        lat: 13.204718351178364,
        lng: 80.27020394677015,
      },
      {
        lat: 13.205256123642753,
        lng: 80.27036897534985,
      },
      {
        lat: 13.205596949433195,
        lng: 80.27024496236176,
      },
      {
        lat: 13.205791096611422,
        lng: 80.27007373935618,
      },
      {
        lat: 13.20605976183081,
        lng: 80.27011991696918,
      },
      {
        lat: 13.20879638740279,
        lng: 80.27077507574774,
      },
      {
        lat: 13.208993039393011,
        lng: 80.27101566841314,
      },
      {
        lat: 13.20918939669962,
        lng: 80.27120781217612,
      },
      {
        lat: 13.21160782569989,
        lng: 80.2716960840472,
      },
      {
        lat: 13.212242892766291,
        lng: 80.27181183780117,
      },
      {
        lat: 13.212388982010877,
        lng: 80.2717621500135,
      },
      {
        lat: 13.212534776571914,
        lng: 80.27166401332336,
      },
      {
        lat: 13.212803147107516,
        lng: 80.27166174203391,
      },
      {
        lat: 13.21612545573563,
        lng: 80.27233616972312,
      },
      {
        lat: 13.216540357541556,
        lng: 80.27235688399975,
      },
      {
        lat: 13.217149259183817,
        lng: 80.27218215081957,
      },
      {
        lat: 13.220262244418743,
        lng: 80.27053268303693,
      },
      {
        lat: 13.220480936260294,
        lng: 80.2703854780019,
      },
      {
        lat: 13.22055324417324,
        lng: 80.27023951185183,
      },
      {
        lat: 13.220551918096211,
        lng: 80.27002149179073,
      },
      {
        lat: 13.220599239320158,
        lng: 80.2697788343167,
      },
      {
        lat: 13.221641841097865,
        lng: 80.26870407978535,
      },
      {
        lat: 13.22188507760257,
        lng: 80.2685808927205,
      },
      {
        lat: 13.22224912197931,
        lng: 80.2682628776416,
      },
      {
        lat: 13.222513514283762,
        lng: 80.26760654616896,
      },
      {
        lat: 13.222852424629604,
        lng: 80.26716761531465,
      },
      {
        lat: 13.223387692282055,
        lng: 80.26692082822343,
      },
      {
        lat: 13.223728812756109,
        lng: 80.26684526413766,
      },
      {
        lat: 13.224170027328238,
        lng: 80.26718068979956,
      },
      {
        lat: 13.224247050181742,
        lng: 80.26780990608913,
      },
      {
        lat: 13.224518220213294,
        lng: 80.26826789937303,
      },
      {
        lat: 13.224715756254874,
        lng: 80.26865383874599,
      },
      {
        lat: 13.224748994089845,
        lng: 80.27010709933938,
      },
      {
        lat: 13.225145834275821,
        lng: 80.27116967149992,
      },
      {
        lat: 13.226426429682974,
        lng: 80.27312111504547,
      },
      {
        lat: 13.22669656832129,
        lng: 80.27340953717079,
      },
      {
        lat: 13.226722733745426,
        lng: 80.27370002410476,
      },
      {
        lat: 13.226483033446135,
        lng: 80.27440459799921,
      },
      {
        lat: 13.226343427244577,
        lng: 80.27552016164115,
      },
      {
        lat: 13.226470128792302,
        lng: 80.27629431167647,
      },
      {
        lat: 13.226453245907377,
        lng: 80.27752996517032,
      },
      {
        lat: 13.226678420792018,
        lng: 80.27844863598953,
      },
      {
        lat: 13.226977819034206,
        lng: 80.27953625852476,
      },
      {
        lat: 13.226956368550622,
        lng: 80.28002095403043,
      },
      {
        lat: 13.22698268131665,
        lng: 80.28033566541566,
      },
      {
        lat: 13.227180217358399,
        lng: 80.28072160478835,
      },
      {
        lat: 13.227206530124436,
        lng: 80.28103631617356,
      },
      {
        lat: 13.227160240293736,
        lng: 80.28144854480637,
      },
      {
        lat: 13.227239915301128,
        lng: 80.28251380121823,
      },
      {
        lat: 13.227367500900208,
        lng: 80.28343329796088,
      },
      {
        lat: 13.227394845059488,
        lng: 80.28391758050464,
      },
      {
        lat: 13.227153081973702,
        lng: 80.28428301208179,
      },
      {
        lat: 13.22666867175061,
        lng: 80.28486852852862,
      },
      {
        lat: 13.226549042613891,
        lng: 80.28525715215271,
      },
      {
        lat: 13.226306690160538,
        lng: 80.28552568592487,
      },
      {
        lat: 13.225843583079142,
        lng: 80.28560228241491,
      },
      {
        lat: 13.225333449457585,
        lng: 80.2859699852815,
      },
      {
        lat: 13.224747913743572,
        lng: 80.28597494082206,
      },
      {
        lat: 13.224480869285003,
        lng: 80.28619523217259,
      },
      {
        lat: 13.224143137674298,
        lng: 80.28682795863672,
      },
      {
        lat: 13.222661384110951,
        lng: 80.28790642982351,
      },
      {
        lat: 13.221349527746169,
        lng: 80.2888381089368,
      },
      {
        lat: 13.221106733267138,
        lng: 80.2890339693552,
      },
      {
        lat: 13.221035898773115,
        lng: 80.28942218001758,
      },
      {
        lat: 13.220779757614574,
        lng: 80.29143508075968,
      },
      {
        lat: 13.22041969146893,
        lng: 80.29240715602178,
      },
      {
        lat: 13.22037826392068,
        lng: 80.29361879154523,
      },
      {
        lat: 13.220137237544355,
        lng: 80.29410534537865,
      },
      {
        lat: 13.219265859042249,
        lng: 80.29525132789755,
      },
      {
        lat: 13.217521923302828,
        lng: 80.2973494973257,
      },
      {
        lat: 13.217182276247526,
        lng: 80.29766730592377,
      },
      {
        lat: 13.216402446013333,
        lng: 80.29781926001857,
      },
      {
        lat: 13.214890843478594,
        lng: 80.2980016329905,
      },
      {
        lat: 13.213867482055912,
        lng: 80.29822832524765,
      },
      {
        lat: 13.213405406367936,
        lng: 80.29847449289618,
      },
      {
        lat: 13.213092219420561,
        lng: 80.29913123733074,
      },
      {
        lat: 13.21287735846821,
        lng: 80.29990827809794,
      },
      {
        lat: 13.212586506055775,
        lng: 80.30022567373433,
      },
      {
        lat: 13.21188119386315,
        lng: 80.30059502844776,
      },
      {
        lat: 13.211150452955614,
        lng: 80.30079501848351,
      },
      {
        lat: 13.211005542446099,
        lng: 80.30103850188092,
      },
      {
        lat: 13.211084038718356,
        lng: 80.30190996268283,
      },
      {
        lat: 13.211539987479714,
        lng: 80.3046678334682,
      },
      {
        lat: 13.211642586389791,
        lng: 80.30549063888691,
      },
      {
        lat: 13.211694917237903,
        lng: 80.3060716127548,
      },
      {
        lat: 13.212063087187625,
        lng: 80.30643188231048,
      },
      {
        lat: 13.215471995892011,
        lng: 80.30931011561937,
      },
      {
        lat: 13.220207678128837,
        lng: 80.31306708214908,
      },
      {
        lat: 13.21875645999418,
        lng: 80.31238430643326,
      },
      {
        lat: 13.216107865728443,
        lng: 80.31087330676962,
      },
      {
        lat: 13.21083668159247,
        lng: 80.30808999786855,
      },
      {
        lat: 13.20718253679052,
        lng: 80.30877914667182,
      },
      {
        lat: 13.203070009860499,
        lng: 80.306766147699,
      },
      {
        lat: 13.199769307807069,
        lng: 80.30501444289763,
      },
      {
        lat: 13.19797190086369,
        lng: 80.30418127872886,
      },
    ],
    properties: {
      Ward_No: 15,
      Zone_Name: "MANALI",
      fillColor: "#de4307",
      zname: "MANALI(15)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.226217615354166,
        lng: 80.31733881494895,
      },
      {
        lat: 13.224318533599352,
        lng: 80.32688104533052,
      },
      {
        lat: 13.224188917978754,
        lng: 80.32554104631204,
      },
      {
        lat: 13.224975497494226,
        lng: 80.32557704819239,
      },
      {
        lat: 13.231775432533876,
        lng: 80.32754204517866,
      },
      {
        lat: 13.228942330177315,
        lng: 80.32640333918242,
      },
      {
        lat: 13.230112146569653,
        lng: 80.31983698749896,
      },
      {
        lat: 13.233544709819904,
        lng: 80.32649763051923,
      },
      {
        lat: 13.220434442092682,
        lng: 80.32431243455416,
      },
      {
        lat: 13.23180137421455,
        lng: 80.32370917205611,
      },
      {
        lat: 13.227035175345199,
        lng: 80.3227121413736,
      },
      {
        lat: 13.219537129490146,
        lng: 80.32561025099204,
      },
      {
        lat: 13.226739246212729,
        lng: 80.32504165612356,
      },
      {
        lat: 13.221673330962144,
        lng: 80.32419635314344,
      },
      {
        lat: 13.231417435882777,
        lng: 80.32415869389504,
      },
      {
        lat: 13.224508283019734,
        lng: 80.31859266554477,
      },
      {
        lat: 13.222363838355065,
        lng: 80.32213179943959,
      },
      {
        lat: 13.223847162404125,
        lng: 80.32577923708516,
      },
      {
        lat: 13.223701377390514,
        lng: 80.32480165075539,
      },
      {
        lat: 13.228312430324532,
        lng: 80.32571617625248,
      },
      {
        lat: 13.227302955049785,
        lng: 80.32117524035742,
      },
      {
        lat: 13.23016246660783,
        lng: 80.32998236915796,
      },
      {
        lat: 13.229004439449577,
        lng: 80.32623452620511,
      },
      {
        lat: 13.224319523787628,
        lng: 80.32300714856476,
      },
      {
        lat: 13.227532777331833,
        lng: 80.32914010945441,
      },
      {
        lat: 13.22219959901567,
        lng: 80.32562353956922,
      },
      {
        lat: 13.228536609205582,
        lng: 80.32139602738458,
      },
      {
        lat: 13.2327594512745,
        lng: 80.32419488811303,
      },
      {
        lat: 13.228271907789132,
        lng: 80.31918337642901,
      },
      {
        lat: 13.227229329938769,
        lng: 80.32670410312721,
      },
      {
        lat: 13.230735456646979,
        lng: 80.32923986787165,
      },
      {
        lat: 13.232892713564631,
        lng: 80.32794524574567,
      },
      {
        lat: 13.228476166872477,
        lng: 80.32204617672065,
      },
      {
        lat: 13.22303457974555,
        lng: 80.32635073610636,
      },
      {
        lat: 13.22878181409899,
        lng: 80.3291784504922,
      },
      {
        lat: 13.226419239650884,
        lng: 80.32211473073734,
      },
      {
        lat: 13.220934228354615,
        lng: 80.32698868322433,
      },
      {
        lat: 13.223314668801148,
        lng: 80.32573457572947,
      },
      {
        lat: 13.23152455291558,
        lng: 80.32605320849554,
      },
      {
        lat: 13.223901754487702,
        lng: 80.32783351929031,
      },
      {
        lat: 13.2245305545191,
        lng: 80.32562401242475,
      },
      {
        lat: 13.221382518530486,
        lng: 80.32605671559729,
      },
      {
        lat: 13.230273836498448,
        lng: 80.32830589285619,
      },
      {
        lat: 13.22455040072601,
        lng: 80.32645950281022,
      },
      {
        lat: 13.222142877682725,
        lng: 80.32211370707398,
      },
      {
        lat: 13.22551727558737,
        lng: 80.32634052936451,
      },
      {
        lat: 13.230868460093436,
        lng: 80.3243665071256,
      },
      {
        lat: 13.229664230337006,
        lng: 80.3227799111412,
      },
      {
        lat: 13.226471428683288,
        lng: 80.32794496676038,
      },
      {
        lat: 13.227672100964453,
        lng: 80.3282705599915,
      },
      {
        lat: 13.22294475996176,
        lng: 80.32166446628405,
      },
      {
        lat: 13.225153961686747,
        lng: 80.32260417403512,
      },
      {
        lat: 13.22902118510552,
        lng: 80.3266684054836,
      },
      {
        lat: 13.226464157098297,
        lng: 80.32324761827361,
      },
      {
        lat: 13.224175882386536,
        lng: 80.32246095732368,
      },
      {
        lat: 13.222771469385531,
        lng: 80.32361363266891,
      },
      {
        lat: 13.227251520765263,
        lng: 80.32627305596017,
      },
      {
        lat: 13.232757250294613,
        lng: 80.32903739607235,
      },
      {
        lat: 13.221999171215229,
        lng: 80.32340087300138,
      },
      {
        lat: 13.220469085020392,
        lng: 80.32485866390051,
      },
      {
        lat: 13.226310679602296,
        lng: 80.31944017690098,
      },
      {
        lat: 13.226210098089917,
        lng: 80.32732333005367,
      },
      {
        lat: 13.22787746967503,
        lng: 80.31746210874557,
      },
      {
        lat: 13.231975454189453,
        lng: 80.3235075433181,
      },
      {
        lat: 13.23026645218121,
        lng: 80.32193825121198,
      },
      {
        lat: 13.228057277401659,
        lng: 80.32243274787433,
      },
      {
        lat: 13.221230334617344,
        lng: 80.32419562966926,
      },
      {
        lat: 13.22384373206681,
        lng: 80.32551870057665,
      },
      {
        lat: 13.227159893929997,
        lng: 80.32900488780182,
      },
      {
        lat: 13.233217698465195,
        lng: 80.32775882685712,
      },
      {
        lat: 13.225621491101188,
        lng: 80.32792700996708,
      },
      {
        lat: 13.229042652686068,
        lng: 80.32508350418959,
      },
      {
        lat: 13.226628533998879,
        lng: 80.317595113213,
      },
      {
        lat: 13.220757950678557,
        lng: 80.32556112009698,
      },
      {
        lat: 13.234464093236527,
        lng: 80.32825594720615,
      },
      {
        lat: 13.221768372737436,
        lng: 80.32591558055074,
      },
      {
        lat: 13.218411188996434,
        lng: 80.32674741807145,
      },
      {
        lat: 13.225630143067747,
        lng: 80.3214893382074,
      },
      {
        lat: 13.229025647106024,
        lng: 80.32295822586369,
      },
      {
        lat: 13.229867665225758,
        lng: 80.32811921142827,
      },
      {
        lat: 13.223422506921603,
        lng: 80.32035032971258,
      },
      {
        lat: 13.228050189834356,
        lng: 80.31687813938095,
      },
      {
        lat: 13.230716953377211,
        lng: 80.31781769560594,
      },
      {
        lat: 13.224690344139056,
        lng: 80.31987442108532,
      },
      {
        lat: 13.231396831052304,
        lng: 80.32921375242054,
      },
      {
        lat: 13.2186265755955,
        lng: 80.32419988578143,
      },
      {
        lat: 13.230071859389245,
        lng: 80.32595736790593,
      },
      {
        lat: 13.22405829303938,
        lng: 80.32222841147968,
      },
      {
        lat: 13.227870004923219,
        lng: 80.32122142114352,
      },
      {
        lat: 13.226761759167141,
        lng: 80.3218722455506,
      },
      {
        lat: 13.22542102822004,
        lng: 80.31803355491378,
      },
      {
        lat: 13.218231797439078,
        lng: 80.32443903544068,
      },
      {
        lat: 13.229358590916108,
        lng: 80.32413531607759,
      },
      {
        lat: 13.231314692636916,
        lng: 80.32703176889675,
      },
      {
        lat: 13.22336314495687,
        lng: 80.32116846729467,
      },
      {
        lat: 13.22847890196403,
        lng: 80.3210928942302,
      },
      {
        lat: 13.227195644344798,
        lng: 80.32402368759386,
      },
      {
        lat: 13.227750692438413,
        lng: 80.32822417297893,
      },
      {
        lat: 13.225447248328061,
        lng: 80.327997266666,
      },
      {
        lat: 13.229741574764999,
        lng: 80.32641557230083,
      },
      {
        lat: 13.218711521409862,
        lng: 80.32461722563633,
      },
      {
        lat: 13.22777801160656,
        lng: 80.32700832006887,
      },
      {
        lat: 13.222807892524633,
        lng: 80.32001261947998,
      },
      {
        lat: 13.229218881043833,
        lng: 80.32718681704479,
      },
      {
        lat: 13.230219291559225,
        lng: 80.32983753527856,
      },
      {
        lat: 13.23096222584609,
        lng: 80.31792576327666,
      },
      {
        lat: 13.219173212619614,
        lng: 80.3257326188734,
      },
      {
        lat: 13.229640120700939,
        lng: 80.32551875858177,
      },
      {
        lat: 13.231363627978197,
        lng: 80.3273828633536,
      },
      {
        lat: 13.23303889140686,
        lng: 80.3247519523014,
      },
      {
        lat: 13.224068275107388,
        lng: 80.3200285871482,
      },
    ],
    cases: 111,
    coords: [
      {
        lat: 13.217993005704125,
        lng: 80.32679529343422,
      },
      {
        lat: 13.21806226393093,
        lng: 80.32476521297215,
      },
      {
        lat: 13.218202895628819,
        lng: 80.32343500431075,
      },
      {
        lat: 13.218392432488065,
        lng: 80.3231591583069,
      },
      {
        lat: 13.219071373690815,
        lng: 80.32300574353377,
      },
      {
        lat: 13.219858207742076,
        lng: 80.3231256575397,
      },
      {
        lat: 13.220857876496456,
        lng: 80.32330705686358,
      },
      {
        lat: 13.221195871601154,
        lng: 80.322987763357,
      },
      {
        lat: 13.221555753205246,
        lng: 80.32277376227458,
      },
      {
        lat: 13.221715320409178,
        lng: 80.32106367379738,
      },
      {
        lat: 13.221859929532979,
        lng: 80.32038739293802,
      },
      {
        lat: 13.222598499943187,
        lng: 80.31955841651236,
      },
      {
        lat: 13.222661208447892,
        lng: 80.31938912154536,
      },
      {
        lat: 13.222554598640235,
        lng: 80.31932673721843,
      },
      {
        lat: 13.222383740678906,
        lng: 80.31918051451598,
      },
      {
        lat: 13.222446705791969,
        lng: 80.31905340843953,
      },
      {
        lat: 13.226635838046025,
        lng: 80.31609143209602,
      },
      {
        lat: 13.229676927390786,
        lng: 80.31754547700086,
      },
      {
        lat: 13.231174706782754,
        lng: 80.31755717952865,
      },
      {
        lat: 13.232487109483865,
        lng: 80.32342132053277,
      },
      {
        lat: 13.232760584367961,
        lng: 80.32397971437916,
      },
      {
        lat: 13.235185445234668,
        lng: 80.32705527730822,
      },
      {
        lat: 13.234007191494891,
        lng: 80.33113647926163,
      },
      {
        lat: 13.233761233380646,
        lng: 80.33106542499901,
      },
      {
        lat: 13.23334282286954,
        lng: 80.33089831575279,
      },
      {
        lat: 13.23309716129905,
        lng: 80.33087601622707,
      },
      {
        lat: 13.232362697211808,
        lng: 80.33122353291297,
      },
      {
        lat: 13.229877082018577,
        lng: 80.3302694243881,
      },
      {
        lat: 13.226703881318134,
        lng: 80.32929675645435,
      },
      {
        lat: 13.225498493804189,
        lng: 80.32891689998917,
      },
      {
        lat: 13.221759722885492,
        lng: 80.32780274688328,
      },
      {
        lat: 13.219054035355756,
        lng: 80.32699677262723,
      },
      {
        lat: 13.217993005704125,
        lng: 80.32679529343422,
      },
    ],
    properties: {
      Ward_No: 1,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#f6d04d",
      zname: "THIRUVOTTIYUR(1)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.209552360342682,
        lng: 80.31017351470209,
      },
      {
        lat: 13.215699374907409,
        lng: 80.31436359638504,
      },
      {
        lat: 13.215151568402005,
        lng: 80.3148491963803,
      },
      {
        lat: 13.213648318120246,
        lng: 80.3202893864291,
      },
      {
        lat: 13.206235750818728,
        lng: 80.31808977933397,
      },
      {
        lat: 13.211573832216132,
        lng: 80.30927772176582,
      },
      {
        lat: 13.208544917967568,
        lng: 80.30870815921,
      },
      {
        lat: 13.213053523602438,
        lng: 80.32386116879668,
      },
      {
        lat: 13.212391236021423,
        lng: 80.31301668809628,
      },
      {
        lat: 13.219034328014397,
        lng: 80.31671192831402,
      },
      {
        lat: 13.219306679436855,
        lng: 80.31788960745375,
      },
      {
        lat: 13.209223586370785,
        lng: 80.32230433921308,
      },
    ],
    cases: 12,
    coords: [
      {
        lat: 13.204650206855025,
        lng: 80.32273244980871,
      },
      {
        lat: 13.204948447877777,
        lng: 80.32170398792262,
      },
      {
        lat: 13.205261615945611,
        lng: 80.32028343112754,
      },
      {
        lat: 13.205868933245885,
        lng: 80.31762221310909,
      },
      {
        lat: 13.20746399519889,
        lng: 80.3120169702451,
      },
      {
        lat: 13.207538370077868,
        lng: 80.31101781517661,
      },
      {
        lat: 13.20718253679052,
        lng: 80.30877914667182,
      },
      {
        lat: 13.21083668159247,
        lng: 80.30808999786855,
      },
      {
        lat: 13.216107865728443,
        lng: 80.31087330676962,
      },
      {
        lat: 13.21875645999418,
        lng: 80.31238430643326,
      },
      {
        lat: 13.220207678128837,
        lng: 80.31306708214908,
      },
      {
        lat: 13.226635838046025,
        lng: 80.31609143209602,
      },
      {
        lat: 13.222446705791969,
        lng: 80.31905340843953,
      },
      {
        lat: 13.222383740678906,
        lng: 80.31918051451598,
      },
      {
        lat: 13.222554598640235,
        lng: 80.31932673721843,
      },
      {
        lat: 13.222661208447892,
        lng: 80.31938912154536,
      },
      {
        lat: 13.222598499943187,
        lng: 80.31955841651236,
      },
      {
        lat: 13.221859929532979,
        lng: 80.32038739293802,
      },
      {
        lat: 13.221715320409178,
        lng: 80.32106367379738,
      },
      {
        lat: 13.221555753205246,
        lng: 80.32277376227458,
      },
      {
        lat: 13.221195871601154,
        lng: 80.322987763357,
      },
      {
        lat: 13.220857876496456,
        lng: 80.32330705686358,
      },
      {
        lat: 13.219858207742076,
        lng: 80.3231256575397,
      },
      {
        lat: 13.219071373690815,
        lng: 80.32300574353377,
      },
      {
        lat: 13.218392432488065,
        lng: 80.3231591583069,
      },
      {
        lat: 13.218202895628819,
        lng: 80.32343500431075,
      },
      {
        lat: 13.21806226393093,
        lng: 80.32476521297215,
      },
      {
        lat: 13.217993005704125,
        lng: 80.32679529343422,
      },
      {
        lat: 13.216964651695697,
        lng: 80.32660001902744,
      },
      {
        lat: 13.21470148119104,
        lng: 80.32588781412815,
      },
      {
        lat: 13.212856572925551,
        lng: 80.32531834110664,
      },
      {
        lat: 13.210887870106829,
        lng: 80.32457926542773,
      },
      {
        lat: 13.208846847746479,
        lng: 80.32406020941728,
      },
      {
        lat: 13.207617353718675,
        lng: 80.32375369284152,
      },
      {
        lat: 13.20557455209462,
        lng: 80.32294210841027,
      },
      {
        lat: 13.204650206855025,
        lng: 80.32273244980871,
      },
    ],
    properties: {
      Ward_No: 2,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#8bc24c",
      zname: "THIRUVOTTIYUR(2)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.199755577609803,
        lng: 80.32061736649341,
      },
      {
        lat: 13.196742449214602,
        lng: 80.31997592371624,
      },
      {
        lat: 13.193719400626355,
        lng: 80.31388543716824,
      },
      {
        lat: 13.197931524113574,
        lng: 80.31013926516533,
      },
      {
        lat: 13.197890810723765,
        lng: 80.3046424583549,
      },
      {
        lat: 13.20181541810573,
        lng: 80.31821962587537,
      },
      {
        lat: 13.194104559440328,
        lng: 80.31674329381195,
      },
      {
        lat: 13.204926048373892,
        lng: 80.31735523943004,
      },
      {
        lat: 13.195345954376055,
        lng: 80.31993530659142,
      },
      {
        lat: 13.19160962655532,
        lng: 80.31752979285513,
      },
      {
        lat: 13.195602589001053,
        lng: 80.30484802662714,
      },
      {
        lat: 13.195070666922474,
        lng: 80.3119970441033,
      },
      {
        lat: 13.202227130005259,
        lng: 80.31159401764242,
      },
      {
        lat: 13.189745442156372,
        lng: 80.31329274399663,
      },
      {
        lat: 13.19835528519495,
        lng: 80.31478063802273,
      },
      {
        lat: 13.197842176451697,
        lng: 80.31854466771387,
      },
      {
        lat: 13.191366355615493,
        lng: 80.31864322232242,
      },
      {
        lat: 13.20487277434984,
        lng: 80.3129562744408,
      },
      {
        lat: 13.199078661979263,
        lng: 80.30596998953465,
      },
      {
        lat: 13.1919657727659,
        lng: 80.31677972854006,
      },
      {
        lat: 13.194946786854713,
        lng: 80.31758649720794,
      },
      {
        lat: 13.200732936721765,
        lng: 80.3161460383759,
      },
      {
        lat: 13.1965804220538,
        lng: 80.30931365416842,
      },
      {
        lat: 13.193667747915462,
        lng: 80.31867964871155,
      },
      {
        lat: 13.19952436676807,
        lng: 80.31847094229661,
      },
      {
        lat: 13.194551724553573,
        lng: 80.30756486333857,
      },
      {
        lat: 13.199598014797411,
        lng: 80.31631727871337,
      },
      {
        lat: 13.197454767212209,
        lng: 80.31515039318641,
      },
      {
        lat: 13.203866174632465,
        lng: 80.31614324173191,
      },
      {
        lat: 13.19703671917644,
        lng: 80.31774583448903,
      },
      {
        lat: 13.201119807738266,
        lng: 80.30576807850315,
      },
      {
        lat: 13.197523795681546,
        lng: 80.30923060834256,
      },
      {
        lat: 13.205146131081758,
        lng: 80.31306932108336,
      },
      {
        lat: 13.201525694630975,
        lng: 80.32151818771949,
      },
      {
        lat: 13.20683033688154,
        lng: 80.31299175496679,
      },
      {
        lat: 13.196648515081005,
        lng: 80.31588731748711,
      },
      {
        lat: 13.20173282661413,
        lng: 80.32062488811525,
      },
      {
        lat: 13.193219301824772,
        lng: 80.3117794790401,
      },
      {
        lat: 13.201834398472792,
        lng: 80.31759189210844,
      },
      {
        lat: 13.205576194172265,
        lng: 80.3131578006911,
      },
      {
        lat: 13.193340819587503,
        lng: 80.31395275380407,
      },
      {
        lat: 13.196355068784566,
        lng: 80.31868274513855,
      },
      {
        lat: 13.202041544013872,
        lng: 80.30693553301981,
      },
      {
        lat: 13.20084346072198,
        lng: 80.30868227922392,
      },
      {
        lat: 13.189150120374931,
        lng: 80.31750893882561,
      },
      {
        lat: 13.205241340557098,
        lng: 80.31146797271855,
      },
      {
        lat: 13.204625403026338,
        lng: 80.31440738370496,
      },
      {
        lat: 13.197501637988326,
        lng: 80.31365144743737,
      },
      {
        lat: 13.193076616620205,
        lng: 80.31392934766316,
      },
      {
        lat: 13.19918702787954,
        lng: 80.3122252055308,
      },
      {
        lat: 13.190252023448759,
        lng: 80.31577925633309,
      },
      {
        lat: 13.198899460850125,
        lng: 80.3202505395517,
      },
      {
        lat: 13.204187530668344,
        lng: 80.31951404348888,
      },
      {
        lat: 13.196125226894468,
        lng: 80.30485931294056,
      },
      {
        lat: 13.206274482717724,
        lng: 80.31285538560965,
      },
      {
        lat: 13.201167671408848,
        lng: 80.32009340388474,
      },
      {
        lat: 13.199121240491609,
        lng: 80.31350133311568,
      },
      {
        lat: 13.191213141296505,
        lng: 80.3166315026201,
      },
      {
        lat: 13.199702142392564,
        lng: 80.32113536264254,
      },
      {
        lat: 13.203972902061498,
        lng: 80.31440633542063,
      },
      {
        lat: 13.200094643807896,
        lng: 80.31850309261459,
      },
      {
        lat: 13.196473914052806,
        lng: 80.30737127209497,
      },
      {
        lat: 13.195142601036816,
        lng: 80.31880334418166,
      },
      {
        lat: 13.192668526079972,
        lng: 80.31279144278399,
      },
      {
        lat: 13.195869502569538,
        lng: 80.30746222602096,
      },
      {
        lat: 13.205210015096204,
        lng: 80.30897167108634,
      },
      {
        lat: 13.190252820400259,
        lng: 80.31657198171271,
      },
      {
        lat: 13.199606717296426,
        lng: 80.31765945196375,
      },
      {
        lat: 13.188612521681515,
        lng: 80.31291126724098,
      },
      {
        lat: 13.189037936130182,
        lng: 80.31804970839411,
      },
      {
        lat: 13.203930287514645,
        lng: 80.3201922252037,
      },
      {
        lat: 13.19929393655653,
        lng: 80.31338455307026,
      },
      {
        lat: 13.195057599022272,
        lng: 80.31798033342218,
      },
      {
        lat: 13.20127242853317,
        lng: 80.31271041264175,
      },
      {
        lat: 13.190044466311639,
        lng: 80.31066787664574,
      },
      {
        lat: 13.204949816180127,
        lng: 80.31201604568722,
      },
      {
        lat: 13.204878692789089,
        lng: 80.30825376667394,
      },
      {
        lat: 13.197932053594206,
        lng: 80.31723026271371,
      },
      {
        lat: 13.200943535604654,
        lng: 80.30889733370431,
      },
    ],
    cases: 79,
    coords: [
      {
        lat: 13.188754753527922,
        lng: 80.31869630653847,
      },
      {
        lat: 13.189886492769087,
        lng: 80.31347494497015,
      },
      {
        lat: 13.186400418124316,
        lng: 80.31264036517629,
      },
      {
        lat: 13.18703146620994,
        lng: 80.30908268180099,
      },
      {
        lat: 13.192994215883001,
        lng: 80.311720476808,
      },
      {
        lat: 13.195437627826832,
        lng: 80.30330071787769,
      },
      {
        lat: 13.197061574123813,
        lng: 80.30387206095753,
      },
      {
        lat: 13.197729797821129,
        lng: 80.30474403604488,
      },
      {
        lat: 13.19797190086369,
        lng: 80.30418127872886,
      },
      {
        lat: 13.199769307807069,
        lng: 80.30501444289763,
      },
      {
        lat: 13.203070009860499,
        lng: 80.306766147699,
      },
      {
        lat: 13.20718253679052,
        lng: 80.30877914667182,
      },
      {
        lat: 13.207538370077868,
        lng: 80.31101781517661,
      },
      {
        lat: 13.20746399519889,
        lng: 80.3120169702451,
      },
      {
        lat: 13.205868933245885,
        lng: 80.31762221310909,
      },
      {
        lat: 13.205261615945611,
        lng: 80.32028343112754,
      },
      {
        lat: 13.204948447877777,
        lng: 80.32170398792262,
      },
      {
        lat: 13.204650206855025,
        lng: 80.32273244980871,
      },
      {
        lat: 13.204123947826325,
        lng: 80.32261308452455,
      },
      {
        lat: 13.200755374391077,
        lng: 80.32181272044383,
      },
      {
        lat: 13.195542485778134,
        lng: 80.32054039281545,
      },
      {
        lat: 13.188754753527922,
        lng: 80.31869630653847,
      },
    ],
    properties: {
      Ward_No: 3,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#f6d04d",
      zname: "THIRUVOTTIYUR(3)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.188189764074684,
        lng: 80.30114022789411,
      },
      {
        lat: 13.18479348797254,
        lng: 80.3080096909132,
      },
      {
        lat: 13.184778555960575,
        lng: 80.30098332914952,
      },
      {
        lat: 13.188698793292456,
        lng: 80.30078469229923,
      },
      {
        lat: 13.181639267094377,
        lng: 80.30079594643277,
      },
      {
        lat: 13.194112438389368,
        lng: 80.30364545234073,
      },
      {
        lat: 13.190936844536107,
        lng: 80.31052954617327,
      },
      {
        lat: 13.18482123601391,
        lng: 80.30228661834856,
      },
      {
        lat: 13.17894444904383,
        lng: 80.30305459782741,
      },
      {
        lat: 13.19256646956374,
        lng: 80.3040972508295,
      },
      {
        lat: 13.191757257189588,
        lng: 80.30593444108035,
      },
      {
        lat: 13.187966147464314,
        lng: 80.30237403161118,
      },
      {
        lat: 13.185053164132125,
        lng: 80.30750520953174,
      },
      {
        lat: 13.183069951138345,
        lng: 80.30631585991317,
      },
      {
        lat: 13.181636016985598,
        lng: 80.30556410834777,
      },
      {
        lat: 13.187516155115663,
        lng: 80.30201962848976,
      },
      {
        lat: 13.182421903355907,
        lng: 80.30686712239842,
      },
      {
        lat: 13.185878463455014,
        lng: 80.30164195089397,
      },
      {
        lat: 13.184757009689832,
        lng: 80.30436882773319,
      },
      {
        lat: 13.190234320700933,
        lng: 80.30094766453442,
      },
      {
        lat: 13.178451878192027,
        lng: 80.30354929676955,
      },
      {
        lat: 13.19418516595429,
        lng: 80.30742023272327,
      },
      {
        lat: 13.190124175512631,
        lng: 80.30778331799742,
      },
      {
        lat: 13.186399921503815,
        lng: 80.29787579745397,
      },
      {
        lat: 13.18496854337529,
        lng: 80.30472635354474,
      },
      {
        lat: 13.186823970607605,
        lng: 80.3056238556673,
      },
      {
        lat: 13.177712208994764,
        lng: 80.30092746631523,
      },
      {
        lat: 13.185738924703282,
        lng: 80.30254355899541,
      },
      {
        lat: 13.190997196491253,
        lng: 80.30157819442242,
      },
      {
        lat: 13.192429421739968,
        lng: 80.31109406896722,
      },
      {
        lat: 13.178665148951417,
        lng: 80.30518695440075,
      },
      {
        lat: 13.188473319888109,
        lng: 80.30625505449206,
      },
      {
        lat: 13.189016603782695,
        lng: 80.30714901639736,
      },
      {
        lat: 13.17731141849861,
        lng: 80.30007085799971,
      },
      {
        lat: 13.187426112051275,
        lng: 80.30503615037108,
      },
      {
        lat: 13.189501762527964,
        lng: 80.30393078334758,
      },
      {
        lat: 13.188915615530608,
        lng: 80.30255239549656,
      },
    ],
    cases: 37,
    coords: [
      {
        lat: 13.18703146620994,
        lng: 80.30908268180099,
      },
      {
        lat: 13.17563775869305,
        lng: 80.30380259109437,
      },
      {
        lat: 13.176846169637743,
        lng: 80.29978397735847,
      },
      {
        lat: 13.17897830038652,
        lng: 80.30058201130028,
      },
      {
        lat: 13.17938134734019,
        lng: 80.2992824751878,
      },
      {
        lat: 13.18304611738844,
        lng: 80.30167569312505,
      },
      {
        lat: 13.182282236895695,
        lng: 80.30326631084795,
      },
      {
        lat: 13.182839368947139,
        lng: 80.30345361421658,
      },
      {
        lat: 13.183758064454041,
        lng: 80.3034698413735,
      },
      {
        lat: 13.185825303957653,
        lng: 80.29724840785212,
      },
      {
        lat: 13.187883732193601,
        lng: 80.29861081691736,
      },
      {
        lat: 13.187692659346569,
        lng: 80.29949006445416,
      },
      {
        lat: 13.18983322147414,
        lng: 80.30022768564334,
      },
      {
        lat: 13.191957980319287,
        lng: 80.3024037793526,
      },
      {
        lat: 13.193271773009478,
        lng: 80.30244141763093,
      },
      {
        lat: 13.195437627826832,
        lng: 80.30330071787769,
      },
      {
        lat: 13.192994215883001,
        lng: 80.311720476808,
      },
      {
        lat: 13.18703146620994,
        lng: 80.30908268180099,
      },
    ],
    properties: {
      Ward_No: 4,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#8bc24c",
      zname: "THIRUVOTTIYUR(4)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.17538735474574,
        lng: 80.29570911099268,
      },
      {
        lat: 13.177351679725628,
        lng: 80.29273077136132,
      },
      {
        lat: 13.17326792365,
        lng: 80.29532003093901,
      },
      {
        lat: 13.180040515186793,
        lng: 80.29190228981585,
      },
      {
        lat: 13.180013867207826,
        lng: 80.29198571834398,
      },
      {
        lat: 13.173474607166888,
        lng: 80.29668530971652,
      },
      {
        lat: 13.173793760210025,
        lng: 80.29098704361988,
      },
      {
        lat: 13.177470689653065,
        lng: 80.29586285272536,
      },
      {
        lat: 13.176318714072174,
        lng: 80.29915061606336,
      },
      {
        lat: 13.177233916496895,
        lng: 80.29087540037276,
      },
      {
        lat: 13.182418829207085,
        lng: 80.29722822746183,
      },
      {
        lat: 13.176086009011396,
        lng: 80.30070860578967,
      },
      {
        lat: 13.173785954474951,
        lng: 80.29088542864477,
      },
      {
        lat: 13.177246088091977,
        lng: 80.2970928683254,
      },
      {
        lat: 13.175053329024724,
        lng: 80.29950476243604,
      },
      {
        lat: 13.175973598299034,
        lng: 80.28726653270516,
      },
      {
        lat: 13.175359606042674,
        lng: 80.30193464126533,
      },
      {
        lat: 13.177933757046183,
        lng: 80.28885141166468,
      },
      {
        lat: 13.184434584615369,
        lng: 80.30006354361252,
      },
      {
        lat: 13.176021688951884,
        lng: 80.29054149314565,
      },
      {
        lat: 13.174037241568904,
        lng: 80.30193815374376,
      },
      {
        lat: 13.173513337248435,
        lng: 80.29047192392761,
      },
      {
        lat: 13.174969360572755,
        lng: 80.29972375575564,
      },
      {
        lat: 13.177692547599879,
        lng: 80.29097636036687,
      },
      {
        lat: 13.179975548110196,
        lng: 80.29699551562031,
      },
      {
        lat: 13.181840178645567,
        lng: 80.2865456759763,
      },
      {
        lat: 13.177595441131956,
        lng: 80.29589499536291,
      },
      {
        lat: 13.179620629909818,
        lng: 80.29680449430263,
      },
      {
        lat: 13.174428589991376,
        lng: 80.29407137732701,
      },
      {
        lat: 13.180151513102466,
        lng: 80.29311592581958,
      },
      {
        lat: 13.180426403751307,
        lng: 80.2974975848759,
      },
      {
        lat: 13.171730390251374,
        lng: 80.3015472493212,
      },
      {
        lat: 13.182247742447169,
        lng: 80.296278589235,
      },
      {
        lat: 13.17684400955432,
        lng: 80.28845762291573,
      },
      {
        lat: 13.177740274898392,
        lng: 80.29726798942359,
      },
      {
        lat: 13.180611607414537,
        lng: 80.29309564387077,
      },
      {
        lat: 13.182864177161784,
        lng: 80.30316483860486,
      },
      {
        lat: 13.172276061821405,
        lng: 80.29566488648032,
      },
      {
        lat: 13.175484358335876,
        lng: 80.29952427862324,
      },
      {
        lat: 13.174742497680024,
        lng: 80.29829070059164,
      },
      {
        lat: 13.179343996986535,
        lng: 80.28725770803305,
      },
      {
        lat: 13.178628859671708,
        lng: 80.29313334208294,
      },
      {
        lat: 13.17125852536718,
        lng: 80.30107505564081,
      },
      {
        lat: 13.180593212859492,
        lng: 80.28901862499858,
      },
      {
        lat: 13.18058210258911,
        lng: 80.29701726870013,
      },
      {
        lat: 13.17870066842907,
        lng: 80.29263729190288,
      },
      {
        lat: 13.175468156063562,
        lng: 80.29270299063012,
      },
      {
        lat: 13.174560224030252,
        lng: 80.29233271678652,
      },
      {
        lat: 13.173981033955652,
        lng: 80.29233876481779,
      },
      {
        lat: 13.176730552184601,
        lng: 80.29718446203412,
      },
      {
        lat: 13.178009153246505,
        lng: 80.28747142359735,
      },
      {
        lat: 13.176674116162,
        lng: 80.29589296911882,
      },
      {
        lat: 13.174935018574566,
        lng: 80.29174487755814,
      },
      {
        lat: 13.178836802086403,
        lng: 80.28967611842434,
      },
      {
        lat: 13.174792867444834,
        lng: 80.29575195814874,
      },
      {
        lat: 13.176568396789893,
        lng: 80.29475283508998,
      },
      {
        lat: 13.175320553635622,
        lng: 80.29212208324029,
      },
      {
        lat: 13.17804835440257,
        lng: 80.28914601256537,
      },
      {
        lat: 13.174026889759094,
        lng: 80.2983540522148,
      },
      {
        lat: 13.177892933694183,
        lng: 80.29162714700783,
      },
      {
        lat: 13.181203284356972,
        lng: 80.29863130895289,
      },
      {
        lat: 13.180889971672874,
        lng: 80.2911332678133,
      },
      {
        lat: 13.180815161967631,
        lng: 80.2894629778926,
      },
      {
        lat: 13.174642042958505,
        lng: 80.29481767956818,
      },
      {
        lat: 13.181605417240075,
        lng: 80.2889503305062,
      },
      {
        lat: 13.177877534336261,
        lng: 80.28981895235866,
      },
      {
        lat: 13.18014112871248,
        lng: 80.29075802058011,
      },
      {
        lat: 13.18108764500087,
        lng: 80.2944481870945,
      },
      {
        lat: 13.17483628931849,
        lng: 80.29236708421215,
      },
      {
        lat: 13.177488536300379,
        lng: 80.29289413645672,
      },
      {
        lat: 13.173455785026382,
        lng: 80.30071758484772,
      },
      {
        lat: 13.181398200977672,
        lng: 80.29799190665335,
      },
      {
        lat: 13.174115492322372,
        lng: 80.30134303085381,
      },
      {
        lat: 13.181519061976648,
        lng: 80.29467730083738,
      },
      {
        lat: 13.172933143222522,
        lng: 80.29224767355832,
      },
      {
        lat: 13.184422585068083,
        lng: 80.29711487368776,
      },
      {
        lat: 13.18074555362133,
        lng: 80.29172629868623,
      },
      {
        lat: 13.183914622111937,
        lng: 80.29669024225352,
      },
      {
        lat: 13.181034663642771,
        lng: 80.30008591599992,
      },
    ],
    cases: 79,
    coords: [
      {
        lat: 13.17563775869305,
        lng: 80.30380259109437,
      },
      {
        lat: 13.170826824926337,
        lng: 80.3015536423158,
      },
      {
        lat: 13.17323122191449,
        lng: 80.29028516007722,
      },
      {
        lat: 13.174928853351762,
        lng: 80.29059818107457,
      },
      {
        lat: 13.175105587801461,
        lng: 80.28674418191613,
      },
      {
        lat: 13.175989287403594,
        lng: 80.28671232431397,
      },
      {
        lat: 13.179890576495763,
        lng: 80.2862892487202,
      },
      {
        lat: 13.182001338465065,
        lng: 80.28616411885187,
      },
      {
        lat: 13.181563627964344,
        lng: 80.29089727622419,
      },
      {
        lat: 13.180501606906258,
        lng: 80.29390483504629,
      },
      {
        lat: 13.183408894594146,
        lng: 80.29556235492232,
      },
      {
        lat: 13.185825303957653,
        lng: 80.29724840785212,
      },
      {
        lat: 13.183758064454041,
        lng: 80.3034698413735,
      },
      {
        lat: 13.182839368947139,
        lng: 80.30345361421658,
      },
      {
        lat: 13.182282236895695,
        lng: 80.30326631084795,
      },
      {
        lat: 13.18304611738844,
        lng: 80.30167569312505,
      },
      {
        lat: 13.17938134734019,
        lng: 80.2992824751878,
      },
      {
        lat: 13.17897830038652,
        lng: 80.30058201130028,
      },
      {
        lat: 13.176846169637743,
        lng: 80.29978397735847,
      },
      {
        lat: 13.17563775869305,
        lng: 80.30380259109437,
      },
    ],
    properties: {
      Ward_No: 6,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#f6d04d",
      zname: "THIRUVOTTIYUR(6)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.174933048175681,
        lng: 80.30557023898415,
      },
      {
        lat: 13.180651833416684,
        lng: 80.31256496067125,
      },
      {
        lat: 13.188770324196293,
        lng: 80.31764542561702,
      },
      {
        lat: 13.185368345985461,
        lng: 80.31328609293811,
      },
      {
        lat: 13.172717896429985,
        lng: 80.30880958134625,
      },
      {
        lat: 13.185915436988966,
        lng: 80.30893750647726,
      },
      {
        lat: 13.179483600161085,
        lng: 80.31571512933152,
      },
      {
        lat: 13.171652275980557,
        lng: 80.30978517079858,
      },
      {
        lat: 13.171107412436873,
        lng: 80.30479105427507,
      },
      {
        lat: 13.181939961909071,
        lng: 80.30981949447478,
      },
      {
        lat: 13.174274500683849,
        lng: 80.31253008442712,
      },
      {
        lat: 13.175439923441798,
        lng: 80.3137892867406,
      },
      {
        lat: 13.1773932611879,
        lng: 80.30591403929071,
      },
      {
        lat: 13.175735398509525,
        lng: 80.31251520852969,
      },
      {
        lat: 13.174082787449942,
        lng: 80.31241485811564,
      },
      {
        lat: 13.180249248721907,
        lng: 80.31079622587423,
      },
      {
        lat: 13.17126676790177,
        lng: 80.31041254113326,
      },
      {
        lat: 13.17606869689729,
        lng: 80.30864631707152,
      },
      {
        lat: 13.18582166529323,
        lng: 80.31208516190577,
      },
      {
        lat: 13.18736071849366,
        lng: 80.31602812601864,
      },
      {
        lat: 13.181813801716917,
        lng: 80.31015362287872,
      },
      {
        lat: 13.175852499505593,
        lng: 80.3142471008868,
      },
      {
        lat: 13.178486765986323,
        lng: 80.31433407484836,
      },
      {
        lat: 13.180313685391123,
        lng: 80.31611419614732,
      },
      {
        lat: 13.183459188927534,
        lng: 80.31109064512161,
      },
      {
        lat: 13.169022818129342,
        lng: 80.30992498437647,
      },
      {
        lat: 13.18047564190823,
        lng: 80.31082637368978,
      },
      {
        lat: 13.172423564328852,
        lng: 80.30495661796938,
      },
      {
        lat: 13.180137328063672,
        lng: 80.30659343770319,
      },
      {
        lat: 13.172358720717472,
        lng: 80.31392291707739,
      },
      {
        lat: 13.180673378621648,
        lng: 80.31028165327717,
      },
      {
        lat: 13.177138548851575,
        lng: 80.3086903911112,
      },
      {
        lat: 13.173534894600767,
        lng: 80.31399762290445,
      },
      {
        lat: 13.181474965165787,
        lng: 80.31189552799563,
      },
      {
        lat: 13.188719903871071,
        lng: 80.31377439411307,
      },
      {
        lat: 13.173884165001457,
        lng: 80.30953965059427,
      },
      {
        lat: 13.173924376538494,
        lng: 80.31441640304904,
      },
      {
        lat: 13.169017428139632,
        lng: 80.30630099992067,
      },
      {
        lat: 13.178715478836915,
        lng: 80.30725207234828,
      },
      {
        lat: 13.18226419214989,
        lng: 80.31067258948933,
      },
      {
        lat: 13.173359975500745,
        lng: 80.30872130305288,
      },
      {
        lat: 13.170712558050553,
        lng: 80.3132281963657,
      },
      {
        lat: 13.18553677113648,
        lng: 80.3086226770701,
      },
      {
        lat: 13.18512760061933,
        lng: 80.30957297466176,
      },
      {
        lat: 13.178911290945717,
        lng: 80.30914968401743,
      },
      {
        lat: 13.185261799609547,
        lng: 80.31262825575656,
      },
      {
        lat: 13.184990203839035,
        lng: 80.31121663846336,
      },
      {
        lat: 13.170910679633037,
        lng: 80.31289667390413,
      },
      {
        lat: 13.169060918724817,
        lng: 80.31043324499345,
      },
      {
        lat: 13.174346477446846,
        lng: 80.31046112628283,
      },
      {
        lat: 13.169770344171964,
        lng: 80.31140592854705,
      },
      {
        lat: 13.175644566696675,
        lng: 80.31408825116877,
      },
      {
        lat: 13.175842455354095,
        lng: 80.3145333658668,
      },
      {
        lat: 13.171105535957846,
        lng: 80.30842539662065,
      },
      {
        lat: 13.174009396798906,
        lng: 80.31084887114744,
      },
      {
        lat: 13.183546390270303,
        lng: 80.3130592206883,
      },
      {
        lat: 13.178817256449486,
        lng: 80.3150972279276,
      },
      {
        lat: 13.168616691563557,
        lng: 80.30735349531534,
      },
      {
        lat: 13.172379510659574,
        lng: 80.30317629903132,
      },
      {
        lat: 13.184418255934322,
        lng: 80.31639323196897,
      },
      {
        lat: 13.186238897736672,
        lng: 80.31637831842365,
      },
      {
        lat: 13.178920185146373,
        lng: 80.3080168710154,
      },
      {
        lat: 13.17874750663711,
        lng: 80.30884081767226,
      },
      {
        lat: 13.169314322429619,
        lng: 80.31094922267262,
      },
      {
        lat: 13.175097028768983,
        lng: 80.31400494922094,
      },
      {
        lat: 13.180979356891752,
        lng: 80.30990800221844,
      },
      {
        lat: 13.18827056512475,
        lng: 80.31633240239549,
      },
      {
        lat: 13.169215448509389,
        lng: 80.3112782214597,
      },
      {
        lat: 13.170965153253542,
        lng: 80.30776610820757,
      },
      {
        lat: 13.175148772586907,
        lng: 80.31079136945388,
      },
      {
        lat: 13.168506753826579,
        lng: 80.30693874454111,
      },
      {
        lat: 13.17366971683976,
        lng: 80.31225900787757,
      },
      {
        lat: 13.181794654237786,
        lng: 80.31093287759629,
      },
      {
        lat: 13.17867034025205,
        lng: 80.30553777398055,
      },
      {
        lat: 13.174465770177571,
        lng: 80.30863638507901,
      },
      {
        lat: 13.17886669032608,
        lng: 80.3091504868305,
      },
      {
        lat: 13.17601203305009,
        lng: 80.31204944463605,
      },
      {
        lat: 13.176367305745972,
        lng: 80.30468026206991,
      },
      {
        lat: 13.18577539661731,
        lng: 80.31181256850623,
      },
      {
        lat: 13.169059726992161,
        lng: 80.31198470912406,
      },
      {
        lat: 13.177602480881122,
        lng: 80.31158349790792,
      },
      {
        lat: 13.186010634326538,
        lng: 80.31507390251106,
      },
      {
        lat: 13.181197305910471,
        lng: 80.3076570415688,
      },
      {
        lat: 13.188175730779955,
        lng: 80.31464714767152,
      },
      {
        lat: 13.171549677211196,
        lng: 80.30419660492379,
      },
      {
        lat: 13.176674951083,
        lng: 80.31240410355127,
      },
      {
        lat: 13.173940885732154,
        lng: 80.30422393664155,
      },
      {
        lat: 13.183698322510983,
        lng: 80.30755617461416,
      },
      {
        lat: 13.178274800610966,
        lng: 80.31009621393068,
      },
      {
        lat: 13.171875172868452,
        lng: 80.31081495110807,
      },
      {
        lat: 13.182827875618745,
        lng: 80.30905025042836,
      },
      {
        lat: 13.174566939420691,
        lng: 80.3091048497685,
      },
      {
        lat: 13.173401413152016,
        lng: 80.31050814465584,
      },
      {
        lat: 13.172161167037054,
        lng: 80.304575460257,
      },
      {
        lat: 13.168632802612596,
        lng: 80.31117698353337,
      },
      {
        lat: 13.18887445875204,
        lng: 80.31419566156848,
      },
      {
        lat: 13.168734317637712,
        lng: 80.30635618849945,
      },
      {
        lat: 13.182868011503437,
        lng: 80.31260566230945,
      },
      {
        lat: 13.182121692858946,
        lng: 80.31275660748307,
      },
      {
        lat: 13.17955051890939,
        lng: 80.31575495257204,
      },
      {
        lat: 13.169543622112855,
        lng: 80.30656910993837,
      },
      {
        lat: 13.174661812979283,
        lng: 80.30913562850021,
      },
      {
        lat: 13.178933855439888,
        lng: 80.30743323703943,
      },
      {
        lat: 13.172262188858955,
        lng: 80.3128121166654,
      },
      {
        lat: 13.186530433745954,
        lng: 80.31134964546399,
      },
      {
        lat: 13.178285109278148,
        lng: 80.30907940899985,
      },
      {
        lat: 13.168351032720745,
        lng: 80.31006045851967,
      },
      {
        lat: 13.1831290936347,
        lng: 80.3126613361079,
      },
      {
        lat: 13.18009294898991,
        lng: 80.31300582043099,
      },
      {
        lat: 13.181468225267862,
        lng: 80.30793892303423,
      },
      {
        lat: 13.172585027392113,
        lng: 80.30948112336907,
      },
      {
        lat: 13.188895275844738,
        lng: 80.31667765670201,
      },
      {
        lat: 13.173002997236727,
        lng: 80.31076803691548,
      },
      {
        lat: 13.185302803185865,
        lng: 80.3162141459991,
      },
      {
        lat: 13.182244779642188,
        lng: 80.31289578482958,
      },
      {
        lat: 13.179891438935098,
        lng: 80.30917354241345,
      },
      {
        lat: 13.172104336023407,
        lng: 80.3052769010472,
      },
      {
        lat: 13.18810318166681,
        lng: 80.31787372287421,
      },
      {
        lat: 13.18274558513234,
        lng: 80.30970178593753,
      },
      {
        lat: 13.179811010366967,
        lng: 80.30919001367684,
      },
      {
        lat: 13.182508110441638,
        lng: 80.31517769575757,
      },
      {
        lat: 13.174600511484593,
        lng: 80.31477153807177,
      },
      {
        lat: 13.176392475626287,
        lng: 80.30420081461865,
      },
      {
        lat: 13.167881030031841,
        lng: 80.30558180548279,
      },
      {
        lat: 13.181080222918752,
        lng: 80.31427452565596,
      },
      {
        lat: 13.175888123598854,
        lng: 80.31155347707619,
      },
      {
        lat: 13.188715591517711,
        lng: 80.31805877048207,
      },
      {
        lat: 13.174938201149756,
        lng: 80.307506936722,
      },
      {
        lat: 13.17943310650047,
        lng: 80.31405174988068,
      },
      {
        lat: 13.17827933885877,
        lng: 80.3147873982108,
      },
      {
        lat: 13.178404324415103,
        lng: 80.31315522380847,
      },
      {
        lat: 13.176039420282063,
        lng: 80.31129057606451,
      },
      {
        lat: 13.179508592005607,
        lng: 80.30695002497453,
      },
      {
        lat: 13.183786051484507,
        lng: 80.31338208775831,
      },
      {
        lat: 13.170602038762842,
        lng: 80.30308224621805,
      },
      {
        lat: 13.172088149594593,
        lng: 80.30473501304056,
      },
      {
        lat: 13.186723054667276,
        lng: 80.30978071710528,
      },
      {
        lat: 13.188883383913755,
        lng: 80.31725528701261,
      },
      {
        lat: 13.180794613513743,
        lng: 80.31041245983194,
      },
      {
        lat: 13.184252687122934,
        lng: 80.31040643992654,
      },
      {
        lat: 13.178599111701875,
        lng: 80.31177971552225,
      },
      {
        lat: 13.16828225091531,
        lng: 80.30541447653118,
      },
      {
        lat: 13.182577790629118,
        lng: 80.31602048470135,
      },
      {
        lat: 13.18814991459658,
        lng: 80.31644923989857,
      },
      {
        lat: 13.184743562633605,
        lng: 80.311755702394,
      },
      {
        lat: 13.181400366440307,
        lng: 80.31273720013125,
      },
      {
        lat: 13.176950062050322,
        lng: 80.31322667631713,
      },
      {
        lat: 13.185116602097022,
        lng: 80.31067312285514,
      },
      {
        lat: 13.173900716495831,
        lng: 80.31214746913301,
      },
      {
        lat: 13.178954250815465,
        lng: 80.31442949123709,
      },
      {
        lat: 13.170057603293303,
        lng: 80.31334371926027,
      },
      {
        lat: 13.178190287326615,
        lng: 80.30960522606856,
      },
      {
        lat: 13.178110173340647,
        lng: 80.31423198995792,
      },
      {
        lat: 13.17711896775729,
        lng: 80.30527622833027,
      },
    ],
    cases: 154,
    coords: [
      {
        lat: 13.16736119550799,
        lng: 80.31270238345026,
      },
      {
        lat: 13.16822211309122,
        lng: 80.30831544903099,
      },
      {
        lat: 13.166997321210879,
        lng: 80.30760827634207,
      },
      {
        lat: 13.168102973208567,
        lng: 80.30456931225902,
      },
      {
        lat: 13.167201480094414,
        lng: 80.3042101999698,
      },
      {
        lat: 13.16743595331164,
        lng: 80.303155825879,
      },
      {
        lat: 13.16941092535781,
        lng: 80.303107220582,
      },
      {
        lat: 13.169579225932385,
        lng: 80.30173450056299,
      },
      {
        lat: 13.16983247285234,
        lng: 80.30112643593591,
      },
      {
        lat: 13.170826824926337,
        lng: 80.3015536423158,
      },
      {
        lat: 13.17563775869305,
        lng: 80.30380259109437,
      },
      {
        lat: 13.18703146620994,
        lng: 80.30908268180099,
      },
      {
        lat: 13.186400418124316,
        lng: 80.31264036517629,
      },
      {
        lat: 13.189886492769087,
        lng: 80.31347494497015,
      },
      {
        lat: 13.188754753527922,
        lng: 80.31869630653847,
      },
      {
        lat: 13.183324342439114,
        lng: 80.31729501040446,
      },
      {
        lat: 13.178391491735212,
        lng: 80.31564976256006,
      },
      {
        lat: 13.177082241568794,
        lng: 80.31527153635173,
      },
      {
        lat: 13.174771048241464,
        lng: 80.31490178984798,
      },
      {
        lat: 13.173331109836589,
        lng: 80.3145246696881,
      },
      {
        lat: 13.169402833163216,
        lng: 80.31330348290882,
      },
      {
        lat: 13.168616599037236,
        lng: 80.31296408658346,
      },
      {
        lat: 13.16736119550799,
        lng: 80.31270238345026,
      },
    ],
    properties: {
      Ward_No: 5,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#de4307",
      zname: "THIRUVOTTIYUR(5)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.165869799028593,
        lng: 80.30519694360083,
      },
      {
        lat: 13.168598476729555,
        lng: 80.30278970660888,
      },
      {
        lat: 13.166195784684016,
        lng: 80.3016635156914,
      },
      {
        lat: 13.164571395877614,
        lng: 80.30977735437345,
      },
      {
        lat: 13.165390989579679,
        lng: 80.30554544074126,
      },
      {
        lat: 13.165774361946651,
        lng: 80.30881783923462,
      },
      {
        lat: 13.167091105937295,
        lng: 80.30857465503048,
      },
      {
        lat: 13.164750689827882,
        lng: 80.31179018236008,
      },
      {
        lat: 13.16688100554133,
        lng: 80.30912104665691,
      },
      {
        lat: 13.16641108725081,
        lng: 80.30724915933878,
      },
      {
        lat: 13.164778786978673,
        lng: 80.30383704692977,
      },
      {
        lat: 13.167202938840662,
        lng: 80.30168919484032,
      },
      {
        lat: 13.169308322118647,
        lng: 80.30194616554951,
      },
      {
        lat: 13.166101937362924,
        lng: 80.30925203778989,
      },
      {
        lat: 13.164996690110502,
        lng: 80.30343330440694,
      },
      {
        lat: 13.16796229017973,
        lng: 80.30935830980187,
      },
      {
        lat: 13.164358245557079,
        lng: 80.30967194058317,
      },
      {
        lat: 13.167850607100604,
        lng: 80.30194940387923,
      },
      {
        lat: 13.165055810160613,
        lng: 80.3111912888521,
      },
      {
        lat: 13.167103121007084,
        lng: 80.30169627186471,
      },
      {
        lat: 13.167278439134394,
        lng: 80.305239060284,
      },
      {
        lat: 13.164795487238948,
        lng: 80.30379615086076,
      },
      {
        lat: 13.165703820142861,
        lng: 80.30409789309739,
      },
      {
        lat: 13.16569311587857,
        lng: 80.30557367397732,
      },
      {
        lat: 13.164882498299148,
        lng: 80.3036456152701,
      },
      {
        lat: 13.166219989433943,
        lng: 80.30821622964498,
      },
      {
        lat: 13.1645927496267,
        lng: 80.30306919592167,
      },
      {
        lat: 13.165290941613048,
        lng: 80.31104407390094,
      },
      {
        lat: 13.167399033837096,
        lng: 80.3115320359031,
      },
      {
        lat: 13.166780099292438,
        lng: 80.30904474521218,
      },
      {
        lat: 13.16782586251266,
        lng: 80.30142283130816,
      },
      {
        lat: 13.164644434884888,
        lng: 80.3100434804212,
      },
      {
        lat: 13.165583319606155,
        lng: 80.3049078521277,
      },
      {
        lat: 13.165345882491062,
        lng: 80.31099225142289,
      },
      {
        lat: 13.16611055104506,
        lng: 80.30512419571039,
      },
      {
        lat: 13.16596688657829,
        lng: 80.30237301120913,
      },
      {
        lat: 13.166175896601409,
        lng: 80.31208174581349,
      },
      {
        lat: 13.166910563797845,
        lng: 80.31212020850623,
      },
      {
        lat: 13.166907634909006,
        lng: 80.3101975224295,
      },
      {
        lat: 13.16436789111313,
        lng: 80.3111196915816,
      },
      {
        lat: 13.164602152025688,
        lng: 80.31182816269809,
      },
      {
        lat: 13.167346293512885,
        lng: 80.30328388149404,
      },
      {
        lat: 13.166796385186597,
        lng: 80.30909730692642,
      },
      {
        lat: 13.16594378304149,
        lng: 80.31184855769307,
      },
      {
        lat: 13.165655249026575,
        lng: 80.30653161882232,
      },
      {
        lat: 13.165245668388753,
        lng: 80.31176578496606,
      },
      {
        lat: 13.165853652488472,
        lng: 80.30688264299272,
      },
      {
        lat: 13.164896849460847,
        lng: 80.30749932697636,
      },
      {
        lat: 13.168720726191024,
        lng: 80.30283845548783,
      },
      {
        lat: 13.165095052666288,
        lng: 80.3069162684898,
      },
      {
        lat: 13.166808803728234,
        lng: 80.31044983058021,
      },
      {
        lat: 13.16503853082879,
        lng: 80.31035102271284,
      },
      {
        lat: 13.16605268568818,
        lng: 80.30485841496368,
      },
      {
        lat: 13.165775214681792,
        lng: 80.30575344827899,
      },
      {
        lat: 13.16719545576086,
        lng: 80.30573471850947,
      },
      {
        lat: 13.165094377482937,
        lng: 80.30628707756837,
      },
      {
        lat: 13.166107711739468,
        lng: 80.30297990276148,
      },
      {
        lat: 13.16646497163462,
        lng: 80.3098695500255,
      },
      {
        lat: 13.16601050405451,
        lng: 80.31090279341414,
      },
      {
        lat: 13.166192191974782,
        lng: 80.3070230867387,
      },
      {
        lat: 13.168796922896753,
        lng: 80.30263374429286,
      },
      {
        lat: 13.166517050522996,
        lng: 80.30747268934427,
      },
      {
        lat: 13.167335029273046,
        lng: 80.31026412694125,
      },
      {
        lat: 13.164531146642242,
        lng: 80.31077262224903,
      },
      {
        lat: 13.166231558396394,
        lng: 80.30970238252833,
      },
      {
        lat: 13.166581464970811,
        lng: 80.30977949281645,
      },
      {
        lat: 13.16486700008042,
        lng: 80.30707986467405,
      },
      {
        lat: 13.166468654093318,
        lng: 80.30187288951323,
      },
      {
        lat: 13.164735217563411,
        lng: 80.30827762865233,
      },
      {
        lat: 13.16422242465604,
        lng: 80.3090799748727,
      },
      {
        lat: 13.16668520003126,
        lng: 80.31042328483666,
      },
      {
        lat: 13.165581432930194,
        lng: 80.31130725011164,
      },
      {
        lat: 13.165162006933588,
        lng: 80.31045713030971,
      },
      {
        lat: 13.165261902590276,
        lng: 80.30782733162083,
      },
      {
        lat: 13.16507973525592,
        lng: 80.30611090585732,
      },
      {
        lat: 13.16599292301883,
        lng: 80.31040393226874,
      },
      {
        lat: 13.165048647262822,
        lng: 80.30670533076594,
      },
      {
        lat: 13.166862958954137,
        lng: 80.31052834495507,
      },
      {
        lat: 13.166824416613103,
        lng: 80.30756993513134,
      },
      {
        lat: 13.166646434396423,
        lng: 80.31014231348328,
      },
      {
        lat: 13.165714024305968,
        lng: 80.30770584258883,
      },
      {
        lat: 13.1676703963184,
        lng: 80.30514414720778,
      },
      {
        lat: 13.167149260235881,
        lng: 80.30213881234639,
      },
      {
        lat: 13.165529136609436,
        lng: 80.30568620375728,
      },
      {
        lat: 13.164962544980911,
        lng: 80.307063608569,
      },
      {
        lat: 13.1642853872335,
        lng: 80.30848959490453,
      },
      {
        lat: 13.167644121615014,
        lng: 80.30988993447963,
      },
      {
        lat: 13.1675471014129,
        lng: 80.30172429739159,
      },
      {
        lat: 13.168905463190155,
        lng: 80.30232094651937,
      },
      {
        lat: 13.165043483222533,
        lng: 80.30935099007681,
      },
      {
        lat: 13.166398767185239,
        lng: 80.31104684512512,
      },
      {
        lat: 13.166810440812242,
        lng: 80.30204872088387,
      },
      {
        lat: 13.165525218216267,
        lng: 80.30627971095527,
      },
      {
        lat: 13.164458472671202,
        lng: 80.31024982792417,
      },
      {
        lat: 13.166246940617553,
        lng: 80.30402462664661,
      },
      {
        lat: 13.164773095202131,
        lng: 80.30340364702235,
      },
      {
        lat: 13.165276176947334,
        lng: 80.31155452735709,
      },
      {
        lat: 13.164688537946152,
        lng: 80.30927561406503,
      },
      {
        lat: 13.166269028363184,
        lng: 80.30611378467036,
      },
      {
        lat: 13.167033694576107,
        lng: 80.31055694826834,
      },
      {
        lat: 13.165972954128746,
        lng: 80.3047041546544,
      },
      {
        lat: 13.16508438568539,
        lng: 80.30819058876206,
      },
      {
        lat: 13.167224121298126,
        lng: 80.30553395968923,
      },
      {
        lat: 13.167152910501397,
        lng: 80.30916630608755,
      },
      {
        lat: 13.164984877322897,
        lng: 80.31196733187177,
      },
      {
        lat: 13.168858525143412,
        lng: 80.30175288094372,
      },
      {
        lat: 13.166105961326332,
        lng: 80.30851670558232,
      },
      {
        lat: 13.164610860014303,
        lng: 80.30765053876753,
      },
      {
        lat: 13.165886774652128,
        lng: 80.31049062882181,
      },
      {
        lat: 13.169170749189293,
        lng: 80.30305753181553,
      },
      {
        lat: 13.166434175035675,
        lng: 80.30659635422266,
      },
      {
        lat: 13.165827909683182,
        lng: 80.30457189893305,
      },
      {
        lat: 13.1662926977477,
        lng: 80.31214112103711,
      },
      {
        lat: 13.166730051608516,
        lng: 80.30140204806757,
      },
      {
        lat: 13.165630511080968,
        lng: 80.31171399350008,
      },
      {
        lat: 13.165373585374244,
        lng: 80.30406215406816,
      },
      {
        lat: 13.165935607746613,
        lng: 80.30758074187048,
      },
      {
        lat: 13.165765619792293,
        lng: 80.30632007168953,
      },
      {
        lat: 13.165320594281608,
        lng: 80.30421816922433,
      },
      {
        lat: 13.166443215493315,
        lng: 80.30623276524996,
      },
      {
        lat: 13.167573824012182,
        lng: 80.3102956663192,
      },
      {
        lat: 13.166659631675145,
        lng: 80.30972678184341,
      },
      {
        lat: 13.166344415444103,
        lng: 80.30412970531066,
      },
      {
        lat: 13.167340049057428,
        lng: 80.30662938822978,
      },
      {
        lat: 13.166642125383207,
        lng: 80.30925229894008,
      },
      {
        lat: 13.166854146764779,
        lng: 80.3051450553805,
      },
    ],
    cases: 126,
    coords: [
      {
        lat: 13.163185144535662,
        lng: 80.31171236528448,
      },
      {
        lat: 13.165164417749487,
        lng: 80.30471724754788,
      },
      {
        lat: 13.164661738758856,
        lng: 80.30413152581603,
      },
      {
        lat: 13.16434976369095,
        lng: 80.30363858627302,
      },
      {
        lat: 13.164559640861992,
        lng: 80.30297603686942,
      },
      {
        lat: 13.16512859495953,
        lng: 80.30273523124966,
      },
      {
        lat: 13.165746373282007,
        lng: 80.3027064037902,
      },
      {
        lat: 13.166280652833857,
        lng: 80.30067236448511,
      },
      {
        lat: 13.168234219800976,
        lng: 80.30143459930682,
      },
      {
        lat: 13.169579225932385,
        lng: 80.30173450056299,
      },
      {
        lat: 13.16941092535781,
        lng: 80.303107220582,
      },
      {
        lat: 13.16743595331164,
        lng: 80.303155825879,
      },
      {
        lat: 13.167201480094414,
        lng: 80.3042101999698,
      },
      {
        lat: 13.168102973208567,
        lng: 80.30456931225902,
      },
      {
        lat: 13.166997321210879,
        lng: 80.30760827634207,
      },
      {
        lat: 13.16822211309122,
        lng: 80.30831544903099,
      },
      {
        lat: 13.16736119550799,
        lng: 80.31270238345026,
      },
      {
        lat: 13.167220749552616,
        lng: 80.31267310589462,
      },
      {
        lat: 13.163185144535662,
        lng: 80.31171236528448,
      },
    ],
    properties: {
      Ward_No: 8,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#de4307",
      zname: "THIRUVOTTIYUR(8)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.163103336507266,
        lng: 80.30715233946539,
      },
      {
        lat: 13.163050769900444,
        lng: 80.31127000415275,
      },
      {
        lat: 13.161754570381122,
        lng: 80.30466065175179,
      },
      {
        lat: 13.160589982225256,
        lng: 80.30443988680524,
      },
      {
        lat: 13.162560287711582,
        lng: 80.30922804756429,
      },
      {
        lat: 13.16344200195603,
        lng: 80.30545745165676,
      },
      {
        lat: 13.163218537344429,
        lng: 80.30354716391628,
      },
      {
        lat: 13.162291547006593,
        lng: 80.30597461674039,
      },
      {
        lat: 13.160550476483168,
        lng: 80.30350454057076,
      },
      {
        lat: 13.163154374326712,
        lng: 80.30534163840596,
      },
      {
        lat: 13.16198113958587,
        lng: 80.30400509471968,
      },
      {
        lat: 13.161348459127684,
        lng: 80.3098924645959,
      },
      {
        lat: 13.161630429904836,
        lng: 80.3057169583948,
      },
      {
        lat: 13.159784353518553,
        lng: 80.309830432423,
      },
      {
        lat: 13.16069965331029,
        lng: 80.30583399805447,
      },
      {
        lat: 13.161666746348683,
        lng: 80.30772706164784,
      },
      {
        lat: 13.163358971763783,
        lng: 80.30348564647052,
      },
      {
        lat: 13.160067827591199,
        lng: 80.30968306406855,
      },
      {
        lat: 13.1615441288057,
        lng: 80.30784154214123,
      },
      {
        lat: 13.161064947842037,
        lng: 80.30962560751921,
      },
      {
        lat: 13.163806772815775,
        lng: 80.30303870083793,
      },
      {
        lat: 13.161683351600159,
        lng: 80.30691438452871,
      },
      {
        lat: 13.16119739747774,
        lng: 80.30904920304526,
      },
      {
        lat: 13.16240859244195,
        lng: 80.3019304611822,
      },
      {
        lat: 13.160466332625864,
        lng: 80.31050366133987,
      },
      {
        lat: 13.162964873716176,
        lng: 80.30606343907233,
      },
      {
        lat: 13.163651051355982,
        lng: 80.30436551945114,
      },
      {
        lat: 13.160577403947878,
        lng: 80.3102690418159,
      },
      {
        lat: 13.16275167131453,
        lng: 80.31088841228012,
      },
      {
        lat: 13.160159913427567,
        lng: 80.30723025177353,
      },
      {
        lat: 13.160935509497111,
        lng: 80.31031215192176,
      },
      {
        lat: 13.160702012869514,
        lng: 80.30271163090637,
      },
      {
        lat: 13.161843609075033,
        lng: 80.30397286127038,
      },
      {
        lat: 13.160971050189431,
        lng: 80.30893584723383,
      },
      {
        lat: 13.162479225356329,
        lng: 80.30258044049575,
      },
      {
        lat: 13.164606817029075,
        lng: 80.30207553832662,
      },
      {
        lat: 13.161823524558656,
        lng: 80.30367262526195,
      },
      {
        lat: 13.162281214696964,
        lng: 80.30634305749898,
      },
      {
        lat: 13.16268272042712,
        lng: 80.30238850689996,
      },
      {
        lat: 13.16372205842961,
        lng: 80.30605993638436,
      },
      {
        lat: 13.164632417124414,
        lng: 80.30642936549121,
      },
      {
        lat: 13.16387798486838,
        lng: 80.30418153599068,
      },
      {
        lat: 13.1621274127196,
        lng: 80.3018324378393,
      },
      {
        lat: 13.164547141048985,
        lng: 80.30634320458488,
      },
      {
        lat: 13.160769982343835,
        lng: 80.30635786994446,
      },
      {
        lat: 13.160647912068734,
        lng: 80.31005124735555,
      },
      {
        lat: 13.16114749501635,
        lng: 80.30497256169,
      },
      {
        lat: 13.161365587713496,
        lng: 80.30939034941267,
      },
      {
        lat: 13.16351561339528,
        lng: 80.30879612507393,
      },
      {
        lat: 13.165675131022994,
        lng: 80.30199457368471,
      },
      {
        lat: 13.163695830355394,
        lng: 80.30691527173911,
      },
      {
        lat: 13.163144454406163,
        lng: 80.31147250651736,
      },
      {
        lat: 13.159906217829462,
        lng: 80.30852759520903,
      },
      {
        lat: 13.163575149699833,
        lng: 80.30572031649041,
      },
      {
        lat: 13.163011555140697,
        lng: 80.30320104713996,
      },
      {
        lat: 13.164249361450883,
        lng: 80.3078261695732,
      },
      {
        lat: 13.163960908369438,
        lng: 80.30638074581576,
      },
      {
        lat: 13.163041806855684,
        lng: 80.30730065295468,
      },
      {
        lat: 13.161644990931972,
        lng: 80.3060034889632,
      },
      {
        lat: 13.161266675536801,
        lng: 80.30795516382854,
      },
      {
        lat: 13.16008283462377,
        lng: 80.30690032496885,
      },
      {
        lat: 13.162495542704226,
        lng: 80.30840258169007,
      },
      {
        lat: 13.163007201067938,
        lng: 80.303721123633,
      },
      {
        lat: 13.165734220695196,
        lng: 80.30263195933695,
      },
      {
        lat: 13.161780346709614,
        lng: 80.30315914587143,
      },
      {
        lat: 13.1622053393488,
        lng: 80.30367097525624,
      },
      {
        lat: 13.162406172047207,
        lng: 80.30706935705399,
      },
      {
        lat: 13.161979223444858,
        lng: 80.31115784097443,
      },
      {
        lat: 13.162908978634594,
        lng: 80.30194931853244,
      },
      {
        lat: 13.162677167903796,
        lng: 80.30468509743186,
      },
      {
        lat: 13.162064206114517,
        lng: 80.30279863025926,
      },
      {
        lat: 13.16437975474606,
        lng: 80.30423437200915,
      },
      {
        lat: 13.163214749866997,
        lng: 80.3080870654139,
      },
      {
        lat: 13.16390946891839,
        lng: 80.3019794865264,
      },
      {
        lat: 13.16471376583248,
        lng: 80.30143426748016,
      },
      {
        lat: 13.16036217836921,
        lng: 80.30675599341484,
      },
      {
        lat: 13.1613412402514,
        lng: 80.30441296091672,
      },
      {
        lat: 13.160632786750009,
        lng: 80.30906839664377,
      },
    ],
    cases: 78,
    coords: [
      {
        lat: 13.159473460394606,
        lng: 80.31065679809063,
      },
      {
        lat: 13.160588528309587,
        lng: 80.3026556598042,
      },
      {
        lat: 13.161683927622994,
        lng: 80.30300037477632,
      },
      {
        lat: 13.162147626152088,
        lng: 80.30108492802385,
      },
      {
        lat: 13.16433698947339,
        lng: 80.30153837970191,
      },
      {
        lat: 13.165615054999945,
        lng: 80.30065439857084,
      },
      {
        lat: 13.166280652833857,
        lng: 80.30067236448511,
      },
      {
        lat: 13.165746373282007,
        lng: 80.3027064037902,
      },
      {
        lat: 13.16512859495953,
        lng: 80.30273523124966,
      },
      {
        lat: 13.164559640861992,
        lng: 80.30297603686942,
      },
      {
        lat: 13.16434976369095,
        lng: 80.30363858627302,
      },
      {
        lat: 13.164661738758856,
        lng: 80.30413152581603,
      },
      {
        lat: 13.165164417749487,
        lng: 80.30471724754788,
      },
      {
        lat: 13.163185144535662,
        lng: 80.31171236528448,
      },
      {
        lat: 13.159473460394606,
        lng: 80.31065679809063,
      },
    ],
    properties: {
      Ward_No: 9,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#f6d04d",
      zname: "THIRUVOTTIYUR(9)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.1681447231781,
        lng: 80.30138435022671,
      },
      {
        lat: 13.161567266759961,
        lng: 80.301587305989,
      },
      {
        lat: 13.161078256409931,
        lng: 80.3023620945564,
      },
      {
        lat: 13.158853513084479,
        lng: 80.29822134104973,
      },
      {
        lat: 13.166467629279403,
        lng: 80.29986988265334,
      },
      {
        lat: 13.158181052387892,
        lng: 80.30002865549012,
      },
      {
        lat: 13.161591101566769,
        lng: 80.3027887656215,
      },
      {
        lat: 13.160591944347091,
        lng: 80.29703170350554,
      },
      {
        lat: 13.159004765048198,
        lng: 80.29718250057934,
      },
      {
        lat: 13.15953250524387,
        lng: 80.30142848067005,
      },
      {
        lat: 13.159792408952779,
        lng: 80.2981315330518,
      },
      {
        lat: 13.164122725827637,
        lng: 80.30001739293023,
      },
      {
        lat: 13.161096573559282,
        lng: 80.29721089988406,
      },
      {
        lat: 13.164051888086,
        lng: 80.29971183198036,
      },
      {
        lat: 13.160774748261995,
        lng: 80.30057328103928,
      },
      {
        lat: 13.15989455986981,
        lng: 80.30228079538526,
      },
      {
        lat: 13.15817678791718,
        lng: 80.29766876834516,
      },
      {
        lat: 13.16056088728882,
        lng: 80.29937213878358,
      },
      {
        lat: 13.161693386284629,
        lng: 80.30159530275732,
      },
      {
        lat: 13.158079892272704,
        lng: 80.29942491847495,
      },
      {
        lat: 13.164823971230001,
        lng: 80.30037607254845,
      },
      {
        lat: 13.167749925515286,
        lng: 80.3002398054357,
      },
      {
        lat: 13.165146796103759,
        lng: 80.29901530723077,
      },
      {
        lat: 13.158531562018789,
        lng: 80.29631024874624,
      },
      {
        lat: 13.164122153917413,
        lng: 80.30028235894281,
      },
      {
        lat: 13.161497756612281,
        lng: 80.30201871111981,
      },
      {
        lat: 13.15945242957939,
        lng: 80.29728316140375,
      },
      {
        lat: 13.163724654328837,
        lng: 80.3005421298396,
      },
      {
        lat: 13.159008914098003,
        lng: 80.30089857794142,
      },
      {
        lat: 13.161719029757837,
        lng: 80.30129820610873,
      },
      {
        lat: 13.164952441901288,
        lng: 80.30078689996515,
      },
      {
        lat: 13.15901291587297,
        lng: 80.29903249985347,
      },
      {
        lat: 13.169466586193773,
        lng: 80.30118971737672,
      },
      {
        lat: 13.16331751607955,
        lng: 80.29882710840052,
      },
      {
        lat: 13.158564960915056,
        lng: 80.29636857357376,
      },
      {
        lat: 13.161997711151749,
        lng: 80.29982043731076,
      },
      {
        lat: 13.1583486593249,
        lng: 80.29788108054308,
      },
      {
        lat: 13.162528316441056,
        lng: 80.29872802441066,
      },
      {
        lat: 13.159189394569006,
        lng: 80.29883169643267,
      },
      {
        lat: 13.166592343898543,
        lng: 80.30021829859359,
      },
      {
        lat: 13.158916990515296,
        lng: 80.2995842692928,
      },
      {
        lat: 13.158012664605272,
        lng: 80.29968240015485,
      },
      {
        lat: 13.164214727950181,
        lng: 80.30076590383023,
      },
      {
        lat: 13.166292693518407,
        lng: 80.30061142992591,
      },
      {
        lat: 13.161920001246695,
        lng: 80.29889089176703,
      },
      {
        lat: 13.160961980136168,
        lng: 80.29765298017378,
      },
      {
        lat: 13.159923501838175,
        lng: 80.29797613925437,
      },
      {
        lat: 13.162266881807172,
        lng: 80.3006593341763,
      },
      {
        lat: 13.161788554869853,
        lng: 80.30118246111182,
      },
      {
        lat: 13.15907383802125,
        lng: 80.29911669991573,
      },
      {
        lat: 13.160592416520128,
        lng: 80.29822694077471,
      },
      {
        lat: 13.15930251794031,
        lng: 80.29750815977064,
      },
      {
        lat: 13.158858001128714,
        lng: 80.29789943658228,
      },
      {
        lat: 13.15949051132308,
        lng: 80.30063609032925,
      },
      {
        lat: 13.160980554529095,
        lng: 80.29774989096059,
      },
      {
        lat: 13.165821193381348,
        lng: 80.29969822846351,
      },
      {
        lat: 13.163343390080303,
        lng: 80.29892333593025,
      },
      {
        lat: 13.167383521467805,
        lng: 80.30097456709858,
      },
      {
        lat: 13.16371890140202,
        lng: 80.29858209260897,
      },
      {
        lat: 13.166251284724067,
        lng: 80.29953933965714,
      },
      {
        lat: 13.157795023626859,
        lng: 80.29844819100762,
      },
      {
        lat: 13.168466193702036,
        lng: 80.30053990514872,
      },
      {
        lat: 13.169693488292381,
        lng: 80.3012737911102,
      },
      {
        lat: 13.161979997751528,
        lng: 80.29776179036422,
      },
      {
        lat: 13.160192382320904,
        lng: 80.30234270204357,
      },
      {
        lat: 13.169001954859564,
        lng: 80.30138717109517,
      },
      {
        lat: 13.160138841972733,
        lng: 80.29795024951997,
      },
      {
        lat: 13.159329975752648,
        lng: 80.298286932191,
      },
      {
        lat: 13.165143400406087,
        lng: 80.30063214339557,
      },
      {
        lat: 13.158073519856332,
        lng: 80.29956655032115,
      },
      {
        lat: 13.168534184497373,
        lng: 80.30102189396166,
      },
      {
        lat: 13.157615251546954,
        lng: 80.29982890597385,
      },
      {
        lat: 13.169213002064044,
        lng: 80.30129793066229,
      },
      {
        lat: 13.164526333998285,
        lng: 80.29914909037572,
      },
      {
        lat: 13.15960292051366,
        lng: 80.29793772118853,
      },
      {
        lat: 13.15902562551661,
        lng: 80.2977087875184,
      },
      {
        lat: 13.160757868990283,
        lng: 80.30179676480947,
      },
      {
        lat: 13.161814289262862,
        lng: 80.29901952423347,
      },
      {
        lat: 13.168050337111865,
        lng: 80.30118090698302,
      },
      {
        lat: 13.15677639967878,
        lng: 80.29939829656495,
      },
      {
        lat: 13.16892908563043,
        lng: 80.3008867831802,
      },
      {
        lat: 13.162654162458534,
        lng: 80.29826347397686,
      },
      {
        lat: 13.158028896734994,
        lng: 80.29955033881234,
      },
      {
        lat: 13.16060618684517,
        lng: 80.30053961059676,
      },
      {
        lat: 13.160104781356145,
        lng: 80.30078723138301,
      },
      {
        lat: 13.163382634937287,
        lng: 80.29892385945391,
      },
      {
        lat: 13.163761536803777,
        lng: 80.30115363586738,
      },
      {
        lat: 13.160335141885202,
        lng: 80.30071622048202,
      },
      {
        lat: 13.159465221093692,
        lng: 80.2998957020902,
      },
      {
        lat: 13.159596431399368,
        lng: 80.29887445460636,
      },
      {
        lat: 13.161995233335668,
        lng: 80.29803474957481,
      },
      {
        lat: 13.162230987095626,
        lng: 80.2990199918172,
      },
      {
        lat: 13.157941661787348,
        lng: 80.29913753191849,
      },
      {
        lat: 13.159974349383747,
        lng: 80.3023317842591,
      },
      {
        lat: 13.162698240472023,
        lng: 80.29956291081957,
      },
      {
        lat: 13.160114125536923,
        lng: 80.30084108528867,
      },
      {
        lat: 13.160399593879914,
        lng: 80.29799258120939,
      },
      {
        lat: 13.16205238829202,
        lng: 80.29915119177265,
      },
      {
        lat: 13.158406304115099,
        lng: 80.29606272323326,
      },
      {
        lat: 13.159619257316397,
        lng: 80.30150803899713,
      },
      {
        lat: 13.157885088705557,
        lng: 80.2995017165616,
      },
      {
        lat: 13.163085995974587,
        lng: 80.29881687920692,
      },
      {
        lat: 13.167430610260787,
        lng: 80.30073216602578,
      },
      {
        lat: 13.161858374674598,
        lng: 80.30026282456166,
      },
      {
        lat: 13.160797533358654,
        lng: 80.30269187511894,
      },
      {
        lat: 13.165440087516338,
        lng: 80.29949314638843,
      },
      {
        lat: 13.16455289804138,
        lng: 80.30014228968989,
      },
      {
        lat: 13.167816179298471,
        lng: 80.30081623607204,
      },
      {
        lat: 13.158279623087168,
        lng: 80.29699147188897,
      },
      {
        lat: 13.15898054621572,
        lng: 80.29849908718533,
      },
      {
        lat: 13.157609701825232,
        lng: 80.29847231486885,
      },
      {
        lat: 13.162432200290139,
        lng: 80.2984576693366,
      },
      {
        lat: 13.163914431664512,
        lng: 80.30113489248757,
      },
      {
        lat: 13.161279569695214,
        lng: 80.30246177914125,
      },
      {
        lat: 13.161684891706345,
        lng: 80.29813510804907,
      },
      {
        lat: 13.164618422908896,
        lng: 80.30097583381766,
      },
      {
        lat: 13.164076427620525,
        lng: 80.29877116659873,
      },
      {
        lat: 13.168118265263804,
        lng: 80.30106433755014,
      },
      {
        lat: 13.160636384555739,
        lng: 80.30263081689017,
      },
      {
        lat: 13.159068269513813,
        lng: 80.3004395492621,
      },
      {
        lat: 13.162261235508641,
        lng: 80.29882820406111,
      },
      {
        lat: 13.162606026633307,
        lng: 80.2978914425402,
      },
      {
        lat: 13.164766001943413,
        lng: 80.3004657304299,
      },
      {
        lat: 13.162016140665289,
        lng: 80.29959829352573,
      },
      {
        lat: 13.163134077303761,
        lng: 80.30103608507218,
      },
      {
        lat: 13.15948289138639,
        lng: 80.29912699706568,
      },
      {
        lat: 13.159926688346347,
        lng: 80.30028054388389,
      },
      {
        lat: 13.161162084531146,
        lng: 80.30165878709471,
      },
      {
        lat: 13.158326279058256,
        lng: 80.30054817734322,
      },
      {
        lat: 13.160513868545584,
        lng: 80.30048573713825,
      },
      {
        lat: 13.159591082306367,
        lng: 80.30194563147069,
      },
      {
        lat: 13.164172195560775,
        lng: 80.30064546269071,
      },
      {
        lat: 13.162564804842326,
        lng: 80.29841608986156,
      },
      {
        lat: 13.158054354005532,
        lng: 80.29772551967233,
      },
      {
        lat: 13.166639534644212,
        lng: 80.29982550740444,
      },
      {
        lat: 13.158774443334721,
        lng: 80.30130539648201,
      },
      {
        lat: 13.162781155183172,
        lng: 80.30108161633794,
      },
      {
        lat: 13.16248864659488,
        lng: 80.30035691435695,
      },
      {
        lat: 13.160299465979278,
        lng: 80.29819506354207,
      },
      {
        lat: 13.162914778407584,
        lng: 80.29843438294603,
      },
      {
        lat: 13.160364206761395,
        lng: 80.29738862966789,
      },
      {
        lat: 13.159097153319877,
        lng: 80.30149392950972,
      },
      {
        lat: 13.159255315552594,
        lng: 80.30184752744013,
      },
      {
        lat: 13.16943009003882,
        lng: 80.30096583034508,
      },
      {
        lat: 13.160642487920704,
        lng: 80.30044545906128,
      },
      {
        lat: 13.161698320703724,
        lng: 80.29937689932757,
      },
      {
        lat: 13.164642522071073,
        lng: 80.29914950502163,
      },
      {
        lat: 13.16630205152918,
        lng: 80.30010983613705,
      },
      {
        lat: 13.164593357066016,
        lng: 80.29972463890007,
      },
      {
        lat: 13.161981166217172,
        lng: 80.29812383505514,
      },
      {
        lat: 13.158423673214108,
        lng: 80.29784969651516,
      },
      {
        lat: 13.165164092390844,
        lng: 80.29903468151709,
      },
      {
        lat: 13.16128118029879,
        lng: 80.29990164071069,
      },
    ],
    cases: 153,
    coords: [
      {
        lat: 13.160588528309587,
        lng: 80.3026556598042,
      },
      {
        lat: 13.158351179915668,
        lng: 80.30159309989646,
      },
      {
        lat: 13.157849632150343,
        lng: 80.30185519072785,
      },
      {
        lat: 13.157751960816821,
        lng: 80.30180767120129,
      },
      {
        lat: 13.157751274715,
        lng: 80.3016948693381,
      },
      {
        lat: 13.158214090452764,
        lng: 80.30040172189588,
      },
      {
        lat: 13.156540192640813,
        lng: 80.30004523475458,
      },
      {
        lat: 13.15725778042462,
        lng: 80.29794416203993,
      },
      {
        lat: 13.157945968031449,
        lng: 80.29802901332559,
      },
      {
        lat: 13.158385326213164,
        lng: 80.2960193764063,
      },
      {
        lat: 13.163508987075154,
        lng: 80.29826512054937,
      },
      {
        lat: 13.16983247285234,
        lng: 80.30112643593591,
      },
      {
        lat: 13.169579225932385,
        lng: 80.30173450056299,
      },
      {
        lat: 13.168234219800976,
        lng: 80.30143459930682,
      },
      {
        lat: 13.166280652833857,
        lng: 80.30067236448511,
      },
      {
        lat: 13.165615054999945,
        lng: 80.30065439857084,
      },
      {
        lat: 13.16433698947339,
        lng: 80.30153837970191,
      },
      {
        lat: 13.162147626152088,
        lng: 80.30108492802385,
      },
      {
        lat: 13.161683927622994,
        lng: 80.30300037477632,
      },
      {
        lat: 13.160588528309587,
        lng: 80.3026556598042,
      },
    ],
    properties: {
      Ward_No: 10,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#de4307",
      zname: "THIRUVOTTIYUR(10)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.160119887768458,
        lng: 80.30270226430588,
      },
      {
        lat: 13.158618879342464,
        lng: 80.30578140526069,
      },
      {
        lat: 13.158619245336624,
        lng: 80.30300146040965,
      },
      {
        lat: 13.158878465821665,
        lng: 80.3045127205783,
      },
      {
        lat: 13.15307318076564,
        lng: 80.30057020104721,
      },
      {
        lat: 13.152653314451507,
        lng: 80.30406947796368,
      },
      {
        lat: 13.1583590216298,
        lng: 80.30685152179834,
      },
      {
        lat: 13.154143995012708,
        lng: 80.30173534194519,
      },
      {
        lat: 13.151762422252757,
        lng: 80.3058102466168,
      },
      {
        lat: 13.158161029217423,
        lng: 80.30665034875484,
      },
      {
        lat: 13.159479412583527,
        lng: 80.310393232494,
      },
      {
        lat: 13.15593976875176,
        lng: 80.3029051100639,
      },
      {
        lat: 13.155260402838662,
        lng: 80.30905026857837,
      },
      {
        lat: 13.15825215794268,
        lng: 80.30247698308835,
      },
      {
        lat: 13.159730838548766,
        lng: 80.30785631792286,
      },
      {
        lat: 13.154180142616086,
        lng: 80.30046624422614,
      },
      {
        lat: 13.159470044518924,
        lng: 80.3085747208647,
      },
      {
        lat: 13.153913174342687,
        lng: 80.30864946747782,
      },
      {
        lat: 13.15919262627278,
        lng: 80.30227809278249,
      },
      {
        lat: 13.15631518254363,
        lng: 80.3036166256672,
      },
      {
        lat: 13.153954089782529,
        lng: 80.300301718268,
      },
      {
        lat: 13.152603692224828,
        lng: 80.3026496519134,
      },
      {
        lat: 13.157330896308478,
        lng: 80.30286723667082,
      },
      {
        lat: 13.156131737642497,
        lng: 80.3032216984023,
      },
      {
        lat: 13.158439944658024,
        lng: 80.30763296646671,
      },
      {
        lat: 13.158360078999479,
        lng: 80.30346315573519,
      },
      {
        lat: 13.153939528235934,
        lng: 80.30146983041145,
      },
      {
        lat: 13.155806511409748,
        lng: 80.30898224506893,
      },
      {
        lat: 13.15261621585303,
        lng: 80.30742471566388,
      },
      {
        lat: 13.155065512568086,
        lng: 80.30851979644517,
      },
      {
        lat: 13.15366526086655,
        lng: 80.29935517575394,
      },
      {
        lat: 13.15437002635378,
        lng: 80.30177110610609,
      },
      {
        lat: 13.157733784233523,
        lng: 80.30743273025729,
      },
      {
        lat: 13.155768894106828,
        lng: 80.29576768803778,
      },
      {
        lat: 13.157108566296417,
        lng: 80.30522356700105,
      },
      {
        lat: 13.152936218245207,
        lng: 80.30090036531298,
      },
      {
        lat: 13.159322548726903,
        lng: 80.30413061415992,
      },
      {
        lat: 13.157947885626934,
        lng: 80.30382668441061,
      },
      {
        lat: 13.155336165463062,
        lng: 80.30486124913355,
      },
      {
        lat: 13.152888734588586,
        lng: 80.3027062562402,
      },
      {
        lat: 13.155628653530645,
        lng: 80.30303689312179,
      },
      {
        lat: 13.1560846647382,
        lng: 80.2966403430861,
      },
      {
        lat: 13.15031795136382,
        lng: 80.3049925842919,
      },
      {
        lat: 13.158391606142683,
        lng: 80.30225061026495,
      },
      {
        lat: 13.153040874086702,
        lng: 80.30233775213718,
      },
      {
        lat: 13.156668985108938,
        lng: 80.30315494840626,
      },
      {
        lat: 13.152893676601481,
        lng: 80.3010038534059,
      },
      {
        lat: 13.15899004506531,
        lng: 80.30315441630601,
      },
      {
        lat: 13.15463467903351,
        lng: 80.30248033054856,
      },
      {
        lat: 13.155468900565655,
        lng: 80.29555572324487,
      },
      {
        lat: 13.155304085586486,
        lng: 80.30624260116282,
      },
      {
        lat: 13.152751797224454,
        lng: 80.30152973845917,
      },
      {
        lat: 13.156359158550954,
        lng: 80.29854208784113,
      },
      {
        lat: 13.154827306426197,
        lng: 80.298903156301,
      },
      {
        lat: 13.156706415478435,
        lng: 80.30573417891304,
      },
      {
        lat: 13.156587672348998,
        lng: 80.30114160776355,
      },
      {
        lat: 13.15653177183491,
        lng: 80.30181081511675,
      },
      {
        lat: 13.151149107250285,
        lng: 80.30777225799274,
      },
      {
        lat: 13.159977529544514,
        lng: 80.30444509125765,
      },
      {
        lat: 13.157983699082468,
        lng: 80.29612868172158,
      },
      {
        lat: 13.159334155885297,
        lng: 80.30364549114768,
      },
      {
        lat: 13.15537172960231,
        lng: 80.29726083511632,
      },
      {
        lat: 13.153069501136203,
        lng: 80.3075091990016,
      },
      {
        lat: 13.156866091635294,
        lng: 80.3030770322121,
      },
    ],
    cases: 64,
    coords: [
      {
        lat: 13.149087542666484,
        lng: 80.30741953398109,
      },
      {
        lat: 13.150611492746,
        lng: 80.30299349363617,
      },
      {
        lat: 13.15144908736658,
        lng: 80.30312965269037,
      },
      {
        lat: 13.152269257179189,
        lng: 80.30040100252027,
      },
      {
        lat: 13.152947952309344,
        lng: 80.29813194275845,
      },
      {
        lat: 13.154941913369148,
        lng: 80.29863075955397,
      },
      {
        lat: 13.155581475332156,
        lng: 80.29467170653596,
      },
      {
        lat: 13.158385326213164,
        lng: 80.2960193764063,
      },
      {
        lat: 13.157945968031449,
        lng: 80.29802901332559,
      },
      {
        lat: 13.15725778042462,
        lng: 80.29794416203993,
      },
      {
        lat: 13.156540192640813,
        lng: 80.30004523475458,
      },
      {
        lat: 13.158214090452764,
        lng: 80.30040172189588,
      },
      {
        lat: 13.157751274715,
        lng: 80.3016948693381,
      },
      {
        lat: 13.157751960816821,
        lng: 80.30180767120129,
      },
      {
        lat: 13.157849632150343,
        lng: 80.30185519072785,
      },
      {
        lat: 13.158351179915668,
        lng: 80.30159309989646,
      },
      {
        lat: 13.160588528309587,
        lng: 80.3026556598042,
      },
      {
        lat: 13.159473460394606,
        lng: 80.31065679809063,
      },
      {
        lat: 13.159344782615527,
        lng: 80.31062020337058,
      },
      {
        lat: 13.155679460940837,
        lng: 80.30965632895642,
      },
      {
        lat: 13.154020393370391,
        lng: 80.30906478182551,
      },
      {
        lat: 13.149087542666484,
        lng: 80.30741953398109,
      },
    ],
    properties: {
      Ward_No: 11,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#f6d04d",
      zname: "THIRUVOTTIYUR(11)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.148014160907987,
        lng: 80.29782684714995,
      },
      {
        lat: 13.147668448563396,
        lng: 80.29659230028948,
      },
      {
        lat: 13.152832417129064,
        lng: 80.29421729254528,
      },
      {
        lat: 13.148604247528656,
        lng: 80.29780052682891,
      },
      {
        lat: 13.14828846496827,
        lng: 80.29651765144048,
      },
      {
        lat: 13.15192579744872,
        lng: 80.29504273184669,
      },
      {
        lat: 13.151460465823034,
        lng: 80.29503364073234,
      },
      {
        lat: 13.152068409443674,
        lng: 80.29438924135252,
      },
      {
        lat: 13.15122485099025,
        lng: 80.2987436996082,
      },
      {
        lat: 13.152711946612655,
        lng: 80.29686441564458,
      },
      {
        lat: 13.154278371914705,
        lng: 80.29587963230762,
      },
      {
        lat: 13.154287515549184,
        lng: 80.29499926727011,
      },
      {
        lat: 13.151585849140742,
        lng: 80.29910503418927,
      },
      {
        lat: 13.153524555996595,
        lng: 80.29607882354888,
      },
      {
        lat: 13.154494148969126,
        lng: 80.29555291186607,
      },
      {
        lat: 13.154297493128084,
        lng: 80.29464332373873,
      },
      {
        lat: 13.154730729586738,
        lng: 80.2978973804965,
      },
      {
        lat: 13.149626204298263,
        lng: 80.29874556552024,
      },
      {
        lat: 13.15360923117685,
        lng: 80.29827332621265,
      },
      {
        lat: 13.151340427767574,
        lng: 80.29680163275079,
      },
      {
        lat: 13.154689510144841,
        lng: 80.29436245499912,
      },
      {
        lat: 13.153165744365065,
        lng: 80.29539853428463,
      },
      {
        lat: 13.154104704448429,
        lng: 80.29654301508965,
      },
      {
        lat: 13.154687797465527,
        lng: 80.29842110067563,
      },
      {
        lat: 13.152860210427116,
        lng: 80.29529135136752,
      },
      {
        lat: 13.150419678302418,
        lng: 80.29753682378981,
      },
      {
        lat: 13.148022091916683,
        lng: 80.295997482635,
      },
      {
        lat: 13.148447599960672,
        lng: 80.29836444541834,
      },
      {
        lat: 13.154344499876373,
        lng: 80.2983037704767,
      },
      {
        lat: 13.151058825605759,
        lng: 80.29583934330023,
      },
      {
        lat: 13.152673364176366,
        lng: 80.29438133566383,
      },
      {
        lat: 13.151025819494448,
        lng: 80.29846393091418,
      },
      {
        lat: 13.152608255379118,
        lng: 80.29834050164747,
      },
      {
        lat: 13.153847133129462,
        lng: 80.29602740248673,
      },
      {
        lat: 13.151853125992364,
        lng: 80.29495500804461,
      },
      {
        lat: 13.154156005588158,
        lng: 80.29801116464922,
      },
      {
        lat: 13.154824545944786,
        lng: 80.29480166640569,
      },
      {
        lat: 13.150455019023122,
        lng: 80.29752658176008,
      },
      {
        lat: 13.1496308195442,
        lng: 80.29920619213483,
      },
      {
        lat: 13.151454992749805,
        lng: 80.30015119866849,
      },
      {
        lat: 13.152651996021412,
        lng: 80.29602268714413,
      },
      {
        lat: 13.1535766098592,
        lng: 80.29416679166604,
      },
      {
        lat: 13.153327447429472,
        lng: 80.29464056035432,
      },
      {
        lat: 13.15438104774415,
        lng: 80.29764598083509,
      },
      {
        lat: 13.15400538525353,
        lng: 80.29654303339052,
      },
      {
        lat: 13.152946680701671,
        lng: 80.29529060985006,
      },
      {
        lat: 13.149099643006481,
        lng: 80.29764576078405,
      },
      {
        lat: 13.15030625264772,
        lng: 80.29839710881164,
      },
      {
        lat: 13.151887051155228,
        lng: 80.29847127553829,
      },
      {
        lat: 13.15135369696826,
        lng: 80.29310711635446,
      },
      {
        lat: 13.149960695251808,
        lng: 80.29970346893994,
      },
      {
        lat: 13.150359151147843,
        lng: 80.29764608974546,
      },
      {
        lat: 13.153022892061372,
        lng: 80.29505071847095,
      },
      {
        lat: 13.153103644216126,
        lng: 80.2955771889918,
      },
      {
        lat: 13.153872407793292,
        lng: 80.29705170132006,
      },
      {
        lat: 13.15136980199662,
        lng: 80.29716462375333,
      },
      {
        lat: 13.151779520742886,
        lng: 80.2937625019587,
      },
      {
        lat: 13.151369678904189,
        lng: 80.2994051222416,
      },
      {
        lat: 13.154562985660444,
        lng: 80.29703359465431,
      },
      {
        lat: 13.14960108903523,
        lng: 80.29919053585631,
      },
      {
        lat: 13.154996441770837,
        lng: 80.29532469198644,
      },
      {
        lat: 13.151001170632705,
        lng: 80.29614305857368,
      },
      {
        lat: 13.153818816078838,
        lng: 80.29453454897754,
      },
      {
        lat: 13.152386235986105,
        lng: 80.295680414059,
      },
      {
        lat: 13.153584801095738,
        lng: 80.294860470387,
      },
      {
        lat: 13.149092526803756,
        lng: 80.29919228589587,
      },
      {
        lat: 13.153565448410298,
        lng: 80.29723657186148,
      },
      {
        lat: 13.151633983644855,
        lng: 80.29985874204804,
      },
      {
        lat: 13.14843991928407,
        lng: 80.2980991228723,
      },
      {
        lat: 13.147594204436297,
        lng: 80.29785624083415,
      },
      {
        lat: 13.152212829225109,
        lng: 80.29390174936673,
      },
      {
        lat: 13.153820382966716,
        lng: 80.29589636733239,
      },
      {
        lat: 13.155030562413085,
        lng: 80.29658251118929,
      },
      {
        lat: 13.150698956698157,
        lng: 80.29883109350828,
      },
      {
        lat: 13.15322509915402,
        lng: 80.29705169576981,
      },
      {
        lat: 13.1527049598346,
        lng: 80.29766162259854,
      },
      {
        lat: 13.151797547475802,
        lng: 80.29677127085911,
      },
      {
        lat: 13.150567025128371,
        lng: 80.299004685849,
      },
      {
        lat: 13.15133499046359,
        lng: 80.29598475879209,
      },
      {
        lat: 13.154531509714559,
        lng: 80.29684546948515,
      },
      {
        lat: 13.152945557970739,
        lng: 80.29462067974598,
      },
      {
        lat: 13.15338120949563,
        lng: 80.2963740035066,
      },
      {
        lat: 13.15158976544215,
        lng: 80.29724585346001,
      },
      {
        lat: 13.149464583114886,
        lng: 80.29758842992604,
      },
      {
        lat: 13.155218438850872,
        lng: 80.29538313156822,
      },
      {
        lat: 13.151296587718283,
        lng: 80.29352786315881,
      },
      {
        lat: 13.151829953362666,
        lng: 80.29917840481495,
      },
      {
        lat: 13.152409032385707,
        lng: 80.29645972376156,
      },
      {
        lat: 13.152048810832532,
        lng: 80.29657083520402,
      },
      {
        lat: 13.147693788441856,
        lng: 80.2960698964329,
      },
      {
        lat: 13.151583149704837,
        lng: 80.29323290463961,
      },
      {
        lat: 13.14844813596335,
        lng: 80.2978016144471,
      },
      {
        lat: 13.152218907873158,
        lng: 80.29538493623093,
      },
      {
        lat: 13.152407514043238,
        lng: 80.29530280500518,
      },
      {
        lat: 13.153420997835713,
        lng: 80.29389259768485,
      },
      {
        lat: 13.151811332227291,
        lng: 80.29858840561884,
      },
      {
        lat: 13.14940861525809,
        lng: 80.2990594988675,
      },
      {
        lat: 13.15347213335058,
        lng: 80.29476523924515,
      },
      {
        lat: 13.150215798170445,
        lng: 80.29880670204868,
      },
      {
        lat: 13.153441498149299,
        lng: 80.2955771404816,
      },
      {
        lat: 13.14919610549291,
        lng: 80.2986725098717,
      },
      {
        lat: 13.151811071023264,
        lng: 80.29939191737368,
      },
      {
        lat: 13.151720613109104,
        lng: 80.29571788229157,
      },
      {
        lat: 13.1519225855098,
        lng: 80.29772495237827,
      },
      {
        lat: 13.147458755290971,
        lng: 80.29701565246926,
      },
      {
        lat: 13.152821292025903,
        lng: 80.29443522150846,
      },
      {
        lat: 13.152261090956435,
        lng: 80.29732859889816,
      },
      {
        lat: 13.155243658329988,
        lng: 80.29596882926015,
      },
      {
        lat: 13.152432075405777,
        lng: 80.29869656905694,
      },
      {
        lat: 13.152081523856317,
        lng: 80.29732986749875,
      },
      {
        lat: 13.150892026609142,
        lng: 80.2982551398781,
      },
      {
        lat: 13.153295747201799,
        lng: 80.29493625105916,
      },
      {
        lat: 13.15303845561589,
        lng: 80.29616859158803,
      },
      {
        lat: 13.152349757693926,
        lng: 80.29394240619615,
      },
      {
        lat: 13.154124536874567,
        lng: 80.29748769042325,
      },
      {
        lat: 13.153554124003044,
        lng: 80.29685144416,
      },
      {
        lat: 13.151846675527203,
        lng: 80.3000832338988,
      },
      {
        lat: 13.153368618637879,
        lng: 80.29676942227424,
      },
      {
        lat: 13.154975961906123,
        lng: 80.29794511319369,
      },
      {
        lat: 13.152087777639144,
        lng: 80.29662829705201,
      },
      {
        lat: 13.153785450687046,
        lng: 80.29734417681786,
      },
      {
        lat: 13.152813434979736,
        lng: 80.29748611886453,
      },
      {
        lat: 13.154146771116961,
        lng: 80.29539805491548,
      },
      {
        lat: 13.153323239237466,
        lng: 80.29747983063619,
      },
      {
        lat: 13.154734555649053,
        lng: 80.29557069554589,
      },
      {
        lat: 13.154989450572211,
        lng: 80.29641352451777,
      },
      {
        lat: 13.154792293856916,
        lng: 80.2960957858951,
      },
      {
        lat: 13.152493289350003,
        lng: 80.29802493639481,
      },
      {
        lat: 13.152748165021327,
        lng: 80.2972319871316,
      },
      {
        lat: 13.155015545632464,
        lng: 80.29546171181464,
      },
      {
        lat: 13.151806778346334,
        lng: 80.29595435735325,
      },
      {
        lat: 13.153152731574853,
        lng: 80.2944866122471,
      },
      {
        lat: 13.147600836091005,
        lng: 80.2960946203464,
      },
      {
        lat: 13.152047976096332,
        lng: 80.29776186090828,
      },
      {
        lat: 13.147752138677278,
        lng: 80.29793850054841,
      },
      {
        lat: 13.152523182081627,
        lng: 80.29547266231843,
      },
      {
        lat: 13.152345628268884,
        lng: 80.29556671313331,
      },
      {
        lat: 13.153380696966517,
        lng: 80.29519961655453,
      },
      {
        lat: 13.150617183373502,
        lng: 80.2991618435194,
      },
      {
        lat: 13.151799369342228,
        lng: 80.29824092736598,
      },
      {
        lat: 13.152296542437139,
        lng: 80.29717756449902,
      },
      {
        lat: 13.152453009993618,
        lng: 80.2978464037506,
      },
      {
        lat: 13.154882768258673,
        lng: 80.29742944192546,
      },
      {
        lat: 13.151679065010525,
        lng: 80.29697728621093,
      },
      {
        lat: 13.152900966645742,
        lng: 80.29827508117235,
      },
      {
        lat: 13.154537997726008,
        lng: 80.29541719673105,
      },
    ],
    cases: 146,
    coords: [
      {
        lat: 13.152269257179189,
        lng: 80.30040100252027,
      },
      {
        lat: 13.14982859047309,
        lng: 80.29972033776507,
      },
      {
        lat: 13.149368054339966,
        lng: 80.29954681456998,
      },
      {
        lat: 13.147852652797557,
        lng: 80.29801354398678,
      },
      {
        lat: 13.147135629510322,
        lng: 80.2976394248444,
      },
      {
        lat: 13.147458210326183,
        lng: 80.2961159447729,
      },
      {
        lat: 13.147635808985969,
        lng: 80.2959370208778,
      },
      {
        lat: 13.147839395501277,
        lng: 80.2958339145399,
      },
      {
        lat: 13.148274097933554,
        lng: 80.29595696470595,
      },
      {
        lat: 13.148454008912454,
        lng: 80.29615820873755,
      },
      {
        lat: 13.148329619200515,
        lng: 80.29669152397508,
      },
      {
        lat: 13.148434033086476,
        lng: 80.29707082778991,
      },
      {
        lat: 13.148818455508394,
        lng: 80.2973210326543,
      },
      {
        lat: 13.149969564609298,
        lng: 80.29771682384911,
      },
      {
        lat: 13.150173305279218,
        lng: 80.29763906203962,
      },
      {
        lat: 13.150274636073075,
        lng: 80.29751147528538,
      },
      {
        lat: 13.150500664887865,
        lng: 80.29690126235035,
      },
      {
        lat: 13.151029452847371,
        lng: 80.2957055329249,
      },
      {
        lat: 13.151097396364529,
        lng: 80.2942855912475,
      },
      {
        lat: 13.151369851324647,
        lng: 80.29291461040287,
      },
      {
        lat: 13.155581475332156,
        lng: 80.29467170653596,
      },
      {
        lat: 13.154941913369148,
        lng: 80.29863075955397,
      },
      {
        lat: 13.152947952309344,
        lng: 80.29813194275845,
      },
      {
        lat: 13.152269257179189,
        lng: 80.30040100252027,
      },
    ],
    properties: {
      Ward_No: 12,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#de4307",
      zname: "THIRUVOTTIYUR(12)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.145780916261351,
        lng: 80.29155433031727,
      },
      {
        lat: 13.145932843791108,
        lng: 80.29179103312762,
      },
      {
        lat: 13.149612530345747,
        lng: 80.29506272513456,
      },
      {
        lat: 13.148798243085995,
        lng: 80.29166625524954,
      },
      {
        lat: 13.147004614233348,
        lng: 80.29751568936099,
      },
      {
        lat: 13.147691897205556,
        lng: 80.29483418783747,
      },
      {
        lat: 13.147647970996912,
        lng: 80.29573955893623,
      },
      {
        lat: 13.150709577232185,
        lng: 80.29347685085291,
      },
      {
        lat: 13.14940782456822,
        lng: 80.29667678434235,
      },
      {
        lat: 13.14533022215971,
        lng: 80.2937544684667,
      },
      {
        lat: 13.147604546921384,
        lng: 80.29589671676206,
      },
      {
        lat: 13.146528946430797,
        lng: 80.29369858637062,
      },
      {
        lat: 13.146733197378666,
        lng: 80.29176761517786,
      },
      {
        lat: 13.147021126416634,
        lng: 80.29509134240098,
      },
      {
        lat: 13.148454394306212,
        lng: 80.29159632225135,
      },
      {
        lat: 13.147451985445322,
        lng: 80.29236422400052,
      },
      {
        lat: 13.146635751547816,
        lng: 80.2951098831636,
      },
      {
        lat: 13.14643641169549,
        lng: 80.29222902783097,
      },
      {
        lat: 13.146950163470247,
        lng: 80.29316263514282,
      },
      {
        lat: 13.146243586182328,
        lng: 80.29348470525636,
      },
      {
        lat: 13.145282178306537,
        lng: 80.29042802133935,
      },
      {
        lat: 13.149467428448766,
        lng: 80.29564350045301,
      },
      {
        lat: 13.147084245222432,
        lng: 80.29420666545704,
      },
      {
        lat: 13.145813405376922,
        lng: 80.29469374958819,
      },
    ],
    cases: 24,
    coords: [
      {
        lat: 13.147135629510322,
        lng: 80.2976394248444,
      },
      {
        lat: 13.146573145964961,
        lng: 80.2974921102898,
      },
      {
        lat: 13.145241817575759,
        lng: 80.29684438600651,
      },
      {
        lat: 13.145539181309083,
        lng: 80.29537181102023,
      },
      {
        lat: 13.14451835568363,
        lng: 80.29496341442571,
      },
      {
        lat: 13.144675719496918,
        lng: 80.29392605800977,
      },
      {
        lat: 13.144527291076237,
        lng: 80.2931427602609,
      },
      {
        lat: 13.14423498903543,
        lng: 80.29232501860936,
      },
      {
        lat: 13.144301178121104,
        lng: 80.2913972583263,
      },
      {
        lat: 13.144475391714085,
        lng: 80.29051494380943,
      },
      {
        lat: 13.144802753182615,
        lng: 80.28984598299421,
      },
      {
        lat: 13.145636902273912,
        lng: 80.29045389236005,
      },
      {
        lat: 13.147785197352395,
        lng: 80.29112004827557,
      },
      {
        lat: 13.151369851324647,
        lng: 80.29291461040287,
      },
      {
        lat: 13.151097396364529,
        lng: 80.2942855912475,
      },
      {
        lat: 13.151029452847371,
        lng: 80.2957055329249,
      },
      {
        lat: 13.150500664887865,
        lng: 80.29690126235035,
      },
      {
        lat: 13.150274636073075,
        lng: 80.29751147528538,
      },
      {
        lat: 13.150173305279218,
        lng: 80.29763906203962,
      },
      {
        lat: 13.149969564609298,
        lng: 80.29771682384911,
      },
      {
        lat: 13.148818455508394,
        lng: 80.2973210326543,
      },
      {
        lat: 13.148434033086476,
        lng: 80.29707082778991,
      },
      {
        lat: 13.148329619200515,
        lng: 80.29669152397508,
      },
      {
        lat: 13.148454008912454,
        lng: 80.29615820873755,
      },
      {
        lat: 13.148274097933554,
        lng: 80.29595696470595,
      },
      {
        lat: 13.147839395501277,
        lng: 80.2958339145399,
      },
      {
        lat: 13.147635808985969,
        lng: 80.2959370208778,
      },
      {
        lat: 13.147458210326183,
        lng: 80.2961159447729,
      },
      {
        lat: 13.147135629510322,
        lng: 80.2976394248444,
      },
    ],
    properties: {
      Ward_No: 13,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#8bc24c",
      zname: "THIRUVOTTIYUR(13)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.150714328813391,
        lng: 80.30107230565294,
      },
      {
        lat: 13.144026557366113,
        lng: 80.29783874012625,
      },
      {
        lat: 13.14358776053897,
        lng: 80.30150722045488,
      },
      {
        lat: 13.14584429094043,
        lng: 80.30133006359098,
      },
      {
        lat: 13.147478546598975,
        lng: 80.29984525940391,
      },
      {
        lat: 13.144388284323053,
        lng: 80.29857798994118,
      },
      {
        lat: 13.144081651692256,
        lng: 80.29986785263696,
      },
      {
        lat: 13.14531317587089,
        lng: 80.29648099896771,
      },
      {
        lat: 13.151564072686872,
        lng: 80.30214445460088,
      },
      {
        lat: 13.150439169002825,
        lng: 80.30016253294374,
      },
      {
        lat: 13.144755057787743,
        lng: 80.2981069798597,
      },
      {
        lat: 13.151430692392344,
        lng: 80.30307438350309,
      },
      {
        lat: 13.14684049455411,
        lng: 80.30295334207496,
      },
      {
        lat: 13.147975082564853,
        lng: 80.30293104407463,
      },
      {
        lat: 13.147249263189233,
        lng: 80.29887340498291,
      },
      {
        lat: 13.146388806718738,
        lng: 80.30010107578578,
      },
      {
        lat: 13.14589702402964,
        lng: 80.29832498155267,
      },
      {
        lat: 13.147693148840638,
        lng: 80.30010134878933,
      },
    ],
    cases: 18,
    coords: [
      {
        lat: 13.14451835568363,
        lng: 80.29496341442571,
      },
      {
        lat: 13.145539181309083,
        lng: 80.29537181102023,
      },
      {
        lat: 13.145241817575759,
        lng: 80.29684438600651,
      },
      {
        lat: 13.146573145964961,
        lng: 80.2974921102898,
      },
      {
        lat: 13.147135629510322,
        lng: 80.2976394248444,
      },
      {
        lat: 13.147852652797557,
        lng: 80.29801354398678,
      },
      {
        lat: 13.149368054339966,
        lng: 80.29954681456998,
      },
      {
        lat: 13.14982859047309,
        lng: 80.29972033776507,
      },
      {
        lat: 13.152269257179189,
        lng: 80.30040100252027,
      },
      {
        lat: 13.15144908736658,
        lng: 80.30312965269037,
      },
      {
        lat: 13.150611492746,
        lng: 80.30299349363617,
      },
      {
        lat: 13.149087542666484,
        lng: 80.30741953398109,
      },
      {
        lat: 13.146336219693753,
        lng: 80.30633908194642,
      },
      {
        lat: 13.142253520696434,
        lng: 80.30450776838703,
      },
      {
        lat: 13.143379429080166,
        lng: 80.29928626024136,
      },
      {
        lat: 13.143592094529334,
        lng: 80.29882086034476,
      },
      {
        lat: 13.143764074101457,
        lng: 80.29757125084608,
      },
      {
        lat: 13.144399467705574,
        lng: 80.29574713467282,
      },
      {
        lat: 13.14451835568363,
        lng: 80.29496341442571,
      },
    ],
    properties: {
      Ward_No: 14,
      Zone_Name: "THIRUVOTTIYUR",
      fillColor: "#8bc24c",
      zname: "THIRUVOTTIYUR(14)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.164737883533624,
        lng: 80.20604042371488,
      },
      {
        lat: 13.170744070802105,
        lng: 80.19942537830002,
      },
      {
        lat: 13.171257046890625,
        lng: 80.20841409036153,
      },
      {
        lat: 13.167309713756474,
        lng: 80.21473867095031,
      },
      {
        lat: 13.171883232360758,
        lng: 80.19518627373844,
      },
      {
        lat: 13.176324666126293,
        lng: 80.20039566758138,
      },
      {
        lat: 13.168139959795399,
        lng: 80.22070570821927,
      },
      {
        lat: 13.167403782824744,
        lng: 80.20774472491475,
      },
      {
        lat: 13.161560788736542,
        lng: 80.19824648501053,
      },
      {
        lat: 13.16868038200874,
        lng: 80.200959824445,
      },
      {
        lat: 13.168717090958445,
        lng: 80.22211510188745,
      },
      {
        lat: 13.169970802962943,
        lng: 80.19340063247923,
      },
      {
        lat: 13.162509736948703,
        lng: 80.2025584511576,
      },
      {
        lat: 13.174823678868822,
        lng: 80.20329884547792,
      },
      {
        lat: 13.166302370125623,
        lng: 80.22567202538677,
      },
      {
        lat: 13.170675550703047,
        lng: 80.19665343161283,
      },
      {
        lat: 13.168732059357792,
        lng: 80.20097522193615,
      },
      {
        lat: 13.170938888803407,
        lng: 80.2157494032042,
      },
      {
        lat: 13.167837007490766,
        lng: 80.2104293093822,
      },
      {
        lat: 13.172639697366504,
        lng: 80.1915502696678,
      },
      {
        lat: 13.171356899064671,
        lng: 80.2167685727899,
      },
      {
        lat: 13.174398737649057,
        lng: 80.1952656450494,
      },
      {
        lat: 13.167819002233388,
        lng: 80.19847704858053,
      },
      {
        lat: 13.174401890212936,
        lng: 80.20852656889376,
      },
      {
        lat: 13.164920368228733,
        lng: 80.19899857236571,
      },
      {
        lat: 13.170410272455662,
        lng: 80.1925401762173,
      },
      {
        lat: 13.172548689935427,
        lng: 80.19912611810132,
      },
      {
        lat: 13.175511855088857,
        lng: 80.20072178816986,
      },
      {
        lat: 13.167484658265675,
        lng: 80.19883645682472,
      },
      {
        lat: 13.174361183664244,
        lng: 80.20762886120994,
      },
      {
        lat: 13.169623845393001,
        lng: 80.22091985861321,
      },
      {
        lat: 13.169447365041522,
        lng: 80.22369565547277,
      },
      {
        lat: 13.161093481397744,
        lng: 80.1924194368118,
      },
      {
        lat: 13.16692454158887,
        lng: 80.19877191007612,
      },
      {
        lat: 13.172607592731675,
        lng: 80.19606934141268,
      },
      {
        lat: 13.176948313001928,
        lng: 80.20089600834082,
      },
      {
        lat: 13.165986985987866,
        lng: 80.2010961393582,
      },
      {
        lat: 13.173872506394959,
        lng: 80.20379749245173,
      },
      {
        lat: 13.170645108743301,
        lng: 80.20707716698628,
      },
      {
        lat: 13.17325828457505,
        lng: 80.19468826150568,
      },
      {
        lat: 13.162585601733012,
        lng: 80.20102740932906,
      },
      {
        lat: 13.172360227954515,
        lng: 80.19561819988004,
      },
      {
        lat: 13.163851865529628,
        lng: 80.19650438582873,
      },
      {
        lat: 13.169320194186895,
        lng: 80.19123184523411,
      },
      {
        lat: 13.170300749808876,
        lng: 80.20989425596171,
      },
      {
        lat: 13.175555332427166,
        lng: 80.20056637746785,
      },
      {
        lat: 13.171784333291873,
        lng: 80.20449177233495,
      },
      {
        lat: 13.16923222279182,
        lng: 80.21446255057553,
      },
      {
        lat: 13.170566210461457,
        lng: 80.2240364757849,
      },
      {
        lat: 13.171378627105295,
        lng: 80.20175716512615,
      },
      {
        lat: 13.171677045260118,
        lng: 80.21168877169039,
      },
      {
        lat: 13.170835013774942,
        lng: 80.20846568528115,
      },
      {
        lat: 13.166684258008043,
        lng: 80.20332354904714,
      },
      {
        lat: 13.169506778414359,
        lng: 80.21640693573947,
      },
      {
        lat: 13.174380055822564,
        lng: 80.19555981070414,
      },
      {
        lat: 13.167949723308674,
        lng: 80.1915081639492,
      },
      {
        lat: 13.162725715467431,
        lng: 80.20077498822252,
      },
      {
        lat: 13.170158918851468,
        lng: 80.20681227909715,
      },
      {
        lat: 13.166997415570112,
        lng: 80.19297626498475,
      },
      {
        lat: 13.165031615955852,
        lng: 80.201007516268,
      },
      {
        lat: 13.171061435112712,
        lng: 80.21395492960646,
      },
      {
        lat: 13.161265372410591,
        lng: 80.19722622617395,
      },
      {
        lat: 13.16697847857451,
        lng: 80.22576537868171,
      },
      {
        lat: 13.172688979189992,
        lng: 80.2068494538021,
      },
      {
        lat: 13.172021084834201,
        lng: 80.1915113350965,
      },
      {
        lat: 13.166562700860625,
        lng: 80.19361839465856,
      },
      {
        lat: 13.162179658185766,
        lng: 80.19349957859686,
      },
      {
        lat: 13.162100305756567,
        lng: 80.19385765375372,
      },
      {
        lat: 13.168000709609053,
        lng: 80.1966483418689,
      },
      {
        lat: 13.1708543235869,
        lng: 80.21285770163563,
      },
      {
        lat: 13.165977678197407,
        lng: 80.20137873559011,
      },
      {
        lat: 13.16848339338877,
        lng: 80.20816690078846,
      },
      {
        lat: 13.167768728415124,
        lng: 80.22172291650627,
      },
      {
        lat: 13.161619548378095,
        lng: 80.20169411047229,
      },
      {
        lat: 13.168749027034092,
        lng: 80.21460046296386,
      },
      {
        lat: 13.172596455314558,
        lng: 80.19359828922788,
      },
      {
        lat: 13.17281736552045,
        lng: 80.19164059284839,
      },
      {
        lat: 13.166952527508627,
        lng: 80.22063733921871,
      },
      {
        lat: 13.163356723269148,
        lng: 80.19591758341156,
      },
      {
        lat: 13.161889619830136,
        lng: 80.19475314738864,
      },
      {
        lat: 13.168539097340314,
        lng: 80.22522269735147,
      },
      {
        lat: 13.17340127459811,
        lng: 80.19740825302415,
      },
      {
        lat: 13.178672864413205,
        lng: 80.20138554399027,
      },
      {
        lat: 13.167381237813194,
        lng: 80.22415576999063,
      },
      {
        lat: 13.172385489415534,
        lng: 80.19264795763557,
      },
      {
        lat: 13.162235703980327,
        lng: 80.19588633131663,
      },
      {
        lat: 13.16743409787551,
        lng: 80.20568834821734,
      },
      {
        lat: 13.16490676912124,
        lng: 80.20531265156949,
      },
      {
        lat: 13.162661248946112,
        lng: 80.20185916627024,
      },
      {
        lat: 13.165556473712362,
        lng: 80.20680853580632,
      },
      {
        lat: 13.17295658462027,
        lng: 80.21101747520817,
      },
      {
        lat: 13.17201356283292,
        lng: 80.19882927017818,
      },
      {
        lat: 13.170832221502947,
        lng: 80.22390401939133,
      },
      {
        lat: 13.171057434559518,
        lng: 80.21214492052394,
      },
      {
        lat: 13.169753468142897,
        lng: 80.19968618915493,
      },
      {
        lat: 13.165109891058687,
        lng: 80.22464740930765,
      },
      {
        lat: 13.167770440758401,
        lng: 80.20440429234358,
      },
      {
        lat: 13.170118539224381,
        lng: 80.2245948428892,
      },
      {
        lat: 13.168759674799682,
        lng: 80.19688072572748,
      },
      {
        lat: 13.167995749422726,
        lng: 80.21185720233038,
      },
      {
        lat: 13.16912590672943,
        lng: 80.21169505467468,
      },
      {
        lat: 13.16983601505397,
        lng: 80.22557585383544,
      },
      {
        lat: 13.165260313512634,
        lng: 80.22149341876654,
      },
      {
        lat: 13.16245367287316,
        lng: 80.20093450750555,
      },
      {
        lat: 13.162333956157251,
        lng: 80.19777518596115,
      },
      {
        lat: 13.170845250235955,
        lng: 80.20014351210493,
      },
      {
        lat: 13.172325809021368,
        lng: 80.1925569874625,
      },
      {
        lat: 13.166382794584228,
        lng: 80.22080794815955,
      },
      {
        lat: 13.170913471748111,
        lng: 80.21400965080963,
      },
      {
        lat: 13.167640714503756,
        lng: 80.19744660393012,
      },
      {
        lat: 13.16459243368599,
        lng: 80.19631504181825,
      },
    ],
    cases: 111,
    coords: [
      {
        lat: 13.164818503768792,
        lng: 80.22565242806901,
      },
      {
        lat: 13.164840678838216,
        lng: 80.21811410309539,
      },
      {
        lat: 13.1678310139005,
        lng: 80.21792223593198,
      },
      {
        lat: 13.167193177393077,
        lng: 80.21418005250747,
      },
      {
        lat: 13.165495187847363,
        lng: 80.21080771966119,
      },
      {
        lat: 13.164843239438413,
        lng: 80.20934196449323,
      },
      {
        lat: 13.164667564961109,
        lng: 80.20803873767767,
      },
      {
        lat: 13.16454476446858,
        lng: 80.20623538582923,
      },
      {
        lat: 13.164399747905021,
        lng: 80.2053760573672,
      },
      {
        lat: 13.164369427664763,
        lng: 80.20498767588336,
      },
      {
        lat: 13.164478552371714,
        lng: 80.20454259451338,
      },
      {
        lat: 13.164615464765765,
        lng: 80.20406951810531,
      },
      {
        lat: 13.164473656102738,
        lng: 80.20373759990426,
      },
      {
        lat: 13.164106675714741,
        lng: 80.2031577486167,
      },
      {
        lat: 13.163826435125909,
        lng: 80.20304908091052,
      },
      {
        lat: 13.16206703131037,
        lng: 80.20336932970206,
      },
      {
        lat: 13.161815084919292,
        lng: 80.20331594226242,
      },
      {
        lat: 13.160741354537391,
        lng: 80.19685698127755,
      },
      {
        lat: 13.159450795486068,
        lng: 80.19219701701923,
      },
      {
        lat: 13.16115290426997,
        lng: 80.19210643952236,
      },
      {
        lat: 13.161516188807033,
        lng: 80.19216342599836,
      },
      {
        lat: 13.161799920506226,
        lng: 80.19240126889284,
      },
      {
        lat: 13.162693997023888,
        lng: 80.19353486199036,
      },
      {
        lat: 13.162855903288925,
        lng: 80.19363359348124,
      },
      {
        lat: 13.163299714781873,
        lng: 80.19366987808769,
      },
      {
        lat: 13.164513342063248,
        lng: 80.19430025801665,
      },
      {
        lat: 13.164775328640594,
        lng: 80.19427802040732,
      },
      {
        lat: 13.164976585147874,
        lng: 80.19421625607389,
      },
      {
        lat: 13.165236258195003,
        lng: 80.1938136514115,
      },
      {
        lat: 13.165628664735197,
        lng: 80.19202851930264,
      },
      {
        lat: 13.165788257469954,
        lng: 80.19174688374044,
      },
      {
        lat: 13.165968986502737,
        lng: 80.19162523208915,
      },
      {
        lat: 13.166169512421567,
        lng: 80.19144335184426,
      },
      {
        lat: 13.166664191039663,
        lng: 80.18989759836961,
      },
      {
        lat: 13.166925203498835,
        lng: 80.18971520621156,
      },
      {
        lat: 13.167187433605738,
        lng: 80.18973300723921,
      },
      {
        lat: 13.172822788343774,
        lng: 80.19134700274653,
      },
      {
        lat: 13.17484022400704,
        lng: 80.19153013215636,
      },
      {
        lat: 13.17536565833864,
        lng: 80.19172588876043,
      },
      {
        lat: 13.175426997232234,
        lng: 80.1918655120773,
      },
      {
        lat: 13.17444550393651,
        lng: 80.19293489721227,
      },
      {
        lat: 13.173901490366495,
        lng: 80.19299956238741,
      },
      {
        lat: 13.173761329223302,
        lng: 80.19316091140035,
      },
      {
        lat: 13.173560316245696,
        lng: 80.19326271437093,
      },
      {
        lat: 13.173338045204911,
        lng: 80.19318451411199,
      },
      {
        lat: 13.172954963781851,
        lng: 80.19318775622939,
      },
      {
        lat: 13.17265435755088,
        lng: 80.19349060557441,
      },
      {
        lat: 13.172615494367578,
        lng: 80.19373117867292,
      },
      {
        lat: 13.17265654931641,
        lng: 80.19385095330883,
      },
      {
        lat: 13.17291902295259,
        lng: 80.19390879297379,
      },
      {
        lat: 13.17396575161435,
        lng: 80.1936196493502,
      },
      {
        lat: 13.174570129960008,
        lng: 80.1935344529431,
      },
      {
        lat: 13.175357916163039,
        lng: 80.19376802989346,
      },
      {
        lat: 13.17568221575222,
        lng: 80.1940455701494,
      },
      {
        lat: 13.17574501582283,
        lng: 80.194425425289,
      },
      {
        lat: 13.175606315856891,
        lng: 80.19482700612512,
      },
      {
        lat: 13.175144168654963,
        lng: 80.19509118193501,
      },
      {
        lat: 13.17498506297927,
        lng: 80.1954528947715,
      },
      {
        lat: 13.174916500144967,
        lng: 80.19612430492063,
      },
      {
        lat: 13.174139868568384,
        lng: 80.19724914039854,
      },
      {
        lat: 13.173972188994489,
        lng: 80.1978583109509,
      },
      {
        lat: 13.17392440727782,
        lng: 80.19805319579963,
      },
      {
        lat: 13.174072927352027,
        lng: 80.19831934946792,
      },
      {
        lat: 13.174444301465668,
        lng: 80.1989968880415,
      },
      {
        lat: 13.174617008108346,
        lng: 80.19921421689753,
      },
      {
        lat: 13.174837348612893,
        lng: 80.19921235209867,
      },
      {
        lat: 13.175154878955718,
        lng: 80.1990881144698,
      },
      {
        lat: 13.175545560423602,
        lng: 80.19891463762904,
      },
      {
        lat: 13.175864421461208,
        lng: 80.19900917925526,
      },
      {
        lat: 13.176061610382298,
        lng: 80.19922630091143,
      },
      {
        lat: 13.176333133267923,
        lng: 80.19958865380477,
      },
      {
        lat: 13.176554952322334,
        lng: 80.1998298770673,
      },
      {
        lat: 13.17675154982354,
        lng: 80.19994976349895,
      },
      {
        lat: 13.176971742473116,
        lng: 80.19992358989408,
      },
      {
        lat: 13.177340455197081,
        lng: 80.20016356995741,
      },
      {
        lat: 13.179079879795307,
        lng: 80.20034332921549,
      },
      {
        lat: 13.17935007198626,
        lng: 80.20048690285374,
      },
      {
        lat: 13.179400219382604,
        lng: 80.20068095890304,
      },
      {
        lat: 13.179327659677595,
        lng: 80.20082743333951,
      },
      {
        lat: 13.179182244557566,
        lng: 80.20107176459992,
      },
      {
        lat: 13.178891266462495,
        lng: 80.20153611831503,
      },
      {
        lat: 13.178598661962708,
        lng: 80.20173307516251,
      },
      {
        lat: 13.178280688054972,
        lng: 80.20178438637308,
      },
      {
        lat: 13.178059756130548,
        lng: 80.2016890159474,
      },
      {
        lat: 13.17761863155638,
        lng: 80.20161981912658,
      },
      {
        lat: 13.177054504170963,
        lng: 80.2014544230807,
      },
      {
        lat: 13.176834607231324,
        lng: 80.20152921429786,
      },
      {
        lat: 13.176713378679713,
        lng: 80.20172472074631,
      },
      {
        lat: 13.176666631947665,
        lng: 80.20208976723796,
      },
      {
        lat: 13.176569737819271,
        lng: 80.20226075768048,
      },
      {
        lat: 13.176228612327849,
        lng: 80.20253105534609,
      },
      {
        lat: 13.17584133152442,
        lng: 80.20326363472788,
      },
      {
        lat: 13.175360261546855,
        lng: 80.20467768948095,
      },
      {
        lat: 13.175240955109615,
        lng: 80.20518921040905,
      },
      {
        lat: 13.174634516641236,
        lng: 80.2061181250388,
      },
      {
        lat: 13.174465210662847,
        lng: 80.20645989872378,
      },
      {
        lat: 13.174468759182114,
        lng: 80.20704331007082,
      },
      {
        lat: 13.174495459285062,
        lng: 80.2074077349631,
      },
      {
        lat: 13.174622454180476,
        lng: 80.20816027195363,
      },
      {
        lat: 13.174723044683107,
        lng: 80.2085970016646,
      },
      {
        lat: 13.174699597389482,
        lng: 80.20876737050735,
      },
      {
        lat: 13.174578368837873,
        lng: 80.20896287695581,
      },
      {
        lat: 13.174287095032861,
        lng: 80.20937861305842,
      },
      {
        lat: 13.174000848296977,
        lng: 80.21062084856949,
      },
      {
        lat: 13.172952473304381,
        lng: 80.21335974069254,
      },
      {
        lat: 13.172324361802232,
        lng: 80.21475072983863,
      },
      {
        lat: 13.17227761507018,
        lng: 80.21511577633045,
      },
      {
        lat: 13.172302836623214,
        lng: 80.21523711316117,
      },
      {
        lat: 13.172161265732333,
        lng: 80.21611347338111,
      },
      {
        lat: 13.172041220020478,
        lng: 80.21650345027862,
      },
      {
        lat: 13.172115997550202,
        lng: 80.21672160793413,
      },
      {
        lat: 13.17228752135313,
        lng: 80.21674446634124,
      },
      {
        lat: 13.172337816604443,
        lng: 80.21696283119667,
      },
      {
        lat: 13.172290330597546,
        lng: 80.21720633365753,
      },
      {
        lat: 13.172193584324118,
        lng: 80.21740163290615,
      },
      {
        lat: 13.171925462102733,
        lng: 80.21759838255376,
      },
      {
        lat: 13.171192028738938,
        lng: 80.21777476019273,
      },
      {
        lat: 13.169944319675961,
        lng: 80.2179311802231,
      },
      {
        lat: 13.169676197454578,
        lng: 80.21812792987062,
      },
      {
        lat: 13.168948382579618,
        lng: 80.21922804214266,
      },
      {
        lat: 13.168681886762727,
        lng: 80.21969218865775,
      },
      {
        lat: 13.168683069602478,
        lng: 80.21988665910685,
      },
      {
        lat: 13.168807107398509,
        lng: 80.22015301997492,
      },
      {
        lat: 13.168906071496645,
        lng: 80.22032235281829,
      },
      {
        lat: 13.169150894279431,
        lng: 80.22032028081956,
      },
      {
        lat: 13.169444681618922,
        lng: 80.22031779442115,
      },
      {
        lat: 13.169740243218044,
        lng: 80.22060701369634,
      },
      {
        lat: 13.169964723661737,
        lng: 80.22128579546927,
      },
      {
        lat: 13.169997781528137,
        lng: 80.22269549902488,
      },
      {
        lat: 13.170096449916336,
        lng: 80.22281621425597,
      },
      {
        lat: 13.17029275170744,
        lng: 80.22288748307552,
      },
      {
        lat: 13.170587574031714,
        lng: 80.22305515831998,
      },
      {
        lat: 13.170735650541186,
        lng: 80.2232483855698,
      },
      {
        lat: 13.170859688337273,
        lng: 80.22351474643787,
      },
      {
        lat: 13.171082838086177,
        lng: 80.2239747489555,
      },
      {
        lat: 13.171527363324643,
        lng: 80.22460304831736,
      },
      {
        lat: 13.171799773340311,
        lng: 80.22511125404755,
      },
      {
        lat: 13.17177736103153,
        lng: 80.22545178453333,
      },
      {
        lat: 13.171462639933067,
        lng: 80.22603788947869,
      },
      {
        lat: 13.171317372667959,
        lng: 80.22630652954534,
      },
      {
        lat: 13.17131855550771,
        lng: 80.22650099999443,
      },
      {
        lat: 13.171417519605674,
        lng: 80.22667033283781,
      },
      {
        lat: 13.171393776602175,
        lng: 80.22679208406842,
      },
      {
        lat: 13.1711740275175,
        lng: 80.22689118409181,
      },
      {
        lat: 13.170366703753798,
        lng: 80.22699525691203,
      },
      {
        lat: 13.169606570287103,
        lng: 80.22680720965896,
      },
      {
        lat: 13.169409529220985,
        lng: 80.2266143968088,
      },
      {
        lat: 13.168768258626562,
        lng: 80.22584190220927,
      },
      {
        lat: 13.168547474557107,
        lng: 80.22577084058962,
      },
      {
        lat: 13.165389112802922,
        lng: 80.22577326056684,
      },
      {
        lat: 13.1651192163219,
        lng: 80.22567830454086,
      },
      {
        lat: 13.164818503768792,
        lng: 80.22565242806901,
      },
    ],
    properties: {
      Ward_No: 22,
      Zone_Name: "MADHAVARAM",
      fillColor: "#f6d04d",
      zname: "MADHAVARAM(22)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.159386565302949,
        lng: 80.21066044066447,
      },
      {
        lat: 13.162621544123931,
        lng: 80.22351279017803,
      },
      {
        lat: 13.158610376584148,
        lng: 80.20456206650012,
      },
      {
        lat: 13.160228558025267,
        lng: 80.21631217692544,
      },
      {
        lat: 13.16164362538216,
        lng: 80.22279256040419,
      },
      {
        lat: 13.163136292101946,
        lng: 80.2201553975099,
      },
      {
        lat: 13.158356152356927,
        lng: 80.20700520768503,
      },
      {
        lat: 13.162823197069164,
        lng: 80.22205949151129,
      },
      {
        lat: 13.160887840346085,
        lng: 80.22285829993113,
      },
      {
        lat: 13.155211902111148,
        lng: 80.20126828178083,
      },
      {
        lat: 13.163226371944269,
        lng: 80.22492976014274,
      },
      {
        lat: 13.160148126289524,
        lng: 80.20109828463139,
      },
      {
        lat: 13.155054858913504,
        lng: 80.21047304846167,
      },
      {
        lat: 13.15613595249244,
        lng: 80.21015300469904,
      },
      {
        lat: 13.158273375448962,
        lng: 80.20615317724058,
      },
      {
        lat: 13.159525958332475,
        lng: 80.21113387583087,
      },
      {
        lat: 13.157492282330317,
        lng: 80.20311551972644,
      },
      {
        lat: 13.162343016776166,
        lng: 80.22345960796183,
      },
      {
        lat: 13.163051322600285,
        lng: 80.2212075939031,
      },
      {
        lat: 13.159922153802608,
        lng: 80.21011776381438,
      },
      {
        lat: 13.158668658384121,
        lng: 80.19830624689186,
      },
      {
        lat: 13.161832648257189,
        lng: 80.21528819064443,
      },
      {
        lat: 13.154711423283146,
        lng: 80.20603420103369,
      },
      {
        lat: 13.155981037341283,
        lng: 80.19542671527932,
      },
      {
        lat: 13.162563346520624,
        lng: 80.20344702151917,
      },
      {
        lat: 13.165914788043585,
        lng: 80.21550461719333,
      },
      {
        lat: 13.157040204522303,
        lng: 80.20922559242258,
      },
      {
        lat: 13.159091815739409,
        lng: 80.19571549856411,
      },
      {
        lat: 13.16476492026474,
        lng: 80.22311299204651,
      },
      {
        lat: 13.166279651133497,
        lng: 80.21748135771576,
      },
      {
        lat: 13.154613793555532,
        lng: 80.20490913264302,
      },
      {
        lat: 13.156802809175694,
        lng: 80.20855590647355,
      },
      {
        lat: 13.164218733117455,
        lng: 80.21600553509016,
      },
      {
        lat: 13.15453034849063,
        lng: 80.20479245484599,
      },
      {
        lat: 13.164033612566765,
        lng: 80.22255154264818,
      },
      {
        lat: 13.162040604986295,
        lng: 80.22155222396947,
      },
      {
        lat: 13.164006855643134,
        lng: 80.21426257053493,
      },
      {
        lat: 13.16175585418023,
        lng: 80.21729852253439,
      },
      {
        lat: 13.157714872872967,
        lng: 80.19304141166235,
      },
      {
        lat: 13.156992301668039,
        lng: 80.19242901581012,
      },
      {
        lat: 13.164697327469305,
        lng: 80.2213590005457,
      },
      {
        lat: 13.164573322527193,
        lng: 80.2231153725654,
      },
      {
        lat: 13.16242587466794,
        lng: 80.20920702089961,
      },
      {
        lat: 13.157833804679466,
        lng: 80.20015942493926,
      },
      {
        lat: 13.157224672892179,
        lng: 80.1982571447876,
      },
      {
        lat: 13.163854018625846,
        lng: 80.22243006939672,
      },
      {
        lat: 13.160696097934009,
        lng: 80.21683763561028,
      },
      {
        lat: 13.155132111185981,
        lng: 80.19535729449665,
      },
      {
        lat: 13.16076462837005,
        lng: 80.21593895449203,
      },
      {
        lat: 13.163625798927923,
        lng: 80.21485490581844,
      },
      {
        lat: 13.15659492976164,
        lng: 80.19806741139934,
      },
      {
        lat: 13.154845556757694,
        lng: 80.20108694859118,
      },
      {
        lat: 13.160510833479199,
        lng: 80.20775755891357,
      },
      {
        lat: 13.159800989855789,
        lng: 80.20514172970888,
      },
      {
        lat: 13.154729524667365,
        lng: 80.20069377506366,
      },
      {
        lat: 13.162150297397789,
        lng: 80.21371208799827,
      },
      {
        lat: 13.160899964462809,
        lng: 80.20542391188393,
      },
      {
        lat: 13.157121366966486,
        lng: 80.20107991359038,
      },
      {
        lat: 13.167135670310657,
        lng: 80.21661283748016,
      },
      {
        lat: 13.162962738879601,
        lng: 80.21121963829717,
      },
      {
        lat: 13.158675757185431,
        lng: 80.2115706769616,
      },
      {
        lat: 13.15623064811409,
        lng: 80.19880543768612,
      },
      {
        lat: 13.164325036035345,
        lng: 80.21609175305665,
      },
      {
        lat: 13.15581896169495,
        lng: 80.21036269938944,
      },
      {
        lat: 13.160832126926977,
        lng: 80.2055673604933,
      },
      {
        lat: 13.157276983391672,
        lng: 80.200808291008,
      },
      {
        lat: 13.159705388218592,
        lng: 80.20913864678846,
      },
      {
        lat: 13.160855634405427,
        lng: 80.207713918431,
      },
      {
        lat: 13.156091374552641,
        lng: 80.1925187484789,
      },
    ],
    cases: 69,
    coords: [
      {
        lat: 13.163625649491411,
        lng: 80.22566663492668,
      },
      {
        lat: 13.163239468127447,
        lng: 80.22555967031981,
      },
      {
        lat: 13.162596719932639,
        lng: 80.22556511006445,
      },
      {
        lat: 13.162219534950248,
        lng: 80.2255022788192,
      },
      {
        lat: 13.161998031494267,
        lng: 80.22552616128023,
      },
      {
        lat: 13.16173126360814,
        lng: 80.22539637208666,
      },
      {
        lat: 13.161552882940244,
        lng: 80.22522181920961,
      },
      {
        lat: 13.161353543215219,
        lng: 80.22524551409322,
      },
      {
        lat: 13.161020685694567,
        lng: 80.22518230769315,
      },
      {
        lat: 13.160886900193475,
        lng: 80.22505139303533,
      },
      {
        lat: 13.160660711895032,
        lng: 80.22430504144982,
      },
      {
        lat: 13.16060969180096,
        lng: 80.22320508225225,
      },
      {
        lat: 13.16060326687392,
        lng: 80.22214876127423,
      },
      {
        lat: 13.160509123991934,
        lng: 80.22124723741489,
      },
      {
        lat: 13.160635279892162,
        lng: 80.22012377091114,
      },
      {
        lat: 13.160489046094927,
        lng: 80.217946234358,
      },
      {
        lat: 13.160486636747287,
        lng: 80.21755011399112,
      },
      {
        lat: 13.16035311895155,
        lng: 80.21746321270754,
      },
      {
        lat: 13.160087020328708,
        lng: 80.21744345694924,
      },
      {
        lat: 13.159998365405405,
        lng: 80.21744420725884,
      },
      {
        lat: 13.159553618409152,
        lng: 80.21720588524951,
      },
      {
        lat: 13.159240381419002,
        lng: 80.21672436421801,
      },
      {
        lat: 13.159146907800299,
        lng: 80.21593287379409,
      },
      {
        lat: 13.159231547144316,
        lng: 80.21527192287309,
      },
      {
        lat: 13.159445019441382,
        lng: 80.2139276391892,
      },
      {
        lat: 13.159613227308293,
        lng: 80.21242968385096,
      },
      {
        lat: 13.159565285825092,
        lng: 80.21183587845553,
      },
      {
        lat: 13.159431366471418,
        lng: 80.21168295711057,
      },
      {
        lat: 13.158877540905314,
        lng: 80.2117316599197,
      },
      {
        lat: 13.158079780447384,
        lng: 80.21176041939333,
      },
      {
        lat: 13.157258116174093,
        lng: 80.21150327951271,
      },
      {
        lat: 13.155989964820144,
        lng: 80.21072173069092,
      },
      {
        lat: 13.155057749597887,
        lng: 80.21050954207135,
      },
      {
        lat: 13.154587922509855,
        lng: 80.21020603408382,
      },
      {
        lat: 13.153350406625698,
        lng: 80.2089950734985,
      },
      {
        lat: 13.153094914660528,
        lng: 80.20835875892801,
      },
      {
        lat: 13.15314897050339,
        lng: 80.20805294293866,
      },
      {
        lat: 13.153815537324268,
        lng: 80.20732554515429,
      },
      {
        lat: 13.153924662031214,
        lng: 80.20688046378444,
      },
      {
        lat: 13.153973990441964,
        lng: 80.20579741162078,
      },
      {
        lat: 13.153628888451157,
        lng: 80.20421802007723,
      },
      {
        lat: 13.153256505283599,
        lng: 80.20274989887614,
      },
      {
        lat: 13.15272026621896,
        lng: 80.20192164129519,
      },
      {
        lat: 13.151820254666909,
        lng: 80.20104094268761,
      },
      {
        lat: 13.15033214308656,
        lng: 80.19999866214117,
      },
      {
        lat: 13.154414133285252,
        lng: 80.20001963492702,
      },
      {
        lat: 13.15470662857019,
        lng: 80.19754653159362,
      },
      {
        lat: 13.15492318961507,
        lng: 80.19637878450568,
      },
      {
        lat: 13.154888817290324,
        lng: 80.19532420058685,
      },
      {
        lat: 13.154660775338709,
        lng: 80.19460437410854,
      },
      {
        lat: 13.154786271632066,
        lng: 80.19369396354608,
      },
      {
        lat: 13.154930714202813,
        lng: 80.19383530658818,
      },
      {
        lat: 13.155290086448625,
        lng: 80.19390354787635,
      },
      {
        lat: 13.155554175252972,
        lng: 80.19404387832155,
      },
      {
        lat: 13.155769249443146,
        lng: 80.19399453626043,
      },
      {
        lat: 13.155863954824577,
        lng: 80.19382740833079,
      },
      {
        lat: 13.155790721933744,
        lng: 80.1935904189579,
      },
      {
        lat: 13.155430049052356,
        lng: 80.19330834043171,
      },
      {
        lat: 13.155094027992938,
        lng: 80.19314485785158,
      },
      {
        lat: 13.15499715488553,
        lng: 80.19295559038422,
      },
      {
        lat: 13.155211217470255,
        lng: 80.19273993047135,
      },
      {
        lat: 13.155711852955433,
        lng: 80.19242680155277,
      },
      {
        lat: 13.157147029698086,
        lng: 80.19231961161498,
      },
      {
        lat: 13.159450795486068,
        lng: 80.19219701701923,
      },
      {
        lat: 13.160741354537391,
        lng: 80.19685698127755,
      },
      {
        lat: 13.161815084919292,
        lng: 80.20331594226242,
      },
      {
        lat: 13.16206703131037,
        lng: 80.20336932970206,
      },
      {
        lat: 13.163826435125909,
        lng: 80.20304908091052,
      },
      {
        lat: 13.164106675714741,
        lng: 80.2031577486167,
      },
      {
        lat: 13.164473656102738,
        lng: 80.20373759990426,
      },
      {
        lat: 13.164615464765765,
        lng: 80.20406951810531,
      },
      {
        lat: 13.164478552371714,
        lng: 80.20454259451338,
      },
      {
        lat: 13.164369427664763,
        lng: 80.20498767588336,
      },
      {
        lat: 13.164399747905021,
        lng: 80.2053760573672,
      },
      {
        lat: 13.16454476446858,
        lng: 80.20623538582923,
      },
      {
        lat: 13.164667564961109,
        lng: 80.20803873767767,
      },
      {
        lat: 13.164843239438413,
        lng: 80.20934196449323,
      },
      {
        lat: 13.165495187847363,
        lng: 80.21080771966119,
      },
      {
        lat: 13.167193177393077,
        lng: 80.21418005250747,
      },
      {
        lat: 13.1678310139005,
        lng: 80.21792223593198,
      },
      {
        lat: 13.164840678838216,
        lng: 80.21811410309539,
      },
      {
        lat: 13.164818503768792,
        lng: 80.22565242806901,
      },
      {
        lat: 13.164604792767932,
        lng: 80.22563403812585,
      },
      {
        lat: 13.16406544337069,
        lng: 80.2255170524924,
      },
      {
        lat: 13.163747025897992,
        lng: 80.2254954372845,
      },
      {
        lat: 13.163625649491411,
        lng: 80.22566663492668,
      },
    ],
    properties: {
      Ward_No: 23,
      Zone_Name: "MADHAVARAM",
      fillColor: "#f6d04d",
      zname: "MADHAVARAM(23)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.131108643680447,
        lng: 80.21032484584785,
      },
      {
        lat: 13.13297278678861,
        lng: 80.20364815680358,
      },
      {
        lat: 13.128465288579129,
        lng: 80.2058780186773,
      },
      {
        lat: 13.130094338493906,
        lng: 80.20239641926554,
      },
      {
        lat: 13.135562576774639,
        lng: 80.20772896435916,
      },
      {
        lat: 13.132176440905015,
        lng: 80.20613105722475,
      },
      {
        lat: 13.130220046221995,
        lng: 80.20136703080613,
      },
      {
        lat: 13.13380098592723,
        lng: 80.2084106721921,
      },
      {
        lat: 13.130061362934832,
        lng: 80.20790740294393,
      },
      {
        lat: 13.133878976523286,
        lng: 80.20611215573607,
      },
      {
        lat: 13.131476920206076,
        lng: 80.20755558571766,
      },
      {
        lat: 13.134938676742168,
        lng: 80.20924503551369,
      },
      {
        lat: 13.12999174235698,
        lng: 80.20389916686959,
      },
      {
        lat: 13.132026398594483,
        lng: 80.21240500552341,
      },
      {
        lat: 13.128804227222671,
        lng: 80.20672094949921,
      },
      {
        lat: 13.130138469462912,
        lng: 80.20821595324011,
      },
      {
        lat: 13.129520868983642,
        lng: 80.20342285756703,
      },
      {
        lat: 13.136198704842446,
        lng: 80.20583020697157,
      },
      {
        lat: 13.132715986764842,
        lng: 80.21063476169421,
      },
      {
        lat: 13.13558072787068,
        lng: 80.20721950905374,
      },
      {
        lat: 13.129283406245984,
        lng: 80.20579633670263,
      },
      {
        lat: 13.130566758347907,
        lng: 80.20746778140746,
      },
      {
        lat: 13.134502851311103,
        lng: 80.20630659006201,
      },
      {
        lat: 13.131156144975593,
        lng: 80.21029572358694,
      },
      {
        lat: 13.132793800510832,
        lng: 80.20537008066695,
      },
      {
        lat: 13.134020245875561,
        lng: 80.20697790419062,
      },
      {
        lat: 13.130417577548606,
        lng: 80.20829366175187,
      },
      {
        lat: 13.12998353464184,
        lng: 80.21118689589363,
      },
      {
        lat: 13.129302690940097,
        lng: 80.20389662086995,
      },
      {
        lat: 13.131112033299607,
        lng: 80.20419602316797,
      },
      {
        lat: 13.12859867042789,
        lng: 80.20469262452846,
      },
      {
        lat: 13.129125235259538,
        lng: 80.20838128735873,
      },
      {
        lat: 13.129068327907946,
        lng: 80.2070049677169,
      },
      {
        lat: 13.12963769720902,
        lng: 80.20840330311434,
      },
      {
        lat: 13.131022524816977,
        lng: 80.21251836536655,
      },
      {
        lat: 13.13139120718537,
        lng: 80.20397965544211,
      },
      {
        lat: 13.13483677351487,
        lng: 80.20716180807747,
      },
      {
        lat: 13.135316318339399,
        lng: 80.20568856016524,
      },
      {
        lat: 13.132555611734828,
        lng: 80.2084783125232,
      },
      {
        lat: 13.129839474854451,
        lng: 80.20175301242857,
      },
      {
        lat: 13.131508300807504,
        lng: 80.2034876606125,
      },
      {
        lat: 13.130710390899003,
        lng: 80.21302424547206,
      },
      {
        lat: 13.130158706806782,
        lng: 80.20749407108934,
      },
      {
        lat: 13.132034932209988,
        lng: 80.21371483430701,
      },
      {
        lat: 13.131683964154089,
        lng: 80.21209172805276,
      },
      {
        lat: 13.13418429970037,
        lng: 80.20868959875943,
      },
      {
        lat: 13.130811910505624,
        lng: 80.21352604414845,
      },
      {
        lat: 13.132554547868194,
        lng: 80.20581643069053,
      },
      {
        lat: 13.131731859519519,
        lng: 80.20994953642014,
      },
      {
        lat: 13.131334843815619,
        lng: 80.20743778226998,
      },
      {
        lat: 13.135688544870847,
        lng: 80.20859669411907,
      },
      {
        lat: 13.132650249325847,
        lng: 80.204742107885,
      },
      {
        lat: 13.131432486645224,
        lng: 80.2090454680619,
      },
      {
        lat: 13.131742959267543,
        lng: 80.20884807052379,
      },
      {
        lat: 13.132339119752407,
        lng: 80.21155534642149,
      },
      {
        lat: 13.129796465486274,
        lng: 80.20732843380428,
      },
      {
        lat: 13.1315939223496,
        lng: 80.20139318242383,
      },
      {
        lat: 13.131692992011656,
        lng: 80.21116840057431,
      },
      {
        lat: 13.133341928755902,
        lng: 80.2045382974065,
      },
      {
        lat: 13.130314500544326,
        lng: 80.20883492788872,
      },
      {
        lat: 13.13037321780828,
        lng: 80.20697633144026,
      },
      {
        lat: 13.133532348158834,
        lng: 80.20895608380194,
      },
      {
        lat: 13.129326119315149,
        lng: 80.20864448499385,
      },
      {
        lat: 13.131809740001557,
        lng: 80.20925058700371,
      },
      {
        lat: 13.13348654228845,
        lng: 80.2062424821279,
      },
      {
        lat: 13.130358771849506,
        lng: 80.20184944554006,
      },
      {
        lat: 13.129054397367103,
        lng: 80.20215289048635,
      },
      {
        lat: 13.129974417637495,
        lng: 80.20350156882411,
      },
      {
        lat: 13.132120513608212,
        lng: 80.20533626789356,
      },
      {
        lat: 13.131710944751102,
        lng: 80.20226490037166,
      },
      {
        lat: 13.129282780848031,
        lng: 80.2084948123455,
      },
      {
        lat: 13.129608685545142,
        lng: 80.2067339121517,
      },
      {
        lat: 13.129336187845782,
        lng: 80.2045546774555,
      },
      {
        lat: 13.12953996690762,
        lng: 80.20852916353026,
      },
      {
        lat: 13.135335577139632,
        lng: 80.20595077227513,
      },
      {
        lat: 13.130486236424977,
        lng: 80.20883275347244,
      },
      {
        lat: 13.133007459250999,
        lng: 80.21015402321406,
      },
      {
        lat: 13.131701438069307,
        lng: 80.2057134752711,
      },
      {
        lat: 13.131951439184459,
        lng: 80.20508841705586,
      },
      {
        lat: 13.129605380778875,
        lng: 80.2054698941124,
      },
      {
        lat: 13.130210490157383,
        lng: 80.20368103950665,
      },
      {
        lat: 13.128603416361065,
        lng: 80.20455156782148,
      },
      {
        lat: 13.132063987766065,
        lng: 80.20792202846614,
      },
      {
        lat: 13.133198097076203,
        lng: 80.20534151457304,
      },
      {
        lat: 13.129755019436153,
        lng: 80.20999798095926,
      },
      {
        lat: 13.135169968582003,
        lng: 80.20635455668507,
      },
      {
        lat: 13.130253992531824,
        lng: 80.20239719584995,
      },
      {
        lat: 13.134959993942264,
        lng: 80.20808306256993,
      },
      {
        lat: 13.132106379433665,
        lng: 80.21298369661956,
      },
      {
        lat: 13.131442888964438,
        lng: 80.20428083652041,
      },
      {
        lat: 13.130865585234677,
        lng: 80.20180572032821,
      },
      {
        lat: 13.133493541363077,
        lng: 80.20593626036171,
      },
      {
        lat: 13.13180223453783,
        lng: 80.20856716431086,
      },
      {
        lat: 13.132803900946126,
        lng: 80.20534204266869,
      },
      {
        lat: 13.133416726681967,
        lng: 80.20405660276167,
      },
      {
        lat: 13.132572429836577,
        lng: 80.20385890745449,
      },
      {
        lat: 13.135085533354372,
        lng: 80.20666324956558,
      },
      {
        lat: 13.133023217069047,
        lng: 80.20546147555584,
      },
      {
        lat: 13.131089655766015,
        lng: 80.20287356490142,
      },
      {
        lat: 13.13097998571569,
        lng: 80.20668790772882,
      },
      {
        lat: 13.130919223918108,
        lng: 80.20648390046078,
      },
      {
        lat: 13.129587108274492,
        lng: 80.20695321521634,
      },
      {
        lat: 13.130401276934583,
        lng: 80.2070347570353,
      },
      {
        lat: 13.134835270113152,
        lng: 80.20685172286807,
      },
      {
        lat: 13.13002490938179,
        lng: 80.20174175806513,
      },
      {
        lat: 13.13433898235903,
        lng: 80.20994560946879,
      },
      {
        lat: 13.132527120377462,
        lng: 80.20880980788966,
      },
      {
        lat: 13.132500252298613,
        lng: 80.20677042759212,
      },
      {
        lat: 13.132920954974281,
        lng: 80.20765406309957,
      },
      {
        lat: 13.131415773925461,
        lng: 80.20159108869566,
      },
      {
        lat: 13.13313176089314,
        lng: 80.20885440938525,
      },
      {
        lat: 13.131586541862221,
        lng: 80.21119333559844,
      },
      {
        lat: 13.130021305151178,
        lng: 80.20499498861123,
      },
      {
        lat: 13.133855628215748,
        lng: 80.21020204477188,
      },
      {
        lat: 13.130222508480268,
        lng: 80.20713928153127,
      },
      {
        lat: 13.13419458441767,
        lng: 80.20823433928908,
      },
      {
        lat: 13.129946607978429,
        lng: 80.20540569883987,
      },
      {
        lat: 13.131443521644954,
        lng: 80.20897340718676,
      },
      {
        lat: 13.129894668871037,
        lng: 80.206449656259,
      },
      {
        lat: 13.13303308806555,
        lng: 80.20741946927829,
      },
      {
        lat: 13.130231150785992,
        lng: 80.20274548460279,
      },
      {
        lat: 13.129780872239145,
        lng: 80.20195132654975,
      },
    ],
    cases: 122,
    coords: [
      {
        lat: 13.129094172013403,
        lng: 80.20040386178798,
      },
      {
        lat: 13.130464889697285,
        lng: 80.20101406448038,
      },
      {
        lat: 13.132059793855934,
        lng: 80.20140042775216,
      },
      {
        lat: 13.13286783515145,
        lng: 80.20182980148134,
      },
      {
        lat: 13.133070287652947,
        lng: 80.2020098432367,
      },
      {
        lat: 13.133108443830205,
        lng: 80.20226397753974,
      },
      {
        lat: 13.133020238771934,
        lng: 80.20280998953224,
      },
      {
        lat: 13.133132828049403,
        lng: 80.20326342456839,
      },
      {
        lat: 13.133614045406913,
        lng: 80.20413177668532,
      },
      {
        lat: 13.134110029622033,
        lng: 80.20441838730389,
      },
      {
        lat: 13.134715950049198,
        lng: 80.2047222430199,
      },
      {
        lat: 13.134974973550408,
        lng: 80.20517443874532,
      },
      {
        lat: 13.135105093295051,
        lng: 80.20550049680214,
      },
      {
        lat: 13.13552697604317,
        lng: 80.20564233042973,
      },
      {
        lat: 13.13598546734724,
        lng: 80.20578385422971,
      },
      {
        lat: 13.136417735996483,
        lng: 80.20578696517876,
      },
      {
        lat: 13.13640905688296,
        lng: 80.20732910563062,
      },
      {
        lat: 13.136302780725309,
        lng: 80.20767069429404,
      },
      {
        lat: 13.136326400688604,
        lng: 80.20858497598414,
      },
      {
        lat: 13.133936677528682,
        lng: 80.21057761210807,
      },
      {
        lat: 13.132337691170479,
        lng: 80.21193597061487,
      },
      {
        lat: 13.13224428377395,
        lng: 80.21439330973635,
      },
      {
        lat: 13.131973195043287,
        lng: 80.2143597420073,
      },
      {
        lat: 13.13101502829346,
        lng: 80.21418854110502,
      },
      {
        lat: 13.13026045942402,
        lng: 80.21414657855199,
      },
      {
        lat: 13.1302574687411,
        lng: 80.21365488096723,
      },
      {
        lat: 13.130544096146306,
        lng: 80.21328366299352,
      },
      {
        lat: 13.130870993859917,
        lng: 80.21274819656975,
      },
      {
        lat: 13.13090927037953,
        lng: 80.21225614972992,
      },
      {
        lat: 13.13078422265398,
        lng: 80.2120523235015,
      },
      {
        lat: 13.13020448803046,
        lng: 80.21172941468225,
      },
      {
        lat: 13.12979106833467,
        lng: 80.21160998283648,
      },
      {
        lat: 13.129664026820855,
        lng: 80.21107835821809,
      },
      {
        lat: 13.129494222762933,
        lng: 80.2103012340625,
      },
      {
        lat: 13.128823978579648,
        lng: 80.20866783019406,
      },
      {
        lat: 13.128691204923568,
        lng: 80.20719378520522,
      },
      {
        lat: 13.128353092149181,
        lng: 80.20588538568627,
      },
      {
        lat: 13.128508441239655,
        lng: 80.20428597151583,
      },
      {
        lat: 13.12895016851165,
        lng: 80.20227436457301,
      },
      {
        lat: 13.128864643423713,
        lng: 80.20178336549829,
      },
      {
        lat: 13.129025455438118,
        lng: 80.20119819243169,
      },
      {
        lat: 13.12911450299436,
        lng: 80.20098210696781,
      },
      {
        lat: 13.12911210195318,
        lng: 80.20058735226449,
      },
      {
        lat: 13.129094172013403,
        lng: 80.20040386178798,
      },
    ],
    properties: {
      Ward_No: 32,
      Zone_Name: "MADHAVARAM",
      fillColor: "#de4307",
      zname: "MADHAVARAM(32)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.150115881096333,
        lng: 80.19781325507472,
      },
      {
        lat: 13.152519995325427,
        lng: 80.19168542486992,
      },
      {
        lat: 13.14742072008191,
        lng: 80.1889103210471,
      },
      {
        lat: 13.1389621604582,
        lng: 80.18178864125166,
      },
      {
        lat: 13.129812006891875,
        lng: 80.19605952239269,
      },
      {
        lat: 13.145974621775904,
        lng: 80.1949728939287,
      },
      {
        lat: 13.144643260869287,
        lng: 80.18901619845342,
      },
      {
        lat: 13.151645272255596,
        lng: 80.19387777840791,
      },
      {
        lat: 13.150764298550714,
        lng: 80.192594405911,
      },
      {
        lat: 13.129916543268529,
        lng: 80.20025781469766,
      },
      {
        lat: 13.152333676116935,
        lng: 80.19356702653077,
      },
      {
        lat: 13.14128134621939,
        lng: 80.1846269947075,
      },
      {
        lat: 13.146611965980737,
        lng: 80.18502381205322,
      },
      {
        lat: 13.14463308012374,
        lng: 80.18566536981031,
      },
      {
        lat: 13.145580618051536,
        lng: 80.18813613628477,
      },
      {
        lat: 13.141746504551149,
        lng: 80.19104087329357,
      },
      {
        lat: 13.136362059932031,
        lng: 80.19157929520546,
      },
      {
        lat: 13.147313338354982,
        lng: 80.1892464966078,
      },
      {
        lat: 13.148860237218983,
        lng: 80.1876697405401,
      },
      {
        lat: 13.138022098132696,
        lng: 80.18330258518827,
      },
      {
        lat: 13.137985460165636,
        lng: 80.20434712176656,
      },
      {
        lat: 13.144020553164259,
        lng: 80.18321682475029,
      },
      {
        lat: 13.13975152696248,
        lng: 80.1976280694951,
      },
      {
        lat: 13.140746143091633,
        lng: 80.19810145054262,
      },
      {
        lat: 13.138934368207961,
        lng: 80.20039733447116,
      },
      {
        lat: 13.150201617617277,
        lng: 80.19769418094765,
      },
      {
        lat: 13.148145338774656,
        lng: 80.1896821008443,
      },
      {
        lat: 13.13511405119245,
        lng: 80.19841086906297,
      },
      {
        lat: 13.154645705991209,
        lng: 80.19498123845325,
      },
      {
        lat: 13.137145803321344,
        lng: 80.19767015728628,
      },
      {
        lat: 13.142487456686803,
        lng: 80.18717168176491,
      },
      {
        lat: 13.137135191890605,
        lng: 80.19737636790433,
      },
      {
        lat: 13.134705505650118,
        lng: 80.19290598338495,
      },
      {
        lat: 13.128802260728053,
        lng: 80.19725690793139,
      },
      {
        lat: 13.139149275127703,
        lng: 80.20278451783648,
      },
      {
        lat: 13.132467410389294,
        lng: 80.19767001433382,
      },
      {
        lat: 13.14857351993163,
        lng: 80.18948477764611,
      },
      {
        lat: 13.154570637156409,
        lng: 80.19389423100554,
      },
      {
        lat: 13.142260592743988,
        lng: 80.18798754987114,
      },
      {
        lat: 13.135218859076948,
        lng: 80.20355524923009,
      },
      {
        lat: 13.153812225623703,
        lng: 80.19377198476732,
      },
      {
        lat: 13.138417696547215,
        lng: 80.19937285328417,
      },
      {
        lat: 13.135163891916152,
        lng: 80.19766257719728,
      },
      {
        lat: 13.136857504649155,
        lng: 80.1972830702409,
      },
      {
        lat: 13.138062886033643,
        lng: 80.19577699431913,
      },
      {
        lat: 13.135663853925596,
        lng: 80.19115528702653,
      },
      {
        lat: 13.14706651393107,
        lng: 80.18217902883556,
      },
      {
        lat: 13.150917878768457,
        lng: 80.1949137078802,
      },
      {
        lat: 13.138671762584751,
        lng: 80.19195894014257,
      },
      {
        lat: 13.131812358385924,
        lng: 80.19579867750838,
      },
      {
        lat: 13.13835165101369,
        lng: 80.19117442213951,
      },
      {
        lat: 13.146333704464078,
        lng: 80.18885504814378,
      },
      {
        lat: 13.151284849021124,
        lng: 80.19292415344519,
      },
      {
        lat: 13.14543194431464,
        lng: 80.19477468743092,
      },
      {
        lat: 13.138800706832269,
        lng: 80.20105216041885,
      },
      {
        lat: 13.137747750535954,
        lng: 80.19083527365983,
      },
      {
        lat: 13.135624167317756,
        lng: 80.18227550885457,
      },
      {
        lat: 13.133945678406736,
        lng: 80.20367899968878,
      },
      {
        lat: 13.135276125924717,
        lng: 80.20456241032763,
      },
      {
        lat: 13.140492235499911,
        lng: 80.18964107893832,
      },
      {
        lat: 13.138944913708746,
        lng: 80.19129994697866,
      },
      {
        lat: 13.141189840046048,
        lng: 80.18113964788827,
      },
      {
        lat: 13.148049697095686,
        lng: 80.1915074321618,
      },
      {
        lat: 13.142764428470855,
        lng: 80.19224413392467,
      },
      {
        lat: 13.139916984777798,
        lng: 80.1907240135233,
      },
      {
        lat: 13.139128545380402,
        lng: 80.19884465437215,
      },
      {
        lat: 13.145865909669064,
        lng: 80.19396642191252,
      },
      {
        lat: 13.142516001022658,
        lng: 80.18782082369503,
      },
      {
        lat: 13.138903199341069,
        lng: 80.20203022512042,
      },
      {
        lat: 13.143559915652828,
        lng: 80.1924965445463,
      },
      {
        lat: 13.148419768693913,
        lng: 80.19382830374717,
      },
      {
        lat: 13.1530641682784,
        lng: 80.19754366165785,
      },
      {
        lat: 13.138604945140461,
        lng: 80.20493894145304,
      },
      {
        lat: 13.138382444516234,
        lng: 80.20066309469388,
      },
      {
        lat: 13.148429439134238,
        lng: 80.19262672506024,
      },
      {
        lat: 13.153564851659247,
        lng: 80.19342447731422,
      },
      {
        lat: 13.133860137374684,
        lng: 80.19421010223614,
      },
      {
        lat: 13.14035840454207,
        lng: 80.19377897949101,
      },
      {
        lat: 13.150125452147288,
        lng: 80.1932129831109,
      },
      {
        lat: 13.140039968932127,
        lng: 80.19908513081083,
      },
      {
        lat: 13.136407397588673,
        lng: 80.19968497230363,
      },
      {
        lat: 13.154195038007954,
        lng: 80.1988070077078,
      },
      {
        lat: 13.138528176061518,
        lng: 80.19124266869707,
      },
      {
        lat: 13.13640022502936,
        lng: 80.17998504062203,
      },
      {
        lat: 13.131536351090515,
        lng: 80.19987443427269,
      },
      {
        lat: 13.14673225250421,
        lng: 80.18889958093234,
      },
      {
        lat: 13.149783074045626,
        lng: 80.19653920586154,
      },
      {
        lat: 13.135594317464724,
        lng: 80.19762749945164,
      },
      {
        lat: 13.136413890202572,
        lng: 80.19511904732329,
      },
      {
        lat: 13.146464009473318,
        lng: 80.18169572622207,
      },
      {
        lat: 13.145517956443575,
        lng: 80.1846243267162,
      },
      {
        lat: 13.131961309304428,
        lng: 80.19601156739279,
      },
      {
        lat: 13.141111720641758,
        lng: 80.18054471360897,
      },
      {
        lat: 13.13002723664877,
        lng: 80.19962703239699,
      },
      {
        lat: 13.148178448535154,
        lng: 80.18915090055461,
      },
      {
        lat: 13.141561700709314,
        lng: 80.1929339463245,
      },
      {
        lat: 13.145619085001815,
        lng: 80.17895488681665,
      },
      {
        lat: 13.129780603129266,
        lng: 80.19623729800104,
      },
      {
        lat: 13.134168947123168,
        lng: 80.20373412968316,
      },
      {
        lat: 13.146987391854225,
        lng: 80.19520169769434,
      },
      {
        lat: 13.137139043914297,
        lng: 80.20340110818582,
      },
      {
        lat: 13.148118871786792,
        lng: 80.19399123170955,
      },
      {
        lat: 13.139490900500787,
        lng: 80.19554587101335,
      },
      {
        lat: 13.138796418281608,
        lng: 80.19570431249676,
      },
      {
        lat: 13.138896724578235,
        lng: 80.1796843023478,
      },
      {
        lat: 13.14137591107139,
        lng: 80.19049003024894,
      },
      {
        lat: 13.13643100215863,
        lng: 80.1975058189411,
      },
      {
        lat: 13.134809130689984,
        lng: 80.1991211950523,
      },
      {
        lat: 13.150292205455655,
        lng: 80.17903655261686,
      },
      {
        lat: 13.13943924014917,
        lng: 80.19571077539017,
      },
      {
        lat: 13.148656416230047,
        lng: 80.17846255621058,
      },
      {
        lat: 13.13990631468267,
        lng: 80.20292385031439,
      },
      {
        lat: 13.143055030430663,
        lng: 80.18475423070139,
      },
      {
        lat: 13.153661890477547,
        lng: 80.19407161951148,
      },
      {
        lat: 13.151728814941782,
        lng: 80.19364071535668,
      },
      {
        lat: 13.139901113818969,
        lng: 80.18016693267975,
      },
      {
        lat: 13.133785079917928,
        lng: 80.19670972784697,
      },
      {
        lat: 13.135543976329316,
        lng: 80.19423372915537,
      },
      {
        lat: 13.15044897690434,
        lng: 80.19302663536176,
      },
      {
        lat: 13.133093441554532,
        lng: 80.2012256983398,
      },
      {
        lat: 13.131231295116255,
        lng: 80.19497203398787,
      },
      {
        lat: 13.149324421743946,
        lng: 80.19315083496755,
      },
      {
        lat: 13.141982424159334,
        lng: 80.18506923181148,
      },
    ],
    cases: 123,
    coords: [
      {
        lat: 13.136417735996483,
        lng: 80.20578696517876,
      },
      {
        lat: 13.13598546734724,
        lng: 80.20578385422971,
      },
      {
        lat: 13.13552697604317,
        lng: 80.20564233042973,
      },
      {
        lat: 13.135105093295051,
        lng: 80.20550049680214,
      },
      {
        lat: 13.134974973550408,
        lng: 80.20517443874532,
      },
      {
        lat: 13.134715950049198,
        lng: 80.2047222430199,
      },
      {
        lat: 13.134110029622033,
        lng: 80.20441838730389,
      },
      {
        lat: 13.133614045406913,
        lng: 80.20413177668532,
      },
      {
        lat: 13.133132828049403,
        lng: 80.20326342456839,
      },
      {
        lat: 13.133020238771934,
        lng: 80.20280998953224,
      },
      {
        lat: 13.133108443830205,
        lng: 80.20226397753974,
      },
      {
        lat: 13.133070287652947,
        lng: 80.2020098432367,
      },
      {
        lat: 13.13286783515145,
        lng: 80.20182980148134,
      },
      {
        lat: 13.132059793855934,
        lng: 80.20140042775216,
      },
      {
        lat: 13.130464889697285,
        lng: 80.20101406448038,
      },
      {
        lat: 13.129094172013403,
        lng: 80.20040386178798,
      },
      {
        lat: 13.129054286062344,
        lng: 80.1999956790396,
      },
      {
        lat: 13.128997125000984,
        lng: 80.19951166618824,
      },
      {
        lat: 13.1288870592695,
        lng: 80.19924343291407,
      },
      {
        lat: 13.12856035449818,
        lng: 80.19901292175071,
      },
      {
        lat: 13.128467596242105,
        lng: 80.19861893176396,
      },
      {
        lat: 13.128426760411925,
        lng: 80.19784767163966,
      },
      {
        lat: 13.128550968609932,
        lng: 80.19746978972883,
      },
      {
        lat: 13.128585910975342,
        lng: 80.19727210649066,
      },
      {
        lat: 13.12844013891063,
        lng: 80.19707595268538,
      },
      {
        lat: 13.12818604732625,
        lng: 80.19689865993563,
      },
      {
        lat: 13.128184628529189,
        lng: 80.19666539579285,
      },
      {
        lat: 13.128273130394252,
        lng: 80.1963595933509,
      },
      {
        lat: 13.128396902039489,
        lng: 80.19590993785762,
      },
      {
        lat: 13.128466786770373,
        lng: 80.19551457138132,
      },
      {
        lat: 13.128356393624015,
        lng: 80.19519250792018,
      },
      {
        lat: 13.12833668510748,
        lng: 80.19492350992947,
      },
      {
        lat: 13.128479728716309,
        lng: 80.19467107884466,
      },
      {
        lat: 13.12866022486986,
        lng: 80.19463366262032,
      },
      {
        lat: 13.129076850303125,
        lng: 80.19479163548452,
      },
      {
        lat: 13.129926317262784,
        lng: 80.1948023905447,
      },
      {
        lat: 13.130142628887626,
        lng: 80.19471083824688,
      },
      {
        lat: 13.130141755781743,
        lng: 80.19456729108218,
      },
      {
        lat: 13.130066741553964,
        lng: 80.1941193179653,
      },
      {
        lat: 13.130536053381,
        lng: 80.19402562446122,
      },
      {
        lat: 13.13278974512165,
        lng: 80.19314522355867,
      },
      {
        lat: 13.133565398373886,
        lng: 80.19290538285364,
      },
      {
        lat: 13.134679606961086,
        lng: 80.19187312681899,
      },
      {
        lat: 13.135309597286899,
        lng: 80.19145507570435,
      },
      {
        lat: 13.135335056464076,
        lng: 80.19118390101582,
      },
      {
        lat: 13.13531087327983,
        lng: 80.19017922380529,
      },
      {
        lat: 13.135218878991404,
        lng: 80.1899108375878,
      },
      {
        lat: 13.135217569332584,
        lng: 80.18969551684066,
      },
      {
        lat: 13.135288872860352,
        lng: 80.18953341450693,
      },
      {
        lat: 13.13530497981512,
        lng: 80.18921028044281,
      },
      {
        lat: 13.135375191960708,
        lng: 80.18886874415297,
      },
      {
        lat: 13.135555797252659,
        lng: 80.18884927132426,
      },
      {
        lat: 13.135827087174212,
        lng: 80.18888286396601,
      },
      {
        lat: 13.136225750302716,
        lng: 80.18905893316919,
      },
      {
        lat: 13.136751568361758,
        lng: 80.18934159214277,
      },
      {
        lat: 13.13713150521785,
        lng: 80.18941015391576,
      },
      {
        lat: 13.137474426082056,
        lng: 80.18933547441047,
      },
      {
        lat: 13.137870469892906,
        lng: 80.1890809021193,
      },
      {
        lat: 13.138445263645716,
        lng: 80.188501819274,
      },
      {
        lat: 13.138914138919809,
        lng: 80.18833635218769,
      },
      {
        lat: 13.139076127077411,
        lng: 80.18822731532428,
      },
      {
        lat: 13.139164847218947,
        lng: 80.1879573996737,
      },
      {
        lat: 13.13919957130789,
        lng: 80.18772382964414,
      },
      {
        lat: 13.138083461866605,
        lng: 80.18547229134003,
      },
      {
        lat: 13.135410698147421,
        lng: 80.18282120806133,
      },
      {
        lat: 13.135264926082707,
        lng: 80.18262505425612,
      },
      {
        lat: 13.135299104480527,
        lng: 80.18230176724863,
      },
      {
        lat: 13.136877449893497,
        lng: 80.17735372150322,
      },
      {
        lat: 13.137222007831177,
        lng: 80.17754819293191,
      },
      {
        lat: 13.138838879041431,
        lng: 80.17893416583458,
      },
      {
        lat: 13.139038428882097,
        lng: 80.17905808722752,
      },
      {
        lat: 13.139219034173989,
        lng: 80.17903861439872,
      },
      {
        lat: 13.139722196984147,
        lng: 80.1785678037004,
      },
      {
        lat: 13.139915824673343,
        lng: 80.1783419551841,
      },
      {
        lat: 13.1401790463873,
        lng: 80.17833972747044,
      },
      {
        lat: 13.140561769819609,
        lng: 80.17831272746662,
      },
      {
        lat: 13.141468624438561,
        lng: 80.17790111694546,
      },
      {
        lat: 13.14416655968446,
        lng: 80.17688032513948,
      },
      {
        lat: 13.145263547641484,
        lng: 80.17625325722477,
      },
      {
        lat: 13.145714879482663,
        lng: 80.17570293641378,
      },
      {
        lat: 13.14600029626237,
        lng: 80.17541538986325,
      },
      {
        lat: 13.146286724647704,
        lng: 80.17529416116452,
      },
      {
        lat: 13.147194879902072,
        lng: 80.17509638788162,
      },
      {
        lat: 13.147649535589801,
        lng: 80.17509254001263,
      },
      {
        lat: 13.14808185169631,
        lng: 80.17535025128744,
      },
      {
        lat: 13.148610318334956,
        lng: 80.17567843156391,
      },
      {
        lat: 13.14868326219543,
        lng: 80.17586790155077,
      },
      {
        lat: 13.148612630575997,
        lng: 80.17605858665411,
      },
      {
        lat: 13.148305596790452,
        lng: 80.17672649081405,
      },
      {
        lat: 13.147596823838207,
        lng: 80.17822942706381,
      },
      {
        lat: 13.147597979958729,
        lng: 80.17841950460891,
      },
      {
        lat: 13.14771805973735,
        lng: 80.17848977109125,
      },
      {
        lat: 13.147908915650865,
        lng: 80.1783931121633,
      },
      {
        lat: 13.14893642810892,
        lng: 80.17814680689686,
      },
      {
        lat: 13.149318428965909,
        lng: 80.17800100842749,
      },
      {
        lat: 13.150013533241173,
        lng: 80.17818521290921,
      },
      {
        lat: 13.15051691451254,
        lng: 80.17832351816021,
      },
      {
        lat: 13.150733722883666,
        lng: 80.17855929241661,
      },
      {
        lat: 13.150711094272376,
        lng: 80.1787733321743,
      },
      {
        lat: 13.150544890181068,
        lng: 80.17898858704825,
      },
      {
        lat: 13.149307217044925,
        lng: 80.18009206394211,
      },
      {
        lat: 13.148382069266743,
        lng: 80.18143050501467,
      },
      {
        lat: 13.147766701060236,
        lng: 80.18255247609673,
      },
      {
        lat: 13.147533044680696,
        lng: 80.18348112932289,
      },
      {
        lat: 13.14753564595187,
        lng: 80.18390880379927,
      },
      {
        lat: 13.147755633654265,
        lng: 80.1846672913047,
      },
      {
        lat: 13.148387043033047,
        lng: 80.18618264616002,
      },
      {
        lat: 13.148724220213102,
        lng: 80.186536206285,
      },
      {
        lat: 13.149599718735065,
        lng: 80.18687095390727,
      },
      {
        lat: 13.15022505848106,
        lng: 80.18738840165118,
      },
      {
        lat: 13.150324821889596,
        lng: 80.188052862981,
      },
      {
        lat: 13.150207343382148,
        lng: 80.18841027097494,
      },
      {
        lat: 13.150017065528894,
        lng: 80.1886019686754,
      },
      {
        lat: 13.14994600036404,
        lng: 80.18872137469941,
      },
      {
        lat: 13.150139313033664,
        lng: 80.18902863055462,
      },
      {
        lat: 13.151967473778127,
        lng: 80.19058137882523,
      },
      {
        lat: 13.152279710105848,
        lng: 80.19076882361784,
      },
      {
        lat: 13.152377161273513,
        lng: 80.19105312985765,
      },
      {
        lat: 13.152354099117137,
        lng: 80.19119589053585,
      },
      {
        lat: 13.152474467926117,
        lng: 80.19131367640426,
      },
      {
        lat: 13.15273797866997,
        lng: 80.19135896807693,
      },
      {
        lat: 13.153674687653522,
        lng: 80.19192130245469,
      },
      {
        lat: 13.154637638124655,
        lng: 80.19286358940298,
      },
      {
        lat: 13.154736678957867,
        lng: 80.19340925226724,
      },
      {
        lat: 13.154786271632066,
        lng: 80.19369396354608,
      },
      {
        lat: 13.154660775338709,
        lng: 80.19460437410854,
      },
      {
        lat: 13.154888817290324,
        lng: 80.19532420058685,
      },
      {
        lat: 13.15492318961507,
        lng: 80.19637878450568,
      },
      {
        lat: 13.15470662857019,
        lng: 80.19754653159362,
      },
      {
        lat: 13.154414133285252,
        lng: 80.20001963492702,
      },
      {
        lat: 13.15033214308656,
        lng: 80.19999866214117,
      },
      {
        lat: 13.150070614679562,
        lng: 80.19989763158773,
      },
      {
        lat: 13.149943554048305,
        lng: 80.1997911208652,
      },
      {
        lat: 13.149906129278495,
        lng: 80.19957626546145,
      },
      {
        lat: 13.149986059040634,
        lng: 80.19787214289218,
      },
      {
        lat: 13.149913063475257,
        lng: 80.19774724359135,
      },
      {
        lat: 13.149622935187796,
        lng: 80.19755245789464,
      },
      {
        lat: 13.14929581835995,
        lng: 80.19721453714867,
      },
      {
        lat: 13.148369296342983,
        lng: 80.19630789695255,
      },
      {
        lat: 13.14724686230685,
        lng: 80.19585119010112,
      },
      {
        lat: 13.146106152114783,
        lng: 80.19535877590228,
      },
      {
        lat: 13.144403134331899,
        lng: 80.19445870739142,
      },
      {
        lat: 13.143841699199243,
        lng: 80.1941944937884,
      },
      {
        lat: 13.143515891058795,
        lng: 80.19407173410578,
      },
      {
        lat: 13.143351950944384,
        lng: 80.19384001842256,
      },
      {
        lat: 13.14335042414222,
        lng: 80.19358899718183,
      },
      {
        lat: 13.143187683657978,
        lng: 80.19355451247363,
      },
      {
        lat: 13.14297207773806,
        lng: 80.19373564731845,
      },
      {
        lat: 13.142256081418422,
        lng: 80.1947817056544,
      },
      {
        lat: 13.141897210800249,
        lng: 80.19516129411315,
      },
      {
        lat: 13.141735233717027,
        lng: 80.1952523200253,
      },
      {
        lat: 13.141664964584086,
        lng: 80.1955756729401,
      },
      {
        lat: 13.141296651451304,
        lng: 80.19737189123053,
      },
      {
        lat: 13.140951303937975,
        lng: 80.19997481067944,
      },
      {
        lat: 13.140779557168484,
        lng: 80.20142867615724,
      },
      {
        lat: 13.14044234348202,
        lng: 80.20239980471109,
      },
      {
        lat: 13.13998177711264,
        lng: 80.20387404555555,
      },
      {
        lat: 13.139949587093295,
        lng: 80.20451983440594,
      },
      {
        lat: 13.139810575629408,
        lng: 80.20541756147661,
      },
      {
        lat: 13.13901940259849,
        lng: 80.20598011873894,
      },
      {
        lat: 13.138495828464825,
        lng: 80.20600248089428,
      },
      {
        lat: 13.137212507918278,
        lng: 80.2058161008412,
      },
      {
        lat: 13.136417735996483,
        lng: 80.20578696517876,
      },
    ],
    properties: {
      Ward_No: 24,
      Zone_Name: "MADHAVARAM",
      fillColor: "#de4307",
      zname: "MADHAVARAM(24)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.152125288343827,
        lng: 80.20352731044115,
      },
      {
        lat: 13.152242951841819,
        lng: 80.20165989796021,
      },
      {
        lat: 13.143511282472833,
        lng: 80.19758829894734,
      },
      {
        lat: 13.148798835301548,
        lng: 80.20773913922156,
      },
      {
        lat: 13.158308324610294,
        lng: 80.2118305224615,
      },
      {
        lat: 13.158672443900494,
        lng: 80.21286728395276,
      },
      {
        lat: 13.14289144053548,
        lng: 80.19901511336461,
      },
      {
        lat: 13.151232091070268,
        lng: 80.20821433095503,
      },
      {
        lat: 13.150292329711576,
        lng: 80.20921846457371,
      },
      {
        lat: 13.145130057825762,
        lng: 80.20673520481219,
      },
      {
        lat: 13.14962197382362,
        lng: 80.20674348275001,
      },
      {
        lat: 13.153553103905894,
        lng: 80.21408719327991,
      },
      {
        lat: 13.145339278719057,
        lng: 80.20703398897545,
      },
      {
        lat: 13.14437391437049,
        lng: 80.19980856550904,
      },
      {
        lat: 13.159348236777388,
        lng: 80.21387384290581,
      },
      {
        lat: 13.14351475480061,
        lng: 80.1959097692667,
      },
      {
        lat: 13.153217626749935,
        lng: 80.21574400490134,
      },
      {
        lat: 13.148858715178894,
        lng: 80.20264753327659,
      },
      {
        lat: 13.152038294733224,
        lng: 80.20538640834309,
      },
      {
        lat: 13.150651823746372,
        lng: 80.21174528402521,
      },
      {
        lat: 13.149871527528886,
        lng: 80.20272027352677,
      },
      {
        lat: 13.156807275240121,
        lng: 80.21717108288833,
      },
      {
        lat: 13.15140865761,
        lng: 80.21245431913076,
      },
      {
        lat: 13.14864060133393,
        lng: 80.21354042356947,
      },
      {
        lat: 13.143545941453773,
        lng: 80.20151443016401,
      },
      {
        lat: 13.149920220719112,
        lng: 80.20241982974508,
      },
      {
        lat: 13.155992789286225,
        lng: 80.2169010239578,
      },
      {
        lat: 13.151619800030279,
        lng: 80.21535021051507,
      },
      {
        lat: 13.146257044640983,
        lng: 80.19992228062414,
      },
      {
        lat: 13.151613469439146,
        lng: 80.20708472266905,
      },
      {
        lat: 13.150518664582838,
        lng: 80.20908192928783,
      },
      {
        lat: 13.15078095604958,
        lng: 80.2119476260146,
      },
      {
        lat: 13.140748775929358,
        lng: 80.20342078321733,
      },
      {
        lat: 13.14247227910419,
        lng: 80.20019588224501,
      },
      {
        lat: 13.154101965977322,
        lng: 80.21103564395328,
      },
      {
        lat: 13.140137978671905,
        lng: 80.2036636841567,
      },
      {
        lat: 13.149584116180696,
        lng: 80.20159364462339,
      },
      {
        lat: 13.149144070648232,
        lng: 80.20695244455557,
      },
      {
        lat: 13.14572827794678,
        lng: 80.20507674457953,
      },
      {
        lat: 13.145774624689595,
        lng: 80.19775187564444,
      },
      {
        lat: 13.149320790983436,
        lng: 80.20880885556213,
      },
      {
        lat: 13.145841772912018,
        lng: 80.20040377577024,
      },
      {
        lat: 13.158619165603628,
        lng: 80.21441450312807,
      },
      {
        lat: 13.141507352836785,
        lng: 80.19799597515811,
      },
      {
        lat: 13.15893138469847,
        lng: 80.214552928438,
      },
      {
        lat: 13.15703375613456,
        lng: 80.21620132293907,
      },
      {
        lat: 13.152553089779962,
        lng: 80.20557558330694,
      },
      {
        lat: 13.158458147707478,
        lng: 80.21301552235792,
      },
      {
        lat: 13.151834908528427,
        lng: 80.2063183226839,
      },
      {
        lat: 13.152215384445082,
        lng: 80.20389512425649,
      },
      {
        lat: 13.1419487903719,
        lng: 80.19536182036032,
      },
      {
        lat: 13.155439842413333,
        lng: 80.21719358298618,
      },
      {
        lat: 13.144640972934388,
        lng: 80.19868492734324,
      },
      {
        lat: 13.157584808371293,
        lng: 80.2127445858699,
      },
      {
        lat: 13.148950521549589,
        lng: 80.21391207875998,
      },
      {
        lat: 13.149810350804973,
        lng: 80.20431528072578,
      },
      {
        lat: 13.143371389241969,
        lng: 80.20322194684374,
      },
      {
        lat: 13.143493904391532,
        lng: 80.20650113688698,
      },
      {
        lat: 13.150045546020134,
        lng: 80.20195001020359,
      },
      {
        lat: 13.157671835943631,
        lng: 80.21264500213182,
      },
      {
        lat: 13.14359769030446,
        lng: 80.19543402202508,
      },
      {
        lat: 13.147332639927194,
        lng: 80.20190637611192,
      },
      {
        lat: 13.147326410301911,
        lng: 80.20159978545152,
      },
      {
        lat: 13.142725883657128,
        lng: 80.19603917962631,
      },
      {
        lat: 13.148936308234205,
        lng: 80.21175610330941,
      },
      {
        lat: 13.152680567549053,
        lng: 80.21320555841007,
      },
      {
        lat: 13.143636475272915,
        lng: 80.19603774051845,
      },
      {
        lat: 13.147101025534816,
        lng: 80.19971252113186,
      },
      {
        lat: 13.153827165732254,
        lng: 80.21025844410644,
      },
      {
        lat: 13.149362896728409,
        lng: 80.19977274503351,
      },
      {
        lat: 13.14583908859726,
        lng: 80.1955328616697,
      },
      {
        lat: 13.14908782969319,
        lng: 80.19781418717311,
      },
      {
        lat: 13.143088426158577,
        lng: 80.19647228967739,
      },
      {
        lat: 13.147404593886382,
        lng: 80.19679270208333,
      },
      {
        lat: 13.15148717446168,
        lng: 80.20705065403557,
      },
      {
        lat: 13.153063152931677,
        lng: 80.21262732670236,
      },
      {
        lat: 13.15063142290004,
        lng: 80.20728351762075,
      },
      {
        lat: 13.143242680322096,
        lng: 80.20534420710437,
      },
      {
        lat: 13.145544992019547,
        lng: 80.2055412293584,
      },
      {
        lat: 13.150252567074102,
        lng: 80.21257829431984,
      },
      {
        lat: 13.151292930372263,
        lng: 80.21070706969995,
      },
      {
        lat: 13.149423423320025,
        lng: 80.20305056761234,
      },
      {
        lat: 13.149964260173324,
        lng: 80.20402432201568,
      },
      {
        lat: 13.159330869925807,
        lng: 80.21377937533943,
      },
      {
        lat: 13.157680177792988,
        lng: 80.21409226973763,
      },
      {
        lat: 13.142577353218591,
        lng: 80.1994964539262,
      },
      {
        lat: 13.142015122357655,
        lng: 80.2020554034134,
      },
      {
        lat: 13.141620453537666,
        lng: 80.19638832660631,
      },
      {
        lat: 13.158680308762827,
        lng: 80.21435592063804,
      },
      {
        lat: 13.151187655401735,
        lng: 80.2144760999106,
      },
      {
        lat: 13.149861336969785,
        lng: 80.20899956689544,
      },
      {
        lat: 13.149112868302273,
        lng: 80.20290296419627,
      },
      {
        lat: 13.149210813248578,
        lng: 80.21393720915168,
      },
      {
        lat: 13.14167751846539,
        lng: 80.19562657876445,
      },
      {
        lat: 13.148126149790492,
        lng: 80.1972545749351,
      },
      {
        lat: 13.142005242532584,
        lng: 80.20362535747383,
      },
      {
        lat: 13.14638250163677,
        lng: 80.2042529526643,
      },
      {
        lat: 13.145560618373873,
        lng: 80.1966708065392,
      },
      {
        lat: 13.15329608625027,
        lng: 80.20458184930189,
      },
      {
        lat: 13.151964413532808,
        lng: 80.21529941359621,
      },
      {
        lat: 13.15798776713289,
        lng: 80.2151503241005,
      },
      {
        lat: 13.150509057706307,
        lng: 80.20640815173856,
      },
      {
        lat: 13.145538478212192,
        lng: 80.19840690249264,
      },
      {
        lat: 13.1469167325491,
        lng: 80.19962715069828,
      },
      {
        lat: 13.144150360736273,
        lng: 80.20024609626789,
      },
      {
        lat: 13.145937461860822,
        lng: 80.19737005082307,
      },
      {
        lat: 13.149061542601432,
        lng: 80.21252894362453,
      },
      {
        lat: 13.153770552732727,
        lng: 80.20660902790492,
      },
      {
        lat: 13.14161508461286,
        lng: 80.20474200061143,
      },
      {
        lat: 13.14927876141389,
        lng: 80.20522500915119,
      },
      {
        lat: 13.142620316418201,
        lng: 80.20103699430933,
      },
      {
        lat: 13.144235632991737,
        lng: 80.20615668121563,
      },
      {
        lat: 13.141215938672396,
        lng: 80.19905911804193,
      },
      {
        lat: 13.146130408672319,
        lng: 80.19925937121252,
      },
      {
        lat: 13.150587751827972,
        lng: 80.21482481217318,
      },
      {
        lat: 13.154481914882888,
        lng: 80.21324367415822,
      },
      {
        lat: 13.149943927458484,
        lng: 80.20075782340413,
      },
      {
        lat: 13.143005634568194,
        lng: 80.20080373230421,
      },
      {
        lat: 13.147536473172314,
        lng: 80.19827205242535,
      },
      {
        lat: 13.153943046562109,
        lng: 80.21048953254798,
      },
      {
        lat: 13.150973078159375,
        lng: 80.21422776174795,
      },
      {
        lat: 13.158319711010503,
        lng: 80.21761545450535,
      },
      {
        lat: 13.141534829014919,
        lng: 80.2030940135302,
      },
      {
        lat: 13.159491172579019,
        lng: 80.21745666425383,
      },
      {
        lat: 13.14077706588646,
        lng: 80.20318950656906,
      },
      {
        lat: 13.147557654919973,
        lng: 80.20661982810799,
      },
      {
        lat: 13.14403901862382,
        lng: 80.20023763780357,
      },
      {
        lat: 13.15061877135492,
        lng: 80.20514438671876,
      },
      {
        lat: 13.155579160978766,
        lng: 80.21212319846302,
      },
      {
        lat: 13.1411259941126,
        lng: 80.20443658277695,
      },
      {
        lat: 13.145890821125144,
        lng: 80.20098492712137,
      },
      {
        lat: 13.155447341205234,
        lng: 80.21453042857688,
      },
      {
        lat: 13.148024107317186,
        lng: 80.20441761315764,
      },
      {
        lat: 13.150204671503635,
        lng: 80.20241022640806,
      },
      {
        lat: 13.156938296374161,
        lng: 80.21659553139719,
      },
      {
        lat: 13.143189194231873,
        lng: 80.20108165080961,
      },
      {
        lat: 13.15616674727741,
        lng: 80.21284430789292,
      },
      {
        lat: 13.149001903898602,
        lng: 80.20513458899742,
      },
      {
        lat: 13.144573853335507,
        lng: 80.19627711003494,
      },
      {
        lat: 13.14872180531527,
        lng: 80.19931825311842,
      },
      {
        lat: 13.150830988559807,
        lng: 80.20825501552811,
      },
      {
        lat: 13.14736254286468,
        lng: 80.20270978915383,
      },
      {
        lat: 13.150633426074942,
        lng: 80.20195154971225,
      },
      {
        lat: 13.156689715595398,
        lng: 80.21151452273563,
      },
      {
        lat: 13.151790066402775,
        lng: 80.20263175169161,
      },
      {
        lat: 13.151975062917247,
        lng: 80.21600619877324,
      },
      {
        lat: 13.143886266741221,
        lng: 80.20318200661407,
      },
      {
        lat: 13.14380581414306,
        lng: 80.19877411063841,
      },
      {
        lat: 13.148568668709508,
        lng: 80.20033401457891,
      },
      {
        lat: 13.147040178592006,
        lng: 80.20084713810428,
      },
      {
        lat: 13.147810891438757,
        lng: 80.20198582880766,
      },
      {
        lat: 13.152468532037783,
        lng: 80.20266838762913,
      },
      {
        lat: 13.144218357331898,
        lng: 80.19870630706203,
      },
    ],
    cases: 153,
    coords: [
      {
        lat: 13.139810575629408,
        lng: 80.20541756147661,
      },
      {
        lat: 13.139949587093295,
        lng: 80.20451983440594,
      },
      {
        lat: 13.13998177711264,
        lng: 80.20387404555555,
      },
      {
        lat: 13.14044234348202,
        lng: 80.20239980471109,
      },
      {
        lat: 13.140779557168484,
        lng: 80.20142867615724,
      },
      {
        lat: 13.140951303937975,
        lng: 80.19997481067944,
      },
      {
        lat: 13.141296651451304,
        lng: 80.19737189123053,
      },
      {
        lat: 13.141664964584086,
        lng: 80.1955756729401,
      },
      {
        lat: 13.141735233717027,
        lng: 80.1952523200253,
      },
      {
        lat: 13.141897210800249,
        lng: 80.19516129411315,
      },
      {
        lat: 13.142256081418422,
        lng: 80.1947817056544,
      },
      {
        lat: 13.14297207773806,
        lng: 80.19373564731845,
      },
      {
        lat: 13.143187683657978,
        lng: 80.19355451247363,
      },
      {
        lat: 13.14335042414222,
        lng: 80.19358899718183,
      },
      {
        lat: 13.143351950944384,
        lng: 80.19384001842256,
      },
      {
        lat: 13.143515891058795,
        lng: 80.19407173410578,
      },
      {
        lat: 13.143841699199243,
        lng: 80.1941944937884,
      },
      {
        lat: 13.144403134331899,
        lng: 80.19445870739142,
      },
      {
        lat: 13.146106152114783,
        lng: 80.19535877590228,
      },
      {
        lat: 13.14724686230685,
        lng: 80.19585119010112,
      },
      {
        lat: 13.148369296342983,
        lng: 80.19630789695255,
      },
      {
        lat: 13.14929581835995,
        lng: 80.19721453714867,
      },
      {
        lat: 13.149622935187796,
        lng: 80.19755245789464,
      },
      {
        lat: 13.149913063475257,
        lng: 80.19774724359135,
      },
      {
        lat: 13.149986059040634,
        lng: 80.19787214289218,
      },
      {
        lat: 13.149906129278495,
        lng: 80.19957626546145,
      },
      {
        lat: 13.149943554048305,
        lng: 80.1997911208652,
      },
      {
        lat: 13.150070614679562,
        lng: 80.19989763158773,
      },
      {
        lat: 13.15033214308656,
        lng: 80.19999866214117,
      },
      {
        lat: 13.151820254666909,
        lng: 80.20104094268761,
      },
      {
        lat: 13.15272026621896,
        lng: 80.20192164129519,
      },
      {
        lat: 13.153256505283599,
        lng: 80.20274989887614,
      },
      {
        lat: 13.153628888451157,
        lng: 80.20421802007723,
      },
      {
        lat: 13.153973990441964,
        lng: 80.20579741162078,
      },
      {
        lat: 13.153924662031214,
        lng: 80.20688046378444,
      },
      {
        lat: 13.153815537324268,
        lng: 80.20732554515429,
      },
      {
        lat: 13.15314897050339,
        lng: 80.20805294293866,
      },
      {
        lat: 13.153094914660528,
        lng: 80.20835875892801,
      },
      {
        lat: 13.153350406625698,
        lng: 80.2089950734985,
      },
      {
        lat: 13.154587922509855,
        lng: 80.21020603408382,
      },
      {
        lat: 13.155057749597887,
        lng: 80.21050954207135,
      },
      {
        lat: 13.155989964820144,
        lng: 80.21072173069092,
      },
      {
        lat: 13.157258116174093,
        lng: 80.21150327951271,
      },
      {
        lat: 13.158079780447384,
        lng: 80.21176041939333,
      },
      {
        lat: 13.158877540905314,
        lng: 80.2117316599197,
      },
      {
        lat: 13.159431366471418,
        lng: 80.21168295711057,
      },
      {
        lat: 13.159565285825092,
        lng: 80.21183587845553,
      },
      {
        lat: 13.159613227308293,
        lng: 80.21242968385096,
      },
      {
        lat: 13.159445019441382,
        lng: 80.2139276391892,
      },
      {
        lat: 13.159231547144316,
        lng: 80.21527192287309,
      },
      {
        lat: 13.159146907800299,
        lng: 80.21593287379409,
      },
      {
        lat: 13.159240381419002,
        lng: 80.21672436421801,
      },
      {
        lat: 13.159553618409152,
        lng: 80.21720588524951,
      },
      {
        lat: 13.159998365405405,
        lng: 80.21744420725884,
      },
      {
        lat: 13.160087020328708,
        lng: 80.21744345694924,
      },
      {
        lat: 13.16035311895155,
        lng: 80.21746321270754,
      },
      {
        lat: 13.160486636747287,
        lng: 80.21755011399112,
      },
      {
        lat: 13.160489046094927,
        lng: 80.217946234358,
      },
      {
        lat: 13.150765114494044,
        lng: 80.21684041356721,
      },
      {
        lat: 13.149480262523094,
        lng: 80.21665134937722,
      },
      {
        lat: 13.149126827843816,
        lng: 80.21647939463918,
      },
      {
        lat: 13.1485153156645,
        lng: 80.21525994839433,
      },
      {
        lat: 13.14848026605489,
        lng: 80.2136357469463,
      },
      {
        lat: 13.14862702592792,
        lng: 80.21293472109014,
      },
      {
        lat: 13.148651979879,
        lng: 80.20876079944142,
      },
      {
        lat: 13.148790531521474,
        lng: 80.20671026003164,
      },
      {
        lat: 13.148662404796958,
        lng: 80.20633646022857,
      },
      {
        lat: 13.148536254127944,
        lng: 80.20628754331811,
      },
      {
        lat: 13.148284712811257,
        lng: 80.20631466445566,
      },
      {
        lat: 13.1481346088443,
        lng: 80.20646588849351,
      },
      {
        lat: 13.147934166213272,
        lng: 80.20661753856076,
      },
      {
        lat: 13.146326369044571,
        lng: 80.2071309913287,
      },
      {
        lat: 13.145471979791868,
        lng: 80.20736315275022,
      },
      {
        lat: 13.145119305133942,
        lng: 80.20731615297106,
      },
      {
        lat: 13.144412587779888,
        lng: 80.20699723448675,
      },
      {
        lat: 13.142292891730357,
        lng: 80.20611545200943,
      },
      {
        lat: 13.141231523662873,
        lng: 80.20542465085352,
      },
      {
        lat: 13.139810575629408,
        lng: 80.20541756147661,
      },
    ],
    properties: {
      Ward_No: 25,
      Zone_Name: "MADHAVARAM",
      fillColor: "#de4307",
      zname: "MADHAVARAM(25)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.152581001110285,
        lng: 80.25803067995484,
      },
      {
        lat: 13.154608625838957,
        lng: 80.2581180970555,
      },
      {
        lat: 13.1530604657817,
        lng: 80.2579879963775,
      },
      {
        lat: 13.151104649752567,
        lng: 80.26136481149047,
      },
      {
        lat: 13.155622858953908,
        lng: 80.26016537726699,
      },
      {
        lat: 13.16369024214312,
        lng: 80.25662587292987,
      },
      {
        lat: 13.155956760854568,
        lng: 80.25863753726429,
      },
      {
        lat: 13.152393295588386,
        lng: 80.2621801029615,
      },
      {
        lat: 13.150725810462244,
        lng: 80.26254037065813,
      },
      {
        lat: 13.161472660342858,
        lng: 80.26519507718058,
      },
      {
        lat: 13.148788289892655,
        lng: 80.26241571266158,
      },
      {
        lat: 13.160963650328116,
        lng: 80.25778000967128,
      },
      {
        lat: 13.161952220613463,
        lng: 80.25836170245366,
      },
      {
        lat: 13.157512534973304,
        lng: 80.2639208238165,
      },
      {
        lat: 13.152802385330535,
        lng: 80.26340558778725,
      },
      {
        lat: 13.147100271498056,
        lng: 80.25957418034747,
      },
      {
        lat: 13.151547306649991,
        lng: 80.25681922588387,
      },
      {
        lat: 13.151714796364441,
        lng: 80.26502254582203,
      },
      {
        lat: 13.157664418612098,
        lng: 80.26595756261852,
      },
      {
        lat: 13.161592903639068,
        lng: 80.26117045793913,
      },
      {
        lat: 13.16018528040352,
        lng: 80.26319109283693,
      },
      {
        lat: 13.163051689970258,
        lng: 80.25850341693376,
      },
      {
        lat: 13.147313372763536,
        lng: 80.25908739719637,
      },
      {
        lat: 13.14687466692064,
        lng: 80.26129175937285,
      },
      {
        lat: 13.162466827070956,
        lng: 80.25915049575812,
      },
      {
        lat: 13.159264056254294,
        lng: 80.25791768420956,
      },
      {
        lat: 13.150045602084116,
        lng: 80.25725639112098,
      },
      {
        lat: 13.15047977328761,
        lng: 80.2593791741345,
      },
      {
        lat: 13.156887454089574,
        lng: 80.25908698064015,
      },
      {
        lat: 13.147559641954635,
        lng: 80.2618500123883,
      },
      {
        lat: 13.147716604253294,
        lng: 80.26704147414424,
      },
      {
        lat: 13.150555581516745,
        lng: 80.25657601521927,
      },
      {
        lat: 13.160718455333871,
        lng: 80.26090155444038,
      },
      {
        lat: 13.156936692605361,
        lng: 80.25981194613894,
      },
      {
        lat: 13.150032137546754,
        lng: 80.25637383523751,
      },
      {
        lat: 13.159605497027242,
        lng: 80.26193446893829,
      },
      {
        lat: 13.157889066162031,
        lng: 80.26533839111605,
      },
      {
        lat: 13.150466946614408,
        lng: 80.25682861533856,
      },
      {
        lat: 13.162016036081704,
        lng: 80.25906877946768,
      },
      {
        lat: 13.156482586046055,
        lng: 80.26168668371602,
      },
      {
        lat: 13.15188479478182,
        lng: 80.26116700729942,
      },
      {
        lat: 13.151075815199745,
        lng: 80.26201979909331,
      },
      {
        lat: 13.147913407618475,
        lng: 80.26646725729947,
      },
      {
        lat: 13.150643860194569,
        lng: 80.25757909276705,
      },
      {
        lat: 13.157967195138824,
        lng: 80.26258172592044,
      },
      {
        lat: 13.14804840652031,
        lng: 80.26713649779963,
      },
      {
        lat: 13.150197805747304,
        lng: 80.26553868427067,
      },
      {
        lat: 13.153630063248151,
        lng: 80.25829264401892,
      },
      {
        lat: 13.149850098026427,
        lng: 80.25848078245158,
      },
      {
        lat: 13.149225000301056,
        lng: 80.26126179570001,
      },
      {
        lat: 13.152600520394836,
        lng: 80.26683943622312,
      },
      {
        lat: 13.160096986520555,
        lng: 80.2582550673672,
      },
      {
        lat: 13.153610747155662,
        lng: 80.25916463025925,
      },
      {
        lat: 13.15190810456141,
        lng: 80.26122432661525,
      },
      {
        lat: 13.155063623397801,
        lng: 80.25962737986083,
      },
      {
        lat: 13.147367678468372,
        lng: 80.26355379293912,
      },
      {
        lat: 13.159072669015467,
        lng: 80.25943898915115,
      },
      {
        lat: 13.146724453449021,
        lng: 80.26406988096922,
      },
      {
        lat: 13.16099540966562,
        lng: 80.26535897005641,
      },
      {
        lat: 13.148250150377688,
        lng: 80.26093630521008,
      },
      {
        lat: 13.155358974386692,
        lng: 80.25910646288322,
      },
      {
        lat: 13.150268630436457,
        lng: 80.25806420675906,
      },
      {
        lat: 13.157909085169365,
        lng: 80.25973240846285,
      },
      {
        lat: 13.157086020867546,
        lng: 80.25923980415703,
      },
      {
        lat: 13.162089157510804,
        lng: 80.26005252827197,
      },
      {
        lat: 13.15950511138363,
        lng: 80.26796762981904,
      },
      {
        lat: 13.159594143202938,
        lng: 80.2601354878071,
      },
      {
        lat: 13.145725334544393,
        lng: 80.26604157997112,
      },
      {
        lat: 13.15299705021788,
        lng: 80.25848059953532,
      },
      {
        lat: 13.157215454864799,
        lng: 80.2638304348935,
      },
      {
        lat: 13.147440390626247,
        lng: 80.26710283819943,
      },
      {
        lat: 13.14751441416386,
        lng: 80.26744362763651,
      },
      {
        lat: 13.146809294060533,
        lng: 80.26266115412956,
      },
      {
        lat: 13.1485621394838,
        lng: 80.25745457741797,
      },
      {
        lat: 13.14889020243555,
        lng: 80.25719553510866,
      },
      {
        lat: 13.154193735068283,
        lng: 80.26553855463459,
      },
      {
        lat: 13.157581920691623,
        lng: 80.26772098931107,
      },
      {
        lat: 13.15970425332531,
        lng: 80.25976613262907,
      },
      {
        lat: 13.150132766229149,
        lng: 80.26105740843816,
      },
      {
        lat: 13.151163409337384,
        lng: 80.25668192887728,
      },
      {
        lat: 13.14681749977123,
        lng: 80.26709165816771,
      },
      {
        lat: 13.161579703329148,
        lng: 80.26469332374184,
      },
      {
        lat: 13.154744045960877,
        lng: 80.26491935179332,
      },
      {
        lat: 13.146501617513627,
        lng: 80.25995095258519,
      },
      {
        lat: 13.157708339352535,
        lng: 80.26583891454553,
      },
      {
        lat: 13.15084784642572,
        lng: 80.26420573344673,
      },
      {
        lat: 13.159464825917706,
        lng: 80.26764087481043,
      },
      {
        lat: 13.153626705536517,
        lng: 80.26758265830271,
      },
      {
        lat: 13.14853201691077,
        lng: 80.26738672463522,
      },
      {
        lat: 13.152922915906194,
        lng: 80.2673806606657,
      },
      {
        lat: 13.163708346389432,
        lng: 80.25746833164067,
      },
      {
        lat: 13.152298808974493,
        lng: 80.26012438539573,
      },
      {
        lat: 13.14569008913942,
        lng: 80.2659549012339,
      },
      {
        lat: 13.153400962723966,
        lng: 80.26483947871526,
      },
      {
        lat: 13.161880793386908,
        lng: 80.25847972598274,
      },
      {
        lat: 13.147948216297305,
        lng: 80.26767521778186,
      },
    ],
    cases: 96,
    coords: [
      {
        lat: 13.147432256570115,
        lng: 80.25536136618199,
      },
      {
        lat: 13.14957881950122,
        lng: 80.25588713478935,
      },
      {
        lat: 13.150537873750556,
        lng: 80.2563537384699,
      },
      {
        lat: 13.152747744395578,
        lng: 80.2568827900752,
      },
      {
        lat: 13.155655019858894,
        lng: 80.2571868376374,
      },
      {
        lat: 13.157090158715196,
        lng: 80.25732075950201,
      },
      {
        lat: 13.15797543956068,
        lng: 80.2577514705917,
      },
      {
        lat: 13.158969270581549,
        lng: 80.25788912735908,
      },
      {
        lat: 13.159741115097564,
        lng: 80.25780956113029,
      },
      {
        lat: 13.160730282062326,
        lng: 80.25718040133148,
      },
      {
        lat: 13.161206144571143,
        lng: 80.25681120444101,
      },
      {
        lat: 13.161647452406404,
        lng: 80.25680746953837,
      },
      {
        lat: 13.161829109692002,
        lng: 80.2564407625831,
      },
      {
        lat: 13.16212153813211,
        lng: 80.2561461520514,
      },
      {
        lat: 13.162635953077414,
        lng: 80.25606876451579,
      },
      {
        lat: 13.16386190193769,
        lng: 80.25645818525392,
      },
      {
        lat: 13.163486893096552,
        lng: 80.25920839554466,
      },
      {
        lat: 13.161950633264867,
        lng: 80.26425357920556,
      },
      {
        lat: 13.161355467687727,
        lng: 80.26846931935236,
      },
      {
        lat: 13.156760513936355,
        lng: 80.2678743634324,
      },
      {
        lat: 13.155605415258155,
        lng: 80.26768911034593,
      },
      {
        lat: 13.15386197429564,
        lng: 80.2676551082924,
      },
      {
        lat: 13.15290328625612,
        lng: 80.2674681929317,
      },
      {
        lat: 13.14975315416555,
        lng: 80.26625154352965,
      },
      {
        lat: 13.149457945119485,
        lng: 80.2661565274672,
      },
      {
        lat: 13.149311230228374,
        lng: 80.26625528364652,
      },
      {
        lat: 13.149323091987574,
        lng: 80.26820547311911,
      },
      {
        lat: 13.149250031086105,
        lng: 80.2683036059455,
      },
      {
        lat: 13.148956008215967,
        lng: 80.26840360883045,
      },
      {
        lat: 13.148439837201407,
        lng: 80.26831046282648,
      },
      {
        lat: 13.148316042648228,
        lng: 80.26814086016896,
      },
      {
        lat: 13.148143145435448,
        lng: 80.26797167307998,
      },
      {
        lat: 13.147872784263315,
        lng: 80.26792520397022,
      },
      {
        lat: 13.147135354736267,
        lng: 80.26778517328785,
      },
      {
        lat: 13.146494202992608,
        lng: 80.26732740567931,
      },
      {
        lat: 13.146296902721994,
        lng: 80.26718280374297,
      },
      {
        lat: 13.146294085554187,
        lng: 80.26671963374316,
      },
      {
        lat: 13.146293047650259,
        lng: 80.2665489921643,
      },
      {
        lat: 13.146145146583219,
        lng: 80.26645272939639,
      },
      {
        lat: 13.145826127567512,
        lng: 80.26647980796031,
      },
      {
        lat: 13.145556804299137,
        lng: 80.2666039804294,
      },
      {
        lat: 13.145433454562102,
        lng: 80.26650750987704,
      },
      {
        lat: 13.145437273371522,
        lng: 80.26560434796048,
      },
      {
        lat: 13.145542196276585,
        lng: 80.26513985991701,
      },
      {
        lat: 13.145874009286098,
        lng: 80.2606436973303,
      },
      {
        lat: 13.145943451800871,
        lng: 80.26025083265172,
      },
      {
        lat: 13.146476959033077,
        lng: 80.25939044041925,
      },
      {
        lat: 13.147043994598908,
        lng: 80.25813748745942,
      },
      {
        lat: 13.147432256570115,
        lng: 80.25536136618199,
      },
    ],
    properties: {
      Ward_No: 29,
      Zone_Name: "MADHAVARAM",
      fillColor: "#f6d04d",
      zname: "MADHAVARAM(29)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.153851889599338,
        lng: 80.24342533547835,
      },
      {
        lat: 13.15042513752661,
        lng: 80.253138352815,
      },
      {
        lat: 13.155255821966934,
        lng: 80.25680620309109,
      },
      {
        lat: 13.159425264238772,
        lng: 80.24818958485258,
      },
      {
        lat: 13.155807910623771,
        lng: 80.25441683403054,
      },
      {
        lat: 13.155566165969413,
        lng: 80.24683383466389,
      },
      {
        lat: 13.166566809784586,
        lng: 80.24840839489188,
      },
      {
        lat: 13.146679281326463,
        lng: 80.24529709598283,
      },
      {
        lat: 13.15304404244855,
        lng: 80.25668346346741,
      },
      {
        lat: 13.150582751318694,
        lng: 80.24724550421782,
      },
      {
        lat: 13.163626002965641,
        lng: 80.24862826871959,
      },
      {
        lat: 13.154654007207421,
        lng: 80.24223209469207,
      },
      {
        lat: 13.150347879445484,
        lng: 80.24699893904497,
      },
      {
        lat: 13.158313122876727,
        lng: 80.24532715867389,
      },
      {
        lat: 13.162327057159539,
        lng: 80.2494106660929,
      },
      {
        lat: 13.161445018655485,
        lng: 80.24608025579273,
      },
      {
        lat: 13.158235450301436,
        lng: 80.24366503224365,
      },
      {
        lat: 13.160395955717016,
        lng: 80.24725321304227,
      },
      {
        lat: 13.147779755346921,
        lng: 80.24053122598194,
      },
      {
        lat: 13.160394775698055,
        lng: 80.25037282446601,
      },
      {
        lat: 13.160151985100626,
        lng: 80.24519424256384,
      },
      {
        lat: 13.148263333120523,
        lng: 80.25384367415653,
      },
      {
        lat: 13.15323397098906,
        lng: 80.25494951007038,
      },
      {
        lat: 13.163470203734772,
        lng: 80.24907842950792,
      },
      {
        lat: 13.15297184942739,
        lng: 80.24764585000815,
      },
      {
        lat: 13.151091377903747,
        lng: 80.24520250961571,
      },
      {
        lat: 13.149503638919265,
        lng: 80.25118496267018,
      },
      {
        lat: 13.155539531415462,
        lng: 80.25216637263264,
      },
      {
        lat: 13.156733918140409,
        lng: 80.24755029179042,
      },
      {
        lat: 13.149330539688437,
        lng: 80.24710607481197,
      },
      {
        lat: 13.148577904961067,
        lng: 80.25330186537899,
      },
      {
        lat: 13.149439669324043,
        lng: 80.25119196964273,
      },
      {
        lat: 13.147515731297807,
        lng: 80.25529770300486,
      },
      {
        lat: 13.1482928691109,
        lng: 80.24594684116211,
      },
      {
        lat: 13.148143430902637,
        lng: 80.24651441622814,
      },
      {
        lat: 13.150952482537278,
        lng: 80.24089012570714,
      },
      {
        lat: 13.149866119746038,
        lng: 80.25195447263494,
      },
      {
        lat: 13.159041370021335,
        lng: 80.24520301550504,
      },
      {
        lat: 13.155026523450482,
        lng: 80.2533871158086,
      },
      {
        lat: 13.156008226413533,
        lng: 80.25571726849793,
      },
      {
        lat: 13.15583698354337,
        lng: 80.2490186284548,
      },
      {
        lat: 13.14864531888932,
        lng: 80.24014113972835,
      },
      {
        lat: 13.153031475942889,
        lng: 80.25157295821951,
      },
      {
        lat: 13.156981979420461,
        lng: 80.25334935342478,
      },
      {
        lat: 13.146391086346652,
        lng: 80.24468467453573,
      },
      {
        lat: 13.152141070642635,
        lng: 80.24123366546078,
      },
      {
        lat: 13.1516905283437,
        lng: 80.24829605287972,
      },
      {
        lat: 13.147838980787089,
        lng: 80.24105292984748,
      },
      {
        lat: 13.163206155071396,
        lng: 80.25077606395043,
      },
      {
        lat: 13.163065004140108,
        lng: 80.25415407800736,
      },
      {
        lat: 13.164914401545458,
        lng: 80.24950644777776,
      },
      {
        lat: 13.160174873547593,
        lng: 80.25199562440653,
      },
      {
        lat: 13.158791151844339,
        lng: 80.24628674785876,
      },
      {
        lat: 13.16055831648423,
        lng: 80.25063804289543,
      },
      {
        lat: 13.1505902909709,
        lng: 80.24520461562568,
      },
      {
        lat: 13.156528987886066,
        lng: 80.24407334106364,
      },
      {
        lat: 13.148139807059684,
        lng: 80.24897955487974,
      },
      {
        lat: 13.156505469751828,
        lng: 80.2459137290975,
      },
      {
        lat: 13.155587508331186,
        lng: 80.25654479016761,
      },
      {
        lat: 13.156686693367696,
        lng: 80.24816045161164,
      },
      {
        lat: 13.152813233424506,
        lng: 80.25600424451936,
      },
      {
        lat: 13.149056181118556,
        lng: 80.25429514365749,
      },
      {
        lat: 13.146819474850336,
        lng: 80.24778795015517,
      },
      {
        lat: 13.158621520635213,
        lng: 80.2441058686714,
      },
      {
        lat: 13.163855436086624,
        lng: 80.25131001449319,
      },
      {
        lat: 13.161660521498142,
        lng: 80.24785954950279,
      },
      {
        lat: 13.153718396031556,
        lng: 80.24574263999622,
      },
      {
        lat: 13.161448544786953,
        lng: 80.25459124918213,
      },
      {
        lat: 13.15108200358097,
        lng: 80.24885905329772,
      },
      {
        lat: 13.150679328462013,
        lng: 80.25041209923896,
      },
      {
        lat: 13.159989584348752,
        lng: 80.24838209671792,
      },
      {
        lat: 13.15310719148996,
        lng: 80.24121005723703,
      },
      {
        lat: 13.151762123296816,
        lng: 80.25644805975504,
      },
      {
        lat: 13.146989460528797,
        lng: 80.24036487764313,
      },
      {
        lat: 13.16283051778993,
        lng: 80.25449307321298,
      },
      {
        lat: 13.155887572040216,
        lng: 80.24577421653478,
      },
      {
        lat: 13.156125457407077,
        lng: 80.24809682269232,
      },
      {
        lat: 13.155155059169578,
        lng: 80.25002060667165,
      },
      {
        lat: 13.148964215239678,
        lng: 80.24591548656463,
      },
      {
        lat: 13.153798070476194,
        lng: 80.24993944650537,
      },
      {
        lat: 13.146878465101477,
        lng: 80.24562777089095,
      },
      {
        lat: 13.158138173833487,
        lng: 80.24722071560505,
      },
      {
        lat: 13.161024526263642,
        lng: 80.24852824883688,
      },
      {
        lat: 13.16128535267425,
        lng: 80.25024405743649,
      },
      {
        lat: 13.148465405028569,
        lng: 80.25281696968167,
      },
      {
        lat: 13.155098016380753,
        lng: 80.24292218547752,
      },
      {
        lat: 13.160108581571905,
        lng: 80.24827668634809,
      },
      {
        lat: 13.157753007964015,
        lng: 80.25350342209444,
      },
      {
        lat: 13.160352424908396,
        lng: 80.24606070793247,
      },
      {
        lat: 13.152513710061555,
        lng: 80.24400413404705,
      },
      {
        lat: 13.149370130429913,
        lng: 80.24041545892926,
      },
      {
        lat: 13.164379271318467,
        lng: 80.25166232208927,
      },
      {
        lat: 13.149455112755513,
        lng: 80.24725452870321,
      },
      {
        lat: 13.162603840892583,
        lng: 80.25007301680165,
      },
      {
        lat: 13.163860865631582,
        lng: 80.25270796296445,
      },
      {
        lat: 13.148071588692723,
        lng: 80.24661587523747,
      },
      {
        lat: 13.156783470444417,
        lng: 80.24295746878363,
      },
      {
        lat: 13.16092375360958,
        lng: 80.25189184574855,
      },
      {
        lat: 13.151271296232101,
        lng: 80.24744599765981,
      },
      {
        lat: 13.166562852861036,
        lng: 80.2490275286941,
      },
      {
        lat: 13.154113920492927,
        lng: 80.2458441031431,
      },
      {
        lat: 13.16195336257605,
        lng: 80.25568014857808,
      },
      {
        lat: 13.16228065156356,
        lng: 80.25078976721723,
      },
      {
        lat: 13.154329793458905,
        lng: 80.25166375208441,
      },
      {
        lat: 13.16577714675562,
        lng: 80.24712643327585,
      },
      {
        lat: 13.155944562446878,
        lng: 80.25344766226993,
      },
      {
        lat: 13.161679647459065,
        lng: 80.25474357967437,
      },
      {
        lat: 13.164134668314063,
        lng: 80.24683382610681,
      },
      {
        lat: 13.156809017170374,
        lng: 80.25645790948909,
      },
      {
        lat: 13.152122677389656,
        lng: 80.25015548385322,
      },
      {
        lat: 13.152227684158618,
        lng: 80.2466424996176,
      },
      {
        lat: 13.152185218750674,
        lng: 80.24628572952884,
      },
      {
        lat: 13.148899243567401,
        lng: 80.24208507289298,
      },
      {
        lat: 13.148686980583522,
        lng: 80.24011184570868,
      },
      {
        lat: 13.148119407584453,
        lng: 80.24432384638047,
      },
      {
        lat: 13.150230931561527,
        lng: 80.24660843353682,
      },
      {
        lat: 13.145925878310061,
        lng: 80.24213397390304,
      },
      {
        lat: 13.149498985389066,
        lng: 80.24518765233684,
      },
      {
        lat: 13.15022695221838,
        lng: 80.24376014285161,
      },
      {
        lat: 13.158531908755995,
        lng: 80.24901580002566,
      },
      {
        lat: 13.146623223161688,
        lng: 80.2432460823401,
      },
      {
        lat: 13.155900157623226,
        lng: 80.25693136371761,
      },
      {
        lat: 13.15964006322194,
        lng: 80.24536741753943,
      },
      {
        lat: 13.16293676402513,
        lng: 80.25532541801503,
      },
      {
        lat: 13.161735346227026,
        lng: 80.25071934898789,
      },
      {
        lat: 13.16030789446702,
        lng: 80.25489461117463,
      },
      {
        lat: 13.150764754503232,
        lng: 80.25551504947673,
      },
      {
        lat: 13.150703047317606,
        lng: 80.25225520912024,
      },
      {
        lat: 13.154954010901518,
        lng: 80.24406484307495,
      },
      {
        lat: 13.157816575609584,
        lng: 80.25104469771532,
      },
      {
        lat: 13.153094500596712,
        lng: 80.25425329274566,
      },
      {
        lat: 13.152970553978985,
        lng: 80.2481273788787,
      },
      {
        lat: 13.156550433495637,
        lng: 80.24697982800315,
      },
      {
        lat: 13.14669728301417,
        lng: 80.2438452206311,
      },
      {
        lat: 13.158240381232405,
        lng: 80.25497064484512,
      },
      {
        lat: 13.165170666285416,
        lng: 80.25242030294353,
      },
      {
        lat: 13.15415309912566,
        lng: 80.24403925605995,
      },
      {
        lat: 13.155287427200195,
        lng: 80.24270149827882,
      },
      {
        lat: 13.154031507538868,
        lng: 80.25482761373948,
      },
      {
        lat: 13.150784910374762,
        lng: 80.24084379061546,
      },
      {
        lat: 13.163726256968454,
        lng: 80.25350890586363,
      },
      {
        lat: 13.151405772014858,
        lng: 80.24805043781315,
      },
      {
        lat: 13.165257441913004,
        lng: 80.25123820533787,
      },
      {
        lat: 13.150600652531649,
        lng: 80.2444911805885,
      },
      {
        lat: 13.160013023731644,
        lng: 80.25602459537251,
      },
      {
        lat: 13.149238236837808,
        lng: 80.24843664582401,
      },
      {
        lat: 13.16570425328224,
        lng: 80.25169265483235,
      },
      {
        lat: 13.15790973175086,
        lng: 80.24571637728741,
      },
      {
        lat: 13.150990024284312,
        lng: 80.24499506648363,
      },
    ],
    cases: 149,
    coords: [
      {
        lat: 13.16386190193769,
        lng: 80.25645818525392,
      },
      {
        lat: 13.162635953077414,
        lng: 80.25606876451579,
      },
      {
        lat: 13.16212153813211,
        lng: 80.2561461520514,
      },
      {
        lat: 13.161829109692002,
        lng: 80.2564407625831,
      },
      {
        lat: 13.161647452406404,
        lng: 80.25680746953837,
      },
      {
        lat: 13.161206144571143,
        lng: 80.25681120444101,
      },
      {
        lat: 13.160730282062326,
        lng: 80.25718040133148,
      },
      {
        lat: 13.159741115097564,
        lng: 80.25780956113029,
      },
      {
        lat: 13.158969270581549,
        lng: 80.25788912735908,
      },
      {
        lat: 13.15797543956068,
        lng: 80.2577514705917,
      },
      {
        lat: 13.157090158715196,
        lng: 80.25732075950201,
      },
      {
        lat: 13.155655019858894,
        lng: 80.2571868376374,
      },
      {
        lat: 13.152747744395578,
        lng: 80.2568827900752,
      },
      {
        lat: 13.150537873750556,
        lng: 80.2563537384699,
      },
      {
        lat: 13.14957881950122,
        lng: 80.25588713478935,
      },
      {
        lat: 13.147432256570115,
        lng: 80.25536136618199,
      },
      {
        lat: 13.148007730888565,
        lng: 80.2512466533882,
      },
      {
        lat: 13.147886974629019,
        lng: 80.249107982824,
      },
      {
        lat: 13.147739413789157,
        lng: 80.24846732390291,
      },
      {
        lat: 13.147235313880238,
        lng: 80.24825762097545,
      },
      {
        lat: 13.146839607200581,
        lng: 80.24815398531602,
      },
      {
        lat: 13.146191633671256,
        lng: 80.24790983848588,
      },
      {
        lat: 13.145974196526609,
        lng: 80.24759072482941,
      },
      {
        lat: 13.145972027573988,
        lng: 80.24723412775984,
      },
      {
        lat: 13.146184693022867,
        lng: 80.24676872786326,
      },
      {
        lat: 13.146253918642207,
        lng: 80.24634020347781,
      },
      {
        lat: 13.145965303820859,
        lng: 80.24612867684424,
      },
      {
        lat: 13.145532164693043,
        lng: 80.24577572718712,
      },
      {
        lat: 13.145457733587403,
        lng: 80.24534841860567,
      },
      {
        lat: 13.145633400378985,
        lng: 80.24470502412531,
      },
      {
        lat: 13.145807765798939,
        lng: 80.24384767140327,
      },
      {
        lat: 13.145804512370004,
        lng: 80.24331277579883,
      },
      {
        lat: 13.145550944148484,
        lng: 80.24295830638663,
      },
      {
        lat: 13.146634345632473,
        lng: 80.23956766013299,
      },
      {
        lat: 13.149636393506777,
        lng: 80.24007575903207,
      },
      {
        lat: 13.153408969539335,
        lng: 80.24104912217993,
      },
      {
        lat: 13.163887718250713,
        lng: 80.24650873254915,
      },
      {
        lat: 13.167020787944008,
        lng: 80.24666900228486,
      },
      {
        lat: 13.167101512259775,
        lng: 80.24968715765043,
      },
      {
        lat: 13.166984926354033,
        lng: 80.25064596061134,
      },
      {
        lat: 13.166789215982638,
        lng: 80.25067192701636,
      },
      {
        lat: 13.166423312503102,
        lng: 80.25089381426969,
      },
      {
        lat: 13.16386190193769,
        lng: 80.25645818525392,
      },
    ],
    properties: {
      Ward_No: 28,
      Zone_Name: "MADHAVARAM",
      fillColor: "#de4307",
      zname: "MADHAVARAM(28)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.158699110432613,
        lng: 80.2399098420899,
      },
      {
        lat: 13.156223176218832,
        lng: 80.23239951019116,
      },
      {
        lat: 13.166226310290961,
        lng: 80.2449357137201,
      },
      {
        lat: 13.16262758638572,
        lng: 80.23170942661369,
      },
      {
        lat: 13.162872492280039,
        lng: 80.24573776910916,
      },
      {
        lat: 13.154671331776754,
        lng: 80.22746126805949,
      },
      {
        lat: 13.156793726355207,
        lng: 80.22726515596673,
      },
      {
        lat: 13.164237884408909,
        lng: 80.2423075457193,
      },
      {
        lat: 13.157780770484152,
        lng: 80.23614938745702,
      },
      {
        lat: 13.158642282759574,
        lng: 80.24085853578991,
      },
      {
        lat: 13.152275814672073,
        lng: 80.23879306781166,
      },
      {
        lat: 13.153170602469423,
        lng: 80.23227453521245,
      },
      {
        lat: 13.162424655398265,
        lng: 80.23998345811265,
      },
      {
        lat: 13.16386656755877,
        lng: 80.24536405998371,
      },
      {
        lat: 13.166916257174021,
        lng: 80.24606549355495,
      },
      {
        lat: 13.165978710747645,
        lng: 80.24342146436696,
      },
      {
        lat: 13.154796142654883,
        lng: 80.23967029494976,
      },
      {
        lat: 13.15267316318594,
        lng: 80.23090562069848,
      },
      {
        lat: 13.160473049518076,
        lng: 80.22453517709202,
      },
      {
        lat: 13.158976306349356,
        lng: 80.23616168066394,
      },
      {
        lat: 13.165746516061127,
        lng: 80.24524810008302,
      },
      {
        lat: 13.165938354922085,
        lng: 80.23204757948017,
      },
      {
        lat: 13.161885061314111,
        lng: 80.22669520044344,
      },
      {
        lat: 13.15704033743456,
        lng: 80.23281009444324,
      },
      {
        lat: 13.166276506134489,
        lng: 80.24355202817462,
      },
      {
        lat: 13.163202616819717,
        lng: 80.22811386721328,
      },
      {
        lat: 13.15793417647909,
        lng: 80.22802014340824,
      },
      {
        lat: 13.158137590623495,
        lng: 80.24276727863321,
      },
      {
        lat: 13.157663547444805,
        lng: 80.24101791543045,
      },
      {
        lat: 13.160382908594938,
        lng: 80.24062953837824,
      },
      {
        lat: 13.1646572501684,
        lng: 80.23568883863517,
      },
      {
        lat: 13.161531174281182,
        lng: 80.2263014668616,
      },
      {
        lat: 13.16450437829323,
        lng: 80.24543261095437,
      },
      {
        lat: 13.164428391904346,
        lng: 80.24462380129508,
      },
      {
        lat: 13.164432342577747,
        lng: 80.24597880570951,
      },
      {
        lat: 13.15911374504503,
        lng: 80.22863956934985,
      },
      {
        lat: 13.157897769909447,
        lng: 80.23498701650253,
      },
      {
        lat: 13.159775504904042,
        lng: 80.2252537046964,
      },
      {
        lat: 13.152449608281621,
        lng: 80.23525069874353,
      },
      {
        lat: 13.160276515804599,
        lng: 80.22439965514427,
      },
      {
        lat: 13.163206396172688,
        lng: 80.23892140080368,
      },
      {
        lat: 13.158446172852578,
        lng: 80.23578862060839,
      },
      {
        lat: 13.162854482554346,
        lng: 80.23530154263705,
      },
      {
        lat: 13.155806039853877,
        lng: 80.23142401336746,
      },
      {
        lat: 13.162132221928845,
        lng: 80.22690877912214,
      },
      {
        lat: 13.154380757436982,
        lng: 80.23599708632152,
      },
      {
        lat: 13.154878893717475,
        lng: 80.23502136922724,
      },
      {
        lat: 13.15993068734109,
        lng: 80.23677557882974,
      },
      {
        lat: 13.15706783771034,
        lng: 80.22606004804385,
      },
      {
        lat: 13.158930889649719,
        lng: 80.22680321592885,
      },
      {
        lat: 13.16621023386564,
        lng: 80.24567592954676,
      },
      {
        lat: 13.155239324023219,
        lng: 80.23207982560304,
      },
      {
        lat: 13.160672942900375,
        lng: 80.22696218347936,
      },
      {
        lat: 13.151754408398581,
        lng: 80.23296386850905,
      },
      {
        lat: 13.163011913260538,
        lng: 80.23943170165802,
      },
      {
        lat: 13.155498306031348,
        lng: 80.22958900629692,
      },
      {
        lat: 13.163645242078392,
        lng: 80.2377204572607,
      },
      {
        lat: 13.151468556156981,
        lng: 80.22981412151601,
      },
      {
        lat: 13.161779770554288,
        lng: 80.23411400801615,
      },
      {
        lat: 13.156987207897938,
        lng: 80.24215878425359,
      },
      {
        lat: 13.150023447517414,
        lng: 80.23917459827074,
      },
      {
        lat: 13.158862362316615,
        lng: 80.22907991649969,
      },
      {
        lat: 13.151017582863025,
        lng: 80.2392750353713,
      },
      {
        lat: 13.163474078007559,
        lng: 80.2435189835265,
      },
      {
        lat: 13.157346965229708,
        lng: 80.22972170122007,
      },
      {
        lat: 13.157999687754675,
        lng: 80.22855858717797,
      },
      {
        lat: 13.15311830280621,
        lng: 80.23590642757114,
      },
      {
        lat: 13.153395857396267,
        lng: 80.24066758323643,
      },
      {
        lat: 13.155612653911898,
        lng: 80.22965617031366,
      },
      {
        lat: 13.158045595230126,
        lng: 80.22830549026112,
      },
      {
        lat: 13.158679642309513,
        lng: 80.23002969028212,
      },
      {
        lat: 13.162372773908377,
        lng: 80.23081510439626,
      },
      {
        lat: 13.156855819787582,
        lng: 80.23361615585212,
      },
      {
        lat: 13.159226330954516,
        lng: 80.23307739988968,
      },
      {
        lat: 13.15494074473501,
        lng: 80.23836766373421,
      },
      {
        lat: 13.157324471956345,
        lng: 80.23857504296205,
      },
      {
        lat: 13.16027066939709,
        lng: 80.23742903405144,
      },
      {
        lat: 13.162385754278981,
        lng: 80.24568572085587,
      },
      {
        lat: 13.157206107002812,
        lng: 80.23552132888064,
      },
      {
        lat: 13.163740642439333,
        lng: 80.23085104699061,
      },
      {
        lat: 13.165589418778763,
        lng: 80.24564932694126,
      },
      {
        lat: 13.163590699202116,
        lng: 80.23386218624658,
      },
      {
        lat: 13.157832434166187,
        lng: 80.2263170039432,
      },
      {
        lat: 13.16170042330703,
        lng: 80.23541608435767,
      },
      {
        lat: 13.153480529791906,
        lng: 80.2312071662384,
      },
      {
        lat: 13.154477415123884,
        lng: 80.23577160537052,
      },
      {
        lat: 13.158413652824695,
        lng: 80.2264437337711,
      },
      {
        lat: 13.153828988118304,
        lng: 80.23095428371882,
      },
      {
        lat: 13.155383306464413,
        lng: 80.23353815944462,
      },
      {
        lat: 13.164171730389409,
        lng: 80.24501750359344,
      },
      {
        lat: 13.160854449731819,
        lng: 80.22770257835461,
      },
      {
        lat: 13.154631280993012,
        lng: 80.23001506287792,
      },
      {
        lat: 13.1567006229905,
        lng: 80.23657893773314,
      },
      {
        lat: 13.161110198697939,
        lng: 80.23999129586196,
      },
      {
        lat: 13.157664238879276,
        lng: 80.24249593385335,
      },
      {
        lat: 13.162135208812085,
        lng: 80.23472030509463,
      },
      {
        lat: 13.163243305712768,
        lng: 80.24327388669953,
      },
      {
        lat: 13.152270764271924,
        lng: 80.23000149295117,
      },
      {
        lat: 13.16480850860732,
        lng: 80.24241779017375,
      },
      {
        lat: 13.157015229383367,
        lng: 80.2390537348043,
      },
      {
        lat: 13.158128615602084,
        lng: 80.23999787205192,
      },
      {
        lat: 13.159449546866732,
        lng: 80.23382770722553,
      },
      {
        lat: 13.161915196932675,
        lng: 80.2332719934308,
      },
      {
        lat: 13.157884145856288,
        lng: 80.23478300992997,
      },
      {
        lat: 13.154681552011613,
        lng: 80.24044113947255,
      },
      {
        lat: 13.16262904030856,
        lng: 80.22822709856335,
      },
      {
        lat: 13.160311470939046,
        lng: 80.2417406931123,
      },
      {
        lat: 13.156367807451733,
        lng: 80.23623417406233,
      },
    ],
    cases: 108,
    coords: [
      {
        lat: 13.149636393506777,
        lng: 80.24007575903207,
      },
      {
        lat: 13.150277496497669,
        lng: 80.23720100588048,
      },
      {
        lat: 13.150541811029179,
        lng: 80.2360923152638,
      },
      {
        lat: 13.150416800049383,
        lng: 80.23534576944375,
      },
      {
        lat: 13.150319358774109,
        lng: 80.23418033215438,
      },
      {
        lat: 13.150278693815016,
        lng: 80.23244623544913,
      },
      {
        lat: 13.151421646944002,
        lng: 80.23219732911787,
      },
      {
        lat: 13.151377708171475,
        lng: 80.22992498536871,
      },
      {
        lat: 13.151243785143373,
        lng: 80.22771321148551,
      },
      {
        lat: 13.16060969180096,
        lng: 80.22320508225225,
      },
      {
        lat: 13.160660711895032,
        lng: 80.22430504144982,
      },
      {
        lat: 13.160886900193475,
        lng: 80.22505139303533,
      },
      {
        lat: 13.161020685694567,
        lng: 80.22518230769315,
      },
      {
        lat: 13.161353543215219,
        lng: 80.22524551409322,
      },
      {
        lat: 13.161552882940244,
        lng: 80.22522181920961,
      },
      {
        lat: 13.16173126360814,
        lng: 80.22539637208666,
      },
      {
        lat: 13.161998031494267,
        lng: 80.22552616128023,
      },
      {
        lat: 13.162219534950248,
        lng: 80.2255022788192,
      },
      {
        lat: 13.162596719932639,
        lng: 80.22556511006445,
      },
      {
        lat: 13.163239468127447,
        lng: 80.22555967031981,
      },
      {
        lat: 13.163625649491411,
        lng: 80.22566663492668,
      },
      {
        lat: 13.163535852401544,
        lng: 80.22700444806351,
      },
      {
        lat: 13.1634929498987,
        lng: 80.22800152351475,
      },
      {
        lat: 13.163568023138193,
        lng: 80.22826829878254,
      },
      {
        lat: 13.163519945711414,
        lng: 80.22841456601891,
      },
      {
        lat: 13.163301675176443,
        lng: 80.22875675410366,
      },
      {
        lat: 13.16352364208565,
        lng: 80.22902228617217,
      },
      {
        lat: 13.16377038698315,
        lng: 80.22933622865332,
      },
      {
        lat: 13.164069497101542,
        lng: 80.23020885927559,
      },
      {
        lat: 13.164196344141985,
        lng: 80.23093708746016,
      },
      {
        lat: 13.16478658021047,
        lng: 80.2313696731737,
      },
      {
        lat: 13.16593902154954,
        lng: 80.23165164045342,
      },
      {
        lat: 13.166185322882187,
        lng: 80.23189265651607,
      },
      {
        lat: 13.16640714193643,
        lng: 80.23213387977843,
      },
      {
        lat: 13.166652009075818,
        lng: 80.23213910042155,
      },
      {
        lat: 13.16680023344032,
        lng: 80.23235663647768,
      },
      {
        lat: 13.166777821131644,
        lng: 80.23269716696323,
      },
      {
        lat: 13.165389933519252,
        lng: 80.23798419548629,
      },
      {
        lat: 13.16525013688801,
        lng: 80.23915226137987,
      },
      {
        lat: 13.165277871975576,
        lng: 80.23968684791494,
      },
      {
        lat: 13.16550264812937,
        lng: 80.2404142473,
      },
      {
        lat: 13.165996729344082,
        lng: 80.24113936748645,
      },
      {
        lat: 13.166145692983426,
        lng: 80.24147844757312,
      },
      {
        lat: 13.166325200954699,
        lng: 80.24281398151122,
      },
      {
        lat: 13.16670101071718,
        lng: 80.2442207842688,
      },
      {
        lat: 13.166974751427569,
        lng: 80.24494776925414,
      },
      {
        lat: 13.167020787944008,
        lng: 80.24666900228486,
      },
      {
        lat: 13.163887718250713,
        lng: 80.24650873254915,
      },
      {
        lat: 13.153408969539335,
        lng: 80.24104912217993,
      },
      {
        lat: 13.149636393506777,
        lng: 80.24007575903207,
      },
    ],
    properties: {
      Ward_No: 27,
      Zone_Name: "MADHAVARAM",
      fillColor: "#f6d04d",
      zname: "MADHAVARAM(27)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.147965791950476,
        lng: 80.23782619324399,
      },
      {
        lat: 13.146417526836728,
        lng: 80.23107840073686,
      },
      {
        lat: 13.149983789826875,
        lng: 80.22848944935672,
      },
      {
        lat: 13.148999051120335,
        lng: 80.23375641590698,
      },
      {
        lat: 13.145925216052976,
        lng: 80.23730553618343,
      },
      {
        lat: 13.1452839322963,
        lng: 80.23438926834386,
      },
      {
        lat: 13.145401557756754,
        lng: 80.23845389520174,
      },
      {
        lat: 13.144199009752619,
        lng: 80.23312367965208,
      },
      {
        lat: 13.151306002984878,
        lng: 80.23154280573961,
      },
      {
        lat: 13.145686683764911,
        lng: 80.23915325833927,
      },
      {
        lat: 13.148527783200809,
        lng: 80.2376108219965,
      },
      {
        lat: 13.148324235113476,
        lng: 80.23644436221625,
      },
      {
        lat: 13.14504504151246,
        lng: 80.23455111221918,
      },
      {
        lat: 13.149694885236954,
        lng: 80.23357971057891,
      },
      {
        lat: 13.14263360826524,
        lng: 80.23488493215923,
      },
      {
        lat: 13.146094942707583,
        lng: 80.23307937397406,
      },
      {
        lat: 13.146647861962906,
        lng: 80.23681212122438,
      },
      {
        lat: 13.148199015307299,
        lng: 80.23878610541423,
      },
      {
        lat: 13.147258910546162,
        lng: 80.23105268336336,
      },
      {
        lat: 13.146014771064998,
        lng: 80.23122167499506,
      },
      {
        lat: 13.147032864461721,
        lng: 80.23430740958545,
      },
      {
        lat: 13.147764685884786,
        lng: 80.2361780431321,
      },
      {
        lat: 13.145472364265872,
        lng: 80.2313306000712,
      },
      {
        lat: 13.149764693030455,
        lng: 80.23854027125964,
      },
      {
        lat: 13.149766517915364,
        lng: 80.22949269262523,
      },
      {
        lat: 13.146921568591793,
        lng: 80.23033629425221,
      },
      {
        lat: 13.149425265462233,
        lng: 80.23995790491576,
      },
      {
        lat: 13.146510283003854,
        lng: 80.23839328657682,
      },
      {
        lat: 13.146623636278175,
        lng: 80.23063222352383,
      },
      {
        lat: 13.146718140783861,
        lng: 80.23195000506351,
      },
      {
        lat: 13.149704121642113,
        lng: 80.23519581670155,
      },
      {
        lat: 13.144425579904038,
        lng: 80.23900863743077,
      },
      {
        lat: 13.149978982165441,
        lng: 80.2299193933633,
      },
      {
        lat: 13.151414941731952,
        lng: 80.23208499822545,
      },
      {
        lat: 13.147854478696747,
        lng: 80.23915249625873,
      },
      {
        lat: 13.14964689809172,
        lng: 80.2311331842671,
      },
      {
        lat: 13.146162707378735,
        lng: 80.23540134653948,
      },
      {
        lat: 13.145382499076605,
        lng: 80.23272484341813,
      },
      {
        lat: 13.147137270381194,
        lng: 80.23683681380167,
      },
      {
        lat: 13.1467071108564,
        lng: 80.23409512185445,
      },
      {
        lat: 13.149860925835478,
        lng: 80.2365968597186,
      },
      {
        lat: 13.147922280761422,
        lng: 80.2363638907116,
      },
      {
        lat: 13.144001198162385,
        lng: 80.23271260785644,
      },
      {
        lat: 13.146374832432242,
        lng: 80.23633112069967,
      },
      {
        lat: 13.150735525714367,
        lng: 80.22938972563252,
      },
      {
        lat: 13.148688135139473,
        lng: 80.23615286490019,
      },
      {
        lat: 13.14730994643058,
        lng: 80.23488075975287,
      },
      {
        lat: 13.147046336321385,
        lng: 80.23621905753102,
      },
      {
        lat: 13.15051422528132,
        lng: 80.22991044818008,
      },
      {
        lat: 13.149538147236886,
        lng: 80.23961603673872,
      },
      {
        lat: 13.147617017852223,
        lng: 80.23144152741082,
      },
      {
        lat: 13.149611873421238,
        lng: 80.23429540772862,
      },
      {
        lat: 13.147807630726872,
        lng: 80.23042916651592,
      },
      {
        lat: 13.149353264684342,
        lng: 80.22895917479283,
      },
      {
        lat: 13.147085969581973,
        lng: 80.23702459410694,
      },
      {
        lat: 13.144731798289918,
        lng: 80.23626277638597,
      },
      {
        lat: 13.147634047126509,
        lng: 80.23412399584677,
      },
      {
        lat: 13.147344302344338,
        lng: 80.23247449631371,
      },
    ],
    cases: 58,
    coords: [
      {
        lat: 13.143912465780565,
        lng: 80.2392990343665,
      },
      {
        lat: 13.143840856145797,
        lng: 80.23860116771374,
      },
      {
        lat: 13.143773712753212,
        lng: 80.23746537815862,
      },
      {
        lat: 13.143831216583532,
        lng: 80.23701632919655,
      },
      {
        lat: 13.14376952954695,
        lng: 80.23677761804746,
      },
      {
        lat: 13.143436252837748,
        lng: 80.23645149297361,
      },
      {
        lat: 13.14204909786201,
        lng: 80.23616419129775,
      },
      {
        lat: 13.142671166872407,
        lng: 80.2344543898557,
      },
      {
        lat: 13.144098365339188,
        lng: 80.23142199167025,
      },
      {
        lat: 13.147190492324865,
        lng: 80.22978099794547,
      },
      {
        lat: 13.151243785143373,
        lng: 80.22771321148551,
      },
      {
        lat: 13.151377708171475,
        lng: 80.22992498536871,
      },
      {
        lat: 13.151421646944002,
        lng: 80.23219732911787,
      },
      {
        lat: 13.150278693815016,
        lng: 80.23244623544913,
      },
      {
        lat: 13.150319358774109,
        lng: 80.23418033215438,
      },
      {
        lat: 13.150416800049383,
        lng: 80.23534576944375,
      },
      {
        lat: 13.150541811029179,
        lng: 80.2360923152638,
      },
      {
        lat: 13.150277496497669,
        lng: 80.23720100588048,
      },
      {
        lat: 13.149636393506777,
        lng: 80.24007575903207,
      },
      {
        lat: 13.146634345632473,
        lng: 80.23956766013299,
      },
      {
        lat: 13.145550944148484,
        lng: 80.24295830638663,
      },
      {
        lat: 13.145474994776016,
        lng: 80.24228137985645,
      },
      {
        lat: 13.145716633757948,
        lng: 80.2406745653862,
      },
      {
        lat: 13.145749511405606,
        lng: 80.24017502553777,
      },
      {
        lat: 13.145567337756214,
        lng: 80.23974862880956,
      },
      {
        lat: 13.144488177156713,
        lng: 80.23947246968471,
      },
      {
        lat: 13.143912465780565,
        lng: 80.2392990343665,
      },
    ],
    properties: {
      Ward_No: 30,
      Zone_Name: "MADHAVARAM",
      fillColor: "#8bc24c",
      zname: "MADHAVARAM(30)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.127753358256449,
        lng: 80.23158245136214,
      },
      {
        lat: 13.12981140095697,
        lng: 80.22644861864319,
      },
      {
        lat: 13.129534342658104,
        lng: 80.22521613204165,
      },
      {
        lat: 13.136746862525355,
        lng: 80.2244607068391,
      },
      {
        lat: 13.12286659060845,
        lng: 80.23678615697592,
      },
      {
        lat: 13.124647413847363,
        lng: 80.24165292620991,
      },
      {
        lat: 13.13602377606079,
        lng: 80.22427543102272,
      },
      {
        lat: 13.132239104976922,
        lng: 80.23023175739262,
      },
      {
        lat: 13.13847711687147,
        lng: 80.22885550752126,
      },
      {
        lat: 13.137496070984643,
        lng: 80.22378129366393,
      },
      {
        lat: 13.127681596695101,
        lng: 80.23408258339649,
      },
      {
        lat: 13.126747154441594,
        lng: 80.23487526146269,
      },
      {
        lat: 13.13147750284112,
        lng: 80.23157970725896,
      },
      {
        lat: 13.126629325369336,
        lng: 80.23823218667894,
      },
      {
        lat: 13.137911311434136,
        lng: 80.22518572374992,
      },
      {
        lat: 13.134421348336616,
        lng: 80.22830210103223,
      },
      {
        lat: 13.131669443317822,
        lng: 80.23767667941058,
      },
      {
        lat: 13.12860451143768,
        lng: 80.2330325345984,
      },
      {
        lat: 13.13347140547165,
        lng: 80.22886542244947,
      },
      {
        lat: 13.131654925839982,
        lng: 80.22813534195377,
      },
      {
        lat: 13.127417625982524,
        lng: 80.24050508878031,
      },
      {
        lat: 13.135796545183316,
        lng: 80.2314412600132,
      },
      {
        lat: 13.13368680054322,
        lng: 80.22486872591244,
      },
      {
        lat: 13.134219704526545,
        lng: 80.23045745418693,
      },
      {
        lat: 13.138118532275568,
        lng: 80.22336737506288,
      },
      {
        lat: 13.124616796109315,
        lng: 80.23794414026298,
      },
      {
        lat: 13.136926680658126,
        lng: 80.22727361961888,
      },
      {
        lat: 13.130450232309181,
        lng: 80.23105038906041,
      },
      {
        lat: 13.129022386572908,
        lng: 80.22486885173765,
      },
      {
        lat: 13.127627870785716,
        lng: 80.23237094721665,
      },
      {
        lat: 13.13701826916708,
        lng: 80.22625169322792,
      },
      {
        lat: 13.129202528315854,
        lng: 80.23546273317662,
      },
      {
        lat: 13.128801154522748,
        lng: 80.2358385973798,
      },
      {
        lat: 13.132560949064521,
        lng: 80.22554742892743,
      },
      {
        lat: 13.13544575703937,
        lng: 80.22367938177926,
      },
      {
        lat: 13.140682923824343,
        lng: 80.22375832140114,
      },
      {
        lat: 13.127896332425765,
        lng: 80.22876411887978,
      },
      {
        lat: 13.136321916809491,
        lng: 80.2272020926054,
      },
      {
        lat: 13.123994949178073,
        lng: 80.23512048590233,
      },
      {
        lat: 13.129500651876842,
        lng: 80.23597261339701,
      },
      {
        lat: 13.128185440402355,
        lng: 80.23724581966748,
      },
      {
        lat: 13.132273983973006,
        lng: 80.2365232943281,
      },
      {
        lat: 13.13627744296558,
        lng: 80.22916601305762,
      },
      {
        lat: 13.127838800054153,
        lng: 80.22569325990901,
      },
      {
        lat: 13.138156747737575,
        lng: 80.22591773134879,
      },
      {
        lat: 13.12728678488424,
        lng: 80.23113013320098,
      },
      {
        lat: 13.129021467382165,
        lng: 80.23960217116294,
      },
      {
        lat: 13.131652472825783,
        lng: 80.23208445779555,
      },
      {
        lat: 13.129059494105052,
        lng: 80.23967860641761,
      },
    ],
    cases: 49,
    coords: [
      {
        lat: 13.129109545449948,
        lng: 80.24161681273193,
      },
      {
        lat: 13.12685769215258,
        lng: 80.24154006643502,
      },
      {
        lat: 13.126465139799167,
        lng: 80.24152668770398,
      },
      {
        lat: 13.123703726361597,
        lng: 80.24212373497356,
      },
      {
        lat: 13.122466457957547,
        lng: 80.24225713702083,
      },
      {
        lat: 13.12229964458237,
        lng: 80.24197171044996,
      },
      {
        lat: 13.122376197621369,
        lng: 80.24098761677055,
      },
      {
        lat: 13.122883910853341,
        lng: 80.23625458468213,
      },
      {
        lat: 13.122912218429827,
        lng: 80.23412354589351,
      },
      {
        lat: 13.12303103556578,
        lng: 80.2333030021539,
      },
      {
        lat: 13.123358431726546,
        lng: 80.23284948532773,
      },
      {
        lat: 13.123894157688017,
        lng: 80.23272202061592,
      },
      {
        lat: 13.126003021816434,
        lng: 80.23340078018747,
      },
      {
        lat: 13.126332660989213,
        lng: 80.2333160365497,
      },
      {
        lat: 13.126536504765948,
        lng: 80.23290454228737,
      },
      {
        lat: 13.126534510977333,
        lng: 80.23257674389747,
      },
      {
        lat: 13.126403731109866,
        lng: 80.23143049729832,
      },
      {
        lat: 13.126308237078693,
        lng: 80.22930050627467,
      },
      {
        lat: 13.126084953863206,
        lng: 80.22651596623668,
      },
      {
        lat: 13.126080467838824,
        lng: 80.22577841985961,
      },
      {
        lat: 13.126407116328684,
        lng: 80.2252019786373,
      },
      {
        lat: 13.126859310991238,
        lng: 80.22491131324071,
      },
      {
        lat: 13.128262146652412,
        lng: 80.22485846377074,
      },
      {
        lat: 13.12912875790524,
        lng: 80.22485112941492,
      },
      {
        lat: 13.129580703344049,
        lng: 80.22451948921982,
      },
      {
        lat: 13.129944631459159,
        lng: 80.22328710196271,
      },
      {
        lat: 13.130001093546124,
        lng: 80.22061531002015,
      },
      {
        lat: 13.133207833518043,
        lng: 80.22070140283019,
      },
      {
        lat: 13.133445184630967,
        lng: 80.22127983414781,
      },
      {
        lat: 13.133566096021964,
        lng: 80.22193664293573,
      },
      {
        lat: 13.133840534468144,
        lng: 80.22220519233011,
      },
      {
        lat: 13.135714160812373,
        lng: 80.22269238341528,
      },
      {
        lat: 13.137041263774957,
        lng: 80.22302941584769,
      },
      {
        lat: 13.137968545231024,
        lng: 80.22170590383918,
      },
      {
        lat: 13.141019042397309,
        lng: 80.22346010294096,
      },
      {
        lat: 13.141723093801353,
        lng: 80.22387980043135,
      },
      {
        lat: 13.141961358491622,
        lng: 80.22409496271833,
      },
      {
        lat: 13.135494012632675,
        lng: 80.23305832671076,
      },
      {
        lat: 13.13289358298752,
        lng: 80.2363421291896,
      },
      {
        lat: 13.129109545449948,
        lng: 80.24161681273193,
      },
    ],
    properties: {
      Ward_No: 33,
      Zone_Name: "MADHAVARAM",
      fillColor: "#8bc24c",
      zname: "MADHAVARAM(33)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.138971868555693,
        lng: 80.23643581444055,
      },
      {
        lat: 13.139593474990004,
        lng: 80.23738914004379,
      },
      {
        lat: 13.138906203988324,
        lng: 80.23117692912571,
      },
      {
        lat: 13.133596506338085,
        lng: 80.23558725203671,
      },
      {
        lat: 13.131600543997521,
        lng: 80.24080562405997,
      },
      {
        lat: 13.140383046888994,
        lng: 80.23296833997223,
      },
      {
        lat: 13.142657190667325,
        lng: 80.23930060601833,
      },
      {
        lat: 13.138789316770254,
        lng: 80.23247669679947,
      },
      {
        lat: 13.141798968839762,
        lng: 80.23286705239938,
      },
      {
        lat: 13.14467164843851,
        lng: 80.22966352690995,
      },
      {
        lat: 13.136524745736986,
        lng: 80.23904952268924,
      },
      {
        lat: 13.140325400279076,
        lng: 80.2386117204574,
      },
      {
        lat: 13.142693644484355,
        lng: 80.22866192001595,
      },
      {
        lat: 13.13373712754314,
        lng: 80.24243168790079,
      },
      {
        lat: 13.139092145945131,
        lng: 80.23576371695297,
      },
      {
        lat: 13.14397386188782,
        lng: 80.22973351955022,
      },
      {
        lat: 13.132273444571807,
        lng: 80.24057146262554,
      },
      {
        lat: 13.140188744146554,
        lng: 80.22928397147562,
      },
      {
        lat: 13.139748878689375,
        lng: 80.23774397065131,
      },
      {
        lat: 13.140687121836907,
        lng: 80.23768363856954,
      },
      {
        lat: 13.141996413115901,
        lng: 80.23783087380748,
      },
      {
        lat: 13.134522438201993,
        lng: 80.2361291171869,
      },
      {
        lat: 13.142808847397331,
        lng: 80.23143333437471,
      },
      {
        lat: 13.132577638394675,
        lng: 80.23765312279002,
      },
      {
        lat: 13.138846325785517,
        lng: 80.23496309034329,
      },
      {
        lat: 13.140519323062469,
        lng: 80.23794076011085,
      },
      {
        lat: 13.142905978425494,
        lng: 80.22969908912887,
      },
      {
        lat: 13.141892206307347,
        lng: 80.23701534629166,
      },
      {
        lat: 13.134813752274223,
        lng: 80.23576835052863,
      },
      {
        lat: 13.13377248790826,
        lng: 80.23548030155109,
      },
      {
        lat: 13.14474835265074,
        lng: 80.22949710324633,
      },
      {
        lat: 13.137169370529199,
        lng: 80.238722833572,
      },
      {
        lat: 13.14072164372516,
        lng: 80.22706837794189,
      },
      {
        lat: 13.13992531636354,
        lng: 80.2395282737626,
      },
      {
        lat: 13.13836948371465,
        lng: 80.23239388040545,
      },
      {
        lat: 13.137788641220775,
        lng: 80.23775353007159,
      },
      {
        lat: 13.13858268136393,
        lng: 80.2305770785606,
      },
      {
        lat: 13.141520964386384,
        lng: 80.22492084186959,
      },
      {
        lat: 13.143796883524058,
        lng: 80.23929696088206,
      },
      {
        lat: 13.141868429672689,
        lng: 80.22595114924299,
      },
      {
        lat: 13.141770724795709,
        lng: 80.23789028669508,
      },
      {
        lat: 13.13137665189799,
        lng: 80.23998710193179,
      },
      {
        lat: 13.134625130273552,
        lng: 80.23442553730068,
      },
      {
        lat: 13.140548550277957,
        lng: 80.22805001668492,
      },
      {
        lat: 13.14256673091803,
        lng: 80.23418237942519,
      },
      {
        lat: 13.140700273389038,
        lng: 80.23493923056405,
      },
      {
        lat: 13.133109687656944,
        lng: 80.24188455274358,
      },
      {
        lat: 13.143951212893008,
        lng: 80.22797159037206,
      },
      {
        lat: 13.138509498278623,
        lng: 80.23446289237937,
      },
      {
        lat: 13.14522576580726,
        lng: 80.23052134589635,
      },
      {
        lat: 13.140950440146701,
        lng: 80.23269807141646,
      },
      {
        lat: 13.143784223622283,
        lng: 80.2277341826388,
      },
      {
        lat: 13.133550640281044,
        lng: 80.2385658286543,
      },
      {
        lat: 13.141532693773781,
        lng: 80.2329731157763,
      },
      {
        lat: 13.141669071603395,
        lng: 80.23488877363415,
      },
      {
        lat: 13.13353635902505,
        lng: 80.24213841795304,
      },
      {
        lat: 13.142581113336844,
        lng: 80.23998236709069,
      },
      {
        lat: 13.143131304662543,
        lng: 80.23765762527427,
      },
      {
        lat: 13.141437052893185,
        lng: 80.23294181542073,
      },
      {
        lat: 13.139635203453764,
        lng: 80.23431434859346,
      },
      {
        lat: 13.138519274482285,
        lng: 80.23321870099055,
      },
      {
        lat: 13.141480826472833,
        lng: 80.23502541692994,
      },
      {
        lat: 13.13868017927172,
        lng: 80.232147582113,
      },
      {
        lat: 13.139381463908212,
        lng: 80.23265095410544,
      },
      {
        lat: 13.142568456081872,
        lng: 80.22519716754917,
      },
      {
        lat: 13.13744020522563,
        lng: 80.23501490605307,
      },
      {
        lat: 13.14518420762049,
        lng: 80.2297550999355,
      },
      {
        lat: 13.142471975447345,
        lng: 80.23811686442507,
      },
      {
        lat: 13.136547767622542,
        lng: 80.23651755324505,
      },
      {
        lat: 13.142877241515336,
        lng: 80.22901962698334,
      },
      {
        lat: 13.13447069508312,
        lng: 80.23455868541821,
      },
      {
        lat: 13.13984264541973,
        lng: 80.23766829736464,
      },
      {
        lat: 13.142586457503265,
        lng: 80.22838124278373,
      },
      {
        lat: 13.145421270164265,
        lng: 80.229512825322,
      },
      {
        lat: 13.13886135648758,
        lng: 80.23203337374437,
      },
      {
        lat: 13.136856707737163,
        lng: 80.2385020716984,
      },
      {
        lat: 13.137404468339012,
        lng: 80.23451947142105,
      },
      {
        lat: 13.134454595555548,
        lng: 80.23671576694214,
      },
      {
        lat: 13.134393568012689,
        lng: 80.23828996717673,
      },
      {
        lat: 13.138876239673575,
        lng: 80.22939952600883,
      },
      {
        lat: 13.133574591219132,
        lng: 80.2369053058094,
      },
      {
        lat: 13.14658822046634,
        lng: 80.22944335009439,
      },
      {
        lat: 13.141008721012104,
        lng: 80.23111984180642,
      },
      {
        lat: 13.135304961351999,
        lng: 80.23540949432204,
      },
      {
        lat: 13.143971249011587,
        lng: 80.22854315797397,
      },
      {
        lat: 13.139783070797323,
        lng: 80.22963382495502,
      },
      {
        lat: 13.139158304503242,
        lng: 80.22923190389105,
      },
      {
        lat: 13.141058244103926,
        lng: 80.23401945498112,
      },
      {
        lat: 13.134639650743365,
        lng: 80.23814894360476,
      },
      {
        lat: 13.138773043041411,
        lng: 80.23504416776849,
      },
      {
        lat: 13.137562934540945,
        lng: 80.23235309146943,
      },
      {
        lat: 13.141707227693983,
        lng: 80.23059803849728,
      },
      {
        lat: 13.14034381270337,
        lng: 80.22939777468564,
      },
      {
        lat: 13.140566905532049,
        lng: 80.23683387452316,
      },
      {
        lat: 13.139911264974474,
        lng: 80.23762619676161,
      },
      {
        lat: 13.134218454346591,
        lng: 80.238299740757,
      },
      {
        lat: 13.140783808724743,
        lng: 80.23555146030733,
      },
      {
        lat: 13.142900443763208,
        lng: 80.23214201573475,
      },
      {
        lat: 13.139080243977125,
        lng: 80.22889146105955,
      },
      {
        lat: 13.1327305803507,
        lng: 80.24069510278508,
      },
      {
        lat: 13.138699330788228,
        lng: 80.23905637021454,
      },
      {
        lat: 13.139736001995702,
        lng: 80.23720715649792,
      },
      {
        lat: 13.134865213882232,
        lng: 80.23710047934881,
      },
      {
        lat: 13.141415262354245,
        lng: 80.23815011157257,
      },
      {
        lat: 13.136961793752148,
        lng: 80.23225948297831,
      },
      {
        lat: 13.131953408037838,
        lng: 80.2379591473348,
      },
      {
        lat: 13.130104146626683,
        lng: 80.24114551696414,
      },
      {
        lat: 13.144581500019404,
        lng: 80.22951393490499,
      },
      {
        lat: 13.143769538954192,
        lng: 80.23133857514267,
      },
      {
        lat: 13.139386926336417,
        lng: 80.23807059802856,
      },
      {
        lat: 13.135543599851099,
        lng: 80.23828125541944,
      },
      {
        lat: 13.137207451680478,
        lng: 80.23096823331434,
      },
      {
        lat: 13.1427747094672,
        lng: 80.23900781994385,
      },
      {
        lat: 13.140244932791862,
        lng: 80.23401646807095,
      },
      {
        lat: 13.136859038434656,
        lng: 80.23484319095562,
      },
      {
        lat: 13.142149832062097,
        lng: 80.23865483296423,
      },
      {
        lat: 13.13504666504784,
        lng: 80.24055612181729,
      },
      {
        lat: 13.140622805754269,
        lng: 80.23041219882049,
      },
      {
        lat: 13.139196332750194,
        lng: 80.23164295019312,
      },
      {
        lat: 13.142287949451104,
        lng: 80.23225966656182,
      },
      {
        lat: 13.14026525619181,
        lng: 80.22707043488259,
      },
      {
        lat: 13.142750042957507,
        lng: 80.2387070793814,
      },
      {
        lat: 13.139124820645254,
        lng: 80.23671582781144,
      },
      {
        lat: 13.144070295717386,
        lng: 80.22803343176773,
      },
      {
        lat: 13.145498411757533,
        lng: 80.23041000746571,
      },
      {
        lat: 13.13470778351765,
        lng: 80.23746728913936,
      },
      {
        lat: 13.141612078929835,
        lng: 80.23395665266392,
      },
      {
        lat: 13.14213439587543,
        lng: 80.23720149480333,
      },
      {
        lat: 13.143745288418259,
        lng: 80.22836507064017,
      },
      {
        lat: 13.136385729011304,
        lng: 80.23357689544738,
      },
      {
        lat: 13.142633536401796,
        lng: 80.23677128373274,
      },
      {
        lat: 13.140725361065815,
        lng: 80.23469058100066,
      },
      {
        lat: 13.142806354681072,
        lng: 80.23334464940622,
      },
      {
        lat: 13.131116274024764,
        lng: 80.24187180071901,
      },
      {
        lat: 13.130675856223762,
        lng: 80.24061267967416,
      },
    ],
    cases: 135,
    coords: [
      {
        lat: 13.129109545449948,
        lng: 80.24161681273193,
      },
      {
        lat: 13.13289358298752,
        lng: 80.2363421291896,
      },
      {
        lat: 13.135494012632675,
        lng: 80.23305832671076,
      },
      {
        lat: 13.141961358491622,
        lng: 80.22409496271833,
      },
      {
        lat: 13.142232529850192,
        lng: 80.22433984100726,
      },
      {
        lat: 13.144946416805777,
        lng: 80.22841864925493,
      },
      {
        lat: 13.147190492324865,
        lng: 80.22978099794547,
      },
      {
        lat: 13.144098365339188,
        lng: 80.23142199167025,
      },
      {
        lat: 13.142671166872407,
        lng: 80.2344543898557,
      },
      {
        lat: 13.14204909786201,
        lng: 80.23616419129775,
      },
      {
        lat: 13.143436252837748,
        lng: 80.23645149297361,
      },
      {
        lat: 13.14376952954695,
        lng: 80.23677761804746,
      },
      {
        lat: 13.143831216583532,
        lng: 80.23701632919655,
      },
      {
        lat: 13.143773712753212,
        lng: 80.23746537815862,
      },
      {
        lat: 13.143840856145797,
        lng: 80.23860116771374,
      },
      {
        lat: 13.143912465780565,
        lng: 80.2392990343665,
      },
      {
        lat: 13.14359032262532,
        lng: 80.23948006846047,
      },
      {
        lat: 13.142841113410222,
        lng: 80.24030662469208,
      },
      {
        lat: 13.14251831956947,
        lng: 80.24038067966525,
      },
      {
        lat: 13.142158310175878,
        lng: 80.24024108034774,
      },
      {
        lat: 13.14168925686707,
        lng: 80.23988843464167,
      },
      {
        lat: 13.14093419147972,
        lng: 80.23975217878534,
      },
      {
        lat: 13.13712273346694,
        lng: 80.2390355437485,
      },
      {
        lat: 13.13615348436387,
        lng: 80.2391150698402,
      },
      {
        lat: 13.135868556761526,
        lng: 80.23950975822493,
      },
      {
        lat: 13.135831548648872,
        lng: 80.24124253281705,
      },
      {
        lat: 13.135672211981172,
        lng: 80.24218635020786,
      },
      {
        lat: 13.135302551723449,
        lng: 80.24247631709424,
      },
      {
        lat: 13.13369288160251,
        lng: 80.24244896324191,
      },
      {
        lat: 13.131215354110976,
        lng: 80.2422240697518,
      },
      {
        lat: 13.129354591645036,
        lng: 80.24162516424752,
      },
      {
        lat: 13.129109545449948,
        lng: 80.24161681273193,
      },
    ],
    properties: {
      Ward_No: 31,
      Zone_Name: "MADHAVARAM",
      fillColor: "#de4307",
      zname: "MADHAVARAM(31)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.141360465381096,
        lng: 80.24543122060618,
      },
      {
        lat: 13.13992000605313,
        lng: 80.24526559150561,
      },
      {
        lat: 13.139607053586195,
        lng: 80.25491064300394,
      },
      {
        lat: 13.145128412619163,
        lng: 80.25757284688888,
      },
      {
        lat: 13.143668890412332,
        lng: 80.25524796083543,
      },
      {
        lat: 13.144727647168155,
        lng: 80.2404237596372,
      },
      {
        lat: 13.140152941677753,
        lng: 80.24579156686264,
      },
      {
        lat: 13.146196677791062,
        lng: 80.25015987736042,
      },
      {
        lat: 13.143936831912171,
        lng: 80.25654384085279,
      },
      {
        lat: 13.139808046380915,
        lng: 80.2543678861901,
      },
      {
        lat: 13.138766790703084,
        lng: 80.25556035028879,
      },
      {
        lat: 13.146762414502245,
        lng: 80.25700811405687,
      },
      {
        lat: 13.147523218418753,
        lng: 80.25371381386243,
      },
      {
        lat: 13.141754120182792,
        lng: 80.24204234572989,
      },
      {
        lat: 13.146288542202148,
        lng: 80.24922274158023,
      },
      {
        lat: 13.142727018190495,
        lng: 80.25567098385159,
      },
      {
        lat: 13.138556553919964,
        lng: 80.2532897804684,
      },
      {
        lat: 13.13175870980082,
        lng: 80.24258672705572,
      },
      {
        lat: 13.146080076660954,
        lng: 80.2554928124798,
      },
      {
        lat: 13.142995558425838,
        lng: 80.24650948957373,
      },
      {
        lat: 13.141763614437021,
        lng: 80.24416086903895,
      },
      {
        lat: 13.138754021709326,
        lng: 80.25036551195407,
      },
      {
        lat: 13.142582560032956,
        lng: 80.25822203761064,
      },
      {
        lat: 13.140698143279351,
        lng: 80.24582471803963,
      },
      {
        lat: 13.147018874471065,
        lng: 80.24823430496308,
      },
      {
        lat: 13.139577573896403,
        lng: 80.25235512078086,
      },
      {
        lat: 13.139904752893449,
        lng: 80.24048531542644,
      },
      {
        lat: 13.137961369429282,
        lng: 80.25743247850112,
      },
      {
        lat: 13.147597387825781,
        lng: 80.25054764846999,
      },
      {
        lat: 13.140694762949119,
        lng: 80.2417769323402,
      },
      {
        lat: 13.140171785065382,
        lng: 80.24134648062916,
      },
      {
        lat: 13.138476621713227,
        lng: 80.25716030695037,
      },
      {
        lat: 13.144651747383723,
        lng: 80.25125681502176,
      },
      {
        lat: 13.137427656810628,
        lng: 80.24157135282648,
      },
      {
        lat: 13.143926291051178,
        lng: 80.25379602233441,
      },
      {
        lat: 13.144803114179789,
        lng: 80.24222608651833,
      },
      {
        lat: 13.138800605218696,
        lng: 80.2471781957287,
      },
      {
        lat: 13.14151019512358,
        lng: 80.25721543815084,
      },
      {
        lat: 13.144401792663986,
        lng: 80.25478264646323,
      },
      {
        lat: 13.141229617610543,
        lng: 80.25208552085986,
      },
      {
        lat: 13.143490369205505,
        lng: 80.2435926052836,
      },
      {
        lat: 13.14060263640647,
        lng: 80.24593296476284,
      },
      {
        lat: 13.145285873662464,
        lng: 80.24818746556515,
      },
      {
        lat: 13.140508089187557,
        lng: 80.24264194133455,
      },
      {
        lat: 13.147150262863883,
        lng: 80.2563129015079,
      },
      {
        lat: 13.144816113340731,
        lng: 80.25122352234129,
      },
      {
        lat: 13.144035945782305,
        lng: 80.24221435182689,
      },
      {
        lat: 13.138731155864605,
        lng: 80.2436319751189,
      },
      {
        lat: 13.139352389191265,
        lng: 80.24706062464519,
      },
      {
        lat: 13.140282197587876,
        lng: 80.25292719719393,
      },
      {
        lat: 13.140807129274172,
        lng: 80.25537611429932,
      },
      {
        lat: 13.137805411388829,
        lng: 80.23963836337364,
      },
      {
        lat: 13.145304578996573,
        lng: 80.24122086950642,
      },
      {
        lat: 13.140492532516388,
        lng: 80.2420476023832,
      },
      {
        lat: 13.143598431990855,
        lng: 80.24826222457334,
      },
      {
        lat: 13.140502390835556,
        lng: 80.25886666857537,
      },
      {
        lat: 13.138976249122464,
        lng: 80.25686120653852,
      },
      {
        lat: 13.140343609225257,
        lng: 80.25180644358359,
      },
      {
        lat: 13.138978548907396,
        lng: 80.25464876218756,
      },
      {
        lat: 13.1397270598517,
        lng: 80.24080860940516,
      },
      {
        lat: 13.142807745290733,
        lng: 80.24078371241413,
      },
      {
        lat: 13.140233905501086,
        lng: 80.24177632177783,
      },
      {
        lat: 13.145613427120415,
        lng: 80.24666880151001,
      },
      {
        lat: 13.140710467080497,
        lng: 80.25140780584374,
      },
      {
        lat: 13.142770611794452,
        lng: 80.24114881643311,
      },
      {
        lat: 13.141146742921652,
        lng: 80.2436028704621,
      },
      {
        lat: 13.144216594051782,
        lng: 80.2516910250521,
      },
      {
        lat: 13.140183074680916,
        lng: 80.25147885061072,
      },
      {
        lat: 13.138590649052803,
        lng: 80.24992706093926,
      },
      {
        lat: 13.140942162467537,
        lng: 80.2565749136381,
      },
      {
        lat: 13.138860239289604,
        lng: 80.25225014461861,
      },
      {
        lat: 13.138948731232833,
        lng: 80.2406959559568,
      },
      {
        lat: 13.141461662785808,
        lng: 80.25142932271106,
      },
      {
        lat: 13.140624889574497,
        lng: 80.25002487620776,
      },
      {
        lat: 13.142910596254781,
        lng: 80.24692075658977,
      },
      {
        lat: 13.13087340658336,
        lng: 80.24214079756864,
      },
      {
        lat: 13.138891516534816,
        lng: 80.2547523561014,
      },
      {
        lat: 13.144340070140778,
        lng: 80.25166885016817,
      },
      {
        lat: 13.13993820415587,
        lng: 80.24989844865266,
      },
      {
        lat: 13.141223343506512,
        lng: 80.2563016081948,
      },
      {
        lat: 13.13904870574871,
        lng: 80.24205599734705,
      },
      {
        lat: 13.142655098169792,
        lng: 80.24527107914552,
      },
      {
        lat: 13.140475532026993,
        lng: 80.25204688922769,
      },
      {
        lat: 13.137578071218508,
        lng: 80.24976928805155,
      },
      {
        lat: 13.137667880612964,
        lng: 80.2554770631489,
      },
      {
        lat: 13.145024969309336,
        lng: 80.25425773930823,
      },
      {
        lat: 13.136801497600015,
        lng: 80.23960372838623,
      },
      {
        lat: 13.139192062146565,
        lng: 80.2586256808615,
      },
      {
        lat: 13.143064816942026,
        lng: 80.25387586613883,
      },
      {
        lat: 13.143289831776082,
        lng: 80.24182285074438,
      },
      {
        lat: 13.14592422190128,
        lng: 80.25149766647063,
      },
      {
        lat: 13.139231030080142,
        lng: 80.25070977960726,
      },
      {
        lat: 13.145366381517231,
        lng: 80.25106394112227,
      },
      {
        lat: 13.14444563756796,
        lng: 80.25959769357863,
      },
      {
        lat: 13.14377062520686,
        lng: 80.241217501377,
      },
      {
        lat: 13.14276903311628,
        lng: 80.25571603742154,
      },
      {
        lat: 13.141019715177276,
        lng: 80.25006360399551,
      },
      {
        lat: 13.144921592219598,
        lng: 80.25227990443935,
      },
      {
        lat: 13.14281491790894,
        lng: 80.24996956697565,
      },
      {
        lat: 13.139501772409922,
        lng: 80.24632249254981,
      },
      {
        lat: 13.136489537665849,
        lng: 80.24032925048353,
      },
      {
        lat: 13.14468818486721,
        lng: 80.24721767498426,
      },
      {
        lat: 13.138923136298036,
        lng: 80.24980638387025,
      },
      {
        lat: 13.137253752817816,
        lng: 80.25585458667454,
      },
      {
        lat: 13.145382830165783,
        lng: 80.25507513679251,
      },
      {
        lat: 13.138862588499645,
        lng: 80.24141458292014,
      },
      {
        lat: 13.137688889494248,
        lng: 80.25641838562859,
      },
      {
        lat: 13.1455991278613,
        lng: 80.25495204450247,
      },
      {
        lat: 13.143184829542564,
        lng: 80.25433870985215,
      },
      {
        lat: 13.141881375104811,
        lng: 80.2489175949947,
      },
      {
        lat: 13.144197616406638,
        lng: 80.24725353283073,
      },
      {
        lat: 13.145337598417802,
        lng: 80.24190052299012,
      },
      {
        lat: 13.143262729077701,
        lng: 80.24209750217064,
      },
      {
        lat: 13.140716171695304,
        lng: 80.24683090321732,
      },
      {
        lat: 13.13714676493103,
        lng: 80.25434909444918,
      },
      {
        lat: 13.143439513499532,
        lng: 80.24900390056436,
      },
      {
        lat: 13.138804114203772,
        lng: 80.2485909080434,
      },
      {
        lat: 13.146858390954623,
        lng: 80.25743682798678,
      },
      {
        lat: 13.141628289218373,
        lng: 80.24127255148356,
      },
      {
        lat: 13.139166247589179,
        lng: 80.24161117160963,
      },
      {
        lat: 13.143798949295823,
        lng: 80.25520733080667,
      },
      {
        lat: 13.138060035155842,
        lng: 80.24112311476591,
      },
      {
        lat: 13.147273683121186,
        lng: 80.25609584534088,
      },
      {
        lat: 13.140566346456065,
        lng: 80.24706977642776,
      },
      {
        lat: 13.141131276037765,
        lng: 80.249339757586,
      },
    ],
    cases: 125,
    coords: [
      {
        lat: 13.145911134066889,
        lng: 80.26043366727747,
      },
      {
        lat: 13.136322572372238,
        lng: 80.25808654083072,
      },
      {
        lat: 13.13663851993713,
        lng: 80.25679790162887,
      },
      {
        lat: 13.136714085007773,
        lng: 80.25591316098887,
      },
      {
        lat: 13.136548941696544,
        lng: 80.25537873978293,
      },
      {
        lat: 13.136517724417727,
        lng: 80.25468243946929,
      },
      {
        lat: 13.136774333357831,
        lng: 80.25251020135246,
      },
      {
        lat: 13.136893920332687,
        lng: 80.24999084063138,
      },
      {
        lat: 13.137184190709204,
        lng: 80.24891674628488,
      },
      {
        lat: 13.138325949836718,
        lng: 80.24587970674196,
      },
      {
        lat: 13.138500313008992,
        lng: 80.24349383714976,
      },
      {
        lat: 13.130832080071952,
        lng: 80.24262105239482,
      },
      {
        lat: 13.129705289875066,
        lng: 80.24173804007357,
      },
      {
        lat: 13.131215354110976,
        lng: 80.2422240697518,
      },
      {
        lat: 13.13369288160251,
        lng: 80.24244896324191,
      },
      {
        lat: 13.135302551723449,
        lng: 80.24247631709424,
      },
      {
        lat: 13.135672211981172,
        lng: 80.24218635020786,
      },
      {
        lat: 13.135831548648872,
        lng: 80.24124253281705,
      },
      {
        lat: 13.135868556761526,
        lng: 80.23950975822493,
      },
      {
        lat: 13.13615348436387,
        lng: 80.2391150698402,
      },
      {
        lat: 13.13712273346694,
        lng: 80.2390355437485,
      },
      {
        lat: 13.14093419147972,
        lng: 80.23975217878534,
      },
      {
        lat: 13.14168925686707,
        lng: 80.23988843464167,
      },
      {
        lat: 13.142158310175878,
        lng: 80.24024108034774,
      },
      {
        lat: 13.14251831956947,
        lng: 80.24038067966525,
      },
      {
        lat: 13.142841113410222,
        lng: 80.24030662469208,
      },
      {
        lat: 13.14359032262532,
        lng: 80.23948006846047,
      },
      {
        lat: 13.143912465780565,
        lng: 80.2392990343665,
      },
      {
        lat: 13.144488177156713,
        lng: 80.23947246968471,
      },
      {
        lat: 13.145567337756214,
        lng: 80.23974862880956,
      },
      {
        lat: 13.145749511405606,
        lng: 80.24017502553777,
      },
      {
        lat: 13.145716633757948,
        lng: 80.2406745653862,
      },
      {
        lat: 13.145474994776016,
        lng: 80.24228137985645,
      },
      {
        lat: 13.145550944148484,
        lng: 80.24295830638663,
      },
      {
        lat: 13.145804512370004,
        lng: 80.24331277579883,
      },
      {
        lat: 13.145807765798939,
        lng: 80.24384767140327,
      },
      {
        lat: 13.145633400378985,
        lng: 80.24470502412531,
      },
      {
        lat: 13.145457733587403,
        lng: 80.24534841860567,
      },
      {
        lat: 13.145532164693043,
        lng: 80.24577572718712,
      },
      {
        lat: 13.145965303820859,
        lng: 80.24612867684424,
      },
      {
        lat: 13.146253918642207,
        lng: 80.24634020347781,
      },
      {
        lat: 13.146184693022867,
        lng: 80.24676872786326,
      },
      {
        lat: 13.145972027573988,
        lng: 80.24723412775984,
      },
      {
        lat: 13.145974196526609,
        lng: 80.24759072482941,
      },
      {
        lat: 13.146191633671256,
        lng: 80.24790983848588,
      },
      {
        lat: 13.146839607200581,
        lng: 80.24815398531602,
      },
      {
        lat: 13.147235313880238,
        lng: 80.24825762097545,
      },
      {
        lat: 13.147739413789157,
        lng: 80.24846732390291,
      },
      {
        lat: 13.147886974629019,
        lng: 80.249107982824,
      },
      {
        lat: 13.148007730888565,
        lng: 80.2512466533882,
      },
      {
        lat: 13.147043994598908,
        lng: 80.25813748745942,
      },
      {
        lat: 13.146476959033077,
        lng: 80.25939044041925,
      },
      {
        lat: 13.145943451800871,
        lng: 80.26025083265172,
      },
      {
        lat: 13.145911134066889,
        lng: 80.26043366727747,
      },
    ],
    properties: {
      Ward_No: 34,
      Zone_Name: "TONDIARPET",
      fillColor: "#de4307",
      zname: "TONDIARPET(34)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.133911784412959,
        lng: 80.25416415175965,
      },
      {
        lat: 13.134172839161618,
        lng: 80.24331464272184,
      },
      {
        lat: 13.130730529551727,
        lng: 80.24924130762913,
      },
      {
        lat: 13.136359855369053,
        lng: 80.2458521560511,
      },
      {
        lat: 13.135757967022816,
        lng: 80.24427786910508,
      },
      {
        lat: 13.135715012054497,
        lng: 80.25551839149047,
      },
      {
        lat: 13.12309770253947,
        lng: 80.24827290051917,
      },
      {
        lat: 13.137152418699365,
        lng: 80.24369359226668,
      },
      {
        lat: 13.1290615717491,
        lng: 80.2482563109686,
      },
      {
        lat: 13.12310161634266,
        lng: 80.24654772016865,
      },
      {
        lat: 13.124112667663036,
        lng: 80.24716568254253,
      },
      {
        lat: 13.130968009362098,
        lng: 80.2448067187157,
      },
      {
        lat: 13.129725133886074,
        lng: 80.24826497765649,
      },
      {
        lat: 13.136028554085609,
        lng: 80.2554327378401,
      },
      {
        lat: 13.133067385556645,
        lng: 80.2570200800138,
      },
      {
        lat: 13.130545335482358,
        lng: 80.24971429629444,
      },
      {
        lat: 13.133042639116773,
        lng: 80.25637187489654,
      },
      {
        lat: 13.137225609886107,
        lng: 80.24821810634424,
      },
      {
        lat: 13.125411550335459,
        lng: 80.24574122042743,
      },
      {
        lat: 13.123229899313602,
        lng: 80.24799030094715,
      },
      {
        lat: 13.133424085430685,
        lng: 80.25264482646881,
      },
      {
        lat: 13.132561342493702,
        lng: 80.25334008107949,
      },
      {
        lat: 13.134832291197494,
        lng: 80.25551662506302,
      },
      {
        lat: 13.12484633257121,
        lng: 80.24804119081777,
      },
      {
        lat: 13.132420794465663,
        lng: 80.24459129854834,
      },
      {
        lat: 13.130743373585283,
        lng: 80.24411985473914,
      },
      {
        lat: 13.125286644266163,
        lng: 80.24473428641207,
      },
      {
        lat: 13.133386356249565,
        lng: 80.25302184606562,
      },
      {
        lat: 13.129135167035649,
        lng: 80.24884452067847,
      },
      {
        lat: 13.136445376923838,
        lng: 80.25128641145606,
      },
      {
        lat: 13.122613618565751,
        lng: 80.24608548677637,
      },
      {
        lat: 13.124010586021711,
        lng: 80.24808236348429,
      },
      {
        lat: 13.12746729505396,
        lng: 80.24756334333338,
      },
      {
        lat: 13.129313674574739,
        lng: 80.24456483933108,
      },
      {
        lat: 13.131985152825207,
        lng: 80.2513010336808,
      },
      {
        lat: 13.133112786725876,
        lng: 80.25468116791178,
      },
      {
        lat: 13.129879854724265,
        lng: 80.24359149168353,
      },
      {
        lat: 13.12978314218575,
        lng: 80.24653477433242,
      },
      {
        lat: 13.129058560762807,
        lng: 80.24580039161823,
      },
      {
        lat: 13.130020917131748,
        lng: 80.24398614236877,
      },
      {
        lat: 13.133159352959254,
        lng: 80.25497142977407,
      },
      {
        lat: 13.133741390456084,
        lng: 80.25620449109543,
      },
      {
        lat: 13.133248244166717,
        lng: 80.25644653631824,
      },
      {
        lat: 13.125348295130838,
        lng: 80.24631650111813,
      },
      {
        lat: 13.132936869889377,
        lng: 80.24517740986104,
      },
      {
        lat: 13.132166167060003,
        lng: 80.25517648976322,
      },
      {
        lat: 13.131301014972193,
        lng: 80.24793809113562,
      },
      {
        lat: 13.134254072840537,
        lng: 80.24336537215244,
      },
      {
        lat: 13.13674066985636,
        lng: 80.24428691184085,
      },
      {
        lat: 13.13589611653811,
        lng: 80.24681693176755,
      },
    ],
    cases: 50,
    coords: [
      {
        lat: 13.136322572372238,
        lng: 80.25808654083072,
      },
      {
        lat: 13.136039161651825,
        lng: 80.25868612534721,
      },
      {
        lat: 13.131381964976079,
        lng: 80.25881158608918,
      },
      {
        lat: 13.131441723077398,
        lng: 80.25795062355314,
      },
      {
        lat: 13.131526415830617,
        lng: 80.25762723548097,
      },
      {
        lat: 13.131523613777125,
        lng: 80.25360462371323,
      },
      {
        lat: 13.131627222840544,
        lng: 80.25282933573395,
      },
      {
        lat: 13.131603465690235,
        lng: 80.25248535408186,
      },
      {
        lat: 13.130641230361624,
        lng: 80.25100920977026,
      },
      {
        lat: 13.130027840226703,
        lng: 80.24989580722607,
      },
      {
        lat: 13.129788884105224,
        lng: 80.24979027247522,
      },
      {
        lat: 13.128575972188322,
        lng: 80.24984356050274,
      },
      {
        lat: 13.12403907671626,
        lng: 80.24837615804724,
      },
      {
        lat: 13.123714119506344,
        lng: 80.24837890824438,
      },
      {
        lat: 13.123477518387984,
        lng: 80.24866055911698,
      },
      {
        lat: 13.123111065219263,
        lng: 80.24896482038076,
      },
      {
        lat: 13.122915960059716,
        lng: 80.24894496018673,
      },
      {
        lat: 13.12256803070059,
        lng: 80.24873279060623,
      },
      {
        lat: 13.122457618294318,
        lng: 80.24838954234,
      },
      {
        lat: 13.121915630443834,
        lng: 80.24832959506448,
      },
      {
        lat: 13.122449114115758,
        lng: 80.24699137203307,
      },
      {
        lat: 13.122469600428161,
        lng: 80.24679759587482,
      },
      {
        lat: 13.122273971934552,
        lng: 80.24669169443105,
      },
      {
        lat: 13.122186270010456,
        lng: 80.24652034531749,
      },
      {
        lat: 13.122271616931256,
        lng: 80.2463045088076,
      },
      {
        lat: 13.12236253556364,
        lng: 80.24592424356439,
      },
      {
        lat: 13.122450080443715,
        lng: 80.24570284856655,
      },
      {
        lat: 13.122716606967705,
        lng: 80.24567852747522,
      },
      {
        lat: 13.123071886197662,
        lng: 80.24563138983835,
      },
      {
        lat: 13.123312433313194,
        lng: 80.24498945718533,
      },
      {
        lat: 13.126948341798474,
        lng: 80.24356856482643,
      },
      {
        lat: 13.12728113089566,
        lng: 80.24347748671299,
      },
      {
        lat: 13.127280057272444,
        lng: 80.24330097253294,
      },
      {
        lat: 13.127211915858782,
        lng: 80.24305882974016,
      },
      {
        lat: 13.12716666718682,
        lng: 80.24292682024173,
      },
      {
        lat: 13.127810963073985,
        lng: 80.24289930199032,
      },
      {
        lat: 13.127810292059472,
        lng: 80.24278898062776,
      },
      {
        lat: 13.127232124542434,
        lng: 80.24272767758451,
      },
      {
        lat: 13.127164385737483,
        lng: 80.24255172760925,
      },
      {
        lat: 13.127008028429291,
        lng: 80.24242065845195,
      },
      {
        lat: 13.12685769215258,
        lng: 80.24154006643502,
      },
      {
        lat: 13.129354591645036,
        lng: 80.24162516424752,
      },
      {
        lat: 13.129705289875066,
        lng: 80.24173804007357,
      },
      {
        lat: 13.130832080071952,
        lng: 80.24262105239482,
      },
      {
        lat: 13.138500313008992,
        lng: 80.24349383714976,
      },
      {
        lat: 13.138325949836718,
        lng: 80.24587970674196,
      },
      {
        lat: 13.137184190709204,
        lng: 80.24891674628488,
      },
      {
        lat: 13.136893920332687,
        lng: 80.24999084063138,
      },
      {
        lat: 13.136774333357831,
        lng: 80.25251020135246,
      },
      {
        lat: 13.136517724417727,
        lng: 80.25468243946929,
      },
      {
        lat: 13.136548941696544,
        lng: 80.25537873978293,
      },
      {
        lat: 13.136714085007773,
        lng: 80.25591316098887,
      },
      {
        lat: 13.13663851993713,
        lng: 80.25679790162887,
      },
      {
        lat: 13.136322572372238,
        lng: 80.25808654083072,
      },
    ],
    properties: {
      Ward_No: 35,
      Zone_Name: "TONDIARPET",
      fillColor: "#8bc24c",
      zname: "TONDIARPET(35)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.113201903767871,
        lng: 80.24791538364376,
      },
      {
        lat: 13.113706062591225,
        lng: 80.24609539867653,
      },
      {
        lat: 13.120232490428513,
        lng: 80.25187427892658,
      },
      {
        lat: 13.113993210158368,
        lng: 80.24780155090721,
      },
      {
        lat: 13.11907680769918,
        lng: 80.24951936204927,
      },
      {
        lat: 13.113739470031378,
        lng: 80.2444833573756,
      },
      {
        lat: 13.114340198555213,
        lng: 80.24446850937083,
      },
      {
        lat: 13.12163753130342,
        lng: 80.24778411958869,
      },
      {
        lat: 13.113808870846023,
        lng: 80.24538872134151,
      },
      {
        lat: 13.116709724059866,
        lng: 80.24563199994957,
      },
      {
        lat: 13.116271351619591,
        lng: 80.24506141066986,
      },
      {
        lat: 13.117445864685354,
        lng: 80.25104819677222,
      },
      {
        lat: 13.120489575098235,
        lng: 80.25002523599682,
      },
      {
        lat: 13.119434817929553,
        lng: 80.25126453408629,
      },
      {
        lat: 13.119057487385009,
        lng: 80.25031813647637,
      },
      {
        lat: 13.118877689745334,
        lng: 80.24582297650359,
      },
      {
        lat: 13.12069944078104,
        lng: 80.24657260872677,
      },
      {
        lat: 13.11602816744623,
        lng: 80.24519913013154,
      },
      {
        lat: 13.12182804267456,
        lng: 80.24792920113735,
      },
      {
        lat: 13.117381925568353,
        lng: 80.25032206521786,
      },
      {
        lat: 13.117290149170609,
        lng: 80.24767982108987,
      },
      {
        lat: 13.119705592703902,
        lng: 80.24905993907358,
      },
      {
        lat: 13.116622730918923,
        lng: 80.2481819689491,
      },
      {
        lat: 13.113528662071346,
        lng: 80.24627895925218,
      },
      {
        lat: 13.117689367869051,
        lng: 80.2489020893791,
      },
      {
        lat: 13.115083185498369,
        lng: 80.24659387367142,
      },
      {
        lat: 13.120419758908039,
        lng: 80.24653742745214,
      },
      {
        lat: 13.113412008263287,
        lng: 80.24525592625328,
      },
      {
        lat: 13.119031220819506,
        lng: 80.24830689294708,
      },
      {
        lat: 13.113686852262067,
        lng: 80.24371256551817,
      },
      {
        lat: 13.116324215291852,
        lng: 80.24480392009926,
      },
      {
        lat: 13.116748967281515,
        lng: 80.24812029477035,
      },
      {
        lat: 13.1186437701883,
        lng: 80.2527343160126,
      },
      {
        lat: 13.119537936877606,
        lng: 80.24955207975417,
      },
      {
        lat: 13.117944339932835,
        lng: 80.24944036732565,
      },
      {
        lat: 13.116961600105634,
        lng: 80.24700513783006,
      },
      {
        lat: 13.119457646588035,
        lng: 80.25093174274723,
      },
      {
        lat: 13.119729871226584,
        lng: 80.24676804086741,
      },
      {
        lat: 13.116654004538931,
        lng: 80.24739293494174,
      },
      {
        lat: 13.121021364695999,
        lng: 80.24864324855996,
      },
      {
        lat: 13.120227517261597,
        lng: 80.24953048864916,
      },
      {
        lat: 13.11377389266972,
        lng: 80.24536318050646,
      },
      {
        lat: 13.115706430133455,
        lng: 80.24882218384563,
      },
      {
        lat: 13.116264224068974,
        lng: 80.24687571816553,
      },
      {
        lat: 13.117221775380797,
        lng: 80.24464342744204,
      },
      {
        lat: 13.11621454393056,
        lng: 80.24833997872344,
      },
      {
        lat: 13.116431001381399,
        lng: 80.2480522362389,
      },
      {
        lat: 13.117469087224036,
        lng: 80.2496375296942,
      },
      {
        lat: 13.11993378090929,
        lng: 80.25208177675762,
      },
      {
        lat: 13.114659061795226,
        lng: 80.24634305679724,
      },
      {
        lat: 13.120570301683328,
        lng: 80.24672680157124,
      },
      {
        lat: 13.116385967130515,
        lng: 80.24858270461398,
      },
      {
        lat: 13.118537656796871,
        lng: 80.25075606416462,
      },
      {
        lat: 13.118613060685353,
        lng: 80.24791935473638,
      },
      {
        lat: 13.115725562040112,
        lng: 80.24843602899671,
      },
      {
        lat: 13.121043643937803,
        lng: 80.24556294920177,
      },
      {
        lat: 13.11790130243831,
        lng: 80.25035272541234,
      },
      {
        lat: 13.115696070385225,
        lng: 80.2455929658069,
      },
      {
        lat: 13.117513370072272,
        lng: 80.25075315593865,
      },
      {
        lat: 13.115521197849938,
        lng: 80.24633797149563,
      },
      {
        lat: 13.113176253501946,
        lng: 80.2470226383086,
      },
      {
        lat: 13.114164646217967,
        lng: 80.24680425435875,
      },
      {
        lat: 13.11475956697886,
        lng: 80.24438083932421,
      },
      {
        lat: 13.120188080286601,
        lng: 80.25031689959373,
      },
      {
        lat: 13.116316476452154,
        lng: 80.25100591121833,
      },
      {
        lat: 13.11912733643932,
        lng: 80.25167227803098,
      },
      {
        lat: 13.117177104638627,
        lng: 80.24609015460716,
      },
      {
        lat: 13.118602167940601,
        lng: 80.24703315730487,
      },
      {
        lat: 13.121982057770277,
        lng: 80.2477488125082,
      },
      {
        lat: 13.115212873777454,
        lng: 80.24737229869139,
      },
      {
        lat: 13.114461875824448,
        lng: 80.24579937814816,
      },
      {
        lat: 13.118520841139533,
        lng: 80.24681254612321,
      },
      {
        lat: 13.12070508135694,
        lng: 80.24576336081695,
      },
      {
        lat: 13.117376634697806,
        lng: 80.24881855229367,
      },
      {
        lat: 13.118365719001636,
        lng: 80.25058625116041,
      },
      {
        lat: 13.11750255831636,
        lng: 80.24519624489471,
      },
      {
        lat: 13.114990395352436,
        lng: 80.24725803190306,
      },
      {
        lat: 13.115296600302152,
        lng: 80.24630148760765,
      },
      {
        lat: 13.119882421538305,
        lng: 80.25095089844761,
      },
      {
        lat: 13.1179704015599,
        lng: 80.24618644291775,
      },
      {
        lat: 13.12011585394844,
        lng: 80.24621705205836,
      },
      {
        lat: 13.117439000333825,
        lng: 80.2498092525551,
      },
      {
        lat: 13.117899308028509,
        lng: 80.25196036454689,
      },
      {
        lat: 13.116809080871002,
        lng: 80.24795994971251,
      },
      {
        lat: 13.120412082820438,
        lng: 80.24564062415182,
      },
      {
        lat: 13.119759747440781,
        lng: 80.25058926394787,
      },
      {
        lat: 13.119414181492397,
        lng: 80.24641660928171,
      },
      {
        lat: 13.119382118918663,
        lng: 80.24865931305405,
      },
      {
        lat: 13.11967509158322,
        lng: 80.24708219913236,
      },
      {
        lat: 13.117277564598279,
        lng: 80.25121475040694,
      },
      {
        lat: 13.114581627052456,
        lng: 80.24738516980558,
      },
      {
        lat: 13.119315840378484,
        lng: 80.24777320418984,
      },
      {
        lat: 13.115752324588057,
        lng: 80.2486869514943,
      },
      {
        lat: 13.113169871709912,
        lng: 80.24405247515095,
      },
      {
        lat: 13.118466555710725,
        lng: 80.24808153484643,
      },
      {
        lat: 13.118982585828958,
        lng: 80.25150227327894,
      },
      {
        lat: 13.120401623769496,
        lng: 80.24618937558846,
      },
      {
        lat: 13.117770097782342,
        lng: 80.24709560482489,
      },
      {
        lat: 13.11428889362657,
        lng: 80.24777710775265,
      },
      {
        lat: 13.116263862252493,
        lng: 80.247791177547,
      },
      {
        lat: 13.117615961101368,
        lng: 80.24725807756273,
      },
      {
        lat: 13.118651022811791,
        lng: 80.24577191182064,
      },
    ],
    cases: 102,
    coords: [
      {
        lat: 13.121915630443834,
        lng: 80.24832959506448,
      },
      {
        lat: 13.121827618611048,
        lng: 80.2486989973093,
      },
      {
        lat: 13.121582713065562,
        lng: 80.24905792513255,
      },
      {
        lat: 13.121550106095036,
        lng: 80.2492366286557,
      },
      {
        lat: 13.121483196520565,
        lng: 80.24931525698719,
      },
      {
        lat: 13.12132569506562,
        lng: 80.24927198307229,
      },
      {
        lat: 13.121190248109055,
        lng: 80.24916161216872,
      },
      {
        lat: 13.120920982004007,
        lng: 80.24920849792822,
      },
      {
        lat: 13.120731755307565,
        lng: 80.24948889246845,
      },
      {
        lat: 13.120720931534327,
        lng: 80.24955589440847,
      },
      {
        lat: 13.120766464863411,
        lng: 80.24965587455162,
      },
      {
        lat: 13.120968617918237,
        lng: 80.24965416367792,
      },
      {
        lat: 13.121035663143132,
        lng: 80.24959783764385,
      },
      {
        lat: 13.121159201121058,
        lng: 80.2495967921099,
      },
      {
        lat: 13.12118240865023,
        lng: 80.24971926464735,
      },
      {
        lat: 13.121137824875728,
        lng: 80.24977540058453,
      },
      {
        lat: 13.120811930366992,
        lng: 80.24974470354648,
      },
      {
        lat: 13.12066613441454,
        lng: 80.24977939262324,
      },
      {
        lat: 13.120655446291792,
        lng: 80.24986869686052,
      },
      {
        lat: 13.12082560280398,
        lng: 80.25014604984753,
      },
      {
        lat: 13.120961117585875,
        lng: 80.25026757189953,
      },
      {
        lat: 13.120895361042198,
        lng: 80.25053576975712,
      },
      {
        lat: 13.12060988666988,
        lng: 80.251051054529,
      },
      {
        lat: 13.120689877570939,
        lng: 80.25114239544317,
      },
      {
        lat: 13.122015650457891,
        lng: 80.25144666501866,
      },
      {
        lat: 13.121989733033997,
        lng: 80.2515389022624,
      },
      {
        lat: 13.1205579718712,
        lng: 80.25122238427913,
      },
      {
        lat: 13.120285079385928,
        lng: 80.25206600033334,
      },
      {
        lat: 13.119826447576644,
        lng: 80.2528454612777,
      },
      {
        lat: 13.11962850907592,
        lng: 80.25295229979446,
      },
      {
        lat: 13.119349860114571,
        lng: 80.25284949476101,
      },
      {
        lat: 13.118886271339953,
        lng: 80.25281398199117,
      },
      {
        lat: 13.117217895418195,
        lng: 80.25277552023324,
      },
      {
        lat: 13.117066994701709,
        lng: 80.25190920002298,
      },
      {
        lat: 13.116972965749348,
        lng: 80.25168652377683,
      },
      {
        lat: 13.116786586817112,
        lng: 80.25151721076833,
      },
      {
        lat: 13.116428746465223,
        lng: 80.25145451219419,
      },
      {
        lat: 13.116217249619588,
        lng: 80.25150888380264,
      },
      {
        lat: 13.116086623136528,
        lng: 80.25179918843592,
      },
      {
        lat: 13.115743220591161,
        lng: 80.25193354888042,
      },
      {
        lat: 13.114724518656946,
        lng: 80.25249818152814,
      },
      {
        lat: 13.115011656473296,
        lng: 80.25220890109847,
      },
      {
        lat: 13.115319002973472,
        lng: 80.25158846851318,
      },
      {
        lat: 13.115470663180082,
        lng: 80.25094728813315,
      },
      {
        lat: 13.115576806308814,
        lng: 80.25012996970959,
      },
      {
        lat: 13.115486040559253,
        lng: 80.24982182216758,
      },
      {
        lat: 13.11545469303471,
        lng: 80.248321639706,
      },
      {
        lat: 13.11543072666962,
        lng: 80.24803499223172,
      },
      {
        lat: 13.115296859494597,
        lng: 80.24794786355127,
      },
      {
        lat: 13.114318969292997,
        lng: 80.24793407428143,
      },
      {
        lat: 13.113851776209172,
        lng: 80.24784976662434,
      },
      {
        lat: 13.113518852909253,
        lng: 80.24791878046547,
      },
      {
        lat: 13.113343494743527,
        lng: 80.24831744191643,
      },
      {
        lat: 13.113055149100887,
        lng: 80.24840814389349,
      },
      {
        lat: 13.112787683156581,
        lng: 80.24827801507736,
      },
      {
        lat: 13.112830784582103,
        lng: 80.24805699621584,
      },
      {
        lat: 13.112961430887479,
        lng: 80.24761458235652,
      },
      {
        lat: 13.113089929946469,
        lng: 80.24681914013729,
      },
      {
        lat: 13.113051872276808,
        lng: 80.24421574405086,
      },
      {
        lat: 13.113160565260626,
        lng: 80.24381764680463,
      },
      {
        lat: 13.113381843112881,
        lng: 80.24366131621477,
      },
      {
        lat: 13.114315826671653,
        lng: 80.24376373871121,
      },
      {
        lat: 13.11594136783309,
        lng: 80.24430161654209,
      },
      {
        lat: 13.116964104097944,
        lng: 80.244381222493,
      },
      {
        lat: 13.118966743607984,
        lng: 80.24480558180129,
      },
      {
        lat: 13.119145591049225,
        lng: 80.24498059143535,
      },
      {
        lat: 13.119124308742183,
        lng: 80.24513522941102,
      },
      {
        lat: 13.119058717183664,
        lng: 80.24531230779569,
      },
      {
        lat: 13.119015615758304,
        lng: 80.24553332665724,
      },
      {
        lat: 13.119173046689605,
        lng: 80.2458409099945,
      },
      {
        lat: 13.119329806606329,
        lng: 80.24603817196926,
      },
      {
        lat: 13.11953033896326,
        lng: 80.24612473644504,
      },
      {
        lat: 13.119751482612386,
        lng: 80.24594634158261,
      },
      {
        lat: 13.119881055294599,
        lng: 80.2453274135434,
      },
      {
        lat: 13.120079977216706,
        lng: 80.24514920674926,
      },
      {
        lat: 13.120502861048772,
        lng: 80.24525595481519,
      },
      {
        lat: 13.122162405815585,
        lng: 80.24590387190611,
      },
      {
        lat: 13.12236253556364,
        lng: 80.24592424356439,
      },
      {
        lat: 13.122271616931256,
        lng: 80.2463045088076,
      },
      {
        lat: 13.122186270010456,
        lng: 80.24652034531749,
      },
      {
        lat: 13.122273971934552,
        lng: 80.24669169443105,
      },
      {
        lat: 13.122469600428161,
        lng: 80.24679759587482,
      },
      {
        lat: 13.122449114115758,
        lng: 80.24699137203307,
      },
      {
        lat: 13.121915630443834,
        lng: 80.24832959506448,
      },
    ],
    properties: {
      Ward_No: 44,
      Zone_Name: "TONDIARPET",
      fillColor: "#f6d04d",
      zname: "TONDIARPET(44)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.11793663924264,
        lng: 80.25901461049632,
      },
      {
        lat: 13.109933328039045,
        lng: 80.26375355443702,
      },
      {
        lat: 13.116382942793216,
        lng: 80.2597531725205,
      },
      {
        lat: 13.109714652926652,
        lng: 80.26198412969028,
      },
      {
        lat: 13.11401432918168,
        lng: 80.26059034333221,
      },
      {
        lat: 13.11135870788435,
        lng: 80.25475204727894,
      },
      {
        lat: 13.111580566513096,
        lng: 80.26264758895353,
      },
      {
        lat: 13.11561218787751,
        lng: 80.26056830041091,
      },
      {
        lat: 13.116968992590948,
        lng: 80.26004757423371,
      },
      {
        lat: 13.111671428261948,
        lng: 80.26276304374485,
      },
      {
        lat: 13.110263273535196,
        lng: 80.25859272078814,
      },
      {
        lat: 13.115455552860968,
        lng: 80.25890275496805,
      },
      {
        lat: 13.110058996659921,
        lng: 80.2593683040025,
      },
      {
        lat: 13.112616935433717,
        lng: 80.25665230087748,
      },
      {
        lat: 13.109802402758062,
        lng: 80.2640934229677,
      },
      {
        lat: 13.116516618979567,
        lng: 80.26017654844271,
      },
      {
        lat: 13.112166924151232,
        lng: 80.26421711879068,
      },
      {
        lat: 13.11244730677417,
        lng: 80.25610785397335,
      },
      {
        lat: 13.109463989783341,
        lng: 80.26194511040521,
      },
      {
        lat: 13.116506630453905,
        lng: 80.25224107340563,
      },
      {
        lat: 13.118093855329967,
        lng: 80.25919477291586,
      },
      {
        lat: 13.110670617431609,
        lng: 80.25596398911331,
      },
      {
        lat: 13.110271985129396,
        lng: 80.26475661582569,
      },
      {
        lat: 13.110226055083094,
        lng: 80.25658449900737,
      },
      {
        lat: 13.1133577350203,
        lng: 80.25572025651897,
      },
      {
        lat: 13.112784659833325,
        lng: 80.25915850926381,
      },
      {
        lat: 13.112705782617265,
        lng: 80.25875785523047,
      },
      {
        lat: 13.11649124957574,
        lng: 80.26011202961766,
      },
      {
        lat: 13.116768100205562,
        lng: 80.25667616999968,
      },
      {
        lat: 13.112730982249502,
        lng: 80.25412236919038,
      },
      {
        lat: 13.117439937279483,
        lng: 80.25954967505811,
      },
      {
        lat: 13.114025348988303,
        lng: 80.25769699873918,
      },
      {
        lat: 13.11494790437179,
        lng: 80.25988633579094,
      },
      {
        lat: 13.11271960375239,
        lng: 80.26278252573263,
      },
      {
        lat: 13.115711687044778,
        lng: 80.25355298498818,
      },
      {
        lat: 13.109889121220784,
        lng: 80.25896109270779,
      },
      {
        lat: 13.114011832692938,
        lng: 80.26087073979929,
      },
      {
        lat: 13.112007208009288,
        lng: 80.25744168603873,
      },
      {
        lat: 13.11174486718518,
        lng: 80.26407110530027,
      },
      {
        lat: 13.111325813503445,
        lng: 80.25850287756766,
      },
      {
        lat: 13.110553357353819,
        lng: 80.25278148112149,
      },
      {
        lat: 13.110142039097017,
        lng: 80.26388329614582,
      },
      {
        lat: 13.112930330289947,
        lng: 80.25440310850831,
      },
      {
        lat: 13.110494949337617,
        lng: 80.26448850433627,
      },
      {
        lat: 13.111370229867184,
        lng: 80.26043020158745,
      },
      {
        lat: 13.116656124517537,
        lng: 80.2577231916027,
      },
    ],
    cases: 46,
    coords: [
      {
        lat: 13.110704339924538,
        lng: 80.2656412644089,
      },
      {
        lat: 13.110546908993069,
        lng: 80.26533368107164,
      },
      {
        lat: 13.11039041748215,
        lng: 80.26518054764176,
      },
      {
        lat: 13.110034869846164,
        lng: 80.26518355673366,
      },
      {
        lat: 13.109789625629045,
        lng: 80.26505323984927,
      },
      {
        lat: 13.10967636974633,
        lng: 80.26470115183051,
      },
      {
        lat: 13.109560832414493,
        lng: 80.26397397117941,
      },
      {
        lat: 13.109275732208827,
        lng: 80.2609445969572,
      },
      {
        lat: 13.109138375758114,
        lng: 80.26028379719163,
      },
      {
        lat: 13.10915871864484,
        lng: 80.25997470930838,
      },
      {
        lat: 13.109313062909381,
        lng: 80.25977481437833,
      },
      {
        lat: 13.109535280181898,
        lng: 80.2597729336959,
      },
      {
        lat: 13.10977931657289,
        lng: 80.25970467212778,
      },
      {
        lat: 13.109822686404051,
        lng: 80.2595277818113,
      },
      {
        lat: 13.109751860932343,
        lng: 80.25884435356858,
      },
      {
        lat: 13.109657337501517,
        lng: 80.2579184063967,
      },
      {
        lat: 13.109542873792734,
        lng: 80.25736773992546,
      },
      {
        lat: 13.109318777679764,
        lng: 80.25706072079305,
      },
      {
        lat: 13.109112206192167,
        lng: 80.25598126405474,
      },
      {
        lat: 13.108829306914098,
        lng: 80.25331374390146,
      },
      {
        lat: 13.10873572290365,
        lng: 80.25254224663709,
      },
      {
        lat: 13.108801717070818,
        lng: 80.25243136106987,
      },
      {
        lat: 13.109068243594805,
        lng: 80.25240703997855,
      },
      {
        lat: 13.109556853188467,
        lng: 80.25235877393222,
      },
      {
        lat: 13.110069026538275,
        lng: 80.25253096254268,
      },
      {
        lat: 13.11264688110151,
        lng: 80.2525312108994,
      },
      {
        lat: 13.113181276178505,
        lng: 80.25270321144147,
      },
      {
        lat: 13.114325466986724,
        lng: 80.25265601666383,
      },
      {
        lat: 13.114724518656946,
        lng: 80.25249818152814,
      },
      {
        lat: 13.115743220591161,
        lng: 80.25193354888042,
      },
      {
        lat: 13.116086623136528,
        lng: 80.25179918843592,
      },
      {
        lat: 13.116217249619588,
        lng: 80.25150888380264,
      },
      {
        lat: 13.116428746465223,
        lng: 80.25145451219419,
      },
      {
        lat: 13.116786586817112,
        lng: 80.25151721076833,
      },
      {
        lat: 13.116972965749348,
        lng: 80.25168652377683,
      },
      {
        lat: 13.117066994701709,
        lng: 80.25190920002298,
      },
      {
        lat: 13.117217895418195,
        lng: 80.25277552023324,
      },
      {
        lat: 13.116373006471,
        lng: 80.25277816772397,
      },
      {
        lat: 13.11653539539398,
        lng: 80.25420145828126,
      },
      {
        lat: 13.116699331617292,
        lng: 80.25587914018912,
      },
      {
        lat: 13.117621985321987,
        lng: 80.25592221242076,
      },
      {
        lat: 13.117845578458995,
        lng: 80.2561747245398,
      },
      {
        lat: 13.11805332898739,
        lng: 80.25663089429588,
      },
      {
        lat: 13.11831087671561,
        lng: 80.25684919845574,
      },
      {
        lat: 13.118756412535872,
        lng: 80.25708287191998,
      },
      {
        lat: 13.118808582263032,
        lng: 80.25723507306157,
      },
      {
        lat: 13.118776381278936,
        lng: 80.25755759121824,
      },
      {
        lat: 13.118275933599525,
        lng: 80.25952922101793,
      },
      {
        lat: 13.118180052720765,
        lng: 80.26061549145172,
      },
      {
        lat: 13.11807922048068,
        lng: 80.26088770956333,
      },
      {
        lat: 13.11799526238287,
        lng: 80.26112586427195,
      },
      {
        lat: 13.118149914803814,
        lng: 80.2612771980759,
      },
      {
        lat: 13.118168439399838,
        lng: 80.26151448544684,
      },
      {
        lat: 13.118067091392959,
        lng: 80.2617019064416,
      },
      {
        lat: 13.117642452695767,
        lng: 80.26209558708601,
      },
      {
        lat: 13.117352188216659,
        lng: 80.26211500396593,
      },
      {
        lat: 13.116429328205419,
        lng: 80.26203801288757,
      },
      {
        lat: 13.114478443501628,
        lng: 80.26144395306079,
      },
      {
        lat: 13.114221721000234,
        lng: 80.26136132428795,
      },
      {
        lat: 13.114120063533324,
        lng: 80.2614978670126,
      },
      {
        lat: 13.114259595419163,
        lng: 80.26197157441689,
      },
      {
        lat: 13.114248497865043,
        lng: 80.26295536552907,
      },
      {
        lat: 13.11416629337434,
        lng: 80.26348183043504,
      },
      {
        lat: 13.11379124557049,
        lng: 80.26360372663659,
      },
      {
        lat: 13.11312655220751,
        lng: 80.26384679625835,
      },
      {
        lat: 13.110704339924538,
        lng: 80.2656412644089,
      },
    ],
    properties: {
      Ward_No: 45,
      Zone_Name: "TONDIARPET",
      fillColor: "#8bc24c",
      zname: "TONDIARPET(45)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.125025920183731,
        lng: 80.25197991312398,
      },
      {
        lat: 13.127159047193437,
        lng: 80.25746487927009,
      },
      {
        lat: 13.11862610094146,
        lng: 80.25378173443694,
      },
      {
        lat: 13.122014235351534,
        lng: 80.25651518684214,
      },
      {
        lat: 13.129053373844249,
        lng: 80.25865635041663,
      },
      {
        lat: 13.124622058947683,
        lng: 80.25153174716034,
      },
      {
        lat: 13.125525240544397,
        lng: 80.25088742914521,
      },
      {
        lat: 13.125041851072213,
        lng: 80.25197156794619,
      },
      {
        lat: 13.124446642388753,
        lng: 80.25028979471966,
      },
      {
        lat: 13.124151059631185,
        lng: 80.25223915864522,
      },
      {
        lat: 13.121151211208808,
        lng: 80.25056152370507,
      },
      {
        lat: 13.124404557610125,
        lng: 80.25524241497804,
      },
      {
        lat: 13.120582035682888,
        lng: 80.25659305414861,
      },
      {
        lat: 13.128522631372007,
        lng: 80.25060713679781,
      },
      {
        lat: 13.12453080133522,
        lng: 80.25036861079138,
      },
      {
        lat: 13.123534361312764,
        lng: 80.25610741391863,
      },
      {
        lat: 13.12538201502073,
        lng: 80.25746802009184,
      },
      {
        lat: 13.122787830228857,
        lng: 80.2532318842985,
      },
      {
        lat: 13.121616888482269,
        lng: 80.25385861886812,
      },
      {
        lat: 13.126264366208451,
        lng: 80.25335287971406,
      },
      {
        lat: 13.13038773541556,
        lng: 80.25553938723769,
      },
      {
        lat: 13.129657612320504,
        lng: 80.25183969621004,
      },
      {
        lat: 13.128814604533995,
        lng: 80.25563698944418,
      },
      {
        lat: 13.122648497809227,
        lng: 80.25155748459437,
      },
      {
        lat: 13.128080433203467,
        lng: 80.25292718555998,
      },
      {
        lat: 13.123215045816506,
        lng: 80.25191772617141,
      },
      {
        lat: 13.12710194512099,
        lng: 80.25797414161647,
      },
      {
        lat: 13.120293599057117,
        lng: 80.25796174713659,
      },
      {
        lat: 13.128215805554003,
        lng: 80.24974557945717,
      },
      {
        lat: 13.12652364639749,
        lng: 80.25720731217424,
      },
      {
        lat: 13.121598148895995,
        lng: 80.25671883576365,
      },
      {
        lat: 13.123251516487365,
        lng: 80.25465295796997,
      },
      {
        lat: 13.124537337173026,
        lng: 80.25391445808974,
      },
      {
        lat: 13.128994915715195,
        lng: 80.25675714365725,
      },
      {
        lat: 13.130270750898564,
        lng: 80.25728831831242,
      },
      {
        lat: 13.130053534484219,
        lng: 80.25335196713559,
      },
      {
        lat: 13.127697327061874,
        lng: 80.25809856923507,
      },
      {
        lat: 13.119341681484755,
        lng: 80.25610093814191,
      },
      {
        lat: 13.12118266312975,
        lng: 80.25672457405204,
      },
      {
        lat: 13.127067234364455,
        lng: 80.25113361803913,
      },
      {
        lat: 13.120935601649073,
        lng: 80.2578234557093,
      },
      {
        lat: 13.126427126798358,
        lng: 80.25325354560094,
      },
      {
        lat: 13.116625946703355,
        lng: 80.25496662530512,
      },
      {
        lat: 13.13133984336832,
        lng: 80.25525750621998,
      },
      {
        lat: 13.120186870539756,
        lng: 80.25634334931887,
      },
      {
        lat: 13.119364677177382,
        lng: 80.25441233974188,
      },
      {
        lat: 13.117354059055772,
        lng: 80.25423707650545,
      },
      {
        lat: 13.125753520505862,
        lng: 80.24938021649668,
      },
      {
        lat: 13.1289665908474,
        lng: 80.25803225349587,
      },
      {
        lat: 13.127090992155056,
        lng: 80.25479159892953,
      },
      {
        lat: 13.11771273116284,
        lng: 80.25484504792506,
      },
      {
        lat: 13.121667224566684,
        lng: 80.25290631879068,
      },
      {
        lat: 13.120996020404476,
        lng: 80.25091097477733,
      },
      {
        lat: 13.121899641078397,
        lng: 80.25595268308545,
      },
      {
        lat: 13.129902844236186,
        lng: 80.25379997593411,
      },
      {
        lat: 13.128280513041084,
        lng: 80.25380730546148,
      },
      {
        lat: 13.123581426413539,
        lng: 80.25209615213788,
      },
      {
        lat: 13.12279290486305,
        lng: 80.25448970267769,
      },
      {
        lat: 13.121965125888003,
        lng: 80.24962966813376,
      },
      {
        lat: 13.128608701767428,
        lng: 80.25441713002427,
      },
      {
        lat: 13.119560997430808,
        lng: 80.2537668542048,
      },
    ],
    cases: 61,
    coords: [
      {
        lat: 13.131381964976079,
        lng: 80.25881158608918,
      },
      {
        lat: 13.126125885534185,
        lng: 80.25910246540774,
      },
      {
        lat: 13.122149466766983,
        lng: 80.2593379265912,
      },
      {
        lat: 13.118275933599525,
        lng: 80.25952922101793,
      },
      {
        lat: 13.118776381278936,
        lng: 80.25755759121824,
      },
      {
        lat: 13.118808582263032,
        lng: 80.25723507306157,
      },
      {
        lat: 13.118756412535872,
        lng: 80.25708287191998,
      },
      {
        lat: 13.11831087671561,
        lng: 80.25684919845574,
      },
      {
        lat: 13.11805332898739,
        lng: 80.25663089429588,
      },
      {
        lat: 13.117845578458995,
        lng: 80.2561747245398,
      },
      {
        lat: 13.117621985321987,
        lng: 80.25592221242076,
      },
      {
        lat: 13.116699331617292,
        lng: 80.25587914018912,
      },
      {
        lat: 13.11653539539398,
        lng: 80.25420145828126,
      },
      {
        lat: 13.116373006471,
        lng: 80.25277816772397,
      },
      {
        lat: 13.117217895418195,
        lng: 80.25277552023324,
      },
      {
        lat: 13.118886271339953,
        lng: 80.25281398199117,
      },
      {
        lat: 13.119349860114571,
        lng: 80.25284949476101,
      },
      {
        lat: 13.11962850907592,
        lng: 80.25295229979446,
      },
      {
        lat: 13.119826447576644,
        lng: 80.2528454612777,
      },
      {
        lat: 13.120285079385928,
        lng: 80.25206600033334,
      },
      {
        lat: 13.1205579718712,
        lng: 80.25122238427913,
      },
      {
        lat: 13.121989733033997,
        lng: 80.2515389022624,
      },
      {
        lat: 13.122015650457891,
        lng: 80.25144666501866,
      },
      {
        lat: 13.120689877570939,
        lng: 80.25114239544317,
      },
      {
        lat: 13.12060988666988,
        lng: 80.251051054529,
      },
      {
        lat: 13.120895361042198,
        lng: 80.25053576975712,
      },
      {
        lat: 13.120961117585875,
        lng: 80.25026757189953,
      },
      {
        lat: 13.12082560280398,
        lng: 80.25014604984753,
      },
      {
        lat: 13.120655446291792,
        lng: 80.24986869686052,
      },
      {
        lat: 13.12066613441454,
        lng: 80.24977939262324,
      },
      {
        lat: 13.120811930366992,
        lng: 80.24974470354648,
      },
      {
        lat: 13.121137824875728,
        lng: 80.24977540058453,
      },
      {
        lat: 13.12118240865023,
        lng: 80.24971926464735,
      },
      {
        lat: 13.121159201121058,
        lng: 80.2495967921099,
      },
      {
        lat: 13.121035663143132,
        lng: 80.24959783764385,
      },
      {
        lat: 13.120968617918237,
        lng: 80.24965416367792,
      },
      {
        lat: 13.120766464863411,
        lng: 80.24965587455162,
      },
      {
        lat: 13.120720931534327,
        lng: 80.24955589440847,
      },
      {
        lat: 13.120731755307565,
        lng: 80.24948889246845,
      },
      {
        lat: 13.120920982004007,
        lng: 80.24920849792822,
      },
      {
        lat: 13.121190248109055,
        lng: 80.24916161216872,
      },
      {
        lat: 13.12132569506562,
        lng: 80.24927198307229,
      },
      {
        lat: 13.121483196520565,
        lng: 80.24931525698719,
      },
      {
        lat: 13.121550106095036,
        lng: 80.2492366286557,
      },
      {
        lat: 13.121582713065562,
        lng: 80.24905792513255,
      },
      {
        lat: 13.121827618611048,
        lng: 80.2486989973093,
      },
      {
        lat: 13.121915630443834,
        lng: 80.24832959506448,
      },
      {
        lat: 13.122457618294318,
        lng: 80.24838954234,
      },
      {
        lat: 13.12256803070059,
        lng: 80.24873279060623,
      },
      {
        lat: 13.122915960059716,
        lng: 80.24894496018673,
      },
      {
        lat: 13.123111065219263,
        lng: 80.24896482038076,
      },
      {
        lat: 13.123477518387984,
        lng: 80.24866055911698,
      },
      {
        lat: 13.123714119506344,
        lng: 80.24837890824438,
      },
      {
        lat: 13.12403907671626,
        lng: 80.24837615804724,
      },
      {
        lat: 13.128575972188322,
        lng: 80.24984356050274,
      },
      {
        lat: 13.129788884105224,
        lng: 80.24979027247522,
      },
      {
        lat: 13.130027840226703,
        lng: 80.24989580722607,
      },
      {
        lat: 13.130641230361624,
        lng: 80.25100920977026,
      },
      {
        lat: 13.131603465690235,
        lng: 80.25248535408186,
      },
      {
        lat: 13.131627222840544,
        lng: 80.25282933573395,
      },
      {
        lat: 13.131523613777125,
        lng: 80.25360462371323,
      },
      {
        lat: 13.131526415830617,
        lng: 80.25762723548097,
      },
      {
        lat: 13.131441723077398,
        lng: 80.25795062355314,
      },
      {
        lat: 13.131381964976079,
        lng: 80.25881158608918,
      },
    ],
    properties: {
      Ward_No: 36,
      Zone_Name: "TONDIARPET",
      fillColor: "#f6d04d",
      zname: "TONDIARPET(36)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.113456732434438,
        lng: 80.26724408197231,
      },
      {
        lat: 13.116291948525205,
        lng: 80.26573727727562,
      },
      {
        lat: 13.11025446384117,
        lng: 80.26690345293052,
      },
      {
        lat: 13.115156738102517,
        lng: 80.26536669278642,
      },
      {
        lat: 13.112752066235327,
        lng: 80.26676454874992,
      },
      {
        lat: 13.116465018441485,
        lng: 80.26734118506761,
      },
      {
        lat: 13.112399130896726,
        lng: 80.26605463155379,
      },
      {
        lat: 13.112521012112497,
        lng: 80.27008467004899,
      },
      {
        lat: 13.109377174903683,
        lng: 80.26841777364304,
      },
      {
        lat: 13.115759713429775,
        lng: 80.26937787883269,
      },
      {
        lat: 13.117675356713862,
        lng: 80.26986157321217,
      },
      {
        lat: 13.118137477840316,
        lng: 80.26528648882743,
      },
      {
        lat: 13.112559136711715,
        lng: 80.26529325415335,
      },
      {
        lat: 13.11307962293357,
        lng: 80.26642239472991,
      },
      {
        lat: 13.116248741219374,
        lng: 80.26682944055557,
      },
      {
        lat: 13.115103082408973,
        lng: 80.26178821719103,
      },
      {
        lat: 13.11464133548655,
        lng: 80.26580142131968,
      },
      {
        lat: 13.118069245726309,
        lng: 80.26917738761502,
      },
      {
        lat: 13.11153460386312,
        lng: 80.26918542312086,
      },
      {
        lat: 13.116809709221625,
        lng: 80.26363594126829,
      },
      {
        lat: 13.115724115356675,
        lng: 80.26285570434241,
      },
      {
        lat: 13.11135878891004,
        lng: 80.26529800555139,
      },
      {
        lat: 13.118334555545848,
        lng: 80.26993048869555,
      },
      {
        lat: 13.113667166787833,
        lng: 80.26825367042804,
      },
      {
        lat: 13.117665300253158,
        lng: 80.26533235233978,
      },
      {
        lat: 13.113573803772809,
        lng: 80.26776615596481,
      },
      {
        lat: 13.119777586667487,
        lng: 80.27076243498342,
      },
      {
        lat: 13.113036772958424,
        lng: 80.26704848233939,
      },
      {
        lat: 13.119245471793562,
        lng: 80.26887662231812,
      },
      {
        lat: 13.115676854405063,
        lng: 80.26957411337523,
      },
      {
        lat: 13.11641460376267,
        lng: 80.26842248084145,
      },
      {
        lat: 13.118374344434274,
        lng: 80.26155663527557,
      },
      {
        lat: 13.117972511155482,
        lng: 80.26977238238442,
      },
      {
        lat: 13.11766005052773,
        lng: 80.26729785808568,
      },
      {
        lat: 13.11490635817672,
        lng: 80.26459895849686,
      },
      {
        lat: 13.11781189181361,
        lng: 80.2620833049568,
      },
      {
        lat: 13.115237929803927,
        lng: 80.26172186717397,
      },
      {
        lat: 13.118384316353657,
        lng: 80.26967640547662,
      },
      {
        lat: 13.116089048148154,
        lng: 80.27012851589052,
      },
      {
        lat: 13.108382344725031,
        lng: 80.26970906699279,
      },
      {
        lat: 13.114497735553773,
        lng: 80.26714389519839,
      },
      {
        lat: 13.115204607428007,
        lng: 80.26700348198891,
      },
      {
        lat: 13.11501799411519,
        lng: 80.27039425273543,
      },
      {
        lat: 13.116934111277645,
        lng: 80.27009673030878,
      },
      {
        lat: 13.116445948936377,
        lng: 80.26427315141318,
      },
      {
        lat: 13.113706544019067,
        lng: 80.26998567146397,
      },
      {
        lat: 13.110322276348473,
        lng: 80.266494867986,
      },
      {
        lat: 13.108917622544991,
        lng: 80.26752737669422,
      },
      {
        lat: 13.108268663988515,
        lng: 80.26906432420776,
      },
      {
        lat: 13.118058160124354,
        lng: 80.26399894619709,
      },
      {
        lat: 13.10906513706968,
        lng: 80.26902176193572,
      },
      {
        lat: 13.119851607407742,
        lng: 80.27176251778302,
      },
      {
        lat: 13.116505581251111,
        lng: 80.26915162814494,
      },
      {
        lat: 13.116562378116708,
        lng: 80.26677187001377,
      },
      {
        lat: 13.11713071091545,
        lng: 80.26409649240331,
      },
      {
        lat: 13.118599857510192,
        lng: 80.26753424104666,
      },
      {
        lat: 13.11375767589083,
        lng: 80.26860408195826,
      },
      {
        lat: 13.116037406088891,
        lng: 80.26967156613254,
      },
      {
        lat: 13.118952838397972,
        lng: 80.26392208685257,
      },
      {
        lat: 13.11343286069313,
        lng: 80.26507008291193,
      },
      {
        lat: 13.111354702164341,
        lng: 80.2679825893002,
      },
      {
        lat: 13.116146285336479,
        lng: 80.26616894332538,
      },
      {
        lat: 13.114080882796058,
        lng: 80.26905281795983,
      },
      {
        lat: 13.11473340312335,
        lng: 80.26695192134628,
      },
      {
        lat: 13.114738247738416,
        lng: 80.2652395821558,
      },
      {
        lat: 13.116423862051873,
        lng: 80.26724028911653,
      },
      {
        lat: 13.119502502000259,
        lng: 80.26265491563892,
      },
      {
        lat: 13.117172342988725,
        lng: 80.264124996383,
      },
      {
        lat: 13.108246301244158,
        lng: 80.2680253445546,
      },
      {
        lat: 13.111632882852144,
        lng: 80.26672327333489,
      },
      {
        lat: 13.119030336816843,
        lng: 80.27184015212143,
      },
      {
        lat: 13.118533067096672,
        lng: 80.26223574970231,
      },
      {
        lat: 13.116537568310624,
        lng: 80.26351987715299,
      },
      {
        lat: 13.115642089947062,
        lng: 80.26972900945282,
      },
      {
        lat: 13.11648120318409,
        lng: 80.26810576777527,
      },
      {
        lat: 13.114735531118054,
        lng: 80.2645192541292,
      },
      {
        lat: 13.115606947155248,
        lng: 80.26266360501214,
      },
      {
        lat: 13.117780465134958,
        lng: 80.26409989787116,
      },
      {
        lat: 13.118118997391992,
        lng: 80.27139882967525,
      },
      {
        lat: 13.118920758763949,
        lng: 80.26317187565613,
      },
      {
        lat: 13.104882341563064,
        lng: 80.27120048274466,
      },
      {
        lat: 13.118562734232391,
        lng: 80.26759958456158,
      },
      {
        lat: 13.113655979353418,
        lng: 80.26735910077831,
      },
      {
        lat: 13.11077583543769,
        lng: 80.26900223969533,
      },
      {
        lat: 13.119471459234509,
        lng: 80.26198566861869,
      },
      {
        lat: 13.109469802434868,
        lng: 80.2674143849877,
      },
      {
        lat: 13.114198494703567,
        lng: 80.2664585711078,
      },
      {
        lat: 13.116564934184797,
        lng: 80.26329693313663,
      },
      {
        lat: 13.108758131503931,
        lng: 80.26919117480641,
      },
      {
        lat: 13.11047431856069,
        lng: 80.26907992390754,
      },
      {
        lat: 13.114762080318211,
        lng: 80.26301145874035,
      },
      {
        lat: 13.11881288787365,
        lng: 80.26256369491688,
      },
      {
        lat: 13.118507406130904,
        lng: 80.26257762913693,
      },
      {
        lat: 13.114243975072403,
        lng: 80.26879132935395,
      },
      {
        lat: 13.106867422229714,
        lng: 80.2699022579787,
      },
      {
        lat: 13.115001598393558,
        lng: 80.26923129493952,
      },
      {
        lat: 13.108095958080204,
        lng: 80.26940761573017,
      },
      {
        lat: 13.108364590768288,
        lng: 80.27014629551394,
      },
      {
        lat: 13.113030496931614,
        lng: 80.26773561423519,
      },
      {
        lat: 13.113125546831466,
        lng: 80.26529871567588,
      },
      {
        lat: 13.117861414657181,
        lng: 80.26236217239932,
      },
      {
        lat: 13.115276581680508,
        lng: 80.2650137964039,
      },
      {
        lat: 13.115793351134775,
        lng: 80.26740541228122,
      },
      {
        lat: 13.117182488070961,
        lng: 80.26760651541996,
      },
      {
        lat: 13.110703379016888,
        lng: 80.26586266159579,
      },
      {
        lat: 13.112542083590556,
        lng: 80.26865801862688,
      },
      {
        lat: 13.116608179318252,
        lng: 80.26923879884812,
      },
      {
        lat: 13.116277183213656,
        lng: 80.2686135595333,
      },
      {
        lat: 13.117491373070523,
        lng: 80.26490695011533,
      },
      {
        lat: 13.108961055500094,
        lng: 80.26782536770612,
      },
      {
        lat: 13.115444474762816,
        lng: 80.26967097819706,
      },
      {
        lat: 13.113580414665813,
        lng: 80.2689256319173,
      },
      {
        lat: 13.111150484594992,
        lng: 80.26742357959031,
      },
      {
        lat: 13.118622820802004,
        lng: 80.26679400373217,
      },
      {
        lat: 13.116815208318165,
        lng: 80.26544415965597,
      },
      {
        lat: 13.117122237607584,
        lng: 80.2661057031359,
      },
      {
        lat: 13.11925841232969,
        lng: 80.26407369785029,
      },
      {
        lat: 13.115167413562679,
        lng: 80.26448051492949,
      },
      {
        lat: 13.112642189389293,
        lng: 80.26695386093874,
      },
      {
        lat: 13.115164166385702,
        lng: 80.26847758739699,
      },
      {
        lat: 13.119976413288775,
        lng: 80.27109068650321,
      },
      {
        lat: 13.11806936476176,
        lng: 80.2706548952401,
      },
      {
        lat: 13.113984030435034,
        lng: 80.26985118407414,
      },
      {
        lat: 13.112871429605232,
        lng: 80.26948050904521,
      },
      {
        lat: 13.112840929345532,
        lng: 80.26590584511538,
      },
      {
        lat: 13.116331981901856,
        lng: 80.2631284825892,
      },
      {
        lat: 13.1110386762606,
        lng: 80.26784648650784,
      },
      {
        lat: 13.114952094529002,
        lng: 80.26248315757222,
      },
      {
        lat: 13.112095171791575,
        lng: 80.26717424675506,
      },
      {
        lat: 13.115501916968709,
        lng: 80.26453440078835,
      },
      {
        lat: 13.106505188623323,
        lng: 80.27032756434672,
      },
      {
        lat: 13.114330584464373,
        lng: 80.26198268791582,
      },
      {
        lat: 13.113629500569155,
        lng: 80.26922965710882,
      },
      {
        lat: 13.108728207491884,
        lng: 80.26888962555006,
      },
      {
        lat: 13.116633863058556,
        lng: 80.26885759455503,
      },
      {
        lat: 13.116829104163589,
        lng: 80.26474028839284,
      },
      {
        lat: 13.116302382604813,
        lng: 80.26456426170887,
      },
      {
        lat: 13.119569416623667,
        lng: 80.26974530541182,
      },
      {
        lat: 13.116531227618914,
        lng: 80.26258069618167,
      },
      {
        lat: 13.112145684877834,
        lng: 80.26888490955344,
      },
      {
        lat: 13.11412797384691,
        lng: 80.26530298918188,
      },
      {
        lat: 13.118225712191569,
        lng: 80.27017987883504,
      },
      {
        lat: 13.116243714005542,
        lng: 80.2674354373036,
      },
      {
        lat: 13.115186357795109,
        lng: 80.26484830006653,
      },
      {
        lat: 13.115174966448388,
        lng: 80.26366926978253,
      },
      {
        lat: 13.11601871933579,
        lng: 80.27075377310794,
      },
      {
        lat: 13.114679414613612,
        lng: 80.26282678605233,
      },
      {
        lat: 13.109475951773,
        lng: 80.26989077303516,
      },
      {
        lat: 13.113615609278154,
        lng: 80.2657399523634,
      },
    ],
    cases: 149,
    coords: [
      {
        lat: 13.110285538133956,
        lng: 80.2698593018206,
      },
      {
        lat: 13.109685954107029,
        lng: 80.2699305724805,
      },
      {
        lat: 13.107844637412509,
        lng: 80.27045366041189,
      },
      {
        lat: 13.104804957881488,
        lng: 80.27125167529832,
      },
      {
        lat: 13.104538028748793,
        lng: 80.27120980357216,
      },
      {
        lat: 13.106043335476645,
        lng: 80.27024825121428,
      },
      {
        lat: 13.106572630843525,
        lng: 80.26958180940173,
      },
      {
        lat: 13.107699094584984,
        lng: 80.26844694002413,
      },
      {
        lat: 13.10818528852634,
        lng: 80.26800151707293,
      },
      {
        lat: 13.110019358264086,
        lng: 80.26628695842678,
      },
      {
        lat: 13.110704339924538,
        lng: 80.2656412644089,
      },
      {
        lat: 13.11312655220751,
        lng: 80.26384679625835,
      },
      {
        lat: 13.11379124557049,
        lng: 80.26360372663659,
      },
      {
        lat: 13.11416629337434,
        lng: 80.26348183043504,
      },
      {
        lat: 13.114248497865043,
        lng: 80.26295536552907,
      },
      {
        lat: 13.114259595419163,
        lng: 80.26197157441689,
      },
      {
        lat: 13.114120063533324,
        lng: 80.2614978670126,
      },
      {
        lat: 13.114221721000234,
        lng: 80.26136132428795,
      },
      {
        lat: 13.114478443501628,
        lng: 80.26144395306079,
      },
      {
        lat: 13.116429328205419,
        lng: 80.26203801288757,
      },
      {
        lat: 13.117352188216659,
        lng: 80.26211500396593,
      },
      {
        lat: 13.117642452695767,
        lng: 80.26209558708601,
      },
      {
        lat: 13.118067091392959,
        lng: 80.2617019064416,
      },
      {
        lat: 13.118168439399838,
        lng: 80.26151448544684,
      },
      {
        lat: 13.11815929556676,
        lng: 80.26139735921645,
      },
      {
        lat: 13.119599106420921,
        lng: 80.26168880985247,
      },
      {
        lat: 13.119588067365958,
        lng: 80.26456368426274,
      },
      {
        lat: 13.119462381154385,
        lng: 80.26500375394076,
      },
      {
        lat: 13.11952463214585,
        lng: 80.26820372217989,
      },
      {
        lat: 13.120162006502735,
        lng: 80.2721635430654,
      },
      {
        lat: 13.114683482530582,
        lng: 80.27071028385888,
      },
      {
        lat: 13.111962603117854,
        lng: 80.27007480242237,
      },
      {
        lat: 13.110285538133956,
        lng: 80.2698593018206,
      },
    ],
    properties: {
      Ward_No: 46,
      Zone_Name: "TONDIARPET",
      fillColor: "#de4307",
      zname: "TONDIARPET(46)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.11502050545486,
        lng: 80.27425020087571,
      },
      {
        lat: 13.1179122732156,
        lng: 80.27862494044827,
      },
      {
        lat: 13.112855032339727,
        lng: 80.27322521734399,
      },
      {
        lat: 13.118313093739888,
        lng: 80.27917928603229,
      },
      {
        lat: 13.11343518712986,
        lng: 80.27177354943974,
      },
      {
        lat: 13.116789398627487,
        lng: 80.27894219648744,
      },
      {
        lat: 13.11681920157556,
        lng: 80.27160003659601,
      },
      {
        lat: 13.110959143888875,
        lng: 80.2732788694301,
      },
      {
        lat: 13.119802855665467,
        lng: 80.2799396618619,
      },
      {
        lat: 13.120034490365416,
        lng: 80.28007395713811,
      },
      {
        lat: 13.112165568103793,
        lng: 80.27081395369898,
      },
      {
        lat: 13.11260575027114,
        lng: 80.270744446408,
      },
      {
        lat: 13.114101357628394,
        lng: 80.27502956666942,
      },
      {
        lat: 13.119662483348723,
        lng: 80.27434013810006,
      },
      {
        lat: 13.11646027041455,
        lng: 80.27366095571351,
      },
      {
        lat: 13.111642075055881,
        lng: 80.27211962000351,
      },
      {
        lat: 13.118972679176123,
        lng: 80.27550358809032,
      },
      {
        lat: 13.118023608440486,
        lng: 80.28045236448777,
      },
      {
        lat: 13.11204715729917,
        lng: 80.27379332282419,
      },
      {
        lat: 13.113417912944,
        lng: 80.27247974462496,
      },
      {
        lat: 13.111563403836932,
        lng: 80.27209132756708,
      },
      {
        lat: 13.111050345670884,
        lng: 80.27131088935866,
      },
      {
        lat: 13.116455732731918,
        lng: 80.27813656306294,
      },
      {
        lat: 13.117690680346962,
        lng: 80.27864010436367,
      },
      {
        lat: 13.114699544953234,
        lng: 80.27414027878059,
      },
      {
        lat: 13.11493297808649,
        lng: 80.27226570894715,
      },
      {
        lat: 13.111955077602548,
        lng: 80.27374648904815,
      },
      {
        lat: 13.115997773105303,
        lng: 80.27489292249426,
      },
      {
        lat: 13.116035459775492,
        lng: 80.27551565258945,
      },
      {
        lat: 13.114600887138666,
        lng: 80.27199878531728,
      },
      {
        lat: 13.11864988995734,
        lng: 80.27533197581033,
      },
      {
        lat: 13.118630257850503,
        lng: 80.27801768127999,
      },
      {
        lat: 13.119025629271446,
        lng: 80.27258900101687,
      },
      {
        lat: 13.115589259651253,
        lng: 80.27237944155972,
      },
      {
        lat: 13.116141767805022,
        lng: 80.27336222390298,
      },
      {
        lat: 13.11375682523287,
        lng: 80.27333360387742,
      },
      {
        lat: 13.110439145223634,
        lng: 80.27309264091723,
      },
      {
        lat: 13.119476654303565,
        lng: 80.27200688897007,
      },
      {
        lat: 13.119131541552315,
        lng: 80.27903324820686,
      },
      {
        lat: 13.118762018636497,
        lng: 80.2769576770656,
      },
      {
        lat: 13.116267083085063,
        lng: 80.27961970152822,
      },
      {
        lat: 13.115781794245144,
        lng: 80.27146954915649,
      },
      {
        lat: 13.119187001746694,
        lng: 80.27459498023735,
      },
      {
        lat: 13.114783314555241,
        lng: 80.27696513009444,
      },
      {
        lat: 13.112363632631503,
        lng: 80.2724762898044,
      },
      {
        lat: 13.111437766573886,
        lng: 80.271842537968,
      },
      {
        lat: 13.116024669401076,
        lng: 80.27507974165975,
      },
      {
        lat: 13.11503334205939,
        lng: 80.27491840844753,
      },
      {
        lat: 13.115237150935977,
        lng: 80.27835092864728,
      },
      {
        lat: 13.116463435648784,
        lng: 80.27541534752737,
      },
      {
        lat: 13.117007763322889,
        lng: 80.27484481473304,
      },
      {
        lat: 13.11293201602862,
        lng: 80.27170193905388,
      },
      {
        lat: 13.118162566232083,
        lng: 80.27437033152802,
      },
      {
        lat: 13.118427161887986,
        lng: 80.27180753569957,
      },
      {
        lat: 13.118060438394131,
        lng: 80.27844610396122,
      },
      {
        lat: 13.118397794149208,
        lng: 80.2782366571087,
      },
      {
        lat: 13.116591176883706,
        lng: 80.2781768474206,
      },
      {
        lat: 13.117474357931032,
        lng: 80.27456699761461,
      },
      {
        lat: 13.111382146274595,
        lng: 80.2703126717122,
      },
      {
        lat: 13.11883849394651,
        lng: 80.27288402903672,
      },
      {
        lat: 13.116520068211733,
        lng: 80.27220707091283,
      },
      {
        lat: 13.1146511483794,
        lng: 80.27118089935988,
      },
      {
        lat: 13.118085002825625,
        lng: 80.27201622695138,
      },
      {
        lat: 13.114595932645766,
        lng: 80.27477445232057,
      },
      {
        lat: 13.119528955378971,
        lng: 80.27998675370245,
      },
      {
        lat: 13.11752360155742,
        lng: 80.27349753626225,
      },
      {
        lat: 13.112945170311312,
        lng: 80.27242656188677,
      },
      {
        lat: 13.118633386522085,
        lng: 80.27933347738991,
      },
      {
        lat: 13.112910568625324,
        lng: 80.27103784613556,
      },
      {
        lat: 13.113234480681863,
        lng: 80.27412716320751,
      },
      {
        lat: 13.116533328646343,
        lng: 80.27643914069971,
      },
      {
        lat: 13.117376541676506,
        lng: 80.27462245393721,
      },
      {
        lat: 13.118972365658765,
        lng: 80.27892106653022,
      },
      {
        lat: 13.116561491467058,
        lng: 80.27178938512827,
      },
      {
        lat: 13.117587431452904,
        lng: 80.27979778643025,
      },
      {
        lat: 13.116794363749046,
        lng: 80.2766082048461,
      },
      {
        lat: 13.116512702727384,
        lng: 80.27616350336744,
      },
      {
        lat: 13.115076211886462,
        lng: 80.27405294904237,
      },
      {
        lat: 13.117156973483167,
        lng: 80.2729170945893,
      },
      {
        lat: 13.116674801461366,
        lng: 80.27684723708049,
      },
      {
        lat: 13.112758128845808,
        lng: 80.27080095682649,
      },
      {
        lat: 13.115004863348972,
        lng: 80.27704106426037,
      },
      {
        lat: 13.111707503764457,
        lng: 80.27129846627558,
      },
      {
        lat: 13.112994619050713,
        lng: 80.2706280228689,
      },
      {
        lat: 13.118153880564888,
        lng: 80.27359474670125,
      },
      {
        lat: 13.116811924292426,
        lng: 80.27244889671984,
      },
      {
        lat: 13.111258323607439,
        lng: 80.2708083564484,
      },
      {
        lat: 13.119189179253352,
        lng: 80.28019632420676,
      },
      {
        lat: 13.113988774817466,
        lng: 80.2750024791442,
      },
      {
        lat: 13.114340879249653,
        lng: 80.27327457897917,
      },
      {
        lat: 13.114459764540355,
        lng: 80.27427810929157,
      },
      {
        lat: 13.11959478904408,
        lng: 80.27261219382801,
      },
      {
        lat: 13.11760233924231,
        lng: 80.273418518472,
      },
      {
        lat: 13.119553641320612,
        lng: 80.27494342900599,
      },
      {
        lat: 13.116690857930458,
        lng: 80.27812949953115,
      },
      {
        lat: 13.118703625897327,
        lng: 80.2730660749824,
      },
      {
        lat: 13.116538351972821,
        lng: 80.27860102792503,
      },
      {
        lat: 13.118558917600538,
        lng: 80.27426032758035,
      },
      {
        lat: 13.110750728458592,
        lng: 80.27074358383682,
      },
      {
        lat: 13.110662652570232,
        lng: 80.2734450239832,
      },
      {
        lat: 13.11625550005547,
        lng: 80.27911370319059,
      },
      {
        lat: 13.118381181606324,
        lng: 80.27729887075571,
      },
      {
        lat: 13.1159920960131,
        lng: 80.27363348298961,
      },
      {
        lat: 13.113962894320482,
        lng: 80.2732060441408,
      },
      {
        lat: 13.118606407755124,
        lng: 80.27666508368026,
      },
      {
        lat: 13.119178918345053,
        lng: 80.27228181882862,
      },
      {
        lat: 13.117622244525242,
        lng: 80.27685204143502,
      },
      {
        lat: 13.117096451373186,
        lng: 80.27268965373759,
      },
      {
        lat: 13.111478647455245,
        lng: 80.27277647119742,
      },
      {
        lat: 13.118879141168627,
        lng: 80.27439040708545,
      },
      {
        lat: 13.117745730413814,
        lng: 80.27339994961142,
      },
      {
        lat: 13.117927750283993,
        lng: 80.27452261225442,
      },
    ],
    cases: 112,
    coords: [
      {
        lat: 13.1139996916533,
        lng: 80.27796499419135,
      },
      {
        lat: 13.114056180280782,
        lng: 80.2778317658464,
      },
      {
        lat: 13.114074356218907,
        lng: 80.27767989742772,
      },
      {
        lat: 13.114284095603633,
        lng: 80.27762122937716,
      },
      {
        lat: 13.11449337362037,
        lng: 80.2774867079358,
      },
      {
        lat: 13.114511318874648,
        lng: 80.27729691282165,
      },
      {
        lat: 13.114357376065621,
        lng: 80.27710857244082,
      },
      {
        lat: 13.114375205977714,
        lng: 80.27689981397886,
      },
      {
        lat: 13.115247285817873,
        lng: 80.27583043120363,
      },
      {
        lat: 13.115361185808235,
        lng: 80.27571568129518,
      },
      {
        lat: 13.115303313077309,
        lng: 80.27562134946781,
      },
      {
        lat: 13.114629567133303,
        lng: 80.27525062761617,
      },
      {
        lat: 13.113472292678907,
        lng: 80.27497357162213,
      },
      {
        lat: 13.112848205475396,
        lng: 80.27466993771789,
      },
      {
        lat: 13.110709819949639,
        lng: 80.27384954991415,
      },
      {
        lat: 13.110420132278142,
        lng: 80.27371960916635,
      },
      {
        lat: 13.110330305948734,
        lng: 80.27356591153188,
      },
      {
        lat: 13.110438462121168,
        lng: 80.27307955719571,
      },
      {
        lat: 13.110609257388216,
        lng: 80.27193071047984,
      },
      {
        lat: 13.110533466409176,
        lng: 80.27043090415482,
      },
      {
        lat: 13.110441895442035,
        lng: 80.26999037097781,
      },
      {
        lat: 13.110285538133956,
        lng: 80.2698593018206,
      },
      {
        lat: 13.111962603117854,
        lng: 80.27007480242237,
      },
      {
        lat: 13.114683482530582,
        lng: 80.27071028385888,
      },
      {
        lat: 13.120162006502735,
        lng: 80.2721635430654,
      },
      {
        lat: 13.120144418345019,
        lng: 80.27288429359082,
      },
      {
        lat: 13.119955054456769,
        lng: 80.27335437338324,
      },
      {
        lat: 13.119906853864736,
        lng: 80.27404882154957,
      },
      {
        lat: 13.119856753738743,
        lng: 80.27443096768748,
      },
      {
        lat: 13.119772233408293,
        lng: 80.27490016016186,
      },
      {
        lat: 13.119274565097347,
        lng: 80.27639655855683,
      },
      {
        lat: 13.118913733365991,
        lng: 80.27740597070618,
      },
      {
        lat: 13.118863000061786,
        lng: 80.27768401616805,
      },
      {
        lat: 13.118969954213227,
        lng: 80.27803013110356,
      },
      {
        lat: 13.119408068726996,
        lng: 80.27823463529639,
      },
      {
        lat: 13.120668829662788,
        lng: 80.27865774029475,
      },
      {
        lat: 13.11986000762796,
        lng: 80.2807120042504,
      },
      {
        lat: 13.119545160365318,
        lng: 80.2806626158669,
      },
      {
        lat: 13.118915254781038,
        lng: 80.28052913887483,
      },
      {
        lat: 13.118564087780044,
        lng: 80.28025449479941,
      },
      {
        lat: 13.118301767826063,
        lng: 80.28022201286964,
      },
      {
        lat: 13.118233032947218,
        lng: 80.28041345565445,
      },
      {
        lat: 13.118076189729136,
        lng: 80.28048418708264,
      },
      {
        lat: 13.11793639831868,
        lng: 80.28048537017362,
      },
      {
        lat: 13.117813236597284,
        lng: 80.2803476044766,
      },
      {
        lat: 13.117619545992417,
        lng: 80.2801063296492,
      },
      {
        lat: 13.117111852362472,
        lng: 80.2799544673398,
      },
      {
        lat: 13.114853705741197,
        lng: 80.27931424039978,
      },
      {
        lat: 13.11446695770968,
        lng: 80.27893579142092,
      },
      {
        lat: 13.114220950855499,
        lng: 80.27871231036525,
      },
      {
        lat: 13.114131470630616,
        lng: 80.2783660475434,
      },
      {
        lat: 13.1139996916533,
        lng: 80.27796499419135,
      },
    ],
    properties: {
      Ward_No: 47,
      Zone_Name: "TONDIARPET",
      fillColor: "#f6d04d",
      zname: "TONDIARPET(47)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.11387451810951,
        lng: 80.27932420489145,
      },
      {
        lat: 13.116056449967692,
        lng: 80.28294908038356,
      },
      {
        lat: 13.114797623490059,
        lng: 80.28445969859531,
      },
      {
        lat: 13.114612372887278,
        lng: 80.28353902692854,
      },
      {
        lat: 13.11572407595197,
        lng: 80.28183779309941,
      },
      {
        lat: 13.113033137262072,
        lng: 80.281455370693,
      },
      {
        lat: 13.116173316887934,
        lng: 80.28394551626178,
      },
      {
        lat: 13.112978615420547,
        lng: 80.28450259029228,
      },
      {
        lat: 13.116600266882964,
        lng: 80.2855766745371,
      },
      {
        lat: 13.115620436342658,
        lng: 80.28563102670054,
      },
      {
        lat: 13.118532537001633,
        lng: 80.2822290581311,
      },
      {
        lat: 13.1160216962057,
        lng: 80.28253230584579,
      },
      {
        lat: 13.117800484352959,
        lng: 80.28111053634895,
      },
      {
        lat: 13.11327318344994,
        lng: 80.28187522638089,
      },
      {
        lat: 13.11837510320886,
        lng: 80.28201982705752,
      },
      {
        lat: 13.11374582112176,
        lng: 80.27873514872933,
      },
      {
        lat: 13.112028676466714,
        lng: 80.28328544080915,
      },
      {
        lat: 13.115870125227495,
        lng: 80.28400680805093,
      },
      {
        lat: 13.114131817426111,
        lng: 80.28137219395981,
      },
      {
        lat: 13.112847987681265,
        lng: 80.27812943587784,
      },
      {
        lat: 13.11714123197186,
        lng: 80.285986618586,
      },
      {
        lat: 13.116606959575488,
        lng: 80.28008462104022,
      },
      {
        lat: 13.113334470146054,
        lng: 80.28010771811302,
      },
      {
        lat: 13.115485817936312,
        lng: 80.28470205547058,
      },
      {
        lat: 13.114839898532233,
        lng: 80.28227990182047,
      },
      {
        lat: 13.113625680237215,
        lng: 80.28018256522707,
      },
      {
        lat: 13.113502281759537,
        lng: 80.2812995331657,
      },
      {
        lat: 13.11380079239463,
        lng: 80.28168196799571,
      },
      {
        lat: 13.118131164807169,
        lng: 80.28304935661413,
      },
      {
        lat: 13.116173748458708,
        lng: 80.28552541310592,
      },
      {
        lat: 13.117470199703165,
        lng: 80.28479178537216,
      },
      {
        lat: 13.117345403551885,
        lng: 80.28266845301464,
      },
      {
        lat: 13.113368392689747,
        lng: 80.28327324392514,
      },
      {
        lat: 13.11451725544835,
        lng: 80.2819428741489,
      },
      {
        lat: 13.113284539103807,
        lng: 80.2834759453478,
      },
      {
        lat: 13.115601674218897,
        lng: 80.2816682431835,
      },
      {
        lat: 13.111964261771053,
        lng: 80.283588306598,
      },
      {
        lat: 13.112552388942909,
        lng: 80.28170687423572,
      },
      {
        lat: 13.11395472987171,
        lng: 80.28060453683884,
      },
      {
        lat: 13.112587042586465,
        lng: 80.27974071227753,
      },
      {
        lat: 13.115224783262313,
        lng: 80.28062672493085,
      },
      {
        lat: 13.113547784649638,
        lng: 80.28332191217861,
      },
      {
        lat: 13.11440660581545,
        lng: 80.28518917069512,
      },
      {
        lat: 13.114375919275268,
        lng: 80.28260021022618,
      },
      {
        lat: 13.116218905392172,
        lng: 80.28033406561117,
      },
      {
        lat: 13.11560969508854,
        lng: 80.28559456759851,
      },
      {
        lat: 13.113816381912047,
        lng: 80.28479452497638,
      },
      {
        lat: 13.115338177700165,
        lng: 80.28388689593585,
      },
      {
        lat: 13.114805314286965,
        lng: 80.28527416559486,
      },
      {
        lat: 13.117751682054928,
        lng: 80.2829593123946,
      },
      {
        lat: 13.118717616734418,
        lng: 80.28236801420707,
      },
      {
        lat: 13.113034585262744,
        lng: 80.2832983001432,
      },
      {
        lat: 13.112427767885682,
        lng: 80.27907999356945,
      },
      {
        lat: 13.11759838141762,
        lng: 80.28533879948729,
      },
      {
        lat: 13.114126279934181,
        lng: 80.28016439608373,
      },
      {
        lat: 13.117723107779014,
        lng: 80.281876573952,
      },
      {
        lat: 13.114538764404255,
        lng: 80.28202123786622,
      },
      {
        lat: 13.115079263252296,
        lng: 80.28500824214824,
      },
      {
        lat: 13.118376702033892,
        lng: 80.28277023704153,
      },
      {
        lat: 13.116985635338901,
        lng: 80.28236210689039,
      },
      {
        lat: 13.115797341529493,
        lng: 80.28055442289411,
      },
      {
        lat: 13.113933299192727,
        lng: 80.2803305329858,
      },
      {
        lat: 13.113422767559156,
        lng: 80.283456660933,
      },
      {
        lat: 13.11182881468184,
        lng: 80.28370835098004,
      },
      {
        lat: 13.112777337202953,
        lng: 80.27848188450463,
      },
      {
        lat: 13.115969556892612,
        lng: 80.28476196499858,
      },
      {
        lat: 13.116200057490074,
        lng: 80.28494633411931,
      },
      {
        lat: 13.116996286847934,
        lng: 80.28092294805542,
      },
      {
        lat: 13.116876020904758,
        lng: 80.28573792034652,
      },
      {
        lat: 13.113068858752786,
        lng: 80.28294931572071,
      },
      {
        lat: 13.118024609177931,
        lng: 80.2830429613516,
      },
      {
        lat: 13.117324643552141,
        lng: 80.28163726508322,
      },
      {
        lat: 13.113350838127264,
        lng: 80.27912031684554,
      },
      {
        lat: 13.117033512919859,
        lng: 80.28067732405384,
      },
      {
        lat: 13.114696555111314,
        lng: 80.28089194729804,
      },
      {
        lat: 13.113014074122972,
        lng: 80.27957604531649,
      },
      {
        lat: 13.117285105249666,
        lng: 80.28206827851182,
      },
      {
        lat: 13.116059855834148,
        lng: 80.28386905315934,
      },
      {
        lat: 13.116297841848583,
        lng: 80.282221444309,
      },
      {
        lat: 13.116495833825423,
        lng: 80.28534980972644,
      },
      {
        lat: 13.116588522524339,
        lng: 80.28369544466858,
      },
      {
        lat: 13.11454621839289,
        lng: 80.28460387389954,
      },
      {
        lat: 13.114818299635097,
        lng: 80.28261302669972,
      },
      {
        lat: 13.11384833702387,
        lng: 80.28160421562461,
      },
      {
        lat: 13.113241789994927,
        lng: 80.27869004109463,
      },
    ],
    cases: 85,
    coords: [
      {
        lat: 13.11836299884049,
        lng: 80.28543526201155,
      },
      {
        lat: 13.11834279933043,
        lng: 80.28547603825014,
      },
      {
        lat: 13.118005020985596,
        lng: 80.2864650417976,
      },
      {
        lat: 13.117834055658156,
        lng: 80.28661820331249,
      },
      {
        lat: 13.117489702821958,
        lng: 80.28652629604062,
      },
      {
        lat: 13.113336870669432,
        lng: 80.28517704689264,
      },
      {
        lat: 13.111730159898906,
        lng: 80.28479239410098,
      },
      {
        lat: 13.111595546447429,
        lng: 80.28464181877847,
      },
      {
        lat: 13.111574486960627,
        lng: 80.28431960350439,
      },
      {
        lat: 13.111761898096892,
        lng: 80.283730123355,
      },
      {
        lat: 13.112156165069026,
        lng: 80.28260789146226,
      },
      {
        lat: 13.112129569168273,
        lng: 80.28137543549782,
      },
      {
        lat: 13.112276245434147,
        lng: 80.2803690849729,
      },
      {
        lat: 13.112402092897126,
        lng: 80.27907844586905,
      },
      {
        lat: 13.1123613580268,
        lng: 80.2786615754935,
      },
      {
        lat: 13.112434177120972,
        lng: 80.27807306516631,
      },
      {
        lat: 13.112566829759233,
        lng: 80.27790126357765,
      },
      {
        lat: 13.112833865164946,
        lng: 80.27784211061619,
      },
      {
        lat: 13.11338864943742,
        lng: 80.27798912992408,
      },
      {
        lat: 13.1139996916533,
        lng: 80.27796499419135,
      },
      {
        lat: 13.114131470630616,
        lng: 80.2783660475434,
      },
      {
        lat: 13.114220950855499,
        lng: 80.27871231036525,
      },
      {
        lat: 13.11446695770968,
        lng: 80.27893579142092,
      },
      {
        lat: 13.114853705741197,
        lng: 80.27931424039978,
      },
      {
        lat: 13.117111852362472,
        lng: 80.2799544673398,
      },
      {
        lat: 13.117619545992417,
        lng: 80.2801063296492,
      },
      {
        lat: 13.117813236597284,
        lng: 80.2803476044766,
      },
      {
        lat: 13.11793639831868,
        lng: 80.28048537017362,
      },
      {
        lat: 13.118076189729136,
        lng: 80.28048418708264,
      },
      {
        lat: 13.118233032947218,
        lng: 80.28041345565445,
      },
      {
        lat: 13.1178946588276,
        lng: 80.28110277401905,
      },
      {
        lat: 13.117800863432809,
        lng: 80.28133197914799,
      },
      {
        lat: 13.117738365105467,
        lng: 80.2814900331319,
      },
      {
        lat: 13.117739179469298,
        lng: 80.28162392252753,
      },
      {
        lat: 13.117930506751525,
        lng: 80.28177982831936,
      },
      {
        lat: 13.11874029443851,
        lng: 80.28189111865946,
      },
      {
        lat: 13.118721336566859,
        lng: 80.2826867805785,
      },
      {
        lat: 13.118594327954657,
        lng: 80.28267210298108,
      },
      {
        lat: 13.11857632815804,
        lng: 80.28362528183614,
      },
      {
        lat: 13.117783266839265,
        lng: 80.28365562247636,
      },
      {
        lat: 13.116776902430002,
        lng: 80.28382954089578,
      },
      {
        lat: 13.116713877161365,
        lng: 80.28390096056494,
      },
      {
        lat: 13.116817616564168,
        lng: 80.28400247387037,
      },
      {
        lat: 13.117708640904173,
        lng: 80.28442812677224,
      },
      {
        lat: 13.11779694734014,
        lng: 80.28460065696063,
      },
      {
        lat: 13.117758963719995,
        lng: 80.28487664725337,
      },
      {
        lat: 13.117680553388336,
        lng: 80.28502695965255,
      },
      {
        lat: 13.117753331049972,
        lng: 80.28525475503066,
      },
      {
        lat: 13.11808705200759,
        lng: 80.28534644569018,
      },
      {
        lat: 13.11836299884049,
        lng: 80.28543526201155,
      },
    ],
    properties: {
      Ward_No: 48,
      Zone_Name: "TONDIARPET",
      fillColor: "#f6d04d",
      zname: "TONDIARPET(48)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.1245978701231,
        lng: 80.28711148117263,
      },
      {
        lat: 13.127506485735607,
        lng: 80.28871527121235,
      },
      {
        lat: 13.124578277504918,
        lng: 80.28586024322546,
      },
      {
        lat: 13.126132841233092,
        lng: 80.28805397014192,
      },
      {
        lat: 13.118941503974352,
        lng: 80.28382693342456,
      },
      {
        lat: 13.124803743059358,
        lng: 80.28671414733418,
      },
      {
        lat: 13.125957666265665,
        lng: 80.28855513674804,
      },
      {
        lat: 13.120411755061141,
        lng: 80.28412966542375,
      },
      {
        lat: 13.119899459956539,
        lng: 80.28519395359149,
      },
      {
        lat: 13.127340771290458,
        lng: 80.2866660714356,
      },
      {
        lat: 13.125990988150551,
        lng: 80.28601097833987,
      },
      {
        lat: 13.126600847217189,
        lng: 80.28676292100825,
      },
      {
        lat: 13.121767175689481,
        lng: 80.28182140298883,
      },
      {
        lat: 13.126102006441526,
        lng: 80.28670403427795,
      },
      {
        lat: 13.123798784729644,
        lng: 80.28436830948085,
      },
      {
        lat: 13.123812416674287,
        lng: 80.28533626210368,
      },
      {
        lat: 13.123866788931096,
        lng: 80.2875091498261,
      },
      {
        lat: 13.118907140290672,
        lng: 80.28284382787814,
      },
      {
        lat: 13.125911381140238,
        lng: 80.28739773662407,
      },
      {
        lat: 13.127115172863716,
        lng: 80.2900140093206,
      },
      {
        lat: 13.12292698969488,
        lng: 80.28298163465524,
      },
      {
        lat: 13.124916122325098,
        lng: 80.28559658672434,
      },
      {
        lat: 13.121208148638244,
        lng: 80.28262161220245,
      },
      {
        lat: 13.124523546096018,
        lng: 80.28710976289129,
      },
      {
        lat: 13.118978206018095,
        lng: 80.2824254598945,
      },
      {
        lat: 13.118550912979492,
        lng: 80.28497979927316,
      },
      {
        lat: 13.118263272311534,
        lng: 80.2837851670083,
      },
      {
        lat: 13.125976815469853,
        lng: 80.2870134363303,
      },
      {
        lat: 13.127315145740601,
        lng: 80.28847687627798,
      },
      {
        lat: 13.12541062123248,
        lng: 80.28646903723197,
      },
      {
        lat: 13.12013971967403,
        lng: 80.2831298704924,
      },
      {
        lat: 13.120649205533011,
        lng: 80.283478916406,
      },
      {
        lat: 13.118944620476935,
        lng: 80.28499048375373,
      },
      {
        lat: 13.124904969815706,
        lng: 80.28711586923161,
      },
      {
        lat: 13.124927183308376,
        lng: 80.28552681477645,
      },
      {
        lat: 13.127264463582476,
        lng: 80.28570492706129,
      },
      {
        lat: 13.125045061117826,
        lng: 80.2845220732325,
      },
      {
        lat: 13.121464352013197,
        lng: 80.28467370863979,
      },
      {
        lat: 13.123980721056643,
        lng: 80.28431719410843,
      },
      {
        lat: 13.126765758943312,
        lng: 80.28824238130248,
      },
      {
        lat: 13.123843321678692,
        lng: 80.28466956484758,
      },
      {
        lat: 13.123921755336282,
        lng: 80.28661339143231,
      },
      {
        lat: 13.124764804117559,
        lng: 80.28877002059082,
      },
      {
        lat: 13.126413247027235,
        lng: 80.28876585382002,
      },
      {
        lat: 13.12252549736893,
        lng: 80.28667578362906,
      },
      {
        lat: 13.12556733883711,
        lng: 80.2879823774097,
      },
      {
        lat: 13.1199217915595,
        lng: 80.28516076738377,
      },
      {
        lat: 13.117973132528263,
        lng: 80.28507788658837,
      },
      {
        lat: 13.122025574552508,
        lng: 80.28579285771482,
      },
      {
        lat: 13.126273731177742,
        lng: 80.28828605204119,
      },
      {
        lat: 13.128077257582305,
        lng: 80.28483516747669,
      },
      {
        lat: 13.127197639280467,
        lng: 80.2881464427053,
      },
      {
        lat: 13.123940688026265,
        lng: 80.2870575985986,
      },
      {
        lat: 13.120714136244114,
        lng: 80.2837803313991,
      },
      {
        lat: 13.127155902374016,
        lng: 80.2885944417193,
      },
      {
        lat: 13.120273842361815,
        lng: 80.28407448249233,
      },
      {
        lat: 13.119516094979288,
        lng: 80.2809609916821,
      },
      {
        lat: 13.126608033205942,
        lng: 80.28641000886188,
      },
      {
        lat: 13.124312588392327,
        lng: 80.2878544342664,
      },
      {
        lat: 13.120005818975306,
        lng: 80.28163919383344,
      },
      {
        lat: 13.122681512660419,
        lng: 80.28398957666579,
      },
      {
        lat: 13.125405770267399,
        lng: 80.28626145999213,
      },
      {
        lat: 13.118912635205222,
        lng: 80.28147470030777,
      },
      {
        lat: 13.122168587320953,
        lng: 80.28316183738617,
      },
      {
        lat: 13.12610711168532,
        lng: 80.28935980426229,
      },
      {
        lat: 13.128047922383795,
        lng: 80.28513006651792,
      },
      {
        lat: 13.125110008292344,
        lng: 80.28583997605008,
      },
      {
        lat: 13.120902408677109,
        lng: 80.28376768094545,
      },
      {
        lat: 13.127903004712248,
        lng: 80.28558168409062,
      },
      {
        lat: 13.123675047466284,
        lng: 80.28424441615229,
      },
      {
        lat: 13.126708703821404,
        lng: 80.28978249184497,
      },
      {
        lat: 13.126323347834852,
        lng: 80.28581777195677,
      },
      {
        lat: 13.121452475179954,
        lng: 80.28622949678072,
      },
      {
        lat: 13.127757028440284,
        lng: 80.28535162732261,
      },
      {
        lat: 13.121776840839551,
        lng: 80.28360161175675,
      },
      {
        lat: 13.120957659209992,
        lng: 80.28166368873762,
      },
      {
        lat: 13.127070027780448,
        lng: 80.28928870085346,
      },
      {
        lat: 13.12066874810207,
        lng: 80.28301174890001,
      },
    ],
    cases: 78,
    coords: [
      {
        lat: 13.123369984536449,
        lng: 80.28769972858191,
      },
      {
        lat: 13.123292321080218,
        lng: 80.28749177830512,
      },
      {
        lat: 13.122908386793169,
        lng: 80.28717263413449,
      },
      {
        lat: 13.122218873727265,
        lng: 80.28685607615651,
      },
      {
        lat: 13.120916703658072,
        lng: 80.28629816704306,
      },
      {
        lat: 13.11910593888407,
        lng: 80.28533682933497,
      },
      {
        lat: 13.118627549306451,
        lng: 80.28518916347899,
      },
      {
        lat: 13.118456007269069,
        lng: 80.28524750825531,
      },
      {
        lat: 13.11836299884049,
        lng: 80.28543526201155,
      },
      {
        lat: 13.11808705200759,
        lng: 80.28534644569018,
      },
      {
        lat: 13.117753331049972,
        lng: 80.28525475503066,
      },
      {
        lat: 13.117680553388336,
        lng: 80.28502695965255,
      },
      {
        lat: 13.117758963719995,
        lng: 80.28487664725337,
      },
      {
        lat: 13.11779694734014,
        lng: 80.28460065696063,
      },
      {
        lat: 13.117708640904173,
        lng: 80.28442812677224,
      },
      {
        lat: 13.116817616564168,
        lng: 80.28400247387037,
      },
      {
        lat: 13.116713877161365,
        lng: 80.28390096056494,
      },
      {
        lat: 13.116776902430002,
        lng: 80.28382954089578,
      },
      {
        lat: 13.117783266839265,
        lng: 80.28365562247636,
      },
      {
        lat: 13.11857632815804,
        lng: 80.28362528183614,
      },
      {
        lat: 13.118594327954657,
        lng: 80.28267210298108,
      },
      {
        lat: 13.118721336566859,
        lng: 80.2826867805785,
      },
      {
        lat: 13.11874029443851,
        lng: 80.28189111865946,
      },
      {
        lat: 13.117930506751525,
        lng: 80.28177982831936,
      },
      {
        lat: 13.117739179469298,
        lng: 80.28162392252753,
      },
      {
        lat: 13.117738365105467,
        lng: 80.2814900331319,
      },
      {
        lat: 13.117800863432809,
        lng: 80.28133197914799,
      },
      {
        lat: 13.1178946588276,
        lng: 80.28110277401905,
      },
      {
        lat: 13.118233032947218,
        lng: 80.28041345565445,
      },
      {
        lat: 13.118301767826063,
        lng: 80.28022201286964,
      },
      {
        lat: 13.118564087780044,
        lng: 80.28025449479941,
      },
      {
        lat: 13.118915254781038,
        lng: 80.28052913887483,
      },
      {
        lat: 13.119545160365318,
        lng: 80.2806626158669,
      },
      {
        lat: 13.11986000762796,
        lng: 80.2807120042504,
      },
      {
        lat: 13.121730037508756,
        lng: 80.28171274599825,
      },
      {
        lat: 13.122197060170912,
        lng: 80.28212988392107,
      },
      {
        lat: 13.12248026373578,
        lng: 80.28220769480205,
      },
      {
        lat: 13.12355578888585,
        lng: 80.28306082519381,
      },
      {
        lat: 13.124105416825138,
        lng: 80.28377804290449,
      },
      {
        lat: 13.124531197827038,
        lng: 80.28405516638068,
      },
      {
        lat: 13.125340176645887,
        lng: 80.28424883904829,
      },
      {
        lat: 13.128292400529023,
        lng: 80.28486551526034,
      },
      {
        lat: 13.127628001304645,
        lng: 80.28852058886889,
      },
      {
        lat: 13.127132420490147,
        lng: 80.29004872951288,
      },
      {
        lat: 13.124944884845668,
        lng: 80.28898443916262,
      },
      {
        lat: 13.123890041504158,
        lng: 80.28821134144133,
      },
      {
        lat: 13.123369984536449,
        lng: 80.28769972858191,
      },
    ],
    properties: {
      Ward_No: 42,
      Zone_Name: "TONDIARPET",
      fillColor: "#f6d04d",
      zname: "TONDIARPET(42)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.127643112189272,
        lng: 80.29382007603951,
      },
      {
        lat: 13.130514849801871,
        lng: 80.29304210812617,
      },
      {
        lat: 13.125535979473172,
        lng: 80.29673200232148,
      },
      {
        lat: 13.126793907933616,
        lng: 80.29289074343114,
      },
      {
        lat: 13.125358201683522,
        lng: 80.29405862604138,
      },
      {
        lat: 13.121975719629482,
        lng: 80.29507791010457,
      },
      {
        lat: 13.12328165940324,
        lng: 80.29276077051162,
      },
      {
        lat: 13.12547304557281,
        lng: 80.29379766808302,
      },
      {
        lat: 13.126074587768445,
        lng: 80.29047496650665,
      },
      {
        lat: 13.123233474883147,
        lng: 80.28912435299061,
      },
      {
        lat: 13.127261122953218,
        lng: 80.29107082844999,
      },
      {
        lat: 13.122157538333205,
        lng: 80.29658193490168,
      },
      {
        lat: 13.125277747304981,
        lng: 80.29042971661123,
      },
      {
        lat: 13.127776433987984,
        lng: 80.29089973356827,
      },
      {
        lat: 13.122476417213427,
        lng: 80.29617658542674,
      },
      {
        lat: 13.12154743029735,
        lng: 80.2992929405682,
      },
      {
        lat: 13.125616016226983,
        lng: 80.29507481582347,
      },
      {
        lat: 13.126991990706852,
        lng: 80.29159537964136,
      },
      {
        lat: 13.127968691039053,
        lng: 80.29304878927051,
      },
      {
        lat: 13.12759471548398,
        lng: 80.29552527235678,
      },
      {
        lat: 13.123962692105602,
        lng: 80.28913995977186,
      },
      {
        lat: 13.121907712124555,
        lng: 80.2960596102262,
      },
      {
        lat: 13.123157849352246,
        lng: 80.290495479035,
      },
      {
        lat: 13.121376080832118,
        lng: 80.29558693735035,
      },
      {
        lat: 13.127324639388036,
        lng: 80.29227472954263,
      },
      {
        lat: 13.123799275356165,
        lng: 80.29601928870481,
      },
      {
        lat: 13.125117062549588,
        lng: 80.29131660620995,
      },
      {
        lat: 13.122377116532277,
        lng: 80.29744374047142,
      },
      {
        lat: 13.12521409275219,
        lng: 80.29145431754301,
      },
      {
        lat: 13.121049795692883,
        lng: 80.29590147235673,
      },
      {
        lat: 13.123706469234255,
        lng: 80.29653866743871,
      },
      {
        lat: 13.129660727287263,
        lng: 80.29732461041462,
      },
      {
        lat: 13.123711875613507,
        lng: 80.29153430992268,
      },
      {
        lat: 13.122640155633738,
        lng: 80.29742766998936,
      },
      {
        lat: 13.127601130667331,
        lng: 80.29464874871441,
      },
      {
        lat: 13.124094726206982,
        lng: 80.28872619181529,
      },
      {
        lat: 13.130038475012867,
        lng: 80.29220452582845,
      },
      {
        lat: 13.126784270557039,
        lng: 80.29596350386782,
      },
      {
        lat: 13.130066061663596,
        lng: 80.29482077137256,
      },
      {
        lat: 13.123733852435493,
        lng: 80.2962815550881,
      },
      {
        lat: 13.124024228462885,
        lng: 80.29635575565344,
      },
      {
        lat: 13.123983170125465,
        lng: 80.29085201739548,
      },
      {
        lat: 13.122296698346819,
        lng: 80.29621936742815,
      },
      {
        lat: 13.131757863060926,
        lng: 80.29625055193223,
      },
      {
        lat: 13.12734981316381,
        lng: 80.29374033081784,
      },
      {
        lat: 13.12763168065232,
        lng: 80.29244353875312,
      },
      {
        lat: 13.128414447805442,
        lng: 80.29775811147992,
      },
      {
        lat: 13.126870104766658,
        lng: 80.29592537371975,
      },
      {
        lat: 13.128364884500032,
        lng: 80.29483359230547,
      },
      {
        lat: 13.131242865471311,
        lng: 80.2922156900513,
      },
      {
        lat: 13.126873301777099,
        lng: 80.29631642943181,
      },
      {
        lat: 13.12207819271068,
        lng: 80.29562359361884,
      },
      {
        lat: 13.124966381474968,
        lng: 80.29098627098219,
      },
      {
        lat: 13.124841085943558,
        lng: 80.29552337065982,
      },
      {
        lat: 13.129254943157592,
        lng: 80.29407534133183,
      },
      {
        lat: 13.124392799591085,
        lng: 80.29362095543836,
      },
      {
        lat: 13.129702320669917,
        lng: 80.29657042248212,
      },
      {
        lat: 13.127555901583,
        lng: 80.29467682519176,
      },
      {
        lat: 13.127497829501541,
        lng: 80.29025645342757,
      },
      {
        lat: 13.129047792479916,
        lng: 80.29511157224292,
      },
      {
        lat: 13.130035130486526,
        lng: 80.29157059294171,
      },
      {
        lat: 13.121191396850582,
        lng: 80.29764165599461,
      },
      {
        lat: 13.130779760233807,
        lng: 80.29575371202561,
      },
      {
        lat: 13.125749735545979,
        lng: 80.29566819768506,
      },
      {
        lat: 13.122377779246277,
        lng: 80.29445994410507,
      },
      {
        lat: 13.121460036646253,
        lng: 80.29823967030572,
      },
      {
        lat: 13.123148338813456,
        lng: 80.29254521425742,
      },
      {
        lat: 13.131769808139364,
        lng: 80.2920290448266,
      },
      {
        lat: 13.123953603379004,
        lng: 80.29458697011206,
      },
      {
        lat: 13.124549683241202,
        lng: 80.2919832545288,
      },
      {
        lat: 13.130351315724578,
        lng: 80.29661464913653,
      },
      {
        lat: 13.125740717872732,
        lng: 80.28970412474041,
      },
      {
        lat: 13.125659258987296,
        lng: 80.29109985156649,
      },
      {
        lat: 13.12124462374741,
        lng: 80.29433895936141,
      },
      {
        lat: 13.123350803839282,
        lng: 80.29130334939688,
      },
      {
        lat: 13.127887621655818,
        lng: 80.29470260902848,
      },
      {
        lat: 13.12653780919886,
        lng: 80.29015686005395,
      },
      {
        lat: 13.128469107137452,
        lng: 80.29096488276626,
      },
      {
        lat: 13.123091802083849,
        lng: 80.29343906751379,
      },
      {
        lat: 13.126555954828898,
        lng: 80.29158570325467,
      },
      {
        lat: 13.131534385720965,
        lng: 80.29505363940848,
      },
      {
        lat: 13.123937072094174,
        lng: 80.29048584870017,
      },
      {
        lat: 13.125408748880846,
        lng: 80.29518953813536,
      },
      {
        lat: 13.1298833077976,
        lng: 80.292144248165,
      },
      {
        lat: 13.12748579636385,
        lng: 80.29409739880094,
      },
      {
        lat: 13.124965047616813,
        lng: 80.29256810179403,
      },
      {
        lat: 13.128989239186026,
        lng: 80.29705523690019,
      },
      {
        lat: 13.12713860475355,
        lng: 80.29488613764369,
      },
      {
        lat: 13.122382129005135,
        lng: 80.29744096977183,
      },
      {
        lat: 13.128553102034914,
        lng: 80.29122154387655,
      },
      {
        lat: 13.12518021837816,
        lng: 80.29062845181565,
      },
      {
        lat: 13.127942363904134,
        lng: 80.29611115400965,
      },
      {
        lat: 13.124175055221722,
        lng: 80.29151085936667,
      },
      {
        lat: 13.122187013578099,
        lng: 80.29876293837832,
      },
      {
        lat: 13.124945566799974,
        lng: 80.29138910999866,
      },
      {
        lat: 13.121709182100455,
        lng: 80.29619617068099,
      },
      {
        lat: 13.1266579345544,
        lng: 80.29508157589926,
      },
      {
        lat: 13.12471144303809,
        lng: 80.29029806632319,
      },
      {
        lat: 13.12846851642768,
        lng: 80.29100581678264,
      },
      {
        lat: 13.128405576458993,
        lng: 80.2975746185277,
      },
      {
        lat: 13.131850348312202,
        lng: 80.2959604749917,
      },
      {
        lat: 13.121317338221369,
        lng: 80.29712745258041,
      },
      {
        lat: 13.126727777817207,
        lng: 80.2963672772744,
      },
      {
        lat: 13.122985644405366,
        lng: 80.2940385393999,
      },
      {
        lat: 13.128267535219472,
        lng: 80.2977765559771,
      },
      {
        lat: 13.121874137922138,
        lng: 80.29780941436572,
      },
      {
        lat: 13.12824227260077,
        lng: 80.29073364421292,
      },
      {
        lat: 13.127902675523078,
        lng: 80.29664730972202,
      },
      {
        lat: 13.12299700399443,
        lng: 80.29415302904881,
      },
      {
        lat: 13.122806519393498,
        lng: 80.2914560969733,
      },
      {
        lat: 13.124267414073259,
        lng: 80.29627595487015,
      },
      {
        lat: 13.124682407863373,
        lng: 80.2932566648282,
      },
    ],
    cases: 112,
    coords: [
      {
        lat: 13.13181475722759,
        lng: 80.29638143317167,
      },
      {
        lat: 13.130849195343636,
        lng: 80.29706717428151,
      },
      {
        lat: 13.13023995563388,
        lng: 80.29728629969085,
      },
      {
        lat: 13.129738024677753,
        lng: 80.29743319383306,
      },
      {
        lat: 13.12952492543784,
        lng: 80.29782727431571,
      },
      {
        lat: 13.129347089693693,
        lng: 80.29811407172643,
      },
      {
        lat: 13.12895246749063,
        lng: 80.29818873460168,
      },
      {
        lat: 13.125606158671818,
        lng: 80.29718287054571,
      },
      {
        lat: 13.125101624972153,
        lng: 80.29690184820457,
      },
      {
        lat: 13.12488462161792,
        lng: 80.296654053962,
      },
      {
        lat: 13.124560743301029,
        lng: 80.29654981040053,
      },
      {
        lat: 13.123880976600354,
        lng: 80.29698350195346,
      },
      {
        lat: 13.12331112139612,
        lng: 80.29777287872288,
      },
      {
        lat: 13.122958486441446,
        lng: 80.29884570944192,
      },
      {
        lat: 13.122745387201869,
        lng: 80.2992397899246,
      },
      {
        lat: 13.12210153468253,
        lng: 80.2996731775267,
      },
      {
        lat: 13.121053628990682,
        lng: 80.30002959059155,
      },
      {
        lat: 13.121015388279988,
        lng: 80.29938271532836,
      },
      {
        lat: 13.121030911353001,
        lng: 80.29879468991221,
      },
      {
        lat: 13.121159411680923,
        lng: 80.29794020780595,
      },
      {
        lat: 13.121192072614539,
        lng: 80.29702964384163,
      },
      {
        lat: 13.121034092837133,
        lng: 80.2961775862907,
      },
      {
        lat: 13.121029825184836,
        lng: 80.29547594242533,
      },
      {
        lat: 13.121270149347838,
        lng: 80.29416537015166,
      },
      {
        lat: 13.12145882924576,
        lng: 80.29378448682706,
      },
      {
        lat: 13.121800759900758,
        lng: 80.29347816379718,
      },
      {
        lat: 13.121912699077905,
        lng: 80.29304103697756,
      },
      {
        lat: 13.122367260962,
        lng: 80.29241136721437,
      },
      {
        lat: 13.12264993478268,
        lng: 80.29178315218444,
      },
      {
        lat: 13.122835500447625,
        lng: 80.29089025847153,
      },
      {
        lat: 13.122828695272345,
        lng: 80.28977142095658,
      },
      {
        lat: 13.122881146931443,
        lng: 80.28897447544148,
      },
      {
        lat: 13.123219040618052,
        lng: 80.28800443524152,
      },
      {
        lat: 13.123369984536449,
        lng: 80.28769972858191,
      },
      {
        lat: 13.123890041504158,
        lng: 80.28821134144133,
      },
      {
        lat: 13.124944884845668,
        lng: 80.28898443916262,
      },
      {
        lat: 13.127132420490147,
        lng: 80.29004872951288,
      },
      {
        lat: 13.130372604253838,
        lng: 80.29152520148583,
      },
      {
        lat: 13.131221727121515,
        lng: 80.29167843055099,
      },
      {
        lat: 13.132416220936468,
        lng: 80.29216961940296,
      },
      {
        lat: 13.13181475722759,
        lng: 80.29638143317167,
      },
    ],
    properties: {
      Ward_No: 43,
      Zone_Name: "TONDIARPET",
      fillColor: "#f6d04d",
      zname: "TONDIARPET(43)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.134310044977324,
        lng: 80.29625454996315,
      },
      {
        lat: 13.142837445044732,
        lng: 80.29021254899995,
      },
      {
        lat: 13.132876479820487,
        lng: 80.2939198476426,
      },
      {
        lat: 13.142915052585108,
        lng: 80.2952945745388,
      },
      {
        lat: 13.134121963506727,
        lng: 80.29545701709158,
      },
      {
        lat: 13.144319483524994,
        lng: 80.29355668874473,
      },
      {
        lat: 13.142421667394952,
        lng: 80.29479632141184,
      },
      {
        lat: 13.143152042192199,
        lng: 80.28941996046734,
      },
      {
        lat: 13.133098185393484,
        lng: 80.29495741682959,
      },
      {
        lat: 13.134634983745695,
        lng: 80.29665744393566,
      },
      {
        lat: 13.139964122119306,
        lng: 80.29665449074979,
      },
      {
        lat: 13.14454337834965,
        lng: 80.2898115627351,
      },
      {
        lat: 13.138818156990165,
        lng: 80.29491055331059,
      },
      {
        lat: 13.141512638417383,
        lng: 80.29796356722083,
      },
      {
        lat: 13.139791447403226,
        lng: 80.29208605129746,
      },
      {
        lat: 13.143109926158477,
        lng: 80.29119353141697,
      },
      {
        lat: 13.143332243744949,
        lng: 80.2913280003304,
      },
      {
        lat: 13.139192912103635,
        lng: 80.29124393152291,
      },
      {
        lat: 13.138762315910746,
        lng: 80.29663452096194,
      },
      {
        lat: 13.134138876571335,
        lng: 80.2952125841286,
      },
      {
        lat: 13.132850181148898,
        lng: 80.29273532718385,
      },
      {
        lat: 13.142213114020082,
        lng: 80.29285099573471,
      },
      {
        lat: 13.141313647478793,
        lng: 80.29687486061616,
      },
      {
        lat: 13.13967548267185,
        lng: 80.29660103374891,
      },
      {
        lat: 13.138376980103233,
        lng: 80.29411076752412,
      },
      {
        lat: 13.139616962724784,
        lng: 80.29835489555879,
      },
      {
        lat: 13.133576179915949,
        lng: 80.29407356470426,
      },
      {
        lat: 13.139471754459507,
        lng: 80.29252861170933,
      },
      {
        lat: 13.139175686746848,
        lng: 80.29591528273689,
      },
      {
        lat: 13.142448606787076,
        lng: 80.29516053529487,
      },
      {
        lat: 13.143552608101249,
        lng: 80.29623109526771,
      },
      {
        lat: 13.139103688183793,
        lng: 80.29701919481364,
      },
      {
        lat: 13.14316528818085,
        lng: 80.29879908750334,
      },
      {
        lat: 13.135411218390733,
        lng: 80.29316354843225,
      },
      {
        lat: 13.13603297790207,
        lng: 80.29469287782115,
      },
      {
        lat: 13.140427081205004,
        lng: 80.29740885496763,
      },
      {
        lat: 13.143226396716065,
        lng: 80.2934836008353,
      },
      {
        lat: 13.136731931668862,
        lng: 80.29484182034386,
      },
      {
        lat: 13.141376987589746,
        lng: 80.2883746349753,
      },
      {
        lat: 13.13546981587162,
        lng: 80.29706792853304,
      },
      {
        lat: 13.142881971754292,
        lng: 80.29395509438062,
      },
      {
        lat: 13.143113864321341,
        lng: 80.29007687744425,
      },
      {
        lat: 13.140385495744445,
        lng: 80.2929194273386,
      },
      {
        lat: 13.134698311378283,
        lng: 80.29623211345407,
      },
      {
        lat: 13.13689698774209,
        lng: 80.29370162216563,
      },
      {
        lat: 13.132214479171907,
        lng: 80.29514105200184,
      },
      {
        lat: 13.138481036195074,
        lng: 80.29364257779604,
      },
      {
        lat: 13.139094100207096,
        lng: 80.29213490057369,
      },
    ],
    cases: 48,
    coords: [
      {
        lat: 13.132416220936468,
        lng: 80.29216961940296,
      },
      {
        lat: 13.133320721882061,
        lng: 80.29250482113815,
      },
      {
        lat: 13.133757368934292,
        lng: 80.29265464604691,
      },
      {
        lat: 13.133982168351977,
        lng: 80.2926387871142,
      },
      {
        lat: 13.134079791282366,
        lng: 80.29251235333392,
      },
      {
        lat: 13.134192403200064,
        lng: 80.29253931306377,
      },
      {
        lat: 13.134606250947499,
        lng: 80.29269313093803,
      },
      {
        lat: 13.138859273668237,
        lng: 80.29350073851484,
      },
      {
        lat: 13.139079315887033,
        lng: 80.29158159894266,
      },
      {
        lat: 13.13924926782434,
        lng: 80.2898929565731,
      },
      {
        lat: 13.13965636474412,
        lng: 80.28909703658987,
      },
      {
        lat: 13.139831292030836,
        lng: 80.2882263904265,
      },
      {
        lat: 13.141146919951627,
        lng: 80.28864983878029,
      },
      {
        lat: 13.141554638790078,
        lng: 80.28795616832302,
      },
      {
        lat: 13.142845765749056,
        lng: 80.28858433361329,
      },
      {
        lat: 13.144802753182615,
        lng: 80.28984598299421,
      },
      {
        lat: 13.144475391714085,
        lng: 80.29051494380943,
      },
      {
        lat: 13.144301178121104,
        lng: 80.2913972583263,
      },
      {
        lat: 13.14423498903543,
        lng: 80.29232501860936,
      },
      {
        lat: 13.144527291076237,
        lng: 80.2931427602609,
      },
      {
        lat: 13.144675719496918,
        lng: 80.29392605800977,
      },
      {
        lat: 13.144399467705574,
        lng: 80.29574713467282,
      },
      {
        lat: 13.143764074101457,
        lng: 80.29757125084608,
      },
      {
        lat: 13.143592094529334,
        lng: 80.29882086034476,
      },
      {
        lat: 13.143379429080166,
        lng: 80.29928626024136,
      },
      {
        lat: 13.143128680497341,
        lng: 80.29939536701926,
      },
      {
        lat: 13.1424813576538,
        lng: 80.29925819931007,
      },
      {
        lat: 13.137517175516482,
        lng: 80.29798073539483,
      },
      {
        lat: 13.136905766854266,
        lng: 80.2978432637344,
      },
      {
        lat: 13.136296527144685,
        lng: 80.2980623891438,
      },
      {
        lat: 13.135757380635255,
        lng: 80.2979956289953,
      },
      {
        lat: 13.135110708477507,
        lng: 80.29796544040683,
      },
      {
        lat: 13.134893922018527,
        lng: 80.2977533058713,
      },
      {
        lat: 13.134675617292665,
        lng: 80.2972915533869,
      },
      {
        lat: 13.134171083593047,
        lng: 80.29701053104574,
      },
      {
        lat: 13.132155985328549,
        lng: 80.29638567757821,
      },
      {
        lat: 13.13181475722759,
        lng: 80.29638143317167,
      },
      {
        lat: 13.132416220936468,
        lng: 80.29216961940296,
      },
    ],
    properties: {
      Ward_No: 39,
      Zone_Name: "TONDIARPET",
      fillColor: "#8bc24c",
      zname: "TONDIARPET(39)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.138169365445904,
        lng: 80.29281253700617,
      },
      {
        lat: 13.13048950130041,
        lng: 80.2885424002155,
      },
      {
        lat: 13.131942218897228,
        lng: 80.28994715689441,
      },
      {
        lat: 13.130264054366576,
        lng: 80.28858795150656,
      },
      {
        lat: 13.127301451182468,
        lng: 80.29007322753756,
      },
      {
        lat: 13.132286513380608,
        lng: 80.29069287491043,
      },
      {
        lat: 13.139128082411654,
        lng: 80.28957510280361,
      },
      {
        lat: 13.129961034331199,
        lng: 80.29048194165391,
      },
      {
        lat: 13.132986642553306,
        lng: 80.28742525899004,
      },
      {
        lat: 13.130661160466396,
        lng: 80.29150873393598,
      },
      {
        lat: 13.135807081813857,
        lng: 80.28774629649189,
      },
      {
        lat: 13.128763717640215,
        lng: 80.28956446408569,
      },
      {
        lat: 13.12924371426894,
        lng: 80.28849997866523,
      },
      {
        lat: 13.128777569448056,
        lng: 80.28878139305253,
      },
      {
        lat: 13.134781511328399,
        lng: 80.28749781906347,
      },
      {
        lat: 13.136764679407593,
        lng: 80.29023276908815,
      },
      {
        lat: 13.132991877926855,
        lng: 80.2894139509993,
      },
      {
        lat: 13.132329056361145,
        lng: 80.2919085345498,
      },
      {
        lat: 13.132903210331623,
        lng: 80.28931002934684,
      },
      {
        lat: 13.133488478474526,
        lng: 80.29172765055782,
      },
      {
        lat: 13.13121504989209,
        lng: 80.29042315049914,
      },
      {
        lat: 13.133485593533901,
        lng: 80.28754902597527,
      },
      {
        lat: 13.133213424549378,
        lng: 80.28577518799536,
      },
      {
        lat: 13.133213076476716,
        lng: 80.28679357066984,
      },
      {
        lat: 13.137234657431996,
        lng: 80.29227607863355,
      },
      {
        lat: 13.13194182176185,
        lng: 80.28974856729894,
      },
      {
        lat: 13.132603344207787,
        lng: 80.29166987641709,
      },
      {
        lat: 13.134038454753192,
        lng: 80.29201879550511,
      },
      {
        lat: 13.12993627168721,
        lng: 80.28846766045481,
      },
      {
        lat: 13.134963386638978,
        lng: 80.29024226563675,
      },
      {
        lat: 13.132558404719815,
        lng: 80.28965431504719,
      },
      {
        lat: 13.135195404201896,
        lng: 80.29097909936117,
      },
      {
        lat: 13.135023055140072,
        lng: 80.28910625725182,
      },
      {
        lat: 13.129228265730873,
        lng: 80.28681913231371,
      },
      {
        lat: 13.128796410660007,
        lng: 80.28635139043183,
      },
      {
        lat: 13.131163069469256,
        lng: 80.28683607568966,
      },
      {
        lat: 13.136999198338293,
        lng: 80.29209928186438,
      },
      {
        lat: 13.128179005921739,
        lng: 80.2886628189031,
      },
      {
        lat: 13.136589692183286,
        lng: 80.29163068470184,
      },
      {
        lat: 13.127821326650349,
        lng: 80.288607952202,
      },
      {
        lat: 13.127860040631486,
        lng: 80.29016119814564,
      },
      {
        lat: 13.129338703077927,
        lng: 80.28861915903602,
      },
      {
        lat: 13.136765261590275,
        lng: 80.29068438291877,
      },
      {
        lat: 13.132515945537849,
        lng: 80.28613562379896,
      },
      {
        lat: 13.131364939714794,
        lng: 80.29007510428161,
      },
      {
        lat: 13.12944648620006,
        lng: 80.28578372056123,
      },
      {
        lat: 13.130342219523444,
        lng: 80.28992178601794,
      },
      {
        lat: 13.129309960857622,
        lng: 80.28636351382926,
      },
      {
        lat: 13.129407619534767,
        lng: 80.28557794709184,
      },
      {
        lat: 13.139495050454741,
        lng: 80.28902457810614,
      },
      {
        lat: 13.138558594324827,
        lng: 80.28963181457955,
      },
      {
        lat: 13.133237300734864,
        lng: 80.29174341118917,
      },
      {
        lat: 13.135366328230624,
        lng: 80.29192041511367,
      },
      {
        lat: 13.131912786656049,
        lng: 80.28941177248606,
      },
      {
        lat: 13.127792714378712,
        lng: 80.28849903349825,
      },
      {
        lat: 13.129414602216258,
        lng: 80.28902177113027,
      },
      {
        lat: 13.128884555393018,
        lng: 80.28647485207928,
      },
      {
        lat: 13.136767673409267,
        lng: 80.28895627161414,
      },
      {
        lat: 13.136099947663125,
        lng: 80.29243300115033,
      },
      {
        lat: 13.127753636152569,
        lng: 80.28952544423765,
      },
      {
        lat: 13.128683698540122,
        lng: 80.28610151174401,
      },
      {
        lat: 13.13235849832177,
        lng: 80.28775477509564,
      },
      {
        lat: 13.136859664639498,
        lng: 80.29016287437378,
      },
      {
        lat: 13.132187891174079,
        lng: 80.28790202043685,
      },
      {
        lat: 13.13125079683158,
        lng: 80.28598704582897,
      },
      {
        lat: 13.130355535102233,
        lng: 80.28724364814295,
      },
      {
        lat: 13.134245816134285,
        lng: 80.28899975552774,
      },
      {
        lat: 13.133809646609476,
        lng: 80.28793288269361,
      },
      {
        lat: 13.135775409502164,
        lng: 80.29168316768812,
      },
      {
        lat: 13.135517612247778,
        lng: 80.28834389523368,
      },
      {
        lat: 13.134955667489411,
        lng: 80.29054523058646,
      },
      {
        lat: 13.139624122638446,
        lng: 80.28915473903861,
      },
      {
        lat: 13.138763273573813,
        lng: 80.29334431646659,
      },
      {
        lat: 13.130346864062124,
        lng: 80.29089901537273,
      },
      {
        lat: 13.13472192115122,
        lng: 80.28633939978037,
      },
      {
        lat: 13.13735343244399,
        lng: 80.29304852200126,
      },
      {
        lat: 13.137517697435374,
        lng: 80.29138887394946,
      },
      {
        lat: 13.128952057521598,
        lng: 80.28836587481607,
      },
      {
        lat: 13.12908704327446,
        lng: 80.29089093028396,
      },
      {
        lat: 13.135747674182525,
        lng: 80.28917939564235,
      },
      {
        lat: 13.132820602303289,
        lng: 80.29104535582528,
      },
      {
        lat: 13.130013434814721,
        lng: 80.29021365107822,
      },
      {
        lat: 13.132745503170701,
        lng: 80.28565315359606,
      },
      {
        lat: 13.13348997037354,
        lng: 80.28872245816086,
      },
      {
        lat: 13.133150926605515,
        lng: 80.28859225781011,
      },
      {
        lat: 13.136863340008206,
        lng: 80.28816469007077,
      },
      {
        lat: 13.130424092696598,
        lng: 80.28805412023674,
      },
      {
        lat: 13.129619711255595,
        lng: 80.28832631237482,
      },
      {
        lat: 13.132047685467315,
        lng: 80.28921875547005,
      },
      {
        lat: 13.1342571212655,
        lng: 80.28759882268297,
      },
      {
        lat: 13.128629516387873,
        lng: 80.28643586497007,
      },
      {
        lat: 13.132810912470692,
        lng: 80.290599279787,
      },
      {
        lat: 13.136869965524914,
        lng: 80.2896316380752,
      },
      {
        lat: 13.131754592665077,
        lng: 80.29050517771455,
      },
      {
        lat: 13.132927555073323,
        lng: 80.28632848294473,
      },
      {
        lat: 13.139626258205505,
        lng: 80.28914113340254,
      },
      {
        lat: 13.135241456946245,
        lng: 80.29170501745325,
      },
      {
        lat: 13.133642060914088,
        lng: 80.28963867052504,
      },
      {
        lat: 13.135407240720896,
        lng: 80.29051014549513,
      },
      {
        lat: 13.132610161459667,
        lng: 80.28636636264233,
      },
      {
        lat: 13.133529499620684,
        lng: 80.29208303134031,
      },
      {
        lat: 13.137267293633922,
        lng: 80.28767814379898,
      },
      {
        lat: 13.137801485754212,
        lng: 80.28768881154566,
      },
      {
        lat: 13.134408829896739,
        lng: 80.29227022603708,
      },
      {
        lat: 13.134801088335049,
        lng: 80.28949664881216,
      },
      {
        lat: 13.129988009740996,
        lng: 80.29036045127012,
      },
      {
        lat: 13.129617473452662,
        lng: 80.28676290605709,
      },
      {
        lat: 13.137330264258647,
        lng: 80.28890426405606,
      },
      {
        lat: 13.13129299193362,
        lng: 80.28651323070535,
      },
      {
        lat: 13.136601163670617,
        lng: 80.29164717844709,
      },
      {
        lat: 13.137352466141724,
        lng: 80.2874341984404,
      },
      {
        lat: 13.130525919390292,
        lng: 80.2895864812106,
      },
      {
        lat: 13.13556715435551,
        lng: 80.28911051430748,
      },
      {
        lat: 13.130871320264912,
        lng: 80.29040213075048,
      },
      {
        lat: 13.137985825921382,
        lng: 80.29163268491686,
      },
      {
        lat: 13.131477177249144,
        lng: 80.28994513126123,
      },
      {
        lat: 13.133655685155405,
        lng: 80.29046358607496,
      },
      {
        lat: 13.13307162820639,
        lng: 80.29159940904331,
      },
      {
        lat: 13.128265400193978,
        lng: 80.28531876303224,
      },
      {
        lat: 13.1354399618717,
        lng: 80.28737699291837,
      },
      {
        lat: 13.128087981663246,
        lng: 80.28884727805818,
      },
      {
        lat: 13.134068245127379,
        lng: 80.2897047988739,
      },
      {
        lat: 13.135739263950581,
        lng: 80.2909189942594,
      },
      {
        lat: 13.137596541030971,
        lng: 80.28851374242633,
      },
      {
        lat: 13.139142320967496,
        lng: 80.28891450867259,
      },
    ],
    cases: 125,
    coords: [
      {
        lat: 13.128292400529023,
        lng: 80.28486551526034,
      },
      {
        lat: 13.132782137802527,
        lng: 80.28563329018365,
      },
      {
        lat: 13.13477101544283,
        lng: 80.28610364411281,
      },
      {
        lat: 13.134958489445845,
        lng: 80.28619485486536,
      },
      {
        lat: 13.13507784774124,
        lng: 80.28661143297627,
      },
      {
        lat: 13.135220005338818,
        lng: 80.28693502073736,
      },
      {
        lat: 13.135548084844011,
        lng: 80.28709463955414,
      },
      {
        lat: 13.137419015184571,
        lng: 80.28738039691463,
      },
      {
        lat: 13.139831292030836,
        lng: 80.2882263904265,
      },
      {
        lat: 13.13965636474412,
        lng: 80.28909703658987,
      },
      {
        lat: 13.13924926782434,
        lng: 80.2898929565731,
      },
      {
        lat: 13.139079315887033,
        lng: 80.29158159894266,
      },
      {
        lat: 13.138859273668237,
        lng: 80.29350073851484,
      },
      {
        lat: 13.134606250947499,
        lng: 80.29269313093803,
      },
      {
        lat: 13.134192403200064,
        lng: 80.29253931306377,
      },
      {
        lat: 13.134079791282366,
        lng: 80.29251235333392,
      },
      {
        lat: 13.133982168351977,
        lng: 80.2926387871142,
      },
      {
        lat: 13.133757368934292,
        lng: 80.29265464604691,
      },
      {
        lat: 13.133320721882061,
        lng: 80.29250482113815,
      },
      {
        lat: 13.132416220936468,
        lng: 80.29216961940296,
      },
      {
        lat: 13.131221727121515,
        lng: 80.29167843055099,
      },
      {
        lat: 13.130372604253838,
        lng: 80.29152520148583,
      },
      {
        lat: 13.127132420490147,
        lng: 80.29004872951288,
      },
      {
        lat: 13.127628001304645,
        lng: 80.28852058886889,
      },
      {
        lat: 13.128292400529023,
        lng: 80.28486551526034,
      },
    ],
    properties: {
      Ward_No: 40,
      Zone_Name: "TONDIARPET",
      fillColor: "#de4307",
      zname: "TONDIARPET(40)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.12197666183786,
        lng: 80.2767191516773,
      },
      {
        lat: 13.12607735533232,
        lng: 80.27502420062922,
      },
      {
        lat: 13.130464048021828,
        lng: 80.27165095773064,
      },
      {
        lat: 13.121095103007496,
        lng: 80.27714818520268,
      },
      {
        lat: 13.125019407789935,
        lng: 80.27881008211948,
      },
      {
        lat: 13.127501343244798,
        lng: 80.27058837655584,
      },
      {
        lat: 13.122397252557532,
        lng: 80.27721769701583,
      },
      {
        lat: 13.121701562641407,
        lng: 80.2783816237662,
      },
      {
        lat: 13.120221529340592,
        lng: 80.27224667112047,
      },
      {
        lat: 13.125415103187812,
        lng: 80.27828790081563,
      },
      {
        lat: 13.120017859248412,
        lng: 80.27426258475727,
      },
      {
        lat: 13.124091783693382,
        lng: 80.27431551172495,
      },
      {
        lat: 13.130556840390998,
        lng: 80.2745385344529,
      },
      {
        lat: 13.121665241971943,
        lng: 80.2726359611489,
      },
      {
        lat: 13.127509025408065,
        lng: 80.2735635088238,
      },
      {
        lat: 13.126812405296533,
        lng: 80.27885269455768,
      },
      {
        lat: 13.12705949398167,
        lng: 80.27313206029527,
      },
      {
        lat: 13.127082627136193,
        lng: 80.27185475140135,
      },
      {
        lat: 13.125445305459046,
        lng: 80.2747310917713,
      },
      {
        lat: 13.1257918334282,
        lng: 80.27484658179623,
      },
      {
        lat: 13.1265456219207,
        lng: 80.28073761627068,
      },
      {
        lat: 13.127003867578907,
        lng: 80.2728485633791,
      },
      {
        lat: 13.125678271506983,
        lng: 80.27804596801134,
      },
    ],
    cases: 23,
    coords: [
      {
        lat: 13.120668829662788,
        lng: 80.27865774029475,
      },
      {
        lat: 13.119408068726996,
        lng: 80.27823463529639,
      },
      {
        lat: 13.118969954213227,
        lng: 80.27803013110356,
      },
      {
        lat: 13.118863000061786,
        lng: 80.27768401616805,
      },
      {
        lat: 13.118913733365991,
        lng: 80.27740597070618,
      },
      {
        lat: 13.119274565097347,
        lng: 80.27639655855683,
      },
      {
        lat: 13.119772233408293,
        lng: 80.27490016016186,
      },
      {
        lat: 13.119856753738743,
        lng: 80.27443096768748,
      },
      {
        lat: 13.119906853864736,
        lng: 80.27404882154957,
      },
      {
        lat: 13.119955054456769,
        lng: 80.27335437338324,
      },
      {
        lat: 13.120144418345019,
        lng: 80.27288429359082,
      },
      {
        lat: 13.120162006502735,
        lng: 80.2721635430654,
      },
      {
        lat: 13.12516073863823,
        lng: 80.27337893806032,
      },
      {
        lat: 13.126273306992253,
        lng: 80.27190796315666,
      },
      {
        lat: 13.126038682289403,
        lng: 80.27174755340741,
      },
      {
        lat: 13.126959120354199,
        lng: 80.26941982866128,
      },
      {
        lat: 13.130710575807226,
        lng: 80.27156881787066,
      },
      {
        lat: 13.130748613864448,
        lng: 80.2739812281785,
      },
      {
        lat: 13.13058972424653,
        lng: 80.27474815139973,
      },
      {
        lat: 13.126423366166431,
        lng: 80.27353064755498,
      },
      {
        lat: 13.126309228555625,
        lng: 80.27397240115073,
      },
      {
        lat: 13.12622790831733,
        lng: 80.27596823334747,
      },
      {
        lat: 13.125955163287838,
        lng: 80.27722330647299,
      },
      {
        lat: 13.125160233070005,
        lng: 80.27713723678008,
      },
      {
        lat: 13.124528707656305,
        lng: 80.27702658480142,
      },
      {
        lat: 13.124249472046099,
        lng: 80.27721454283187,
      },
      {
        lat: 13.124321115243331,
        lng: 80.27746912932935,
      },
      {
        lat: 13.124602749546433,
        lng: 80.27767553992086,
      },
      {
        lat: 13.125257779760057,
        lng: 80.2778091923207,
      },
      {
        lat: 13.128182193120539,
        lng: 80.27843402400723,
      },
      {
        lat: 13.128463827423644,
        lng: 80.27864043459869,
      },
      {
        lat: 13.128537022716277,
        lng: 80.27915020079277,
      },
      {
        lat: 13.128063740252736,
        lng: 80.28200772615827,
      },
      {
        lat: 13.120668829662788,
        lng: 80.27865774029475,
      },
    ],
    properties: {
      Ward_No: 41,
      Zone_Name: "TONDIARPET",
      fillColor: "#8bc24c",
      zname: "TONDIARPET(41)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.127152541749386,
        lng: 80.2755501766235,
      },
      {
        lat: 13.13194040682499,
        lng: 80.28063162761242,
      },
      {
        lat: 13.14428903697039,
        lng: 80.28261049157199,
      },
      {
        lat: 13.137689001847523,
        lng: 80.2819335573535,
      },
      {
        lat: 13.135487965939024,
        lng: 80.2826726240949,
      },
      {
        lat: 13.130644542599002,
        lng: 80.27589726204928,
      },
      {
        lat: 13.120440951111942,
        lng: 80.2794791258488,
      },
      {
        lat: 13.129906201777189,
        lng: 80.27771330844202,
      },
      {
        lat: 13.133984578327532,
        lng: 80.27969645033879,
      },
      {
        lat: 13.140974633500663,
        lng: 80.28390587892929,
      },
      {
        lat: 13.144071471423086,
        lng: 80.28851464449573,
      },
      {
        lat: 13.140047828190793,
        lng: 80.28408404164489,
      },
      {
        lat: 13.14527320745029,
        lng: 80.28748897939084,
      },
      {
        lat: 13.136379207913148,
        lng: 80.27792389730605,
      },
      {
        lat: 13.132018949776834,
        lng: 80.27904465196669,
      },
      {
        lat: 13.124719045406964,
        lng: 80.28262147699319,
      },
      {
        lat: 13.144127776172676,
        lng: 80.28289774750209,
      },
      {
        lat: 13.135643198110516,
        lng: 80.28213546970825,
      },
      {
        lat: 13.127725875630551,
        lng: 80.27781911395091,
      },
      {
        lat: 13.126474021251232,
        lng: 80.2767363367752,
      },
      {
        lat: 13.12643826886275,
        lng: 80.2819367513865,
      },
      {
        lat: 13.14056718225578,
        lng: 80.28230153164085,
      },
      {
        lat: 13.123816296364437,
        lng: 80.28129918723666,
      },
      {
        lat: 13.129553351741954,
        lng: 80.2824367126649,
      },
      {
        lat: 13.130768125607442,
        lng: 80.28098166085567,
      },
      {
        lat: 13.12593983428061,
        lng: 80.28269114168333,
      },
      {
        lat: 13.1288314697681,
        lng: 80.27447969737132,
      },
      {
        lat: 13.121221954627025,
        lng: 80.27952645803221,
      },
      {
        lat: 13.14416026067996,
        lng: 80.28604505141986,
      },
      {
        lat: 13.137304444636564,
        lng: 80.28413184847874,
      },
      {
        lat: 13.136351582549208,
        lng: 80.28468447496255,
      },
      {
        lat: 13.130386510078942,
        lng: 80.2795130477246,
      },
      {
        lat: 13.14528797908461,
        lng: 80.28475386628364,
      },
      {
        lat: 13.144124665413178,
        lng: 80.28326556727464,
      },
      {
        lat: 13.143608249275516,
        lng: 80.28175205935204,
      },
      {
        lat: 13.139965228456273,
        lng: 80.27844753261276,
      },
      {
        lat: 13.13796822817243,
        lng: 80.28474150549714,
      },
      {
        lat: 13.1219504939457,
        lng: 80.28124504058725,
      },
      {
        lat: 13.128059205906435,
        lng: 80.27581534266727,
      },
    ],
    cases: 39,
    coords: [
      {
        lat: 13.13058972424653,
        lng: 80.27474815139973,
      },
      {
        lat: 13.135083335763245,
        lng: 80.27612726905357,
      },
      {
        lat: 13.138466189402122,
        lng: 80.27710453370345,
      },
      {
        lat: 13.142182161357912,
        lng: 80.27853324307141,
      },
      {
        lat: 13.142358876899246,
        lng: 80.27912116279226,
      },
      {
        lat: 13.144499069328365,
        lng: 80.28259788096325,
      },
      {
        lat: 13.145585387471806,
        lng: 80.2840508104175,
      },
      {
        lat: 13.14594973477047,
        lng: 80.28490360387421,
      },
      {
        lat: 13.146207424002032,
        lng: 80.28593560771868,
      },
      {
        lat: 13.14624832677422,
        lng: 80.28675547702768,
      },
      {
        lat: 13.145929870838545,
        lng: 80.28754272613992,
      },
      {
        lat: 13.144802753182615,
        lng: 80.28984598299421,
      },
      {
        lat: 13.142845765749056,
        lng: 80.28858433361329,
      },
      {
        lat: 13.141554638790078,
        lng: 80.28795616832302,
      },
      {
        lat: 13.141146919951627,
        lng: 80.28864983878029,
      },
      {
        lat: 13.139831292030836,
        lng: 80.2882263904265,
      },
      {
        lat: 13.137419015184571,
        lng: 80.28738039691463,
      },
      {
        lat: 13.135548084844011,
        lng: 80.28709463955414,
      },
      {
        lat: 13.135220005338818,
        lng: 80.28693502073736,
      },
      {
        lat: 13.13507784774124,
        lng: 80.28661143297627,
      },
      {
        lat: 13.134958489445845,
        lng: 80.28619485486536,
      },
      {
        lat: 13.13477101544283,
        lng: 80.28610364411281,
      },
      {
        lat: 13.132782137802527,
        lng: 80.28563329018365,
      },
      {
        lat: 13.128292400529023,
        lng: 80.28486551526034,
      },
      {
        lat: 13.125340176645887,
        lng: 80.28424883904829,
      },
      {
        lat: 13.124531197827038,
        lng: 80.28405516638068,
      },
      {
        lat: 13.124105416825138,
        lng: 80.28377804290449,
      },
      {
        lat: 13.12355578888585,
        lng: 80.28306082519381,
      },
      {
        lat: 13.12248026373578,
        lng: 80.28220769480205,
      },
      {
        lat: 13.122197060170912,
        lng: 80.28212988392107,
      },
      {
        lat: 13.121730037508756,
        lng: 80.28171274599825,
      },
      {
        lat: 13.11986000762796,
        lng: 80.2807120042504,
      },
      {
        lat: 13.120668829662788,
        lng: 80.27865774029475,
      },
      {
        lat: 13.128063740252736,
        lng: 80.28200772615827,
      },
      {
        lat: 13.128537022716277,
        lng: 80.27915020079277,
      },
      {
        lat: 13.128463827423644,
        lng: 80.27864043459869,
      },
      {
        lat: 13.128182193120539,
        lng: 80.27843402400723,
      },
      {
        lat: 13.125257779760057,
        lng: 80.2778091923207,
      },
      {
        lat: 13.124602749546433,
        lng: 80.27767553992086,
      },
      {
        lat: 13.124321115243331,
        lng: 80.27746912932935,
      },
      {
        lat: 13.124249472046099,
        lng: 80.27721454283187,
      },
      {
        lat: 13.124528707656305,
        lng: 80.27702658480142,
      },
      {
        lat: 13.125160233070005,
        lng: 80.27713723678008,
      },
      {
        lat: 13.125955163287838,
        lng: 80.27722330647299,
      },
      {
        lat: 13.12622790831733,
        lng: 80.27596823334747,
      },
      {
        lat: 13.126309228555625,
        lng: 80.27397240115073,
      },
      {
        lat: 13.126423366166431,
        lng: 80.27353064755498,
      },
      {
        lat: 13.13058972424653,
        lng: 80.27474815139973,
      },
    ],
    properties: {
      Ward_No: 38,
      Zone_Name: "TONDIARPET",
      fillColor: "#8bc24c",
      zname: "TONDIARPET(38)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.123663470030483,
        lng: 80.21779777123524,
      },
      {
        lat: 13.129314702303853,
        lng: 80.21599641678313,
      },
      {
        lat: 13.126043478937126,
        lng: 80.21158363183315,
      },
      {
        lat: 13.12820417153178,
        lng: 80.21926934025734,
      },
      {
        lat: 13.129857889170088,
        lng: 80.22128982733972,
      },
      {
        lat: 13.126847883906041,
        lng: 80.21804940673222,
      },
      {
        lat: 13.120955047794956,
        lng: 80.21597425157327,
      },
      {
        lat: 13.124744192401456,
        lng: 80.20845559232932,
      },
      {
        lat: 13.12886746787781,
        lng: 80.22179259862713,
      },
      {
        lat: 13.130040863477443,
        lng: 80.21499827811151,
      },
      {
        lat: 13.122688683590972,
        lng: 80.2114604694882,
      },
      {
        lat: 13.118111559555096,
        lng: 80.21021308106306,
      },
      {
        lat: 13.12122097853877,
        lng: 80.21417297758009,
      },
      {
        lat: 13.122203205551418,
        lng: 80.21529498966082,
      },
      {
        lat: 13.118673884169429,
        lng: 80.22304195825996,
      },
      {
        lat: 13.118366496310232,
        lng: 80.2201076102802,
      },
      {
        lat: 13.124630384341023,
        lng: 80.21386175330349,
      },
      {
        lat: 13.12126837896794,
        lng: 80.21500905807598,
      },
      {
        lat: 13.12149559505158,
        lng: 80.21977192323514,
      },
      {
        lat: 13.1298811517903,
        lng: 80.22016551802592,
      },
      {
        lat: 13.121013685121486,
        lng: 80.21202022852457,
      },
      {
        lat: 13.119802056990336,
        lng: 80.2222252186187,
      },
      {
        lat: 13.122075922030572,
        lng: 80.21766900274659,
      },
      {
        lat: 13.117723952934753,
        lng: 80.22247816024067,
      },
      {
        lat: 13.12914294339291,
        lng: 80.22261653122872,
      },
      {
        lat: 13.127183266715212,
        lng: 80.22201875229692,
      },
      {
        lat: 13.120014926476934,
        lng: 80.2119820044682,
      },
      {
        lat: 13.12035520840138,
        lng: 80.2207086356839,
      },
      {
        lat: 13.12024069730588,
        lng: 80.2177604368008,
      },
      {
        lat: 13.122668901503038,
        lng: 80.21185633864162,
      },
      {
        lat: 13.118811409566241,
        lng: 80.21122185308283,
      },
      {
        lat: 13.121264130271818,
        lng: 80.21895659420734,
      },
      {
        lat: 13.125486130279965,
        lng: 80.22342717765297,
      },
      {
        lat: 13.123944514714708,
        lng: 80.21491587468962,
      },
      {
        lat: 13.117625537362166,
        lng: 80.21292605548577,
      },
      {
        lat: 13.1207999345297,
        lng: 80.21455819432914,
      },
      {
        lat: 13.127301395063785,
        lng: 80.20990550226777,
      },
      {
        lat: 13.12041087909757,
        lng: 80.22001315609691,
      },
      {
        lat: 13.123185138725145,
        lng: 80.21121424405382,
      },
      {
        lat: 13.120571062785704,
        lng: 80.2195431278861,
      },
      {
        lat: 13.127079289371672,
        lng: 80.2246935441939,
      },
      {
        lat: 13.122619868232388,
        lng: 80.20748420238216,
      },
      {
        lat: 13.124333350941304,
        lng: 80.21703047365368,
      },
      {
        lat: 13.126959103014183,
        lng: 80.22133481194753,
      },
      {
        lat: 13.122598533843826,
        lng: 80.21787322758637,
      },
      {
        lat: 13.122301800537194,
        lng: 80.21429170927205,
      },
      {
        lat: 13.126037496740507,
        lng: 80.21271997515325,
      },
      {
        lat: 13.128632318138193,
        lng: 80.218005446467,
      },
      {
        lat: 13.118170579724886,
        lng: 80.21934581585688,
      },
      {
        lat: 13.121130590962048,
        lng: 80.21420293587927,
      },
      {
        lat: 13.127591165849076,
        lng: 80.21445303298582,
      },
      {
        lat: 13.125493253742896,
        lng: 80.20885537368906,
      },
      {
        lat: 13.128798641041861,
        lng: 80.22067477131475,
      },
      {
        lat: 13.122141161998137,
        lng: 80.21626996321773,
      },
      {
        lat: 13.124284149514828,
        lng: 80.21387020359184,
      },
      {
        lat: 13.123354910559575,
        lng: 80.2183122484274,
      },
      {
        lat: 13.118885448646415,
        lng: 80.2150385072336,
      },
    ],
    cases: 57,
    coords: [
      {
        lat: 13.126859310991238,
        lng: 80.22491131324071,
      },
      {
        lat: 13.125118625848717,
        lng: 80.22506635499066,
      },
      {
        lat: 13.124701789634067,
        lng: 80.2248635538331,
      },
      {
        lat: 13.124362718052708,
        lng: 80.22463430341466,
      },
      {
        lat: 13.123322196144846,
        lng: 80.22438519843323,
      },
      {
        lat: 13.121554720667241,
        lng: 80.22419382808603,
      },
      {
        lat: 13.121501361237575,
        lng: 80.22396215961156,
      },
      {
        lat: 13.121138198178306,
        lng: 80.22404260651105,
      },
      {
        lat: 13.120644224782207,
        lng: 80.22396941377966,
      },
      {
        lat: 13.119630617883455,
        lng: 80.22387482772245,
      },
      {
        lat: 13.118669899825848,
        lng: 80.223934540766,
      },
      {
        lat: 13.11684671197572,
        lng: 80.22312465507561,
      },
      {
        lat: 13.118000475580603,
        lng: 80.2206389430692,
      },
      {
        lat: 13.118045835004532,
        lng: 80.21955533219057,
      },
      {
        lat: 13.118041442844522,
        lng: 80.21883321803585,
      },
      {
        lat: 13.117909534467454,
        lng: 80.21849904986607,
      },
      {
        lat: 13.117545587093955,
        lng: 80.21845054780961,
      },
      {
        lat: 13.11746248912368,
        lng: 80.2176001441685,
      },
      {
        lat: 13.11748218844135,
        lng: 80.21656833269563,
      },
      {
        lat: 13.117347299669992,
        lng: 80.21574415849233,
      },
      {
        lat: 13.117293626514556,
        lng: 80.21546091043525,
      },
      {
        lat: 13.117265142876866,
        lng: 80.21504849359859,
      },
      {
        lat: 13.117392659093921,
        lng: 80.21466054761369,
      },
      {
        lat: 13.117260750716856,
        lng: 80.2143263794439,
      },
      {
        lat: 13.117100672427808,
        lng: 80.21363137402007,
      },
      {
        lat: 13.116864084408617,
        lng: 80.2131691361872,
      },
      {
        lat: 13.11675203221208,
        lng: 80.21182894633588,
      },
      {
        lat: 13.1166983590567,
        lng: 80.21154569827874,
      },
      {
        lat: 13.11693024119028,
        lng: 80.21123424237449,
      },
      {
        lat: 13.117395260360171,
        lng: 80.21081764889563,
      },
      {
        lat: 13.117859652078753,
        lng: 80.21029789625194,
      },
      {
        lat: 13.118063364300175,
        lng: 80.20962560309339,
      },
      {
        lat: 13.118603010846153,
        lng: 80.2086667645287,
      },
      {
        lat: 13.118861337400157,
        lng: 80.20843245817493,
      },
      {
        lat: 13.119121546308564,
        lng: 80.20850762931568,
      },
      {
        lat: 13.122109321300753,
        lng: 80.20861129859522,
      },
      {
        lat: 13.122151072879015,
        lng: 80.20693452251813,
      },
      {
        lat: 13.124540194832697,
        lng: 80.20683692940298,
      },
      {
        lat: 13.125864546538077,
        lng: 80.20677413883341,
      },
      {
        lat: 13.129225118626056,
        lng: 80.21265186364812,
      },
      {
        lat: 13.13026045942402,
        lng: 80.21414657855199,
      },
      {
        lat: 13.130716890887328,
        lng: 80.2145524847337,
      },
      {
        lat: 13.130969229350434,
        lng: 80.21532891037926,
      },
      {
        lat: 13.130974463045545,
        lng: 80.21618938115245,
      },
      {
        lat: 13.130325402866282,
        lng: 80.21803883517565,
      },
      {
        lat: 13.130082534899264,
        lng: 80.21881945188164,
      },
      {
        lat: 13.130008723319326,
        lng: 80.22025426834715,
      },
      {
        lat: 13.129944631459159,
        lng: 80.22328710196271,
      },
      {
        lat: 13.129580703344049,
        lng: 80.22451948921982,
      },
      {
        lat: 13.12912875790524,
        lng: 80.22485112941492,
      },
      {
        lat: 13.128262146652412,
        lng: 80.22485846377074,
      },
      {
        lat: 13.126859310991238,
        lng: 80.22491131324071,
      },
    ],
    properties: {
      Ward_No: 64,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#8bc24c",
      zname: "THIRU-VI-KA-NAGAR(64)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.120185837709663,
        lng: 80.2029512592929,
      },
      {
        lat: 13.115504524446374,
        lng: 80.20997965508339,
      },
      {
        lat: 13.114759224179469,
        lng: 80.20873899054628,
      },
      {
        lat: 13.113481617725926,
        lng: 80.21910991985656,
      },
      {
        lat: 13.116805996366557,
        lng: 80.20908448566156,
      },
      {
        lat: 13.128255192455113,
        lng: 80.20246077527457,
      },
      {
        lat: 13.127070484507986,
        lng: 80.20314067045234,
      },
      {
        lat: 13.113807934062734,
        lng: 80.21014563370748,
      },
      {
        lat: 13.113117763933376,
        lng: 80.21714811175937,
      },
      {
        lat: 13.127555274111568,
        lng: 80.20653238120843,
      },
      {
        lat: 13.125008718167333,
        lng: 80.20275056973036,
      },
      {
        lat: 13.117094179356691,
        lng: 80.21383486037307,
      },
      {
        lat: 13.121750660958408,
        lng: 80.20223480272539,
      },
      {
        lat: 13.114009127372515,
        lng: 80.21015280734892,
      },
      {
        lat: 13.126498887066791,
        lng: 80.20551192589528,
      },
      {
        lat: 13.11331028685144,
        lng: 80.21221267481953,
      },
      {
        lat: 13.122796573481626,
        lng: 80.20590263523569,
      },
      {
        lat: 13.121702622371572,
        lng: 80.20283471233272,
      },
      {
        lat: 13.13020349432888,
        lng: 80.21190844871781,
      },
      {
        lat: 13.110967045973824,
        lng: 80.21157484916375,
      },
      {
        lat: 13.128587821035262,
        lng: 80.21009372800916,
      },
      {
        lat: 13.124724036451969,
        lng: 80.20528451342776,
      },
      {
        lat: 13.112573222673573,
        lng: 80.20799244998184,
      },
      {
        lat: 13.113712587585518,
        lng: 80.2089898477293,
      },
      {
        lat: 13.116717103769384,
        lng: 80.20878336332171,
      },
      {
        lat: 13.12169532479957,
        lng: 80.20407754017202,
      },
      {
        lat: 13.11233524392575,
        lng: 80.21347434954257,
      },
      {
        lat: 13.124780933248806,
        lng: 80.20298644311653,
      },
      {
        lat: 13.127082739807923,
        lng: 80.20389677028173,
      },
      {
        lat: 13.12069589040309,
        lng: 80.20467170227039,
      },
      {
        lat: 13.113937801012442,
        lng: 80.21035062164178,
      },
      {
        lat: 13.114942307974855,
        lng: 80.21066671608388,
      },
      {
        lat: 13.118520375920053,
        lng: 80.2079833596567,
      },
      {
        lat: 13.11383769225649,
        lng: 80.20789667806514,
      },
      {
        lat: 13.11892440584364,
        lng: 80.20415267999348,
      },
      {
        lat: 13.120640777641656,
        lng: 80.2080080071335,
      },
      {
        lat: 13.112551606714323,
        lng: 80.21076824136382,
      },
      {
        lat: 13.12060294224714,
        lng: 80.20607439680288,
      },
      {
        lat: 13.124587238852016,
        lng: 80.20674003807677,
      },
      {
        lat: 13.113069826139416,
        lng: 80.2138171558702,
      },
      {
        lat: 13.120609348425967,
        lng: 80.20778228099596,
      },
      {
        lat: 13.121842708326064,
        lng: 80.20661367804846,
      },
      {
        lat: 13.125117309023548,
        lng: 80.20569205407142,
      },
      {
        lat: 13.127241898648206,
        lng: 80.20803620768612,
      },
      {
        lat: 13.126261563224181,
        lng: 80.2061065278613,
      },
      {
        lat: 13.129854781245209,
        lng: 80.21273432448973,
      },
      {
        lat: 13.11669809394364,
        lng: 80.21670652109016,
      },
      {
        lat: 13.11144390272092,
        lng: 80.21848104646655,
      },
      {
        lat: 13.120566061311612,
        lng: 80.20459486129836,
      },
      {
        lat: 13.123520010066676,
        lng: 80.20314598175302,
      },
      {
        lat: 13.128631434248904,
        lng: 80.2098527520072,
      },
      {
        lat: 13.119179197312558,
        lng: 80.20699089681531,
      },
      {
        lat: 13.114078355082395,
        lng: 80.2099288944836,
      },
      {
        lat: 13.122592123682688,
        lng: 80.20610484251033,
      },
      {
        lat: 13.116029160436922,
        lng: 80.20920020602333,
      },
      {
        lat: 13.128888920448686,
        lng: 80.2113846392397,
      },
      {
        lat: 13.125421480553618,
        lng: 80.20572978839722,
      },
      {
        lat: 13.112428729678605,
        lng: 80.2157761602612,
      },
      {
        lat: 13.11210542098791,
        lng: 80.21745646671428,
      },
      {
        lat: 13.12044789748884,
        lng: 80.20324404928873,
      },
      {
        lat: 13.114957848725638,
        lng: 80.21480175680199,
      },
      {
        lat: 13.114952552511598,
        lng: 80.20864565406963,
      },
      {
        lat: 13.112343014622557,
        lng: 80.21765028601162,
      },
      {
        lat: 13.11298217811722,
        lng: 80.21730380432709,
      },
      {
        lat: 13.12009284118379,
        lng: 80.20463796888322,
      },
      {
        lat: 13.116068593556326,
        lng: 80.21426165720048,
      },
      {
        lat: 13.115325205997898,
        lng: 80.21412299212317,
      },
      {
        lat: 13.117069151379532,
        lng: 80.21377394839875,
      },
      {
        lat: 13.117009749963671,
        lng: 80.21007211222631,
      },
      {
        lat: 13.129493129613413,
        lng: 80.2103865280477,
      },
      {
        lat: 13.113039435327282,
        lng: 80.20778499030936,
      },
      {
        lat: 13.111772893770794,
        lng: 80.21770798581015,
      },
      {
        lat: 13.112081374210373,
        lng: 80.20962871438802,
      },
      {
        lat: 13.122538935848805,
        lng: 80.20418763170737,
      },
      {
        lat: 13.127613789563018,
        lng: 80.20357254094861,
      },
      {
        lat: 13.11652971772909,
        lng: 80.21375867528035,
      },
      {
        lat: 13.125700243528176,
        lng: 80.20382948923412,
      },
      {
        lat: 13.118639842039679,
        lng: 80.20765148125773,
      },
      {
        lat: 13.111888505899541,
        lng: 80.2126769120658,
      },
      {
        lat: 13.125528724373394,
        lng: 80.20316114734929,
      },
      {
        lat: 13.116228529925879,
        lng: 80.21283752048485,
      },
      {
        lat: 13.113018431813574,
        lng: 80.21018199197685,
      },
      {
        lat: 13.11183600369118,
        lng: 80.20900057570914,
      },
      {
        lat: 13.110978404105753,
        lng: 80.21135112873914,
      },
      {
        lat: 13.11700145589569,
        lng: 80.20925928372648,
      },
      {
        lat: 13.119351996844834,
        lng: 80.20465005314898,
      },
      {
        lat: 13.120730026905282,
        lng: 80.20244040790207,
      },
      {
        lat: 13.111774168443201,
        lng: 80.21196194461328,
      },
      {
        lat: 13.115272939165582,
        lng: 80.20827322796603,
      },
      {
        lat: 13.119265324153144,
        lng: 80.20584303247149,
      },
      {
        lat: 13.11222536145599,
        lng: 80.21655037135423,
      },
      {
        lat: 13.115693907652012,
        lng: 80.21684157924487,
      },
      {
        lat: 13.129213266082804,
        lng: 80.2108826199265,
      },
      {
        lat: 13.125584100907703,
        lng: 80.20395356468143,
      },
      {
        lat: 13.130125858475273,
        lng: 80.21181275392682,
      },
      {
        lat: 13.124951636536656,
        lng: 80.20458948089977,
      },
      {
        lat: 13.113819251679079,
        lng: 80.21011700666755,
      },
      {
        lat: 13.111852891862483,
        lng: 80.20761924671436,
      },
      {
        lat: 13.128866226637205,
        lng: 80.20224603382145,
      },
      {
        lat: 13.110822137248629,
        lng: 80.2135455962833,
      },
      {
        lat: 13.114968810355093,
        lng: 80.21533284712586,
      },
      {
        lat: 13.11145660790355,
        lng: 80.21282103354925,
      },
      {
        lat: 13.11305448771265,
        lng: 80.21598933436242,
      },
      {
        lat: 13.115722933695979,
        lng: 80.21025422062456,
      },
      {
        lat: 13.125361239039158,
        lng: 80.20676073119526,
      },
      {
        lat: 13.11099286639107,
        lng: 80.2075233572789,
      },
      {
        lat: 13.126174276600956,
        lng: 80.20403842354845,
      },
      {
        lat: 13.116504994040135,
        lng: 80.20869093187494,
      },
    ],
    cases: 108,
    coords: [
      {
        lat: 13.118045835004532,
        lng: 80.21955533219057,
      },
      {
        lat: 13.11747362633737,
        lng: 80.2194746270962,
      },
      {
        lat: 13.116418313281121,
        lng: 80.21940874151235,
      },
      {
        lat: 13.115966686314936,
        lng: 80.21948738072044,
      },
      {
        lat: 13.114252655799527,
        lng: 80.21952059124824,
      },
      {
        lat: 13.114046361627283,
        lng: 80.21967197111309,
      },
      {
        lat: 13.11384018121556,
        lng: 80.21984205425811,
      },
      {
        lat: 13.113613798931706,
        lng: 80.21978785746126,
      },
      {
        lat: 13.113499754588814,
        lng: 80.21962048446127,
      },
      {
        lat: 13.113441537936156,
        lng: 80.21934041351919,
      },
      {
        lat: 13.113309339403623,
        lng: 80.21928541962079,
      },
      {
        lat: 13.11140534874371,
        lng: 80.21905837842877,
      },
      {
        lat: 13.11119734816976,
        lng: 80.21892920909056,
      },
      {
        lat: 13.111120522286946,
        lng: 80.2186867041292,
      },
      {
        lat: 13.111230243744934,
        lng: 80.21814335248169,
      },
      {
        lat: 13.111321242212648,
        lng: 80.21761886353467,
      },
      {
        lat: 13.111149663777814,
        lng: 80.21728363927383,
      },
      {
        lat: 13.11111017011521,
        lng: 80.21698470563115,
      },
      {
        lat: 13.11110732611199,
        lng: 80.2165171236261,
      },
      {
        lat: 13.111347084659831,
        lng: 80.21567340355342,
      },
      {
        lat: 13.111599764431324,
        lng: 80.21385695348985,
      },
      {
        lat: 13.11012484071358,
        lng: 80.21361424320284,
      },
      {
        lat: 13.110197601700351,
        lng: 80.21324113372246,
      },
      {
        lat: 13.110032777511023,
        lng: 80.212143343445,
      },
      {
        lat: 13.109771159901896,
        lng: 80.21073231943345,
      },
      {
        lat: 13.110038046110876,
        lng: 80.20780936853087,
      },
      {
        lat: 13.110064898675663,
        lng: 80.20702400896558,
      },
      {
        lat: 13.110506159501012,
        lng: 80.20676903212305,
      },
      {
        lat: 13.111107087285118,
        lng: 80.20676394631536,
      },
      {
        lat: 13.111994193140763,
        lng: 80.20700768084619,
      },
      {
        lat: 13.114461541850943,
        lng: 80.20704960961854,
      },
      {
        lat: 13.115536122272594,
        lng: 80.20691489399708,
      },
      {
        lat: 13.116293469870566,
        lng: 80.20662583673796,
      },
      {
        lat: 13.117495325439021,
        lng: 80.20661566512263,
      },
      {
        lat: 13.11812902705257,
        lng: 80.20679873369471,
      },
      {
        lat: 13.118666890289065,
        lng: 80.20682558691095,
      },
      {
        lat: 13.118855892923607,
        lng: 80.20669836616376,
      },
      {
        lat: 13.118854746872382,
        lng: 80.2065099441099,
      },
      {
        lat: 13.118819871949084,
        lng: 80.20597634929784,
      },
      {
        lat: 13.11865695784513,
        lng: 80.20519259577706,
      },
      {
        lat: 13.118652373640233,
        lng: 80.2044389075614,
      },
      {
        lat: 13.118642854145952,
        lng: 80.20287380939715,
      },
      {
        lat: 13.118925353598906,
        lng: 80.20287141853105,
      },
      {
        lat: 13.119418815463764,
        lng: 80.20258040387944,
      },
      {
        lat: 13.119912526552378,
        lng: 80.20233036402661,
      },
      {
        lat: 13.120365967332651,
        lng: 80.20224457262381,
      },
      {
        lat: 13.121273721175996,
        lng: 80.20221640161367,
      },
      {
        lat: 13.123870315027007,
        lng: 80.2016617261629,
      },
      {
        lat: 13.125727588363272,
        lng: 80.201686984485,
      },
      {
        lat: 13.12841020574919,
        lng: 80.20170525770631,
      },
      {
        lat: 13.128864643423713,
        lng: 80.20178336549829,
      },
      {
        lat: 13.12895016851165,
        lng: 80.20227436457301,
      },
      {
        lat: 13.128508441239655,
        lng: 80.20428597151583,
      },
      {
        lat: 13.128353092149181,
        lng: 80.20588538568627,
      },
      {
        lat: 13.128691204923568,
        lng: 80.20719378520522,
      },
      {
        lat: 13.128823978579648,
        lng: 80.20866783019406,
      },
      {
        lat: 13.129494222762933,
        lng: 80.2103012340625,
      },
      {
        lat: 13.129664026820855,
        lng: 80.21107835821809,
      },
      {
        lat: 13.12979106833467,
        lng: 80.21160998283648,
      },
      {
        lat: 13.13020448803046,
        lng: 80.21172941468225,
      },
      {
        lat: 13.13078422265398,
        lng: 80.2120523235015,
      },
      {
        lat: 13.13090927037953,
        lng: 80.21225614972992,
      },
      {
        lat: 13.130870993859917,
        lng: 80.21274819656975,
      },
      {
        lat: 13.130544096146306,
        lng: 80.21328366299352,
      },
      {
        lat: 13.1302574687411,
        lng: 80.21365488096723,
      },
      {
        lat: 13.13026045942402,
        lng: 80.21414657855199,
      },
      {
        lat: 13.129225118626056,
        lng: 80.21265186364812,
      },
      {
        lat: 13.125864546538077,
        lng: 80.20677413883341,
      },
      {
        lat: 13.124540194832697,
        lng: 80.20683692940298,
      },
      {
        lat: 13.122151072879015,
        lng: 80.20693452251813,
      },
      {
        lat: 13.122109321300753,
        lng: 80.20861129859522,
      },
      {
        lat: 13.119121546308564,
        lng: 80.20850762931568,
      },
      {
        lat: 13.118861337400157,
        lng: 80.20843245817493,
      },
      {
        lat: 13.118603010846153,
        lng: 80.2086667645287,
      },
      {
        lat: 13.118063364300175,
        lng: 80.20962560309339,
      },
      {
        lat: 13.117859652078753,
        lng: 80.21029789625194,
      },
      {
        lat: 13.117395260360171,
        lng: 80.21081764889563,
      },
      {
        lat: 13.11693024119028,
        lng: 80.21123424237449,
      },
      {
        lat: 13.1166983590567,
        lng: 80.21154569827874,
      },
      {
        lat: 13.11675203221208,
        lng: 80.21182894633588,
      },
      {
        lat: 13.116864084408617,
        lng: 80.2131691361872,
      },
      {
        lat: 13.117100672427808,
        lng: 80.21363137402007,
      },
      {
        lat: 13.117260750716856,
        lng: 80.2143263794439,
      },
      {
        lat: 13.117392659093921,
        lng: 80.21466054761369,
      },
      {
        lat: 13.117265142876866,
        lng: 80.21504849359859,
      },
      {
        lat: 13.117293626514556,
        lng: 80.21546091043525,
      },
      {
        lat: 13.117347299669992,
        lng: 80.21574415849233,
      },
      {
        lat: 13.11748218844135,
        lng: 80.21656833269563,
      },
      {
        lat: 13.11746248912368,
        lng: 80.2176001441685,
      },
      {
        lat: 13.117545587093955,
        lng: 80.21845054780961,
      },
      {
        lat: 13.117909534467454,
        lng: 80.21849904986607,
      },
      {
        lat: 13.118041442844522,
        lng: 80.21883321803585,
      },
      {
        lat: 13.118045835004532,
        lng: 80.21955533219057,
      },
    ],
    properties: {
      Ward_No: 65,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#f6d04d",
      zname: "THIRU-VI-KA-NAGAR(65)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.109883378698303,
        lng: 80.2277571990867,
      },
      {
        lat: 13.112200125909824,
        lng: 80.23486565645008,
      },
      {
        lat: 13.11263036094551,
        lng: 80.23501877946876,
      },
      {
        lat: 13.11187382430254,
        lng: 80.23635948588462,
      },
      {
        lat: 13.110035949080629,
        lng: 80.23594759317969,
      },
      {
        lat: 13.11019596959813,
        lng: 80.23400695285952,
      },
      {
        lat: 13.111277564258636,
        lng: 80.21567320351532,
      },
      {
        lat: 13.11187508697708,
        lng: 80.2324148596643,
      },
      {
        lat: 13.113971752665847,
        lng: 80.23217480522223,
      },
      {
        lat: 13.112983759914213,
        lng: 80.23569515655396,
      },
      {
        lat: 13.110408536306794,
        lng: 80.2366361334005,
      },
      {
        lat: 13.110851305398317,
        lng: 80.22583686477218,
      },
      {
        lat: 13.108111498168642,
        lng: 80.23509018295684,
      },
      {
        lat: 13.109367364445191,
        lng: 80.22444340520998,
      },
      {
        lat: 13.112967479911644,
        lng: 80.23624870908851,
      },
      {
        lat: 13.111686768131998,
        lng: 80.22470937953015,
      },
      {
        lat: 13.11090196437065,
        lng: 80.21651822006793,
      },
      {
        lat: 13.11109567603062,
        lng: 80.21886503351843,
      },
      {
        lat: 13.10952279655207,
        lng: 80.22868283706312,
      },
      {
        lat: 13.112035350314873,
        lng: 80.22269467852941,
      },
      {
        lat: 13.110624765922024,
        lng: 80.21702088322687,
      },
      {
        lat: 13.110393684700083,
        lng: 80.22406603827876,
      },
      {
        lat: 13.112198223538893,
        lng: 80.23357976863197,
      },
      {
        lat: 13.110859892687898,
        lng: 80.22198391572792,
      },
      {
        lat: 13.11114246286663,
        lng: 80.21939860682863,
      },
      {
        lat: 13.111287788782796,
        lng: 80.22322079737008,
      },
      {
        lat: 13.108027290450515,
        lng: 80.23438930306747,
      },
      {
        lat: 13.109697308758827,
        lng: 80.2276239032572,
      },
      {
        lat: 13.110181309770459,
        lng: 80.21810519982172,
      },
      {
        lat: 13.111722638959051,
        lng: 80.23390741100874,
      },
      {
        lat: 13.111639906763008,
        lng: 80.23236758547097,
      },
      {
        lat: 13.110404732480847,
        lng: 80.21655346496397,
      },
      {
        lat: 13.112527001589138,
        lng: 80.2328027690285,
      },
      {
        lat: 13.112270228123426,
        lng: 80.23638360603694,
      },
      {
        lat: 13.109804041126,
        lng: 80.22452296649374,
      },
      {
        lat: 13.11502448364946,
        lng: 80.23149276983231,
      },
      {
        lat: 13.11297138958117,
        lng: 80.23295727331532,
      },
      {
        lat: 13.108787757184375,
        lng: 80.2297632316573,
      },
      {
        lat: 13.111182142127609,
        lng: 80.21439208663399,
      },
      {
        lat: 13.112015835748881,
        lng: 80.22125410921139,
      },
      {
        lat: 13.10968855673572,
        lng: 80.22820504221666,
      },
      {
        lat: 13.110967807152688,
        lng: 80.2149857269961,
      },
      {
        lat: 13.112116519420631,
        lng: 80.23547227842302,
      },
      {
        lat: 13.110819504308159,
        lng: 80.2359274104264,
      },
      {
        lat: 13.112628645385653,
        lng: 80.23320178725497,
      },
      {
        lat: 13.110355853609702,
        lng: 80.22755945619507,
      },
      {
        lat: 13.110182039276117,
        lng: 80.21768233351112,
      },
      {
        lat: 13.110274467011175,
        lng: 80.2236129703246,
      },
      {
        lat: 13.110588905471863,
        lng: 80.22267949681337,
      },
      {
        lat: 13.110252909083048,
        lng: 80.21699010595283,
      },
      {
        lat: 13.11080626043095,
        lng: 80.21832862113146,
      },
      {
        lat: 13.110173066531095,
        lng: 80.23595669836223,
      },
      {
        lat: 13.111282211379484,
        lng: 80.22418984976032,
      },
      {
        lat: 13.113353099563914,
        lng: 80.23247191959112,
      },
      {
        lat: 13.111902564823554,
        lng: 80.22116377088848,
      },
      {
        lat: 13.111691564063385,
        lng: 80.22104415539967,
      },
      {
        lat: 13.109682568619885,
        lng: 80.2199805499521,
      },
      {
        lat: 13.110594945975143,
        lng: 80.22173795860766,
      },
      {
        lat: 13.11090365309854,
        lng: 80.22645020609085,
      },
      {
        lat: 13.109014975854025,
        lng: 80.22269737255934,
      },
      {
        lat: 13.111056082630357,
        lng: 80.23182056959324,
      },
      {
        lat: 13.10981326856212,
        lng: 80.22082774572772,
      },
      {
        lat: 13.109362802258334,
        lng: 80.22273095162,
      },
      {
        lat: 13.112172369043279,
        lng: 80.23702386662072,
      },
    ],
    cases: 64,
    coords: [
      {
        lat: 13.107780211429647,
        lng: 80.23701341521931,
      },
      {
        lat: 13.107904430439675,
        lng: 80.23663550041523,
      },
      {
        lat: 13.10793051897026,
        lng: 80.23572452614712,
      },
      {
        lat: 13.108046524613245,
        lng: 80.23399625328997,
      },
      {
        lat: 13.108276434805191,
        lng: 80.23019426714326,
      },
      {
        lat: 13.108667673730068,
        lng: 80.22691538400956,
      },
      {
        lat: 13.10872672709863,
        lng: 80.22622396779705,
      },
      {
        lat: 13.109133686026292,
        lng: 80.22552960716972,
      },
      {
        lat: 13.10916130262517,
        lng: 80.22486986230686,
      },
      {
        lat: 13.109126427701876,
        lng: 80.22433626749479,
      },
      {
        lat: 13.10899495036737,
        lng: 80.22352084262428,
      },
      {
        lat: 13.108990939188091,
        lng: 80.22286136543553,
      },
      {
        lat: 13.109141628745832,
        lng: 80.22163528371472,
      },
      {
        lat: 13.109569327965037,
        lng: 80.21915064590083,
      },
      {
        lat: 13.109654660872485,
        lng: 80.21757965909593,
      },
      {
        lat: 13.109864594807105,
        lng: 80.21569356483819,
      },
      {
        lat: 13.110074910758566,
        lng: 80.21387027793185,
      },
      {
        lat: 13.11012484071358,
        lng: 80.21361424320284,
      },
      {
        lat: 13.111599764431324,
        lng: 80.21385695348985,
      },
      {
        lat: 13.111347084659831,
        lng: 80.21567340355342,
      },
      {
        lat: 13.11110732611199,
        lng: 80.2165171236261,
      },
      {
        lat: 13.11111017011521,
        lng: 80.21698470563115,
      },
      {
        lat: 13.111149663777814,
        lng: 80.21728363927383,
      },
      {
        lat: 13.111321242212648,
        lng: 80.21761886353467,
      },
      {
        lat: 13.111230243744934,
        lng: 80.21814335248169,
      },
      {
        lat: 13.111120522286946,
        lng: 80.2186867041292,
      },
      {
        lat: 13.11119734816976,
        lng: 80.21892920909056,
      },
      {
        lat: 13.11140534874371,
        lng: 80.21905837842877,
      },
      {
        lat: 13.113309339403623,
        lng: 80.21928541962079,
      },
      {
        lat: 13.113441537936156,
        lng: 80.21934041351919,
      },
      {
        lat: 13.113499754588814,
        lng: 80.21962048446127,
      },
      {
        lat: 13.113613798931706,
        lng: 80.21978785746126,
      },
      {
        lat: 13.11384018121556,
        lng: 80.21984205425811,
      },
      {
        lat: 13.112209291875908,
        lng: 80.22373698731587,
      },
      {
        lat: 13.111320144199533,
        lng: 80.22621347247666,
      },
      {
        lat: 13.110898638345896,
        lng: 80.227114843453,
      },
      {
        lat: 13.110335539300475,
        lng: 80.22951375220768,
      },
      {
        lat: 13.110205919706196,
        lng: 80.23037569374026,
      },
      {
        lat: 13.110100501030805,
        lng: 80.23127304153537,
      },
      {
        lat: 13.110133039336143,
        lng: 80.23141431177767,
      },
      {
        lat: 13.110323295151895,
        lng: 80.23144415617624,
      },
      {
        lat: 13.11049713833359,
        lng: 80.23137977573064,
      },
      {
        lat: 13.110701032812342,
        lng: 80.23104777699966,
      },
      {
        lat: 13.110890236432363,
        lng: 80.23090463009775,
      },
      {
        lat: 13.111175811464118,
        lng: 80.23098084965906,
      },
      {
        lat: 13.112190541032273,
        lng: 80.23114526194487,
      },
      {
        lat: 13.112506454323855,
        lng: 80.23100104266638,
      },
      {
        lat: 13.112790690197334,
        lng: 80.23085709148182,
      },
      {
        lat: 13.113441033805728,
        lng: 80.23100886036923,
      },
      {
        lat: 13.1137412996965,
        lng: 80.23089622810136,
      },
      {
        lat: 13.114058647800345,
        lng: 80.23098790605049,
      },
      {
        lat: 13.114375613287656,
        lng: 80.23101667807215,
      },
      {
        lat: 13.115070603397962,
        lng: 80.23069625036274,
      },
      {
        lat: 13.115529543340601,
        lng: 80.23062945706934,
      },
      {
        lat: 13.116560398580326,
        lng: 80.2308409147537,
      },
      {
        lat: 13.116514412920198,
        lng: 80.23109294060458,
      },
      {
        lat: 13.116043460434922,
        lng: 80.23178892721894,
      },
      {
        lat: 13.11579319767911,
        lng: 80.2323100458736,
      },
      {
        lat: 13.114768559960114,
        lng: 80.2331208095094,
      },
      {
        lat: 13.114375748823715,
        lng: 80.23364313458801,
      },
      {
        lat: 13.114381105456816,
        lng: 80.23452381757146,
      },
      {
        lat: 13.114146585755975,
        lng: 80.2350290756971,
      },
      {
        lat: 13.113785260729237,
        lng: 80.2355196797178,
      },
      {
        lat: 13.113293495518548,
        lng: 80.23539802332313,
      },
      {
        lat: 13.11253189833108,
        lng: 80.23518428683803,
      },
      {
        lat: 13.112564723598851,
        lng: 80.23537273652597,
      },
      {
        lat: 13.113580122746319,
        lng: 80.23564723418473,
      },
      {
        lat: 13.113360676520909,
        lng: 80.23602654640857,
      },
      {
        lat: 13.112263923665173,
        lng: 80.23800173993676,
      },
      {
        lat: 13.111740863653315,
        lng: 80.23794325756378,
      },
      {
        lat: 13.111407485532117,
        lng: 80.23782026069814,
      },
      {
        lat: 13.111327335445829,
        lng: 80.23766366611517,
      },
      {
        lat: 13.111577502547254,
        lng: 80.2371268209787,
      },
      {
        lat: 13.111591810789793,
        lng: 80.23687506322202,
      },
      {
        lat: 13.111305853141333,
        lng: 80.23673593773299,
      },
      {
        lat: 13.110957592852793,
        lng: 80.23677033973307,
      },
      {
        lat: 13.110482240276045,
        lng: 80.23674290818228,
      },
      {
        lat: 13.109657001290168,
        lng: 80.2364825284403,
      },
      {
        lat: 13.109212560898168,
        lng: 80.23632901694066,
      },
      {
        lat: 13.109022400736746,
        lng: 80.2363148990239,
      },
      {
        lat: 13.108880617589351,
        lng: 80.23644191730256,
      },
      {
        lat: 13.108694761865243,
        lng: 80.23713549106915,
      },
      {
        lat: 13.108552691755587,
        lng: 80.23721532990231,
      },
      {
        lat: 13.108124377034981,
        lng: 80.2371088638011,
      },
      {
        lat: 13.107780211429647,
        lng: 80.23701341521931,
      },
    ],
    properties: {
      Ward_No: 67,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#f6d04d",
      zname: "THIRU-VI-KA-NAGAR(67)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.113326195045424,
        lng: 80.23789574473204,
      },
      {
        lat: 13.11697171058087,
        lng: 80.23891537314505,
      },
      {
        lat: 13.113544340368026,
        lng: 80.23580259373685,
      },
      {
        lat: 13.121799221440247,
        lng: 80.23774384063366,
      },
      {
        lat: 13.122835351338228,
        lng: 80.23356727202615,
      },
      {
        lat: 13.113403847805285,
        lng: 80.23651871965279,
      },
      {
        lat: 13.122139492650595,
        lng: 80.23437332459402,
      },
      {
        lat: 13.119657098312318,
        lng: 80.23356703676642,
      },
      {
        lat: 13.12225495614578,
        lng: 80.24160825379133,
      },
      {
        lat: 13.115464191610636,
        lng: 80.23688414004131,
      },
      {
        lat: 13.120580140299476,
        lng: 80.23440109905827,
      },
      {
        lat: 13.120005778408293,
        lng: 80.23744915942409,
      },
      {
        lat: 13.117523689300288,
        lng: 80.24052056235523,
      },
      {
        lat: 13.119723580408627,
        lng: 80.23426728088701,
      },
      {
        lat: 13.121963224465784,
        lng: 80.23380206815054,
      },
      {
        lat: 13.122685534690493,
        lng: 80.23476073427081,
      },
      {
        lat: 13.117441877031233,
        lng: 80.23879643706786,
      },
      {
        lat: 13.115537246074954,
        lng: 80.23750515450254,
      },
      {
        lat: 13.122759271563293,
        lng: 80.23581603687148,
      },
      {
        lat: 13.119837013428747,
        lng: 80.23400477426382,
      },
      {
        lat: 13.115572449095218,
        lng: 80.23411046658474,
      },
      {
        lat: 13.120910773356202,
        lng: 80.23340932487312,
      },
      {
        lat: 13.125035371188563,
        lng: 80.2323829243272,
      },
      {
        lat: 13.120504318301494,
        lng: 80.23759854826528,
      },
      {
        lat: 13.119120589783446,
        lng: 80.23698473704685,
      },
      {
        lat: 13.118005866309176,
        lng: 80.23659638662058,
      },
      {
        lat: 13.113440806770601,
        lng: 80.23719621366891,
      },
      {
        lat: 13.121508560805797,
        lng: 80.2392798178895,
      },
      {
        lat: 13.122554107243511,
        lng: 80.23908205348617,
      },
      {
        lat: 13.118352937141566,
        lng: 80.23628685285139,
      },
      {
        lat: 13.119701347321662,
        lng: 80.23886013058309,
      },
      {
        lat: 13.121639287582976,
        lng: 80.23512845018566,
      },
      {
        lat: 13.116637398083446,
        lng: 80.23709430977739,
      },
      {
        lat: 13.120206369510965,
        lng: 80.23747142490697,
      },
      {
        lat: 13.117393175017442,
        lng: 80.24116098511811,
      },
      {
        lat: 13.11701939006168,
        lng: 80.23416122621515,
      },
      {
        lat: 13.116283013900391,
        lng: 80.23992672354221,
      },
      {
        lat: 13.121676114618852,
        lng: 80.23322842530082,
      },
      {
        lat: 13.122811033539579,
        lng: 80.23396912812373,
      },
      {
        lat: 13.119504174956216,
        lng: 80.23559479720002,
      },
      {
        lat: 13.11614215848824,
        lng: 80.24198314063385,
      },
      {
        lat: 13.119873169458248,
        lng: 80.23776725657716,
      },
      {
        lat: 13.120367446434413,
        lng: 80.23940127105271,
      },
      {
        lat: 13.121556835882728,
        lng: 80.23841600594747,
      },
      {
        lat: 13.121879390813266,
        lng: 80.23637975196273,
      },
      {
        lat: 13.118627502902518,
        lng: 80.23557470579911,
      },
      {
        lat: 13.116451102524938,
        lng: 80.24245576701232,
      },
      {
        lat: 13.11759236075346,
        lng: 80.23522124357238,
      },
      {
        lat: 13.117266690237187,
        lng: 80.23308884142142,
      },
      {
        lat: 13.120295880918802,
        lng: 80.23505241602024,
      },
      {
        lat: 13.11816004205142,
        lng: 80.23834921971229,
      },
      {
        lat: 13.120980030291328,
        lng: 80.23545784794987,
      },
      {
        lat: 13.121281342353148,
        lng: 80.23533122889754,
      },
      {
        lat: 13.1164068715604,
        lng: 80.24218141391009,
      },
      {
        lat: 13.115750164358008,
        lng: 80.23272113557908,
      },
      {
        lat: 13.117890987885545,
        lng: 80.23371745676977,
      },
      {
        lat: 13.11806517072626,
        lng: 80.23566663221479,
      },
      {
        lat: 13.117649682615486,
        lng: 80.2344262444856,
      },
      {
        lat: 13.12162192432025,
        lng: 80.23367215174679,
      },
      {
        lat: 13.119188952351042,
        lng: 80.23775047168135,
      },
      {
        lat: 13.116991925667978,
        lng: 80.23256090830148,
      },
      {
        lat: 13.116305647344621,
        lng: 80.23574773224203,
      },
      {
        lat: 13.123140861253288,
        lng: 80.23297684647592,
      },
      {
        lat: 13.121888414287397,
        lng: 80.2418536647306,
      },
      {
        lat: 13.117806483811254,
        lng: 80.23294552837557,
      },
      {
        lat: 13.116597304564053,
        lng: 80.235678250844,
      },
      {
        lat: 13.115970919104948,
        lng: 80.23971764762496,
      },
      {
        lat: 13.113127841702227,
        lng: 80.23752770800193,
      },
      {
        lat: 13.122569118414534,
        lng: 80.2361401258953,
      },
      {
        lat: 13.120867018520121,
        lng: 80.23657124516434,
      },
      {
        lat: 13.121847349591418,
        lng: 80.2356158738612,
      },
    ],
    cases: 71,
    coords: [
      {
        lat: 13.116131599275608,
        lng: 80.23165867320118,
      },
      {
        lat: 13.119929017723937,
        lng: 80.23236548103205,
      },
      {
        lat: 13.12207162953534,
        lng: 80.2328279202712,
      },
      {
        lat: 13.122759540531284,
        lng: 80.23284739160287,
      },
      {
        lat: 13.123369341878792,
        lng: 80.23258929768627,
      },
      {
        lat: 13.123952747728042,
        lng: 80.23217966735568,
      },
      {
        lat: 13.124257956071947,
        lng: 80.23210120439533,
      },
      {
        lat: 13.125862568945278,
        lng: 80.2320623308394,
      },
      {
        lat: 13.125864568801644,
        lng: 80.23239112682684,
      },
      {
        lat: 13.126171777001858,
        lng: 80.23264145985407,
      },
      {
        lat: 13.126536504765948,
        lng: 80.23290454228737,
      },
      {
        lat: 13.126332660989213,
        lng: 80.2333160365497,
      },
      {
        lat: 13.126003021816434,
        lng: 80.23340078018747,
      },
      {
        lat: 13.123894157688017,
        lng: 80.23272202061592,
      },
      {
        lat: 13.123358431726546,
        lng: 80.23284948532773,
      },
      {
        lat: 13.12303103556578,
        lng: 80.2333030021539,
      },
      {
        lat: 13.122912218429827,
        lng: 80.23412354589351,
      },
      {
        lat: 13.122883910853341,
        lng: 80.23625458468213,
      },
      {
        lat: 13.122376197621369,
        lng: 80.24098761677055,
      },
      {
        lat: 13.12229964458237,
        lng: 80.24197171044996,
      },
      {
        lat: 13.122466457957547,
        lng: 80.24225713702083,
      },
      {
        lat: 13.1215266140971,
        lng: 80.24266624898819,
      },
      {
        lat: 13.121435975405431,
        lng: 80.24262215101636,
      },
      {
        lat: 13.121434611047567,
        lng: 80.242397837211,
      },
      {
        lat: 13.121700660383754,
        lng: 80.2415655817633,
      },
      {
        lat: 13.121852797394379,
        lng: 80.24057726263851,
      },
      {
        lat: 13.121827750095257,
        lng: 80.24017368898629,
      },
      {
        lat: 13.121645790532757,
        lng: 80.23997333614018,
      },
      {
        lat: 13.12108059484963,
        lng: 80.23991082193213,
      },
      {
        lat: 13.120355758187799,
        lng: 80.23960290091964,
      },
      {
        lat: 13.118704398997847,
        lng: 80.23925795623715,
      },
      {
        lat: 13.118320071390754,
        lng: 80.23921634383089,
      },
      {
        lat: 13.118230387749534,
        lng: 80.239329265523,
      },
      {
        lat: 13.118033340700382,
        lng: 80.24036282980359,
      },
      {
        lat: 13.117784834695058,
        lng: 80.24036493297433,
      },
      {
        lat: 13.117696242540186,
        lng: 80.24065730571056,
      },
      {
        lat: 13.117742926243942,
        lng: 80.24090366850187,
      },
      {
        lat: 13.117700062742207,
        lng: 80.24128538436547,
      },
      {
        lat: 13.11749742182592,
        lng: 80.24139926204413,
      },
      {
        lat: 13.117157458514045,
        lng: 80.24122267896006,
      },
      {
        lat: 13.11690963468766,
        lng: 80.2413369390336,
      },
      {
        lat: 13.116848409240278,
        lng: 80.24241421889106,
      },
      {
        lat: 13.116600721849677,
        lng: 80.24255091034514,
      },
      {
        lat: 13.116239395005024,
        lng: 80.24257640088307,
      },
      {
        lat: 13.116125073371927,
        lng: 80.24235304306441,
      },
      {
        lat: 13.116056071084893,
        lng: 80.2421517342318,
      },
      {
        lat: 13.115896702977588,
        lng: 80.24195119018844,
      },
      {
        lat: 13.115850019273775,
        lng: 80.24170482739711,
      },
      {
        lat: 13.115959838525805,
        lng: 80.24118794965828,
      },
      {
        lat: 13.115932471818262,
        lng: 80.24040304253697,
      },
      {
        lat: 13.11581828662112,
        lng: 80.24020211609874,
      },
      {
        lat: 13.115682601455074,
        lng: 80.24018083190238,
      },
      {
        lat: 13.115345776166455,
        lng: 80.24052017057043,
      },
      {
        lat: 13.115245041226594,
        lng: 80.23881615043939,
      },
      {
        lat: 13.112263923665173,
        lng: 80.23800173993676,
      },
      {
        lat: 13.113360676520909,
        lng: 80.23602654640857,
      },
      {
        lat: 13.113580122746319,
        lng: 80.23564723418473,
      },
      {
        lat: 13.112564723598851,
        lng: 80.23537273652597,
      },
      {
        lat: 13.11253189833108,
        lng: 80.23518428683803,
      },
      {
        lat: 13.113293495518548,
        lng: 80.23539802332313,
      },
      {
        lat: 13.113785260729237,
        lng: 80.2355196797178,
      },
      {
        lat: 13.114146585755975,
        lng: 80.2350290756971,
      },
      {
        lat: 13.114381105456816,
        lng: 80.23452381757146,
      },
      {
        lat: 13.114375748823715,
        lng: 80.23364313458801,
      },
      {
        lat: 13.114768559960114,
        lng: 80.2331208095094,
      },
      {
        lat: 13.11579319767911,
        lng: 80.2323100458736,
      },
      {
        lat: 13.116043460434922,
        lng: 80.23178892721894,
      },
      {
        lat: 13.116131599275608,
        lng: 80.23165867320118,
      },
    ],
    properties: {
      Ward_No: 68,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#f6d04d",
      zname: "THIRU-VI-KA-NAGAR(68)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.122428213152052,
        lng: 80.22818595584069,
      },
      {
        lat: 13.119070575772232,
        lng: 80.23177369463548,
      },
      {
        lat: 13.121053983710231,
        lng: 80.22954913406453,
      },
      {
        lat: 13.123828509235128,
        lng: 80.22531918954324,
      },
      {
        lat: 13.118210013930149,
        lng: 80.227737973825,
      },
      {
        lat: 13.119936567278236,
        lng: 80.22794571218026,
      },
      {
        lat: 13.119779368367357,
        lng: 80.22581745044896,
      },
      {
        lat: 13.125991662705328,
        lng: 80.22621635270218,
      },
      {
        lat: 13.119996822820484,
        lng: 80.2289386096435,
      },
      {
        lat: 13.11706994543566,
        lng: 80.22704341141336,
      },
      {
        lat: 13.124905582620343,
        lng: 80.23131963586195,
      },
      {
        lat: 13.120094466671882,
        lng: 80.22871875017447,
      },
      {
        lat: 13.1218537717366,
        lng: 80.23186474909681,
      },
      {
        lat: 13.11717301866431,
        lng: 80.22997231175786,
      },
      {
        lat: 13.115553615486842,
        lng: 80.22019580759515,
      },
      {
        lat: 13.119516343483808,
        lng: 80.2256678907962,
      },
      {
        lat: 13.112716334811436,
        lng: 80.22707046874774,
      },
      {
        lat: 13.117071652366434,
        lng: 80.22501775768096,
      },
      {
        lat: 13.11218001556106,
        lng: 80.22440916378407,
      },
      {
        lat: 13.126041876196924,
        lng: 80.23242174747342,
      },
      {
        lat: 13.112693663814676,
        lng: 80.2273171822534,
      },
      {
        lat: 13.121470312581874,
        lng: 80.22786499148745,
      },
      {
        lat: 13.113098065517974,
        lng: 80.22597835476095,
      },
      {
        lat: 13.114645577793786,
        lng: 80.2251162101167,
      },
      {
        lat: 13.122346142534258,
        lng: 80.23009223066721,
      },
      {
        lat: 13.115152131522049,
        lng: 80.2294061142777,
      },
      {
        lat: 13.125924943587469,
        lng: 80.23079635625672,
      },
      {
        lat: 13.116509921122438,
        lng: 80.22045646582536,
      },
      {
        lat: 13.112080836409259,
        lng: 80.23047427483993,
      },
      {
        lat: 13.121788209161336,
        lng: 80.23167033657275,
      },
      {
        lat: 13.117973163478787,
        lng: 80.22910360620882,
      },
      {
        lat: 13.117393304374188,
        lng: 80.22503972426308,
      },
      {
        lat: 13.116298495736993,
        lng: 80.22313365622367,
      },
      {
        lat: 13.124398561205274,
        lng: 80.22563568941086,
      },
      {
        lat: 13.119914908209138,
        lng: 80.22883954759048,
      },
      {
        lat: 13.11772340081021,
        lng: 80.22666718306574,
      },
      {
        lat: 13.122442410217735,
        lng: 80.22442697001014,
      },
    ],
    cases: 37,
    coords: [
      {
        lat: 13.126536504765948,
        lng: 80.23290454228737,
      },
      {
        lat: 13.126171777001858,
        lng: 80.23264145985407,
      },
      {
        lat: 13.125864568801644,
        lng: 80.23239112682684,
      },
      {
        lat: 13.125862568945278,
        lng: 80.2320623308394,
      },
      {
        lat: 13.124257956071947,
        lng: 80.23210120439533,
      },
      {
        lat: 13.123952747728042,
        lng: 80.23217966735568,
      },
      {
        lat: 13.123369341878792,
        lng: 80.23258929768627,
      },
      {
        lat: 13.122759540531284,
        lng: 80.23284739160287,
      },
      {
        lat: 13.12207162953534,
        lng: 80.2328279202712,
      },
      {
        lat: 13.119929017723937,
        lng: 80.23236548103205,
      },
      {
        lat: 13.116131599275608,
        lng: 80.23165867320118,
      },
      {
        lat: 13.116514412920198,
        lng: 80.23109294060458,
      },
      {
        lat: 13.116560398580326,
        lng: 80.2308409147537,
      },
      {
        lat: 13.115529543340601,
        lng: 80.23062945706934,
      },
      {
        lat: 13.115070603397962,
        lng: 80.23069625036274,
      },
      {
        lat: 13.114375613287656,
        lng: 80.23101667807215,
      },
      {
        lat: 13.114058647800345,
        lng: 80.23098790605049,
      },
      {
        lat: 13.1137412996965,
        lng: 80.23089622810136,
      },
      {
        lat: 13.113441033805728,
        lng: 80.23100886036923,
      },
      {
        lat: 13.112790690197334,
        lng: 80.23085709148182,
      },
      {
        lat: 13.112506454323855,
        lng: 80.23100104266638,
      },
      {
        lat: 13.112190541032273,
        lng: 80.23114526194487,
      },
      {
        lat: 13.111175811464118,
        lng: 80.23098084965906,
      },
      {
        lat: 13.110890236432363,
        lng: 80.23090463009775,
      },
      {
        lat: 13.110701032812342,
        lng: 80.23104777699966,
      },
      {
        lat: 13.11049713833359,
        lng: 80.23137977573064,
      },
      {
        lat: 13.110323295151895,
        lng: 80.23144415617624,
      },
      {
        lat: 13.110133039336143,
        lng: 80.23141431177767,
      },
      {
        lat: 13.110100501030805,
        lng: 80.23127304153537,
      },
      {
        lat: 13.110205919706196,
        lng: 80.23037569374026,
      },
      {
        lat: 13.110335539300475,
        lng: 80.22951375220768,
      },
      {
        lat: 13.110898638345896,
        lng: 80.227114843453,
      },
      {
        lat: 13.111320144199533,
        lng: 80.22621347247666,
      },
      {
        lat: 13.112209291875908,
        lng: 80.22373698731587,
      },
      {
        lat: 13.11384018121556,
        lng: 80.21984205425811,
      },
      {
        lat: 13.114046361627283,
        lng: 80.21967197111309,
      },
      {
        lat: 13.114252655799527,
        lng: 80.21952059124824,
      },
      {
        lat: 13.115966686314936,
        lng: 80.21948738072044,
      },
      {
        lat: 13.116418313281121,
        lng: 80.21940874151235,
      },
      {
        lat: 13.11747362633737,
        lng: 80.2194746270962,
      },
      {
        lat: 13.118045835004532,
        lng: 80.21955533219057,
      },
      {
        lat: 13.118000475580603,
        lng: 80.2206389430692,
      },
      {
        lat: 13.11684671197572,
        lng: 80.22312465507561,
      },
      {
        lat: 13.118669899825848,
        lng: 80.223934540766,
      },
      {
        lat: 13.119630617883455,
        lng: 80.22387482772245,
      },
      {
        lat: 13.120644224782207,
        lng: 80.22396941377966,
      },
      {
        lat: 13.121138198178306,
        lng: 80.22404260651105,
      },
      {
        lat: 13.121501361237575,
        lng: 80.22396215961156,
      },
      {
        lat: 13.121554720667241,
        lng: 80.22419382808603,
      },
      {
        lat: 13.123322196144846,
        lng: 80.22438519843323,
      },
      {
        lat: 13.124362718052708,
        lng: 80.22463430341466,
      },
      {
        lat: 13.124701789634067,
        lng: 80.2248635538331,
      },
      {
        lat: 13.125118625848717,
        lng: 80.22506635499066,
      },
      {
        lat: 13.126859310991238,
        lng: 80.22491131324071,
      },
      {
        lat: 13.126407116328684,
        lng: 80.2252019786373,
      },
      {
        lat: 13.126080467838824,
        lng: 80.22577841985961,
      },
      {
        lat: 13.126084953863206,
        lng: 80.22651596623668,
      },
      {
        lat: 13.126308237078693,
        lng: 80.22930050627467,
      },
      {
        lat: 13.126403731109866,
        lng: 80.23143049729832,
      },
      {
        lat: 13.126534510977333,
        lng: 80.23257674389747,
      },
      {
        lat: 13.126536504765948,
        lng: 80.23290454228737,
      },
    ],
    properties: {
      Ward_No: 66,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#8bc24c",
      zname: "THIRU-VI-KA-NAGAR(66)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.093844648203044,
        lng: 80.27115826037007,
      },
      {
        lat: 13.09918264403735,
        lng: 80.26253110907157,
      },
      {
        lat: 13.094908809136369,
        lng: 80.26727338874976,
      },
      {
        lat: 13.098250504488608,
        lng: 80.26151407289183,
      },
      {
        lat: 13.098779559591902,
        lng: 80.2636783300757,
      },
      {
        lat: 13.09453450460048,
        lng: 80.26545979010452,
      },
      {
        lat: 13.094314323178503,
        lng: 80.2640712110852,
      },
      {
        lat: 13.092667240477606,
        lng: 80.27084477546974,
      },
      {
        lat: 13.092903849842138,
        lng: 80.26889517034253,
      },
      {
        lat: 13.093117534579727,
        lng: 80.26897905083905,
      },
      {
        lat: 13.097819270319398,
        lng: 80.26664914267653,
      },
      {
        lat: 13.097929681649052,
        lng: 80.26338720164944,
      },
      {
        lat: 13.092956479737675,
        lng: 80.26929403286074,
      },
      {
        lat: 13.09884233923838,
        lng: 80.2632878622584,
      },
      {
        lat: 13.097872462967182,
        lng: 80.26682093616725,
      },
      {
        lat: 13.098568002007724,
        lng: 80.26083773138537,
      },
      {
        lat: 13.098711371001597,
        lng: 80.26062704245395,
      },
      {
        lat: 13.099179110273344,
        lng: 80.26351354262137,
      },
      {
        lat: 13.091130471599358,
        lng: 80.27443440960957,
      },
      {
        lat: 13.095708998810913,
        lng: 80.27185304857345,
      },
      {
        lat: 13.091807430858855,
        lng: 80.27408716541797,
      },
      {
        lat: 13.096014127810514,
        lng: 80.26395265021507,
      },
      {
        lat: 13.097072159777587,
        lng: 80.26388098819939,
      },
      {
        lat: 13.093075916972557,
        lng: 80.26947453029834,
      },
      {
        lat: 13.098718731580416,
        lng: 80.26043702872784,
      },
      {
        lat: 13.09580884691301,
        lng: 80.26688695888228,
      },
      {
        lat: 13.099571461437767,
        lng: 80.26086873576656,
      },
      {
        lat: 13.095264292821701,
        lng: 80.27012274683065,
      },
      {
        lat: 13.0948819717459,
        lng: 80.26557077500689,
      },
      {
        lat: 13.09327614613848,
        lng: 80.27000578819062,
      },
      {
        lat: 13.092704738546452,
        lng: 80.27124092841952,
      },
      {
        lat: 13.095601803784808,
        lng: 80.26722899424912,
      },
      {
        lat: 13.09564462204825,
        lng: 80.2655981175623,
      },
      {
        lat: 13.097343396030121,
        lng: 80.26292868487877,
      },
      {
        lat: 13.095086613098339,
        lng: 80.27290734976849,
      },
      {
        lat: 13.097288464648216,
        lng: 80.26381963309392,
      },
      {
        lat: 13.098441080237427,
        lng: 80.26717927668554,
      },
      {
        lat: 13.093327383592465,
        lng: 80.26918350453826,
      },
      {
        lat: 13.095150125451271,
        lng: 80.26793107027527,
      },
      {
        lat: 13.095441119596744,
        lng: 80.26983233127943,
      },
      {
        lat: 13.096180209686024,
        lng: 80.26679650665051,
      },
      {
        lat: 13.098095706394888,
        lng: 80.2652231937929,
      },
      {
        lat: 13.094110348351979,
        lng: 80.26601526076993,
      },
      {
        lat: 13.094436597553107,
        lng: 80.26848839059136,
      },
      {
        lat: 13.093476102322741,
        lng: 80.26869832002211,
      },
      {
        lat: 13.095656891437558,
        lng: 80.27244947092694,
      },
      {
        lat: 13.097267945529296,
        lng: 80.26432818658765,
      },
      {
        lat: 13.093882306510023,
        lng: 80.26290910182321,
      },
      {
        lat: 13.093747337944823,
        lng: 80.27045417660317,
      },
      {
        lat: 13.092611877567723,
        lng: 80.2705798426592,
      },
      {
        lat: 13.094434261364365,
        lng: 80.26296870877054,
      },
      {
        lat: 13.099794340258878,
        lng: 80.26056669205215,
      },
      {
        lat: 13.094020777193435,
        lng: 80.2641608984427,
      },
      {
        lat: 13.098731144635844,
        lng: 80.26220892710083,
      },
      {
        lat: 13.094129894696204,
        lng: 80.27305552865164,
      },
      {
        lat: 13.097496329220688,
        lng: 80.26524657889284,
      },
      {
        lat: 13.096100996922146,
        lng: 80.26788163780846,
      },
      {
        lat: 13.098248470034362,
        lng: 80.26610204095483,
      },
      {
        lat: 13.09504648239724,
        lng: 80.26935113769235,
      },
      {
        lat: 13.09538536607073,
        lng: 80.26431592358054,
      },
      {
        lat: 13.095925789962628,
        lng: 80.26719268672225,
      },
      {
        lat: 13.09392172591084,
        lng: 80.27360395379267,
      },
      {
        lat: 13.097624962637047,
        lng: 80.2646273369941,
      },
      {
        lat: 13.094887086654671,
        lng: 80.27337656173722,
      },
      {
        lat: 13.095113224197187,
        lng: 80.27158286742403,
      },
      {
        lat: 13.094952582506693,
        lng: 80.27063702884647,
      },
      {
        lat: 13.09570687985331,
        lng: 80.2684821796977,
      },
      {
        lat: 13.092879726708665,
        lng: 80.27087350903436,
      },
      {
        lat: 13.098660935783387,
        lng: 80.26147261909477,
      },
      {
        lat: 13.097540459648027,
        lng: 80.26448585623382,
      },
      {
        lat: 13.09276731875551,
        lng: 80.26900072587551,
      },
      {
        lat: 13.094924917254414,
        lng: 80.27068421912152,
      },
      {
        lat: 13.093232777775501,
        lng: 80.26962981613612,
      },
      {
        lat: 13.099327887964812,
        lng: 80.26185508067313,
      },
      {
        lat: 13.097283524886102,
        lng: 80.26248015972175,
      },
      {
        lat: 13.09850026967342,
        lng: 80.26110308012063,
      },
      {
        lat: 13.095320870954252,
        lng: 80.26327541014517,
      },
      {
        lat: 13.09266283065204,
        lng: 80.27212900740629,
      },
      {
        lat: 13.092214978904133,
        lng: 80.27204348262651,
      },
      {
        lat: 13.09853987225767,
        lng: 80.26383472670426,
      },
      {
        lat: 13.096541127062169,
        lng: 80.26670849965286,
      },
      {
        lat: 13.093655511584931,
        lng: 80.27045466129702,
      },
      {
        lat: 13.097969537631185,
        lng: 80.26570668215037,
      },
      {
        lat: 13.098381067846395,
        lng: 80.26620903531607,
      },
      {
        lat: 13.095629812492895,
        lng: 80.26927261968048,
      },
      {
        lat: 13.09661404961647,
        lng: 80.26660582618088,
      },
      {
        lat: 13.091849481523115,
        lng: 80.2736421590631,
      },
      {
        lat: 13.096567309109828,
        lng: 80.2646476073901,
      },
      {
        lat: 13.093714511807148,
        lng: 80.2706951819576,
      },
      {
        lat: 13.09852469773629,
        lng: 80.26672441029393,
      },
      {
        lat: 13.09656118572012,
        lng: 80.26748844259566,
      },
      {
        lat: 13.097364478600545,
        lng: 80.26246841017803,
      },
      {
        lat: 13.095688500122701,
        lng: 80.26558000940888,
      },
      {
        lat: 13.095633645062717,
        lng: 80.26376760805995,
      },
      {
        lat: 13.098489444289052,
        lng: 80.2632584241486,
      },
      {
        lat: 13.097851518659445,
        lng: 80.26212186047347,
      },
      {
        lat: 13.097359760698103,
        lng: 80.26696307676372,
      },
      {
        lat: 13.09257547549258,
        lng: 80.26930793338175,
      },
      {
        lat: 13.095677325142049,
        lng: 80.26494680705028,
      },
      {
        lat: 13.096971988769928,
        lng: 80.26627325927389,
      },
      {
        lat: 13.094079803393825,
        lng: 80.26893196146044,
      },
      {
        lat: 13.093598866851902,
        lng: 80.27104511871119,
      },
      {
        lat: 13.098879535116897,
        lng: 80.26109735998817,
      },
      {
        lat: 13.096469527619762,
        lng: 80.26573362352913,
      },
      {
        lat: 13.095635964146709,
        lng: 80.26840880264741,
      },
      {
        lat: 13.092865100509256,
        lng: 80.27292968849696,
      },
      {
        lat: 13.09426864360803,
        lng: 80.26839186642619,
      },
      {
        lat: 13.094344138330793,
        lng: 80.26569696268919,
      },
      {
        lat: 13.09495305085864,
        lng: 80.27156518368534,
      },
      {
        lat: 13.09703908476813,
        lng: 80.26456649717461,
      },
      {
        lat: 13.094672598567733,
        lng: 80.27118049928333,
      },
      {
        lat: 13.096183974066792,
        lng: 80.26851022865156,
      },
      {
        lat: 13.09599254929326,
        lng: 80.26740643217272,
      },
    ],
    cases: 113,
    coords: [
      {
        lat: 13.093671763785776,
        lng: 80.26285756681222,
      },
      {
        lat: 13.094184771510772,
        lng: 80.26270012802654,
      },
      {
        lat: 13.094801394693077,
        lng: 80.26267789859934,
      },
      {
        lat: 13.095024929219855,
        lng: 80.26281209305161,
      },
      {
        lat: 13.095933715571665,
        lng: 80.26294048803992,
      },
      {
        lat: 13.096602249943036,
        lng: 80.26300287320159,
      },
      {
        lat: 13.096756535064129,
        lng: 80.26301857823206,
      },
      {
        lat: 13.096927020337096,
        lng: 80.26288104908797,
      },
      {
        lat: 13.098521532679628,
        lng: 80.26026490415529,
      },
      {
        lat: 13.09924239208182,
        lng: 80.26047994354495,
      },
      {
        lat: 13.099852698314745,
        lng: 80.26050970117954,
      },
      {
        lat: 13.099732501774698,
        lng: 80.26147016632072,
      },
      {
        lat: 13.099629885427879,
        lng: 80.2618673284817,
      },
      {
        lat: 13.099358719054853,
        lng: 80.26218248687441,
      },
      {
        lat: 13.099119467685286,
        lng: 80.26429112555884,
      },
      {
        lat: 13.098568688430388,
        lng: 80.26698641254164,
      },
      {
        lat: 13.098282098428466,
        lng: 80.26821943422961,
      },
      {
        lat: 13.09786287992711,
        lng: 80.26836898512367,
      },
      {
        lat: 13.096730114662574,
        lng: 80.26862886276304,
      },
      {
        lat: 13.095637837767017,
        lng: 80.26863810698563,
      },
      {
        lat: 13.095925495514495,
        lng: 80.27103429218015,
      },
      {
        lat: 13.095829348860711,
        lng: 80.27249513528697,
      },
      {
        lat: 13.095774044412005,
        lng: 80.27370909303606,
      },
      {
        lat: 13.095040394310141,
        lng: 80.27370899517075,
      },
      {
        lat: 13.094697933948732,
        lng: 80.27379201943634,
      },
      {
        lat: 13.094597797975476,
        lng: 80.27391305581511,
      },
      {
        lat: 13.094477366776147,
        lng: 80.27401423247403,
      },
      {
        lat: 13.093529217276526,
        lng: 80.27402225690915,
      },
      {
        lat: 13.093246789766072,
        lng: 80.27402464716641,
      },
      {
        lat: 13.093205833816459,
        lng: 80.27392483636878,
      },
      {
        lat: 13.093668238047302,
        lng: 80.27366051363401,
      },
      {
        lat: 13.09384894576259,
        lng: 80.27351876387206,
      },
      {
        lat: 13.093706270017579,
        lng: 80.27327959356971,
      },
      {
        lat: 13.093443772235775,
        lng: 80.27324175218912,
      },
      {
        lat: 13.092637932983514,
        lng: 80.27342885556884,
      },
      {
        lat: 13.09227688305065,
        lng: 80.27377244645054,
      },
      {
        lat: 13.092160715987864,
        lng: 80.27457468894976,
      },
      {
        lat: 13.092060580014772,
        lng: 80.27469572532839,
      },
      {
        lat: 13.091091891624078,
        lng: 80.27464382913841,
      },
      {
        lat: 13.090768629996484,
        lng: 80.27456643905084,
      },
      {
        lat: 13.090727308549289,
        lng: 80.27440653689547,
      },
      {
        lat: 13.09084481576933,
        lng: 80.27382462937472,
      },
      {
        lat: 13.091085190838095,
        lng: 80.27354215424674,
      },
      {
        lat: 13.091386086087589,
        lng: 80.27325916692064,
      },
      {
        lat: 13.091603607448363,
        lng: 80.27253619256858,
      },
      {
        lat: 13.092398725442822,
        lng: 80.27058640936197,
      },
      {
        lat: 13.092334062957919,
        lng: 80.26990588617235,
      },
      {
        lat: 13.09210439357007,
        lng: 80.26960084446783,
      },
      {
        lat: 13.092614158529003,
        lng: 80.26924556211254,
      },
      {
        lat: 13.092715601317162,
        lng: 80.26902356336619,
      },
      {
        lat: 13.092781539988714,
        lng: 80.268597735676,
      },
      {
        lat: 13.09300968709828,
        lng: 80.2666735860613,
      },
      {
        lat: 13.093163351456816,
        lng: 80.26658723163308,
      },
      {
        lat: 13.093540239960651,
        lng: 80.26658404192791,
      },
      {
        lat: 13.093866458800585,
        lng: 80.26670035655103,
      },
      {
        lat: 13.094020847382174,
        lng: 80.266733071491,
      },
      {
        lat: 13.094216594188454,
        lng: 80.26511539022523,
      },
      {
        lat: 13.094146827480587,
        lng: 80.2649118512544,
      },
      {
        lat: 13.093992231978184,
        lng: 80.2648451164948,
      },
      {
        lat: 13.093271993338618,
        lng: 80.2647321365637,
      },
      {
        lat: 13.093186026479104,
        lng: 80.26468183176742,
      },
      {
        lat: 13.09321894408475,
        lng: 80.26446041296742,
      },
      {
        lat: 13.093504589246995,
        lng: 80.26353941306853,
      },
      {
        lat: 13.093671763785776,
        lng: 80.26285756681222,
      },
    ],
    properties: {
      Ward_No: 77,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#f6d04d",
      zname: "THIRU-VI-KA-NAGAR(77)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.10255904144419,
        lng: 80.25293735441294,
      },
      {
        lat: 13.100097913794254,
        lng: 80.25719069248694,
      },
      {
        lat: 13.100724149709563,
        lng: 80.25874928622156,
      },
      {
        lat: 13.101279979076592,
        lng: 80.25415664100937,
      },
      {
        lat: 13.100460004311392,
        lng: 80.25639344029733,
      },
      {
        lat: 13.10110445591267,
        lng: 80.26331157172709,
      },
      {
        lat: 13.100205521643204,
        lng: 80.25287311644979,
      },
      {
        lat: 13.098821841554807,
        lng: 80.25745559108876,
      },
      {
        lat: 13.097623043878908,
        lng: 80.25385785156263,
      },
      {
        lat: 13.099423307287907,
        lng: 80.25699063097099,
      },
      {
        lat: 13.102942960200771,
        lng: 80.25626985635027,
      },
      {
        lat: 13.103507419202288,
        lng: 80.25447614776635,
      },
      {
        lat: 13.098708221672299,
        lng: 80.25546166056583,
      },
      {
        lat: 13.10362543983752,
        lng: 80.25395369388477,
      },
      {
        lat: 13.100470075873154,
        lng: 80.25191242742888,
      },
      {
        lat: 13.101478821202495,
        lng: 80.25329330214251,
      },
      {
        lat: 13.100836999073639,
        lng: 80.26027419948596,
      },
      {
        lat: 13.099610112788008,
        lng: 80.25859264983735,
      },
      {
        lat: 13.098187266131804,
        lng: 80.25633302013354,
      },
      {
        lat: 13.101536952153333,
        lng: 80.2560281671821,
      },
      {
        lat: 13.099992674734034,
        lng: 80.26182143873993,
      },
      {
        lat: 13.10217150610305,
        lng: 80.26015258377225,
      },
      {
        lat: 13.099157913755906,
        lng: 80.25655347914179,
      },
      {
        lat: 13.099516794225757,
        lng: 80.26500432035024,
      },
      {
        lat: 13.099261789989457,
        lng: 80.25509718750185,
      },
      {
        lat: 13.101753807168869,
        lng: 80.2578821563068,
      },
      {
        lat: 13.099383231304039,
        lng: 80.26755285560972,
      },
      {
        lat: 13.098523253955202,
        lng: 80.26733450686115,
      },
      {
        lat: 13.100606565853047,
        lng: 80.25403785694088,
      },
      {
        lat: 13.100557529769814,
        lng: 80.25774704059333,
      },
      {
        lat: 13.101012727646006,
        lng: 80.2594678074886,
      },
      {
        lat: 13.100995943580918,
        lng: 80.26452783560835,
      },
      {
        lat: 13.101962920185626,
        lng: 80.25961957355689,
      },
      {
        lat: 13.10026333180728,
        lng: 80.25578532934804,
      },
      {
        lat: 13.101100232860238,
        lng: 80.25852418677202,
      },
      {
        lat: 13.101868175152774,
        lng: 80.25538883934183,
      },
      {
        lat: 13.102253386450881,
        lng: 80.26001763590673,
      },
      {
        lat: 13.098640054727111,
        lng: 80.26718862251656,
      },
      {
        lat: 13.09970610019781,
        lng: 80.26496229001063,
      },
      {
        lat: 13.100187195927784,
        lng: 80.25793105076812,
      },
      {
        lat: 13.10224166799215,
        lng: 80.26025577717779,
      },
      {
        lat: 13.10095351793427,
        lng: 80.26382199969535,
      },
      {
        lat: 13.099691449007764,
        lng: 80.25794341320349,
      },
      {
        lat: 13.100001241927526,
        lng: 80.25336734456953,
      },
      {
        lat: 13.099240027172497,
        lng: 80.26354347226722,
      },
      {
        lat: 13.101610403848289,
        lng: 80.2581470195417,
      },
      {
        lat: 13.101746323467788,
        lng: 80.26145808091258,
      },
      {
        lat: 13.09946867059953,
        lng: 80.25248730412115,
      },
      {
        lat: 13.098680128413159,
        lng: 80.25318187110116,
      },
      {
        lat: 13.101869895544402,
        lng: 80.25450342527701,
      },
      {
        lat: 13.100959233783604,
        lng: 80.2581699333493,
      },
      {
        lat: 13.100834569859057,
        lng: 80.25340717133989,
      },
      {
        lat: 13.101734236663335,
        lng: 80.256728586596,
      },
      {
        lat: 13.102950712917622,
        lng: 80.25525731804518,
      },
      {
        lat: 13.101514693442917,
        lng: 80.25668977107902,
      },
      {
        lat: 13.101786898322182,
        lng: 80.26377713420824,
      },
      {
        lat: 13.099855973544425,
        lng: 80.25515244183906,
      },
      {
        lat: 13.09945433981787,
        lng: 80.26653362727635,
      },
      {
        lat: 13.100919778636246,
        lng: 80.25692527128251,
      },
      {
        lat: 13.10223059173435,
        lng: 80.26070640332135,
      },
      {
        lat: 13.100305075627205,
        lng: 80.26092176723894,
      },
      {
        lat: 13.103388467182613,
        lng: 80.25456522552508,
      },
      {
        lat: 13.098691943372952,
        lng: 80.25594349640329,
      },
      {
        lat: 13.097841541674125,
        lng: 80.25703537367231,
      },
      {
        lat: 13.100546434121219,
        lng: 80.2611877174992,
      },
      {
        lat: 13.102814311533715,
        lng: 80.25732048102962,
      },
      {
        lat: 13.100373435553053,
        lng: 80.26433826165014,
      },
      {
        lat: 13.098484841498161,
        lng: 80.25255455602098,
      },
      {
        lat: 13.098107286042643,
        lng: 80.25640230139769,
      },
      {
        lat: 13.101519270232156,
        lng: 80.26007504332841,
      },
      {
        lat: 13.099042549618174,
        lng: 80.25246089831784,
      },
      {
        lat: 13.101422149889377,
        lng: 80.25326469687249,
      },
      {
        lat: 13.103984469387203,
        lng: 80.25408265863106,
      },
      {
        lat: 13.101131717866725,
        lng: 80.25750115395276,
      },
      {
        lat: 13.098305263986422,
        lng: 80.25516851415998,
      },
      {
        lat: 13.098146699659697,
        lng: 80.25213726733158,
      },
      {
        lat: 13.103059566211005,
        lng: 80.25336487095167,
      },
      {
        lat: 13.099526594511161,
        lng: 80.25861206854657,
      },
      {
        lat: 13.099117651513723,
        lng: 80.25573162998755,
      },
      {
        lat: 13.10109860491514,
        lng: 80.25599129114586,
      },
      {
        lat: 13.101904464442155,
        lng: 80.25786259293947,
      },
      {
        lat: 13.098765155509394,
        lng: 80.26785312649602,
      },
      {
        lat: 13.098803157927728,
        lng: 80.26802593083526,
      },
      {
        lat: 13.099672089611612,
        lng: 80.2659177867124,
      },
      {
        lat: 13.10014313907814,
        lng: 80.25876980637254,
      },
      {
        lat: 13.102689963708043,
        lng: 80.2596023611276,
      },
      {
        lat: 13.098872982991045,
        lng: 80.25649163641216,
      },
      {
        lat: 13.098325191132878,
        lng: 80.25542789588461,
      },
      {
        lat: 13.101103538824976,
        lng: 80.25917826341711,
      },
      {
        lat: 13.100273763883356,
        lng: 80.25287410868009,
      },
      {
        lat: 13.102462316568612,
        lng: 80.25305901142515,
      },
      {
        lat: 13.101388367613989,
        lng: 80.26040342861977,
      },
      {
        lat: 13.098441087817328,
        lng: 80.25455344084713,
      },
      {
        lat: 13.103773697930855,
        lng: 80.25717702346762,
      },
      {
        lat: 13.097692215740071,
        lng: 80.25423044240678,
      },
      {
        lat: 13.101664386788672,
        lng: 80.263217166705,
      },
      {
        lat: 13.10313246466721,
        lng: 80.25604553006372,
      },
      {
        lat: 13.10003721609934,
        lng: 80.25171965769653,
      },
      {
        lat: 13.099748845549254,
        lng: 80.26144050078963,
      },
      {
        lat: 13.10219668519843,
        lng: 80.25982436826679,
      },
      {
        lat: 13.104369295030436,
        lng: 80.25427596981777,
      },
      {
        lat: 13.099821821686728,
        lng: 80.25553739639399,
      },
      {
        lat: 13.100528317264532,
        lng: 80.25377901934407,
      },
      {
        lat: 13.102405966698905,
        lng: 80.25625141825412,
      },
      {
        lat: 13.099348118488646,
        lng: 80.2577144941499,
      },
      {
        lat: 13.100462053481454,
        lng: 80.25904138030297,
      },
      {
        lat: 13.10035087112441,
        lng: 80.25528452429299,
      },
      {
        lat: 13.099754105366696,
        lng: 80.25820681021187,
      },
      {
        lat: 13.09950643180731,
        lng: 80.266720466393,
      },
      {
        lat: 13.100553879273829,
        lng: 80.25571625450351,
      },
      {
        lat: 13.098258327721023,
        lng: 80.25159433949193,
      },
      {
        lat: 13.100099025815496,
        lng: 80.26384273846769,
      },
      {
        lat: 13.103503002392523,
        lng: 80.25699480058397,
      },
      {
        lat: 13.100008896457945,
        lng: 80.25413508786848,
      },
      {
        lat: 13.102684196500443,
        lng: 80.25752841577813,
      },
      {
        lat: 13.103266461743717,
        lng: 80.2582277598537,
      },
      {
        lat: 13.103795165079086,
        lng: 80.25566264004132,
      },
      {
        lat: 13.102893670850934,
        lng: 80.25275955624704,
      },
      {
        lat: 13.104390787700254,
        lng: 80.25355753203398,
      },
      {
        lat: 13.100523702502521,
        lng: 80.25469945117857,
      },
      {
        lat: 13.101785336815578,
        lng: 80.255425893034,
      },
      {
        lat: 13.098355338351993,
        lng: 80.25611206914732,
      },
      {
        lat: 13.100005494485933,
        lng: 80.26637449694125,
      },
    ],
    cases: 123,
    coords: [
      {
        lat: 13.098282098428466,
        lng: 80.26821943422961,
      },
      {
        lat: 13.098568688430388,
        lng: 80.26698641254164,
      },
      {
        lat: 13.099119467685286,
        lng: 80.26429112555884,
      },
      {
        lat: 13.099358719054853,
        lng: 80.26218248687441,
      },
      {
        lat: 13.099629885427879,
        lng: 80.2618673284817,
      },
      {
        lat: 13.099732501774698,
        lng: 80.26147016632072,
      },
      {
        lat: 13.099852698314745,
        lng: 80.26050970117954,
      },
      {
        lat: 13.099892552400261,
        lng: 80.2601547853173,
      },
      {
        lat: 13.099849019469685,
        lng: 80.25990486299477,
      },
      {
        lat: 13.099448396015609,
        lng: 80.25965796282196,
      },
      {
        lat: 13.098691443178357,
        lng: 80.25953922373364,
      },
      {
        lat: 13.09868560776896,
        lng: 80.25857982523347,
      },
      {
        lat: 13.098809990580973,
        lng: 80.25830762423364,
      },
      {
        lat: 13.098761890808378,
        lng: 80.25730686830231,
      },
      {
        lat: 13.097837402798728,
        lng: 80.25727297735868,
      },
      {
        lat: 13.097542567109558,
        lng: 80.25715032725314,
      },
      {
        lat: 13.097457277242752,
        lng: 80.25694247345574,
      },
      {
        lat: 13.097476252859982,
        lng: 80.25660859185614,
      },
      {
        lat: 13.097578869206794,
        lng: 80.25621142969496,
      },
      {
        lat: 13.097546193062977,
        lng: 80.25429281046853,
      },
      {
        lat: 13.097687775498223,
        lng: 80.25339473702121,
      },
      {
        lat: 13.097844654705636,
        lng: 80.25155794378956,
      },
      {
        lat: 13.097969164374373,
        lng: 80.25130659927895,
      },
      {
        lat: 13.098114171941045,
        lng: 80.2509716510382,
      },
      {
        lat: 13.099061060699592,
        lng: 80.25123478559034,
      },
      {
        lat: 13.103103597336878,
        lng: 80.25276488976064,
      },
      {
        lat: 13.105039258646903,
        lng: 80.25326994682581,
      },
      {
        lat: 13.103944751221965,
        lng: 80.25636612921313,
      },
      {
        lat: 13.103821763833935,
        lng: 80.25686775159329,
      },
      {
        lat: 13.10388731694316,
        lng: 80.25728434805535,
      },
      {
        lat: 13.103805959634863,
        lng: 80.2577230454211,
      },
      {
        lat: 13.103536315542492,
        lng: 80.25828848168335,
      },
      {
        lat: 13.102371307596187,
        lng: 80.26015466453244,
      },
      {
        lat: 13.102393327775006,
        lng: 80.26032133867204,
      },
      {
        lat: 13.10260515701822,
        lng: 80.26061155178473,
      },
      {
        lat: 13.102486355902156,
        lng: 80.26180143830604,
      },
      {
        lat: 13.102328715554334,
        lng: 80.26351309260292,
      },
      {
        lat: 13.102144741899226,
        lng: 80.26434895212958,
      },
      {
        lat: 13.101452073604115,
        lng: 80.26443824461214,
      },
      {
        lat: 13.101496875101828,
        lng: 80.26489673182583,
      },
      {
        lat: 13.101667454835658,
        lng: 80.26531243942033,
      },
      {
        lat: 13.101669611400004,
        lng: 80.2656669997356,
      },
      {
        lat: 13.10156521905919,
        lng: 80.26577217104882,
      },
      {
        lat: 13.101291769265098,
        lng: 80.2657119126371,
      },
      {
        lat: 13.10053456271457,
        lng: 80.26555146057046,
      },
      {
        lat: 13.100324382608623,
        lng: 80.26553238181634,
      },
      {
        lat: 13.100272276312365,
        lng: 80.2673265732021,
      },
      {
        lat: 13.09970741596087,
        lng: 80.26770678989126,
      },
      {
        lat: 13.098743581292787,
        lng: 80.2681112407663,
      },
      {
        lat: 13.098282098428466,
        lng: 80.26821943422961,
      },
    ],
    properties: {
      Ward_No: 73,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#de4307",
      zname: "THIRU-VI-KA-NAGAR(73)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.100943682111835,
        lng: 80.25170633828856,
      },
      {
        lat: 13.097454331198916,
        lng: 80.24708694452777,
      },
      {
        lat: 13.106998323358406,
        lng: 80.24543532767565,
      },
      {
        lat: 13.098096551872771,
        lng: 80.24732857113695,
      },
      {
        lat: 13.107766781594803,
        lng: 80.24463074010178,
      },
      {
        lat: 13.098194482851298,
        lng: 80.2446150943,
      },
      {
        lat: 13.099399722316333,
        lng: 80.24961989764789,
      },
      {
        lat: 13.104704164361369,
        lng: 80.2485355205748,
      },
      {
        lat: 13.097371379975058,
        lng: 80.24525617200149,
      },
      {
        lat: 13.10745216920292,
        lng: 80.24301865247335,
      },
      {
        lat: 13.101934007354927,
        lng: 80.2476045613907,
      },
      {
        lat: 13.107858558726646,
        lng: 80.2431318459699,
      },
      {
        lat: 13.102594508923271,
        lng: 80.24465522392165,
      },
      {
        lat: 13.097832826766139,
        lng: 80.24879995627211,
      },
      {
        lat: 13.102952374722694,
        lng: 80.2458120572286,
      },
      {
        lat: 13.09727017693052,
        lng: 80.2459938114343,
      },
      {
        lat: 13.100437072899158,
        lng: 80.25083092047053,
      },
      {
        lat: 13.10550910568124,
        lng: 80.24447296276351,
      },
      {
        lat: 13.097965352399138,
        lng: 80.24942807969192,
      },
      {
        lat: 13.099836462568106,
        lng: 80.24596459000362,
      },
      {
        lat: 13.100110522053448,
        lng: 80.24401690007089,
      },
      {
        lat: 13.106386098900414,
        lng: 80.2436219404659,
      },
      {
        lat: 13.10434793083597,
        lng: 80.24507898012246,
      },
      {
        lat: 13.10373965667042,
        lng: 80.24497614122807,
      },
      {
        lat: 13.107134036817282,
        lng: 80.24616773292449,
      },
      {
        lat: 13.104478929185005,
        lng: 80.24821288702017,
      },
      {
        lat: 13.107680602021288,
        lng: 80.24618146564394,
      },
      {
        lat: 13.100174761415582,
        lng: 80.24506937472633,
      },
      {
        lat: 13.101776316897643,
        lng: 80.2445008090956,
      },
      {
        lat: 13.107102961857489,
        lng: 80.2465093519509,
      },
      {
        lat: 13.102432746480646,
        lng: 80.24648091017437,
      },
      {
        lat: 13.10401720085767,
        lng: 80.24471744537972,
      },
      {
        lat: 13.106388680492236,
        lng: 80.24393241162515,
      },
      {
        lat: 13.099670046616088,
        lng: 80.24892543426004,
      },
      {
        lat: 13.104838010765318,
        lng: 80.24847772842071,
      },
      {
        lat: 13.099164531885325,
        lng: 80.24611703794542,
      },
      {
        lat: 13.102142541634619,
        lng: 80.24929775913553,
      },
      {
        lat: 13.100703112135738,
        lng: 80.24931024100206,
      },
      {
        lat: 13.104378719525279,
        lng: 80.24822383708788,
      },
      {
        lat: 13.09765724867433,
        lng: 80.2459920539497,
      },
      {
        lat: 13.103128285233193,
        lng: 80.2515920251294,
      },
      {
        lat: 13.101720762907354,
        lng: 80.24912685296752,
      },
      {
        lat: 13.106503128487644,
        lng: 80.24344811498683,
      },
      {
        lat: 13.105599686439184,
        lng: 80.245857126367,
      },
      {
        lat: 13.098424058020708,
        lng: 80.2481720256996,
      },
      {
        lat: 13.107207632842943,
        lng: 80.24558928192741,
      },
      {
        lat: 13.103002068362924,
        lng: 80.25224023349547,
      },
      {
        lat: 13.102666963711657,
        lng: 80.25190682016611,
      },
      {
        lat: 13.101205345215988,
        lng: 80.24890455997728,
      },
      {
        lat: 13.105808435691253,
        lng: 80.24396565518305,
      },
      {
        lat: 13.09795973862508,
        lng: 80.24953405911837,
      },
      {
        lat: 13.102252639405746,
        lng: 80.24532620475578,
      },
      {
        lat: 13.100531253383439,
        lng: 80.25165143113064,
      },
      {
        lat: 13.102978244844332,
        lng: 80.24687825685227,
      },
      {
        lat: 13.097265284021779,
        lng: 80.24453095143626,
      },
      {
        lat: 13.105815102304332,
        lng: 80.24342889890153,
      },
      {
        lat: 13.10453881007312,
        lng: 80.2455644163803,
      },
      {
        lat: 13.098184074348424,
        lng: 80.24946686646373,
      },
      {
        lat: 13.101640821927388,
        lng: 80.25139967736656,
      },
      {
        lat: 13.097478686230241,
        lng: 80.24766925423158,
      },
      {
        lat: 13.099267172723396,
        lng: 80.24949070172752,
      },
      {
        lat: 13.097417383903634,
        lng: 80.24327136913513,
      },
      {
        lat: 13.103035730304802,
        lng: 80.24421115262541,
      },
      {
        lat: 13.101931713442845,
        lng: 80.24463415747809,
      },
      {
        lat: 13.098863762402559,
        lng: 80.24837590828484,
      },
      {
        lat: 13.104696180846172,
        lng: 80.24723059233645,
      },
      {
        lat: 13.100552973231373,
        lng: 80.2434235551132,
      },
      {
        lat: 13.101810587960644,
        lng: 80.25009232207243,
      },
      {
        lat: 13.101552910740024,
        lng: 80.24776008881912,
      },
      {
        lat: 13.106299119099651,
        lng: 80.24600039216308,
      },
      {
        lat: 13.10114408009448,
        lng: 80.25013800411101,
      },
      {
        lat: 13.103508324855076,
        lng: 80.24821784631035,
      },
      {
        lat: 13.101049107858062,
        lng: 80.24608458066488,
      },
      {
        lat: 13.102859536072664,
        lng: 80.2432735810521,
      },
      {
        lat: 13.097002511172494,
        lng: 80.24669770895512,
      },
      {
        lat: 13.105219698890492,
        lng: 80.24574846179867,
      },
      {
        lat: 13.098398044482964,
        lng: 80.24490627301543,
      },
      {
        lat: 13.104310855773795,
        lng: 80.24865620765814,
      },
      {
        lat: 13.0976284599061,
        lng: 80.24596703925214,
      },
      {
        lat: 13.100126108337161,
        lng: 80.2434346440662,
      },
      {
        lat: 13.09968944618803,
        lng: 80.24527201019922,
      },
      {
        lat: 13.103295186189904,
        lng: 80.24454821435015,
      },
      {
        lat: 13.10658183655874,
        lng: 80.24663909366937,
      },
      {
        lat: 13.103278534145106,
        lng: 80.24389202226644,
      },
      {
        lat: 13.098116424058656,
        lng: 80.25054221584624,
      },
      {
        lat: 13.09828149995709,
        lng: 80.24938354946107,
      },
      {
        lat: 13.102925485161393,
        lng: 80.2491077839451,
      },
      {
        lat: 13.0986749840049,
        lng: 80.24933110999031,
      },
      {
        lat: 13.09874620773319,
        lng: 80.24516430880412,
      },
      {
        lat: 13.102892434058125,
        lng: 80.2477964868063,
      },
      {
        lat: 13.09922137290696,
        lng: 80.24592683879331,
      },
      {
        lat: 13.098695721273444,
        lng: 80.24468419779903,
      },
      {
        lat: 13.098696715750988,
        lng: 80.24319599698501,
      },
      {
        lat: 13.103651520140197,
        lng: 80.24667109037307,
      },
      {
        lat: 13.106003286649118,
        lng: 80.24596293232672,
      },
      {
        lat: 13.099280350362585,
        lng: 80.24970705384061,
      },
      {
        lat: 13.10259270470578,
        lng: 80.25187851748395,
      },
      {
        lat: 13.09701348992012,
        lng: 80.24439054588812,
      },
      {
        lat: 13.105585213041277,
        lng: 80.24491378582779,
      },
      {
        lat: 13.098111026365375,
        lng: 80.2447866077763,
      },
      {
        lat: 13.100144189831056,
        lng: 80.2466372630837,
      },
      {
        lat: 13.108277254818436,
        lng: 80.24244728849311,
      },
      {
        lat: 13.097295187484594,
        lng: 80.24253131861329,
      },
      {
        lat: 13.10669145834115,
        lng: 80.24617967752118,
      },
      {
        lat: 13.10252881656775,
        lng: 80.25132601141219,
      },
      {
        lat: 13.099004121274794,
        lng: 80.24619611040582,
      },
      {
        lat: 13.103164134247685,
        lng: 80.2521579149345,
      },
      {
        lat: 13.103160248047649,
        lng: 80.2488661684294,
      },
      {
        lat: 13.098993171899476,
        lng: 80.24953049193803,
      },
      {
        lat: 13.103156113017583,
        lng: 80.24460436322462,
      },
      {
        lat: 13.099843952218798,
        lng: 80.2503540961618,
      },
      {
        lat: 13.108263480641646,
        lng: 80.24242787632078,
      },
      {
        lat: 13.106991354756921,
        lng: 80.2449746313051,
      },
      {
        lat: 13.100798758932163,
        lng: 80.24969357790441,
      },
      {
        lat: 13.106028669097366,
        lng: 80.24596514478722,
      },
      {
        lat: 13.098154276748337,
        lng: 80.2496770346568,
      },
      {
        lat: 13.100337696552181,
        lng: 80.24645401648723,
      },
      {
        lat: 13.108452528322298,
        lng: 80.2426500907033,
      },
      {
        lat: 13.10397396576079,
        lng: 80.246298505359,
      },
      {
        lat: 13.099545107469435,
        lng: 80.24851204814641,
      },
      {
        lat: 13.10026493496841,
        lng: 80.24962311641525,
      },
      {
        lat: 13.107964339866715,
        lng: 80.24219351021769,
      },
      {
        lat: 13.105522836113947,
        lng: 80.24531318181043,
      },
      {
        lat: 13.102421082115502,
        lng: 80.2452195030526,
      },
      {
        lat: 13.102870253819955,
        lng: 80.24572796856313,
      },
      {
        lat: 13.104581667885366,
        lng: 80.24308263033056,
      },
      {
        lat: 13.098571735188461,
        lng: 80.2478686873028,
      },
      {
        lat: 13.097022094014106,
        lng: 80.24677004562389,
      },
      {
        lat: 13.098923497222502,
        lng: 80.24402446471349,
      },
      {
        lat: 13.10414266967159,
        lng: 80.24481445586363,
      },
      {
        lat: 13.099526296154735,
        lng: 80.25138158367615,
      },
      {
        lat: 13.098444800357523,
        lng: 80.24407243905632,
      },
      {
        lat: 13.099466866496245,
        lng: 80.2491334956373,
      },
      {
        lat: 13.0976721312121,
        lng: 80.24227077408298,
      },
      {
        lat: 13.102942746462892,
        lng: 80.24985301031688,
      },
      {
        lat: 13.10272047480349,
        lng: 80.24981459497403,
      },
      {
        lat: 13.102322069271034,
        lng: 80.25049601383036,
      },
      {
        lat: 13.101973234634489,
        lng: 80.24779650657868,
      },
      {
        lat: 13.102046248224232,
        lng: 80.24865929551146,
      },
      {
        lat: 13.104589846890464,
        lng: 80.2488833301745,
      },
      {
        lat: 13.099702449521246,
        lng: 80.24892159080424,
      },
      {
        lat: 13.104090955882125,
        lng: 80.24717229622038,
      },
      {
        lat: 13.105085849853207,
        lng: 80.24523232661181,
      },
      {
        lat: 13.09814312521162,
        lng: 80.245802821465,
      },
      {
        lat: 13.100892053123216,
        lng: 80.24903294785527,
      },
      {
        lat: 13.102680711896136,
        lng: 80.24386260894346,
      },
      {
        lat: 13.097519679104597,
        lng: 80.24847272170703,
      },
      {
        lat: 13.100048719950333,
        lng: 80.25046236622013,
      },
      {
        lat: 13.09808181703219,
        lng: 80.24983941738927,
      },
      {
        lat: 13.100213697726627,
        lng: 80.24893144443041,
      },
      {
        lat: 13.1029940690298,
        lng: 80.24354865113291,
      },
      {
        lat: 13.098871225143144,
        lng: 80.24421708983489,
      },
      {
        lat: 13.107637567703396,
        lng: 80.24626490024221,
      },
      {
        lat: 13.107269670437733,
        lng: 80.24596877516393,
      },
      {
        lat: 13.098884338406988,
        lng: 80.24432153359628,
      },
      {
        lat: 13.09900406922188,
        lng: 80.2463750133209,
      },
      {
        lat: 13.098505104526243,
        lng: 80.24357042805575,
      },
      {
        lat: 13.10405747711267,
        lng: 80.24418267456618,
      },
      {
        lat: 13.10117670711866,
        lng: 80.2519017933726,
      },
      {
        lat: 13.099704511408953,
        lng: 80.24436772733867,
      },
      {
        lat: 13.106701574792005,
        lng: 80.24258593216547,
      },
      {
        lat: 13.101750695160568,
        lng: 80.24947759110694,
      },
      {
        lat: 13.10424600664389,
        lng: 80.24425878669634,
      },
      {
        lat: 13.104160983473477,
        lng: 80.24751197787423,
      },
      {
        lat: 13.104337149563671,
        lng: 80.24418339691007,
      },
      {
        lat: 13.0985636269912,
        lng: 80.24369108246121,
      },
    ],
    cases: 166,
    coords: [
      {
        lat: 13.103148918711943,
        lng: 80.25277671511499,
      },
      {
        lat: 13.103103597336878,
        lng: 80.25276488976064,
      },
      {
        lat: 13.099061060699592,
        lng: 80.25123478559034,
      },
      {
        lat: 13.098114171941045,
        lng: 80.2509716510382,
      },
      {
        lat: 13.097018036452242,
        lng: 80.2468928456175,
      },
      {
        lat: 13.096886549663566,
        lng: 80.24599708322593,
      },
      {
        lat: 13.09692145633676,
        lng: 80.24482876428755,
      },
      {
        lat: 13.097341978239395,
        lng: 80.24212660344686,
      },
      {
        lat: 13.098272340789306,
        lng: 80.2424395692505,
      },
      {
        lat: 13.101113133921233,
        lng: 80.24324982939156,
      },
      {
        lat: 13.102079632580567,
        lng: 80.24328336478835,
      },
      {
        lat: 13.10262488303121,
        lng: 80.243132747253,
      },
      {
        lat: 13.103653763381661,
        lng: 80.24306146688357,
      },
      {
        lat: 13.10533393566095,
        lng: 80.24300553202441,
      },
      {
        lat: 13.105395302498438,
        lng: 80.24273386434517,
      },
      {
        lat: 13.105268763121908,
        lng: 80.24265150502973,
      },
      {
        lat: 13.105245474375996,
        lng: 80.24227626599878,
      },
      {
        lat: 13.105349232433346,
        lng: 80.24206681224003,
      },
      {
        lat: 13.106693700084309,
        lng: 80.24207629122449,
      },
      {
        lat: 13.106777087100275,
        lng: 80.24197129768474,
      },
      {
        lat: 13.106924124374745,
        lng: 80.24197005327018,
      },
      {
        lat: 13.107029531569365,
        lng: 80.24203173387,
      },
      {
        lat: 13.108625809406005,
        lng: 80.24199736659381,
      },
      {
        lat: 13.10840743650455,
        lng: 80.24408497101551,
      },
      {
        lat: 13.108054532252996,
        lng: 80.24477625730647,
      },
      {
        lat: 13.107955341037787,
        lng: 80.24573654467396,
      },
      {
        lat: 13.107938141414726,
        lng: 80.24636241712129,
      },
      {
        lat: 13.107709112548285,
        lng: 80.24669807645611,
      },
      {
        lat: 13.107415672283155,
        lng: 80.24680484773094,
      },
      {
        lat: 13.10609068767626,
        lng: 80.24654491310345,
      },
      {
        lat: 13.105859629102431,
        lng: 80.24654686861211,
      },
      {
        lat: 13.105542900091327,
        lng: 80.24627840085617,
      },
      {
        lat: 13.105374223208392,
        lng: 80.24617554059857,
      },
      {
        lat: 13.105269957724134,
        lng: 80.24630156840092,
      },
      {
        lat: 13.104854671781455,
        lng: 80.24709767045806,
      },
      {
        lat: 13.10475218229142,
        lng: 80.24751568910824,
      },
      {
        lat: 13.104840643576546,
        lng: 80.24824495513367,
      },
      {
        lat: 13.104844449278334,
        lng: 80.24887064980749,
      },
      {
        lat: 13.104640358295294,
        lng: 80.2498526825317,
      },
      {
        lat: 13.104497507292903,
        lng: 80.25054219108765,
      },
      {
        lat: 13.104414247133715,
        lng: 80.25066804111647,
      },
      {
        lat: 13.104141051053073,
        lng: 80.25064949568308,
      },
      {
        lat: 13.103846215363898,
        lng: 80.25052684557728,
      },
      {
        lat: 13.103657548009929,
        lng: 80.25059101500642,
      },
      {
        lat: 13.103532530914286,
        lng: 80.25075893356042,
      },
      {
        lat: 13.103148918711943,
        lng: 80.25277671511499,
      },
    ],
    properties: {
      Ward_No: 71,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#de4307",
      zname: "THIRU-VI-KA-NAGAR(71)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.124752317274998,
        lng: 80.24328630055292,
      },
      {
        lat: 13.099724575678659,
        lng: 80.23716379735647,
      },
      {
        lat: 13.10219356854848,
        lng: 80.24324099091893,
      },
      {
        lat: 13.100847805699358,
        lng: 80.23922001074688,
      },
      {
        lat: 13.110362808416212,
        lng: 80.2411971167699,
      },
      {
        lat: 13.106520677864333,
        lng: 80.24034143513997,
      },
      {
        lat: 13.100664250324076,
        lng: 80.24205030496097,
      },
      {
        lat: 13.111749366338485,
        lng: 80.2412980343805,
      },
      {
        lat: 13.121106312859466,
        lng: 80.24235470579221,
      },
      {
        lat: 13.111507568452202,
        lng: 80.23986561392681,
      },
      {
        lat: 13.122946372565204,
        lng: 80.24338598274728,
      },
      {
        lat: 13.104917633727124,
        lng: 80.24067017639564,
      },
      {
        lat: 13.116035297642492,
        lng: 80.24381187532502,
      },
      {
        lat: 13.108962557522931,
        lng: 80.23789071112233,
      },
      {
        lat: 13.115205432764258,
        lng: 80.24102352794881,
      },
      {
        lat: 13.114792983323461,
        lng: 80.24061528652513,
      },
      {
        lat: 13.10901620981255,
        lng: 80.23759630543348,
      },
      {
        lat: 13.111607818427338,
        lng: 80.24122274329359,
      },
      {
        lat: 13.114311744276293,
        lng: 80.23993738550446,
      },
      {
        lat: 13.098978391824826,
        lng: 80.23956494550885,
      },
      {
        lat: 13.1218704243205,
        lng: 80.24319478908888,
      },
      {
        lat: 13.106064213222426,
        lng: 80.23969990343265,
      },
      {
        lat: 13.102848361964696,
        lng: 80.2414753690003,
      },
      {
        lat: 13.116926185163251,
        lng: 80.24292117595463,
      },
      {
        lat: 13.11596827985133,
        lng: 80.24332953314945,
      },
      {
        lat: 13.121669028560756,
        lng: 80.24419309316089,
      },
      {
        lat: 13.121653514845896,
        lng: 80.24372949233043,
      },
      {
        lat: 13.098521128524453,
        lng: 80.23965853957256,
      },
      {
        lat: 13.101928398788973,
        lng: 80.24128647237055,
      },
      {
        lat: 13.126846778490693,
        lng: 80.24163569399481,
      },
      {
        lat: 13.12318732342223,
        lng: 80.24502076378052,
      },
      {
        lat: 13.108817754275336,
        lng: 80.23822676303278,
      },
      {
        lat: 13.113472851626188,
        lng: 80.24145701088673,
      },
      {
        lat: 13.120405299820293,
        lng: 80.24304693988834,
      },
      {
        lat: 13.106464553163628,
        lng: 80.24146818677156,
      },
      {
        lat: 13.103351015991088,
        lng: 80.24223776987003,
      },
      {
        lat: 13.110888750402665,
        lng: 80.24310462082994,
      },
      {
        lat: 13.118528521038032,
        lng: 80.24450116606296,
      },
      {
        lat: 13.099418890147053,
        lng: 80.23858489185614,
      },
      {
        lat: 13.118573186735006,
        lng: 80.24318856344196,
      },
      {
        lat: 13.120784688821162,
        lng: 80.24440494976645,
      },
      {
        lat: 13.106853018404427,
        lng: 80.23976475062501,
      },
      {
        lat: 13.113070619022437,
        lng: 80.23940798012079,
      },
      {
        lat: 13.120601452608621,
        lng: 80.2452553466669,
      },
      {
        lat: 13.101657660243948,
        lng: 80.24263790185255,
      },
    ],
    cases: 45,
    coords: [
      {
        lat: 13.113381843112881,
        lng: 80.24366131621477,
      },
      {
        lat: 13.11297155993732,
        lng: 80.24352414920452,
      },
      {
        lat: 13.110794054919559,
        lng: 80.24355652813345,
      },
      {
        lat: 13.110386889417063,
        lng: 80.24360182449843,
      },
      {
        lat: 13.109841270536302,
        lng: 80.24398309597277,
      },
      {
        lat: 13.1096866470554,
        lng: 80.24397045445204,
      },
      {
        lat: 13.109685289528432,
        lng: 80.24374726371292,
      },
      {
        lat: 13.10970787250869,
        lng: 80.24284031353493,
      },
      {
        lat: 13.109621287880936,
        lng: 80.24246439256282,
      },
      {
        lat: 13.109578122835035,
        lng: 80.24229735620874,
      },
      {
        lat: 13.10966156818185,
        lng: 80.24215714859635,
      },
      {
        lat: 13.109744419610617,
        lng: 80.2419192950358,
      },
      {
        lat: 13.108625809406005,
        lng: 80.24199736659381,
      },
      {
        lat: 13.107029531569365,
        lng: 80.24203173387,
      },
      {
        lat: 13.106924124374745,
        lng: 80.24197005327018,
      },
      {
        lat: 13.106777087100275,
        lng: 80.24197129768474,
      },
      {
        lat: 13.106693700084309,
        lng: 80.24207629122449,
      },
      {
        lat: 13.105349232433346,
        lng: 80.24206681224003,
      },
      {
        lat: 13.105245474375996,
        lng: 80.24227626599878,
      },
      {
        lat: 13.105268763121908,
        lng: 80.24265150502973,
      },
      {
        lat: 13.105395302498438,
        lng: 80.24273386434517,
      },
      {
        lat: 13.10533393566095,
        lng: 80.24300553202441,
      },
      {
        lat: 13.103653763381661,
        lng: 80.24306146688357,
      },
      {
        lat: 13.10262488303121,
        lng: 80.243132747253,
      },
      {
        lat: 13.102079632580567,
        lng: 80.24328336478835,
      },
      {
        lat: 13.101113133921233,
        lng: 80.24324982939156,
      },
      {
        lat: 13.098272340789306,
        lng: 80.2424395692505,
      },
      {
        lat: 13.097341978239395,
        lng: 80.24212660344686,
      },
      {
        lat: 13.09762586420825,
        lng: 80.24199857967764,
      },
      {
        lat: 13.097717118380361,
        lng: 80.2414011068178,
      },
      {
        lat: 13.09795314084556,
        lng: 80.23860403829217,
      },
      {
        lat: 13.098506014117302,
        lng: 80.23589850405253,
      },
      {
        lat: 13.098630806152944,
        lng: 80.23561480027513,
      },
      {
        lat: 13.098915265147408,
        lng: 80.23558098753283,
      },
      {
        lat: 13.099454083426652,
        lng: 80.23576485912727,
      },
      {
        lat: 13.100530191916771,
        lng: 80.23588137291088,
      },
      {
        lat: 13.10094269009201,
        lng: 80.23609771887713,
      },
      {
        lat: 13.100944218160306,
        lng: 80.23634894828241,
      },
      {
        lat: 13.100946701271292,
        lng: 80.23675719606587,
      },
      {
        lat: 13.10113799600822,
        lng: 80.23700681942663,
      },
      {
        lat: 13.101581548936068,
        lng: 80.23712868669185,
      },
      {
        lat: 13.102087975402938,
        lng: 80.23718721125788,
      },
      {
        lat: 13.103669364308706,
        lng: 80.23717382755345,
      },
      {
        lat: 13.104524269360576,
        lng: 80.23732361873141,
      },
      {
        lat: 13.106928935539987,
        lng: 80.23746029387893,
      },
      {
        lat: 13.107434024947326,
        lng: 80.23729899271528,
      },
      {
        lat: 13.107780211429647,
        lng: 80.23701341521931,
      },
      {
        lat: 13.108124377034981,
        lng: 80.2371088638011,
      },
      {
        lat: 13.108552691755587,
        lng: 80.23721532990231,
      },
      {
        lat: 13.108694761865243,
        lng: 80.23713549106915,
      },
      {
        lat: 13.108880617589351,
        lng: 80.23644191730256,
      },
      {
        lat: 13.109022400736746,
        lng: 80.2363148990239,
      },
      {
        lat: 13.109212560898168,
        lng: 80.23632901694066,
      },
      {
        lat: 13.109657001290168,
        lng: 80.2364825284403,
      },
      {
        lat: 13.110482240276045,
        lng: 80.23674290818228,
      },
      {
        lat: 13.110957592852793,
        lng: 80.23677033973307,
      },
      {
        lat: 13.111305853141333,
        lng: 80.23673593773299,
      },
      {
        lat: 13.111591810789793,
        lng: 80.23687506322202,
      },
      {
        lat: 13.111577502547254,
        lng: 80.2371268209787,
      },
      {
        lat: 13.111327335445829,
        lng: 80.23766366611517,
      },
      {
        lat: 13.111407485532117,
        lng: 80.23782026069814,
      },
      {
        lat: 13.111740863653315,
        lng: 80.23794325756378,
      },
      {
        lat: 13.112263923665173,
        lng: 80.23800173993676,
      },
      {
        lat: 13.115245041226594,
        lng: 80.23881615043939,
      },
      {
        lat: 13.115345776166455,
        lng: 80.24052017057043,
      },
      {
        lat: 13.115682601455074,
        lng: 80.24018083190238,
      },
      {
        lat: 13.11581828662112,
        lng: 80.24020211609874,
      },
      {
        lat: 13.115932471818262,
        lng: 80.24040304253697,
      },
      {
        lat: 13.115959838525805,
        lng: 80.24118794965828,
      },
      {
        lat: 13.115850019273775,
        lng: 80.24170482739711,
      },
      {
        lat: 13.115896702977588,
        lng: 80.24195119018844,
      },
      {
        lat: 13.116056071084893,
        lng: 80.2421517342318,
      },
      {
        lat: 13.116125073371927,
        lng: 80.24235304306441,
      },
      {
        lat: 13.116239395005024,
        lng: 80.24257640088307,
      },
      {
        lat: 13.116600721849677,
        lng: 80.24255091034514,
      },
      {
        lat: 13.116848409240278,
        lng: 80.24241421889106,
      },
      {
        lat: 13.11690963468766,
        lng: 80.2413369390336,
      },
      {
        lat: 13.117157458514045,
        lng: 80.24122267896006,
      },
      {
        lat: 13.11749742182592,
        lng: 80.24139926204413,
      },
      {
        lat: 13.117700062742207,
        lng: 80.24128538436547,
      },
      {
        lat: 13.117742926243942,
        lng: 80.24090366850187,
      },
      {
        lat: 13.117696242540186,
        lng: 80.24065730571056,
      },
      {
        lat: 13.117784834695058,
        lng: 80.24036493297433,
      },
      {
        lat: 13.118033340700382,
        lng: 80.24036282980359,
      },
      {
        lat: 13.118230387749534,
        lng: 80.239329265523,
      },
      {
        lat: 13.118320071390754,
        lng: 80.23921634383089,
      },
      {
        lat: 13.118704398997847,
        lng: 80.23925795623715,
      },
      {
        lat: 13.120355758187799,
        lng: 80.23960290091964,
      },
      {
        lat: 13.12108059484963,
        lng: 80.23991082193213,
      },
      {
        lat: 13.121645790532757,
        lng: 80.23997333614018,
      },
      {
        lat: 13.121827750095257,
        lng: 80.24017368898629,
      },
      {
        lat: 13.121852797394379,
        lng: 80.24057726263851,
      },
      {
        lat: 13.121700660383754,
        lng: 80.2415655817633,
      },
      {
        lat: 13.121434611047567,
        lng: 80.242397837211,
      },
      {
        lat: 13.121435975405431,
        lng: 80.24262215101636,
      },
      {
        lat: 13.1215266140971,
        lng: 80.24266624898819,
      },
      {
        lat: 13.122466457957547,
        lng: 80.24225713702083,
      },
      {
        lat: 13.123703726361597,
        lng: 80.24212373497356,
      },
      {
        lat: 13.126465139799167,
        lng: 80.24152668770398,
      },
      {
        lat: 13.12685769215258,
        lng: 80.24154006643502,
      },
      {
        lat: 13.127008028429291,
        lng: 80.24242065845195,
      },
      {
        lat: 13.127164385737483,
        lng: 80.24255172760925,
      },
      {
        lat: 13.127232124542434,
        lng: 80.24272767758451,
      },
      {
        lat: 13.127810292059472,
        lng: 80.24278898062776,
      },
      {
        lat: 13.127810963073985,
        lng: 80.24289930199032,
      },
      {
        lat: 13.12716666718682,
        lng: 80.24292682024173,
      },
      {
        lat: 13.127211915858782,
        lng: 80.24305882974016,
      },
      {
        lat: 13.127280057272444,
        lng: 80.24330097253294,
      },
      {
        lat: 13.12728113089566,
        lng: 80.24347748671299,
      },
      {
        lat: 13.126948341798474,
        lng: 80.24356856482643,
      },
      {
        lat: 13.123312433313194,
        lng: 80.24498945718533,
      },
      {
        lat: 13.123071886197662,
        lng: 80.24563138983835,
      },
      {
        lat: 13.122716606967705,
        lng: 80.24567852747522,
      },
      {
        lat: 13.122450080443715,
        lng: 80.24570284856655,
      },
      {
        lat: 13.12236253556364,
        lng: 80.24592424356439,
      },
      {
        lat: 13.122162405815585,
        lng: 80.24590387190611,
      },
      {
        lat: 13.120502861048772,
        lng: 80.24525595481519,
      },
      {
        lat: 13.120079977216706,
        lng: 80.24514920674926,
      },
      {
        lat: 13.119881055294599,
        lng: 80.2453274135434,
      },
      {
        lat: 13.119751482612386,
        lng: 80.24594634158261,
      },
      {
        lat: 13.11953033896326,
        lng: 80.24612473644504,
      },
      {
        lat: 13.119329806606329,
        lng: 80.24603817196926,
      },
      {
        lat: 13.119173046689605,
        lng: 80.2458409099945,
      },
      {
        lat: 13.119015615758304,
        lng: 80.24553332665724,
      },
      {
        lat: 13.119058717183664,
        lng: 80.24531230779569,
      },
      {
        lat: 13.119124308742183,
        lng: 80.24513522941102,
      },
      {
        lat: 13.119145591049225,
        lng: 80.24498059143535,
      },
      {
        lat: 13.118966743607984,
        lng: 80.24480558180129,
      },
      {
        lat: 13.116964104097944,
        lng: 80.244381222493,
      },
      {
        lat: 13.11594136783309,
        lng: 80.24430161654209,
      },
      {
        lat: 13.114315826671653,
        lng: 80.24376373871121,
      },
      {
        lat: 13.113381843112881,
        lng: 80.24366131621477,
      },
    ],
    properties: {
      Ward_No: 69,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#8bc24c",
      zname: "THIRU-VI-KA-NAGAR(69)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.105552653765544,
        lng: 80.26421761120012,
      },
      {
        lat: 13.102901203107786,
        lng: 80.26591630278656,
      },
      {
        lat: 13.100613095428704,
        lng: 80.26880863875802,
      },
      {
        lat: 13.10957227086702,
        lng: 80.26578640186061,
      },
      {
        lat: 13.102874426774402,
        lng: 80.26721605832451,
      },
      {
        lat: 13.10494509158662,
        lng: 80.26581143737585,
      },
      {
        lat: 13.109091233586739,
        lng: 80.26443179726466,
      },
      {
        lat: 13.10940256000256,
        lng: 80.26480133896098,
      },
      {
        lat: 13.104814647408281,
        lng: 80.26899671928102,
      },
      {
        lat: 13.104300960724357,
        lng: 80.26357103578627,
      },
      {
        lat: 13.098269339781597,
        lng: 80.26841273210329,
      },
      {
        lat: 13.096395808729257,
        lng: 80.2689391574963,
      },
      {
        lat: 13.103060395595339,
        lng: 80.26675923402884,
      },
      {
        lat: 13.101980778812392,
        lng: 80.2680845177387,
      },
      {
        lat: 13.105501261424733,
        lng: 80.26846720702864,
      },
      {
        lat: 13.10420619158951,
        lng: 80.26325725258158,
      },
      {
        lat: 13.103176576423943,
        lng: 80.26488167889478,
      },
      {
        lat: 13.106939234998451,
        lng: 80.26144725220567,
      },
      {
        lat: 13.102052517680933,
        lng: 80.27040451110338,
      },
      {
        lat: 13.103008663124593,
        lng: 80.26375057115528,
      },
      {
        lat: 13.107032039281123,
        lng: 80.26245276902031,
      },
      {
        lat: 13.101722776771263,
        lng: 80.26924480876654,
      },
      {
        lat: 13.101324815909807,
        lng: 80.2682958312308,
      },
      {
        lat: 13.10704327872959,
        lng: 80.26373425514797,
      },
      {
        lat: 13.100287719380823,
        lng: 80.26990449986961,
      },
      {
        lat: 13.106202099672126,
        lng: 80.2636822019078,
      },
      {
        lat: 13.106457425808525,
        lng: 80.2660787926873,
      },
      {
        lat: 13.106998630160135,
        lng: 80.26874605526946,
      },
      {
        lat: 13.10701314744555,
        lng: 80.2663678940963,
      },
      {
        lat: 13.104031465965553,
        lng: 80.26948793471401,
      },
      {
        lat: 13.096557015363851,
        lng: 80.2693528070549,
      },
      {
        lat: 13.103991714112505,
        lng: 80.26217448698155,
      },
      {
        lat: 13.10041275991472,
        lng: 80.26996937406372,
      },
      {
        lat: 13.105416498359572,
        lng: 80.26832606512237,
      },
      {
        lat: 13.109992921203206,
        lng: 80.26587075564521,
      },
      {
        lat: 13.107773390046354,
        lng: 80.26604149868251,
      },
      {
        lat: 13.107991136935405,
        lng: 80.26476613752182,
      },
      {
        lat: 13.096363319710454,
        lng: 80.27173297219741,
      },
      {
        lat: 13.103326422780968,
        lng: 80.26246983898531,
      },
      {
        lat: 13.098050037754627,
        lng: 80.27019703587396,
      },
      {
        lat: 13.105286999295023,
        lng: 80.26751102875977,
      },
      {
        lat: 13.102908166697818,
        lng: 80.26529968358997,
      },
      {
        lat: 13.104554846653258,
        lng: 80.26541598964096,
      },
      {
        lat: 13.107639070703977,
        lng: 80.26173533881965,
      },
      {
        lat: 13.101157287800575,
        lng: 80.27059303373579,
      },
      {
        lat: 13.108278946993583,
        lng: 80.26154650777308,
      },
      {
        lat: 13.102085059781901,
        lng: 80.26744148742483,
      },
      {
        lat: 13.104070186806016,
        lng: 80.26217506082439,
      },
      {
        lat: 13.10909997281437,
        lng: 80.26602017768627,
      },
      {
        lat: 13.107069437466404,
        lng: 80.26877324795039,
      },
      {
        lat: 13.099791150542407,
        lng: 80.26840878430913,
      },
      {
        lat: 13.10679957962844,
        lng: 80.26806312787666,
      },
      {
        lat: 13.099972032265905,
        lng: 80.27001411165412,
      },
      {
        lat: 13.104324292913645,
        lng: 80.27114483034063,
      },
      {
        lat: 13.108757600732783,
        lng: 80.2623478177632,
      },
      {
        lat: 13.104846534860126,
        lng: 80.26240960476595,
      },
      {
        lat: 13.099566224901684,
        lng: 80.26995601422243,
      },
      {
        lat: 13.104749257782917,
        lng: 80.26118221013823,
      },
      {
        lat: 13.096779498583373,
        lng: 80.2715472850951,
      },
      {
        lat: 13.109052491746548,
        lng: 80.2634256758916,
      },
      {
        lat: 13.106648043190113,
        lng: 80.26566162903819,
      },
      {
        lat: 13.106860715480543,
        lng: 80.26551995711309,
      },
      {
        lat: 13.107223431528197,
        lng: 80.26517174807557,
      },
      {
        lat: 13.104091184966373,
        lng: 80.26250176764954,
      },
      {
        lat: 13.099238280180206,
        lng: 80.26959858607239,
      },
      {
        lat: 13.103223688258431,
        lng: 80.26122445046018,
      },
      {
        lat: 13.100702930757418,
        lng: 80.2696521288574,
      },
      {
        lat: 13.10563069227467,
        lng: 80.26319405397844,
      },
      {
        lat: 13.104324184315645,
        lng: 80.26500244581679,
      },
      {
        lat: 13.096860004545295,
        lng: 80.2693526244426,
      },
      {
        lat: 13.102793146856747,
        lng: 80.26965593404496,
      },
      {
        lat: 13.09813786400556,
        lng: 80.26841297599611,
      },
      {
        lat: 13.105608204223188,
        lng: 80.26167544896302,
      },
      {
        lat: 13.101590639155233,
        lng: 80.26707827668208,
      },
      {
        lat: 13.09774738229052,
        lng: 80.26993119877153,
      },
      {
        lat: 13.102801183405155,
        lng: 80.26566295774838,
      },
      {
        lat: 13.107232355113158,
        lng: 80.26862784611357,
      },
      {
        lat: 13.107853858053069,
        lng: 80.26228081638517,
      },
      {
        lat: 13.10294406000315,
        lng: 80.26237657526175,
      },
      {
        lat: 13.107573865398287,
        lng: 80.2625364054505,
      },
      {
        lat: 13.105847381591206,
        lng: 80.26683794427201,
      },
      {
        lat: 13.109313839743054,
        lng: 80.26623189076729,
      },
      {
        lat: 13.105675609486182,
        lng: 80.26848674754797,
      },
      {
        lat: 13.104949264952616,
        lng: 80.26766154047704,
      },
      {
        lat: 13.101039537515248,
        lng: 80.27083925611693,
      },
      {
        lat: 13.108650623952439,
        lng: 80.26493443971354,
      },
      {
        lat: 13.107357747008319,
        lng: 80.26280097330184,
      },
      {
        lat: 13.10353680133301,
        lng: 80.26743522394665,
      },
      {
        lat: 13.10059563583416,
        lng: 80.27078848676469,
      },
      {
        lat: 13.0960926290853,
        lng: 80.26886551252498,
      },
      {
        lat: 13.096606657068188,
        lng: 80.26913470617876,
      },
      {
        lat: 13.108835444809404,
        lng: 80.26580819250661,
      },
      {
        lat: 13.103472807215885,
        lng: 80.26117521792236,
      },
      {
        lat: 13.10094073471809,
        lng: 80.26900250108775,
      },
      {
        lat: 13.102803798893646,
        lng: 80.26085774246621,
      },
      {
        lat: 13.108536237230854,
        lng: 80.26374651295734,
      },
      {
        lat: 13.097355271046387,
        lng: 80.27370622800402,
      },
      {
        lat: 13.098124950698017,
        lng: 80.27083745198415,
      },
      {
        lat: 13.09767878711775,
        lng: 80.26889319430177,
      },
      {
        lat: 13.10566917472378,
        lng: 80.26708174264392,
      },
      {
        lat: 13.104698663697858,
        lng: 80.27033547901517,
      },
      {
        lat: 13.096618440299077,
        lng: 80.27367863058637,
      },
      {
        lat: 13.097203883877851,
        lng: 80.27318180850314,
      },
      {
        lat: 13.10271509602059,
        lng: 80.27078196757873,
      },
      {
        lat: 13.097048347217731,
        lng: 80.26961237423305,
      },
      {
        lat: 13.107834247363796,
        lng: 80.26812428909057,
      },
      {
        lat: 13.102206473556757,
        lng: 80.2669279820848,
      },
      {
        lat: 13.105340995659914,
        lng: 80.26425676905481,
      },
      {
        lat: 13.106470956010984,
        lng: 80.26155931673341,
      },
      {
        lat: 13.100621088686019,
        lng: 80.27018980870892,
      },
      {
        lat: 13.105209249058884,
        lng: 80.26725097965696,
      },
      {
        lat: 13.10900594627581,
        lng: 80.26596725561994,
      },
      {
        lat: 13.09807425864277,
        lng: 80.26937820737993,
      },
      {
        lat: 13.105452315570004,
        lng: 80.26897333430267,
      },
      {
        lat: 13.10831735520152,
        lng: 80.26420743159831,
      },
      {
        lat: 13.103758265193575,
        lng: 80.26369889805655,
      },
      {
        lat: 13.104764443981546,
        lng: 80.26114538673393,
      },
      {
        lat: 13.0976067339898,
        lng: 80.26979433078513,
      },
      {
        lat: 13.108954342644418,
        lng: 80.26588216831848,
      },
      {
        lat: 13.104099471456669,
        lng: 80.26558938434002,
      },
      {
        lat: 13.100339370969344,
        lng: 80.26636321445734,
      },
      {
        lat: 13.104292945797715,
        lng: 80.26654303969879,
      },
      {
        lat: 13.106619918162535,
        lng: 80.2619290227112,
      },
      {
        lat: 13.102460665408211,
        lng: 80.2685221086689,
      },
      {
        lat: 13.105262389203231,
        lng: 80.26099265248486,
      },
      {
        lat: 13.108081434630693,
        lng: 80.26539904789252,
      },
      {
        lat: 13.109083974018954,
        lng: 80.26680689450447,
      },
      {
        lat: 13.102220559083513,
        lng: 80.26657526675841,
      },
      {
        lat: 13.104922380055047,
        lng: 80.26102884756388,
      },
    ],
    cases: 129,
    coords: [
      {
        lat: 13.10260515701822,
        lng: 80.26061155178473,
      },
      {
        lat: 13.103279659859668,
        lng: 80.26067186966833,
      },
      {
        lat: 13.103792130724454,
        lng: 80.26096800730126,
      },
      {
        lat: 13.10432237211733,
        lng: 80.26107619778342,
      },
      {
        lat: 13.105891213886737,
        lng: 80.2609126828652,
      },
      {
        lat: 13.107195049178753,
        lng: 80.2607138514147,
      },
      {
        lat: 13.107950415674368,
        lng: 80.26051966179803,
      },
      {
        lat: 13.109313062909381,
        lng: 80.25977481437833,
      },
      {
        lat: 13.10915871864484,
        lng: 80.25997470930838,
      },
      {
        lat: 13.109138375758114,
        lng: 80.26028379719163,
      },
      {
        lat: 13.109275732208827,
        lng: 80.2609445969572,
      },
      {
        lat: 13.109560832414493,
        lng: 80.26397397117941,
      },
      {
        lat: 13.10967636974633,
        lng: 80.26470115183051,
      },
      {
        lat: 13.109789625629045,
        lng: 80.26505323984927,
      },
      {
        lat: 13.110034869846164,
        lng: 80.26518355673366,
      },
      {
        lat: 13.11039041748215,
        lng: 80.26518054764176,
      },
      {
        lat: 13.110546908993069,
        lng: 80.26533368107164,
      },
      {
        lat: 13.110704339924538,
        lng: 80.2656412644089,
      },
      {
        lat: 13.110019358264086,
        lng: 80.26628695842678,
      },
      {
        lat: 13.10818528852634,
        lng: 80.26800151707293,
      },
      {
        lat: 13.107699094584984,
        lng: 80.26844694002413,
      },
      {
        lat: 13.106572630843525,
        lng: 80.26958180940173,
      },
      {
        lat: 13.106043335476645,
        lng: 80.27024825121428,
      },
      {
        lat: 13.104538028748793,
        lng: 80.27120980357216,
      },
      {
        lat: 13.104265797848743,
        lng: 80.27132729756535,
      },
      {
        lat: 13.103115427083216,
        lng: 80.27125690751673,
      },
      {
        lat: 13.102751696835906,
        lng: 80.27115982844171,
      },
      {
        lat: 13.102085609349317,
        lng: 80.27110537126177,
      },
      {
        lat: 13.099695305871604,
        lng: 80.27116566398738,
      },
      {
        lat: 13.098039869271004,
        lng: 80.27097935953977,
      },
      {
        lat: 13.097273889476105,
        lng: 80.27108599964386,
      },
      {
        lat: 13.097194048729085,
        lng: 80.27122689574256,
      },
      {
        lat: 13.097215684112177,
        lng: 80.2714670904408,
      },
      {
        lat: 13.097370543104427,
        lng: 80.2737093060009,
      },
      {
        lat: 13.095774044412005,
        lng: 80.27370909303606,
      },
      {
        lat: 13.095829348860711,
        lng: 80.27249513528697,
      },
      {
        lat: 13.095925495514495,
        lng: 80.27103429218015,
      },
      {
        lat: 13.095637837767017,
        lng: 80.26863810698563,
      },
      {
        lat: 13.096730114662574,
        lng: 80.26862886276304,
      },
      {
        lat: 13.09786287992711,
        lng: 80.26836898512367,
      },
      {
        lat: 13.098282098428466,
        lng: 80.26821943422961,
      },
      {
        lat: 13.098743581292787,
        lng: 80.2681112407663,
      },
      {
        lat: 13.09970741596087,
        lng: 80.26770678989126,
      },
      {
        lat: 13.100272276312365,
        lng: 80.2673265732021,
      },
      {
        lat: 13.100324382608623,
        lng: 80.26553238181634,
      },
      {
        lat: 13.10053456271457,
        lng: 80.26555146057046,
      },
      {
        lat: 13.101291769265098,
        lng: 80.2657119126371,
      },
      {
        lat: 13.10156521905919,
        lng: 80.26577217104882,
      },
      {
        lat: 13.101669611400004,
        lng: 80.2656669997356,
      },
      {
        lat: 13.101667454835658,
        lng: 80.26531243942033,
      },
      {
        lat: 13.101496875101828,
        lng: 80.26489673182583,
      },
      {
        lat: 13.101452073604115,
        lng: 80.26443824461214,
      },
      {
        lat: 13.102144741899226,
        lng: 80.26434895212958,
      },
      {
        lat: 13.102328715554334,
        lng: 80.26351309260292,
      },
      {
        lat: 13.102486355902156,
        lng: 80.26180143830604,
      },
      {
        lat: 13.10260515701822,
        lng: 80.26061155178473,
      },
    ],
    properties: {
      Ward_No: 72,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#de4307",
      zname: "THIRU-VI-KA-NAGAR(72)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.094861387219488,
        lng: 80.2490704514001,
      },
      {
        lat: 13.09178963632921,
        lng: 80.24900494331962,
      },
      {
        lat: 13.089857833223078,
        lng: 80.24563663577824,
      },
      {
        lat: 13.091788264084224,
        lng: 80.24255085652614,
      },
      {
        lat: 13.092044965879994,
        lng: 80.24621942863634,
      },
      {
        lat: 13.094675019016133,
        lng: 80.24881092289557,
      },
      {
        lat: 13.095670092384381,
        lng: 80.24859095869378,
      },
      {
        lat: 13.092144486295593,
        lng: 80.24564301381716,
      },
      {
        lat: 13.094226551133701,
        lng: 80.24893671328455,
      },
      {
        lat: 13.09033532107648,
        lng: 80.24707627386638,
      },
      {
        lat: 13.08766596802536,
        lng: 80.2447024019688,
      },
      {
        lat: 13.089796836939046,
        lng: 80.24696461132619,
      },
      {
        lat: 13.09525371963738,
        lng: 80.24666601508231,
      },
      {
        lat: 13.087790610643905,
        lng: 80.24652708888158,
      },
      {
        lat: 13.095289111855465,
        lng: 80.24841596482622,
      },
      {
        lat: 13.095833107037702,
        lng: 80.25017740762024,
      },
      {
        lat: 13.091571438450403,
        lng: 80.24644019888777,
      },
      {
        lat: 13.09256219782837,
        lng: 80.24939152587928,
      },
      {
        lat: 13.089158106492276,
        lng: 80.244588501181,
      },
      {
        lat: 13.088949194675736,
        lng: 80.24247440462207,
      },
      {
        lat: 13.09361549085496,
        lng: 80.24404188882868,
      },
      {
        lat: 13.094637157240923,
        lng: 80.24846706047431,
      },
      {
        lat: 13.092841065856495,
        lng: 80.24358568757341,
      },
      {
        lat: 13.090217446798595,
        lng: 80.24559624252518,
      },
      {
        lat: 13.095189820885365,
        lng: 80.24912020307698,
      },
      {
        lat: 13.091431127239842,
        lng: 80.24329585739517,
      },
      {
        lat: 13.093130521527202,
        lng: 80.24271464789312,
      },
      {
        lat: 13.093537830462006,
        lng: 80.24322058762698,
      },
      {
        lat: 13.089371721592514,
        lng: 80.24293606545228,
      },
      {
        lat: 13.096112448084483,
        lng: 80.25029627251185,
      },
      {
        lat: 13.096493934449386,
        lng: 80.24514254927368,
      },
      {
        lat: 13.09162371983995,
        lng: 80.24198786862999,
      },
      {
        lat: 13.091799556302476,
        lng: 80.24331124143959,
      },
      {
        lat: 13.095818759355483,
        lng: 80.25019455505705,
      },
      {
        lat: 13.093977063443049,
        lng: 80.24574529333603,
      },
      {
        lat: 13.094565883415683,
        lng: 80.24926060623854,
      },
      {
        lat: 13.095678612734574,
        lng: 80.24210691899786,
      },
      {
        lat: 13.088029749090657,
        lng: 80.2464743564794,
      },
      {
        lat: 13.091886651734828,
        lng: 80.24415997339365,
      },
      {
        lat: 13.094857711901236,
        lng: 80.24497733009247,
      },
      {
        lat: 13.097566799349266,
        lng: 80.24983228147649,
      },
      {
        lat: 13.090793314658187,
        lng: 80.24814701613283,
      },
      {
        lat: 13.094141300076473,
        lng: 80.24725965162699,
      },
      {
        lat: 13.095225492780889,
        lng: 80.24702895044437,
      },
      {
        lat: 13.096432687143265,
        lng: 80.24842666517516,
      },
      {
        lat: 13.088166064535,
        lng: 80.24328224462838,
      },
      {
        lat: 13.09369418847054,
        lng: 80.24778302285428,
      },
      {
        lat: 13.09008862057213,
        lng: 80.24672243378025,
      },
      {
        lat: 13.090590647943406,
        lng: 80.24510072910262,
      },
      {
        lat: 13.09434324333177,
        lng: 80.2422479633852,
      },
      {
        lat: 13.096169880485954,
        lng: 80.24889811278037,
      },
      {
        lat: 13.096775943993089,
        lng: 80.24656828486782,
      },
      {
        lat: 13.092339274264212,
        lng: 80.2446851002098,
      },
      {
        lat: 13.092131970087749,
        lng: 80.24895914162457,
      },
      {
        lat: 13.09013465916835,
        lng: 80.2421655610435,
      },
      {
        lat: 13.096578281573656,
        lng: 80.24758385905673,
      },
      {
        lat: 13.096882425180334,
        lng: 80.24615504245048,
      },
      {
        lat: 13.093925989138345,
        lng: 80.24747305288885,
      },
      {
        lat: 13.092059268546176,
        lng: 80.24787924541747,
      },
      {
        lat: 13.088459764291667,
        lng: 80.24693744160288,
      },
      {
        lat: 13.09114097826704,
        lng: 80.24706541092358,
      },
      {
        lat: 13.097213865136315,
        lng: 80.2422911746875,
      },
      {
        lat: 13.09567095125725,
        lng: 80.24638366710833,
      },
      {
        lat: 13.09515692842124,
        lng: 80.24948654917624,
      },
      {
        lat: 13.09126326040119,
        lng: 80.24595399608661,
      },
      {
        lat: 13.08972814903072,
        lng: 80.24561848931407,
      },
      {
        lat: 13.096541434278706,
        lng: 80.24913966008494,
      },
      {
        lat: 13.090873084475973,
        lng: 80.2434488763801,
      },
      {
        lat: 13.095740762425216,
        lng: 80.2482382732761,
      },
      {
        lat: 13.09157081776797,
        lng: 80.2452009257718,
      },
      {
        lat: 13.092101345798525,
        lng: 80.24917023415881,
      },
      {
        lat: 13.096195841171541,
        lng: 80.24806353313679,
      },
      {
        lat: 13.093642720519448,
        lng: 80.24817837194207,
      },
      {
        lat: 13.091969275160437,
        lng: 80.24878743326079,
      },
      {
        lat: 13.092947320720446,
        lng: 80.24880272582968,
      },
      {
        lat: 13.090238246960967,
        lng: 80.2452921144847,
      },
      {
        lat: 13.095632757886104,
        lng: 80.24778440257411,
      },
      {
        lat: 13.09553767233834,
        lng: 80.24429201657104,
      },
      {
        lat: 13.09213255694762,
        lng: 80.24203396741878,
      },
      {
        lat: 13.094008688386435,
        lng: 80.24546328598353,
      },
      {
        lat: 13.087168895950294,
        lng: 80.24521519151175,
      },
      {
        lat: 13.090174785563846,
        lng: 80.24169166636558,
      },
      {
        lat: 13.092042661237194,
        lng: 80.24097209245251,
      },
      {
        lat: 13.092142777974665,
        lng: 80.24570129739504,
      },
      {
        lat: 13.096828465001943,
        lng: 80.24225559641432,
      },
      {
        lat: 13.091399253774501,
        lng: 80.24633994401793,
      },
      {
        lat: 13.08779307192169,
        lng: 80.24445197353674,
      },
      {
        lat: 13.095206346073537,
        lng: 80.2484748258752,
      },
      {
        lat: 13.08990844097064,
        lng: 80.2412461724092,
      },
      {
        lat: 13.091562158115904,
        lng: 80.24759362285647,
      },
      {
        lat: 13.096326611192003,
        lng: 80.242682859485,
      },
      {
        lat: 13.093047391216771,
        lng: 80.24817833880063,
      },
      {
        lat: 13.089304261241706,
        lng: 80.2435720955938,
      },
      {
        lat: 13.088862234245257,
        lng: 80.24237983765804,
      },
      {
        lat: 13.089791060985831,
        lng: 80.24791639714391,
      },
      {
        lat: 13.0906691776014,
        lng: 80.24571247729374,
      },
      {
        lat: 13.089997456123648,
        lng: 80.24116256219965,
      },
      {
        lat: 13.097062364095589,
        lng: 80.24376709188518,
      },
      {
        lat: 13.092571000080328,
        lng: 80.24143289946015,
      },
      {
        lat: 13.087823355440143,
        lng: 80.24400087096366,
      },
      {
        lat: 13.094486580932589,
        lng: 80.24811967041434,
      },
      {
        lat: 13.092184200285935,
        lng: 80.2483261179912,
      },
      {
        lat: 13.094887236673147,
        lng: 80.2483674071047,
      },
      {
        lat: 13.097654724918385,
        lng: 80.25022476892525,
      },
      {
        lat: 13.088521571242987,
        lng: 80.24490925277199,
      },
      {
        lat: 13.088886622143882,
        lng: 80.24184512555865,
      },
      {
        lat: 13.093022823779632,
        lng: 80.2428414288337,
      },
      {
        lat: 13.091445639492465,
        lng: 80.24579481687609,
      },
      {
        lat: 13.096429683871897,
        lng: 80.24966091892917,
      },
      {
        lat: 13.090906085259736,
        lng: 80.24843963299615,
      },
      {
        lat: 13.095094190939715,
        lng: 80.2474738888349,
      },
      {
        lat: 13.092066704400771,
        lng: 80.24132633577742,
      },
      {
        lat: 13.094351494440104,
        lng: 80.24839869209984,
      },
      {
        lat: 13.091402758111851,
        lng: 80.24873336050508,
      },
      {
        lat: 13.093813519201861,
        lng: 80.24622919206654,
      },
      {
        lat: 13.093352230563708,
        lng: 80.24147895433791,
      },
      {
        lat: 13.093755889240052,
        lng: 80.24557232943685,
      },
      {
        lat: 13.093166378962938,
        lng: 80.24381403043776,
      },
      {
        lat: 13.092239313737524,
        lng: 80.24145280835756,
      },
      {
        lat: 13.089005183476177,
        lng: 80.245971104554,
      },
      {
        lat: 13.093031922189484,
        lng: 80.24546446107375,
      },
    ],
    cases: 121,
    coords: [
      {
        lat: 13.090946887732505,
        lng: 80.24894856722686,
      },
      {
        lat: 13.089835331293475,
        lng: 80.24820424760446,
      },
      {
        lat: 13.089103308191937,
        lng: 80.2474567158928,
      },
      {
        lat: 13.08792964224798,
        lng: 80.24690135367226,
      },
      {
        lat: 13.087229819950213,
        lng: 80.24624776531346,
      },
      {
        lat: 13.086654407670844,
        lng: 80.24524766582626,
      },
      {
        lat: 13.086618004679423,
        lng: 80.24446284160898,
      },
      {
        lat: 13.087624363322956,
        lng: 80.24351216576869,
      },
      {
        lat: 13.087811455872066,
        lng: 80.24307090826504,
      },
      {
        lat: 13.087933955805317,
        lng: 80.24241036037995,
      },
      {
        lat: 13.088089993602049,
        lng: 80.24206358157727,
      },
      {
        lat: 13.088405316340529,
        lng: 80.24190388645822,
      },
      {
        lat: 13.089507704369595,
        lng: 80.2411408296494,
      },
      {
        lat: 13.090549128944957,
        lng: 80.24075515229657,
      },
      {
        lat: 13.091656865213242,
        lng: 80.24087139840614,
      },
      {
        lat: 13.093650370277233,
        lng: 80.24101155331692,
      },
      {
        lat: 13.095487837544608,
        lng: 80.24149848703028,
      },
      {
        lat: 13.096739713395294,
        lng: 80.24191186352499,
      },
      {
        lat: 13.097341978239395,
        lng: 80.24212660344686,
      },
      {
        lat: 13.09692145633676,
        lng: 80.24482876428755,
      },
      {
        lat: 13.096886549663566,
        lng: 80.24599708322593,
      },
      {
        lat: 13.097018036452242,
        lng: 80.2468928456175,
      },
      {
        lat: 13.098114171941045,
        lng: 80.2509716510382,
      },
      {
        lat: 13.097025266089476,
        lng: 80.25078096408592,
      },
      {
        lat: 13.09560338573816,
        lng: 80.25022960756512,
      },
      {
        lat: 13.09403088896355,
        lng: 80.24979220380295,
      },
      {
        lat: 13.092952064555464,
        lng: 80.24966987644574,
      },
      {
        lat: 13.090946887732505,
        lng: 80.24894856722686,
      },
    ],
    properties: {
      Ward_No: 74,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#de4307",
      zname: "THIRU-VI-KA-NAGAR(74)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.092683018865834,
        lng: 80.2501575623045,
      },
      {
        lat: 13.094120527961913,
        lng: 80.25163299124357,
      },
      {
        lat: 13.094349156012244,
        lng: 80.25368951569085,
      },
      {
        lat: 13.091881486782864,
        lng: 80.25145650932252,
      },
      {
        lat: 13.093497367223325,
        lng: 80.25006822304717,
      },
      {
        lat: 13.093155630097158,
        lng: 80.25144495692943,
      },
      {
        lat: 13.097865257079526,
        lng: 80.25119049903327,
      },
      {
        lat: 13.096831948726187,
        lng: 80.25121724247272,
      },
      {
        lat: 13.09389579850389,
        lng: 80.25386854938817,
      },
      {
        lat: 13.093527613174443,
        lng: 80.25276259517625,
      },
      {
        lat: 13.092634692933828,
        lng: 80.25303423018491,
      },
      {
        lat: 13.093822555711428,
        lng: 80.25142188230781,
      },
      {
        lat: 13.09685644693124,
        lng: 80.25258032309793,
      },
      {
        lat: 13.092119607426957,
        lng: 80.2521054648524,
      },
      {
        lat: 13.095079592940548,
        lng: 80.25027940602253,
      },
      {
        lat: 13.097168728246421,
        lng: 80.25344321209992,
      },
      {
        lat: 13.094056746265887,
        lng: 80.25338165372516,
      },
      {
        lat: 13.095366693535409,
        lng: 80.25146638044247,
      },
      {
        lat: 13.093580123074151,
        lng: 80.25371921137062,
      },
      {
        lat: 13.092269450621135,
        lng: 80.25267185943235,
      },
      {
        lat: 13.096512630216479,
        lng: 80.25289628548616,
      },
      {
        lat: 13.092822273915598,
        lng: 80.25370008216237,
      },
      {
        lat: 13.093442819758053,
        lng: 80.2523440823038,
      },
      {
        lat: 13.093085174112462,
        lng: 80.25072151177066,
      },
      {
        lat: 13.094028808220864,
        lng: 80.25397847494177,
      },
      {
        lat: 13.09308108285053,
        lng: 80.25139097349583,
      },
      {
        lat: 13.095190026852384,
        lng: 80.25363853621408,
      },
      {
        lat: 13.094632294195161,
        lng: 80.2540376487747,
      },
      {
        lat: 13.091705976252438,
        lng: 80.25412958026874,
      },
      {
        lat: 13.092548300948456,
        lng: 80.2540461594168,
      },
      {
        lat: 13.096101420462666,
        lng: 80.2512573159414,
      },
      {
        lat: 13.096157843296751,
        lng: 80.25160288046985,
      },
      {
        lat: 13.096893808504696,
        lng: 80.25093377838962,
      },
      {
        lat: 13.092473572105215,
        lng: 80.2513648497258,
      },
      {
        lat: 13.096522467070567,
        lng: 80.25414960833453,
      },
      {
        lat: 13.092840667718905,
        lng: 80.25326244324637,
      },
      {
        lat: 13.092556105380877,
        lng: 80.25196792592129,
      },
      {
        lat: 13.093131369695794,
        lng: 80.25340376164344,
      },
      {
        lat: 13.097602614155111,
        lng: 80.25282194683801,
      },
      {
        lat: 13.09666868131207,
        lng: 80.25111187046015,
      },
      {
        lat: 13.091868234719312,
        lng: 80.25320567946778,
      },
      {
        lat: 13.091405427678554,
        lng: 80.25142932372297,
      },
      {
        lat: 13.09292342468167,
        lng: 80.25422172227188,
      },
      {
        lat: 13.096557177185955,
        lng: 80.25387850514997,
      },
      {
        lat: 13.097473587091136,
        lng: 80.2532347587136,
      },
      {
        lat: 13.097146953538209,
        lng: 80.25172289829875,
      },
      {
        lat: 13.094858109362042,
        lng: 80.25170332494697,
      },
      {
        lat: 13.092337604150698,
        lng: 80.2510679153923,
      },
      {
        lat: 13.091870258044132,
        lng: 80.25163460439462,
      },
      {
        lat: 13.095323276868257,
        lng: 80.25261442372566,
      },
      {
        lat: 13.092778327374255,
        lng: 80.25218902653717,
      },
      {
        lat: 13.096446808010874,
        lng: 80.25419105741555,
      },
      {
        lat: 13.091284860248173,
        lng: 80.2504512885005,
      },
      {
        lat: 13.092909510872264,
        lng: 80.25229426662541,
      },
      {
        lat: 13.094736439946963,
        lng: 80.25034538707038,
      },
      {
        lat: 13.096009861828792,
        lng: 80.25418957759149,
      },
      {
        lat: 13.094575487065413,
        lng: 80.25287288408504,
      },
      {
        lat: 13.0917761637073,
        lng: 80.25156324527802,
      },
      {
        lat: 13.095597797133125,
        lng: 80.25353467080407,
      },
      {
        lat: 13.092278579468283,
        lng: 80.2546288690711,
      },
      {
        lat: 13.094822544991242,
        lng: 80.25353888993577,
      },
      {
        lat: 13.097426484027153,
        lng: 80.25237829808385,
      },
      {
        lat: 13.096466966592242,
        lng: 80.25354200207467,
      },
      {
        lat: 13.091421114308824,
        lng: 80.25027804081545,
      },
      {
        lat: 13.091519500348873,
        lng: 80.25089164874075,
      },
      {
        lat: 13.095473285584657,
        lng: 80.25087490796756,
      },
      {
        lat: 13.096952438972703,
        lng: 80.25211467130677,
      },
      {
        lat: 13.091839824478607,
        lng: 80.25429709277216,
      },
      {
        lat: 13.091783934615046,
        lng: 80.25031816002189,
      },
      {
        lat: 13.093917347097515,
        lng: 80.2503052733094,
      },
      {
        lat: 13.09187361683625,
        lng: 80.24931224348671,
      },
      {
        lat: 13.094861635284582,
        lng: 80.25090307998026,
      },
      {
        lat: 13.093935294078602,
        lng: 80.24992629715724,
      },
      {
        lat: 13.09254984317625,
        lng: 80.2520017451578,
      },
      {
        lat: 13.092988684618513,
        lng: 80.2519562526303,
      },
      {
        lat: 13.095370183669202,
        lng: 80.25082161205738,
      },
      {
        lat: 13.0952434146351,
        lng: 80.25177531178855,
      },
      {
        lat: 13.094028847935563,
        lng: 80.25358985379987,
      },
      {
        lat: 13.09229464809053,
        lng: 80.25163115896592,
      },
      {
        lat: 13.097891350467105,
        lng: 80.25120532920342,
      },
      {
        lat: 13.09341593396413,
        lng: 80.24986510468267,
      },
      {
        lat: 13.092612536551844,
        lng: 80.25358486093772,
      },
      {
        lat: 13.092371069130355,
        lng: 80.25042124285419,
      },
      {
        lat: 13.093356251835745,
        lng: 80.25401649929422,
      },
      {
        lat: 13.092811163423796,
        lng: 80.25181794635274,
      },
      {
        lat: 13.095888126184402,
        lng: 80.25369455715696,
      },
      {
        lat: 13.095324959971737,
        lng: 80.25411927129832,
      },
      {
        lat: 13.095564543103302,
        lng: 80.25397112244188,
      },
      {
        lat: 13.097722237409311,
        lng: 80.25173543755172,
      },
      {
        lat: 13.0919645968072,
        lng: 80.25099067773247,
      },
      {
        lat: 13.096507520304783,
        lng: 80.25133443107096,
      },
      {
        lat: 13.092633578247332,
        lng: 80.25014545194274,
      },
      {
        lat: 13.092049816713176,
        lng: 80.2504849796103,
      },
      {
        lat: 13.091587155174947,
        lng: 80.25169031171133,
      },
      {
        lat: 13.093514117562153,
        lng: 80.25315581384027,
      },
      {
        lat: 13.09501475785332,
        lng: 80.25212495029483,
      },
      {
        lat: 13.092536335373469,
        lng: 80.25118311714077,
      },
      {
        lat: 13.09591963691727,
        lng: 80.25044309551303,
      },
      {
        lat: 13.09301399726298,
        lng: 80.2529528513985,
      },
      {
        lat: 13.097707943990333,
        lng: 80.25096307265107,
      },
      {
        lat: 13.096485167062497,
        lng: 80.25361156305395,
      },
      {
        lat: 13.095219646731191,
        lng: 80.25076990770093,
      },
      {
        lat: 13.096644980981342,
        lng: 80.25088342081499,
      },
      {
        lat: 13.093837709249108,
        lng: 80.2520591919492,
      },
      {
        lat: 13.095937684306461,
        lng: 80.25177260942264,
      },
      {
        lat: 13.0945369793279,
        lng: 80.25091917431087,
      },
      {
        lat: 13.093985264252307,
        lng: 80.25302755301868,
      },
      {
        lat: 13.093488483366054,
        lng: 80.25212743730846,
      },
      {
        lat: 13.091900145007523,
        lng: 80.25362779700193,
      },
      {
        lat: 13.093110802236021,
        lng: 80.25254746751571,
      },
      {
        lat: 13.091909860278625,
        lng: 80.25367522591145,
      },
      {
        lat: 13.09341252269953,
        lng: 80.25263253524857,
      },
      {
        lat: 13.094161054286666,
        lng: 80.25075950697611,
      },
      {
        lat: 13.092635723600202,
        lng: 80.25181428406572,
      },
      {
        lat: 13.097009978335079,
        lng: 80.25412294717945,
      },
      {
        lat: 13.095976958708762,
        lng: 80.25057624425314,
      },
      {
        lat: 13.095960439442251,
        lng: 80.25230653181885,
      },
      {
        lat: 13.097406991187965,
        lng: 80.25216674662501,
      },
      {
        lat: 13.09177053537147,
        lng: 80.25182776833914,
      },
      {
        lat: 13.091717449066056,
        lng: 80.25279895677087,
      },
      {
        lat: 13.095552538633086,
        lng: 80.25376983627905,
      },
      {
        lat: 13.094057050906397,
        lng: 80.25403626210566,
      },
      {
        lat: 13.094583714235908,
        lng: 80.25193576015016,
      },
      {
        lat: 13.093175302631812,
        lng: 80.25196265960567,
      },
      {
        lat: 13.094023932567548,
        lng: 80.25158814288672,
      },
      {
        lat: 13.091553103329282,
        lng: 80.25231875105543,
      },
      {
        lat: 13.094297011103874,
        lng: 80.2500798814892,
      },
      {
        lat: 13.096916966098055,
        lng: 80.25362876917833,
      },
      {
        lat: 13.093319582008208,
        lng: 80.25232761678714,
      },
      {
        lat: 13.097204887463006,
        lng: 80.25114454253804,
      },
      {
        lat: 13.096343255245886,
        lng: 80.25418925233383,
      },
      {
        lat: 13.096268767933738,
        lng: 80.25306253040542,
      },
      {
        lat: 13.094455901415648,
        lng: 80.25206055750348,
      },
      {
        lat: 13.092366763077955,
        lng: 80.25271499821017,
      },
      {
        lat: 13.092577738491666,
        lng: 80.25429596433904,
      },
      {
        lat: 13.09398034903821,
        lng: 80.25406194178643,
      },
      {
        lat: 13.091956997155812,
        lng: 80.25043995735872,
      },
      {
        lat: 13.09436172376225,
        lng: 80.25125084632887,
      },
      {
        lat: 13.092420439944576,
        lng: 80.25278280279154,
      },
      {
        lat: 13.09445719970656,
        lng: 80.2542239946142,
      },
      {
        lat: 13.094758773439727,
        lng: 80.25141692512823,
      },
      {
        lat: 13.09177362980409,
        lng: 80.25432915731172,
      },
      {
        lat: 13.094951235782997,
        lng: 80.25211005147675,
      },
      {
        lat: 13.09672951594922,
        lng: 80.25434607708473,
      },
      {
        lat: 13.095868593235933,
        lng: 80.25162923539692,
      },
      {
        lat: 13.094051518684147,
        lng: 80.2527282138882,
      },
      {
        lat: 13.094225004832625,
        lng: 80.25210015382856,
      },
      {
        lat: 13.093671903092082,
        lng: 80.25342463399602,
      },
      {
        lat: 13.093962355209861,
        lng: 80.25055448827916,
      },
      {
        lat: 13.092845879706546,
        lng: 80.2498532446977,
      },
      {
        lat: 13.094138183492717,
        lng: 80.25307122798894,
      },
      {
        lat: 13.09568942083895,
        lng: 80.25026435326743,
      },
      {
        lat: 13.097405882825283,
        lng: 80.25252909763718,
      },
      {
        lat: 13.094136069434413,
        lng: 80.25204719805357,
      },
      {
        lat: 13.093760557256145,
        lng: 80.25018952239054,
      },
      {
        lat: 13.091904771265973,
        lng: 80.25161485609564,
      },
      {
        lat: 13.092829811059373,
        lng: 80.25429936764375,
      },
      {
        lat: 13.092341268256373,
        lng: 80.2545851703548,
      },
      {
        lat: 13.092483754948745,
        lng: 80.25006584860301,
      },
      {
        lat: 13.093247919699829,
        lng: 80.25267420756755,
      },
      {
        lat: 13.096271414927912,
        lng: 80.25325488829738,
      },
      {
        lat: 13.091834098064263,
        lng: 80.25195738792435,
      },
      {
        lat: 13.097143559278077,
        lng: 80.25116498935787,
      },
    ],
    cases: 163,
    coords: [
      {
        lat: 13.097547389409966,
        lng: 80.2543630554284,
      },
      {
        lat: 13.096182890221494,
        lng: 80.25433582779883,
      },
      {
        lat: 13.096168878337888,
        lng: 80.2536177506897,
      },
      {
        lat: 13.096053152955202,
        lng: 80.25361873010363,
      },
      {
        lat: 13.096066349459887,
        lng: 80.25420275091697,
      },
      {
        lat: 13.09591216543248,
        lng: 80.25422320770177,
      },
      {
        lat: 13.095797129288208,
        lng: 80.25409658100129,
      },
      {
        lat: 13.095438130319717,
        lng: 80.25415595832902,
      },
      {
        lat: 13.094437241158394,
        lng: 80.25440856489412,
      },
      {
        lat: 13.094134980341348,
        lng: 80.25446746203197,
      },
      {
        lat: 13.09409635537474,
        lng: 80.25433633119712,
      },
      {
        lat: 13.09390642867112,
        lng: 80.25420648086794,
      },
      {
        lat: 13.093471664615212,
        lng: 80.25424771973982,
      },
      {
        lat: 13.091867167570928,
        lng: 80.25476835025096,
      },
      {
        lat: 13.09148834213391,
        lng: 80.2546776579718,
      },
      {
        lat: 13.091260361558415,
        lng: 80.25451057035175,
      },
      {
        lat: 13.091122432981889,
        lng: 80.25415691492009,
      },
      {
        lat: 13.091421002026257,
        lng: 80.25409502259663,
      },
      {
        lat: 13.091513784266661,
        lng: 80.25374877914233,
      },
      {
        lat: 13.091510346112988,
        lng: 80.25318351298048,
      },
      {
        lat: 13.091380205838307,
        lng: 80.2525879138393,
      },
      {
        lat: 13.091439450215349,
        lng: 80.25192790130262,
      },
      {
        lat: 13.09146897689943,
        lng: 80.2515821931963,
      },
      {
        lat: 13.091149642981671,
        lng: 80.25108241112673,
      },
      {
        lat: 13.091020839766747,
        lng: 80.25070663771534,
      },
      {
        lat: 13.091236694966012,
        lng: 80.24979405740271,
      },
      {
        lat: 13.091169810247655,
        lng: 80.24919792291355,
      },
      {
        lat: 13.090946887732505,
        lng: 80.24894856722686,
      },
      {
        lat: 13.092952064555464,
        lng: 80.24966987644574,
      },
      {
        lat: 13.09403088896355,
        lng: 80.24979220380295,
      },
      {
        lat: 13.09560338573816,
        lng: 80.25022960756512,
      },
      {
        lat: 13.097025266089476,
        lng: 80.25078096408592,
      },
      {
        lat: 13.098114171941045,
        lng: 80.2509716510382,
      },
      {
        lat: 13.097969164374373,
        lng: 80.25130659927895,
      },
      {
        lat: 13.097844654705636,
        lng: 80.25155794378956,
      },
      {
        lat: 13.097687775498223,
        lng: 80.25339473702121,
      },
      {
        lat: 13.097546193062977,
        lng: 80.25429281046853,
      },
      {
        lat: 13.097547389409966,
        lng: 80.2543630554284,
      },
    ],
    properties: {
      Ward_No: 75,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#de4307",
      zname: "THIRU-VI-KA-NAGAR(75)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.092326033095397,
        lng: 80.25668631720433,
      },
      {
        lat: 13.091035058379891,
        lng: 80.26719247141644,
      },
      {
        lat: 13.093051637396172,
        lng: 80.26004413929009,
      },
      {
        lat: 13.091743725974649,
        lng: 80.26084059655368,
      },
      {
        lat: 13.087596032066816,
        lng: 80.25554477451091,
      },
      {
        lat: 13.09257836550216,
        lng: 80.26287831545278,
      },
      {
        lat: 13.092223449189229,
        lng: 80.26095666147317,
      },
      {
        lat: 13.090217181508109,
        lng: 80.25444838176898,
      },
      {
        lat: 13.09078396558244,
        lng: 80.25522670463637,
      },
      {
        lat: 13.090241589434202,
        lng: 80.25591709036776,
      },
      {
        lat: 13.091938018557576,
        lng: 80.25751718198676,
      },
      {
        lat: 13.090957337924063,
        lng: 80.25547713160012,
      },
      {
        lat: 13.091794860537165,
        lng: 80.25840300540455,
      },
      {
        lat: 13.093388108453857,
        lng: 80.2656540301289,
      },
      {
        lat: 13.092323609337058,
        lng: 80.26568533200522,
      },
      {
        lat: 13.091695437971477,
        lng: 80.2622998944321,
      },
      {
        lat: 13.091902659785568,
        lng: 80.26193629472031,
      },
      {
        lat: 13.092681332997778,
        lng: 80.26536188755098,
      },
      {
        lat: 13.092452513791836,
        lng: 80.25624335670834,
      },
      {
        lat: 13.092560287623149,
        lng: 80.25870390116887,
      },
      {
        lat: 13.09077405298725,
        lng: 80.26871594483485,
      },
      {
        lat: 13.092176172321098,
        lng: 80.26559936150859,
      },
      {
        lat: 13.090932307094187,
        lng: 80.26256186293264,
      },
      {
        lat: 13.090637343353823,
        lng: 80.26133974665836,
      },
      {
        lat: 13.09087525937924,
        lng: 80.26276446342582,
      },
      {
        lat: 13.09155080780336,
        lng: 80.26767207832373,
      },
      {
        lat: 13.091259185594181,
        lng: 80.26540684463768,
      },
      {
        lat: 13.090592758238907,
        lng: 80.26700487673044,
      },
      {
        lat: 13.093452441242313,
        lng: 80.25630073197019,
      },
      {
        lat: 13.092865750389713,
        lng: 80.26184034347146,
      },
      {
        lat: 13.093315658525352,
        lng: 80.26033665265942,
      },
      {
        lat: 13.090922759363862,
        lng: 80.25465184144541,
      },
      {
        lat: 13.093074948431,
        lng: 80.26321906186747,
      },
      {
        lat: 13.091677657396847,
        lng: 80.2615560419308,
      },
      {
        lat: 13.091394016050522,
        lng: 80.26499636306754,
      },
      {
        lat: 13.093124272352567,
        lng: 80.25743163979993,
      },
      {
        lat: 13.090906541167652,
        lng: 80.26050505070022,
      },
      {
        lat: 13.090524252482227,
        lng: 80.25611695750314,
      },
      {
        lat: 13.093355331398419,
        lng: 80.2647596785095,
      },
      {
        lat: 13.092370065502616,
        lng: 80.2594211480741,
      },
      {
        lat: 13.09014504435041,
        lng: 80.25558570294046,
      },
      {
        lat: 13.091904082423053,
        lng: 80.26945680938186,
      },
      {
        lat: 13.091597673460049,
        lng: 80.26792245266367,
      },
      {
        lat: 13.08825464011965,
        lng: 80.25615170982984,
      },
      {
        lat: 13.08651993195515,
        lng: 80.25649437596007,
      },
      {
        lat: 13.092512464196988,
        lng: 80.26477058196421,
      },
      {
        lat: 13.09302180307908,
        lng: 80.2621823186757,
      },
      {
        lat: 13.090877135428288,
        lng: 80.26521026957252,
      },
      {
        lat: 13.091048049162383,
        lng: 80.25918534201355,
      },
      {
        lat: 13.092612208119863,
        lng: 80.25816965239872,
      },
      {
        lat: 13.092801453239368,
        lng: 80.26404328552029,
      },
      {
        lat: 13.090439604614144,
        lng: 80.25591910053352,
      },
      {
        lat: 13.091831624953425,
        lng: 80.26596604795785,
      },
      {
        lat: 13.092092865987482,
        lng: 80.25502435443946,
      },
      {
        lat: 13.0919439641686,
        lng: 80.26186943376823,
      },
      {
        lat: 13.093420581872145,
        lng: 80.266181597992,
      },
      {
        lat: 13.08959238718825,
        lng: 80.25729206806689,
      },
      {
        lat: 13.093650608177777,
        lng: 80.2626660770749,
      },
      {
        lat: 13.090884609672782,
        lng: 80.25900721819467,
      },
      {
        lat: 13.091166892035746,
        lng: 80.26907832148018,
      },
      {
        lat: 13.09204567218423,
        lng: 80.26421396627529,
      },
      {
        lat: 13.091368748132435,
        lng: 80.26057771250844,
      },
      {
        lat: 13.094466086437643,
        lng: 80.25599443595377,
      },
      {
        lat: 13.091175590550867,
        lng: 80.26295735744245,
      },
      {
        lat: 13.093368397959102,
        lng: 80.26541908485281,
      },
      {
        lat: 13.092520508215442,
        lng: 80.2650793146419,
      },
      {
        lat: 13.088917920964215,
        lng: 80.25734239437757,
      },
      {
        lat: 13.093474779748359,
        lng: 80.26283010751727,
      },
      {
        lat: 13.091718090848687,
        lng: 80.2653004052729,
      },
      {
        lat: 13.092390224534611,
        lng: 80.25632435429641,
      },
      {
        lat: 13.090671447967665,
        lng: 80.26833270855472,
      },
      {
        lat: 13.088556445013529,
        lng: 80.25553697682277,
      },
      {
        lat: 13.088939674220198,
        lng: 80.25654394232944,
      },
      {
        lat: 13.091808355856541,
        lng: 80.26260938893819,
      },
      {
        lat: 13.08806781854111,
        lng: 80.2555887267553,
      },
      {
        lat: 13.093018627718518,
        lng: 80.25970333392259,
      },
      {
        lat: 13.092068913059983,
        lng: 80.26404908294263,
      },
      {
        lat: 13.092166798475235,
        lng: 80.2679971499045,
      },
      {
        lat: 13.093303069673313,
        lng: 80.26130946652545,
      },
      {
        lat: 13.092822378694468,
        lng: 80.26751003541688,
      },
      {
        lat: 13.089982156821025,
        lng: 80.2544177577527,
      },
      {
        lat: 13.08803371927777,
        lng: 80.25575502738509,
      },
      {
        lat: 13.090820746945052,
        lng: 80.25490781442299,
      },
      {
        lat: 13.091821485451653,
        lng: 80.25644457290903,
      },
      {
        lat: 13.09238045653505,
        lng: 80.25596778055086,
      },
      {
        lat: 13.091938494588964,
        lng: 80.26045781771676,
      },
      {
        lat: 13.08654208285507,
        lng: 80.25530232260657,
      },
      {
        lat: 13.091759026378641,
        lng: 80.25487571210671,
      },
      {
        lat: 13.090641603301137,
        lng: 80.26452258353774,
      },
      {
        lat: 13.09185861215813,
        lng: 80.2640033857991,
      },
      {
        lat: 13.090072871331978,
        lng: 80.2569523809282,
      },
      {
        lat: 13.09101452630779,
        lng: 80.2618827054168,
      },
      {
        lat: 13.092449730199155,
        lng: 80.2635845999104,
      },
      {
        lat: 13.093015320691139,
        lng: 80.26380052997105,
      },
      {
        lat: 13.09396154852441,
        lng: 80.2558292544799,
      },
      {
        lat: 13.090498427872571,
        lng: 80.26196523520363,
      },
      {
        lat: 13.092499667280816,
        lng: 80.26801424985064,
      },
      {
        lat: 13.09198798500526,
        lng: 80.25835616686116,
      },
      {
        lat: 13.093210141884034,
        lng: 80.26443565213171,
      },
      {
        lat: 13.092993030384331,
        lng: 80.25567228205121,
      },
      {
        lat: 13.090269710483696,
        lng: 80.26475515798553,
      },
      {
        lat: 13.087279747711163,
        lng: 80.25540464394196,
      },
      {
        lat: 13.09339681396244,
        lng: 80.26486878656894,
      },
      {
        lat: 13.09092450733488,
        lng: 80.25924686800802,
      },
      {
        lat: 13.093271665275308,
        lng: 80.26320742677642,
      },
      {
        lat: 13.088084715803566,
        lng: 80.25630313396843,
      },
      {
        lat: 13.091791150868602,
        lng: 80.26581555026934,
      },
      {
        lat: 13.092572067764507,
        lng: 80.26060780479897,
      },
      {
        lat: 13.087626944637632,
        lng: 80.25564712730309,
      },
      {
        lat: 13.088270508060274,
        lng: 80.25511451952951,
      },
      {
        lat: 13.092702535137299,
        lng: 80.26223078282904,
      },
      {
        lat: 13.090743840592816,
        lng: 80.25912157192059,
      },
      {
        lat: 13.090215011572043,
        lng: 80.25540574993637,
      },
      {
        lat: 13.091617842677977,
        lng: 80.25920961634941,
      },
      {
        lat: 13.093445596426486,
        lng: 80.26527179652516,
      },
      {
        lat: 13.09320997090968,
        lng: 80.25438536802629,
      },
      {
        lat: 13.091433681183743,
        lng: 80.26888842622587,
      },
      {
        lat: 13.092230591654205,
        lng: 80.2684888111007,
      },
      {
        lat: 13.090689509521988,
        lng: 80.26152012371976,
      },
      {
        lat: 13.093305286322826,
        lng: 80.25920598221649,
      },
      {
        lat: 13.087408946771705,
        lng: 80.25597383622336,
      },
      {
        lat: 13.092831095700651,
        lng: 80.26266040356226,
      },
      {
        lat: 13.09239491314853,
        lng: 80.25484282308139,
      },
      {
        lat: 13.091610182642647,
        lng: 80.26659319837064,
      },
      {
        lat: 13.090341774244823,
        lng: 80.25588712325323,
      },
      {
        lat: 13.092980523492402,
        lng: 80.26144014943475,
      },
      {
        lat: 13.090877317154474,
        lng: 80.259716026781,
      },
      {
        lat: 13.091023218859814,
        lng: 80.26179665911043,
      },
      {
        lat: 13.08982688647208,
        lng: 80.25683922151403,
      },
      {
        lat: 13.092613712073982,
        lng: 80.26145765603192,
      },
      {
        lat: 13.091915175615798,
        lng: 80.25477512820464,
      },
      {
        lat: 13.091942473907912,
        lng: 80.2692762156873,
      },
      {
        lat: 13.092561944093381,
        lng: 80.25524996834663,
      },
      {
        lat: 13.091344107347945,
        lng: 80.26133765435375,
      },
      {
        lat: 13.090564016603762,
        lng: 80.26301018274195,
      },
      {
        lat: 13.09182310066778,
        lng: 80.26130046861212,
      },
      {
        lat: 13.091799051251801,
        lng: 80.26360573820894,
      },
      {
        lat: 13.090573937449276,
        lng: 80.26069313104207,
      },
      {
        lat: 13.090255709850316,
        lng: 80.26471898600415,
      },
      {
        lat: 13.091131839695267,
        lng: 80.26860098499462,
      },
      {
        lat: 13.093085401208317,
        lng: 80.26655401805677,
      },
      {
        lat: 13.09259691936443,
        lng: 80.26648237673317,
      },
      {
        lat: 13.090503267098294,
        lng: 80.26550216474135,
      },
      {
        lat: 13.091928181258025,
        lng: 80.265902845404,
      },
      {
        lat: 13.09366952788844,
        lng: 80.25448990938885,
      },
      {
        lat: 13.093050214768727,
        lng: 80.26258869290737,
      },
      {
        lat: 13.089889809196046,
        lng: 80.25646768521155,
      },
      {
        lat: 13.0922590685257,
        lng: 80.26933075538741,
      },
      {
        lat: 13.091555764049248,
        lng: 80.26058656072595,
      },
      {
        lat: 13.091440488576465,
        lng: 80.26169321393955,
      },
      {
        lat: 13.091404291476415,
        lng: 80.26351602182261,
      },
      {
        lat: 13.093547253551753,
        lng: 80.26297314165568,
      },
      {
        lat: 13.089575020699096,
        lng: 80.25590504498814,
      },
      {
        lat: 13.090518292461214,
        lng: 80.26205933187744,
      },
      {
        lat: 13.091164389827927,
        lng: 80.25904894946645,
      },
      {
        lat: 13.093165101601768,
        lng: 80.26536810311106,
      },
      {
        lat: 13.092575361878136,
        lng: 80.25629750655546,
      },
      {
        lat: 13.092120504459643,
        lng: 80.26352768133691,
      },
    ],
    cases: 158,
    coords: [
      {
        lat: 13.09210439357007,
        lng: 80.26960084446783,
      },
      {
        lat: 13.092049320630485,
        lng: 80.2695276978307,
      },
      {
        lat: 13.091584479750196,
        lng: 80.26939141151382,
      },
      {
        lat: 13.090938809322147,
        lng: 80.26937684450637,
      },
      {
        lat: 13.090634990093317,
        lng: 80.26917910097042,
      },
      {
        lat: 13.090571545933136,
        lng: 80.26869888230675,
      },
      {
        lat: 13.090460810534779,
        lng: 80.26707726931109,
      },
      {
        lat: 13.090271574546653,
        lng: 80.26581688739262,
      },
      {
        lat: 13.090163032132796,
        lng: 80.26455582254356,
      },
      {
        lat: 13.09047264852348,
        lng: 80.26238980194252,
      },
      {
        lat: 13.09057626684162,
        lng: 80.25952442282778,
      },
      {
        lat: 13.090633253880592,
        lng: 80.25894302750518,
      },
      {
        lat: 13.090732415194068,
        lng: 80.25866174750568,
      },
      {
        lat: 13.09099369465132,
        lng: 80.25849928436041,
      },
      {
        lat: 13.091052752842495,
        lng: 80.25825840673156,
      },
      {
        lat: 13.09102648782528,
        lng: 80.25725705483529,
      },
      {
        lat: 13.090884543075108,
        lng: 80.25713806724858,
      },
      {
        lat: 13.090702373370211,
        lng: 80.2570394515796,
      },
      {
        lat: 13.090420067692227,
        lng: 80.25706187228944,
      },
      {
        lat: 13.088949968440641,
        lng: 80.2574949752779,
      },
      {
        lat: 13.08876743323844,
        lng: 80.25733626825114,
      },
      {
        lat: 13.088664738783319,
        lng: 80.2570366651258,
      },
      {
        lat: 13.08850115864988,
        lng: 80.25667748284074,
      },
      {
        lat: 13.088298815551504,
        lng: 80.25657903790443,
      },
      {
        lat: 13.087855610054474,
        lng: 80.25668294628593,
      },
      {
        lat: 13.08694926933171,
        lng: 80.2569309946865,
      },
      {
        lat: 13.086625764039173,
        lng: 80.25681354369362,
      },
      {
        lat: 13.08646206207326,
        lng: 80.25643433095597,
      },
      {
        lat: 13.086359387001007,
        lng: 80.25595411260075,
      },
      {
        lat: 13.086316706013918,
        lng: 80.25575449069697,
      },
      {
        lat: 13.085930365723556,
        lng: 80.25525697330308,
      },
      {
        lat: 13.085847600997031,
        lng: 80.25491713854,
      },
      {
        lat: 13.085927685409166,
        lng: 80.25481630334637,
      },
      {
        lat: 13.086214011558736,
        lng: 80.25545488757152,
      },
      {
        lat: 13.086354859816604,
        lng: 80.25539360108533,
      },
      {
        lat: 13.086495586242057,
        lng: 80.25531228414633,
      },
      {
        lat: 13.09028221444929,
        lng: 80.25429869422958,
      },
      {
        lat: 13.090584084358733,
        lng: 80.25417595052411,
      },
      {
        lat: 13.090623212821262,
        lng: 80.25397530453309,
      },
      {
        lat: 13.090460729180068,
        lng: 80.25379639632114,
      },
      {
        lat: 13.09046012001771,
        lng: 80.2536962440582,
      },
      {
        lat: 13.090702200740914,
        lng: 80.25369419526625,
      },
      {
        lat: 13.090763451916596,
        lng: 80.2538138657838,
      },
      {
        lat: 13.090765766733568,
        lng: 80.25419444438275,
      },
      {
        lat: 13.090947158380063,
        lng: 80.25419324873486,
      },
      {
        lat: 13.091122432981889,
        lng: 80.25415691492009,
      },
      {
        lat: 13.091260361558415,
        lng: 80.25451057035175,
      },
      {
        lat: 13.09148834213391,
        lng: 80.2546776579718,
      },
      {
        lat: 13.091867167570928,
        lng: 80.25476835025096,
      },
      {
        lat: 13.093471664615212,
        lng: 80.25424771973982,
      },
      {
        lat: 13.09390642867112,
        lng: 80.25420648086794,
      },
      {
        lat: 13.09409635537474,
        lng: 80.25433633119712,
      },
      {
        lat: 13.094134980341348,
        lng: 80.25446746203197,
      },
      {
        lat: 13.094538338279795,
        lng: 80.25604591862052,
      },
      {
        lat: 13.094299431284856,
        lng: 80.25620103762068,
      },
      {
        lat: 13.094042875692118,
        lng: 80.25627125205877,
      },
      {
        lat: 13.093307885347008,
        lng: 80.25654964503849,
      },
      {
        lat: 13.09338127316997,
        lng: 80.25734853085062,
      },
      {
        lat: 13.093457868266446,
        lng: 80.25867472386514,
      },
      {
        lat: 13.093671763785776,
        lng: 80.26285756681222,
      },
      {
        lat: 13.093504589246995,
        lng: 80.26353941306853,
      },
      {
        lat: 13.09321894408475,
        lng: 80.26446041296742,
      },
      {
        lat: 13.093186026479104,
        lng: 80.26468183176742,
      },
      {
        lat: 13.093271993338618,
        lng: 80.2647321365637,
      },
      {
        lat: 13.093992231978184,
        lng: 80.2648451164948,
      },
      {
        lat: 13.094146827480587,
        lng: 80.2649118512544,
      },
      {
        lat: 13.094216594188454,
        lng: 80.26511539022523,
      },
      {
        lat: 13.094020847382174,
        lng: 80.266733071491,
      },
      {
        lat: 13.093866458800585,
        lng: 80.26670035655103,
      },
      {
        lat: 13.093540239960651,
        lng: 80.26658404192791,
      },
      {
        lat: 13.093163351456816,
        lng: 80.26658723163308,
      },
      {
        lat: 13.09300968709828,
        lng: 80.2666735860613,
      },
      {
        lat: 13.092781539988714,
        lng: 80.268597735676,
      },
      {
        lat: 13.092715601317162,
        lng: 80.26902356336619,
      },
      {
        lat: 13.092614158529003,
        lng: 80.26924556211254,
      },
      {
        lat: 13.09210439357007,
        lng: 80.26960084446783,
      },
    ],
    properties: {
      Ward_No: 78,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#de4307",
      zname: "THIRU-VI-KA-NAGAR(78)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.098303491687087,
        lng: 80.26000914413983,
      },
      {
        lat: 13.097161675449238,
        lng: 80.26061895074284,
      },
      {
        lat: 13.09535013439151,
        lng: 80.26271636568364,
      },
      {
        lat: 13.095625956351297,
        lng: 80.25518869319117,
      },
      {
        lat: 13.094759905408898,
        lng: 80.26025667220401,
      },
      {
        lat: 13.096548609731974,
        lng: 80.2564598386804,
      },
      {
        lat: 13.097220681150477,
        lng: 80.25911679086572,
      },
      {
        lat: 13.094777590527718,
        lng: 80.25625071218367,
      },
      {
        lat: 13.09741672609368,
        lng: 80.25584740621953,
      },
      {
        lat: 13.097372117026614,
        lng: 80.26001111078301,
      },
      {
        lat: 13.095061467685435,
        lng: 80.25475554023622,
      },
      {
        lat: 13.094486255184172,
        lng: 80.26145348034088,
      },
      {
        lat: 13.095544274759163,
        lng: 80.25941248639577,
      },
      {
        lat: 13.094327136723708,
        lng: 80.25666547141323,
      },
      {
        lat: 13.093584467423154,
        lng: 80.25686030817825,
      },
      {
        lat: 13.093728420908567,
        lng: 80.26147087106403,
      },
      {
        lat: 13.094243024141491,
        lng: 80.25725848501665,
      },
      {
        lat: 13.09552247898983,
        lng: 80.25803940487573,
      },
      {
        lat: 13.095879646734824,
        lng: 80.2571209814387,
      },
      {
        lat: 13.094581778778652,
        lng: 80.255047158554,
      },
      {
        lat: 13.097489407678408,
        lng: 80.25596587072619,
      },
      {
        lat: 13.095915004242814,
        lng: 80.26252468620766,
      },
      {
        lat: 13.09821671615546,
        lng: 80.25929068133571,
      },
      {
        lat: 13.095615000791428,
        lng: 80.26069316406763,
      },
      {
        lat: 13.095225734448967,
        lng: 80.26098079958676,
      },
      {
        lat: 13.096969287930191,
        lng: 80.26237493108887,
      },
      {
        lat: 13.098219435321992,
        lng: 80.25821923004436,
      },
      {
        lat: 13.09678153364529,
        lng: 80.25642418741037,
      },
      {
        lat: 13.096678961698293,
        lng: 80.25781729573467,
      },
      {
        lat: 13.096264747008025,
        lng: 80.2569864958823,
      },
      {
        lat: 13.095158038259365,
        lng: 80.26163594973006,
      },
      {
        lat: 13.094220132476062,
        lng: 80.26165879608921,
      },
      {
        lat: 13.093875349636495,
        lng: 80.26039253913335,
      },
      {
        lat: 13.096547677095584,
        lng: 80.25736539516278,
      },
      {
        lat: 13.09469294606017,
        lng: 80.25684861822822,
      },
      {
        lat: 13.094083275993086,
        lng: 80.26114966849046,
      },
      {
        lat: 13.095931446280785,
        lng: 80.26007172275644,
      },
      {
        lat: 13.09494186808688,
        lng: 80.2569149894166,
      },
      {
        lat: 13.096806216009185,
        lng: 80.26184875351744,
      },
      {
        lat: 13.097794274438684,
        lng: 80.26137694310889,
      },
      {
        lat: 13.097533868910265,
        lng: 80.25902609572316,
      },
      {
        lat: 13.095801584071976,
        lng: 80.25738674483023,
      },
      {
        lat: 13.09387622059135,
        lng: 80.2619999500601,
      },
      {
        lat: 13.094399695939986,
        lng: 80.2621256122589,
      },
      {
        lat: 13.094950110775605,
        lng: 80.25445330468877,
      },
      {
        lat: 13.096666364161283,
        lng: 80.25519340100013,
      },
    ],
    cases: 46,
    coords: [
      {
        lat: 13.099852698314745,
        lng: 80.26050970117954,
      },
      {
        lat: 13.09924239208182,
        lng: 80.26047994354495,
      },
      {
        lat: 13.098521532679628,
        lng: 80.26026490415529,
      },
      {
        lat: 13.096927020337096,
        lng: 80.26288104908797,
      },
      {
        lat: 13.096756535064129,
        lng: 80.26301857823206,
      },
      {
        lat: 13.096602249943036,
        lng: 80.26300287320159,
      },
      {
        lat: 13.095933715571665,
        lng: 80.26294048803992,
      },
      {
        lat: 13.095024929219855,
        lng: 80.26281209305161,
      },
      {
        lat: 13.094801394693077,
        lng: 80.26267789859934,
      },
      {
        lat: 13.094184771510772,
        lng: 80.26270012802654,
      },
      {
        lat: 13.093671763785776,
        lng: 80.26285756681222,
      },
      {
        lat: 13.093457868266446,
        lng: 80.25867472386514,
      },
      {
        lat: 13.09338127316997,
        lng: 80.25734853085062,
      },
      {
        lat: 13.093307885347008,
        lng: 80.25654964503849,
      },
      {
        lat: 13.094042875692118,
        lng: 80.25627125205877,
      },
      {
        lat: 13.094299431284856,
        lng: 80.25620103762068,
      },
      {
        lat: 13.094538338279795,
        lng: 80.25604591862052,
      },
      {
        lat: 13.094134980341348,
        lng: 80.25446746203197,
      },
      {
        lat: 13.094437241158394,
        lng: 80.25440856489412,
      },
      {
        lat: 13.095438130319717,
        lng: 80.25415595832902,
      },
      {
        lat: 13.095797129288208,
        lng: 80.25409658100129,
      },
      {
        lat: 13.09591216543248,
        lng: 80.25422320770177,
      },
      {
        lat: 13.096066349459887,
        lng: 80.25420275091697,
      },
      {
        lat: 13.096053152955202,
        lng: 80.25361873010363,
      },
      {
        lat: 13.096168878337888,
        lng: 80.2536177506897,
      },
      {
        lat: 13.096182890221494,
        lng: 80.25433582779883,
      },
      {
        lat: 13.097547389409966,
        lng: 80.2543630554284,
      },
      {
        lat: 13.097578869206794,
        lng: 80.25621142969496,
      },
      {
        lat: 13.097476252859982,
        lng: 80.25660859185614,
      },
      {
        lat: 13.097457277242752,
        lng: 80.25694247345574,
      },
      {
        lat: 13.097542567109558,
        lng: 80.25715032725314,
      },
      {
        lat: 13.097837402798728,
        lng: 80.25727297735868,
      },
      {
        lat: 13.098761890808378,
        lng: 80.25730686830231,
      },
      {
        lat: 13.098809990580973,
        lng: 80.25830762423364,
      },
      {
        lat: 13.09868560776896,
        lng: 80.25857982523347,
      },
      {
        lat: 13.098691443178357,
        lng: 80.25953922373364,
      },
      {
        lat: 13.099448396015609,
        lng: 80.25965796282196,
      },
      {
        lat: 13.099849019469685,
        lng: 80.25990486299477,
      },
      {
        lat: 13.099892552400261,
        lng: 80.2601547853173,
      },
      {
        lat: 13.099852698314745,
        lng: 80.26050970117954,
      },
    ],
    properties: {
      Ward_No: 76,
      Zone_Name: "THIRU-VI-KA-NAGAR",
      fillColor: "#8bc24c",
      zname: "THIRU-VI-KA-NAGAR(76)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.104833261127537,
        lng: 80.27567140473403,
      },
      {
        lat: 13.102883842444953,
        lng: 80.27332706800622,
      },
      {
        lat: 13.11224234360502,
        lng: 80.27749421835783,
      },
      {
        lat: 13.110370873965433,
        lng: 80.2756685122652,
      },
      {
        lat: 13.107325156833703,
        lng: 80.27869818193169,
      },
      {
        lat: 13.100997615323218,
        lng: 80.27274985135186,
      },
      {
        lat: 13.10586432936513,
        lng: 80.27649655666622,
      },
      {
        lat: 13.100629601844311,
        lng: 80.27248342266523,
      },
      {
        lat: 13.110200103094167,
        lng: 80.27393556559018,
      },
      {
        lat: 13.112846243633058,
        lng: 80.27584132735892,
      },
      {
        lat: 13.099319280455955,
        lng: 80.27341718718546,
      },
    ],
    cases: 11,
    coords: [
      {
        lat: 13.112132809255273,
        lng: 80.28152558136809,
      },
      {
        lat: 13.111715464800286,
        lng: 80.28148597303475,
      },
      {
        lat: 13.110512187587414,
        lng: 80.28120935843734,
      },
      {
        lat: 13.109910514094562,
        lng: 80.28106531546901,
      },
      {
        lat: 13.109470027231467,
        lng: 80.28082813289822,
      },
      {
        lat: 13.109422977153796,
        lng: 80.28069086793789,
      },
      {
        lat: 13.109560917835918,
        lng: 80.28057498121225,
      },
      {
        lat: 13.109618125657565,
        lng: 80.28048272160943,
      },
      {
        lat: 13.109559941016114,
        lng: 80.28041438246244,
      },
      {
        lat: 13.10947969656554,
        lng: 80.28051830896004,
      },
      {
        lat: 13.109364443648133,
        lng: 80.28056517209455,
      },
      {
        lat: 13.109249469822158,
        lng: 80.28065792058597,
      },
      {
        lat: 13.10838925018392,
        lng: 80.28070322334246,
      },
      {
        lat: 13.107799143166751,
        lng: 80.28218740124422,
      },
      {
        lat: 13.106662085996193,
        lng: 80.28160213536812,
      },
      {
        lat: 13.10648319184765,
        lng: 80.28147502472844,
      },
      {
        lat: 13.106465728621643,
        lng: 80.28126615744003,
      },
      {
        lat: 13.106750730383059,
        lng: 80.28020259189155,
      },
      {
        lat: 13.106020135255468,
        lng: 80.27988721343958,
      },
      {
        lat: 13.105761552416498,
        lng: 80.27996979231033,
      },
      {
        lat: 13.1055516433237,
        lng: 80.28006803732576,
      },
      {
        lat: 13.105437517119464,
        lng: 80.27994037853826,
      },
      {
        lat: 13.105452731228674,
        lng: 80.27977946894316,
      },
      {
        lat: 13.10541936937935,
        lng: 80.27961897045917,
      },
      {
        lat: 13.104893402809038,
        lng: 80.27833717517836,
      },
      {
        lat: 13.10413908604262,
        lng: 80.27678398506684,
      },
      {
        lat: 13.103992673653952,
        lng: 80.27667267760901,
      },
      {
        lat: 13.103231650308064,
        lng: 80.27667911834696,
      },
      {
        lat: 13.102795248985387,
        lng: 80.27681143639188,
      },
      {
        lat: 13.10226062008139,
        lng: 80.27676772684465,
      },
      {
        lat: 13.102162881438716,
        lng: 80.27667208553174,
      },
      {
        lat: 13.102160338958603,
        lng: 80.27625407688092,
      },
      {
        lat: 13.101241030476645,
        lng: 80.27622530233938,
      },
      {
        lat: 13.10131979325264,
        lng: 80.27523549234017,
      },
      {
        lat: 13.101464828133478,
        lng: 80.27468707915654,
      },
      {
        lat: 13.101379153335605,
        lng: 80.27454048501352,
      },
      {
        lat: 13.101146395650357,
        lng: 80.27460559171556,
      },
      {
        lat: 13.099898340430986,
        lng: 80.275016020815,
      },
      {
        lat: 13.097397877978237,
        lng: 80.27512136529502,
      },
      {
        lat: 13.097370543104427,
        lng: 80.2737093060009,
      },
      {
        lat: 13.097215684112177,
        lng: 80.2714670904408,
      },
      {
        lat: 13.097194048729085,
        lng: 80.27122689574256,
      },
      {
        lat: 13.097273889476105,
        lng: 80.27108599964386,
      },
      {
        lat: 13.098039869271004,
        lng: 80.27097935953977,
      },
      {
        lat: 13.099695305871604,
        lng: 80.27116566398738,
      },
      {
        lat: 13.102085609349317,
        lng: 80.27110537126177,
      },
      {
        lat: 13.102751696835906,
        lng: 80.27115982844171,
      },
      {
        lat: 13.103115427083216,
        lng: 80.27125690751673,
      },
      {
        lat: 13.104265797848743,
        lng: 80.27132729756535,
      },
      {
        lat: 13.104538028748793,
        lng: 80.27120980357216,
      },
      {
        lat: 13.104804957881488,
        lng: 80.27125167529832,
      },
      {
        lat: 13.107844637412509,
        lng: 80.27045366041189,
      },
      {
        lat: 13.109685954107029,
        lng: 80.2699305724805,
      },
      {
        lat: 13.110285538133956,
        lng: 80.2698593018206,
      },
      {
        lat: 13.110441895442035,
        lng: 80.26999037097781,
      },
      {
        lat: 13.110533466409176,
        lng: 80.27043090415482,
      },
      {
        lat: 13.110609257388216,
        lng: 80.27193071047984,
      },
      {
        lat: 13.110438462121168,
        lng: 80.27307955719571,
      },
      {
        lat: 13.110330305948734,
        lng: 80.27356591153188,
      },
      {
        lat: 13.110420132278142,
        lng: 80.27371960916635,
      },
      {
        lat: 13.110709819949639,
        lng: 80.27384954991415,
      },
      {
        lat: 13.112848205475396,
        lng: 80.27466993771789,
      },
      {
        lat: 13.113472292678907,
        lng: 80.27497357162213,
      },
      {
        lat: 13.114629567133303,
        lng: 80.27525062761617,
      },
      {
        lat: 13.115303313077309,
        lng: 80.27562134946781,
      },
      {
        lat: 13.115361185808235,
        lng: 80.27571568129518,
      },
      {
        lat: 13.115247285817873,
        lng: 80.27583043120363,
      },
      {
        lat: 13.114375205977714,
        lng: 80.27689981397886,
      },
      {
        lat: 13.114357376065621,
        lng: 80.27710857244082,
      },
      {
        lat: 13.114511318874648,
        lng: 80.27729691282165,
      },
      {
        lat: 13.11449337362037,
        lng: 80.2774867079358,
      },
      {
        lat: 13.114284095603633,
        lng: 80.27762122937716,
      },
      {
        lat: 13.114074356218907,
        lng: 80.27767989742772,
      },
      {
        lat: 13.114056180280782,
        lng: 80.2778317658464,
      },
      {
        lat: 13.1139996916533,
        lng: 80.27796499419135,
      },
      {
        lat: 13.11338864943742,
        lng: 80.27798912992408,
      },
      {
        lat: 13.112833865164946,
        lng: 80.27784211061619,
      },
      {
        lat: 13.112566829759233,
        lng: 80.27790126357765,
      },
      {
        lat: 13.112434177120972,
        lng: 80.27807306516631,
      },
      {
        lat: 13.1123613580268,
        lng: 80.2786615754935,
      },
      {
        lat: 13.112402092897126,
        lng: 80.27907844586905,
      },
      {
        lat: 13.112276245434147,
        lng: 80.2803690849729,
      },
      {
        lat: 13.112129569168273,
        lng: 80.28137543549782,
      },
      {
        lat: 13.112132809255273,
        lng: 80.28152558136809,
      },
    ],
    properties: {
      Ward_No: 53,
      Zone_Name: "ROYAPURAM",
      fillColor: "#8bc24c",
      zname: "ROYAPURAM(53)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.114166227977922,
        lng: 80.28807373160093,
      },
      {
        lat: 13.111448181160272,
        lng: 80.28163478454167,
      },
      {
        lat: 13.111482361610161,
        lng: 80.2833571179454,
      },
      {
        lat: 13.110807719402112,
        lng: 80.28340487289825,
      },
      {
        lat: 13.110535350356209,
        lng: 80.28426899348841,
      },
      {
        lat: 13.111837330521057,
        lng: 80.28341614584781,
      },
      {
        lat: 13.110157735983094,
        lng: 80.28304808726953,
      },
      {
        lat: 13.111175723049593,
        lng: 80.2831264496977,
      },
      {
        lat: 13.113573973176962,
        lng: 80.28820208619778,
      },
      {
        lat: 13.113189649155856,
        lng: 80.28812873337526,
      },
      {
        lat: 13.109958113284453,
        lng: 80.28616098944748,
      },
      {
        lat: 13.115983367656728,
        lng: 80.28630432514198,
      },
      {
        lat: 13.112599671962151,
        lng: 80.28652119502797,
      },
      {
        lat: 13.111012244883868,
        lng: 80.28189645236111,
      },
    ],
    cases: 14,
    coords: [
      {
        lat: 13.110512187587414,
        lng: 80.28120935843734,
      },
      {
        lat: 13.111715464800286,
        lng: 80.28148597303475,
      },
      {
        lat: 13.112132809255273,
        lng: 80.28152558136809,
      },
      {
        lat: 13.112156165069026,
        lng: 80.28260789146226,
      },
      {
        lat: 13.111761898096892,
        lng: 80.283730123355,
      },
      {
        lat: 13.111574486960627,
        lng: 80.28431960350439,
      },
      {
        lat: 13.111595546447429,
        lng: 80.28464181877847,
      },
      {
        lat: 13.111730159898906,
        lng: 80.28479239410098,
      },
      {
        lat: 13.113336870669432,
        lng: 80.28517704689264,
      },
      {
        lat: 13.116550485633711,
        lng: 80.2862211457373,
      },
      {
        lat: 13.116281535276682,
        lng: 80.28703862030456,
      },
      {
        lat: 13.116119207735982,
        lng: 80.28736630679386,
      },
      {
        lat: 13.11593497672492,
        lng: 80.28746983869783,
      },
      {
        lat: 13.11554399102944,
        lng: 80.28735078046006,
      },
      {
        lat: 13.114926207787713,
        lng: 80.2870908798761,
      },
      {
        lat: 13.114638041251553,
        lng: 80.28699134599513,
      },
      {
        lat: 13.114535842295227,
        lng: 80.28707378909864,
      },
      {
        lat: 13.114072251631374,
        lng: 80.2885257250565,
      },
      {
        lat: 13.11308538616464,
        lng: 80.28837092082024,
      },
      {
        lat: 13.11170666671798,
        lng: 80.28795430390214,
      },
      {
        lat: 13.110821131923306,
        lng: 80.28757430211795,
      },
      {
        lat: 13.109007247718223,
        lng: 80.28652913731528,
      },
      {
        lat: 13.110512187587414,
        lng: 80.28120935843734,
      },
    ],
    properties: {
      Ward_No: 51,
      Zone_Name: "ROYAPURAM",
      fillColor: "#8bc24c",
      zname: "ROYAPURAM(51)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.115912901662885,
        lng: 80.29091194803783,
      },
      {
        lat: 13.118707065943921,
        lng: 80.28594887767473,
      },
      {
        lat: 13.112378892091696,
        lng: 80.28830067740316,
      },
      {
        lat: 13.118545725251034,
        lng: 80.28737810039924,
      },
      {
        lat: 13.118965092585853,
        lng: 80.28763750787256,
      },
      {
        lat: 13.118046984399086,
        lng: 80.28694812043476,
      },
      {
        lat: 13.121073242167643,
        lng: 80.29239704265014,
      },
      {
        lat: 13.116161774967958,
        lng: 80.29193960362862,
      },
      {
        lat: 13.114268027378978,
        lng: 80.2881854108482,
      },
      {
        lat: 13.121923259339628,
        lng: 80.28945304837043,
      },
      {
        lat: 13.117217840878032,
        lng: 80.288259034933,
      },
      {
        lat: 13.11845619430072,
        lng: 80.2863851096024,
      },
      {
        lat: 13.12057658393142,
        lng: 80.28937148765431,
      },
      {
        lat: 13.11996606226673,
        lng: 80.28800998469569,
      },
      {
        lat: 13.120484832010451,
        lng: 80.28728070263818,
      },
      {
        lat: 13.11987163063157,
        lng: 80.28904877603306,
      },
      {
        lat: 13.117025491831997,
        lng: 80.28844996108211,
      },
      {
        lat: 13.120666273775164,
        lng: 80.28708789272086,
      },
      {
        lat: 13.112264552881603,
        lng: 80.28839390129234,
      },
      {
        lat: 13.114921877706465,
        lng: 80.291420822762,
      },
      {
        lat: 13.118577993743445,
        lng: 80.28873743703153,
      },
      {
        lat: 13.116746907800705,
        lng: 80.29144694725791,
      },
      {
        lat: 13.120771889816252,
        lng: 80.29319914242927,
      },
      {
        lat: 13.121732823734513,
        lng: 80.29328572082431,
      },
      {
        lat: 13.11773313532001,
        lng: 80.28735915767217,
      },
      {
        lat: 13.11860911484811,
        lng: 80.2884458977572,
      },
      {
        lat: 13.120935279784188,
        lng: 80.28751655376699,
      },
      {
        lat: 13.11416858994746,
        lng: 80.2886149203267,
      },
      {
        lat: 13.12017654626821,
        lng: 80.29034047878275,
      },
      {
        lat: 13.11878092858889,
        lng: 80.28856560698418,
      },
      {
        lat: 13.118641831353003,
        lng: 80.29108745037344,
      },
      {
        lat: 13.116624661370414,
        lng: 80.29058818851539,
      },
      {
        lat: 13.122461781260593,
        lng: 80.28906048253499,
      },
      {
        lat: 13.120143068297402,
        lng: 80.28794795959612,
      },
      {
        lat: 13.116884347054572,
        lng: 80.29036904846473,
      },
      {
        lat: 13.122293809859388,
        lng: 80.29054018797541,
      },
      {
        lat: 13.113822830796089,
        lng: 80.2888193613481,
      },
      {
        lat: 13.115152641439934,
        lng: 80.28828567102696,
      },
      {
        lat: 13.11268144861632,
        lng: 80.29187668409595,
      },
      {
        lat: 13.120289468906906,
        lng: 80.28609768248566,
      },
      {
        lat: 13.118620532545192,
        lng: 80.29138554585592,
      },
      {
        lat: 13.12258327193705,
        lng: 80.29003236814495,
      },
      {
        lat: 13.120020064161949,
        lng: 80.29031961264309,
      },
      {
        lat: 13.114858110358885,
        lng: 80.28981175182479,
      },
      {
        lat: 13.115634033118232,
        lng: 80.28854176641951,
      },
      {
        lat: 13.113277872128332,
        lng: 80.29172596710778,
      },
      {
        lat: 13.113110599067038,
        lng: 80.29171245861662,
      },
      {
        lat: 13.121738669980692,
        lng: 80.28689542827354,
      },
      {
        lat: 13.122715765475355,
        lng: 80.2905940556881,
      },
      {
        lat: 13.12204189389633,
        lng: 80.28901420080926,
      },
      {
        lat: 13.119327293888967,
        lng: 80.29224634845723,
      },
      {
        lat: 13.114772057671619,
        lng: 80.29042411479033,
      },
      {
        lat: 13.115536490851214,
        lng: 80.29015296816755,
      },
      {
        lat: 13.117334233549656,
        lng: 80.28776105127228,
      },
    ],
    cases: 54,
    coords: [
      {
        lat: 13.121800759900758,
        lng: 80.29347816379718,
      },
      {
        lat: 13.115081940570294,
        lng: 80.29243293182836,
      },
      {
        lat: 13.115120909927393,
        lng: 80.29208589480949,
      },
      {
        lat: 13.11503800958879,
        lng: 80.29196422916554,
      },
      {
        lat: 13.11489473258459,
        lng: 80.2920470199233,
      },
      {
        lat: 13.114732032922282,
        lng: 80.2923135259365,
      },
      {
        lat: 13.11460941898252,
        lng: 80.29241653635918,
      },
      {
        lat: 13.111833177634042,
        lng: 80.29186898523844,
      },
      {
        lat: 13.112206549562954,
        lng: 80.28909216760636,
      },
      {
        lat: 13.112204440872587,
        lng: 80.28874547824168,
      },
      {
        lat: 13.11168910466874,
        lng: 80.28844392153806,
      },
      {
        lat: 13.11170666671798,
        lng: 80.28795430390214,
      },
      {
        lat: 13.11308538616464,
        lng: 80.28837092082024,
      },
      {
        lat: 13.114072251631374,
        lng: 80.2885257250565,
      },
      {
        lat: 13.114535842295227,
        lng: 80.28707378909864,
      },
      {
        lat: 13.114638041251553,
        lng: 80.28699134599513,
      },
      {
        lat: 13.114926207787713,
        lng: 80.2870908798761,
      },
      {
        lat: 13.11554399102944,
        lng: 80.28735078046006,
      },
      {
        lat: 13.11593497672492,
        lng: 80.28746983869783,
      },
      {
        lat: 13.116119207735982,
        lng: 80.28736630679386,
      },
      {
        lat: 13.116281535276682,
        lng: 80.28703862030456,
      },
      {
        lat: 13.116550485633711,
        lng: 80.2862211457373,
      },
      {
        lat: 13.117489702821958,
        lng: 80.28652629604062,
      },
      {
        lat: 13.117834055658156,
        lng: 80.28661820331249,
      },
      {
        lat: 13.118005020985596,
        lng: 80.2864650417976,
      },
      {
        lat: 13.11834279933043,
        lng: 80.28547603825014,
      },
      {
        lat: 13.118456007269069,
        lng: 80.28524750825531,
      },
      {
        lat: 13.118627549306451,
        lng: 80.28518916347899,
      },
      {
        lat: 13.11910593888407,
        lng: 80.28533682933497,
      },
      {
        lat: 13.120916703658072,
        lng: 80.28629816704306,
      },
      {
        lat: 13.122218873727265,
        lng: 80.28685607615651,
      },
      {
        lat: 13.122908386793169,
        lng: 80.28717263413449,
      },
      {
        lat: 13.123292321080218,
        lng: 80.28749177830512,
      },
      {
        lat: 13.123369984536449,
        lng: 80.28769972858191,
      },
      {
        lat: 13.123219040618052,
        lng: 80.28800443524152,
      },
      {
        lat: 13.122881146931443,
        lng: 80.28897447544148,
      },
      {
        lat: 13.122828695272345,
        lng: 80.28977142095658,
      },
      {
        lat: 13.122835500447625,
        lng: 80.29089025847153,
      },
      {
        lat: 13.12264993478268,
        lng: 80.29178315218444,
      },
      {
        lat: 13.122367260962,
        lng: 80.29241136721437,
      },
      {
        lat: 13.121912699077905,
        lng: 80.29304103697756,
      },
      {
        lat: 13.121800759900758,
        lng: 80.29347816379718,
      },
    ],
    properties: {
      Ward_No: 49,
      Zone_Name: "ROYAPURAM",
      fillColor: "#8bc24c",
      zname: "ROYAPURAM(49)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.109711976443968,
        lng: 80.296309690738,
      },
      {
        lat: 13.109056284871432,
        lng: 80.29266085343646,
      },
      {
        lat: 13.112846173225952,
        lng: 80.29253594782854,
      },
      {
        lat: 13.114653386354068,
        lng: 80.29501302130417,
      },
      {
        lat: 13.119031239292424,
        lng: 80.29962755571722,
      },
      {
        lat: 13.118689286885141,
        lng: 80.29462379360399,
      },
      {
        lat: 13.120386939956601,
        lng: 80.29777646366873,
      },
      {
        lat: 13.109423004801116,
        lng: 80.29728914342236,
      },
      {
        lat: 13.113099485830155,
        lng: 80.29347016387149,
      },
      {
        lat: 13.113577231398855,
        lng: 80.29472883351511,
      },
      {
        lat: 13.1151273758472,
        lng: 80.30019150320695,
      },
      {
        lat: 13.114933857392172,
        lng: 80.29243033843778,
      },
      {
        lat: 13.118747120297183,
        lng: 80.2949886918106,
      },
      {
        lat: 13.109567478471943,
        lng: 80.29565678320085,
      },
      {
        lat: 13.109059336597952,
        lng: 80.29917202295344,
      },
      {
        lat: 13.120171561513056,
        lng: 80.29426411536335,
      },
      {
        lat: 13.11275997664278,
        lng: 80.30086488148143,
      },
      {
        lat: 13.11308209006097,
        lng: 80.2946815035342,
      },
      {
        lat: 13.11679704888998,
        lng: 80.30014840054429,
      },
      {
        lat: 13.119193759599744,
        lng: 80.2990761359316,
      },
      {
        lat: 13.109608425081705,
        lng: 80.29209771162948,
      },
      {
        lat: 13.11608544419678,
        lng: 80.29538185934973,
      },
      {
        lat: 13.111764737692662,
        lng: 80.29257324323973,
      },
    ],
    cases: 23,
    coords: [
      {
        lat: 13.10418405293303,
        lng: 80.29843160952109,
      },
      {
        lat: 13.10493546645473,
        lng: 80.29586350892997,
      },
      {
        lat: 13.106130151254165,
        lng: 80.2930389512303,
      },
      {
        lat: 13.108702862974033,
        lng: 80.29389414300257,
      },
      {
        lat: 13.109204802791991,
        lng: 80.29199320256548,
      },
      {
        lat: 13.109162360297754,
        lng: 80.2917692218073,
      },
      {
        lat: 13.10901747076568,
        lng: 80.29158689716854,
      },
      {
        lat: 13.10905780456966,
        lng: 80.29146418856206,
      },
      {
        lat: 13.10930390073324,
        lng: 80.29140092216096,
      },
      {
        lat: 13.111833177634042,
        lng: 80.29186898523844,
      },
      {
        lat: 13.11460941898252,
        lng: 80.29241653635918,
      },
      {
        lat: 13.114732032922282,
        lng: 80.2923135259365,
      },
      {
        lat: 13.11489473258459,
        lng: 80.2920470199233,
      },
      {
        lat: 13.11503800958879,
        lng: 80.29196422916554,
      },
      {
        lat: 13.115120909927393,
        lng: 80.29208589480949,
      },
      {
        lat: 13.115081940570294,
        lng: 80.29243293182836,
      },
      {
        lat: 13.121800759900758,
        lng: 80.29347816379718,
      },
      {
        lat: 13.12145882924576,
        lng: 80.29378448682706,
      },
      {
        lat: 13.121270149347838,
        lng: 80.29416537015166,
      },
      {
        lat: 13.121029825184836,
        lng: 80.29547594242533,
      },
      {
        lat: 13.121034092837133,
        lng: 80.2961775862907,
      },
      {
        lat: 13.121192072614539,
        lng: 80.29702964384163,
      },
      {
        lat: 13.121159411680923,
        lng: 80.29794020780595,
      },
      {
        lat: 13.121030911353001,
        lng: 80.29879468991221,
      },
      {
        lat: 13.121015388279988,
        lng: 80.29938271532836,
      },
      {
        lat: 13.121053628990682,
        lng: 80.30002959059155,
      },
      {
        lat: 13.119198342173432,
        lng: 80.30066060964786,
      },
      {
        lat: 13.117839242562619,
        lng: 80.30159931216774,
      },
      {
        lat: 13.117122260309213,
        lng: 80.30181934942999,
      },
      {
        lat: 13.116655375952972,
        lng: 80.3018233007934,
      },
      {
        lat: 13.115504386991041,
        lng: 80.30154774957079,
      },
      {
        lat: 13.114714058108142,
        lng: 80.3015187767864,
      },
      {
        lat: 13.113959643406634,
        lng: 80.30148950005113,
      },
      {
        lat: 13.112987791560593,
        lng: 80.30114110965928,
      },
      {
        lat: 13.108777373439048,
        lng: 80.29978594335863,
      },
      {
        lat: 13.105503109877935,
        lng: 80.29881513110746,
      },
      {
        lat: 13.10418405293303,
        lng: 80.29843160952109,
      },
    ],
    properties: {
      Ward_No: 50,
      Zone_Name: "ROYAPURAM",
      fillColor: "#8bc24c",
      zname: "ROYAPURAM(50)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.097734470512227,
        lng: 80.27750776004348,
      },
      {
        lat: 13.101625986838327,
        lng: 80.27955580232627,
      },
      {
        lat: 13.101215197835991,
        lng: 80.27661971537559,
      },
      {
        lat: 13.098801774329928,
        lng: 80.27811318365701,
      },
      {
        lat: 13.098313087003266,
        lng: 80.2780332026783,
      },
      {
        lat: 13.105109418624574,
        lng: 80.27978716256506,
      },
      {
        lat: 13.099667230875484,
        lng: 80.27821370126505,
      },
      {
        lat: 13.098917149826153,
        lng: 80.27801403759032,
      },
      {
        lat: 13.09628824974492,
        lng: 80.27521374926938,
      },
      {
        lat: 13.09576955812953,
        lng: 80.2742060575525,
      },
      {
        lat: 13.097650550518221,
        lng: 80.27888347788668,
      },
      {
        lat: 13.096002456756292,
        lng: 80.2766297773544,
      },
      {
        lat: 13.100237204271522,
        lng: 80.27497019051637,
      },
      {
        lat: 13.103763622939233,
        lng: 80.27971989044474,
      },
      {
        lat: 13.096948466300494,
        lng: 80.27953169847126,
      },
      {
        lat: 13.103025353569306,
        lng: 80.2776913147126,
      },
      {
        lat: 13.096849330274441,
        lng: 80.27922258722252,
      },
      {
        lat: 13.095609343409802,
        lng: 80.27581287048328,
      },
      {
        lat: 13.101273349945375,
        lng: 80.27923550588307,
      },
      {
        lat: 13.098297798472084,
        lng: 80.27652907159637,
      },
      {
        lat: 13.096407240409592,
        lng: 80.27844643498884,
      },
      {
        lat: 13.096343097472777,
        lng: 80.27945612859115,
      },
      {
        lat: 13.101988676918289,
        lng: 80.27904403592261,
      },
      {
        lat: 13.10165075356234,
        lng: 80.27913412633849,
      },
      {
        lat: 13.095634171629888,
        lng: 80.27530821919358,
      },
      {
        lat: 13.097492506921004,
        lng: 80.27816194482,
      },
      {
        lat: 13.09705056232666,
        lng: 80.27623069003577,
      },
      {
        lat: 13.096699233839196,
        lng: 80.27896068235837,
      },
      {
        lat: 13.103970906133211,
        lng: 80.27853037328994,
      },
      {
        lat: 13.10101168298914,
        lng: 80.27723063000089,
      },
      {
        lat: 13.097109544111834,
        lng: 80.27500743567859,
      },
      {
        lat: 13.102874654520363,
        lng: 80.27857702223811,
      },
      {
        lat: 13.102083860408944,
        lng: 80.27712470601091,
      },
      {
        lat: 13.10002320060236,
        lng: 80.27735448789889,
      },
      {
        lat: 13.102691958714288,
        lng: 80.2795789062682,
      },
      {
        lat: 13.104059023196335,
        lng: 80.27827686077224,
      },
      {
        lat: 13.100220206166194,
        lng: 80.2751501366819,
      },
      {
        lat: 13.099209044572834,
        lng: 80.27564317047283,
      },
      {
        lat: 13.098972462865563,
        lng: 80.27682027369558,
      },
      {
        lat: 13.09474646859853,
        lng: 80.27847713511517,
      },
      {
        lat: 13.09884216974781,
        lng: 80.2759677809859,
      },
      {
        lat: 13.102428247193947,
        lng: 80.27973089152472,
      },
      {
        lat: 13.098823602477685,
        lng: 80.27572567942816,
      },
      {
        lat: 13.100828784099363,
        lng: 80.27752801778718,
      },
      {
        lat: 13.099626055011262,
        lng: 80.27655734631783,
      },
      {
        lat: 13.100881197790384,
        lng: 80.27653174196108,
      },
      {
        lat: 13.097644905659518,
        lng: 80.27925191035206,
      },
      {
        lat: 13.098019965857722,
        lng: 80.27534342830843,
      },
      {
        lat: 13.097317589982966,
        lng: 80.27918710145433,
      },
      {
        lat: 13.096572726166398,
        lng: 80.27663046090179,
      },
      {
        lat: 13.103928868296695,
        lng: 80.27886196645484,
      },
      {
        lat: 13.099695664135059,
        lng: 80.27822802485126,
      },
      {
        lat: 13.102956895207965,
        lng: 80.2789595597561,
      },
      {
        lat: 13.094762873087873,
        lng: 80.2793535133503,
      },
      {
        lat: 13.101188286254953,
        lng: 80.27740216530427,
      },
      {
        lat: 13.099995587478945,
        lng: 80.27788381535912,
      },
      {
        lat: 13.101546711918386,
        lng: 80.27917640853263,
      },
      {
        lat: 13.103671438109341,
        lng: 80.2774816585149,
      },
      {
        lat: 13.10114165017379,
        lng: 80.2781200901473,
      },
      {
        lat: 13.096250957035432,
        lng: 80.27926394482095,
      },
      {
        lat: 13.101043262400609,
        lng: 80.27690049251703,
      },
      {
        lat: 13.10287344547375,
        lng: 80.27781660857666,
      },
      {
        lat: 13.100197285517437,
        lng: 80.27808427605281,
      },
      {
        lat: 13.101679220916287,
        lng: 80.27705207139343,
      },
      {
        lat: 13.098602334175196,
        lng: 80.27839331236605,
      },
      {
        lat: 13.094882314631263,
        lng: 80.27786723279692,
      },
      {
        lat: 13.097532433274313,
        lng: 80.27587897671377,
      },
      {
        lat: 13.098942663659027,
        lng: 80.2791957780609,
      },
      {
        lat: 13.099687230263548,
        lng: 80.27949035480896,
      },
      {
        lat: 13.094525104929158,
        lng: 80.27909858302857,
      },
      {
        lat: 13.103718120413355,
        lng: 80.27707128242173,
      },
      {
        lat: 13.095674887500142,
        lng: 80.27877337426428,
      },
      {
        lat: 13.099904550517548,
        lng: 80.27768235910831,
      },
      {
        lat: 13.10489892785618,
        lng: 80.27844128786442,
      },
      {
        lat: 13.100387676526397,
        lng: 80.27646284405817,
      },
      {
        lat: 13.097093220743005,
        lng: 80.27567939654786,
      },
      {
        lat: 13.09875340966758,
        lng: 80.27727498801279,
      },
      {
        lat: 13.096388931109468,
        lng: 80.27482964516204,
      },
      {
        lat: 13.099657866884355,
        lng: 80.27864121350885,
      },
      {
        lat: 13.098289412397952,
        lng: 80.27558765714603,
      },
      {
        lat: 13.095836427131617,
        lng: 80.2796084217261,
      },
    ],
    cases: 81,
    coords: [
      {
        lat: 13.105437517119464,
        lng: 80.27994037853826,
      },
      {
        lat: 13.103981544087986,
        lng: 80.27988622247204,
      },
      {
        lat: 13.10034662143021,
        lng: 80.27991698576511,
      },
      {
        lat: 13.099995562858753,
        lng: 80.28003638759895,
      },
      {
        lat: 13.09814249997663,
        lng: 80.27998221211021,
      },
      {
        lat: 13.095209411206474,
        lng: 80.27972760183907,
      },
      {
        lat: 13.094575239738019,
        lng: 80.27956996596878,
      },
      {
        lat: 13.094433541662143,
        lng: 80.2794081621686,
      },
      {
        lat: 13.095373793208648,
        lng: 80.27590728255065,
      },
      {
        lat: 13.095766797045627,
        lng: 80.27497251058372,
      },
      {
        lat: 13.095740796636871,
        lng: 80.27455357998896,
      },
      {
        lat: 13.094730982463206,
        lng: 80.27432926483985,
      },
      {
        lat: 13.094477366776147,
        lng: 80.27401423247403,
      },
      {
        lat: 13.094597797975476,
        lng: 80.27391305581511,
      },
      {
        lat: 13.094697933948732,
        lng: 80.27379201943634,
      },
      {
        lat: 13.095040394310141,
        lng: 80.27370899517075,
      },
      {
        lat: 13.097370543104427,
        lng: 80.2737093060009,
      },
      {
        lat: 13.097397877978237,
        lng: 80.27512136529502,
      },
      {
        lat: 13.099898340430986,
        lng: 80.275016020815,
      },
      {
        lat: 13.101146395650357,
        lng: 80.27460559171556,
      },
      {
        lat: 13.101379153335605,
        lng: 80.27454048501352,
      },
      {
        lat: 13.101464828133478,
        lng: 80.27468707915654,
      },
      {
        lat: 13.10131979325264,
        lng: 80.27523549234017,
      },
      {
        lat: 13.101241030476645,
        lng: 80.27622530233938,
      },
      {
        lat: 13.102160338958603,
        lng: 80.27625407688092,
      },
      {
        lat: 13.102162881438716,
        lng: 80.27667208553174,
      },
      {
        lat: 13.10226062008139,
        lng: 80.27676772684465,
      },
      {
        lat: 13.102795248985387,
        lng: 80.27681143639188,
      },
      {
        lat: 13.103231650308064,
        lng: 80.27667911834696,
      },
      {
        lat: 13.103992673653952,
        lng: 80.27667267760901,
      },
      {
        lat: 13.10413908604262,
        lng: 80.27678398506684,
      },
      {
        lat: 13.104893402809038,
        lng: 80.27833717517836,
      },
      {
        lat: 13.10541936937935,
        lng: 80.27961897045917,
      },
      {
        lat: 13.105452731228674,
        lng: 80.27977946894316,
      },
      {
        lat: 13.105437517119464,
        lng: 80.27994037853826,
      },
    ],
    properties: {
      Ward_No: 54,
      Zone_Name: "ROYAPURAM",
      fillColor: "#f6d04d",
      zname: "ROYAPURAM(54)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.104413368643351,
        lng: 80.28156675019119,
      },
      {
        lat: 13.102273802017507,
        lng: 80.28086987818295,
      },
      {
        lat: 13.102826042921517,
        lng: 80.28352604430415,
      },
      {
        lat: 13.10082698130976,
        lng: 80.28346969069861,
      },
      {
        lat: 13.100065094068006,
        lng: 80.28251805123959,
      },
      {
        lat: 13.096488238997248,
        lng: 80.2839234136068,
      },
      {
        lat: 13.09570754154391,
        lng: 80.28558931904038,
      },
      {
        lat: 13.097263760502452,
        lng: 80.28242398729857,
      },
      {
        lat: 13.103193643515649,
        lng: 80.28410864848574,
      },
      {
        lat: 13.095419949951806,
        lng: 80.28509561184089,
      },
      {
        lat: 13.097699352396996,
        lng: 80.28542553084547,
      },
      {
        lat: 13.1041616816369,
        lng: 80.28085659603536,
      },
      {
        lat: 13.103428657670197,
        lng: 80.28001174714738,
      },
      {
        lat: 13.094651717664048,
        lng: 80.28584794822983,
      },
      {
        lat: 13.103174889993214,
        lng: 80.28257605455667,
      },
      {
        lat: 13.097986087391092,
        lng: 80.28076518496628,
      },
      {
        lat: 13.105514646160586,
        lng: 80.2801561265148,
      },
      {
        lat: 13.094525037927733,
        lng: 80.28509492094052,
      },
      {
        lat: 13.097502651345753,
        lng: 80.28230487215885,
      },
      {
        lat: 13.103604831169415,
        lng: 80.28275510502462,
      },
      {
        lat: 13.10232785043035,
        lng: 80.28089254697646,
      },
      {
        lat: 13.097007565147644,
        lng: 80.28487715182058,
      },
      {
        lat: 13.096972730933407,
        lng: 80.28358166383498,
      },
      {
        lat: 13.09816621345953,
        lng: 80.28131091724141,
      },
      {
        lat: 13.101616443983582,
        lng: 80.28208952546835,
      },
      {
        lat: 13.104125051823942,
        lng: 80.28281092205519,
      },
      {
        lat: 13.10221701175284,
        lng: 80.28048812870982,
      },
      {
        lat: 13.10256307583324,
        lng: 80.28198391290994,
      },
      {
        lat: 13.099218194760217,
        lng: 80.28050192379435,
      },
      {
        lat: 13.101359432774295,
        lng: 80.28238195640967,
      },
      {
        lat: 13.096122898033183,
        lng: 80.28458660782988,
      },
      {
        lat: 13.104724758851026,
        lng: 80.28447844721387,
      },
      {
        lat: 13.095485278509672,
        lng: 80.2801370631057,
      },
      {
        lat: 13.099526165906385,
        lng: 80.28152965173919,
      },
      {
        lat: 13.100758674860458,
        lng: 80.28058952982951,
      },
      {
        lat: 13.10452048234855,
        lng: 80.28073706181155,
      },
      {
        lat: 13.101783975933648,
        lng: 80.28374854111146,
      },
      {
        lat: 13.095776752915777,
        lng: 80.282604249467,
      },
      {
        lat: 13.100205994897088,
        lng: 80.28084725340543,
      },
      {
        lat: 13.100406585262991,
        lng: 80.28026417520924,
      },
      {
        lat: 13.097815611592951,
        lng: 80.28006209924831,
      },
      {
        lat: 13.097579460857295,
        lng: 80.28170251535327,
      },
      {
        lat: 13.099565687896716,
        lng: 80.2826696614553,
      },
      {
        lat: 13.102470436671583,
        lng: 80.28158924234816,
      },
      {
        lat: 13.104522091175305,
        lng: 80.28392466188782,
      },
      {
        lat: 13.101048965677075,
        lng: 80.28050070446572,
      },
      {
        lat: 13.097636436551634,
        lng: 80.28224604269616,
      },
      {
        lat: 13.104014663091275,
        lng: 80.28386540625294,
      },
      {
        lat: 13.102051856552944,
        lng: 80.28113533089063,
      },
      {
        lat: 13.10331133733871,
        lng: 80.28285443923644,
      },
      {
        lat: 13.095215208455516,
        lng: 80.28278507318491,
      },
      {
        lat: 13.100418757992717,
        lng: 80.28077385613321,
      },
      {
        lat: 13.104192618067431,
        lng: 80.28066721711336,
      },
      {
        lat: 13.099225364407319,
        lng: 80.28050865555869,
      },
      {
        lat: 13.095664416559917,
        lng: 80.28054199054102,
      },
      {
        lat: 13.097357838626502,
        lng: 80.28243596498389,
      },
      {
        lat: 13.095234973944518,
        lng: 80.28620657023913,
      },
      {
        lat: 13.096832038576798,
        lng: 80.28190922155862,
      },
      {
        lat: 13.103932417973823,
        lng: 80.281119439343,
      },
      {
        lat: 13.09543415069751,
        lng: 80.2855693701247,
      },
      {
        lat: 13.096523095012902,
        lng: 80.28233492559086,
      },
      {
        lat: 13.100533326602413,
        lng: 80.28252097797765,
      },
      {
        lat: 13.104315543360501,
        lng: 80.28088122332304,
      },
      {
        lat: 13.096123828414628,
        lng: 80.28036982044625,
      },
      {
        lat: 13.094757568147763,
        lng: 80.28586069650552,
      },
      {
        lat: 13.09672142359592,
        lng: 80.28137267495674,
      },
      {
        lat: 13.096777884824462,
        lng: 80.28441573263127,
      },
      {
        lat: 13.102117890547284,
        lng: 80.28273260637091,
      },
      {
        lat: 13.097471560303457,
        lng: 80.28452761625972,
      },
      {
        lat: 13.103796030864125,
        lng: 80.28026530022413,
      },
      {
        lat: 13.09892264737718,
        lng: 80.28010069881702,
      },
      {
        lat: 13.09615890775137,
        lng: 80.2823959002839,
      },
      {
        lat: 13.099341358545063,
        lng: 80.28316651108703,
      },
      {
        lat: 13.096186395847631,
        lng: 80.28367435356003,
      },
      {
        lat: 13.09892999011314,
        lng: 80.28009528316514,
      },
      {
        lat: 13.099525708267102,
        lng: 80.28191169561771,
      },
      {
        lat: 13.097345965141713,
        lng: 80.28241011159454,
      },
      {
        lat: 13.097371666811938,
        lng: 80.28512207734727,
      },
      {
        lat: 13.09725988273251,
        lng: 80.2851974088733,
      },
      {
        lat: 13.104398385784426,
        lng: 80.2830246125096,
      },
      {
        lat: 13.099550582697642,
        lng: 80.28310029088132,
      },
      {
        lat: 13.10498251203073,
        lng: 80.28025461355016,
      },
      {
        lat: 13.10181207221483,
        lng: 80.28124256625622,
      },
      {
        lat: 13.104040221505414,
        lng: 80.28316222170993,
      },
      {
        lat: 13.10361882470623,
        lng: 80.28166271443088,
      },
      {
        lat: 13.101345938943942,
        lng: 80.28311499888564,
      },
      {
        lat: 13.098056625272312,
        lng: 80.28137068532016,
      },
      {
        lat: 13.09599317275064,
        lng: 80.28265631448465,
      },
      {
        lat: 13.10101032161495,
        lng: 80.28010745704258,
      },
      {
        lat: 13.094880831305723,
        lng: 80.28617247173025,
      },
      {
        lat: 13.100572673616744,
        lng: 80.28305773130104,
      },
    ],
    cases: 91,
    coords: [
      {
        lat: 13.095196144795908,
        lng: 80.27972430421022,
      },
      {
        lat: 13.095209411206474,
        lng: 80.27972760183907,
      },
      {
        lat: 13.09814249997663,
        lng: 80.27998221211021,
      },
      {
        lat: 13.099995562858753,
        lng: 80.28003638759895,
      },
      {
        lat: 13.10034662143021,
        lng: 80.27991698576511,
      },
      {
        lat: 13.103981544087986,
        lng: 80.27988622247204,
      },
      {
        lat: 13.105437517119464,
        lng: 80.27994037853826,
      },
      {
        lat: 13.1055516433237,
        lng: 80.28006803732576,
      },
      {
        lat: 13.105401074142735,
        lng: 80.28192338954244,
      },
      {
        lat: 13.105112780078588,
        lng: 80.28465030862954,
      },
      {
        lat: 13.102412219662762,
        lng: 80.28406772435501,
      },
      {
        lat: 13.100016806982909,
        lng: 80.28352912982969,
      },
      {
        lat: 13.09879678254911,
        lng: 80.2834463106256,
      },
      {
        lat: 13.098256840418962,
        lng: 80.2833577357084,
      },
      {
        lat: 13.09795183431048,
        lng: 80.28333703090738,
      },
      {
        lat: 13.097742757070073,
        lng: 80.28366480643798,
      },
      {
        lat: 13.097675802788197,
        lng: 80.28422424061345,
      },
      {
        lat: 13.097843642900445,
        lng: 80.2848282599569,
      },
      {
        lat: 13.097824015728877,
        lng: 80.28545715203123,
      },
      {
        lat: 13.097571718575493,
        lng: 80.28639073316077,
      },
      {
        lat: 13.097314322832313,
        lng: 80.28648605615517,
      },
      {
        lat: 13.096868893295403,
        lng: 80.28651311208766,
      },
      {
        lat: 13.09412284692675,
        lng: 80.28616377424073,
      },
      {
        lat: 13.094825188274324,
        lng: 80.28210604059443,
      },
      {
        lat: 13.095083292154804,
        lng: 80.28212714234138,
      },
      {
        lat: 13.096092114935947,
        lng: 80.28218846285303,
      },
      {
        lat: 13.0962758600397,
        lng: 80.28228110128335,
      },
      {
        lat: 13.096432916315395,
        lng: 80.28236267914951,
      },
      {
        lat: 13.096495095913438,
        lng: 80.28228960921759,
      },
      {
        lat: 13.096644462454147,
        lng: 80.2811069192834,
      },
      {
        lat: 13.09673713306034,
        lng: 80.2808988673027,
      },
      {
        lat: 13.096663319078441,
        lng: 80.28077513139833,
      },
      {
        lat: 13.095868987571547,
        lng: 80.28059531311501,
      },
      {
        lat: 13.095116655395325,
        lng: 80.28045659291757,
      },
      {
        lat: 13.095196144795908,
        lng: 80.27972430421022,
      },
    ],
    properties: {
      Ward_No: 55,
      Zone_Name: "ROYAPURAM",
      fillColor: "#f6d04d",
      zname: "ROYAPURAM(55)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.098061649220003,
        lng: 80.28472712481621,
      },
      {
        lat: 13.098003671729328,
        lng: 80.28655064879439,
      },
      {
        lat: 13.098169751282331,
        lng: 80.2839299039939,
      },
      {
        lat: 13.103876361813033,
        lng: 80.28485616497602,
      },
      {
        lat: 13.099274913370085,
        lng: 80.28553130332105,
      },
      {
        lat: 13.099603372177656,
        lng: 80.28497154142242,
      },
      {
        lat: 13.100258779014547,
        lng: 80.284954943616,
      },
      {
        lat: 13.100535703995403,
        lng: 80.28801003172424,
      },
      {
        lat: 13.099510915673367,
        lng: 80.2897089992384,
      },
      {
        lat: 13.101101727627551,
        lng: 80.29005389855399,
      },
      {
        lat: 13.09818763498287,
        lng: 80.28458622243825,
      },
      {
        lat: 13.09801577919456,
        lng: 80.28863019287577,
      },
      {
        lat: 13.103619113433487,
        lng: 80.28531250810931,
      },
      {
        lat: 13.101597005346855,
        lng: 80.28427035191933,
      },
      {
        lat: 13.096876703011864,
        lng: 80.28831593383286,
      },
      {
        lat: 13.100061203175416,
        lng: 80.28878027511271,
      },
      {
        lat: 13.101193492565374,
        lng: 80.28978513386033,
      },
      {
        lat: 13.10296914753344,
        lng: 80.29001854274583,
      },
      {
        lat: 13.099626845992345,
        lng: 80.28443041317144,
      },
      {
        lat: 13.101175706893828,
        lng: 80.2868073449115,
      },
      {
        lat: 13.102506073044283,
        lng: 80.28657114420147,
      },
      {
        lat: 13.100930142102895,
        lng: 80.28977880571276,
      },
      {
        lat: 13.101856418702411,
        lng: 80.28822633444044,
      },
      {
        lat: 13.101527860768533,
        lng: 80.28632548733377,
      },
      {
        lat: 13.098850926122179,
        lng: 80.2858017576765,
      },
      {
        lat: 13.102647930175626,
        lng: 80.28931500602334,
      },
      {
        lat: 13.104241548488071,
        lng: 80.28714244432545,
      },
      {
        lat: 13.097522954979269,
        lng: 80.28692359228862,
      },
      {
        lat: 13.100454187450218,
        lng: 80.28435733544977,
      },
      {
        lat: 13.102858997478178,
        lng: 80.28456103825489,
      },
      {
        lat: 13.099202945513285,
        lng: 80.28417985595382,
      },
      {
        lat: 13.098339545384228,
        lng: 80.28841650746823,
      },
      {
        lat: 13.098575435521628,
        lng: 80.28768073037256,
      },
      {
        lat: 13.101287338171362,
        lng: 80.28880093710305,
      },
      {
        lat: 13.10003647734101,
        lng: 80.28973450235662,
      },
      {
        lat: 13.097072062961812,
        lng: 80.28665579595457,
      },
      {
        lat: 13.09988105496946,
        lng: 80.28714555191509,
      },
      {
        lat: 13.10089437179537,
        lng: 80.28658398569323,
      },
      {
        lat: 13.097468591302938,
        lng: 80.28824135970937,
      },
      {
        lat: 13.097366016113064,
        lng: 80.28682592559797,
      },
      {
        lat: 13.096705243231805,
        lng: 80.28866050203395,
      },
      {
        lat: 13.100376326551682,
        lng: 80.28497635634744,
      },
      {
        lat: 13.098727651354395,
        lng: 80.29072803733519,
      },
      {
        lat: 13.099226555838877,
        lng: 80.28758935788153,
      },
      {
        lat: 13.099072288586628,
        lng: 80.28957501888935,
      },
      {
        lat: 13.097041257945794,
        lng: 80.28980286578157,
      },
      {
        lat: 13.103087478778969,
        lng: 80.28780344274554,
      },
      {
        lat: 13.10219899164375,
        lng: 80.28551746277617,
      },
      {
        lat: 13.101342291159414,
        lng: 80.28481870792007,
      },
      {
        lat: 13.102987732278862,
        lng: 80.28846706290986,
      },
      {
        lat: 13.099241072223078,
        lng: 80.28859560178975,
      },
      {
        lat: 13.100975040309473,
        lng: 80.28431186926996,
      },
      {
        lat: 13.099616172408595,
        lng: 80.28412587044834,
      },
      {
        lat: 13.103001599231362,
        lng: 80.28736923865746,
      },
      {
        lat: 13.102768186398029,
        lng: 80.28599679897971,
      },
      {
        lat: 13.098720103149747,
        lng: 80.28853122876887,
      },
      {
        lat: 13.099222207360782,
        lng: 80.28662182135871,
      },
      {
        lat: 13.100979989437038,
        lng: 80.28903914713364,
      },
      {
        lat: 13.098193667605036,
        lng: 80.28562127569676,
      },
      {
        lat: 13.104988468626916,
        lng: 80.28477219105393,
      },
      {
        lat: 13.10436937667732,
        lng: 80.2857215733558,
      },
      {
        lat: 13.099618157786088,
        lng: 80.28938707665968,
      },
      {
        lat: 13.097875231767858,
        lng: 80.28481246092787,
      },
      {
        lat: 13.103919986615345,
        lng: 80.28749534402603,
      },
      {
        lat: 13.10153925020219,
        lng: 80.28693141958222,
      },
      {
        lat: 13.098668450263746,
        lng: 80.28835101454193,
      },
      {
        lat: 13.097047003301897,
        lng: 80.28792195138034,
      },
      {
        lat: 13.097443668309177,
        lng: 80.28811052574015,
      },
      {
        lat: 13.100676833036678,
        lng: 80.28521678458075,
      },
      {
        lat: 13.097954816077145,
        lng: 80.29035350350553,
      },
      {
        lat: 13.09797929727053,
        lng: 80.28427614498112,
      },
      {
        lat: 13.09800417644617,
        lng: 80.28459101811603,
      },
      {
        lat: 13.100432385213471,
        lng: 80.28430503287568,
      },
      {
        lat: 13.101151992797432,
        lng: 80.28642462655564,
      },
      {
        lat: 13.101847777506624,
        lng: 80.28849219266445,
      },
      {
        lat: 13.102579861762441,
        lng: 80.28844159945017,
      },
    ],
    cases: 76,
    coords: [
      {
        lat: 13.096868893295403,
        lng: 80.28651311208766,
      },
      {
        lat: 13.097314322832313,
        lng: 80.28648605615517,
      },
      {
        lat: 13.097571718575493,
        lng: 80.28639073316077,
      },
      {
        lat: 13.097824015728877,
        lng: 80.28545715203123,
      },
      {
        lat: 13.097843642900445,
        lng: 80.2848282599569,
      },
      {
        lat: 13.097675802788197,
        lng: 80.28422424061345,
      },
      {
        lat: 13.097742757070073,
        lng: 80.28366480643798,
      },
      {
        lat: 13.09795183431048,
        lng: 80.28333703090738,
      },
      {
        lat: 13.098256840418962,
        lng: 80.2833577357084,
      },
      {
        lat: 13.09879678254911,
        lng: 80.2834463106256,
      },
      {
        lat: 13.100016806982909,
        lng: 80.28352912982969,
      },
      {
        lat: 13.102412219662762,
        lng: 80.28406772435501,
      },
      {
        lat: 13.105112780078588,
        lng: 80.28465030862954,
      },
      {
        lat: 13.104908093289993,
        lng: 80.28569991755452,
      },
      {
        lat: 13.102756881469327,
        lng: 80.29060821465309,
      },
      {
        lat: 13.099046790135388,
        lng: 80.28984788512575,
      },
      {
        lat: 13.09870252968384,
        lng: 80.2910849644734,
      },
      {
        lat: 13.096213170921004,
        lng: 80.29052387889138,
      },
      {
        lat: 13.096868893295403,
        lng: 80.28651311208766,
      },
    ],
    properties: {
      Ward_No: 56,
      Zone_Name: "ROYAPURAM",
      fillColor: "#f6d04d",
      zname: "ROYAPURAM(56)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.108624150603106,
        lng: 80.29128579400742,
      },
      {
        lat: 13.109233647347198,
        lng: 80.28424684281362,
      },
      {
        lat: 13.10571465201143,
        lng: 80.29270668625442,
      },
      {
        lat: 13.110912489141416,
        lng: 80.29087000974502,
      },
      {
        lat: 13.108428671392256,
        lng: 80.2813821039992,
      },
      {
        lat: 13.103047494345024,
        lng: 80.29792560624578,
      },
      {
        lat: 13.105526008112603,
        lng: 80.2828034682526,
      },
      {
        lat: 13.102984493099102,
        lng: 80.29461278210545,
      },
      {
        lat: 13.108105258640597,
        lng: 80.28443517850707,
      },
      {
        lat: 13.105102652497953,
        lng: 80.28912843330451,
      },
      {
        lat: 13.105128749472996,
        lng: 80.29083172732774,
      },
      {
        lat: 13.108346787661109,
        lng: 80.29244093755058,
      },
      {
        lat: 13.108232267635543,
        lng: 80.29129601527767,
      },
      {
        lat: 13.110345654896285,
        lng: 80.2893311806627,
      },
      {
        lat: 13.108172763670126,
        lng: 80.29330283464236,
      },
      {
        lat: 13.10598840289099,
        lng: 80.28585335465054,
      },
      {
        lat: 13.108530804168623,
        lng: 80.29226627731121,
      },
      {
        lat: 13.104485189547905,
        lng: 80.29207827480228,
      },
      {
        lat: 13.102677806683527,
        lng: 80.29319396753006,
      },
      {
        lat: 13.102720862168225,
        lng: 80.2976793987606,
      },
      {
        lat: 13.111424373130568,
        lng: 80.29135052504323,
      },
      {
        lat: 13.109142468107358,
        lng: 80.29096939769468,
      },
      {
        lat: 13.103064332498334,
        lng: 80.2971769205284,
      },
      {
        lat: 13.111792049540679,
        lng: 80.28946548676139,
      },
      {
        lat: 13.110421082027255,
        lng: 80.28826052762886,
      },
      {
        lat: 13.108964058562217,
        lng: 80.29290448193511,
      },
      {
        lat: 13.108216828402929,
        lng: 80.28703769681614,
      },
      {
        lat: 13.10498798851055,
        lng: 80.29353751620707,
      },
      {
        lat: 13.107244802064077,
        lng: 80.28789554263841,
      },
      {
        lat: 13.105520196242567,
        lng: 80.28554387222393,
      },
      {
        lat: 13.110812909984189,
        lng: 80.2878341170278,
      },
      {
        lat: 13.110132749468752,
        lng: 80.28169408186199,
      },
      {
        lat: 13.10275744050881,
        lng: 80.29254262271789,
      },
      {
        lat: 13.106937382772585,
        lng: 80.2879159111791,
      },
      {
        lat: 13.104582849317996,
        lng: 80.29527421672428,
      },
      {
        lat: 13.110859044134436,
        lng: 80.28771940125387,
      },
      {
        lat: 13.10872707051502,
        lng: 80.28424990226038,
      },
      {
        lat: 13.106417416631398,
        lng: 80.28787922108424,
      },
      {
        lat: 13.106150177298835,
        lng: 80.280743762376,
      },
      {
        lat: 13.104345133576889,
        lng: 80.29066037136845,
      },
      {
        lat: 13.108534140744602,
        lng: 80.28779388815644,
      },
      {
        lat: 13.108572335960483,
        lng: 80.28347993022733,
      },
      {
        lat: 13.102764655495525,
        lng: 80.29449696525768,
      },
      {
        lat: 13.104737790473742,
        lng: 80.29222310152085,
      },
      {
        lat: 13.10631073644633,
        lng: 80.29254114744958,
      },
      {
        lat: 13.108706116762768,
        lng: 80.2909345671857,
      },
      {
        lat: 13.106759886018324,
        lng: 80.28432441137106,
      },
      {
        lat: 13.104646731314617,
        lng: 80.29255774163627,
      },
      {
        lat: 13.106252608768626,
        lng: 80.28638207194776,
      },
      {
        lat: 13.107119704632643,
        lng: 80.28229853258391,
      },
      {
        lat: 13.103147985060437,
        lng: 80.29682637775602,
      },
      {
        lat: 13.106869777517975,
        lng: 80.28728934132093,
      },
      {
        lat: 13.106318655004927,
        lng: 80.28640946652271,
      },
      {
        lat: 13.108929566832938,
        lng: 80.28594173177726,
      },
      {
        lat: 13.107656682592221,
        lng: 80.2855879721141,
      },
      {
        lat: 13.1063669482484,
        lng: 80.28297139959676,
      },
      {
        lat: 13.111283828082788,
        lng: 80.29095474750504,
      },
      {
        lat: 13.10694647251271,
        lng: 80.28603517952715,
      },
      {
        lat: 13.108764910704776,
        lng: 80.2860117177929,
      },
      {
        lat: 13.106684136311431,
        lng: 80.29201484000941,
      },
      {
        lat: 13.108910273792274,
        lng: 80.28664227898302,
      },
      {
        lat: 13.104528628332698,
        lng: 80.28979864223034,
      },
      {
        lat: 13.107918927724915,
        lng: 80.2859544742744,
      },
      {
        lat: 13.102844784820245,
        lng: 80.29773970237626,
      },
      {
        lat: 13.110172136389515,
        lng: 80.290667739121,
      },
      {
        lat: 13.112034447892974,
        lng: 80.28997289904635,
      },
      {
        lat: 13.106315698029006,
        lng: 80.28892193713642,
      },
      {
        lat: 13.103149383010717,
        lng: 80.29690418548181,
      },
      {
        lat: 13.108931365313993,
        lng: 80.28680132806713,
      },
      {
        lat: 13.110815279333798,
        lng: 80.2914479122507,
      },
      {
        lat: 13.103166336584744,
        lng: 80.29611451307203,
      },
      {
        lat: 13.108979463512126,
        lng: 80.29117051231957,
      },
      {
        lat: 13.106549625944396,
        lng: 80.28070987711708,
      },
      {
        lat: 13.10661201605517,
        lng: 80.28172968669121,
      },
      {
        lat: 13.109029901698829,
        lng: 80.2905674042421,
      },
      {
        lat: 13.105147528647029,
        lng: 80.29233440220132,
      },
      {
        lat: 13.104376204672766,
        lng: 80.29114064213223,
      },
      {
        lat: 13.104351226796735,
        lng: 80.29544586570172,
      },
      {
        lat: 13.103191163407164,
        lng: 80.29279326131648,
      },
      {
        lat: 13.106418189603342,
        lng: 80.29142376429465,
      },
      {
        lat: 13.106804668804708,
        lng: 80.28500409037768,
      },
      {
        lat: 13.108431912116858,
        lng: 80.28698419352541,
      },
      {
        lat: 13.103016105076701,
        lng: 80.2930585674504,
      },
      {
        lat: 13.105724217967506,
        lng: 80.28851852651667,
      },
      {
        lat: 13.110960953875306,
        lng: 80.29149816259351,
      },
      {
        lat: 13.104558590543029,
        lng: 80.28679715389,
      },
      {
        lat: 13.103822964014427,
        lng: 80.29097016737857,
      },
    ],
    cases: 87,
    coords: [
      {
        lat: 13.102180678518366,
        lng: 80.2976916588518,
      },
      {
        lat: 13.102460573688143,
        lng: 80.29587339156684,
      },
      {
        lat: 13.102575421590476,
        lng: 80.29547655508296,
      },
      {
        lat: 13.10250195244369,
        lng: 80.29496488164115,
      },
      {
        lat: 13.102568056960768,
        lng: 80.29426573777654,
      },
      {
        lat: 13.10256285448763,
        lng: 80.29056845163113,
      },
      {
        lat: 13.102756881469327,
        lng: 80.29060821465309,
      },
      {
        lat: 13.104908093289993,
        lng: 80.28569991755452,
      },
      {
        lat: 13.105112780078588,
        lng: 80.28465030862954,
      },
      {
        lat: 13.105401074142735,
        lng: 80.28192338954244,
      },
      {
        lat: 13.1055516433237,
        lng: 80.28006803732576,
      },
      {
        lat: 13.105761552416498,
        lng: 80.27996979231033,
      },
      {
        lat: 13.106020135255468,
        lng: 80.27988721343958,
      },
      {
        lat: 13.106750730383059,
        lng: 80.28020259189155,
      },
      {
        lat: 13.106465728621643,
        lng: 80.28126615744003,
      },
      {
        lat: 13.10648319184765,
        lng: 80.28147502472844,
      },
      {
        lat: 13.106662085996193,
        lng: 80.28160213536812,
      },
      {
        lat: 13.107799143166751,
        lng: 80.28218740124422,
      },
      {
        lat: 13.10838925018392,
        lng: 80.28070322334246,
      },
      {
        lat: 13.109249469822158,
        lng: 80.28065792058597,
      },
      {
        lat: 13.109364443648133,
        lng: 80.28056517209455,
      },
      {
        lat: 13.10947969656554,
        lng: 80.28051830896004,
      },
      {
        lat: 13.109559941016114,
        lng: 80.28041438246244,
      },
      {
        lat: 13.109618125657565,
        lng: 80.28048272160943,
      },
      {
        lat: 13.109560917835918,
        lng: 80.28057498121225,
      },
      {
        lat: 13.109422977153796,
        lng: 80.28069086793789,
      },
      {
        lat: 13.109470027231467,
        lng: 80.28082813289822,
      },
      {
        lat: 13.109910514094562,
        lng: 80.28106531546901,
      },
      {
        lat: 13.110512187587414,
        lng: 80.28120935843734,
      },
      {
        lat: 13.109007247718223,
        lng: 80.28652913731528,
      },
      {
        lat: 13.110821131923306,
        lng: 80.28757430211795,
      },
      {
        lat: 13.11170666671798,
        lng: 80.28795430390214,
      },
      {
        lat: 13.11168910466874,
        lng: 80.28844392153806,
      },
      {
        lat: 13.112204440872587,
        lng: 80.28874547824168,
      },
      {
        lat: 13.112206549562954,
        lng: 80.28909216760636,
      },
      {
        lat: 13.111833177634042,
        lng: 80.29186898523844,
      },
      {
        lat: 13.10930390073324,
        lng: 80.29140092216096,
      },
      {
        lat: 13.10905780456966,
        lng: 80.29146418856206,
      },
      {
        lat: 13.10901747076568,
        lng: 80.29158689716854,
      },
      {
        lat: 13.109162360297754,
        lng: 80.2917692218073,
      },
      {
        lat: 13.109204802791991,
        lng: 80.29199320256548,
      },
      {
        lat: 13.108702862974033,
        lng: 80.29389414300257,
      },
      {
        lat: 13.106130151254165,
        lng: 80.2930389512303,
      },
      {
        lat: 13.10493546645473,
        lng: 80.29586350892997,
      },
      {
        lat: 13.10418405293303,
        lng: 80.29843160952109,
      },
      {
        lat: 13.102642726982268,
        lng: 80.29798346224737,
      },
      {
        lat: 13.102180678518366,
        lng: 80.2976916588518,
      },
    ],
    properties: {
      Ward_No: 52,
      Zone_Name: "ROYAPURAM",
      fillColor: "#f6d04d",
      zname: "ROYAPURAM(52)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.092658499193625,
        lng: 80.28154256454279,
      },
      {
        lat: 13.088733786972968,
        lng: 80.28374538414758,
      },
      {
        lat: 13.092247872773143,
        lng: 80.27633386281096,
      },
      {
        lat: 13.090857699525625,
        lng: 80.28135093898094,
      },
      {
        lat: 13.093650231724855,
        lng: 80.28155531973799,
      },
      {
        lat: 13.093561243351541,
        lng: 80.27461549187059,
      },
      {
        lat: 13.094319115024637,
        lng: 80.28264366728548,
      },
      {
        lat: 13.093285361738504,
        lng: 80.27921126802094,
      },
      {
        lat: 13.08924464036996,
        lng: 80.28132444344668,
      },
      {
        lat: 13.093477783459317,
        lng: 80.28452769536811,
      },
      {
        lat: 13.08881978705675,
        lng: 80.28446283979807,
      },
      {
        lat: 13.088721728326806,
        lng: 80.28408844406707,
      },
      {
        lat: 13.092307132695973,
        lng: 80.28000275877876,
      },
      {
        lat: 13.089907971562342,
        lng: 80.27542207430409,
      },
      {
        lat: 13.094307086900763,
        lng: 80.27902886556346,
      },
      {
        lat: 13.095090501723275,
        lng: 80.2797955913422,
      },
      {
        lat: 13.08934363830206,
        lng: 80.28389480007746,
      },
      {
        lat: 13.093017702324207,
        lng: 80.28365677899517,
      },
      {
        lat: 13.092404671924033,
        lng: 80.28116403732659,
      },
      {
        lat: 13.088599130645628,
        lng: 80.28412750033405,
      },
      {
        lat: 13.090433119560736,
        lng: 80.27712228446914,
      },
      {
        lat: 13.093874694289706,
        lng: 80.28282338106817,
      },
      {
        lat: 13.092198706090826,
        lng: 80.27915549560508,
      },
      {
        lat: 13.091684801763845,
        lng: 80.27823893196049,
      },
      {
        lat: 13.093662788756964,
        lng: 80.27830536558422,
      },
      {
        lat: 13.093196683922784,
        lng: 80.2733478922416,
      },
      {
        lat: 13.092091610197597,
        lng: 80.28026618598088,
      },
      {
        lat: 13.092431125372492,
        lng: 80.28510321538621,
      },
      {
        lat: 13.089215131664721,
        lng: 80.28033644173586,
      },
      {
        lat: 13.09259542748951,
        lng: 80.27712424353957,
      },
      {
        lat: 13.094785342125812,
        lng: 80.27977575501546,
      },
      {
        lat: 13.08967613585746,
        lng: 80.27514718180468,
      },
      {
        lat: 13.09451213603463,
        lng: 80.28387243400856,
      },
      {
        lat: 13.092814965636538,
        lng: 80.27487421995308,
      },
      {
        lat: 13.09413058097677,
        lng: 80.27680105653762,
      },
      {
        lat: 13.091196057964392,
        lng: 80.28514707982613,
      },
      {
        lat: 13.08938288444972,
        lng: 80.27971412870599,
      },
      {
        lat: 13.089439842735587,
        lng: 80.28155858427935,
      },
      {
        lat: 13.092718807947998,
        lng: 80.27512600309811,
      },
      {
        lat: 13.092434635290202,
        lng: 80.27813708955152,
      },
      {
        lat: 13.091048186554021,
        lng: 80.28225157176654,
      },
      {
        lat: 13.091429148966734,
        lng: 80.27749929001163,
      },
      {
        lat: 13.093404155559929,
        lng: 80.27456464250251,
      },
      {
        lat: 13.093947648670731,
        lng: 80.28581256215043,
      },
      {
        lat: 13.093904567105051,
        lng: 80.27980187513417,
      },
      {
        lat: 13.093312307575923,
        lng: 80.2797693279378,
      },
      {
        lat: 13.09284607304467,
        lng: 80.2738407577752,
      },
      {
        lat: 13.090428032457963,
        lng: 80.28245874197385,
      },
      {
        lat: 13.0944215331495,
        lng: 80.27892155838884,
      },
      {
        lat: 13.090672210890832,
        lng: 80.28362659007843,
      },
      {
        lat: 13.093124480655195,
        lng: 80.28544465492321,
      },
      {
        lat: 13.093813889008294,
        lng: 80.28043873981144,
      },
      {
        lat: 13.09158778141298,
        lng: 80.27681743407082,
      },
      {
        lat: 13.093082350880273,
        lng: 80.27525469184678,
      },
      {
        lat: 13.092581183316536,
        lng: 80.2824537311259,
      },
      {
        lat: 13.09360330386451,
        lng: 80.28116040672343,
      },
      {
        lat: 13.09242167804011,
        lng: 80.28340820712154,
      },
      {
        lat: 13.090246121470527,
        lng: 80.28477996108852,
      },
      {
        lat: 13.089947203415644,
        lng: 80.27686161558024,
      },
      {
        lat: 13.09293620470434,
        lng: 80.28435078074855,
      },
      {
        lat: 13.09306836508389,
        lng: 80.27867585064115,
      },
    ],
    cases: 61,
    coords: [
      {
        lat: 13.09412284692675,
        lng: 80.28616377424073,
      },
      {
        lat: 13.089671180300375,
        lng: 80.28536078805051,
      },
      {
        lat: 13.088668606741612,
        lng: 80.28532333877348,
      },
      {
        lat: 13.088633762548692,
        lng: 80.28466524179132,
      },
      {
        lat: 13.088522750052368,
        lng: 80.28416090383152,
      },
      {
        lat: 13.088498576398615,
        lng: 80.28272183315205,
      },
      {
        lat: 13.088752426758846,
        lng: 80.28135696667856,
      },
      {
        lat: 13.089189826046681,
        lng: 80.27974556375851,
      },
      {
        lat: 13.08975309899158,
        lng: 80.27854650439053,
      },
      {
        lat: 13.08996748774015,
        lng: 80.27829970693864,
      },
      {
        lat: 13.089951509080047,
        lng: 80.27820797353621,
      },
      {
        lat: 13.089781517564536,
        lng: 80.2781481664624,
      },
      {
        lat: 13.089611060424499,
        lng: 80.27801180613429,
      },
      {
        lat: 13.089454346946125,
        lng: 80.27759972358774,
      },
      {
        lat: 13.089468276857815,
        lng: 80.27735462267115,
      },
      {
        lat: 13.08972855283812,
        lng: 80.27704619110754,
      },
      {
        lat: 13.089835560962552,
        lng: 80.27689217108001,
      },
      {
        lat: 13.08968052373288,
        lng: 80.2767556802493,
      },
      {
        lat: 13.089448573200604,
        lng: 80.27665046323362,
      },
      {
        lat: 13.08941447400711,
        lng: 80.27611485145835,
      },
      {
        lat: 13.08956727623846,
        lng: 80.27588388666825,
      },
      {
        lat: 13.089532432045546,
        lng: 80.27522578968615,
      },
      {
        lat: 13.089162261068054,
        lng: 80.2752136111001,
      },
      {
        lat: 13.089176377229602,
        lng: 80.27499913148523,
      },
      {
        lat: 13.089608227849187,
        lng: 80.27501078806048,
      },
      {
        lat: 13.089731121508622,
        lng: 80.27493319078445,
      },
      {
        lat: 13.089854853292577,
        lng: 80.27499338936636,
      },
      {
        lat: 13.09005605712871,
        lng: 80.27511417803827,
      },
      {
        lat: 13.090610615157866,
        lng: 80.27501761603575,
      },
      {
        lat: 13.090768629996484,
        lng: 80.27456643905084,
      },
      {
        lat: 13.091091891624078,
        lng: 80.27464382913841,
      },
      {
        lat: 13.092060580014772,
        lng: 80.27469572532839,
      },
      {
        lat: 13.092160715987864,
        lng: 80.27457468894976,
      },
      {
        lat: 13.09227688305065,
        lng: 80.27377244645054,
      },
      {
        lat: 13.092637932983514,
        lng: 80.27342885556884,
      },
      {
        lat: 13.093443772235775,
        lng: 80.27324175218912,
      },
      {
        lat: 13.093706270017579,
        lng: 80.27327959356971,
      },
      {
        lat: 13.09384894576259,
        lng: 80.27351876387206,
      },
      {
        lat: 13.093668238047302,
        lng: 80.27366051363401,
      },
      {
        lat: 13.093205833816459,
        lng: 80.27392483636878,
      },
      {
        lat: 13.093246789766072,
        lng: 80.27402464716641,
      },
      {
        lat: 13.093529217276526,
        lng: 80.27402225690915,
      },
      {
        lat: 13.094477366776147,
        lng: 80.27401423247403,
      },
      {
        lat: 13.094730982463206,
        lng: 80.27432926483985,
      },
      {
        lat: 13.095740796636871,
        lng: 80.27455357998896,
      },
      {
        lat: 13.095766797045627,
        lng: 80.27497251058372,
      },
      {
        lat: 13.095373793208648,
        lng: 80.27590728255065,
      },
      {
        lat: 13.094433541662143,
        lng: 80.2794081621686,
      },
      {
        lat: 13.094575239738019,
        lng: 80.27956996596878,
      },
      {
        lat: 13.095196144795908,
        lng: 80.27972430421022,
      },
      {
        lat: 13.095116655395325,
        lng: 80.28045659291757,
      },
      {
        lat: 13.095868987571547,
        lng: 80.28059531311501,
      },
      {
        lat: 13.096663319078441,
        lng: 80.28077513139833,
      },
      {
        lat: 13.09673713306034,
        lng: 80.2808988673027,
      },
      {
        lat: 13.096644462454147,
        lng: 80.2811069192834,
      },
      {
        lat: 13.096495095913438,
        lng: 80.28228960921759,
      },
      {
        lat: 13.096432916315395,
        lng: 80.28236267914951,
      },
      {
        lat: 13.0962758600397,
        lng: 80.28228110128335,
      },
      {
        lat: 13.096092114935947,
        lng: 80.28218846285303,
      },
      {
        lat: 13.095083292154804,
        lng: 80.28212714234138,
      },
      {
        lat: 13.094825188274324,
        lng: 80.28210604059443,
      },
      {
        lat: 13.09412284692675,
        lng: 80.28616377424073,
      },
    ],
    properties: {
      Ward_No: 57,
      Zone_Name: "ROYAPURAM",
      fillColor: "#f6d04d",
      zname: "ROYAPURAM(57)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.076893578809763,
        lng: 80.25656603859593,
      },
      {
        lat: 13.071362439038985,
        lng: 80.25647316229912,
      },
      {
        lat: 13.071376910465366,
        lng: 80.25009364001461,
      },
      {
        lat: 13.071114781935075,
        lng: 80.26236481294245,
      },
      {
        lat: 13.078746107301503,
        lng: 80.25675093810382,
      },
      {
        lat: 13.070004042384731,
        lng: 80.25435373039879,
      },
      {
        lat: 13.067687120014766,
        lng: 80.26247154189406,
      },
      {
        lat: 13.065578019626816,
        lng: 80.25786153631105,
      },
      {
        lat: 13.072378068583207,
        lng: 80.26693144889153,
      },
      {
        lat: 13.074208865446822,
        lng: 80.25622555743654,
      },
      {
        lat: 13.067168566187647,
        lng: 80.26278815572888,
      },
      {
        lat: 13.070441650977356,
        lng: 80.25410510606902,
      },
      {
        lat: 13.064319376436337,
        lng: 80.26003621332796,
      },
      {
        lat: 13.076008325609864,
        lng: 80.25267711177034,
      },
      {
        lat: 13.066912298684443,
        lng: 80.26222816385085,
      },
      {
        lat: 13.071475882907139,
        lng: 80.25639001572293,
      },
      {
        lat: 13.072399933963245,
        lng: 80.26085752195353,
      },
      {
        lat: 13.066812817633059,
        lng: 80.25586514778193,
      },
      {
        lat: 13.071588295298332,
        lng: 80.25170391448026,
      },
      {
        lat: 13.0654146707647,
        lng: 80.26019553721488,
      },
      {
        lat: 13.068308681287023,
        lng: 80.25669544305285,
      },
      {
        lat: 13.06759964085889,
        lng: 80.2637406103779,
      },
      {
        lat: 13.07132123015951,
        lng: 80.26176231771049,
      },
      {
        lat: 13.067854853304208,
        lng: 80.26014143807404,
      },
      {
        lat: 13.071481574564432,
        lng: 80.25927772190879,
      },
      {
        lat: 13.076496225370526,
        lng: 80.26061480623103,
      },
      {
        lat: 13.073411984484645,
        lng: 80.25086144492394,
      },
      {
        lat: 13.072065469202952,
        lng: 80.25854016706667,
      },
      {
        lat: 13.07143151925882,
        lng: 80.26529565347427,
      },
      {
        lat: 13.071348777001075,
        lng: 80.25395959470995,
      },
      {
        lat: 13.071225479024426,
        lng: 80.26110616638957,
      },
      {
        lat: 13.06898587226124,
        lng: 80.26024627910141,
      },
      {
        lat: 13.075625258357874,
        lng: 80.2539280778431,
      },
      {
        lat: 13.072592145185801,
        lng: 80.25998593089653,
      },
      {
        lat: 13.066721845570763,
        lng: 80.26208021216907,
      },
      {
        lat: 13.0717051262666,
        lng: 80.2601388931195,
      },
      {
        lat: 13.069857618317833,
        lng: 80.25975612615322,
      },
      {
        lat: 13.076724097627377,
        lng: 80.26526940685866,
      },
      {
        lat: 13.071138207054007,
        lng: 80.25461829384456,
      },
      {
        lat: 13.070362130681907,
        lng: 80.25850042892807,
      },
      {
        lat: 13.073934341001175,
        lng: 80.25917746870915,
      },
      {
        lat: 13.073349472784216,
        lng: 80.26515710728584,
      },
      {
        lat: 13.078086421251797,
        lng: 80.25839830412356,
      },
      {
        lat: 13.071143632401357,
        lng: 80.25541611861578,
      },
      {
        lat: 13.070735942872238,
        lng: 80.25350288692614,
      },
    ],
    cases: 45,
    coords: [
      {
        lat: 13.063827388788235,
        lng: 80.26050006355509,
      },
      {
        lat: 13.063730677988659,
        lng: 80.26028771010314,
      },
      {
        lat: 13.063539129143894,
        lng: 80.2601709023751,
      },
      {
        lat: 13.063180749372034,
        lng: 80.26007919235137,
      },
      {
        lat: 13.062582105210542,
        lng: 80.25970528649351,
      },
      {
        lat: 13.061813893019233,
        lng: 80.25890647185324,
      },
      {
        lat: 13.06140362842587,
        lng: 80.2581283135806,
      },
      {
        lat: 13.061256905559175,
        lng: 80.25753741105531,
      },
      {
        lat: 13.061396713640466,
        lng: 80.25699145508663,
      },
      {
        lat: 13.061751636019629,
        lng: 80.25651473586338,
      },
      {
        lat: 13.062726319027215,
        lng: 80.25596171413153,
      },
      {
        lat: 13.063560185547145,
        lng: 80.2557888565049,
      },
      {
        lat: 13.06396627245766,
        lng: 80.25588016277072,
      },
      {
        lat: 13.065373777105682,
        lng: 80.25589193646486,
      },
      {
        lat: 13.065778711551905,
        lng: 80.25579376631516,
      },
      {
        lat: 13.066990201556425,
        lng: 80.25495451117055,
      },
      {
        lat: 13.067299289551313,
        lng: 80.25478609488053,
      },
      {
        lat: 13.067179301414528,
        lng: 80.25466868151558,
      },
      {
        lat: 13.06677177392372,
        lng: 80.2543405297301,
      },
      {
        lat: 13.066794763144872,
        lng: 80.25419822053938,
      },
      {
        lat: 13.066985591699492,
        lng: 80.25419660550784,
      },
      {
        lat: 13.067824644308466,
        lng: 80.25487639175162,
      },
      {
        lat: 13.067896060958422,
        lng: 80.25485210156283,
      },
      {
        lat: 13.068085737048808,
        lng: 80.25466101011577,
      },
      {
        lat: 13.068796446155478,
        lng: 80.25384967898087,
      },
      {
        lat: 13.070051033441544,
        lng: 80.2522521144157,
      },
      {
        lat: 13.071300722754392,
        lng: 80.24984927508386,
      },
      {
        lat: 13.071661943055423,
        lng: 80.24945110341652,
      },
      {
        lat: 13.071733714596483,
        lng: 80.24968792735416,
      },
      {
        lat: 13.071893667478262,
        lng: 80.25044079593117,
      },
      {
        lat: 13.072015445733777,
        lng: 80.25059403803333,
      },
      {
        lat: 13.072585745366217,
        lng: 80.25069205993499,
      },
      {
        lat: 13.073137426832735,
        lng: 80.25056740100047,
      },
      {
        lat: 13.073499738206477,
        lng: 80.25053005183346,
      },
      {
        lat: 13.073706788170739,
        lng: 80.25051115810005,
      },
      {
        lat: 13.073845412258228,
        lng: 80.25059569196793,
      },
      {
        lat: 13.073864551698659,
        lng: 80.25090407547208,
      },
      {
        lat: 13.073920135133234,
        lng: 80.25152755259397,
      },
      {
        lat: 13.07397578112059,
        lng: 80.25216131403604,
      },
      {
        lat: 13.074097038101643,
        lng: 80.25222885347037,
      },
      {
        lat: 13.074235453679261,
        lng: 80.2522791062712,
      },
      {
        lat: 13.07445924522039,
        lng: 80.25217436376984,
      },
      {
        lat: 13.075097657964616,
        lng: 80.25211753646965,
      },
      {
        lat: 13.076461423241062,
        lng: 80.25210599457046,
      },
      {
        lat: 13.076582680221884,
        lng: 80.25217353400483,
      },
      {
        lat: 13.076584139791017,
        lng: 80.25241350147469,
      },
      {
        lat: 13.076708732930008,
        lng: 80.25302953798305,
      },
      {
        lat: 13.07679577697253,
        lng: 80.25314879121812,
      },
      {
        lat: 13.076934818079764,
        lng: 80.25330188722032,
      },
      {
        lat: 13.077210606685673,
        lng: 80.2532309874862,
      },
      {
        lat: 13.078366592213065,
        lng: 80.25311835558553,
      },
      {
        lat: 13.078504382261054,
        lng: 80.25306576518486,
      },
      {
        lat: 13.078591843323325,
        lng: 80.2532535805541,
      },
      {
        lat: 13.078628662635234,
        lng: 80.25363038009272,
      },
      {
        lat: 13.078823141220019,
        lng: 80.25438295646977,
      },
      {
        lat: 13.079070581144995,
        lng: 80.25697786121798,
      },
      {
        lat: 13.079426063367796,
        lng: 80.25982869753868,
      },
      {
        lat: 13.080328684895386,
        lng: 80.2630666074739,
      },
      {
        lat: 13.080698867787339,
        lng: 80.26524582647083,
      },
      {
        lat: 13.079253011416354,
        lng: 80.26535132603225,
      },
      {
        lat: 13.078014354217967,
        lng: 80.26554833491701,
      },
      {
        lat: 13.076604819385981,
        lng: 80.26544834869117,
      },
      {
        lat: 13.075833511321331,
        lng: 80.26526835066367,
      },
      {
        lat: 13.074744451224097,
        lng: 80.2653521779847,
      },
      {
        lat: 13.074031766244033,
        lng: 80.26554473543032,
      },
      {
        lat: 13.073152060791717,
        lng: 80.2660744528674,
      },
      {
        lat: 13.072461110127655,
        lng: 80.26675179347004,
      },
      {
        lat: 13.072331432207655,
        lng: 80.26705133226027,
      },
      {
        lat: 13.07220050638275,
        lng: 80.26714570322352,
      },
      {
        lat: 13.072050115160172,
        lng: 80.26712832344253,
      },
      {
        lat: 13.072007554095569,
        lng: 80.26630797009372,
      },
      {
        lat: 13.071930373180823,
        lng: 80.26597287684179,
      },
      {
        lat: 13.07189166927733,
        lng: 80.26578667859528,
      },
      {
        lat: 13.071665685382738,
        lng: 80.26569532825177,
      },
      {
        lat: 13.071121041888176,
        lng: 80.26571859029154,
      },
      {
        lat: 13.071117865402513,
        lng: 80.26519634491332,
      },
      {
        lat: 13.071173538893314,
        lng: 80.26508395824943,
      },
      {
        lat: 13.071248223998005,
        lng: 80.26500871584688,
      },
      {
        lat: 13.07124788366025,
        lng: 80.26495276098485,
      },
      {
        lat: 13.071078367377831,
        lng: 80.26487958532216,
      },
      {
        lat: 13.069912807041181,
        lng: 80.26474022911522,
      },
      {
        lat: 13.069480758433267,
        lng: 80.26474388565461,
      },
      {
        lat: 13.069292343982935,
        lng: 80.26465221735111,
      },
      {
        lat: 13.06743254305063,
        lng: 80.2646493047479,
      },
      {
        lat: 13.06722046570354,
        lng: 80.26375577573629,
      },
      {
        lat: 13.066971159250283,
        lng: 80.26291851954642,
      },
      {
        lat: 13.066325218160607,
        lng: 80.26173022114367,
      },
      {
        lat: 13.066209219896514,
        lng: 80.26119027802451,
      },
      {
        lat: 13.06620570307309,
        lng: 80.26061207778426,
      },
      {
        lat: 13.06513372600921,
        lng: 80.26041597181545,
      },
      {
        lat: 13.064211459492455,
        lng: 80.26012533590382,
      },
      {
        lat: 13.064060727932134,
        lng: 80.26005200126096,
      },
      {
        lat: 13.063892459554623,
        lng: 80.26018399342534,
      },
      {
        lat: 13.063827388788235,
        lng: 80.26050006355509,
      },
    ],
    properties: {
      Ward_No: 61,
      Zone_Name: "ROYAPURAM",
      fillColor: "#8bc24c",
      zname: "ROYAPURAM(61)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.083077435389955,
        lng: 80.26924185297874,
      },
      {
        lat: 13.08069755526422,
        lng: 80.26185866398642,
      },
      {
        lat: 13.089139542867688,
        lng: 80.26032072959315,
      },
      {
        lat: 13.084631164937276,
        lng: 80.2579143786641,
      },
      {
        lat: 13.082420048559085,
        lng: 80.2562896482733,
      },
      {
        lat: 13.079810533735694,
        lng: 80.26783277929223,
      },
      {
        lat: 13.088296610224733,
        lng: 80.26748405534214,
      },
      {
        lat: 13.079162661226114,
        lng: 80.25511765055978,
      },
      {
        lat: 13.085344748597123,
        lng: 80.26877738699324,
      },
      {
        lat: 13.085326417795134,
        lng: 80.25639673147626,
      },
      {
        lat: 13.081975283485159,
        lng: 80.267184511757,
      },
      {
        lat: 13.082210686917222,
        lng: 80.26416133596446,
      },
      {
        lat: 13.083580875458688,
        lng: 80.26812607652842,
      },
      {
        lat: 13.08697468371384,
        lng: 80.27047166846125,
      },
      {
        lat: 13.081712657652417,
        lng: 80.26103568446369,
      },
      {
        lat: 13.084223705852303,
        lng: 80.25909355066229,
      },
      {
        lat: 13.083405227619071,
        lng: 80.26123173705544,
      },
      {
        lat: 13.089434343442237,
        lng: 80.26437183611058,
      },
      {
        lat: 13.083212555135914,
        lng: 80.27156345045654,
      },
      {
        lat: 13.083594681724895,
        lng: 80.26076059058359,
      },
      {
        lat: 13.089340356037804,
        lng: 80.26871902877939,
      },
      {
        lat: 13.081417957590181,
        lng: 80.26975843751394,
      },
      {
        lat: 13.082469725950734,
        lng: 80.25788796338648,
      },
      {
        lat: 13.083215469626841,
        lng: 80.27113161367248,
      },
      {
        lat: 13.08262367755217,
        lng: 80.27137090804439,
      },
      {
        lat: 13.078354620968808,
        lng: 80.26576957260052,
      },
      {
        lat: 13.088268231381571,
        lng: 80.26008627431743,
      },
      {
        lat: 13.090017179308738,
        lng: 80.26297701242076,
      },
      {
        lat: 13.081874839790943,
        lng: 80.25702252438273,
      },
      {
        lat: 13.089746415955718,
        lng: 80.26548733918591,
      },
      {
        lat: 13.085669425562951,
        lng: 80.26154302595448,
      },
      {
        lat: 13.085144322766684,
        lng: 80.26605013961614,
      },
      {
        lat: 13.087890650016892,
        lng: 80.26645204251773,
      },
      {
        lat: 13.081392084659797,
        lng: 80.26938074699564,
      },
      {
        lat: 13.082221936904363,
        lng: 80.2680644759904,
      },
      {
        lat: 13.088630763829883,
        lng: 80.26952409289467,
      },
      {
        lat: 13.081280966291027,
        lng: 80.27232622477187,
      },
      {
        lat: 13.080502401379773,
        lng: 80.26968436135907,
      },
      {
        lat: 13.089427585197246,
        lng: 80.26011530140252,
      },
      {
        lat: 13.083495567938295,
        lng: 80.25906530046439,
      },
      {
        lat: 13.084065280257613,
        lng: 80.26228153903017,
      },
      {
        lat: 13.084855014347664,
        lng: 80.25722187114887,
      },
      {
        lat: 13.088245467741446,
        lng: 80.26203946207423,
      },
      {
        lat: 13.090218275406,
        lng: 80.25745095479853,
      },
      {
        lat: 13.086374337213039,
        lng: 80.26669618646633,
      },
      {
        lat: 13.083082389428347,
        lng: 80.25840867069978,
      },
      {
        lat: 13.082813298921742,
        lng: 80.25906176598133,
      },
      {
        lat: 13.081641507073046,
        lng: 80.26017985528605,
      },
      {
        lat: 13.080781803253256,
        lng: 80.25799492055118,
      },
      {
        lat: 13.086600608229926,
        lng: 80.26643752810958,
      },
      {
        lat: 13.084721094034183,
        lng: 80.26138512150334,
      },
      {
        lat: 13.080686813919238,
        lng: 80.25728010310762,
      },
      {
        lat: 13.080249544813379,
        lng: 80.26057178767748,
      },
      {
        lat: 13.082000141828443,
        lng: 80.272081982771,
      },
      {
        lat: 13.08713379924442,
        lng: 80.26888616247288,
      },
      {
        lat: 13.086186700993029,
        lng: 80.27101676479703,
      },
      {
        lat: 13.087863848698921,
        lng: 80.26445926950801,
      },
      {
        lat: 13.089187539272318,
        lng: 80.25790054722613,
      },
      {
        lat: 13.085457042488187,
        lng: 80.26533843162652,
      },
      {
        lat: 13.09037613243423,
        lng: 80.25921749416221,
      },
      {
        lat: 13.079198073136533,
        lng: 80.25684936483447,
      },
      {
        lat: 13.077536226130697,
        lng: 80.26610279512798,
      },
      {
        lat: 13.085345141130253,
        lng: 80.25816811963836,
      },
      {
        lat: 13.080971861692571,
        lng: 80.26360525805332,
      },
      {
        lat: 13.084783634576024,
        lng: 80.2598039137528,
      },
      {
        lat: 13.081063289601962,
        lng: 80.25663062250165,
      },
    ],
    cases: 66,
    coords: [
      {
        lat: 13.09090011698905,
        lng: 80.27375964252226,
      },
      {
        lat: 13.088017791043564,
        lng: 80.2737672998497,
      },
      {
        lat: 13.08360112432752,
        lng: 80.27457829505194,
      },
      {
        lat: 13.083055521611898,
        lng: 80.27454423183899,
      },
      {
        lat: 13.08287963678289,
        lng: 80.27444901842202,
      },
      {
        lat: 13.08276194892322,
        lng: 80.27431463167885,
      },
      {
        lat: 13.082645202097282,
        lng: 80.27433496013263,
      },
      {
        lat: 13.082450898522328,
        lng: 80.27441396615481,
      },
      {
        lat: 13.082003388628,
        lng: 80.27449511512776,
      },
      {
        lat: 13.081495254886201,
        lng: 80.27472420938508,
      },
      {
        lat: 13.08121337618032,
        lng: 80.27230308666945,
      },
      {
        lat: 13.080940224080338,
        lng: 80.27131670348662,
      },
      {
        lat: 13.080622314594592,
        lng: 80.27095767637624,
      },
      {
        lat: 13.080365778470036,
        lng: 80.27070664514527,
      },
      {
        lat: 13.079648920759379,
        lng: 80.27064036856575,
      },
      {
        lat: 13.079028104441269,
        lng: 80.27039242033257,
      },
      {
        lat: 13.079205917901366,
        lng: 80.26967953738466,
      },
      {
        lat: 13.079335234001928,
        lng: 80.26897912213792,
      },
      {
        lat: 13.079341583382131,
        lng: 80.26802654522761,
      },
      {
        lat: 13.079083727266559,
        lng: 80.26755849457162,
      },
      {
        lat: 13.078107913448209,
        lng: 80.2668433178152,
      },
      {
        lat: 13.076234348480124,
        lng: 80.26626836876842,
      },
      {
        lat: 13.076433164063282,
        lng: 80.26540828996379,
      },
      {
        lat: 13.076604819385981,
        lng: 80.26544834869117,
      },
      {
        lat: 13.078014354217967,
        lng: 80.26554833491701,
      },
      {
        lat: 13.079253011416354,
        lng: 80.26535132603225,
      },
      {
        lat: 13.080698867787339,
        lng: 80.26524582647083,
      },
      {
        lat: 13.080328684895386,
        lng: 80.2630666074739,
      },
      {
        lat: 13.079426063367796,
        lng: 80.25982869753868,
      },
      {
        lat: 13.079070581144995,
        lng: 80.25697786121798,
      },
      {
        lat: 13.078823141220019,
        lng: 80.25438295646977,
      },
      {
        lat: 13.0791529077344,
        lng: 80.25467156964054,
      },
      {
        lat: 13.079395004676519,
        lng: 80.25473808637496,
      },
      {
        lat: 13.081277385285267,
        lng: 80.25484214521093,
      },
      {
        lat: 13.08145895799195,
        lng: 80.25489203276182,
      },
      {
        lat: 13.081563681905772,
        lng: 80.25507970203118,
      },
      {
        lat: 13.081530303006978,
        lng: 80.25526854010029,
      },
      {
        lat: 13.0814287067411,
        lng: 80.25559508683796,
      },
      {
        lat: 13.081533222145227,
        lng: 80.25574847504026,
      },
      {
        lat: 13.082863921287284,
        lng: 80.2559771928109,
      },
      {
        lat: 13.083537798029239,
        lng: 80.25607433811261,
      },
      {
        lat: 13.083728106416688,
        lng: 80.25614129314707,
      },
      {
        lat: 13.083746099052812,
        lng: 80.25626113078208,
      },
      {
        lat: 13.08381629726352,
        lng: 80.25644909225124,
      },
      {
        lat: 13.083954712841138,
        lng: 80.25649934505205,
      },
      {
        lat: 13.084213134340379,
        lng: 80.25641145088444,
      },
      {
        lat: 13.084730707123482,
        lng: 80.256355646284,
      },
      {
        lat: 13.085092497222533,
        lng: 80.25623259444936,
      },
      {
        lat: 13.085833965801767,
        lng: 80.25608918788126,
      },
      {
        lat: 13.086359387001007,
        lng: 80.25595411260075,
      },
      {
        lat: 13.08646206207326,
        lng: 80.25643433095597,
      },
      {
        lat: 13.086625764039173,
        lng: 80.25681354369362,
      },
      {
        lat: 13.08694926933171,
        lng: 80.2569309946865,
      },
      {
        lat: 13.087855610054474,
        lng: 80.25668294628593,
      },
      {
        lat: 13.088298815551504,
        lng: 80.25657903790443,
      },
      {
        lat: 13.08850115864988,
        lng: 80.25667748284074,
      },
      {
        lat: 13.088664738783319,
        lng: 80.2570366651258,
      },
      {
        lat: 13.08876743323844,
        lng: 80.25733626825114,
      },
      {
        lat: 13.088949968440641,
        lng: 80.2574949752779,
      },
      {
        lat: 13.090420067692227,
        lng: 80.25706187228944,
      },
      {
        lat: 13.090702373370211,
        lng: 80.2570394515796,
      },
      {
        lat: 13.090884543075108,
        lng: 80.25713806724858,
      },
      {
        lat: 13.09102648782528,
        lng: 80.25725705483529,
      },
      {
        lat: 13.091052752842495,
        lng: 80.25825840673156,
      },
      {
        lat: 13.09099369465132,
        lng: 80.25849928436041,
      },
      {
        lat: 13.090732415194068,
        lng: 80.25866174750568,
      },
      {
        lat: 13.090633253880592,
        lng: 80.25894302750518,
      },
      {
        lat: 13.09057626684162,
        lng: 80.25952442282778,
      },
      {
        lat: 13.09047264852348,
        lng: 80.26238980194252,
      },
      {
        lat: 13.090163032132796,
        lng: 80.26455582254356,
      },
      {
        lat: 13.090271574546653,
        lng: 80.26581688739262,
      },
      {
        lat: 13.090460810534779,
        lng: 80.26707726931109,
      },
      {
        lat: 13.090571545933136,
        lng: 80.26869888230675,
      },
      {
        lat: 13.090634990093317,
        lng: 80.26917910097042,
      },
      {
        lat: 13.090938809322147,
        lng: 80.26937684450637,
      },
      {
        lat: 13.091584479750196,
        lng: 80.26939141151382,
      },
      {
        lat: 13.092049320630485,
        lng: 80.2695276978307,
      },
      {
        lat: 13.092334062957919,
        lng: 80.26990588617235,
      },
      {
        lat: 13.092398725442822,
        lng: 80.27058640936197,
      },
      {
        lat: 13.091603607448363,
        lng: 80.27253619256858,
      },
      {
        lat: 13.091386086087589,
        lng: 80.27325916692064,
      },
      {
        lat: 13.091085190838095,
        lng: 80.27354215424674,
      },
      {
        lat: 13.09090011698905,
        lng: 80.27375964252226,
      },
    ],
    properties: {
      Ward_No: 58,
      Zone_Name: "ROYAPURAM",
      fillColor: "#f6d04d",
      zname: "ROYAPURAM(58)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.068743180219418,
        lng: 80.27021484317581,
      },
      {
        lat: 13.065728462967062,
        lng: 80.27018161748894,
      },
      {
        lat: 13.066890275612428,
        lng: 80.2708107654265,
      },
      {
        lat: 13.063133143315747,
        lng: 80.26513746145514,
      },
      {
        lat: 13.072936956047373,
        lng: 80.2670064376442,
      },
      {
        lat: 13.066516296594529,
        lng: 80.26654630663832,
      },
      {
        lat: 13.063856990536417,
        lng: 80.26701372926635,
      },
      {
        lat: 13.069875890845067,
        lng: 80.27083811360025,
      },
      {
        lat: 13.059250206362945,
        lng: 80.26590573421178,
      },
      {
        lat: 13.071491995122186,
        lng: 80.26626807379778,
      },
      {
        lat: 13.067472579558581,
        lng: 80.26672009474557,
      },
      {
        lat: 13.069728800515117,
        lng: 80.26990234240424,
      },
      {
        lat: 13.062489779782464,
        lng: 80.27010137932773,
      },
      {
        lat: 13.064125360412536,
        lng: 80.27057348250442,
      },
      {
        lat: 13.069947327201051,
        lng: 80.26774093056756,
      },
      {
        lat: 13.069643291343448,
        lng: 80.26592305349907,
      },
      {
        lat: 13.074644043399628,
        lng: 80.26629270063812,
      },
      {
        lat: 13.062568219044381,
        lng: 80.2685807047141,
      },
      {
        lat: 13.066018676505928,
        lng: 80.2662393241526,
      },
      {
        lat: 13.064501040822055,
        lng: 80.26868447030598,
      },
      {
        lat: 13.063527243407604,
        lng: 80.26696353897701,
      },
      {
        lat: 13.062265967880666,
        lng: 80.2693812383421,
      },
      {
        lat: 13.073674347796194,
        lng: 80.26634147187491,
      },
      {
        lat: 13.065650109260742,
        lng: 80.26785559691673,
      },
      {
        lat: 13.06875013885491,
        lng: 80.26642800559947,
      },
      {
        lat: 13.067735947132041,
        lng: 80.26816262701722,
      },
      {
        lat: 13.059565700621464,
        lng: 80.26711198230058,
      },
      {
        lat: 13.06653011377135,
        lng: 80.26480920276708,
      },
      {
        lat: 13.060156516755876,
        lng: 80.2657440281506,
      },
      {
        lat: 13.067736958611427,
        lng: 80.27013498119877,
      },
      {
        lat: 13.063051320985888,
        lng: 80.26589542444425,
      },
      {
        lat: 13.068645986755488,
        lng: 80.26706905952665,
      },
      {
        lat: 13.06129962849127,
        lng: 80.2653256162472,
      },
      {
        lat: 13.066218068626679,
        lng: 80.26438649196864,
      },
      {
        lat: 13.063118548189232,
        lng: 80.26230760557716,
      },
      {
        lat: 13.074335954075092,
        lng: 80.26612699880626,
      },
      {
        lat: 13.061392693512175,
        lng: 80.2655283523769,
      },
      {
        lat: 13.068899155585708,
        lng: 80.26956993621553,
      },
      {
        lat: 13.066329983606346,
        lng: 80.26340876726181,
      },
      {
        lat: 13.066124980941996,
        lng: 80.26509638305282,
      },
      {
        lat: 13.06415463471294,
        lng: 80.2684695709613,
      },
      {
        lat: 13.063449316695195,
        lng: 80.26536174073053,
      },
      {
        lat: 13.066283694328783,
        lng: 80.26544957437446,
      },
      {
        lat: 13.066605958477295,
        lng: 80.26352348630857,
      },
      {
        lat: 13.068925473191348,
        lng: 80.27142835080025,
      },
      {
        lat: 13.063962446372399,
        lng: 80.2712167031793,
      },
      {
        lat: 13.067135328582262,
        lng: 80.26800126337756,
      },
      {
        lat: 13.07017540197954,
        lng: 80.26934014553675,
      },
      {
        lat: 13.067291412983572,
        lng: 80.27084847292542,
      },
      {
        lat: 13.063671864911658,
        lng: 80.2673900878867,
      },
      {
        lat: 13.059350427223404,
        lng: 80.26745921803858,
      },
      {
        lat: 13.059642210599987,
        lng: 80.27034351616798,
      },
      {
        lat: 13.071276375990402,
        lng: 80.26575863146434,
      },
      {
        lat: 13.06342608777414,
        lng: 80.26486717861066,
      },
      {
        lat: 13.071552201489165,
        lng: 80.26637759562749,
      },
      {
        lat: 13.069654700737615,
        lng: 80.27064338970942,
      },
      {
        lat: 13.065008070019193,
        lng: 80.26581878446704,
      },
      {
        lat: 13.069209242113496,
        lng: 80.26834388468549,
      },
      {
        lat: 13.074869885234506,
        lng: 80.2660164986431,
      },
      {
        lat: 13.063553201669961,
        lng: 80.26487780986874,
      },
      {
        lat: 13.068791822590176,
        lng: 80.26973524994078,
      },
      {
        lat: 13.063176756836077,
        lng: 80.26301115353029,
      },
      {
        lat: 13.060624756880458,
        lng: 80.26913966381865,
      },
      {
        lat: 13.07014390507762,
        lng: 80.27013267839396,
      },
      {
        lat: 13.066176327486144,
        lng: 80.26744630809318,
      },
    ],
    cases: 65,
    coords: [
      {
        lat: 13.076234348480124,
        lng: 80.26626836876842,
      },
      {
        lat: 13.075610325603556,
        lng: 80.26612044758298,
      },
      {
        lat: 13.074992338547572,
        lng: 80.26619653173604,
      },
      {
        lat: 13.074090220614249,
        lng: 80.26648758246752,
      },
      {
        lat: 13.073096121658272,
        lng: 80.2672990074266,
      },
      {
        lat: 13.07113228363704,
        lng: 80.26901612313844,
      },
      {
        lat: 13.070562729070538,
        lng: 80.2692335053316,
      },
      {
        lat: 13.070420735454848,
        lng: 80.26935279700776,
      },
      {
        lat: 13.070304538187367,
        lng: 80.26980252221513,
      },
      {
        lat: 13.069863103487894,
        lng: 80.27153037142237,
      },
      {
        lat: 13.069557772927224,
        lng: 80.27217064122938,
      },
      {
        lat: 13.06788691192967,
        lng: 80.27121644459149,
      },
      {
        lat: 13.067219917801086,
        lng: 80.27105676360951,
      },
      {
        lat: 13.066457926088345,
        lng: 80.2709215046062,
      },
      {
        lat: 13.064673453447421,
        lng: 80.27084213510311,
      },
      {
        lat: 13.064566294492993,
        lng: 80.27154659373805,
      },
      {
        lat: 13.064473019300456,
        lng: 80.27151042481162,
      },
      {
        lat: 13.064100592877857,
        lng: 80.27147661841465,
      },
      {
        lat: 13.063728616019958,
        lng: 80.27151672488962,
      },
      {
        lat: 13.063560338543375,
        lng: 80.27138879488123,
      },
      {
        lat: 13.063577262757173,
        lng: 80.27111146410903,
      },
      {
        lat: 13.06376066618788,
        lng: 80.27066641185723,
      },
      {
        lat: 13.06383420738631,
        lng: 80.2705179561053,
      },
      {
        lat: 13.063740707411336,
        lng: 80.27044483074302,
      },
      {
        lat: 13.063368280988735,
        lng: 80.27041102434602,
      },
      {
        lat: 13.062827914263037,
        lng: 80.27030472259437,
      },
      {
        lat: 13.06156220390375,
        lng: 80.27027847629098,
      },
      {
        lat: 13.061356931045596,
        lng: 80.2701878177224,
      },
      {
        lat: 13.061318924143055,
        lng: 80.27005878520012,
      },
      {
        lat: 13.061261745202337,
        lng: 80.2698375190898,
      },
      {
        lat: 13.06116813283637,
        lng: 80.2697459155096,
      },
      {
        lat: 13.060516892357189,
        lng: 80.26976990629579,
      },
      {
        lat: 13.060312743411178,
        lng: 80.26986402990732,
      },
      {
        lat: 13.060239539386403,
        lng: 80.27006792031327,
      },
      {
        lat: 13.0604478468077,
        lng: 80.27065749076837,
      },
      {
        lat: 13.060674427137032,
        lng: 80.27119146906735,
      },
      {
        lat: 13.060675213875564,
        lng: 80.27132081659342,
      },
      {
        lat: 13.06047117732082,
        lng: 80.2714334184229,
      },
      {
        lat: 13.059968592715338,
        lng: 80.27141919275763,
      },
      {
        lat: 13.05985535874609,
        lng: 80.27116144271714,
      },
      {
        lat: 13.059853560486582,
        lng: 80.27086579122893,
      },
      {
        lat: 13.059647725672331,
        lng: 80.27068274157016,
      },
      {
        lat: 13.059553663741262,
        lng: 80.27051722511786,
      },
      {
        lat: 13.059495922844674,
        lng: 80.27020356791738,
      },
      {
        lat: 13.059328319715341,
        lng: 80.270186507217,
      },
      {
        lat: 13.059198611097164,
        lng: 80.27028000082053,
      },
      {
        lat: 13.05912731772335,
        lng: 80.2707980209328,
      },
      {
        lat: 13.05905298978656,
        lng: 80.27081712915869,
      },
      {
        lat: 13.058846817798598,
        lng: 80.27057864484578,
      },
      {
        lat: 13.05873212274355,
        lng: 80.27008067797121,
      },
      {
        lat: 13.058633826979758,
        lng: 80.26945934227945,
      },
      {
        lat: 13.058530633568875,
        lng: 80.26818118398944,
      },
      {
        lat: 13.058263058217387,
        lng: 80.2673307607875,
      },
      {
        lat: 13.058282878161894,
        lng: 80.26666739145374,
      },
      {
        lat: 13.058327992256023,
        lng: 80.26624066576049,
      },
      {
        lat: 13.05827567526044,
        lng: 80.26548316385583,
      },
      {
        lat: 13.059325376368543,
        lng: 80.2654979657343,
      },
      {
        lat: 13.060065125133585,
        lng: 80.265539076591,
      },
      {
        lat: 13.060517910776554,
        lng: 80.26546418723518,
      },
      {
        lat: 13.061728824548732,
        lng: 80.26453019388262,
      },
      {
        lat: 13.06227495003339,
        lng: 80.26411343946292,
      },
      {
        lat: 13.062631168934985,
        lng: 80.26384988120732,
      },
      {
        lat: 13.062677723609404,
        lng: 80.26366000103371,
      },
      {
        lat: 13.062628719948483,
        lng: 80.2634472438239,
      },
      {
        lat: 13.062196906714265,
        lng: 80.26304824026165,
      },
      {
        lat: 13.062196042366086,
        lng: 80.26290613294987,
      },
      {
        lat: 13.06231458992241,
        lng: 80.26278670079532,
      },
      {
        lat: 13.063500209545019,
        lng: 80.26161606380225,
      },
      {
        lat: 13.063687004474822,
        lng: 80.26095128131584,
      },
      {
        lat: 13.063827388788235,
        lng: 80.26050006355509,
      },
      {
        lat: 13.063892459554623,
        lng: 80.26018399342534,
      },
      {
        lat: 13.064060727932134,
        lng: 80.26005200126096,
      },
      {
        lat: 13.064211459492455,
        lng: 80.26012533590382,
      },
      {
        lat: 13.06513372600921,
        lng: 80.26041597181545,
      },
      {
        lat: 13.06620570307309,
        lng: 80.26061207778426,
      },
      {
        lat: 13.066209219896514,
        lng: 80.26119027802451,
      },
      {
        lat: 13.066325218160607,
        lng: 80.26173022114367,
      },
      {
        lat: 13.066971159250283,
        lng: 80.26291851954642,
      },
      {
        lat: 13.06722046570354,
        lng: 80.26375577573629,
      },
      {
        lat: 13.06743254305063,
        lng: 80.2646493047479,
      },
      {
        lat: 13.069292343982935,
        lng: 80.26465221735111,
      },
      {
        lat: 13.069480758433267,
        lng: 80.26474388565461,
      },
      {
        lat: 13.069912807041181,
        lng: 80.26474022911522,
      },
      {
        lat: 13.071078367377831,
        lng: 80.26487958532216,
      },
      {
        lat: 13.07124788366025,
        lng: 80.26495276098485,
      },
      {
        lat: 13.071248223998005,
        lng: 80.26500871584688,
      },
      {
        lat: 13.071173538893314,
        lng: 80.26508395824943,
      },
      {
        lat: 13.071117865402513,
        lng: 80.26519634491332,
      },
      {
        lat: 13.071121041888176,
        lng: 80.26571859029154,
      },
      {
        lat: 13.071665685382738,
        lng: 80.26569532825177,
      },
      {
        lat: 13.07189166927733,
        lng: 80.26578667859528,
      },
      {
        lat: 13.071930373180823,
        lng: 80.26597287684179,
      },
      {
        lat: 13.072007554095569,
        lng: 80.26630797009372,
      },
      {
        lat: 13.072050115160172,
        lng: 80.26712832344253,
      },
      {
        lat: 13.07220050638275,
        lng: 80.26714570322352,
      },
      {
        lat: 13.072331432207655,
        lng: 80.26705133226027,
      },
      {
        lat: 13.072461110127655,
        lng: 80.26675179347004,
      },
      {
        lat: 13.073152060791717,
        lng: 80.2660744528674,
      },
      {
        lat: 13.074031766244033,
        lng: 80.26554473543032,
      },
      {
        lat: 13.074744451224097,
        lng: 80.2653521779847,
      },
      {
        lat: 13.075833511321331,
        lng: 80.26526835066367,
      },
      {
        lat: 13.076433164063282,
        lng: 80.26540828996379,
      },
      {
        lat: 13.076234348480124,
        lng: 80.26626836876842,
      },
    ],
    properties: {
      Ward_No: 63,
      Zone_Name: "ROYAPURAM",
      fillColor: "#f6d04d",
      zname: "ROYAPURAM(63)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.078391988860153,
        lng: 80.2699349344187,
      },
      {
        lat: 13.076020772066313,
        lng: 80.2712515643831,
      },
      {
        lat: 13.071548019348484,
        lng: 80.2739479228671,
      },
      {
        lat: 13.075146581186214,
        lng: 80.26672217697327,
      },
      {
        lat: 13.076236686347206,
        lng: 80.2686477848321,
      },
      {
        lat: 13.075009814485293,
        lng: 80.26691604152393,
      },
      {
        lat: 13.072408187359697,
        lng: 80.27235255623813,
      },
      {
        lat: 13.074770007824338,
        lng: 80.27296558453459,
      },
      {
        lat: 13.07813402965844,
        lng: 80.26924724092723,
      },
      {
        lat: 13.075789400633926,
        lng: 80.26998485657553,
      },
      {
        lat: 13.076717352627115,
        lng: 80.26910790245192,
      },
      {
        lat: 13.075024215967115,
        lng: 80.2680058720653,
      },
      {
        lat: 13.072905014709942,
        lng: 80.27087712355394,
      },
      {
        lat: 13.070832996669818,
        lng: 80.26956094542564,
      },
      {
        lat: 13.070536972194327,
        lng: 80.27156498989822,
      },
      {
        lat: 13.073307895106725,
        lng: 80.26876055021323,
      },
      {
        lat: 13.078216576634938,
        lng: 80.26912198024448,
      },
      {
        lat: 13.070448353792907,
        lng: 80.27099252268214,
      },
      {
        lat: 13.074488987235295,
        lng: 80.27119232882222,
      },
      {
        lat: 13.073391605393438,
        lng: 80.27124532563992,
      },
      {
        lat: 13.076224781918992,
        lng: 80.27068995867877,
      },
      {
        lat: 13.074293894683183,
        lng: 80.26863127715394,
      },
      {
        lat: 13.074887403869052,
        lng: 80.26671354075665,
      },
      {
        lat: 13.07691318945109,
        lng: 80.26821460305402,
      },
      {
        lat: 13.076597215225613,
        lng: 80.26872942121355,
      },
      {
        lat: 13.070548778844353,
        lng: 80.27222065565648,
      },
      {
        lat: 13.072303209603513,
        lng: 80.269200059117,
      },
      {
        lat: 13.072212007768735,
        lng: 80.27288901321909,
      },
      {
        lat: 13.077392360410732,
        lng: 80.26837415714371,
      },
      {
        lat: 13.075802804128825,
        lng: 80.26972758102647,
      },
      {
        lat: 13.075602344839623,
        lng: 80.27062852941425,
      },
      {
        lat: 13.077901399960073,
        lng: 80.27026271069236,
      },
    ],
    cases: 32,
    coords: [
      {
        lat: 13.079028104441269,
        lng: 80.27039242033257,
      },
      {
        lat: 13.0788043396316,
        lng: 80.27050634139646,
      },
      {
        lat: 13.078273149433642,
        lng: 80.27166186290495,
      },
      {
        lat: 13.077759533615536,
        lng: 80.27230338482511,
      },
      {
        lat: 13.0766241783555,
        lng: 80.2728268444831,
      },
      {
        lat: 13.07530177621832,
        lng: 80.27322856296267,
      },
      {
        lat: 13.074247469392878,
        lng: 80.27346358020952,
      },
      {
        lat: 13.072964466377096,
        lng: 80.273536100699,
      },
      {
        lat: 13.072758219981647,
        lng: 80.27366117042018,
      },
      {
        lat: 13.072243104035742,
        lng: 80.27405605662737,
      },
      {
        lat: 13.072037982736145,
        lng: 80.27436610313335,
      },
      {
        lat: 13.071809536567033,
        lng: 80.27424471232798,
      },
      {
        lat: 13.071621989647063,
        lng: 80.27404075924545,
      },
      {
        lat: 13.071124448080782,
        lng: 80.2739216458643,
      },
      {
        lat: 13.070646231043227,
        lng: 80.27357627455983,
      },
      {
        lat: 13.069557772927224,
        lng: 80.27217064122938,
      },
      {
        lat: 13.069863103487894,
        lng: 80.27153037142237,
      },
      {
        lat: 13.070304538187367,
        lng: 80.26980252221513,
      },
      {
        lat: 13.070420735454848,
        lng: 80.26935279700776,
      },
      {
        lat: 13.070562729070538,
        lng: 80.2692335053316,
      },
      {
        lat: 13.07113228363704,
        lng: 80.26901612313844,
      },
      {
        lat: 13.073096121658272,
        lng: 80.2672990074266,
      },
      {
        lat: 13.074090220614249,
        lng: 80.26648758246752,
      },
      {
        lat: 13.074992338547572,
        lng: 80.26619653173604,
      },
      {
        lat: 13.075610325603556,
        lng: 80.26612044758298,
      },
      {
        lat: 13.076234348480124,
        lng: 80.26626836876842,
      },
      {
        lat: 13.078107913448209,
        lng: 80.2668433178152,
      },
      {
        lat: 13.079083727266559,
        lng: 80.26755849457162,
      },
      {
        lat: 13.079341583382131,
        lng: 80.26802654522761,
      },
      {
        lat: 13.079335234001928,
        lng: 80.26897912213792,
      },
      {
        lat: 13.079205917901366,
        lng: 80.26967953738466,
      },
      {
        lat: 13.079028104441269,
        lng: 80.27039242033257,
      },
    ],
    properties: {
      Ward_No: 62,
      Zone_Name: "ROYAPURAM",
      fillColor: "#8bc24c",
      zname: "ROYAPURAM(62)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.08437234368631,
        lng: 80.2789070078465,
      },
      {
        lat: 13.08228543652595,
        lng: 80.27998720688574,
      },
      {
        lat: 13.080405518839127,
        lng: 80.27789233713425,
      },
      {
        lat: 13.08512595964157,
        lng: 80.28102680799431,
      },
      {
        lat: 13.080049410825145,
        lng: 80.27700701726297,
      },
      {
        lat: 13.069996774043737,
        lng: 80.27401587547467,
      },
      {
        lat: 13.065336902063917,
        lng: 80.27305794377843,
      },
      {
        lat: 13.066400319759467,
        lng: 80.27870647474163,
      },
      {
        lat: 13.081253152764099,
        lng: 80.27568839288769,
      },
      {
        lat: 13.07945238722702,
        lng: 80.27753886195609,
      },
      {
        lat: 13.083192345830156,
        lng: 80.27554584007632,
      },
      {
        lat: 13.078953150731241,
        lng: 80.28042095878591,
      },
      {
        lat: 13.06885760863576,
        lng: 80.27181815448482,
      },
      {
        lat: 13.065791521481687,
        lng: 80.27356092113246,
      },
      {
        lat: 13.079662742642052,
        lng: 80.27853905995988,
      },
      {
        lat: 13.067059966705312,
        lng: 80.27377625787244,
      },
      {
        lat: 13.06510569812583,
        lng: 80.27802325922886,
      },
      {
        lat: 13.067150377692743,
        lng: 80.27877998016349,
      },
      {
        lat: 13.082590017561635,
        lng: 80.27482796530721,
      },
      {
        lat: 13.079076491966783,
        lng: 80.27157740893543,
      },
      {
        lat: 13.080249279362572,
        lng: 80.27331932804088,
      },
      {
        lat: 13.068983566397268,
        lng: 80.27531816696006,
      },
      {
        lat: 13.067270493550769,
        lng: 80.27238796139402,
      },
      {
        lat: 13.088738031860764,
        lng: 80.27450338483351,
      },
      {
        lat: 13.083989130485882,
        lng: 80.27902220944266,
      },
      {
        lat: 13.087529875887318,
        lng: 80.28077262236316,
      },
      {
        lat: 13.065861664275735,
        lng: 80.27694908952517,
      },
      {
        lat: 13.079348289604741,
        lng: 80.2766778328621,
      },
      {
        lat: 13.067492970776504,
        lng: 80.27667418345845,
      },
      {
        lat: 13.083481332374765,
        lng: 80.27837391226521,
      },
      {
        lat: 13.080011007688668,
        lng: 80.27995515170556,
      },
      {
        lat: 13.08373419762567,
        lng: 80.2823120296161,
      },
      {
        lat: 13.06831234478425,
        lng: 80.27919179173362,
      },
      {
        lat: 13.088384939988295,
        lng: 80.27680430493436,
      },
      {
        lat: 13.076937644468032,
        lng: 80.27629912712766,
      },
      {
        lat: 13.066735446961783,
        lng: 80.27854828484452,
      },
      {
        lat: 13.069893886774699,
        lng: 80.27469291224149,
      },
      {
        lat: 13.083774878246006,
        lng: 80.27524815401284,
      },
      {
        lat: 13.079090571490303,
        lng: 80.28174067074107,
      },
      {
        lat: 13.072729646855061,
        lng: 80.27477734848708,
      },
      {
        lat: 13.077892661703466,
        lng: 80.28073490639049,
      },
      {
        lat: 13.087219263283217,
        lng: 80.27512105864464,
      },
      {
        lat: 13.088170976754851,
        lng: 80.27996668258827,
      },
      {
        lat: 13.069728770954862,
        lng: 80.2775206408726,
      },
      {
        lat: 13.068865533613147,
        lng: 80.27594802454136,
      },
      {
        lat: 13.086547516407613,
        lng: 80.27926382452922,
      },
      {
        lat: 13.081935730779358,
        lng: 80.27678071071693,
      },
      {
        lat: 13.087846860307614,
        lng: 80.28341500905941,
      },
      {
        lat: 13.07886480297673,
        lng: 80.27418126038913,
      },
      {
        lat: 13.088050204057756,
        lng: 80.27521225962774,
      },
      {
        lat: 13.066326877148136,
        lng: 80.27113997697461,
      },
      {
        lat: 13.08094898812702,
        lng: 80.27688028803017,
      },
      {
        lat: 13.090476673281136,
        lng: 80.27433565538045,
      },
      {
        lat: 13.078065769428136,
        lng: 80.27479062112664,
      },
      {
        lat: 13.080509383067684,
        lng: 80.28067774146668,
      },
      {
        lat: 13.077413960182238,
        lng: 80.2727497706064,
      },
      {
        lat: 13.070439401245324,
        lng: 80.27646132546694,
      },
      {
        lat: 13.083064721193232,
        lng: 80.27955659331572,
      },
      {
        lat: 13.08468303791579,
        lng: 80.28218244316717,
      },
    ],
    cases: 59,
    coords: [
      {
        lat: 13.09084481576933,
        lng: 80.27382462937472,
      },
      {
        lat: 13.090727308549347,
        lng: 80.27440653689547,
      },
      {
        lat: 13.090768629996543,
        lng: 80.27456643905084,
      },
      {
        lat: 13.09061061515793,
        lng: 80.27501761603571,
      },
      {
        lat: 13.090056057128766,
        lng: 80.27511417803827,
      },
      {
        lat: 13.089854853292577,
        lng: 80.27499338936636,
      },
      {
        lat: 13.089731121508622,
        lng: 80.27493319078458,
      },
      {
        lat: 13.089608227849245,
        lng: 80.2750107880605,
      },
      {
        lat: 13.089176377229602,
        lng: 80.27499913148523,
      },
      {
        lat: 13.089162261068054,
        lng: 80.2752136111001,
      },
      {
        lat: 13.089532432045546,
        lng: 80.27522578968615,
      },
      {
        lat: 13.089567276238515,
        lng: 80.27588388666827,
      },
      {
        lat: 13.08941447400711,
        lng: 80.27611485145835,
      },
      {
        lat: 13.089448573200658,
        lng: 80.27665046323362,
      },
      {
        lat: 13.089680523732937,
        lng: 80.27675568024931,
      },
      {
        lat: 13.089835560962605,
        lng: 80.27689217107995,
      },
      {
        lat: 13.089728552838178,
        lng: 80.27704619110754,
      },
      {
        lat: 13.089468276857874,
        lng: 80.27735462267113,
      },
      {
        lat: 13.089454346946182,
        lng: 80.2775997235877,
      },
      {
        lat: 13.089611060424556,
        lng: 80.2780118061343,
      },
      {
        lat: 13.089781517564536,
        lng: 80.2781481664624,
      },
      {
        lat: 13.089951509080104,
        lng: 80.27820797353631,
      },
      {
        lat: 13.089967487740205,
        lng: 80.27829970693858,
      },
      {
        lat: 13.089753098991581,
        lng: 80.27854650439048,
      },
      {
        lat: 13.089189826046681,
        lng: 80.27974556375851,
      },
      {
        lat: 13.088752426758903,
        lng: 80.2813569666785,
      },
      {
        lat: 13.088498576398615,
        lng: 80.28272183315205,
      },
      {
        lat: 13.088522750052368,
        lng: 80.28416090383145,
      },
      {
        lat: 13.088633762548692,
        lng: 80.28466524179132,
      },
      {
        lat: 13.088668606741667,
        lng: 80.28532333877344,
      },
      {
        lat: 13.08663705458399,
        lng: 80.28484473997422,
      },
      {
        lat: 13.085589789738622,
        lng: 80.28465761942707,
      },
      {
        lat: 13.085371726689992,
        lng: 80.28450267789428,
      },
      {
        lat: 13.085072688374526,
        lng: 80.28401524917976,
      },
      {
        lat: 13.084732506834932,
        lng: 80.28325379132161,
      },
      {
        lat: 13.083034455141727,
        lng: 80.28221642387942,
      },
      {
        lat: 13.081976785225388,
        lng: 80.28159202496666,
      },
      {
        lat: 13.08139173250474,
        lng: 80.28115638494288,
      },
      {
        lat: 13.080974746887831,
        lng: 80.28099469219656,
      },
      {
        lat: 13.080586833168928,
        lng: 80.2810530491456,
      },
      {
        lat: 13.07962023101498,
        lng: 80.28172211696514,
      },
      {
        lat: 13.079287781393491,
        lng: 80.2817800045072,
      },
      {
        lat: 13.078982896339495,
        lng: 80.28181012179563,
      },
      {
        lat: 13.078594312695987,
        lng: 80.28175833654535,
      },
      {
        lat: 13.075775120331981,
        lng: 80.27878066666692,
      },
      {
        lat: 13.071956559269594,
        lng: 80.274592886616,
      },
      {
        lat: 13.07177038746683,
        lng: 80.27461501627025,
      },
      {
        lat: 13.07162611499856,
        lng: 80.27471900745583,
      },
      {
        lat: 13.071586840887642,
        lng: 80.2750687584221,
      },
      {
        lat: 13.07080687990666,
        lng: 80.27616472324021,
      },
      {
        lat: 13.070519210044669,
        lng: 80.27651657644392,
      },
      {
        lat: 13.069637751004528,
        lng: 80.27794226481146,
      },
      {
        lat: 13.068405773100668,
        lng: 80.27959701408582,
      },
      {
        lat: 13.068027235980676,
        lng: 80.28060905414388,
      },
      {
        lat: 13.067914451576527,
        lng: 80.28042521697543,
      },
      {
        lat: 13.067560185670963,
        lng: 80.28031734020443,
      },
      {
        lat: 13.064857452913113,
        lng: 80.27963800570278,
      },
      {
        lat: 13.064595563070284,
        lng: 80.27941847211389,
      },
      {
        lat: 13.064575829076226,
        lng: 80.27923384743569,
      },
      {
        lat: 13.064665058184643,
        lng: 80.27860480051346,
      },
      {
        lat: 13.064977495886211,
        lng: 80.27795538534976,
      },
      {
        lat: 13.065806070670494,
        lng: 80.27648851853893,
      },
      {
        lat: 13.065932182769256,
        lng: 80.27580372195891,
      },
      {
        lat: 13.066482055888342,
        lng: 80.27441313054925,
      },
      {
        lat: 13.066682833097728,
        lng: 80.27376466039732,
      },
      {
        lat: 13.066756374295982,
        lng: 80.27361620464542,
      },
      {
        lat: 13.066662649538575,
        lng: 80.27350612284697,
      },
      {
        lat: 13.066365113008857,
        lng: 80.27354559931437,
      },
      {
        lat: 13.066160851671679,
        lng: 80.27362124470783,
      },
      {
        lat: 13.06515635680791,
        lng: 80.27370366268514,
      },
      {
        lat: 13.064635724076435,
        lng: 80.27378198561182,
      },
      {
        lat: 13.064523838802042,
        lng: 80.27374597418745,
      },
      {
        lat: 13.064485157552186,
        lng: 80.27350607235722,
      },
      {
        lat: 13.064610707695023,
        lng: 80.272728884687,
      },
      {
        lat: 13.064641971124411,
        lng: 80.27174922412836,
      },
      {
        lat: 13.064566294492996,
        lng: 80.27154659373817,
      },
      {
        lat: 13.064673453447478,
        lng: 80.2708421351031,
      },
      {
        lat: 13.066457926088402,
        lng: 80.2709215046062,
      },
      {
        lat: 13.067219917801143,
        lng: 80.27105676360951,
      },
      {
        lat: 13.06788691192967,
        lng: 80.27121644459149,
      },
      {
        lat: 13.069557772927277,
        lng: 80.2721706412294,
      },
      {
        lat: 13.070646231043284,
        lng: 80.27357627455976,
      },
      {
        lat: 13.071124448080841,
        lng: 80.27392164586426,
      },
      {
        lat: 13.07162198964712,
        lng: 80.27404075924545,
      },
      {
        lat: 13.071809536567033,
        lng: 80.27424471232798,
      },
      {
        lat: 13.072037982736145,
        lng: 80.27436610313335,
      },
      {
        lat: 13.07224310403574,
        lng: 80.27405605662732,
      },
      {
        lat: 13.072758219981647,
        lng: 80.27366117042018,
      },
      {
        lat: 13.072964466377094,
        lng: 80.27353610069912,
      },
      {
        lat: 13.074247469392935,
        lng: 80.27346358020947,
      },
      {
        lat: 13.075301776218378,
        lng: 80.27322856296279,
      },
      {
        lat: 13.076624178355557,
        lng: 80.2728268444831,
      },
      {
        lat: 13.077759533615593,
        lng: 80.27230338482511,
      },
      {
        lat: 13.078273149433699,
        lng: 80.27166186290495,
      },
      {
        lat: 13.0788043396316,
        lng: 80.27050634139646,
      },
      {
        lat: 13.079028104441324,
        lng: 80.27039242033253,
      },
      {
        lat: 13.079648920759379,
        lng: 80.27064036856575,
      },
      {
        lat: 13.080365778470092,
        lng: 80.27070664514527,
      },
      {
        lat: 13.080622314594644,
        lng: 80.27095767637617,
      },
      {
        lat: 13.080940224080338,
        lng: 80.27131670348662,
      },
      {
        lat: 13.08121337618038,
        lng: 80.27230308666945,
      },
      {
        lat: 13.081495254886201,
        lng: 80.27472420938508,
      },
      {
        lat: 13.082003388628,
        lng: 80.27449511512776,
      },
      {
        lat: 13.082450898522328,
        lng: 80.27441396615481,
      },
      {
        lat: 13.082645202097343,
        lng: 80.27433496013263,
      },
      {
        lat: 13.082761948923276,
        lng: 80.27431463167885,
      },
      {
        lat: 13.082879636782947,
        lng: 80.27444901842202,
      },
      {
        lat: 13.083055521611898,
        lng: 80.27454423183899,
      },
      {
        lat: 13.08360112432752,
        lng: 80.27457829505194,
      },
      {
        lat: 13.08801779104362,
        lng: 80.2737672998497,
      },
      {
        lat: 13.090900116989053,
        lng: 80.27375964252236,
      },
      {
        lat: 13.09084481576933,
        lng: 80.27382462937472,
      },
    ],
    properties: {
      Ward_No: 59,
      Zone_Name: "ROYAPURAM",
      fillColor: "#8bc24c",
      zname: "ROYAPURAM(59)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.106019049387445,
        lng: 80.19579907178804,
      },
      {
        lat: 13.10722124155644,
        lng: 80.20521787350731,
      },
      {
        lat: 13.10959702036545,
        lng: 80.20122730785927,
      },
      {
        lat: 13.105978433763045,
        lng: 80.19813008150588,
      },
      {
        lat: 13.111326925656343,
        lng: 80.20515233713698,
      },
      {
        lat: 13.104319020171516,
        lng: 80.20632136314549,
      },
      {
        lat: 13.108983226583613,
        lng: 80.1986976874637,
      },
      {
        lat: 13.114079194714032,
        lng: 80.20394014763008,
      },
      {
        lat: 13.107998843130318,
        lng: 80.20995471874201,
      },
      {
        lat: 13.115449312687213,
        lng: 80.20330508194058,
      },
      {
        lat: 13.109875588027593,
        lng: 80.19802069740315,
      },
      {
        lat: 13.10934825660943,
        lng: 80.19909647754285,
      },
      {
        lat: 13.105651327673538,
        lng: 80.19682084660758,
      },
      {
        lat: 13.104775691926744,
        lng: 80.19948127521744,
      },
      {
        lat: 13.115634577638916,
        lng: 80.20171747415057,
      },
      {
        lat: 13.10866612499133,
        lng: 80.20824410706122,
      },
      {
        lat: 13.108913305823702,
        lng: 80.1980238657052,
      },
      {
        lat: 13.10773571552289,
        lng: 80.19643708969981,
      },
      {
        lat: 13.108494792736803,
        lng: 80.20034278634792,
      },
      {
        lat: 13.10388598592685,
        lng: 80.2072026287475,
      },
      {
        lat: 13.10960908780466,
        lng: 80.2074843828782,
      },
      {
        lat: 13.113585348889876,
        lng: 80.20595844980907,
      },
      {
        lat: 13.110946766696648,
        lng: 80.20131452854162,
      },
      {
        lat: 13.115274501721508,
        lng: 80.20121497103096,
      },
      {
        lat: 13.117266818132762,
        lng: 80.2047661477635,
      },
      {
        lat: 13.105558059764409,
        lng: 80.20623317036586,
      },
      {
        lat: 13.107193681356227,
        lng: 80.20947535273741,
      },
      {
        lat: 13.107237381430204,
        lng: 80.20010655413834,
      },
      {
        lat: 13.105434673821229,
        lng: 80.1999787228931,
      },
      {
        lat: 13.107862706205156,
        lng: 80.19780744218328,
      },
      {
        lat: 13.109996728161894,
        lng: 80.19804216120458,
      },
      {
        lat: 13.11090515798534,
        lng: 80.20232159109113,
      },
      {
        lat: 13.113042188218527,
        lng: 80.20164187847189,
      },
      {
        lat: 13.104153955137972,
        lng: 80.20442989901571,
      },
      {
        lat: 13.106677263390724,
        lng: 80.1956309085144,
      },
      {
        lat: 13.104799959691757,
        lng: 80.20178670267713,
      },
      {
        lat: 13.110557090423148,
        lng: 80.20538969977108,
      },
      {
        lat: 13.114410313753714,
        lng: 80.20357402017265,
      },
      {
        lat: 13.10949298303907,
        lng: 80.21035168983197,
      },
      {
        lat: 13.111119999651663,
        lng: 80.20443365693068,
      },
      {
        lat: 13.109969491059632,
        lng: 80.20580525721259,
      },
      {
        lat: 13.105861681041231,
        lng: 80.20425317233241,
      },
      {
        lat: 13.107357805964314,
        lng: 80.20063177857908,
      },
      {
        lat: 13.112013748563706,
        lng: 80.20545921286573,
      },
      {
        lat: 13.105580117692478,
        lng: 80.20085785614688,
      },
      {
        lat: 13.103457891328171,
        lng: 80.2072860218161,
      },
      {
        lat: 13.1082924657192,
        lng: 80.20593719258554,
      },
      {
        lat: 13.111216749275643,
        lng: 80.19808086265381,
      },
      {
        lat: 13.106692328734647,
        lng: 80.19595737936622,
      },
      {
        lat: 13.114482007331244,
        lng: 80.2055203617931,
      },
      {
        lat: 13.10454087900183,
        lng: 80.20413543413848,
      },
      {
        lat: 13.116858137126519,
        lng: 80.20542238918182,
      },
      {
        lat: 13.10526585744501,
        lng: 80.19570540089555,
      },
      {
        lat: 13.117092447851002,
        lng: 80.20584437374671,
      },
      {
        lat: 13.102961533104764,
        lng: 80.19648642131524,
      },
      {
        lat: 13.111966464228,
        lng: 80.20198051419686,
      },
      {
        lat: 13.111421114492847,
        lng: 80.19852707691605,
      },
      {
        lat: 13.117518602601784,
        lng: 80.2031514274089,
      },
      {
        lat: 13.115277626423705,
        lng: 80.20346034267234,
      },
      {
        lat: 13.105466663604926,
        lng: 80.19846600471963,
      },
      {
        lat: 13.10945391179923,
        lng: 80.2015157466987,
      },
      {
        lat: 13.105346357657092,
        lng: 80.20513009917144,
      },
      {
        lat: 13.10395066096586,
        lng: 80.19663147869785,
      },
      {
        lat: 13.104858347173783,
        lng: 80.20651077016548,
      },
      {
        lat: 13.1057887611431,
        lng: 80.20146331146664,
      },
      {
        lat: 13.111410953715291,
        lng: 80.19928861035747,
      },
      {
        lat: 13.105742166270128,
        lng: 80.20987978865209,
      },
      {
        lat: 13.112735997146185,
        lng: 80.20347990938184,
      },
      {
        lat: 13.10677203169283,
        lng: 80.20375745413091,
      },
      {
        lat: 13.107713088106138,
        lng: 80.21023910662197,
      },
      {
        lat: 13.115884109183739,
        lng: 80.20442956519472,
      },
      {
        lat: 13.111728384491805,
        lng: 80.20044132581184,
      },
      {
        lat: 13.109647916764265,
        lng: 80.2033962163504,
      },
      {
        lat: 13.10742389545587,
        lng: 80.20138437680468,
      },
      {
        lat: 13.113071449684075,
        lng: 80.20285309464306,
      },
      {
        lat: 13.109127021601594,
        lng: 80.20574821337638,
      },
      {
        lat: 13.10822711330577,
        lng: 80.2078078970121,
      },
      {
        lat: 13.1152436165509,
        lng: 80.2019682237665,
      },
      {
        lat: 13.115153652927287,
        lng: 80.20254074189015,
      },
      {
        lat: 13.106658413592877,
        lng: 80.20342511905757,
      },
      {
        lat: 13.105277998560613,
        lng: 80.20415455022722,
      },
      {
        lat: 13.107760181737463,
        lng: 80.20186229479836,
      },
      {
        lat: 13.10233345700052,
        lng: 80.19512541355739,
      },
      {
        lat: 13.105711133139017,
        lng: 80.2015206003673,
      },
      {
        lat: 13.109338326303929,
        lng: 80.2027031579111,
      },
      {
        lat: 13.114232004355518,
        lng: 80.20605803936711,
      },
      {
        lat: 13.10689396186667,
        lng: 80.20054575006158,
      },
      {
        lat: 13.107971105169751,
        lng: 80.1964926500177,
      },
      {
        lat: 13.110317340687347,
        lng: 80.2025648165081,
      },
      {
        lat: 13.117740567700736,
        lng: 80.20502508056633,
      },
      {
        lat: 13.106895921480703,
        lng: 80.2092630667128,
      },
      {
        lat: 13.112074374997679,
        lng: 80.20344620259107,
      },
      {
        lat: 13.105538400732689,
        lng: 80.20334517139939,
      },
      {
        lat: 13.11452425641966,
        lng: 80.20271811198212,
      },
      {
        lat: 13.116396610389149,
        lng: 80.20378621496555,
      },
      {
        lat: 13.11024201386223,
        lng: 80.20637645349989,
      },
      {
        lat: 13.115886021032393,
        lng: 80.2059543772283,
      },
      {
        lat: 13.107723003362285,
        lng: 80.2054169348196,
      },
      {
        lat: 13.111551772729545,
        lng: 80.20301542909493,
      },
      {
        lat: 13.108815886194286,
        lng: 80.21008510160782,
      },
      {
        lat: 13.114404364211463,
        lng: 80.20344312862565,
      },
      {
        lat: 13.116644055929305,
        lng: 80.20607484120691,
      },
      {
        lat: 13.11753863612961,
        lng: 80.20460537912224,
      },
      {
        lat: 13.112981014762328,
        lng: 80.20689045140189,
      },
      {
        lat: 13.11579869205903,
        lng: 80.2017337240035,
      },
      {
        lat: 13.109112222502272,
        lng: 80.19837074304273,
      },
      {
        lat: 13.105723280550867,
        lng: 80.20853940922633,
      },
      {
        lat: 13.116723770441789,
        lng: 80.20429529276888,
      },
      {
        lat: 13.111574574460011,
        lng: 80.19880470716353,
      },
      {
        lat: 13.10428967593759,
        lng: 80.20676830152647,
      },
      {
        lat: 13.109448478937177,
        lng: 80.19900366076568,
      },
      {
        lat: 13.10629222963185,
        lng: 80.19985355281293,
      },
      {
        lat: 13.115772953570074,
        lng: 80.20613882063205,
      },
      {
        lat: 13.105960531440436,
        lng: 80.19532618377974,
      },
      {
        lat: 13.105007027713727,
        lng: 80.20556880823715,
      },
      {
        lat: 13.115256149784786,
        lng: 80.20646157766261,
      },
      {
        lat: 13.113407124620752,
        lng: 80.2037196698373,
      },
      {
        lat: 13.106584114880944,
        lng: 80.2002008907087,
      },
      {
        lat: 13.104130298887851,
        lng: 80.2021720039729,
      },
      {
        lat: 13.111445943531756,
        lng: 80.20546682484171,
      },
    ],
    cases: 120,
    coords: [
      {
        lat: 13.109785218076738,
        lng: 80.21057835360992,
      },
      {
        lat: 13.109503375394363,
        lng: 80.21064301361778,
      },
      {
        lat: 13.109192617365496,
        lng: 80.2106456436436,
      },
      {
        lat: 13.109021137424516,
        lng: 80.21055452332334,
      },
      {
        lat: 13.108787318202934,
        lng: 80.21043307339664,
      },
      {
        lat: 13.108554437356357,
        lng: 80.21046590152756,
      },
      {
        lat: 13.108383895790666,
        lng: 80.21052905926467,
      },
      {
        lat: 13.108025961032341,
        lng: 80.21043951695984,
      },
      {
        lat: 13.105539652934128,
        lng: 80.21014510441299,
      },
      {
        lat: 13.105682716244852,
        lng: 80.2081954554732,
      },
      {
        lat: 13.103433815263175,
        lng: 80.20777865365427,
      },
      {
        lat: 13.103303628930192,
        lng: 80.20760029404573,
      },
      {
        lat: 13.10337937063047,
        lng: 80.20731764223798,
      },
      {
        lat: 13.103838186805243,
        lng: 80.20633954007882,
      },
      {
        lat: 13.103987955002502,
        lng: 80.20549224019355,
      },
      {
        lat: 13.103718850391465,
        lng: 80.20369990360292,
      },
      {
        lat: 13.103337737963221,
        lng: 80.20047282368911,
      },
      {
        lat: 13.103014344647137,
        lng: 80.19824511170668,
      },
      {
        lat: 13.102096803212852,
        lng: 80.1959711534654,
      },
      {
        lat: 13.101954460516907,
        lng: 80.19457380455862,
      },
      {
        lat: 13.102946617941921,
        lng: 80.19485224602893,
      },
      {
        lat: 13.105919850310627,
        lng: 80.19515489805605,
      },
      {
        lat: 13.10732642432579,
        lng: 80.19571667056695,
      },
      {
        lat: 13.109811179300827,
        lng: 80.19712983322016,
      },
      {
        lat: 13.11080308750249,
        lng: 80.19736729989162,
      },
      {
        lat: 13.111917800417045,
        lng: 80.19743981960302,
      },
      {
        lat: 13.112166400526501,
        lng: 80.19760162326769,
      },
      {
        lat: 13.112047583390549,
        lng: 80.1984221670073,
      },
      {
        lat: 13.112175123351692,
        lng: 80.19903574122308,
      },
      {
        lat: 13.11263130559113,
        lng: 80.19940067260616,
      },
      {
        lat: 13.114329740706792,
        lng: 80.20045169791656,
      },
      {
        lat: 13.117725614043508,
        lng: 80.20238984934234,
      },
      {
        lat: 13.118512681573959,
        lng: 80.2028749110814,
      },
      {
        lat: 13.118642854145952,
        lng: 80.20287380939715,
      },
      {
        lat: 13.118652373640233,
        lng: 80.2044389075614,
      },
      {
        lat: 13.11865695784513,
        lng: 80.20519259577706,
      },
      {
        lat: 13.118819871949084,
        lng: 80.20597634929784,
      },
      {
        lat: 13.118854746872382,
        lng: 80.2065099441099,
      },
      {
        lat: 13.118855892923607,
        lng: 80.20669836616376,
      },
      {
        lat: 13.118666890289065,
        lng: 80.20682558691095,
      },
      {
        lat: 13.11812902705257,
        lng: 80.20679873369471,
      },
      {
        lat: 13.117495325439021,
        lng: 80.20661566512263,
      },
      {
        lat: 13.116293469870566,
        lng: 80.20662583673796,
      },
      {
        lat: 13.115536122272594,
        lng: 80.20691489399708,
      },
      {
        lat: 13.114461541850943,
        lng: 80.20704960961854,
      },
      {
        lat: 13.111994193140763,
        lng: 80.20700768084619,
      },
      {
        lat: 13.111107087285118,
        lng: 80.20676394631536,
      },
      {
        lat: 13.110506159501012,
        lng: 80.20676903212305,
      },
      {
        lat: 13.110064898675663,
        lng: 80.20702400896558,
      },
      {
        lat: 13.110038046110876,
        lng: 80.20780936853087,
      },
      {
        lat: 13.109785218076738,
        lng: 80.21057835360992,
      },
    ],
    properties: {
      Ward_No: 94,
      Zone_Name: "ANNANAGAR",
      fillColor: "#de4307",
      zname: "ANNANAGAR(94)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.096375438689407,
        lng: 80.21332426327626,
      },
      {
        lat: 13.078915488719113,
        lng: 80.21103189415594,
      },
      {
        lat: 13.096405372023387,
        lng: 80.21092580783383,
      },
      {
        lat: 13.087096619041752,
        lng: 80.21283594649364,
      },
      {
        lat: 13.093388974452532,
        lng: 80.21575269295695,
      },
      {
        lat: 13.077749723409813,
        lng: 80.2053760168461,
      },
      {
        lat: 13.078046957112223,
        lng: 80.20351790689831,
      },
      {
        lat: 13.083674985803395,
        lng: 80.21381646438306,
      },
      {
        lat: 13.084702653561182,
        lng: 80.2120866362229,
      },
      {
        lat: 13.092014265609382,
        lng: 80.21387527082115,
      },
      {
        lat: 13.0923867708857,
        lng: 80.21551423070865,
      },
      {
        lat: 13.09824565429371,
        lng: 80.20288225178889,
      },
      {
        lat: 13.095042042812931,
        lng: 80.21849141648667,
      },
      {
        lat: 13.096525381160085,
        lng: 80.21902935103839,
      },
      {
        lat: 13.075775373788051,
        lng: 80.21781723671764,
      },
      {
        lat: 13.097975349575444,
        lng: 80.20861436051796,
      },
      {
        lat: 13.097431667771023,
        lng: 80.20594073695528,
      },
      {
        lat: 13.076248561530724,
        lng: 80.21623707030763,
      },
      {
        lat: 13.09560093424344,
        lng: 80.21808659591306,
      },
      {
        lat: 13.07721383029536,
        lng: 80.20975934459344,
      },
      {
        lat: 13.097659946947724,
        lng: 80.21317037936112,
      },
      {
        lat: 13.085321713636668,
        lng: 80.2111514057346,
      },
      {
        lat: 13.082474982357237,
        lng: 80.21586269538483,
      },
      {
        lat: 13.078557529813223,
        lng: 80.208561358082,
      },
      {
        lat: 13.083911312838351,
        lng: 80.21149250968296,
      },
      {
        lat: 13.080235354284348,
        lng: 80.21267394645142,
      },
      {
        lat: 13.096655208579506,
        lng: 80.21088222201669,
      },
      {
        lat: 13.096968437897866,
        lng: 80.21713460731274,
      },
      {
        lat: 13.096489050648085,
        lng: 80.2135834687825,
      },
      {
        lat: 13.078543109491461,
        lng: 80.20498294293299,
      },
      {
        lat: 13.0767382345645,
        lng: 80.21241384749257,
      },
      {
        lat: 13.098249299126081,
        lng: 80.21102311301371,
      },
      {
        lat: 13.096064473725457,
        lng: 80.2121698198789,
      },
      {
        lat: 13.089930009678652,
        lng: 80.21102384127836,
      },
      {
        lat: 13.082656302337247,
        lng: 80.20390201530793,
      },
      {
        lat: 13.0775222530159,
        lng: 80.20671125863012,
      },
      {
        lat: 13.080642769314412,
        lng: 80.2154883044216,
      },
      {
        lat: 13.08988336295559,
        lng: 80.21107814295088,
      },
      {
        lat: 13.07718327097439,
        lng: 80.21594314292045,
      },
      {
        lat: 13.096110050011275,
        lng: 80.21856535502118,
      },
      {
        lat: 13.096889459324899,
        lng: 80.20898943714845,
      },
      {
        lat: 13.098328723659069,
        lng: 80.2125770957629,
      },
      {
        lat: 13.078399116557206,
        lng: 80.21366651004087,
      },
      {
        lat: 13.078600962601081,
        lng: 80.21082547749506,
      },
      {
        lat: 13.08264190300427,
        lng: 80.20763499775113,
      },
      {
        lat: 13.100583058652445,
        lng: 80.20880401954307,
      },
      {
        lat: 13.095989150238966,
        lng: 80.21618415386979,
      },
      {
        lat: 13.09798490398848,
        lng: 80.20667080453285,
      },
      {
        lat: 13.100724044272285,
        lng: 80.20602961839488,
      },
      {
        lat: 13.078365176827814,
        lng: 80.20909379909602,
      },
      {
        lat: 13.098267129382384,
        lng: 80.2054877737165,
      },
      {
        lat: 13.094225936095814,
        lng: 80.21320949792887,
      },
      {
        lat: 13.07704295019948,
        lng: 80.20640023827492,
      },
      {
        lat: 13.075798602546364,
        lng: 80.21819225140233,
      },
      {
        lat: 13.099604050123752,
        lng: 80.21132013792457,
      },
      {
        lat: 13.09429641493619,
        lng: 80.21771160525638,
      },
      {
        lat: 13.09599053705706,
        lng: 80.20909849180454,
      },
      {
        lat: 13.097350913702897,
        lng: 80.21070411633877,
      },
      {
        lat: 13.083118297058155,
        lng: 80.20931986779162,
      },
      {
        lat: 13.079789901925936,
        lng: 80.20827566867615,
      },
      {
        lat: 13.08684789632056,
        lng: 80.21182306750616,
      },
      {
        lat: 13.08492051865025,
        lng: 80.2081710678095,
      },
      {
        lat: 13.079399735589648,
        lng: 80.20564618551519,
      },
      {
        lat: 13.080724323455941,
        lng: 80.21358259559243,
      },
      {
        lat: 13.081035072634906,
        lng: 80.21361074182701,
      },
      {
        lat: 13.08397909641721,
        lng: 80.2114963132635,
      },
      {
        lat: 13.09628364452041,
        lng: 80.21446219721258,
      },
      {
        lat: 13.077776464099866,
        lng: 80.21047241752598,
      },
      {
        lat: 13.0881154495014,
        lng: 80.21361822910373,
      },
      {
        lat: 13.08834044305447,
        lng: 80.21505909121167,
      },
      {
        lat: 13.077329067260997,
        lng: 80.20616836436386,
      },
      {
        lat: 13.078078856373676,
        lng: 80.21630299952818,
      },
      {
        lat: 13.085556245017358,
        lng: 80.21418501821069,
      },
      {
        lat: 13.08002311748114,
        lng: 80.20075384290834,
      },
      {
        lat: 13.094572577434288,
        lng: 80.21181618933636,
      },
      {
        lat: 13.084023363040702,
        lng: 80.21320720380199,
      },
      {
        lat: 13.079991427270144,
        lng: 80.21794890294302,
      },
      {
        lat: 13.07664558615984,
        lng: 80.21522080690593,
      },
      {
        lat: 13.07856186776504,
        lng: 80.20519705764492,
      },
      {
        lat: 13.084530359294666,
        lng: 80.21645098107179,
      },
      {
        lat: 13.080748193815111,
        lng: 80.20680965808354,
      },
      {
        lat: 13.075208864480631,
        lng: 80.21705919466385,
      },
      {
        lat: 13.07963719184618,
        lng: 80.21343889723666,
      },
      {
        lat: 13.077658353943153,
        lng: 80.21245318695351,
      },
      {
        lat: 13.085130093088857,
        lng: 80.21152520715953,
      },
      {
        lat: 13.09534017386637,
        lng: 80.21206459170583,
      },
      {
        lat: 13.077815258848988,
        lng: 80.20903541610797,
      },
      {
        lat: 13.080384856707749,
        lng: 80.20881654959638,
      },
      {
        lat: 13.097458888300862,
        lng: 80.21270102374237,
      },
      {
        lat: 13.084159603394593,
        lng: 80.21437973133055,
      },
      {
        lat: 13.098711087591147,
        lng: 80.205245303865,
      },
      {
        lat: 13.07967477450323,
        lng: 80.20820467794502,
      },
      {
        lat: 13.098798138159987,
        lng: 80.20426788103671,
      },
      {
        lat: 13.083688808995984,
        lng: 80.21687501351089,
      },
      {
        lat: 13.100171999014723,
        lng: 80.20862731652232,
      },
      {
        lat: 13.077616490199407,
        lng: 80.20862182032107,
      },
      {
        lat: 13.0785676449516,
        lng: 80.2045551896829,
      },
      {
        lat: 13.095796608164978,
        lng: 80.21699461852434,
      },
      {
        lat: 13.080492206895562,
        lng: 80.21427546653447,
      },
      {
        lat: 13.095624577545363,
        lng: 80.20972339317285,
      },
      {
        lat: 13.097183314137009,
        lng: 80.2150528685158,
      },
      {
        lat: 13.08022006828583,
        lng: 80.20857866973503,
      },
      {
        lat: 13.099489344229989,
        lng: 80.20614432528657,
      },
      {
        lat: 13.091886802736072,
        lng: 80.21245912259666,
      },
      {
        lat: 13.0978621363277,
        lng: 80.21016514997801,
      },
      {
        lat: 13.09136351008569,
        lng: 80.2139966272195,
      },
      {
        lat: 13.096886823625928,
        lng: 80.21462851078111,
      },
      {
        lat: 13.082030848489282,
        lng: 80.21488986554418,
      },
      {
        lat: 13.0756839654298,
        lng: 80.21414850853449,
      },
      {
        lat: 13.086157717956825,
        lng: 80.2139584692841,
      },
    ],
    cases: 110,
    coords: [
      {
        lat: 13.079999033202892,
        lng: 80.20036377637557,
      },
      {
        lat: 13.080114222511067,
        lng: 80.2004460617757,
      },
      {
        lat: 13.08003273779867,
        lng: 80.20166484869742,
      },
      {
        lat: 13.079945240367831,
        lng: 80.20189508580279,
      },
      {
        lat: 13.080005018510244,
        lng: 80.20295379492448,
      },
      {
        lat: 13.080610029394332,
        lng: 80.20303694248057,
      },
      {
        lat: 13.082868561918213,
        lng: 80.20312374943792,
      },
      {
        lat: 13.082844493208743,
        lng: 80.2050128866251,
      },
      {
        lat: 13.082756566298249,
        lng: 80.20517251302925,
      },
      {
        lat: 13.082759465287413,
        lng: 80.20564913526208,
      },
      {
        lat: 13.084982118405213,
        lng: 80.20568328512424,
      },
      {
        lat: 13.085018794167816,
        lng: 80.20879000551064,
      },
      {
        lat: 13.084878605012813,
        lng: 80.20912661006386,
      },
      {
        lat: 13.084903040598872,
        lng: 80.21022092546654,
      },
      {
        lat: 13.08566752246562,
        lng: 80.21021445545824,
      },
      {
        lat: 13.085952732424525,
        lng: 80.21033561674054,
      },
      {
        lat: 13.088878773258882,
        lng: 80.21038651591158,
      },
      {
        lat: 13.089148390507958,
        lng: 80.21046283581425,
      },
      {
        lat: 13.09210911281744,
        lng: 80.21046921915088,
      },
      {
        lat: 13.092347353468508,
        lng: 80.21059296564785,
      },
      {
        lat: 13.092553357013738,
        lng: 80.21062266288374,
      },
      {
        lat: 13.092561922223647,
        lng: 80.20942784389537,
      },
      {
        lat: 13.092704120841102,
        lng: 80.20937947938557,
      },
      {
        lat: 13.092741534586745,
        lng: 80.20875889925395,
      },
      {
        lat: 13.0930703714819,
        lng: 80.20868345789049,
      },
      {
        lat: 13.093332083609612,
        lng: 80.20839586705631,
      },
      {
        lat: 13.093400317029264,
        lng: 80.20780075646161,
      },
      {
        lat: 13.094574146498164,
        lng: 80.20783838468773,
      },
      {
        lat: 13.095580616646718,
        lng: 80.2079249919687,
      },
      {
        lat: 13.095937260138369,
        lng: 80.20749390975769,
      },
      {
        lat: 13.096596717315235,
        lng: 80.20565716659787,
      },
      {
        lat: 13.097778875551276,
        lng: 80.20312634126705,
      },
      {
        lat: 13.097892115786744,
        lng: 80.20205522327377,
      },
      {
        lat: 13.098058607271845,
        lng: 80.20186356361542,
      },
      {
        lat: 13.099112543102505,
        lng: 80.20187842520778,
      },
      {
        lat: 13.099328669857575,
        lng: 80.2019717213698,
      },
      {
        lat: 13.099449575969977,
        lng: 80.20216094870774,
      },
      {
        lat: 13.099308046759118,
        lng: 80.20251886637676,
      },
      {
        lat: 13.099359562338568,
        lng: 80.20311296350481,
      },
      {
        lat: 13.101105499163877,
        lng: 80.20664160457031,
      },
      {
        lat: 13.101680728276792,
        lng: 80.2067080802314,
      },
      {
        lat: 13.101825294910878,
        lng: 80.20684954467467,
      },
      {
        lat: 13.1016836210606,
        lng: 80.20718368224303,
      },
      {
        lat: 13.099769722330702,
        lng: 80.21148051851206,
      },
      {
        lat: 13.098635174415849,
        lng: 80.21396337825487,
      },
      {
        lat: 13.098400738789534,
        lng: 80.21479770870921,
      },
      {
        lat: 13.097502796088802,
        lng: 80.21680293950676,
      },
      {
        lat: 13.096749420022155,
        lng: 80.21894963474753,
      },
      {
        lat: 13.096438940459956,
        lng: 80.21909495036495,
      },
      {
        lat: 13.095982881789162,
        lng: 80.21893234083483,
      },
      {
        lat: 13.094422673461306,
        lng: 80.2183747934895,
      },
      {
        lat: 13.094204666396815,
        lng: 80.21797235601969,
      },
      {
        lat: 13.094032967900857,
        lng: 80.21730793205668,
      },
      {
        lat: 13.093839633832102,
        lng: 80.21702419239651,
      },
      {
        lat: 13.093595796658404,
        lng: 80.21631281631261,
      },
      {
        lat: 13.09347344415392,
        lng: 80.2158857879687,
      },
      {
        lat: 13.089686143386333,
        lng: 80.21580459273322,
      },
      {
        lat: 13.087115039520706,
        lng: 80.21569342480697,
      },
      {
        lat: 13.086659236266126,
        lng: 80.215590940112,
      },
      {
        lat: 13.0846244190808,
        lng: 80.21560816129752,
      },
      {
        lat: 13.084670962371428,
        lng: 80.21773190645115,
      },
      {
        lat: 13.08450638439534,
        lng: 80.21802233953954,
      },
      {
        lat: 13.084195509067715,
        lng: 80.21819013639931,
      },
      {
        lat: 13.081949597266824,
        lng: 80.21814720694287,
      },
      {
        lat: 13.081429420771043,
        lng: 80.21808967214088,
      },
      {
        lat: 13.080119148593313,
        lng: 80.2180388241237,
      },
      {
        lat: 13.07968176335895,
        lng: 80.21791865144732,
      },
      {
        lat: 13.079474220147867,
        lng: 80.21798234512988,
      },
      {
        lat: 13.079286715518002,
        lng: 80.21792199483966,
      },
      {
        lat: 13.078932874955381,
        lng: 80.21786305229367,
      },
      {
        lat: 13.078559623652891,
        lng: 80.21803137705743,
      },
      {
        lat: 13.077417068660225,
        lng: 80.2182062126381,
      },
      {
        lat: 13.076898022279536,
        lng: 80.21833447984308,
      },
      {
        lat: 13.076398512207065,
        lng: 80.21825612440573,
      },
      {
        lat: 13.075919919694606,
        lng: 80.21819823766786,
      },
      {
        lat: 13.074797026578219,
        lng: 80.21818709527335,
      },
      {
        lat: 13.074998165803635,
        lng: 80.217070523551,
      },
      {
        lat: 13.075117141609377,
        lng: 80.21611981304038,
      },
      {
        lat: 13.075358483566088,
        lng: 80.2147757980403,
      },
      {
        lat: 13.07580950339617,
        lng: 80.21371904870402,
      },
      {
        lat: 13.076962930480915,
        lng: 80.21191310842734,
      },
      {
        lat: 13.077084919927001,
        lng: 80.21145786993547,
      },
      {
        lat: 13.077175946345148,
        lng: 80.20933058934834,
      },
      {
        lat: 13.077039516538774,
        lng: 80.20741169108406,
      },
      {
        lat: 13.076905346962752,
        lng: 80.2058643968337,
      },
      {
        lat: 13.076477578136911,
        lng: 80.20390667278666,
      },
      {
        lat: 13.076369851128236,
        lng: 80.20328821260347,
      },
      {
        lat: 13.076363533323686,
        lng: 80.20310275718893,
      },
      {
        lat: 13.076417421533236,
        lng: 80.20310072704133,
      },
      {
        lat: 13.077887056057477,
        lng: 80.20270935974507,
      },
      {
        lat: 13.078131226922192,
        lng: 80.20251782856491,
      },
      {
        lat: 13.078262085158066,
        lng: 80.2016235303537,
      },
      {
        lat: 13.078449928946663,
        lng: 80.20113474564268,
      },
      {
        lat: 13.079182606160524,
        lng: 80.20058721709395,
      },
      {
        lat: 13.079918081898901,
        lng: 80.20049979341522,
      },
      {
        lat: 13.079999033202892,
        lng: 80.20036377637557,
      },
    ],
    properties: {
      Ward_No: 100,
      Zone_Name: "ANNANAGAR",
      fillColor: "#f6d04d",
      zname: "ANNANAGAR(100)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.0817794494933,
        lng: 80.20273765999956,
      },
      {
        lat: 13.090624456148577,
        lng: 80.20235291256881,
      },
      {
        lat: 13.092133582308849,
        lng: 80.21005565434562,
      },
      {
        lat: 13.088854837257843,
        lng: 80.20615235127192,
      },
      {
        lat: 13.091740398477175,
        lng: 80.20105053782142,
      },
      {
        lat: 13.088402431275734,
        lng: 80.20574066620232,
      },
      {
        lat: 13.085174610970062,
        lng: 80.20554021277317,
      },
      {
        lat: 13.086469925366378,
        lng: 80.2049173694969,
      },
      {
        lat: 13.08401905701676,
        lng: 80.19917101047216,
      },
      {
        lat: 13.079731172876917,
        lng: 80.19918335648723,
      },
      {
        lat: 13.090128941776147,
        lng: 80.20514192524438,
      },
      {
        lat: 13.083590112217218,
        lng: 80.20523354571397,
      },
      {
        lat: 13.090392441822832,
        lng: 80.2012995811673,
      },
      {
        lat: 13.081797073802605,
        lng: 80.20031500733492,
      },
      {
        lat: 13.090721744687094,
        lng: 80.2092405591743,
      },
      {
        lat: 13.083496916148233,
        lng: 80.20231303882808,
      },
      {
        lat: 13.082709749088675,
        lng: 80.20221117252304,
      },
      {
        lat: 13.089620776852463,
        lng: 80.20518575570266,
      },
      {
        lat: 13.092121954248254,
        lng: 80.19688420478164,
      },
      {
        lat: 13.085757422773174,
        lng: 80.20280403615534,
      },
      {
        lat: 13.091848862957724,
        lng: 80.19621442262023,
      },
      {
        lat: 13.086883291502279,
        lng: 80.19780375276036,
      },
      {
        lat: 13.087822997245965,
        lng: 80.20601568689297,
      },
      {
        lat: 13.09121038234632,
        lng: 80.20279088388706,
      },
      {
        lat: 13.080080221835455,
        lng: 80.19884521952102,
      },
      {
        lat: 13.087530176445632,
        lng: 80.20875240452618,
      },
      {
        lat: 13.0886454729966,
        lng: 80.20696043950883,
      },
      {
        lat: 13.09046241139788,
        lng: 80.20538811736571,
      },
      {
        lat: 13.08967023214084,
        lng: 80.20873797261648,
      },
      {
        lat: 13.091486474907066,
        lng: 80.19966837551539,
      },
      {
        lat: 13.08975146710758,
        lng: 80.2057914776281,
      },
      {
        lat: 13.087751312910356,
        lng: 80.20107598459735,
      },
      {
        lat: 13.084688319923606,
        lng: 80.20351167340186,
      },
      {
        lat: 13.089056548026093,
        lng: 80.20128599512702,
      },
      {
        lat: 13.089820320460204,
        lng: 80.20729821326702,
      },
      {
        lat: 13.087273212701518,
        lng: 80.19858616754945,
      },
      {
        lat: 13.092688467128026,
        lng: 80.19953082294512,
      },
      {
        lat: 13.081637443242212,
        lng: 80.1990779774403,
      },
      {
        lat: 13.08766812966097,
        lng: 80.20571735362172,
      },
      {
        lat: 13.0923378059351,
        lng: 80.2093088754072,
      },
      {
        lat: 13.086254785175152,
        lng: 80.20220763738908,
      },
      {
        lat: 13.09273782675473,
        lng: 80.20479967722203,
      },
      {
        lat: 13.086365433301486,
        lng: 80.20617498989778,
      },
      {
        lat: 13.091865164288748,
        lng: 80.20264587530967,
      },
      {
        lat: 13.089339034204896,
        lng: 80.20474964464968,
      },
      {
        lat: 13.085513872821275,
        lng: 80.207057705677,
      },
      {
        lat: 13.090366857267309,
        lng: 80.2091064017965,
      },
      {
        lat: 13.090492849222976,
        lng: 80.20758490647268,
      },
      {
        lat: 13.083213768902892,
        lng: 80.19978069948536,
      },
      {
        lat: 13.08579447857408,
        lng: 80.20564507847014,
      },
      {
        lat: 13.082233902907882,
        lng: 80.19948504445401,
      },
      {
        lat: 13.090279538147403,
        lng: 80.20124285705347,
      },
      {
        lat: 13.092601026046664,
        lng: 80.19693121345736,
      },
      {
        lat: 13.08667098755979,
        lng: 80.20929902081826,
      },
      {
        lat: 13.083396203495488,
        lng: 80.19739384424008,
      },
      {
        lat: 13.084603994083006,
        lng: 80.19759505566535,
      },
      {
        lat: 13.085968314001287,
        lng: 80.20497043383659,
      },
      {
        lat: 13.081596822735873,
        lng: 80.20293720722452,
      },
      {
        lat: 13.088064737800268,
        lng: 80.2053156548872,
      },
      {
        lat: 13.087674670824073,
        lng: 80.20473561174735,
      },
      {
        lat: 13.092048566816965,
        lng: 80.19672322080693,
      },
      {
        lat: 13.091837403194567,
        lng: 80.20377066179192,
      },
      {
        lat: 13.092422740947724,
        lng: 80.20382097985598,
      },
      {
        lat: 13.084996973821294,
        lng: 80.20629041640693,
      },
      {
        lat: 13.0926348997803,
        lng: 80.20576939284527,
      },
      {
        lat: 13.081160641061006,
        lng: 80.19863660614342,
      },
    ],
    cases: 66,
    coords: [
      {
        lat: 13.092741534586745,
        lng: 80.20875889925395,
      },
      {
        lat: 13.092704120841102,
        lng: 80.20937947938557,
      },
      {
        lat: 13.092561922223647,
        lng: 80.20942784389537,
      },
      {
        lat: 13.092553357013738,
        lng: 80.21062266288374,
      },
      {
        lat: 13.092347353468508,
        lng: 80.21059296564785,
      },
      {
        lat: 13.09210911281744,
        lng: 80.21046921915088,
      },
      {
        lat: 13.089148390507958,
        lng: 80.21046283581425,
      },
      {
        lat: 13.088878773258882,
        lng: 80.21038651591158,
      },
      {
        lat: 13.085952732424525,
        lng: 80.21033561674054,
      },
      {
        lat: 13.08566752246562,
        lng: 80.21021445545824,
      },
      {
        lat: 13.084903040598872,
        lng: 80.21022092546654,
      },
      {
        lat: 13.084878605012813,
        lng: 80.20912661006386,
      },
      {
        lat: 13.085018794167816,
        lng: 80.20879000551064,
      },
      {
        lat: 13.084982118405213,
        lng: 80.20568328512424,
      },
      {
        lat: 13.082759465287413,
        lng: 80.20564913526208,
      },
      {
        lat: 13.082756566298249,
        lng: 80.20517251302925,
      },
      {
        lat: 13.082844493208743,
        lng: 80.2050128866251,
      },
      {
        lat: 13.082868561918213,
        lng: 80.20312374943792,
      },
      {
        lat: 13.080610029394332,
        lng: 80.20303694248057,
      },
      {
        lat: 13.080005018510244,
        lng: 80.20295379492448,
      },
      {
        lat: 13.079945240367831,
        lng: 80.20189508580279,
      },
      {
        lat: 13.08003273779867,
        lng: 80.20166484869742,
      },
      {
        lat: 13.080114222511067,
        lng: 80.2004460617757,
      },
      {
        lat: 13.079999033202892,
        lng: 80.20036377637557,
      },
      {
        lat: 13.07997045811701,
        lng: 80.20014748712934,
      },
      {
        lat: 13.079830875069376,
        lng: 80.1996073407454,
      },
      {
        lat: 13.0794198630235,
        lng: 80.19925895623442,
      },
      {
        lat: 13.079200481005355,
        lng: 80.19904428183668,
      },
      {
        lat: 13.07924958913423,
        lng: 80.19823956526339,
      },
      {
        lat: 13.079319950348456,
        lng: 80.19824840346543,
      },
      {
        lat: 13.081798723957712,
        lng: 80.19867817094907,
      },
      {
        lat: 13.082500266400396,
        lng: 80.19867223361346,
      },
      {
        lat: 13.08282990557318,
        lng: 80.19858748997576,
      },
      {
        lat: 13.08299322981811,
        lng: 80.19829926936457,
      },
      {
        lat: 13.083029014101903,
        lng: 80.19739747453765,
      },
      {
        lat: 13.08331688762522,
        lng: 80.19723113055751,
      },
      {
        lat: 13.083791211230306,
        lng: 80.19718613932567,
      },
      {
        lat: 13.085896586228648,
        lng: 80.19729125171514,
      },
      {
        lat: 13.086597630223833,
        lng: 80.19720336478213,
      },
      {
        lat: 13.086761203692342,
        lng: 80.19695611896948,
      },
      {
        lat: 13.086924278713697,
        lng: 80.19662692355963,
      },
      {
        lat: 13.087295683536166,
        lng: 80.19662378026429,
      },
      {
        lat: 13.08746299535832,
        lng: 80.19699115643255,
      },
      {
        lat: 13.087466484488397,
        lng: 80.19756480361477,
      },
      {
        lat: 13.087182349319072,
        lng: 80.19834576957578,
      },
      {
        lat: 13.087473213524966,
        lng: 80.19867112318029,
      },
      {
        lat: 13.090816355374853,
        lng: 80.19872478311974,
      },
      {
        lat: 13.091020697598683,
        lng: 80.19839523845485,
      },
      {
        lat: 13.09125733497623,
        lng: 80.1965902517808,
      },
      {
        lat: 13.091378644348122,
        lng: 80.19617945602833,
      },
      {
        lat: 13.0916254998922,
        lng: 80.19605443610197,
      },
      {
        lat: 13.0926984471573,
        lng: 80.19604535547104,
      },
      {
        lat: 13.093027089435777,
        lng: 80.19579671263843,
      },
      {
        lat: 13.093190912127858,
        lng: 80.19559044162472,
      },
      {
        lat: 13.09290403095799,
        lng: 80.20205506328132,
      },
      {
        lat: 13.092722575008523,
        lng: 80.2026935075556,
      },
      {
        lat: 13.092629668593132,
        lng: 80.20316992034087,
      },
      {
        lat: 13.092917355469181,
        lng: 80.2032150482217,
      },
      {
        lat: 13.092921983923281,
        lng: 80.20397601144059,
      },
      {
        lat: 13.092830234621415,
        lng: 80.20464266503046,
      },
      {
        lat: 13.092648760966297,
        lng: 80.20630889361877,
      },
      {
        lat: 13.092639853642272,
        lng: 80.20878222677342,
      },
      {
        lat: 13.092741534586745,
        lng: 80.20875889925395,
      },
    ],
    properties: {
      Ward_No: 99,
      Zone_Name: "ANNANAGAR",
      fillColor: "#f6d04d",
      zname: "ANNANAGAR(99)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.103878870987735,
        lng: 80.20789287604212,
      },
      {
        lat: 13.102320730306468,
        lng: 80.20624104598707,
      },
      {
        lat: 13.093807987885576,
        lng: 80.195592978624,
      },
      {
        lat: 13.108185048081172,
        lng: 80.2126610197286,
      },
      {
        lat: 13.100071129903364,
        lng: 80.1991592376387,
      },
      {
        lat: 13.102861755376438,
        lng: 80.20829832258713,
      },
      {
        lat: 13.10466753101919,
        lng: 80.21280295012205,
      },
      {
        lat: 13.108165916699516,
        lng: 80.2161587650667,
      },
      {
        lat: 13.102297469194786,
        lng: 80.20332785632499,
      },
      {
        lat: 13.096292505878932,
        lng: 80.2023793513191,
      },
      {
        lat: 13.093469660407537,
        lng: 80.19831507453134,
      },
      {
        lat: 13.094163999590421,
        lng: 80.20474599507882,
      },
      {
        lat: 13.105620698941479,
        lng: 80.21124478482479,
      },
      {
        lat: 13.096896017498393,
        lng: 80.20079558493161,
      },
      {
        lat: 13.099693109337684,
        lng: 80.21165322997282,
      },
      {
        lat: 13.099999506263806,
        lng: 80.20234351205468,
      },
      {
        lat: 13.106339016387112,
        lng: 80.21505458713905,
      },
      {
        lat: 13.096587602931663,
        lng: 80.19848064232664,
      },
      {
        lat: 13.100861711684674,
        lng: 80.19533057034394,
      },
      {
        lat: 13.093876821063185,
        lng: 80.19591621208274,
      },
      {
        lat: 13.094943091372777,
        lng: 80.20612522721608,
      },
      {
        lat: 13.099902829488885,
        lng: 80.19759801267587,
      },
      {
        lat: 13.097148085135062,
        lng: 80.1980258156691,
      },
      {
        lat: 13.099252920283082,
        lng: 80.2133314954334,
      },
      {
        lat: 13.107020027913757,
        lng: 80.214835421315,
      },
      {
        lat: 13.103742314922206,
        lng: 80.20579664218869,
      },
      {
        lat: 13.100509701075243,
        lng: 80.2004497627526,
      },
      {
        lat: 13.100549164043517,
        lng: 80.19617888357347,
      },
      {
        lat: 13.1025400398727,
        lng: 80.20666798297687,
      },
      {
        lat: 13.09703837947098,
        lng: 80.20138591766398,
      },
      {
        lat: 13.097947591789598,
        lng: 80.19682834707586,
      },
      {
        lat: 13.098492745147407,
        lng: 80.19873228630195,
      },
      {
        lat: 13.106123298933761,
        lng: 80.21207034420604,
      },
      {
        lat: 13.101549853662698,
        lng: 80.21493336023694,
      },
      {
        lat: 13.103259480951575,
        lng: 80.20090862319655,
      },
      {
        lat: 13.108221760942442,
        lng: 80.21331613558533,
      },
      {
        lat: 13.100990270024846,
        lng: 80.21277159136076,
      },
      {
        lat: 13.103890847514467,
        lng: 80.21254206279376,
      },
      {
        lat: 13.093870718723206,
        lng: 80.19891237888179,
      },
      {
        lat: 13.09664735698482,
        lng: 80.20370310370483,
      },
      {
        lat: 13.101558278814577,
        lng: 80.20796967129922,
      },
      {
        lat: 13.098295696570236,
        lng: 80.20084635049754,
      },
      {
        lat: 13.100965033620584,
        lng: 80.21376214081667,
      },
      {
        lat: 13.097425792529483,
        lng: 80.1982499561395,
      },
      {
        lat: 13.10813983952127,
        lng: 80.21255210984543,
      },
      {
        lat: 13.09450855050123,
        lng: 80.20716423014476,
      },
      {
        lat: 13.094646192840797,
        lng: 80.20057172671349,
      },
      {
        lat: 13.10060345673901,
        lng: 80.19492761511886,
      },
      {
        lat: 13.094273125738813,
        lng: 80.20179849203224,
      },
      {
        lat: 13.104452873773925,
        lng: 80.2130557364014,
      },
      {
        lat: 13.105651828714134,
        lng: 80.21504108077495,
      },
      {
        lat: 13.102977564096872,
        lng: 80.20009522753507,
      },
      {
        lat: 13.094851371085248,
        lng: 80.19822880590539,
      },
      {
        lat: 13.095087003536984,
        lng: 80.19795508580671,
      },
      {
        lat: 13.103205848623409,
        lng: 80.20448444150949,
      },
      {
        lat: 13.093782245977756,
        lng: 80.20650297836575,
      },
      {
        lat: 13.099862484603996,
        lng: 80.21454917626706,
      },
      {
        lat: 13.101866422460025,
        lng: 80.2088200633777,
      },
      {
        lat: 13.107147122509417,
        lng: 80.21206222586807,
      },
      {
        lat: 13.101349670297655,
        lng: 80.19765971502694,
      },
      {
        lat: 13.101429973120611,
        lng: 80.21317458933467,
      },
      {
        lat: 13.09630495344268,
        lng: 80.20232380112837,
      },
      {
        lat: 13.104684557427579,
        lng: 80.21239673570446,
      },
      {
        lat: 13.09719229609058,
        lng: 80.20301755140373,
      },
      {
        lat: 13.10103671897382,
        lng: 80.20884197506551,
      },
      {
        lat: 13.105980185189054,
        lng: 80.21494865260533,
      },
      {
        lat: 13.095340152469735,
        lng: 80.20425234232076,
      },
      {
        lat: 13.099497747304993,
        lng: 80.19914468380537,
      },
      {
        lat: 13.100264130577399,
        lng: 80.2106151618403,
      },
      {
        lat: 13.10606976885094,
        lng: 80.21152523064467,
      },
      {
        lat: 13.109663762725244,
        lng: 80.2134290549577,
      },
      {
        lat: 13.100233363404959,
        lng: 80.21045619984272,
      },
      {
        lat: 13.094760764707091,
        lng: 80.19617703023526,
      },
      {
        lat: 13.101977511811034,
        lng: 80.19584258568948,
      },
      {
        lat: 13.106600425726946,
        lng: 80.21348468908559,
      },
      {
        lat: 13.097936391390073,
        lng: 80.19800760555447,
      },
      {
        lat: 13.101706126739302,
        lng: 80.20555583752795,
      },
      {
        lat: 13.097307277385923,
        lng: 80.19708441768013,
      },
      {
        lat: 13.096846190192215,
        lng: 80.20151260421386,
      },
      {
        lat: 13.104573509272463,
        lng: 80.20960674918028,
      },
      {
        lat: 13.101215704848588,
        lng: 80.20633912118413,
      },
      {
        lat: 13.094313420866989,
        lng: 80.19975774970266,
      },
      {
        lat: 13.093702081615893,
        lng: 80.19689561534709,
      },
      {
        lat: 13.096667129494042,
        lng: 80.2008804972282,
      },
      {
        lat: 13.102757231646134,
        lng: 80.2073498621216,
      },
      {
        lat: 13.104314633475143,
        lng: 80.21459622675147,
      },
      {
        lat: 13.108966446162983,
        lng: 80.21151694349504,
      },
      {
        lat: 13.1024237661071,
        lng: 80.20754743952772,
      },
      {
        lat: 13.10303058235811,
        lng: 80.21218491682423,
      },
      {
        lat: 13.09794207619839,
        lng: 80.19698643643177,
      },
      {
        lat: 13.095332901015803,
        lng: 80.20170978556577,
      },
      {
        lat: 13.101532129784127,
        lng: 80.20165505275014,
      },
      {
        lat: 13.094320391078671,
        lng: 80.20349565375241,
      },
      {
        lat: 13.101414826584982,
        lng: 80.19775294459298,
      },
      {
        lat: 13.103136100712142,
        lng: 80.20892655673299,
      },
      {
        lat: 13.096203609829685,
        lng: 80.19841060112782,
      },
      {
        lat: 13.103924756029333,
        lng: 80.21433108612776,
      },
      {
        lat: 13.102890050178916,
        lng: 80.21053613817537,
      },
      {
        lat: 13.102610796383159,
        lng: 80.19753845847374,
      },
      {
        lat: 13.095142475279292,
        lng: 80.20295331780665,
      },
      {
        lat: 13.100144041825123,
        lng: 80.2123514324886,
      },
      {
        lat: 13.100352362114588,
        lng: 80.21427124507109,
      },
      {
        lat: 13.103066817434664,
        lng: 80.20908131016635,
      },
      {
        lat: 13.096608280608939,
        lng: 80.20164390948126,
      },
      {
        lat: 13.09666459650202,
        lng: 80.19749884330848,
      },
      {
        lat: 13.096014613726753,
        lng: 80.202187003914,
      },
      {
        lat: 13.106092943979524,
        lng: 80.21582973967614,
      },
      {
        lat: 13.09940605857094,
        lng: 80.19539175813405,
      },
      {
        lat: 13.10391618966754,
        lng: 80.2086189218614,
      },
      {
        lat: 13.09750409067425,
        lng: 80.19545447564856,
      },
      {
        lat: 13.097427667187702,
        lng: 80.19695727705117,
      },
      {
        lat: 13.1068736166786,
        lng: 80.21562937202387,
      },
      {
        lat: 13.100979673748343,
        lng: 80.19506691147429,
      },
      {
        lat: 13.095951502319723,
        lng: 80.19886356886221,
      },
      {
        lat: 13.102926019608358,
        lng: 80.19908874011182,
      },
      {
        lat: 13.097659220802718,
        lng: 80.19867183541888,
      },
      {
        lat: 13.104793746577771,
        lng: 80.21192687755385,
      },
      {
        lat: 13.10117266860638,
        lng: 80.2002397935983,
      },
      {
        lat: 13.096133920328556,
        lng: 80.20555428188797,
      },
      {
        lat: 13.103550291863378,
        lng: 80.2043359662711,
      },
      {
        lat: 13.101537176018928,
        lng: 80.20165723941572,
      },
      {
        lat: 13.096197995073233,
        lng: 80.20052295205768,
      },
      {
        lat: 13.097362760796168,
        lng: 80.20283968158432,
      },
      {
        lat: 13.093586997352121,
        lng: 80.20385375996283,
      },
      {
        lat: 13.103864767755104,
        lng: 80.21292909522556,
      },
      {
        lat: 13.100493852780962,
        lng: 80.20124644897308,
      },
      {
        lat: 13.102631245135452,
        lng: 80.20858639923163,
      },
      {
        lat: 13.109318384793225,
        lng: 80.21417317625883,
      },
      {
        lat: 13.095214068428971,
        lng: 80.20469539452988,
      },
      {
        lat: 13.101824176309066,
        lng: 80.1961112560971,
      },
      {
        lat: 13.102033827378365,
        lng: 80.21020075394897,
      },
      {
        lat: 13.10179327488307,
        lng: 80.21025925095223,
      },
      {
        lat: 13.102296773485948,
        lng: 80.21479156725557,
      },
      {
        lat: 13.09861024918658,
        lng: 80.20183633578431,
      },
      {
        lat: 13.10064450521645,
        lng: 80.20084094006536,
      },
      {
        lat: 13.100733209249105,
        lng: 80.20218404067712,
      },
      {
        lat: 13.093199451507214,
        lng: 80.1991584312876,
      },
      {
        lat: 13.09651899172735,
        lng: 80.20507313018673,
      },
      {
        lat: 13.101667727193195,
        lng: 80.2111997398174,
      },
      {
        lat: 13.094684608330455,
        lng: 80.19747481287523,
      },
      {
        lat: 13.1029133870773,
        lng: 80.19975944000235,
      },
      {
        lat: 13.10174344088433,
        lng: 80.20077544645042,
      },
      {
        lat: 13.10402774392294,
        lng: 80.21165839598727,
      },
      {
        lat: 13.098964192622454,
        lng: 80.19814403053492,
      },
      {
        lat: 13.102858433995484,
        lng: 80.20168305635009,
      },
      {
        lat: 13.099434554016572,
        lng: 80.19812582779645,
      },
      {
        lat: 13.097079755241964,
        lng: 80.19728445499128,
      },
      {
        lat: 13.093388706109428,
        lng: 80.20441521264355,
      },
    ],
    cases: 148,
    coords: [
      {
        lat: 13.109695900087337,
        lng: 80.21720915660237,
      },
      {
        lat: 13.109270630231636,
        lng: 80.21708029116233,
      },
      {
        lat: 13.107548872300423,
        lng: 80.21614268580427,
      },
      {
        lat: 13.105478246689012,
        lng: 80.21593469443059,
      },
      {
        lat: 13.103810768359827,
        lng: 80.21562306195476,
      },
      {
        lat: 13.099159639446858,
        lng: 80.21435944655518,
      },
      {
        lat: 13.098644793717972,
        lng: 80.21394232724653,
      },
      {
        lat: 13.099769722330702,
        lng: 80.21148051851206,
      },
      {
        lat: 13.1016836210606,
        lng: 80.20718368224303,
      },
      {
        lat: 13.101825294910878,
        lng: 80.20684954467467,
      },
      {
        lat: 13.101680728276792,
        lng: 80.2067080802314,
      },
      {
        lat: 13.101105499163877,
        lng: 80.20664160457031,
      },
      {
        lat: 13.099359562338568,
        lng: 80.20311296350481,
      },
      {
        lat: 13.099308046759118,
        lng: 80.20251886637676,
      },
      {
        lat: 13.099449575969977,
        lng: 80.20216094870774,
      },
      {
        lat: 13.099328669857575,
        lng: 80.2019717213698,
      },
      {
        lat: 13.099112543102505,
        lng: 80.20187842520778,
      },
      {
        lat: 13.098058607271845,
        lng: 80.20186356361542,
      },
      {
        lat: 13.097892115786744,
        lng: 80.20205522327377,
      },
      {
        lat: 13.097778875551276,
        lng: 80.20312634126705,
      },
      {
        lat: 13.096596717315235,
        lng: 80.20565716659787,
      },
      {
        lat: 13.095937260138369,
        lng: 80.20749390975769,
      },
      {
        lat: 13.095580616646718,
        lng: 80.2079249919687,
      },
      {
        lat: 13.094574146498164,
        lng: 80.20783838468773,
      },
      {
        lat: 13.093400317029264,
        lng: 80.20780075646161,
      },
      {
        lat: 13.093332083609612,
        lng: 80.20839586705631,
      },
      {
        lat: 13.0930703714819,
        lng: 80.20868345789049,
      },
      {
        lat: 13.092639853642272,
        lng: 80.20878222677342,
      },
      {
        lat: 13.092648760966297,
        lng: 80.20630889361877,
      },
      {
        lat: 13.092830234621415,
        lng: 80.20464266503046,
      },
      {
        lat: 13.092921983923281,
        lng: 80.20397601144059,
      },
      {
        lat: 13.092917355469181,
        lng: 80.2032150482217,
      },
      {
        lat: 13.092629668593132,
        lng: 80.20316992034087,
      },
      {
        lat: 13.092722575008523,
        lng: 80.2026935075556,
      },
      {
        lat: 13.09290403095799,
        lng: 80.20205506328132,
      },
      {
        lat: 13.093190912127858,
        lng: 80.19559044162472,
      },
      {
        lat: 13.093891706899702,
        lng: 80.19546157989271,
      },
      {
        lat: 13.098719969592139,
        lng: 80.19542071705345,
      },
      {
        lat: 13.099297710427335,
        lng: 80.19541582748298,
      },
      {
        lat: 13.09974915741916,
        lng: 80.19500223769039,
      },
      {
        lat: 13.100427823248083,
        lng: 80.19462770179386,
      },
      {
        lat: 13.101334704808618,
        lng: 80.19445611898801,
      },
      {
        lat: 13.101954460516907,
        lng: 80.19457380455862,
      },
      {
        lat: 13.102096803212852,
        lng: 80.1959711534654,
      },
      {
        lat: 13.103014344647137,
        lng: 80.19824511170668,
      },
      {
        lat: 13.103337737963221,
        lng: 80.20047282368911,
      },
      {
        lat: 13.103718850391465,
        lng: 80.20369990360292,
      },
      {
        lat: 13.103987955002502,
        lng: 80.20549224019355,
      },
      {
        lat: 13.103838186805243,
        lng: 80.20633954007882,
      },
      {
        lat: 13.10337937063047,
        lng: 80.20731764223798,
      },
      {
        lat: 13.103303628930192,
        lng: 80.20760029404573,
      },
      {
        lat: 13.103433815263175,
        lng: 80.20777865365427,
      },
      {
        lat: 13.105682716244852,
        lng: 80.2081954554732,
      },
      {
        lat: 13.105539652934128,
        lng: 80.21014510441299,
      },
      {
        lat: 13.108025961032341,
        lng: 80.21043951695984,
      },
      {
        lat: 13.108383895790666,
        lng: 80.21052905926467,
      },
      {
        lat: 13.108554437356357,
        lng: 80.21046590152756,
      },
      {
        lat: 13.108787318202934,
        lng: 80.21043307339664,
      },
      {
        lat: 13.109021137424516,
        lng: 80.21055452332334,
      },
      {
        lat: 13.109192617365496,
        lng: 80.2106456436436,
      },
      {
        lat: 13.109503375394363,
        lng: 80.21064301361778,
      },
      {
        lat: 13.109785218076738,
        lng: 80.21057835360992,
      },
      {
        lat: 13.109771159901896,
        lng: 80.21073231943345,
      },
      {
        lat: 13.110032777511023,
        lng: 80.212143343445,
      },
      {
        lat: 13.110197601700351,
        lng: 80.21324113372246,
      },
      {
        lat: 13.110074910758566,
        lng: 80.21387027793185,
      },
      {
        lat: 13.109864594807105,
        lng: 80.21569356483819,
      },
      {
        lat: 13.109695900087337,
        lng: 80.21720915660237,
      },
    ],
    properties: {
      Ward_No: 95,
      Zone_Name: "ANNANAGAR",
      fillColor: "#de4307",
      zname: "ANNANAGAR(95)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.107572980859848,
        lng: 80.21906975034624,
      },
      {
        lat: 13.101877375318612,
        lng: 80.22631482415659,
      },
      {
        lat: 13.10480023390243,
        lng: 80.22018050265004,
      },
      {
        lat: 13.103224491166623,
        lng: 80.23601478600096,
      },
      {
        lat: 13.099109377777651,
        lng: 80.21998264400249,
      },
      {
        lat: 13.107269154977342,
        lng: 80.23464318309763,
      },
      {
        lat: 13.09726177950058,
        lng: 80.23871204543775,
      },
      {
        lat: 13.097920776216615,
        lng: 80.23819431496632,
      },
      {
        lat: 13.099115414617819,
        lng: 80.22086888091286,
      },
      {
        lat: 13.0990040249852,
        lng: 80.23005929029213,
      },
      {
        lat: 13.101640777326482,
        lng: 80.21537096706176,
      },
      {
        lat: 13.105781216092714,
        lng: 80.22855588521202,
      },
      {
        lat: 13.101641646552817,
        lng: 80.2235176402155,
      },
      {
        lat: 13.100506761419014,
        lng: 80.2265297782342,
      },
      {
        lat: 13.104107200661543,
        lng: 80.21955751994872,
      },
      {
        lat: 13.106390281480218,
        lng: 80.2199765682465,
      },
      {
        lat: 13.100696436991893,
        lng: 80.22670837425082,
      },
      {
        lat: 13.103645832993754,
        lng: 80.21688985973489,
      },
      {
        lat: 13.097822270506311,
        lng: 80.23642933234048,
      },
      {
        lat: 13.104197935340405,
        lng: 80.23495141246516,
      },
      {
        lat: 13.106248697120968,
        lng: 80.22489659666581,
      },
      {
        lat: 13.102802122866555,
        lng: 80.21572265107369,
      },
      {
        lat: 13.104193577717965,
        lng: 80.23579934281787,
      },
      {
        lat: 13.096671180245403,
        lng: 80.2194440375947,
      },
      {
        lat: 13.099812624881334,
        lng: 80.21857504588279,
      },
      {
        lat: 13.107709936735818,
        lng: 80.22457499480646,
      },
      {
        lat: 13.107432380068033,
        lng: 80.23692002183576,
      },
      {
        lat: 13.107618118803881,
        lng: 80.23542596572669,
      },
      {
        lat: 13.10206635186867,
        lng: 80.22939861619165,
      },
      {
        lat: 13.102440370720304,
        lng: 80.2255961298216,
      },
      {
        lat: 13.09939706553596,
        lng: 80.23403578227645,
      },
      {
        lat: 13.108894159320679,
        lng: 80.22392078229262,
      },
      {
        lat: 13.104284264869701,
        lng: 80.23521064680148,
      },
      {
        lat: 13.098383098229913,
        lng: 80.22316566607105,
      },
      {
        lat: 13.108533877894446,
        lng: 80.21752190745475,
      },
      {
        lat: 13.105086782673588,
        lng: 80.23241362724806,
      },
      {
        lat: 13.10660211016608,
        lng: 80.23062440957698,
      },
      {
        lat: 13.104362273822712,
        lng: 80.23201840226261,
      },
      {
        lat: 13.097430223683903,
        lng: 80.24102688257364,
      },
      {
        lat: 13.100865828411917,
        lng: 80.23129588173471,
      },
      {
        lat: 13.096573897298425,
        lng: 80.21974565402438,
      },
      {
        lat: 13.107401379802205,
        lng: 80.23408143362369,
      },
      {
        lat: 13.101257561282603,
        lng: 80.22949999718197,
      },
      {
        lat: 13.09634393116347,
        lng: 80.23851261758068,
      },
      {
        lat: 13.10124993705498,
        lng: 80.21995147771237,
      },
      {
        lat: 13.10952482698987,
        lng: 80.21855992107353,
      },
      {
        lat: 13.098842189330195,
        lng: 80.21787345493897,
      },
      {
        lat: 13.10432730902681,
        lng: 80.22522862160635,
      },
      {
        lat: 13.106418230549918,
        lng: 80.23716911150045,
      },
      {
        lat: 13.102675670704272,
        lng: 80.23397769868414,
      },
      {
        lat: 13.100695713509666,
        lng: 80.23595114091282,
      },
      {
        lat: 13.103822219083764,
        lng: 80.21697410632697,
      },
      {
        lat: 13.108485087692944,
        lng: 80.22818758311016,
      },
      {
        lat: 13.102949790546868,
        lng: 80.23459016521092,
      },
      {
        lat: 13.09959025027462,
        lng: 80.21893846116674,
      },
      {
        lat: 13.105868629418989,
        lng: 80.22863133928197,
      },
      {
        lat: 13.100449114873143,
        lng: 80.23020561574143,
      },
      {
        lat: 13.097982713570287,
        lng: 80.23282544943962,
      },
      {
        lat: 13.099357222130353,
        lng: 80.2328581625036,
      },
      {
        lat: 13.102596958730013,
        lng: 80.21560367120934,
      },
      {
        lat: 13.102943390807463,
        lng: 80.21890818469555,
      },
      {
        lat: 13.105215015822195,
        lng: 80.22833780423355,
      },
      {
        lat: 13.098235364656611,
        lng: 80.22935691606627,
      },
      {
        lat: 13.098113775638964,
        lng: 80.2314366462994,
      },
      {
        lat: 13.096146790424362,
        lng: 80.24007773089549,
      },
      {
        lat: 13.105991482339064,
        lng: 80.22321542257559,
      },
      {
        lat: 13.1018713375702,
        lng: 80.22986309360627,
      },
    ],
    cases: 67,
    coords: [
      {
        lat: 13.09576392228492,
        lng: 80.2415896517748,
      },
      {
        lat: 13.096798999683333,
        lng: 80.23548659280829,
      },
      {
        lat: 13.096865694620107,
        lng: 80.23503797230262,
      },
      {
        lat: 13.096725806433431,
        lng: 80.23486682696293,
      },
      {
        lat: 13.096817248491785,
        lng: 80.23468223519872,
      },
      {
        lat: 13.09697647828675,
        lng: 80.23350165372626,
      },
      {
        lat: 13.097172489013449,
        lng: 80.23263392481039,
      },
      {
        lat: 13.097391539303132,
        lng: 80.23187703552372,
      },
      {
        lat: 13.097428974359012,
        lng: 80.23067754481897,
      },
      {
        lat: 13.0979205825635,
        lng: 80.2306067634399,
      },
      {
        lat: 13.098069988331103,
        lng: 80.22954581233776,
      },
      {
        lat: 13.098194647611377,
        lng: 80.22935738639464,
      },
      {
        lat: 13.098179104765565,
        lng: 80.22887035354812,
      },
      {
        lat: 13.098241016552672,
        lng: 80.22870744144278,
      },
      {
        lat: 13.098404327176295,
        lng: 80.22866858511878,
      },
      {
        lat: 13.098669112609368,
        lng: 80.22876627532784,
      },
      {
        lat: 13.098832499206102,
        lng: 80.22873990975548,
      },
      {
        lat: 13.098861090400225,
        lng: 80.22516712892323,
      },
      {
        lat: 13.098734759707822,
        lng: 80.22508075833039,
      },
      {
        lat: 13.098657153128352,
        lng: 80.22473165608598,
      },
      {
        lat: 13.09757505484489,
        lng: 80.22470333998055,
      },
      {
        lat: 13.097431585867835,
        lng: 80.2238676307572,
      },
      {
        lat: 13.09732646434067,
        lng: 80.22313152814604,
      },
      {
        lat: 13.09639304551414,
        lng: 80.22272721188973,
      },
      {
        lat: 13.096666688144742,
        lng: 80.22221274880214,
      },
      {
        lat: 13.096727688252503,
        lng: 80.22189994767736,
      },
      {
        lat: 13.096663877133436,
        lng: 80.22175059099216,
      },
      {
        lat: 13.096385979999235,
        lng: 80.22156557198846,
      },
      {
        lat: 13.095894908529528,
        lng: 80.22149477968605,
      },
      {
        lat: 13.096749420022155,
        lng: 80.21894963474753,
      },
      {
        lat: 13.097502796088802,
        lng: 80.21680293950676,
      },
      {
        lat: 13.098400738789534,
        lng: 80.21479770870921,
      },
      {
        lat: 13.098635174415849,
        lng: 80.21396337825487,
      },
      {
        lat: 13.098644793717972,
        lng: 80.21394232724653,
      },
      {
        lat: 13.099159639446858,
        lng: 80.21435944655518,
      },
      {
        lat: 13.103810768359827,
        lng: 80.21562306195476,
      },
      {
        lat: 13.105478246689012,
        lng: 80.21593469443059,
      },
      {
        lat: 13.107548872300423,
        lng: 80.21614268580427,
      },
      {
        lat: 13.109270630231636,
        lng: 80.21708029116233,
      },
      {
        lat: 13.109695900087337,
        lng: 80.21720915660237,
      },
      {
        lat: 13.109654660872485,
        lng: 80.21757965909593,
      },
      {
        lat: 13.109569327965037,
        lng: 80.21915064590083,
      },
      {
        lat: 13.109141628745832,
        lng: 80.22163528371472,
      },
      {
        lat: 13.108990939188091,
        lng: 80.22286136543553,
      },
      {
        lat: 13.10899495036737,
        lng: 80.22352084262428,
      },
      {
        lat: 13.109126427701876,
        lng: 80.22433626749479,
      },
      {
        lat: 13.10916130262517,
        lng: 80.22486986230686,
      },
      {
        lat: 13.109133686026292,
        lng: 80.22552960716972,
      },
      {
        lat: 13.10872672709863,
        lng: 80.22622396779705,
      },
      {
        lat: 13.108667673730068,
        lng: 80.22691538400956,
      },
      {
        lat: 13.108276434805191,
        lng: 80.23019426714326,
      },
      {
        lat: 13.108046524613245,
        lng: 80.23399625328997,
      },
      {
        lat: 13.10793051897026,
        lng: 80.23572452614712,
      },
      {
        lat: 13.107904430439675,
        lng: 80.23663550041523,
      },
      {
        lat: 13.107780211429647,
        lng: 80.23701341521931,
      },
      {
        lat: 13.107434024947326,
        lng: 80.23729899271528,
      },
      {
        lat: 13.106928935539987,
        lng: 80.23746029387893,
      },
      {
        lat: 13.104524269360576,
        lng: 80.23732361873141,
      },
      {
        lat: 13.103669364308706,
        lng: 80.23717382755345,
      },
      {
        lat: 13.102087975402938,
        lng: 80.23718721125788,
      },
      {
        lat: 13.101581548936068,
        lng: 80.23712868669185,
      },
      {
        lat: 13.10113799600822,
        lng: 80.23700681942663,
      },
      {
        lat: 13.100946701271292,
        lng: 80.23675719606587,
      },
      {
        lat: 13.100944218160306,
        lng: 80.23634894828241,
      },
      {
        lat: 13.10094269009201,
        lng: 80.23609771887713,
      },
      {
        lat: 13.100530191916771,
        lng: 80.23588137291088,
      },
      {
        lat: 13.099454083426652,
        lng: 80.23576485912727,
      },
      {
        lat: 13.098915265147408,
        lng: 80.23558098753283,
      },
      {
        lat: 13.098630806152944,
        lng: 80.23561480027513,
      },
      {
        lat: 13.098506014117302,
        lng: 80.23589850405253,
      },
      {
        lat: 13.09795314084556,
        lng: 80.23860403829217,
      },
      {
        lat: 13.097717118380361,
        lng: 80.2414011068178,
      },
      {
        lat: 13.09762586420825,
        lng: 80.24199857967764,
      },
      {
        lat: 13.097341978239395,
        lng: 80.24212660344686,
      },
      {
        lat: 13.096739713395294,
        lng: 80.24191186352499,
      },
      {
        lat: 13.09576392228492,
        lng: 80.2415896517748,
      },
    ],
    properties: {
      Ward_No: 96,
      Zone_Name: "ANNANAGAR",
      fillColor: "#f6d04d",
      zname: "ANNANAGAR(96)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.093777352705569,
        lng: 80.2295250937923,
      },
      {
        lat: 13.097151343491428,
        lng: 80.23181387258781,
      },
      {
        lat: 13.096082448962738,
        lng: 80.22637049915683,
      },
      {
        lat: 13.096755094133195,
        lng: 80.22813884108503,
      },
      {
        lat: 13.09641248119468,
        lng: 80.22340876328468,
      },
      {
        lat: 13.098727852806121,
        lng: 80.22643226431354,
      },
      {
        lat: 13.096997419447844,
        lng: 80.22415717671932,
      },
      {
        lat: 13.096702455554093,
        lng: 80.23004550333847,
      },
      {
        lat: 13.091491905722322,
        lng: 80.22939533809293,
      },
      {
        lat: 13.09000691602119,
        lng: 80.23122155061085,
      },
      {
        lat: 13.096784208240306,
        lng: 80.23095638614538,
      },
      {
        lat: 13.093872293931287,
        lng: 80.22521942826262,
      },
      {
        lat: 13.095447038697634,
        lng: 80.22990602391549,
      },
      {
        lat: 13.09573973663071,
        lng: 80.2268934173836,
      },
      {
        lat: 13.094343098887713,
        lng: 80.22869963529737,
      },
      {
        lat: 13.093946358167974,
        lng: 80.22724911884742,
      },
      {
        lat: 13.091841715611338,
        lng: 80.23016821236392,
      },
      {
        lat: 13.093688162216253,
        lng: 80.23160721133712,
      },
      {
        lat: 13.09232293280247,
        lng: 80.22911234144532,
      },
      {
        lat: 13.097353072592727,
        lng: 80.231104599293,
      },
    ],
    cases: 20,
    coords: [
      {
        lat: 13.0969795207858,
        lng: 80.23348818474726,
      },
      {
        lat: 13.09664951816273,
        lng: 80.23350034158115,
      },
      {
        lat: 13.096394402022215,
        lng: 80.23334028094372,
      },
      {
        lat: 13.095995055646176,
        lng: 80.23334366071563,
      },
      {
        lat: 13.09557689940989,
        lng: 80.23323905317947,
      },
      {
        lat: 13.095485700368455,
        lng: 80.2331677273533,
      },
      {
        lat: 13.095629711353022,
        lng: 80.2329682399659,
      },
      {
        lat: 13.095646657581709,
        lng: 80.23276982796077,
      },
      {
        lat: 13.095409035796896,
        lng: 80.23250147276318,
      },
      {
        lat: 13.09513543867214,
        lng: 80.23228749528464,
      },
      {
        lat: 13.094535870981181,
        lng: 80.2322024474975,
      },
      {
        lat: 13.093972607506293,
        lng: 80.23211709245838,
      },
      {
        lat: 13.093846200502654,
        lng: 80.23222630877456,
      },
      {
        lat: 13.093831556407329,
        lng: 80.23280321404937,
      },
      {
        lat: 13.09370536865448,
        lng: 80.23294847734367,
      },
      {
        lat: 13.093306022278446,
        lng: 80.23295185711564,
      },
      {
        lat: 13.093051454264888,
        lng: 80.23288191392362,
      },
      {
        lat: 13.092921977750262,
        lng: 80.23248647254687,
      },
      {
        lat: 13.09268315008613,
        lng: 80.23201985896985,
      },
      {
        lat: 13.092606485514578,
        lng: 80.23135360437975,
      },
      {
        lat: 13.091081379566504,
        lng: 80.23131243849639,
      },
      {
        lat: 13.09090073548969,
        lng: 80.23145816266872,
      },
      {
        lat: 13.090757163006698,
        lng: 80.23172974401207,
      },
      {
        lat: 13.09057553230135,
        lng: 80.23171325678312,
      },
      {
        lat: 13.090302702554341,
        lng: 80.23162544372788,
      },
      {
        lat: 13.088776829228525,
        lng: 80.23145811342133,
      },
      {
        lat: 13.089438810189877,
        lng: 80.22986636222454,
      },
      {
        lat: 13.090268949276862,
        lng: 80.22892426273559,
      },
      {
        lat: 13.091944960847924,
        lng: 80.22700584889992,
      },
      {
        lat: 13.09232711496025,
        lng: 80.22677410711279,
      },
      {
        lat: 13.092580494574404,
        lng: 80.2263911168324,
      },
      {
        lat: 13.093774544403113,
        lng: 80.225111525069,
      },
      {
        lat: 13.095221201738255,
        lng: 80.22332200093878,
      },
      {
        lat: 13.095894908529528,
        lng: 80.22149477968605,
      },
      {
        lat: 13.096385979999235,
        lng: 80.22156557198846,
      },
      {
        lat: 13.096663877133436,
        lng: 80.22175059099216,
      },
      {
        lat: 13.096727688252503,
        lng: 80.22189994767736,
      },
      {
        lat: 13.096666688144742,
        lng: 80.22221274880214,
      },
      {
        lat: 13.09639304551414,
        lng: 80.22272721188973,
      },
      {
        lat: 13.09732646434067,
        lng: 80.22313152814604,
      },
      {
        lat: 13.097431585867835,
        lng: 80.2238676307572,
      },
      {
        lat: 13.09757505484489,
        lng: 80.22470333998055,
      },
      {
        lat: 13.098657153128352,
        lng: 80.22473165608598,
      },
      {
        lat: 13.098734759707822,
        lng: 80.22508075833039,
      },
      {
        lat: 13.098861090400225,
        lng: 80.22516712892323,
      },
      {
        lat: 13.098832499206102,
        lng: 80.22873990975548,
      },
      {
        lat: 13.098669112609368,
        lng: 80.22876627532784,
      },
      {
        lat: 13.098404327176295,
        lng: 80.22866858511878,
      },
      {
        lat: 13.098241016552672,
        lng: 80.22870744144278,
      },
      {
        lat: 13.098179104765565,
        lng: 80.22887035354812,
      },
      {
        lat: 13.098194647611377,
        lng: 80.22935738639464,
      },
      {
        lat: 13.098069988331103,
        lng: 80.22954581233776,
      },
      {
        lat: 13.0979205825635,
        lng: 80.2306067634399,
      },
      {
        lat: 13.097428974359012,
        lng: 80.23067754481897,
      },
      {
        lat: 13.097391539303132,
        lng: 80.23187703552372,
      },
      {
        lat: 13.097172489013449,
        lng: 80.23263392481039,
      },
      {
        lat: 13.0969795207858,
        lng: 80.23348818474726,
      },
    ],
    properties: {
      Ward_No: 97,
      Zone_Name: "ANNANAGAR",
      fillColor: "#8bc24c",
      zname: "ANNANAGAR(97)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.077030454614276,
        lng: 80.21094637406223,
      },
      {
        lat: 13.07000230775804,
        lng: 80.20918571085278,
      },
      {
        lat: 13.069322785729831,
        lng: 80.21471418349145,
      },
      {
        lat: 13.073916248187098,
        lng: 80.20584461817077,
      },
      {
        lat: 13.075662846193215,
        lng: 80.20867955194339,
      },
      {
        lat: 13.076721314562212,
        lng: 80.21214442703217,
      },
      {
        lat: 13.075978602856608,
        lng: 80.20358889738215,
      },
      {
        lat: 13.074564375793011,
        lng: 80.21287875126106,
      },
      {
        lat: 13.064664593833234,
        lng: 80.2141306391521,
      },
      {
        lat: 13.073579024082179,
        lng: 80.21563327084544,
      },
      {
        lat: 13.070476997526935,
        lng: 80.20936075626457,
      },
      {
        lat: 13.061592581601653,
        lng: 80.20856740962199,
      },
      {
        lat: 13.074889282337866,
        lng: 80.20426502175012,
      },
      {
        lat: 13.065503987458234,
        lng: 80.21410602657352,
      },
      {
        lat: 13.070838218967268,
        lng: 80.21215885135696,
      },
      {
        lat: 13.07562466710317,
        lng: 80.21242085839769,
      },
      {
        lat: 13.06225853477236,
        lng: 80.21350769641198,
      },
      {
        lat: 13.073058482903306,
        lng: 80.21086494777859,
      },
      {
        lat: 13.074404832277002,
        lng: 80.21543603348104,
      },
      {
        lat: 13.074765156391654,
        lng: 80.21034284625362,
      },
      {
        lat: 13.076948279189457,
        lng: 80.21128964201704,
      },
      {
        lat: 13.074855784537377,
        lng: 80.21501823547935,
      },
      {
        lat: 13.070305927824249,
        lng: 80.20983463098908,
      },
      {
        lat: 13.067077820309304,
        lng: 80.20996486271191,
      },
      {
        lat: 13.074612442438525,
        lng: 80.21340309943233,
      },
      {
        lat: 13.062744588048023,
        lng: 80.21330136369903,
      },
      {
        lat: 13.070627210563039,
        lng: 80.2145675870506,
      },
      {
        lat: 13.06936665565592,
        lng: 80.21292356144875,
      },
      {
        lat: 13.074854009742047,
        lng: 80.2046688868988,
      },
      {
        lat: 13.067033828320804,
        lng: 80.21200195961374,
      },
      {
        lat: 13.066402369219494,
        lng: 80.21157430126688,
      },
      {
        lat: 13.06442669000146,
        lng: 80.20929243924195,
      },
      {
        lat: 13.076889897375647,
        lng: 80.20632402587125,
      },
      {
        lat: 13.070648350904063,
        lng: 80.21475097536921,
      },
      {
        lat: 13.074624014830219,
        lng: 80.21508528351438,
      },
      {
        lat: 13.070483061836738,
        lng: 80.21413906275056,
      },
      {
        lat: 13.076084173551774,
        lng: 80.20497416084031,
      },
      {
        lat: 13.061181437129646,
        lng: 80.21041280098824,
      },
      {
        lat: 13.076508404870154,
        lng: 80.20859775854966,
      },
      {
        lat: 13.07412995509666,
        lng: 80.2112349815191,
      },
      {
        lat: 13.065916831321589,
        lng: 80.21114722383803,
      },
      {
        lat: 13.06083410151912,
        lng: 80.2086052701913,
      },
      {
        lat: 13.066868498677287,
        lng: 80.2122915359987,
      },
      {
        lat: 13.064280902019881,
        lng: 80.21055965339893,
      },
      {
        lat: 13.074822360545237,
        lng: 80.2090075593141,
      },
      {
        lat: 13.072348304088344,
        lng: 80.21447111234941,
      },
      {
        lat: 13.070024246914397,
        lng: 80.21191860447964,
      },
      {
        lat: 13.075863559195314,
        lng: 80.21123270765278,
      },
      {
        lat: 13.074489211892658,
        lng: 80.21604111558229,
      },
      {
        lat: 13.060018483808502,
        lng: 80.20996193312193,
      },
      {
        lat: 13.073038301183004,
        lng: 80.21471243775756,
      },
      {
        lat: 13.073836298843176,
        lng: 80.21363417794205,
      },
      {
        lat: 13.072970594266996,
        lng: 80.21261106062211,
      },
      {
        lat: 13.073320260449396,
        lng: 80.21516947139727,
      },
      {
        lat: 13.076316086794886,
        lng: 80.20725760638135,
      },
      {
        lat: 13.069423958167636,
        lng: 80.21215701202581,
      },
      {
        lat: 13.072265951286164,
        lng: 80.2135348481839,
      },
      {
        lat: 13.074414598771511,
        lng: 80.21515935508819,
      },
      {
        lat: 13.06585780701327,
        lng: 80.2116757436272,
      },
      {
        lat: 13.064164437049945,
        lng: 80.21144340549709,
      },
      {
        lat: 13.076735934551332,
        lng: 80.21013058978515,
      },
      {
        lat: 13.07476294508164,
        lng: 80.2036470076528,
      },
      {
        lat: 13.06677737535643,
        lng: 80.2096459629959,
      },
      {
        lat: 13.060417809203848,
        lng: 80.21025705368803,
      },
      {
        lat: 13.065734626125638,
        lng: 80.21034995864777,
      },
      {
        lat: 13.071631639344622,
        lng: 80.21449348867094,
      },
      {
        lat: 13.073493802315872,
        lng: 80.21071503722756,
      },
      {
        lat: 13.073527660665572,
        lng: 80.21497004740456,
      },
      {
        lat: 13.074839393133606,
        lng: 80.21253186655926,
      },
      {
        lat: 13.07531636086296,
        lng: 80.20852449642963,
      },
      {
        lat: 13.073947667621672,
        lng: 80.20370798214374,
      },
      {
        lat: 13.065676445188416,
        lng: 80.21063235840141,
      },
      {
        lat: 13.077038955778207,
        lng: 80.20885552610497,
      },
      {
        lat: 13.06209359345303,
        lng: 80.20952268858215,
      },
      {
        lat: 13.075510612729056,
        lng: 80.20505486556678,
      },
      {
        lat: 13.063779631234812,
        lng: 80.21400682651233,
      },
      {
        lat: 13.065885020925508,
        lng: 80.20969822725455,
      },
      {
        lat: 13.069432430154231,
        lng: 80.21279692888537,
      },
      {
        lat: 13.062272382345215,
        lng: 80.21361082602024,
      },
      {
        lat: 13.076800226693571,
        lng: 80.210209068984,
      },
      {
        lat: 13.0676322572111,
        lng: 80.21139714910015,
      },
      {
        lat: 13.06156129662101,
        lng: 80.20954628477918,
      },
      {
        lat: 13.06711916409882,
        lng: 80.2145040385863,
      },
      {
        lat: 13.06747173442293,
        lng: 80.21197573216091,
      },
      {
        lat: 13.075221878308643,
        lng: 80.2113563049812,
      },
      {
        lat: 13.06190872053878,
        lng: 80.21113694234202,
      },
      {
        lat: 13.060497869238375,
        lng: 80.2097286363285,
      },
      {
        lat: 13.072430427018002,
        lng: 80.21080894873657,
      },
      {
        lat: 13.072676420765058,
        lng: 80.21395102635194,
      },
      {
        lat: 13.076046970738883,
        lng: 80.20350831807372,
      },
      {
        lat: 13.062382667681684,
        lng: 80.2132054710924,
      },
      {
        lat: 13.065747360632658,
        lng: 80.21063033831867,
      },
      {
        lat: 13.070924116059096,
        lng: 80.21014333349443,
      },
      {
        lat: 13.075410390819068,
        lng: 80.20433221312508,
      },
      {
        lat: 13.075995540937685,
        lng: 80.21336445070637,
      },
      {
        lat: 13.070427548130604,
        lng: 80.21437968981782,
      },
      {
        lat: 13.062875680955964,
        lng: 80.21335006683334,
      },
      {
        lat: 13.070343736110562,
        lng: 80.21058539231714,
      },
      {
        lat: 13.068714094722216,
        lng: 80.21249365054803,
      },
      {
        lat: 13.06114579056145,
        lng: 80.20782063657265,
      },
      {
        lat: 13.062052870297121,
        lng: 80.21128844528437,
      },
      {
        lat: 13.06221188710057,
        lng: 80.20933834593632,
      },
      {
        lat: 13.075837888454403,
        lng: 80.2035756853891,
      },
      {
        lat: 13.064262553205335,
        lng: 80.21344159751324,
      },
      {
        lat: 13.073267098199779,
        lng: 80.2131149210462,
      },
      {
        lat: 13.07485650750149,
        lng: 80.20886749235721,
      },
      {
        lat: 13.064116491023713,
        lng: 80.2136615609772,
      },
      {
        lat: 13.064383665921495,
        lng: 80.20995894177526,
      },
      {
        lat: 13.072610296108923,
        lng: 80.21088445022303,
      },
      {
        lat: 13.064413061614317,
        lng: 80.20956409787614,
      },
      {
        lat: 13.062222735058265,
        lng: 80.20881267678077,
      },
      {
        lat: 13.074921286659222,
        lng: 80.20462994783792,
      },
      {
        lat: 13.074379754457041,
        lng: 80.20923840162536,
      },
      {
        lat: 13.077045094013602,
        lng: 80.20974803379754,
      },
      {
        lat: 13.069482721691717,
        lng: 80.21392347787261,
      },
      {
        lat: 13.06806635249563,
        lng: 80.21115077836576,
      },
      {
        lat: 13.076579265833983,
        lng: 80.20437555654776,
      },
      {
        lat: 13.0757760572582,
        lng: 80.20729786929726,
      },
      {
        lat: 13.062643911353,
        lng: 80.21114924808023,
      },
      {
        lat: 13.071008905383412,
        lng: 80.21167759568051,
      },
      {
        lat: 13.066040597532414,
        lng: 80.21184576483768,
      },
      {
        lat: 13.075932409413147,
        lng: 80.20712966640635,
      },
      {
        lat: 13.072812144883203,
        lng: 80.21091038752242,
      },
      {
        lat: 13.076280224270684,
        lng: 80.20561348839841,
      },
      {
        lat: 13.066744525226307,
        lng: 80.21407068461747,
      },
      {
        lat: 13.062868146937994,
        lng: 80.21172626279953,
      },
      {
        lat: 13.076021558032355,
        lng: 80.2062047084919,
      },
      {
        lat: 13.074589003565675,
        lng: 80.21387150078843,
      },
      {
        lat: 13.073199691450355,
        lng: 80.2106391004289,
      },
      {
        lat: 13.0767072684638,
        lng: 80.20682506942812,
      },
    ],
    cases: 130,
    coords: [
      {
        lat: 13.075102547529573,
        lng: 80.21623643124616,
      },
      {
        lat: 13.073568482301694,
        lng: 80.21591507275589,
      },
      {
        lat: 13.071255796885321,
        lng: 80.21572787371527,
      },
      {
        lat: 13.069921698389457,
        lng: 80.21551171544998,
      },
      {
        lat: 13.068714402872027,
        lng: 80.21550551250756,
      },
      {
        lat: 13.068674694161137,
        lng: 80.21452331160732,
      },
      {
        lat: 13.062772912317858,
        lng: 80.21452985248389,
      },
      {
        lat: 13.06279727784978,
        lng: 80.2149420168926,
      },
      {
        lat: 13.062469679753862,
        lng: 80.21498819687376,
      },
      {
        lat: 13.062162751103868,
        lng: 80.21483886847261,
      },
      {
        lat: 13.06207439718829,
        lng: 80.21468769021669,
      },
      {
        lat: 13.06200750473716,
        lng: 80.21447121917615,
      },
      {
        lat: 13.061831456921784,
        lng: 80.21427737566276,
      },
      {
        lat: 13.061830268894088,
        lng: 80.21408205226554,
      },
      {
        lat: 13.062025402118294,
        lng: 80.21381995620001,
      },
      {
        lat: 13.062476752882839,
        lng: 80.21255732072346,
      },
      {
        lat: 13.062537441189338,
        lng: 80.21175376957854,
      },
      {
        lat: 13.062361657379952,
        lng: 80.2116033312645,
      },
      {
        lat: 13.062077774230938,
        lng: 80.21164914127493,
      },
      {
        lat: 13.06175030813809,
        lng: 80.21171702385594,
      },
      {
        lat: 13.061400984571797,
        lng: 80.21178509142261,
      },
      {
        lat: 13.061050208971823,
        lng: 80.21161443039249,
      },
      {
        lat: 13.058832796477144,
        lng: 80.21165268305141,
      },
      {
        lat: 13.058879502496515,
        lng: 80.21154451449414,
      },
      {
        lat: 13.059396186100711,
        lng: 80.21121827113552,
      },
      {
        lat: 13.059869780110615,
        lng: 80.21091385049097,
      },
      {
        lat: 13.059976133753368,
        lng: 80.21063399593218,
      },
      {
        lat: 13.059991087854714,
        lng: 80.20953950956573,
      },
      {
        lat: 13.060271104962704,
        lng: 80.20938693346058,
      },
      {
        lat: 13.060399851709912,
        lng: 80.20923563759555,
      },
      {
        lat: 13.060737129460385,
        lng: 80.20783801082476,
      },
      {
        lat: 13.061101237615551,
        lng: 80.20729847839777,
      },
      {
        lat: 13.061341931714374,
        lng: 80.20742360458523,
      },
      {
        lat: 13.06290469935531,
        lng: 80.20889902968402,
      },
      {
        lat: 13.063286477838943,
        lng: 80.20892286497924,
      },
      {
        lat: 13.06399584777784,
        lng: 80.2090251269397,
      },
      {
        lat: 13.065742672968236,
        lng: 80.20938927250324,
      },
      {
        lat: 13.066396374307164,
        lng: 80.20930254090263,
      },
      {
        lat: 13.067184226263713,
        lng: 80.20886281093799,
      },
      {
        lat: 13.067974218268416,
        lng: 80.20877492587388,
      },
      {
        lat: 13.069665539477931,
        lng: 80.20897714286856,
      },
      {
        lat: 13.070157832065982,
        lng: 80.20924364032439,
      },
      {
        lat: 13.071168523041576,
        lng: 80.20958694959717,
      },
      {
        lat: 13.071604817791528,
        lng: 80.20961032350708,
      },
      {
        lat: 13.071905480352017,
        lng: 80.20974311084983,
      },
      {
        lat: 13.072015665218254,
        lng: 80.20993164302563,
      },
      {
        lat: 13.072044898780566,
        lng: 80.21025619224137,
      },
      {
        lat: 13.072236364189207,
        lng: 80.21036283736223,
      },
      {
        lat: 13.072426841883226,
        lng: 80.21030709252898,
      },
      {
        lat: 13.072534392844037,
        lng: 80.21006258482717,
      },
      {
        lat: 13.073464722355709,
        lng: 80.20615715168456,
      },
      {
        lat: 13.073750898435907,
        lng: 80.20390821969937,
      },
      {
        lat: 13.073938577605352,
        lng: 80.20339236999604,
      },
      {
        lat: 13.074564197715677,
        lng: 80.20317054412621,
      },
      {
        lat: 13.076363533323686,
        lng: 80.20310275718893,
      },
      {
        lat: 13.076369851128236,
        lng: 80.20328821260347,
      },
      {
        lat: 13.076477578136911,
        lng: 80.20390667278666,
      },
      {
        lat: 13.076905346962752,
        lng: 80.2058643968337,
      },
      {
        lat: 13.077039516538774,
        lng: 80.20741169108406,
      },
      {
        lat: 13.077175946345148,
        lng: 80.20933058934834,
      },
      {
        lat: 13.077084919927001,
        lng: 80.21145786993547,
      },
      {
        lat: 13.076962930480915,
        lng: 80.21191310842734,
      },
      {
        lat: 13.07580950339617,
        lng: 80.21371904870402,
      },
      {
        lat: 13.075358483566088,
        lng: 80.2147757980403,
      },
      {
        lat: 13.075117141609377,
        lng: 80.21611981304038,
      },
      {
        lat: 13.075102547529573,
        lng: 80.21623643124616,
      },
    ],
    properties: {
      Ward_No: 105,
      Zone_Name: "ANNANAGAR",
      fillColor: "#de4307",
      zname: "ANNANAGAR(105)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.077024230985232,
        lng: 80.25064954850542,
      },
      {
        lat: 13.078388282954771,
        lng: 80.24859627152615,
      },
      {
        lat: 13.079805052578976,
        lng: 80.25294147063757,
      },
      {
        lat: 13.076147817611076,
        lng: 80.24203513414832,
      },
      {
        lat: 13.088002332505443,
        lng: 80.24968662333082,
      },
      {
        lat: 13.08539787841945,
        lng: 80.25058557177621,
      },
      {
        lat: 13.079560691337829,
        lng: 80.25110775283285,
      },
      {
        lat: 13.087069606095758,
        lng: 80.25332827450782,
      },
      {
        lat: 13.085047164924807,
        lng: 80.25488862898568,
      },
      {
        lat: 13.080097087590218,
        lng: 80.25032631366741,
      },
      {
        lat: 13.075248096330673,
        lng: 80.24484870003644,
      },
      {
        lat: 13.081812384277283,
        lng: 80.2541142497356,
      },
      {
        lat: 13.075988396623458,
        lng: 80.24227212272191,
      },
      {
        lat: 13.088498209374265,
        lng: 80.24991011692825,
      },
      {
        lat: 13.077610785041621,
        lng: 80.24974754620607,
      },
      {
        lat: 13.077131773621486,
        lng: 80.25297990012334,
      },
      {
        lat: 13.085500615697592,
        lng: 80.25337730855622,
      },
      {
        lat: 13.084231424956387,
        lng: 80.25224145733004,
      },
      {
        lat: 13.085673764677724,
        lng: 80.25228027626927,
      },
      {
        lat: 13.081885300315221,
        lng: 80.24999211274343,
      },
      {
        lat: 13.087904329672767,
        lng: 80.25489536665556,
      },
      {
        lat: 13.076816506083226,
        lng: 80.24584860744942,
      },
      {
        lat: 13.088586314973313,
        lng: 80.25155898274016,
      },
      {
        lat: 13.076021863183275,
        lng: 80.24132778510022,
      },
      {
        lat: 13.076673325398346,
        lng: 80.24283624300844,
      },
      {
        lat: 13.08945682850312,
        lng: 80.25405707741773,
      },
      {
        lat: 13.0891158089675,
        lng: 80.25071165919796,
      },
      {
        lat: 13.090162275741122,
        lng: 80.24854618501355,
      },
      {
        lat: 13.077029840586507,
        lng: 80.25016135646827,
      },
      {
        lat: 13.088775512834484,
        lng: 80.24879646848805,
      },
      {
        lat: 13.090120445589724,
        lng: 80.25111237859056,
      },
      {
        lat: 13.078546109161492,
        lng: 80.24976896907927,
      },
      {
        lat: 13.081184180359205,
        lng: 80.25223252268184,
      },
      {
        lat: 13.091151597695662,
        lng: 80.25265331607696,
      },
      {
        lat: 13.081393032110865,
        lng: 80.25472096640495,
      },
      {
        lat: 13.08373797959926,
        lng: 80.25310658284819,
      },
      {
        lat: 13.074759614283813,
        lng: 80.24159313231587,
      },
      {
        lat: 13.077431898169326,
        lng: 80.2460419689113,
      },
      {
        lat: 13.082125354816856,
        lng: 80.25262699080851,
      },
      {
        lat: 13.078170560525757,
        lng: 80.24833541567376,
      },
      {
        lat: 13.090982038556195,
        lng: 80.2493304745078,
      },
      {
        lat: 13.084115327351615,
        lng: 80.25495178450751,
      },
      {
        lat: 13.083448350773462,
        lng: 80.25229915423918,
      },
      {
        lat: 13.084199553126359,
        lng: 80.25057838241995,
      },
      {
        lat: 13.089171588208313,
        lng: 80.25444192268625,
      },
      {
        lat: 13.09122539750013,
        lng: 80.2539039531498,
      },
      {
        lat: 13.087567772398378,
        lng: 80.25298729099732,
      },
      {
        lat: 13.080235466183415,
        lng: 80.25047802947776,
      },
      {
        lat: 13.085900675085863,
        lng: 80.2534004551009,
      },
      {
        lat: 13.075560422911028,
        lng: 80.2411020210006,
      },
      {
        lat: 13.076926809243167,
        lng: 80.25076229685725,
      },
      {
        lat: 13.088287532303557,
        lng: 80.25270886043305,
      },
      {
        lat: 13.087017259984968,
        lng: 80.25386886717914,
      },
      {
        lat: 13.084236133834759,
        lng: 80.2560088040242,
      },
      {
        lat: 13.081673048019793,
        lng: 80.25490819783649,
      },
      {
        lat: 13.08192824270067,
        lng: 80.25427990993961,
      },
      {
        lat: 13.07473435694346,
        lng: 80.24134670284502,
      },
      {
        lat: 13.085374072671236,
        lng: 80.25118855396772,
      },
      {
        lat: 13.078437593896906,
        lng: 80.25017841477566,
      },
      {
        lat: 13.075891617497641,
        lng: 80.24514310571408,
      },
      {
        lat: 13.075224300921395,
        lng: 80.24511308885202,
      },
      {
        lat: 13.090532512794498,
        lng: 80.25228798589933,
      },
      {
        lat: 13.07672942941033,
        lng: 80.24780117583232,
      },
      {
        lat: 13.076008678820353,
        lng: 80.241880880147,
      },
      {
        lat: 13.087855435168514,
        lng: 80.25211302283519,
      },
      {
        lat: 13.08818424054892,
        lng: 80.25320813642318,
      },
      {
        lat: 13.07640180721279,
        lng: 80.25091381860562,
      },
      {
        lat: 13.084472792962604,
        lng: 80.25618582116039,
      },
      {
        lat: 13.076719952332212,
        lng: 80.24091375940563,
      },
      {
        lat: 13.076918638721192,
        lng: 80.24824974457198,
      },
      {
        lat: 13.089319803441546,
        lng: 80.24906861437913,
      },
      {
        lat: 13.089294606008409,
        lng: 80.24823376606982,
      },
      {
        lat: 13.081126232719093,
        lng: 80.2525022595127,
      },
      {
        lat: 13.075934888647332,
        lng: 80.2439352058355,
      },
      {
        lat: 13.074469470688577,
        lng: 80.24240658927664,
      },
      {
        lat: 13.081411862970974,
        lng: 80.25179433799724,
      },
      {
        lat: 13.088082629073158,
        lng: 80.24926328534451,
      },
    ],
    cases: 77,
    coords: [
      {
        lat: 13.076461423241062,
        lng: 80.25210599457046,
      },
      {
        lat: 13.075995132576757,
        lng: 80.24982848928404,
      },
      {
        lat: 13.075102696059934,
        lng: 80.24637501577608,
      },
      {
        lat: 13.073437474742326,
        lng: 80.24065329501391,
      },
      {
        lat: 13.076655982512872,
        lng: 80.24062605594798,
      },
      {
        lat: 13.077312190957352,
        lng: 80.24100722213318,
      },
      {
        lat: 13.078056652784934,
        lng: 80.2465459958649,
      },
      {
        lat: 13.078425511156963,
        lng: 80.24876090384298,
      },
      {
        lat: 13.078712547197734,
        lng: 80.25004392362591,
      },
      {
        lat: 13.078891304381921,
        lng: 80.25021884494018,
      },
      {
        lat: 13.079170215663476,
        lng: 80.25016607467514,
      },
      {
        lat: 13.079499740534168,
        lng: 80.25008767117117,
      },
      {
        lat: 13.081123974309497,
        lng: 80.25002351509293,
      },
      {
        lat: 13.081452119503918,
        lng: 80.24971827931672,
      },
      {
        lat: 13.081705800639353,
        lng: 80.24969092746426,
      },
      {
        lat: 13.082798515079327,
        lng: 80.24988331860429,
      },
      {
        lat: 13.086044794332874,
        lng: 80.25048560326765,
      },
      {
        lat: 13.086038282372199,
        lng: 80.24941497310704,
      },
      {
        lat: 13.08686554227234,
        lng: 80.24929325551278,
      },
      {
        lat: 13.088153290057958,
        lng: 80.2488999693718,
      },
      {
        lat: 13.08911207777502,
        lng: 80.24824179598708,
      },
      {
        lat: 13.089455357189825,
        lng: 80.24781622338573,
      },
      {
        lat: 13.089835331293475,
        lng: 80.24820424760446,
      },
      {
        lat: 13.090946887732505,
        lng: 80.24894856722686,
      },
      {
        lat: 13.091169810247655,
        lng: 80.24919792291355,
      },
      {
        lat: 13.091236694966012,
        lng: 80.24979405740271,
      },
      {
        lat: 13.091020839766747,
        lng: 80.25070663771534,
      },
      {
        lat: 13.091149642981671,
        lng: 80.25108241112673,
      },
      {
        lat: 13.09146897689943,
        lng: 80.2515821931963,
      },
      {
        lat: 13.091439450215349,
        lng: 80.25192790130262,
      },
      {
        lat: 13.091380205838307,
        lng: 80.2525879138393,
      },
      {
        lat: 13.091510346112988,
        lng: 80.25318351298048,
      },
      {
        lat: 13.091513784266661,
        lng: 80.25374877914233,
      },
      {
        lat: 13.091421002026257,
        lng: 80.25409502259663,
      },
      {
        lat: 13.090947158380063,
        lng: 80.25419324873486,
      },
      {
        lat: 13.090765766733568,
        lng: 80.25419444438275,
      },
      {
        lat: 13.090763451916596,
        lng: 80.2538138657838,
      },
      {
        lat: 13.090702200740914,
        lng: 80.25369419526625,
      },
      {
        lat: 13.09046012001771,
        lng: 80.2536962440582,
      },
      {
        lat: 13.090460729180068,
        lng: 80.25379639632114,
      },
      {
        lat: 13.090623212821262,
        lng: 80.25397530453309,
      },
      {
        lat: 13.090584084358733,
        lng: 80.25417595052411,
      },
      {
        lat: 13.09028221444929,
        lng: 80.25429869422958,
      },
      {
        lat: 13.086495586242057,
        lng: 80.25531228414633,
      },
      {
        lat: 13.086354859816604,
        lng: 80.25539360108533,
      },
      {
        lat: 13.086214011558736,
        lng: 80.25545488757152,
      },
      {
        lat: 13.085927685409166,
        lng: 80.25481630334637,
      },
      {
        lat: 13.085847600997031,
        lng: 80.25491713854,
      },
      {
        lat: 13.085930365723556,
        lng: 80.25525697330308,
      },
      {
        lat: 13.086316706013918,
        lng: 80.25575449069697,
      },
      {
        lat: 13.086359387001007,
        lng: 80.25595411260075,
      },
      {
        lat: 13.085833965801767,
        lng: 80.25608918788126,
      },
      {
        lat: 13.085092497222533,
        lng: 80.25623259444936,
      },
      {
        lat: 13.084730707123482,
        lng: 80.256355646284,
      },
      {
        lat: 13.084213134340379,
        lng: 80.25641145088444,
      },
      {
        lat: 13.083954712841138,
        lng: 80.25649934505205,
      },
      {
        lat: 13.08381629726352,
        lng: 80.25644909225124,
      },
      {
        lat: 13.083746099052812,
        lng: 80.25626113078208,
      },
      {
        lat: 13.083728106416688,
        lng: 80.25614129314707,
      },
      {
        lat: 13.083537798029239,
        lng: 80.25607433811261,
      },
      {
        lat: 13.082863921287284,
        lng: 80.2559771928109,
      },
      {
        lat: 13.081533222145227,
        lng: 80.25574847504026,
      },
      {
        lat: 13.0814287067411,
        lng: 80.25559508683796,
      },
      {
        lat: 13.081530303006978,
        lng: 80.25526854010029,
      },
      {
        lat: 13.081563681905772,
        lng: 80.25507970203118,
      },
      {
        lat: 13.08145895799195,
        lng: 80.25489203276182,
      },
      {
        lat: 13.081277385285267,
        lng: 80.25484214521093,
      },
      {
        lat: 13.079395004676519,
        lng: 80.25473808637496,
      },
      {
        lat: 13.0791529077344,
        lng: 80.25467156964054,
      },
      {
        lat: 13.078823141220019,
        lng: 80.25438295646977,
      },
      {
        lat: 13.078628662635234,
        lng: 80.25363038009272,
      },
      {
        lat: 13.078591843323325,
        lng: 80.2532535805541,
      },
      {
        lat: 13.078504382261054,
        lng: 80.25306576518486,
      },
      {
        lat: 13.078366592213065,
        lng: 80.25311835558553,
      },
      {
        lat: 13.077210606685673,
        lng: 80.2532309874862,
      },
      {
        lat: 13.076934818079764,
        lng: 80.25330188722032,
      },
      {
        lat: 13.07679577697253,
        lng: 80.25314879121812,
      },
      {
        lat: 13.076708732930008,
        lng: 80.25302953798305,
      },
      {
        lat: 13.076584139791017,
        lng: 80.25241350147469,
      },
      {
        lat: 13.076582680221884,
        lng: 80.25217353400483,
      },
      {
        lat: 13.076461423241062,
        lng: 80.25210599457046,
      },
    ],
    properties: {
      Ward_No: 104,
      Zone_Name: "ANNANAGAR",
      fillColor: "#f6d04d",
      zname: "ANNANAGAR(104)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.071095202200627,
        lng: 80.2324986791485,
      },
      {
        lat: 13.069378318201224,
        lng: 80.2382128958905,
      },
      {
        lat: 13.073773734375402,
        lng: 80.2405431570689,
      },
      {
        lat: 13.07211160919456,
        lng: 80.23358607344609,
      },
      {
        lat: 13.073901988026503,
        lng: 80.23151321948656,
      },
      {
        lat: 13.07494755652523,
        lng: 80.24688596799282,
      },
      {
        lat: 13.074277785638122,
        lng: 80.23581201930331,
      },
      {
        lat: 13.073124821064182,
        lng: 80.23566436391295,
      },
      {
        lat: 13.07468868377253,
        lng: 80.24889128877115,
      },
      {
        lat: 13.074515873505753,
        lng: 80.24603309508393,
      },
      {
        lat: 13.0715310604798,
        lng: 80.23530495892392,
      },
      {
        lat: 13.072697418080391,
        lng: 80.23625667437501,
      },
      {
        lat: 13.069828462688397,
        lng: 80.23919282978257,
      },
      {
        lat: 13.075224521343122,
        lng: 80.23430518411419,
      },
      {
        lat: 13.074356298006881,
        lng: 80.2366993834879,
      },
      {
        lat: 13.069246717226147,
        lng: 80.23048108932713,
      },
      {
        lat: 13.075019912986377,
        lng: 80.23582504227599,
      },
      {
        lat: 13.076308326128542,
        lng: 80.23784979510471,
      },
      {
        lat: 13.073104088799772,
        lng: 80.2343510188535,
      },
      {
        lat: 13.07489634948056,
        lng: 80.23236264719155,
      },
      {
        lat: 13.073341851805598,
        lng: 80.23987754421498,
      },
      {
        lat: 13.072113838855056,
        lng: 80.23039277184931,
      },
      {
        lat: 13.072963526792844,
        lng: 80.24173988835265,
      },
      {
        lat: 13.07476135929122,
        lng: 80.23564158961072,
      },
      {
        lat: 13.073396299525108,
        lng: 80.24784648317838,
      },
      {
        lat: 13.070666957647012,
        lng: 80.23045710490415,
      },
      {
        lat: 13.074048136521467,
        lng: 80.23932210191391,
      },
      {
        lat: 13.070385582255216,
        lng: 80.24410097604785,
      },
      {
        lat: 13.07175853709616,
        lng: 80.23609933898416,
      },
      {
        lat: 13.072953552734706,
        lng: 80.25023743709966,
      },
      {
        lat: 13.070488311354445,
        lng: 80.23969109340358,
      },
      {
        lat: 13.071754635839927,
        lng: 80.23924712103276,
      },
      {
        lat: 13.076164877893202,
        lng: 80.23770598791282,
      },
      {
        lat: 13.072387462200776,
        lng: 80.24681294077511,
      },
      {
        lat: 13.07457421385905,
        lng: 80.25194004154861,
      },
      {
        lat: 13.071318444580715,
        lng: 80.24800412948821,
      },
      {
        lat: 13.070425410894167,
        lng: 80.23242514210008,
      },
      {
        lat: 13.07027099141604,
        lng: 80.24093788164694,
      },
      {
        lat: 13.071546693888296,
        lng: 80.24534172110562,
      },
      {
        lat: 13.074148246108088,
        lng: 80.25213738719786,
      },
      {
        lat: 13.073660180403092,
        lng: 80.24054035521056,
      },
      {
        lat: 13.070691637558086,
        lng: 80.24265522768731,
      },
      {
        lat: 13.072568447426958,
        lng: 80.23403805953667,
      },
      {
        lat: 13.071542223940131,
        lng: 80.24712357490928,
      },
      {
        lat: 13.070824365388242,
        lng: 80.24453705009977,
      },
      {
        lat: 13.067996082958162,
        lng: 80.2292379750683,
      },
      {
        lat: 13.074928732437119,
        lng: 80.23216076811335,
      },
      {
        lat: 13.069755937856423,
        lng: 80.23561878265295,
      },
      {
        lat: 13.07079876693895,
        lng: 80.24538093077678,
      },
      {
        lat: 13.07107482779333,
        lng: 80.23705552503299,
      },
      {
        lat: 13.071743529685554,
        lng: 80.23895638468017,
      },
      {
        lat: 13.073531853649316,
        lng: 80.23218420751573,
      },
      {
        lat: 13.0711783082021,
        lng: 80.24398876746956,
      },
      {
        lat: 13.076889529986191,
        lng: 80.24069463458292,
      },
      {
        lat: 13.070376036085623,
        lng: 80.23209406466451,
      },
      {
        lat: 13.076525190855996,
        lng: 80.2397663360959,
      },
      {
        lat: 13.072114040160223,
        lng: 80.24862050455393,
      },
      {
        lat: 13.07371632887332,
        lng: 80.24967983200254,
      },
      {
        lat: 13.070412287087578,
        lng: 80.23351661310697,
      },
      {
        lat: 13.071934943176775,
        lng: 80.2415820689306,
      },
      {
        lat: 13.073666506164528,
        lng: 80.24818590512712,
      },
      {
        lat: 13.073698937664968,
        lng: 80.24881012370862,
      },
      {
        lat: 13.070821286938733,
        lng: 80.23766548105775,
      },
      {
        lat: 13.070725231212215,
        lng: 80.24217458395454,
      },
      {
        lat: 13.069864202647903,
        lng: 80.23513805302319,
      },
      {
        lat: 13.071006412493718,
        lng: 80.23911024694964,
      },
      {
        lat: 13.075715024717072,
        lng: 80.23857598561264,
      },
      {
        lat: 13.07078284369688,
        lng: 80.24464911645761,
      },
      {
        lat: 13.072807286159303,
        lng: 80.24334933795944,
      },
      {
        lat: 13.069336038326712,
        lng: 80.23135503709723,
      },
      {
        lat: 13.06943093297342,
        lng: 80.23098319856383,
      },
      {
        lat: 13.07253460223032,
        lng: 80.23117113034208,
      },
      {
        lat: 13.076058181972869,
        lng: 80.23885400130611,
      },
      {
        lat: 13.070535700374819,
        lng: 80.22984171163216,
      },
      {
        lat: 13.072294608984018,
        lng: 80.2382749549876,
      },
      {
        lat: 13.073539442169752,
        lng: 80.23640383192466,
      },
      {
        lat: 13.074323354259665,
        lng: 80.23178172222052,
      },
      {
        lat: 13.071341941348141,
        lng: 80.23104842104914,
      },
    ],
    cases: 78,
    coords: [
      {
        lat: 13.06761938060582,
        lng: 80.22798885186684,
      },
      {
        lat: 13.068640261274604,
        lng: 80.2287260002565,
      },
      {
        lat: 13.069760455277533,
        lng: 80.22916857247287,
      },
      {
        lat: 13.072432780637048,
        lng: 80.22961855644931,
      },
      {
        lat: 13.073448632736543,
        lng: 80.2299181767856,
      },
      {
        lat: 13.07392620601025,
        lng: 80.23018125701435,
      },
      {
        lat: 13.074503798406074,
        lng: 80.23013974632846,
      },
      {
        lat: 13.075442119676895,
        lng: 80.23404129638327,
      },
      {
        lat: 13.07579794777488,
        lng: 80.23411389956733,
      },
      {
        lat: 13.076740724930879,
        lng: 80.23886964352577,
      },
      {
        lat: 13.077312190957352,
        lng: 80.24100722213318,
      },
      {
        lat: 13.076655982512872,
        lng: 80.24062605594798,
      },
      {
        lat: 13.073437474742326,
        lng: 80.24065329501391,
      },
      {
        lat: 13.075102696059934,
        lng: 80.24637501577608,
      },
      {
        lat: 13.075995132576757,
        lng: 80.24982848928404,
      },
      {
        lat: 13.076461423241062,
        lng: 80.25210599457046,
      },
      {
        lat: 13.075097657964616,
        lng: 80.25211753646965,
      },
      {
        lat: 13.07445924522039,
        lng: 80.25217436376984,
      },
      {
        lat: 13.074235453679261,
        lng: 80.2522791062712,
      },
      {
        lat: 13.074097038101643,
        lng: 80.25222885347037,
      },
      {
        lat: 13.07397578112059,
        lng: 80.25216131403604,
      },
      {
        lat: 13.073920135133234,
        lng: 80.25152755259397,
      },
      {
        lat: 13.073864551698659,
        lng: 80.25090407547208,
      },
      {
        lat: 13.073845412258228,
        lng: 80.25059569196793,
      },
      {
        lat: 13.073706788170739,
        lng: 80.25051115810005,
      },
      {
        lat: 13.073499738206477,
        lng: 80.25053005183346,
      },
      {
        lat: 13.073137426832735,
        lng: 80.25056740100047,
      },
      {
        lat: 13.072585745366217,
        lng: 80.25069205993499,
      },
      {
        lat: 13.072015445733777,
        lng: 80.25059403803333,
      },
      {
        lat: 13.071893667478262,
        lng: 80.25044079593117,
      },
      {
        lat: 13.071733714596483,
        lng: 80.24968792735416,
      },
      {
        lat: 13.071557541412629,
        lng: 80.24910661021276,
      },
      {
        lat: 13.071309501939123,
        lng: 80.24806308306489,
      },
      {
        lat: 13.070207242342148,
        lng: 80.24565547213149,
      },
      {
        lat: 13.069470109002387,
        lng: 80.24367330643736,
      },
      {
        lat: 13.069070144277239,
        lng: 80.24319673179734,
      },
      {
        lat: 13.068775111976015,
        lng: 80.24294210749358,
      },
      {
        lat: 13.068515647927399,
        lng: 80.24285859632562,
      },
      {
        lat: 13.068497551036165,
        lng: 80.24272161815699,
      },
      {
        lat: 13.069238081321016,
        lng: 80.24242394678683,
      },
      {
        lat: 13.069427138649159,
        lng: 80.2422852154184,
      },
      {
        lat: 13.069408833248223,
        lng: 80.24211395618269,
      },
      {
        lat: 13.06933884354739,
        lng: 80.24196027578058,
      },
      {
        lat: 13.069305755317364,
        lng: 80.2407777385987,
      },
      {
        lat: 13.069315343054608,
        lng: 80.23870759340976,
      },
      {
        lat: 13.069417526888689,
        lng: 80.23727530133581,
      },
      {
        lat: 13.069254512664452,
        lng: 80.23599940740752,
      },
      {
        lat: 13.06935977708714,
        lng: 80.23507359429429,
      },
      {
        lat: 13.069622028495543,
        lng: 80.23443273801252,
      },
      {
        lat: 13.070168977158614,
        lng: 80.2331948794001,
      },
      {
        lat: 13.07040838090474,
        lng: 80.23244410669449,
      },
      {
        lat: 13.070341043365723,
        lng: 80.23231254484185,
      },
      {
        lat: 13.070119531552214,
        lng: 80.23235846346955,
      },
      {
        lat: 13.069831753708998,
        lng: 80.23244898684001,
      },
      {
        lat: 13.069155285470801,
        lng: 80.23244810537864,
      },
      {
        lat: 13.068910658117913,
        lng: 80.23234006593378,
      },
      {
        lat: 13.068708779439445,
        lng: 80.23196740120055,
      },
      {
        lat: 13.068416983437176,
        lng: 80.23139729983961,
      },
      {
        lat: 13.067710313171265,
        lng: 80.2282541405892,
      },
      {
        lat: 13.06761938060582,
        lng: 80.22798885186684,
      },
    ],
    properties: {
      Ward_No: 107,
      Zone_Name: "ANNANAGAR",
      fillColor: "#f6d04d",
      zname: "ANNANAGAR(107)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.095664629063682,
        lng: 80.23691024021196,
      },
      {
        lat: 13.090817865998337,
        lng: 80.23807826839308,
      },
      {
        lat: 13.09611507843528,
        lng: 80.23622424217605,
      },
      {
        lat: 13.08644041318573,
        lng: 80.24278186623013,
      },
      {
        lat: 13.087136904287457,
        lng: 80.24291954190036,
      },
      {
        lat: 13.087044388842571,
        lng: 80.23821715490989,
      },
      {
        lat: 13.085890450644046,
        lng: 80.24355555290185,
      },
      {
        lat: 13.09138522186878,
        lng: 80.23573680109473,
      },
      {
        lat: 13.093744554375467,
        lng: 80.24075607393272,
      },
      {
        lat: 13.091088536417008,
        lng: 80.23705798447831,
      },
      {
        lat: 13.093426758757051,
        lng: 80.23390717470674,
      },
      {
        lat: 13.095759063184824,
        lng: 80.24068346949437,
      },
      {
        lat: 13.092862979302282,
        lng: 80.2394726454256,
      },
      {
        lat: 13.092348498003165,
        lng: 80.2400703923031,
      },
      {
        lat: 13.092240853312509,
        lng: 80.23597106696724,
      },
      {
        lat: 13.091292353689866,
        lng: 80.23701233175456,
      },
      {
        lat: 13.088608815459162,
        lng: 80.24048777656965,
      },
      {
        lat: 13.09489884584637,
        lng: 80.238253667758,
      },
      {
        lat: 13.096343629192098,
        lng: 80.23581130221544,
      },
      {
        lat: 13.091233638827655,
        lng: 80.23586128266103,
      },
      {
        lat: 13.088394725653634,
        lng: 80.23997785991574,
      },
      {
        lat: 13.092529228505516,
        lng: 80.23193255699105,
      },
      {
        lat: 13.095360276646817,
        lng: 80.23276564170203,
      },
      {
        lat: 13.088446970119083,
        lng: 80.23460912505881,
      },
      {
        lat: 13.093507703965889,
        lng: 80.23342551238188,
      },
      {
        lat: 13.088603351888716,
        lng: 80.23678281283586,
      },
      {
        lat: 13.087445659795236,
        lng: 80.23794948334388,
      },
      {
        lat: 13.087539684497,
        lng: 80.24069996801575,
      },
      {
        lat: 13.089777648078142,
        lng: 80.23484019568966,
      },
      {
        lat: 13.092227194594734,
        lng: 80.23156217787853,
      },
      {
        lat: 13.088799499196064,
        lng: 80.23186258264523,
      },
      {
        lat: 13.087204334893004,
        lng: 80.23760395839854,
      },
      {
        lat: 13.092446623635459,
        lng: 80.23224206367503,
      },
      {
        lat: 13.090559877897874,
        lng: 80.23601810093241,
      },
      {
        lat: 13.09319958451963,
        lng: 80.2356721590468,
      },
      {
        lat: 13.08684173015517,
        lng: 80.23720101465379,
      },
      {
        lat: 13.08848024146423,
        lng: 80.23933978716,
      },
      {
        lat: 13.090871880457119,
        lng: 80.23300817717139,
      },
      {
        lat: 13.096407103530838,
        lng: 80.23729399202402,
      },
      {
        lat: 13.093848783779361,
        lng: 80.23504314095668,
      },
      {
        lat: 13.092990754852288,
        lng: 80.23579047314368,
      },
      {
        lat: 13.096540659802244,
        lng: 80.23575125931383,
      },
      {
        lat: 13.087159742821163,
        lng: 80.238536631719,
      },
      {
        lat: 13.08933371586926,
        lng: 80.23705404261814,
      },
      {
        lat: 13.089531938803157,
        lng: 80.23612277435849,
      },
      {
        lat: 13.0939391043719,
        lng: 80.24047341636977,
      },
      {
        lat: 13.095296876979923,
        lng: 80.24112561774535,
      },
      {
        lat: 13.094870598654433,
        lng: 80.23301011921,
      },
      {
        lat: 13.090451646221688,
        lng: 80.23898868848133,
      },
      {
        lat: 13.09256781715218,
        lng: 80.23871107208272,
      },
      {
        lat: 13.09597554379395,
        lng: 80.23391891828095,
      },
      {
        lat: 13.094738135093092,
        lng: 80.2366961711412,
      },
      {
        lat: 13.086523836011198,
        lng: 80.24051576745175,
      },
      {
        lat: 13.094737461285597,
        lng: 80.23371702872204,
      },
      {
        lat: 13.089546988357974,
        lng: 80.23603977579174,
      },
      {
        lat: 13.08611637542976,
        lng: 80.23928764845252,
      },
      {
        lat: 13.090682393927226,
        lng: 80.23336739725679,
      },
      {
        lat: 13.086025537901543,
        lng: 80.23882496873769,
      },
      {
        lat: 13.093872224253388,
        lng: 80.2325121859387,
      },
      {
        lat: 13.090074808927913,
        lng: 80.23554742626942,
      },
      {
        lat: 13.089167525677805,
        lng: 80.23350715587782,
      },
      {
        lat: 13.087764574340842,
        lng: 80.24058903968327,
      },
      {
        lat: 13.086803663828539,
        lng: 80.2375662565169,
      },
      {
        lat: 13.091495686748303,
        lng: 80.23414831559458,
      },
      {
        lat: 13.091523249249086,
        lng: 80.23139260857914,
      },
      {
        lat: 13.087166830454263,
        lng: 80.24160403895688,
      },
      {
        lat: 13.09262028738211,
        lng: 80.23522957475656,
      },
      {
        lat: 13.092626918164973,
        lng: 80.23838131139486,
      },
      {
        lat: 13.08949480480651,
        lng: 80.23841719340419,
      },
      {
        lat: 13.094556268261325,
        lng: 80.23917236197545,
      },
      {
        lat: 13.095406379138906,
        lng: 80.24127825209824,
      },
      {
        lat: 13.091659305660896,
        lng: 80.24022229983136,
      },
      {
        lat: 13.093606482665882,
        lng: 80.23645548743444,
      },
      {
        lat: 13.089571510131021,
        lng: 80.23706711971955,
      },
      {
        lat: 13.094123454700998,
        lng: 80.23343388012486,
      },
      {
        lat: 13.093496198390126,
        lng: 80.23994714454709,
      },
      {
        lat: 13.086235472959782,
        lng: 80.2390697371707,
      },
      {
        lat: 13.094553198733106,
        lng: 80.23276363426977,
      },
      {
        lat: 13.09175178679597,
        lng: 80.234803921762,
      },
      {
        lat: 13.091158564110895,
        lng: 80.23239248446808,
      },
      {
        lat: 13.090610198278068,
        lng: 80.2401929028968,
      },
      {
        lat: 13.089831315548583,
        lng: 80.23854436209379,
      },
      {
        lat: 13.092753230237166,
        lng: 80.23997719465602,
      },
      {
        lat: 13.090405442810756,
        lng: 80.23814659620959,
      },
      {
        lat: 13.094903664040629,
        lng: 80.24107926712415,
      },
      {
        lat: 13.089277302011677,
        lng: 80.23617191658282,
      },
      {
        lat: 13.090712547190945,
        lng: 80.23476865383572,
      },
      {
        lat: 13.089109812806457,
        lng: 80.23619407082683,
      },
      {
        lat: 13.089395226211957,
        lng: 80.23818367896604,
      },
      {
        lat: 13.093135525915018,
        lng: 80.23614207448755,
      },
      {
        lat: 13.092357838604801,
        lng: 80.2361280461975,
      },
      {
        lat: 13.089617184132264,
        lng: 80.23654729332605,
      },
      {
        lat: 13.0886745331485,
        lng: 80.24144983469913,
      },
      {
        lat: 13.088779721197946,
        lng: 80.23327729781569,
      },
      {
        lat: 13.093639625443675,
        lng: 80.23777137549483,
      },
      {
        lat: 13.095501979462393,
        lng: 80.23625849995585,
      },
      {
        lat: 13.095218295252606,
        lng: 80.23939203611177,
      },
      {
        lat: 13.090241385027367,
        lng: 80.23204062760169,
      },
      {
        lat: 13.092803169428908,
        lng: 80.2375748682686,
      },
      {
        lat: 13.095075186483172,
        lng: 80.23627306167036,
      },
      {
        lat: 13.08594267293936,
        lng: 80.24089411363781,
      },
      {
        lat: 13.094938485608958,
        lng: 80.2337819524975,
      },
      {
        lat: 13.091633348040592,
        lng: 80.23682445737037,
      },
      {
        lat: 13.089163237374898,
        lng: 80.23624435818573,
      },
      {
        lat: 13.094721807253936,
        lng: 80.23577808940574,
      },
      {
        lat: 13.085860973075862,
        lng: 80.23937383360744,
      },
      {
        lat: 13.089022434234778,
        lng: 80.23294294335206,
      },
      {
        lat: 13.086030604317573,
        lng: 80.24142991510132,
      },
      {
        lat: 13.09430413918847,
        lng: 80.24060584048489,
      },
      {
        lat: 13.091683995092353,
        lng: 80.23881128995748,
      },
      {
        lat: 13.091984054599275,
        lng: 80.2358681199879,
      },
      {
        lat: 13.09627575623479,
        lng: 80.23846449120798,
      },
      {
        lat: 13.095998187601367,
        lng: 80.23738439792311,
      },
      {
        lat: 13.08903269089645,
        lng: 80.23162623857813,
      },
      {
        lat: 13.094398697501438,
        lng: 80.23650221431762,
      },
      {
        lat: 13.089562817081251,
        lng: 80.23594073727526,
      },
      {
        lat: 13.089188424557781,
        lng: 80.2384301129883,
      },
      {
        lat: 13.094862839883765,
        lng: 80.23923957773155,
      },
      {
        lat: 13.090565651379778,
        lng: 80.23465170450885,
      },
      {
        lat: 13.09558425284044,
        lng: 80.23770384044593,
      },
      {
        lat: 13.091972983827716,
        lng: 80.23671829203724,
      },
      {
        lat: 13.088473935886555,
        lng: 80.23761862731769,
      },
      {
        lat: 13.089860507715223,
        lng: 80.23472228971282,
      },
      {
        lat: 13.091159455047839,
        lng: 80.23407671208838,
      },
      {
        lat: 13.088363734245974,
        lng: 80.23815718025955,
      },
    ],
    cases: 125,
    coords: [
      {
        lat: 13.086634898292562,
        lng: 80.24482705663955,
      },
      {
        lat: 13.086038812770338,
        lng: 80.24483210146593,
      },
      {
        lat: 13.08591027820318,
        lng: 80.24411401452336,
      },
      {
        lat: 13.085462844192765,
        lng: 80.24200137268272,
      },
      {
        lat: 13.085226093557914,
        lng: 80.24050338328587,
      },
      {
        lat: 13.085380643277992,
        lng: 80.2386938644568,
      },
      {
        lat: 13.085747251077134,
        lng: 80.23772501279132,
      },
      {
        lat: 13.086627195200087,
        lng: 80.23609428541481,
      },
      {
        lat: 13.086706719574877,
        lng: 80.23555936826908,
      },
      {
        lat: 13.08657980965724,
        lng: 80.23510838963196,
      },
      {
        lat: 13.086578309980753,
        lng: 80.23486182811939,
      },
      {
        lat: 13.086743357436207,
        lng: 80.23477823987838,
      },
      {
        lat: 13.087881620423728,
        lng: 80.23478915431402,
      },
      {
        lat: 13.087859677274945,
        lng: 80.2345838615205,
      },
      {
        lat: 13.088086430066573,
        lng: 80.2344381075001,
      },
      {
        lat: 13.0881461358341,
        lng: 80.23404719303726,
      },
      {
        lat: 13.08842722055252,
        lng: 80.23262701246715,
      },
      {
        lat: 13.088776829228525,
        lng: 80.23145811342133,
      },
      {
        lat: 13.090302702554341,
        lng: 80.23162544372788,
      },
      {
        lat: 13.09057553230135,
        lng: 80.23171325678312,
      },
      {
        lat: 13.090757163006698,
        lng: 80.23172974401207,
      },
      {
        lat: 13.09090073548969,
        lng: 80.23145816266872,
      },
      {
        lat: 13.091081379566504,
        lng: 80.23131243849639,
      },
      {
        lat: 13.092606485514578,
        lng: 80.23135360437975,
      },
      {
        lat: 13.09268315008613,
        lng: 80.23201985896985,
      },
      {
        lat: 13.092921977750262,
        lng: 80.23248647254687,
      },
      {
        lat: 13.093051454264888,
        lng: 80.23288191392362,
      },
      {
        lat: 13.093306022278446,
        lng: 80.23295185711564,
      },
      {
        lat: 13.09370536865448,
        lng: 80.23294847734367,
      },
      {
        lat: 13.093831556407329,
        lng: 80.23280321404937,
      },
      {
        lat: 13.093846200502654,
        lng: 80.23222630877456,
      },
      {
        lat: 13.093972607506293,
        lng: 80.23211709245838,
      },
      {
        lat: 13.094535870981181,
        lng: 80.2322024474975,
      },
      {
        lat: 13.09513543867214,
        lng: 80.23228749528464,
      },
      {
        lat: 13.095409035796896,
        lng: 80.23250147276318,
      },
      {
        lat: 13.095646657581709,
        lng: 80.23276982796077,
      },
      {
        lat: 13.095629711353022,
        lng: 80.2329682399659,
      },
      {
        lat: 13.095485700368455,
        lng: 80.2331677273533,
      },
      {
        lat: 13.09557689940989,
        lng: 80.23323905317947,
      },
      {
        lat: 13.095995055646176,
        lng: 80.23334366071563,
      },
      {
        lat: 13.096394402022215,
        lng: 80.23334028094372,
      },
      {
        lat: 13.09664951816273,
        lng: 80.23350034158115,
      },
      {
        lat: 13.0969795207858,
        lng: 80.23348818474726,
      },
      {
        lat: 13.09697647828675,
        lng: 80.23350165372626,
      },
      {
        lat: 13.096817248491785,
        lng: 80.23468223519872,
      },
      {
        lat: 13.096725806433431,
        lng: 80.23486682696293,
      },
      {
        lat: 13.096865694620107,
        lng: 80.23503797230262,
      },
      {
        lat: 13.096798999683333,
        lng: 80.23548659280829,
      },
      {
        lat: 13.09576392228492,
        lng: 80.2415896517748,
      },
      {
        lat: 13.095487837544608,
        lng: 80.24149848703028,
      },
      {
        lat: 13.093650370277233,
        lng: 80.24101155331692,
      },
      {
        lat: 13.091656865213242,
        lng: 80.24087139840614,
      },
      {
        lat: 13.090549128944957,
        lng: 80.24075515229657,
      },
      {
        lat: 13.089507704369595,
        lng: 80.2411408296494,
      },
      {
        lat: 13.088405316340529,
        lng: 80.24190388645822,
      },
      {
        lat: 13.088089993602049,
        lng: 80.24206358157727,
      },
      {
        lat: 13.087933955805317,
        lng: 80.24241036037995,
      },
      {
        lat: 13.087811455872066,
        lng: 80.24307090826504,
      },
      {
        lat: 13.087624363322956,
        lng: 80.24351216576869,
      },
      {
        lat: 13.086618004679423,
        lng: 80.24446284160898,
      },
      {
        lat: 13.086634898292562,
        lng: 80.24482705663955,
      },
    ],
    properties: {
      Ward_No: 98,
      Zone_Name: "ANNANAGAR",
      fillColor: "#de4307",
      zname: "ANNANAGAR(98)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.081653512723335,
        lng: 80.24373008351338,
      },
      {
        lat: 13.079964254101988,
        lng: 80.2315792278257,
      },
      {
        lat: 13.076596533445965,
        lng: 80.23622088775743,
      },
      {
        lat: 13.081175645541274,
        lng: 80.23238517360353,
      },
      {
        lat: 13.081741643665959,
        lng: 80.24017403619703,
      },
      {
        lat: 13.082226875480572,
        lng: 80.2352931488391,
      },
      {
        lat: 13.082788432124888,
        lng: 80.24425576437436,
      },
      {
        lat: 13.077076129583622,
        lng: 80.23093455649789,
      },
      {
        lat: 13.083027561993054,
        lng: 80.23249109415363,
      },
      {
        lat: 13.082338930191305,
        lng: 80.24687912433832,
      },
      {
        lat: 13.083265949627565,
        lng: 80.24882265970238,
      },
      {
        lat: 13.07960645768194,
        lng: 80.24201280274043,
      },
      {
        lat: 13.077508511962778,
        lng: 80.23453636892495,
      },
      {
        lat: 13.077406038276681,
        lng: 80.24040322099654,
      },
      {
        lat: 13.078954397111906,
        lng: 80.23815324118222,
      },
      {
        lat: 13.084878829834423,
        lng: 80.2390960694201,
      },
      {
        lat: 13.082738139045965,
        lng: 80.2461494838652,
      },
      {
        lat: 13.082584184444128,
        lng: 80.23794854574227,
      },
      {
        lat: 13.084629294605596,
        lng: 80.2408490387824,
      },
      {
        lat: 13.079011081466897,
        lng: 80.24510400335933,
      },
      {
        lat: 13.078496202592364,
        lng: 80.24703240442676,
      },
      {
        lat: 13.084812298156791,
        lng: 80.24823445484529,
      },
      {
        lat: 13.079318086335766,
        lng: 80.23397924148769,
      },
      {
        lat: 13.084777153382188,
        lng: 80.24466645838612,
      },
      {
        lat: 13.07996206569594,
        lng: 80.23135658006923,
      },
      {
        lat: 13.0802019247491,
        lng: 80.24225675423311,
      },
      {
        lat: 13.080168378281785,
        lng: 80.2417668739045,
      },
      {
        lat: 13.080581995920571,
        lng: 80.24014923517188,
      },
      {
        lat: 13.07929093708997,
        lng: 80.24679432576757,
      },
      {
        lat: 13.07812175796659,
        lng: 80.24001017430942,
      },
      {
        lat: 13.079316884204466,
        lng: 80.24138913818096,
      },
      {
        lat: 13.085175205807975,
        lng: 80.23523066779335,
      },
      {
        lat: 13.081184199928945,
        lng: 80.24002048880492,
      },
      {
        lat: 13.078164070687919,
        lng: 80.23443575173651,
      },
      {
        lat: 13.07885207067528,
        lng: 80.24121584912082,
      },
      {
        lat: 13.077212689501405,
        lng: 80.23150515973047,
      },
      {
        lat: 13.0764426240962,
        lng: 80.23249962226048,
      },
      {
        lat: 13.081884215081146,
        lng: 80.23885319811629,
      },
      {
        lat: 13.083110099444447,
        lng: 80.23942805043899,
      },
      {
        lat: 13.081207841405556,
        lng: 80.23206267768524,
      },
      {
        lat: 13.075568536357615,
        lng: 80.23216484112577,
      },
      {
        lat: 13.080016311151779,
        lng: 80.23967215121672,
      },
      {
        lat: 13.082521422360095,
        lng: 80.23853013484062,
      },
      {
        lat: 13.082085851505047,
        lng: 80.23537148126745,
      },
      {
        lat: 13.085109471991208,
        lng: 80.24833726022091,
      },
      {
        lat: 13.082254660070221,
        lng: 80.24045623135072,
      },
      {
        lat: 13.079248282091127,
        lng: 80.24606626414688,
      },
      {
        lat: 13.08408668734155,
        lng: 80.24837081067007,
      },
      {
        lat: 13.080881607402322,
        lng: 80.2339417892976,
      },
      {
        lat: 13.078799594165451,
        lng: 80.23459255234924,
      },
      {
        lat: 13.080932726596348,
        lng: 80.24987291496161,
      },
      {
        lat: 13.079848415957315,
        lng: 80.23097224560082,
      },
      {
        lat: 13.082384826378002,
        lng: 80.23946534784498,
      },
      {
        lat: 13.081897714138238,
        lng: 80.23263327750287,
      },
      {
        lat: 13.07585631733352,
        lng: 80.23383874993529,
      },
      {
        lat: 13.08313041501647,
        lng: 80.24751020025592,
      },
      {
        lat: 13.08495580887469,
        lng: 80.24800750505628,
      },
      {
        lat: 13.084415323065624,
        lng: 80.23743768731994,
      },
      {
        lat: 13.076072087942377,
        lng: 80.23382082185795,
      },
      {
        lat: 13.080610729632742,
        lng: 80.24127162954812,
      },
      {
        lat: 13.084920731917014,
        lng: 80.23659154353787,
      },
      {
        lat: 13.081305240838251,
        lng: 80.23284770925706,
      },
      {
        lat: 13.078740703175255,
        lng: 80.24556518472836,
      },
      {
        lat: 13.080758350818593,
        lng: 80.23289525873895,
      },
      {
        lat: 13.07909666055182,
        lng: 80.24445429440065,
      },
      {
        lat: 13.085667599788817,
        lng: 80.23660873339436,
      },
      {
        lat: 13.084360418768307,
        lng: 80.24753081919056,
      },
      {
        lat: 13.084219787367893,
        lng: 80.23713018357363,
      },
      {
        lat: 13.085239740418185,
        lng: 80.24257004832472,
      },
      {
        lat: 13.082189638752201,
        lng: 80.23939775720221,
      },
      {
        lat: 13.086239608118028,
        lng: 80.23579833959943,
      },
      {
        lat: 13.08184007356891,
        lng: 80.23470123974296,
      },
      {
        lat: 13.08451320608045,
        lng: 80.24719174167384,
      },
      {
        lat: 13.078002048104493,
        lng: 80.23643635529969,
      },
      {
        lat: 13.082708900611316,
        lng: 80.23912779838149,
      },
      {
        lat: 13.078992646072594,
        lng: 80.2396620707633,
      },
      {
        lat: 13.080160888362723,
        lng: 80.23172092308799,
      },
      {
        lat: 13.080670324132972,
        lng: 80.23845066641361,
      },
      {
        lat: 13.079165339624922,
        lng: 80.24157454803617,
      },
      {
        lat: 13.087888795056779,
        lng: 80.24803509599836,
      },
      {
        lat: 13.084889980831667,
        lng: 80.23756743055958,
      },
      {
        lat: 13.082250481035237,
        lng: 80.23905160465557,
      },
      {
        lat: 13.077264900190038,
        lng: 80.23966377250908,
      },
      {
        lat: 13.079754164523624,
        lng: 80.24204979272204,
      },
      {
        lat: 13.079142195341765,
        lng: 80.23393766754192,
      },
      {
        lat: 13.08646528039256,
        lng: 80.24674857569386,
      },
      {
        lat: 13.085723580669985,
        lng: 80.24987428249155,
      },
      {
        lat: 13.082420173086874,
        lng: 80.24377470241116,
      },
      {
        lat: 13.0790543687017,
        lng: 80.23142796868007,
      },
      {
        lat: 13.08276781447792,
        lng: 80.23688025418714,
      },
      {
        lat: 13.07855049807217,
        lng: 80.24022335435404,
      },
      {
        lat: 13.082390635687236,
        lng: 80.2470206038152,
      },
      {
        lat: 13.086405862571247,
        lng: 80.24565598419191,
      },
      {
        lat: 13.076478055388323,
        lng: 80.23059987996838,
      },
      {
        lat: 13.080106767307335,
        lng: 80.23055009021797,
      },
      {
        lat: 13.079021510432419,
        lng: 80.23534431047815,
      },
      {
        lat: 13.080018988249064,
        lng: 80.24270696134586,
      },
      {
        lat: 13.083457185601173,
        lng: 80.24753516661198,
      },
      {
        lat: 13.075258032321944,
        lng: 80.23239958972461,
      },
      {
        lat: 13.082063831019987,
        lng: 80.24895469391683,
      },
      {
        lat: 13.078499691681621,
        lng: 80.23514865437554,
      },
      {
        lat: 13.084268239388662,
        lng: 80.24796216905511,
      },
      {
        lat: 13.081526072147755,
        lng: 80.23908264887247,
      },
      {
        lat: 13.080800231299547,
        lng: 80.24872315769821,
      },
      {
        lat: 13.081472896603408,
        lng: 80.23457044920572,
      },
      {
        lat: 13.081691953317582,
        lng: 80.23670172966868,
      },
      {
        lat: 13.081803811490627,
        lng: 80.24488548032718,
      },
      {
        lat: 13.081261908586248,
        lng: 80.23866000022608,
      },
      {
        lat: 13.082314882672145,
        lng: 80.24154346688805,
      },
      {
        lat: 13.081835791937438,
        lng: 80.24104770404846,
      },
      {
        lat: 13.08181053765892,
        lng: 80.23211125770094,
      },
      {
        lat: 13.081323638370232,
        lng: 80.24017212551185,
      },
    ],
    cases: 112,
    coords: [
      {
        lat: 13.087859677274945,
        lng: 80.2345838615205,
      },
      {
        lat: 13.087881620423728,
        lng: 80.23478915431402,
      },
      {
        lat: 13.086743357436207,
        lng: 80.23477823987838,
      },
      {
        lat: 13.086578309980753,
        lng: 80.23486182811939,
      },
      {
        lat: 13.08657980965724,
        lng: 80.23510838963196,
      },
      {
        lat: 13.086706719574877,
        lng: 80.23555936826908,
      },
      {
        lat: 13.086627195200087,
        lng: 80.23609428541481,
      },
      {
        lat: 13.085747251077134,
        lng: 80.23772501279132,
      },
      {
        lat: 13.085380643277992,
        lng: 80.2386938644568,
      },
      {
        lat: 13.085226093557914,
        lng: 80.24050338328587,
      },
      {
        lat: 13.085462844192765,
        lng: 80.24200137268272,
      },
      {
        lat: 13.08591027820318,
        lng: 80.24411401452336,
      },
      {
        lat: 13.086038812770338,
        lng: 80.24483210146593,
      },
      {
        lat: 13.086634898292562,
        lng: 80.24482705663955,
      },
      {
        lat: 13.086654407670844,
        lng: 80.24524766582626,
      },
      {
        lat: 13.087229819950213,
        lng: 80.24624776531346,
      },
      {
        lat: 13.08792964224798,
        lng: 80.24690135367226,
      },
      {
        lat: 13.089103308191937,
        lng: 80.2474567158928,
      },
      {
        lat: 13.089455357189825,
        lng: 80.24781622338573,
      },
      {
        lat: 13.08911207777502,
        lng: 80.24824179598708,
      },
      {
        lat: 13.088153290057958,
        lng: 80.2488999693718,
      },
      {
        lat: 13.08686554227234,
        lng: 80.24929325551278,
      },
      {
        lat: 13.086038282372199,
        lng: 80.24941497310704,
      },
      {
        lat: 13.086044794332874,
        lng: 80.25048560326765,
      },
      {
        lat: 13.082798515079327,
        lng: 80.24988331860429,
      },
      {
        lat: 13.081705800639353,
        lng: 80.24969092746426,
      },
      {
        lat: 13.081452119503918,
        lng: 80.24971827931672,
      },
      {
        lat: 13.081123974309497,
        lng: 80.25002351509293,
      },
      {
        lat: 13.079499740534168,
        lng: 80.25008767117117,
      },
      {
        lat: 13.079170215663476,
        lng: 80.25016607467514,
      },
      {
        lat: 13.078891304381921,
        lng: 80.25021884494018,
      },
      {
        lat: 13.078712547197734,
        lng: 80.25004392362591,
      },
      {
        lat: 13.078425511156963,
        lng: 80.24876090384298,
      },
      {
        lat: 13.078056652784934,
        lng: 80.2465459958649,
      },
      {
        lat: 13.077312190957352,
        lng: 80.24100722213318,
      },
      {
        lat: 13.076740724930879,
        lng: 80.23886964352577,
      },
      {
        lat: 13.07579794777488,
        lng: 80.23411389956733,
      },
      {
        lat: 13.075442119676895,
        lng: 80.23404129638327,
      },
      {
        lat: 13.07457822701078,
        lng: 80.23044922127686,
      },
      {
        lat: 13.075438200260322,
        lng: 80.23039448696923,
      },
      {
        lat: 13.075872387128523,
        lng: 80.23032916878186,
      },
      {
        lat: 13.076864921375742,
        lng: 80.23019748160473,
      },
      {
        lat: 13.077113117423984,
        lng: 80.23017483320682,
      },
      {
        lat: 13.077320176581615,
        lng: 80.23019362866184,
      },
      {
        lat: 13.077693282978844,
        lng: 80.23029321021777,
      },
      {
        lat: 13.078563156391684,
        lng: 80.23040913535576,
      },
      {
        lat: 13.08127549388467,
        lng: 80.23063275434497,
      },
      {
        lat: 13.082952285643941,
        lng: 80.2307213024734,
      },
      {
        lat: 13.083097514492126,
        lng: 80.2307817169153,
      },
      {
        lat: 13.08318166286941,
        lng: 80.23100703109995,
      },
      {
        lat: 13.083253366048977,
        lng: 80.2325886087379,
      },
      {
        lat: 13.083443106059919,
        lng: 80.23316234273013,
      },
      {
        lat: 13.083487117330854,
        lng: 80.23359347510956,
      },
      {
        lat: 13.083549947424384,
        lng: 80.23371623046441,
      },
      {
        lat: 13.083674857773383,
        lng: 80.23383846041821,
      },
      {
        lat: 13.084379683730878,
        lng: 80.23403797379737,
      },
      {
        lat: 13.085664425297157,
        lng: 80.23431477060181,
      },
      {
        lat: 13.086597128803694,
        lng: 80.23455345109483,
      },
      {
        lat: 13.087859677274945,
        lng: 80.2345838615205,
      },
    ],
    properties: {
      Ward_No: 103,
      Zone_Name: "ANNANAGAR",
      fillColor: "#f6d04d",
      zname: "ANNANAGAR(103)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.076933329790844,
        lng: 80.22787222792653,
      },
      {
        lat: 13.078479432950212,
        lng: 80.22524739850452,
      },
      {
        lat: 13.085625035852892,
        lng: 80.22660313086932,
      },
      {
        lat: 13.08283424808335,
        lng: 80.23068100219704,
      },
      {
        lat: 13.07789819786392,
        lng: 80.22218161506999,
      },
      {
        lat: 13.075849645434786,
        lng: 80.22943497544966,
      },
      {
        lat: 13.09091570083988,
        lng: 80.22425826188551,
      },
      {
        lat: 13.086698786621126,
        lng: 80.22596197380715,
      },
      {
        lat: 13.086663375338949,
        lng: 80.2343524248206,
      },
      {
        lat: 13.078701596570324,
        lng: 80.22939142122463,
      },
      {
        lat: 13.087048341173745,
        lng: 80.22709188352634,
      },
      {
        lat: 13.076720223529321,
        lng: 80.22939580019703,
      },
      {
        lat: 13.076509143016352,
        lng: 80.22275705902804,
      },
      {
        lat: 13.074056070026963,
        lng: 80.22772264963872,
      },
      {
        lat: 13.089402319729356,
        lng: 80.22384604955188,
      },
      {
        lat: 13.086123968433695,
        lng: 80.22511413550554,
      },
      {
        lat: 13.084985253898443,
        lng: 80.22966577461447,
      },
      {
        lat: 13.088409856181629,
        lng: 80.23263106691178,
      },
      {
        lat: 13.08289839157675,
        lng: 80.22773963215892,
      },
      {
        lat: 13.084870247195536,
        lng: 80.23010897298687,
      },
      {
        lat: 13.085471704374568,
        lng: 80.22563160951067,
      },
      {
        lat: 13.086480646350788,
        lng: 80.228939154947,
      },
      {
        lat: 13.076312728460348,
        lng: 80.2292407077908,
      },
      {
        lat: 13.075244733663586,
        lng: 80.22864485036934,
      },
      {
        lat: 13.081670726989728,
        lng: 80.22914466363967,
      },
      {
        lat: 13.09055922612787,
        lng: 80.22531808383553,
      },
      {
        lat: 13.080131099438502,
        lng: 80.22634598008722,
      },
      {
        lat: 13.075429919398863,
        lng: 80.22461288866371,
      },
      {
        lat: 13.089547134811637,
        lng: 80.22472984123473,
      },
      {
        lat: 13.080447974403425,
        lng: 80.22538297859356,
      },
      {
        lat: 13.078282992486704,
        lng: 80.22915119191256,
      },
    ],
    cases: 31,
    coords: [
      {
        lat: 13.074301665847393,
        lng: 80.22182657998484,
      },
      {
        lat: 13.077497806242972,
        lng: 80.22178761326003,
      },
      {
        lat: 13.077623107963063,
        lng: 80.22188971717163,
      },
      {
        lat: 13.077810370347475,
        lng: 80.22192939807043,
      },
      {
        lat: 13.07799763273166,
        lng: 80.22196907896927,
      },
      {
        lat: 13.078040194742865,
        lng: 80.22213378175199,
      },
      {
        lat: 13.077856948043605,
        lng: 80.22275431885136,
      },
      {
        lat: 13.07775719399448,
        lng: 80.22343604795417,
      },
      {
        lat: 13.07753100881376,
        lng: 80.22382998683338,
      },
      {
        lat: 13.077490454645137,
        lng: 80.2239953930499,
      },
      {
        lat: 13.077658694846965,
        lng: 80.22432409518147,
      },
      {
        lat: 13.077867112746645,
        lng: 80.22442549565922,
      },
      {
        lat: 13.078281689723225,
        lng: 80.2242569239907,
      },
      {
        lat: 13.079091946983736,
        lng: 80.22422943369891,
      },
      {
        lat: 13.079509033763477,
        lng: 80.22447349827924,
      },
      {
        lat: 13.079739485609672,
        lng: 80.22478104102305,
      },
      {
        lat: 13.079866544192068,
        lng: 80.22517199030885,
      },
      {
        lat: 13.080012750447217,
        lng: 80.22529455017425,
      },
      {
        lat: 13.081155848896424,
        lng: 80.22532614158465,
      },
      {
        lat: 13.081613740824942,
        lng: 80.22544606357353,
      },
      {
        lat: 13.083712298868628,
        lng: 80.22540767005815,
      },
      {
        lat: 13.085199856769671,
        lng: 80.22399204500387,
      },
      {
        lat: 13.08567664539079,
        lng: 80.22380231394762,
      },
      {
        lat: 13.08848018507877,
        lng: 80.22351035949575,
      },
      {
        lat: 13.090246780364884,
        lng: 80.22355730696526,
      },
      {
        lat: 13.090933367277344,
        lng: 80.22369592632364,
      },
      {
        lat: 13.092014003101635,
        lng: 80.22370741349685,
      },
      {
        lat: 13.092389280811206,
        lng: 80.22391056616932,
      },
      {
        lat: 13.092932673232378,
        lng: 80.2244217891609,
      },
      {
        lat: 13.093730364054135,
        lng: 80.22515887050591,
      },
      {
        lat: 13.092580494574404,
        lng: 80.2263911168324,
      },
      {
        lat: 13.09232711496025,
        lng: 80.22677410711279,
      },
      {
        lat: 13.091944960847924,
        lng: 80.22700584889992,
      },
      {
        lat: 13.090268949276862,
        lng: 80.22892426273559,
      },
      {
        lat: 13.089438810189877,
        lng: 80.22986636222454,
      },
      {
        lat: 13.088776829228525,
        lng: 80.23145811342133,
      },
      {
        lat: 13.08842722055252,
        lng: 80.23262701246715,
      },
      {
        lat: 13.0881461358341,
        lng: 80.23404719303726,
      },
      {
        lat: 13.088086430066573,
        lng: 80.2344381075001,
      },
      {
        lat: 13.087859677274945,
        lng: 80.2345838615205,
      },
      {
        lat: 13.086597128803694,
        lng: 80.23455345109483,
      },
      {
        lat: 13.085664425297157,
        lng: 80.23431477060181,
      },
      {
        lat: 13.084379683730878,
        lng: 80.23403797379737,
      },
      {
        lat: 13.083674857773383,
        lng: 80.23383846041821,
      },
      {
        lat: 13.083549947424384,
        lng: 80.23371623046441,
      },
      {
        lat: 13.083487117330854,
        lng: 80.23359347510956,
      },
      {
        lat: 13.083443106059919,
        lng: 80.23316234273013,
      },
      {
        lat: 13.083253366048977,
        lng: 80.2325886087379,
      },
      {
        lat: 13.08318166286941,
        lng: 80.23100703109995,
      },
      {
        lat: 13.083097514492126,
        lng: 80.2307817169153,
      },
      {
        lat: 13.082952285643941,
        lng: 80.2307213024734,
      },
      {
        lat: 13.08127549388467,
        lng: 80.23063275434497,
      },
      {
        lat: 13.078563156391684,
        lng: 80.23040913535576,
      },
      {
        lat: 13.077693282978844,
        lng: 80.23029321021777,
      },
      {
        lat: 13.077320176581615,
        lng: 80.23019362866184,
      },
      {
        lat: 13.077113117423984,
        lng: 80.23017483320682,
      },
      {
        lat: 13.076864921375742,
        lng: 80.23019748160473,
      },
      {
        lat: 13.075872387128523,
        lng: 80.23032916878186,
      },
      {
        lat: 13.075438200260322,
        lng: 80.23039448696923,
      },
      {
        lat: 13.07457822701078,
        lng: 80.23044922127686,
      },
      {
        lat: 13.073905558998053,
        lng: 80.2276522603604,
      },
      {
        lat: 13.073890229263686,
        lng: 80.22513190177924,
      },
      {
        lat: 13.07418272009279,
        lng: 80.2231382405804,
      },
      {
        lat: 13.074301665847393,
        lng: 80.22182657998484,
      },
    ],
    properties: {
      Ward_No: 102,
      Zone_Name: "ANNANAGAR",
      fillColor: "#8bc24c",
      zname: "ANNANAGAR(102)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.0603642175223,
        lng: 80.213103314428,
      },
      {
        lat: 13.065783216090814,
        lng: 80.21975565795665,
      },
      {
        lat: 13.060269980159132,
        lng: 80.21930662192639,
      },
      {
        lat: 13.059963192353706,
        lng: 80.21384458659728,
      },
      {
        lat: 13.067933402754818,
        lng: 80.2182824735738,
      },
      {
        lat: 13.066620854376634,
        lng: 80.21524981074128,
      },
      {
        lat: 13.059640992733046,
        lng: 80.21651871139736,
      },
      {
        lat: 13.061819403237534,
        lng: 80.21814755929773,
      },
      {
        lat: 13.058929820831926,
        lng: 80.21231543659927,
      },
      {
        lat: 13.061760997360096,
        lng: 80.21455067482485,
      },
      {
        lat: 13.067384656710841,
        lng: 80.21516224545142,
      },
      {
        lat: 13.05956325724624,
        lng: 80.21557791321783,
      },
      {
        lat: 13.059460416633833,
        lng: 80.21711935278022,
      },
      {
        lat: 13.065724513693318,
        lng: 80.22077731160067,
      },
      {
        lat: 13.067315464639869,
        lng: 80.21710554954815,
      },
      {
        lat: 13.063857657728894,
        lng: 80.21491381835553,
      },
      {
        lat: 13.062878151233422,
        lng: 80.21774710945097,
      },
      {
        lat: 13.064835903248934,
        lng: 80.2190024997167,
      },
      {
        lat: 13.060703229281504,
        lng: 80.2199505953666,
      },
      {
        lat: 13.06204665913739,
        lng: 80.21712915522318,
      },
      {
        lat: 13.06809548537878,
        lng: 80.21652078490432,
      },
      {
        lat: 13.065628807198316,
        lng: 80.21737309244571,
      },
      {
        lat: 13.063664869778945,
        lng: 80.21888091581798,
      },
      {
        lat: 13.060036422442082,
        lng: 80.21826161430312,
      },
      {
        lat: 13.062800278631343,
        lng: 80.21767696923158,
      },
      {
        lat: 13.062755258716154,
        lng: 80.21896128777352,
      },
      {
        lat: 13.063172080605373,
        lng: 80.21856763221383,
      },
      {
        lat: 13.060307344599924,
        lng: 80.21436798455849,
      },
      {
        lat: 13.064184776998058,
        lng: 80.21660819691981,
      },
      {
        lat: 13.058838054398455,
        lng: 80.2181214018968,
      },
      {
        lat: 13.061554362064756,
        lng: 80.21545497434235,
      },
      {
        lat: 13.068249718876377,
        lng: 80.21629965479657,
      },
      {
        lat: 13.065349807582708,
        lng: 80.22156533403276,
      },
      {
        lat: 13.064872869348166,
        lng: 80.21615058360727,
      },
      {
        lat: 13.067780326543774,
        lng: 80.22084828107043,
      },
      {
        lat: 13.06029673605856,
        lng: 80.21833927309305,
      },
      {
        lat: 13.060525899267523,
        lng: 80.21630566443078,
      },
      {
        lat: 13.062940260778491,
        lng: 80.22130642188061,
      },
      {
        lat: 13.062992193986519,
        lng: 80.21912547423274,
      },
      {
        lat: 13.064604368691096,
        lng: 80.21530141214855,
      },
      {
        lat: 13.060017288902316,
        lng: 80.21436705459557,
      },
      {
        lat: 13.061638723580776,
        lng: 80.22136634875764,
      },
      {
        lat: 13.061776629263287,
        lng: 80.21316223989115,
      },
      {
        lat: 13.061381401330326,
        lng: 80.21826401297047,
      },
      {
        lat: 13.06633942339963,
        lng: 80.21736102073638,
      },
      {
        lat: 13.064817740890264,
        lng: 80.220093840457,
      },
      {
        lat: 13.066783959322002,
        lng: 80.22292928247597,
      },
      {
        lat: 13.059825103741522,
        lng: 80.21184756217794,
      },
      {
        lat: 13.060670838193815,
        lng: 80.22123337950252,
      },
      {
        lat: 13.067299463069357,
        lng: 80.21513346770888,
      },
      {
        lat: 13.061686490419227,
        lng: 80.21690255159963,
      },
      {
        lat: 13.065383542746531,
        lng: 80.21728420085175,
      },
      {
        lat: 13.066122936199879,
        lng: 80.2210813627212,
      },
      {
        lat: 13.063951387340083,
        lng: 80.21764320089106,
      },
      {
        lat: 13.059765035060812,
        lng: 80.21809854285576,
      },
      {
        lat: 13.068088217915204,
        lng: 80.21976296292742,
      },
      {
        lat: 13.064867536422064,
        lng: 80.21936961492702,
      },
      {
        lat: 13.06509766809434,
        lng: 80.21611805531387,
      },
      {
        lat: 13.059165922061926,
        lng: 80.21915463852797,
      },
      {
        lat: 13.059726488308586,
        lng: 80.21320745915806,
      },
      {
        lat: 13.06125227596879,
        lng: 80.22049417602351,
      },
      {
        lat: 13.061838784321177,
        lng: 80.21983244537594,
      },
      {
        lat: 13.06090727116299,
        lng: 80.21261807920999,
      },
      {
        lat: 13.06607602805834,
        lng: 80.22129568912247,
      },
      {
        lat: 13.063361011708857,
        lng: 80.21997699271678,
      },
      {
        lat: 13.060902801622419,
        lng: 80.21857643139262,
      },
      {
        lat: 13.060320931386677,
        lng: 80.21599230128125,
      },
      {
        lat: 13.061380448387498,
        lng: 80.21940096046457,
      },
      {
        lat: 13.06700368278547,
        lng: 80.21561649433315,
      },
      {
        lat: 13.065753588023814,
        lng: 80.21824282163301,
      },
      {
        lat: 13.063658364967957,
        lng: 80.21809872062761,
      },
      {
        lat: 13.058532486960022,
        lng: 80.21552116015953,
      },
      {
        lat: 13.061459090741298,
        lng: 80.215044684135,
      },
      {
        lat: 13.063320819325723,
        lng: 80.21508674201851,
      },
      {
        lat: 13.062783148495672,
        lng: 80.2158941545019,
      },
      {
        lat: 13.061158997389876,
        lng: 80.21945542122744,
      },
      {
        lat: 13.06080239763369,
        lng: 80.21358532025872,
      },
      {
        lat: 13.065106401161028,
        lng: 80.21483461782059,
      },
      {
        lat: 13.065373186980732,
        lng: 80.2216955555475,
      },
      {
        lat: 13.068562927333788,
        lng: 80.21954152367283,
      },
      {
        lat: 13.061725630507521,
        lng: 80.21248590222699,
      },
      {
        lat: 13.066805836943146,
        lng: 80.2163784747396,
      },
      {
        lat: 13.06805611051851,
        lng: 80.21655105654214,
      },
      {
        lat: 13.061426282125387,
        lng: 80.21454743036979,
      },
      {
        lat: 13.060509796842432,
        lng: 80.2159861920831,
      },
      {
        lat: 13.06296654230299,
        lng: 80.2158323423859,
      },
      {
        lat: 13.05989337388028,
        lng: 80.21838454597979,
      },
      {
        lat: 13.068171757697442,
        lng: 80.21540410313494,
      },
      {
        lat: 13.064329899983802,
        lng: 80.21825438417464,
      },
      {
        lat: 13.06664608733623,
        lng: 80.21716356996251,
      },
      {
        lat: 13.067714296104478,
        lng: 80.21485523631667,
      },
      {
        lat: 13.068623484525412,
        lng: 80.21955429014038,
      },
      {
        lat: 13.061778341103265,
        lng: 80.21324941236247,
      },
      {
        lat: 13.05888803936294,
        lng: 80.21357114538857,
      },
      {
        lat: 13.062107416067912,
        lng: 80.22118054439746,
      },
      {
        lat: 13.066361180306739,
        lng: 80.22059025270131,
      },
      {
        lat: 13.063322398180512,
        lng: 80.22031185428365,
      },
      {
        lat: 13.06074320975202,
        lng: 80.22138357326736,
      },
      {
        lat: 13.060942696739657,
        lng: 80.21984138117125,
      },
      {
        lat: 13.061168407547582,
        lng: 80.21474635365936,
      },
      {
        lat: 13.062071538316856,
        lng: 80.21782590777579,
      },
      {
        lat: 13.068222553018591,
        lng: 80.21482717317022,
      },
      {
        lat: 13.067830836123946,
        lng: 80.21662032671595,
      },
      {
        lat: 13.067998596382692,
        lng: 80.21775115234556,
      },
      {
        lat: 13.063207310112658,
        lng: 80.21896021340484,
      },
      {
        lat: 13.058918140498815,
        lng: 80.21371517322524,
      },
      {
        lat: 13.065263819601103,
        lng: 80.2188406778962,
      },
      {
        lat: 13.068859828664602,
        lng: 80.21713778465964,
      },
      {
        lat: 13.064954258382706,
        lng: 80.218026969022,
      },
      {
        lat: 13.067768399077075,
        lng: 80.2189313541914,
      },
      {
        lat: 13.062217183400682,
        lng: 80.21947901455768,
      },
      {
        lat: 13.062212990063667,
        lng: 80.21760456248556,
      },
      {
        lat: 13.059352512384338,
        lng: 80.21895033023354,
      },
      {
        lat: 13.062467007398764,
        lng: 80.21862307778355,
      },
      {
        lat: 13.062288328615347,
        lng: 80.21978595683879,
      },
      {
        lat: 13.066413907659857,
        lng: 80.22240651383493,
      },
      {
        lat: 13.06679155560303,
        lng: 80.21996196005574,
      },
      {
        lat: 13.068637656025832,
        lng: 80.21624835807594,
      },
      {
        lat: 13.067163122489795,
        lng: 80.21582184539385,
      },
      {
        lat: 13.062535470256812,
        lng: 80.21976250488154,
      },
      {
        lat: 13.058470027427354,
        lng: 80.21345898769226,
      },
      {
        lat: 13.065866171634998,
        lng: 80.22256653403007,
      },
      {
        lat: 13.059766783061773,
        lng: 80.2118212378597,
      },
      {
        lat: 13.068060183696554,
        lng: 80.21570586721734,
      },
      {
        lat: 13.05992015093631,
        lng: 80.21285592548739,
      },
      {
        lat: 13.066651638369574,
        lng: 80.22236629073042,
      },
      {
        lat: 13.063837137432408,
        lng: 80.22064778772011,
      },
      {
        lat: 13.058748090662341,
        lng: 80.212602478555,
      },
      {
        lat: 13.05925289911767,
        lng: 80.2157835573202,
      },
      {
        lat: 13.06337443520375,
        lng: 80.21538409226561,
      },
      {
        lat: 13.06564515630835,
        lng: 80.21951441732132,
      },
      {
        lat: 13.068027262887723,
        lng: 80.21686131728865,
      },
      {
        lat: 13.063241776751083,
        lng: 80.21822947064422,
      },
      {
        lat: 13.05946046155265,
        lng: 80.2185990731464,
      },
      {
        lat: 13.059151864988294,
        lng: 80.216124760771,
      },
      {
        lat: 13.061860274622058,
        lng: 80.21552772869538,
      },
      {
        lat: 13.067355157126453,
        lng: 80.21955008973322,
      },
      {
        lat: 13.064168250246457,
        lng: 80.21675816221159,
      },
      {
        lat: 13.065011617158062,
        lng: 80.22046905954376,
      },
      {
        lat: 13.059494014861382,
        lng: 80.21273904850544,
      },
      {
        lat: 13.059925970116153,
        lng: 80.21485040148708,
      },
      {
        lat: 13.059941302143601,
        lng: 80.22058763700073,
      },
      {
        lat: 13.068559693648082,
        lng: 80.2184129241495,
      },
      {
        lat: 13.063387573429308,
        lng: 80.22181653201574,
      },
      {
        lat: 13.058685915915818,
        lng: 80.21369637043966,
      },
      {
        lat: 13.063579455710213,
        lng: 80.21822603183499,
      },
      {
        lat: 13.060478154024256,
        lng: 80.2154723117881,
      },
      {
        lat: 13.060704537617292,
        lng: 80.21758918093687,
      },
      {
        lat: 13.067587547900741,
        lng: 80.2212048142283,
      },
      {
        lat: 13.063306988160774,
        lng: 80.21930326793253,
      },
      {
        lat: 13.06767392176195,
        lng: 80.21805254933862,
      },
      {
        lat: 13.059395069691753,
        lng: 80.21703061255728,
      },
      {
        lat: 13.063432817862724,
        lng: 80.21728293013894,
      },
      {
        lat: 13.062716286294618,
        lng: 80.21509895867695,
      },
      {
        lat: 13.064970575187258,
        lng: 80.22103546371295,
      },
      {
        lat: 13.065620933538066,
        lng: 80.21912296025684,
      },
      {
        lat: 13.063336892442294,
        lng: 80.22056552855136,
      },
      {
        lat: 13.061785315796985,
        lng: 80.21333035888274,
      },
    ],
    cases: 158,
    coords: [
      {
        lat: 13.067093798773032,
        lng: 80.22309704741747,
      },
      {
        lat: 13.06679131701335,
        lng: 80.22302069230432,
      },
      {
        lat: 13.06596912489433,
        lng: 80.22276338723422,
      },
      {
        lat: 13.06259405543698,
        lng: 80.22213129258442,
      },
      {
        lat: 13.060749667661137,
        lng: 80.22155230925239,
      },
      {
        lat: 13.06023796710898,
        lng: 80.2212923764117,
      },
      {
        lat: 13.059635545600988,
        lng: 80.22070288199693,
      },
      {
        lat: 13.059431122088453,
        lng: 80.21991182160056,
      },
      {
        lat: 13.059340936887212,
        lng: 80.21967034332414,
      },
      {
        lat: 13.059051417841738,
        lng: 80.21947459597743,
      },
      {
        lat: 13.058827361194163,
        lng: 80.21910211894222,
      },
      {
        lat: 13.058781130115236,
        lng: 80.21879420279667,
      },
      {
        lat: 13.058733291772512,
        lng: 80.21822203675885,
      },
      {
        lat: 13.058594504372898,
        lng: 80.21798433713023,
      },
      {
        lat: 13.05870177157752,
        lng: 80.21785468108753,
      },
      {
        lat: 13.058698508856756,
        lng: 80.21731825781558,
      },
      {
        lat: 13.05854084356334,
        lng: 80.21626814844268,
      },
      {
        lat: 13.058485225121272,
        lng: 80.21423010579201,
      },
      {
        lat: 13.058351780056165,
        lng: 80.21360895214534,
      },
      {
        lat: 13.05824111962196,
        lng: 80.2131807279851,
      },
      {
        lat: 13.058368039245597,
        lng: 80.21272903508773,
      },
      {
        lat: 13.058832796477144,
        lng: 80.21165268305141,
      },
      {
        lat: 13.061050208971823,
        lng: 80.21161443039249,
      },
      {
        lat: 13.061400984571797,
        lng: 80.21178509142261,
      },
      {
        lat: 13.06175030813809,
        lng: 80.21171702385594,
      },
      {
        lat: 13.062077774230938,
        lng: 80.21164914127493,
      },
      {
        lat: 13.062361657379952,
        lng: 80.2116033312645,
      },
      {
        lat: 13.062537441189338,
        lng: 80.21175376957854,
      },
      {
        lat: 13.062476752882839,
        lng: 80.21255732072346,
      },
      {
        lat: 13.062025402118294,
        lng: 80.21381995620001,
      },
      {
        lat: 13.061830268894088,
        lng: 80.21408205226554,
      },
      {
        lat: 13.061831456921784,
        lng: 80.21427737566276,
      },
      {
        lat: 13.06200750473716,
        lng: 80.21447121917615,
      },
      {
        lat: 13.06207439718829,
        lng: 80.21468769021669,
      },
      {
        lat: 13.062162751103868,
        lng: 80.21483886847261,
      },
      {
        lat: 13.062469679753862,
        lng: 80.21498819687376,
      },
      {
        lat: 13.06279727784978,
        lng: 80.2149420168926,
      },
      {
        lat: 13.062772912317858,
        lng: 80.21452985248389,
      },
      {
        lat: 13.068674694161137,
        lng: 80.21452331160732,
      },
      {
        lat: 13.068751882852348,
        lng: 80.21643258542353,
      },
      {
        lat: 13.068908185243503,
        lng: 80.21697385551738,
      },
      {
        lat: 13.068912409341975,
        lng: 80.21766833870737,
      },
      {
        lat: 13.068783112544278,
        lng: 80.2179732850158,
      },
      {
        lat: 13.068741773652762,
        lng: 80.21836430178108,
      },
      {
        lat: 13.068681481355434,
        lng: 80.21923296072495,
      },
      {
        lat: 13.068575758071084,
        lng: 80.21981985584387,
      },
      {
        lat: 13.068511901690675,
        lng: 80.22010254459624,
      },
      {
        lat: 13.068447253291854,
        lng: 80.22025501775042,
      },
      {
        lat: 13.068228414550886,
        lng: 80.22021346240571,
      },
      {
        lat: 13.068052894747824,
        lng: 80.22010642929104,
      },
      {
        lat: 13.067856309489596,
        lng: 80.22012979675988,
      },
      {
        lat: 13.067815366607318,
        lng: 80.22058592132424,
      },
      {
        lat: 13.067776403771187,
        lng: 80.22136758488405,
      },
      {
        lat: 13.067519394212558,
        lng: 80.22223790869701,
      },
      {
        lat: 13.067093798773032,
        lng: 80.22309704741747,
      },
    ],
    properties: {
      Ward_No: 108,
      Zone_Name: "ANNANAGAR",
      fillColor: "#de4307",
      zname: "ANNANAGAR(108)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.067997701935134,
        lng: 80.22044783556755,
      },
      {
        lat: 13.073151948746355,
        lng: 80.22155417162905,
      },
      {
        lat: 13.068035238182867,
        lng: 80.22450412416804,
      },
      {
        lat: 13.069355766523529,
        lng: 80.22822300540322,
      },
      {
        lat: 13.073180464968262,
        lng: 80.2258339324079,
      },
      {
        lat: 13.068832788043228,
        lng: 80.22876201956484,
      },
      {
        lat: 13.069039701211569,
        lng: 80.2249391083307,
      },
      {
        lat: 13.072201731931328,
        lng: 80.22567631296323,
      },
      {
        lat: 13.072682143604293,
        lng: 80.22117069836294,
      },
      {
        lat: 13.073081628510456,
        lng: 80.22962510928112,
      },
    ],
    cases: 10,
    coords: [
      {
        lat: 13.068714402872027,
        lng: 80.21550551250756,
      },
      {
        lat: 13.069921698389457,
        lng: 80.21551171544998,
      },
      {
        lat: 13.071255796885321,
        lng: 80.21572787371527,
      },
      {
        lat: 13.073568482301694,
        lng: 80.21591507275589,
      },
      {
        lat: 13.075102547529573,
        lng: 80.21623643124616,
      },
      {
        lat: 13.074998165803635,
        lng: 80.217070523551,
      },
      {
        lat: 13.074797026578219,
        lng: 80.21818709527335,
      },
      {
        lat: 13.074395381455929,
        lng: 80.22054033780189,
      },
      {
        lat: 13.074301665847393,
        lng: 80.22182657998484,
      },
      {
        lat: 13.07418272009279,
        lng: 80.2231382405804,
      },
      {
        lat: 13.073890229263686,
        lng: 80.22513190177924,
      },
      {
        lat: 13.073905558998053,
        lng: 80.2276522603604,
      },
      {
        lat: 13.074503798406074,
        lng: 80.23013974632846,
      },
      {
        lat: 13.07392620601025,
        lng: 80.23018125701435,
      },
      {
        lat: 13.073448632736543,
        lng: 80.2299181767856,
      },
      {
        lat: 13.072432780637048,
        lng: 80.22961855644931,
      },
      {
        lat: 13.069760455277533,
        lng: 80.22916857247287,
      },
      {
        lat: 13.068640261274604,
        lng: 80.2287260002565,
      },
      {
        lat: 13.06761938060582,
        lng: 80.22798885186684,
      },
      {
        lat: 13.067507095106444,
        lng: 80.22766126761229,
      },
      {
        lat: 13.067506425413267,
        lng: 80.2275511634903,
      },
      {
        lat: 13.067812898821076,
        lng: 80.22688791098831,
      },
      {
        lat: 13.068055383155809,
        lng: 80.22664361724328,
      },
      {
        lat: 13.067987375923675,
        lng: 80.2264019512689,
      },
      {
        lat: 13.067206593092722,
        lng: 80.22565981266685,
      },
      {
        lat: 13.066984411586215,
        lng: 80.22559562717285,
      },
      {
        lat: 13.066916538292716,
        lng: 80.22537598202271,
      },
      {
        lat: 13.067025017242646,
        lng: 80.22497866869452,
      },
      {
        lat: 13.06750382473489,
        lng: 80.2234771232833,
      },
      {
        lat: 13.067591063286219,
        lng: 80.22323414342343,
      },
      {
        lat: 13.06745732577852,
        lng: 80.22312516548915,
      },
      {
        lat: 13.067213368118802,
        lng: 80.2231272301661,
      },
      {
        lat: 13.067093798773032,
        lng: 80.22309704741747,
      },
      {
        lat: 13.067519394212558,
        lng: 80.22223790869701,
      },
      {
        lat: 13.067776403771187,
        lng: 80.22136758488405,
      },
      {
        lat: 13.067815366607318,
        lng: 80.22058592132424,
      },
      {
        lat: 13.067856309489596,
        lng: 80.22012979675988,
      },
      {
        lat: 13.068052894747824,
        lng: 80.22010642929104,
      },
      {
        lat: 13.068228414550886,
        lng: 80.22021346240571,
      },
      {
        lat: 13.068447253291854,
        lng: 80.22025501775042,
      },
      {
        lat: 13.068511901690675,
        lng: 80.22010254459624,
      },
      {
        lat: 13.068575758071084,
        lng: 80.21981985584387,
      },
      {
        lat: 13.068681481355434,
        lng: 80.21923296072495,
      },
      {
        lat: 13.068741773652762,
        lng: 80.21836430178108,
      },
      {
        lat: 13.068783112544278,
        lng: 80.2179732850158,
      },
      {
        lat: 13.068912409341975,
        lng: 80.21766833870737,
      },
      {
        lat: 13.068908185243503,
        lng: 80.21697385551738,
      },
      {
        lat: 13.068751882852348,
        lng: 80.21643258542353,
      },
      {
        lat: 13.068714402872027,
        lng: 80.21550551250756,
      },
    ],
    properties: {
      Ward_No: 106,
      Zone_Name: "ANNANAGAR",
      fillColor: "#8bc24c",
      zname: "ANNANAGAR(106)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.066833793420455,
        lng: 80.22886684978683,
      },
      {
        lat: 13.063336645026565,
        lng: 80.22640431315847,
      },
      {
        lat: 13.058476038549726,
        lng: 80.22245956801385,
      },
      {
        lat: 13.059064114069216,
        lng: 80.226689719025,
      },
      {
        lat: 13.058771017055788,
        lng: 80.22904756153785,
      },
      {
        lat: 13.06084779133776,
        lng: 80.22766952406485,
      },
      {
        lat: 13.064116842705674,
        lng: 80.22889450764207,
      },
      {
        lat: 13.059746127599166,
        lng: 80.2294490921987,
      },
      {
        lat: 13.066005122889912,
        lng: 80.22380925857463,
      },
      {
        lat: 13.064362234822381,
        lng: 80.23023622796327,
      },
      {
        lat: 13.062726039349936,
        lng: 80.22286307428949,
      },
      {
        lat: 13.06013622480576,
        lng: 80.22713023009251,
      },
      {
        lat: 13.06544090243526,
        lng: 80.22391107222282,
      },
    ],
    cases: 13,
    coords: [
      {
        lat: 13.060382201842504,
        lng: 80.22136564454436,
      },
      {
        lat: 13.060749667661137,
        lng: 80.22155230925239,
      },
      {
        lat: 13.06259405543698,
        lng: 80.22213129258442,
      },
      {
        lat: 13.06596912489433,
        lng: 80.22276338723422,
      },
      {
        lat: 13.06679131701335,
        lng: 80.22302069230432,
      },
      {
        lat: 13.067213368118802,
        lng: 80.2231272301661,
      },
      {
        lat: 13.06745732577852,
        lng: 80.22312516548915,
      },
      {
        lat: 13.067591063286219,
        lng: 80.22323414342343,
      },
      {
        lat: 13.06750382473489,
        lng: 80.2234771232833,
      },
      {
        lat: 13.067025017242646,
        lng: 80.22497866869452,
      },
      {
        lat: 13.066916538292716,
        lng: 80.22537598202271,
      },
      {
        lat: 13.066984411586215,
        lng: 80.22559562717285,
      },
      {
        lat: 13.067206593092722,
        lng: 80.22565981266685,
      },
      {
        lat: 13.067987375923675,
        lng: 80.2264019512689,
      },
      {
        lat: 13.068055383155809,
        lng: 80.22664361724328,
      },
      {
        lat: 13.067812898821076,
        lng: 80.22688791098831,
      },
      {
        lat: 13.067506425413267,
        lng: 80.2275511634903,
      },
      {
        lat: 13.067507095106444,
        lng: 80.22766126761229,
      },
      {
        lat: 13.067710313171265,
        lng: 80.2282541405892,
      },
      {
        lat: 13.068416983437176,
        lng: 80.23139729983961,
      },
      {
        lat: 13.06854163857433,
        lng: 80.23164084691622,
      },
      {
        lat: 13.067738470360204,
        lng: 80.23313204055006,
      },
      {
        lat: 13.066275550583065,
        lng: 80.23274751606444,
      },
      {
        lat: 13.066528640943657,
        lng: 80.23138673584864,
      },
      {
        lat: 13.063110284471643,
        lng: 80.23053026158613,
      },
      {
        lat: 13.061724883342501,
        lng: 80.23025194022779,
      },
      {
        lat: 13.061494462859326,
        lng: 80.23028442153279,
      },
      {
        lat: 13.061263949529964,
        lng: 80.230301638025,
      },
      {
        lat: 13.060986479350467,
        lng: 80.23018186154091,
      },
      {
        lat: 13.060125642474762,
        lng: 80.23020441262508,
      },
      {
        lat: 13.059032899577865,
        lng: 80.23001520801404,
      },
      {
        lat: 13.058340895359297,
        lng: 80.22999053342848,
      },
      {
        lat: 13.057739462380715,
        lng: 80.22969031154626,
      },
      {
        lat: 13.057646477141546,
        lng: 80.2295689737183,
      },
      {
        lat: 13.0576610152715,
        lng: 80.22943146029392,
      },
      {
        lat: 13.058285860896468,
        lng: 80.22852550176525,
      },
      {
        lat: 13.058712426220195,
        lng: 80.22788073650464,
      },
      {
        lat: 13.058693392765157,
        lng: 80.22475144994347,
      },
      {
        lat: 13.058472682413525,
        lng: 80.22385264757438,
      },
      {
        lat: 13.058247515448027,
        lng: 80.22222113420543,
      },
      {
        lat: 13.059541138337645,
        lng: 80.222576560298,
      },
      {
        lat: 13.059756556460314,
        lng: 80.22260526835508,
      },
      {
        lat: 13.059909643987742,
        lng: 80.22249711354777,
      },
      {
        lat: 13.060382201842504,
        lng: 80.22136564454436,
      },
    ],
    properties: {
      Ward_No: 109,
      Zone_Name: "TEYNAMPET",
      fillColor: "#8bc24c",
      zname: "TEYNAMPET(109)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.057611818570598,
        lng: 80.2312260573977,
      },
      {
        lat: 13.058528591285448,
        lng: 80.22613925241473,
      },
      {
        lat: 13.063139153885427,
        lng: 80.23186255525164,
      },
      {
        lat: 13.058687662778684,
        lng: 80.21877130383501,
      },
      {
        lat: 13.055693047982098,
        lng: 80.22607167241891,
      },
      {
        lat: 13.055928493813898,
        lng: 80.22577493265038,
      },
      {
        lat: 13.060258124091863,
        lng: 80.23020327384297,
      },
      {
        lat: 13.053949205962734,
        lng: 80.21811129706224,
      },
      {
        lat: 13.058656366155565,
        lng: 80.2188924673094,
      },
      {
        lat: 13.063272183332552,
        lng: 80.23163972550563,
      },
      {
        lat: 13.055107509093979,
        lng: 80.23016043556535,
      },
      {
        lat: 13.055392185083901,
        lng: 80.22294728317769,
      },
      {
        lat: 13.05672283083248,
        lng: 80.22699782898034,
      },
      {
        lat: 13.056407139077953,
        lng: 80.22192281932426,
      },
      {
        lat: 13.055006445955698,
        lng: 80.22398262738604,
      },
      {
        lat: 13.056136075911734,
        lng: 80.21883486652291,
      },
      {
        lat: 13.0594521436305,
        lng: 80.22223719212158,
      },
      {
        lat: 13.056325823957305,
        lng: 80.22036161985966,
      },
      {
        lat: 13.055680525864824,
        lng: 80.22987474638855,
      },
      {
        lat: 13.056094717291028,
        lng: 80.2233438419027,
      },
      {
        lat: 13.061100004502483,
        lng: 80.23121603449971,
      },
      {
        lat: 13.0633595294,
        lng: 80.23073296730774,
      },
      {
        lat: 13.058068256329424,
        lng: 80.21851953996118,
      },
      {
        lat: 13.054874047833723,
        lng: 80.22392361509304,
      },
      {
        lat: 13.05825360326818,
        lng: 80.22257847671196,
      },
      {
        lat: 13.06385503748351,
        lng: 80.23167463096031,
      },
      {
        lat: 13.057081381784617,
        lng: 80.23076725728151,
      },
      {
        lat: 13.05402268307506,
        lng: 80.22277047387716,
      },
      {
        lat: 13.055361959189984,
        lng: 80.22178146810427,
      },
      {
        lat: 13.05642315090281,
        lng: 80.22209583035021,
      },
      {
        lat: 13.055327224662573,
        lng: 80.22928671705861,
      },
      {
        lat: 13.053998413642573,
        lng: 80.22085241537451,
      },
      {
        lat: 13.053979615734232,
        lng: 80.21999657201235,
      },
      {
        lat: 13.055177346834233,
        lng: 80.22531419000906,
      },
      {
        lat: 13.058438827455507,
        lng: 80.22503095630118,
      },
      {
        lat: 13.05534440946649,
        lng: 80.2240631338343,
      },
      {
        lat: 13.058998660294451,
        lng: 80.2314385958846,
      },
      {
        lat: 13.055228002805496,
        lng: 80.22794705593265,
      },
      {
        lat: 13.060038986975178,
        lng: 80.23130347334425,
      },
      {
        lat: 13.057449205195867,
        lng: 80.22610250153431,
      },
      {
        lat: 13.058766627869412,
        lng: 80.21920069629871,
      },
      {
        lat: 13.053945555008529,
        lng: 80.22515026611623,
      },
      {
        lat: 13.054991533414425,
        lng: 80.22912047245697,
      },
      {
        lat: 13.054514893326408,
        lng: 80.22402460271445,
      },
      {
        lat: 13.055510370687356,
        lng: 80.222541878749,
      },
      {
        lat: 13.057328426022169,
        lng: 80.22368150538371,
      },
      {
        lat: 13.05564067842578,
        lng: 80.23046794419054,
      },
      {
        lat: 13.054327352575491,
        lng: 80.220840154312,
      },
      {
        lat: 13.057627077578582,
        lng: 80.22270370995508,
      },
      {
        lat: 13.053679783335303,
        lng: 80.22092174738405,
      },
      {
        lat: 13.056194388797543,
        lng: 80.2209420202736,
      },
      {
        lat: 13.056948694431671,
        lng: 80.23121464189367,
      },
      {
        lat: 13.055938604187222,
        lng: 80.22364541551083,
      },
      {
        lat: 13.065481610758894,
        lng: 80.23236574028527,
      },
      {
        lat: 13.056210764765682,
        lng: 80.22188035287027,
      },
      {
        lat: 13.053530163467718,
        lng: 80.21865357922925,
      },
      {
        lat: 13.056650665631498,
        lng: 80.22698205725361,
      },
      {
        lat: 13.059845436100083,
        lng: 80.22126018878703,
      },
      {
        lat: 13.054262646551292,
        lng: 80.22227457410723,
      },
      {
        lat: 13.057376210128668,
        lng: 80.23011593912409,
      },
      {
        lat: 13.057354995221006,
        lng: 80.2205459947927,
      },
      {
        lat: 13.059793237327485,
        lng: 80.22147125334462,
      },
      {
        lat: 13.057255493500035,
        lng: 80.22085080064203,
      },
      {
        lat: 13.06605359809289,
        lng: 80.23238600069774,
      },
      {
        lat: 13.057537010127556,
        lng: 80.22281892374343,
      },
      {
        lat: 13.055739484770093,
        lng: 80.22759400679256,
      },
      {
        lat: 13.056745042231071,
        lng: 80.22554865959367,
      },
      {
        lat: 13.05339988331364,
        lng: 80.22293170177716,
      },
      {
        lat: 13.05513748784767,
        lng: 80.22910530070016,
      },
      {
        lat: 13.05524052666198,
        lng: 80.22726392779953,
      },
      {
        lat: 13.056987202271648,
        lng: 80.23019800439772,
      },
      {
        lat: 13.055457942594506,
        lng: 80.21922309050555,
      },
      {
        lat: 13.056063719787158,
        lng: 80.2250281372956,
      },
      {
        lat: 13.056897136694333,
        lng: 80.22064400979512,
      },
      {
        lat: 13.06381088755531,
        lng: 80.2313468112389,
      },
      {
        lat: 13.054714943379956,
        lng: 80.22330775577616,
      },
      {
        lat: 13.058341134332284,
        lng: 80.22750802038107,
      },
      {
        lat: 13.055051325668149,
        lng: 80.22897579625294,
      },
      {
        lat: 13.05803598755957,
        lng: 80.2244100867567,
      },
      {
        lat: 13.059083408352757,
        lng: 80.22117344937094,
      },
      {
        lat: 13.060535890599263,
        lng: 80.230900811341,
      },
      {
        lat: 13.055286802541701,
        lng: 80.22555279616031,
      },
      {
        lat: 13.055915262146662,
        lng: 80.22922902543398,
      },
      {
        lat: 13.05597064969799,
        lng: 80.22322810078198,
      },
      {
        lat: 13.054296928956665,
        lng: 80.2234922724605,
      },
      {
        lat: 13.052434150349683,
        lng: 80.21774782182436,
      },
      {
        lat: 13.058922065959985,
        lng: 80.21995621182805,
      },
      {
        lat: 13.054608912730483,
        lng: 80.21850448008469,
      },
      {
        lat: 13.053727061535813,
        lng: 80.22216743978458,
      },
    ],
    cases: 89,
    coords: [
      {
        lat: 13.066275550583065,
        lng: 80.23274751606444,
      },
      {
        lat: 13.059768212394179,
        lng: 80.23155699141215,
      },
      {
        lat: 13.058410859223374,
        lng: 80.23154305166116,
      },
      {
        lat: 13.057259448760535,
        lng: 80.23170536064404,
      },
      {
        lat: 13.055768954523716,
        lng: 80.23085344406415,
      },
      {
        lat: 13.05423300698859,
        lng: 80.23081846556205,
      },
      {
        lat: 13.054219512928913,
        lng: 80.2285999098925,
      },
      {
        lat: 13.05381566117503,
        lng: 80.2255430934866,
      },
      {
        lat: 13.052078730324686,
        lng: 80.21867226638994,
      },
      {
        lat: 13.0512609968847,
        lng: 80.21678037841674,
      },
      {
        lat: 13.052838530646126,
        lng: 80.21676702733944,
      },
      {
        lat: 13.05294736395671,
        lng: 80.2168948544673,
      },
      {
        lat: 13.053082375127785,
        lng: 80.2177734912846,
      },
      {
        lat: 13.053234689559206,
        lng: 80.21794386649135,
      },
      {
        lat: 13.054056263043565,
        lng: 80.21800128740811,
      },
      {
        lat: 13.05429462615457,
        lng: 80.21810656025635,
      },
      {
        lat: 13.054317671803181,
        lng: 80.2183424036045,
      },
      {
        lat: 13.054490682724264,
        lng: 80.2183623974037,
      },
      {
        lat: 13.05570136764503,
        lng: 80.2184379832047,
      },
      {
        lat: 13.056155439744748,
        lng: 80.21847705634572,
      },
      {
        lat: 13.056283794965461,
        lng: 80.21826138968814,
      },
      {
        lat: 13.056281967841839,
        lng: 80.21796099265572,
      },
      {
        lat: 13.056410845098103,
        lng: 80.2178311537217,
      },
      {
        lat: 13.056669774189936,
        lng: 80.21776458823138,
      },
      {
        lat: 13.056972445420042,
        lng: 80.21778348468165,
      },
      {
        lat: 13.057556047320178,
        lng: 80.21780000354296,
      },
      {
        lat: 13.058313704211757,
        lng: 80.21800817165024,
      },
      {
        lat: 13.058594504372898,
        lng: 80.21798433713023,
      },
      {
        lat: 13.058733291772512,
        lng: 80.21822203675885,
      },
      {
        lat: 13.058781130115236,
        lng: 80.21879420279667,
      },
      {
        lat: 13.058827361194163,
        lng: 80.21910211894222,
      },
      {
        lat: 13.059051417841738,
        lng: 80.21947459597743,
      },
      {
        lat: 13.059340936887212,
        lng: 80.21967034332414,
      },
      {
        lat: 13.059431122088453,
        lng: 80.21991182160056,
      },
      {
        lat: 13.059635545600988,
        lng: 80.22070288199693,
      },
      {
        lat: 13.06023796710898,
        lng: 80.2212923764117,
      },
      {
        lat: 13.060382201842504,
        lng: 80.22136564454436,
      },
      {
        lat: 13.059909643987742,
        lng: 80.22249711354777,
      },
      {
        lat: 13.059756556460314,
        lng: 80.22260526835508,
      },
      {
        lat: 13.059541138337645,
        lng: 80.222576560298,
      },
      {
        lat: 13.058247515448027,
        lng: 80.22222113420543,
      },
      {
        lat: 13.058472682413525,
        lng: 80.22385264757438,
      },
      {
        lat: 13.058693392765157,
        lng: 80.22475144994347,
      },
      {
        lat: 13.058712426220195,
        lng: 80.22788073650464,
      },
      {
        lat: 13.058285860896468,
        lng: 80.22852550176525,
      },
      {
        lat: 13.0576610152715,
        lng: 80.22943146029392,
      },
      {
        lat: 13.057646477141546,
        lng: 80.2295689737183,
      },
      {
        lat: 13.057739462380715,
        lng: 80.22969031154626,
      },
      {
        lat: 13.058340895359297,
        lng: 80.22999053342848,
      },
      {
        lat: 13.059032899577865,
        lng: 80.23001520801404,
      },
      {
        lat: 13.060125642474762,
        lng: 80.23020441262508,
      },
      {
        lat: 13.060986479350467,
        lng: 80.23018186154091,
      },
      {
        lat: 13.061263949529964,
        lng: 80.230301638025,
      },
      {
        lat: 13.061494462859326,
        lng: 80.23028442153279,
      },
      {
        lat: 13.061724883342501,
        lng: 80.23025194022779,
      },
      {
        lat: 13.063110284471643,
        lng: 80.23053026158613,
      },
      {
        lat: 13.066528640943657,
        lng: 80.23138673584864,
      },
      {
        lat: 13.066275550583065,
        lng: 80.23274751606444,
      },
    ],
    properties: {
      Ward_No: 112,
      Zone_Name: "TEYNAMPET",
      fillColor: "#f6d04d",
      zname: "TEYNAMPET(112)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.055319752747351,
        lng: 80.23115237145791,
      },
      {
        lat: 13.058520729895449,
        lng: 80.23390144922404,
      },
      {
        lat: 13.055383942521942,
        lng: 80.2332157902934,
      },
      {
        lat: 13.064127332022,
        lng: 80.2342230147596,
      },
      {
        lat: 13.06817239218732,
        lng: 80.23363374936255,
      },
      {
        lat: 13.069584856389096,
        lng: 80.23343123514486,
      },
      {
        lat: 13.064952176785258,
        lng: 80.23948285770479,
      },
      {
        lat: 13.066116872406566,
        lng: 80.23931829977721,
      },
      {
        lat: 13.053281267878916,
        lng: 80.24148658460112,
      },
      {
        lat: 13.06148226225677,
        lng: 80.2343594275774,
      },
      {
        lat: 13.0641182774156,
        lng: 80.23845822561441,
      },
      {
        lat: 13.070248388964023,
        lng: 80.2326522951206,
      },
      {
        lat: 13.063388378850268,
        lng: 80.24199203512994,
      },
      {
        lat: 13.065095170558754,
        lng: 80.23822627244273,
      },
      {
        lat: 13.068122916298371,
        lng: 80.23952480423021,
      },
      {
        lat: 13.055468578753452,
        lng: 80.23250973278586,
      },
      {
        lat: 13.053945150586028,
        lng: 80.2335209049363,
      },
      {
        lat: 13.06213432044176,
        lng: 80.24186841713563,
      },
      {
        lat: 13.06254761508817,
        lng: 80.2332731900476,
      },
      {
        lat: 13.061547961907221,
        lng: 80.23926429501795,
      },
      {
        lat: 13.065836889982794,
        lng: 80.23712222748601,
      },
      {
        lat: 13.057779769813312,
        lng: 80.23565263611927,
      },
      {
        lat: 13.061874099958173,
        lng: 80.24188011133654,
      },
      {
        lat: 13.068309290206777,
        lng: 80.2402126792231,
      },
      {
        lat: 13.068937370291414,
        lng: 80.23359663449001,
      },
      {
        lat: 13.05837341685226,
        lng: 80.23459388885423,
      },
      {
        lat: 13.068814503658334,
        lng: 80.23428652225769,
      },
      {
        lat: 13.05615633342459,
        lng: 80.23364670657358,
      },
      {
        lat: 13.054258946214384,
        lng: 80.23136167666665,
      },
      {
        lat: 13.057693020309056,
        lng: 80.23181177285235,
      },
      {
        lat: 13.061221218558076,
        lng: 80.23538981463915,
      },
      {
        lat: 13.064965761465906,
        lng: 80.2374603709443,
      },
      {
        lat: 13.054600339716854,
        lng: 80.2386213864379,
      },
      {
        lat: 13.06521018158766,
        lng: 80.23868387797074,
      },
      {
        lat: 13.05577645520798,
        lng: 80.23969399631326,
      },
      {
        lat: 13.06732923438514,
        lng: 80.23988197036952,
      },
      {
        lat: 13.059677616925812,
        lng: 80.23219525177213,
      },
      {
        lat: 13.066307369914357,
        lng: 80.23567591540196,
      },
      {
        lat: 13.061460460645261,
        lng: 80.23694885439872,
      },
      {
        lat: 13.069549949189545,
        lng: 80.23402872942538,
      },
      {
        lat: 13.064482203459322,
        lng: 80.23268585143072,
      },
      {
        lat: 13.059898802504192,
        lng: 80.23485469713094,
      },
      {
        lat: 13.056334685381344,
        lng: 80.23537934527998,
      },
      {
        lat: 13.05283884705882,
        lng: 80.24086738968175,
      },
      {
        lat: 13.061521911350297,
        lng: 80.23600718083165,
      },
      {
        lat: 13.063148652338137,
        lng: 80.240347809798,
      },
      {
        lat: 13.054603490029514,
        lng: 80.2389533007755,
      },
      {
        lat: 13.066122467444956,
        lng: 80.2402732500471,
      },
      {
        lat: 13.062775419379049,
        lng: 80.24177852891634,
      },
      {
        lat: 13.059063086551394,
        lng: 80.23728579195857,
      },
      {
        lat: 13.067312215626623,
        lng: 80.23683222997754,
      },
      {
        lat: 13.060707550542283,
        lng: 80.23336950395182,
      },
      {
        lat: 13.053712410810157,
        lng: 80.24034423032155,
      },
      {
        lat: 13.058427762921033,
        lng: 80.23235235735999,
      },
      {
        lat: 13.061922065123651,
        lng: 80.23959277094089,
      },
      {
        lat: 13.058286011087311,
        lng: 80.23181796644155,
      },
      {
        lat: 13.068892169996571,
        lng: 80.24127131387813,
      },
      {
        lat: 13.064854273573706,
        lng: 80.23502881900978,
      },
      {
        lat: 13.061892316556763,
        lng: 80.23544886724491,
      },
      {
        lat: 13.063480639793758,
        lng: 80.23429976129584,
      },
      {
        lat: 13.065948532166248,
        lng: 80.2348904144105,
      },
      {
        lat: 13.053106319529881,
        lng: 80.24069330812878,
      },
      {
        lat: 13.053629280897487,
        lng: 80.24065482644528,
      },
      {
        lat: 13.064385301054937,
        lng: 80.23902936072261,
      },
      {
        lat: 13.069241694331193,
        lng: 80.23727879661692,
      },
      {
        lat: 13.067448141132981,
        lng: 80.2333883489386,
      },
      {
        lat: 13.054298220331232,
        lng: 80.23748093342643,
      },
      {
        lat: 13.068752091789259,
        lng: 80.23454962205332,
      },
      {
        lat: 13.062982538376302,
        lng: 80.24211215702728,
      },
      {
        lat: 13.056849521007456,
        lng: 80.23727582189578,
      },
      {
        lat: 13.059435492268575,
        lng: 80.23242703372843,
      },
      {
        lat: 13.068687182536326,
        lng: 80.2353255199127,
      },
      {
        lat: 13.061978994883216,
        lng: 80.24171020899018,
      },
      {
        lat: 13.068547838560056,
        lng: 80.23751843150974,
      },
      {
        lat: 13.059551396533083,
        lng: 80.23566877107021,
      },
      {
        lat: 13.060895235216744,
        lng: 80.23643997269112,
      },
      {
        lat: 13.056270433336259,
        lng: 80.24019340768582,
      },
      {
        lat: 13.062196938149755,
        lng: 80.23891163653761,
      },
      {
        lat: 13.059412391411229,
        lng: 80.23179857774754,
      },
      {
        lat: 13.068641258218642,
        lng: 80.23255076525712,
      },
      {
        lat: 13.061052643083153,
        lng: 80.24115045226027,
      },
      {
        lat: 13.054240311575974,
        lng: 80.23418408412412,
      },
      {
        lat: 13.067878516071488,
        lng: 80.24061486544726,
      },
      {
        lat: 13.053093753743653,
        lng: 80.23858587821499,
      },
      {
        lat: 13.053711607426607,
        lng: 80.23603301063996,
      },
      {
        lat: 13.058610474623896,
        lng: 80.23645331996943,
      },
      {
        lat: 13.05539046499345,
        lng: 80.23524275742554,
      },
      {
        lat: 13.063110232707517,
        lng: 80.24140787044558,
      },
      {
        lat: 13.058454167321367,
        lng: 80.23690843037365,
      },
      {
        lat: 13.062449034179838,
        lng: 80.23436051622244,
      },
      {
        lat: 13.066070047164406,
        lng: 80.24348506514995,
      },
      {
        lat: 13.062122964630749,
        lng: 80.23584686615963,
      },
      {
        lat: 13.054973847806291,
        lng: 80.23534097816935,
      },
      {
        lat: 13.054542370463745,
        lng: 80.23690851312668,
      },
      {
        lat: 13.05830610989356,
        lng: 80.23972355637407,
      },
      {
        lat: 13.067229956584558,
        lng: 80.24192786483277,
      },
      {
        lat: 13.062383811429742,
        lng: 80.23360061304912,
      },
      {
        lat: 13.065082605489177,
        lng: 80.2425758595072,
      },
      {
        lat: 13.057711546611246,
        lng: 80.23923931967252,
      },
      {
        lat: 13.055372222326856,
        lng: 80.23925111505237,
      },
      {
        lat: 13.059450361210061,
        lng: 80.23476856985774,
      },
      {
        lat: 13.055379326987174,
        lng: 80.23896779713371,
      },
      {
        lat: 13.056017173373588,
        lng: 80.23715114190284,
      },
      {
        lat: 13.063718642404062,
        lng: 80.23924421682436,
      },
      {
        lat: 13.063257395280099,
        lng: 80.2418979316606,
      },
      {
        lat: 13.061104662245754,
        lng: 80.23635421152117,
      },
      {
        lat: 13.062746194459807,
        lng: 80.23502121740934,
      },
      {
        lat: 13.053742213522709,
        lng: 80.23576825008162,
      },
      {
        lat: 13.067390253340855,
        lng: 80.23651460367284,
      },
      {
        lat: 13.06248735267493,
        lng: 80.23569268088413,
      },
      {
        lat: 13.055015425180605,
        lng: 80.23921593887016,
      },
      {
        lat: 13.058112942382609,
        lng: 80.24052132587005,
      },
      {
        lat: 13.058875267698065,
        lng: 80.23283682448643,
      },
      {
        lat: 13.068391942120218,
        lng: 80.23466112853846,
      },
      {
        lat: 13.063665659497072,
        lng: 80.24283934810111,
      },
      {
        lat: 13.067390879614306,
        lng: 80.23936058585323,
      },
      {
        lat: 13.056646671228476,
        lng: 80.23898838552259,
      },
      {
        lat: 13.055314936111271,
        lng: 80.23095700357773,
      },
      {
        lat: 13.058183555439243,
        lng: 80.23994958646924,
      },
      {
        lat: 13.05420537010044,
        lng: 80.24112785847001,
      },
      {
        lat: 13.055451415657446,
        lng: 80.24079482314403,
      },
      {
        lat: 13.063178310383801,
        lng: 80.2340829116291,
      },
      {
        lat: 13.068416793572956,
        lng: 80.2419209120438,
      },
      {
        lat: 13.06188914425854,
        lng: 80.23307718097882,
      },
      {
        lat: 13.065143654360186,
        lng: 80.23314482086893,
      },
      {
        lat: 13.060067608771595,
        lng: 80.23390027328911,
      },
      {
        lat: 13.066823532508469,
        lng: 80.24292386820227,
      },
      {
        lat: 13.056903649343635,
        lng: 80.23661201974971,
      },
      {
        lat: 13.069056505827504,
        lng: 80.2415343649921,
      },
      {
        lat: 13.054512023663117,
        lng: 80.23986481398425,
      },
      {
        lat: 13.061108900926165,
        lng: 80.23694421016701,
      },
      {
        lat: 13.066920896036896,
        lng: 80.23597542418425,
      },
      {
        lat: 13.062320719958214,
        lng: 80.24501016031303,
      },
      {
        lat: 13.065283000960354,
        lng: 80.23754226195797,
      },
      {
        lat: 13.056156602047363,
        lng: 80.24182551824906,
      },
      {
        lat: 13.06526304307552,
        lng: 80.234447922688,
      },
      {
        lat: 13.058280590880281,
        lng: 80.23161325968212,
      },
      {
        lat: 13.068957006665748,
        lng: 80.23259558767354,
      },
      {
        lat: 13.067245458967196,
        lng: 80.2374885423212,
      },
      {
        lat: 13.054750828524732,
        lng: 80.233319621543,
      },
      {
        lat: 13.064998442241318,
        lng: 80.23589965626265,
      },
      {
        lat: 13.065226380733751,
        lng: 80.23472517477553,
      },
      {
        lat: 13.054316302437801,
        lng: 80.23989878724723,
      },
      {
        lat: 13.057919994490558,
        lng: 80.24076997106732,
      },
      {
        lat: 13.061235562533488,
        lng: 80.23756984062622,
      },
      {
        lat: 13.063438580658014,
        lng: 80.24147729537758,
      },
      {
        lat: 13.060981846969579,
        lng: 80.23545313130025,
      },
      {
        lat: 13.055831411129462,
        lng: 80.2329336412718,
      },
      {
        lat: 13.069090314942526,
        lng: 80.24157449229958,
      },
      {
        lat: 13.06374401417233,
        lng: 80.2352292999652,
      },
      {
        lat: 13.066877330927293,
        lng: 80.23492345808948,
      },
      {
        lat: 13.056822548655948,
        lng: 80.23174770306738,
      },
      {
        lat: 13.068545616249553,
        lng: 80.2362050690037,
      },
      {
        lat: 13.059591960209856,
        lng: 80.23862004314083,
      },
      {
        lat: 13.066508117691507,
        lng: 80.2367350028576,
      },
      {
        lat: 13.063244914508472,
        lng: 80.23851735655731,
      },
      {
        lat: 13.057569858828815,
        lng: 80.2322420013131,
      },
      {
        lat: 13.067858701038999,
        lng: 80.23546117674435,
      },
      {
        lat: 13.066483516276197,
        lng: 80.24307770913812,
      },
      {
        lat: 13.063778239863636,
        lng: 80.24357099954491,
      },
      {
        lat: 13.05677397341002,
        lng: 80.23323849652144,
      },
      {
        lat: 13.064896947356866,
        lng: 80.24087432934685,
      },
      {
        lat: 13.056285315667095,
        lng: 80.23830227700795,
      },
    ],
    cases: 163,
    coords: [
      {
        lat: 13.068497551036165,
        lng: 80.24272161815699,
      },
      {
        lat: 13.06819002251057,
        lng: 80.24272443733113,
      },
      {
        lat: 13.066115088059691,
        lng: 80.24360820462745,
      },
      {
        lat: 13.063587935119617,
        lng: 80.24369622385416,
      },
      {
        lat: 13.063277352929763,
        lng: 80.24412085047314,
      },
      {
        lat: 13.062923386045906,
        lng: 80.2447679485211,
      },
      {
        lat: 13.062299519968304,
        lng: 80.24517301611759,
      },
      {
        lat: 13.061898250927802,
        lng: 80.24539851641482,
      },
      {
        lat: 13.061651800185118,
        lng: 80.24533397091538,
      },
      {
        lat: 13.0616508545908,
        lng: 80.24517850594087,
      },
      {
        lat: 13.061783710371612,
        lng: 80.24495527729462,
      },
      {
        lat: 13.061751076147429,
        lng: 80.24326756116139,
      },
      {
        lat: 13.061415154318107,
        lng: 80.24320377287894,
      },
      {
        lat: 13.060899614891216,
        lng: 80.24303045262008,
      },
      {
        lat: 13.06054146037525,
        lng: 80.24298906292402,
      },
      {
        lat: 13.060473141296198,
        lng: 80.24278974729808,
      },
      {
        lat: 13.060560721194024,
        lng: 80.24247806013209,
      },
      {
        lat: 13.060557614241254,
        lng: 80.24196724664444,
      },
      {
        lat: 13.060599648086567,
        lng: 80.24152268239449,
      },
      {
        lat: 13.060710271180682,
        lng: 80.24132185233455,
      },
      {
        lat: 13.06070919050146,
        lng: 80.24114417807782,
      },
      {
        lat: 13.060573768107483,
        lng: 80.2409454303648,
      },
      {
        lat: 13.060560124532277,
        lng: 80.23870229287543,
      },
      {
        lat: 13.06040138877243,
        lng: 80.23834826949204,
      },
      {
        lat: 13.06024508454079,
        lng: 80.238394013186,
      },
      {
        lat: 13.059402944142942,
        lng: 80.23968934510772,
      },
      {
        lat: 13.059114864809711,
        lng: 80.24013599170459,
      },
      {
        lat: 13.05878029382959,
        lng: 80.24029429624287,
      },
      {
        lat: 13.058491403986766,
        lng: 80.24060768714709,
      },
      {
        lat: 13.058335910264601,
        lng: 80.24078668653334,
      },
      {
        lat: 13.057890040765846,
        lng: 80.2410347747213,
      },
      {
        lat: 13.057601286008095,
        lng: 80.24137037490756,
      },
      {
        lat: 13.057137236369737,
        lng: 80.24230714014394,
      },
      {
        lat: 13.057005056013443,
        lng: 80.24264141520062,
      },
      {
        lat: 13.05680428640828,
        lng: 80.2427319560672,
      },
      {
        lat: 13.05644572663755,
        lng: 80.24262393852472,
      },
      {
        lat: 13.055974247329575,
        lng: 80.24233919324709,
      },
      {
        lat: 13.055459518412107,
        lng: 80.24229912868087,
      },
      {
        lat: 13.054766928000914,
        lng: 80.24243825280492,
      },
      {
        lat: 13.054252604338213,
        lng: 80.24246481608495,
      },
      {
        lat: 13.052750046176282,
        lng: 80.2418334302897,
      },
      {
        lat: 13.052457779211059,
        lng: 80.24159158914222,
      },
      {
        lat: 13.052388379452784,
        lng: 80.24121459925959,
      },
      {
        lat: 13.052408585866107,
        lng: 80.24085906144232,
      },
      {
        lat: 13.052913858840302,
        lng: 80.23934447626353,
      },
      {
        lat: 13.053283033996076,
        lng: 80.23752009696122,
      },
      {
        lat: 13.053799327610017,
        lng: 80.23413974278421,
      },
      {
        lat: 13.053897522893047,
        lng: 80.23189565877354,
      },
      {
        lat: 13.054098967922545,
        lng: 80.23191616431728,
      },
      {
        lat: 13.054027726466705,
        lng: 80.23078269580681,
      },
      {
        lat: 13.05423300698859,
        lng: 80.23081846556205,
      },
      {
        lat: 13.055768954523716,
        lng: 80.23085344406415,
      },
      {
        lat: 13.057259448760535,
        lng: 80.23170536064404,
      },
      {
        lat: 13.058410859223374,
        lng: 80.23154305166116,
      },
      {
        lat: 13.059768212394179,
        lng: 80.23155699141215,
      },
      {
        lat: 13.066275550583065,
        lng: 80.23274751606444,
      },
      {
        lat: 13.067738470360204,
        lng: 80.23313204055006,
      },
      {
        lat: 13.06854163857433,
        lng: 80.23164084691622,
      },
      {
        lat: 13.068708779439445,
        lng: 80.23196740120055,
      },
      {
        lat: 13.068910658117913,
        lng: 80.23234006593378,
      },
      {
        lat: 13.069155285470801,
        lng: 80.23244810537864,
      },
      {
        lat: 13.069831753708998,
        lng: 80.23244898684001,
      },
      {
        lat: 13.070119531552214,
        lng: 80.23235846346955,
      },
      {
        lat: 13.070341043365723,
        lng: 80.23231254484185,
      },
      {
        lat: 13.07040838090474,
        lng: 80.23244410669449,
      },
      {
        lat: 13.070168977158614,
        lng: 80.2331948794001,
      },
      {
        lat: 13.069622028495543,
        lng: 80.23443273801252,
      },
      {
        lat: 13.06935977708714,
        lng: 80.23507359429429,
      },
      {
        lat: 13.069254512664452,
        lng: 80.23599940740752,
      },
      {
        lat: 13.069417526888689,
        lng: 80.23727530133581,
      },
      {
        lat: 13.069315343054608,
        lng: 80.23870759340976,
      },
      {
        lat: 13.069305755317364,
        lng: 80.2407777385987,
      },
      {
        lat: 13.06933884354739,
        lng: 80.24196027578058,
      },
      {
        lat: 13.069408833248223,
        lng: 80.24211395618269,
      },
      {
        lat: 13.069427138649159,
        lng: 80.2422852154184,
      },
      {
        lat: 13.069238081321016,
        lng: 80.24242394678683,
      },
      {
        lat: 13.068497551036165,
        lng: 80.24272161815699,
      },
    ],
    properties: {
      Ward_No: 110,
      Zone_Name: "TEYNAMPET",
      fillColor: "#de4307",
      zname: "TEYNAMPET(110)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.057747262904847,
        lng: 80.25490463675457,
      },
      {
        lat: 13.059911890545257,
        lng: 80.26081073532742,
      },
      {
        lat: 13.067382828270443,
        lng: 80.24821427408214,
      },
      {
        lat: 13.061123329693139,
        lng: 80.2503194709895,
      },
      {
        lat: 13.057317942706229,
        lng: 80.2524778936549,
      },
      {
        lat: 13.064146477410803,
        lng: 80.25567339476045,
      },
      {
        lat: 13.069267961785947,
        lng: 80.24993284680174,
      },
      {
        lat: 13.065768616119847,
        lng: 80.24383436582043,
      },
      {
        lat: 13.063523054305925,
        lng: 80.24592165084971,
      },
      {
        lat: 13.060827958516986,
        lng: 80.25151237064043,
      },
      {
        lat: 13.062781126493121,
        lng: 80.24884979200384,
      },
      {
        lat: 13.06381414942019,
        lng: 80.24787947512466,
      },
      {
        lat: 13.065836996788885,
        lng: 80.2456865581408,
      },
      {
        lat: 13.064467014869019,
        lng: 80.25514617774414,
      },
      {
        lat: 13.054043338351812,
        lng: 80.25159115727291,
      },
      {
        lat: 13.060910407625528,
        lng: 80.25680933163994,
      },
      {
        lat: 13.06052833312214,
        lng: 80.2633751370553,
      },
      {
        lat: 13.066881551628414,
        lng: 80.24623776002842,
      },
      {
        lat: 13.05971707149718,
        lng: 80.24837900414202,
      },
      {
        lat: 13.067059138718562,
        lng: 80.25067112159512,
      },
      {
        lat: 13.065048866257783,
        lng: 80.24452661439135,
      },
      {
        lat: 13.0679687687621,
        lng: 80.25227081324022,
      },
      {
        lat: 13.053451100890259,
        lng: 80.25419903711828,
      },
      {
        lat: 13.060336036791433,
        lng: 80.25668124754627,
      },
      {
        lat: 13.053417802290836,
        lng: 80.25590733361685,
      },
      {
        lat: 13.061958625052924,
        lng: 80.25458502081588,
      },
      {
        lat: 13.061454600485295,
        lng: 80.26229341510712,
      },
      {
        lat: 13.063674194876405,
        lng: 80.25276143369177,
      },
      {
        lat: 13.060918832125575,
        lng: 80.26258282377418,
      },
      {
        lat: 13.060256612284405,
        lng: 80.26350978579073,
      },
      {
        lat: 13.067973561379604,
        lng: 80.24847047060722,
      },
      {
        lat: 13.065746583417663,
        lng: 80.24803103595514,
      },
      {
        lat: 13.064651524712996,
        lng: 80.25480080601459,
      },
      {
        lat: 13.05387373009488,
        lng: 80.2565912892666,
      },
      {
        lat: 13.04746080070255,
        lng: 80.25686978750637,
      },
      {
        lat: 13.055116971160173,
        lng: 80.25680095039229,
      },
      {
        lat: 13.067408676879735,
        lng: 80.24625205423922,
      },
      {
        lat: 13.06558483498545,
        lng: 80.25507545327686,
      },
      {
        lat: 13.065790945152056,
        lng: 80.25204296097986,
      },
      {
        lat: 13.057538647149936,
        lng: 80.25697235891766,
      },
      {
        lat: 13.065897900608155,
        lng: 80.25408791283392,
      },
      {
        lat: 13.066097856175256,
        lng: 80.24445166168131,
      },
      {
        lat: 13.062700587013977,
        lng: 80.24677689731061,
      },
      {
        lat: 13.057666078327918,
        lng: 80.25903401128312,
      },
      {
        lat: 13.0639687317798,
        lng: 80.2441635230205,
      },
      {
        lat: 13.054749143035389,
        lng: 80.25237769975045,
      },
      {
        lat: 13.058484288749876,
        lng: 80.25861451863815,
      },
      {
        lat: 13.058207502037558,
        lng: 80.25669504993093,
      },
      {
        lat: 13.059449635147848,
        lng: 80.2589432645609,
      },
      {
        lat: 13.054323140388123,
        lng: 80.25346577500102,
      },
      {
        lat: 13.056656916451137,
        lng: 80.24965598095778,
      },
      {
        lat: 13.047358838829577,
        lng: 80.25471369538351,
      },
      {
        lat: 13.068077074688679,
        lng: 80.25416877195693,
      },
      {
        lat: 13.054018701045877,
        lng: 80.25273991476178,
      },
      {
        lat: 13.068448293487107,
        lng: 80.24605720262969,
      },
      {
        lat: 13.059731773137207,
        lng: 80.2537506792953,
      },
      {
        lat: 13.069268957540878,
        lng: 80.24717827781414,
      },
      {
        lat: 13.068814428205467,
        lng: 80.24635152778677,
      },
      {
        lat: 13.06506622228073,
        lng: 80.24606623534144,
      },
      {
        lat: 13.061178079592109,
        lng: 80.25273996720256,
      },
      {
        lat: 13.058164971519387,
        lng: 80.26307576212646,
      },
      {
        lat: 13.055545404147324,
        lng: 80.25374265649154,
      },
      {
        lat: 13.070542769175331,
        lng: 80.25107169003863,
      },
      {
        lat: 13.061738179809582,
        lng: 80.2507082685717,
      },
      {
        lat: 13.060770581832248,
        lng: 80.25635783005367,
      },
      {
        lat: 13.062437408741452,
        lng: 80.24995732533723,
      },
      {
        lat: 13.05707168695599,
        lng: 80.25721612183987,
      },
      {
        lat: 13.062443563324935,
        lng: 80.24632097569828,
      },
      {
        lat: 13.06300168314338,
        lng: 80.24716749497998,
      },
      {
        lat: 13.064845376810258,
        lng: 80.2500898141985,
      },
      {
        lat: 13.060662539027694,
        lng: 80.25117240674147,
      },
      {
        lat: 13.055047531122707,
        lng: 80.25494478425858,
      },
      {
        lat: 13.062598850327575,
        lng: 80.26002393045128,
      },
      {
        lat: 13.05371329352247,
        lng: 80.25117193716139,
      },
      {
        lat: 13.066275631988015,
        lng: 80.24750454639972,
      },
      {
        lat: 13.069670521852313,
        lng: 80.24841305938205,
      },
      {
        lat: 13.060154279913716,
        lng: 80.25008963137088,
      },
      {
        lat: 13.065211427499294,
        lng: 80.25561757986657,
      },
      {
        lat: 13.055056098140033,
        lng: 80.25647087240851,
      },
      {
        lat: 13.059878486578075,
        lng: 80.25727588227885,
      },
      {
        lat: 13.0696051644066,
        lng: 80.2522099649463,
      },
      {
        lat: 13.052662344344009,
        lng: 80.25146328369028,
      },
      {
        lat: 13.065811232328295,
        lng: 80.24868010885389,
      },
      {
        lat: 13.066105900681693,
        lng: 80.25293305467306,
      },
      {
        lat: 13.060182926452704,
        lng: 80.2520616760689,
      },
      {
        lat: 13.053196719756743,
        lng: 80.25334551811528,
      },
      {
        lat: 13.066405068686855,
        lng: 80.24647314288684,
      },
      {
        lat: 13.048375258548061,
        lng: 80.25510770138337,
      },
      {
        lat: 13.051566939834817,
        lng: 80.25665800364563,
      },
      {
        lat: 13.062054454186383,
        lng: 80.25530209482503,
      },
      {
        lat: 13.057191472383943,
        lng: 80.25225955476085,
      },
      {
        lat: 13.064178142146469,
        lng: 80.25412958515052,
      },
      {
        lat: 13.066801558668818,
        lng: 80.24951083051509,
      },
      {
        lat: 13.063521474064451,
        lng: 80.2448593105453,
      },
      {
        lat: 13.059597369458286,
        lng: 80.25131799906497,
      },
      {
        lat: 13.070156143749777,
        lng: 80.24699864095794,
      },
      {
        lat: 13.067956298164583,
        lng: 80.24389623333273,
      },
      {
        lat: 13.058702415115235,
        lng: 80.26394013184552,
      },
      {
        lat: 13.068139999915502,
        lng: 80.24983553789043,
      },
      {
        lat: 13.06589677337626,
        lng: 80.24616416085934,
      },
      {
        lat: 13.048242504074407,
        lng: 80.25550015233365,
      },
      {
        lat: 13.054132772230838,
        lng: 80.25478574145797,
      },
      {
        lat: 13.069642309757633,
        lng: 80.24900544636563,
      },
      {
        lat: 13.05652569578412,
        lng: 80.25405753834752,
      },
      {
        lat: 13.061238149416145,
        lng: 80.24792970257269,
      },
      {
        lat: 13.052526256758396,
        lng: 80.25200250635393,
      },
      {
        lat: 13.053110715311895,
        lng: 80.25113067948,
      },
      {
        lat: 13.054587393534472,
        lng: 80.25750933039488,
      },
      {
        lat: 13.062564058329437,
        lng: 80.25320171507066,
      },
      {
        lat: 13.067051685706435,
        lng: 80.24714235348382,
      },
      {
        lat: 13.0585851407825,
        lng: 80.2621322006317,
      },
      {
        lat: 13.062249574959946,
        lng: 80.24815716587767,
      },
      {
        lat: 13.064741619269478,
        lng: 80.24516139063329,
      },
      {
        lat: 13.053949819677625,
        lng: 80.25443806408406,
      },
      {
        lat: 13.064167154647993,
        lng: 80.24806849292312,
      },
      {
        lat: 13.056043744251898,
        lng: 80.25215525310166,
      },
      {
        lat: 13.060657218150805,
        lng: 80.25767977486727,
      },
      {
        lat: 13.06931963463361,
        lng: 80.24687341262266,
      },
      {
        lat: 13.054240229492287,
        lng: 80.25003237635691,
      },
      {
        lat: 13.06966524166025,
        lng: 80.25036500183522,
      },
      {
        lat: 13.06974037976218,
        lng: 80.25111468546747,
      },
      {
        lat: 13.060071595585438,
        lng: 80.26060185617021,
      },
      {
        lat: 13.058416828937249,
        lng: 80.26203256222298,
      },
      {
        lat: 13.059629882038857,
        lng: 80.24879888899115,
      },
      {
        lat: 13.049092218837295,
        lng: 80.25725504034737,
      },
      {
        lat: 13.058658865523533,
        lng: 80.24987885290282,
      },
      {
        lat: 13.065651328146789,
        lng: 80.24364848476418,
      },
      {
        lat: 13.066486082812984,
        lng: 80.24774049764143,
      },
      {
        lat: 13.06082069795023,
        lng: 80.25504456588908,
      },
      {
        lat: 13.067582470626652,
        lng: 80.24372965300579,
      },
      {
        lat: 13.047919082269473,
        lng: 80.256372740251,
      },
      {
        lat: 13.052511112470501,
        lng: 80.2544343663983,
      },
      {
        lat: 13.061117971139765,
        lng: 80.25908977807602,
      },
      {
        lat: 13.061122508697483,
        lng: 80.25701588574958,
      },
      {
        lat: 13.058097533574319,
        lng: 80.25705398913802,
      },
      {
        lat: 13.065558781102595,
        lng: 80.24954096442855,
      },
      {
        lat: 13.05883816084926,
        lng: 80.26533358482716,
      },
      {
        lat: 13.062609600258577,
        lng: 80.2511592667471,
      },
      {
        lat: 13.060507663523415,
        lng: 80.26374191304814,
      },
      {
        lat: 13.051331646930404,
        lng: 80.2567767628455,
      },
      {
        lat: 13.061094742672418,
        lng: 80.2633972290723,
      },
      {
        lat: 13.056016501482596,
        lng: 80.25205787213314,
      },
      {
        lat: 13.059042629767584,
        lng: 80.25491231387852,
      },
      {
        lat: 13.052896800698958,
        lng: 80.25255165920467,
      },
      {
        lat: 13.068495927059926,
        lng: 80.24394841844531,
      },
      {
        lat: 13.063173418841366,
        lng: 80.25107304458645,
      },
      {
        lat: 13.068704468669635,
        lng: 80.2435892125774,
      },
      {
        lat: 13.053415002640573,
        lng: 80.2533032095333,
      },
      {
        lat: 13.064773290147393,
        lng: 80.2472103903983,
      },
      {
        lat: 13.05450694845834,
        lng: 80.25056476977913,
      },
      {
        lat: 13.058764480885063,
        lng: 80.25819593631171,
      },
      {
        lat: 13.054356070357848,
        lng: 80.25437574515071,
      },
    ],
    cases: 152,
    coords: [
      {
        lat: 13.05827567526044,
        lng: 80.26548316385583,
      },
      {
        lat: 13.057831339488326,
        lng: 80.26436233302968,
      },
      {
        lat: 13.057849929464334,
        lng: 80.2633562781472,
      },
      {
        lat: 13.057792903581467,
        lng: 80.26210552235531,
      },
      {
        lat: 13.057486709707923,
        lng: 80.26051339812423,
      },
      {
        lat: 13.056581110012205,
        lng: 80.25787138109615,
      },
      {
        lat: 13.056259012078296,
        lng: 80.25772690257482,
      },
      {
        lat: 13.055814120759205,
        lng: 80.25770613371883,
      },
      {
        lat: 13.055641016280166,
        lng: 80.25768306466028,
      },
      {
        lat: 13.055345118415893,
        lng: 80.25778370526709,
      },
      {
        lat: 13.054481088195304,
        lng: 80.25791368821201,
      },
      {
        lat: 13.045975454560173,
        lng: 80.25697977604442,
      },
      {
        lat: 13.046169984148388,
        lng: 80.25646291387142,
      },
      {
        lat: 13.04687741082058,
        lng: 80.25496034745316,
      },
      {
        lat: 13.047442111173977,
        lng: 80.25436675016918,
      },
      {
        lat: 13.047909919777092,
        lng: 80.25409291603117,
      },
      {
        lat: 13.048898981645793,
        lng: 80.25420721577694,
      },
      {
        lat: 13.049295054045745,
        lng: 80.25432653414656,
      },
      {
        lat: 13.049345215922434,
        lng: 80.25444878004662,
      },
      {
        lat: 13.049247130431937,
        lng: 80.25457228060262,
      },
      {
        lat: 13.049224213147745,
        lng: 80.25486688359665,
      },
      {
        lat: 13.049299679789037,
        lng: 80.25508705168238,
      },
      {
        lat: 13.050014794584696,
        lng: 80.25522061903436,
      },
      {
        lat: 13.050826685020171,
        lng: 80.25531768991767,
      },
      {
        lat: 13.0511145508525,
        lng: 80.25531525363449,
      },
      {
        lat: 13.05137371833903,
        lng: 80.25489729174232,
      },
      {
        lat: 13.051764840248087,
        lng: 80.25466011180019,
      },
      {
        lat: 13.052859855157498,
        lng: 80.25397522060798,
      },
      {
        lat: 13.051563793783705,
        lng: 80.25172544834427,
      },
      {
        lat: 13.052318445573377,
        lng: 80.25101745220277,
      },
      {
        lat: 13.055396662056845,
        lng: 80.24938029755748,
      },
      {
        lat: 13.05819001556848,
        lng: 80.24823927886408,
      },
      {
        lat: 13.059783991777016,
        lng: 80.24783600567015,
      },
      {
        lat: 13.060644902503727,
        lng: 80.24738696553821,
      },
      {
        lat: 13.06158353186749,
        lng: 80.2468073400001,
      },
      {
        lat: 13.061895512732155,
        lng: 80.24646688772654,
      },
      {
        lat: 13.062210543435278,
        lng: 80.24522301806803,
      },
      {
        lat: 13.062299519968304,
        lng: 80.24517301611759,
      },
      {
        lat: 13.062923386045906,
        lng: 80.2447679485211,
      },
      {
        lat: 13.063277352929763,
        lng: 80.24412085047314,
      },
      {
        lat: 13.063587935119617,
        lng: 80.24369622385416,
      },
      {
        lat: 13.066115088059691,
        lng: 80.24360820462745,
      },
      {
        lat: 13.06819002251057,
        lng: 80.24272443733113,
      },
      {
        lat: 13.068497551036165,
        lng: 80.24272161815699,
      },
      {
        lat: 13.068515647927399,
        lng: 80.24285859632562,
      },
      {
        lat: 13.068775111976015,
        lng: 80.24294210749358,
      },
      {
        lat: 13.069070144277239,
        lng: 80.24319673179734,
      },
      {
        lat: 13.069470109002387,
        lng: 80.24367330643736,
      },
      {
        lat: 13.070207242342148,
        lng: 80.24565547213149,
      },
      {
        lat: 13.071309501939123,
        lng: 80.24806308306489,
      },
      {
        lat: 13.071557541412629,
        lng: 80.24910661021276,
      },
      {
        lat: 13.071661943055423,
        lng: 80.24945110341652,
      },
      {
        lat: 13.071300722754392,
        lng: 80.24984927508386,
      },
      {
        lat: 13.070051033441544,
        lng: 80.2522521144157,
      },
      {
        lat: 13.068796446155478,
        lng: 80.25384967898087,
      },
      {
        lat: 13.068085737048808,
        lng: 80.25466101011577,
      },
      {
        lat: 13.067896060958422,
        lng: 80.25485210156283,
      },
      {
        lat: 13.067824644308466,
        lng: 80.25487639175162,
      },
      {
        lat: 13.066985591699492,
        lng: 80.25419660550784,
      },
      {
        lat: 13.066794763144872,
        lng: 80.25419822053938,
      },
      {
        lat: 13.06677177392372,
        lng: 80.2543405297301,
      },
      {
        lat: 13.067179301414528,
        lng: 80.25466868151558,
      },
      {
        lat: 13.067299289551313,
        lng: 80.25478609488053,
      },
      {
        lat: 13.066990201556425,
        lng: 80.25495451117055,
      },
      {
        lat: 13.065778711551905,
        lng: 80.25579376631516,
      },
      {
        lat: 13.065373777105682,
        lng: 80.25589193646486,
      },
      {
        lat: 13.06396627245766,
        lng: 80.25588016277072,
      },
      {
        lat: 13.063560185547145,
        lng: 80.2557888565049,
      },
      {
        lat: 13.062726319027215,
        lng: 80.25596171413153,
      },
      {
        lat: 13.061751636019629,
        lng: 80.25651473586338,
      },
      {
        lat: 13.061396713640466,
        lng: 80.25699145508663,
      },
      {
        lat: 13.061256905559175,
        lng: 80.25753741105531,
      },
      {
        lat: 13.06140362842587,
        lng: 80.2581283135806,
      },
      {
        lat: 13.061813893019233,
        lng: 80.25890647185324,
      },
      {
        lat: 13.062582105210542,
        lng: 80.25970528649351,
      },
      {
        lat: 13.063180749372034,
        lng: 80.26007919235137,
      },
      {
        lat: 13.063539129143894,
        lng: 80.2601709023751,
      },
      {
        lat: 13.063730677988659,
        lng: 80.26028771010314,
      },
      {
        lat: 13.063827388788235,
        lng: 80.26050006355509,
      },
      {
        lat: 13.063687004474822,
        lng: 80.26095128131584,
      },
      {
        lat: 13.063500209545019,
        lng: 80.26161606380225,
      },
      {
        lat: 13.06231458992241,
        lng: 80.26278670079532,
      },
      {
        lat: 13.062196042366086,
        lng: 80.26290613294987,
      },
      {
        lat: 13.062196906714265,
        lng: 80.26304824026165,
      },
      {
        lat: 13.062628719948483,
        lng: 80.2634472438239,
      },
      {
        lat: 13.062677723609404,
        lng: 80.26366000103371,
      },
      {
        lat: 13.062631168934985,
        lng: 80.26384988120732,
      },
      {
        lat: 13.06227495003339,
        lng: 80.26411343946292,
      },
      {
        lat: 13.061728824548732,
        lng: 80.26453019388262,
      },
      {
        lat: 13.060517910776554,
        lng: 80.26546418723518,
      },
      {
        lat: 13.060065125133585,
        lng: 80.265539076591,
      },
      {
        lat: 13.059325376368543,
        lng: 80.2654979657343,
      },
      {
        lat: 13.05827567526044,
        lng: 80.26548316385583,
      },
    ],
    properties: {
      Ward_No: 111,
      Zone_Name: "TEYNAMPET",
      fillColor: "#de4307",
      zname: "TEYNAMPET(111)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.063161792285447,
        lng: 80.28505512515059,
      },
      {
        lat: 13.061457422553442,
        lng: 80.28211877322259,
      },
      {
        lat: 13.0617412945809,
        lng: 80.27155793786832,
      },
      {
        lat: 13.065499906542426,
        lng: 80.28318359854157,
      },
      {
        lat: 13.060004466437025,
        lng: 80.27712332598561,
      },
      {
        lat: 13.063199204372642,
        lng: 80.27965710178184,
      },
      {
        lat: 13.066130233491354,
        lng: 80.28619775173354,
      },
      {
        lat: 13.067265033738897,
        lng: 80.28493613072277,
      },
      {
        lat: 13.058423179631914,
        lng: 80.28499229768883,
      },
      {
        lat: 13.06133808443617,
        lng: 80.28188660886453,
      },
      {
        lat: 13.065187463674418,
        lng: 80.27674884003358,
      },
      {
        lat: 13.059772570559058,
        lng: 80.27772313267849,
      },
      {
        lat: 13.05892043132621,
        lng: 80.27623339961262,
      },
      {
        lat: 13.065582060558322,
        lng: 80.28420030282693,
      },
      {
        lat: 13.067466810451872,
        lng: 80.28111174213461,
      },
      {
        lat: 13.063982568239151,
        lng: 80.28103689619493,
      },
      {
        lat: 13.06064794232671,
        lng: 80.28307477510609,
      },
      {
        lat: 13.065691443137586,
        lng: 80.28827593944972,
      },
      {
        lat: 13.064578598762601,
        lng: 80.27582936853585,
      },
      {
        lat: 13.058612149204269,
        lng: 80.28464151195394,
      },
      {
        lat: 13.06233485855568,
        lng: 80.28095468995545,
      },
      {
        lat: 13.060227639911638,
        lng: 80.27350175641966,
      },
      {
        lat: 13.06206142957171,
        lng: 80.27061369800678,
      },
      {
        lat: 13.064310972085917,
        lng: 80.282156952204,
      },
      {
        lat: 13.058990029138004,
        lng: 80.27998332074036,
      },
      {
        lat: 13.064415319136794,
        lng: 80.27828669447568,
      },
      {
        lat: 13.058990657366929,
        lng: 80.27633772090833,
      },
      {
        lat: 13.061807396528232,
        lng: 80.27449252276396,
      },
      {
        lat: 13.062519716154664,
        lng: 80.2773409785139,
      },
      {
        lat: 13.058302587212978,
        lng: 80.28378297371323,
      },
      {
        lat: 13.064013379459734,
        lng: 80.2839459161743,
      },
      {
        lat: 13.06580723133145,
        lng: 80.2817751539876,
      },
      {
        lat: 13.06098773235877,
        lng: 80.27854138318595,
      },
      {
        lat: 13.065472023420012,
        lng: 80.2828351112122,
      },
      {
        lat: 13.06238276591501,
        lng: 80.27653586884372,
      },
      {
        lat: 13.059217274561082,
        lng: 80.27739264513161,
      },
      {
        lat: 13.063959826654642,
        lng: 80.28342165608625,
      },
      {
        lat: 13.059389982935397,
        lng: 80.2728712078248,
      },
      {
        lat: 13.063697625324021,
        lng: 80.2741194041744,
      },
      {
        lat: 13.059513121821464,
        lng: 80.27734433959426,
      },
      {
        lat: 13.05788212058797,
        lng: 80.28123611896252,
      },
      {
        lat: 13.059865149542668,
        lng: 80.27156335828171,
      },
      {
        lat: 13.063290014165359,
        lng: 80.28236260135526,
      },
      {
        lat: 13.067544195180046,
        lng: 80.28365793918552,
      },
      {
        lat: 13.0599981881687,
        lng: 80.28201653620428,
      },
      {
        lat: 13.063514192704242,
        lng: 80.28137672124947,
      },
      {
        lat: 13.059589814174869,
        lng: 80.27126544242823,
      },
      {
        lat: 13.065622579799996,
        lng: 80.28380380714847,
      },
      {
        lat: 13.066548428588684,
        lng: 80.28246688752945,
      },
      {
        lat: 13.059039800353574,
        lng: 80.27364827443566,
      },
      {
        lat: 13.066508229147585,
        lng: 80.2807602994934,
      },
      {
        lat: 13.060319962832157,
        lng: 80.27605750697491,
      },
      {
        lat: 13.060918042255038,
        lng: 80.27088506124025,
      },
      {
        lat: 13.064371917140832,
        lng: 80.27500125548477,
      },
      {
        lat: 13.06537611323304,
        lng: 80.28581905364419,
      },
      {
        lat: 13.062128006017067,
        lng: 80.28001811236199,
      },
      {
        lat: 13.064015686999705,
        lng: 80.28810093855846,
      },
      {
        lat: 13.0589722893878,
        lng: 80.28424115432449,
      },
      {
        lat: 13.063203099772732,
        lng: 80.27673980154003,
      },
      {
        lat: 13.062404839024111,
        lng: 80.27160966252379,
      },
      {
        lat: 13.063993612439743,
        lng: 80.27749889142352,
      },
      {
        lat: 13.063864912080543,
        lng: 80.28440705405241,
      },
      {
        lat: 13.060604909761661,
        lng: 80.27182101115504,
      },
      {
        lat: 13.061827414857326,
        lng: 80.28447094192941,
      },
      {
        lat: 13.06101398261597,
        lng: 80.27467979850333,
      },
      {
        lat: 13.059912234305866,
        lng: 80.28448523157901,
      },
      {
        lat: 13.063870669151033,
        lng: 80.27206669360245,
      },
      {
        lat: 13.066009127784803,
        lng: 80.28302358131361,
      },
      {
        lat: 13.059398915323712,
        lng: 80.27851434576196,
      },
      {
        lat: 13.058143962093412,
        lng: 80.28365418772995,
      },
      {
        lat: 13.062448825817917,
        lng: 80.28621893208016,
      },
      {
        lat: 13.059196926196572,
        lng: 80.27872233150313,
      },
      {
        lat: 13.062218796864983,
        lng: 80.2772454630365,
      },
      {
        lat: 13.066352141492162,
        lng: 80.28247644318779,
      },
      {
        lat: 13.059366851839016,
        lng: 80.28176606676857,
      },
      {
        lat: 13.063654901282131,
        lng: 80.28340470945753,
      },
      {
        lat: 13.063170662929531,
        lng: 80.28011172256237,
      },
      {
        lat: 13.061771869998772,
        lng: 80.27254784808429,
      },
      {
        lat: 13.064261093358336,
        lng: 80.2807828200405,
      },
      {
        lat: 13.06567567831002,
        lng: 80.28773241632507,
      },
      {
        lat: 13.06002462919078,
        lng: 80.27338133728868,
      },
      {
        lat: 13.058434806511352,
        lng: 80.28123624221078,
      },
      {
        lat: 13.063958291336027,
        lng: 80.27820269167499,
      },
      {
        lat: 13.061148401000509,
        lng: 80.27735338275942,
      },
      {
        lat: 13.06400896487035,
        lng: 80.2746248789924,
      },
      {
        lat: 13.061391464692207,
        lng: 80.28666395197686,
      },
      {
        lat: 13.065141950483934,
        lng: 80.28406354032192,
      },
      {
        lat: 13.061122622536093,
        lng: 80.2712190238906,
      },
      {
        lat: 13.060106967514251,
        lng: 80.2747497441523,
      },
      {
        lat: 13.06654655619609,
        lng: 80.28146577111707,
      },
      {
        lat: 13.06029539479174,
        lng: 80.2778680989266,
      },
      {
        lat: 13.064281243565649,
        lng: 80.27671992290377,
      },
      {
        lat: 13.059306090423311,
        lng: 80.27184098340908,
      },
      {
        lat: 13.062345398397728,
        lng: 80.27205655044219,
      },
      {
        lat: 13.059400341617938,
        lng: 80.27568730767088,
      },
      {
        lat: 13.059642307225069,
        lng: 80.2784537656023,
      },
      {
        lat: 13.058806011601341,
        lng: 80.27919914900096,
      },
      {
        lat: 13.062432203241848,
        lng: 80.27964420395993,
      },
      {
        lat: 13.059649112051709,
        lng: 80.28168648015122,
      },
      {
        lat: 13.061453072665156,
        lng: 80.27620885294729,
      },
      {
        lat: 13.062396646945427,
        lng: 80.27441212152611,
      },
      {
        lat: 13.059768625637743,
        lng: 80.27467096328877,
      },
      {
        lat: 13.059873386326293,
        lng: 80.27840648979526,
      },
      {
        lat: 13.060474214571835,
        lng: 80.28565750369897,
      },
      {
        lat: 13.061781612942038,
        lng: 80.28288908604999,
      },
      {
        lat: 13.059986624515407,
        lng: 80.27751655657046,
      },
      {
        lat: 13.059463409399784,
        lng: 80.27149042800092,
      },
      {
        lat: 13.06742265936345,
        lng: 80.2810935231506,
      },
      {
        lat: 13.06602352489185,
        lng: 80.2835817341471,
      },
      {
        lat: 13.063196393332513,
        lng: 80.27785438115109,
      },
      {
        lat: 13.064015469838733,
        lng: 80.28523140149291,
      },
      {
        lat: 13.059718627285859,
        lng: 80.2757489945848,
      },
      {
        lat: 13.058538652459122,
        lng: 80.28012563129168,
      },
      {
        lat: 13.062497128601638,
        lng: 80.2722415629825,
      },
      {
        lat: 13.058279508466127,
        lng: 80.28428257523377,
      },
      {
        lat: 13.064004035095621,
        lng: 80.27712322736436,
      },
      {
        lat: 13.063611705921772,
        lng: 80.27193988435177,
      },
      {
        lat: 13.06603289378784,
        lng: 80.28097126433403,
      },
      {
        lat: 13.064652106487701,
        lng: 80.2847178836572,
      },
      {
        lat: 13.062855482921478,
        lng: 80.27318220824532,
      },
      {
        lat: 13.063488921465758,
        lng: 80.2706990241797,
      },
      {
        lat: 13.061933731753781,
        lng: 80.27269628288707,
      },
      {
        lat: 13.063173496067003,
        lng: 80.28148506234943,
      },
      {
        lat: 13.062112135719817,
        lng: 80.27453179522882,
      },
      {
        lat: 13.060750523108188,
        lng: 80.27667277938319,
      },
      {
        lat: 13.065183778829061,
        lng: 80.27647872966503,
      },
      {
        lat: 13.058059530822241,
        lng: 80.28531618275339,
      },
      {
        lat: 13.066489643045385,
        lng: 80.28325829547826,
      },
      {
        lat: 13.061479269227748,
        lng: 80.2716862076582,
      },
      {
        lat: 13.067156327624842,
        lng: 80.28480652871232,
      },
      {
        lat: 13.059397982754035,
        lng: 80.28479523823495,
      },
      {
        lat: 13.059296680433533,
        lng: 80.28510304644767,
      },
      {
        lat: 13.06795733879421,
        lng: 80.28286300052439,
      },
      {
        lat: 13.06255445886994,
        lng: 80.27744846434814,
      },
      {
        lat: 13.059095342233999,
        lng: 80.28526342346316,
      },
      {
        lat: 13.064960843889832,
        lng: 80.28316324291544,
      },
      {
        lat: 13.066910346864258,
        lng: 80.285959972907,
      },
      {
        lat: 13.061591338626817,
        lng: 80.27754306811573,
      },
      {
        lat: 13.058902946199964,
        lng: 80.28094190708232,
      },
      {
        lat: 13.061236944457939,
        lng: 80.27323444510323,
      },
      {
        lat: 13.064444465865932,
        lng: 80.28179637683489,
      },
      {
        lat: 13.059215848887595,
        lng: 80.27747971544184,
      },
      {
        lat: 13.059578632897672,
        lng: 80.277221642857,
      },
      {
        lat: 13.06658365414448,
        lng: 80.28693753117585,
      },
      {
        lat: 13.061822139384702,
        lng: 80.28557393211825,
      },
      {
        lat: 13.062865240738416,
        lng: 80.27268552643343,
      },
      {
        lat: 13.059404351445936,
        lng: 80.27054866126814,
      },
      {
        lat: 13.06136402266433,
        lng: 80.28447194122512,
      },
    ],
    cases: 148,
    coords: [
      {
        lat: 13.057581653666618,
        lng: 80.28249543934119,
      },
      {
        lat: 13.057915422094597,
        lng: 80.27975240191248,
      },
      {
        lat: 13.058268273972509,
        lng: 80.2771375939897,
      },
      {
        lat: 13.058748788941573,
        lng: 80.27530281116002,
      },
      {
        lat: 13.058961717440935,
        lng: 80.27393407439767,
      },
      {
        lat: 13.059316944680006,
        lng: 80.27170979914088,
      },
      {
        lat: 13.059215645583675,
        lng: 80.27122246549965,
      },
      {
        lat: 13.05905298978656,
        lng: 80.27081712915869,
      },
      {
        lat: 13.05912731772335,
        lng: 80.2707980209328,
      },
      {
        lat: 13.059198611097164,
        lng: 80.27028000082053,
      },
      {
        lat: 13.059328319715341,
        lng: 80.270186507217,
      },
      {
        lat: 13.059495922844674,
        lng: 80.27020356791738,
      },
      {
        lat: 13.059553663741262,
        lng: 80.27051722511786,
      },
      {
        lat: 13.059647725672331,
        lng: 80.27068274157016,
      },
      {
        lat: 13.059853560486582,
        lng: 80.27086579122893,
      },
      {
        lat: 13.05985535874609,
        lng: 80.27116144271714,
      },
      {
        lat: 13.059968592715338,
        lng: 80.27141919275763,
      },
      {
        lat: 13.06047117732082,
        lng: 80.2714334184229,
      },
      {
        lat: 13.060675213875564,
        lng: 80.27132081659342,
      },
      {
        lat: 13.060674427137032,
        lng: 80.27119146906735,
      },
      {
        lat: 13.0604478468077,
        lng: 80.27065749076837,
      },
      {
        lat: 13.060239539386403,
        lng: 80.27006792031327,
      },
      {
        lat: 13.060312743411178,
        lng: 80.26986402990732,
      },
      {
        lat: 13.060516892357189,
        lng: 80.26976990629579,
      },
      {
        lat: 13.06116813283637,
        lng: 80.2697459155096,
      },
      {
        lat: 13.061261745202337,
        lng: 80.2698375190898,
      },
      {
        lat: 13.061318924143055,
        lng: 80.27005878520012,
      },
      {
        lat: 13.061356931045596,
        lng: 80.2701878177224,
      },
      {
        lat: 13.06156220390375,
        lng: 80.27027847629098,
      },
      {
        lat: 13.062827914263037,
        lng: 80.27030472259437,
      },
      {
        lat: 13.063368280988735,
        lng: 80.27041102434602,
      },
      {
        lat: 13.063740707411336,
        lng: 80.27044483074302,
      },
      {
        lat: 13.06383420738631,
        lng: 80.2705179561053,
      },
      {
        lat: 13.06376066618788,
        lng: 80.27066641185723,
      },
      {
        lat: 13.063577262757173,
        lng: 80.27111146410903,
      },
      {
        lat: 13.063560338543375,
        lng: 80.27138879488123,
      },
      {
        lat: 13.063728616019958,
        lng: 80.27151672488962,
      },
      {
        lat: 13.064100592877857,
        lng: 80.27147661841465,
      },
      {
        lat: 13.064473019300456,
        lng: 80.27151042481162,
      },
      {
        lat: 13.064566294492993,
        lng: 80.27154659373805,
      },
      {
        lat: 13.064641971124411,
        lng: 80.27174922412836,
      },
      {
        lat: 13.064610707695023,
        lng: 80.272728884687,
      },
      {
        lat: 13.06448515755213,
        lng: 80.27350607235728,
      },
      {
        lat: 13.064523838801986,
        lng: 80.27374597418745,
      },
      {
        lat: 13.064635724076435,
        lng: 80.27378198561182,
      },
      {
        lat: 13.065156356807856,
        lng: 80.27370366268514,
      },
      {
        lat: 13.066160851671622,
        lng: 80.27362124470785,
      },
      {
        lat: 13.066365113008859,
        lng: 80.27354559931426,
      },
      {
        lat: 13.066662649538575,
        lng: 80.27350612284702,
      },
      {
        lat: 13.066756374295926,
        lng: 80.27361620464544,
      },
      {
        lat: 13.066682833097728,
        lng: 80.27376466039732,
      },
      {
        lat: 13.066482055888288,
        lng: 80.27441313054925,
      },
      {
        lat: 13.065932182769256,
        lng: 80.27580372195891,
      },
      {
        lat: 13.065806070670437,
        lng: 80.27648851853894,
      },
      {
        lat: 13.064977495886216,
        lng: 80.27795538534981,
      },
      {
        lat: 13.06466505818464,
        lng: 80.2786048005135,
      },
      {
        lat: 13.064575829076167,
        lng: 80.2792338474357,
      },
      {
        lat: 13.064595563070284,
        lng: 80.27941847211389,
      },
      {
        lat: 13.06485745291306,
        lng: 80.27963800570282,
      },
      {
        lat: 13.06756018567091,
        lng: 80.28031734020449,
      },
      {
        lat: 13.067914451576469,
        lng: 80.28042521697544,
      },
      {
        lat: 13.068027235980674,
        lng: 80.28060905414394,
      },
      {
        lat: 13.067991814076178,
        lng: 80.28090502063606,
      },
      {
        lat: 13.067952381709137,
        lng: 80.28360146515027,
      },
      {
        lat: 13.068010909344318,
        lng: 80.28404446987659,
      },
      {
        lat: 13.06795845083497,
        lng: 80.28459928892292,
      },
      {
        lat: 13.067758685146389,
        lng: 80.28541406303695,
      },
      {
        lat: 13.067300401351723,
        lng: 80.28656365010272,
      },
      {
        lat: 13.066543569506367,
        lng: 80.28758640967347,
      },
      {
        lat: 13.066250753407859,
        lng: 80.2884019712973,
      },
      {
        lat: 13.06640278101801,
        lng: 80.28891810138609,
      },
      {
        lat: 13.066586131365586,
        lng: 80.28946897037059,
      },
      {
        lat: 13.066135609198911,
        lng: 80.28937704580343,
      },
      {
        lat: 13.064549745947062,
        lng: 80.28846326726799,
      },
      {
        lat: 13.0623886051097,
        lng: 80.28744737282804,
      },
      {
        lat: 13.058538196381575,
        lng: 80.28623180584465,
      },
      {
        lat: 13.057495166858494,
        lng: 80.28599100247584,
      },
      {
        lat: 13.05727838039952,
        lng: 80.28577886794032,
      },
      {
        lat: 13.05731125804701,
        lng: 80.28527932809189,
      },
      {
        lat: 13.057727262449122,
        lng: 80.28281516089966,
      },
      {
        lat: 13.057581653666618,
        lng: 80.28249543934119,
      },
    ],
    properties: {
      Ward_No: 114,
      Zone_Name: "TEYNAMPET",
      fillColor: "#de4307",
      zname: "TEYNAMPET(114)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.05318615836247,
        lng: 80.2783683687562,
      },
      {
        lat: 13.052704945990946,
        lng: 80.27735071599098,
      },
      {
        lat: 13.055627887839874,
        lng: 80.2748306679127,
      },
      {
        lat: 13.05490964480745,
        lng: 80.27584916685883,
      },
      {
        lat: 13.05308808386566,
        lng: 80.27374112418477,
      },
      {
        lat: 13.053063550299496,
        lng: 80.28035329039157,
      },
      {
        lat: 13.053315634101278,
        lng: 80.27727408015137,
      },
      {
        lat: 13.05303847166326,
        lng: 80.27604346052979,
      },
      {
        lat: 13.05541439590435,
        lng: 80.27920117040345,
      },
      {
        lat: 13.05295768497072,
        lng: 80.27898914057825,
      },
      {
        lat: 13.057704630527255,
        lng: 80.27982715089112,
      },
      {
        lat: 13.053271515167374,
        lng: 80.27600265036622,
      },
      {
        lat: 13.052015440944428,
        lng: 80.2814409101057,
      },
      {
        lat: 13.057493384191224,
        lng: 80.27591518683364,
      },
      {
        lat: 13.055167846928775,
        lng: 80.2754676434844,
      },
      {
        lat: 13.054370218043061,
        lng: 80.28124058115759,
      },
      {
        lat: 13.055638551663725,
        lng: 80.27848702469038,
      },
      {
        lat: 13.054359658493134,
        lng: 80.28139928819702,
      },
      {
        lat: 13.057145695109908,
        lng: 80.27395339771739,
      },
      {
        lat: 13.053330211338938,
        lng: 80.27577427552824,
      },
      {
        lat: 13.051626744325313,
        lng: 80.27925464195884,
      },
      {
        lat: 13.058039236606476,
        lng: 80.27856390042767,
      },
      {
        lat: 13.057648800168032,
        lng: 80.27646501732308,
      },
      {
        lat: 13.052675332513118,
        lng: 80.28144850552107,
      },
      {
        lat: 13.052911835901055,
        lng: 80.2783389920069,
      },
    ],
    cases: 25,
    coords: [
      {
        lat: 13.052870130849087,
        lng: 80.27355393885809,
      },
      {
        lat: 13.055789093727801,
        lng: 80.27371682965395,
      },
      {
        lat: 13.057486472096496,
        lng: 80.27389774068529,
      },
      {
        lat: 13.05895848308405,
        lng: 80.27395486533581,
      },
      {
        lat: 13.058748788941573,
        lng: 80.27530281116002,
      },
      {
        lat: 13.058268273972509,
        lng: 80.2771375939897,
      },
      {
        lat: 13.057915422094597,
        lng: 80.27975240191248,
      },
      {
        lat: 13.057581653666618,
        lng: 80.28249543934119,
      },
      {
        lat: 13.05718507940625,
        lng: 80.28224916485384,
      },
      {
        lat: 13.049831251910375,
        lng: 80.2811144615091,
      },
      {
        lat: 13.05131440310547,
        lng: 80.27768463736406,
      },
      {
        lat: 13.052862888592227,
        lng: 80.27359513759833,
      },
      {
        lat: 13.052870130849087,
        lng: 80.27355393885809,
      },
    ],
    properties: {
      Ward_No: 116,
      Zone_Name: "TEYNAMPET",
      fillColor: "#8bc24c",
      zname: "TEYNAMPET(116)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.056061493459985,
        lng: 80.25996384007689,
      },
      {
        lat: 13.056392973114074,
        lng: 80.26700961583958,
      },
      {
        lat: 13.058150147644007,
        lng: 80.27084573670135,
      },
      {
        lat: 13.055058628785194,
        lng: 80.2702826723587,
      },
      {
        lat: 13.054554607997273,
        lng: 80.27106907254132,
      },
      {
        lat: 13.055277529725673,
        lng: 80.26936798646942,
      },
      {
        lat: 13.057742884341746,
        lng: 80.2649111062952,
      },
      {
        lat: 13.055049053497706,
        lng: 80.25929044618562,
      },
      {
        lat: 13.054079053990575,
        lng: 80.26696130712784,
      },
      {
        lat: 13.05393045506618,
        lng: 80.27138672647351,
      },
      {
        lat: 13.054817895615294,
        lng: 80.26415162610436,
      },
      {
        lat: 13.056065977149727,
        lng: 80.26595464544296,
      },
      {
        lat: 13.056878802873385,
        lng: 80.26189055160066,
      },
      {
        lat: 13.055247391120957,
        lng: 80.2735098526757,
      },
      {
        lat: 13.056339485538802,
        lng: 80.26439652792499,
      },
      {
        lat: 13.056981624721232,
        lng: 80.26281897580532,
      },
      {
        lat: 13.055337802845573,
        lng: 80.26909183329799,
      },
      {
        lat: 13.055468067581812,
        lng: 80.26725770513683,
      },
      {
        lat: 13.054287225858737,
        lng: 80.26849335751477,
      },
      {
        lat: 13.053722614609804,
        lng: 80.26953015504375,
      },
      {
        lat: 13.053875292347414,
        lng: 80.2654998013212,
      },
      {
        lat: 13.05630388126775,
        lng: 80.26936048314047,
      },
      {
        lat: 13.056467761705116,
        lng: 80.27291735114227,
      },
      {
        lat: 13.054535554837049,
        lng: 80.26290754123573,
      },
      {
        lat: 13.056897397271513,
        lng: 80.26827064397109,
      },
      {
        lat: 13.05664086774556,
        lng: 80.26092844049273,
      },
      {
        lat: 13.056691136540445,
        lng: 80.26883958714724,
      },
      {
        lat: 13.05720562426314,
        lng: 80.27199951073234,
      },
      {
        lat: 13.056841153442033,
        lng: 80.26668836651183,
      },
      {
        lat: 13.057044614425543,
        lng: 80.26060914500366,
      },
      {
        lat: 13.057784000151171,
        lng: 80.26604247962561,
      },
      {
        lat: 13.054387392260256,
        lng: 80.26902311343764,
      },
      {
        lat: 13.055402893322078,
        lng: 80.27244064723494,
      },
      {
        lat: 13.05460608304189,
        lng: 80.26051785891643,
      },
      {
        lat: 13.055470782456851,
        lng: 80.26881750113886,
      },
      {
        lat: 13.053994632483986,
        lng: 80.26552597918179,
      },
      {
        lat: 13.054534258536972,
        lng: 80.26852596197635,
      },
      {
        lat: 13.054481152291558,
        lng: 80.2709532998265,
      },
      {
        lat: 13.056450855002518,
        lng: 80.26397503494877,
      },
      {
        lat: 13.05759542626325,
        lng: 80.26867776031908,
      },
      {
        lat: 13.055971578440934,
        lng: 80.2692564249331,
      },
      {
        lat: 13.053903614684174,
        lng: 80.26324427708491,
      },
      {
        lat: 13.05500721181806,
        lng: 80.26843887735366,
      },
      {
        lat: 13.058991302667849,
        lng: 80.27280680724529,
      },
      {
        lat: 13.055692692193816,
        lng: 80.26293218144465,
      },
      {
        lat: 13.05602677969268,
        lng: 80.26337738582532,
      },
      {
        lat: 13.056026624304803,
        lng: 80.27267380805067,
      },
      {
        lat: 13.055737581425097,
        lng: 80.27067716135296,
      },
      {
        lat: 13.05661282833226,
        lng: 80.26192449936225,
      },
      {
        lat: 13.057316099724886,
        lng: 80.26244546987544,
      },
      {
        lat: 13.058217176297221,
        lng: 80.26863300403305,
      },
      {
        lat: 13.05690957167666,
        lng: 80.26143786912412,
      },
      {
        lat: 13.05796452281451,
        lng: 80.27091370939867,
      },
      {
        lat: 13.057799110968046,
        lng: 80.26676917730111,
      },
      {
        lat: 13.057112633760568,
        lng: 80.2650352060002,
      },
      {
        lat: 13.057076363418032,
        lng: 80.26885993342793,
      },
      {
        lat: 13.056262506184824,
        lng: 80.27311254581166,
      },
      {
        lat: 13.056282113458286,
        lng: 80.26010349827362,
      },
      {
        lat: 13.057351659574907,
        lng: 80.27305016197548,
      },
      {
        lat: 13.054545261327224,
        lng: 80.25965719640428,
      },
      {
        lat: 13.05627712290806,
        lng: 80.27244116791387,
      },
      {
        lat: 13.054919274658502,
        lng: 80.26828826517011,
      },
      {
        lat: 13.054247245460559,
        lng: 80.26776002331812,
      },
      {
        lat: 13.056821801813408,
        lng: 80.27285584423579,
      },
      {
        lat: 13.055839385012291,
        lng: 80.26226290165152,
      },
      {
        lat: 13.059228885738026,
        lng: 80.27189712040332,
      },
      {
        lat: 13.058385791420859,
        lng: 80.2719686156087,
      },
      {
        lat: 13.056520655060236,
        lng: 80.26011598294738,
      },
      {
        lat: 13.05441880765576,
        lng: 80.2702053422185,
      },
      {
        lat: 13.056473024654336,
        lng: 80.2670818221603,
      },
      {
        lat: 13.056119212679167,
        lng: 80.27088865562378,
      },
      {
        lat: 13.054799174221223,
        lng: 80.2690537443494,
      },
      {
        lat: 13.057734788764671,
        lng: 80.2738375195146,
      },
      {
        lat: 13.056351126151652,
        lng: 80.25935740990596,
      },
      {
        lat: 13.055536241706992,
        lng: 80.26355954249365,
      },
      {
        lat: 13.057802117406993,
        lng: 80.26616615215718,
      },
      {
        lat: 13.055928182054016,
        lng: 80.26725711809713,
      },
      {
        lat: 13.055898842111803,
        lng: 80.27150911645612,
      },
      {
        lat: 13.055480590527148,
        lng: 80.26685592927106,
      },
      {
        lat: 13.05824235585946,
        lng: 80.26822477662961,
      },
      {
        lat: 13.057015317802572,
        lng: 80.26399442552727,
      },
      {
        lat: 13.055222316834984,
        lng: 80.27298595937188,
      },
      {
        lat: 13.05560266914971,
        lng: 80.26175747665786,
      },
      {
        lat: 13.058676156983346,
        lng: 80.27288914915285,
      },
      {
        lat: 13.057382509317646,
        lng: 80.26975150576827,
      },
      {
        lat: 13.0576898625698,
        lng: 80.2682115090954,
      },
      {
        lat: 13.054261304260239,
        lng: 80.26187185372054,
      },
      {
        lat: 13.05713303241289,
        lng: 80.26070714649738,
      },
      {
        lat: 13.054167464331803,
        lng: 80.26227631974028,
      },
      {
        lat: 13.057019959599415,
        lng: 80.273591803023,
      },
      {
        lat: 13.054602168921432,
        lng: 80.26763045907484,
      },
      {
        lat: 13.057206918915886,
        lng: 80.26766579536184,
      },
      {
        lat: 13.05518105160325,
        lng: 80.26139769435792,
      },
      {
        lat: 13.054601653648117,
        lng: 80.25969156117702,
      },
      {
        lat: 13.057074139347487,
        lng: 80.26626286626096,
      },
      {
        lat: 13.05783831678732,
        lng: 80.27075362060687,
      },
      {
        lat: 13.055303571450375,
        lng: 80.26597587882071,
      },
      {
        lat: 13.055608251188541,
        lng: 80.26015864886644,
      },
      {
        lat: 13.05533951070436,
        lng: 80.26217084740715,
      },
      {
        lat: 13.055851475262395,
        lng: 80.2622228791924,
      },
      {
        lat: 13.05669130690082,
        lng: 80.26791677704269,
      },
      {
        lat: 13.055544290062889,
        lng: 80.2662949404339,
      },
      {
        lat: 13.054478324687887,
        lng: 80.27008002502694,
      },
      {
        lat: 13.05667440313533,
        lng: 80.25954792019094,
      },
      {
        lat: 13.055517054953206,
        lng: 80.2704090020437,
      },
      {
        lat: 13.05619379073648,
        lng: 80.26060898111366,
      },
      {
        lat: 13.055816963887088,
        lng: 80.26264273860883,
      },
      {
        lat: 13.054192651816663,
        lng: 80.2682469894864,
      },
      {
        lat: 13.055713696810612,
        lng: 80.27114714830277,
      },
      {
        lat: 13.054101252493306,
        lng: 80.27263162065033,
      },
      {
        lat: 13.054951442748742,
        lng: 80.26530039759534,
      },
    ],
    cases: 111,
    coords: [
      {
        lat: 13.05895848308405,
        lng: 80.27395486533581,
      },
      {
        lat: 13.057486472096496,
        lng: 80.27389774068529,
      },
      {
        lat: 13.055789093727801,
        lng: 80.27371682965395,
      },
      {
        lat: 13.052870130849087,
        lng: 80.27355393885809,
      },
      {
        lat: 13.053266835398645,
        lng: 80.27129722137096,
      },
      {
        lat: 13.053940088296754,
        lng: 80.26477417411644,
      },
      {
        lat: 13.053985096362023,
        lng: 80.26409032585481,
      },
      {
        lat: 13.053885727246453,
        lng: 80.26392030000478,
      },
      {
        lat: 13.053857581731004,
        lng: 80.26333470905345,
      },
      {
        lat: 13.054426200255222,
        lng: 80.25790766155755,
      },
      {
        lat: 13.054481088195304,
        lng: 80.25791368821201,
      },
      {
        lat: 13.055345118415893,
        lng: 80.25778370526709,
      },
      {
        lat: 13.055641016280166,
        lng: 80.25768306466028,
      },
      {
        lat: 13.055814120759205,
        lng: 80.25770613371883,
      },
      {
        lat: 13.056259012078296,
        lng: 80.25772690257482,
      },
      {
        lat: 13.056581110012205,
        lng: 80.25787138109615,
      },
      {
        lat: 13.057486709707923,
        lng: 80.26051339812423,
      },
      {
        lat: 13.057792903581467,
        lng: 80.26210552235531,
      },
      {
        lat: 13.057849929464334,
        lng: 80.2633562781472,
      },
      {
        lat: 13.057831339488326,
        lng: 80.26436233302968,
      },
      {
        lat: 13.05827567526044,
        lng: 80.26548316385583,
      },
      {
        lat: 13.058327992256023,
        lng: 80.26624066576049,
      },
      {
        lat: 13.058282878161894,
        lng: 80.26666739145374,
      },
      {
        lat: 13.058263058217387,
        lng: 80.2673307607875,
      },
      {
        lat: 13.058530633568875,
        lng: 80.26818118398944,
      },
      {
        lat: 13.058633826979758,
        lng: 80.26945934227945,
      },
      {
        lat: 13.05873212274355,
        lng: 80.27008067797121,
      },
      {
        lat: 13.058846817798598,
        lng: 80.27057864484578,
      },
      {
        lat: 13.05905298978656,
        lng: 80.27081712915869,
      },
      {
        lat: 13.059215645583675,
        lng: 80.27122246549965,
      },
      {
        lat: 13.059316944680006,
        lng: 80.27170979914088,
      },
      {
        lat: 13.058961717440935,
        lng: 80.27393407439767,
      },
      {
        lat: 13.05895848308405,
        lng: 80.27395486533581,
      },
    ],
    properties: {
      Ward_No: 115,
      Zone_Name: "TEYNAMPET",
      fillColor: "#f6d04d",
      zname: "TEYNAMPET(115)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.058472874802607,
        lng: 80.24688789991683,
      },
      {
        lat: 13.05294361029033,
        lng: 80.24946324975227,
      },
      {
        lat: 13.0505682622343,
        lng: 80.24205221641591,
      },
      {
        lat: 13.058570254865964,
        lng: 80.24236720418817,
      },
      {
        lat: 13.053538862129518,
        lng: 80.24359111484961,
      },
      {
        lat: 13.054147687185704,
        lng: 80.2496060604177,
      },
      {
        lat: 13.05344548581033,
        lng: 80.23202789312525,
      },
      {
        lat: 13.057185382199364,
        lng: 80.2470671007325,
      },
      {
        lat: 13.052536247634492,
        lng: 80.24539261407612,
      },
      {
        lat: 13.053114184115495,
        lng: 80.24357776921251,
      },
      {
        lat: 13.060151000604149,
        lng: 80.24433876547927,
      },
      {
        lat: 13.057133679378607,
        lng: 80.24857343743071,
      },
      {
        lat: 13.059320742620454,
        lng: 80.24080268834238,
      },
      {
        lat: 13.058091817912919,
        lng: 80.24477468260572,
      },
      {
        lat: 13.052629485336896,
        lng: 80.23964182322699,
      },
      {
        lat: 13.053186048549561,
        lng: 80.23617100532296,
      },
      {
        lat: 13.055516120106976,
        lng: 80.24622604388628,
      },
      {
        lat: 13.052728216151861,
        lng: 80.24481595714414,
      },
      {
        lat: 13.050565320598341,
        lng: 80.24137138269896,
      },
      {
        lat: 13.050346755985508,
        lng: 80.24705484738898,
      },
      {
        lat: 13.058679825208078,
        lng: 80.24639849504278,
      },
      {
        lat: 13.057652559613652,
        lng: 80.24277549001225,
      },
      {
        lat: 13.06126301198268,
        lng: 80.24555713390619,
      },
      {
        lat: 13.057895430568337,
        lng: 80.24809817643879,
      },
      {
        lat: 13.055574334559266,
        lng: 80.24487669989833,
      },
      {
        lat: 13.05025708155084,
        lng: 80.24323645197916,
      },
      {
        lat: 13.059394421129367,
        lng: 80.24106618272994,
      },
      {
        lat: 13.05185618997738,
        lng: 80.23949930876596,
      },
      {
        lat: 13.05371942808836,
        lng: 80.24857595465213,
      },
      {
        lat: 13.05038858242095,
        lng: 80.24623306199065,
      },
      {
        lat: 13.05683458127,
        lng: 80.2480445156352,
      },
      {
        lat: 13.060152677972424,
        lng: 80.24707693732103,
      },
      {
        lat: 13.057222180300366,
        lng: 80.24844702373407,
      },
      {
        lat: 13.049005520587823,
        lng: 80.24680154210122,
      },
      {
        lat: 13.052642064162614,
        lng: 80.23287926783196,
      },
      {
        lat: 13.056826320330602,
        lng: 80.2469992424165,
      },
      {
        lat: 13.050430164561583,
        lng: 80.24176600919515,
      },
      {
        lat: 13.052700715331433,
        lng: 80.24721158042756,
      },
      {
        lat: 13.05828081863897,
        lng: 80.24323291006183,
      },
      {
        lat: 13.052453134430278,
        lng: 80.2322219864391,
      },
      {
        lat: 13.053627289464526,
        lng: 80.23400827399308,
      },
      {
        lat: 13.050391377342116,
        lng: 80.23919889914889,
      },
      {
        lat: 13.055108739130572,
        lng: 80.24723443369027,
      },
      {
        lat: 13.051977228274032,
        lng: 80.24744183236183,
      },
      {
        lat: 13.051293449485636,
        lng: 80.2470766692879,
      },
      {
        lat: 13.051659192216656,
        lng: 80.23614670558142,
      },
      {
        lat: 13.050090296716856,
        lng: 80.2390209438626,
      },
      {
        lat: 13.059264880704875,
        lng: 80.24337152218273,
      },
      {
        lat: 13.05129324040024,
        lng: 80.24622138004842,
      },
      {
        lat: 13.053967337540822,
        lng: 80.24939155155177,
      },
      {
        lat: 13.05092815905857,
        lng: 80.24903423779013,
      },
      {
        lat: 13.051752009503009,
        lng: 80.24302650900005,
      },
      {
        lat: 13.060555441695454,
        lng: 80.23886300874065,
      },
      {
        lat: 13.058970127108205,
        lng: 80.24164507793543,
      },
      {
        lat: 13.059937039577258,
        lng: 80.24125800545573,
      },
      {
        lat: 13.05623772139138,
        lng: 80.24367078486922,
      },
      {
        lat: 13.05861856919966,
        lng: 80.24556204706184,
      },
      {
        lat: 13.05696452713897,
        lng: 80.2443820440405,
      },
      {
        lat: 13.058331008147373,
        lng: 80.24445054406907,
      },
      {
        lat: 13.060548705713373,
        lng: 80.24502819502882,
      },
      {
        lat: 13.058884876922463,
        lng: 80.24757001825874,
      },
      {
        lat: 13.052373481035778,
        lng: 80.23266801069471,
      },
      {
        lat: 13.055716085853856,
        lng: 80.2488183927062,
      },
      {
        lat: 13.050519552170577,
        lng: 80.24057532638288,
      },
      {
        lat: 13.05999107691628,
        lng: 80.24743983502987,
      },
      {
        lat: 13.051407957530742,
        lng: 80.24049969843631,
      },
      {
        lat: 13.052796870042561,
        lng: 80.2460549122796,
      },
      {
        lat: 13.056194600909977,
        lng: 80.24624689614011,
      },
      {
        lat: 13.051094618821619,
        lng: 80.23912512494205,
      },
      {
        lat: 13.050095872842787,
        lng: 80.244863802272,
      },
      {
        lat: 13.058143510607405,
        lng: 80.24344515823462,
      },
      {
        lat: 13.055546842082595,
        lng: 80.24600411909047,
      },
      {
        lat: 13.050260273750725,
        lng: 80.24672479862981,
      },
      {
        lat: 13.052453343412813,
        lng: 80.24787729074608,
      },
      {
        lat: 13.05099868073875,
        lng: 80.24694356287328,
      },
      {
        lat: 13.06039413878512,
        lng: 80.24069841985586,
      },
      {
        lat: 13.054227928431247,
        lng: 80.24931115234725,
      },
      {
        lat: 13.056504360339344,
        lng: 80.24469573340893,
      },
      {
        lat: 13.051663328197293,
        lng: 80.23820253668569,
      },
      {
        lat: 13.061506478140506,
        lng: 80.2438080237796,
      },
      {
        lat: 13.058180971615736,
        lng: 80.24412546422816,
      },
      {
        lat: 13.051946097304615,
        lng: 80.25002525554355,
      },
      {
        lat: 13.057792495713134,
        lng: 80.24409102753485,
      },
      {
        lat: 13.05799499145294,
        lng: 80.24526826179702,
      },
      {
        lat: 13.056788534364165,
        lng: 80.24738410902421,
      },
    ],
    cases: 85,
    coords: [
      {
        lat: 13.0523573433175,
        lng: 80.25099676437183,
      },
      {
        lat: 13.05197540790526,
        lng: 80.25070064716462,
      },
      {
        lat: 13.051704010820535,
        lng: 80.25028029626142,
      },
      {
        lat: 13.049855259120024,
        lng: 80.24836066066185,
      },
      {
        lat: 13.049379400994694,
        lng: 80.24747490311528,
      },
      {
        lat: 13.048770211952553,
        lng: 80.2467682309542,
      },
      {
        lat: 13.048589235465057,
        lng: 80.24648058252653,
      },
      {
        lat: 13.04809327510876,
        lng: 80.24597315367477,
      },
      {
        lat: 13.04858409433541,
        lng: 80.24563533042289,
      },
      {
        lat: 13.049005271660057,
        lng: 80.24489769338267,
      },
      {
        lat: 13.049375015213393,
        lng: 80.24307050518964,
      },
      {
        lat: 13.049920188245812,
        lng: 80.24061898289129,
      },
      {
        lat: 13.049561482300229,
        lng: 80.24057752946983,
      },
      {
        lat: 13.049871460332097,
        lng: 80.23997430126663,
      },
      {
        lat: 13.050954635874112,
        lng: 80.23389235242023,
      },
      {
        lat: 13.051568639051158,
        lng: 80.23170718305164,
      },
      {
        lat: 13.051678620707808,
        lng: 80.2313725829268,
      },
      {
        lat: 13.051789820000595,
        lng: 80.23123817408978,
      },
      {
        lat: 13.053897522893047,
        lng: 80.23189565877354,
      },
      {
        lat: 13.053799327610017,
        lng: 80.23413974278421,
      },
      {
        lat: 13.053283033996076,
        lng: 80.23752009696122,
      },
      {
        lat: 13.052913858840302,
        lng: 80.23934447626353,
      },
      {
        lat: 13.052408585866107,
        lng: 80.24085906144232,
      },
      {
        lat: 13.052388379452784,
        lng: 80.24121459925959,
      },
      {
        lat: 13.052457779211059,
        lng: 80.24159158914222,
      },
      {
        lat: 13.052750046176282,
        lng: 80.2418334302897,
      },
      {
        lat: 13.054252604338213,
        lng: 80.24246481608495,
      },
      {
        lat: 13.054766928000914,
        lng: 80.24243825280492,
      },
      {
        lat: 13.055459518412107,
        lng: 80.24229912868087,
      },
      {
        lat: 13.055974247329575,
        lng: 80.24233919324709,
      },
      {
        lat: 13.05644572663755,
        lng: 80.24262393852472,
      },
      {
        lat: 13.05680428640828,
        lng: 80.2427319560672,
      },
      {
        lat: 13.057005056013443,
        lng: 80.24264141520062,
      },
      {
        lat: 13.057137236369737,
        lng: 80.24230714014394,
      },
      {
        lat: 13.057601286008095,
        lng: 80.24137037490756,
      },
      {
        lat: 13.057890040765846,
        lng: 80.2410347747213,
      },
      {
        lat: 13.058335910264601,
        lng: 80.24078668653334,
      },
      {
        lat: 13.058491403986766,
        lng: 80.24060768714709,
      },
      {
        lat: 13.05878029382959,
        lng: 80.24029429624287,
      },
      {
        lat: 13.059114864809711,
        lng: 80.24013599170459,
      },
      {
        lat: 13.059402944142942,
        lng: 80.23968934510772,
      },
      {
        lat: 13.06024508454079,
        lng: 80.238394013186,
      },
      {
        lat: 13.06040138877243,
        lng: 80.23834826949204,
      },
      {
        lat: 13.060560124532277,
        lng: 80.23870229287543,
      },
      {
        lat: 13.060573768107483,
        lng: 80.2409454303648,
      },
      {
        lat: 13.06070919050146,
        lng: 80.24114417807782,
      },
      {
        lat: 13.060710271180682,
        lng: 80.24132185233455,
      },
      {
        lat: 13.060599648086567,
        lng: 80.24152268239449,
      },
      {
        lat: 13.060557614241254,
        lng: 80.24196724664444,
      },
      {
        lat: 13.060560721194024,
        lng: 80.24247806013209,
      },
      {
        lat: 13.060473141296198,
        lng: 80.24278974729808,
      },
      {
        lat: 13.06054146037525,
        lng: 80.24298906292402,
      },
      {
        lat: 13.060899614891216,
        lng: 80.24303045262008,
      },
      {
        lat: 13.061415154318107,
        lng: 80.24320377287894,
      },
      {
        lat: 13.061751076147429,
        lng: 80.24326756116139,
      },
      {
        lat: 13.061783710371612,
        lng: 80.24495527729462,
      },
      {
        lat: 13.0616508545908,
        lng: 80.24517850594087,
      },
      {
        lat: 13.061651800185118,
        lng: 80.24533397091538,
      },
      {
        lat: 13.061898250927802,
        lng: 80.24539851641482,
      },
      {
        lat: 13.062210543435278,
        lng: 80.24522301806803,
      },
      {
        lat: 13.061895512732155,
        lng: 80.24646688772654,
      },
      {
        lat: 13.06158353186749,
        lng: 80.2468073400001,
      },
      {
        lat: 13.060644902503727,
        lng: 80.24738696553821,
      },
      {
        lat: 13.059783991777016,
        lng: 80.24783600567015,
      },
      {
        lat: 13.05819001556848,
        lng: 80.24823927886408,
      },
      {
        lat: 13.055396662056845,
        lng: 80.24938029755748,
      },
      {
        lat: 13.0523573433175,
        lng: 80.25099676437183,
      },
    ],
    properties: {
      Ward_No: 113,
      Zone_Name: "TEYNAMPET",
      fillColor: "#f6d04d",
      zname: "TEYNAMPET(113)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.048360491645283,
        lng: 80.23054579906912,
      },
      {
        lat: 13.04570080367994,
        lng: 80.24681858918208,
      },
      {
        lat: 13.0473102107665,
        lng: 80.2441566969472,
      },
      {
        lat: 13.048418820882356,
        lng: 80.24329572976774,
      },
      {
        lat: 13.048837765967368,
        lng: 80.23855538875289,
      },
      {
        lat: 13.035812201118796,
        lng: 80.24005390078635,
      },
      {
        lat: 13.04819968993005,
        lng: 80.23432916481711,
      },
      {
        lat: 13.039933855017246,
        lng: 80.24711484175653,
      },
      {
        lat: 13.04794780505116,
        lng: 80.23829770171241,
      },
      {
        lat: 13.046396885203162,
        lng: 80.24387058986377,
      },
      {
        lat: 13.03290742746251,
        lng: 80.24101192507977,
      },
      {
        lat: 13.047216158965897,
        lng: 80.24117195655039,
      },
      {
        lat: 13.040290755108948,
        lng: 80.24466567458461,
      },
      {
        lat: 13.040441702756869,
        lng: 80.24609121582762,
      },
      {
        lat: 13.03574856008873,
        lng: 80.23942244017867,
      },
      {
        lat: 13.042057517083727,
        lng: 80.2452368687075,
      },
      {
        lat: 13.039566614180735,
        lng: 80.24141809459586,
      },
      {
        lat: 13.035560176109628,
        lng: 80.23976277920113,
      },
      {
        lat: 13.045941699189774,
        lng: 80.24749740306093,
      },
      {
        lat: 13.044656767587067,
        lng: 80.2421374622495,
      },
      {
        lat: 13.033911144462245,
        lng: 80.24147346170145,
      },
      {
        lat: 13.046516323467896,
        lng: 80.24589950126814,
      },
      {
        lat: 13.04766600423334,
        lng: 80.24148808844437,
      },
      {
        lat: 13.044694933407936,
        lng: 80.24503371132622,
      },
      {
        lat: 13.048258895259023,
        lng: 80.24172036687996,
      },
      {
        lat: 13.047414786612645,
        lng: 80.24111743774566,
      },
      {
        lat: 13.047499036192432,
        lng: 80.23774179854703,
      },
      {
        lat: 13.045687790253226,
        lng: 80.24135971307041,
      },
      {
        lat: 13.049130996528977,
        lng: 80.24780974080936,
      },
      {
        lat: 13.035262523831493,
        lng: 80.24395618099788,
      },
      {
        lat: 13.051244640377652,
        lng: 80.23268740677987,
      },
      {
        lat: 13.046734538092473,
        lng: 80.24105856887715,
      },
      {
        lat: 13.034066366281282,
        lng: 80.24027605576399,
      },
      {
        lat: 13.036981229131761,
        lng: 80.24364070708552,
      },
      {
        lat: 13.035212360746891,
        lng: 80.24253270677262,
      },
      {
        lat: 13.039148186223079,
        lng: 80.24251663515193,
      },
      {
        lat: 13.047759186223264,
        lng: 80.24342378208657,
      },
      {
        lat: 13.032766710969272,
        lng: 80.2390548083163,
      },
      {
        lat: 13.040074694360436,
        lng: 80.24171907967194,
      },
      {
        lat: 13.050372702412048,
        lng: 80.24967933433823,
      },
      {
        lat: 13.046664330825829,
        lng: 80.24180832254568,
      },
      {
        lat: 13.03384232894606,
        lng: 80.24197974339312,
      },
      {
        lat: 13.043588260000375,
        lng: 80.24679751086002,
      },
      {
        lat: 13.047091541679602,
        lng: 80.24599657561934,
      },
      {
        lat: 13.04838368005769,
        lng: 80.24104767102726,
      },
      {
        lat: 13.046038906338952,
        lng: 80.24522314517569,
      },
      {
        lat: 13.048873581790982,
        lng: 80.24924755073233,
      },
      {
        lat: 13.047802428406893,
        lng: 80.23750199968215,
      },
      {
        lat: 13.046768356279893,
        lng: 80.2411915267609,
      },
      {
        lat: 13.05034884787616,
        lng: 80.2492104773961,
      },
      {
        lat: 13.045632257649073,
        lng: 80.23992826460328,
      },
      {
        lat: 13.040463363826314,
        lng: 80.24505755186071,
      },
      {
        lat: 13.046937828061697,
        lng: 80.24614298319014,
      },
      {
        lat: 13.04624011830868,
        lng: 80.24384374242052,
      },
      {
        lat: 13.047192844790128,
        lng: 80.24568726743146,
      },
      {
        lat: 13.039075433989076,
        lng: 80.2443330128609,
      },
      {
        lat: 13.037101947724413,
        lng: 80.24006651656606,
      },
      {
        lat: 13.042415799451756,
        lng: 80.24740761120705,
      },
      {
        lat: 13.052953707383441,
        lng: 80.23092949471865,
      },
      {
        lat: 13.039940106913072,
        lng: 80.24609225411953,
      },
      {
        lat: 13.039349720784184,
        lng: 80.24706306208175,
      },
      {
        lat: 13.046269836712764,
        lng: 80.24836733944706,
      },
      {
        lat: 13.048890051393292,
        lng: 80.23343950151991,
      },
      {
        lat: 13.050032411902729,
        lng: 80.23147193817607,
      },
      {
        lat: 13.043125488924286,
        lng: 80.2476472280264,
      },
      {
        lat: 13.034434383859672,
        lng: 80.24099002885302,
      },
      {
        lat: 13.0390918317275,
        lng: 80.24194575921206,
      },
      {
        lat: 13.050654928137465,
        lng: 80.23182537706498,
      },
      {
        lat: 13.048923533696449,
        lng: 80.23851101536667,
      },
      {
        lat: 13.04553071993628,
        lng: 80.24234209893703,
      },
      {
        lat: 13.04989906910274,
        lng: 80.23440082094322,
      },
      {
        lat: 13.039620722865738,
        lng: 80.24234072807484,
      },
      {
        lat: 13.047616773339135,
        lng: 80.23640119918852,
      },
      {
        lat: 13.048627049621937,
        lng: 80.2430624073728,
      },
      {
        lat: 13.047662242170361,
        lng: 80.24652453375536,
      },
      {
        lat: 13.034182092556263,
        lng: 80.2413179225744,
      },
      {
        lat: 13.03874015798517,
        lng: 80.24388549877465,
      },
      {
        lat: 13.036853638508752,
        lng: 80.24428915874365,
      },
      {
        lat: 13.03698457983188,
        lng: 80.24399216586193,
      },
      {
        lat: 13.047944784901908,
        lng: 80.2351599945138,
      },
      {
        lat: 13.049221179622947,
        lng: 80.24263880266015,
      },
      {
        lat: 13.039817721547228,
        lng: 80.24314316847938,
      },
      {
        lat: 13.048397237502076,
        lng: 80.23833574428936,
      },
      {
        lat: 13.048000105287567,
        lng: 80.24714016865626,
      },
      {
        lat: 13.048839291802107,
        lng: 80.23223344635922,
      },
      {
        lat: 13.048679160334567,
        lng: 80.23195876243054,
      },
      {
        lat: 13.032796854598278,
        lng: 80.23926105228176,
      },
      {
        lat: 13.050910907576599,
        lng: 80.2304549254151,
      },
      {
        lat: 13.047980027233251,
        lng: 80.24074138042667,
      },
      {
        lat: 13.04553334352377,
        lng: 80.24648557661874,
      },
      {
        lat: 13.036712854660161,
        lng: 80.24183296484335,
      },
      {
        lat: 13.047031965065745,
        lng: 80.23918688299786,
      },
      {
        lat: 13.044164239805797,
        lng: 80.24324467382777,
      },
      {
        lat: 13.04863923040321,
        lng: 80.23029582980013,
      },
      {
        lat: 13.049134293187722,
        lng: 80.23219578353962,
      },
      {
        lat: 13.037276364930829,
        lng: 80.24042868133297,
      },
      {
        lat: 13.045572893198349,
        lng: 80.24526844822847,
      },
      {
        lat: 13.037684063729724,
        lng: 80.24392480633823,
      },
      {
        lat: 13.049461444861157,
        lng: 80.23898152137613,
      },
      {
        lat: 13.046273937803091,
        lng: 80.24753906865192,
      },
      {
        lat: 13.044889582715287,
        lng: 80.24325055243915,
      },
      {
        lat: 13.052465857083696,
        lng: 80.2313981864107,
      },
      {
        lat: 13.048141916441237,
        lng: 80.2345139432387,
      },
      {
        lat: 13.043907138405096,
        lng: 80.24648874854924,
      },
      {
        lat: 13.047325888525853,
        lng: 80.23635046645695,
      },
      {
        lat: 13.0360992195099,
        lng: 80.2419326184949,
      },
      {
        lat: 13.04240232093352,
        lng: 80.24586521775085,
      },
      {
        lat: 13.044519012164852,
        lng: 80.24661479140663,
      },
      {
        lat: 13.035013828883844,
        lng: 80.24250734781373,
      },
      {
        lat: 13.048423016282761,
        lng: 80.24739163002371,
      },
      {
        lat: 13.037768682128327,
        lng: 80.23957893277323,
      },
      {
        lat: 13.049003634205537,
        lng: 80.23467361556403,
      },
      {
        lat: 13.050791645786767,
        lng: 80.23400779505715,
      },
      {
        lat: 13.037308678878288,
        lng: 80.24400579641024,
      },
      {
        lat: 13.04594781285233,
        lng: 80.24351885628154,
      },
      {
        lat: 13.0345921980404,
        lng: 80.23863778188708,
      },
      {
        lat: 13.047361999407489,
        lng: 80.24827911283045,
      },
      {
        lat: 13.047646254980984,
        lng: 80.2446246217122,
      },
      {
        lat: 13.034766324561417,
        lng: 80.23942969404018,
      },
      {
        lat: 13.047900911504572,
        lng: 80.23700624133572,
      },
      {
        lat: 13.035795106571864,
        lng: 80.23950614637499,
      },
      {
        lat: 13.045124249420473,
        lng: 80.24503595576346,
      },
      {
        lat: 13.035539786583577,
        lng: 80.2395911008158,
      },
      {
        lat: 13.035304741482515,
        lng: 80.24182021469296,
      },
      {
        lat: 13.048238244435398,
        lng: 80.23627853348545,
      },
      {
        lat: 13.049441358907144,
        lng: 80.23576981209919,
      },
      {
        lat: 13.036479049953561,
        lng: 80.24378557950212,
      },
      {
        lat: 13.03276726431416,
        lng: 80.23908098244935,
      },
      {
        lat: 13.037051724116935,
        lng: 80.24041175154264,
      },
      {
        lat: 13.049689633647661,
        lng: 80.23923736424274,
      },
      {
        lat: 13.035766924717171,
        lng: 80.24371024525719,
      },
      {
        lat: 13.046747928897803,
        lng: 80.2410218176059,
      },
      {
        lat: 13.049515050236462,
        lng: 80.23515275440683,
      },
      {
        lat: 13.045862503599492,
        lng: 80.24356435762085,
      },
      {
        lat: 13.035938674554043,
        lng: 80.24244369886755,
      },
      {
        lat: 13.043886678953287,
        lng: 80.24669355758923,
      },
      {
        lat: 13.04534874960356,
        lng: 80.2428631391078,
      },
      {
        lat: 13.036811480512606,
        lng: 80.24247915850678,
      },
      {
        lat: 13.043371439484105,
        lng: 80.245053565566,
      },
      {
        lat: 13.033319901147067,
        lng: 80.24069184749119,
      },
      {
        lat: 13.04876228709627,
        lng: 80.24797339171892,
      },
      {
        lat: 13.047684911234363,
        lng: 80.23505193848827,
      },
      {
        lat: 13.04638645684197,
        lng: 80.24471698364617,
      },
      {
        lat: 13.048696789146296,
        lng: 80.23513907247829,
      },
      {
        lat: 13.04681438800692,
        lng: 80.24803830344736,
      },
      {
        lat: 13.037910562098864,
        lng: 80.24318278381709,
      },
      {
        lat: 13.033773049471,
        lng: 80.2387279572536,
      },
      {
        lat: 13.03671370722279,
        lng: 80.24110491542467,
      },
      {
        lat: 13.034965821617561,
        lng: 80.24297187534417,
      },
      {
        lat: 13.047947874371273,
        lng: 80.2385969107595,
      },
      {
        lat: 13.035318585799695,
        lng: 80.24273600946965,
      },
      {
        lat: 13.048491837361881,
        lng: 80.2405684785598,
      },
      {
        lat: 13.040298028300558,
        lng: 80.24553597557228,
      },
      {
        lat: 13.04897090186579,
        lng: 80.24918298540535,
      },
      {
        lat: 13.045415668166443,
        lng: 80.24492904300229,
      },
      {
        lat: 13.033233472723708,
        lng: 80.24018261134533,
      },
      {
        lat: 13.05052235382085,
        lng: 80.23276204431032,
      },
      {
        lat: 13.034210729579003,
        lng: 80.23885616669097,
      },
      {
        lat: 13.049005731304652,
        lng: 80.24739152047958,
      },
      {
        lat: 13.047669749744406,
        lng: 80.24276284777615,
      },
      {
        lat: 13.03607350355692,
        lng: 80.24133346948095,
      },
      {
        lat: 13.050560497405042,
        lng: 80.24911298114644,
      },
      {
        lat: 13.032525804425106,
        lng: 80.24026982126624,
      },
      {
        lat: 13.039201964124961,
        lng: 80.24003475011668,
      },
      {
        lat: 13.043554065984065,
        lng: 80.24672168819912,
      },
      {
        lat: 13.036610373122963,
        lng: 80.24320103440756,
      },
      {
        lat: 13.049111786444017,
        lng: 80.23645044505562,
      },
      {
        lat: 13.03704664667768,
        lng: 80.24090080368924,
      },
      {
        lat: 13.047860417727309,
        lng: 80.24045039810912,
      },
      {
        lat: 13.051667161318434,
        lng: 80.23055799766435,
      },
    ],
    cases: 170,
    coords: [
      {
        lat: 13.051805489730574,
        lng: 80.25043747094685,
      },
      {
        lat: 13.05022438261778,
        lng: 80.25011486176707,
      },
      {
        lat: 13.0485421874751,
        lng: 80.24979542929928,
      },
      {
        lat: 13.047191425524106,
        lng: 80.24871687469775,
      },
      {
        lat: 13.043713806552391,
        lng: 80.24787876645313,
      },
      {
        lat: 13.039519722739715,
        lng: 80.24711345570044,
      },
      {
        lat: 13.03900366015251,
        lng: 80.24698435554336,
      },
      {
        lat: 13.039422402205163,
        lng: 80.24584633592778,
      },
      {
        lat: 13.03701873638448,
        lng: 80.24477669232476,
      },
      {
        lat: 13.036368260537495,
        lng: 80.24464872974198,
      },
      {
        lat: 13.035941671497255,
        lng: 80.24449662772564,
      },
      {
        lat: 13.035334241262634,
        lng: 80.24407912075804,
      },
      {
        lat: 13.034814796353404,
        lng: 80.24339393369048,
      },
      {
        lat: 13.034341914671613,
        lng: 80.2429975326251,
      },
      {
        lat: 13.033036633604915,
        lng: 80.2420298162142,
      },
      {
        lat: 13.032471301932564,
        lng: 80.24116706052685,
      },
      {
        lat: 13.032038677006321,
        lng: 80.23904417248055,
      },
      {
        lat: 13.034469528525475,
        lng: 80.23742076765257,
      },
      {
        lat: 13.03526241607216,
        lng: 80.23881597538902,
      },
      {
        lat: 13.035610377095542,
        lng: 80.23928033655422,
      },
      {
        lat: 13.036238623818878,
        lng: 80.23939963448592,
      },
      {
        lat: 13.03903303328322,
        lng: 80.23971867577703,
      },
      {
        lat: 13.040163650010888,
        lng: 80.23989602949446,
      },
      {
        lat: 13.040322038041683,
        lng: 80.24014391890853,
      },
      {
        lat: 13.040324501263015,
        lng: 80.24054889663824,
      },
      {
        lat: 13.039900796338983,
        lng: 80.24310708896799,
      },
      {
        lat: 13.039998519753048,
        lng: 80.24369818290579,
      },
      {
        lat: 13.040187903267073,
        lng: 80.24388350252381,
      },
      {
        lat: 13.041131220744694,
        lng: 80.2442182100865,
      },
      {
        lat: 13.040950742723178,
        lng: 80.24549704072146,
      },
      {
        lat: 13.041108183361153,
        lng: 80.24558916947043,
      },
      {
        lat: 13.041319656866124,
        lng: 80.24424776903949,
      },
      {
        lat: 13.043485819607072,
        lng: 80.24444751239889,
      },
      {
        lat: 13.043855142309202,
        lng: 80.24111982812497,
      },
      {
        lat: 13.045236652544393,
        lng: 80.24022831868197,
      },
      {
        lat: 13.046118282369452,
        lng: 80.23949405157211,
      },
      {
        lat: 13.046423914557359,
        lng: 80.23907068271038,
      },
      {
        lat: 13.046763184890931,
        lng: 80.23784371765426,
      },
      {
        lat: 13.047949186619428,
        lng: 80.23014484151538,
      },
      {
        lat: 13.049836393669883,
        lng: 80.23005236373491,
      },
      {
        lat: 13.054027726466705,
        lng: 80.23078269580681,
      },
      {
        lat: 13.054098967922545,
        lng: 80.23191616431728,
      },
      {
        lat: 13.053897522893047,
        lng: 80.23189565877354,
      },
      {
        lat: 13.051789820000595,
        lng: 80.23123817408978,
      },
      {
        lat: 13.051678620707808,
        lng: 80.2313725829268,
      },
      {
        lat: 13.051568639051158,
        lng: 80.23170718305164,
      },
      {
        lat: 13.050954635874112,
        lng: 80.23389235242023,
      },
      {
        lat: 13.049871460332097,
        lng: 80.23997430126663,
      },
      {
        lat: 13.049561482300229,
        lng: 80.24057752946983,
      },
      {
        lat: 13.049920188245812,
        lng: 80.24061898289129,
      },
      {
        lat: 13.049375015213393,
        lng: 80.24307050518964,
      },
      {
        lat: 13.049005271660057,
        lng: 80.24489769338267,
      },
      {
        lat: 13.04858409433541,
        lng: 80.24563533042289,
      },
      {
        lat: 13.04809327510876,
        lng: 80.24597315367477,
      },
      {
        lat: 13.048589235465057,
        lng: 80.24648058252653,
      },
      {
        lat: 13.048770211952553,
        lng: 80.2467682309542,
      },
      {
        lat: 13.049379400994694,
        lng: 80.24747490311528,
      },
      {
        lat: 13.049855259120024,
        lng: 80.24836066066185,
      },
      {
        lat: 13.051704010820535,
        lng: 80.25028029626142,
      },
      {
        lat: 13.051805489730574,
        lng: 80.25043747094685,
      },
    ],
    properties: {
      Ward_No: 117,
      Zone_Name: "TEYNAMPET",
      fillColor: "#de4307",
      zname: "TEYNAMPET(117)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.03361036751493,
        lng: 80.25023575897673,
      },
      {
        lat: 13.026059144814347,
        lng: 80.2532635594389,
      },
      {
        lat: 13.027206481118116,
        lng: 80.25257375404968,
      },
      {
        lat: 13.027132959059056,
        lng: 80.24756445198547,
      },
      {
        lat: 13.024409527790956,
        lng: 80.25089144435611,
      },
      {
        lat: 13.026001069297113,
        lng: 80.24774672722017,
      },
      {
        lat: 13.028101975218577,
        lng: 80.24189338026825,
      },
      {
        lat: 13.023482616111945,
        lng: 80.24592530864652,
      },
      {
        lat: 13.031854106753867,
        lng: 80.25151262919562,
      },
      {
        lat: 13.031506687077849,
        lng: 80.24234863353384,
      },
      {
        lat: 13.032897649157512,
        lng: 80.24835679477701,
      },
      {
        lat: 13.027191009801351,
        lng: 80.25356315955167,
      },
      {
        lat: 13.02508031951067,
        lng: 80.25010711935919,
      },
      {
        lat: 13.030663653551594,
        lng: 80.25106764596043,
      },
      {
        lat: 13.031472655137947,
        lng: 80.2425630438873,
      },
      {
        lat: 13.025564233581543,
        lng: 80.25267691031253,
      },
      {
        lat: 13.035384480535143,
        lng: 80.2476873434537,
      },
      {
        lat: 13.027628083326267,
        lng: 80.24127603758744,
      },
      {
        lat: 13.030106352596901,
        lng: 80.24762573837208,
      },
      {
        lat: 13.031970420741,
        lng: 80.24427174879509,
      },
      {
        lat: 13.031977674717906,
        lng: 80.24854605605596,
      },
      {
        lat: 13.029072389039776,
        lng: 80.23964239056951,
      },
      {
        lat: 13.021121005815159,
        lng: 80.24996340946834,
      },
      {
        lat: 13.032382882794698,
        lng: 80.24616351355675,
      },
      {
        lat: 13.028234874131543,
        lng: 80.24099568626718,
      },
      {
        lat: 13.03424222147792,
        lng: 80.2490893931621,
      },
      {
        lat: 13.028885602983681,
        lng: 80.24265054952667,
      },
      {
        lat: 13.032330435845248,
        lng: 80.24099544121934,
      },
      {
        lat: 13.021339196756312,
        lng: 80.24943995803467,
      },
      {
        lat: 13.032041328148214,
        lng: 80.24163499910048,
      },
      {
        lat: 13.02693744526848,
        lng: 80.2422160312351,
      },
      {
        lat: 13.026291819733876,
        lng: 80.24213088348745,
      },
      {
        lat: 13.022854242598669,
        lng: 80.24755796056007,
      },
      {
        lat: 13.026590503195727,
        lng: 80.2422249448699,
      },
      {
        lat: 13.031025235940518,
        lng: 80.2492326160667,
      },
      {
        lat: 13.01977105290533,
        lng: 80.25055462678144,
      },
      {
        lat: 13.02679664758655,
        lng: 80.2441017987752,
      },
      {
        lat: 13.03455326328085,
        lng: 80.2467149172451,
      },
      {
        lat: 13.02956595902748,
        lng: 80.24269110520278,
      },
      {
        lat: 13.024706079677514,
        lng: 80.24776724190782,
      },
      {
        lat: 13.024842828265093,
        lng: 80.25029904760294,
      },
      {
        lat: 13.024364544153052,
        lng: 80.24501139585955,
      },
      {
        lat: 13.026776904608305,
        lng: 80.247969213156,
      },
      {
        lat: 13.034294921005667,
        lng: 80.25033652650829,
      },
      {
        lat: 13.03669313745053,
        lng: 80.24623907185541,
      },
      {
        lat: 13.024819482139854,
        lng: 80.24879412628441,
      },
      {
        lat: 13.026653492608922,
        lng: 80.24683808841625,
      },
      {
        lat: 13.031474687672658,
        lng: 80.24618699757426,
      },
      {
        lat: 13.034145399807116,
        lng: 80.25324020173656,
      },
      {
        lat: 13.028995824771947,
        lng: 80.23989761524348,
      },
      {
        lat: 13.028135084078238,
        lng: 80.24400816138743,
      },
      {
        lat: 13.021964176611924,
        lng: 80.24835078292493,
      },
      {
        lat: 13.025689110600519,
        lng: 80.25271157589253,
      },
      {
        lat: 13.02478336083817,
        lng: 80.25172906909498,
      },
      {
        lat: 13.032667158464623,
        lng: 80.24620630222631,
      },
      {
        lat: 13.033897883876495,
        lng: 80.24871143332673,
      },
      {
        lat: 13.03180674340658,
        lng: 80.23927868530856,
      },
      {
        lat: 13.022473307509214,
        lng: 80.24927012618585,
      },
      {
        lat: 13.029743444538225,
        lng: 80.24939516985752,
      },
      {
        lat: 13.03326472650432,
        lng: 80.25077760154237,
      },
      {
        lat: 13.024839624546356,
        lng: 80.24998254138403,
      },
      {
        lat: 13.0287798972694,
        lng: 80.24773743637239,
      },
      {
        lat: 13.0247513617914,
        lng: 80.24724559366854,
      },
      {
        lat: 13.029665501603553,
        lng: 80.24674393106636,
      },
      {
        lat: 13.034997391209608,
        lng: 80.24906909270659,
      },
      {
        lat: 13.023854670978823,
        lng: 80.2479917233602,
      },
      {
        lat: 13.022413668071493,
        lng: 80.24816107935192,
      },
      {
        lat: 13.024226573358685,
        lng: 80.2527530176209,
      },
      {
        lat: 13.02429133962623,
        lng: 80.25142510079476,
      },
      {
        lat: 13.030346059240834,
        lng: 80.24973338786663,
      },
      {
        lat: 13.026485680678272,
        lng: 80.24062523723542,
      },
      {
        lat: 13.021561671066971,
        lng: 80.25124645638232,
      },
      {
        lat: 13.027178853439553,
        lng: 80.24172677276258,
      },
      {
        lat: 13.020973432973745,
        lng: 80.24855870270295,
      },
      {
        lat: 13.026986359709317,
        lng: 80.24647526428387,
      },
      {
        lat: 13.026850430336932,
        lng: 80.24427461882061,
      },
      {
        lat: 13.030847372462576,
        lng: 80.2448318149319,
      },
      {
        lat: 13.034219453468566,
        lng: 80.25317956372784,
      },
      {
        lat: 13.031710581820453,
        lng: 80.24788173268314,
      },
      {
        lat: 13.025527146135612,
        lng: 80.24774285373508,
      },
      {
        lat: 13.024477697348606,
        lng: 80.24573461469403,
      },
      {
        lat: 13.023180343679185,
        lng: 80.24660811288126,
      },
      {
        lat: 13.024982581315657,
        lng: 80.24674994026155,
      },
      {
        lat: 13.037411284677628,
        lng: 80.2456422629114,
      },
      {
        lat: 13.034699397593597,
        lng: 80.25374183470737,
      },
      {
        lat: 13.031456584289927,
        lng: 80.23977570158034,
      },
      {
        lat: 13.027956380492316,
        lng: 80.24596058369042,
      },
      {
        lat: 13.02779677125241,
        lng: 80.25057718653065,
      },
      {
        lat: 13.031948103750754,
        lng: 80.24600614632352,
      },
      {
        lat: 13.027931502661398,
        lng: 80.24741570844459,
      },
      {
        lat: 13.039002859973916,
        lng: 80.24642061238296,
      },
      {
        lat: 13.031770214322885,
        lng: 80.24093995469883,
      },
      {
        lat: 13.032843748949453,
        lng: 80.24745903225384,
      },
      {
        lat: 13.034979847184381,
        lng: 80.24434211529744,
      },
      {
        lat: 13.032321243670292,
        lng: 80.24098923184262,
      },
      {
        lat: 13.026767126935816,
        lng: 80.24280402557638,
      },
      {
        lat: 13.038324658467543,
        lng: 80.24617574248222,
      },
      {
        lat: 13.03311372812863,
        lng: 80.24303337060213,
      },
      {
        lat: 13.025761753896175,
        lng: 80.24390922280743,
      },
      {
        lat: 13.02646776833593,
        lng: 80.25060630579269,
      },
      {
        lat: 13.0279164747841,
        lng: 80.24016961160645,
      },
      {
        lat: 13.025736880468743,
        lng: 80.25277178878584,
      },
      {
        lat: 13.027210659742604,
        lng: 80.24260150729684,
      },
      {
        lat: 13.02762326759937,
        lng: 80.24728393501,
      },
      {
        lat: 13.02536769221412,
        lng: 80.2461067291303,
      },
      {
        lat: 13.023583093812183,
        lng: 80.24746266941837,
      },
      {
        lat: 13.035804370610556,
        lng: 80.24492756531437,
      },
      {
        lat: 13.026312192365824,
        lng: 80.24908235977772,
      },
      {
        lat: 13.026688005816842,
        lng: 80.24078783204473,
      },
      {
        lat: 13.024623640876293,
        lng: 80.2470256108767,
      },
      {
        lat: 13.032819590471677,
        lng: 80.24307113254275,
      },
      {
        lat: 13.02955517617742,
        lng: 80.24264148801676,
      },
      {
        lat: 13.025160369577108,
        lng: 80.24811653291154,
      },
      {
        lat: 13.024760069562264,
        lng: 80.2483495384788,
      },
      {
        lat: 13.034303423832524,
        lng: 80.24727524541571,
      },
      {
        lat: 13.033781229640484,
        lng: 80.25322849697393,
      },
      {
        lat: 13.022428944753866,
        lng: 80.25204961107886,
      },
      {
        lat: 13.032826959247526,
        lng: 80.25181483566179,
      },
      {
        lat: 13.025697368603721,
        lng: 80.2523092682122,
      },
      {
        lat: 13.025626413413601,
        lng: 80.24585501964518,
      },
      {
        lat: 13.030615287892353,
        lng: 80.2473256124965,
      },
      {
        lat: 13.024203742781411,
        lng: 80.24608737047024,
      },
      {
        lat: 13.023064441848659,
        lng: 80.25036008798396,
      },
      {
        lat: 13.028345255336585,
        lng: 80.24059288394741,
      },
      {
        lat: 13.03430256993192,
        lng: 80.24911375668155,
      },
      {
        lat: 13.027173118585774,
        lng: 80.23992402755324,
      },
      {
        lat: 13.03369518283842,
        lng: 80.24635173874263,
      },
      {
        lat: 13.033692757432739,
        lng: 80.25270401269577,
      },
      {
        lat: 13.025323824436015,
        lng: 80.2497426261002,
      },
      {
        lat: 13.027972152622503,
        lng: 80.24562345621163,
      },
      {
        lat: 13.029659146315648,
        lng: 80.24434639891014,
      },
      {
        lat: 13.033884016916808,
        lng: 80.25063800502313,
      },
      {
        lat: 13.028087012156536,
        lng: 80.24930993641325,
      },
      {
        lat: 13.024552642755172,
        lng: 80.2461946122666,
      },
      {
        lat: 13.024598451517907,
        lng: 80.24726183912645,
      },
      {
        lat: 13.025676869833084,
        lng: 80.24290598430743,
      },
      {
        lat: 13.025523341292788,
        lng: 80.24609471307141,
      },
      {
        lat: 13.020261370345494,
        lng: 80.25118892114669,
      },
      {
        lat: 13.021764792635015,
        lng: 80.25032679692761,
      },
      {
        lat: 13.033216160662425,
        lng: 80.24358010590497,
      },
      {
        lat: 13.033748338638498,
        lng: 80.24742256155415,
      },
      {
        lat: 13.025750628595372,
        lng: 80.25293399505888,
      },
      {
        lat: 13.030495506881394,
        lng: 80.2438929449508,
      },
      {
        lat: 13.034129169265084,
        lng: 80.25228670975702,
      },
      {
        lat: 13.02358494392244,
        lng: 80.251005728139,
      },
      {
        lat: 13.034376998203655,
        lng: 80.24742748671689,
      },
      {
        lat: 13.021837560071955,
        lng: 80.2521072065582,
      },
      {
        lat: 13.022930574280306,
        lng: 80.24689593652808,
      },
      {
        lat: 13.034773022596891,
        lng: 80.24652134298167,
      },
      {
        lat: 13.024562068404185,
        lng: 80.24989410613163,
      },
      {
        lat: 13.027391462990852,
        lng: 80.23991221464541,
      },
      {
        lat: 13.028256843332748,
        lng: 80.24280231086979,
      },
    ],
    cases: 152,
    coords: [
      {
        lat: 13.03900366015251,
        lng: 80.24698435554336,
      },
      {
        lat: 13.035571657358322,
        lng: 80.2462793289658,
      },
      {
        lat: 13.035218070067264,
        lng: 80.25444609753245,
      },
      {
        lat: 13.029096933907578,
        lng: 80.24993775490147,
      },
      {
        lat: 13.028895178724685,
        lng: 80.24991721778646,
      },
      {
        lat: 13.02867210367485,
        lng: 80.2500748180785,
      },
      {
        lat: 13.027963079942477,
        lng: 80.25137100677341,
      },
      {
        lat: 13.0276554018895,
        lng: 80.25235237407547,
      },
      {
        lat: 13.02732829806998,
        lng: 80.2538232874546,
      },
      {
        lat: 13.027149350975819,
        lng: 80.253869291173,
      },
      {
        lat: 13.026497522199923,
        lng: 80.2535188938262,
      },
      {
        lat: 13.025329224957995,
        lng: 80.25297266589281,
      },
      {
        lat: 13.02483502340944,
        lng: 80.25275440223444,
      },
      {
        lat: 13.0226298438953,
        lng: 80.25279101422049,
      },
      {
        lat: 13.022604654941004,
        lng: 80.25272760626281,
      },
      {
        lat: 13.020653242682858,
        lng: 80.25173234437884,
      },
      {
        lat: 13.0183580796489,
        lng: 80.25006547361876,
      },
      {
        lat: 13.021586378122551,
        lng: 80.24776931798856,
      },
      {
        lat: 13.022859704242748,
        lng: 80.24643096718388,
      },
      {
        lat: 13.024608138643028,
        lng: 80.2445152550058,
      },
      {
        lat: 13.02540534951762,
        lng: 80.24358871055621,
      },
      {
        lat: 13.025860301417037,
        lng: 80.24223582392443,
      },
      {
        lat: 13.02679977701061,
        lng: 80.2393151809825,
      },
      {
        lat: 13.027232243170038,
        lng: 80.23934218082424,
      },
      {
        lat: 13.029920790338078,
        lng: 80.23968734593117,
      },
      {
        lat: 13.030693277771235,
        lng: 80.23977278791436,
      },
      {
        lat: 13.031094120909719,
        lng: 80.23967741573026,
      },
      {
        lat: 13.031555412963245,
        lng: 80.23936691254687,
      },
      {
        lat: 13.032038677006321,
        lng: 80.23904417248055,
      },
      {
        lat: 13.032471301932564,
        lng: 80.24116706052685,
      },
      {
        lat: 13.033036633604915,
        lng: 80.2420298162142,
      },
      {
        lat: 13.034341914671613,
        lng: 80.2429975326251,
      },
      {
        lat: 13.034814796353404,
        lng: 80.24339393369048,
      },
      {
        lat: 13.035334241262634,
        lng: 80.24407912075804,
      },
      {
        lat: 13.035941671497255,
        lng: 80.24449662772564,
      },
      {
        lat: 13.036368260537495,
        lng: 80.24464872974198,
      },
      {
        lat: 13.03701873638448,
        lng: 80.24477669232476,
      },
      {
        lat: 13.039422402205163,
        lng: 80.24584633592778,
      },
      {
        lat: 13.03900366015251,
        lng: 80.24698435554336,
      },
    ],
    properties: {
      Ward_No: 122,
      Zone_Name: "TEYNAMPET",
      fillColor: "#de4307",
      zname: "TEYNAMPET(122)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.026376191317272,
        lng: 80.26477569978036,
      },
      {
        lat: 13.025221610647758,
        lng: 80.27552737092464,
      },
      {
        lat: 13.024818291284207,
        lng: 80.27277137087579,
      },
      {
        lat: 13.025698807226672,
        lng: 80.2640909963006,
      },
      {
        lat: 13.027511071862689,
        lng: 80.27360104820379,
      },
      {
        lat: 13.022659893518764,
        lng: 80.27797154545632,
      },
      {
        lat: 13.026863122984798,
        lng: 80.27114682159673,
      },
      {
        lat: 13.02907994228659,
        lng: 80.27029104487605,
      },
      {
        lat: 13.027111078191902,
        lng: 80.26770617701325,
      },
      {
        lat: 13.02526851777846,
        lng: 80.26095049628243,
      },
      {
        lat: 13.027713843544424,
        lng: 80.27479712203373,
      },
      {
        lat: 13.026413202185257,
        lng: 80.26447514178109,
      },
      {
        lat: 13.025785876089397,
        lng: 80.27118363796308,
      },
      {
        lat: 13.022946926692912,
        lng: 80.27723370669487,
      },
      {
        lat: 13.024176658709337,
        lng: 80.2778253148793,
      },
      {
        lat: 13.026001309929434,
        lng: 80.27733473779278,
      },
      {
        lat: 13.027131522704224,
        lng: 80.27388691783058,
      },
      {
        lat: 13.027832552586881,
        lng: 80.2650453360162,
      },
      {
        lat: 13.02797603221591,
        lng: 80.26481701249335,
      },
      {
        lat: 13.027136191582162,
        lng: 80.27753492625226,
      },
      {
        lat: 13.027560097427688,
        lng: 80.26361624815704,
      },
      {
        lat: 13.0285615696708,
        lng: 80.27404528830739,
      },
      {
        lat: 13.025459236170034,
        lng: 80.26104473988688,
      },
      {
        lat: 13.026965021995863,
        lng: 80.269239111258,
      },
      {
        lat: 13.024488831565092,
        lng: 80.26426147294613,
      },
      {
        lat: 13.027783797670278,
        lng: 80.26711745852559,
      },
      {
        lat: 13.025814866518374,
        lng: 80.2778041831528,
      },
      {
        lat: 13.027465838280124,
        lng: 80.27139182014177,
      },
      {
        lat: 13.027528817980063,
        lng: 80.27686412890027,
      },
      {
        lat: 13.027496717449544,
        lng: 80.26352221855362,
      },
      {
        lat: 13.028851525227285,
        lng: 80.26675167695791,
      },
      {
        lat: 13.026661444896634,
        lng: 80.27868396110173,
      },
      {
        lat: 13.024623217782047,
        lng: 80.27319573152992,
      },
      {
        lat: 13.023550590500774,
        lng: 80.27517588089265,
      },
      {
        lat: 13.02516835562952,
        lng: 80.26068231988027,
      },
      {
        lat: 13.02598934295048,
        lng: 80.25999670775951,
      },
      {
        lat: 13.028523730689455,
        lng: 80.26859534206405,
      },
      {
        lat: 13.029085321181462,
        lng: 80.26402036253891,
      },
      {
        lat: 13.028576490573192,
        lng: 80.26649706768164,
      },
      {
        lat: 13.028725881143622,
        lng: 80.26749023681651,
      },
      {
        lat: 13.027245530642622,
        lng: 80.27820474196125,
      },
      {
        lat: 13.026680810874693,
        lng: 80.26166165249924,
      },
      {
        lat: 13.028112668510564,
        lng: 80.2757800474832,
      },
      {
        lat: 13.023045050285068,
        lng: 80.2770153216692,
      },
      {
        lat: 13.028527342166527,
        lng: 80.27199743169984,
      },
      {
        lat: 13.027768664390228,
        lng: 80.27288501413538,
      },
      {
        lat: 13.028147653833637,
        lng: 80.27470640319746,
      },
      {
        lat: 13.028870376418158,
        lng: 80.27171406729681,
      },
      {
        lat: 13.027580376104334,
        lng: 80.26288510767097,
      },
      {
        lat: 13.026836154851967,
        lng: 80.27837805363166,
      },
      {
        lat: 13.024283476003276,
        lng: 80.26422794522016,
      },
      {
        lat: 13.026330589172668,
        lng: 80.26627976316851,
      },
      {
        lat: 13.025873917695344,
        lng: 80.26768606671237,
      },
      {
        lat: 13.027263988143751,
        lng: 80.26848920164319,
      },
      {
        lat: 13.025155748587812,
        lng: 80.27140446198341,
      },
      {
        lat: 13.027784092730938,
        lng: 80.2779095842939,
      },
      {
        lat: 13.026036480298115,
        lng: 80.26402804076857,
      },
      {
        lat: 13.02879086222386,
        lng: 80.26950417835258,
      },
      {
        lat: 13.026491246904518,
        lng: 80.26785160716645,
      },
      {
        lat: 13.026337478984388,
        lng: 80.26770780896011,
      },
      {
        lat: 13.027267116097214,
        lng: 80.26417230128021,
      },
      {
        lat: 13.025879562041125,
        lng: 80.27361304046778,
      },
      {
        lat: 13.02911589350651,
        lng: 80.26346651597954,
      },
      {
        lat: 13.026516496938157,
        lng: 80.2637522577562,
      },
      {
        lat: 13.025915957147404,
        lng: 80.26360485264348,
      },
      {
        lat: 13.0270565874473,
        lng: 80.27446695631929,
      },
      {
        lat: 13.028749153062476,
        lng: 80.27440729226518,
      },
      {
        lat: 13.026860694080561,
        lng: 80.27349981927168,
      },
      {
        lat: 13.025561133362835,
        lng: 80.26271949645191,
      },
      {
        lat: 13.0257226,
        lng: 80.2735427,
      },
      {
        lat: 13.02577,
        lng: 80.2735161,
      },
      {
        lat: 13.0255885,
        lng: 80.2736841,
      },
      {
        lat: 13.0258443,
        lng: 80.2735254,
      },
    ],
    cases: 73,
    coords: [
      {
        lat: 13.027831785635367,
        lng: 80.27888265341575,
      },
      {
        lat: 13.026581163174553,
        lng: 80.27872805021809,
      },
      {
        lat: 13.022024066105498,
        lng: 80.27811455099413,
      },
      {
        lat: 13.022239712425648,
        lng: 80.27720853171192,
      },
      {
        lat: 13.022687370912115,
        lng: 80.2762012005947,
      },
      {
        lat: 13.023931578336105,
        lng: 80.27389685919019,
      },
      {
        lat: 13.024213485050254,
        lng: 80.27276804812196,
      },
      {
        lat: 13.02447825398396,
        lng: 80.27221283493371,
      },
      {
        lat: 13.02500679534569,
        lng: 80.27093857332274,
      },
      {
        lat: 13.025208566563478,
        lng: 80.27019956917391,
      },
      {
        lat: 13.025115476498343,
        lng: 80.26845951804493,
      },
      {
        lat: 13.02507011481568,
        lng: 80.26778404682138,
      },
      {
        lat: 13.025620527358319,
        lng: 80.26671440469426,
      },
      {
        lat: 13.025288151871969,
        lng: 80.26632808896228,
      },
      {
        lat: 13.025472785308967,
        lng: 80.26616268269349,
      },
      {
        lat: 13.02578378786722,
        lng: 80.26642629655889,
      },
      {
        lat: 13.02590741659902,
        lng: 80.26640476979838,
      },
      {
        lat: 13.026071050797396,
        lng: 80.26617809987594,
      },
      {
        lat: 13.026172185532497,
        lng: 80.26584955661014,
      },
      {
        lat: 13.02613006249221,
        lng: 80.26570654989865,
      },
      {
        lat: 13.025383930391206,
        lng: 80.26511893131108,
      },
      {
        lat: 13.024870774628718,
        lng: 80.2648497538077,
      },
      {
        lat: 13.024374503002386,
        lng: 80.26448603490412,
      },
      {
        lat: 13.024250808046139,
        lng: 80.26445642185183,
      },
      {
        lat: 13.02418625667878,
        lng: 80.2639970694431,
      },
      {
        lat: 13.024367603423109,
        lng: 80.26335167644564,
      },
      {
        lat: 13.024516954196542,
        lng: 80.26252259474862,
      },
      {
        lat: 13.025457735115813,
        lng: 80.25981656016397,
      },
      {
        lat: 13.025578446470382,
        lng: 80.25935563982874,
      },
      {
        lat: 13.025296822885203,
        lng: 80.2587448249841,
      },
      {
        lat: 13.026356321820748,
        lng: 80.25977082746407,
      },
      {
        lat: 13.029206814205352,
        lng: 80.260636487865,
      },
      {
        lat: 13.029254730361759,
        lng: 80.26114770863121,
      },
      {
        lat: 13.029412763467695,
        lng: 80.26134657274883,
      },
      {
        lat: 13.029659864242005,
        lng: 80.2614557045782,
      },
      {
        lat: 13.029773228220732,
        lng: 80.26167719136365,
      },
      {
        lat: 13.0297998245102,
        lng: 80.2623665495369,
      },
      {
        lat: 13.029580131782728,
        lng: 80.26308023673916,
      },
      {
        lat: 13.029405784646618,
        lng: 80.26388251865585,
      },
      {
        lat: 13.029393664695897,
        lng: 80.26557321245903,
      },
      {
        lat: 13.029283006574818,
        lng: 80.26579659520162,
      },
      {
        lat: 13.029222699881919,
        lng: 80.26693158128596,
      },
      {
        lat: 13.029212879910416,
        lng: 80.26900041418813,
      },
      {
        lat: 13.029327867403824,
        lng: 80.26948882269068,
      },
      {
        lat: 13.029377136488913,
        lng: 80.2702224782209,
      },
      {
        lat: 13.029361904801993,
        lng: 80.27140157206648,
      },
      {
        lat: 13.029051385598422,
        lng: 80.27191582636407,
      },
      {
        lat: 13.02871981711368,
        lng: 80.27265270502137,
      },
      {
        lat: 13.028702556033307,
        lng: 80.27349814672075,
      },
      {
        lat: 13.028930907505673,
        lng: 80.27420804200882,
      },
      {
        lat: 13.029044271484452,
        lng: 80.27442952879444,
      },
      {
        lat: 13.029023898667722,
        lng: 80.27476337053642,
      },
      {
        lat: 13.029026469232543,
        lng: 80.27518599658819,
      },
      {
        lat: 13.028585730848528,
        lng: 80.2763909362288,
      },
      {
        lat: 13.028499098451928,
        lng: 80.27688105109281,
      },
      {
        lat: 13.02847777858516,
        lng: 80.27705918849972,
      },
      {
        lat: 13.028276564573808,
        lng: 80.27712762529046,
      },
      {
        lat: 13.02816374176656,
        lng: 80.27699511241052,
      },
      {
        lat: 13.028007196882365,
        lng: 80.27704092653343,
      },
      {
        lat: 13.027941207888276,
        lng: 80.27724168660812,
      },
      {
        lat: 13.027831785635367,
        lng: 80.27888265341575,
      },
    ],
    properties: {
      Ward_No: 126,
      Zone_Name: "TEYNAMPET",
      fillColor: "#f6d04d",
      zname: "TEYNAMPET(126)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.036272249151523,
        lng: 80.26994197310619,
      },
      {
        lat: 13.038717827605534,
        lng: 80.26821528674931,
      },
      {
        lat: 13.036348677937326,
        lng: 80.26971028900415,
      },
      {
        lat: 13.039547031581911,
        lng: 80.2698769371965,
      },
      {
        lat: 13.038364062388737,
        lng: 80.27186894556705,
      },
      {
        lat: 13.04133951991809,
        lng: 80.27121253755195,
      },
      {
        lat: 13.03021805474677,
        lng: 80.26177774238731,
      },
      {
        lat: 13.030133617411659,
        lng: 80.26678253795457,
      },
      {
        lat: 13.042019239208308,
        lng: 80.27433513104285,
      },
      {
        lat: 13.034630277844164,
        lng: 80.26760265312572,
      },
      {
        lat: 13.030093332613244,
        lng: 80.26424287804643,
      },
      {
        lat: 13.04178897221231,
        lng: 80.27390870416458,
      },
      {
        lat: 13.037598061571671,
        lng: 80.27134705827869,
      },
      {
        lat: 13.029279106592748,
        lng: 80.27302380116456,
      },
      {
        lat: 13.034263584010723,
        lng: 80.26650529244878,
      },
      {
        lat: 13.035058242290233,
        lng: 80.27069351025308,
      },
      {
        lat: 13.029619439858067,
        lng: 80.27221698385284,
      },
      {
        lat: 13.040152742773765,
        lng: 80.27186525383064,
      },
      {
        lat: 13.037818172817103,
        lng: 80.26828268488228,
      },
      {
        lat: 13.030998885104493,
        lng: 80.27170103508271,
      },
      {
        lat: 13.030028890545452,
        lng: 80.26846076252376,
      },
      {
        lat: 13.032814627102395,
        lng: 80.2699940819928,
      },
      {
        lat: 13.030325132178731,
        lng: 80.27253195271707,
      },
      {
        lat: 13.04086970827897,
        lng: 80.27004001850638,
      },
      {
        lat: 13.03153506051368,
        lng: 80.26681998771538,
      },
      {
        lat: 13.039077413149363,
        lng: 80.27245121157121,
      },
      {
        lat: 13.036774100255212,
        lng: 80.26834619036956,
      },
      {
        lat: 13.038039586738122,
        lng: 80.26862279675387,
      },
      {
        lat: 13.040464005196313,
        lng: 80.27311803035829,
      },
      {
        lat: 13.030799891063449,
        lng: 80.26148658379252,
      },
      {
        lat: 13.031996039185396,
        lng: 80.26611653941133,
      },
      {
        lat: 13.029479443226082,
        lng: 80.27000911429704,
      },
      {
        lat: 13.034300762453494,
        lng: 80.26586703734135,
      },
      {
        lat: 13.030719410164947,
        lng: 80.27054499154943,
      },
      {
        lat: 13.030973259400144,
        lng: 80.2643898511606,
      },
      {
        lat: 13.036679116505567,
        lng: 80.26824936858233,
      },
      {
        lat: 13.031213010153628,
        lng: 80.26982438603075,
      },
      {
        lat: 13.031284257091844,
        lng: 80.2628126938534,
      },
      {
        lat: 13.033425230845694,
        lng: 80.26603913216326,
      },
      {
        lat: 13.031204384020866,
        lng: 80.26987554573897,
      },
      {
        lat: 13.033124413418893,
        lng: 80.26961435233105,
      },
      {
        lat: 13.03197628425275,
        lng: 80.26808903209819,
      },
      {
        lat: 13.029765368301964,
        lng: 80.27308797382577,
      },
      {
        lat: 13.037927457921601,
        lng: 80.27080226568917,
      },
      {
        lat: 13.031100106374533,
        lng: 80.26591126748907,
      },
      {
        lat: 13.04041704439105,
        lng: 80.27291791564954,
      },
      {
        lat: 13.040796981298293,
        lng: 80.27146520784719,
      },
      {
        lat: 13.034930093494093,
        lng: 80.26986232154461,
      },
      {
        lat: 13.029737865770782,
        lng: 80.26632269363456,
      },
      {
        lat: 13.031710212748697,
        lng: 80.26663035425746,
      },
      {
        lat: 13.032328314067792,
        lng: 80.2670307147685,
      },
      {
        lat: 13.032508681555816,
        lng: 80.26513078741242,
      },
      {
        lat: 13.030613295407445,
        lng: 80.27053527366013,
      },
      {
        lat: 13.032481668129034,
        lng: 80.26786828221087,
      },
      {
        lat: 13.037360833713471,
        lng: 80.27201583659364,
      },
      {
        lat: 13.030359947599111,
        lng: 80.26953051702209,
      },
      {
        lat: 13.02932702710458,
        lng: 80.26760947832109,
      },
      {
        lat: 13.034838206604693,
        lng: 80.26846704927682,
      },
      {
        lat: 13.029941980209271,
        lng: 80.27178926551994,
      },
      {
        lat: 13.041662380031376,
        lng: 80.27175045877567,
      },
      {
        lat: 13.029720000712354,
        lng: 80.27111386253134,
      },
      {
        lat: 13.029241345212554,
        lng: 80.26794221922587,
      },
      {
        lat: 13.036557450829354,
        lng: 80.27250996480129,
      },
      {
        lat: 13.03350235845337,
        lng: 80.2679129650022,
      },
      {
        lat: 13.034047867838337,
        lng: 80.26520778424421,
      },
      {
        lat: 13.029808506886493,
        lng: 80.26629441567896,
      },
      {
        lat: 13.031757596230372,
        lng: 80.27092795862683,
      },
      {
        lat: 13.033444855088236,
        lng: 80.2653017815948,
      },
      {
        lat: 13.039666879264672,
        lng: 80.27106659957039,
      },
      {
        lat: 13.030793091603853,
        lng: 80.26216412551415,
      },
      {
        lat: 13.03178661223037,
        lng: 80.26907742452997,
      },
      {
        lat: 13.038419289161308,
        lng: 80.26827232577203,
      },
      {
        lat: 13.041247611381458,
        lng: 80.27392905564872,
      },
      {
        lat: 13.037794563271229,
        lng: 80.27184301282486,
      },
      {
        lat: 13.036601137316062,
        lng: 80.26953706209183,
      },
      {
        lat: 13.039564519040944,
        lng: 80.2694375990731,
      },
      {
        lat: 13.032737032853424,
        lng: 80.2662526591561,
      },
      {
        lat: 13.035200871027376,
        lng: 80.26974099491288,
      },
      {
        lat: 13.028753616373576,
        lng: 80.27300549115716,
      },
      {
        lat: 13.037032790416918,
        lng: 80.27175572788305,
      },
      {
        lat: 13.041834146039127,
        lng: 80.27198102410863,
      },
      {
        lat: 13.029300518491251,
        lng: 80.2718565375806,
      },
      {
        lat: 13.03567510525567,
        lng: 80.26739119048916,
      },
      {
        lat: 13.030499654010637,
        lng: 80.27012770845835,
      },
      {
        lat: 13.035042192368078,
        lng: 80.26776439098863,
      },
      {
        lat: 13.031052146613018,
        lng: 80.26268125044975,
      },
      {
        lat: 13.033087661555475,
        lng: 80.26750650545839,
      },
      {
        lat: 13.033338288083469,
        lng: 80.27061595496866,
      },
      {
        lat: 13.031825548752968,
        lng: 80.26819289891645,
      },
      {
        lat: 13.039620037548008,
        lng: 80.27362217826466,
      },
      {
        lat: 13.032179845945784,
        lng: 80.26986406341108,
      },
      {
        lat: 13.037052845365984,
        lng: 80.26859446440086,
      },
      {
        lat: 13.028992749742471,
        lng: 80.27220712686893,
      },
      {
        lat: 13.039730669651762,
        lng: 80.26999125645476,
      },
      {
        lat: 13.031773331590394,
        lng: 80.26712653708964,
      },
      {
        lat: 13.03219821784511,
        lng: 80.26774458355023,
      },
      {
        lat: 13.039267325414704,
        lng: 80.27351156716027,
      },
      {
        lat: 13.030574556334422,
        lng: 80.26963510250069,
      },
      {
        lat: 13.02961740745875,
        lng: 80.26583682373709,
      },
      {
        lat: 13.032830943379789,
        lng: 80.26377567146096,
      },
      {
        lat: 13.036656039481883,
        lng: 80.2707097212435,
      },
      {
        lat: 13.036893274833146,
        lng: 80.27214494206511,
      },
      {
        lat: 13.040282002688796,
        lng: 80.27417007653314,
      },
      {
        lat: 13.031434420399563,
        lng: 80.2644496504076,
      },
      {
        lat: 13.03103382475183,
        lng: 80.27297836090324,
      },
      {
        lat: 13.038356080650614,
        lng: 80.27083114811299,
      },
      {
        lat: 13.038005949786974,
        lng: 80.26895595091244,
      },
      {
        lat: 13.03971845774077,
        lng: 80.26976443700256,
      },
      {
        lat: 13.03156578587339,
        lng: 80.26437059393633,
      },
      {
        lat: 13.04051120025439,
        lng: 80.27179390554909,
      },
      {
        lat: 13.029853485142413,
        lng: 80.26315883071099,
      },
      {
        lat: 13.042032802348654,
        lng: 80.27290088389819,
      },
      {
        lat: 13.031024717342003,
        lng: 80.26846503628482,
      },
      {
        lat: 13.03030780028878,
        lng: 80.2682004057054,
      },
      {
        lat: 13.030930636778555,
        lng: 80.26409906108285,
      },
      {
        lat: 13.036612719233919,
        lng: 80.26969422219534,
      },
      {
        lat: 13.034122948325201,
        lng: 80.26849473605722,
      },
      {
        lat: 13.035629509893964,
        lng: 80.26786433925781,
      },
      {
        lat: 13.029629068886285,
        lng: 80.27285493453674,
      },
      {
        lat: 13.035385336011716,
        lng: 80.27037770870801,
      },
      {
        lat: 13.039079991393113,
        lng: 80.27308920970951,
      },
      {
        lat: 13.032839692973253,
        lng: 80.27021612945214,
      },
      {
        lat: 13.03377464638851,
        lng: 80.26601655985797,
      },
      {
        lat: 13.030035032985195,
        lng: 80.2636210777185,
      },
      {
        lat: 13.035835055173632,
        lng: 80.27014414127613,
      },
      {
        lat: 13.033724378814386,
        lng: 80.26803086305915,
      },
      {
        lat: 13.03806857193989,
        lng: 80.26899064483717,
      },
    ],
    cases: 127,
    coords: [
      {
        lat: 13.029042278014588,
        lng: 80.27446219504141,
      },
      {
        lat: 13.029044271484452,
        lng: 80.27442952879444,
      },
      {
        lat: 13.028930907505673,
        lng: 80.27420804200882,
      },
      {
        lat: 13.028702556033307,
        lng: 80.27349814672075,
      },
      {
        lat: 13.02871981711368,
        lng: 80.27265270502137,
      },
      {
        lat: 13.029051385598422,
        lng: 80.27191582636407,
      },
      {
        lat: 13.029361904801993,
        lng: 80.27140157206648,
      },
      {
        lat: 13.029377136488913,
        lng: 80.2702224782209,
      },
      {
        lat: 13.029327867403824,
        lng: 80.26948882269068,
      },
      {
        lat: 13.029212879910416,
        lng: 80.26900041418813,
      },
      {
        lat: 13.029222699881919,
        lng: 80.26693158128596,
      },
      {
        lat: 13.029283006574818,
        lng: 80.26579659520162,
      },
      {
        lat: 13.029393664695897,
        lng: 80.26557321245903,
      },
      {
        lat: 13.029405784646618,
        lng: 80.26388251865585,
      },
      {
        lat: 13.029580131782728,
        lng: 80.26308023673916,
      },
      {
        lat: 13.0297998245102,
        lng: 80.2623665495369,
      },
      {
        lat: 13.029773228220732,
        lng: 80.26167719136365,
      },
      {
        lat: 13.029659864242005,
        lng: 80.2614557045782,
      },
      {
        lat: 13.029412763467695,
        lng: 80.26134657274883,
      },
      {
        lat: 13.029254730361759,
        lng: 80.26114770863121,
      },
      {
        lat: 13.029206814205352,
        lng: 80.260636487865,
      },
      {
        lat: 13.030868636531462,
        lng: 80.2612897620748,
      },
      {
        lat: 13.031792538856624,
        lng: 80.26217172770713,
      },
      {
        lat: 13.033506877297194,
        lng: 80.26409250088085,
      },
      {
        lat: 13.034117013389581,
        lng: 80.26495487737675,
      },
      {
        lat: 13.034413924420686,
        lng: 80.26588663864189,
      },
      {
        lat: 13.034866297992854,
        lng: 80.26659463797299,
      },
      {
        lat: 13.035789388560756,
        lng: 80.2673431427467,
      },
      {
        lat: 13.035969688583776,
        lng: 80.26751957379226,
      },
      {
        lat: 13.03608237609813,
        lng: 80.26762984319575,
      },
      {
        lat: 13.03641813866232,
        lng: 80.26758251230734,
      },
      {
        lat: 13.037201810133459,
        lng: 80.2675091460281,
      },
      {
        lat: 13.037628263880638,
        lng: 80.267639004568,
      },
      {
        lat: 13.039622601741625,
        lng: 80.26771110445533,
      },
      {
        lat: 13.039583073744124,
        lng: 80.26857897922689,
      },
      {
        lat: 13.039450148705965,
        lng: 80.26882479504162,
      },
      {
        lat: 13.0393620280878,
        lng: 80.26907023166514,
      },
      {
        lat: 13.039407914850752,
        lng: 80.26924780028503,
      },
      {
        lat: 13.039833962719394,
        lng: 80.26931092839565,
      },
      {
        lat: 13.040597424494868,
        lng: 80.26962127466922,
      },
      {
        lat: 13.041617369315974,
        lng: 80.27068831454218,
      },
      {
        lat: 13.041935461257161,
        lng: 80.27127550705475,
      },
      {
        lat: 13.042148085328977,
        lng: 80.27175949488563,
      },
      {
        lat: 13.042358176901349,
        lng: 80.2718271150152,
      },
      {
        lat: 13.042327663860176,
        lng: 80.27255605424023,
      },
      {
        lat: 13.04245313668626,
        lng: 80.27307547874912,
      },
      {
        lat: 13.042386623522283,
        lng: 80.2736312271798,
      },
      {
        lat: 13.042284110235393,
        lng: 80.27401378481629,
      },
      {
        lat: 13.042234014320664,
        lng: 80.2743958988309,
      },
      {
        lat: 13.042076762203719,
        lng: 80.27439722969635,
      },
      {
        lat: 13.041971294334347,
        lng: 80.27429402501468,
      },
      {
        lat: 13.040590864676041,
        lng: 80.2742883584016,
      },
      {
        lat: 13.039890594608162,
        lng: 80.27406874085419,
      },
      {
        lat: 13.039067384213235,
        lng: 80.27374606649926,
      },
      {
        lat: 13.0387876138528,
        lng: 80.27371373517381,
      },
      {
        lat: 13.036288946918381,
        lng: 80.27371753249275,
      },
      {
        lat: 13.036265776337755,
        lng: 80.27278085303905,
      },
      {
        lat: 13.036489541618677,
        lng: 80.2722237737429,
      },
      {
        lat: 13.03653763263798,
        lng: 80.27151203529817,
      },
      {
        lat: 13.036517838723048,
        lng: 80.2711305127794,
      },
      {
        lat: 13.036219329655358,
        lng: 80.27089014547708,
      },
      {
        lat: 13.035502114733994,
        lng: 80.27075741907491,
      },
      {
        lat: 13.035257078246728,
        lng: 80.2706900946933,
      },
      {
        lat: 13.035135087607081,
        lng: 80.27074317577333,
      },
      {
        lat: 13.034927000929788,
        lng: 80.27100518007404,
      },
      {
        lat: 13.034805854456618,
        lng: 80.27119705038784,
      },
      {
        lat: 13.034614290549545,
        lng: 80.27130276892665,
      },
      {
        lat: 13.034002121414561,
        lng: 80.27120385258904,
      },
      {
        lat: 13.033442580693176,
        lng: 80.27113918993831,
      },
      {
        lat: 13.033232278079252,
        lng: 80.2710368725003,
      },
      {
        lat: 13.032917140720484,
        lng: 80.27093544230573,
      },
      {
        lat: 13.032550113593631,
        lng: 80.27092119900418,
      },
      {
        lat: 13.03226981562901,
        lng: 80.27080212440775,
      },
      {
        lat: 13.03195520587432,
        lng: 80.27078743748439,
      },
      {
        lat: 13.031833742838687,
        lng: 80.27092726183568,
      },
      {
        lat: 13.031695651512031,
        lng: 80.27120602329482,
      },
      {
        lat: 13.03157492712202,
        lng: 80.27146728822548,
      },
      {
        lat: 13.03148956973016,
        lng: 80.27179765202533,
      },
      {
        lat: 13.03148560393547,
        lng: 80.27401842763906,
      },
      {
        lat: 13.030909223881629,
        lng: 80.27405800478753,
      },
      {
        lat: 13.030333476952665,
        lng: 80.27420167386124,
      },
      {
        lat: 13.029042278014588,
        lng: 80.27446219504141,
      },
    ],
    properties: {
      Ward_No: 124,
      Zone_Name: "TEYNAMPET",
      fillColor: "#de4307",
      zname: "TEYNAMPET(124)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.037036240045147,
        lng: 80.2795233610457,
      },
      {
        lat: 13.042056824038903,
        lng: 80.28016642224149,
      },
      {
        lat: 13.029362663722022,
        lng: 80.27620065623655,
      },
      {
        lat: 13.031300586571549,
        lng: 80.27436099621926,
      },
      {
        lat: 13.034562816377457,
        lng: 80.27597470758707,
      },
      {
        lat: 13.033654282555114,
        lng: 80.27694312647783,
      },
      {
        lat: 13.040811523468799,
        lng: 80.27924540350601,
      },
      {
        lat: 13.028223780126897,
        lng: 80.27797823617398,
      },
      {
        lat: 13.041885931368745,
        lng: 80.27446994366062,
      },
      {
        lat: 13.029058016191923,
        lng: 80.27553750731144,
      },
      {
        lat: 13.038969736079665,
        lng: 80.27986678404852,
      },
      {
        lat: 13.041164766094548,
        lng: 80.27552348662769,
      },
      {
        lat: 13.034370230469383,
        lng: 80.27696059689754,
      },
      {
        lat: 13.037375606672953,
        lng: 80.27471574918005,
      },
      {
        lat: 13.033601899231204,
        lng: 80.27315967383457,
      },
      {
        lat: 13.037267033791785,
        lng: 80.27928975715355,
      },
      {
        lat: 13.038499539573575,
        lng: 80.27753190656674,
      },
      {
        lat: 13.033897892354881,
        lng: 80.27859322445002,
      },
      {
        lat: 13.033020468995158,
        lng: 80.27589156085233,
      },
      {
        lat: 13.041599288170508,
        lng: 80.27918709083256,
      },
      {
        lat: 13.038122243894657,
        lng: 80.2772249227037,
      },
      {
        lat: 13.029491361745551,
        lng: 80.27774504809467,
      },
      {
        lat: 13.030429577388414,
        lng: 80.27592286571003,
      },
      {
        lat: 13.03924802868663,
        lng: 80.27466926113632,
      },
      {
        lat: 13.028474712767476,
        lng: 80.27876647058568,
      },
      {
        lat: 13.038360454536777,
        lng: 80.27760339058288,
      },
      {
        lat: 13.042055389379849,
        lng: 80.27508674976734,
      },
      {
        lat: 13.038560881324546,
        lng: 80.27751345681254,
      },
      {
        lat: 13.03406831314873,
        lng: 80.2767160588826,
      },
      {
        lat: 13.036559022732963,
        lng: 80.27774999605626,
      },
      {
        lat: 13.031976184940676,
        lng: 80.27104417084979,
      },
      {
        lat: 13.032969176539057,
        lng: 80.27752966325983,
      },
      {
        lat: 13.043058224512485,
        lng: 80.27926056266728,
      },
      {
        lat: 13.034478091259501,
        lng: 80.27941491415197,
      },
      {
        lat: 13.030974126767058,
        lng: 80.2749054386927,
      },
      {
        lat: 13.03516680070279,
        lng: 80.27968802324445,
      },
      {
        lat: 13.041991696426564,
        lng: 80.2755833824209,
      },
      {
        lat: 13.032680423115064,
        lng: 80.27258653931197,
      },
      {
        lat: 13.032206635460343,
        lng: 80.27145705708772,
      },
      {
        lat: 13.043150134370949,
        lng: 80.27891699157986,
      },
      {
        lat: 13.035560754800231,
        lng: 80.27553500062905,
      },
      {
        lat: 13.036505814115111,
        lng: 80.27188382965122,
      },
      {
        lat: 13.034778302009654,
        lng: 80.27340052180601,
      },
      {
        lat: 13.035852860128362,
        lng: 80.27654737545957,
      },
      {
        lat: 13.031859072019527,
        lng: 80.27629045839085,
      },
      {
        lat: 13.03821390875226,
        lng: 80.2737731888715,
      },
      {
        lat: 13.02973698537702,
        lng: 80.27452831738985,
      },
      {
        lat: 13.034260546781649,
        lng: 80.27921979475843,
      },
      {
        lat: 13.035761286660835,
        lng: 80.27384739833509,
      },
      {
        lat: 13.03719278297809,
        lng: 80.2790814751405,
      },
      {
        lat: 13.034183606835034,
        lng: 80.27185881502096,
      },
      {
        lat: 13.033872897352385,
        lng: 80.27311659141057,
      },
      {
        lat: 13.03440690696759,
        lng: 80.27393984611099,
      },
      {
        lat: 13.033289989759785,
        lng: 80.27641189641413,
      },
      {
        lat: 13.04288599064857,
        lng: 80.27175346878454,
      },
      {
        lat: 13.029641289087754,
        lng: 80.27750623169281,
      },
      {
        lat: 13.041843845070455,
        lng: 80.27821143372778,
      },
      {
        lat: 13.034224282706518,
        lng: 80.27481396466133,
      },
      {
        lat: 13.036371809763681,
        lng: 80.27868088471644,
      },
      {
        lat: 13.038886951979618,
        lng: 80.27909092135387,
      },
      {
        lat: 13.029080650462632,
        lng: 80.27864438260623,
      },
      {
        lat: 13.033141145750797,
        lng: 80.27935789910656,
      },
      {
        lat: 13.040614729424282,
        lng: 80.27738270137749,
      },
      {
        lat: 13.03217857394219,
        lng: 80.27262108834444,
      },
      {
        lat: 13.030906648863319,
        lng: 80.27495830972698,
      },
      {
        lat: 13.028969871313938,
        lng: 80.27539761071637,
      },
      {
        lat: 13.032476105282667,
        lng: 80.2743145506005,
      },
      {
        lat: 13.031070408097609,
        lng: 80.27436314291245,
      },
      {
        lat: 13.03299844587346,
        lng: 80.2712241071974,
      },
      {
        lat: 13.036301388626834,
        lng: 80.27413070141779,
      },
      {
        lat: 13.030430322865397,
        lng: 80.27613667928027,
      },
      {
        lat: 13.042056881671604,
        lng: 80.27902421666448,
      },
      {
        lat: 13.04039345177556,
        lng: 80.27806817050507,
      },
      {
        lat: 13.032554272447184,
        lng: 80.27487427860373,
      },
      {
        lat: 13.031383886644951,
        lng: 80.27486893608517,
      },
      {
        lat: 13.032185020498213,
        lng: 80.27302035519865,
      },
      {
        lat: 13.035273004569836,
        lng: 80.27110908787654,
      },
      {
        lat: 13.028257279217412,
        lng: 80.2772658082759,
      },
      {
        lat: 13.038338505576377,
        lng: 80.27962486391732,
      },
      {
        lat: 13.038340762095748,
        lng: 80.27480215897475,
      },
      {
        lat: 13.030850232898304,
        lng: 80.27815879127219,
      },
      {
        lat: 13.03551940486068,
        lng: 80.2759226285999,
      },
      {
        lat: 13.037255957473018,
        lng: 80.2764075584315,
      },
      {
        lat: 13.035507681189431,
        lng: 80.27486629053533,
      },
      {
        lat: 13.043376839092547,
        lng: 80.27656879742597,
      },
      {
        lat: 13.034611382453622,
        lng: 80.2729184715983,
      },
      {
        lat: 13.032181438819068,
        lng: 80.2743597191848,
      },
      {
        lat: 13.035082826782334,
        lng: 80.27742533657882,
      },
      {
        lat: 13.031924160060633,
        lng: 80.27191310371823,
      },
      {
        lat: 13.037108043137192,
        lng: 80.27601326228141,
      },
      {
        lat: 13.040131326221864,
        lng: 80.28004278674396,
      },
      {
        lat: 13.041653181304133,
        lng: 80.27511123136797,
      },
      {
        lat: 13.031511802388662,
        lng: 80.27764756418155,
      },
      {
        lat: 13.034018639877809,
        lng: 80.27347095759399,
      },
      {
        lat: 13.033087745668828,
        lng: 80.27420704860582,
      },
      {
        lat: 13.03877466875506,
        lng: 80.27410077202488,
      },
      {
        lat: 13.036978349476412,
        lng: 80.27836237730938,
      },
    ],
    cases: 97,
    coords: [
      {
        lat: 13.029042278014588,
        lng: 80.27446219504141,
      },
      {
        lat: 13.030333476952665,
        lng: 80.27420167386124,
      },
      {
        lat: 13.030909223881629,
        lng: 80.27405800478753,
      },
      {
        lat: 13.03148560393547,
        lng: 80.27401842763906,
      },
      {
        lat: 13.03148956973016,
        lng: 80.27179765202533,
      },
      {
        lat: 13.03157492712202,
        lng: 80.27146728822548,
      },
      {
        lat: 13.031695651512031,
        lng: 80.27120602329482,
      },
      {
        lat: 13.031833742838687,
        lng: 80.27092726183568,
      },
      {
        lat: 13.03195520587432,
        lng: 80.27078743748439,
      },
      {
        lat: 13.03226981562901,
        lng: 80.27080212440775,
      },
      {
        lat: 13.032550113593631,
        lng: 80.27092119900418,
      },
      {
        lat: 13.032917140720484,
        lng: 80.27093544230573,
      },
      {
        lat: 13.033232278079252,
        lng: 80.2710368725003,
      },
      {
        lat: 13.033442580693176,
        lng: 80.27113918993831,
      },
      {
        lat: 13.034002121414561,
        lng: 80.27120385258904,
      },
      {
        lat: 13.034614290549545,
        lng: 80.27130276892665,
      },
      {
        lat: 13.034805854456618,
        lng: 80.27119705038784,
      },
      {
        lat: 13.034927000929788,
        lng: 80.27100518007404,
      },
      {
        lat: 13.035135087607081,
        lng: 80.27074317577333,
      },
      {
        lat: 13.035257078246728,
        lng: 80.2706900946933,
      },
      {
        lat: 13.035502114733994,
        lng: 80.27075741907491,
      },
      {
        lat: 13.036219329655358,
        lng: 80.27089014547708,
      },
      {
        lat: 13.036517838723048,
        lng: 80.2711305127794,
      },
      {
        lat: 13.03653763263798,
        lng: 80.27151203529817,
      },
      {
        lat: 13.036489541618677,
        lng: 80.2722237737429,
      },
      {
        lat: 13.036265776337755,
        lng: 80.27278085303905,
      },
      {
        lat: 13.036288946918381,
        lng: 80.27371753249275,
      },
      {
        lat: 13.0387876138528,
        lng: 80.27371373517381,
      },
      {
        lat: 13.039067384213235,
        lng: 80.27374606649926,
      },
      {
        lat: 13.039890594608162,
        lng: 80.27406874085419,
      },
      {
        lat: 13.040590864676041,
        lng: 80.2742883584016,
      },
      {
        lat: 13.041971294334347,
        lng: 80.27429402501468,
      },
      {
        lat: 13.042076762203719,
        lng: 80.27439722969635,
      },
      {
        lat: 13.042234014320664,
        lng: 80.2743958988309,
      },
      {
        lat: 13.042284110235393,
        lng: 80.27401378481629,
      },
      {
        lat: 13.042386623522283,
        lng: 80.2736312271798,
      },
      {
        lat: 13.04245313668626,
        lng: 80.27307547874912,
      },
      {
        lat: 13.042327663860176,
        lng: 80.27255605424023,
      },
      {
        lat: 13.042358176901349,
        lng: 80.2718271150152,
      },
      {
        lat: 13.042374066546756,
        lng: 80.27156673732796,
      },
      {
        lat: 13.043020441951064,
        lng: 80.27154391733802,
      },
      {
        lat: 13.043041607636605,
        lng: 80.27215097236177,
      },
      {
        lat: 13.042940993724164,
        lng: 80.27284580577411,
      },
      {
        lat: 13.042734700900516,
        lng: 80.27340273719628,
      },
      {
        lat: 13.042667449090924,
        lng: 80.27383704504751,
      },
      {
        lat: 13.042652192570332,
        lng: 80.2742015146599,
      },
      {
        lat: 13.043193944271641,
        lng: 80.27421427922204,
      },
      {
        lat: 13.043315407307272,
        lng: 80.27407445487073,
      },
      {
        lat: 13.04348949875684,
        lng: 80.27396888420611,
      },
      {
        lat: 13.043542338212449,
        lng: 80.27403783520107,
      },
      {
        lat: 13.04350855402633,
        lng: 80.27422896614539,
      },
      {
        lat: 13.043406462822647,
        lng: 80.27468091839876,
      },
      {
        lat: 13.043452320376042,
        lng: 80.27934755850914,
      },
      {
        lat: 13.043577476639776,
        lng: 80.27981493705549,
      },
      {
        lat: 13.04366756909577,
        lng: 80.28078916354565,
      },
      {
        lat: 13.038190770214296,
        lng: 80.28016324143466,
      },
      {
        lat: 13.027831785635367,
        lng: 80.27888265341575,
      },
      {
        lat: 13.027941207888276,
        lng: 80.27724168660812,
      },
      {
        lat: 13.028007196882365,
        lng: 80.27704092653343,
      },
      {
        lat: 13.02816374176656,
        lng: 80.27699511241052,
      },
      {
        lat: 13.028276564573808,
        lng: 80.27712762529046,
      },
      {
        lat: 13.02847777858516,
        lng: 80.27705918849972,
      },
      {
        lat: 13.028499098451928,
        lng: 80.27688105109281,
      },
      {
        lat: 13.028585730848528,
        lng: 80.2763909362288,
      },
      {
        lat: 13.029026469232543,
        lng: 80.27518599658819,
      },
      {
        lat: 13.029023898667722,
        lng: 80.27476337053642,
      },
      {
        lat: 13.029042278014588,
        lng: 80.27446219504141,
      },
    ],
    properties: {
      Ward_No: 125,
      Zone_Name: "TEYNAMPET",
      fillColor: "#f6d04d",
      zname: "TEYNAMPET(125)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.035877166918663,
        lng: 80.25217825272948,
      },
      {
        lat: 13.037864072937374,
        lng: 80.25132715579937,
      },
      {
        lat: 13.025498676836486,
        lng: 80.2537772946847,
      },
      {
        lat: 13.03301721308624,
        lng: 80.25963490069728,
      },
      {
        lat: 13.026947705196493,
        lng: 80.25655777247592,
      },
      {
        lat: 13.03537499992014,
        lng: 80.25583400485249,
      },
      {
        lat: 13.035867045914163,
        lng: 80.25592297745747,
      },
      {
        lat: 13.038424536387144,
        lng: 80.25357088475587,
      },
      {
        lat: 13.028068648484766,
        lng: 80.25476577168531,
      },
      {
        lat: 13.025665314892992,
        lng: 80.25531717970773,
      },
      {
        lat: 13.036192106551848,
        lng: 80.2477884255305,
      },
      {
        lat: 13.032641320574227,
        lng: 80.25965113043038,
      },
      {
        lat: 13.03829060909117,
        lng: 80.25775949337532,
      },
      {
        lat: 13.033248148119377,
        lng: 80.25668959761785,
      },
      {
        lat: 13.034973051138676,
        lng: 80.25520813274385,
      },
      {
        lat: 13.030280301077259,
        lng: 80.25471843074445,
      },
      {
        lat: 13.034636626417178,
        lng: 80.25546051082549,
      },
      {
        lat: 13.028884511582769,
        lng: 80.25950655283785,
      },
      {
        lat: 13.036702271214399,
        lng: 80.24821918118631,
      },
      {
        lat: 13.0261433693239,
        lng: 80.25667216742099,
      },
      {
        lat: 13.03498569797126,
        lng: 80.26176524840521,
      },
      {
        lat: 13.036461768589323,
        lng: 80.24936855239899,
      },
      {
        lat: 13.03072219213442,
        lng: 80.25373052807159,
      },
      {
        lat: 13.028413759978145,
        lng: 80.2511065628177,
      },
      {
        lat: 13.036368511373983,
        lng: 80.25661772017651,
      },
      {
        lat: 13.034939799024272,
        lng: 80.25575020227271,
      },
      {
        lat: 13.03176507774706,
        lng: 80.25630918247721,
      },
      {
        lat: 13.031296212271648,
        lng: 80.25170526423105,
      },
      {
        lat: 13.038628267588827,
        lng: 80.25104968102286,
      },
      {
        lat: 13.036593040778971,
        lng: 80.25530578393256,
      },
      {
        lat: 13.039102404377513,
        lng: 80.26480706803859,
      },
      {
        lat: 13.03531495876598,
        lng: 80.25724302550444,
      },
      {
        lat: 13.036780487174797,
        lng: 80.26040668349336,
      },
      {
        lat: 13.028874717238734,
        lng: 80.25900926360144,
      },
      {
        lat: 13.031170584156174,
        lng: 80.2615688650235,
      },
      {
        lat: 13.029953075814776,
        lng: 80.2576750405419,
      },
      {
        lat: 13.030518778340891,
        lng: 80.25205528472708,
      },
      {
        lat: 13.024244163353307,
        lng: 80.25586231015349,
      },
      {
        lat: 13.037112056905173,
        lng: 80.2663372127896,
      },
      {
        lat: 13.03730450012472,
        lng: 80.25372433749972,
      },
      {
        lat: 13.032300048585418,
        lng: 80.25293652929153,
      },
      {
        lat: 13.027319134331607,
        lng: 80.25874100170158,
      },
      {
        lat: 13.02464355144925,
        lng: 80.2568049980434,
      },
      {
        lat: 13.02828226950907,
        lng: 80.25560688779741,
      },
      {
        lat: 13.027008966210602,
        lng: 80.2580651050235,
      },
      {
        lat: 13.039570375097332,
        lng: 80.26444855883537,
      },
      {
        lat: 13.0373497841745,
        lng: 80.25364916585136,
      },
      {
        lat: 13.026831737512815,
        lng: 80.25877042961022,
      },
      {
        lat: 13.032946547918497,
        lng: 80.25375377069531,
      },
      {
        lat: 13.035903935114451,
        lng: 80.26246805537444,
      },
      {
        lat: 13.034986383387567,
        lng: 80.25706433085793,
      },
      {
        lat: 13.029845533084645,
        lng: 80.25184127185379,
      },
    ],
    cases: 52,
    coords: [
      {
        lat: 13.039307885015365,
        lng: 80.26769972672393,
      },
      {
        lat: 13.037628263880638,
        lng: 80.267639004568,
      },
      {
        lat: 13.037201810133459,
        lng: 80.2675091460281,
      },
      {
        lat: 13.03641813866232,
        lng: 80.26758251230734,
      },
      {
        lat: 13.03608237609813,
        lng: 80.26762984319575,
      },
      {
        lat: 13.035969688583776,
        lng: 80.26751957379226,
      },
      {
        lat: 13.035789388560756,
        lng: 80.2673431427467,
      },
      {
        lat: 13.034866297992854,
        lng: 80.26659463797299,
      },
      {
        lat: 13.034413924420686,
        lng: 80.26588663864189,
      },
      {
        lat: 13.034117013389581,
        lng: 80.26495487737675,
      },
      {
        lat: 13.033506877297194,
        lng: 80.26409250088085,
      },
      {
        lat: 13.031792538856624,
        lng: 80.26217172770713,
      },
      {
        lat: 13.030868636531462,
        lng: 80.2612897620748,
      },
      {
        lat: 13.029206814205352,
        lng: 80.260636487865,
      },
      {
        lat: 13.026356321820748,
        lng: 80.25977082746407,
      },
      {
        lat: 13.025296822885203,
        lng: 80.2587448249841,
      },
      {
        lat: 13.024703258020102,
        lng: 80.25761543162588,
      },
      {
        lat: 13.023642993323287,
        lng: 80.25590744968608,
      },
      {
        lat: 13.022980961113339,
        lng: 80.2536748788481,
      },
      {
        lat: 13.0226298438953,
        lng: 80.25279101422049,
      },
      {
        lat: 13.02483502340944,
        lng: 80.25275440223444,
      },
      {
        lat: 13.025329224957995,
        lng: 80.25297266589281,
      },
      {
        lat: 13.026497522199923,
        lng: 80.2535188938262,
      },
      {
        lat: 13.027149350975819,
        lng: 80.253869291173,
      },
      {
        lat: 13.02732829806998,
        lng: 80.2538232874546,
      },
      {
        lat: 13.0276554018895,
        lng: 80.25235237407547,
      },
      {
        lat: 13.027963079942477,
        lng: 80.25137100677341,
      },
      {
        lat: 13.02867210367485,
        lng: 80.2500748180785,
      },
      {
        lat: 13.028895178724685,
        lng: 80.24991721778646,
      },
      {
        lat: 13.029096933907578,
        lng: 80.24993775490147,
      },
      {
        lat: 13.035218070067264,
        lng: 80.25444609753245,
      },
      {
        lat: 13.035571657358322,
        lng: 80.2462793289658,
      },
      {
        lat: 13.03900366015251,
        lng: 80.24698435554336,
      },
      {
        lat: 13.039519722739715,
        lng: 80.24711345570044,
      },
      {
        lat: 13.03903662987453,
        lng: 80.24989366759853,
      },
      {
        lat: 13.038483327952884,
        lng: 80.25410023423402,
      },
      {
        lat: 13.03818047303356,
        lng: 80.25681816574159,
      },
      {
        lat: 13.038942110836999,
        lng: 80.25732704518694,
      },
      {
        lat: 13.038733272739842,
        lng: 80.25909281106674,
      },
      {
        lat: 13.038859666572678,
        lng: 80.26018185275151,
      },
      {
        lat: 13.039102930987218,
        lng: 80.26079422036065,
      },
      {
        lat: 13.03922703441792,
        lng: 80.26150669749556,
      },
      {
        lat: 13.039633479671355,
        lng: 80.26269247006773,
      },
      {
        lat: 13.039720796095997,
        lng: 80.26392058392422,
      },
      {
        lat: 13.03970746558702,
        lng: 80.26501080813235,
      },
      {
        lat: 13.039352031941839,
        lng: 80.26564806288553,
      },
      {
        lat: 13.039094954828416,
        lng: 80.2660466427371,
      },
      {
        lat: 13.039076440777903,
        lng: 80.26628464191107,
      },
      {
        lat: 13.039078490085023,
        lng: 80.26662156808752,
      },
      {
        lat: 13.039339665812857,
        lng: 80.26689684058833,
      },
      {
        lat: 13.039360831907704,
        lng: 80.26709486352482,
      },
      {
        lat: 13.039307885015365,
        lng: 80.26769972672393,
      },
    ],
    properties: {
      Ward_No: 123,
      Zone_Name: "TEYNAMPET",
      fillColor: "#8bc24c",
      zname: "TEYNAMPET(123)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.045808202540455,
        lng: 80.25120908021334,
      },
      {
        lat: 13.053987915594247,
        lng: 80.26068865191712,
      },
      {
        lat: 13.040213836622874,
        lng: 80.25219035574627,
      },
      {
        lat: 13.042251170129834,
        lng: 80.25113239255167,
      },
      {
        lat: 13.052858901207882,
        lng: 80.26226235051429,
      },
      {
        lat: 13.041732262290664,
        lng: 80.25218683622656,
      },
      {
        lat: 13.0526177092829,
        lng: 80.2621973175821,
      },
      {
        lat: 13.051532122248874,
        lng: 80.25058834954345,
      },
      {
        lat: 13.043999939941482,
        lng: 80.24813005373787,
      },
      {
        lat: 13.041957180200965,
        lng: 80.25356324631295,
      },
      {
        lat: 13.044260855969258,
        lng: 80.25166696869577,
      },
      {
        lat: 13.04319913067836,
        lng: 80.25741953289051,
      },
      {
        lat: 13.048015707723398,
        lng: 80.25131625060529,
      },
      {
        lat: 13.048703806070352,
        lng: 80.25035385406856,
      },
      {
        lat: 13.051491372606844,
        lng: 80.25319959690839,
      },
      {
        lat: 13.042209578042563,
        lng: 80.24932006042788,
      },
      {
        lat: 13.052402369618939,
        lng: 80.25932708084304,
      },
      {
        lat: 13.04695173651765,
        lng: 80.25893853442794,
      },
      {
        lat: 13.044113284572235,
        lng: 80.25929392675546,
      },
      {
        lat: 13.045181124835485,
        lng: 80.25039410564798,
      },
      {
        lat: 13.044204524038145,
        lng: 80.2480060857014,
      },
      {
        lat: 13.045482898409402,
        lng: 80.25681600973589,
      },
      {
        lat: 13.04036510676062,
        lng: 80.24807481399915,
      },
      {
        lat: 13.04667651279528,
        lng: 80.25320238049206,
      },
      {
        lat: 13.050313211544283,
        lng: 80.25385851834982,
      },
      {
        lat: 13.045778562875757,
        lng: 80.25250518674108,
      },
      {
        lat: 13.053782112985616,
        lng: 80.26188123953422,
      },
      {
        lat: 13.039680317959617,
        lng: 80.25757600424292,
      },
      {
        lat: 13.044203591987674,
        lng: 80.25074156826724,
      },
      {
        lat: 13.038477763000126,
        lng: 80.25598167504876,
      },
      {
        lat: 13.045851990920692,
        lng: 80.25504195082134,
      },
      {
        lat: 13.054210986961353,
        lng: 80.25941605544521,
      },
      {
        lat: 13.051489771318971,
        lng: 80.25076086997035,
      },
      {
        lat: 13.049106854140424,
        lng: 80.2588441368719,
      },
      {
        lat: 13.050112157892675,
        lng: 80.25904751486293,
      },
      {
        lat: 13.049917385681645,
        lng: 80.26029669980127,
      },
      {
        lat: 13.042910660591115,
        lng: 80.25560742367328,
      },
      {
        lat: 13.04069467963742,
        lng: 80.2561762261863,
      },
    ],
    cases: 38,
    coords: [
      {
        lat: 13.053883359670388,
        lng: 80.26387104060593,
      },
      {
        lat: 13.053653670665442,
        lng: 80.26386212701479,
      },
      {
        lat: 13.053433380557975,
        lng: 80.26374507014457,
      },
      {
        lat: 13.05259431122306,
        lng: 80.2636332501637,
      },
      {
        lat: 13.05195460699556,
        lng: 80.26348010248998,
      },
      {
        lat: 13.05145221616031,
        lng: 80.26292938855593,
      },
      {
        lat: 13.050789899268054,
        lng: 80.26234038770367,
      },
      {
        lat: 13.04975025935096,
        lng: 80.26207170354711,
      },
      {
        lat: 13.048990068117078,
        lng: 80.26180065434373,
      },
      {
        lat: 13.048389802940209,
        lng: 80.26156789205876,
      },
      {
        lat: 13.048268833744038,
        lng: 80.26137071378179,
      },
      {
        lat: 13.048287588889334,
        lng: 80.26117235298156,
      },
      {
        lat: 13.049051828476463,
        lng: 80.25882710059005,
      },
      {
        lat: 13.049010822308624,
        lng: 80.25864906577232,
      },
      {
        lat: 13.048850414061883,
        lng: 80.25853150210645,
      },
      {
        lat: 13.048050904324908,
        lng: 80.25835988670114,
      },
      {
        lat: 13.047691974803042,
        lng: 80.25842238503597,
      },
      {
        lat: 13.047573778198913,
        lng: 80.25868104805643,
      },
      {
        lat: 13.047225697949921,
        lng: 80.26052727320676,
      },
      {
        lat: 13.047066856820384,
        lng: 80.26066735896985,
      },
      {
        lat: 13.046807127662285,
        lng: 80.26062991671104,
      },
      {
        lat: 13.046085290551854,
        lng: 80.2601008802149,
      },
      {
        lat: 13.045544063403247,
        lng: 80.25972887682639,
      },
      {
        lat: 13.045284695887581,
        lng: 80.25975089212801,
      },
      {
        lat: 13.044865763957517,
        lng: 80.25979407807176,
      },
      {
        lat: 13.043886488091056,
        lng: 80.25960416386658,
      },
      {
        lat: 13.042787569050569,
        lng: 80.2594350824398,
      },
      {
        lat: 13.042167343253396,
        lng: 80.2592024890868,
      },
      {
        lat: 13.041205375962727,
        lng: 80.25857638383937,
      },
      {
        lat: 13.040144690497865,
        lng: 80.25812949593298,
      },
      {
        lat: 13.038942110836999,
        lng: 80.25732704518694,
      },
      {
        lat: 13.03818047303356,
        lng: 80.25681816574159,
      },
      {
        lat: 13.038483327952884,
        lng: 80.25410023423402,
      },
      {
        lat: 13.03903662987453,
        lng: 80.24989366759853,
      },
      {
        lat: 13.039519722739715,
        lng: 80.24711345570044,
      },
      {
        lat: 13.043713806552391,
        lng: 80.24787876645313,
      },
      {
        lat: 13.047191425524106,
        lng: 80.24871687469775,
      },
      {
        lat: 13.0485421874751,
        lng: 80.24979542929928,
      },
      {
        lat: 13.05022438261778,
        lng: 80.25011486176707,
      },
      {
        lat: 13.051805489730574,
        lng: 80.25043747094685,
      },
      {
        lat: 13.05197540790526,
        lng: 80.25070064716462,
      },
      {
        lat: 13.0523573433175,
        lng: 80.25099676437183,
      },
      {
        lat: 13.052318445573377,
        lng: 80.25101745220277,
      },
      {
        lat: 13.051563793783705,
        lng: 80.25172544834427,
      },
      {
        lat: 13.052859855157498,
        lng: 80.25397522060798,
      },
      {
        lat: 13.051764840248087,
        lng: 80.25466011180019,
      },
      {
        lat: 13.05137371833903,
        lng: 80.25489729174232,
      },
      {
        lat: 13.0511145508525,
        lng: 80.25531525363449,
      },
      {
        lat: 13.050826685020171,
        lng: 80.25531768991767,
      },
      {
        lat: 13.050014794584696,
        lng: 80.25522061903436,
      },
      {
        lat: 13.049299679789037,
        lng: 80.25508705168238,
      },
      {
        lat: 13.049224213147745,
        lng: 80.25486688359665,
      },
      {
        lat: 13.049247130431937,
        lng: 80.25457228060262,
      },
      {
        lat: 13.049345215922434,
        lng: 80.25444878004662,
      },
      {
        lat: 13.049295054045745,
        lng: 80.25432653414656,
      },
      {
        lat: 13.048898981645793,
        lng: 80.25420721577694,
      },
      {
        lat: 13.047909919777092,
        lng: 80.25409291603117,
      },
      {
        lat: 13.047442111173977,
        lng: 80.25436675016918,
      },
      {
        lat: 13.04687741082058,
        lng: 80.25496034745316,
      },
      {
        lat: 13.046169984148388,
        lng: 80.25646291387142,
      },
      {
        lat: 13.045975454560173,
        lng: 80.25697977604442,
      },
      {
        lat: 13.054426200255222,
        lng: 80.25790766155755,
      },
      {
        lat: 13.053857581731004,
        lng: 80.26333470905345,
      },
      {
        lat: 13.053883359670388,
        lng: 80.26387104060593,
      },
    ],
    properties: {
      Ward_No: 118,
      Zone_Name: "TEYNAMPET",
      fillColor: "#8bc24c",
      zname: "TEYNAMPET(118)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.050498287407684,
        lng: 80.27601953113866,
      },
      {
        lat: 13.049057084265664,
        lng: 80.27294465430327,
      },
      {
        lat: 13.047042371233276,
        lng: 80.27501814449096,
      },
      {
        lat: 13.047135249133467,
        lng: 80.27770633550497,
      },
      {
        lat: 13.049811756324953,
        lng: 80.2749504643638,
      },
      {
        lat: 13.048271898916601,
        lng: 80.27647591310628,
      },
      {
        lat: 13.047461623507331,
        lng: 80.27898229327336,
      },
      {
        lat: 13.050538093395815,
        lng: 80.27866651350975,
      },
      {
        lat: 13.04739183031433,
        lng: 80.27810708777173,
      },
      {
        lat: 13.051472886873944,
        lng: 80.27187152100049,
      },
      {
        lat: 13.050512549507783,
        lng: 80.2762198986201,
      },
      {
        lat: 13.050455118027223,
        lng: 80.27821012491336,
      },
      {
        lat: 13.04882005260372,
        lng: 80.27642975728742,
      },
      {
        lat: 13.049302461935284,
        lng: 80.27402512178693,
      },
      {
        lat: 13.048744014493696,
        lng: 80.27145927955306,
      },
      {
        lat: 13.050615350745758,
        lng: 80.27172486222688,
      },
      {
        lat: 13.048773603551203,
        lng: 80.27488373466457,
      },
      {
        lat: 13.048822540768585,
        lng: 80.27409468554006,
      },
      {
        lat: 13.050716532516722,
        lng: 80.27881484613944,
      },
      {
        lat: 13.049981170280784,
        lng: 80.27268966005911,
      },
      {
        lat: 13.049919196108721,
        lng: 80.27901069462744,
      },
      {
        lat: 13.048224426902584,
        lng: 80.2774232622983,
      },
      {
        lat: 13.04827608599047,
        lng: 80.27671202116818,
      },
      {
        lat: 13.049572168153494,
        lng: 80.27722091402784,
      },
      {
        lat: 13.049479622570301,
        lng: 80.27622089311578,
      },
      {
        lat: 13.04939181143252,
        lng: 80.27200043828297,
      },
      {
        lat: 13.048830269115307,
        lng: 80.27245381770237,
      },
      {
        lat: 13.050177089162,
        lng: 80.27266145050868,
      },
      {
        lat: 13.047638966372753,
        lng: 80.27926868285424,
      },
      {
        lat: 13.048428965180038,
        lng: 80.27132205625873,
      },
      {
        lat: 13.046518645060058,
        lng: 80.28053822565983,
      },
      {
        lat: 13.05041699563997,
        lng: 80.27404524965854,
      },
      {
        lat: 13.050799337413293,
        lng: 80.27302075987524,
      },
      {
        lat: 13.048598525964742,
        lng: 80.2773388809134,
      },
      {
        lat: 13.049501050842311,
        lng: 80.27430176663451,
      },
      {
        lat: 13.048684170907322,
        lng: 80.2754014185596,
      },
      {
        lat: 13.046727375106583,
        lng: 80.28064719755314,
      },
      {
        lat: 13.047663383941211,
        lng: 80.27756709102752,
      },
      {
        lat: 13.048254219189804,
        lng: 80.27756394169126,
      },
      {
        lat: 13.047727410595984,
        lng: 80.2721169469401,
      },
      {
        lat: 13.047592797381293,
        lng: 80.27511256863671,
      },
      {
        lat: 13.04907907708932,
        lng: 80.27285384638378,
      },
      {
        lat: 13.048259886238538,
        lng: 80.27565767282566,
      },
      {
        lat: 13.04770907309789,
        lng: 80.27462658421095,
      },
      {
        lat: 13.04877141186016,
        lng: 80.2759716292413,
      },
      {
        lat: 13.050192978316579,
        lng: 80.27388132042638,
      },
      {
        lat: 13.047300700968876,
        lng: 80.27906890453828,
      },
      {
        lat: 13.050021743980023,
        lng: 80.27406146682634,
      },
      {
        lat: 13.047655230016108,
        lng: 80.27525096769195,
      },
      {
        lat: 13.052463698427626,
        lng: 80.27459784806638,
      },
      {
        lat: 13.051490152639438,
        lng: 80.27634348788477,
      },
      {
        lat: 13.047910119475247,
        lng: 80.28000323397984,
      },
      {
        lat: 13.048732596056803,
        lng: 80.2781899386282,
      },
      {
        lat: 13.051397524539327,
        lng: 80.27345585411022,
      },
      {
        lat: 13.050039631064307,
        lng: 80.27593404791544,
      },
      {
        lat: 13.048116566690103,
        lng: 80.27478570851363,
      },
      {
        lat: 13.049999285423802,
        lng: 80.27815735918674,
      },
      {
        lat: 13.05016351326523,
        lng: 80.27898733443865,
      },
      {
        lat: 13.04688545046481,
        lng: 80.2769326830293,
      },
      {
        lat: 13.051318906249257,
        lng: 80.27199911688717,
      },
      {
        lat: 13.046579774318108,
        lng: 80.27839696019937,
      },
      {
        lat: 13.050229020329494,
        lng: 80.27533478851798,
      },
      {
        lat: 13.047352483050755,
        lng: 80.27695078658037,
      },
      {
        lat: 13.04799651407704,
        lng: 80.27141069733914,
      },
      {
        lat: 13.049659242632963,
        lng: 80.27635052602035,
      },
      {
        lat: 13.049596857464566,
        lng: 80.27442974890924,
      },
      {
        lat: 13.04760892849292,
        lng: 80.27718531744935,
      },
      {
        lat: 13.047467710781898,
        lng: 80.27793411755422,
      },
      {
        lat: 13.049792217575076,
        lng: 80.27545814285159,
      },
      {
        lat: 13.04827243612591,
        lng: 80.27967609215068,
      },
      {
        lat: 13.050148518528069,
        lng: 80.27779998692786,
      },
      {
        lat: 13.047411531833333,
        lng: 80.27194465191828,
      },
      {
        lat: 13.047764464421654,
        lng: 80.27966878510058,
      },
      {
        lat: 13.051039464545992,
        lng: 80.27543002431449,
      },
      {
        lat: 13.05027530982159,
        lng: 80.27283338067141,
      },
      {
        lat: 13.051212292645152,
        lng: 80.27121611344279,
      },
      {
        lat: 13.048727622964545,
        lng: 80.27641438186576,
      },
      {
        lat: 13.052044275772223,
        lng: 80.27529978995221,
      },
      {
        lat: 13.050706651075792,
        lng: 80.2785546734628,
      },
      {
        lat: 13.052226601884014,
        lng: 80.27401187229567,
      },
      {
        lat: 13.050179228791453,
        lng: 80.2784797333934,
      },
      {
        lat: 13.049295379638389,
        lng: 80.27755068879998,
      },
      {
        lat: 13.052454896037778,
        lng: 80.27427211418541,
      },
      {
        lat: 13.04868877753411,
        lng: 80.28047578831293,
      },
      {
        lat: 13.049746443009646,
        lng: 80.27945808700188,
      },
      {
        lat: 13.049977457341615,
        lng: 80.27133524619214,
      },
      {
        lat: 13.04772010375474,
        lng: 80.27620615913564,
      },
      {
        lat: 13.047393444872313,
        lng: 80.277118487532,
      },
      {
        lat: 13.04873040214689,
        lng: 80.28013656610406,
      },
      {
        lat: 13.049042840245784,
        lng: 80.27747345828935,
      },
      {
        lat: 13.04762108561926,
        lng: 80.27933579484733,
      },
      {
        lat: 13.049454874859963,
        lng: 80.2806202522222,
      },
      {
        lat: 13.047652478052461,
        lng: 80.27995511166063,
      },
      {
        lat: 13.048316536454198,
        lng: 80.27661688782582,
      },
      {
        lat: 13.051609477412788,
        lng: 80.2733012702893,
      },
      {
        lat: 13.048803165187183,
        lng: 80.27837471274076,
      },
      {
        lat: 13.05250739601906,
        lng: 80.27217605093998,
      },
      {
        lat: 13.049213665960576,
        lng: 80.2776312699314,
      },
      {
        lat: 13.048314600388656,
        lng: 80.28085745181986,
      },
      {
        lat: 13.049932933989743,
        lng: 80.27416246172156,
      },
      {
        lat: 13.049500432777798,
        lng: 80.27610753219432,
      },
      {
        lat: 13.047731690750764,
        lng: 80.2717193731684,
      },
      {
        lat: 13.05133344599514,
        lng: 80.27609710995242,
      },
      {
        lat: 13.0510354878674,
        lng: 80.27675824209446,
      },
      {
        lat: 13.052225155450875,
        lng: 80.27446830498711,
      },
      {
        lat: 13.051419083130872,
        lng: 80.2755362738099,
      },
      {
        lat: 13.049129539955008,
        lng: 80.27863056708041,
      },
      {
        lat: 13.05171856908253,
        lng: 80.27450074398855,
      },
      {
        lat: 13.050737552163767,
        lng: 80.27393510398119,
      },
      {
        lat: 13.049846798244912,
        lng: 80.27176090807338,
      },
      {
        lat: 13.04873605772423,
        lng: 80.27522952960994,
      },
      {
        lat: 13.051084412537083,
        lng: 80.27294889941605,
      },
      {
        lat: 13.05251833943083,
        lng: 80.27238389362883,
      },
      {
        lat: 13.049349214913738,
        lng: 80.27674368612217,
      },
      {
        lat: 13.047351535791636,
        lng: 80.27946934900002,
      },
      {
        lat: 13.049370338447606,
        lng: 80.27316407766973,
      },
      {
        lat: 13.050760732631856,
        lng: 80.2780400828367,
      },
      {
        lat: 13.049186542977488,
        lng: 80.27154899117741,
      },
      {
        lat: 13.047531985691847,
        lng: 80.27596829549543,
      },
      {
        lat: 13.052220795401253,
        lng: 80.27230651155448,
      },
      {
        lat: 13.049754102726908,
        lng: 80.27584676901468,
      },
      {
        lat: 13.049944957422861,
        lng: 80.27583362526924,
      },
      {
        lat: 13.049698454859957,
        lng: 80.27300875195652,
      },
      {
        lat: 13.051240164651974,
        lng: 80.27658249935438,
      },
      {
        lat: 13.05046039492993,
        lng: 80.27780171873437,
      },
      {
        lat: 13.050635002219083,
        lng: 80.2783545170763,
      },
      {
        lat: 13.049780177061768,
        lng: 80.27696511915126,
      },
      {
        lat: 13.048871309227785,
        lng: 80.27311218817697,
      },
      {
        lat: 13.052480725581262,
        lng: 80.27375447614044,
      },
      {
        lat: 13.050156840543963,
        lng: 80.27110340634196,
      },
      {
        lat: 13.047536482599929,
        lng: 80.27882314271616,
      },
      {
        lat: 13.047654821317067,
        lng: 80.2745865404581,
      },
      {
        lat: 13.04921567104775,
        lng: 80.27521129294091,
      },
      {
        lat: 13.04776401161166,
        lng: 80.27928901064602,
      },
      {
        lat: 13.049201122995962,
        lng: 80.27173854146524,
      },
      {
        lat: 13.04798265457825,
        lng: 80.28064328653858,
      },
      {
        lat: 13.048703875102772,
        lng: 80.2780538313276,
      },
      {
        lat: 13.050707128335127,
        lng: 80.27456509469,
      },
      {
        lat: 13.048263666202082,
        lng: 80.2715236397833,
      },
      {
        lat: 13.050307713902061,
        lng: 80.27635129768153,
      },
      {
        lat: 13.047461850849555,
        lng: 80.27528344524913,
      },
      {
        lat: 13.04924114019194,
        lng: 80.27234328495726,
      },
      {
        lat: 13.048719993558247,
        lng: 80.27679603340846,
      },
      {
        lat: 13.050346183514264,
        lng: 80.27549270153915,
      },
      {
        lat: 13.050318934424595,
        lng: 80.2755806085402,
      },
      {
        lat: 13.048386238929897,
        lng: 80.28021375047108,
      },
      {
        lat: 13.052373992109214,
        lng: 80.27139003863185,
      },
      {
        lat: 13.048749046806863,
        lng: 80.27898495721568,
      },
    ],
    cases: 148,
    coords: [
      {
        lat: 13.0462659293213,
        lng: 80.28086689983168,
      },
      {
        lat: 13.046656505884327,
        lng: 80.2767203368032,
      },
      {
        lat: 13.047035230321113,
        lng: 80.27442870116587,
      },
      {
        lat: 13.047752023988274,
        lng: 80.27438873208263,
      },
      {
        lat: 13.047974674209991,
        lng: 80.27450550708993,
      },
      {
        lat: 13.048555205770993,
        lng: 80.27451754523703,
      },
      {
        lat: 13.048622254286121,
        lng: 80.2743135617531,
      },
      {
        lat: 13.048568874933865,
        lng: 80.27395803545495,
      },
      {
        lat: 13.048499042749592,
        lng: 80.27370435641923,
      },
      {
        lat: 13.048548091949401,
        lng: 80.27334796324169,
      },
      {
        lat: 13.048700291393658,
        lng: 80.27310935643098,
      },
      {
        lat: 13.048767546106276,
        lng: 80.27293927387419,
      },
      {
        lat: 13.048698435614108,
        lng: 80.27280424808423,
      },
      {
        lat: 13.048407912086356,
        lng: 80.27275585285139,
      },
      {
        lat: 13.047315134665668,
        lng: 80.27273119863759,
      },
      {
        lat: 13.047525928518052,
        lng: 80.27089867031938,
      },
      {
        lat: 13.047748063245445,
        lng: 80.2709306930081,
      },
      {
        lat: 13.048021721545547,
        lng: 80.27101313364805,
      },
      {
        lat: 13.049814221208205,
        lng: 80.27099796325855,
      },
      {
        lat: 13.050173236635006,
        lng: 80.27107968149922,
      },
      {
        lat: 13.05056629181362,
        lng: 80.27114416031625,
      },
      {
        lat: 13.05089095819211,
        lng: 80.27119226658925,
      },
      {
        lat: 13.05106167244566,
        lng: 80.27119082179028,
      },
      {
        lat: 13.051283291678736,
        lng: 80.27113809216044,
      },
      {
        lat: 13.051471695950845,
        lng: 80.27123820566382,
      },
      {
        lat: 13.053266835398645,
        lng: 80.27129722137096,
      },
      {
        lat: 13.052862888592227,
        lng: 80.27359513759833,
      },
      {
        lat: 13.05131440310547,
        lng: 80.27768463736406,
      },
      {
        lat: 13.049831251910375,
        lng: 80.2811144615091,
      },
      {
        lat: 13.049078406409778,
        lng: 80.28099829665369,
      },
      {
        lat: 13.046993214944717,
        lng: 80.28065932874374,
      },
      {
        lat: 13.046419455625763,
        lng: 80.28080683078802,
      },
      {
        lat: 13.0462659293213,
        lng: 80.28086689983168,
      },
    ],
    properties: {
      Ward_No: 120,
      Zone_Name: "TEYNAMPET",
      fillColor: "#de4307",
      zname: "TEYNAMPET(120)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.043961315119647,
        lng: 80.2717672720594,
      },
      {
        lat: 13.040156093308832,
        lng: 80.26743076684986,
      },
      {
        lat: 13.043248454075894,
        lng: 80.27334219436702,
      },
      {
        lat: 13.043096118632578,
        lng: 80.27272862673261,
      },
      {
        lat: 13.044557152351597,
        lng: 80.27117238972247,
      },
      {
        lat: 13.042893457223446,
        lng: 80.26438644187284,
      },
      {
        lat: 13.043625109217913,
        lng: 80.27167896770064,
      },
      {
        lat: 13.042513619558827,
        lng: 80.26682725956715,
      },
      {
        lat: 13.042094781963034,
        lng: 80.2667571865945,
      },
      {
        lat: 13.04414511887527,
        lng: 80.27122460723616,
      },
      {
        lat: 13.044292914328114,
        lng: 80.27701247786156,
      },
      {
        lat: 13.044581543733578,
        lng: 80.27559270854277,
      },
      {
        lat: 13.043899745791588,
        lng: 80.27904447110508,
      },
      {
        lat: 13.04091488287403,
        lng: 80.26579409981419,
      },
      {
        lat: 13.042123644650125,
        lng: 80.26805778350509,
      },
      {
        lat: 13.04721117529722,
        lng: 80.27232880201078,
      },
      {
        lat: 13.043884029793333,
        lng: 80.27551795427657,
      },
      {
        lat: 13.044778069388922,
        lng: 80.27213083908019,
      },
      {
        lat: 13.04033879399303,
        lng: 80.26895801062948,
      },
      {
        lat: 13.041324941859365,
        lng: 80.26480193856986,
      },
      {
        lat: 13.04344526214627,
        lng: 80.27530711155893,
      },
      {
        lat: 13.041825578526527,
        lng: 80.26757669106406,
      },
      {
        lat: 13.04359250128931,
        lng: 80.26331321433375,
      },
      {
        lat: 13.04642816434742,
        lng: 80.27058400593319,
      },
      {
        lat: 13.045289440011501,
        lng: 80.27667313892152,
      },
      {
        lat: 13.044049711786526,
        lng: 80.27156580391092,
      },
      {
        lat: 13.043711299284592,
        lng: 80.28029426939757,
      },
      {
        lat: 13.043332964253114,
        lng: 80.2652346029055,
      },
      {
        lat: 13.044031149527456,
        lng: 80.28008265622357,
      },
      {
        lat: 13.041435477452161,
        lng: 80.27037794387542,
      },
      {
        lat: 13.039450770110257,
        lng: 80.26738798235942,
      },
      {
        lat: 13.042000919387307,
        lng: 80.26973436706885,
      },
      {
        lat: 13.04593690140783,
        lng: 80.2746909607509,
      },
      {
        lat: 13.042608995708258,
        lng: 80.26800287839664,
      },
      {
        lat: 13.046379804970666,
        lng: 80.27148721291285,
      },
      {
        lat: 13.043441955424738,
        lng: 80.27769947159,
      },
      {
        lat: 13.045030703904784,
        lng: 80.28022866961919,
      },
      {
        lat: 13.04697607767612,
        lng: 80.27345431496184,
      },
      {
        lat: 13.043455329499595,
        lng: 80.26769320953207,
      },
      {
        lat: 13.042053283916156,
        lng: 80.27093911637053,
      },
      {
        lat: 13.043188168930785,
        lng: 80.27412455716585,
      },
      {
        lat: 13.042128718445191,
        lng: 80.26432749913555,
      },
      {
        lat: 13.04368336588125,
        lng: 80.27944001625723,
      },
      {
        lat: 13.048554295811025,
        lng: 80.27401487294892,
      },
      {
        lat: 13.044532782793965,
        lng: 80.27406271112713,
      },
      {
        lat: 13.04285631662185,
        lng: 80.2641340901414,
      },
      {
        lat: 13.047775624885903,
        lng: 80.27276402048189,
      },
      {
        lat: 13.042468246346113,
        lng: 80.27082021492932,
      },
      {
        lat: 13.039545320120414,
        lng: 80.26642688717031,
      },
      {
        lat: 13.043425234631375,
        lng: 80.27286461999904,
      },
      {
        lat: 13.046713021666402,
        lng: 80.27517174950754,
      },
      {
        lat: 13.03973425945111,
        lng: 80.26921891917418,
      },
      {
        lat: 13.040370266904308,
        lng: 80.26938139555153,
      },
      {
        lat: 13.041748730548882,
        lng: 80.26699125218289,
      },
      {
        lat: 13.042115472317926,
        lng: 80.26785357943889,
      },
      {
        lat: 13.04163327803609,
        lng: 80.26860548754796,
      },
      {
        lat: 13.04381304941561,
        lng: 80.26797294951002,
      },
      {
        lat: 13.039777865287801,
        lng: 80.26567127322423,
      },
      {
        lat: 13.041750457582866,
        lng: 80.26509972883595,
      },
      {
        lat: 13.04610598471559,
        lng: 80.27813121990003,
      },
      {
        lat: 13.044545756297463,
        lng: 80.27351658317112,
      },
      {
        lat: 13.045971541934827,
        lng: 80.27475675767562,
      },
    ],
    cases: 62,
    coords: [
      {
        lat: 13.039094954828416,
        lng: 80.2660466427371,
      },
      {
        lat: 13.04163364554944,
        lng: 80.26454233301988,
      },
      {
        lat: 13.042077086063824,
        lng: 80.26403740422437,
      },
      {
        lat: 13.042306871271023,
        lng: 80.26351500765513,
      },
      {
        lat: 13.042518533539742,
        lng: 80.2632048003989,
      },
      {
        lat: 13.042751132448235,
        lng: 80.2631450038722,
      },
      {
        lat: 13.043023025410394,
        lng: 80.26316197876544,
      },
      {
        lat: 13.043412097108286,
        lng: 80.26329361790859,
      },
      {
        lat: 13.044752266604966,
        lng: 80.26339793167058,
      },
      {
        lat: 13.044754074049104,
        lng: 80.26688687128348,
      },
      {
        lat: 13.044365637419821,
        lng: 80.27005142173557,
      },
      {
        lat: 13.044212330044015,
        lng: 80.27038041111325,
      },
      {
        lat: 13.044290918151525,
        lng: 80.27053395395347,
      },
      {
        lat: 13.045203190846609,
        lng: 80.27050695715906,
      },
      {
        lat: 13.047591755375635,
        lng: 80.27062167407598,
      },
      {
        lat: 13.047525928518052,
        lng: 80.27089867031938,
      },
      {
        lat: 13.047315134665668,
        lng: 80.27273119863759,
      },
      {
        lat: 13.048407912086356,
        lng: 80.27275585285139,
      },
      {
        lat: 13.048698435614108,
        lng: 80.27280424808423,
      },
      {
        lat: 13.048767546106276,
        lng: 80.27293927387419,
      },
      {
        lat: 13.048700291393658,
        lng: 80.27310935643098,
      },
      {
        lat: 13.048548091949401,
        lng: 80.27334796324169,
      },
      {
        lat: 13.048499042749592,
        lng: 80.27370435641923,
      },
      {
        lat: 13.048568874933865,
        lng: 80.27395803545495,
      },
      {
        lat: 13.048622254286121,
        lng: 80.2743135617531,
      },
      {
        lat: 13.048555205770993,
        lng: 80.27451754523703,
      },
      {
        lat: 13.047974674209991,
        lng: 80.27450550708993,
      },
      {
        lat: 13.047752023988274,
        lng: 80.27438873208263,
      },
      {
        lat: 13.047035230321113,
        lng: 80.27442870116587,
      },
      {
        lat: 13.046656505884327,
        lng: 80.2767203368032,
      },
      {
        lat: 13.0462659293213,
        lng: 80.28086689983168,
      },
      {
        lat: 13.045953655745715,
        lng: 80.28098908068624,
      },
      {
        lat: 13.045163326863163,
        lng: 80.28096010790186,
      },
      {
        lat: 13.04366756909577,
        lng: 80.28078916354565,
      },
      {
        lat: 13.043577476639776,
        lng: 80.27981493705549,
      },
      {
        lat: 13.043452320376042,
        lng: 80.27934755850914,
      },
      {
        lat: 13.043406462822647,
        lng: 80.27468091839876,
      },
      {
        lat: 13.04350855402633,
        lng: 80.27422896614539,
      },
      {
        lat: 13.043542338212449,
        lng: 80.27403783520107,
      },
      {
        lat: 13.04348949875684,
        lng: 80.27396888420611,
      },
      {
        lat: 13.043315407307272,
        lng: 80.27407445487073,
      },
      {
        lat: 13.043193944271641,
        lng: 80.27421427922204,
      },
      {
        lat: 13.042652192570332,
        lng: 80.2742015146599,
      },
      {
        lat: 13.042667449090924,
        lng: 80.27383704504751,
      },
      {
        lat: 13.042734700900516,
        lng: 80.27340273719628,
      },
      {
        lat: 13.042940993724164,
        lng: 80.27284580577411,
      },
      {
        lat: 13.043041607636605,
        lng: 80.27215097236177,
      },
      {
        lat: 13.043020441951064,
        lng: 80.27154391733802,
      },
      {
        lat: 13.042374066546756,
        lng: 80.27156673732796,
      },
      {
        lat: 13.042358176901349,
        lng: 80.2718271150152,
      },
      {
        lat: 13.042148085328977,
        lng: 80.27175949488563,
      },
      {
        lat: 13.041935461257161,
        lng: 80.27127550705475,
      },
      {
        lat: 13.041617369315974,
        lng: 80.27068831454218,
      },
      {
        lat: 13.040597424494868,
        lng: 80.26962127466922,
      },
      {
        lat: 13.039833962719394,
        lng: 80.26931092839565,
      },
      {
        lat: 13.039407914850752,
        lng: 80.26924780028503,
      },
      {
        lat: 13.0393620280878,
        lng: 80.26907023166514,
      },
      {
        lat: 13.039450148705965,
        lng: 80.26882479504162,
      },
      {
        lat: 13.039583073744124,
        lng: 80.26857897922689,
      },
      {
        lat: 13.039622601741625,
        lng: 80.26771110445533,
      },
      {
        lat: 13.039307885015365,
        lng: 80.26769972672393,
      },
      {
        lat: 13.039360831907704,
        lng: 80.26709486352482,
      },
      {
        lat: 13.039339665812857,
        lng: 80.26689684058833,
      },
      {
        lat: 13.039078490085023,
        lng: 80.26662156808752,
      },
      {
        lat: 13.039076440777903,
        lng: 80.26628464191107,
      },
      {
        lat: 13.039094954828416,
        lng: 80.2660466427371,
      },
    ],
    properties: {
      Ward_No: 121,
      Zone_Name: "TEYNAMPET",
      fillColor: "#f6d04d",
      zname: "TEYNAMPET(121)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.130825477335197,
        lng: 80.15303005928779,
      },
      {
        lat: 13.134212765420019,
        lng: 80.14981962403438,
      },
      {
        lat: 13.135616789191078,
        lng: 80.15103961349188,
      },
      {
        lat: 13.135505418136743,
        lng: 80.15214806134749,
      },
      {
        lat: 13.133270954284706,
        lng: 80.15282227473361,
      },
      {
        lat: 13.129149718127799,
        lng: 80.15370266806089,
      },
      {
        lat: 13.139126725812634,
        lng: 80.15609250904153,
      },
      {
        lat: 13.130457343778247,
        lng: 80.15008433367413,
      },
      {
        lat: 13.13562319188922,
        lng: 80.15636216209688,
      },
      {
        lat: 13.134561915945543,
        lng: 80.15658926675094,
      },
      {
        lat: 13.122914302810386,
        lng: 80.14830139229244,
      },
      {
        lat: 13.125357980391904,
        lng: 80.15086924449793,
      },
      {
        lat: 13.132313340019046,
        lng: 80.15379614742511,
      },
      {
        lat: 13.123220752284558,
        lng: 80.14888469215427,
      },
      {
        lat: 13.134065879730922,
        lng: 80.15277618170718,
      },
      {
        lat: 13.12512623650348,
        lng: 80.1466878088837,
      },
      {
        lat: 13.140514381163754,
        lng: 80.15809752495919,
      },
      {
        lat: 13.133275620504275,
        lng: 80.14729753388417,
      },
      {
        lat: 13.132288051977959,
        lng: 80.15635202024448,
      },
      {
        lat: 13.125806201589791,
        lng: 80.1460215592065,
      },
      {
        lat: 13.136111315545577,
        lng: 80.14558765016837,
      },
      {
        lat: 13.125279724472037,
        lng: 80.1491546849962,
      },
      {
        lat: 13.136579829302844,
        lng: 80.15402194844036,
      },
      {
        lat: 13.137855241995322,
        lng: 80.15892979326516,
      },
      {
        lat: 13.137522273388015,
        lng: 80.15878394094192,
      },
      {
        lat: 13.13719642499917,
        lng: 80.14895946485292,
      },
      {
        lat: 13.13582266664401,
        lng: 80.15446181424394,
      },
      {
        lat: 13.129065721528915,
        lng: 80.15569153789026,
      },
      {
        lat: 13.123709393849662,
        lng: 80.14836576083904,
      },
      {
        lat: 13.125977066811245,
        lng: 80.14971192121304,
      },
      {
        lat: 13.14096558376982,
        lng: 80.15269424116202,
      },
      {
        lat: 13.129612476421023,
        lng: 80.15474766718815,
      },
      {
        lat: 13.135097118779269,
        lng: 80.1567036827397,
      },
      {
        lat: 13.137481468473505,
        lng: 80.1497349317655,
      },
      {
        lat: 13.126726304943306,
        lng: 80.15174463176668,
      },
      {
        lat: 13.128981966732095,
        lng: 80.15053892219422,
      },
      {
        lat: 13.14000610771316,
        lng: 80.1584381529134,
      },
      {
        lat: 13.131443309120284,
        lng: 80.14836627523759,
      },
      {
        lat: 13.125205594804516,
        lng: 80.15064197338474,
      },
      {
        lat: 13.136408215774653,
        lng: 80.15106308956213,
      },
      {
        lat: 13.133430422544187,
        lng: 80.14882065049815,
      },
      {
        lat: 13.127266388356903,
        lng: 80.15146363583587,
      },
      {
        lat: 13.136418354470191,
        lng: 80.1563455690388,
      },
      {
        lat: 13.132257239415948,
        lng: 80.14892497101617,
      },
      {
        lat: 13.137363402406905,
        lng: 80.14935686748233,
      },
      {
        lat: 13.13679158056992,
        lng: 80.14716438458058,
      },
    ],
    cases: 46,
    coords: [
      {
        lat: 13.1408303286251,
        lng: 80.15982440748073,
      },
      {
        lat: 13.1401761714962,
        lng: 80.16028954068057,
      },
      {
        lat: 13.139652498978027,
        lng: 80.16029397265689,
      },
      {
        lat: 13.138526401495675,
        lng: 80.16011091469014,
      },
      {
        lat: 13.137885185974612,
        lng: 80.1599237530416,
      },
      {
        lat: 13.137919759735153,
        lng: 80.15923014211656,
      },
      {
        lat: 13.136193345686742,
        lng: 80.15920623552195,
      },
      {
        lat: 13.136169382212264,
        lng: 80.15845534348612,
      },
      {
        lat: 13.134888942434976,
        lng: 80.15840840365351,
      },
      {
        lat: 13.134826188403556,
        lng: 80.15765783991216,
      },
      {
        lat: 13.134708294000987,
        lng: 80.1574084727348,
      },
      {
        lat: 13.134665169514587,
        lng: 80.15669626054792,
      },
      {
        lat: 13.134450532986204,
        lng: 80.15648622980864,
      },
      {
        lat: 13.133655795102747,
        lng: 80.1565699912501,
      },
      {
        lat: 13.133618293011319,
        lng: 80.15678215590408,
      },
      {
        lat: 13.13330820282247,
        lng: 80.15682329796213,
      },
      {
        lat: 13.133237064036392,
        lng: 80.1578831363479,
      },
      {
        lat: 13.132771577353486,
        lng: 80.15788707588243,
      },
      {
        lat: 13.132364862172277,
        lng: 80.15798681222932,
      },
      {
        lat: 13.131589051034293,
        lng: 80.15799337812014,
      },
      {
        lat: 13.131085593727102,
        lng: 80.15813245090521,
      },
      {
        lat: 13.130523247785213,
        lng: 80.15815646902685,
      },
      {
        lat: 13.130424982927424,
        lng: 80.15794545340391,
      },
      {
        lat: 13.128703322503874,
        lng: 80.15551415130606,
      },
      {
        lat: 13.127920834771857,
        lng: 80.15442301969847,
      },
      {
        lat: 13.127549630416961,
        lng: 80.15398320792717,
      },
      {
        lat: 13.127063108590528,
        lng: 80.15371770169703,
      },
      {
        lat: 13.125797964428617,
        lng: 80.1529965729375,
      },
      {
        lat: 13.125193313933154,
        lng: 80.1524431838282,
      },
      {
        lat: 13.123095529559103,
        lng: 80.14876324022332,
      },
      {
        lat: 13.122781222574023,
        lng: 80.14811109965078,
      },
      {
        lat: 13.122719639875063,
        lng: 80.14755311441806,
      },
      {
        lat: 13.122794526924688,
        lng: 80.14710952725937,
      },
      {
        lat: 13.12452804123694,
        lng: 80.14416990291357,
      },
      {
        lat: 13.125203464729848,
        lng: 80.14500236250403,
      },
      {
        lat: 13.127304965518139,
        lng: 80.14762236574005,
      },
      {
        lat: 13.127603937588844,
        lng: 80.1477924010831,
      },
      {
        lat: 13.128125152059209,
        lng: 80.14776333767824,
      },
      {
        lat: 13.13169737349086,
        lng: 80.14726471264144,
      },
      {
        lat: 13.132467006662754,
        lng: 80.14725819903629,
      },
      {
        lat: 13.132888613755084,
        lng: 80.14718067417115,
      },
      {
        lat: 13.135808889145475,
        lng: 80.14562752076364,
      },
      {
        lat: 13.136527219267391,
        lng: 80.14535026680207,
      },
      {
        lat: 13.138116589172425,
        lng: 80.145410772247,
      },
      {
        lat: 13.138489742000319,
        lng: 80.14553087531608,
      },
      {
        lat: 13.138540595242185,
        lng: 80.14572766278476,
      },
      {
        lat: 13.138255567227791,
        lng: 80.14785016696882,
      },
      {
        lat: 13.137991917559491,
        lng: 80.14940548889564,
      },
      {
        lat: 13.138019293349561,
        lng: 80.14982434514454,
      },
      {
        lat: 13.138293588479808,
        lng: 80.15001924156651,
      },
      {
        lat: 13.138940736566328,
        lng: 80.15028493913213,
      },
      {
        lat: 13.139812526029607,
        lng: 80.15074595335231,
      },
      {
        lat: 13.140364714756469,
        lng: 80.15172736930033,
      },
      {
        lat: 13.141066014678318,
        lng: 80.15273217551305,
      },
      {
        lat: 13.141570198949012,
        lng: 80.15398517228286,
      },
      {
        lat: 13.141532240210992,
        lng: 80.1559083676029,
      },
      {
        lat: 13.141167933612381,
        lng: 80.15724267133095,
      },
      {
        lat: 13.14083595069444,
        lng: 80.15980931307563,
      },
      {
        lat: 13.1408303286251,
        lng: 80.15982440748073,
      },
    ],
    properties: {
      Ward_No: 79,
      Zone_Name: "AMBATTUR",
      fillColor: "#8bc24c",
      zname: "AMBATTUR(79)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.128039048698009,
        lng: 80.16345582727672,
      },
      {
        lat: 13.122669436031606,
        lng: 80.15497752248167,
      },
      {
        lat: 13.129411506502162,
        lng: 80.16191451129608,
      },
      {
        lat: 13.124138977099001,
        lng: 80.15794959870804,
      },
      {
        lat: 13.129282034451823,
        lng: 80.16310876880702,
      },
      {
        lat: 13.13538345118181,
        lng: 80.16237783539015,
      },
      {
        lat: 13.13106822823478,
        lng: 80.1628154631426,
      },
      {
        lat: 13.124792935293465,
        lng: 80.15900130956368,
      },
      {
        lat: 13.134149346673714,
        lng: 80.16028526996408,
      },
      {
        lat: 13.136769531661251,
        lng: 80.16134313734956,
      },
      {
        lat: 13.133570870404771,
        lng: 80.15931109486381,
      },
      {
        lat: 13.12405341188132,
        lng: 80.1548651536921,
      },
      {
        lat: 13.12969212393986,
        lng: 80.16392091713077,
      },
      {
        lat: 13.125202753716172,
        lng: 80.15691053293362,
      },
      {
        lat: 13.140135920776967,
        lng: 80.16132919221569,
      },
      {
        lat: 13.126958985518025,
        lng: 80.15666914720772,
      },
      {
        lat: 13.128307749664199,
        lng: 80.15806115794803,
      },
      {
        lat: 13.126175490492933,
        lng: 80.16161430590986,
      },
      {
        lat: 13.125844171033368,
        lng: 80.15978735721038,
      },
      {
        lat: 13.138891059252408,
        lng: 80.16045808989287,
      },
      {
        lat: 13.123509134539963,
        lng: 80.15855621044325,
      },
      {
        lat: 13.13097323256763,
        lng: 80.16064344422985,
      },
      {
        lat: 13.129231657014095,
        lng: 80.15925574263386,
      },
      {
        lat: 13.125459926938998,
        lng: 80.15934007285836,
      },
      {
        lat: 13.126579498831218,
        lng: 80.16324850536112,
      },
      {
        lat: 13.133978308467663,
        lng: 80.15876967193323,
      },
      {
        lat: 13.12813487239267,
        lng: 80.15883282516782,
      },
      {
        lat: 13.126005361756254,
        lng: 80.16243271698046,
      },
      {
        lat: 13.126785258193731,
        lng: 80.15602040644188,
      },
      {
        lat: 13.123854468772866,
        lng: 80.15990724898134,
      },
      {
        lat: 13.1255715318091,
        lng: 80.1536845610662,
      },
      {
        lat: 13.124702626353947,
        lng: 80.15942125389671,
      },
      {
        lat: 13.131018904922897,
        lng: 80.15945967404771,
      },
      {
        lat: 13.139211480812952,
        lng: 80.16024138544724,
      },
      {
        lat: 13.123607384539373,
        lng: 80.15765132252663,
      },
      {
        lat: 13.127307657581648,
        lng: 80.15855279668983,
      },
      {
        lat: 13.127467170941646,
        lng: 80.16054340690405,
      },
      {
        lat: 13.134181132247273,
        lng: 80.15800954728567,
      },
      {
        lat: 13.13125013023853,
        lng: 80.16157579920024,
      },
      {
        lat: 13.137980755308305,
        lng: 80.16054810985796,
      },
      {
        lat: 13.12298067576297,
        lng: 80.15849985351467,
      },
      {
        lat: 13.12669502966648,
        lng: 80.16235602179992,
      },
      {
        lat: 13.129990837119959,
        lng: 80.15829977507352,
      },
      {
        lat: 13.133741418556351,
        lng: 80.16217984866269,
      },
      {
        lat: 13.123930548965781,
        lng: 80.15795305229271,
      },
      {
        lat: 13.126741055034364,
        lng: 80.16260692572129,
      },
      {
        lat: 13.130884065793703,
        lng: 80.16402242633013,
      },
      {
        lat: 13.138054296670711,
        lng: 80.16141384961193,
      },
      {
        lat: 13.139764602957172,
        lng: 80.16166318452277,
      },
      {
        lat: 13.133973884261284,
        lng: 80.15715378531482,
      },
      {
        lat: 13.12785008505518,
        lng: 80.15438685397265,
      },
      {
        lat: 13.132409108734976,
        lng: 80.16371547758186,
      },
      {
        lat: 13.13080706477838,
        lng: 80.16157200104999,
      },
      {
        lat: 13.127499879819617,
        lng: 80.16108069448558,
      },
      {
        lat: 13.13728692323932,
        lng: 80.16285206752406,
      },
      {
        lat: 13.139170464663648,
        lng: 80.1608848840864,
      },
      {
        lat: 13.137156561648762,
        lng: 80.16023935811904,
      },
      {
        lat: 13.132307911170141,
        lng: 80.16269649090168,
      },
      {
        lat: 13.132091840481202,
        lng: 80.16448121339135,
      },
      {
        lat: 13.130631871278784,
        lng: 80.16220873630178,
      },
      {
        lat: 13.123023435036458,
        lng: 80.15552515902624,
      },
      {
        lat: 13.132247416883194,
        lng: 80.16326831579836,
      },
      {
        lat: 13.13661710209522,
        lng: 80.16082566814391,
      },
      {
        lat: 13.124257221205571,
        lng: 80.16152309579702,
      },
      {
        lat: 13.130275285204972,
        lng: 80.16209947088849,
      },
      {
        lat: 13.12538931511024,
        lng: 80.15684127388887,
      },
      {
        lat: 13.12598306847364,
        lng: 80.16219925754241,
      },
      {
        lat: 13.138091961597958,
        lng: 80.16130925727708,
      },
      {
        lat: 13.13542071592022,
        lng: 80.16068221699543,
      },
      {
        lat: 13.1240531774054,
        lng: 80.15256839166102,
      },
      {
        lat: 13.137641274724368,
        lng: 80.16123078080584,
      },
      {
        lat: 13.130363073063812,
        lng: 80.15943700217021,
      },
      {
        lat: 13.128997188867675,
        lng: 80.1605539534595,
      },
      {
        lat: 13.123656660744507,
        lng: 80.16047320714172,
      },
      {
        lat: 13.126283826676652,
        lng: 80.15682279691343,
      },
      {
        lat: 13.136554159190554,
        lng: 80.16338963342005,
      },
      {
        lat: 13.130760547683128,
        lng: 80.16289531025392,
      },
      {
        lat: 13.1282193042833,
        lng: 80.15974839722259,
      },
      {
        lat: 13.138303730413096,
        lng: 80.1614642075182,
      },
      {
        lat: 13.12611415655102,
        lng: 80.16015996738504,
      },
      {
        lat: 13.127980546560398,
        lng: 80.1602455233435,
      },
      {
        lat: 13.13337317213251,
        lng: 80.15814744683401,
      },
      {
        lat: 13.127472004995361,
        lng: 80.15435706658027,
      },
      {
        lat: 13.125446795816694,
        lng: 80.15501444086135,
      },
      {
        lat: 13.130147164923782,
        lng: 80.15923069586907,
      },
      {
        lat: 13.123177629944008,
        lng: 80.15427984828743,
      },
      {
        lat: 13.125310820259097,
        lng: 80.15672831279429,
      },
      {
        lat: 13.129350183707531,
        lng: 80.16264854566009,
      },
      {
        lat: 13.138142464588979,
        lng: 80.16052218092919,
      },
      {
        lat: 13.126257339639455,
        lng: 80.15599912838344,
      },
      {
        lat: 13.130874865987249,
        lng: 80.16457595537165,
      },
      {
        lat: 13.135877806077682,
        lng: 80.1618903052703,
      },
      {
        lat: 13.126331727093813,
        lng: 80.1542134694733,
      },
    ],
    cases: 93,
    coords: [
      {
        lat: 13.13387527725108,
        lng: 80.1645874861034,
      },
      {
        lat: 13.133158667086464,
        lng: 80.16459355096104,
      },
      {
        lat: 13.132388129874291,
        lng: 80.16423520063697,
      },
      {
        lat: 13.132204502517258,
        lng: 80.16425333979463,
      },
      {
        lat: 13.132172005225987,
        lng: 80.16440288047427,
      },
      {
        lat: 13.13230784492261,
        lng: 80.16476660240801,
      },
      {
        lat: 13.132309660604669,
        lng: 80.16506511833455,
      },
      {
        lat: 13.132176544431136,
        lng: 80.16514917029048,
      },
      {
        lat: 13.131859195673442,
        lng: 80.16515185609529,
      },
      {
        lat: 13.130468847829896,
        lng: 80.16450022009607,
      },
      {
        lat: 13.130218107594406,
        lng: 80.16446917203214,
      },
      {
        lat: 13.130101794858339,
        lng: 80.16456966684797,
      },
      {
        lat: 13.129727218602293,
        lng: 80.16614841880829,
      },
      {
        lat: 13.129610704123767,
        lng: 80.16621574518794,
      },
      {
        lat: 13.129476579237977,
        lng: 80.16613395496253,
      },
      {
        lat: 13.12929234665382,
        lng: 80.16605258881143,
      },
      {
        lat: 13.128841579108917,
        lng: 80.16608957391784,
      },
      {
        lat: 13.128773861002886,
        lng: 80.1659408813871,
      },
      {
        lat: 13.128771540964703,
        lng: 80.16555944437005,
      },
      {
        lat: 13.128853338984937,
        lng: 80.16527680587092,
      },
      {
        lat: 13.128846177127931,
        lng: 80.16409932638328,
      },
      {
        lat: 13.127608270676067,
        lng: 80.16379468674161,
      },
      {
        lat: 13.126955458397063,
        lng: 80.1635680206555,
      },
      {
        lat: 13.126669900832123,
        lng: 80.1633050762539,
      },
      {
        lat: 13.125717753687653,
        lng: 80.16329654945021,
      },
      {
        lat: 13.124347437160777,
        lng: 80.16319205129149,
      },
      {
        lat: 13.123829052381069,
        lng: 80.16309692808538,
      },
      {
        lat: 13.124120821904853,
        lng: 80.16163497244267,
      },
      {
        lat: 13.124068999395481,
        lng: 80.16135346480878,
      },
      {
        lat: 13.123951476204702,
        lng: 80.16125494900687,
      },
      {
        lat: 13.12385105906512,
        lng: 80.16122262871957,
      },
      {
        lat: 13.123665918639702,
        lng: 80.16099200460526,
      },
      {
        lat: 13.12300685234495,
        lng: 80.15973711699468,
      },
      {
        lat: 13.12263213316026,
        lng: 80.15854616316811,
      },
      {
        lat: 13.122526168103272,
        lng: 80.15760171088326,
      },
      {
        lat: 13.122403500480054,
        lng: 80.15665739995634,
      },
      {
        lat: 13.122381149125134,
        lng: 80.15572882509879,
      },
      {
        lat: 13.122622205723026,
        lng: 80.1541677882218,
      },
      {
        lat: 13.122688108146598,
        lng: 80.15401796482587,
      },
      {
        lat: 13.12318878164782,
        lng: 80.15394738720903,
      },
      {
        lat: 13.123355000339975,
        lng: 80.15381329988237,
      },
      {
        lat: 13.123504012109771,
        lng: 80.15359643282325,
      },
      {
        lat: 13.123598881332128,
        lng: 80.15271662111303,
      },
      {
        lat: 13.12367926715503,
        lng: 80.15220180356002,
      },
      {
        lat: 13.12367906541258,
        lng: 80.15216863512381,
      },
      {
        lat: 13.12379568076233,
        lng: 80.15211789296242,
      },
      {
        lat: 13.123979408990529,
        lng: 80.15211633802276,
      },
      {
        lat: 13.124147039879842,
        lng: 80.15221442974999,
      },
      {
        lat: 13.125193313933154,
        lng: 80.1524431838282,
      },
      {
        lat: 13.125797964428617,
        lng: 80.1529965729375,
      },
      {
        lat: 13.127063108590528,
        lng: 80.15371770169703,
      },
      {
        lat: 13.127549630416961,
        lng: 80.15398320792717,
      },
      {
        lat: 13.127920834771857,
        lng: 80.15442301969847,
      },
      {
        lat: 13.128703322503874,
        lng: 80.15551415130606,
      },
      {
        lat: 13.130424982927424,
        lng: 80.15794545340391,
      },
      {
        lat: 13.130523247785213,
        lng: 80.15815646902685,
      },
      {
        lat: 13.131085593727102,
        lng: 80.15813245090521,
      },
      {
        lat: 13.131589051034293,
        lng: 80.15799337812014,
      },
      {
        lat: 13.132364862172277,
        lng: 80.15798681222932,
      },
      {
        lat: 13.132771577353486,
        lng: 80.15788707588243,
      },
      {
        lat: 13.133237064036392,
        lng: 80.1578831363479,
      },
      {
        lat: 13.13330820282247,
        lng: 80.15682329796213,
      },
      {
        lat: 13.133618293011319,
        lng: 80.15678215590408,
      },
      {
        lat: 13.133655795102747,
        lng: 80.1565699912501,
      },
      {
        lat: 13.134450532986204,
        lng: 80.15648622980864,
      },
      {
        lat: 13.134665169514587,
        lng: 80.15669626054792,
      },
      {
        lat: 13.134708294000987,
        lng: 80.1574084727348,
      },
      {
        lat: 13.134826188403556,
        lng: 80.15765783991216,
      },
      {
        lat: 13.134888942434976,
        lng: 80.15840840365351,
      },
      {
        lat: 13.136169382212264,
        lng: 80.15845534348612,
      },
      {
        lat: 13.136193345686742,
        lng: 80.15920623552195,
      },
      {
        lat: 13.137919759735153,
        lng: 80.15923014211656,
      },
      {
        lat: 13.137885185974612,
        lng: 80.1599237530416,
      },
      {
        lat: 13.138526401495675,
        lng: 80.16011091469014,
      },
      {
        lat: 13.139652498978027,
        lng: 80.16029397265689,
      },
      {
        lat: 13.1401761714962,
        lng: 80.16028954068057,
      },
      {
        lat: 13.1408303286251,
        lng: 80.15982440748073,
      },
      {
        lat: 13.140274078636047,
        lng: 80.16131785447254,
      },
      {
        lat: 13.139953278410582,
        lng: 80.16164104849908,
      },
      {
        lat: 13.139209071859952,
        lng: 80.16174595583854,
      },
      {
        lat: 13.138664529873585,
        lng: 80.16202173898624,
      },
      {
        lat: 13.138220344946271,
        lng: 80.16246923840734,
      },
      {
        lat: 13.137578744495052,
        lng: 80.16311562646013,
      },
      {
        lat: 13.136805844693123,
        lng: 80.16358884253869,
      },
      {
        lat: 13.135226249092801,
        lng: 80.16386388844231,
      },
      {
        lat: 13.134059864484282,
        lng: 80.16398590730489,
      },
      {
        lat: 13.133873219455289,
        lng: 80.16424916430663,
      },
      {
        lat: 13.13387527725108,
        lng: 80.1645874861034,
      },
    ],
    properties: {
      Ward_No: 80,
      Zone_Name: "AMBATTUR",
      fillColor: "#f6d04d",
      zname: "AMBATTUR(80)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.135958269973159,
        lng: 80.185332197139,
      },
      {
        lat: 13.113388295721865,
        lng: 80.19124940644146,
      },
      {
        lat: 13.114484314989886,
        lng: 80.17857798217354,
      },
      {
        lat: 13.114721203132703,
        lng: 80.18152716629177,
      },
      {
        lat: 13.1241750745179,
        lng: 80.19395419693257,
      },
      {
        lat: 13.115392075217471,
        lng: 80.19525647796029,
      },
      {
        lat: 13.114359069800441,
        lng: 80.19696624843765,
      },
      {
        lat: 13.118409587300198,
        lng: 80.18977023024003,
      },
      {
        lat: 13.114915817393554,
        lng: 80.18828262656345,
      },
      {
        lat: 13.118006586731164,
        lng: 80.18775641418439,
      },
      {
        lat: 13.118640580709982,
        lng: 80.19760556218317,
      },
      {
        lat: 13.116661894091415,
        lng: 80.19694957044858,
      },
      {
        lat: 13.10821853379826,
        lng: 80.19597496408123,
      },
      {
        lat: 13.123939005067273,
        lng: 80.18567604114949,
      },
      {
        lat: 13.113822048738058,
        lng: 80.18463441680926,
      },
      {
        lat: 13.116541772466814,
        lng: 80.19627688796282,
      },
      {
        lat: 13.115379099863437,
        lng: 80.18583254585963,
      },
      {
        lat: 13.121720382514734,
        lng: 80.19490272795387,
      },
      {
        lat: 13.134856226878863,
        lng: 80.1883079787985,
      },
      {
        lat: 13.122184218948902,
        lng: 80.18622337799624,
      },
      {
        lat: 13.12013519720914,
        lng: 80.18226561485632,
      },
      {
        lat: 13.119104922019433,
        lng: 80.18300550992006,
      },
      {
        lat: 13.127820899068938,
        lng: 80.1994623561032,
      },
      {
        lat: 13.117119432949943,
        lng: 80.19819213667608,
      },
      {
        lat: 13.124865982967005,
        lng: 80.18370443130387,
      },
      {
        lat: 13.113878285760311,
        lng: 80.19836949206535,
      },
      {
        lat: 13.123229782149327,
        lng: 80.18785701575773,
      },
      {
        lat: 13.116231410429192,
        lng: 80.1931457345974,
      },
      {
        lat: 13.11841499754644,
        lng: 80.19959577751348,
      },
      {
        lat: 13.116341886981669,
        lng: 80.18220809066594,
      },
      {
        lat: 13.123668536230168,
        lng: 80.19768218672299,
      },
      {
        lat: 13.127510882909167,
        lng: 80.19791162173621,
      },
      {
        lat: 13.121255099976544,
        lng: 80.19467203128919,
      },
      {
        lat: 13.128085587126156,
        lng: 80.18640421908108,
      },
      {
        lat: 13.110091245687649,
        lng: 80.19421568937152,
      },
      {
        lat: 13.129913990316865,
        lng: 80.18634355395405,
      },
      {
        lat: 13.120223286577339,
        lng: 80.19401538902449,
      },
      {
        lat: 13.1102969684615,
        lng: 80.18024992592777,
      },
      {
        lat: 13.124601909388966,
        lng: 80.18769171799038,
      },
      {
        lat: 13.121300431811287,
        lng: 80.19788819082608,
      },
      {
        lat: 13.120446328014555,
        lng: 80.17978359750529,
      },
      {
        lat: 13.117204887164597,
        lng: 80.19824960389697,
      },
      {
        lat: 13.11576875145974,
        lng: 80.19036733014215,
      },
      {
        lat: 13.112762116343804,
        lng: 80.18271950189634,
      },
      {
        lat: 13.116863619528663,
        lng: 80.19009296938053,
      },
      {
        lat: 13.134530361944664,
        lng: 80.19148586937325,
      },
      {
        lat: 13.124668733066898,
        lng: 80.18593903699075,
      },
      {
        lat: 13.125622139487438,
        lng: 80.19043444441697,
      },
      {
        lat: 13.124769808452346,
        lng: 80.19252133458187,
      },
      {
        lat: 13.123209288507589,
        lng: 80.18163375301904,
      },
      {
        lat: 13.11154330859564,
        lng: 80.19207393253174,
      },
      {
        lat: 13.120548398819746,
        lng: 80.18639994457881,
      },
      {
        lat: 13.114624937279867,
        lng: 80.19675413013822,
      },
      {
        lat: 13.118595613507278,
        lng: 80.17783425799901,
      },
      {
        lat: 13.122039393784092,
        lng: 80.17507341917636,
      },
      {
        lat: 13.124574637572671,
        lng: 80.18491268146023,
      },
      {
        lat: 13.125288095906322,
        lng: 80.19472214203456,
      },
      {
        lat: 13.112836193947851,
        lng: 80.18799313515929,
      },
      {
        lat: 13.11683320163762,
        lng: 80.19017295539383,
      },
      {
        lat: 13.123402738593319,
        lng: 80.18975334555026,
      },
      {
        lat: 13.119778657839477,
        lng: 80.19599432619732,
      },
      {
        lat: 13.126277417866326,
        lng: 80.19148456784853,
      },
      {
        lat: 13.109343899070394,
        lng: 80.18879553506807,
      },
      {
        lat: 13.115655835858663,
        lng: 80.17478612040634,
      },
      {
        lat: 13.115746440658285,
        lng: 80.20014868832703,
      },
      {
        lat: 13.11431446003726,
        lng: 80.19314216077599,
      },
      {
        lat: 13.109416809447932,
        lng: 80.19536895800913,
      },
      {
        lat: 13.137022671102253,
        lng: 80.18666787773486,
      },
      {
        lat: 13.127277741894593,
        lng: 80.17488812427764,
      },
      {
        lat: 13.121105332482633,
        lng: 80.18792786081632,
      },
      {
        lat: 13.111039496238156,
        lng: 80.1868634822889,
      },
      {
        lat: 13.130963655625196,
        lng: 80.184169891638,
      },
      {
        lat: 13.124875165792217,
        lng: 80.19583864000505,
      },
      {
        lat: 13.12227067504527,
        lng: 80.19649193234109,
      },
      {
        lat: 13.119112332158702,
        lng: 80.19352157618995,
      },
      {
        lat: 13.112219734159952,
        lng: 80.18284361327228,
      },
      {
        lat: 13.126246958870942,
        lng: 80.19471950404788,
      },
      {
        lat: 13.119847321534523,
        lng: 80.20193929946883,
      },
      {
        lat: 13.11087963352483,
        lng: 80.19373499206654,
      },
      {
        lat: 13.135762632665033,
        lng: 80.18409876417002,
      },
      {
        lat: 13.118589626147976,
        lng: 80.19772211026105,
      },
      {
        lat: 13.120461600200933,
        lng: 80.19980609851646,
      },
      {
        lat: 13.137364880930136,
        lng: 80.18528131902964,
      },
      {
        lat: 13.122127511642235,
        lng: 80.19720369284947,
      },
      {
        lat: 13.122039047076258,
        lng: 80.18153596571618,
      },
      {
        lat: 13.115546333969583,
        lng: 80.18901123503598,
      },
      {
        lat: 13.125499959456471,
        lng: 80.18989965858812,
      },
      {
        lat: 13.111698339795213,
        lng: 80.19428070013626,
      },
      {
        lat: 13.120570127384001,
        lng: 80.19545425240106,
      },
      {
        lat: 13.137747861292578,
        lng: 80.1876135300125,
      },
      {
        lat: 13.122916043838455,
        lng: 80.19771967464006,
      },
      {
        lat: 13.12261445777016,
        lng: 80.19739268357844,
      },
      {
        lat: 13.114167370107848,
        lng: 80.19021548768443,
      },
      {
        lat: 13.118967265148639,
        lng: 80.20125193261792,
      },
      {
        lat: 13.121980423372687,
        lng: 80.19397926671473,
      },
      {
        lat: 13.122850634897665,
        lng: 80.18429537899617,
      },
      {
        lat: 13.108662667816038,
        lng: 80.18592266253431,
      },
      {
        lat: 13.123039208554847,
        lng: 80.19036868755116,
      },
      {
        lat: 13.115977626767693,
        lng: 80.19520961150788,
      },
      {
        lat: 13.114926797378812,
        lng: 80.18748938619821,
      },
      {
        lat: 13.123399735859486,
        lng: 80.17737750820984,
      },
      {
        lat: 13.113409625845751,
        lng: 80.19613674532918,
      },
      {
        lat: 13.11303995206119,
        lng: 80.1843280164063,
      },
      {
        lat: 13.113880128827455,
        lng: 80.18048496470908,
      },
      {
        lat: 13.125320332973157,
        lng: 80.18453198102404,
      },
      {
        lat: 13.109150622803158,
        lng: 80.18968207761189,
      },
      {
        lat: 13.128474575761743,
        lng: 80.18661049802247,
      },
      {
        lat: 13.135820948786176,
        lng: 80.18829159769317,
      },
      {
        lat: 13.115160670596138,
        lng: 80.17473808184745,
      },
      {
        lat: 13.1245333565743,
        lng: 80.18332732023825,
      },
      {
        lat: 13.132782971873468,
        lng: 80.19068658260231,
      },
      {
        lat: 13.12080302741723,
        lng: 80.19781905855298,
      },
      {
        lat: 13.110411317918693,
        lng: 80.18692398499188,
      },
      {
        lat: 13.128291466754098,
        lng: 80.18780178260235,
      },
      {
        lat: 13.115637508801488,
        lng: 80.19060825992119,
      },
      {
        lat: 13.120245987227758,
        lng: 80.18399826342096,
      },
      {
        lat: 13.11355627578114,
        lng: 80.199603058498,
      },
      {
        lat: 13.118737311819554,
        lng: 80.18631081301166,
      },
      {
        lat: 13.115692459024476,
        lng: 80.19176665115957,
      },
      {
        lat: 13.117925809484257,
        lng: 80.19831333936156,
      },
      {
        lat: 13.129809495499257,
        lng: 80.18323627385617,
      },
      {
        lat: 13.127904074558439,
        lng: 80.18660814567914,
      },
      {
        lat: 13.121974222062999,
        lng: 80.19068551292104,
      },
      {
        lat: 13.126739534855773,
        lng: 80.1974988130903,
      },
      {
        lat: 13.114498614553147,
        lng: 80.18859698442881,
      },
      {
        lat: 13.125839786905075,
        lng: 80.17589837684889,
      },
      {
        lat: 13.12801796941766,
        lng: 80.19565737392782,
      },
      {
        lat: 13.117858819464466,
        lng: 80.17902137339776,
      },
      {
        lat: 13.124648135314956,
        lng: 80.19614364972732,
      },
      {
        lat: 13.111853122137116,
        lng: 80.18610284580477,
      },
      {
        lat: 13.125240323420472,
        lng: 80.19390905832486,
      },
      {
        lat: 13.126458461335474,
        lng: 80.18190407237942,
      },
      {
        lat: 13.112097197068296,
        lng: 80.17993450897349,
      },
      {
        lat: 13.11035314187345,
        lng: 80.18316346596413,
      },
      {
        lat: 13.12236918846674,
        lng: 80.20003024727876,
      },
      {
        lat: 13.1138932116572,
        lng: 80.1845523201055,
      },
      {
        lat: 13.114496927699054,
        lng: 80.19384922424068,
      },
      {
        lat: 13.115350204649063,
        lng: 80.18551471471415,
      },
      {
        lat: 13.133149770367629,
        lng: 80.18393787914505,
      },
      {
        lat: 13.116389133021181,
        lng: 80.1744775053435,
      },
      {
        lat: 13.110105001518276,
        lng: 80.18096196399429,
      },
      {
        lat: 13.125161514165324,
        lng: 80.19294478802095,
      },
      {
        lat: 13.11166833137231,
        lng: 80.18475828609127,
      },
      {
        lat: 13.109618796154377,
        lng: 80.18747477006487,
      },
      {
        lat: 13.116178704291464,
        lng: 80.20047059668052,
      },
      {
        lat: 13.10857364787553,
        lng: 80.18389154764296,
      },
      {
        lat: 13.11644400909208,
        lng: 80.17514111985422,
      },
      {
        lat: 13.111050260595503,
        lng: 80.19038283430457,
      },
      {
        lat: 13.124687597096068,
        lng: 80.19338708166873,
      },
      {
        lat: 13.123206667162675,
        lng: 80.18006027013647,
      },
      {
        lat: 13.129112243962343,
        lng: 80.19006639293828,
      },
      {
        lat: 13.116918484262102,
        lng: 80.20013252527058,
      },
      {
        lat: 13.112983930909293,
        lng: 80.1885413368713,
      },
      {
        lat: 13.12694011550279,
        lng: 80.18690598837165,
      },
      {
        lat: 13.120290487363706,
        lng: 80.19101198252754,
      },
      {
        lat: 13.132640183096713,
        lng: 80.18626296557896,
      },
      {
        lat: 13.13343268800355,
        lng: 80.19228725300445,
      },
      {
        lat: 13.114482645376548,
        lng: 80.18438764666276,
      },
      {
        lat: 13.113439073131772,
        lng: 80.19756042312943,
      },
      {
        lat: 13.129514717163296,
        lng: 80.18408078690358,
      },
      {
        lat: 13.111122742538436,
        lng: 80.18994980603796,
      },
      {
        lat: 13.118134876454933,
        lng: 80.18491404091672,
      },
      {
        lat: 13.129889282863004,
        lng: 80.18460712743322,
      },
      {
        lat: 13.115484510838218,
        lng: 80.18230585891335,
      },
      {
        lat: 13.113577263977147,
        lng: 80.1874092811598,
      },
      {
        lat: 13.12423686737103,
        lng: 80.19201283944395,
      },
      {
        lat: 13.118550998263986,
        lng: 80.18130755445489,
      },
    ],
    cases: 167,
    coords: [
      {
        lat: 13.113809633699043,
        lng: 80.17987539402556,
      },
      {
        lat: 13.114235507816774,
        lng: 80.17988067964853,
      },
      {
        lat: 13.114422619273777,
        lng: 80.1779891770509,
      },
      {
        lat: 13.114602034359296,
        lng: 80.17483231554672,
      },
      {
        lat: 13.114716688446958,
        lng: 80.17463413625775,
      },
      {
        lat: 13.115014397597896,
        lng: 80.17459874851092,
      },
      {
        lat: 13.115344707990785,
        lng: 80.17448091445921,
      },
      {
        lat: 13.115558165332788,
        lng: 80.17419972858018,
      },
      {
        lat: 13.115706220285247,
        lng: 80.17405056884644,
      },
      {
        lat: 13.115922176449276,
        lng: 80.17418021378118,
      },
      {
        lat: 13.116453491749054,
        lng: 80.17445509645616,
      },
      {
        lat: 13.117363969327926,
        lng: 80.17448025900525,
      },
      {
        lat: 13.117385517475032,
        lng: 80.17530178056155,
      },
      {
        lat: 13.117886330543197,
        lng: 80.17600420742532,
      },
      {
        lat: 13.118202989066281,
        lng: 80.17636307718848,
      },
      {
        lat: 13.118502097557654,
        lng: 80.17655775469747,
      },
      {
        lat: 13.11856909919317,
        lng: 80.17668866027317,
      },
      {
        lat: 13.118604299209968,
        lng: 80.17703347801432,
      },
      {
        lat: 13.118639299320783,
        lng: 80.17734542929041,
      },
      {
        lat: 13.118956557561296,
        lng: 80.17780289844892,
      },
      {
        lat: 13.119241114606922,
        lng: 80.17832638068012,
      },
      {
        lat: 13.119324766698425,
        lng: 80.1784735794171,
      },
      {
        lat: 13.11945697081796,
        lng: 80.17843959238247,
      },
      {
        lat: 13.119753780393008,
        lng: 80.17825630554283,
      },
      {
        lat: 13.120528555502075,
        lng: 80.17774029198726,
      },
      {
        lat: 13.120887968786128,
        lng: 80.17696484849104,
      },
      {
        lat: 13.12108087750997,
        lng: 80.17602647338131,
      },
      {
        lat: 13.121475090999255,
        lng: 80.17553011469583,
      },
      {
        lat: 13.121885155321701,
        lng: 80.17491858331161,
      },
      {
        lat: 13.122083361547986,
        lng: 80.17485116952703,
      },
      {
        lat: 13.122506806003408,
        lng: 80.17473871003682,
      },
      {
        lat: 13.12405841567386,
        lng: 80.17472557836174,
      },
      {
        lat: 13.125031007616018,
        lng: 80.17475843226025,
      },
      {
        lat: 13.125652900895245,
        lng: 80.17495859499697,
      },
      {
        lat: 13.126066788415036,
        lng: 80.17497563475773,
      },
      {
        lat: 13.126665869566637,
        lng: 80.17482676639187,
      },
      {
        lat: 13.127161759955642,
        lng: 80.17471985655251,
      },
      {
        lat: 13.127472331771912,
        lng: 80.17475831329882,
      },
      {
        lat: 13.127619022790912,
        lng: 80.17506521078624,
      },
      {
        lat: 13.127848716207831,
        lng: 80.17541249099888,
      },
      {
        lat: 13.128723614795932,
        lng: 80.17639113121378,
      },
      {
        lat: 13.128766115523568,
        lng: 80.17657565490214,
      },
      {
        lat: 13.128602359667509,
        lng: 80.17686463718371,
      },
      {
        lat: 13.128396727789005,
        lng: 80.17707180348062,
      },
      {
        lat: 13.128185319272973,
        lng: 80.17711985429654,
      },
      {
        lat: 13.128156787112543,
        lng: 80.17728127238597,
      },
      {
        lat: 13.128364087158298,
        lng: 80.17739673730797,
      },
      {
        lat: 13.128752115657802,
        lng: 80.1781114218774,
      },
      {
        lat: 13.128828837621523,
        lng: 80.17859430240281,
      },
      {
        lat: 13.12871622396437,
        lng: 80.17948905307045,
      },
      {
        lat: 13.128526620993298,
        lng: 80.17985696821708,
      },
      {
        lat: 13.12799619904171,
        lng: 80.17999332909106,
      },
      {
        lat: 13.12756924887967,
        lng: 80.18015811909547,
      },
      {
        lat: 13.127190488520977,
        lng: 80.18096720771482,
      },
      {
        lat: 13.126988446362624,
        lng: 80.18171619104423,
      },
      {
        lat: 13.126919121088148,
        lng: 80.1824493987367,
      },
      {
        lat: 13.126967132658026,
        lng: 80.18306439402113,
      },
      {
        lat: 13.128855929110038,
        lng: 80.18304840864694,
      },
      {
        lat: 13.129179942183137,
        lng: 80.18294309950373,
      },
      {
        lat: 13.132854241531446,
        lng: 80.18291200295546,
      },
      {
        lat: 13.133399508822071,
        lng: 80.18279016886112,
      },
      {
        lat: 13.135367053365513,
        lng: 80.18276247876997,
      },
      {
        lat: 13.135410698147421,
        lng: 80.18282120806133,
      },
      {
        lat: 13.138083461866605,
        lng: 80.18547229134003,
      },
      {
        lat: 13.13919957130789,
        lng: 80.18772382964414,
      },
      {
        lat: 13.139164847218947,
        lng: 80.1879573996737,
      },
      {
        lat: 13.139076127077411,
        lng: 80.18822731532445,
      },
      {
        lat: 13.138914138919809,
        lng: 80.18833635218769,
      },
      {
        lat: 13.138445263645886,
        lng: 80.188501819274,
      },
      {
        lat: 13.137870469893082,
        lng: 80.18908090211929,
      },
      {
        lat: 13.137474426082056,
        lng: 80.18933547441047,
      },
      {
        lat: 13.13713150521802,
        lng: 80.18941015391574,
      },
      {
        lat: 13.13675156836193,
        lng: 80.18934159214277,
      },
      {
        lat: 13.13622575030289,
        lng: 80.18905893316938,
      },
      {
        lat: 13.135827087174212,
        lng: 80.18888286396601,
      },
      {
        lat: 13.135555797252827,
        lng: 80.18884927132444,
      },
      {
        lat: 13.135375191960708,
        lng: 80.18886874415297,
      },
      {
        lat: 13.13530497981512,
        lng: 80.18921028044298,
      },
      {
        lat: 13.135288872860523,
        lng: 80.18953341450694,
      },
      {
        lat: 13.135217569332584,
        lng: 80.18969551684066,
      },
      {
        lat: 13.135218878991404,
        lng: 80.1899108375878,
      },
      {
        lat: 13.13531087327983,
        lng: 80.19017922380529,
      },
      {
        lat: 13.135335056464076,
        lng: 80.19118390101582,
      },
      {
        lat: 13.1353095972869,
        lng: 80.19145507570455,
      },
      {
        lat: 13.134679606961086,
        lng: 80.19187312681899,
      },
      {
        lat: 13.133565398374058,
        lng: 80.19290538285362,
      },
      {
        lat: 13.13278974512165,
        lng: 80.19314522355867,
      },
      {
        lat: 13.13053605338117,
        lng: 80.19402562446122,
      },
      {
        lat: 13.130066741553964,
        lng: 80.1941193179653,
      },
      {
        lat: 13.130141755781743,
        lng: 80.19456729108218,
      },
      {
        lat: 13.130142628887626,
        lng: 80.19471083824688,
      },
      {
        lat: 13.129926317262784,
        lng: 80.19480239054488,
      },
      {
        lat: 13.1290768503033,
        lng: 80.19479163548453,
      },
      {
        lat: 13.12866022486986,
        lng: 80.19463366262032,
      },
      {
        lat: 13.128479728716483,
        lng: 80.19467107884465,
      },
      {
        lat: 13.128336685107653,
        lng: 80.19492350992947,
      },
      {
        lat: 13.128356393624015,
        lng: 80.19519250792018,
      },
      {
        lat: 13.128466786770545,
        lng: 80.19551457138132,
      },
      {
        lat: 13.128396902039661,
        lng: 80.19590993785762,
      },
      {
        lat: 13.128273130394426,
        lng: 80.19635959335113,
      },
      {
        lat: 13.12818462852936,
        lng: 80.19666539579285,
      },
      {
        lat: 13.12818604732642,
        lng: 80.19689865993561,
      },
      {
        lat: 13.12844013891063,
        lng: 80.19707595268538,
      },
      {
        lat: 13.128585910975342,
        lng: 80.19727210649066,
      },
      {
        lat: 13.128550968609932,
        lng: 80.19746978972883,
      },
      {
        lat: 13.128426760412095,
        lng: 80.19784767163966,
      },
      {
        lat: 13.128467596242105,
        lng: 80.19861893176396,
      },
      {
        lat: 13.12856035449818,
        lng: 80.19901292175071,
      },
      {
        lat: 13.1288870592695,
        lng: 80.19924343291407,
      },
      {
        lat: 13.128997125000984,
        lng: 80.19951166618824,
      },
      {
        lat: 13.129054286062344,
        lng: 80.1999956790396,
      },
      {
        lat: 13.12911210195335,
        lng: 80.20058735226449,
      },
      {
        lat: 13.129114502994529,
        lng: 80.20098210696783,
      },
      {
        lat: 13.12902545543829,
        lng: 80.20119819243168,
      },
      {
        lat: 13.128864643423713,
        lng: 80.20178336549829,
      },
      {
        lat: 13.12841020574919,
        lng: 80.20170525770631,
      },
      {
        lat: 13.125727588363437,
        lng: 80.20168698448518,
      },
      {
        lat: 13.123870315027007,
        lng: 80.2016617261629,
      },
      {
        lat: 13.121273721175996,
        lng: 80.20221640161367,
      },
      {
        lat: 13.120365967332651,
        lng: 80.20224457262381,
      },
      {
        lat: 13.119912526552378,
        lng: 80.20233036402661,
      },
      {
        lat: 13.119418815463764,
        lng: 80.20258040387944,
      },
      {
        lat: 13.118925353598906,
        lng: 80.20287141853105,
      },
      {
        lat: 13.118642854145952,
        lng: 80.20287380939715,
      },
      {
        lat: 13.11851268157413,
        lng: 80.2028749110814,
      },
      {
        lat: 13.117725614043511,
        lng: 80.20238984934251,
      },
      {
        lat: 13.114329740706792,
        lng: 80.20045169791656,
      },
      {
        lat: 13.112631305591128,
        lng: 80.19940067260632,
      },
      {
        lat: 13.112175123351863,
        lng: 80.1990357412231,
      },
      {
        lat: 13.112047583390549,
        lng: 80.1984221670073,
      },
      {
        lat: 13.112166400526675,
        lng: 80.19760162326767,
      },
      {
        lat: 13.111917800417045,
        lng: 80.19743981960302,
      },
      {
        lat: 13.11080308750249,
        lng: 80.19736729989162,
      },
      {
        lat: 13.109811179300827,
        lng: 80.19712983322016,
      },
      {
        lat: 13.107937118673307,
        lng: 80.19606399271875,
      },
      {
        lat: 13.108147174981243,
        lng: 80.18748623609814,
      },
      {
        lat: 13.108462923095106,
        lng: 80.18289380879476,
      },
      {
        lat: 13.10865428139879,
        lng: 80.1804522823226,
      },
      {
        lat: 13.108361752558196,
        lng: 80.18038650892436,
      },
      {
        lat: 13.10840053141784,
        lng: 80.17987212466383,
      },
      {
        lat: 13.113809633699043,
        lng: 80.17987539402556,
      },
    ],
    properties: {
      Ward_No: 83,
      Zone_Name: "AMBATTUR",
      fillColor: "#de4307",
      zname: "AMBATTUR(83)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.119715132354258,
        lng: 80.15477007040651,
      },
      {
        lat: 13.117131352900609,
        lng: 80.1484520418816,
      },
      {
        lat: 13.119871497541912,
        lng: 80.15212060592224,
      },
      {
        lat: 13.117909059257812,
        lng: 80.149198552481,
      },
      {
        lat: 13.119601901456535,
        lng: 80.14380711471412,
      },
      {
        lat: 13.119274148210124,
        lng: 80.15573126939115,
      },
      {
        lat: 13.116633406812769,
        lng: 80.15008296549271,
      },
      {
        lat: 13.121947974430288,
        lng: 80.15095908646241,
      },
      {
        lat: 13.114983974012896,
        lng: 80.14726719887227,
      },
      {
        lat: 13.11683479359635,
        lng: 80.14998476096955,
      },
      {
        lat: 13.115243944756228,
        lng: 80.14473910195838,
      },
      {
        lat: 13.11829345863594,
        lng: 80.15278844106275,
      },
      {
        lat: 13.121682993627271,
        lng: 80.15357790565336,
      },
      {
        lat: 13.121807918830028,
        lng: 80.1504877070059,
      },
      {
        lat: 13.11518632430877,
        lng: 80.1543096254731,
      },
      {
        lat: 13.122699043063292,
        lng: 80.15363019484212,
      },
      {
        lat: 13.119673983204716,
        lng: 80.14412768741282,
      },
      {
        lat: 13.117317259395264,
        lng: 80.15189059851957,
      },
      {
        lat: 13.11718206739799,
        lng: 80.14828698424553,
      },
      {
        lat: 13.120256453551491,
        lng: 80.14658932608546,
      },
      {
        lat: 13.117949146022044,
        lng: 80.15703968709708,
      },
      {
        lat: 13.118035226266684,
        lng: 80.14731448396219,
      },
      {
        lat: 13.116519031866236,
        lng: 80.15273530470911,
      },
      {
        lat: 13.122560888980692,
        lng: 80.15836271287105,
      },
      {
        lat: 13.116750093544724,
        lng: 80.15579494859422,
      },
      {
        lat: 13.11928550797145,
        lng: 80.14947513263043,
      },
      {
        lat: 13.116337480063132,
        lng: 80.14865702395878,
      },
      {
        lat: 13.115664429177754,
        lng: 80.15376511367684,
      },
      {
        lat: 13.119003369315548,
        lng: 80.1516000280158,
      },
      {
        lat: 13.122797192356757,
        lng: 80.14658019953255,
      },
      {
        lat: 13.123013728602356,
        lng: 80.14531374610524,
      },
      {
        lat: 13.116003974677263,
        lng: 80.14670655988549,
      },
      {
        lat: 13.122407867181808,
        lng: 80.15519344375775,
      },
      {
        lat: 13.116402286577031,
        lng: 80.1439919784706,
      },
      {
        lat: 13.11481120327244,
        lng: 80.14759255622663,
      },
      {
        lat: 13.11712957109829,
        lng: 80.14821831948512,
      },
      {
        lat: 13.119258082217616,
        lng: 80.15943091029665,
      },
      {
        lat: 13.11919374595348,
        lng: 80.14949289119193,
      },
      {
        lat: 13.117429789066666,
        lng: 80.15759453590468,
      },
      {
        lat: 13.119009579773527,
        lng: 80.15034079041295,
      },
      {
        lat: 13.11635611374618,
        lng: 80.15490775447148,
      },
      {
        lat: 13.116944209346132,
        lng: 80.15303672266218,
      },
      {
        lat: 13.11993289782643,
        lng: 80.14717253157555,
      },
      {
        lat: 13.120987287116623,
        lng: 80.14435053075128,
      },
      {
        lat: 13.11687143300108,
        lng: 80.15045942633043,
      },
      {
        lat: 13.115836544710923,
        lng: 80.14316646168147,
      },
      {
        lat: 13.115029043662542,
        lng: 80.14456609692104,
      },
      {
        lat: 13.116447451898594,
        lng: 80.14945240585276,
      },
      {
        lat: 13.121924655151084,
        lng: 80.15732097176578,
      },
      {
        lat: 13.120844024859617,
        lng: 80.14449587693849,
      },
      {
        lat: 13.1240882985787,
        lng: 80.14363334925356,
      },
      {
        lat: 13.118959394589227,
        lng: 80.15814697059875,
      },
      {
        lat: 13.12279434364118,
        lng: 80.15196753715219,
      },
      {
        lat: 13.114976141614335,
        lng: 80.1521793819435,
      },
      {
        lat: 13.118826227477411,
        lng: 80.15986246405667,
      },
      {
        lat: 13.118957861538053,
        lng: 80.1522162606373,
      },
      {
        lat: 13.116633688812815,
        lng: 80.14746256327908,
      },
      {
        lat: 13.117307669946355,
        lng: 80.14988964787558,
      },
      {
        lat: 13.11431104524546,
        lng: 80.15298496800695,
      },
      {
        lat: 13.122971463094675,
        lng: 80.14498952150228,
      },
      {
        lat: 13.11755591025197,
        lng: 80.15095999665148,
      },
      {
        lat: 13.115605310389567,
        lng: 80.15067494601347,
      },
      {
        lat: 13.120326251398064,
        lng: 80.14410315200354,
      },
      {
        lat: 13.119282780643566,
        lng: 80.15085258769871,
      },
      {
        lat: 13.122440433291095,
        lng: 80.15274186130696,
      },
      {
        lat: 13.121886203833194,
        lng: 80.14639781628084,
      },
      {
        lat: 13.115721768643468,
        lng: 80.15052747104563,
      },
      {
        lat: 13.11485524347406,
        lng: 80.14491366275892,
      },
      {
        lat: 13.115516159687377,
        lng: 80.15353111697728,
      },
      {
        lat: 13.121757504627205,
        lng: 80.15084739392233,
      },
      {
        lat: 13.117711153797208,
        lng: 80.14805312558993,
      },
      {
        lat: 13.1203420668472,
        lng: 80.1569023067588,
      },
      {
        lat: 13.119852069475169,
        lng: 80.15629062266686,
      },
      {
        lat: 13.115870392125352,
        lng: 80.14345172082362,
      },
      {
        lat: 13.117255860845692,
        lng: 80.14690926399643,
      },
      {
        lat: 13.121986912047289,
        lng: 80.14671158138918,
      },
      {
        lat: 13.118692881309007,
        lng: 80.15252178188325,
      },
      {
        lat: 13.115517392591167,
        lng: 80.14609654203934,
      },
      {
        lat: 13.120712999631673,
        lng: 80.15828216195007,
      },
      {
        lat: 13.11567572851116,
        lng: 80.15148386935662,
      },
      {
        lat: 13.119371955409552,
        lng: 80.15831885742416,
      },
      {
        lat: 13.119559778129176,
        lng: 80.15519128916458,
      },
      {
        lat: 13.12318652482529,
        lng: 80.14558873234611,
      },
      {
        lat: 13.116028900180362,
        lng: 80.15420385164819,
      },
      {
        lat: 13.116685339084574,
        lng: 80.15522032710182,
      },
      {
        lat: 13.119814570356658,
        lng: 80.15837301373996,
      },
    ],
    cases: 86,
    coords: [
      {
        lat: 13.123040987252136,
        lng: 80.15980211117274,
      },
      {
        lat: 13.121424471319397,
        lng: 80.16027909764986,
      },
      {
        lat: 13.12094991529037,
        lng: 80.16037297307248,
      },
      {
        lat: 13.120586839334544,
        lng: 80.16019632761429,
      },
      {
        lat: 13.120156848008472,
        lng: 80.16017750195957,
      },
      {
        lat: 13.119976403085909,
        lng: 80.16026888824362,
      },
      {
        lat: 13.119843665400438,
        lng: 80.16076423686349,
      },
      {
        lat: 13.119552286932185,
        lng: 80.16121599853518,
      },
      {
        lat: 13.119461654575174,
        lng: 80.1611943007975,
      },
      {
        lat: 13.119325501091724,
        lng: 80.16112805875066,
      },
      {
        lat: 13.11907677447973,
        lng: 80.16115262857156,
      },
      {
        lat: 13.118828184499483,
        lng: 80.16119966201904,
      },
      {
        lat: 13.118556424060314,
        lng: 80.1611570324321,
      },
      {
        lat: 13.118035663745315,
        lng: 80.16109404541263,
      },
      {
        lat: 13.118101076164388,
        lng: 80.16068912571602,
      },
      {
        lat: 13.118251655663997,
        lng: 80.1594073586908,
      },
      {
        lat: 13.11668869155917,
        lng: 80.1591060794991,
      },
      {
        lat: 13.116597922570183,
        lng: 80.15906191813474,
      },
      {
        lat: 13.116597102778668,
        lng: 80.15892713637471,
      },
      {
        lat: 13.116773858639418,
        lng: 80.15822923217067,
      },
      {
        lat: 13.117308771706735,
        lng: 80.15689928286476,
      },
      {
        lat: 13.117284781456304,
        lng: 80.15667483807029,
      },
      {
        lat: 13.116942963112699,
        lng: 80.15627336487331,
      },
      {
        lat: 13.116147299824163,
        lng: 80.15565108485372,
      },
      {
        lat: 13.11567124084403,
        lng: 80.15549786038316,
      },
      {
        lat: 13.115012414198537,
        lng: 80.15505414054358,
      },
      {
        lat: 13.114276300516037,
        lng: 80.15473637987856,
      },
      {
        lat: 13.114300319108171,
        lng: 80.15090839892405,
      },
      {
        lat: 13.114488504149602,
        lng: 80.14936179435583,
      },
      {
        lat: 13.114742727962918,
        lng: 80.14649033496165,
      },
      {
        lat: 13.114828840628583,
        lng: 80.14440506629008,
      },
      {
        lat: 13.115057012521914,
        lng: 80.14180432785689,
      },
      {
        lat: 13.115078984490726,
        lng: 80.14180930180237,
      },
      {
        lat: 13.115378406369697,
        lng: 80.14205329003322,
      },
      {
        lat: 13.115454536296152,
        lng: 80.14232382027357,
      },
      {
        lat: 13.115678727865207,
        lng: 80.14244518404021,
      },
      {
        lat: 13.11642443377709,
        lng: 80.14258678632754,
      },
      {
        lat: 13.117716930716321,
        lng: 80.14282236990663,
      },
      {
        lat: 13.120799661614043,
        lng: 80.1434865424407,
      },
      {
        lat: 13.12174533196295,
        lng: 80.14384832246138,
      },
      {
        lat: 13.122341326935198,
        lng: 80.14386793063294,
      },
      {
        lat: 13.123357879446976,
        lng: 80.14363745720101,
      },
      {
        lat: 13.12392844779855,
        lng: 80.14355867163826,
      },
      {
        lat: 13.124202143184666,
        lng: 80.14365496420965,
      },
      {
        lat: 13.12452804123694,
        lng: 80.14416990291357,
      },
      {
        lat: 13.122794526924688,
        lng: 80.14710952725937,
      },
      {
        lat: 13.122719639875063,
        lng: 80.14755311441806,
      },
      {
        lat: 13.122781222574023,
        lng: 80.14811109965078,
      },
      {
        lat: 13.123095529559103,
        lng: 80.14876324022332,
      },
      {
        lat: 13.125193313933154,
        lng: 80.1524431838282,
      },
      {
        lat: 13.124147039879842,
        lng: 80.15221442974999,
      },
      {
        lat: 13.123979408990529,
        lng: 80.15211633802276,
      },
      {
        lat: 13.12379568076233,
        lng: 80.15211789296242,
      },
      {
        lat: 13.12367906541258,
        lng: 80.15216863512381,
      },
      {
        lat: 13.12367926715503,
        lng: 80.15220180356002,
      },
      {
        lat: 13.123598881332128,
        lng: 80.15271662111303,
      },
      {
        lat: 13.123504012109771,
        lng: 80.15359643282325,
      },
      {
        lat: 13.123355000339975,
        lng: 80.15381329988237,
      },
      {
        lat: 13.12318878164782,
        lng: 80.15394738720903,
      },
      {
        lat: 13.122688108146598,
        lng: 80.15401796482587,
      },
      {
        lat: 13.122622205723026,
        lng: 80.1541677882218,
      },
      {
        lat: 13.122381149125134,
        lng: 80.15572882509879,
      },
      {
        lat: 13.122403500480054,
        lng: 80.15665739995634,
      },
      {
        lat: 13.122526168103272,
        lng: 80.15760171088326,
      },
      {
        lat: 13.12263213316026,
        lng: 80.15854616316811,
      },
      {
        lat: 13.12300685234495,
        lng: 80.15973711699468,
      },
      {
        lat: 13.123040987252136,
        lng: 80.15980211117274,
      },
    ],
    properties: {
      Ward_No: 81,
      Zone_Name: "AMBATTUR",
      fillColor: "#f6d04d",
      zname: "AMBATTUR(81)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.121902828067594,
        lng: 80.16700700595739,
      },
      {
        lat: 13.131346065738153,
        lng: 80.16925841239373,
      },
      {
        lat: 13.132355141400325,
        lng: 80.17900141530211,
      },
      {
        lat: 13.133124823451535,
        lng: 80.16718704882516,
      },
      {
        lat: 13.133926667529833,
        lng: 80.17205296758968,
      },
      {
        lat: 13.128970309262904,
        lng: 80.17241490776625,
      },
      {
        lat: 13.122704287016436,
        lng: 80.17364277569907,
      },
      {
        lat: 13.124103048026647,
        lng: 80.17419486220628,
      },
      {
        lat: 13.123195465995316,
        lng: 80.17308925186913,
      },
      {
        lat: 13.129334080594266,
        lng: 80.16712526103694,
      },
      {
        lat: 13.129401365170427,
        lng: 80.18157582333204,
      },
      {
        lat: 13.134055950947399,
        lng: 80.16568852118905,
      },
      {
        lat: 13.119037247265915,
        lng: 80.17006970958981,
      },
      {
        lat: 13.115847493166957,
        lng: 80.16514010676136,
      },
      {
        lat: 13.128454408791661,
        lng: 80.16600165727687,
      },
      {
        lat: 13.135299325442933,
        lng: 80.17411028128048,
      },
      {
        lat: 13.116957250717284,
        lng: 80.15926634087192,
      },
      {
        lat: 13.13911463222517,
        lng: 80.16903956847698,
      },
      {
        lat: 13.130726394032656,
        lng: 80.17841366004374,
      },
      {
        lat: 13.13480042090942,
        lng: 80.16920975811257,
      },
      {
        lat: 13.119551803285985,
        lng: 80.16124455509356,
      },
      {
        lat: 13.119894739305861,
        lng: 80.16791664784,
      },
      {
        lat: 13.115708306363318,
        lng: 80.15573103992546,
      },
      {
        lat: 13.133350044346424,
        lng: 80.17787604981234,
      },
      {
        lat: 13.138008824277104,
        lng: 80.1724768320359,
      },
      {
        lat: 13.118891435108447,
        lng: 80.16488700367472,
      },
      {
        lat: 13.138027955386054,
        lng: 80.17132374068696,
      },
      {
        lat: 13.135397012735785,
        lng: 80.17786541379594,
      },
      {
        lat: 13.117106977936613,
        lng: 80.16185302951767,
      },
      {
        lat: 13.119012767118809,
        lng: 80.17193307126081,
      },
      {
        lat: 13.132584657602356,
        lng: 80.17422135990033,
      },
      {
        lat: 13.131591921335403,
        lng: 80.1801358528396,
      },
      {
        lat: 13.129861835852301,
        lng: 80.18143581106264,
      },
      {
        lat: 13.133989078719656,
        lng: 80.17486087931043,
      },
      {
        lat: 13.120945340252606,
        lng: 80.16886489211494,
      },
      {
        lat: 13.139322977173803,
        lng: 80.1676456435061,
      },
      {
        lat: 13.1212976676397,
        lng: 80.17369292154271,
      },
      {
        lat: 13.11587175280516,
        lng: 80.173961683806,
      },
      {
        lat: 13.137147364018347,
        lng: 80.17307475668004,
      },
      {
        lat: 13.13760479847971,
        lng: 80.1747292408514,
      },
      {
        lat: 13.118326862241977,
        lng: 80.17300393072878,
      },
      {
        lat: 13.126869598918118,
        lng: 80.16936401460632,
      },
      {
        lat: 13.11685525751761,
        lng: 80.17354547866825,
      },
      {
        lat: 13.114282859781067,
        lng: 80.17880815561523,
      },
      {
        lat: 13.120961915495055,
        lng: 80.17502478557577,
      },
      {
        lat: 13.118917221251397,
        lng: 80.17715932425631,
      },
      {
        lat: 13.115735813175286,
        lng: 80.16395974557439,
      },
      {
        lat: 13.115748217651497,
        lng: 80.16878765401682,
      },
      {
        lat: 13.136976815361972,
        lng: 80.17008737566056,
      },
      {
        lat: 13.132474546877944,
        lng: 80.17247910256282,
      },
      {
        lat: 13.127367173636188,
        lng: 80.1641205830248,
      },
      {
        lat: 13.116250575730115,
        lng: 80.1683275834469,
      },
      {
        lat: 13.125176353093494,
        lng: 80.16992581739025,
      },
      {
        lat: 13.126232249739324,
        lng: 80.16418949435901,
      },
      {
        lat: 13.132596757864935,
        lng: 80.17189862220818,
      },
      {
        lat: 13.114599912500003,
        lng: 80.1612890204971,
      },
      {
        lat: 13.131060341813798,
        lng: 80.18246533589898,
      },
      {
        lat: 13.133937665530244,
        lng: 80.16641601002381,
      },
      {
        lat: 13.114936450752113,
        lng: 80.15655003554235,
      },
      {
        lat: 13.129812549062873,
        lng: 80.17370047152242,
      },
      {
        lat: 13.132766904316147,
        lng: 80.17842022651428,
      },
      {
        lat: 13.11749679307398,
        lng: 80.16705836177142,
      },
      {
        lat: 13.128579114998947,
        lng: 80.17698690642462,
      },
      {
        lat: 13.11610875332751,
        lng: 80.16174380356463,
      },
      {
        lat: 13.135523684963275,
        lng: 80.17944278331825,
      },
      {
        lat: 13.136113421859966,
        lng: 80.17637075840447,
      },
      {
        lat: 13.126588715683054,
        lng: 80.16623619968493,
      },
      {
        lat: 13.122979787066589,
        lng: 80.15999094659047,
      },
      {
        lat: 13.121051396977206,
        lng: 80.16191142008539,
      },
      {
        lat: 13.128601129905954,
        lng: 80.17054371563447,
      },
      {
        lat: 13.129938146961374,
        lng: 80.17253189031155,
      },
      {
        lat: 13.125974736924716,
        lng: 80.17069383239085,
      },
      {
        lat: 13.128880937224045,
        lng: 80.16952433949197,
      },
      {
        lat: 13.117397683089203,
        lng: 80.16303241463784,
      },
      {
        lat: 13.131352484570863,
        lng: 80.17702405682513,
      },
      {
        lat: 13.132839602745937,
        lng: 80.17184895390645,
      },
      {
        lat: 13.118859897697376,
        lng: 80.1754793621689,
      },
      {
        lat: 13.127926300106573,
        lng: 80.18065125507177,
      },
      {
        lat: 13.130349010767926,
        lng: 80.16559399993957,
      },
      {
        lat: 13.114575579810658,
        lng: 80.15784944086938,
      },
      {
        lat: 13.118434095244,
        lng: 80.17531758363181,
      },
      {
        lat: 13.127931397833418,
        lng: 80.16669280291968,
      },
      {
        lat: 13.131578282519717,
        lng: 80.1721932680146,
      },
      {
        lat: 13.133658658451028,
        lng: 80.18080431140869,
      },
      {
        lat: 13.138073805620124,
        lng: 80.16830227716966,
      },
      {
        lat: 13.118319104300825,
        lng: 80.16555842826831,
      },
      {
        lat: 13.119856283890956,
        lng: 80.17190201435707,
      },
      {
        lat: 13.126431019033902,
        lng: 80.16411353353173,
      },
      {
        lat: 13.11898654531666,
        lng: 80.16345537976127,
      },
      {
        lat: 13.12129296487591,
        lng: 80.16882649560293,
      },
      {
        lat: 13.127720696014796,
        lng: 80.16390978042071,
      },
    ],
    cases: 91,
    coords: [
      {
        lat: 13.135367053365513,
        lng: 80.18276247876997,
      },
      {
        lat: 13.133399508822071,
        lng: 80.18279016886112,
      },
      {
        lat: 13.132854241531446,
        lng: 80.18291200295546,
      },
      {
        lat: 13.129179942183137,
        lng: 80.18294309950373,
      },
      {
        lat: 13.128855929110038,
        lng: 80.18304840864694,
      },
      {
        lat: 13.126967132658026,
        lng: 80.18306439402113,
      },
      {
        lat: 13.126919121088148,
        lng: 80.1824493987367,
      },
      {
        lat: 13.126988446362624,
        lng: 80.18171619104423,
      },
      {
        lat: 13.127190488520977,
        lng: 80.18096720771482,
      },
      {
        lat: 13.12756924887967,
        lng: 80.18015811909547,
      },
      {
        lat: 13.12799619904171,
        lng: 80.17999332909106,
      },
      {
        lat: 13.128526620993298,
        lng: 80.17985696821708,
      },
      {
        lat: 13.12871622396437,
        lng: 80.17948905307045,
      },
      {
        lat: 13.128828837621523,
        lng: 80.17859430240281,
      },
      {
        lat: 13.128752115657802,
        lng: 80.1781114218774,
      },
      {
        lat: 13.128364087158298,
        lng: 80.17739673730797,
      },
      {
        lat: 13.128156787112543,
        lng: 80.17728127238597,
      },
      {
        lat: 13.128185319272973,
        lng: 80.17711985429654,
      },
      {
        lat: 13.128396727789005,
        lng: 80.17707180348062,
      },
      {
        lat: 13.128602359667509,
        lng: 80.17686463718371,
      },
      {
        lat: 13.128766115523568,
        lng: 80.17657565490214,
      },
      {
        lat: 13.128723614795932,
        lng: 80.17639113121378,
      },
      {
        lat: 13.127848716207831,
        lng: 80.17541249099888,
      },
      {
        lat: 13.127619022790912,
        lng: 80.17506521078624,
      },
      {
        lat: 13.127472331771912,
        lng: 80.17475831329882,
      },
      {
        lat: 13.127161759955642,
        lng: 80.17471985655251,
      },
      {
        lat: 13.126665869566637,
        lng: 80.17482676639187,
      },
      {
        lat: 13.126066788415036,
        lng: 80.17497563475773,
      },
      {
        lat: 13.125652900895245,
        lng: 80.17495859499697,
      },
      {
        lat: 13.125031007616018,
        lng: 80.17475843226025,
      },
      {
        lat: 13.12405841567386,
        lng: 80.17472557836174,
      },
      {
        lat: 13.122506806003408,
        lng: 80.17473871003682,
      },
      {
        lat: 13.122083361547986,
        lng: 80.17485116952703,
      },
      {
        lat: 13.121885155321701,
        lng: 80.17491858331161,
      },
      {
        lat: 13.121475090999255,
        lng: 80.17553011469583,
      },
      {
        lat: 13.12108087750997,
        lng: 80.17602647338131,
      },
      {
        lat: 13.120887968786128,
        lng: 80.17696484849104,
      },
      {
        lat: 13.120528555502075,
        lng: 80.17774029198726,
      },
      {
        lat: 13.119753780393008,
        lng: 80.17825630554283,
      },
      {
        lat: 13.11945697081796,
        lng: 80.17843959238247,
      },
      {
        lat: 13.119324766698425,
        lng: 80.1784735794171,
      },
      {
        lat: 13.119241114606922,
        lng: 80.17832638068012,
      },
      {
        lat: 13.118956557561296,
        lng: 80.17780289844892,
      },
      {
        lat: 13.118639299320783,
        lng: 80.17734542929041,
      },
      {
        lat: 13.118604299209968,
        lng: 80.17703347801432,
      },
      {
        lat: 13.11856909919317,
        lng: 80.17668866027317,
      },
      {
        lat: 13.118502097557654,
        lng: 80.17655775469747,
      },
      {
        lat: 13.118202989066281,
        lng: 80.17636307718848,
      },
      {
        lat: 13.117886330543197,
        lng: 80.17600420742532,
      },
      {
        lat: 13.117385517475032,
        lng: 80.17530178056155,
      },
      {
        lat: 13.117363969327926,
        lng: 80.17448025900525,
      },
      {
        lat: 13.116453491749054,
        lng: 80.17445509645616,
      },
      {
        lat: 13.115922176449276,
        lng: 80.17418021378118,
      },
      {
        lat: 13.115706220285247,
        lng: 80.17405056884644,
      },
      {
        lat: 13.115558165332788,
        lng: 80.17419972858018,
      },
      {
        lat: 13.115344707990785,
        lng: 80.17448091445921,
      },
      {
        lat: 13.115014397597896,
        lng: 80.17459874851092,
      },
      {
        lat: 13.114716688446958,
        lng: 80.17463413625775,
      },
      {
        lat: 13.114602034359296,
        lng: 80.17483231554672,
      },
      {
        lat: 13.114422619273777,
        lng: 80.1779891770509,
      },
      {
        lat: 13.114235507816774,
        lng: 80.17988067964853,
      },
      {
        lat: 13.113809633699043,
        lng: 80.17987539402556,
      },
      {
        lat: 13.11395036230066,
        lng: 80.17864800307473,
      },
      {
        lat: 13.114137801561236,
        lng: 80.17697878482528,
      },
      {
        lat: 13.11432509166565,
        lng: 80.17528504383976,
      },
      {
        lat: 13.114461643894515,
        lng: 80.17337101627476,
      },
      {
        lat: 13.114557153398922,
        lng: 80.17283067998471,
      },
      {
        lat: 13.114614839425313,
        lng: 80.17013255193085,
      },
      {
        lat: 13.114756910432453,
        lng: 80.16912586560596,
      },
      {
        lat: 13.114735282787853,
        lng: 80.16557006885468,
      },
      {
        lat: 13.114681710944973,
        lng: 80.16488385028721,
      },
      {
        lat: 13.114577848695232,
        lng: 80.16405091334926,
      },
      {
        lat: 13.114615311671619,
        lng: 80.16208867640528,
      },
      {
        lat: 13.11443124081387,
        lng: 80.16025093435245,
      },
      {
        lat: 13.11427843056251,
        lng: 80.15949198366967,
      },
      {
        lat: 13.114275236093704,
        lng: 80.15490602297388,
      },
      {
        lat: 13.114276300516037,
        lng: 80.15473637987856,
      },
      {
        lat: 13.115012414198537,
        lng: 80.15505414054358,
      },
      {
        lat: 13.11567124084403,
        lng: 80.15549786038316,
      },
      {
        lat: 13.116147299824163,
        lng: 80.15565108485372,
      },
      {
        lat: 13.116942963112699,
        lng: 80.15627336487331,
      },
      {
        lat: 13.117284781456304,
        lng: 80.15667483807029,
      },
      {
        lat: 13.117308771706735,
        lng: 80.15689928286476,
      },
      {
        lat: 13.116773858639418,
        lng: 80.15822923217067,
      },
      {
        lat: 13.116597102778668,
        lng: 80.15892713637471,
      },
      {
        lat: 13.116597922570183,
        lng: 80.15906191813474,
      },
      {
        lat: 13.11668869155917,
        lng: 80.1591060794991,
      },
      {
        lat: 13.118251655663997,
        lng: 80.1594073586908,
      },
      {
        lat: 13.118101076164388,
        lng: 80.16068912571602,
      },
      {
        lat: 13.118035663745315,
        lng: 80.16109404541263,
      },
      {
        lat: 13.118556424060314,
        lng: 80.1611570324321,
      },
      {
        lat: 13.118828184499483,
        lng: 80.16119966201904,
      },
      {
        lat: 13.11907677447973,
        lng: 80.16115262857156,
      },
      {
        lat: 13.119325501091724,
        lng: 80.16112805875066,
      },
      {
        lat: 13.119461654575174,
        lng: 80.1611943007975,
      },
      {
        lat: 13.119552286932185,
        lng: 80.16121599853518,
      },
      {
        lat: 13.119843665400438,
        lng: 80.16076423686349,
      },
      {
        lat: 13.119976403085909,
        lng: 80.16026888824362,
      },
      {
        lat: 13.120156848008472,
        lng: 80.16017750195957,
      },
      {
        lat: 13.120586839334544,
        lng: 80.16019632761429,
      },
      {
        lat: 13.12094991529037,
        lng: 80.16037297307248,
      },
      {
        lat: 13.121424471319397,
        lng: 80.16027909764986,
      },
      {
        lat: 13.123040987252136,
        lng: 80.15980211117274,
      },
      {
        lat: 13.123665918639702,
        lng: 80.16099200460526,
      },
      {
        lat: 13.12385105906512,
        lng: 80.16122262871957,
      },
      {
        lat: 13.123951476204702,
        lng: 80.16125494900687,
      },
      {
        lat: 13.124068999395481,
        lng: 80.16135346480878,
      },
      {
        lat: 13.124120821904853,
        lng: 80.16163497244267,
      },
      {
        lat: 13.123829052381069,
        lng: 80.16309692808538,
      },
      {
        lat: 13.124347437160777,
        lng: 80.16319205129149,
      },
      {
        lat: 13.125717753687653,
        lng: 80.16329654945021,
      },
      {
        lat: 13.126669900832123,
        lng: 80.1633050762539,
      },
      {
        lat: 13.126955458397063,
        lng: 80.1635680206555,
      },
      {
        lat: 13.127608270676067,
        lng: 80.16379468674161,
      },
      {
        lat: 13.128846177127931,
        lng: 80.16409932638328,
      },
      {
        lat: 13.128853338984937,
        lng: 80.16527680587092,
      },
      {
        lat: 13.128771540964703,
        lng: 80.16555944437005,
      },
      {
        lat: 13.128773861002886,
        lng: 80.1659408813871,
      },
      {
        lat: 13.128841579108917,
        lng: 80.16608957391784,
      },
      {
        lat: 13.12929234665382,
        lng: 80.16605258881143,
      },
      {
        lat: 13.129476579237977,
        lng: 80.16613395496253,
      },
      {
        lat: 13.129610704123767,
        lng: 80.16621574518794,
      },
      {
        lat: 13.129727218602293,
        lng: 80.16614841880829,
      },
      {
        lat: 13.130101794858339,
        lng: 80.16456966684797,
      },
      {
        lat: 13.130218107594406,
        lng: 80.16446917203214,
      },
      {
        lat: 13.130468847829896,
        lng: 80.16450022009607,
      },
      {
        lat: 13.131859195673442,
        lng: 80.16515185609529,
      },
      {
        lat: 13.132176544431136,
        lng: 80.16514917029048,
      },
      {
        lat: 13.132309660604669,
        lng: 80.16506511833455,
      },
      {
        lat: 13.13230784492261,
        lng: 80.16476660240801,
      },
      {
        lat: 13.132172005225987,
        lng: 80.16440288047427,
      },
      {
        lat: 13.132204502517258,
        lng: 80.16425333979463,
      },
      {
        lat: 13.132388129874291,
        lng: 80.16423520063697,
      },
      {
        lat: 13.133158667086464,
        lng: 80.16459355096104,
      },
      {
        lat: 13.13387527725108,
        lng: 80.1645874861034,
      },
      {
        lat: 13.13387594780052,
        lng: 80.16469773100415,
      },
      {
        lat: 13.134066685347362,
        lng: 80.16510732404875,
      },
      {
        lat: 13.134899473469682,
        lng: 80.16584792559406,
      },
      {
        lat: 13.136184484068822,
        lng: 80.16665946482794,
      },
      {
        lat: 13.138370756553028,
        lng: 80.16708955162521,
      },
      {
        lat: 13.140711256081788,
        lng: 80.16811645287666,
      },
      {
        lat: 13.142074517013922,
        lng: 80.16941330212839,
      },
      {
        lat: 13.14253037728456,
        lng: 80.17008232874709,
      },
      {
        lat: 13.142420391053781,
        lng: 80.17056923185962,
      },
      {
        lat: 13.142045964185462,
        lng: 80.17090884307261,
      },
      {
        lat: 13.14155905010289,
        lng: 80.17132417125862,
      },
      {
        lat: 13.139995142487596,
        lng: 80.17417847567343,
      },
      {
        lat: 13.139024270030129,
        lng: 80.1754950793012,
      },
      {
        lat: 13.138840808070581,
        lng: 80.17628166411703,
      },
      {
        lat: 13.13861787990135,
        lng: 80.17676952308634,
      },
      {
        lat: 13.138354576074144,
        lng: 80.17680913397736,
      },
      {
        lat: 13.137487778432783,
        lng: 80.17666693998133,
      },
      {
        lat: 13.137187282017214,
        lng: 80.17678163060724,
      },
      {
        lat: 13.136877449893497,
        lng: 80.17735372150322,
      },
      {
        lat: 13.135299104480698,
        lng: 80.18230176724865,
      },
      {
        lat: 13.135264926082707,
        lng: 80.18262505425612,
      },
      {
        lat: 13.135367053365513,
        lng: 80.18276247876997,
      },
    ],
    properties: {
      Ward_No: 82,
      Zone_Name: "AMBATTUR",
      fillColor: "#f6d04d",
      zname: "AMBATTUR(82)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.105000518343497,
        lng: 80.14819334456557,
      },
      {
        lat: 13.110690556387397,
        lng: 80.14546301722936,
      },
      {
        lat: 13.109311857598243,
        lng: 80.14928704504958,
      },
      {
        lat: 13.107541055161994,
        lng: 80.14736709701758,
      },
      {
        lat: 13.109433308885372,
        lng: 80.15506784960672,
      },
      {
        lat: 13.102921363192346,
        lng: 80.16322577438814,
      },
      {
        lat: 13.102912619839486,
        lng: 80.14968911146461,
      },
      {
        lat: 13.101157577067815,
        lng: 80.1458444615399,
      },
      {
        lat: 13.112789274009051,
        lng: 80.14841092122106,
      },
      {
        lat: 13.102974128817495,
        lng: 80.17070094555083,
      },
      {
        lat: 13.112399504581713,
        lng: 80.14723243882626,
      },
      {
        lat: 13.102574941411046,
        lng: 80.16619468404282,
      },
      {
        lat: 13.112766279436642,
        lng: 80.15933520429155,
      },
      {
        lat: 13.111361343080288,
        lng: 80.16213224641882,
      },
      {
        lat: 13.107410907267132,
        lng: 80.15416727643161,
      },
      {
        lat: 13.103087357755346,
        lng: 80.14842184721758,
      },
      {
        lat: 13.09997269647795,
        lng: 80.14419318354709,
      },
      {
        lat: 13.104143233837481,
        lng: 80.15250047100945,
      },
      {
        lat: 13.111950435460496,
        lng: 80.15391127224268,
      },
      {
        lat: 13.104924172837341,
        lng: 80.15351862861925,
      },
      {
        lat: 13.10630184634849,
        lng: 80.16101387100231,
      },
      {
        lat: 13.113518476978351,
        lng: 80.15262171892506,
      },
      {
        lat: 13.101438262938313,
        lng: 80.16435486843972,
      },
      {
        lat: 13.10668013851926,
        lng: 80.14446496750729,
      },
      {
        lat: 13.10574660520322,
        lng: 80.15386765175363,
      },
      {
        lat: 13.109161258361762,
        lng: 80.14720840162649,
      },
      {
        lat: 13.109225229649791,
        lng: 80.1425053156486,
      },
      {
        lat: 13.10279681028757,
        lng: 80.14386261525563,
      },
      {
        lat: 13.105727682004929,
        lng: 80.16310710710196,
      },
      {
        lat: 13.102947933584602,
        lng: 80.16104746579539,
      },
      {
        lat: 13.11237268511772,
        lng: 80.15867521159068,
      },
      {
        lat: 13.101895004250736,
        lng: 80.16935488019577,
      },
      {
        lat: 13.105710568281015,
        lng: 80.1599854515291,
      },
      {
        lat: 13.113889976670945,
        lng: 80.15378242578672,
      },
      {
        lat: 13.11341177882691,
        lng: 80.14935707160097,
      },
      {
        lat: 13.104715702235916,
        lng: 80.17273936456216,
      },
      {
        lat: 13.105274579207368,
        lng: 80.15564356160138,
      },
      {
        lat: 13.113691355329614,
        lng: 80.16350165006659,
      },
      {
        lat: 13.107266519901511,
        lng: 80.15184050376885,
      },
      {
        lat: 13.112802069796421,
        lng: 80.15327730408136,
      },
      {
        lat: 13.103059652960809,
        lng: 80.1527348242526,
      },
      {
        lat: 13.110255980030466,
        lng: 80.15367565648991,
      },
      {
        lat: 13.109832058220045,
        lng: 80.1571225736117,
      },
      {
        lat: 13.112397844581071,
        lng: 80.16367443026459,
      },
      {
        lat: 13.103208429709918,
        lng: 80.14947405183206,
      },
      {
        lat: 13.09967875485735,
        lng: 80.14352650885732,
      },
      {
        lat: 13.101407501623093,
        lng: 80.14693162174311,
      },
      {
        lat: 13.114065592074661,
        lng: 80.1421594344892,
      },
      {
        lat: 13.111373046555421,
        lng: 80.1443162159878,
      },
      {
        lat: 13.103259101734121,
        lng: 80.16504795054983,
      },
      {
        lat: 13.102699584799444,
        lng: 80.16041975783722,
      },
      {
        lat: 13.109426110914306,
        lng: 80.15589771535457,
      },
      {
        lat: 13.106256600192836,
        lng: 80.14720272089599,
      },
      {
        lat: 13.105714918269577,
        lng: 80.16254981495588,
      },
      {
        lat: 13.102338295489195,
        lng: 80.16955959846892,
      },
      {
        lat: 13.110964982481974,
        lng: 80.14472576235832,
      },
      {
        lat: 13.110563515961136,
        lng: 80.15302311638114,
      },
      {
        lat: 13.109475840922046,
        lng: 80.15126533831489,
      },
      {
        lat: 13.103593872758125,
        lng: 80.15564841120427,
      },
      {
        lat: 13.104873184820196,
        lng: 80.16151446411914,
      },
      {
        lat: 13.104971073267274,
        lng: 80.15575087682515,
      },
      {
        lat: 13.11064029342385,
        lng: 80.15990519133575,
      },
      {
        lat: 13.114384866971747,
        lng: 80.15020028672127,
      },
      {
        lat: 13.11011849057897,
        lng: 80.14737969710025,
      },
      {
        lat: 13.104150530089859,
        lng: 80.14976109186611,
      },
      {
        lat: 13.110571945087697,
        lng: 80.14378888883847,
      },
      {
        lat: 13.108632547198262,
        lng: 80.15188617959922,
      },
      {
        lat: 13.109292378143062,
        lng: 80.15229888206918,
      },
      {
        lat: 13.112906081967358,
        lng: 80.15665513293729,
      },
    ],
    cases: 69,
    coords: [
      {
        lat: 13.114577848695232,
        lng: 80.16405091334926,
      },
      {
        lat: 13.113548465541866,
        lng: 80.16395095947777,
      },
      {
        lat: 13.106562282439343,
        lng: 80.16315207049033,
      },
      {
        lat: 13.106358178068115,
        lng: 80.16324411523294,
      },
      {
        lat: 13.106223664878705,
        lng: 80.16356136439319,
      },
      {
        lat: 13.106068884306634,
        lng: 80.1642852131768,
      },
      {
        lat: 13.10551562251364,
        lng: 80.17426996323728,
      },
      {
        lat: 13.103354839849487,
        lng: 80.17419793314691,
      },
      {
        lat: 13.10330742484922,
        lng: 80.17282201676429,
      },
      {
        lat: 13.10346341554975,
        lng: 80.17268053068096,
      },
      {
        lat: 13.10353434086681,
        lng: 80.1723665481272,
      },
      {
        lat: 13.103604972952143,
        lng: 80.17200435539456,
      },
      {
        lat: 13.103576443983348,
        lng: 80.1713055132629,
      },
      {
        lat: 13.103429461793164,
        lng: 80.1710898002382,
      },
      {
        lat: 13.101554406466146,
        lng: 80.17016552243467,
      },
      {
        lat: 13.10038895867198,
        lng: 80.17015127959115,
      },
      {
        lat: 13.100048346082058,
        lng: 80.17003363063218,
      },
      {
        lat: 13.100047173154934,
        lng: 80.16984078991622,
      },
      {
        lat: 13.100237724620733,
        lng: 80.16923651897137,
      },
      {
        lat: 13.100427982854809,
        lng: 80.16858403784757,
      },
      {
        lat: 13.10042285129863,
        lng: 80.16774035971551,
      },
      {
        lat: 13.100467007037643,
        lng: 80.16701679610398,
      },
      {
        lat: 13.10080336776656,
        lng: 80.16643539746767,
      },
      {
        lat: 13.101230825675593,
        lng: 80.1648648683088,
      },
      {
        lat: 13.10173170137189,
        lng: 80.16339014311733,
      },
      {
        lat: 13.101689390509355,
        lng: 80.1604254225738,
      },
      {
        lat: 13.101766759116705,
        lng: 80.16018822321352,
      },
      {
        lat: 13.102027432849562,
        lng: 80.15961776135018,
      },
      {
        lat: 13.103079166967136,
        lng: 80.15882204464779,
      },
      {
        lat: 13.103516459011793,
        lng: 80.15833751197388,
      },
      {
        lat: 13.103689621164001,
        lng: 80.15785521469977,
      },
      {
        lat: 13.104098787640842,
        lng: 80.15636554456592,
      },
      {
        lat: 13.101190301454752,
        lng: 80.15588747209196,
      },
      {
        lat: 13.100815452794675,
        lng: 80.15578136458832,
      },
      {
        lat: 13.10076752077573,
        lng: 80.15151985239531,
      },
      {
        lat: 13.100875713888543,
        lng: 80.15121295288297,
      },
      {
        lat: 13.10124856861056,
        lng: 80.15099123742694,
      },
      {
        lat: 13.103095749776406,
        lng: 80.15069147639674,
      },
      {
        lat: 13.10327103879588,
        lng: 80.15055885694613,
      },
      {
        lat: 13.103380029484148,
        lng: 80.15038308661782,
      },
      {
        lat: 13.103203012384965,
        lng: 80.15023159283639,
      },
      {
        lat: 13.102805754112905,
        lng: 80.15006010702429,
      },
      {
        lat: 13.102034444756157,
        lng: 80.1499136428924,
      },
      {
        lat: 13.100688328311525,
        lng: 80.14935677971101,
      },
      {
        lat: 13.099848593756036,
        lng: 80.14881748687739,
      },
      {
        lat: 13.098832373818231,
        lng: 80.14821411971842,
      },
      {
        lat: 13.098211551143473,
        lng: 80.1474762702759,
      },
      {
        lat: 13.097811767216536,
        lng: 80.14688954204807,
      },
      {
        lat: 13.097630363453634,
        lng: 80.1460168377554,
      },
      {
        lat: 13.097424821999084,
        lng: 80.14479464192209,
      },
      {
        lat: 13.097611487506692,
        lng: 80.14291344706989,
      },
      {
        lat: 13.097965619835632,
        lng: 80.14069687283323,
      },
      {
        lat: 13.098038301232265,
        lng: 80.14040043093242,
      },
      {
        lat: 13.098310797129495,
        lng: 80.14029951580257,
      },
      {
        lat: 13.100719453958893,
        lng: 80.14035308740999,
      },
      {
        lat: 13.102307774311127,
        lng: 80.14024103611652,
      },
      {
        lat: 13.104095009547658,
        lng: 80.14017660581803,
      },
      {
        lat: 13.105783387086197,
        lng: 80.14018696887268,
      },
      {
        lat: 13.106305801045785,
        lng: 80.14035511316912,
      },
      {
        lat: 13.107375905648935,
        lng: 80.14076514453363,
      },
      {
        lat: 13.108719405766132,
        lng: 80.14122216654441,
      },
      {
        lat: 13.109688253694603,
        lng: 80.14131257585954,
      },
      {
        lat: 13.11413421375881,
        lng: 80.14159542755743,
      },
      {
        lat: 13.115057012521914,
        lng: 80.14180432785689,
      },
      {
        lat: 13.114828840628583,
        lng: 80.14440506629008,
      },
      {
        lat: 13.114742727962918,
        lng: 80.14649033496165,
      },
      {
        lat: 13.114488504149602,
        lng: 80.14936179435583,
      },
      {
        lat: 13.114300319108171,
        lng: 80.15090839892405,
      },
      {
        lat: 13.114275236093704,
        lng: 80.15490602297388,
      },
      {
        lat: 13.11427843056251,
        lng: 80.15949198366967,
      },
      {
        lat: 13.11443124081387,
        lng: 80.16025093435245,
      },
      {
        lat: 13.114615311671619,
        lng: 80.16208867640528,
      },
      {
        lat: 13.114577848695232,
        lng: 80.16405091334926,
      },
    ],
    properties: {
      Ward_No: 85,
      Zone_Name: "AMBATTUR",
      fillColor: "#f6d04d",
      zname: "AMBATTUR(85)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.100605698554316,
        lng: 80.18151120285813,
      },
      {
        lat: 13.101175319137594,
        lng: 80.17409020286236,
      },
      {
        lat: 13.106718428232544,
        lng: 80.18001180587527,
      },
      {
        lat: 13.111586205556176,
        lng: 80.1776533467663,
      },
      {
        lat: 13.110644208969683,
        lng: 80.17715341595347,
      },
      {
        lat: 13.100847373268518,
        lng: 80.17787028159897,
      },
      {
        lat: 13.101174257963795,
        lng: 80.1829074872103,
      },
      {
        lat: 13.11196776506824,
        lng: 80.17918940817574,
      },
      {
        lat: 13.112695298362603,
        lng: 80.16649094352556,
      },
      {
        lat: 13.109564355768132,
        lng: 80.17586121660516,
      },
      {
        lat: 13.103902033509609,
        lng: 80.18534738944454,
      },
      {
        lat: 13.113362255767305,
        lng: 80.17388353911556,
      },
      {
        lat: 13.11101694838217,
        lng: 80.1692196672934,
      },
      {
        lat: 13.106218338575719,
        lng: 80.17075556695154,
      },
      {
        lat: 13.100116003438785,
        lng: 80.17738586444943,
      },
    ],
    cases: 15,
    coords: [
      {
        lat: 13.10330742484922,
        lng: 80.17282201676429,
      },
      {
        lat: 13.103354839849487,
        lng: 80.17419793314691,
      },
      {
        lat: 13.10551562251364,
        lng: 80.17426996323728,
      },
      {
        lat: 13.106068884306634,
        lng: 80.1642852131768,
      },
      {
        lat: 13.106223664878705,
        lng: 80.16356136439319,
      },
      {
        lat: 13.106358178068115,
        lng: 80.16324411523294,
      },
      {
        lat: 13.106562282439343,
        lng: 80.16315207049033,
      },
      {
        lat: 13.113548465541866,
        lng: 80.16395095947777,
      },
      {
        lat: 13.114577848695232,
        lng: 80.16405091334926,
      },
      {
        lat: 13.114681710944973,
        lng: 80.16488385028721,
      },
      {
        lat: 13.114735282787853,
        lng: 80.16557006885468,
      },
      {
        lat: 13.114756910432453,
        lng: 80.16912586560596,
      },
      {
        lat: 13.114614839425313,
        lng: 80.17013255193085,
      },
      {
        lat: 13.114557153398922,
        lng: 80.17283067998471,
      },
      {
        lat: 13.114461643894515,
        lng: 80.17337101627476,
      },
      {
        lat: 13.11432509166565,
        lng: 80.17528504383976,
      },
      {
        lat: 13.114137801561236,
        lng: 80.17697878482528,
      },
      {
        lat: 13.11395036230066,
        lng: 80.17864800307473,
      },
      {
        lat: 13.113809633699043,
        lng: 80.17987539402556,
      },
      {
        lat: 13.10840053141784,
        lng: 80.17987212466383,
      },
      {
        lat: 13.108361752558196,
        lng: 80.18038650892436,
      },
      {
        lat: 13.10865428139879,
        lng: 80.1804522823226,
      },
      {
        lat: 13.108462923095106,
        lng: 80.18289380879476,
      },
      {
        lat: 13.108147174981243,
        lng: 80.18748623609814,
      },
      {
        lat: 13.107937118673307,
        lng: 80.19606399271875,
      },
      {
        lat: 13.107326424325962,
        lng: 80.19571667056695,
      },
      {
        lat: 13.105919850310627,
        lng: 80.19515489805605,
      },
      {
        lat: 13.102946617941921,
        lng: 80.19485224602893,
      },
      {
        lat: 13.102175693604265,
        lng: 80.19463589194982,
      },
      {
        lat: 13.101998364992161,
        lng: 80.19326165614316,
      },
      {
        lat: 13.10182050797893,
        lng: 80.19196141955494,
      },
      {
        lat: 13.101544516222926,
        lng: 80.19049326919418,
      },
      {
        lat: 13.101443742394006,
        lng: 80.18989146381085,
      },
      {
        lat: 13.100659983994895,
        lng: 80.18876509943568,
      },
      {
        lat: 13.100290842436353,
        lng: 80.18794860834508,
      },
      {
        lat: 13.099917742248758,
        lng: 80.18648127983816,
      },
      {
        lat: 13.099667640375344,
        lng: 80.18527807999838,
      },
      {
        lat: 13.099321456381567,
        lng: 80.18424443763882,
      },
      {
        lat: 13.09867734937826,
        lng: 80.18212853181387,
      },
      {
        lat: 13.098574376310976,
        lng: 80.18116515008819,
      },
      {
        lat: 13.098597187260019,
        lng: 80.18092389372987,
      },
      {
        lat: 13.098739624357812,
        lng: 80.18039234898023,
      },
      {
        lat: 13.099076278318506,
        lng: 80.17985916052311,
      },
      {
        lat: 13.099265070393676,
        lng: 80.17896562850444,
      },
      {
        lat: 13.099861001898976,
        lng: 80.1771526102064,
      },
      {
        lat: 13.100999173048633,
        lng: 80.17467810529182,
      },
      {
        lat: 13.101140583834963,
        lng: 80.17397782491601,
      },
      {
        lat: 13.10123241409454,
        lng: 80.17310921984057,
      },
      {
        lat: 13.101327030055876,
        lng: 80.1726986114654,
      },
      {
        lat: 13.101545084179287,
        lng: 80.17262444702585,
      },
      {
        lat: 13.102711851516647,
        lng: 80.17285563567461,
      },
      {
        lat: 13.103221964013901,
        lng: 80.17289953112103,
      },
      {
        lat: 13.10330742484922,
        lng: 80.17282201676429,
      },
    ],
    properties: {
      Ward_No: 84,
      Zone_Name: "AMBATTUR",
      fillColor: "#8bc24c",
      zname: "AMBATTUR(84)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.077237811561075,
        lng: 80.1945792352096,
      },
      {
        lat: 13.076518905873678,
        lng: 80.18252984330918,
      },
      {
        lat: 13.077406690564697,
        lng: 80.18428683987449,
      },
      {
        lat: 13.077134184609198,
        lng: 80.19061908950333,
      },
      {
        lat: 13.074864350899151,
        lng: 80.18402550811635,
      },
      {
        lat: 13.074341878325484,
        lng: 80.19151946502038,
      },
      {
        lat: 13.083339703210626,
        lng: 80.18801936903091,
      },
      {
        lat: 13.080975272900606,
        lng: 80.1852333783623,
      },
      {
        lat: 13.077591013686991,
        lng: 80.19163951329107,
      },
      {
        lat: 13.078231282300575,
        lng: 80.18846721134747,
      },
      {
        lat: 13.07416712702336,
        lng: 80.18245128656027,
      },
      {
        lat: 13.086035081326642,
        lng: 80.1886382474645,
      },
      {
        lat: 13.083431726904186,
        lng: 80.1872695574954,
      },
      {
        lat: 13.082762364085275,
        lng: 80.18513119753038,
      },
      {
        lat: 13.07885841580198,
        lng: 80.18613286945417,
      },
      {
        lat: 13.075883170718393,
        lng: 80.19251990430625,
      },
      {
        lat: 13.07666023100509,
        lng: 80.18363974771175,
      },
      {
        lat: 13.078780561159032,
        lng: 80.18912984364705,
      },
      {
        lat: 13.085847605237674,
        lng: 80.18674843297042,
      },
      {
        lat: 13.077802997844156,
        lng: 80.19229644325934,
      },
      {
        lat: 13.078184017230537,
        lng: 80.18855446363507,
      },
      {
        lat: 13.074205782425967,
        lng: 80.18361177362976,
      },
      {
        lat: 13.084402734027373,
        lng: 80.18475321225252,
      },
      {
        lat: 13.078067721876346,
        lng: 80.19135411648006,
      },
      {
        lat: 13.086280157868993,
        lng: 80.18863388833924,
      },
      {
        lat: 13.076063788835901,
        lng: 80.19205389625802,
      },
      {
        lat: 13.077066131211637,
        lng: 80.19086251698715,
      },
      {
        lat: 13.081133180154286,
        lng: 80.18677960101157,
      },
      {
        lat: 13.07691298842818,
        lng: 80.18647188982011,
      },
      {
        lat: 13.075632084503631,
        lng: 80.18242707242486,
      },
      {
        lat: 13.075462061048063,
        lng: 80.1837848527957,
      },
      {
        lat: 13.07338774666483,
        lng: 80.18453819588824,
      },
      {
        lat: 13.07541479131157,
        lng: 80.18207389592685,
      },
      {
        lat: 13.084211125689503,
        lng: 80.18389375655636,
      },
      {
        lat: 13.074967894159748,
        lng: 80.18364156729433,
      },
      {
        lat: 13.080248000651194,
        lng: 80.18590208423296,
      },
      {
        lat: 13.073577791004801,
        lng: 80.18199713540561,
      },
      {
        lat: 13.075871145612046,
        lng: 80.18774711976015,
      },
      {
        lat: 13.07833666670399,
        lng: 80.18845750027573,
      },
      {
        lat: 13.076215682993734,
        lng: 80.19164157053174,
      },
      {
        lat: 13.080096836547346,
        lng: 80.1880148672376,
      },
      {
        lat: 13.076392293680827,
        lng: 80.19342765017534,
      },
      {
        lat: 13.075636346936896,
        lng: 80.18635428133084,
      },
      {
        lat: 13.085376929236338,
        lng: 80.18624284703901,
      },
      {
        lat: 13.075070794436447,
        lng: 80.18470570039179,
      },
      {
        lat: 13.076863911438847,
        lng: 80.19449856204415,
      },
      {
        lat: 13.073917696361004,
        lng: 80.1861263528234,
      },
      {
        lat: 13.079254714174226,
        lng: 80.18745499063681,
      },
      {
        lat: 13.074107386933548,
        lng: 80.1820501510542,
      },
      {
        lat: 13.085668104844153,
        lng: 80.18823265688962,
      },
      {
        lat: 13.07662151002279,
        lng: 80.18421869341361,
      },
      {
        lat: 13.074912649249061,
        lng: 80.18304867695815,
      },
      {
        lat: 13.076718780338986,
        lng: 80.19502832588195,
      },
      {
        lat: 13.082422669436667,
        lng: 80.18496696341087,
      },
      {
        lat: 13.080910837986579,
        lng: 80.18707289292887,
      },
      {
        lat: 13.075155222364033,
        lng: 80.18871620026324,
      },
      {
        lat: 13.075638396015119,
        lng: 80.19659478994524,
      },
      {
        lat: 13.080292418489146,
        lng: 80.185951086829,
      },
      {
        lat: 13.073965332270708,
        lng: 80.18353385517622,
      },
      {
        lat: 13.074256243584726,
        lng: 80.19030968175316,
      },
      {
        lat: 13.080715954996348,
        lng: 80.18519071781365,
      },
      {
        lat: 13.083368311174919,
        lng: 80.1884835491109,
      },
      {
        lat: 13.081919573656974,
        lng: 80.1843355693705,
      },
      {
        lat: 13.082298463482854,
        lng: 80.18806405886998,
      },
      {
        lat: 13.077543865879738,
        lng: 80.18748561092121,
      },
      {
        lat: 13.084749115621907,
        lng: 80.18595709102742,
      },
      {
        lat: 13.073960473842405,
        lng: 80.18406288832573,
      },
      {
        lat: 13.07542523097087,
        lng: 80.19036223273035,
      },
      {
        lat: 13.082950794936218,
        lng: 80.18549709933203,
      },
      {
        lat: 13.074959899282932,
        lng: 80.18231620116997,
      },
      {
        lat: 13.079514099636228,
        lng: 80.1859940158423,
      },
      {
        lat: 13.07709964384349,
        lng: 80.1896920270459,
      },
      {
        lat: 13.076545743360388,
        lng: 80.19401583498077,
      },
      {
        lat: 13.07816725454,
        lng: 80.18796464082138,
      },
      {
        lat: 13.085460693784785,
        lng: 80.1878202777262,
      },
      {
        lat: 13.074324917443999,
        lng: 80.1886962581563,
      },
      {
        lat: 13.079238157652592,
        lng: 80.18719233320274,
      },
      {
        lat: 13.074821158928367,
        lng: 80.18858561651084,
      },
      {
        lat: 13.084298456190021,
        lng: 80.18409066985726,
      },
      {
        lat: 13.07356011472766,
        lng: 80.18434413775327,
      },
      {
        lat: 13.082205876188524,
        lng: 80.18473647159881,
      },
      {
        lat: 13.074560233156848,
        lng: 80.18215848099099,
      },
      {
        lat: 13.075424865016023,
        lng: 80.19023248316005,
      },
      {
        lat: 13.085144949110896,
        lng: 80.18692006321655,
      },
      {
        lat: 13.077494112592134,
        lng: 80.18768882300394,
      },
      {
        lat: 13.078205337250964,
        lng: 80.19040210753585,
      },
      {
        lat: 13.074085799879509,
        lng: 80.18848206997727,
      },
      {
        lat: 13.077199383248807,
        lng: 80.18658088870498,
      },
      {
        lat: 13.073985939617383,
        lng: 80.182568019733,
      },
      {
        lat: 13.074872033380421,
        lng: 80.18668564672979,
      },
      {
        lat: 13.075312314125812,
        lng: 80.1912055321715,
      },
      {
        lat: 13.079551472823404,
        lng: 80.18792710141683,
      },
      {
        lat: 13.083986788353856,
        lng: 80.18622044049654,
      },
      {
        lat: 13.086551582442778,
        lng: 80.18571566453716,
      },
      {
        lat: 13.073827769957122,
        lng: 80.19009711959038,
      },
      {
        lat: 13.07636249535594,
        lng: 80.18382752589397,
      },
      {
        lat: 13.076127216545306,
        lng: 80.18666681868689,
      },
      {
        lat: 13.076767792146931,
        lng: 80.18223821128764,
      },
      {
        lat: 13.077522547286309,
        lng: 80.19287158912707,
      },
      {
        lat: 13.08289724625461,
        lng: 80.1853152375744,
      },
      {
        lat: 13.07752653383534,
        lng: 80.18687588486117,
      },
      {
        lat: 13.086543981817222,
        lng: 80.18826232870677,
      },
      {
        lat: 13.084925443675042,
        lng: 80.18804599086981,
      },
      {
        lat: 13.073079769208,
        lng: 80.1856016380273,
      },
      {
        lat: 13.074155859527576,
        lng: 80.18186395198938,
      },
    ],
    cases: 105,
    coords: [
      {
        lat: 13.072689826373894,
        lng: 80.18160265754004,
      },
      {
        lat: 13.073154526647242,
        lng: 80.1814988252764,
      },
      {
        lat: 13.073927533312785,
        lng: 80.1814744301727,
      },
      {
        lat: 13.074467458956295,
        lng: 80.18155912538163,
      },
      {
        lat: 13.07502558118948,
        lng: 80.18167937248404,
      },
      {
        lat: 13.075727864565138,
        lng: 80.18185195835694,
      },
      {
        lat: 13.076446390044836,
        lng: 80.1817387596019,
      },
      {
        lat: 13.077092997827151,
        lng: 80.18162616950507,
      },
      {
        lat: 13.077245146925662,
        lng: 80.18159698845808,
      },
      {
        lat: 13.077636437217677,
        lng: 80.18160707193101,
      },
      {
        lat: 13.077692875106363,
        lng: 80.18423202685574,
      },
      {
        lat: 13.078009417319533,
        lng: 80.18526076781642,
      },
      {
        lat: 13.078106291118361,
        lng: 80.18566179987288,
      },
      {
        lat: 13.078188127038652,
        lng: 80.18580845297988,
      },
      {
        lat: 13.078296128183288,
        lng: 80.18582093400236,
      },
      {
        lat: 13.078362518877551,
        lng: 80.18564623628673,
      },
      {
        lat: 13.078536910716391,
        lng: 80.18548401959356,
      },
      {
        lat: 13.07927804378894,
        lng: 80.18534379655067,
      },
      {
        lat: 13.08034301735651,
        lng: 80.18521422782585,
      },
      {
        lat: 13.08107106781683,
        lng: 80.18514109082521,
      },
      {
        lat: 13.081232051165566,
        lng: 80.18499238267574,
      },
      {
        lat: 13.081257646104046,
        lng: 80.18476444996863,
      },
      {
        lat: 13.081067401691977,
        lng: 80.18453834396587,
      },
      {
        lat: 13.081106160711895,
        lng: 80.18425671959105,
      },
      {
        lat: 13.08117304022281,
        lng: 80.1841623881234,
      },
      {
        lat: 13.081563841698008,
        lng: 80.18409210534858,
      },
      {
        lat: 13.083491846789288,
        lng: 80.18390165230754,
      },
      {
        lat: 13.084515943131457,
        lng: 80.18370545421655,
      },
      {
        lat: 13.084920804852295,
        lng: 80.18372881789494,
      },
      {
        lat: 13.085005247794648,
        lng: 80.18430409099086,
      },
      {
        lat: 13.085076689594276,
        lng: 80.1849598445037,
      },
      {
        lat: 13.085159014330982,
        lng: 80.18518686385866,
      },
      {
        lat: 13.085267504292267,
        lng: 80.18527971112903,
      },
      {
        lat: 13.08546993515271,
        lng: 80.18529139296832,
      },
      {
        lat: 13.085887390669127,
        lng: 80.1851673043567,
      },
      {
        lat: 13.087241671512865,
        lng: 80.18519921344337,
      },
      {
        lat: 13.087229324707435,
        lng: 80.18786508537116,
      },
      {
        lat: 13.087104172497098,
        lng: 80.18828889431269,
      },
      {
        lat: 13.087097813113958,
        lng: 80.18895613329843,
      },
      {
        lat: 13.086614923176219,
        lng: 80.18909074058719,
      },
      {
        lat: 13.08618177661591,
        lng: 80.18915055059139,
      },
      {
        lat: 13.085955950418473,
        lng: 80.18920860598973,
      },
      {
        lat: 13.085861144770403,
        lng: 80.18911583473354,
      },
      {
        lat: 13.085671874945945,
        lng: 80.18898643350374,
      },
      {
        lat: 13.085502021546482,
        lng: 80.18895044156885,
      },
      {
        lat: 13.085332623442525,
        lng: 80.1889893046772,
      },
      {
        lat: 13.085220564022935,
        lng: 80.18915868558284,
      },
      {
        lat: 13.084994851649547,
        lng: 80.18923545474209,
      },
      {
        lat: 13.08469272563814,
        lng: 80.1891444380919,
      },
      {
        lat: 13.082804580347592,
        lng: 80.18859897622949,
      },
      {
        lat: 13.081937490459659,
        lng: 80.18858759991181,
      },
      {
        lat: 13.081428271732898,
        lng: 80.18853576538926,
      },
      {
        lat: 13.08131461877898,
        lng: 80.18844315364277,
      },
      {
        lat: 13.081125918073795,
        lng: 80.18840732121751,
      },
      {
        lat: 13.080938241783603,
        lng: 80.18853991263983,
      },
      {
        lat: 13.080788146280936,
        lng: 80.18865347128221,
      },
      {
        lat: 13.079940245170242,
        lng: 80.18869807673765,
      },
      {
        lat: 13.07993961402733,
        lng: 80.18884614911896,
      },
      {
        lat: 13.080031018323224,
        lng: 80.18893559201102,
      },
      {
        lat: 13.08005530973731,
        lng: 80.18919475877829,
      },
      {
        lat: 13.07998778532997,
        lng: 80.18929682378379,
      },
      {
        lat: 13.079750112445089,
        lng: 80.18943415997654,
      },
      {
        lat: 13.07954685334657,
        lng: 80.18962759021012,
      },
      {
        lat: 13.078228749952142,
        lng: 80.19325868152897,
      },
      {
        lat: 13.076498187477478,
        lng: 80.19789395662379,
      },
      {
        lat: 13.07626368512759,
        lng: 80.19786450035078,
      },
      {
        lat: 13.075891283411044,
        lng: 80.19770374445123,
      },
      {
        lat: 13.07572347314173,
        lng: 80.19725441868533,
      },
      {
        lat: 13.07530232751484,
        lng: 80.19586476807939,
      },
      {
        lat: 13.074968202317505,
        lng: 80.19521196533985,
      },
      {
        lat: 13.075006977284273,
        lng: 80.1948018680975,
      },
      {
        lat: 13.075501934490765,
        lng: 80.19475670223852,
      },
      {
        lat: 13.075789309566703,
        lng: 80.19450840866078,
      },
      {
        lat: 13.075872093195636,
        lng: 80.19454868494941,
      },
      {
        lat: 13.075909372820714,
        lng: 80.19389273891491,
      },
      {
        lat: 13.075821604720478,
        lng: 80.19303296665163,
      },
      {
        lat: 13.075529992844016,
        lng: 80.192584688651,
      },
      {
        lat: 13.074910486359306,
        lng: 80.19250797787929,
      },
      {
        lat: 13.074496069769035,
        lng: 80.1922246468383,
      },
      {
        lat: 13.074242485188387,
        lng: 80.19124334719935,
      },
      {
        lat: 13.073826324033023,
        lng: 80.19067319256749,
      },
      {
        lat: 13.073644178029852,
        lng: 80.18972090202405,
      },
      {
        lat: 13.073652113071255,
        lng: 80.18925515755738,
      },
      {
        lat: 13.073789231738585,
        lng: 80.18883799778224,
      },
      {
        lat: 13.073740152011549,
        lng: 80.18842241385279,
      },
      {
        lat: 13.07308016443436,
        lng: 80.18706444622396,
      },
      {
        lat: 13.07291246170841,
        lng: 80.18628008907241,
      },
      {
        lat: 13.07302222925845,
        lng: 80.18519293967593,
      },
      {
        lat: 13.072746246565629,
        lng: 80.18191350310121,
      },
      {
        lat: 13.072689826373894,
        lng: 80.18160265754004,
      },
    ],
    properties: {
      Ward_No: 93,
      Zone_Name: "AMBATTUR",
      fillColor: "#f6d04d",
      zname: "AMBATTUR(93)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.079512653281474,
        lng: 80.19133482751086,
      },
      {
        lat: 13.080523393136831,
        lng: 80.19434760954745,
      },
      {
        lat: 13.08449071217119,
        lng: 80.18948883526697,
      },
      {
        lat: 13.094268825608918,
        lng: 80.19441678090479,
      },
      {
        lat: 13.081316385471952,
        lng: 80.19086860853717,
      },
      {
        lat: 13.077461774981783,
        lng: 80.19780602585698,
      },
      {
        lat: 13.092468032790174,
        lng: 80.19557691751018,
      },
      {
        lat: 13.08783899289163,
        lng: 80.19802559949576,
      },
      {
        lat: 13.08150507479346,
        lng: 80.1940891887748,
      },
      {
        lat: 13.09197803107408,
        lng: 80.19532477991244,
      },
      {
        lat: 13.09007000722287,
        lng: 80.19759237452831,
      },
      {
        lat: 13.082011841225091,
        lng: 80.19634640941187,
      },
      {
        lat: 13.08617181805743,
        lng: 80.19098754212109,
      },
      {
        lat: 13.088458361349847,
        lng: 80.19836031280028,
      },
      {
        lat: 13.082267887954195,
        lng: 80.19785837613621,
      },
      {
        lat: 13.082203415680024,
        lng: 80.19122054795012,
      },
      {
        lat: 13.080618933106965,
        lng: 80.19827295081278,
      },
      {
        lat: 13.090050119616128,
        lng: 80.19459750354102,
      },
      {
        lat: 13.07808030202851,
        lng: 80.19684254865851,
      },
      {
        lat: 13.08198729622028,
        lng: 80.19102017836344,
      },
      {
        lat: 13.088718056653473,
        lng: 80.19278431187442,
      },
      {
        lat: 13.077302756622466,
        lng: 80.19775150839234,
      },
      {
        lat: 13.0879533499592,
        lng: 80.19428869653308,
      },
      {
        lat: 13.095644685696325,
        lng: 80.19418714073169,
      },
      {
        lat: 13.084723231125057,
        lng: 80.19508788435789,
      },
      {
        lat: 13.083897991349579,
        lng: 80.18966645537063,
      },
      {
        lat: 13.086585522164087,
        lng: 80.19524122012427,
      },
      {
        lat: 13.091666624754284,
        lng: 80.19482681984483,
      },
      {
        lat: 13.0901992590129,
        lng: 80.19593918042061,
      },
      {
        lat: 13.08640617058502,
        lng: 80.19409009482304,
      },
      {
        lat: 13.080113043702061,
        lng: 80.19649699473787,
      },
      {
        lat: 13.08625103895101,
        lng: 80.19061952189287,
      },
      {
        lat: 13.08181744363555,
        lng: 80.19824734349454,
      },
      {
        lat: 13.085623019374374,
        lng: 80.19296569548634,
      },
      {
        lat: 13.078690898959234,
        lng: 80.19534868329602,
      },
      {
        lat: 13.085620448357389,
        lng: 80.19255762188062,
      },
      {
        lat: 13.093965175389034,
        lng: 80.1929122791135,
      },
      {
        lat: 13.086244996661094,
        lng: 80.19223155021572,
      },
      {
        lat: 13.087969413797326,
        lng: 80.19250638781709,
      },
      {
        lat: 13.077681341472267,
        lng: 80.19791234943902,
      },
      {
        lat: 13.087805303582714,
        lng: 80.1925141394252,
      },
      {
        lat: 13.081396555785863,
        lng: 80.19523643033402,
      },
      {
        lat: 13.079751168605275,
        lng: 80.19481650695424,
      },
      {
        lat: 13.090598027773492,
        lng: 80.19530207946207,
      },
      {
        lat: 13.087805342768322,
        lng: 80.19240003063561,
      },
      {
        lat: 13.080294491049626,
        lng: 80.1924422095106,
      },
      {
        lat: 13.093511957351335,
        lng: 80.193931969255,
      },
      {
        lat: 13.080971567870682,
        lng: 80.18856804445969,
      },
      {
        lat: 13.084469563364607,
        lng: 80.18961951141192,
      },
      {
        lat: 13.0885448889659,
        lng: 80.19292389689973,
      },
      {
        lat: 13.093362982320926,
        lng: 80.1954873250956,
      },
      {
        lat: 13.089915781576137,
        lng: 80.19288887164777,
      },
      {
        lat: 13.083073778648235,
        lng: 80.19332137327117,
      },
      {
        lat: 13.079349706945752,
        lng: 80.19277099331089,
      },
      {
        lat: 13.080188382918939,
        lng: 80.19681167035613,
      },
      {
        lat: 13.082961971637294,
        lng: 80.18931636348148,
      },
      {
        lat: 13.08078191375536,
        lng: 80.19814858864103,
      },
      {
        lat: 13.093610585466426,
        lng: 80.19291749294072,
      },
      {
        lat: 13.082498546576316,
        lng: 80.19302879573127,
      },
      {
        lat: 13.081097091189024,
        lng: 80.19625492268403,
      },
      {
        lat: 13.085041950439319,
        lng: 80.19147485730717,
      },
      {
        lat: 13.094653891345402,
        lng: 80.19322727084796,
      },
      {
        lat: 13.078938537654656,
        lng: 80.19229407985382,
      },
      {
        lat: 13.078497833815003,
        lng: 80.19574012728948,
      },
      {
        lat: 13.079452756092168,
        lng: 80.19552703722384,
      },
      {
        lat: 13.080576679927272,
        lng: 80.19071403512605,
      },
      {
        lat: 13.091064649483902,
        lng: 80.19453057307076,
      },
      {
        lat: 13.08234484349689,
        lng: 80.19546236955684,
      },
      {
        lat: 13.086185183394656,
        lng: 80.19605129260157,
      },
      {
        lat: 13.077125124405232,
        lng: 80.19738932553678,
      },
      {
        lat: 13.085947773014304,
        lng: 80.19486767034375,
      },
      {
        lat: 13.085499684651875,
        lng: 80.1948966611569,
      },
      {
        lat: 13.085805515518178,
        lng: 80.18964864627107,
      },
      {
        lat: 13.079652898721948,
        lng: 80.19668366585836,
      },
      {
        lat: 13.08836370829822,
        lng: 80.1978352932084,
      },
      {
        lat: 13.084463470265737,
        lng: 80.19556081540422,
      },
      {
        lat: 13.083619424664214,
        lng: 80.19079144515189,
      },
      {
        lat: 13.090778122557087,
        lng: 80.19765547275645,
      },
      {
        lat: 13.093338163944907,
        lng: 80.19477445374633,
      },
      {
        lat: 13.08053528048225,
        lng: 80.19230854664357,
      },
      {
        lat: 13.084006264710387,
        lng: 80.18918584236245,
      },
      {
        lat: 13.088884064743741,
        lng: 80.19810718039095,
      },
      {
        lat: 13.092348779320531,
        lng: 80.19276350429409,
      },
      {
        lat: 13.089442948154483,
        lng: 80.19544479542346,
      },
      {
        lat: 13.089164255305434,
        lng: 80.19360431129142,
      },
      {
        lat: 13.080275346711572,
        lng: 80.19610743944573,
      },
      {
        lat: 13.083042679756753,
        lng: 80.19630899023379,
      },
      {
        lat: 13.085827038032194,
        lng: 80.18945628793591,
      },
      {
        lat: 13.078457700383765,
        lng: 80.19655009982104,
      },
      {
        lat: 13.09505076028748,
        lng: 80.19394215606661,
      },
      {
        lat: 13.07999859234372,
        lng: 80.19666191788971,
      },
      {
        lat: 13.093520990307253,
        lng: 80.19533907162612,
      },
      {
        lat: 13.084115791870078,
        lng: 80.19596220222526,
      },
      {
        lat: 13.079794019906531,
        lng: 80.19133448121634,
      },
      {
        lat: 13.079713786469386,
        lng: 80.18995759838606,
      },
      {
        lat: 13.090187373051513,
        lng: 80.1935805566186,
      },
      {
        lat: 13.082357761304392,
        lng: 80.19625380626856,
      },
      {
        lat: 13.090022867717693,
        lng: 80.19710151442175,
      },
      {
        lat: 13.08633024119852,
        lng: 80.19709598667173,
      },
      {
        lat: 13.08135414037146,
        lng: 80.19320954838035,
      },
      {
        lat: 13.082969516135885,
        lng: 80.19509251509444,
      },
      {
        lat: 13.08592893035697,
        lng: 80.19187501437351,
      },
      {
        lat: 13.09143782049332,
        lng: 80.19475170486453,
      },
      {
        lat: 13.08851886527549,
        lng: 80.1968829583406,
      },
      {
        lat: 13.088602046015657,
        lng: 80.19553027931674,
      },
      {
        lat: 13.08484312580244,
        lng: 80.19267662017224,
      },
      {
        lat: 13.082252169005503,
        lng: 80.19703211664394,
      },
      {
        lat: 13.079825225043507,
        lng: 80.19629536509109,
      },
      {
        lat: 13.092919919468795,
        lng: 80.19482649878195,
      },
      {
        lat: 13.083415015907995,
        lng: 80.18946802319427,
      },
      {
        lat: 13.081201257051536,
        lng: 80.19066057825015,
      },
      {
        lat: 13.078939749606876,
        lng: 80.1974773582108,
      },
      {
        lat: 13.086904482959127,
        lng: 80.19113153486985,
      },
      {
        lat: 13.090812299338006,
        lng: 80.19272253955052,
      },
      {
        lat: 13.088817711366145,
        lng: 80.19382837413639,
      },
      {
        lat: 13.08005510849392,
        lng: 80.19606066575429,
      },
      {
        lat: 13.085571383468794,
        lng: 80.19067239604648,
      },
      {
        lat: 13.083827555699887,
        lng: 80.19681295846536,
      },
      {
        lat: 13.086192555085725,
        lng: 80.19551091800491,
      },
      {
        lat: 13.07685201599193,
        lng: 80.1977306671658,
      },
      {
        lat: 13.08144173365028,
        lng: 80.18926816866305,
      },
      {
        lat: 13.095280437650702,
        lng: 80.19466772693022,
      },
    ],
    cases: 122,
    coords: [
      {
        lat: 13.076498187477478,
        lng: 80.19789395662379,
      },
      {
        lat: 13.078228749952142,
        lng: 80.19325868152897,
      },
      {
        lat: 13.07954685334657,
        lng: 80.18962759021012,
      },
      {
        lat: 13.079750112445089,
        lng: 80.18943415997654,
      },
      {
        lat: 13.07998778532997,
        lng: 80.18929682378379,
      },
      {
        lat: 13.08005530973731,
        lng: 80.18919475877829,
      },
      {
        lat: 13.080031018323224,
        lng: 80.18893559201102,
      },
      {
        lat: 13.07993961402733,
        lng: 80.18884614911896,
      },
      {
        lat: 13.079940245170242,
        lng: 80.18869807673765,
      },
      {
        lat: 13.080788146280936,
        lng: 80.18865347128221,
      },
      {
        lat: 13.080938241783603,
        lng: 80.18853991263983,
      },
      {
        lat: 13.081125918073795,
        lng: 80.18840732121751,
      },
      {
        lat: 13.08131461877898,
        lng: 80.18844315364277,
      },
      {
        lat: 13.081428271732898,
        lng: 80.18853576538926,
      },
      {
        lat: 13.081937490459659,
        lng: 80.18858759991181,
      },
      {
        lat: 13.082804580347592,
        lng: 80.18859897622949,
      },
      {
        lat: 13.08469272563814,
        lng: 80.1891444380919,
      },
      {
        lat: 13.084994851649547,
        lng: 80.18923545474209,
      },
      {
        lat: 13.085220564022935,
        lng: 80.18915868558284,
      },
      {
        lat: 13.085332623442525,
        lng: 80.1889893046772,
      },
      {
        lat: 13.085502021546482,
        lng: 80.18895044156885,
      },
      {
        lat: 13.085671874945945,
        lng: 80.18898643350374,
      },
      {
        lat: 13.085861144770403,
        lng: 80.18911583473354,
      },
      {
        lat: 13.085955950418473,
        lng: 80.18920860598973,
      },
      {
        lat: 13.08618177661591,
        lng: 80.18915055059139,
      },
      {
        lat: 13.086614923176219,
        lng: 80.18909074058719,
      },
      {
        lat: 13.087097813113958,
        lng: 80.18895613329843,
      },
      {
        lat: 13.087078182174043,
        lng: 80.19101585013695,
      },
      {
        lat: 13.086973674399983,
        lng: 80.19133379692265,
      },
      {
        lat: 13.086975859907088,
        lng: 80.19169311571022,
      },
      {
        lat: 13.087147571919154,
        lng: 80.19192417482726,
      },
      {
        lat: 13.087659236855394,
        lng: 80.19204666939793,
      },
      {
        lat: 13.089534698826006,
        lng: 80.19239013416099,
      },
      {
        lat: 13.092344999198774,
        lng: 80.19242976232204,
      },
      {
        lat: 13.092792673878199,
        lng: 80.19253166097158,
      },
      {
        lat: 13.093729569228442,
        lng: 80.19256600675791,
      },
      {
        lat: 13.095287604765401,
        lng: 80.19257835706894,
      },
      {
        lat: 13.095276061963215,
        lng: 80.19293196495158,
      },
      {
        lat: 13.095181452306635,
        lng: 80.19313671384586,
      },
      {
        lat: 13.09518227925527,
        lng: 80.19327267230604,
      },
      {
        lat: 13.095333396977908,
        lng: 80.19335297263287,
      },
      {
        lat: 13.096744175907691,
        lng: 80.19340901557433,
      },
      {
        lat: 13.096747592383052,
        lng: 80.19543740979329,
      },
      {
        lat: 13.093891706899702,
        lng: 80.19546157989271,
      },
      {
        lat: 13.093190912127858,
        lng: 80.19559044162472,
      },
      {
        lat: 13.093027089435777,
        lng: 80.19579671263843,
      },
      {
        lat: 13.092698447157304,
        lng: 80.19604535547118,
      },
      {
        lat: 13.0916254998922,
        lng: 80.19605443610214,
      },
      {
        lat: 13.091378644348296,
        lng: 80.19617945602833,
      },
      {
        lat: 13.09125733497623,
        lng: 80.1965902517808,
      },
      {
        lat: 13.091020697598683,
        lng: 80.19839523845485,
      },
      {
        lat: 13.090816355374853,
        lng: 80.19872478311974,
      },
      {
        lat: 13.087473213524966,
        lng: 80.19867112318029,
      },
      {
        lat: 13.08718234931924,
        lng: 80.19834576957578,
      },
      {
        lat: 13.087466484488397,
        lng: 80.19756480361477,
      },
      {
        lat: 13.08746299535832,
        lng: 80.19699115643255,
      },
      {
        lat: 13.087295683536166,
        lng: 80.19662378026429,
      },
      {
        lat: 13.086924278713697,
        lng: 80.19662692355963,
      },
      {
        lat: 13.086761203692342,
        lng: 80.19695611896948,
      },
      {
        lat: 13.086597630223835,
        lng: 80.19720336478228,
      },
      {
        lat: 13.085896586228815,
        lng: 80.19729125171516,
      },
      {
        lat: 13.083791211230475,
        lng: 80.1971861393257,
      },
      {
        lat: 13.083316887625394,
        lng: 80.19723113055773,
      },
      {
        lat: 13.083029014101905,
        lng: 80.19739747453781,
      },
      {
        lat: 13.082993229818277,
        lng: 80.19829926936458,
      },
      {
        lat: 13.08282990557335,
        lng: 80.19858748997574,
      },
      {
        lat: 13.082500266400569,
        lng: 80.19867223361344,
      },
      {
        lat: 13.081798723957712,
        lng: 80.19867817094907,
      },
      {
        lat: 13.079319950348456,
        lng: 80.19824840346543,
      },
      {
        lat: 13.07924958913423,
        lng: 80.19823956526339,
      },
      {
        lat: 13.076498187477478,
        lng: 80.19789395662379,
      },
    ],
    properties: {
      Ward_No: 90,
      Zone_Name: "AMBATTUR",
      fillColor: "#de4307",
      zname: "AMBATTUR(90)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.079294063510542,
        lng: 80.17467045261694,
      },
      {
        lat: 13.074581470379437,
        lng: 80.17751151197716,
      },
      {
        lat: 13.081731422196189,
        lng: 80.17457560792708,
      },
      {
        lat: 13.082074683734621,
        lng: 80.16747518070743,
      },
      {
        lat: 13.086341181980826,
        lng: 80.1660065572967,
      },
      {
        lat: 13.076396827271383,
        lng: 80.18001775141047,
      },
      {
        lat: 13.07517822797984,
        lng: 80.17806858863626,
      },
      {
        lat: 13.083556079679513,
        lng: 80.16344569735219,
      },
      {
        lat: 13.08850445895914,
        lng: 80.17116481811158,
      },
      {
        lat: 13.082259608844192,
        lng: 80.16974546050363,
      },
      {
        lat: 13.082368854533053,
        lng: 80.17004965396933,
      },
      {
        lat: 13.088815173154833,
        lng: 80.16307968255825,
      },
      {
        lat: 13.076692989802423,
        lng: 80.17471797236458,
      },
      {
        lat: 13.082875121959049,
        lng: 80.17005883019725,
      },
      {
        lat: 13.079504266734258,
        lng: 80.17746093112783,
      },
      {
        lat: 13.080013679016066,
        lng: 80.17394313111186,
      },
      {
        lat: 13.078930095437165,
        lng: 80.17392374525265,
      },
      {
        lat: 13.08897351429629,
        lng: 80.1625031261268,
      },
      {
        lat: 13.079123357201969,
        lng: 80.17867364352821,
      },
      {
        lat: 13.08535594319566,
        lng: 80.17201508583128,
      },
      {
        lat: 13.083642418778991,
        lng: 80.17203353613768,
      },
      {
        lat: 13.083821863110979,
        lng: 80.16927658405781,
      },
      {
        lat: 13.081937714223924,
        lng: 80.17430339922696,
      },
      {
        lat: 13.087254359777454,
        lng: 80.165439692366,
      },
      {
        lat: 13.087329031515035,
        lng: 80.17202612601692,
      },
      {
        lat: 13.08650277928259,
        lng: 80.1659784876916,
      },
      {
        lat: 13.085155065200333,
        lng: 80.16172668429151,
      },
      {
        lat: 13.080994182256969,
        lng: 80.17502480328923,
      },
      {
        lat: 13.074706210096082,
        lng: 80.1777177748037,
      },
      {
        lat: 13.08898754491803,
        lng: 80.16400697339604,
      },
      {
        lat: 13.087687542451713,
        lng: 80.17135873115656,
      },
      {
        lat: 13.077143364939902,
        lng: 80.18013864906649,
      },
      {
        lat: 13.086852738457456,
        lng: 80.16386028632478,
      },
      {
        lat: 13.075507413825237,
        lng: 80.18077397378975,
      },
      {
        lat: 13.080369895536096,
        lng: 80.17511387745992,
      },
      {
        lat: 13.087100277843476,
        lng: 80.17134506240635,
      },
      {
        lat: 13.086669351221381,
        lng: 80.17057177728303,
      },
      {
        lat: 13.084577315707863,
        lng: 80.1737774622439,
      },
      {
        lat: 13.075638716684638,
        lng: 80.17627983819607,
      },
      {
        lat: 13.080282013826041,
        lng: 80.17551153093964,
      },
      {
        lat: 13.07628452756877,
        lng: 80.18089926844823,
      },
      {
        lat: 13.08212591157404,
        lng: 80.1727929811232,
      },
      {
        lat: 13.084002124602916,
        lng: 80.16159564777053,
      },
      {
        lat: 13.081142488270721,
        lng: 80.1744642345581,
      },
      {
        lat: 13.077703763068603,
        lng: 80.18104351223494,
      },
      {
        lat: 13.083012669181855,
        lng: 80.17210403778714,
      },
      {
        lat: 13.081860535211613,
        lng: 80.17268781468727,
      },
      {
        lat: 13.078987907300561,
        lng: 80.17675210376918,
      },
      {
        lat: 13.082286756130665,
        lng: 80.1615146748855,
      },
      {
        lat: 13.083120090326267,
        lng: 80.17228477246314,
      },
      {
        lat: 13.082349991837644,
        lng: 80.17559179186122,
      },
      {
        lat: 13.088284207526428,
        lng: 80.16943948890041,
      },
      {
        lat: 13.083698125036339,
        lng: 80.16837405493676,
      },
      {
        lat: 13.081916736083903,
        lng: 80.17196987992625,
      },
      {
        lat: 13.08205848581741,
        lng: 80.1738463941674,
      },
      {
        lat: 13.083992994470755,
        lng: 80.17268602941402,
      },
      {
        lat: 13.084914838783279,
        lng: 80.16501159121441,
      },
      {
        lat: 13.085957784702428,
        lng: 80.16192562349009,
      },
      {
        lat: 13.083531859403164,
        lng: 80.1664019818283,
      },
      {
        lat: 13.079526433088901,
        lng: 80.17789179205727,
      },
      {
        lat: 13.081415868289753,
        lng: 80.17696025795216,
      },
      {
        lat: 13.085377573390165,
        lng: 80.16488902922876,
      },
      {
        lat: 13.077923697867496,
        lng: 80.18017540079423,
      },
      {
        lat: 13.075276880712297,
        lng: 80.17834385132714,
      },
      {
        lat: 13.07748536074619,
        lng: 80.17740775315418,
      },
      {
        lat: 13.079488528882978,
        lng: 80.17518407322432,
      },
      {
        lat: 13.087300239361344,
        lng: 80.16818521221924,
      },
      {
        lat: 13.084421439711841,
        lng: 80.17068040405223,
      },
      {
        lat: 13.081951348363267,
        lng: 80.17328645648962,
      },
      {
        lat: 13.084889076749993,
        lng: 80.16604177461947,
      },
      {
        lat: 13.079851577699264,
        lng: 80.17790091814966,
      },
      {
        lat: 13.086349290589805,
        lng: 80.17027966172546,
      },
      {
        lat: 13.085373046424,
        lng: 80.16377512602048,
      },
      {
        lat: 13.08613541319057,
        lng: 80.16869051212704,
      },
      {
        lat: 13.084783376482633,
        lng: 80.16205698179272,
      },
      {
        lat: 13.07761186383213,
        lng: 80.17481737559079,
      },
      {
        lat: 13.082876303520564,
        lng: 80.17399124318743,
      },
      {
        lat: 13.074854003629557,
        lng: 80.18038544017122,
      },
      {
        lat: 13.08318677275732,
        lng: 80.17457639424356,
      },
      {
        lat: 13.080095030492839,
        lng: 80.17509762369671,
      },
      {
        lat: 13.081137660019982,
        lng: 80.1748753037422,
      },
      {
        lat: 13.074209018580794,
        lng: 80.18074932483071,
      },
      {
        lat: 13.084259381379136,
        lng: 80.1734759511912,
      },
      {
        lat: 13.088170337527028,
        lng: 80.16161463462174,
      },
    ],
    cases: 84,
    coords: [
      {
        lat: 13.088664042312454,
        lng: 80.17185546966276,
      },
      {
        lat: 13.088166211139605,
        lng: 80.17185968293708,
      },
      {
        lat: 13.087973464584481,
        lng: 80.17171841843196,
      },
      {
        lat: 13.087445511880896,
        lng: 80.17167525470956,
      },
      {
        lat: 13.087432322523364,
        lng: 80.17213580825732,
      },
      {
        lat: 13.086776451515723,
        lng: 80.17209372714072,
      },
      {
        lat: 13.086439990001812,
        lng: 80.17198543354789,
      },
      {
        lat: 13.085544561874064,
        lng: 80.17199301178833,
      },
      {
        lat: 13.085081630554686,
        lng: 80.17212394815765,
      },
      {
        lat: 13.084857870089248,
        lng: 80.172141719208,
      },
      {
        lat: 13.08479478003642,
        lng: 80.17228514892473,
      },
      {
        lat: 13.084897285291005,
        lng: 80.17336393831836,
      },
      {
        lat: 13.085048146176058,
        lng: 80.17450582769649,
      },
      {
        lat: 13.085001045911206,
        lng: 80.17464912208743,
      },
      {
        lat: 13.084730185180796,
        lng: 80.17481018752893,
      },
      {
        lat: 13.083502061635336,
        lng: 80.17532865530272,
      },
      {
        lat: 13.082036022601264,
        lng: 80.17616668277039,
      },
      {
        lat: 13.081576084845521,
        lng: 80.1767897903425,
      },
      {
        lat: 13.081291262225209,
        lng: 80.17728439740833,
      },
      {
        lat: 13.081105468463045,
        lng: 80.17828624021298,
      },
      {
        lat: 13.080853784217554,
        lng: 80.17897109451218,
      },
      {
        lat: 13.080536209488743,
        lng: 80.17933896030601,
      },
      {
        lat: 13.080457419347425,
        lng: 80.17953015481962,
      },
      {
        lat: 13.080524854896025,
        lng: 80.1801011671715,
      },
      {
        lat: 13.080573693359117,
        lng: 80.18024364960814,
      },
      {
        lat: 13.080494420385179,
        lng: 80.18035546166956,
      },
      {
        lat: 13.080334812204827,
        lng: 80.180404444398,
      },
      {
        lat: 13.07907374341756,
        lng: 80.18076441791928,
      },
      {
        lat: 13.078037400761525,
        lng: 80.18126538529376,
      },
      {
        lat: 13.077590362663553,
        lng: 80.18138030984699,
      },
      {
        lat: 13.077335008888445,
        lng: 80.18146185751063,
      },
      {
        lat: 13.077239070160326,
        lng: 80.18146266946496,
      },
      {
        lat: 13.077174145342694,
        lng: 80.1813044458638,
      },
      {
        lat: 13.077060961461434,
        lng: 80.18109899876846,
      },
      {
        lat: 13.0767568657898,
        lng: 80.181053940486,
      },
      {
        lat: 13.076213406131036,
        lng: 80.18109029454132,
      },
      {
        lat: 13.075590383798456,
        lng: 80.18119083118695,
      },
      {
        lat: 13.075528974003934,
        lng: 80.18161051183677,
      },
      {
        lat: 13.07535582077412,
        lng: 80.18153576620062,
      },
      {
        lat: 13.075038759452102,
        lng: 80.18146223849587,
      },
      {
        lat: 13.074232178857782,
        lng: 80.18135474818136,
      },
      {
        lat: 13.073531536443918,
        lng: 80.18131304597682,
      },
      {
        lat: 13.073572147435337,
        lng: 80.18010285143016,
      },
      {
        lat: 13.074321140635421,
        lng: 80.17862944927695,
      },
      {
        lat: 13.074444933942665,
        lng: 80.1784759794283,
      },
      {
        lat: 13.074444064843545,
        lng: 80.17833309101452,
      },
      {
        lat: 13.074385806327525,
        lng: 80.17821926745624,
      },
      {
        lat: 13.074222073150528,
        lng: 80.178115862942,
      },
      {
        lat: 13.074202190125613,
        lng: 80.17800171460175,
      },
      {
        lat: 13.074422153920745,
        lng: 80.17788553637585,
      },
      {
        lat: 13.074536121595422,
        lng: 80.17769404414568,
      },
      {
        lat: 13.074608642962206,
        lng: 80.1769980043023,
      },
      {
        lat: 13.074722784456702,
        lng: 80.1768350897548,
      },
      {
        lat: 13.07502903516697,
        lng: 80.1767086548757,
      },
      {
        lat: 13.076000599459539,
        lng: 80.17554773973761,
      },
      {
        lat: 13.07649355336538,
        lng: 80.17450071110032,
      },
      {
        lat: 13.076588157981584,
        lng: 80.17448544482454,
      },
      {
        lat: 13.077494891213522,
        lng: 80.17431599339893,
      },
      {
        lat: 13.078239122538962,
        lng: 80.1742172504865,
      },
      {
        lat: 13.078935539201344,
        lng: 80.17391091258972,
      },
      {
        lat: 13.080704142819192,
        lng: 80.1738497222937,
      },
      {
        lat: 13.081168233177037,
        lng: 80.17361468385216,
      },
      {
        lat: 13.08176663094877,
        lng: 80.17250028797908,
      },
      {
        lat: 13.082220179105764,
        lng: 80.17053200832504,
      },
      {
        lat: 13.0822148377238,
        lng: 80.16965383277739,
      },
      {
        lat: 13.081886460405947,
        lng: 80.16924061261568,
      },
      {
        lat: 13.081834569425592,
        lng: 80.16836283102973,
      },
      {
        lat: 13.081897646814383,
        lng: 80.1672529657118,
      },
      {
        lat: 13.082125615675913,
        lng: 80.16646525988858,
      },
      {
        lat: 13.082402664263965,
        lng: 80.16609313799542,
      },
      {
        lat: 13.08247136416009,
        lng: 80.16590766799071,
      },
      {
        lat: 13.082305910437071,
        lng: 80.16549306896437,
      },
      {
        lat: 13.082350492158065,
        lng: 80.16516913664351,
      },
      {
        lat: 13.082675355408837,
        lng: 80.16500460973454,
      },
      {
        lat: 13.083043957004163,
        lng: 80.16437749120729,
      },
      {
        lat: 13.083528792033238,
        lng: 80.16372627789416,
      },
      {
        lat: 13.083501159791034,
        lng: 80.16301006850712,
      },
      {
        lat: 13.083006907062,
        lng: 80.16211291967056,
      },
      {
        lat: 13.081979864078603,
        lng: 80.16163627928007,
      },
      {
        lat: 13.081374438172704,
        lng: 80.16159518101172,
      },
      {
        lat: 13.080069643788235,
        lng: 80.16137511310039,
      },
      {
        lat: 13.079766087459097,
        lng: 80.16121590466915,
      },
      {
        lat: 13.079748251222318,
        lng: 80.16108089943424,
      },
      {
        lat: 13.085343974142937,
        lng: 80.16139576999896,
      },
      {
        lat: 13.0891134855723,
        lng: 80.1616386549812,
      },
      {
        lat: 13.089124413107786,
        lng: 80.16343524891887,
      },
      {
        lat: 13.08889886701911,
        lng: 80.16485336942274,
      },
      {
        lat: 13.088771068666057,
        lng: 80.16834213641741,
      },
      {
        lat: 13.088607327327098,
        lng: 80.1694215340549,
      },
      {
        lat: 13.088664042312454,
        lng: 80.17185546966276,
      },
    ],
    properties: {
      Ward_No: 91,
      Zone_Name: "AMBATTUR",
      fillColor: "#f6d04d",
      zname: "AMBATTUR(91)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.08738533912365,
        lng: 80.18032096013084,
      },
      {
        lat: 13.078508740015375,
        lng: 80.18258640031245,
      },
      {
        lat: 13.0832462035919,
        lng: 80.17680295605075,
      },
      {
        lat: 13.0867951279249,
        lng: 80.17731618756186,
      },
      {
        lat: 13.082198960619786,
        lng: 80.18374391216172,
      },
      {
        lat: 13.072429948168557,
        lng: 80.17802085064544,
      },
      {
        lat: 13.08552323418407,
        lng: 80.1839657607745,
      },
      {
        lat: 13.08710412459274,
        lng: 80.18171725255156,
      },
      {
        lat: 13.07838475659798,
        lng: 80.18320200888054,
      },
      {
        lat: 13.08438392400253,
        lng: 80.17928600831723,
      },
      {
        lat: 13.084255761259234,
        lng: 80.17569340529109,
      },
      {
        lat: 13.085126395256053,
        lng: 80.18189694991102,
      },
      {
        lat: 13.07239395507291,
        lng: 80.17791986081414,
      },
      {
        lat: 13.087063251022373,
        lng: 80.18502936993119,
      },
      {
        lat: 13.085145526865361,
        lng: 80.18204405678492,
      },
      {
        lat: 13.082180091462403,
        lng: 80.17729974994799,
      },
      {
        lat: 13.081296504960404,
        lng: 80.183843218005,
      },
      {
        lat: 13.084101310113672,
        lng: 80.18075612062955,
      },
      {
        lat: 13.081197262771937,
        lng: 80.17859698815684,
      },
      {
        lat: 13.08562982560964,
        lng: 80.18279385977337,
      },
      {
        lat: 13.074871142245373,
        lng: 80.176725300996,
      },
      {
        lat: 13.085372821523691,
        lng: 80.17480140628302,
      },
      {
        lat: 13.082369597345442,
        lng: 80.18395157962513,
      },
      {
        lat: 13.073498048754706,
        lng: 80.18085849673791,
      },
      {
        lat: 13.085442828176323,
        lng: 80.17567738318273,
      },
      {
        lat: 13.083102838718233,
        lng: 80.17849930297123,
      },
      {
        lat: 13.08148424012471,
        lng: 80.18124962896808,
      },
      {
        lat: 13.075160379528871,
        lng: 80.1755499475223,
      },
      {
        lat: 13.079752705553478,
        lng: 80.18134919138227,
      },
      {
        lat: 13.081588522792705,
        lng: 80.18221361016451,
      },
      {
        lat: 13.082458281574043,
        lng: 80.1826245528788,
      },
      {
        lat: 13.085754064783432,
        lng: 80.17405053056618,
      },
      {
        lat: 13.078637779079303,
        lng: 80.1850679461483,
      },
      {
        lat: 13.081767319089781,
        lng: 80.18311360034758,
      },
      {
        lat: 13.086581874152213,
        lng: 80.18076466403485,
      },
      {
        lat: 13.085926215876277,
        lng: 80.17289334425395,
      },
      {
        lat: 13.087345954582316,
        lng: 80.17445678592051,
      },
      {
        lat: 13.085458292158421,
        lng: 80.17324087687398,
      },
      {
        lat: 13.084096363488273,
        lng: 80.18354225600353,
      },
      {
        lat: 13.086897984420212,
        lng: 80.1777202701382,
      },
      {
        lat: 13.078125641508205,
        lng: 80.18139236493494,
      },
      {
        lat: 13.0850318135675,
        lng: 80.17530651538567,
      },
      {
        lat: 13.07851455931791,
        lng: 80.18159208081578,
      },
      {
        lat: 13.084725465620405,
        lng: 80.17704135381061,
      },
      {
        lat: 13.073616514768975,
        lng: 80.1789449291732,
      },
      {
        lat: 13.087339241179905,
        lng: 80.17238967950185,
      },
      {
        lat: 13.081321573245217,
        lng: 80.17741346719824,
      },
      {
        lat: 13.073246258541536,
        lng: 80.1785957068023,
      },
      {
        lat: 13.075597110874993,
        lng: 80.1750031127811,
      },
      {
        lat: 13.086981794014587,
        lng: 80.17314311163958,
      },
      {
        lat: 13.078942703939056,
        lng: 80.18507879929494,
      },
      {
        lat: 13.077803250897793,
        lng: 80.18269004916375,
      },
      {
        lat: 13.081099061284272,
        lng: 80.18306359197332,
      },
      {
        lat: 13.0811708487243,
        lng: 80.17812663637294,
      },
      {
        lat: 13.086866486245679,
        lng: 80.17972022006333,
      },
      {
        lat: 13.08568842156468,
        lng: 80.18500929190259,
      },
      {
        lat: 13.085654032194405,
        lng: 80.17746874405988,
      },
      {
        lat: 13.08296337706252,
        lng: 80.17566089688566,
      },
      {
        lat: 13.087926154810907,
        lng: 80.17599766008293,
      },
      {
        lat: 13.076274511207494,
        lng: 80.18109917890071,
      },
      {
        lat: 13.080352427544417,
        lng: 80.18056038115887,
      },
      {
        lat: 13.085718990010546,
        lng: 80.18355656623025,
      },
      {
        lat: 13.076735946998015,
        lng: 80.18110232106535,
      },
      {
        lat: 13.085864531599164,
        lng: 80.1849165102661,
      },
      {
        lat: 13.085400384550988,
        lng: 80.17917665411801,
      },
      {
        lat: 13.084439826737835,
        lng: 80.1774818553132,
      },
      {
        lat: 13.074685171608449,
        lng: 80.17628224753626,
      },
      {
        lat: 13.073064268679092,
        lng: 80.18037001078628,
      },
      {
        lat: 13.08462895337734,
        lng: 80.17596833288373,
      },
      {
        lat: 13.076146597720797,
        lng: 80.18131945194546,
      },
      {
        lat: 13.080998102801702,
        lng: 80.18453836779851,
      },
      {
        lat: 13.07244993438603,
        lng: 80.17759493383537,
      },
      {
        lat: 13.075974245917253,
        lng: 80.18144945310442,
      },
      {
        lat: 13.08276711235058,
        lng: 80.18290966950721,
      },
      {
        lat: 13.07274215784292,
        lng: 80.17977911625844,
      },
      {
        lat: 13.083034151871436,
        lng: 80.17817569078852,
      },
      {
        lat: 13.083052342755382,
        lng: 80.17566378227622,
      },
      {
        lat: 13.08434649345697,
        lng: 80.18280390604903,
      },
      {
        lat: 13.087673539405511,
        lng: 80.17792625505489,
      },
      {
        lat: 13.072670936273147,
        lng: 80.18019564947666,
      },
      {
        lat: 13.084681197394723,
        lng: 80.17672303066365,
      },
      {
        lat: 13.085195317234687,
        lng: 80.17389689127235,
      },
      {
        lat: 13.082702032698432,
        lng: 80.18096149313568,
      },
      {
        lat: 13.079817205038937,
        lng: 80.18078098274174,
      },
      {
        lat: 13.079160007177933,
        lng: 80.18434201899966,
      },
      {
        lat: 13.085835558929357,
        lng: 80.18172063870175,
      },
      {
        lat: 13.073480602825233,
        lng: 80.17801176901239,
      },
      {
        lat: 13.085483711886235,
        lng: 80.18083419943184,
      },
      {
        lat: 13.085886117643879,
        lng: 80.17966726829222,
      },
      {
        lat: 13.082609007685734,
        lng: 80.17832742856137,
      },
      {
        lat: 13.08255295423257,
        lng: 80.1830816017324,
      },
      {
        lat: 13.07460589054759,
        lng: 80.1815836756503,
      },
      {
        lat: 13.08217476062212,
        lng: 80.17647806502274,
      },
      {
        lat: 13.085642877667938,
        lng: 80.18196132447144,
      },
      {
        lat: 13.080670316162076,
        lng: 80.18474306769505,
      },
      {
        lat: 13.085192492085472,
        lng: 80.17678127826044,
      },
      {
        lat: 13.078322585705923,
        lng: 80.18154546592159,
      },
      {
        lat: 13.079106727869254,
        lng: 80.18382774078866,
      },
      {
        lat: 13.07520737241488,
        lng: 80.17613168411613,
      },
      {
        lat: 13.085407999352832,
        lng: 80.1814000023352,
      },
      {
        lat: 13.088585465755429,
        lng: 80.17205326567061,
      },
      {
        lat: 13.08462677771664,
        lng: 80.17543598405418,
      },
      {
        lat: 13.088245472222871,
        lng: 80.17251283467613,
      },
      {
        lat: 13.082292433309378,
        lng: 80.179358790135,
      },
      {
        lat: 13.085816808966529,
        lng: 80.18453584990563,
      },
      {
        lat: 13.083371039244916,
        lng: 80.17802251504551,
      },
      {
        lat: 13.077196298023448,
        lng: 80.1815750052842,
      },
      {
        lat: 13.078620768026115,
        lng: 80.18237237053421,
      },
    ],
    cases: 108,
    coords: [
      {
        lat: 13.087241671512865,
        lng: 80.18519921344337,
      },
      {
        lat: 13.085887390669127,
        lng: 80.1851673043567,
      },
      {
        lat: 13.08546993515271,
        lng: 80.18529139296832,
      },
      {
        lat: 13.085267504292267,
        lng: 80.18527971112903,
      },
      {
        lat: 13.085159014330982,
        lng: 80.18518686385866,
      },
      {
        lat: 13.085076689594276,
        lng: 80.1849598445037,
      },
      {
        lat: 13.085005247794648,
        lng: 80.18430409099086,
      },
      {
        lat: 13.084920804852295,
        lng: 80.18372881789494,
      },
      {
        lat: 13.084515943131457,
        lng: 80.18370545421655,
      },
      {
        lat: 13.083491846789288,
        lng: 80.18390165230754,
      },
      {
        lat: 13.081563841698008,
        lng: 80.18409210534858,
      },
      {
        lat: 13.08117304022281,
        lng: 80.1841623881234,
      },
      {
        lat: 13.081106160711895,
        lng: 80.18425671959105,
      },
      {
        lat: 13.081067401691977,
        lng: 80.18453834396587,
      },
      {
        lat: 13.081257646104046,
        lng: 80.18476444996863,
      },
      {
        lat: 13.081232051165566,
        lng: 80.18499238267574,
      },
      {
        lat: 13.08107106781683,
        lng: 80.18514109082521,
      },
      {
        lat: 13.08034301735651,
        lng: 80.18521422782585,
      },
      {
        lat: 13.07927804378894,
        lng: 80.18534379655067,
      },
      {
        lat: 13.078536910716391,
        lng: 80.18548401959356,
      },
      {
        lat: 13.078362518877551,
        lng: 80.18564623628673,
      },
      {
        lat: 13.078296128183288,
        lng: 80.18582093400236,
      },
      {
        lat: 13.078188127038652,
        lng: 80.18580845297988,
      },
      {
        lat: 13.078106291118361,
        lng: 80.18566179987288,
      },
      {
        lat: 13.078009417319533,
        lng: 80.18526076781642,
      },
      {
        lat: 13.077692875106363,
        lng: 80.18423202685574,
      },
      {
        lat: 13.077636437217677,
        lng: 80.18160707193101,
      },
      {
        lat: 13.077245146925662,
        lng: 80.18159698845808,
      },
      {
        lat: 13.077092997827151,
        lng: 80.18162616950507,
      },
      {
        lat: 13.076446390044836,
        lng: 80.1817387596019,
      },
      {
        lat: 13.075727864565138,
        lng: 80.18185195835694,
      },
      {
        lat: 13.07502558118948,
        lng: 80.18167937248404,
      },
      {
        lat: 13.074467458956295,
        lng: 80.18155912538163,
      },
      {
        lat: 13.073927533312785,
        lng: 80.1814744301727,
      },
      {
        lat: 13.073154526647242,
        lng: 80.1814988252764,
      },
      {
        lat: 13.072689826373894,
        lng: 80.18160265754004,
      },
      {
        lat: 13.072360918010995,
        lng: 80.1797905455713,
      },
      {
        lat: 13.072183657022464,
        lng: 80.17743471638707,
      },
      {
        lat: 13.072649153007651,
        lng: 80.17743077677382,
      },
      {
        lat: 13.073184051702206,
        lng: 80.17735691657008,
      },
      {
        lat: 13.07348367227621,
        lng: 80.17686904828203,
      },
      {
        lat: 13.073738008315631,
        lng: 80.1765895629008,
      },
      {
        lat: 13.074016603093197,
        lng: 80.1764716497188,
      },
      {
        lat: 13.074619779996167,
        lng: 80.17614298986173,
      },
      {
        lat: 13.075497334796037,
        lng: 80.17500312033788,
      },
      {
        lat: 13.075983575452124,
        lng: 80.17458300585295,
      },
      {
        lat: 13.07649355336538,
        lng: 80.17450071110032,
      },
      {
        lat: 13.076000599459539,
        lng: 80.17554773973761,
      },
      {
        lat: 13.07502903516697,
        lng: 80.1767086548757,
      },
      {
        lat: 13.074722784456702,
        lng: 80.1768350897548,
      },
      {
        lat: 13.074608642962206,
        lng: 80.1769980043023,
      },
      {
        lat: 13.074536121595422,
        lng: 80.17769404414568,
      },
      {
        lat: 13.074422153920745,
        lng: 80.17788553637585,
      },
      {
        lat: 13.074202190125613,
        lng: 80.17800171460175,
      },
      {
        lat: 13.074222073150528,
        lng: 80.178115862942,
      },
      {
        lat: 13.074385806327525,
        lng: 80.17821926745624,
      },
      {
        lat: 13.074444064843545,
        lng: 80.17833309101452,
      },
      {
        lat: 13.074444933942665,
        lng: 80.1784759794283,
      },
      {
        lat: 13.074321140635421,
        lng: 80.17862944927695,
      },
      {
        lat: 13.073572147435337,
        lng: 80.18010285143016,
      },
      {
        lat: 13.073531536443918,
        lng: 80.18131304597682,
      },
      {
        lat: 13.074232178857782,
        lng: 80.18135474818136,
      },
      {
        lat: 13.075038759452102,
        lng: 80.18146223849587,
      },
      {
        lat: 13.07535582077412,
        lng: 80.18153576620062,
      },
      {
        lat: 13.075528974003934,
        lng: 80.18161051183677,
      },
      {
        lat: 13.075590383798456,
        lng: 80.18119083118695,
      },
      {
        lat: 13.076213406131036,
        lng: 80.18109029454132,
      },
      {
        lat: 13.0767568657898,
        lng: 80.181053940486,
      },
      {
        lat: 13.077060961461434,
        lng: 80.18109899876846,
      },
      {
        lat: 13.077174145342694,
        lng: 80.1813044458638,
      },
      {
        lat: 13.077239070160326,
        lng: 80.18146266946496,
      },
      {
        lat: 13.077335008888445,
        lng: 80.18146185751063,
      },
      {
        lat: 13.077590362663553,
        lng: 80.18138030984699,
      },
      {
        lat: 13.078037400761525,
        lng: 80.18126538529376,
      },
      {
        lat: 13.07907374341756,
        lng: 80.18076441791928,
      },
      {
        lat: 13.080334812204827,
        lng: 80.180404444398,
      },
      {
        lat: 13.080494420385179,
        lng: 80.18035546166956,
      },
      {
        lat: 13.080573693359117,
        lng: 80.18024364960814,
      },
      {
        lat: 13.080524854896025,
        lng: 80.1801011671715,
      },
      {
        lat: 13.080457419347425,
        lng: 80.17953015481962,
      },
      {
        lat: 13.080536209488743,
        lng: 80.17933896030601,
      },
      {
        lat: 13.080853784217554,
        lng: 80.17897109451218,
      },
      {
        lat: 13.081105468463045,
        lng: 80.17828624021298,
      },
      {
        lat: 13.081291262225209,
        lng: 80.17728439740833,
      },
      {
        lat: 13.081576084845521,
        lng: 80.1767897903425,
      },
      {
        lat: 13.082036022601264,
        lng: 80.17616668277039,
      },
      {
        lat: 13.083502061635336,
        lng: 80.17532865530272,
      },
      {
        lat: 13.084730185180796,
        lng: 80.17481018752893,
      },
      {
        lat: 13.085001045911206,
        lng: 80.17464912208743,
      },
      {
        lat: 13.085048146176058,
        lng: 80.17450582769649,
      },
      {
        lat: 13.084897285291005,
        lng: 80.17336393831836,
      },
      {
        lat: 13.08479478003642,
        lng: 80.17228514892473,
      },
      {
        lat: 13.084857870089248,
        lng: 80.172141719208,
      },
      {
        lat: 13.085081630554686,
        lng: 80.17212394815765,
      },
      {
        lat: 13.085544561874064,
        lng: 80.17199301178833,
      },
      {
        lat: 13.086439990001812,
        lng: 80.17198543354789,
      },
      {
        lat: 13.086776451515723,
        lng: 80.17209372714072,
      },
      {
        lat: 13.087432322523364,
        lng: 80.17213580825732,
      },
      {
        lat: 13.087445511880896,
        lng: 80.17167525470956,
      },
      {
        lat: 13.087973464584481,
        lng: 80.17171841843196,
      },
      {
        lat: 13.088166211139605,
        lng: 80.17185968293708,
      },
      {
        lat: 13.088664042312454,
        lng: 80.17185546966276,
      },
      {
        lat: 13.088664943223387,
        lng: 80.17189413245032,
      },
      {
        lat: 13.088378877977226,
        lng: 80.17386233981338,
      },
      {
        lat: 13.088223492988702,
        lng: 80.17631560340314,
      },
      {
        lat: 13.08814502913923,
        lng: 80.17741541680246,
      },
      {
        lat: 13.088084124304196,
        lng: 80.17790209446387,
      },
      {
        lat: 13.087467051678983,
        lng: 80.17794959188393,
      },
      {
        lat: 13.087440161441295,
        lng: 80.18052859291333,
      },
      {
        lat: 13.08731436643475,
        lng: 80.18084671985852,
      },
      {
        lat: 13.087372753686486,
        lng: 80.18344613664945,
      },
      {
        lat: 13.087247730035223,
        lng: 80.18389108199044,
      },
      {
        lat: 13.087241671512865,
        lng: 80.18519921344337,
      },
    ],
    properties: {
      Ward_No: 92,
      Zone_Name: "AMBATTUR",
      fillColor: "#f6d04d",
      zname: "AMBATTUR(92)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.097678730345095,
        lng: 80.19436197911077,
      },
      {
        lat: 13.101522547689248,
        lng: 80.19188406408475,
      },
      {
        lat: 13.094890754856381,
        lng: 80.18976442816026,
      },
      {
        lat: 13.093510339586052,
        lng: 80.19059712283995,
      },
      {
        lat: 13.094095887776358,
        lng: 80.18936676356064,
      },
      {
        lat: 13.097872722712603,
        lng: 80.18937902641804,
      },
      {
        lat: 13.098272293749606,
        lng: 80.1951606866816,
      },
      {
        lat: 13.092057067220075,
        lng: 80.18802349713948,
      },
      {
        lat: 13.097920068702042,
        lng: 80.18551331178145,
      },
      {
        lat: 13.098537496140963,
        lng: 80.19019617377343,
      },
      {
        lat: 13.090673527417422,
        lng: 80.18853873843756,
      },
      {
        lat: 13.090572303657574,
        lng: 80.1902664894073,
      },
      {
        lat: 13.099873608504966,
        lng: 80.19004106843849,
      },
      {
        lat: 13.100188227885202,
        lng: 80.19285960442119,
      },
      {
        lat: 13.101522413101554,
        lng: 80.1941345661713,
      },
      {
        lat: 13.09784003698662,
        lng: 80.1907351945033,
      },
      {
        lat: 13.101352160868915,
        lng: 80.19429472546689,
      },
      {
        lat: 13.091266337268845,
        lng: 80.18748627732973,
      },
      {
        lat: 13.100347704621825,
        lng: 80.19007491440159,
      },
      {
        lat: 13.098276222736377,
        lng: 80.19044670650837,
      },
      {
        lat: 13.09353749505603,
        lng: 80.19065384521352,
      },
      {
        lat: 13.097907684241346,
        lng: 80.19131794862052,
      },
      {
        lat: 13.099218482358417,
        lng: 80.18560781098068,
      },
      {
        lat: 13.098868638520509,
        lng: 80.18983856497795,
      },
      {
        lat: 13.098216319474242,
        lng: 80.18571517288824,
      },
      {
        lat: 13.101070982988299,
        lng: 80.19274302887806,
      },
      {
        lat: 13.091573140737525,
        lng: 80.187612075264,
      },
      {
        lat: 13.098012761893786,
        lng: 80.1897964590033,
      },
      {
        lat: 13.09948926124225,
        lng: 80.19147861032162,
      },
      {
        lat: 13.097965747933348,
        lng: 80.19289492253093,
      },
      {
        lat: 13.097061529618086,
        lng: 80.19377788082191,
      },
      {
        lat: 13.099082845449347,
        lng: 80.1880442871583,
      },
      {
        lat: 13.09411979582518,
        lng: 80.19083416557893,
      },
      {
        lat: 13.096834874444378,
        lng: 80.1920754297346,
      },
      {
        lat: 13.097988599497945,
        lng: 80.18846161534333,
      },
      {
        lat: 13.099375891209869,
        lng: 80.19320233717131,
      },
      {
        lat: 13.096023368736875,
        lng: 80.1891974521721,
      },
      {
        lat: 13.092951550903576,
        lng: 80.19053007490838,
      },
      {
        lat: 13.095394945260969,
        lng: 80.18734133471727,
      },
      {
        lat: 13.100620657016814,
        lng: 80.19246735551519,
      },
      {
        lat: 13.096656358437585,
        lng: 80.19095053104881,
      },
      {
        lat: 13.093814883657469,
        lng: 80.19045876124868,
      },
      {
        lat: 13.099302238740293,
        lng: 80.19537537611575,
      },
      {
        lat: 13.098935438815447,
        lng: 80.19455476887835,
      },
      {
        lat: 13.091346381909434,
        lng: 80.18780707192046,
      },
      {
        lat: 13.09749114550565,
        lng: 80.1914094711788,
      },
      {
        lat: 13.099053321307228,
        lng: 80.18663796586097,
      },
      {
        lat: 13.092243703049737,
        lng: 80.18970642864073,
      },
      {
        lat: 13.094168623207207,
        lng: 80.19043271597697,
      },
      {
        lat: 13.095643917474254,
        lng: 80.19165289134581,
      },
      {
        lat: 13.094177244645945,
        lng: 80.18820129107638,
      },
      {
        lat: 13.098146352802535,
        lng: 80.19323961106174,
      },
      {
        lat: 13.097869335470921,
        lng: 80.18974551250004,
      },
      {
        lat: 13.093886047490784,
        lng: 80.18900065639892,
      },
      {
        lat: 13.096031463149854,
        lng: 80.18917936363032,
      },
      {
        lat: 13.100597936488139,
        lng: 80.19201781670237,
      },
      {
        lat: 13.093178080141326,
        lng: 80.1899727953364,
      },
      {
        lat: 13.09709060734062,
        lng: 80.19436363705204,
      },
      {
        lat: 13.098772914978339,
        lng: 80.18850310812785,
      },
      {
        lat: 13.101085589286576,
        lng: 80.19338751205235,
      },
      {
        lat: 13.090848719556602,
        lng: 80.19034610338949,
      },
      {
        lat: 13.099841432706429,
        lng: 80.19098581759583,
      },
      {
        lat: 13.100575576256405,
        lng: 80.19040923211064,
      },
      {
        lat: 13.099397705737282,
        lng: 80.19259818902786,
      },
      {
        lat: 13.09363681516832,
        lng: 80.19026227471296,
      },
      {
        lat: 13.093396573370645,
        lng: 80.1900416316662,
      },
      {
        lat: 13.098304076115939,
        lng: 80.1900589566462,
      },
      {
        lat: 13.099229622446199,
        lng: 80.18571510916644,
      },
      {
        lat: 13.098926861549119,
        lng: 80.19326236545957,
      },
      {
        lat: 13.099469484397282,
        lng: 80.19362512644472,
      },
      {
        lat: 13.101148816624129,
        lng: 80.19447389337437,
      },
      {
        lat: 13.09875844067562,
        lng: 80.18629747899347,
      },
      {
        lat: 13.097850920826819,
        lng: 80.19491060007492,
      },
      {
        lat: 13.092719542235821,
        lng: 80.18770357179504,
      },
      {
        lat: 13.100436253066796,
        lng: 80.18890962880448,
      },
      {
        lat: 13.096978984886286,
        lng: 80.19458693580997,
      },
      {
        lat: 13.09971743337374,
        lng: 80.1930138635022,
      },
      {
        lat: 13.100274518885168,
        lng: 80.18998502747786,
      },
      {
        lat: 13.099043525594396,
        lng: 80.19494779323742,
      },
      {
        lat: 13.094833439071452,
        lng: 80.18810539288995,
      },
      {
        lat: 13.098497090508094,
        lng: 80.18685857761768,
      },
      {
        lat: 13.092291855415443,
        lng: 80.18975280554643,
      },
      {
        lat: 13.096733330381246,
        lng: 80.1917622200632,
      },
      {
        lat: 13.097240251010758,
        lng: 80.19405403008172,
      },
      {
        lat: 13.09800274884393,
        lng: 80.18537613181743,
      },
      {
        lat: 13.095708570099994,
        lng: 80.19059633251496,
      },
      {
        lat: 13.098955992432495,
        lng: 80.18875330106975,
      },
      {
        lat: 13.098411633776813,
        lng: 80.19373276197884,
      },
      {
        lat: 13.099621834022228,
        lng: 80.1857433567472,
      },
      {
        lat: 13.096544686447611,
        lng: 80.18955838368042,
      },
    ],
    cases: 90,
    coords: [
      {
        lat: 13.095287604765401,
        lng: 80.19257835706894,
      },
      {
        lat: 13.095273158324071,
        lng: 80.19136745912674,
      },
      {
        lat: 13.095055611568393,
        lng: 80.1912698775209,
      },
      {
        lat: 13.093719374108547,
        lng: 80.19108234092464,
      },
      {
        lat: 13.091064090951697,
        lng: 80.19078997453366,
      },
      {
        lat: 13.090262570197094,
        lng: 80.1907139057115,
      },
      {
        lat: 13.090259244379352,
        lng: 80.19016710867729,
      },
      {
        lat: 13.09159417166858,
        lng: 80.19013924038136,
      },
      {
        lat: 13.09158610908011,
        lng: 80.18881367181336,
      },
      {
        lat: 13.091101657492331,
        lng: 80.1887349195526,
      },
      {
        lat: 13.09045012583106,
        lng: 80.18862444041352,
      },
      {
        lat: 13.090283247322112,
        lng: 80.1886258527496,
      },
      {
        lat: 13.090231974381085,
        lng: 80.1884274411654,
      },
      {
        lat: 13.09034667290781,
        lng: 80.18807849078115,
      },
      {
        lat: 13.090374505580089,
        lng: 80.18716687992342,
      },
      {
        lat: 13.091443737425648,
        lng: 80.18735667625762,
      },
      {
        lat: 13.091878125460495,
        lng: 80.18743585221918,
      },
      {
        lat: 13.092228066417567,
        lng: 80.18735003827796,
      },
      {
        lat: 13.092561823435354,
        lng: 80.18734721360576,
      },
      {
        lat: 13.094132194719414,
        lng: 80.18761562096694,
      },
      {
        lat: 13.09448334506476,
        lng: 80.18772864231099,
      },
      {
        lat: 13.094494893015508,
        lng: 80.18688345111529,
      },
      {
        lat: 13.09454344483276,
        lng: 80.18663448330808,
      },
      {
        lat: 13.095561605302,
        lng: 80.18665900727189,
      },
      {
        lat: 13.095446705210517,
        lng: 80.18697481844212,
      },
      {
        lat: 13.095416453761747,
        lng: 80.18748875872933,
      },
      {
        lat: 13.09528667990177,
        lng: 80.18810296406096,
      },
      {
        lat: 13.095325900980264,
        lng: 80.18906371880543,
      },
      {
        lat: 13.096076753488143,
        lng: 80.18904079368589,
      },
      {
        lat: 13.096105493201463,
        lng: 80.18827830929216,
      },
      {
        lat: 13.096372398033346,
        lng: 80.18825947994736,
      },
      {
        lat: 13.096790198999987,
        lng: 80.18835536674962,
      },
      {
        lat: 13.097524867568637,
        lng: 80.18841543089927,
      },
      {
        lat: 13.097792175529937,
        lng: 80.1884628799827,
      },
      {
        lat: 13.097925476772371,
        lng: 80.18842861089958,
      },
      {
        lat: 13.097972516854453,
        lng: 80.18793109898583,
      },
      {
        lat: 13.098089533375415,
        lng: 80.18796324956483,
      },
      {
        lat: 13.09810964782641,
        lng: 80.1885264749726,
      },
      {
        lat: 13.098593393937527,
        lng: 80.18848923998365,
      },
      {
        lat: 13.09860806614131,
        lng: 80.18815770660805,
      },
      {
        lat: 13.098705472123164,
        lng: 80.1877094798148,
      },
      {
        lat: 13.098785081648026,
        lng: 80.18707912857691,
      },
      {
        lat: 13.097666693291146,
        lng: 80.1870388824076,
      },
      {
        lat: 13.09751569637425,
        lng: 80.18690759665314,
      },
      {
        lat: 13.097514184638912,
        lng: 80.18665905254666,
      },
      {
        lat: 13.097823392782153,
        lng: 80.1853639397545,
      },
      {
        lat: 13.097855760660448,
        lng: 80.18519796121628,
      },
      {
        lat: 13.097989061902828,
        lng: 80.18516369213314,
      },
      {
        lat: 13.098974753711468,
        lng: 80.18533762502832,
      },
      {
        lat: 13.099375463697621,
        lng: 80.1853673746359,
      },
      {
        lat: 13.099672409859641,
        lng: 80.18530102521912,
      },
      {
        lat: 13.099917742248758,
        lng: 80.18648127983816,
      },
      {
        lat: 13.100290842436353,
        lng: 80.18794860834508,
      },
      {
        lat: 13.100659983994895,
        lng: 80.18876509943568,
      },
      {
        lat: 13.101443742394006,
        lng: 80.18989146381085,
      },
      {
        lat: 13.101544516222926,
        lng: 80.19049326919418,
      },
      {
        lat: 13.10182050797893,
        lng: 80.19196141955494,
      },
      {
        lat: 13.101998364992161,
        lng: 80.19326165614316,
      },
      {
        lat: 13.102175693604265,
        lng: 80.19463589194982,
      },
      {
        lat: 13.101954460516907,
        lng: 80.19457380455862,
      },
      {
        lat: 13.101334704808618,
        lng: 80.19445611898801,
      },
      {
        lat: 13.100427823248083,
        lng: 80.19462770179386,
      },
      {
        lat: 13.099749157419335,
        lng: 80.19500223769039,
      },
      {
        lat: 13.099297710427335,
        lng: 80.19541582748298,
      },
      {
        lat: 13.098719969592139,
        lng: 80.19542071705345,
      },
      {
        lat: 13.096747592383052,
        lng: 80.19543740979329,
      },
      {
        lat: 13.096744175907691,
        lng: 80.19340901557433,
      },
      {
        lat: 13.095333396977908,
        lng: 80.19335297263287,
      },
      {
        lat: 13.09518227925527,
        lng: 80.19327267230604,
      },
      {
        lat: 13.095181452306635,
        lng: 80.19313671384586,
      },
      {
        lat: 13.095276061963215,
        lng: 80.19293196495158,
      },
      {
        lat: 13.095287604765401,
        lng: 80.19257835706894,
      },
    ],
    properties: {
      Ward_No: 88,
      Zone_Name: "AMBATTUR",
      fillColor: "#f6d04d",
      zname: "AMBATTUR(88)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.09160980008085,
        lng: 80.18471903973665,
      },
      {
        lat: 13.096262276940395,
        lng: 80.18722122431916,
      },
      {
        lat: 13.093117322549983,
        lng: 80.18505641884809,
      },
      {
        lat: 13.09703527704988,
        lng: 80.18041342543101,
      },
      {
        lat: 13.096649797961023,
        lng: 80.18140237726364,
      },
      {
        lat: 13.093180915911978,
        lng: 80.18076016570608,
      },
      {
        lat: 13.096466677571426,
        lng: 80.18253890588824,
      },
      {
        lat: 13.095250897909215,
        lng: 80.18561721450374,
      },
      {
        lat: 13.093320838747697,
        lng: 80.18110693914092,
      },
      {
        lat: 13.097454948587416,
        lng: 80.18692688712022,
      },
      {
        lat: 13.09398233263171,
        lng: 80.17767415355127,
      },
      {
        lat: 13.096187134566259,
        lng: 80.18209788068384,
      },
      {
        lat: 13.096031061545645,
        lng: 80.18729444445535,
      },
      {
        lat: 13.096724539536089,
        lng: 80.18067530145272,
      },
      {
        lat: 13.095444781020934,
        lng: 80.18536040098164,
      },
      {
        lat: 13.098341861159431,
        lng: 80.18334889258509,
      },
      {
        lat: 13.094165334378559,
        lng: 80.17824098392462,
      },
      {
        lat: 13.099125478668018,
        lng: 80.18426267822532,
      },
      {
        lat: 13.09161985175919,
        lng: 80.18398721762492,
      },
      {
        lat: 13.095013883217367,
        lng: 80.18197865015591,
      },
      {
        lat: 13.090770617842885,
        lng: 80.18521810664107,
      },
      {
        lat: 13.091341481122365,
        lng: 80.18483343533168,
      },
      {
        lat: 13.095139464652812,
        lng: 80.18561363518745,
      },
      {
        lat: 13.096241923429394,
        lng: 80.18439369654662,
      },
      {
        lat: 13.09340104199308,
        lng: 80.1808904962956,
      },
      {
        lat: 13.096059391347548,
        lng: 80.17964564417055,
      },
      {
        lat: 13.096567581617865,
        lng: 80.18444833276827,
      },
      {
        lat: 13.096728389654302,
        lng: 80.18563174930914,
      },
      {
        lat: 13.095004830681622,
        lng: 80.17745345558244,
      },
      {
        lat: 13.095621424805563,
        lng: 80.18246324036728,
      },
      {
        lat: 13.094520692159684,
        lng: 80.18119984855991,
      },
      {
        lat: 13.095658990483221,
        lng: 80.18026708708628,
      },
      {
        lat: 13.096788457139157,
        lng: 80.17687746207271,
      },
      {
        lat: 13.09299753802668,
        lng: 80.1833681893932,
      },
      {
        lat: 13.095703680407523,
        lng: 80.18272938380323,
      },
      {
        lat: 13.091880896150839,
        lng: 80.18544682254749,
      },
      {
        lat: 13.093686341271061,
        lng: 80.18606488778723,
      },
      {
        lat: 13.095222720768643,
        lng: 80.17966179911636,
      },
      {
        lat: 13.09611331104616,
        lng: 80.18741977642756,
      },
      {
        lat: 13.094924095705725,
        lng: 80.18270508428745,
      },
      {
        lat: 13.09376051854026,
        lng: 80.18724717019438,
      },
      {
        lat: 13.093078866494652,
        lng: 80.18127661993195,
      },
      {
        lat: 13.091483278752015,
        lng: 80.18613249279812,
      },
      {
        lat: 13.096590716905947,
        lng: 80.17728169412887,
      },
      {
        lat: 13.092703060501373,
        lng: 80.18542421078314,
      },
      {
        lat: 13.097380685744119,
        lng: 80.1827225939717,
      },
      {
        lat: 13.091287288944471,
        lng: 80.18493254995485,
      },
      {
        lat: 13.09347642511739,
        lng: 80.18108021594885,
      },
      {
        lat: 13.095370758236646,
        lng: 80.1851661142049,
      },
      {
        lat: 13.098914763859774,
        lng: 80.1850056302269,
      },
      {
        lat: 13.093750016594953,
        lng: 80.17794170215065,
      },
      {
        lat: 13.093042859780128,
        lng: 80.18513596203398,
      },
      {
        lat: 13.095415871210395,
        lng: 80.18788630946719,
      },
      {
        lat: 13.09164415500602,
        lng: 80.18347406659541,
      },
      {
        lat: 13.095040068677658,
        lng: 80.18502716603666,
      },
      {
        lat: 13.093823620509893,
        lng: 80.1788283928763,
      },
      {
        lat: 13.093948591267655,
        lng: 80.18496096183308,
      },
      {
        lat: 13.0984392080459,
        lng: 80.18836743626413,
      },
      {
        lat: 13.095392999019868,
        lng: 80.18033216267752,
      },
      {
        lat: 13.094217808164965,
        lng: 80.18573816799498,
      },
      {
        lat: 13.095824298730482,
        lng: 80.18445348072161,
      },
      {
        lat: 13.097239562087859,
        lng: 80.18603617557179,
      },
      {
        lat: 13.092597984174262,
        lng: 80.18004481051351,
      },
      {
        lat: 13.097257987573915,
        lng: 80.17980464310072,
      },
      {
        lat: 13.091626375612694,
        lng: 80.18664092872913,
      },
      {
        lat: 13.094857598908943,
        lng: 80.18206227665569,
      },
      {
        lat: 13.09629096315176,
        lng: 80.17728207560843,
      },
      {
        lat: 13.09387384728468,
        lng: 80.1782238402297,
      },
      {
        lat: 13.096344224473617,
        lng: 80.18155568832523,
      },
      {
        lat: 13.097569833163574,
        lng: 80.18773582999874,
      },
      {
        lat: 13.099236733648539,
        lng: 80.18508928481295,
      },
      {
        lat: 13.091656245009064,
        lng: 80.18436007553112,
      },
      {
        lat: 13.094698551658297,
        lng: 80.17787846127645,
      },
      {
        lat: 13.092274816986246,
        lng: 80.18489809574676,
      },
      {
        lat: 13.097884064693309,
        lng: 80.18148053280319,
      },
      {
        lat: 13.09567411496653,
        lng: 80.17823683161294,
      },
      {
        lat: 13.094324507410118,
        lng: 80.18123390742602,
      },
      {
        lat: 13.091484231571657,
        lng: 80.1833947471434,
      },
      {
        lat: 13.092900825321927,
        lng: 80.18073924875887,
      },
      {
        lat: 13.094131033881403,
        lng: 80.18009417639723,
      },
      {
        lat: 13.096766567462643,
        lng: 80.18060495384317,
      },
      {
        lat: 13.093932205444847,
        lng: 80.1803967874411,
      },
      {
        lat: 13.09435180390575,
        lng: 80.1779274088772,
      },
      {
        lat: 13.094829549309138,
        lng: 80.1778074300628,
      },
      {
        lat: 13.097773650777688,
        lng: 80.17899554591611,
      },
      {
        lat: 13.097760592498174,
        lng: 80.18428439048239,
      },
      {
        lat: 13.095811780798467,
        lng: 80.17758543300951,
      },
      {
        lat: 13.096123041164718,
        lng: 80.18185927840847,
      },
      {
        lat: 13.091029746511321,
        lng: 80.18704882939795,
      },
      {
        lat: 13.094222276524583,
        lng: 80.18350863089134,
      },
      {
        lat: 13.096454705619031,
        lng: 80.18241740995516,
      },
      {
        lat: 13.091326765125324,
        lng: 80.18540404263754,
      },
      {
        lat: 13.09459553922224,
        lng: 80.1801209935579,
      },
      {
        lat: 13.09566111681748,
        lng: 80.18342597666292,
      },
      {
        lat: 13.094077727742215,
        lng: 80.1852527154702,
      },
      {
        lat: 13.091732756864307,
        lng: 80.18327699038853,
      },
      {
        lat: 13.092399133600162,
        lng: 80.18621558602793,
      },
      {
        lat: 13.098073381990762,
        lng: 80.1874405263249,
      },
      {
        lat: 13.09376249440603,
        lng: 80.17747252245958,
      },
      {
        lat: 13.095173836739328,
        lng: 80.18296214905884,
      },
      {
        lat: 13.097170960665329,
        lng: 80.18222560474068,
      },
      {
        lat: 13.098475227197572,
        lng: 80.18467797716141,
      },
      {
        lat: 13.09775218199047,
        lng: 80.18037974524725,
      },
      {
        lat: 13.093666038038794,
        lng: 80.1834444065082,
      },
      {
        lat: 13.098299826440858,
        lng: 80.18797733901962,
      },
      {
        lat: 13.097028604724812,
        lng: 80.18310256532666,
      },
      {
        lat: 13.095343689136936,
        lng: 80.18617803678555,
      },
      {
        lat: 13.09100727903265,
        lng: 80.18693474704601,
      },
      {
        lat: 13.092356387305859,
        lng: 80.18536935441757,
      },
      {
        lat: 13.098148883230431,
        lng: 80.18281053570414,
      },
      {
        lat: 13.098748683954994,
        lng: 80.18391920065699,
      },
      {
        lat: 13.095349188858155,
        lng: 80.17945225763789,
      },
      {
        lat: 13.094389672476543,
        lng: 80.1818416086545,
      },
      {
        lat: 13.097389507935311,
        lng: 80.17870478818726,
      },
      {
        lat: 13.096541478309883,
        lng: 80.17932870429003,
      },
      {
        lat: 13.097381269227826,
        lng: 80.18359747522459,
      },
      {
        lat: 13.09643536191681,
        lng: 80.18508048880051,
      },
      {
        lat: 13.094330487119505,
        lng: 80.1832194244309,
      },
      {
        lat: 13.094403600184348,
        lng: 80.18258525688732,
      },
      {
        lat: 13.096791302700572,
        lng: 80.17759451529112,
      },
      {
        lat: 13.09508724885755,
        lng: 80.18360607070285,
      },
    ],
    cases: 121,
    coords: [
      {
        lat: 13.090374505580089,
        lng: 80.18716687992342,
      },
      {
        lat: 13.090382371712954,
        lng: 80.18599766991635,
      },
      {
        lat: 13.090324344273016,
        lng: 80.18576397382219,
      },
      {
        lat: 13.090248303735,
        lng: 80.1856709424947,
      },
      {
        lat: 13.090247790973294,
        lng: 80.18558663944408,
      },
      {
        lat: 13.090330815500065,
        lng: 80.18527680968786,
      },
      {
        lat: 13.090534656928188,
        lng: 80.18466619781677,
      },
      {
        lat: 13.090625463073103,
        lng: 80.18408464505586,
      },
      {
        lat: 13.091068854038776,
        lng: 80.18408089252316,
      },
      {
        lat: 13.091151414999226,
        lng: 80.1839646040015,
      },
      {
        lat: 13.091162263519974,
        lng: 80.18286908004175,
      },
      {
        lat: 13.092160495879568,
        lng: 80.18287801955412,
      },
      {
        lat: 13.092249637147933,
        lng: 80.18313808230607,
      },
      {
        lat: 13.092442469578897,
        lng: 80.18317122593896,
      },
      {
        lat: 13.09273931043316,
        lng: 80.183029611203,
      },
      {
        lat: 13.094297683064509,
        lng: 80.18299903447937,
      },
      {
        lat: 13.094344505353286,
        lng: 80.18205969637108,
      },
      {
        lat: 13.094201244802532,
        lng: 80.1815392744671,
      },
      {
        lat: 13.092748889911558,
        lng: 80.18172544424141,
      },
      {
        lat: 13.092504264775913,
        lng: 80.1818144536266,
      },
      {
        lat: 13.092493160655286,
        lng: 80.17998882736053,
      },
      {
        lat: 13.092895067468099,
        lng: 80.17984632342436,
      },
      {
        lat: 13.093611537635368,
        lng: 80.17959683038572,
      },
      {
        lat: 13.093569747423203,
        lng: 80.17848436410951,
      },
      {
        lat: 13.09260621978934,
        lng: 80.17842296744635,
      },
      {
        lat: 13.09260019183814,
        lng: 80.17743191318766,
      },
      {
        lat: 13.093631859880816,
        lng: 80.17717975254769,
      },
      {
        lat: 13.094295902822395,
        lng: 80.1769480910261,
      },
      {
        lat: 13.094732831621291,
        lng: 80.17680529068979,
      },
      {
        lat: 13.096851767538622,
        lng: 80.17680474539725,
      },
      {
        lat: 13.096895884328271,
        lng: 80.17829972384374,
      },
      {
        lat: 13.097789050729203,
        lng: 80.17830955255671,
      },
      {
        lat: 13.097815868032956,
        lng: 80.17983945303659,
      },
      {
        lat: 13.098049538894317,
        lng: 80.18082858069452,
      },
      {
        lat: 13.098295750332902,
        lng: 80.18100037506156,
      },
      {
        lat: 13.098574376310976,
        lng: 80.18116515008819,
      },
      {
        lat: 13.09867734937826,
        lng: 80.18212853181387,
      },
      {
        lat: 13.099321456381567,
        lng: 80.18424443763882,
      },
      {
        lat: 13.099667640375344,
        lng: 80.18527807999838,
      },
      {
        lat: 13.099672409859641,
        lng: 80.18530102521912,
      },
      {
        lat: 13.099375463697621,
        lng: 80.1853673746359,
      },
      {
        lat: 13.098974753711468,
        lng: 80.18533762502832,
      },
      {
        lat: 13.097989061902828,
        lng: 80.18516369213314,
      },
      {
        lat: 13.097855760660448,
        lng: 80.18519796121628,
      },
      {
        lat: 13.097823392782153,
        lng: 80.1853639397545,
      },
      {
        lat: 13.097514184638912,
        lng: 80.18665905254666,
      },
      {
        lat: 13.09751569637425,
        lng: 80.18690759665314,
      },
      {
        lat: 13.097666693291146,
        lng: 80.1870388824076,
      },
      {
        lat: 13.098785081648026,
        lng: 80.18707912857691,
      },
      {
        lat: 13.098705472123164,
        lng: 80.1877094798148,
      },
      {
        lat: 13.09860806614131,
        lng: 80.18815770660805,
      },
      {
        lat: 13.098593393937527,
        lng: 80.18848923998365,
      },
      {
        lat: 13.09810964782641,
        lng: 80.1885264749726,
      },
      {
        lat: 13.098089533375415,
        lng: 80.18796324956483,
      },
      {
        lat: 13.097972516854453,
        lng: 80.18793109898583,
      },
      {
        lat: 13.097925476772371,
        lng: 80.18842861089958,
      },
      {
        lat: 13.097792175529937,
        lng: 80.1884628799827,
      },
      {
        lat: 13.097524867568637,
        lng: 80.18841543089927,
      },
      {
        lat: 13.096790198999987,
        lng: 80.18835536674962,
      },
      {
        lat: 13.096372398033346,
        lng: 80.18825947994736,
      },
      {
        lat: 13.096105493201463,
        lng: 80.18827830929216,
      },
      {
        lat: 13.096076753488143,
        lng: 80.18904079368589,
      },
      {
        lat: 13.095325900980264,
        lng: 80.18906371880543,
      },
      {
        lat: 13.09528667990177,
        lng: 80.18810296406096,
      },
      {
        lat: 13.095416453761747,
        lng: 80.18748875872933,
      },
      {
        lat: 13.095446705210517,
        lng: 80.18697481844212,
      },
      {
        lat: 13.095561605302,
        lng: 80.18665900727189,
      },
      {
        lat: 13.09454344483276,
        lng: 80.18663448330808,
      },
      {
        lat: 13.094494893015508,
        lng: 80.18688345111529,
      },
      {
        lat: 13.09448334506476,
        lng: 80.18772864231099,
      },
      {
        lat: 13.094132194719414,
        lng: 80.18761562096694,
      },
      {
        lat: 13.092561823435354,
        lng: 80.18734721360576,
      },
      {
        lat: 13.092228066417567,
        lng: 80.18735003827796,
      },
      {
        lat: 13.091878125460495,
        lng: 80.18743585221918,
      },
      {
        lat: 13.091443737425648,
        lng: 80.18735667625762,
      },
      {
        lat: 13.090374505580089,
        lng: 80.18716687992342,
      },
    ],
    properties: {
      Ward_No: 87,
      Zone_Name: "AMBATTUR",
      fillColor: "#de4307",
      zname: "AMBATTUR(87)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.090087533196277,
        lng: 80.17789157854644,
      },
      {
        lat: 13.090835794176042,
        lng: 80.17777851418202,
      },
      {
        lat: 13.09180135538211,
        lng: 80.18157302758645,
      },
      {
        lat: 13.089371575534631,
        lng: 80.18435118661236,
      },
      {
        lat: 13.090043303803391,
        lng: 80.19116318843874,
      },
      {
        lat: 13.088695308241755,
        lng: 80.18969945154802,
      },
      {
        lat: 13.08916051987642,
        lng: 80.1789024920044,
      },
      {
        lat: 13.089724062257487,
        lng: 80.18415200332915,
      },
      {
        lat: 13.08822782381496,
        lng: 80.18498861063937,
      },
      {
        lat: 13.088208539698629,
        lng: 80.19170224799335,
      },
      {
        lat: 13.087805994860242,
        lng: 80.18293214059331,
      },
      {
        lat: 13.089955963857662,
        lng: 80.18052438418536,
      },
      {
        lat: 13.091901967830843,
        lng: 80.18081007571519,
      },
      {
        lat: 13.093149439052812,
        lng: 80.17904830860756,
      },
      {
        lat: 13.089112427222213,
        lng: 80.17844414805342,
      },
      {
        lat: 13.089710475697276,
        lng: 80.18089941515825,
      },
      {
        lat: 13.088838059795888,
        lng: 80.19140035612902,
      },
      {
        lat: 13.089030939681003,
        lng: 80.18672851134797,
      },
      {
        lat: 13.087414807332742,
        lng: 80.18268108900304,
      },
      {
        lat: 13.091741516999003,
        lng: 80.18208791693628,
      },
      {
        lat: 13.091491279508277,
        lng: 80.18240786016777,
      },
      {
        lat: 13.088837643912083,
        lng: 80.18880640664246,
      },
      {
        lat: 13.090785494675496,
        lng: 80.18068817726125,
      },
      {
        lat: 13.090223405737998,
        lng: 80.17810544874075,
      },
      {
        lat: 13.093514701331868,
        lng: 80.1823771955962,
      },
      {
        lat: 13.08786233819955,
        lng: 80.18430007805215,
      },
      {
        lat: 13.090097541632597,
        lng: 80.18925935308687,
      },
      {
        lat: 13.090516514197578,
        lng: 80.18123193225385,
      },
      {
        lat: 13.089089695126122,
        lng: 80.18969170084422,
      },
      {
        lat: 13.090901228829821,
        lng: 80.18054937610695,
      },
      {
        lat: 13.0883603609867,
        lng: 80.18811654255522,
      },
      {
        lat: 13.087554142787758,
        lng: 80.18438633798623,
      },
      {
        lat: 13.09036651943114,
        lng: 80.18066012396856,
      },
      {
        lat: 13.089323152878153,
        lng: 80.18213745766336,
      },
      {
        lat: 13.089875587124316,
        lng: 80.18377261228608,
      },
      {
        lat: 13.089834186026655,
        lng: 80.18990782412122,
      },
      {
        lat: 13.088077876892642,
        lng: 80.18041372428951,
      },
      {
        lat: 13.092343059448986,
        lng: 80.18089222696254,
      },
      {
        lat: 13.08840056945904,
        lng: 80.18930489586548,
      },
      {
        lat: 13.094061730896588,
        lng: 80.19230386190618,
      },
      {
        lat: 13.087754730291026,
        lng: 80.18783810837992,
      },
      {
        lat: 13.090696580099898,
        lng: 80.18151786473933,
      },
      {
        lat: 13.091523384097808,
        lng: 80.17812856383786,
      },
      {
        lat: 13.088231845598843,
        lng: 80.17801992540173,
      },
      {
        lat: 13.088042620595912,
        lng: 80.19015606721605,
      },
      {
        lat: 13.089626092559755,
        lng: 80.19137389981795,
      },
      {
        lat: 13.089412918057782,
        lng: 80.19099300470081,
      },
      {
        lat: 13.092013915760326,
        lng: 80.19110208262774,
      },
      {
        lat: 13.087275608348977,
        lng: 80.18837328629711,
      },
      {
        lat: 13.087705881136028,
        lng: 80.18350138022555,
      },
      {
        lat: 13.09524609001457,
        lng: 80.19241976157906,
      },
    ],
    cases: 51,
    coords: [
      {
        lat: 13.088147317092137,
        lng: 80.17738334698676,
      },
      {
        lat: 13.09260019183814,
        lng: 80.17743191318766,
      },
      {
        lat: 13.09260621978934,
        lng: 80.17842296744635,
      },
      {
        lat: 13.093569747423203,
        lng: 80.17848436410951,
      },
      {
        lat: 13.093611537635368,
        lng: 80.17959683038572,
      },
      {
        lat: 13.092895067468099,
        lng: 80.17984632342436,
      },
      {
        lat: 13.092493160655286,
        lng: 80.17998882736053,
      },
      {
        lat: 13.092504264775913,
        lng: 80.1818144536266,
      },
      {
        lat: 13.092748889911558,
        lng: 80.18172544424141,
      },
      {
        lat: 13.094201244802532,
        lng: 80.1815392744671,
      },
      {
        lat: 13.094344505353286,
        lng: 80.18205969637108,
      },
      {
        lat: 13.094297683064509,
        lng: 80.18299903447937,
      },
      {
        lat: 13.09273931043316,
        lng: 80.183029611203,
      },
      {
        lat: 13.092442469578897,
        lng: 80.18317122593896,
      },
      {
        lat: 13.092249637147933,
        lng: 80.18313808230607,
      },
      {
        lat: 13.092160495879568,
        lng: 80.18287801955412,
      },
      {
        lat: 13.091162263519974,
        lng: 80.18286908004175,
      },
      {
        lat: 13.091151414999226,
        lng: 80.1839646040015,
      },
      {
        lat: 13.091068854038776,
        lng: 80.18408089252316,
      },
      {
        lat: 13.090625463073103,
        lng: 80.18408464505586,
      },
      {
        lat: 13.090534656928188,
        lng: 80.18466619781677,
      },
      {
        lat: 13.090330815500065,
        lng: 80.18527680968786,
      },
      {
        lat: 13.090247790973294,
        lng: 80.18558663944408,
      },
      {
        lat: 13.090248303735,
        lng: 80.1856709424947,
      },
      {
        lat: 13.090324344273016,
        lng: 80.18576397382219,
      },
      {
        lat: 13.090382371712954,
        lng: 80.18599766991635,
      },
      {
        lat: 13.090374505580089,
        lng: 80.18716687992342,
      },
      {
        lat: 13.09034667290781,
        lng: 80.18807849078115,
      },
      {
        lat: 13.090231974381085,
        lng: 80.1884274411654,
      },
      {
        lat: 13.090283247322112,
        lng: 80.1886258527496,
      },
      {
        lat: 13.09045012583106,
        lng: 80.18862444041352,
      },
      {
        lat: 13.091101657492331,
        lng: 80.1887349195526,
      },
      {
        lat: 13.09158610908011,
        lng: 80.18881367181336,
      },
      {
        lat: 13.09159417166858,
        lng: 80.19013924038136,
      },
      {
        lat: 13.090259244379352,
        lng: 80.19016710867729,
      },
      {
        lat: 13.090262570197094,
        lng: 80.1907139057115,
      },
      {
        lat: 13.091064090951697,
        lng: 80.19078997453366,
      },
      {
        lat: 13.093719374108547,
        lng: 80.19108234092464,
      },
      {
        lat: 13.095055611568393,
        lng: 80.1912698775209,
      },
      {
        lat: 13.095273158324071,
        lng: 80.19136745912674,
      },
      {
        lat: 13.095287604765401,
        lng: 80.19257835706894,
      },
      {
        lat: 13.093729569228442,
        lng: 80.19256600675791,
      },
      {
        lat: 13.092792673878199,
        lng: 80.19253166097158,
      },
      {
        lat: 13.092344999198774,
        lng: 80.19242976232204,
      },
      {
        lat: 13.089534698826006,
        lng: 80.19239013416099,
      },
      {
        lat: 13.087659236855394,
        lng: 80.19204666939793,
      },
      {
        lat: 13.087147571919154,
        lng: 80.19192417482726,
      },
      {
        lat: 13.086975859907088,
        lng: 80.19169311571022,
      },
      {
        lat: 13.086973674399983,
        lng: 80.19133379692265,
      },
      {
        lat: 13.087078182174043,
        lng: 80.19101585013695,
      },
      {
        lat: 13.087104172497098,
        lng: 80.18828889431269,
      },
      {
        lat: 13.087229324707435,
        lng: 80.18786508537116,
      },
      {
        lat: 13.087247730035223,
        lng: 80.18389108199044,
      },
      {
        lat: 13.087372753686486,
        lng: 80.18344613664945,
      },
      {
        lat: 13.08731436643475,
        lng: 80.18084671985852,
      },
      {
        lat: 13.087440161441295,
        lng: 80.18052859291333,
      },
      {
        lat: 13.087467051678983,
        lng: 80.17794959188393,
      },
      {
        lat: 13.088084124304196,
        lng: 80.17790209446387,
      },
      {
        lat: 13.08814502913923,
        lng: 80.17741541680246,
      },
      {
        lat: 13.088147317092137,
        lng: 80.17738334698676,
      },
    ],
    properties: {
      Ward_No: 89,
      Zone_Name: "AMBATTUR",
      fillColor: "#8bc24c",
      zname: "AMBATTUR(89)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.94592189022738,
        lng: 80.25202360474422,
      },
      {
        lat: 12.949131315828433,
        lng: 80.25724448468004,
      },
      {
        lat: 12.955237664661235,
        lng: 80.2542861990088,
      },
      {
        lat: 12.948209202667154,
        lng: 80.25831561967452,
      },
      {
        lat: 12.948597955493362,
        lng: 80.25732445895319,
      },
      {
        lat: 12.950457987970266,
        lng: 80.25733295638234,
      },
      {
        lat: 12.952596588507216,
        lng: 80.26092978954044,
      },
      {
        lat: 12.956632290602528,
        lng: 80.24991631067341,
      },
      {
        lat: 12.950210256184972,
        lng: 80.25035605855594,
      },
      {
        lat: 12.945695871952095,
        lng: 80.25578969541344,
      },
      {
        lat: 12.955780927050405,
        lng: 80.25320004798498,
      },
      {
        lat: 12.942549966081605,
        lng: 80.24960278456348,
      },
      {
        lat: 12.949740801575649,
        lng: 80.25357539120895,
      },
      {
        lat: 12.948509732259273,
        lng: 80.25134867911557,
      },
      {
        lat: 12.955296502745426,
        lng: 80.2572053240456,
      },
      {
        lat: 12.94300734646332,
        lng: 80.25191845418887,
      },
      {
        lat: 12.948224269605209,
        lng: 80.25748923832825,
      },
      {
        lat: 12.951125882712123,
        lng: 80.25425861994967,
      },
      {
        lat: 12.95365318074929,
        lng: 80.2515056785326,
      },
      {
        lat: 12.95411144862326,
        lng: 80.25304836856594,
      },
      {
        lat: 12.953493240512133,
        lng: 80.25039195192822,
      },
      {
        lat: 12.947288941013744,
        lng: 80.25423848763589,
      },
      {
        lat: 12.95231787078862,
        lng: 80.26166767046176,
      },
      {
        lat: 12.940921666213624,
        lng: 80.25427216921268,
      },
      {
        lat: 12.940618978880407,
        lng: 80.25746399076824,
      },
      {
        lat: 12.94250916288241,
        lng: 80.25340916200884,
      },
      {
        lat: 12.947271762229548,
        lng: 80.25931174759802,
      },
      {
        lat: 12.953818110684123,
        lng: 80.2593424474686,
      },
      {
        lat: 12.955013365432787,
        lng: 80.25026081013417,
      },
      {
        lat: 12.94164238548044,
        lng: 80.25388794764237,
      },
      {
        lat: 12.952022395945681,
        lng: 80.25295708485625,
      },
      {
        lat: 12.95097877163511,
        lng: 80.25264680167484,
      },
      {
        lat: 12.939730589050052,
        lng: 80.25859020283585,
      },
      {
        lat: 12.9541966450351,
        lng: 80.25939765154307,
      },
      {
        lat: 12.945892271606008,
        lng: 80.25501807835231,
      },
      {
        lat: 12.95517289573921,
        lng: 80.26176186017709,
      },
      {
        lat: 12.94178319391121,
        lng: 80.24998754102599,
      },
      {
        lat: 12.95549188048222,
        lng: 80.25182503374758,
      },
      {
        lat: 12.944295504176457,
        lng: 80.25248114533376,
      },
      {
        lat: 12.948022143145268,
        lng: 80.25141650058411,
      },
      {
        lat: 12.957009015938285,
        lng: 80.25141652721354,
      },
      {
        lat: 12.947053057196502,
        lng: 80.25338668593726,
      },
      {
        lat: 12.954798981285549,
        lng: 80.25479108940708,
      },
      {
        lat: 12.95414716441532,
        lng: 80.25249785304409,
      },
      {
        lat: 12.952580407236935,
        lng: 80.25475999937575,
      },
      {
        lat: 12.944924928082765,
        lng: 80.25977915801889,
      },
      {
        lat: 12.954259996769224,
        lng: 80.25407906553863,
      },
      {
        lat: 12.953951566593528,
        lng: 80.26149766214529,
      },
      {
        lat: 12.947040632069477,
        lng: 80.25315071089133,
      },
      {
        lat: 12.954489495811213,
        lng: 80.25709278627525,
      },
      {
        lat: 12.953617256537566,
        lng: 80.25502786866504,
      },
      {
        lat: 12.946710123362639,
        lng: 80.26033206573396,
      },
      {
        lat: 12.952786741198835,
        lng: 80.26091122926833,
      },
      {
        lat: 12.953190866187827,
        lng: 80.25472002124313,
      },
      {
        lat: 12.95471497557948,
        lng: 80.26005302590163,
      },
      {
        lat: 12.953862735569711,
        lng: 80.2534735253164,
      },
      {
        lat: 12.952507343421436,
        lng: 80.25697135049926,
      },
      {
        lat: 12.947921014713224,
        lng: 80.25662578880299,
      },
      {
        lat: 12.948820820949244,
        lng: 80.2564624260589,
      },
      {
        lat: 12.947662311342174,
        lng: 80.25244795374773,
      },
      {
        lat: 12.951553019144514,
        lng: 80.26155809222914,
      },
      {
        lat: 12.940310494810744,
        lng: 80.25924784074623,
      },
      {
        lat: 12.949728399527434,
        lng: 80.24836513259369,
      },
      {
        lat: 12.955093128518593,
        lng: 80.25296791068686,
      },
      {
        lat: 12.94338265002589,
        lng: 80.25224197127055,
      },
      {
        lat: 12.95402969879916,
        lng: 80.25939152404666,
      },
      {
        lat: 12.946638661061,
        lng: 80.25229748136155,
      },
      {
        lat: 12.952615150123814,
        lng: 80.2548888262435,
      },
      {
        lat: 12.946025123654893,
        lng: 80.25856799989594,
      },
      {
        lat: 12.944947825716492,
        lng: 80.25640262858947,
      },
      {
        lat: 12.95076628536994,
        lng: 80.25183244249823,
      },
      {
        lat: 12.947591661804811,
        lng: 80.25565550785073,
      },
      {
        lat: 12.946715866527217,
        lng: 80.25410003565187,
      },
      {
        lat: 12.940342895753867,
        lng: 80.25799234580604,
      },
      {
        lat: 12.951184976556785,
        lng: 80.25707235756765,
      },
      {
        lat: 12.955132343363656,
        lng: 80.25576583752415,
      },
      {
        lat: 12.955363116261546,
        lng: 80.25913207968875,
      },
      {
        lat: 12.954701216449102,
        lng: 80.25854265739746,
      },
      {
        lat: 12.957424407998795,
        lng: 80.2509000756024,
      },
      {
        lat: 12.945023035118489,
        lng: 80.25912396081081,
      },
      {
        lat: 12.94633769172152,
        lng: 80.25969409882329,
      },
      {
        lat: 12.956448271116924,
        lng: 80.25052981776206,
      },
      {
        lat: 12.943465753791337,
        lng: 80.24930944369207,
      },
      {
        lat: 12.947043783439485,
        lng: 80.25350351558363,
      },
      {
        lat: 12.946910120513486,
        lng: 80.2593427587449,
      },
      {
        lat: 12.946221889756918,
        lng: 80.24947356253593,
      },
      {
        lat: 12.947665788909926,
        lng: 80.25454962945923,
      },
      {
        lat: 12.951116996383035,
        lng: 80.25151737552123,
      },
      {
        lat: 12.945437991841013,
        lng: 80.25806942702047,
      },
      {
        lat: 12.955218197568028,
        lng: 80.25666256627937,
      },
      {
        lat: 12.951980003090371,
        lng: 80.25056053609958,
      },
      {
        lat: 12.948771109923301,
        lng: 80.24909636455331,
      },
      {
        lat: 12.9515534217821,
        lng: 80.25312623507709,
      },
      {
        lat: 12.953466197493661,
        lng: 80.25142820054542,
      },
      {
        lat: 12.946592651346531,
        lng: 80.2527953363258,
      },
      {
        lat: 12.94268106100119,
        lng: 80.25164903392974,
      },
      {
        lat: 12.953042822851879,
        lng: 80.25711937388907,
      },
      {
        lat: 12.95539233504273,
        lng: 80.25676648631084,
      },
      {
        lat: 12.952779036028359,
        lng: 80.25916180734919,
      },
      {
        lat: 12.954653433043736,
        lng: 80.26178853148488,
      },
      {
        lat: 12.944798961391541,
        lng: 80.25034970898231,
      },
      {
        lat: 12.944502825183962,
        lng: 80.24915311695383,
      },
      {
        lat: 12.948576217245508,
        lng: 80.25456253414498,
      },
      {
        lat: 12.952076220484354,
        lng: 80.24936550676286,
      },
      {
        lat: 12.943308176443288,
        lng: 80.2552786557848,
      },
      {
        lat: 12.953556815766866,
        lng: 80.26120770171605,
      },
      {
        lat: 12.947424766182982,
        lng: 80.24933254020432,
      },
      {
        lat: 12.948060611817668,
        lng: 80.25569902706064,
      },
      {
        lat: 12.939133699889679,
        lng: 80.2582352628801,
      },
      {
        lat: 12.954668128498543,
        lng: 80.2621096142278,
      },
      {
        lat: 12.951602083754315,
        lng: 80.25256482580123,
      },
      {
        lat: 12.95007862172686,
        lng: 80.25297951227947,
      },
      {
        lat: 12.95149755903024,
        lng: 80.26022167519847,
      },
      {
        lat: 12.951865083707865,
        lng: 80.256404917027,
      },
      {
        lat: 12.943316908078556,
        lng: 80.25669418249555,
      },
      {
        lat: 12.941394330384732,
        lng: 80.25270547139363,
      },
      {
        lat: 12.946875219050536,
        lng: 80.25470111526563,
      },
      {
        lat: 12.944040712347334,
        lng: 80.25578085373971,
      },
      {
        lat: 12.955337527360275,
        lng: 80.25535955637051,
      },
      {
        lat: 12.94753701320393,
        lng: 80.25542553113202,
      },
      {
        lat: 12.94350227171317,
        lng: 80.25983690884142,
      },
      {
        lat: 12.945520243742916,
        lng: 80.25450289927495,
      },
      {
        lat: 12.94067179743655,
        lng: 80.25222396700308,
      },
      {
        lat: 12.952994122170837,
        lng: 80.26011180894227,
      },
      {
        lat: 12.954338256817184,
        lng: 80.25611906882587,
      },
      {
        lat: 12.953463232585987,
        lng: 80.26000571710289,
      },
      {
        lat: 12.941787892817283,
        lng: 80.25591376520134,
      },
      {
        lat: 12.94540064393308,
        lng: 80.2603092122699,
      },
      {
        lat: 12.949649657047354,
        lng: 80.26014759038137,
      },
      {
        lat: 12.94879552393749,
        lng: 80.25055820371159,
      },
      {
        lat: 12.950452905021187,
        lng: 80.25670309585975,
      },
      {
        lat: 12.956669853909714,
        lng: 80.25619442760636,
      },
      {
        lat: 12.952413688944446,
        lng: 80.24905313518192,
      },
      {
        lat: 12.955602655312665,
        lng: 80.25603977558083,
      },
      {
        lat: 12.948047405811337,
        lng: 80.25046820852789,
      },
      {
        lat: 12.946878037841696,
        lng: 80.25240444644231,
      },
      {
        lat: 12.956274295162311,
        lng: 80.26065972099482,
      },
      {
        lat: 12.952975671005895,
        lng: 80.26020909223719,
      },
      {
        lat: 12.944641253754357,
        lng: 80.25838663952412,
      },
      {
        lat: 12.942643856020082,
        lng: 80.25660046817556,
      },
      {
        lat: 12.944537326364072,
        lng: 80.25878135354019,
      },
      {
        lat: 12.946127046725945,
        lng: 80.25209838572312,
      },
      {
        lat: 12.95492818778832,
        lng: 80.2580782409146,
      },
    ],
    cases: 143,
    coords: [
      {
        lat: 12.938425302143218,
        lng: 80.25924280666788,
      },
      {
        lat: 12.939686700139855,
        lng: 80.25575894890638,
      },
      {
        lat: 12.940310213425175,
        lng: 80.25299868921743,
      },
      {
        lat: 12.940505775772433,
        lng: 80.25006040417509,
      },
      {
        lat: 12.944793494288023,
        lng: 80.24817736943336,
      },
      {
        lat: 12.946102312177022,
        lng: 80.24780299814158,
      },
      {
        lat: 12.947047287379958,
        lng: 80.24776472603585,
      },
      {
        lat: 12.952755729382336,
        lng: 80.24886684636131,
      },
      {
        lat: 12.95731478916806,
        lng: 80.24970184188287,
      },
      {
        lat: 12.957584454234718,
        lng: 80.24996572032782,
      },
      {
        lat: 12.957734627894729,
        lng: 80.25017146323943,
      },
      {
        lat: 12.957777541099455,
        lng: 80.25232995898594,
      },
      {
        lat: 12.957511113637478,
        lng: 80.25259837452103,
      },
      {
        lat: 12.957215442858287,
        lng: 80.2529557577799,
      },
      {
        lat: 12.956955670473123,
        lng: 80.25431833316216,
      },
      {
        lat: 12.956418753603813,
        lng: 80.25908419624366,
      },
      {
        lat: 12.956319767027916,
        lng: 80.26239725591611,
      },
      {
        lat: 12.955514728900381,
        lng: 80.26225620211247,
      },
      {
        lat: 12.951699277758719,
        lng: 80.26175617188437,
      },
      {
        lat: 12.950177471056746,
        lng: 80.26129587676868,
      },
      {
        lat: 12.947731674421059,
        lng: 80.26072510797916,
      },
      {
        lat: 12.945316739905449,
        lng: 80.26033151845603,
      },
      {
        lat: 12.940426765436698,
        lng: 80.25945612786704,
      },
      {
        lat: 12.938425302143218,
        lng: 80.25924280666788,
      },
    ],
    properties: {
      Ward_No: 192,
      Zone_Name: "SOZHINGANALLUR",
      fillColor: "#de4307",
      zname: "SOZHINGANALLUR(192)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.948977273329259,
        lng: 80.24268386884133,
      },
      {
        lat: 12.9327488140968,
        lng: 80.24680504510631,
      },
      {
        lat: 12.949745267157876,
        lng: 80.24653844953234,
      },
      {
        lat: 12.943449059711048,
        lng: 80.24423931344437,
      },
      {
        lat: 12.95252087971576,
        lng: 80.235151518686,
      },
      {
        lat: 12.946914549753597,
        lng: 80.23602131167162,
      },
      {
        lat: 12.945648051561411,
        lng: 80.2346902063632,
      },
      {
        lat: 12.941241100602445,
        lng: 80.2460633870198,
      },
      {
        lat: 12.94753351021784,
        lng: 80.24213757296903,
      },
      {
        lat: 12.946628009006703,
        lng: 80.24034545023405,
      },
      {
        lat: 12.944260312368945,
        lng: 80.24196697019669,
      },
      {
        lat: 12.951868386168956,
        lng: 80.23488165450459,
      },
      {
        lat: 12.946276290825447,
        lng: 80.23841046758388,
      },
      {
        lat: 12.951379730357678,
        lng: 80.23686166663543,
      },
      {
        lat: 12.946200614144102,
        lng: 80.23411712040914,
      },
      {
        lat: 12.945251968615102,
        lng: 80.24540904836594,
      },
      {
        lat: 12.943603775872049,
        lng: 80.2485862113567,
      },
      {
        lat: 12.934725753137531,
        lng: 80.24764913051396,
      },
      {
        lat: 12.937050031477018,
        lng: 80.24656329847319,
      },
      {
        lat: 12.9504114329274,
        lng: 80.23946280321509,
      },
      {
        lat: 12.94334458498259,
        lng: 80.24568884408131,
      },
      {
        lat: 12.938029832462922,
        lng: 80.24545581492303,
      },
      {
        lat: 12.950844214356714,
        lng: 80.24279990693316,
      },
      {
        lat: 12.933276374813975,
        lng: 80.24524992357632,
      },
      {
        lat: 12.949482952908653,
        lng: 80.24055815288436,
      },
      {
        lat: 12.945881402549828,
        lng: 80.23329398249753,
      },
    ],
    cases: 26,
    coords: [
      {
        lat: 12.940505775772433,
        lng: 80.25006040417509,
      },
      {
        lat: 12.939359637914745,
        lng: 80.24979456511959,
      },
      {
        lat: 12.937746979000853,
        lng: 80.24957293497899,
      },
      {
        lat: 12.930542924086122,
        lng: 80.24558711471528,
      },
      {
        lat: 12.930586878802194,
        lng: 80.24502207433635,
      },
      {
        lat: 12.930798698583493,
        lng: 80.24478500315934,
      },
      {
        lat: 12.932742794701484,
        lng: 80.24495677256276,
      },
      {
        lat: 12.934807080632439,
        lng: 80.24540985895804,
      },
      {
        lat: 12.937768610500804,
        lng: 80.24533773906455,
      },
      {
        lat: 12.938626283148311,
        lng: 80.24517651675207,
      },
      {
        lat: 12.9403665666602,
        lng: 80.24512578288733,
      },
      {
        lat: 12.941073098333696,
        lng: 80.24502978984125,
      },
      {
        lat: 12.941923686442491,
        lng: 80.24477055333476,
      },
      {
        lat: 12.942247840795154,
        lng: 80.24440775599142,
      },
      {
        lat: 12.94253463974614,
        lng: 80.24386524782595,
      },
      {
        lat: 12.942830043015071,
        lng: 80.24175643219655,
      },
      {
        lat: 12.943092580107352,
        lng: 80.24020597833056,
      },
      {
        lat: 12.943536638529793,
        lng: 80.23868999359664,
      },
      {
        lat: 12.943928605608258,
        lng: 80.2375525063632,
      },
      {
        lat: 12.943944108003658,
        lng: 80.23712031043318,
      },
      {
        lat: 12.943539001730098,
        lng: 80.23609758522093,
      },
      {
        lat: 12.94329673908589,
        lng: 80.23501947372927,
      },
      {
        lat: 12.943039521512791,
        lng: 80.23446356701982,
      },
      {
        lat: 12.942254775250653,
        lng: 80.23362408176953,
      },
      {
        lat: 12.942234564643751,
        lng: 80.23328220157303,
      },
      {
        lat: 12.942444035006437,
        lng: 80.23222187962547,
      },
      {
        lat: 12.942524969506739,
        lng: 80.23226040640438,
      },
      {
        lat: 12.943421334925121,
        lng: 80.23272599479198,
      },
      {
        lat: 12.945537720790618,
        lng: 80.23300381736978,
      },
      {
        lat: 12.948487488766405,
        lng: 80.23318586658873,
      },
      {
        lat: 12.952629113073169,
        lng: 80.23344654904841,
      },
      {
        lat: 12.953044994726996,
        lng: 80.23326558887393,
      },
      {
        lat: 12.953282538605352,
        lng: 80.23314528483841,
      },
      {
        lat: 12.953589001401081,
        lng: 80.2345622148456,
      },
      {
        lat: 12.953568571563494,
        lng: 80.2361002050702,
      },
      {
        lat: 12.953317073057717,
        lng: 80.23882308728999,
      },
      {
        lat: 12.952523186680603,
        lng: 80.24051549052814,
      },
      {
        lat: 12.952054214399832,
        lng: 80.2417615427833,
      },
      {
        lat: 12.951906199143155,
        lng: 80.2419106625249,
      },
      {
        lat: 12.951668295531091,
        lng: 80.24197182278503,
      },
      {
        lat: 12.951460174837125,
        lng: 80.24203273098458,
      },
      {
        lat: 12.95125313334485,
        lng: 80.24227107051048,
      },
      {
        lat: 12.950815742651969,
        lng: 80.24381258958293,
      },
      {
        lat: 12.94978826946916,
        lng: 80.24627587838746,
      },
      {
        lat: 12.94976028522012,
        lng: 80.24657184932568,
      },
      {
        lat: 12.949910099146084,
        lng: 80.24671844846166,
      },
      {
        lat: 12.950356663052874,
        lng: 80.24668509566551,
      },
      {
        lat: 12.952675773604104,
        lng: 80.24601485341086,
      },
      {
        lat: 12.955444865534034,
        lng: 80.24587312422771,
      },
      {
        lat: 12.958721526143018,
        lng: 80.24593411322924,
      },
      {
        lat: 12.95731478916806,
        lng: 80.24970184188287,
      },
      {
        lat: 12.952755729382336,
        lng: 80.24886684636131,
      },
      {
        lat: 12.947047287379958,
        lng: 80.24776472603585,
      },
      {
        lat: 12.946102312177022,
        lng: 80.24780299814158,
      },
      {
        lat: 12.944793494288023,
        lng: 80.24817736943336,
      },
      {
        lat: 12.940505775772433,
        lng: 80.25006040417509,
      },
    ],
    properties: {
      Ward_No: 193,
      Zone_Name: "SOZHINGANALLUR",
      fillColor: "#8bc24c",
      zname: "SOZHINGANALLUR(193)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.932811943423786,
        lng: 80.23147261810986,
      },
      {
        lat: 12.932945089436183,
        lng: 80.23516459452564,
      },
      {
        lat: 12.936762906701544,
        lng: 80.23971903029735,
      },
      {
        lat: 12.936855637310224,
        lng: 80.24153526076395,
      },
      {
        lat: 12.934482763335424,
        lng: 80.23091103623419,
      },
      {
        lat: 12.935057001089772,
        lng: 80.23159663098735,
      },
      {
        lat: 12.938749530460278,
        lng: 80.23219215289808,
      },
      {
        lat: 12.938400237215268,
        lng: 80.23598096742813,
      },
      {
        lat: 12.942311520970671,
        lng: 80.23637877273869,
      },
      {
        lat: 12.9368432784109,
        lng: 80.23729130003694,
      },
      {
        lat: 12.939469897184127,
        lng: 80.23648481758786,
      },
      {
        lat: 12.94089266363493,
        lng: 80.2329398880501,
      },
      {
        lat: 12.931283862201665,
        lng: 80.23516382474341,
      },
      {
        lat: 12.933480310082272,
        lng: 80.23253886189529,
      },
      {
        lat: 12.93429713960877,
        lng: 80.23029718677977,
      },
      {
        lat: 12.942699010634769,
        lng: 80.23543404402666,
      },
      {
        lat: 12.932640277369805,
        lng: 80.24097054495448,
      },
      {
        lat: 12.937797039614857,
        lng: 80.23028877569656,
      },
      {
        lat: 12.934893553472472,
        lng: 80.22965811723995,
      },
      {
        lat: 12.935114418776376,
        lng: 80.24229740240804,
      },
      {
        lat: 12.94198805920353,
        lng: 80.24182717976957,
      },
      {
        lat: 12.934485450439972,
        lng: 80.24092699164825,
      },
      {
        lat: 12.941739836279954,
        lng: 80.23432309993593,
      },
      {
        lat: 12.933583043726996,
        lng: 80.23212005308004,
      },
      {
        lat: 12.933941219021126,
        lng: 80.2380742496546,
      },
      {
        lat: 12.941541398418607,
        lng: 80.23604527662575,
      },
      {
        lat: 12.94169975419985,
        lng: 80.23520214916539,
      },
      {
        lat: 12.934255758870286,
        lng: 80.23159140333364,
      },
      {
        lat: 12.932991889332856,
        lng: 80.24421043954732,
      },
      {
        lat: 12.932463762575855,
        lng: 80.24239686808981,
      },
      {
        lat: 12.940576152998503,
        lng: 80.24183418473746,
      },
      {
        lat: 12.936312222608253,
        lng: 80.24402943514181,
      },
      {
        lat: 12.939786145287819,
        lng: 80.23945415662136,
      },
      {
        lat: 12.932799728563273,
        lng: 80.23834500863256,
      },
      {
        lat: 12.934875003304878,
        lng: 80.23966893291622,
      },
      {
        lat: 12.933333925042326,
        lng: 80.24180616108092,
      },
      {
        lat: 12.942320358456621,
        lng: 80.24277543711932,
      },
      {
        lat: 12.933150026570454,
        lng: 80.24044638873049,
      },
      {
        lat: 12.933928967011731,
        lng: 80.23117405246552,
      },
      {
        lat: 12.930501706175326,
        lng: 80.2444164043045,
      },
      {
        lat: 12.935854546242657,
        lng: 80.23346448665951,
      },
      {
        lat: 12.938430477012902,
        lng: 80.24430081787366,
      },
      {
        lat: 12.931771904282597,
        lng: 80.23419929949114,
      },
      {
        lat: 12.938293631778045,
        lng: 80.23567329079926,
      },
      {
        lat: 12.939942148953062,
        lng: 80.24486155165059,
      },
      {
        lat: 12.938088874974072,
        lng: 80.23852428399975,
      },
      {
        lat: 12.941419917331023,
        lng: 80.24037396251883,
      },
      {
        lat: 12.934106884625201,
        lng: 80.24451330569883,
      },
      {
        lat: 12.94192920841667,
        lng: 80.24319402590314,
      },
      {
        lat: 12.94019749129465,
        lng: 80.23418478773524,
      },
      {
        lat: 12.937667710902874,
        lng: 80.24014130784408,
      },
      {
        lat: 12.931207566519909,
        lng: 80.23404948818313,
      },
      {
        lat: 12.939972538604785,
        lng: 80.2397343467086,
      },
      {
        lat: 12.941306856370172,
        lng: 80.24082225216124,
      },
      {
        lat: 12.933130555493733,
        lng: 80.23547021742907,
      },
      {
        lat: 12.942708406543762,
        lng: 80.23872290667103,
      },
      {
        lat: 12.940588478473638,
        lng: 80.23415939270834,
      },
      {
        lat: 12.935666460041588,
        lng: 80.24389228691396,
      },
      {
        lat: 12.93934379919691,
        lng: 80.24250959531211,
      },
      {
        lat: 12.940303131332982,
        lng: 80.23365441741494,
      },
      {
        lat: 12.933811579591346,
        lng: 80.23700600786928,
      },
      {
        lat: 12.935553842879816,
        lng: 80.24344780712013,
      },
      {
        lat: 12.930507509178312,
        lng: 80.24469490176864,
      },
      {
        lat: 12.938498927158124,
        lng: 80.2332476698633,
      },
      {
        lat: 12.930968344557451,
        lng: 80.23560772687435,
      },
      {
        lat: 12.93852713304662,
        lng: 80.24317214814411,
      },
      {
        lat: 12.93807252335238,
        lng: 80.2308010580886,
      },
      {
        lat: 12.933187949713322,
        lng: 80.23456243836453,
      },
      {
        lat: 12.940595235003,
        lng: 80.23371503265741,
      },
      {
        lat: 12.933631359053384,
        lng: 80.24461995553442,
      },
      {
        lat: 12.938627952463577,
        lng: 80.24166949439999,
      },
      {
        lat: 12.936821521871797,
        lng: 80.23726988090914,
      },
      {
        lat: 12.934438024906788,
        lng: 80.24300817223906,
      },
    ],
    cases: 73,
    coords: [
      {
        lat: 12.930542924086122,
        lng: 80.24558711471528,
      },
      {
        lat: 12.928032677603847,
        lng: 80.24424779836288,
      },
      {
        lat: 12.928373677739192,
        lng: 80.24373236724637,
      },
      {
        lat: 12.928732818944951,
        lng: 80.24337054614041,
      },
      {
        lat: 12.929264434524217,
        lng: 80.24307560469165,
      },
      {
        lat: 12.931008419023156,
        lng: 80.24124985205695,
      },
      {
        lat: 12.93159082093471,
        lng: 80.24081780208522,
      },
      {
        lat: 12.93181252297808,
        lng: 80.24049131383887,
      },
      {
        lat: 12.931965609351112,
        lng: 80.240199575981,
      },
      {
        lat: 12.932063025582725,
        lng: 80.2392420005869,
      },
      {
        lat: 12.932041767187508,
        lng: 80.2385758718151,
      },
      {
        lat: 12.931710491345333,
        lng: 80.23786111228713,
      },
      {
        lat: 12.930413192755037,
        lng: 80.2367444923448,
      },
      {
        lat: 12.930205163616565,
        lng: 80.23648998037756,
      },
      {
        lat: 12.93020339713247,
        lng: 80.23619955307944,
      },
      {
        lat: 12.930303826778118,
        lng: 80.23573741248782,
      },
      {
        lat: 12.930928390423363,
        lng: 80.23375028542073,
      },
      {
        lat: 12.931275270151273,
        lng: 80.23137255718741,
      },
      {
        lat: 12.931734529270367,
        lng: 80.23049734361383,
      },
      {
        lat: 12.932214423319703,
        lng: 80.2301857550597,
      },
      {
        lat: 12.932487119517031,
        lng: 80.22975632620788,
      },
      {
        lat: 12.933157050552923,
        lng: 80.2292575323507,
      },
      {
        lat: 12.936701292289383,
        lng: 80.22967766020228,
      },
      {
        lat: 12.938221480189075,
        lng: 80.22987180832814,
      },
      {
        lat: 12.939655377071718,
        lng: 80.23056943472764,
      },
      {
        lat: 12.940163665218366,
        lng: 80.23089044046358,
      },
      {
        lat: 12.940433330285028,
        lng: 80.23115431890842,
      },
      {
        lat: 12.941121035408958,
        lng: 80.231592099832,
      },
      {
        lat: 12.942444035006437,
        lng: 80.23222187962547,
      },
      {
        lat: 12.942234564643751,
        lng: 80.23328220157303,
      },
      {
        lat: 12.942254775250653,
        lng: 80.23362408176953,
      },
      {
        lat: 12.943039521512791,
        lng: 80.23446356701982,
      },
      {
        lat: 12.94329673908589,
        lng: 80.23501947372927,
      },
      {
        lat: 12.943539001730098,
        lng: 80.23609758522093,
      },
      {
        lat: 12.943944108003658,
        lng: 80.23712031043318,
      },
      {
        lat: 12.943928605608258,
        lng: 80.2375525063632,
      },
      {
        lat: 12.943536638529793,
        lng: 80.23868999359664,
      },
      {
        lat: 12.943092580107352,
        lng: 80.24020597833056,
      },
      {
        lat: 12.942830043015071,
        lng: 80.24175643219655,
      },
      {
        lat: 12.94253463974614,
        lng: 80.24386524782595,
      },
      {
        lat: 12.942247840795154,
        lng: 80.24440775599142,
      },
      {
        lat: 12.941923686442491,
        lng: 80.24477055333476,
      },
      {
        lat: 12.941073098333696,
        lng: 80.24502978984125,
      },
      {
        lat: 12.9403665666602,
        lng: 80.24512578288733,
      },
      {
        lat: 12.938626283148311,
        lng: 80.24517651675207,
      },
      {
        lat: 12.937768610500804,
        lng: 80.24533773906455,
      },
      {
        lat: 12.934807080632439,
        lng: 80.24540985895804,
      },
      {
        lat: 12.932742794701484,
        lng: 80.24495677256276,
      },
      {
        lat: 12.930798698583493,
        lng: 80.24478500315934,
      },
      {
        lat: 12.930586878802194,
        lng: 80.24502207433635,
      },
      {
        lat: 12.930542924086122,
        lng: 80.24558711471528,
      },
    ],
    properties: {
      Ward_No: 194,
      Zone_Name: "SOZHINGANALLUR",
      fillColor: "#f6d04d",
      zname: "SOZHINGANALLUR(194)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.926163795218438,
        lng: 80.22777276570919,
      },
      {
        lat: 12.928495636043237,
        lng: 80.2324043418477,
      },
      {
        lat: 12.924450704082426,
        lng: 80.23518845604885,
      },
      {
        lat: 12.926352425212475,
        lng: 80.24115645036488,
      },
      {
        lat: 12.929498335724436,
        lng: 80.22985196571075,
      },
      {
        lat: 12.923080536345266,
        lng: 80.23188846993888,
      },
      {
        lat: 12.925036659680039,
        lng: 80.23789282691754,
      },
      {
        lat: 12.930854262701244,
        lng: 80.23759982773765,
      },
      {
        lat: 12.92110428304095,
        lng: 80.23211126649268,
      },
      {
        lat: 12.92036362876281,
        lng: 80.23628888808612,
      },
      {
        lat: 12.930561544718623,
        lng: 80.23844607194142,
      },
      {
        lat: 12.9276433980492,
        lng: 80.23658517305175,
      },
      {
        lat: 12.925802126947566,
        lng: 80.22848152847108,
      },
    ],
    cases: 13,
    coords: [
      {
        lat: 12.928032677603847,
        lng: 80.24424779836288,
      },
      {
        lat: 12.923590192719999,
        lng: 80.24178586584725,
      },
      {
        lat: 12.923410713166728,
        lng: 80.23990441689563,
      },
      {
        lat: 12.921918412771907,
        lng: 80.2392496655811,
      },
      {
        lat: 12.920521403118704,
        lng: 80.23847493261052,
      },
      {
        lat: 12.919941540580018,
        lng: 80.23786012917208,
      },
      {
        lat: 12.919575538588033,
        lng: 80.23688599021938,
      },
      {
        lat: 12.919429050811678,
        lng: 80.23648203435224,
      },
      {
        lat: 12.919396928830173,
        lng: 80.2351475441293,
      },
      {
        lat: 12.919488885356678,
        lng: 80.23447938483744,
      },
      {
        lat: 12.91967700242015,
        lng: 80.23383424675144,
      },
      {
        lat: 12.920603586489744,
        lng: 80.23225329237924,
      },
      {
        lat: 12.920985329319777,
        lng: 80.23186870099646,
      },
      {
        lat: 12.921993782747009,
        lng: 80.23190783626782,
      },
      {
        lat: 12.92242512798707,
        lng: 80.2317850104955,
      },
      {
        lat: 12.923656949266453,
        lng: 80.22908122607636,
      },
      {
        lat: 12.923778705874925,
        lng: 80.2278647557503,
      },
      {
        lat: 12.923865176758797,
        lng: 80.22739084936443,
      },
      {
        lat: 12.924132683422181,
        lng: 80.22729986515596,
      },
      {
        lat: 12.928217979497441,
        lng: 80.22809334571681,
      },
      {
        lat: 12.932975549636623,
        lng: 80.22923601756818,
      },
      {
        lat: 12.933157050552923,
        lng: 80.2292575323507,
      },
      {
        lat: 12.932487119517031,
        lng: 80.22975632620788,
      },
      {
        lat: 12.932214423319703,
        lng: 80.2301857550597,
      },
      {
        lat: 12.931734529270367,
        lng: 80.23049734361383,
      },
      {
        lat: 12.931275270151273,
        lng: 80.23137255718741,
      },
      {
        lat: 12.930928390423363,
        lng: 80.23375028542073,
      },
      {
        lat: 12.930303826778118,
        lng: 80.23573741248782,
      },
      {
        lat: 12.93020339713247,
        lng: 80.23619955307944,
      },
      {
        lat: 12.930205163616565,
        lng: 80.23648998037756,
      },
      {
        lat: 12.930413192755037,
        lng: 80.2367444923448,
      },
      {
        lat: 12.931710491345333,
        lng: 80.23786111228713,
      },
      {
        lat: 12.932041767187508,
        lng: 80.2385758718151,
      },
      {
        lat: 12.932063025582725,
        lng: 80.2392420005869,
      },
      {
        lat: 12.931965609351112,
        lng: 80.240199575981,
      },
      {
        lat: 12.93181252297808,
        lng: 80.24049131383887,
      },
      {
        lat: 12.93159082093471,
        lng: 80.24081780208522,
      },
      {
        lat: 12.931008419023156,
        lng: 80.24124985205695,
      },
      {
        lat: 12.929264434524217,
        lng: 80.24307560469165,
      },
      {
        lat: 12.928732818944951,
        lng: 80.24337054614041,
      },
      {
        lat: 12.928373677739192,
        lng: 80.24373236724637,
      },
      {
        lat: 12.928032677603847,
        lng: 80.24424779836288,
      },
    ],
    properties: {
      Ward_No: 195,
      Zone_Name: "SOZHINGANALLUR",
      fillColor: "#8bc24c",
      zname: "SOZHINGANALLUR(195)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.937063058574362,
        lng: 80.25393371340363,
      },
      {
        lat: 12.910432992781264,
        lng: 80.25115347696087,
      },
      {
        lat: 12.908439782145251,
        lng: 80.24385738054202,
      },
      {
        lat: 12.924555054189748,
        lng: 80.24772132333057,
      },
      {
        lat: 12.937709570566156,
        lng: 80.25686489714573,
      },
      {
        lat: 12.908202175768041,
        lng: 80.25467932944143,
      },
      {
        lat: 12.922046723849416,
        lng: 80.24962525412447,
      },
      {
        lat: 12.937519456134446,
        lng: 80.25343680577765,
      },
      {
        lat: 12.921907237190974,
        lng: 80.25563405733052,
      },
      {
        lat: 12.905545422389135,
        lng: 80.24667501882594,
      },
      {
        lat: 12.919384553167626,
        lng: 80.2455974945324,
      },
      {
        lat: 12.930610644853795,
        lng: 80.25244305762207,
      },
      {
        lat: 12.92008252474823,
        lng: 80.24645305740378,
      },
      {
        lat: 12.920325809013434,
        lng: 80.25073961883804,
      },
      {
        lat: 12.93027316390346,
        lng: 80.2516893706281,
      },
      {
        lat: 12.93384045559939,
        lng: 80.25614199359458,
      },
      {
        lat: 12.939745015521776,
        lng: 80.2507576681484,
      },
      {
        lat: 12.9180422346246,
        lng: 80.25555487300181,
      },
      {
        lat: 12.927016253240396,
        lng: 80.24950368423012,
      },
      {
        lat: 12.925631693734344,
        lng: 80.2524395311131,
      },
      {
        lat: 12.909404702550425,
        lng: 80.25395739801579,
      },
      {
        lat: 12.933670024062103,
        lng: 80.24836436487344,
      },
      {
        lat: 12.909553348414374,
        lng: 80.25208958571794,
      },
      {
        lat: 12.921618361282405,
        lng: 80.24663171238483,
      },
      {
        lat: 12.905618600389921,
        lng: 80.24945668005108,
      },
      {
        lat: 12.933342044003904,
        lng: 80.24913427685034,
      },
      {
        lat: 12.937689028130414,
        lng: 80.24959101595103,
      },
      {
        lat: 12.931502165482271,
        lng: 80.2478991133038,
      },
      {
        lat: 12.913290202964776,
        lng: 80.25496543093858,
      },
      {
        lat: 12.934909287559224,
        lng: 80.25286632211228,
      },
      {
        lat: 12.92246767399012,
        lng: 80.25530113250534,
      },
      {
        lat: 12.932712609301301,
        lng: 80.2545438971844,
      },
      {
        lat: 12.9376068456022,
        lng: 80.25553638218294,
      },
      {
        lat: 12.930931705439987,
        lng: 80.25368751298797,
      },
      {
        lat: 12.933643281247074,
        lng: 80.25453604515738,
      },
      {
        lat: 12.928863042352093,
        lng: 80.25427701762145,
      },
      {
        lat: 12.9377836332696,
        lng: 80.25476427036698,
      },
      {
        lat: 12.90613552194134,
        lng: 80.25427542044442,
      },
      {
        lat: 12.91061399781844,
        lng: 80.25452078409164,
      },
      {
        lat: 12.914650330174164,
        lng: 80.24514266221686,
      },
      {
        lat: 12.921867151263676,
        lng: 80.24430296522215,
      },
      {
        lat: 12.910018214359445,
        lng: 80.25242794768432,
      },
      {
        lat: 12.932425011471224,
        lng: 80.24789412463805,
      },
      {
        lat: 12.910675453368615,
        lng: 80.24368649893798,
      },
      {
        lat: 12.911762266926283,
        lng: 80.2537786959106,
      },
      {
        lat: 12.924857444281026,
        lng: 80.24543396002494,
      },
      {
        lat: 12.921496562512166,
        lng: 80.25449734288253,
      },
      {
        lat: 12.910686525914421,
        lng: 80.24359950500478,
      },
      {
        lat: 12.932429078181684,
        lng: 80.24674216890595,
      },
      {
        lat: 12.906078943848911,
        lng: 80.25457514860696,
      },
      {
        lat: 12.928436586680505,
        lng: 80.25145936208838,
      },
      {
        lat: 12.939083789436628,
        lng: 80.25498438210842,
      },
      {
        lat: 12.917370172628107,
        lng: 80.24418896440945,
      },
      {
        lat: 12.907647850229107,
        lng: 80.25227401570669,
      },
      {
        lat: 12.937739155037741,
        lng: 80.25729714269234,
      },
      {
        lat: 12.917337964965531,
        lng: 80.25469868374779,
      },
      {
        lat: 12.925631914952108,
        lng: 80.25552284362207,
      },
      {
        lat: 12.91399116626484,
        lng: 80.25128654676055,
      },
      {
        lat: 12.910375400466558,
        lng: 80.24748437110813,
      },
      {
        lat: 12.916889581749997,
        lng: 80.24768847805709,
      },
      {
        lat: 12.93175713348299,
        lng: 80.25005818789403,
      },
      {
        lat: 12.916333379964454,
        lng: 80.24445226378565,
      },
      {
        lat: 12.925243508843904,
        lng: 80.2517330623306,
      },
      {
        lat: 12.925238746327308,
        lng: 80.24622950147977,
      },
      {
        lat: 12.928819107215531,
        lng: 80.25468882271059,
      },
      {
        lat: 12.921841931449599,
        lng: 80.24256080889322,
      },
      {
        lat: 12.934482997076865,
        lng: 80.25215280326827,
      },
      {
        lat: 12.909391295497487,
        lng: 80.25027767295228,
      },
      {
        lat: 12.922410493220985,
        lng: 80.24343140781113,
      },
      {
        lat: 12.914454193267124,
        lng: 80.2527198776462,
      },
      {
        lat: 12.909186318657829,
        lng: 80.24628648126506,
      },
      {
        lat: 12.922810295526887,
        lng: 80.25030186579004,
      },
      {
        lat: 12.92668702305135,
        lng: 80.24455581587367,
      },
      {
        lat: 12.924641662043705,
        lng: 80.25372070442326,
      },
      {
        lat: 12.928560098037272,
        lng: 80.25755774265818,
      },
      {
        lat: 12.906218087738884,
        lng: 80.24758541216966,
      },
      {
        lat: 12.92635775005467,
        lng: 80.24434953332478,
      },
      {
        lat: 12.91300456840092,
        lng: 80.25203696745069,
      },
      {
        lat: 12.906963922532135,
        lng: 80.24451071886293,
      },
      {
        lat: 12.928742781934952,
        lng: 80.24585488267898,
      },
    ],
    cases: 80,
    coords: [
      {
        lat: 12.904216680871004,
        lng: 80.25456442950905,
      },
      {
        lat: 12.904564742857497,
        lng: 80.25357061768206,
      },
      {
        lat: 12.904814268902193,
        lng: 80.25213242146229,
      },
      {
        lat: 12.905312944020466,
        lng: 80.2449477789647,
      },
      {
        lat: 12.90644258297375,
        lng: 80.24383551086052,
      },
      {
        lat: 12.906467005279266,
        lng: 80.24360450488709,
      },
      {
        lat: 12.907571405868195,
        lng: 80.24258907468399,
      },
      {
        lat: 12.911945672743355,
        lng: 80.24353478281442,
      },
      {
        lat: 12.913311381638795,
        lng: 80.2436248860539,
      },
      {
        lat: 12.913857417872629,
        lng: 80.24362026480767,
      },
      {
        lat: 12.914197660000927,
        lng: 80.24344794927022,
      },
      {
        lat: 12.917063938021359,
        lng: 80.24335591682377,
      },
      {
        lat: 12.918633998297329,
        lng: 80.2433765161925,
      },
      {
        lat: 12.920164984822142,
        lng: 80.24258415354747,
      },
      {
        lat: 12.923572828538068,
        lng: 80.24160384053302,
      },
      {
        lat: 12.923590192719999,
        lng: 80.24178586584725,
      },
      {
        lat: 12.928032677603847,
        lng: 80.24424779836288,
      },
      {
        lat: 12.930542924086122,
        lng: 80.24558711471528,
      },
      {
        lat: 12.937746979000853,
        lng: 80.24957293497899,
      },
      {
        lat: 12.939359637914745,
        lng: 80.24979456511959,
      },
      {
        lat: 12.940505775772433,
        lng: 80.25006040417509,
      },
      {
        lat: 12.940310213425175,
        lng: 80.25299868921743,
      },
      {
        lat: 12.939686700139855,
        lng: 80.25575894890638,
      },
      {
        lat: 12.938425302143218,
        lng: 80.25924280666788,
      },
      {
        lat: 12.938370305141488,
        lng: 80.2592369449435,
      },
      {
        lat: 12.932139379883573,
        lng: 80.25825460952889,
      },
      {
        lat: 12.93178234459823,
        lng: 80.25831677803109,
      },
      {
        lat: 12.930978385672498,
        lng: 80.25835315555396,
      },
      {
        lat: 12.925255208960065,
        lng: 80.25760311021195,
      },
      {
        lat: 12.923554884615678,
        lng: 80.25717389934745,
      },
      {
        lat: 12.923018432353047,
        lng: 80.25711929266195,
      },
      {
        lat: 12.922244256345467,
        lng: 80.25715541812431,
      },
      {
        lat: 12.920842660518238,
        lng: 80.25687154609288,
      },
      {
        lat: 12.919144314710012,
        lng: 80.25676762599375,
      },
      {
        lat: 12.916877035716915,
        lng: 80.25616577295332,
      },
      {
        lat: 12.913835580716235,
        lng: 80.25560004537509,
      },
      {
        lat: 12.909155885344392,
        lng: 80.25501860923998,
      },
      {
        lat: 12.904216680871004,
        lng: 80.25456442950905,
      },
    ],
    properties: {
      Ward_No: 196,
      Zone_Name: "SOZHINGANALLUR",
      fillColor: "#f6d04d",
      zname: "SOZHINGANALLUR(196)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.892048988182529,
        lng: 80.23835894429503,
      },
      {
        lat: 12.910379919377377,
        lng: 80.23608144363337,
      },
      {
        lat: 12.902568167443818,
        lng: 80.23660062211621,
      },
      {
        lat: 12.8981885859984,
        lng: 80.21753982507293,
      },
      {
        lat: 12.89996104540127,
        lng: 80.23632548033835,
      },
      {
        lat: 12.907069539455975,
        lng: 80.23125982728396,
      },
      {
        lat: 12.919667399310748,
        lng: 80.23300454576449,
      },
      {
        lat: 12.896626361039052,
        lng: 80.2309576614277,
      },
      {
        lat: 12.911862696425143,
        lng: 80.24193151181125,
      },
      {
        lat: 12.921870818603967,
        lng: 80.2300153424075,
      },
      {
        lat: 12.904978734027637,
        lng: 80.2144081382531,
      },
      {
        lat: 12.901586176405628,
        lng: 80.22524408552465,
      },
      {
        lat: 12.905780866615277,
        lng: 80.22100173558216,
      },
      {
        lat: 12.91061765128356,
        lng: 80.21973488402094,
      },
      {
        lat: 12.897660192529548,
        lng: 80.22397266110697,
      },
      {
        lat: 12.8946959062138,
        lng: 80.21348047917819,
      },
      {
        lat: 12.914246561934434,
        lng: 80.23219104169674,
      },
      {
        lat: 12.908744693605373,
        lng: 80.23823411239289,
      },
      {
        lat: 12.9020347436297,
        lng: 80.23346971670253,
      },
      {
        lat: 12.916826719365437,
        lng: 80.22995481046377,
      },
      {
        lat: 12.898676076456248,
        lng: 80.23278905337527,
      },
      {
        lat: 12.897968499048549,
        lng: 80.22557736687928,
      },
      {
        lat: 12.894047443798774,
        lng: 80.2309027025136,
      },
      {
        lat: 12.891341828429415,
        lng: 80.22639505711923,
      },
      {
        lat: 12.900842159279755,
        lng: 80.21584717633655,
      },
    ],
    cases: 25,
    coords: [
      {
        lat: 12.907571405868195,
        lng: 80.24258907468399,
      },
      {
        lat: 12.907925718335857,
        lng: 80.24061568115724,
      },
      {
        lat: 12.900172582732315,
        lng: 80.23917682856613,
      },
      {
        lat: 12.896297794147255,
        lng: 80.23874992292693,
      },
      {
        lat: 12.89507930810802,
        lng: 80.23909456182112,
      },
      {
        lat: 12.89158889323861,
        lng: 80.23958380108705,
      },
      {
        lat: 12.89125168987581,
        lng: 80.23950307329389,
      },
      {
        lat: 12.890281043994408,
        lng: 80.23215610444521,
      },
      {
        lat: 12.889812496524813,
        lng: 80.23124067192441,
      },
      {
        lat: 12.889722985121185,
        lng: 80.2303638223395,
      },
      {
        lat: 12.889340136448325,
        lng: 80.22969840942548,
      },
      {
        lat: 12.889374598111013,
        lng: 80.2284443932771,
      },
      {
        lat: 12.889704176259414,
        lng: 80.2272714611143,
      },
      {
        lat: 12.891596416789724,
        lng: 80.21322685967105,
      },
      {
        lat: 12.89692020657535,
        lng: 80.21326189178265,
      },
      {
        lat: 12.898910860314313,
        lng: 80.21343191805579,
      },
      {
        lat: 12.904800866487085,
        lng: 80.21372912054832,
      },
      {
        lat: 12.909308839029675,
        lng: 80.2140581418619,
      },
      {
        lat: 12.912604385667134,
        lng: 80.21722417907927,
      },
      {
        lat: 12.915476855973013,
        lng: 80.21938830096032,
      },
      {
        lat: 12.913701827089664,
        lng: 80.22626435461703,
      },
      {
        lat: 12.923778705874925,
        lng: 80.2278647557503,
      },
      {
        lat: 12.923656949266453,
        lng: 80.22908122607636,
      },
      {
        lat: 12.92242512798707,
        lng: 80.2317850104955,
      },
      {
        lat: 12.921993782747009,
        lng: 80.23190783626782,
      },
      {
        lat: 12.920985329319777,
        lng: 80.23186870099646,
      },
      {
        lat: 12.920603586489744,
        lng: 80.23225329237924,
      },
      {
        lat: 12.91967700242015,
        lng: 80.23383424675144,
      },
      {
        lat: 12.919488885356678,
        lng: 80.23447938483744,
      },
      {
        lat: 12.919396928830173,
        lng: 80.2351475441293,
      },
      {
        lat: 12.919429050811678,
        lng: 80.23648203435224,
      },
      {
        lat: 12.919575538588033,
        lng: 80.23688599021938,
      },
      {
        lat: 12.919941540580018,
        lng: 80.23786012917208,
      },
      {
        lat: 12.920521403118704,
        lng: 80.23847493261052,
      },
      {
        lat: 12.921918412771907,
        lng: 80.2392496655811,
      },
      {
        lat: 12.923410713166728,
        lng: 80.23990441689563,
      },
      {
        lat: 12.923572828538068,
        lng: 80.24160384053302,
      },
      {
        lat: 12.920164984822142,
        lng: 80.24258415354747,
      },
      {
        lat: 12.918633998297329,
        lng: 80.2433765161925,
      },
      {
        lat: 12.917063938021359,
        lng: 80.24335591682377,
      },
      {
        lat: 12.914197660000927,
        lng: 80.24344794927022,
      },
      {
        lat: 12.913857417872629,
        lng: 80.24362026480767,
      },
      {
        lat: 12.913311381638795,
        lng: 80.2436248860539,
      },
      {
        lat: 12.911945672743355,
        lng: 80.24353478281442,
      },
      {
        lat: 12.907571405868195,
        lng: 80.24258907468399,
      },
    ],
    properties: {
      Ward_No: 197,
      Zone_Name: "SOZHINGANALLUR",
      fillColor: "#8bc24c",
      zname: "SOZHINGANALLUR(197)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.892365061706148,
        lng: 80.24624221600381,
      },
      {
        lat: 12.885041089495688,
        lng: 80.24536029654197,
      },
      {
        lat: 12.878458326951801,
        lng: 80.22565658251871,
      },
      {
        lat: 12.890999630769546,
        lng: 80.24056183116821,
      },
      {
        lat: 12.884621277774928,
        lng: 80.25079481301921,
      },
      {
        lat: 12.902916094186322,
        lng: 80.2540370999292,
      },
      {
        lat: 12.883818772069624,
        lng: 80.21829180428091,
      },
      {
        lat: 12.883704877160387,
        lng: 80.2512858697057,
      },
      {
        lat: 12.886247875084019,
        lng: 80.24184819186661,
      },
      {
        lat: 12.898513564612832,
        lng: 80.24017200242673,
      },
      {
        lat: 12.878923371892887,
        lng: 80.22063776282543,
      },
      {
        lat: 12.885177984839977,
        lng: 80.23953529177118,
      },
      {
        lat: 12.897715737632893,
        lng: 80.24050354747429,
      },
      {
        lat: 12.900195223701463,
        lng: 80.25377677450193,
      },
      {
        lat: 12.878612820548653,
        lng: 80.21831920521575,
      },
      {
        lat: 12.883144657089424,
        lng: 80.23277224316801,
      },
      {
        lat: 12.887365505729061,
        lng: 80.23902479286683,
      },
      {
        lat: 12.8781020119145,
        lng: 80.22974318058014,
      },
      {
        lat: 12.888556276126675,
        lng: 80.25176414277203,
      },
      {
        lat: 12.905010150253846,
        lng: 80.24647789944308,
      },
      {
        lat: 12.890690690619143,
        lng: 80.25046061865996,
      },
      {
        lat: 12.899023948892754,
        lng: 80.24982330439188,
      },
      {
        lat: 12.883414564368122,
        lng: 80.25103035589049,
      },
      {
        lat: 12.882248277997737,
        lng: 80.21262426317936,
      },
      {
        lat: 12.892909871755526,
        lng: 80.24349710041209,
      },
      {
        lat: 12.885689559084188,
        lng: 80.23736760778948,
      },
      {
        lat: 12.882082420400893,
        lng: 80.2468594040857,
      },
      {
        lat: 12.889038004632836,
        lng: 80.23727790693408,
      },
      {
        lat: 12.884353923170536,
        lng: 80.24580082217363,
      },
      {
        lat: 12.885763289194577,
        lng: 80.22706839264542,
      },
      {
        lat: 12.90332726432132,
        lng: 80.24522037858766,
      },
      {
        lat: 12.897809177096825,
        lng: 80.25328135465324,
      },
      {
        lat: 12.886618081017088,
        lng: 80.2262968826387,
      },
      {
        lat: 12.894786920664535,
        lng: 80.24141016560559,
      },
      {
        lat: 12.904490082864783,
        lng: 80.24520882934236,
      },
      {
        lat: 12.881470937453436,
        lng: 80.23536758469801,
      },
      {
        lat: 12.885874980566754,
        lng: 80.24458783591733,
      },
      {
        lat: 12.90040247019247,
        lng: 80.24668514690593,
      },
      {
        lat: 12.885592564339035,
        lng: 80.23675853947468,
      },
      {
        lat: 12.88673270926774,
        lng: 80.23647885735666,
      },
      {
        lat: 12.901906577726349,
        lng: 80.24769630001916,
      },
      {
        lat: 12.901277455256361,
        lng: 80.25346760492864,
      },
      {
        lat: 12.88568998169443,
        lng: 80.23577277431684,
      },
      {
        lat: 12.881511415852163,
        lng: 80.22422943818594,
      },
      {
        lat: 12.889431422783002,
        lng: 80.21766335911227,
      },
      {
        lat: 12.882301333374897,
        lng: 80.21743305202389,
      },
      {
        lat: 12.878525048912175,
        lng: 80.23036759873033,
      },
      {
        lat: 12.881251134182188,
        lng: 80.24155199327127,
      },
      {
        lat: 12.889325540214065,
        lng: 80.22931116946641,
      },
      {
        lat: 12.884324897007517,
        lng: 80.2163142160176,
      },
      {
        lat: 12.89326563473171,
        lng: 80.2504767083416,
      },
      {
        lat: 12.88670604948345,
        lng: 80.24097078111133,
      },
      {
        lat: 12.899693869579794,
        lng: 80.25180773628288,
      },
      {
        lat: 12.879590905518333,
        lng: 80.22214781076003,
      },
      {
        lat: 12.878976855952034,
        lng: 80.22265392947281,
      },
      {
        lat: 12.883648244056896,
        lng: 80.21231612239762,
      },
      {
        lat: 12.880229351971705,
        lng: 80.22603937213398,
      },
      {
        lat: 12.894118009279753,
        lng: 80.24121639856939,
      },
      {
        lat: 12.876593893534636,
        lng: 80.23436442880667,
      },
      {
        lat: 12.886664613483877,
        lng: 80.2153423823835,
      },
      {
        lat: 12.898580836153291,
        lng: 80.24538210185666,
      },
      {
        lat: 12.885255770508428,
        lng: 80.21786287356582,
      },
      {
        lat: 12.88786791809328,
        lng: 80.25151996121693,
      },
      {
        lat: 12.88578425216667,
        lng: 80.22521771872741,
      },
      {
        lat: 12.901385948579144,
        lng: 80.24123360379004,
      },
      {
        lat: 12.888971178507953,
        lng: 80.2251305002697,
      },
      {
        lat: 12.888389269461632,
        lng: 80.24021227655892,
      },
      {
        lat: 12.882104354171835,
        lng: 80.23641580525648,
      },
      {
        lat: 12.898126951453023,
        lng: 80.24371498259934,
      },
      {
        lat: 12.899629122717275,
        lng: 80.24634083858824,
      },
      {
        lat: 12.89076747746323,
        lng: 80.21446422191515,
      },
      {
        lat: 12.88850999971925,
        lng: 80.2168062352312,
      },
      {
        lat: 12.887472034980979,
        lng: 80.22574195233776,
      },
      {
        lat: 12.901519496779365,
        lng: 80.24745104918354,
      },
      {
        lat: 12.885049818188767,
        lng: 80.23412453522278,
      },
      {
        lat: 12.885585607892185,
        lng: 80.22829603188215,
      },
      {
        lat: 12.88057931380337,
        lng: 80.23197458351619,
      },
    ],
    cases: 77,
    coords: [
      {
        lat: 12.879040520710749,
        lng: 80.25141634556864,
      },
      {
        lat: 12.880274082878486,
        lng: 80.24542277590236,
      },
      {
        lat: 12.880586190730316,
        lng: 80.2436754686239,
      },
      {
        lat: 12.880617717317378,
        lng: 80.24296309330474,
      },
      {
        lat: 12.881313648353505,
        lng: 80.23946787180228,
      },
      {
        lat: 12.877974121212276,
        lng: 80.23871281573483,
      },
      {
        lat: 12.874255535073766,
        lng: 80.2404889529225,
      },
      {
        lat: 12.874741733961832,
        lng: 80.23788564206453,
      },
      {
        lat: 12.875861243819463,
        lng: 80.23328306752727,
      },
      {
        lat: 12.876139226436742,
        lng: 80.2318208924602,
      },
      {
        lat: 12.875952358717523,
        lng: 80.2305762840924,
      },
      {
        lat: 12.875805030081791,
        lng: 80.22993663332147,
      },
      {
        lat: 12.87594477932918,
        lng: 80.22933015836098,
      },
      {
        lat: 12.876432277540912,
        lng: 80.22694046905693,
      },
      {
        lat: 12.876807871674826,
        lng: 80.22383961832463,
      },
      {
        lat: 12.879005186767474,
        lng: 80.21367347573005,
      },
      {
        lat: 12.879419431754016,
        lng: 80.21049184843952,
      },
      {
        lat: 12.87961619165297,
        lng: 80.21065862725256,
      },
      {
        lat: 12.886644800579168,
        lng: 80.21249457547368,
      },
      {
        lat: 12.888447906219866,
        lng: 80.21277297398184,
      },
      {
        lat: 12.890843464143598,
        lng: 80.21320653586291,
      },
      {
        lat: 12.891596416789724,
        lng: 80.21322685967105,
      },
      {
        lat: 12.889704176259414,
        lng: 80.2272714611143,
      },
      {
        lat: 12.889374598111013,
        lng: 80.2284443932771,
      },
      {
        lat: 12.889340136448325,
        lng: 80.22969840942548,
      },
      {
        lat: 12.889722985121185,
        lng: 80.2303638223395,
      },
      {
        lat: 12.889812496524813,
        lng: 80.23124067192441,
      },
      {
        lat: 12.890281043994408,
        lng: 80.23215610444521,
      },
      {
        lat: 12.89125168987581,
        lng: 80.23950307329389,
      },
      {
        lat: 12.89158889323861,
        lng: 80.23958380108705,
      },
      {
        lat: 12.89507930810802,
        lng: 80.23909456182112,
      },
      {
        lat: 12.896297794147255,
        lng: 80.23874992292693,
      },
      {
        lat: 12.900172582732315,
        lng: 80.23917682856613,
      },
      {
        lat: 12.907925718335857,
        lng: 80.24061568115724,
      },
      {
        lat: 12.907571405868195,
        lng: 80.24258907468399,
      },
      {
        lat: 12.906467005279266,
        lng: 80.24360450488709,
      },
      {
        lat: 12.90644258297375,
        lng: 80.24383551086052,
      },
      {
        lat: 12.905312944020466,
        lng: 80.2449477789647,
      },
      {
        lat: 12.904814268902193,
        lng: 80.25213242146229,
      },
      {
        lat: 12.904564742857497,
        lng: 80.25357061768206,
      },
      {
        lat: 12.904216680871004,
        lng: 80.25456442950905,
      },
      {
        lat: 12.904149297339796,
        lng: 80.25455823332214,
      },
      {
        lat: 12.8996775427951,
        lng: 80.25388631709977,
      },
      {
        lat: 12.89344823633929,
        lng: 80.2531701286749,
      },
      {
        lat: 12.889901910663813,
        lng: 80.25284526122859,
      },
      {
        lat: 12.881138358152512,
        lng: 80.2517069196347,
      },
      {
        lat: 12.879040520710749,
        lng: 80.25141634556864,
      },
    ],
    properties: {
      Ward_No: 198,
      Zone_Name: "SOZHINGANALLUR",
      fillColor: "#f6d04d",
      zname: "SOZHINGANALLUR(198)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.878879738503755,
        lng: 80.24023551206209,
      },
      {
        lat: 12.865139711948204,
        lng: 80.24438205247894,
      },
      {
        lat: 12.863862754878351,
        lng: 80.247609612373,
      },
      {
        lat: 12.874559826077956,
        lng: 80.24556074600957,
      },
      {
        lat: 12.873352048738482,
        lng: 80.24217967404786,
      },
      {
        lat: 12.862292976711233,
        lng: 80.24248373095624,
      },
      {
        lat: 12.874333777757014,
        lng: 80.24348285203439,
      },
      {
        lat: 12.880162269891693,
        lng: 80.23960170669214,
      },
      {
        lat: 12.873529150842932,
        lng: 80.24179047836115,
      },
      {
        lat: 12.86254494783031,
        lng: 80.24001096964857,
      },
      {
        lat: 12.86393676931064,
        lng: 80.24281338096121,
      },
      {
        lat: 12.862415196267044,
        lng: 80.24535539772458,
      },
      {
        lat: 12.863097967519304,
        lng: 80.24125656458003,
      },
      {
        lat: 12.87854234262439,
        lng: 80.2483180869464,
      },
      {
        lat: 12.878361239192389,
        lng: 80.2414944641283,
      },
      {
        lat: 12.861560348186565,
        lng: 80.24693850990094,
      },
      {
        lat: 12.873450974514375,
        lng: 80.24465637170152,
      },
      {
        lat: 12.861089741520082,
        lng: 80.24140184877378,
      },
      {
        lat: 12.868634734981168,
        lng: 80.24900218947025,
      },
      {
        lat: 12.86729267763224,
        lng: 80.24340953411986,
      },
      {
        lat: 12.865254587811414,
        lng: 80.23492926009106,
      },
      {
        lat: 12.878503248867702,
        lng: 80.24824684771829,
      },
      {
        lat: 12.874455012967296,
        lng: 80.24416506927295,
      },
      {
        lat: 12.870435044403996,
        lng: 80.24332357937523,
      },
      {
        lat: 12.858803002522002,
        lng: 80.24858795931158,
      },
      {
        lat: 12.872073460010201,
        lng: 80.24862428057625,
      },
      {
        lat: 12.865904477492581,
        lng: 80.24125156079394,
      },
      {
        lat: 12.878402854393903,
        lng: 80.24097619403956,
      },
      {
        lat: 12.869113577890277,
        lng: 80.24952859210552,
      },
      {
        lat: 12.874214903244326,
        lng: 80.24932535081732,
      },
      {
        lat: 12.867387505241172,
        lng: 80.2471535070384,
      },
      {
        lat: 12.874729720511104,
        lng: 80.24212164262059,
      },
      {
        lat: 12.869413228162458,
        lng: 80.24704255113328,
      },
      {
        lat: 12.860007916805294,
        lng: 80.24178982065371,
      },
      {
        lat: 12.859164557806059,
        lng: 80.24681197778173,
      },
      {
        lat: 12.865366237604373,
        lng: 80.24012770734794,
      },
      {
        lat: 12.863016018674156,
        lng: 80.23919146865738,
      },
      {
        lat: 12.871436056493746,
        lng: 80.24433102709541,
      },
      {
        lat: 12.880522986831343,
        lng: 80.24034504371024,
      },
      {
        lat: 12.877162505397296,
        lng: 80.24231679788826,
      },
      {
        lat: 12.864434605481705,
        lng: 80.24074935227684,
      },
      {
        lat: 12.861063395738766,
        lng: 80.24104277723488,
      },
      {
        lat: 12.86549431171124,
        lng: 80.24082627720657,
      },
      {
        lat: 12.866782223399252,
        lng: 80.24699748718601,
      },
      {
        lat: 12.872824119447069,
        lng: 80.24405234148998,
      },
      {
        lat: 12.859496757765196,
        lng: 80.23731301840701,
      },
      {
        lat: 12.859939728658206,
        lng: 80.23980374509392,
      },
      {
        lat: 12.877604899488292,
        lng: 80.24464061826151,
      },
      {
        lat: 12.874482786190026,
        lng: 80.24938779851392,
      },
      {
        lat: 12.878236266385006,
        lng: 80.24340815910797,
      },
      {
        lat: 12.874452607087175,
        lng: 80.24773059552432,
      },
      {
        lat: 12.864789339611573,
        lng: 80.24814652875654,
      },
      {
        lat: 12.860591705591537,
        lng: 80.24774389010352,
      },
      {
        lat: 12.865101755948212,
        lng: 80.23992854766621,
      },
      {
        lat: 12.874227719370419,
        lng: 80.2482397334855,
      },
      {
        lat: 12.87464878898109,
        lng: 80.24569809320539,
      },
      {
        lat: 12.872444504148257,
        lng: 80.24165957329477,
      },
      {
        lat: 12.857439906706624,
        lng: 80.2475629848616,
      },
      {
        lat: 12.865379710439097,
        lng: 80.24293220223164,
      },
      {
        lat: 12.867274158786648,
        lng: 80.24637646848291,
      },
      {
        lat: 12.865853983974546,
        lng: 80.24354455801266,
      },
      {
        lat: 12.867055712552583,
        lng: 80.23634257387258,
      },
      {
        lat: 12.864380581816603,
        lng: 80.24427136313766,
      },
      {
        lat: 12.859613398173163,
        lng: 80.24347875930242,
      },
      {
        lat: 12.861242674568473,
        lng: 80.2368270712718,
      },
      {
        lat: 12.862649523182364,
        lng: 80.24656707989834,
      },
      {
        lat: 12.862157686190928,
        lng: 80.23480584244278,
      },
      {
        lat: 12.858750355974264,
        lng: 80.24228731917474,
      },
      {
        lat: 12.862875986624752,
        lng: 80.23930765521918,
      },
      {
        lat: 12.878322910700273,
        lng: 80.24370484525181,
      },
      {
        lat: 12.875882202845215,
        lng: 80.24437135856597,
      },
      {
        lat: 12.865486288065052,
        lng: 80.23506929812915,
      },
      {
        lat: 12.865753461500208,
        lng: 80.24939110640922,
      },
      {
        lat: 12.876387940312364,
        lng: 80.24058863703968,
      },
      {
        lat: 12.859795375982921,
        lng: 80.2444787121237,
      },
      {
        lat: 12.866709956613805,
        lng: 80.24950141979687,
      },
      {
        lat: 12.866410268224344,
        lng: 80.23769174975327,
      },
      {
        lat: 12.868486175111613,
        lng: 80.24847884710739,
      },
      {
        lat: 12.865413656978182,
        lng: 80.24575937221556,
      },
      {
        lat: 12.864952110102209,
        lng: 80.23802159105222,
      },
      {
        lat: 12.860143259218304,
        lng: 80.23718269155512,
      },
      {
        lat: 12.877595887160078,
        lng: 80.25082011045129,
      },
      {
        lat: 12.880475717327647,
        lng: 80.2396672258465,
      },
    ],
    cases: 83,
    coords: [
      {
        lat: 12.858986915791178,
        lng: 80.23600777409439,
      },
      {
        lat: 12.861907821858532,
        lng: 80.23487628209364,
      },
      {
        lat: 12.863597932532429,
        lng: 80.23434464104544,
      },
      {
        lat: 12.865161135781563,
        lng: 80.23436374482624,
      },
      {
        lat: 12.865919515852722,
        lng: 80.23590933806886,
      },
      {
        lat: 12.86663727045617,
        lng: 80.23612959855193,
      },
      {
        lat: 12.867257140492905,
        lng: 80.23631835388233,
      },
      {
        lat: 12.867290293093637,
        lng: 80.23641507402888,
      },
      {
        lat: 12.867130233068318,
        lng: 80.23686909871137,
      },
      {
        lat: 12.86678541655621,
        lng: 80.23907070009672,
      },
      {
        lat: 12.866865094256612,
        lng: 80.2414627103289,
      },
      {
        lat: 12.867033807075538,
        lng: 80.24243128972483,
      },
      {
        lat: 12.867428098503373,
        lng: 80.24300995708792,
      },
      {
        lat: 12.867755101458192,
        lng: 80.24323352460358,
      },
      {
        lat: 12.86987265616048,
        lng: 80.24337727106497,
      },
      {
        lat: 12.87146724212063,
        lng: 80.24320210779447,
      },
      {
        lat: 12.872085145613585,
        lng: 80.2430675440161,
      },
      {
        lat: 12.872211659729436,
        lng: 80.24245213536551,
      },
      {
        lat: 12.872558836094337,
        lng: 80.24063851691032,
      },
      {
        lat: 12.874255535073766,
        lng: 80.2404889529225,
      },
      {
        lat: 12.877974121212276,
        lng: 80.23871281573483,
      },
      {
        lat: 12.881313648353505,
        lng: 80.23946787180228,
      },
      {
        lat: 12.880617717317378,
        lng: 80.24296309330474,
      },
      {
        lat: 12.880586190730316,
        lng: 80.2436754686239,
      },
      {
        lat: 12.880274082878486,
        lng: 80.24542277590236,
      },
      {
        lat: 12.879040520710749,
        lng: 80.25141634556864,
      },
      {
        lat: 12.878723783370665,
        lng: 80.25137247388687,
      },
      {
        lat: 12.875802898978613,
        lng: 80.25104231316877,
      },
      {
        lat: 12.857086912915575,
        lng: 80.24836002108216,
      },
      {
        lat: 12.85764651729313,
        lng: 80.24549628404071,
      },
      {
        lat: 12.858717642714595,
        lng: 80.24036154042054,
      },
      {
        lat: 12.858659650416042,
        lng: 80.23966792894008,
      },
      {
        lat: 12.85876150900907,
        lng: 80.23873269842461,
      },
      {
        lat: 12.858986915791178,
        lng: 80.23600777409439,
      },
    ],
    properties: {
      Ward_No: 199,
      Zone_Name: "SOZHINGANALLUR",
      fillColor: "#f6d04d",
      zname: "SOZHINGANALLUR(199)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.925412334852613,
        lng: 80.2047287494677,
      },
      {
        lat: 12.92638779888238,
        lng: 80.20070908689884,
      },
      {
        lat: 12.912664284789962,
        lng: 80.20625837331492,
      },
      {
        lat: 12.910430821931222,
        lng: 80.2045262104537,
      },
      {
        lat: 12.91155779688613,
        lng: 80.20100965182216,
      },
      {
        lat: 12.918528917384776,
        lng: 80.19901214570532,
      },
      {
        lat: 12.916737688688906,
        lng: 80.20486218816215,
      },
      {
        lat: 12.91064307990888,
        lng: 80.1954723320688,
      },
      {
        lat: 12.916346108568655,
        lng: 80.20385384679912,
      },
      {
        lat: 12.923967357360096,
        lng: 80.20936058832982,
      },
      {
        lat: 12.913940885731222,
        lng: 80.19869019415088,
      },
      {
        lat: 12.918227487015178,
        lng: 80.20331302092981,
      },
      {
        lat: 12.916826456007097,
        lng: 80.20545799643094,
      },
      {
        lat: 12.91639346466087,
        lng: 80.20318833314258,
      },
      {
        lat: 12.919950313765568,
        lng: 80.20438230145838,
      },
      {
        lat: 12.922862127254998,
        lng: 80.19931343680567,
      },
      {
        lat: 12.91753422949929,
        lng: 80.1997760416527,
      },
      {
        lat: 12.918021389301074,
        lng: 80.19740833071045,
      },
      {
        lat: 12.915876050236113,
        lng: 80.20259327135344,
      },
      {
        lat: 12.910062309171146,
        lng: 80.20652030399704,
      },
      {
        lat: 12.921072395054347,
        lng: 80.2018783396281,
      },
      {
        lat: 12.914167845549212,
        lng: 80.20057587370793,
      },
      {
        lat: 12.916078256624267,
        lng: 80.20367101901786,
      },
      {
        lat: 12.923758095427345,
        lng: 80.1984443870171,
      },
      {
        lat: 12.915081306477699,
        lng: 80.20390688032803,
      },
      {
        lat: 12.922007338428376,
        lng: 80.20671046245934,
      },
      {
        lat: 12.923705105824958,
        lng: 80.20948990403011,
      },
      {
        lat: 12.925390814930257,
        lng: 80.20256330978658,
      },
      {
        lat: 12.910509781482942,
        lng: 80.19897688707819,
      },
      {
        lat: 12.909572668246698,
        lng: 80.20588812595788,
      },
      {
        lat: 12.920701399985349,
        lng: 80.20906231845514,
      },
      {
        lat: 12.911085858095465,
        lng: 80.20851854526168,
      },
      {
        lat: 12.91027418890776,
        lng: 80.20729848392895,
      },
      {
        lat: 12.911548704598351,
        lng: 80.20151347182014,
      },
      {
        lat: 12.92286866104315,
        lng: 80.2028915841845,
      },
      {
        lat: 12.916222325854847,
        lng: 80.20457047265981,
      },
      {
        lat: 12.919419548670147,
        lng: 80.20094658840665,
      },
      {
        lat: 12.909840110300706,
        lng: 80.20583901850979,
      },
      {
        lat: 12.91920559042137,
        lng: 80.20349421212596,
      },
      {
        lat: 12.91922711368441,
        lng: 80.20180859828125,
      },
      {
        lat: 12.914452792860507,
        lng: 80.20408467138225,
      },
      {
        lat: 12.926190010007717,
        lng: 80.20153401718805,
      },
      {
        lat: 12.909496829500714,
        lng: 80.20299983545661,
      },
      {
        lat: 12.910759721877591,
        lng: 80.20892153600384,
      },
      {
        lat: 12.914249007623958,
        lng: 80.19949604524402,
      },
      {
        lat: 12.90986733520809,
        lng: 80.20824693150347,
      },
      {
        lat: 12.918880436841063,
        lng: 80.20393210699204,
      },
      {
        lat: 12.918521261115902,
        lng: 80.2049576856929,
      },
      {
        lat: 12.90992021343056,
        lng: 80.20772114787935,
      },
      {
        lat: 12.918897707678994,
        lng: 80.19949975947824,
      },
      {
        lat: 12.917912518534362,
        lng: 80.20544711681708,
      },
      {
        lat: 12.915232914816386,
        lng: 80.20497353408422,
      },
      {
        lat: 12.917729174940877,
        lng: 80.20431565673773,
      },
      {
        lat: 12.915671506397207,
        lng: 80.20196289480094,
      },
      {
        lat: 12.917693035458209,
        lng: 80.20100504272638,
      },
      {
        lat: 12.913602319441368,
        lng: 80.19740801370061,
      },
      {
        lat: 12.91056163806771,
        lng: 80.20035072246164,
      },
      {
        lat: 12.913880970456956,
        lng: 80.19978637577476,
      },
    ],
    cases: 58,
    coords: [
      {
        lat: 12.909497283351326,
        lng: 80.20879072900948,
      },
      {
        lat: 12.909539594047923,
        lng: 80.20734406120162,
      },
      {
        lat: 12.909293684868011,
        lng: 80.2046139925676,
      },
      {
        lat: 12.909445049956359,
        lng: 80.20311002912034,
      },
      {
        lat: 12.910127468001942,
        lng: 80.19843683101777,
      },
      {
        lat: 12.91034305102893,
        lng: 80.19618098287515,
      },
      {
        lat: 12.910084321355765,
        lng: 80.19511308055566,
      },
      {
        lat: 12.909944392004828,
        lng: 80.19472721025511,
      },
      {
        lat: 12.910081413368497,
        lng: 80.19463497894701,
      },
      {
        lat: 12.911320144663883,
        lng: 80.19471556690357,
      },
      {
        lat: 12.912514263817565,
        lng: 80.19500144366158,
      },
      {
        lat: 12.914099702789555,
        lng: 80.19553445564598,
      },
      {
        lat: 12.915019640740491,
        lng: 80.19598202827709,
      },
      {
        lat: 12.915849800505793,
        lng: 80.19674911153744,
      },
      {
        lat: 12.916264049534824,
        lng: 80.19699605270787,
      },
      {
        lat: 12.916929689031294,
        lng: 80.19710425880434,
      },
      {
        lat: 12.917710251533805,
        lng: 80.19725702810807,
      },
      {
        lat: 12.918283066381555,
        lng: 80.19718387648186,
      },
      {
        lat: 12.919636997634388,
        lng: 80.19735456113233,
      },
      {
        lat: 12.92149509457752,
        lng: 80.19747544306752,
      },
      {
        lat: 12.922064120079861,
        lng: 80.19747062725708,
      },
      {
        lat: 12.924743497307134,
        lng: 80.19899655924165,
      },
      {
        lat: 12.926114508543256,
        lng: 80.20013764152516,
      },
      {
        lat: 12.928216485269571,
        lng: 80.20216586872654,
      },
      {
        lat: 12.928131174077622,
        lng: 80.20245476211421,
      },
      {
        lat: 12.928105833401611,
        lng: 80.2030601364701,
      },
      {
        lat: 12.925929412052911,
        lng: 80.20310737322617,
      },
      {
        lat: 12.92575966600499,
        lng: 80.20382923827307,
      },
      {
        lat: 12.925742181923413,
        lng: 80.20511875493781,
      },
      {
        lat: 12.925637933957733,
        lng: 80.20508520165959,
      },
      {
        lat: 12.925203497620574,
        lng: 80.20493391840658,
      },
      {
        lat: 12.924821289985886,
        lng: 80.20481662868406,
      },
      {
        lat: 12.924475542089331,
        lng: 80.2049917326165,
      },
      {
        lat: 12.924112244999872,
        lng: 80.2051325495167,
      },
      {
        lat: 12.923747586558319,
        lng: 80.20504954682652,
      },
      {
        lat: 12.923226975045656,
        lng: 80.20498508178525,
      },
      {
        lat: 12.923035609429759,
        lng: 80.20488339469502,
      },
      {
        lat: 12.922755660092632,
        lng: 80.20447253731092,
      },
      {
        lat: 12.922460570107816,
        lng: 80.20442338140045,
      },
      {
        lat: 12.921836443664926,
        lng: 80.20444588132224,
      },
      {
        lat: 12.92173292873487,
        lng: 80.20453284628518,
      },
      {
        lat: 12.921547427403953,
        lng: 80.20539530512278,
      },
      {
        lat: 12.921499073318515,
        lng: 80.20599833658035,
      },
      {
        lat: 12.921604892074981,
        lng: 80.206290143232,
      },
      {
        lat: 12.921986785551544,
        lng: 80.20635578227986,
      },
      {
        lat: 12.92295896371723,
        lng: 80.20653695004,
      },
      {
        lat: 12.92330722487855,
        lng: 80.2067750515053,
      },
      {
        lat: 12.923863039497075,
        lng: 80.20692530750249,
      },
      {
        lat: 12.924002385849366,
        lng: 80.20702743484526,
      },
      {
        lat: 12.92402443797584,
        lng: 80.20780204821516,
      },
      {
        lat: 12.924147491767316,
        lng: 80.20807649122443,
      },
      {
        lat: 12.924170381648725,
        lng: 80.2089888397268,
      },
      {
        lat: 12.924275362650194,
        lng: 80.20914291124575,
      },
      {
        lat: 12.924242149212334,
        lng: 80.20938424122951,
      },
      {
        lat: 12.924156707072278,
        lng: 80.20959157768266,
      },
      {
        lat: 12.924175931775581,
        lng: 80.20990133498009,
      },
      {
        lat: 12.924090489635585,
        lng: 80.2101086714331,
      },
      {
        lat: 12.923952399915736,
        lng: 80.21021314678912,
      },
      {
        lat: 12.923848256669258,
        lng: 80.21019681040264,
      },
      {
        lat: 12.923829660282383,
        lng: 80.20999035445475,
      },
      {
        lat: 12.923498634156074,
        lng: 80.20973488934699,
      },
      {
        lat: 12.923341843329798,
        lng: 80.20961569186355,
      },
      {
        lat: 12.923185576100568,
        lng: 80.20958257883804,
      },
      {
        lat: 12.923065140293398,
        lng: 80.20973855811792,
      },
      {
        lat: 12.922944809205658,
        lng: 80.20991175428968,
      },
      {
        lat: 12.922754386064193,
        lng: 80.20996501922346,
      },
      {
        lat: 12.922441432728062,
        lng: 80.20982992560614,
      },
      {
        lat: 12.921381613315518,
        lng: 80.20949453957581,
      },
      {
        lat: 12.92061667444904,
        lng: 80.2091738756728,
      },
      {
        lat: 12.919800763512981,
        lng: 80.20902582093825,
      },
      {
        lat: 12.919297177596638,
        lng: 80.20890955847159,
      },
      {
        lat: 12.919054525752811,
        lng: 80.20892882987485,
      },
      {
        lat: 12.918812711663987,
        lng: 80.20908583641081,
      },
      {
        lat: 12.91862302155831,
        lng: 80.20925961958568,
      },
      {
        lat: 12.918380369714491,
        lng: 80.20927889098907,
      },
      {
        lat: 12.918119540361285,
        lng: 80.20916057401055,
      },
      {
        lat: 12.917962121218764,
        lng: 80.20893807517768,
      },
      {
        lat: 12.917787781199362,
        lng: 80.20878459066208,
      },
      {
        lat: 12.917353344862372,
        lng: 80.2086333074088,
      },
      {
        lat: 12.916936981315388,
        lng: 80.20860239564581,
      },
      {
        lat: 12.91669359643617,
        lng: 80.20850114880808,
      },
      {
        lat: 12.916553935925696,
        lng: 80.20834737079073,
      },
      {
        lat: 12.916188020851648,
        lng: 80.2080577654016,
      },
      {
        lat: 12.915856366409097,
        lng: 80.20769899894472,
      },
      {
        lat: 12.915246647578272,
        lng: 80.20723927915158,
      },
      {
        lat: 12.914725617187994,
        lng: 80.20710594654413,
      },
      {
        lat: 12.914291704447818,
        lng: 80.20704074774893,
      },
      {
        lat: 12.91422286902676,
        lng: 80.20712741921017,
      },
      {
        lat: 12.914243769239876,
        lng: 80.20771264677275,
      },
      {
        lat: 12.914176609328814,
        lng: 80.20807478849896,
      },
      {
        lat: 12.9140561735217,
        lng: 80.20823076777904,
      },
      {
        lat: 12.914056382960448,
        lng: 80.20826520156213,
      },
      {
        lat: 12.9141089258207,
        lng: 80.20835084576744,
      },
      {
        lat: 12.91426498361135,
        lng: 80.20834952500992,
      },
      {
        lat: 12.914352310700092,
        lng: 80.20845209260519,
      },
      {
        lat: 12.914319620859269,
        lng: 80.20877950704674,
      },
      {
        lat: 12.9142515184736,
        lng: 80.2089866967489,
      },
      {
        lat: 12.914202221913788,
        lng: 80.20943477618223,
      },
      {
        lat: 12.913560232119643,
        lng: 80.2093713383971,
      },
      {
        lat: 12.91212009002115,
        lng: 80.20922856669252,
      },
      {
        lat: 12.909497283351326,
        lng: 80.20879072900948,
      },
    ],
    properties: {
      Ward_No: 191,
      Zone_Name: "PERUNGUDI",
      fillColor: "#8bc24c",
      zname: "PERUNGUDI(191)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.970132646911539,
        lng: 80.25007193104302,
      },
      {
        lat: 12.977490363614297,
        lng: 80.24600933062656,
      },
      {
        lat: 12.967257184444302,
        lng: 80.25522267239383,
      },
      {
        lat: 12.975401105867117,
        lng: 80.24847831689675,
      },
      {
        lat: 12.967706955470007,
        lng: 80.25534757979771,
      },
      {
        lat: 12.973539565899353,
        lng: 80.25037575433039,
      },
      {
        lat: 12.978903204027239,
        lng: 80.2529138010837,
      },
      {
        lat: 12.97146478744994,
        lng: 80.25248733437844,
      },
      {
        lat: 12.980541383654819,
        lng: 80.24409121064383,
      },
      {
        lat: 12.971036290346378,
        lng: 80.259551246016,
      },
      {
        lat: 12.974594153519448,
        lng: 80.24712596117116,
      },
      {
        lat: 12.968769193182473,
        lng: 80.25587561729611,
      },
      {
        lat: 12.972348959147222,
        lng: 80.25077041391404,
      },
      {
        lat: 12.977817834899676,
        lng: 80.24367010252398,
      },
      {
        lat: 12.97378739830946,
        lng: 80.25028902867544,
      },
      {
        lat: 12.97581720762275,
        lng: 80.24646363830054,
      },
      {
        lat: 12.97919786555587,
        lng: 80.24590042578279,
      },
      {
        lat: 12.975604879548024,
        lng: 80.2535660271783,
      },
      {
        lat: 12.969645467972875,
        lng: 80.25038110611197,
      },
      {
        lat: 12.971250032817398,
        lng: 80.26013305385648,
      },
      {
        lat: 12.977157993718034,
        lng: 80.25235243634582,
      },
      {
        lat: 12.971759689595288,
        lng: 80.25026048113293,
      },
      {
        lat: 12.971857995137137,
        lng: 80.26014781123403,
      },
      {
        lat: 12.96852145091171,
        lng: 80.25805978993645,
      },
      {
        lat: 12.972752563255085,
        lng: 80.24705837493758,
      },
      {
        lat: 12.969701135526511,
        lng: 80.25461129589424,
      },
      {
        lat: 12.973500723794695,
        lng: 80.26045456141051,
      },
      {
        lat: 12.970894459990138,
        lng: 80.25343827612286,
      },
      {
        lat: 12.96887507054131,
        lng: 80.25358294736886,
      },
      {
        lat: 12.967677566241166,
        lng: 80.25891600933765,
      },
      {
        lat: 12.97337704530634,
        lng: 80.25357931787713,
      },
      {
        lat: 12.973256261828029,
        lng: 80.25636235772879,
      },
      {
        lat: 12.9690890642475,
        lng: 80.25393320133014,
      },
      {
        lat: 12.977840211660173,
        lng: 80.25274518700023,
      },
      {
        lat: 12.972338205580034,
        lng: 80.25777389529395,
      },
      {
        lat: 12.976780953841931,
        lng: 80.24413288810429,
      },
      {
        lat: 12.969044598438023,
        lng: 80.25736309902675,
      },
      {
        lat: 12.969442259789819,
        lng: 80.25502839586501,
      },
      {
        lat: 12.976193799249657,
        lng: 80.25213760411324,
      },
      {
        lat: 12.968949774665623,
        lng: 80.25719718219347,
      },
      {
        lat: 12.97120599229579,
        lng: 80.24982998564144,
      },
      {
        lat: 12.971615592931636,
        lng: 80.26052096195369,
      },
      {
        lat: 12.979473350831386,
        lng: 80.25353040124966,
      },
      {
        lat: 12.977503737597521,
        lng: 80.25048218817338,
      },
      {
        lat: 12.96643831573731,
        lng: 80.26009488992037,
      },
      {
        lat: 12.97293457600355,
        lng: 80.25388179525599,
      },
      {
        lat: 12.98061357004752,
        lng: 80.24384899102876,
      },
      {
        lat: 12.974486249303352,
        lng: 80.25667570658162,
      },
      {
        lat: 12.979831246957348,
        lng: 80.25045483524573,
      },
      {
        lat: 12.979735897721882,
        lng: 80.25338650392342,
      },
      {
        lat: 12.970306237720958,
        lng: 80.25681316023653,
      },
      {
        lat: 12.971377206633273,
        lng: 80.26109005287117,
      },
      {
        lat: 12.972598789741308,
        lng: 80.2657079594797,
      },
      {
        lat: 12.972089335055362,
        lng: 80.2498694898625,
      },
      {
        lat: 12.977533461962617,
        lng: 80.24487129906836,
      },
      {
        lat: 12.970553973179925,
        lng: 80.26339344993647,
      },
      {
        lat: 12.972012864089894,
        lng: 80.26433375814928,
      },
      {
        lat: 12.972638118552192,
        lng: 80.25720221496482,
      },
      {
        lat: 12.9748220331502,
        lng: 80.25423725117588,
      },
      {
        lat: 12.978883541233664,
        lng: 80.24412825714012,
      },
      {
        lat: 12.972758496511243,
        lng: 80.2533058883914,
      },
      {
        lat: 12.9724809427082,
        lng: 80.25797739550534,
      },
      {
        lat: 12.979939848425811,
        lng: 80.25169079120171,
      },
      {
        lat: 12.969616877929607,
        lng: 80.2630753559435,
      },
      {
        lat: 12.978278525074241,
        lng: 80.24685146742289,
      },
      {
        lat: 12.973979065250317,
        lng: 80.26419032274991,
      },
      {
        lat: 12.97298425005908,
        lng: 80.25809480485651,
      },
      {
        lat: 12.976811745428009,
        lng: 80.24484487195478,
      },
      {
        lat: 12.972290622988313,
        lng: 80.24856413562908,
      },
      {
        lat: 12.973684032791146,
        lng: 80.24650053081723,
      },
      {
        lat: 12.980125719881789,
        lng: 80.24621617634237,
      },
      {
        lat: 12.978135058819666,
        lng: 80.24605087340905,
      },
      {
        lat: 12.969054919517914,
        lng: 80.2618468220864,
      },
      {
        lat: 12.97673637577296,
        lng: 80.25096892439632,
      },
      {
        lat: 12.9795550729201,
        lng: 80.25197112003029,
      },
      {
        lat: 12.974001903850489,
        lng: 80.2492717534572,
      },
      {
        lat: 12.968233507737592,
        lng: 80.25137805406547,
      },
      {
        lat: 12.977373190872324,
        lng: 80.24562895724955,
      },
      {
        lat: 12.971170335096744,
        lng: 80.25005126251433,
      },
      {
        lat: 12.979166360533776,
        lng: 80.25019980521637,
      },
      {
        lat: 12.975860185568195,
        lng: 80.24881631146657,
      },
      {
        lat: 12.97764154366439,
        lng: 80.24792556506442,
      },
      {
        lat: 12.967139049943308,
        lng: 80.26303112427132,
      },
      {
        lat: 12.980119811253681,
        lng: 80.24821662844187,
      },
      {
        lat: 12.970301515855809,
        lng: 80.26399875583044,
      },
      {
        lat: 12.969675020498126,
        lng: 80.25509539932007,
      },
      {
        lat: 12.977182715472575,
        lng: 80.25150600859648,
      },
      {
        lat: 12.969130260043254,
        lng: 80.260905638805,
      },
      {
        lat: 12.97834715036578,
        lng: 80.24520740209681,
      },
      {
        lat: 12.971815346806064,
        lng: 80.26323021582334,
      },
      {
        lat: 12.968076608872646,
        lng: 80.262139372403,
      },
      {
        lat: 12.968348433407407,
        lng: 80.2599392671517,
      },
      {
        lat: 12.972875720887506,
        lng: 80.25299070389245,
      },
      {
        lat: 12.976946830034345,
        lng: 80.2483510901199,
      },
      {
        lat: 12.977189080868563,
        lng: 80.24968144118357,
      },
      {
        lat: 12.967006138701704,
        lng: 80.25602513643011,
      },
      {
        lat: 12.979123162938716,
        lng: 80.252687234547,
      },
      {
        lat: 12.970944782605075,
        lng: 80.25820927754044,
      },
      {
        lat: 12.974578749908204,
        lng: 80.2586974326921,
      },
      {
        lat: 12.969986778672114,
        lng: 80.25433612309831,
      },
      {
        lat: 12.975150560488173,
        lng: 80.25912180735666,
      },
      {
        lat: 12.975901419901472,
        lng: 80.24649595614194,
      },
      {
        lat: 12.978766113963992,
        lng: 80.24428620672741,
      },
      {
        lat: 12.968375570038669,
        lng: 80.2548195145091,
      },
      {
        lat: 12.969633724159792,
        lng: 80.26494816056271,
      },
      {
        lat: 12.977152390162354,
        lng: 80.2529047667531,
      },
      {
        lat: 12.97082822722608,
        lng: 80.25072631919535,
      },
      {
        lat: 12.965509085675833,
        lng: 80.24802088530005,
      },
      {
        lat: 12.97184713178468,
        lng: 80.2589469320797,
      },
      {
        lat: 12.976937954528468,
        lng: 80.25098949398672,
      },
      {
        lat: 12.978712100596704,
        lng: 80.24846968935285,
      },
      {
        lat: 12.975835233577703,
        lng: 80.24829372976575,
      },
      {
        lat: 12.975425105286684,
        lng: 80.24677876236866,
      },
      {
        lat: 12.969737796107056,
        lng: 80.25417464785586,
      },
      {
        lat: 12.972050186079375,
        lng: 80.26554389101807,
      },
      {
        lat: 12.979606401811578,
        lng: 80.24687964106903,
      },
      {
        lat: 12.967334108301394,
        lng: 80.25758541879402,
      },
      {
        lat: 12.96978734924363,
        lng: 80.26437373765953,
      },
      {
        lat: 12.968313041248944,
        lng: 80.26159460001581,
      },
      {
        lat: 12.970662181255284,
        lng: 80.26211500519672,
      },
      {
        lat: 12.977528596037384,
        lng: 80.24431271682894,
      },
      {
        lat: 12.967763227577507,
        lng: 80.26246371629567,
      },
      {
        lat: 12.979439157738755,
        lng: 80.24951552627032,
      },
      {
        lat: 12.967961452715883,
        lng: 80.2509032589245,
      },
      {
        lat: 12.980607949513317,
        lng: 80.24570495126443,
      },
      {
        lat: 12.979733536809231,
        lng: 80.2464608883914,
      },
      {
        lat: 12.974248279160594,
        lng: 80.2578720852041,
      },
      {
        lat: 12.97966937997541,
        lng: 80.24614655265066,
      },
      {
        lat: 12.972441782027499,
        lng: 80.25933939886606,
      },
      {
        lat: 12.978391484570716,
        lng: 80.24418958798012,
      },
      {
        lat: 12.973695598417352,
        lng: 80.2473691421346,
      },
      {
        lat: 12.967486254188728,
        lng: 80.25653535684258,
      },
      {
        lat: 12.978754734761806,
        lng: 80.25255971494518,
      },
      {
        lat: 12.972262603293172,
        lng: 80.25618131835468,
      },
      {
        lat: 12.973781844013045,
        lng: 80.26549212495934,
      },
      {
        lat: 12.973752165187317,
        lng: 80.25415067202717,
      },
      {
        lat: 12.97779345373277,
        lng: 80.2470961254762,
      },
      {
        lat: 12.969962475104506,
        lng: 80.25877363024033,
      },
      {
        lat: 12.978426663878396,
        lng: 80.24665514937143,
      },
      {
        lat: 12.968541006283894,
        lng: 80.26057393150491,
      },
      {
        lat: 12.975187028712064,
        lng: 80.24676282773505,
      },
      {
        lat: 12.977325198122013,
        lng: 80.2505130848712,
      },
      {
        lat: 12.969538119694839,
        lng: 80.25719154524606,
      },
      {
        lat: 12.974833325761969,
        lng: 80.2529210321074,
      },
      {
        lat: 12.980496942210722,
        lng: 80.2503865571084,
      },
      {
        lat: 12.97773763053748,
        lng: 80.25170525412844,
      },
      {
        lat: 12.97923803843653,
        lng: 80.2488728387619,
      },
      {
        lat: 12.970100973837347,
        lng: 80.26415854012197,
      },
      {
        lat: 12.974528890487733,
        lng: 80.2512164914462,
      },
      {
        lat: 12.969598038465467,
        lng: 80.24929828819315,
      },
      {
        lat: 12.975938169324722,
        lng: 80.24930224670274,
      },
      {
        lat: 12.973089745048256,
        lng: 80.25763078295968,
      },
      {
        lat: 12.96710788364766,
        lng: 80.25924755839854,
      },
      {
        lat: 12.977937757656992,
        lng: 80.24531833623877,
      },
      {
        lat: 12.979252027161738,
        lng: 80.24403893473229,
      },
    ],
    cases: 155,
    coords: [
      {
        lat: 12.965363372621919,
        lng: 80.26448605458776,
      },
      {
        lat: 12.965922137837039,
        lng: 80.26156023093641,
      },
      {
        lat: 12.96648547694361,
        lng: 80.25766174823485,
      },
      {
        lat: 12.966987874613551,
        lng: 80.25532593043513,
      },
      {
        lat: 12.96733441485823,
        lng: 80.25439037122374,
      },
      {
        lat: 12.967426211093194,
        lng: 80.25403985944678,
      },
      {
        lat: 12.96730483632284,
        lng: 80.25338804822705,
      },
      {
        lat: 12.967395072680768,
        lng: 80.25278107740496,
      },
      {
        lat: 12.967720684616355,
        lng: 80.25226537717353,
      },
      {
        lat: 12.967899739262572,
        lng: 80.2508182909432,
      },
      {
        lat: 12.968148527378753,
        lng: 80.24925403624641,
      },
      {
        lat: 12.964901223614701,
        lng: 80.24813905174015,
      },
      {
        lat: 12.965109005954332,
        lng: 80.24755440175471,
      },
      {
        lat: 12.966167912438442,
        lng: 80.24791849049505,
      },
      {
        lat: 12.966614615530405,
        lng: 80.24800797256614,
      },
      {
        lat: 12.966801185946954,
        lng: 80.24779655264322,
      },
      {
        lat: 12.966822965098043,
        lng: 80.24751658041522,
      },
      {
        lat: 12.967316062631797,
        lng: 80.24751240720293,
      },
      {
        lat: 12.967364016950514,
        lng: 80.24767521096452,
      },
      {
        lat: 12.969430188618343,
        lng: 80.24763440875914,
      },
      {
        lat: 12.96994733421504,
        lng: 80.24772329465696,
      },
      {
        lat: 12.970816266914726,
        lng: 80.24773925631295,
      },
      {
        lat: 12.97133369612534,
        lng: 80.24787477112805,
      },
      {
        lat: 12.971850558108011,
        lng: 80.24791702810874,
      },
      {
        lat: 12.971944056026878,
        lng: 80.24784628983515,
      },
      {
        lat: 12.972244910864415,
        lng: 80.24712095820051,
      },
      {
        lat: 12.972498945839137,
        lng: 80.2464193384731,
      },
      {
        lat: 12.97287336293536,
        lng: 80.24620632875518,
      },
      {
        lat: 12.974867532221422,
        lng: 80.245909663678,
      },
      {
        lat: 12.975336014464409,
        lng: 80.24571917352092,
      },
      {
        lat: 12.975777754311583,
        lng: 80.24499264953992,
      },
      {
        lat: 12.976469558537833,
        lng: 80.24291170098974,
      },
      {
        lat: 12.976679467982494,
        lng: 80.24267676788384,
      },
      {
        lat: 12.977008057864756,
        lng: 80.24265067128368,
      },
      {
        lat: 12.977737665431665,
        lng: 80.24292428433102,
      },
      {
        lat: 12.978772040140328,
        lng: 80.24314134718844,
      },
      {
        lat: 12.979062156675838,
        lng: 80.24334377639676,
      },
      {
        lat: 12.979433312274844,
        lng: 80.24329965830269,
      },
      {
        lat: 12.980629562700113,
        lng: 80.24320758030926,
      },
      {
        lat: 12.981084000374857,
        lng: 80.24328568810124,
      },
      {
        lat: 12.981251810644169,
        lng: 80.24373501386718,
      },
      {
        lat: 12.981254552103515,
        lng: 80.24418573665307,
      },
      {
        lat: 12.98113623341454,
        lng: 80.24508822999006,
      },
      {
        lat: 12.98078140196015,
        lng: 80.24781619740074,
      },
      {
        lat: 12.980665824730524,
        lng: 80.2491694135236,
      },
      {
        lat: 12.980428938129224,
        lng: 80.25093342539898,
      },
      {
        lat: 12.979818902140774,
        lng: 80.2524137569786,
      },
      {
        lat: 12.979902682663493,
        lng: 80.25261793246213,
      },
      {
        lat: 12.980398387540545,
        lng: 80.25269569099912,
      },
      {
        lat: 12.981512602008182,
        lng: 80.25268626111315,
      },
      {
        lat: 12.981721181032816,
        lng: 80.25305328802658,
      },
      {
        lat: 12.981724171715738,
        lng: 80.25354498561114,
      },
      {
        lat: 12.980983355859472,
        lng: 80.25387907059174,
      },
      {
        lat: 12.979168595843799,
        lng: 80.25405833700819,
      },
      {
        lat: 12.977930579768676,
        lng: 80.25406881465928,
      },
      {
        lat: 12.97627764865647,
        lng: 80.25371401167216,
      },
      {
        lat: 12.975699907821388,
        lng: 80.25371890124265,
      },
      {
        lat: 12.975453550724177,
        lng: 80.25392587076662,
      },
      {
        lat: 12.97537525311997,
        lng: 80.25462314085492,
      },
      {
        lat: 12.975379739144351,
        lng: 80.25536068723191,
      },
      {
        lat: 12.975506780658687,
        lng: 80.25589231185032,
      },
      {
        lat: 12.97526216812634,
        lng: 80.25638610496513,
      },
      {
        lat: 12.974851739113753,
        lng: 80.25675837070406,
      },
      {
        lat: 12.974566607049777,
        lng: 80.25737543747029,
      },
      {
        lat: 12.97457084385058,
        lng: 80.25807200904845,
      },
      {
        lat: 12.974614852512458,
        lng: 80.25852238257944,
      },
      {
        lat: 12.974904470600586,
        lng: 80.25864286219036,
      },
      {
        lat: 12.975277619988095,
        lng: 80.25892654248621,
      },
      {
        lat: 12.975279364553131,
        lng: 80.25921336607718,
      },
      {
        lat: 12.97511529263747,
        lng: 80.25937866229219,
      },
      {
        lat: 12.974826172996433,
        lng: 80.25934013227872,
      },
      {
        lat: 12.974538797920438,
        lng: 80.25958842585635,
      },
      {
        lat: 12.974334455696438,
        lng: 80.25991797052124,
      },
      {
        lat: 12.974215389337079,
        lng: 80.2606975394623,
      },
      {
        lat: 12.973985480995879,
        lng: 80.26360884570187,
      },
      {
        lat: 12.97408296881566,
        lng: 80.26606663511524,
      },
      {
        lat: 12.974333313489755,
        lng: 80.2665152623709,
      },
      {
        lat: 12.971415595975621,
        lng: 80.26562434627617,
      },
      {
        lat: 12.969147251618661,
        lng: 80.26515948192717,
      },
      {
        lat: 12.965363372621919,
        lng: 80.26448605458776,
      },
    ],
    properties: {
      Ward_No: 183,
      Zone_Name: "PERUNGUDI",
      fillColor: "#de4307",
      zname: "PERUNGUDI(183)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.960089257210079,
        lng: 80.25013869056272,
      },
      {
        lat: 12.959143759063569,
        lng: 80.25811247477131,
      },
      {
        lat: 12.96552595620182,
        lng: 80.25109087115632,
      },
      {
        lat: 12.958566445538846,
        lng: 80.2607813938877,
      },
      {
        lat: 12.95781777302979,
        lng: 80.2554247925445,
      },
      {
        lat: 12.958023604923024,
        lng: 80.26094850777994,
      },
      {
        lat: 12.963033074439451,
        lng: 80.25253684535642,
      },
      {
        lat: 12.957187091314847,
        lng: 80.26183450609679,
      },
      {
        lat: 12.96445561520218,
        lng: 80.25192139174752,
      },
      {
        lat: 12.963588637836597,
        lng: 80.25791079335512,
      },
      {
        lat: 12.960102840563804,
        lng: 80.25289543403389,
      },
      {
        lat: 12.960918634446742,
        lng: 80.24838339817987,
      },
      {
        lat: 12.957906976446697,
        lng: 80.25593069285917,
      },
      {
        lat: 12.961793869364705,
        lng: 80.2629840290425,
      },
      {
        lat: 12.963378332905906,
        lng: 80.25150433845896,
      },
      {
        lat: 12.962157572472174,
        lng: 80.24634023070661,
      },
      {
        lat: 12.959609939581815,
        lng: 80.24735984142963,
      },
      {
        lat: 12.960270614230051,
        lng: 80.24684349736461,
      },
      {
        lat: 12.96190665988292,
        lng: 80.2606053354048,
      },
      {
        lat: 12.958178959082435,
        lng: 80.25160336216572,
      },
      {
        lat: 12.959456422469971,
        lng: 80.25615171376933,
      },
      {
        lat: 12.964574976245528,
        lng: 80.2574890287086,
      },
      {
        lat: 12.964192587120241,
        lng: 80.25200268802723,
      },
      {
        lat: 12.966974855521878,
        lng: 80.25305077694738,
      },
      {
        lat: 12.96257414768836,
        lng: 80.24490920151173,
      },
      {
        lat: 12.962623985003352,
        lng: 80.25779003739092,
      },
      {
        lat: 12.95865827113097,
        lng: 80.26069189053527,
      },
      {
        lat: 12.963900379595673,
        lng: 80.25714724168232,
      },
      {
        lat: 12.959337589499196,
        lng: 80.24767455258024,
      },
      {
        lat: 12.96624316064916,
        lng: 80.25688681404314,
      },
      {
        lat: 12.960657709800651,
        lng: 80.2437302843677,
      },
      {
        lat: 12.964929376269966,
        lng: 80.26222898752962,
      },
      {
        lat: 12.96292736708327,
        lng: 80.26010889010516,
      },
    ],
    cases: 33,
    coords: [
      {
        lat: 12.958721526143018,
        lng: 80.24593411322924,
      },
      {
        lat: 12.959215555540466,
        lng: 80.24592959322047,
      },
      {
        lat: 12.960064521985998,
        lng: 80.24332854115788,
      },
      {
        lat: 12.963582978291948,
        lng: 80.2441932004599,
      },
      {
        lat: 12.963321667884712,
        lng: 80.24566228853027,
      },
      {
        lat: 12.963863546332806,
        lng: 80.24589025606906,
      },
      {
        lat: 12.963907084482072,
        lng: 80.24712421053036,
      },
      {
        lat: 12.96505511565713,
        lng: 80.24770603620811,
      },
      {
        lat: 12.964901223614701,
        lng: 80.24813905174015,
      },
      {
        lat: 12.968148527378753,
        lng: 80.24925403624641,
      },
      {
        lat: 12.967899739262572,
        lng: 80.2508182909432,
      },
      {
        lat: 12.967720684616355,
        lng: 80.25226537717353,
      },
      {
        lat: 12.967395072680768,
        lng: 80.25278107740496,
      },
      {
        lat: 12.96730483632284,
        lng: 80.25338804822705,
      },
      {
        lat: 12.967426211093194,
        lng: 80.25403985944678,
      },
      {
        lat: 12.96733441485823,
        lng: 80.25439037122374,
      },
      {
        lat: 12.966987874613551,
        lng: 80.25532593043513,
      },
      {
        lat: 12.96648547694361,
        lng: 80.25766174823485,
      },
      {
        lat: 12.965922137837039,
        lng: 80.26156023093641,
      },
      {
        lat: 12.965363372621919,
        lng: 80.26448605458776,
      },
      {
        lat: 12.96532902035905,
        lng: 80.26447994082108,
      },
      {
        lat: 12.962831961106739,
        lng: 80.26413090907161,
      },
      {
        lat: 12.961367582517264,
        lng: 80.26383008594082,
      },
      {
        lat: 12.959240020198944,
        lng: 80.2629369166445,
      },
      {
        lat: 12.956319767027916,
        lng: 80.26239725591611,
      },
      {
        lat: 12.956418753603813,
        lng: 80.25908419624366,
      },
      {
        lat: 12.956955670473123,
        lng: 80.25431833316216,
      },
      {
        lat: 12.957215442858287,
        lng: 80.2529557577799,
      },
      {
        lat: 12.957511113637478,
        lng: 80.25259837452103,
      },
      {
        lat: 12.957777541099455,
        lng: 80.25232995898594,
      },
      {
        lat: 12.957734627894729,
        lng: 80.25017146323943,
      },
      {
        lat: 12.957584454234718,
        lng: 80.24996572032782,
      },
      {
        lat: 12.95731478916806,
        lng: 80.24970184188287,
      },
      {
        lat: 12.958721526143018,
        lng: 80.24593411322924,
      },
    ],
    properties: {
      Ward_No: 185,
      Zone_Name: "PERUNGUDI",
      fillColor: "#8bc24c",
      zname: "PERUNGUDI(185)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.961161449578377,
        lng: 80.23038086964686,
      },
      {
        lat: 12.965022304610129,
        lng: 80.23325923225765,
      },
      {
        lat: 12.972452736792304,
        lng: 80.2433354939494,
      },
      {
        lat: 12.977848576266481,
        lng: 80.24159286170838,
      },
      {
        lat: 12.978930591645305,
        lng: 80.23460889624349,
      },
      {
        lat: 12.969428087200827,
        lng: 80.23162510520511,
      },
      {
        lat: 12.9665981804331,
        lng: 80.23111271142452,
      },
      {
        lat: 12.978699713652627,
        lng: 80.23644098821379,
      },
      {
        lat: 12.9757448758937,
        lng: 80.2348507933331,
      },
      {
        lat: 12.977204344768007,
        lng: 80.23915045309404,
      },
      {
        lat: 12.974929802386194,
        lng: 80.24413538230951,
      },
      {
        lat: 12.967552727850967,
        lng: 80.23464515758147,
      },
      {
        lat: 12.973385015560606,
        lng: 80.24175597373214,
      },
      {
        lat: 12.97254557199761,
        lng: 80.23053284475527,
      },
      {
        lat: 12.970075051567475,
        lng: 80.24525109912592,
      },
      {
        lat: 12.969447708922035,
        lng: 80.2379100990025,
      },
      {
        lat: 12.963160246748187,
        lng: 80.24292588126917,
      },
      {
        lat: 12.966798112517466,
        lng: 80.24249425397954,
      },
      {
        lat: 12.976446230597098,
        lng: 80.23978544469695,
      },
      {
        lat: 12.976411318393508,
        lng: 80.2362788713225,
      },
      {
        lat: 12.969602775323844,
        lng: 80.24627254798908,
      },
      {
        lat: 12.970489799219262,
        lng: 80.23081946498456,
      },
      {
        lat: 12.972202907102389,
        lng: 80.2367062029242,
      },
      {
        lat: 12.970815890713453,
        lng: 80.24722083233235,
      },
      {
        lat: 12.970745193670465,
        lng: 80.2386216084435,
      },
      {
        lat: 12.9599452863189,
        lng: 80.2333213006328,
      },
      {
        lat: 12.973569666085016,
        lng: 80.23943820649586,
      },
      {
        lat: 12.962188463521487,
        lng: 80.23388095918024,
      },
      {
        lat: 12.977586699249333,
        lng: 80.23401951641533,
      },
      {
        lat: 12.969469445221725,
        lng: 80.23295977038595,
      },
      {
        lat: 12.966799723267096,
        lng: 80.23130132434393,
      },
      {
        lat: 12.971986983774652,
        lng: 80.23255231781053,
      },
      {
        lat: 12.977000002104617,
        lng: 80.2380674977965,
      },
      {
        lat: 12.970817081552731,
        lng: 80.23756905959651,
      },
      {
        lat: 12.97102301751668,
        lng: 80.24735005346017,
      },
      {
        lat: 12.964419396750737,
        lng: 80.24433839540835,
      },
      {
        lat: 12.964865605627084,
        lng: 80.2431121713129,
      },
      {
        lat: 12.96982488280385,
        lng: 80.23382053947076,
      },
      {
        lat: 12.965802458265623,
        lng: 80.23908674307684,
      },
      {
        lat: 12.971400284699786,
        lng: 80.2364740745222,
      },
      {
        lat: 12.968366289449826,
        lng: 80.23430266170121,
      },
      {
        lat: 12.97416296019133,
        lng: 80.23783659644866,
      },
      {
        lat: 12.968618386526463,
        lng: 80.2474994221263,
      },
      {
        lat: 12.96983371775518,
        lng: 80.23913677567414,
      },
      {
        lat: 12.977996864672965,
        lng: 80.2342432016,
      },
      {
        lat: 12.966791063755766,
        lng: 80.24539506815303,
      },
      {
        lat: 12.978253958085743,
        lng: 80.24262238736975,
      },
      {
        lat: 12.971050465951715,
        lng: 80.23534405832257,
      },
      {
        lat: 12.964958323501088,
        lng: 80.24176362847982,
      },
      {
        lat: 12.97795147428303,
        lng: 80.23606141437074,
      },
      {
        lat: 12.963222210700465,
        lng: 80.23936045689689,
      },
      {
        lat: 12.97414456387701,
        lng: 80.24204464230249,
      },
      {
        lat: 12.97191320074577,
        lng: 80.23715996344143,
      },
      {
        lat: 12.970276456984372,
        lng: 80.23614224132044,
      },
      {
        lat: 12.969414841443873,
        lng: 80.24037907648228,
      },
      {
        lat: 12.978081579026934,
        lng: 80.23499072989078,
      },
      {
        lat: 12.960207407030989,
        lng: 80.2325829084021,
      },
      {
        lat: 12.963177728140487,
        lng: 80.24265187198631,
      },
      {
        lat: 12.966942582421197,
        lng: 80.24204156821489,
      },
      {
        lat: 12.976574939274311,
        lng: 80.24270388864902,
      },
      {
        lat: 12.976543691159682,
        lng: 80.23769724785267,
      },
      {
        lat: 12.967007135270615,
        lng: 80.24279753874325,
      },
      {
        lat: 12.966053288434647,
        lng: 80.24729448370572,
      },
      {
        lat: 12.971727572064934,
        lng: 80.23093316926973,
      },
      {
        lat: 12.976636096999309,
        lng: 80.23348397251634,
      },
      {
        lat: 12.969851516710209,
        lng: 80.23683906210708,
      },
      {
        lat: 12.96897164295796,
        lng: 80.24582937158732,
      },
      {
        lat: 12.969043792317851,
        lng: 80.22971277488827,
      },
      {
        lat: 12.969346793780485,
        lng: 80.23165248491262,
      },
      {
        lat: 12.969879713468183,
        lng: 80.22974032429423,
      },
      {
        lat: 12.96766581039282,
        lng: 80.24476287041985,
      },
      {
        lat: 12.97396699173413,
        lng: 80.23336513625574,
      },
      {
        lat: 12.970524094501874,
        lng: 80.23380733751401,
      },
      {
        lat: 12.976617669530928,
        lng: 80.23322184508126,
      },
      {
        lat: 12.977223318761608,
        lng: 80.23812854057678,
      },
      {
        lat: 12.969962414636935,
        lng: 80.23106114571414,
      },
      {
        lat: 12.976934000339323,
        lng: 80.23701611492702,
      },
      {
        lat: 12.973954338928806,
        lng: 80.24404141017432,
      },
      {
        lat: 12.97849260882073,
        lng: 80.23660724809243,
      },
      {
        lat: 12.972790847224681,
        lng: 80.23804330002856,
      },
      {
        lat: 12.97598086510395,
        lng: 80.23990447447244,
      },
      {
        lat: 12.973952271873445,
        lng: 80.23802831751556,
      },
      {
        lat: 12.9769693380261,
        lng: 80.2365587170126,
      },
      {
        lat: 12.973854860261428,
        lng: 80.2450031311303,
      },
      {
        lat: 12.968044318699214,
        lng: 80.24281073339547,
      },
      {
        lat: 12.965012353485491,
        lng: 80.24015597618853,
      },
      {
        lat: 12.970262025599606,
        lng: 80.24448172322529,
      },
      {
        lat: 12.969101899316996,
        lng: 80.2415165342781,
      },
      {
        lat: 12.973550879368714,
        lng: 80.2449180493994,
      },
      {
        lat: 12.967433204273206,
        lng: 80.23081639150138,
      },
    ],
    cases: 90,
    coords: [
      {
        lat: 12.962382007538567,
        lng: 80.24389806235295,
      },
      {
        lat: 12.962761468893122,
        lng: 80.24224103349067,
      },
      {
        lat: 12.963368894662652,
        lng: 80.24017753436195,
      },
      {
        lat: 12.963405418206499,
        lng: 80.23981172237315,
      },
      {
        lat: 12.963209930449338,
        lng: 80.23952482193111,
      },
      {
        lat: 12.963112011070072,
        lng: 80.23935251770487,
      },
      {
        lat: 12.962625222182925,
        lng: 80.23895266065743,
      },
      {
        lat: 12.962642138450855,
        lng: 80.23854854062286,
      },
      {
        lat: 12.962796656651046,
        lng: 80.2384702849204,
      },
      {
        lat: 12.96302995495585,
        lng: 80.23860296941234,
      },
      {
        lat: 12.963264423264222,
        lng: 80.2389280139392,
      },
      {
        lat: 12.963539627128752,
        lng: 80.23957974260368,
      },
      {
        lat: 12.964528249165673,
        lng: 80.23966756060756,
      },
      {
        lat: 12.96493473694377,
        lng: 80.23960640941499,
      },
      {
        lat: 12.965320681443362,
        lng: 80.23935306214841,
      },
      {
        lat: 12.965667528391393,
        lng: 80.23904233479325,
      },
      {
        lat: 12.966030940606318,
        lng: 80.23826977939324,
      },
      {
        lat: 12.967976594587174,
        lng: 80.23325169445437,
      },
      {
        lat: 12.966910830450473,
        lng: 80.23322224030517,
      },
      {
        lat: 12.966445286843856,
        lng: 80.23312999535291,
      },
      {
        lat: 12.966058640342371,
        lng: 80.23326792659849,
      },
      {
        lat: 12.963875661262897,
        lng: 80.23430596238316,
      },
      {
        lat: 12.96350862203727,
        lng: 80.23448220167484,
      },
      {
        lat: 12.959572103160575,
        lng: 80.23389993365615,
      },
      {
        lat: 12.95937755140618,
        lng: 80.23376692124219,
      },
      {
        lat: 12.959337517851802,
        lng: 80.23355565312555,
      },
      {
        lat: 12.959883305659831,
        lng: 80.23082671219579,
      },
      {
        lat: 12.96085301117245,
        lng: 80.23054292005735,
      },
      {
        lat: 12.961148668238229,
        lng: 80.23035623684667,
      },
      {
        lat: 12.961853514239461,
        lng: 80.23035027155217,
      },
      {
        lat: 12.964636921284391,
        lng: 80.2305108958448,
      },
      {
        lat: 12.968237810041854,
        lng: 80.23088561876357,
      },
      {
        lat: 12.968341932249341,
        lng: 80.22970597922551,
      },
      {
        lat: 12.972680301829852,
        lng: 80.22863855073325,
      },
      {
        lat: 12.972948919964601,
        lng: 80.22888968888373,
      },
      {
        lat: 12.97614942312625,
        lng: 80.2326324777286,
      },
      {
        lat: 12.976563839716235,
        lng: 80.23291580876929,
      },
      {
        lat: 12.977059793816867,
        lng: 80.23303454210502,
      },
      {
        lat: 12.978752496790257,
        lng: 80.23314314704466,
      },
      {
        lat: 12.979207432912158,
        lng: 80.23330320443414,
      },
      {
        lat: 12.97941725805468,
        lng: 80.23387510534111,
      },
      {
        lat: 12.979426728550598,
        lng: 80.23543214769268,
      },
      {
        lat: 12.978728031478509,
        lng: 80.24269097365742,
      },
      {
        lat: 12.978772040140328,
        lng: 80.24314134718844,
      },
      {
        lat: 12.977737665431665,
        lng: 80.24292428433102,
      },
      {
        lat: 12.977008057864756,
        lng: 80.24265067128368,
      },
      {
        lat: 12.976679467982494,
        lng: 80.24267676788384,
      },
      {
        lat: 12.976469558537833,
        lng: 80.24291170098974,
      },
      {
        lat: 12.975777754311583,
        lng: 80.24499264953992,
      },
      {
        lat: 12.975336014464409,
        lng: 80.24571917352092,
      },
      {
        lat: 12.974867532221422,
        lng: 80.245909663678,
      },
      {
        lat: 12.97287336293536,
        lng: 80.24620632875518,
      },
      {
        lat: 12.972498945839137,
        lng: 80.2464193384731,
      },
      {
        lat: 12.972244910864415,
        lng: 80.24712095820051,
      },
      {
        lat: 12.971944056026878,
        lng: 80.24784628983515,
      },
      {
        lat: 12.971850558108011,
        lng: 80.24791702810874,
      },
      {
        lat: 12.97133369612534,
        lng: 80.24787477112805,
      },
      {
        lat: 12.970816266914726,
        lng: 80.24773925631295,
      },
      {
        lat: 12.96994733421504,
        lng: 80.24772329465696,
      },
      {
        lat: 12.969430188618343,
        lng: 80.24763440875914,
      },
      {
        lat: 12.967364016950514,
        lng: 80.24767521096452,
      },
      {
        lat: 12.967316062631797,
        lng: 80.24751240720293,
      },
      {
        lat: 12.966822965098043,
        lng: 80.24751658041522,
      },
      {
        lat: 12.966801185946954,
        lng: 80.24779655264322,
      },
      {
        lat: 12.966614615530405,
        lng: 80.24800797256614,
      },
      {
        lat: 12.966167912438442,
        lng: 80.24791849049505,
      },
      {
        lat: 12.965109005954332,
        lng: 80.24755440175471,
      },
      {
        lat: 12.96505511565713,
        lng: 80.24770603620811,
      },
      {
        lat: 12.963907084482072,
        lng: 80.24712421053036,
      },
      {
        lat: 12.963863546332806,
        lng: 80.24589025606906,
      },
      {
        lat: 12.963321667884712,
        lng: 80.24566228853027,
      },
      {
        lat: 12.963582978291948,
        lng: 80.2441932004599,
      },
      {
        lat: 12.962382007538567,
        lng: 80.24389806235295,
      },
    ],
    properties: {
      Ward_No: 184,
      Zone_Name: "PERUNGUDI",
      fillColor: "#f6d04d",
      zname: "PERUNGUDI(184)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.964817396028328,
        lng: 80.23475129769464,
      },
      {
        lat: 12.956827943088728,
        lng: 80.23569154942246,
      },
      {
        lat: 12.95569184567243,
        lng: 80.23999882807432,
      },
      {
        lat: 12.954453464300627,
        lng: 80.24537556511066,
      },
      {
        lat: 12.959707314205481,
        lng: 80.24116495149826,
      },
      {
        lat: 12.966375185004997,
        lng: 80.23380903887185,
      },
      {
        lat: 12.959576964045343,
        lng: 80.23202789415073,
      },
      {
        lat: 12.951310765910078,
        lng: 80.24424092422153,
      },
      {
        lat: 12.95671749220744,
        lng: 80.23899330413158,
      },
      {
        lat: 12.957030350646235,
        lng: 80.2433858997254,
      },
      {
        lat: 12.962540518208003,
        lng: 80.23460526929098,
      },
      {
        lat: 12.959161972427927,
        lng: 80.23548316684409,
      },
      {
        lat: 12.960237529439217,
        lng: 80.24152784301981,
      },
      {
        lat: 12.95663057076211,
        lng: 80.236005375604,
      },
      {
        lat: 12.953625461756028,
        lng: 80.2352530639743,
      },
      {
        lat: 12.959062872260091,
        lng: 80.24106939197829,
      },
      {
        lat: 12.955297410718599,
        lng: 80.2373823832655,
      },
      {
        lat: 12.961688542651787,
        lng: 80.23903468196782,
      },
      {
        lat: 12.954930394210926,
        lng: 80.23398828138794,
      },
      {
        lat: 12.961478679397871,
        lng: 80.24353053798431,
      },
      {
        lat: 12.958708157488283,
        lng: 80.23294597301563,
      },
      {
        lat: 12.96677321550721,
        lng: 80.23456508579416,
      },
      {
        lat: 12.964889145379766,
        lng: 80.23601120133783,
      },
      {
        lat: 12.957062189759498,
        lng: 80.23561374931978,
      },
      {
        lat: 12.9583327307931,
        lng: 80.2387252753827,
      },
      {
        lat: 12.9558208396386,
        lng: 80.23929660781029,
      },
      {
        lat: 12.962743888532708,
        lng: 80.2418681413902,
      },
      {
        lat: 12.956155824129048,
        lng: 80.24352698978456,
      },
      {
        lat: 12.960746281137101,
        lng: 80.24335310028641,
      },
      {
        lat: 12.960342635917431,
        lng: 80.23838555691519,
      },
      {
        lat: 12.955274848333046,
        lng: 80.23638795113172,
      },
      {
        lat: 12.958934856239,
        lng: 80.23551631193187,
      },
      {
        lat: 12.954139585167907,
        lng: 80.234349914185,
      },
      {
        lat: 12.95162167059042,
        lng: 80.24212278494952,
      },
      {
        lat: 12.95477996245213,
        lng: 80.237145080413,
      },
      {
        lat: 12.962954898926242,
        lng: 80.23944553175765,
      },
      {
        lat: 12.964568914785708,
        lng: 80.23467750087217,
      },
      {
        lat: 12.961762926449476,
        lng: 80.23706753588928,
      },
      {
        lat: 12.95680700184389,
        lng: 80.24428610009612,
      },
      {
        lat: 12.951681297517935,
        lng: 80.24459750223048,
      },
      {
        lat: 12.954254535764226,
        lng: 80.23972006682324,
      },
      {
        lat: 12.95672164113016,
        lng: 80.24041938428051,
      },
      {
        lat: 12.966691017182331,
        lng: 80.23561913637974,
      },
      {
        lat: 12.957439254783468,
        lng: 80.23316130528342,
      },
      {
        lat: 12.957607493359507,
        lng: 80.24445336186557,
      },
      {
        lat: 12.956611079163254,
        lng: 80.23820868032374,
      },
      {
        lat: 12.956112165421816,
        lng: 80.23916266251321,
      },
      {
        lat: 12.960247410761083,
        lng: 80.23840760562086,
      },
      {
        lat: 12.960020304393243,
        lng: 80.2414432734153,
      },
      {
        lat: 12.955685826558094,
        lng: 80.24140254035153,
      },
      {
        lat: 12.964157118108636,
        lng: 80.2380290942221,
      },
      {
        lat: 12.956462519259743,
        lng: 80.237159428688,
      },
      {
        lat: 12.960128882464039,
        lng: 80.23939074104055,
      },
      {
        lat: 12.957106113226233,
        lng: 80.2389709209123,
      },
      {
        lat: 12.96432458981113,
        lng: 80.23453440360494,
      },
      {
        lat: 12.964224261801835,
        lng: 80.2382851689908,
      },
      {
        lat: 12.956200151186257,
        lng: 80.2373580594674,
      },
      {
        lat: 12.964696539657329,
        lng: 80.23946849772369,
      },
      {
        lat: 12.957135132913121,
        lng: 80.24233609572678,
      },
      {
        lat: 12.95962711833897,
        lng: 80.23687776402697,
      },
      {
        lat: 12.962847213910345,
        lng: 80.23500031289034,
      },
      {
        lat: 12.960621321347737,
        lng: 80.24162193868139,
      },
      {
        lat: 12.951920103608554,
        lng: 80.24524338810318,
      },
      {
        lat: 12.954006684858784,
        lng: 80.24276617816686,
      },
      {
        lat: 12.956151821770675,
        lng: 80.23356610313992,
      },
      {
        lat: 12.959379313321987,
        lng: 80.24025350347561,
      },
      {
        lat: 12.960226109349158,
        lng: 80.23823545952031,
      },
      {
        lat: 12.951353452253729,
        lng: 80.24371453421008,
      },
      {
        lat: 12.9618103248596,
        lng: 80.23675149828168,
      },
      {
        lat: 12.95377290997355,
        lng: 80.2424454178029,
      },
      {
        lat: 12.962354089472617,
        lng: 80.23765428643294,
      },
      {
        lat: 12.961600619773998,
        lng: 80.24023304568655,
      },
      {
        lat: 12.9539981555431,
        lng: 80.24589949761376,
      },
      {
        lat: 12.960600756069338,
        lng: 80.23715896221653,
      },
      {
        lat: 12.95634441336527,
        lng: 80.23410702643328,
      },
      {
        lat: 12.957276998648968,
        lng: 80.23919648908063,
      },
      {
        lat: 12.956449420325715,
        lng: 80.23966151695407,
      },
      {
        lat: 12.951966680056644,
        lng: 80.24504842655804,
      },
      {
        lat: 12.953443714576938,
        lng: 80.24537751905488,
      },
      {
        lat: 12.959729779765722,
        lng: 80.23998537621519,
      },
      {
        lat: 12.95595854851834,
        lng: 80.23679185561919,
      },
      {
        lat: 12.965043811384081,
        lng: 80.23632134368012,
      },
      {
        lat: 12.956389172509782,
        lng: 80.23801875395927,
      },
      {
        lat: 12.953682604686353,
        lng: 80.24509879019685,
      },
      {
        lat: 12.956992294300282,
        lng: 80.23885793859505,
      },
      {
        lat: 12.95914651701703,
        lng: 80.23441207112738,
      },
      {
        lat: 12.961972203873298,
        lng: 80.23623316407227,
      },
      {
        lat: 12.95259260052654,
        lng: 80.24176317208737,
      },
      {
        lat: 12.95782901808663,
        lng: 80.2391153136309,
      },
      {
        lat: 12.96186666853093,
        lng: 80.24019034702397,
      },
      {
        lat: 12.954384975891871,
        lng: 80.24344774367046,
      },
      {
        lat: 12.958373682398255,
        lng: 80.24170195875512,
      },
    ],
    cases: 92,
    coords: [
      {
        lat: 12.962382007538567,
        lng: 80.24389806235295,
      },
      {
        lat: 12.960064521985998,
        lng: 80.24332854115788,
      },
      {
        lat: 12.959215555540466,
        lng: 80.24592959322047,
      },
      {
        lat: 12.958721526143018,
        lng: 80.24593411322924,
      },
      {
        lat: 12.955444865534034,
        lng: 80.24587312422771,
      },
      {
        lat: 12.952675773604104,
        lng: 80.24601485341086,
      },
      {
        lat: 12.950356663052874,
        lng: 80.24668509566551,
      },
      {
        lat: 12.949910099146084,
        lng: 80.24671844846166,
      },
      {
        lat: 12.94976028522012,
        lng: 80.24657184932568,
      },
      {
        lat: 12.94978826946916,
        lng: 80.24627587838746,
      },
      {
        lat: 12.950815742651969,
        lng: 80.24381258958293,
      },
      {
        lat: 12.95125313334485,
        lng: 80.24227107051048,
      },
      {
        lat: 12.951460174837125,
        lng: 80.24203273098458,
      },
      {
        lat: 12.951668295531091,
        lng: 80.24197182278503,
      },
      {
        lat: 12.951906199143155,
        lng: 80.2419106625249,
      },
      {
        lat: 12.952054214399832,
        lng: 80.2417615427833,
      },
      {
        lat: 12.952523186680603,
        lng: 80.24051549052814,
      },
      {
        lat: 12.953317073057717,
        lng: 80.23882308728999,
      },
      {
        lat: 12.953568571563494,
        lng: 80.2361002050702,
      },
      {
        lat: 12.953589001401081,
        lng: 80.2345622148456,
      },
      {
        lat: 12.953282538605352,
        lng: 80.23314528483841,
      },
      {
        lat: 12.9547429580479,
        lng: 80.23239960470612,
      },
      {
        lat: 12.955890505509435,
        lng: 80.2319846945402,
      },
      {
        lat: 12.959816083065997,
        lng: 80.2308463854299,
      },
      {
        lat: 12.959883305659831,
        lng: 80.23082671219579,
      },
      {
        lat: 12.959337517851802,
        lng: 80.23355565312555,
      },
      {
        lat: 12.95937755140618,
        lng: 80.23376692124219,
      },
      {
        lat: 12.959572103160575,
        lng: 80.23389993365615,
      },
      {
        lat: 12.96350862203727,
        lng: 80.23448220167484,
      },
      {
        lat: 12.963875661262897,
        lng: 80.23430596238316,
      },
      {
        lat: 12.966058640342371,
        lng: 80.23326792659849,
      },
      {
        lat: 12.966445286843856,
        lng: 80.23312999535291,
      },
      {
        lat: 12.966910830450473,
        lng: 80.23322224030517,
      },
      {
        lat: 12.967976594587174,
        lng: 80.23325169445437,
      },
      {
        lat: 12.966030940606318,
        lng: 80.23826977939324,
      },
      {
        lat: 12.965667528391393,
        lng: 80.23904233479325,
      },
      {
        lat: 12.965320681443362,
        lng: 80.23935306214841,
      },
      {
        lat: 12.96493473694377,
        lng: 80.23960640941499,
      },
      {
        lat: 12.964528249165673,
        lng: 80.23966756060756,
      },
      {
        lat: 12.963539627128752,
        lng: 80.23957974260368,
      },
      {
        lat: 12.963264423264222,
        lng: 80.2389280139392,
      },
      {
        lat: 12.96302995495585,
        lng: 80.23860296941234,
      },
      {
        lat: 12.962796656651046,
        lng: 80.2384702849204,
      },
      {
        lat: 12.962642138450855,
        lng: 80.23854854062286,
      },
      {
        lat: 12.962625222182925,
        lng: 80.23895266065743,
      },
      {
        lat: 12.963112011070072,
        lng: 80.23935251770487,
      },
      {
        lat: 12.963209930449338,
        lng: 80.23952482193111,
      },
      {
        lat: 12.963405418206499,
        lng: 80.23981172237315,
      },
      {
        lat: 12.963368894662652,
        lng: 80.24017753436195,
      },
      {
        lat: 12.962761468893122,
        lng: 80.24224103349067,
      },
      {
        lat: 12.962382007538567,
        lng: 80.24389806235295,
      },
    ],
    properties: {
      Ward_No: 186,
      Zone_Name: "PERUNGUDI",
      fillColor: "#f6d04d",
      zname: "PERUNGUDI(186)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.958744288579611,
        lng: 80.19385134275177,
      },
      {
        lat: 12.964284974251331,
        lng: 80.19680908761698,
      },
      {
        lat: 12.962646618292355,
        lng: 80.19540764925837,
      },
      {
        lat: 12.962214021327345,
        lng: 80.19194304604423,
      },
      {
        lat: 12.962268439581155,
        lng: 80.19362359453747,
      },
      {
        lat: 12.95932515061796,
        lng: 80.18927394542055,
      },
      {
        lat: 12.966998427534781,
        lng: 80.19215237582564,
      },
      {
        lat: 12.96432286047864,
        lng: 80.19629399927081,
      },
      {
        lat: 12.961161277958366,
        lng: 80.19191959801948,
      },
      {
        lat: 12.958410231631964,
        lng: 80.19002648372229,
      },
      {
        lat: 12.962535978541919,
        lng: 80.19104966636108,
      },
      {
        lat: 12.964712027733778,
        lng: 80.19167218018599,
      },
      {
        lat: 12.95745682419965,
        lng: 80.1931669607616,
      },
      {
        lat: 12.966098175020353,
        lng: 80.19255279850101,
      },
      {
        lat: 12.963860173995648,
        lng: 80.19305675752622,
      },
      {
        lat: 12.9657837881387,
        lng: 80.19253975119945,
      },
      {
        lat: 12.96680998391129,
        lng: 80.19390010369287,
      },
      {
        lat: 12.961631855592461,
        lng: 80.19156847428764,
      },
      {
        lat: 12.959445248403716,
        lng: 80.19145893216414,
      },
      {
        lat: 12.966645362823504,
        lng: 80.19586567819678,
      },
      {
        lat: 12.964782254809732,
        lng: 80.19130928266738,
      },
      {
        lat: 12.961974801076861,
        lng: 80.19149273241585,
      },
      {
        lat: 12.964822774540044,
        lng: 80.19311697932483,
      },
      {
        lat: 12.958544748171933,
        lng: 80.19326779949733,
      },
      {
        lat: 12.958321024781778,
        lng: 80.19532775290244,
      },
      {
        lat: 12.96543963619316,
        lng: 80.19354036629447,
      },
      {
        lat: 12.95812441783242,
        lng: 80.19131391200068,
      },
      {
        lat: 12.964206487333415,
        lng: 80.19179700682928,
      },
      {
        lat: 12.966693652814698,
        lng: 80.1946957899481,
      },
      {
        lat: 12.959839081767445,
        lng: 80.18991796724038,
      },
      {
        lat: 12.96573759806427,
        lng: 80.19154888829512,
      },
      {
        lat: 12.957449872707222,
        lng: 80.19027543144671,
      },
      {
        lat: 12.957087957943692,
        lng: 80.19500855205524,
      },
      {
        lat: 12.961112202278064,
        lng: 80.19233615478399,
      },
      {
        lat: 12.964484674373963,
        lng: 80.19543790547738,
      },
      {
        lat: 12.964498494216095,
        lng: 80.19712047787203,
      },
      {
        lat: 12.95771863821662,
        lng: 80.19138751204956,
      },
      {
        lat: 12.95976460169908,
        lng: 80.19429947448144,
      },
      {
        lat: 12.963390099243965,
        lng: 80.1904430140137,
      },
      {
        lat: 12.958455298415089,
        lng: 80.18880832711051,
      },
      {
        lat: 12.96556633274951,
        lng: 80.19217993614643,
      },
      {
        lat: 12.961912873731745,
        lng: 80.19415024591461,
      },
      {
        lat: 12.964450151212509,
        lng: 80.19195326272829,
      },
      {
        lat: 12.966830428960446,
        lng: 80.19053973154976,
      },
      {
        lat: 12.966072488557181,
        lng: 80.1906831708887,
      },
      {
        lat: 12.963439760515234,
        lng: 80.19037467312232,
      },
      {
        lat: 12.96098604764016,
        lng: 80.19663686606893,
      },
      {
        lat: 12.962788683687718,
        lng: 80.19776691877875,
      },
      {
        lat: 12.961638656298506,
        lng: 80.19486022081935,
      },
      {
        lat: 12.957957796517714,
        lng: 80.19231625483054,
      },
      {
        lat: 12.968119689937444,
        lng: 80.19283463845247,
      },
      {
        lat: 12.962772816558381,
        lng: 80.19801108614112,
      },
      {
        lat: 12.959657815755039,
        lng: 80.19420653283902,
      },
      {
        lat: 12.960471138584861,
        lng: 80.19366689370787,
      },
      {
        lat: 12.958172586216307,
        lng: 80.19315303879564,
      },
      {
        lat: 12.959130509390846,
        lng: 80.19223853812105,
      },
      {
        lat: 12.967890888052867,
        lng: 80.19102274737482,
      },
      {
        lat: 12.962458881733596,
        lng: 80.191324357011,
      },
      {
        lat: 12.966346082650102,
        lng: 80.19569023987576,
      },
      {
        lat: 12.95743077893472,
        lng: 80.19260127165288,
      },
      {
        lat: 12.963312739414706,
        lng: 80.19043082769004,
      },
      {
        lat: 12.967926570822524,
        lng: 80.19332809024662,
      },
      {
        lat: 12.961598272706333,
        lng: 80.19059859554336,
      },
      {
        lat: 12.963193456146524,
        lng: 80.19014879180648,
      },
      {
        lat: 12.966322124577568,
        lng: 80.1954031670492,
      },
      {
        lat: 12.963199589643493,
        lng: 80.19018597247262,
      },
      {
        lat: 12.959501682747833,
        lng: 80.19017487441253,
      },
      {
        lat: 12.961327055780167,
        lng: 80.19107493115443,
      },
      {
        lat: 12.963097104428053,
        lng: 80.19099656620821,
      },
      {
        lat: 12.960582714573615,
        lng: 80.19345465170805,
      },
      {
        lat: 12.962870193416991,
        lng: 80.19213038024331,
      },
    ],
    cases: 71,
    coords: [
      {
        lat: 12.968043164739473,
        lng: 80.19368805579145,
      },
      {
        lat: 12.96785923968149,
        lng: 80.1944603876816,
      },
      {
        lat: 12.967750913733216,
        lng: 80.19441838258764,
      },
      {
        lat: 12.967469814479095,
        lng: 80.19439930066119,
      },
      {
        lat: 12.967367884329834,
        lng: 80.19540882761241,
      },
      {
        lat: 12.96736840643588,
        lng: 80.1954946669631,
      },
      {
        lat: 12.967349795566582,
        lng: 80.19598842614555,
      },
      {
        lat: 12.967220639798393,
        lng: 80.1960753629937,
      },
      {
        lat: 12.967025992460362,
        lng: 80.19605554940227,
      },
      {
        lat: 12.96657159779439,
        lng: 80.19597355129282,
      },
      {
        lat: 12.966248065232161,
        lng: 80.19586379937503,
      },
      {
        lat: 12.966011664419282,
        lng: 80.19586580009643,
      },
      {
        lat: 12.965951003681267,
        lng: 80.19625754323235,
      },
      {
        lat: 12.965620232901555,
        lng: 80.19629164101114,
      },
      {
        lat: 12.965619185930954,
        lng: 80.19611950878266,
      },
      {
        lat: 12.965209424522197,
        lng: 80.1961229766997,
      },
      {
        lat: 12.964815423167538,
        lng: 80.19612631123536,
      },
      {
        lat: 12.9647961811689,
        lng: 80.19814521958664,
      },
      {
        lat: 12.962463597970373,
        lng: 80.19814931165332,
      },
      {
        lat: 12.962408322759584,
        lng: 80.19683524750722,
      },
      {
        lat: 12.958812746105636,
        lng: 80.19649009724664,
      },
      {
        lat: 12.9584337433719,
        lng: 80.19636811132555,
      },
      {
        lat: 12.958290570376288,
        lng: 80.19615023437672,
      },
      {
        lat: 12.958318949572897,
        lng: 80.19563357092838,
      },
      {
        lat: 12.957136659971535,
        lng: 80.19559662938207,
      },
      {
        lat: 12.956777851027951,
        lng: 80.1936122189537,
      },
      {
        lat: 12.957187136540972,
        lng: 80.19353050911454,
      },
      {
        lat: 12.95721294002109,
        lng: 80.19219378208838,
      },
      {
        lat: 12.957326608304518,
        lng: 80.19227708990043,
      },
      {
        lat: 12.957464045094769,
        lng: 80.19225315882207,
      },
      {
        lat: 12.95732009995206,
        lng: 80.19120705296662,
      },
      {
        lat: 12.95744950515843,
        lng: 80.18986265077837,
      },
      {
        lat: 12.958103651181359,
        lng: 80.18808121718317,
      },
      {
        lat: 12.958239980167098,
        lng: 80.18787515215858,
      },
      {
        lat: 12.958377832384096,
        lng: 80.18791952130992,
      },
      {
        lat: 12.958378801713188,
        lng: 80.18807888851283,
      },
      {
        lat: 12.958244272910205,
        lng: 80.18858092120003,
      },
      {
        lat: 12.95838295598071,
        lng: 80.18876189081098,
      },
      {
        lat: 12.959049149379517,
        lng: 80.1889611638806,
      },
      {
        lat: 12.959395441629136,
        lng: 80.18934528767835,
      },
      {
        lat: 12.960199348769525,
        lng: 80.18956616315604,
      },
      {
        lat: 12.965089225158993,
        lng: 80.19050379921892,
      },
      {
        lat: 12.96545636948597,
        lng: 80.19054622781158,
      },
      {
        lat: 12.965638972320265,
        lng: 80.19040807490519,
      },
      {
        lat: 12.965728750505896,
        lng: 80.19008856427585,
      },
      {
        lat: 12.96591121486483,
        lng: 80.18992764462618,
      },
      {
        lat: 12.966990411012405,
        lng: 80.19016895817681,
      },
      {
        lat: 12.967221364829227,
        lng: 80.19044021853749,
      },
      {
        lat: 12.967383946439886,
        lng: 80.19078036129557,
      },
      {
        lat: 12.967499007921578,
        lng: 80.19084769124606,
      },
      {
        lat: 12.9685925839065,
        lng: 80.19115109413666,
      },
      {
        lat: 12.968043164739473,
        lng: 80.19368805579145,
      },
    ],
    properties: {
      Ward_No: 187,
      Zone_Name: "PERUNGUDI",
      fillColor: "#f6d04d",
      zname: "PERUNGUDI(187)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.972569948278677,
        lng: 80.19777233662452,
      },
      {
        lat: 12.971806675851155,
        lng: 80.19462691267591,
      },
      {
        lat: 12.973126985415247,
        lng: 80.2028380992269,
      },
      {
        lat: 12.967805248035475,
        lng: 80.21138159251522,
      },
      {
        lat: 12.964458257641569,
        lng: 80.21283915426737,
      },
      {
        lat: 12.97026017686076,
        lng: 80.2060938767244,
      },
      {
        lat: 12.96719504068406,
        lng: 80.2037530577864,
      },
      {
        lat: 12.97135283875874,
        lng: 80.21081684828226,
      },
      {
        lat: 12.977833945751158,
        lng: 80.20846803351756,
      },
      {
        lat: 12.96606582552527,
        lng: 80.20956129899075,
      },
      {
        lat: 12.96634192163732,
        lng: 80.21317258308454,
      },
      {
        lat: 12.968160773667865,
        lng: 80.19969819258452,
      },
      {
        lat: 12.971691120008506,
        lng: 80.2061579302677,
      },
      {
        lat: 12.967494925639349,
        lng: 80.20399273160997,
      },
      {
        lat: 12.973772739089986,
        lng: 80.20820170774653,
      },
      {
        lat: 12.973179459855928,
        lng: 80.20900927193661,
      },
      {
        lat: 12.976174322684495,
        lng: 80.2011761687028,
      },
      {
        lat: 12.970851797088526,
        lng: 80.19988004153069,
      },
      {
        lat: 12.972397077955703,
        lng: 80.20374909445208,
      },
      {
        lat: 12.970155891773624,
        lng: 80.19644358648432,
      },
      {
        lat: 12.975025835117249,
        lng: 80.20546915442286,
      },
      {
        lat: 12.971054772841022,
        lng: 80.20817318254046,
      },
      {
        lat: 12.972020006430226,
        lng: 80.19658516991213,
      },
      {
        lat: 12.970263425029405,
        lng: 80.20274754449495,
      },
      {
        lat: 12.973479028917822,
        lng: 80.19061430627576,
      },
      {
        lat: 12.968750302482546,
        lng: 80.21452223310274,
      },
      {
        lat: 12.975636866059858,
        lng: 80.20342979597717,
      },
      {
        lat: 12.976788592748234,
        lng: 80.20824866727705,
      },
      {
        lat: 12.967322177756076,
        lng: 80.21318248290899,
      },
      {
        lat: 12.976945838120017,
        lng: 80.20383542729932,
      },
      {
        lat: 12.968774085832495,
        lng: 80.19750039173655,
      },
      {
        lat: 12.973046060769262,
        lng: 80.20145673021763,
      },
      {
        lat: 12.965336869589239,
        lng: 80.20772474923571,
      },
      {
        lat: 12.975858322685657,
        lng: 80.20519062255093,
      },
      {
        lat: 12.968074303532475,
        lng: 80.20260178657234,
      },
      {
        lat: 12.975488804431343,
        lng: 80.19775455275185,
      },
      {
        lat: 12.972175419037518,
        lng: 80.20285121468741,
      },
      {
        lat: 12.974894949390132,
        lng: 80.203597475841,
      },
      {
        lat: 12.975066979848274,
        lng: 80.20373322519113,
      },
      {
        lat: 12.968201391448698,
        lng: 80.2137912073061,
      },
      {
        lat: 12.967561219814922,
        lng: 80.20971283588399,
      },
      {
        lat: 12.967280203229281,
        lng: 80.20299084030887,
      },
      {
        lat: 12.972622734307999,
        lng: 80.19860645999894,
      },
      {
        lat: 12.967620638863815,
        lng: 80.21537954900472,
      },
      {
        lat: 12.96971563713964,
        lng: 80.20994987681742,
      },
      {
        lat: 12.97340669954769,
        lng: 80.19218641062724,
      },
      {
        lat: 12.968656171909096,
        lng: 80.2050520553635,
      },
      {
        lat: 12.972441500426582,
        lng: 80.20034270934848,
      },
      {
        lat: 12.975464537777588,
        lng: 80.19985436373844,
      },
      {
        lat: 12.972632974213358,
        lng: 80.20683700680961,
      },
      {
        lat: 12.972449982935329,
        lng: 80.19018130522875,
      },
      {
        lat: 12.97034256018414,
        lng: 80.19019450064303,
      },
      {
        lat: 12.974652040979764,
        lng: 80.20591745648748,
      },
      {
        lat: 12.97341147203028,
        lng: 80.20371425148296,
      },
      {
        lat: 12.969579750985314,
        lng: 80.19369322051486,
      },
      {
        lat: 12.970564806049584,
        lng: 80.21126456987425,
      },
      {
        lat: 12.97022028373941,
        lng: 80.20580362461044,
      },
      {
        lat: 12.969991193879192,
        lng: 80.20242794692192,
      },
      {
        lat: 12.968552706158935,
        lng: 80.20815559750459,
      },
      {
        lat: 12.977837837539578,
        lng: 80.20150499634624,
      },
      {
        lat: 12.972174576119084,
        lng: 80.19908781496454,
      },
      {
        lat: 12.972902985106886,
        lng: 80.19797067823211,
      },
      {
        lat: 12.967168148602143,
        lng: 80.2051087535545,
      },
      {
        lat: 12.971207894205707,
        lng: 80.21237392073222,
      },
      {
        lat: 12.971993397107882,
        lng: 80.2065978880081,
      },
      {
        lat: 12.966509450123082,
        lng: 80.2089551913398,
      },
      {
        lat: 12.969388822889714,
        lng: 80.21427934150593,
      },
      {
        lat: 12.975373078920242,
        lng: 80.20642394911421,
      },
      {
        lat: 12.969410286727102,
        lng: 80.21063161030658,
      },
      {
        lat: 12.973247946469002,
        lng: 80.19277683114699,
      },
      {
        lat: 12.964981102902287,
        lng: 80.20870358595013,
      },
      {
        lat: 12.969302695149427,
        lng: 80.20170646258941,
      },
      {
        lat: 12.972744951441324,
        lng: 80.1961326092307,
      },
      {
        lat: 12.970466780849815,
        lng: 80.19170441777808,
      },
      {
        lat: 12.97769499407413,
        lng: 80.20206140511651,
      },
      {
        lat: 12.970329889449687,
        lng: 80.20918363155033,
      },
      {
        lat: 12.96753159349112,
        lng: 80.20277266088873,
      },
      {
        lat: 12.967895602292664,
        lng: 80.19863836146374,
      },
      {
        lat: 12.967924302899426,
        lng: 80.21446353180009,
      },
      {
        lat: 12.977055812316841,
        lng: 80.20845599831054,
      },
      {
        lat: 12.969512392052543,
        lng: 80.20396511317412,
      },
      {
        lat: 12.974922615391879,
        lng: 80.20810087843937,
      },
      {
        lat: 12.978809300707919,
        lng: 80.2068344037396,
      },
      {
        lat: 12.967732840680156,
        lng: 80.20030900016144,
      },
      {
        lat: 12.972942779607624,
        lng: 80.20887914349656,
      },
      {
        lat: 12.97455380164613,
        lng: 80.20977678106304,
      },
      {
        lat: 12.96778941684129,
        lng: 80.21524102438703,
      },
      {
        lat: 12.97120169777542,
        lng: 80.20070984733627,
      },
      {
        lat: 12.972617858816053,
        lng: 80.20201736594758,
      },
      {
        lat: 12.97324287302957,
        lng: 80.20339126411092,
      },
      {
        lat: 12.968812914783596,
        lng: 80.20071238437102,
      },
      {
        lat: 12.965698003911994,
        lng: 80.2103487736606,
      },
      {
        lat: 12.970502662783069,
        lng: 80.20375416029532,
      },
      {
        lat: 12.971253237743174,
        lng: 80.1919844898011,
      },
      {
        lat: 12.975171723246236,
        lng: 80.1984756813418,
      },
      {
        lat: 12.97115820027129,
        lng: 80.18987213824373,
      },
      {
        lat: 12.972289445734242,
        lng: 80.1913530564226,
      },
      {
        lat: 12.970965389141897,
        lng: 80.21193888350356,
      },
      {
        lat: 12.977756555237779,
        lng: 80.20665488097025,
      },
      {
        lat: 12.975064298631947,
        lng: 80.20834888583002,
      },
      {
        lat: 12.967467293102775,
        lng: 80.21183463313055,
      },
      {
        lat: 12.974489051567945,
        lng: 80.20357499688654,
      },
      {
        lat: 12.97026694498484,
        lng: 80.21009354857011,
      },
      {
        lat: 12.974724931756214,
        lng: 80.20556867646854,
      },
      {
        lat: 12.97368795947896,
        lng: 80.20426806677713,
      },
      {
        lat: 12.965882246949539,
        lng: 80.1964489016145,
      },
      {
        lat: 12.96923152130625,
        lng: 80.21221047698184,
      },
      {
        lat: 12.972486433460437,
        lng: 80.20597385389819,
      },
      {
        lat: 12.96740116235864,
        lng: 80.20000721126632,
      },
      {
        lat: 12.96473731466117,
        lng: 80.2143445776598,
      },
      {
        lat: 12.97200866567175,
        lng: 80.2107628810251,
      },
      {
        lat: 12.969381265431332,
        lng: 80.20894555213992,
      },
      {
        lat: 12.97068161698085,
        lng: 80.20516229039211,
      },
      {
        lat: 12.97026458960722,
        lng: 80.20110021910645,
      },
      {
        lat: 12.97499535289546,
        lng: 80.20010814767714,
      },
      {
        lat: 12.969315524907696,
        lng: 80.2052049194501,
      },
      {
        lat: 12.964578713747212,
        lng: 80.21271070617588,
      },
      {
        lat: 12.966768122904682,
        lng: 80.20728377429216,
      },
      {
        lat: 12.970073024287395,
        lng: 80.19190815919941,
      },
    ],
    cases: 119,
    coords: [
      {
        lat: 12.9745296929927,
        lng: 80.19076093922152,
      },
      {
        lat: 12.974547381682664,
        lng: 80.191167320205,
      },
      {
        lat: 12.974469294387317,
        lng: 80.19156774234662,
      },
      {
        lat: 12.974613119689094,
        lng: 80.19204623863824,
      },
      {
        lat: 12.974575534861968,
        lng: 80.19248629412402,
      },
      {
        lat: 12.974333006325791,
        lng: 80.19232844219844,
      },
      {
        lat: 12.973727292827054,
        lng: 80.19203374755739,
      },
      {
        lat: 12.973648476121374,
        lng: 80.19231424749171,
      },
      {
        lat: 12.973449732399962,
        lng: 80.19273567884329,
      },
      {
        lat: 12.97353158831535,
        lng: 80.19295485477333,
      },
      {
        lat: 12.974035194664483,
        lng: 80.19301055681247,
      },
      {
        lat: 12.974056783150973,
        lng: 80.19325023086483,
      },
      {
        lat: 12.973352609554443,
        lng: 80.19331615465897,
      },
      {
        lat: 12.973253541614712,
        lng: 80.19357683792121,
      },
      {
        lat: 12.973396029663986,
        lng: 80.19383547683255,
      },
      {
        lat: 12.97348080322075,
        lng: 80.19453434159222,
      },
      {
        lat: 12.973485787524675,
        lng: 80.19535381000965,
      },
      {
        lat: 12.973649620923668,
        lng: 80.19581214890407,
      },
      {
        lat: 12.973718519708193,
        lng: 80.19721073023626,
      },
      {
        lat: 12.97404156690748,
        lng: 80.19736790071151,
      },
      {
        lat: 12.974364857243541,
        lng: 80.19756504525606,
      },
      {
        lat: 12.974907750377234,
        lng: 80.19746051029338,
      },
      {
        lat: 12.975330473358198,
        lng: 80.19745693267913,
      },
      {
        lat: 12.975572394052428,
        lng: 80.19751484943185,
      },
      {
        lat: 12.975715611512257,
        lng: 80.19789341055066,
      },
      {
        lat: 12.97623696684603,
        lng: 80.20086721963726,
      },
      {
        lat: 12.977103758157902,
        lng: 80.20105976439187,
      },
      {
        lat: 12.977527939959579,
        lng: 80.20129603119248,
      },
      {
        lat: 12.978252851063663,
        lng: 80.20132987220872,
      },
      {
        lat: 12.978333369726837,
        lng: 80.20132919075841,
      },
      {
        lat: 12.978497005649695,
        lng: 80.20140046213093,
      },
      {
        lat: 12.97830935224594,
        lng: 80.20150402257686,
      },
      {
        lat: 12.978140158392264,
        lng: 80.20182836674832,
      },
      {
        lat: 12.978159031409056,
        lng: 80.20211712849911,
      },
      {
        lat: 12.97790322497697,
        lng: 80.20227225188485,
      },
      {
        lat: 12.977589547228614,
        lng: 80.20222226687316,
      },
      {
        lat: 12.977460795264982,
        lng: 80.20221352677342,
      },
      {
        lat: 12.977313260851147,
        lng: 80.20237205157034,
      },
      {
        lat: 12.977082670146366,
        lng: 80.20352408514896,
      },
      {
        lat: 12.976899424170197,
        lng: 80.20432184664321,
      },
      {
        lat: 12.976851242450364,
        lng: 80.2045385091581,
      },
      {
        lat: 12.976743006704687,
        lng: 80.20464755255566,
      },
      {
        lat: 12.97647590230453,
        lng: 80.20467930241225,
      },
      {
        lat: 12.976278153477939,
        lng: 80.20472029505567,
      },
      {
        lat: 12.976278811113941,
        lng: 80.20482841686021,
      },
      {
        lat: 12.97652785048494,
        lng: 80.20508188295973,
      },
      {
        lat: 12.97849241524,
        lng: 80.20580248840905,
      },
      {
        lat: 12.978691120628103,
        lng: 80.20591876384495,
      },
      {
        lat: 12.978701617877418,
        lng: 80.20601697261357,
      },
      {
        lat: 12.978525222259945,
        lng: 80.20631335832353,
      },
      {
        lat: 12.978466782431163,
        lng: 80.20647112908982,
      },
      {
        lat: 12.978537034781093,
        lng: 80.2066278107009,
      },
      {
        lat: 12.97962742176693,
        lng: 80.20740462127456,
      },
      {
        lat: 12.978615297100358,
        lng: 80.20719363238508,
      },
      {
        lat: 12.978203372746028,
        lng: 80.20732004933163,
      },
      {
        lat: 12.978081315703749,
        lng: 80.2076079206879,
      },
      {
        lat: 12.977961501673487,
        lng: 80.2082645652325,
      },
      {
        lat: 12.977841936866628,
        lng: 80.20896218457592,
      },
      {
        lat: 12.977719879824065,
        lng: 80.20925005593222,
      },
      {
        lat: 12.977389742204132,
        lng: 80.20925284997253,
      },
      {
        lat: 12.976977817850086,
        lng: 80.20937926691909,
      },
      {
        lat: 12.97673146075265,
        lng: 80.20958623644287,
      },
      {
        lat: 12.976486349773666,
        lng: 80.20999807996022,
      },
      {
        lat: 12.976197230132632,
        lng: 80.20995954994672,
      },
      {
        lat: 12.975947383905176,
        lng: 80.20959287228852,
      },
      {
        lat: 12.975656768922686,
        lng: 80.20930849348277,
      },
      {
        lat: 12.975327129749902,
        lng: 80.20939323712052,
      },
      {
        lat: 12.975122538302495,
        lng: 80.20968180698667,
      },
      {
        lat: 12.974918694525758,
        lng: 80.21009330124919,
      },
      {
        lat: 12.97492243287941,
        lng: 80.21070792322995,
      },
      {
        lat: 12.974841393815746,
        lng: 80.21095447053253,
      },
      {
        lat: 12.97455327106919,
        lng: 80.21107983971385,
      },
      {
        lat: 12.97393501070236,
        lng: 80.21120800293559,
      },
      {
        lat: 12.972862312660778,
        lng: 80.21125805836522,
      },
      {
        lat: 12.971629281057247,
        lng: 80.21208803199085,
      },
      {
        lat: 12.969335763367676,
        lng: 80.2149758261835,
      },
      {
        lat: 12.968553260374847,
        lng: 80.21593536587697,
      },
      {
        lat: 12.962916626714263,
        lng: 80.21371373798004,
      },
      {
        lat: 12.963233365668804,
        lng: 80.21138943292225,
      },
      {
        lat: 12.963674111624611,
        lng: 80.21131999642364,
      },
      {
        lat: 12.963983579583028,
        lng: 80.21142688790154,
      },
      {
        lat: 12.96455720205338,
        lng: 80.21144393530302,
      },
      {
        lat: 12.964703077583843,
        lng: 80.21004096522519,
      },
      {
        lat: 12.96491432569326,
        lng: 80.20850602917983,
      },
      {
        lat: 12.96583588033041,
        lng: 80.20407400216702,
      },
      {
        lat: 12.965891928226528,
        lng: 80.20240896691857,
      },
      {
        lat: 12.96584608194114,
        lng: 80.20212462740508,
      },
      {
        lat: 12.965358424021998,
        lng: 80.20173451647243,
      },
      {
        lat: 12.965334901435313,
        lng: 80.20149379226197,
      },
      {
        lat: 12.966305821351835,
        lng: 80.20155128146635,
      },
      {
        lat: 12.966791214705331,
        lng: 80.20156907557356,
      },
      {
        lat: 12.96715393315502,
        lng: 80.19955101101876,
      },
      {
        lat: 12.967152068218416,
        lng: 80.19924439716272,
      },
      {
        lat: 12.96699660158543,
        lng: 80.19907049598025,
      },
      {
        lat: 12.966929630489215,
        lng: 80.19893965007127,
      },
      {
        lat: 12.967104889835452,
        lng: 80.19874104775205,
      },
      {
        lat: 12.967411693598608,
        lng: 80.19840991943549,
      },
      {
        lat: 12.967454609269277,
        lng: 80.19821243717509,
      },
      {
        lat: 12.967232437959765,
        lng: 80.19795149206307,
      },
      {
        lat: 12.96707750416581,
        lng: 80.19786519483951,
      },
      {
        lat: 12.967097962928074,
        lng: 80.19760219628625,
      },
      {
        lat: 12.96729088363157,
        lng: 80.19668067462953,
      },
      {
        lat: 12.966760842880674,
        lng: 80.19657564991637,
      },
      {
        lat: 12.964813051131678,
        lng: 80.19637518991142,
      },
      {
        lat: 12.964815423167538,
        lng: 80.19612631123536,
      },
      {
        lat: 12.965209424522197,
        lng: 80.1961229766997,
      },
      {
        lat: 12.965619185930954,
        lng: 80.19611950878266,
      },
      {
        lat: 12.965620232901555,
        lng: 80.19629164101114,
      },
      {
        lat: 12.965951003681267,
        lng: 80.19625754323235,
      },
      {
        lat: 12.966011664419282,
        lng: 80.19586580009643,
      },
      {
        lat: 12.966248065232161,
        lng: 80.19586379937503,
      },
      {
        lat: 12.96657159779439,
        lng: 80.19597355129282,
      },
      {
        lat: 12.967025992460362,
        lng: 80.19605554940227,
      },
      {
        lat: 12.967220639798393,
        lng: 80.1960753629937,
      },
      {
        lat: 12.967349795566582,
        lng: 80.19598842614555,
      },
      {
        lat: 12.96736840643588,
        lng: 80.1954946669631,
      },
      {
        lat: 12.967367884329834,
        lng: 80.19540882761241,
      },
      {
        lat: 12.967469814479095,
        lng: 80.19439930066119,
      },
      {
        lat: 12.967750913733216,
        lng: 80.19441838258764,
      },
      {
        lat: 12.96785923968149,
        lng: 80.1944603876816,
      },
      {
        lat: 12.968043164739473,
        lng: 80.19368805579145,
      },
      {
        lat: 12.968146686366557,
        lng: 80.19382314270949,
      },
      {
        lat: 12.968489518844102,
        lng: 80.19390521813466,
      },
      {
        lat: 12.969208691796451,
        lng: 80.19395011773048,
      },
      {
        lat: 12.969259522309358,
        lng: 80.19386471063355,
      },
      {
        lat: 12.969411703747674,
        lng: 80.19355750582339,
      },
      {
        lat: 12.969540877499288,
        lng: 80.19228175901306,
      },
      {
        lat: 12.969795753630654,
        lng: 80.19197368507125,
      },
      {
        lat: 12.970094844803153,
        lng: 80.19049255563277,
      },
      {
        lat: 12.97066933899199,
        lng: 80.1892640261034,
      },
      {
        lat: 12.970959583722108,
        lng: 80.18914260201957,
      },
      {
        lat: 12.973272178286434,
        lng: 80.18944594217203,
      },
      {
        lat: 12.973700796408485,
        lng: 80.18956128233312,
      },
      {
        lat: 12.973769776370961,
        lng: 80.18964567544313,
      },
      {
        lat: 12.973960530777108,
        lng: 80.19005195018462,
      },
      {
        lat: 12.974357811603689,
        lng: 80.19064342623105,
      },
      {
        lat: 12.9745296929927,
        lng: 80.19076093922152,
      },
    ],
    properties: {
      Ward_No: 169,
      Zone_Name: "PERUNGUDI",
      fillColor: "#f6d04d",
      zname: "PERUNGUDI(169)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.975382102462452,
        lng: 80.19670060659737,
      },
      {
        lat: 12.976000192999763,
        lng: 80.19597972876728,
      },
      {
        lat: 12.976301736730205,
        lng: 80.19101011955016,
      },
      {
        lat: 12.981557606975317,
        lng: 80.19058362705991,
      },
      {
        lat: 12.974347833115178,
        lng: 80.1953272231533,
      },
      {
        lat: 12.980323095919893,
        lng: 80.19544918529269,
      },
      {
        lat: 12.978388859091504,
        lng: 80.19106590198739,
      },
      {
        lat: 12.975988154880906,
        lng: 80.19536217783251,
      },
      {
        lat: 12.978681647311982,
        lng: 80.20038065440426,
      },
      {
        lat: 12.97915388107685,
        lng: 80.19561477655346,
      },
      {
        lat: 12.978647865640498,
        lng: 80.19621593097168,
      },
      {
        lat: 12.976156206494148,
        lng: 80.19398550323028,
      },
      {
        lat: 12.976721323021009,
        lng: 80.195429714055,
      },
      {
        lat: 12.98011812486082,
        lng: 80.19117739608407,
      },
      {
        lat: 12.977105226323868,
        lng: 80.19333159705374,
      },
      {
        lat: 12.975482728596699,
        lng: 80.19144837071232,
      },
      {
        lat: 12.979219568061547,
        lng: 80.19430064936803,
      },
      {
        lat: 12.978032724958316,
        lng: 80.19287895368043,
      },
      {
        lat: 12.980939898429462,
        lng: 80.19364494352291,
      },
      {
        lat: 12.97669685434808,
        lng: 80.1953081304171,
      },
      {
        lat: 12.978172673180994,
        lng: 80.19128342140111,
      },
      {
        lat: 12.97924394684046,
        lng: 80.19966584308438,
      },
      {
        lat: 12.976480739716175,
        lng: 80.19368754013252,
      },
      {
        lat: 12.97763375631425,
        lng: 80.19504693688656,
      },
      {
        lat: 12.976696932627506,
        lng: 80.19549772703611,
      },
      {
        lat: 12.976402459522204,
        lng: 80.19346418434797,
      },
      {
        lat: 12.977733758409885,
        lng: 80.1924924795911,
      },
      {
        lat: 12.977176774579751,
        lng: 80.1949576829677,
      },
      {
        lat: 12.979057688724271,
        lng: 80.19146870113114,
      },
      {
        lat: 12.973659745540699,
        lng: 80.19579648829937,
      },
      {
        lat: 12.977804079814957,
        lng: 80.19378741680615,
      },
      {
        lat: 12.97679151623538,
        lng: 80.20044533847533,
      },
      {
        lat: 12.976007431651857,
        lng: 80.19284350239607,
      },
      {
        lat: 12.97459142381359,
        lng: 80.19654306116539,
      },
      {
        lat: 12.976174467094301,
        lng: 80.19874042082814,
      },
      {
        lat: 12.979024723214563,
        lng: 80.1952027069324,
      },
      {
        lat: 12.975410207042822,
        lng: 80.19660406641658,
      },
      {
        lat: 12.976678408401158,
        lng: 80.19421156220501,
      },
      {
        lat: 12.978048014997619,
        lng: 80.19036428657645,
      },
      {
        lat: 12.979648242064018,
        lng: 80.19482780194501,
      },
      {
        lat: 12.976949806083953,
        lng: 80.19197614235802,
      },
      {
        lat: 12.979899712119211,
        lng: 80.19370261272812,
      },
      {
        lat: 12.976229395420987,
        lng: 80.19997418051086,
      },
      {
        lat: 12.973937598051794,
        lng: 80.19328497626131,
      },
      {
        lat: 12.979607038653134,
        lng: 80.19610234356075,
      },
      {
        lat: 12.978856963802588,
        lng: 80.1911196175055,
      },
      {
        lat: 12.974904357305192,
        lng: 80.19554433805297,
      },
      {
        lat: 12.976935049311177,
        lng: 80.19727214192754,
      },
      {
        lat: 12.97513546364654,
        lng: 80.19169385759292,
      },
      {
        lat: 12.975812645195433,
        lng: 80.19200317671731,
      },
      {
        lat: 12.981469476342692,
        lng: 80.19179176055012,
      },
      {
        lat: 12.975653089822666,
        lng: 80.19177772425385,
      },
      {
        lat: 12.977877911234641,
        lng: 80.19149803737851,
      },
      {
        lat: 12.97516468965794,
        lng: 80.19581535566974,
      },
      {
        lat: 12.978949755552124,
        lng: 80.20072290692917,
      },
      {
        lat: 12.98087387529057,
        lng: 80.19044055100518,
      },
      {
        lat: 12.976450845323873,
        lng: 80.19667709547532,
      },
      {
        lat: 12.978349221194545,
        lng: 80.19868658258646,
      },
      {
        lat: 12.977098949600533,
        lng: 80.19681783057241,
      },
      {
        lat: 12.980699974819508,
        lng: 80.19572930218855,
      },
      {
        lat: 12.9751331419255,
        lng: 80.19560917483938,
      },
      {
        lat: 12.980693725278936,
        lng: 80.19591639135231,
      },
      {
        lat: 12.981906875656811,
        lng: 80.19081135099385,
      },
      {
        lat: 12.977703435554432,
        lng: 80.19531529287045,
      },
      {
        lat: 12.975577768908483,
        lng: 80.19454677797066,
      },
      {
        lat: 12.976218941376121,
        lng: 80.19978701775497,
      },
      {
        lat: 12.980041028934217,
        lng: 80.19049980047303,
      },
      {
        lat: 12.978167781621288,
        lng: 80.200079327451,
      },
      {
        lat: 12.97588144651826,
        lng: 80.19212878285347,
      },
      {
        lat: 12.977872734208935,
        lng: 80.195693294467,
      },
      {
        lat: 12.979337226460846,
        lng: 80.19916236494517,
      },
      {
        lat: 12.978114197111237,
        lng: 80.19051199649077,
      },
      {
        lat: 12.980429002106296,
        lng: 80.19303839889784,
      },
      {
        lat: 12.97689236828402,
        lng: 80.19833284724739,
      },
      {
        lat: 12.974973392474315,
        lng: 80.1965902775713,
      },
      {
        lat: 12.981670623341918,
        lng: 80.1899983775545,
      },
      {
        lat: 12.976836385450591,
        lng: 80.19947025913348,
      },
      {
        lat: 12.9761993164628,
        lng: 80.19757835288914,
      },
      {
        lat: 12.975192256102625,
        lng: 80.1921845222849,
      },
      {
        lat: 12.980295868789455,
        lng: 80.19537309921603,
      },
      {
        lat: 12.978457986393792,
        lng: 80.19065516682016,
      },
      {
        lat: 12.978782262967814,
        lng: 80.19400486999916,
      },
      {
        lat: 12.974123117843183,
        lng: 80.1924115918083,
      },
      {
        lat: 12.978304444166522,
        lng: 80.19815799593817,
      },
      {
        lat: 12.979892976547994,
        lng: 80.19438472228175,
      },
      {
        lat: 12.981564106424432,
        lng: 80.1934536058855,
      },
      {
        lat: 12.980453681592076,
        lng: 80.19541359698627,
      },
      {
        lat: 12.973821263725288,
        lng: 80.1967513757579,
      },
      {
        lat: 12.980824359232289,
        lng: 80.19115591082844,
      },
      {
        lat: 12.976388516116133,
        lng: 80.20052507691288,
      },
      {
        lat: 12.977019261297631,
        lng: 80.19188916852447,
      },
      {
        lat: 12.975214861810551,
        lng: 80.19649864008923,
      },
      {
        lat: 12.977921932232158,
        lng: 80.20026544747104,
      },
      {
        lat: 12.978319710242264,
        lng: 80.19490380729674,
      },
      {
        lat: 12.980018932909035,
        lng: 80.19680312366276,
      },
      {
        lat: 12.98109004883476,
        lng: 80.19149155602848,
      },
      {
        lat: 12.97891994144326,
        lng: 80.19403609628422,
      },
      {
        lat: 12.979612406325698,
        lng: 80.1919394507391,
      },
      {
        lat: 12.975208826624334,
        lng: 80.19592242631983,
      },
      {
        lat: 12.978738934008621,
        lng: 80.20045676851402,
      },
      {
        lat: 12.980553475666566,
        lng: 80.19307741616807,
      },
      {
        lat: 12.974424617370149,
        lng: 80.19294455530824,
      },
      {
        lat: 12.976363697235554,
        lng: 80.20038672872288,
      },
      {
        lat: 12.974876722767954,
        lng: 80.19076396816344,
      },
      {
        lat: 12.980989205303342,
        lng: 80.19490241957779,
      },
      {
        lat: 12.97604533763128,
        lng: 80.19869376148205,
      },
      {
        lat: 12.978153529957185,
        lng: 80.19635953223695,
      },
      {
        lat: 12.97888467650253,
        lng: 80.19676459137645,
      },
      {
        lat: 12.978548952037098,
        lng: 80.19175885152843,
      },
      {
        lat: 12.981780742066553,
        lng: 80.19061600581122,
      },
      {
        lat: 12.976596593982501,
        lng: 80.19337038736417,
      },
      {
        lat: 12.978216274257537,
        lng: 80.19610420254024,
      },
      {
        lat: 12.976022032633102,
        lng: 80.19110020166184,
      },
      {
        lat: 12.981850033946852,
        lng: 80.19133136447141,
      },
      {
        lat: 12.974071371088291,
        lng: 80.1955334249006,
      },
    ],
    cases: 115,
    coords: [
      {
        lat: 12.978497005649695,
        lng: 80.20140046213093,
      },
      {
        lat: 12.978333369726837,
        lng: 80.20132919075841,
      },
      {
        lat: 12.978252851063663,
        lng: 80.20132987220872,
      },
      {
        lat: 12.977527939959579,
        lng: 80.20129603119248,
      },
      {
        lat: 12.977103758157902,
        lng: 80.20105976439187,
      },
      {
        lat: 12.97623696684603,
        lng: 80.20086721963726,
      },
      {
        lat: 12.975715611512257,
        lng: 80.19789341055066,
      },
      {
        lat: 12.975572394052428,
        lng: 80.19751484943185,
      },
      {
        lat: 12.975330473358198,
        lng: 80.19745693267913,
      },
      {
        lat: 12.974907750377234,
        lng: 80.19746051029338,
      },
      {
        lat: 12.974364857243541,
        lng: 80.19756504525606,
      },
      {
        lat: 12.97404156690748,
        lng: 80.19736790071151,
      },
      {
        lat: 12.973718519708193,
        lng: 80.19721073023626,
      },
      {
        lat: 12.973649620923668,
        lng: 80.19581214890407,
      },
      {
        lat: 12.973485787524675,
        lng: 80.19535381000965,
      },
      {
        lat: 12.97348080322075,
        lng: 80.19453434159222,
      },
      {
        lat: 12.973396029663986,
        lng: 80.19383547683255,
      },
      {
        lat: 12.973253541614712,
        lng: 80.19357683792121,
      },
      {
        lat: 12.973352609554443,
        lng: 80.19331615465897,
      },
      {
        lat: 12.974056783150973,
        lng: 80.19325023086483,
      },
      {
        lat: 12.974035194664483,
        lng: 80.19301055681247,
      },
      {
        lat: 12.97353158831535,
        lng: 80.19295485477333,
      },
      {
        lat: 12.973449732399962,
        lng: 80.19273567884329,
      },
      {
        lat: 12.973648476121374,
        lng: 80.19231424749171,
      },
      {
        lat: 12.973727292827054,
        lng: 80.19203374755739,
      },
      {
        lat: 12.974333006325791,
        lng: 80.19232844219844,
      },
      {
        lat: 12.974575534861968,
        lng: 80.19248629412402,
      },
      {
        lat: 12.974613119689094,
        lng: 80.19204623863824,
      },
      {
        lat: 12.974469294387317,
        lng: 80.19156774234662,
      },
      {
        lat: 12.974547381682664,
        lng: 80.191167320205,
      },
      {
        lat: 12.9745296929927,
        lng: 80.19076093922152,
      },
      {
        lat: 12.974939334731223,
        lng: 80.19057052312561,
      },
      {
        lat: 12.97517874894843,
        lng: 80.19053450613895,
      },
      {
        lat: 12.9755719984719,
        lng: 80.19046319644204,
      },
      {
        lat: 12.976000203126965,
        lng: 80.19051055857824,
      },
      {
        lat: 12.976805161723814,
        lng: 80.19058872291002,
      },
      {
        lat: 12.97709675022147,
        lng: 80.19068822740745,
      },
      {
        lat: 12.977284403625282,
        lng: 80.19058466696157,
      },
      {
        lat: 12.977453390745401,
        lng: 80.19022633377764,
      },
      {
        lat: 12.977657229630596,
        lng: 80.18996967792033,
      },
      {
        lat: 12.977982016025322,
        lng: 80.18989894764462,
      },
      {
        lat: 12.980223976759062,
        lng: 80.18984598258746,
      },
      {
        lat: 12.980548866520543,
        lng: 80.18979224681789,
      },
      {
        lat: 12.980890561963873,
        lng: 80.18968738267434,
      },
      {
        lat: 12.981507453690158,
        lng: 80.18980112942724,
      },
      {
        lat: 12.981816674803815,
        lng: 80.18998546160041,
      },
      {
        lat: 12.981957839098065,
        lng: 80.19068107751372,
      },
      {
        lat: 12.982079716908373,
        lng: 80.19101995365136,
      },
      {
        lat: 12.98226964438038,
        lng: 80.1912902723428,
      },
      {
        lat: 12.982357910826801,
        lng: 80.19173140522861,
      },
      {
        lat: 12.982277706986604,
        lng: 80.19261584382964,
      },
      {
        lat: 12.982180077263898,
        lng: 80.19344944376708,
      },
      {
        lat: 12.981944487616472,
        lng: 80.19411425748483,
      },
      {
        lat: 12.980979121339224,
        lng: 80.19580497035477,
      },
      {
        lat: 12.980400079013608,
        lng: 80.19628574160987,
      },
      {
        lat: 12.980196653595456,
        lng: 80.19661037549209,
      },
      {
        lat: 12.980114072320095,
        lng: 80.19710394044945,
      },
      {
        lat: 12.980013548328657,
        lng: 80.19746169421235,
      },
      {
        lat: 12.979827238692776,
        lng: 80.19778618323927,
      },
      {
        lat: 12.979778578881543,
        lng: 80.19822847496728,
      },
      {
        lat: 12.97973074600428,
        lng: 80.19880672274549,
      },
      {
        lat: 12.979697961574363,
        lng: 80.19904493554341,
      },
      {
        lat: 12.979802930335893,
        lng: 80.19941794554879,
      },
      {
        lat: 12.979788088622216,
        lng: 80.19979196954147,
      },
      {
        lat: 12.97946526619567,
        lng: 80.2001855954357,
      },
      {
        lat: 12.97933020053945,
        lng: 80.2004926553904,
      },
      {
        lat: 12.979213904533676,
        lng: 80.2010714825896,
      },
      {
        lat: 12.979027491530822,
        lng: 80.20137897711022,
      },
      {
        lat: 12.978719820918366,
        lng: 80.20144956253081,
      },
      {
        lat: 12.978497005649695,
        lng: 80.20140046213093,
      },
    ],
    properties: {
      Ward_No: 168,
      Zone_Name: "PERUNGUDI",
      fillColor: "#f6d04d",
      zname: "PERUNGUDI(168)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.921836044372599,
        lng: 80.21114313621086,
      },
      {
        lat: 12.912687459649582,
        lng: 80.21274054466942,
      },
      {
        lat: 12.933230371711254,
        lng: 80.19982557375779,
      },
      {
        lat: 12.917497333239066,
        lng: 80.21774875485941,
      },
      {
        lat: 12.923808955383025,
        lng: 80.21558937994162,
      },
      {
        lat: 12.938072919907615,
        lng: 80.20497663593534,
      },
      {
        lat: 12.915410831029837,
        lng: 80.20948250514388,
      },
      {
        lat: 12.937401570498855,
        lng: 80.19886570166052,
      },
      {
        lat: 12.914270388729566,
        lng: 80.20914661556645,
      },
      {
        lat: 12.915869151128446,
        lng: 80.20904837415968,
      },
      {
        lat: 12.921771237168285,
        lng: 80.21768785060658,
      },
      {
        lat: 12.924992684057264,
        lng: 80.21194843629901,
      },
      {
        lat: 12.909838326311451,
        lng: 80.20930123414774,
      },
      {
        lat: 12.913188019998792,
        lng: 80.21336700824243,
      },
      {
        lat: 12.935596483843407,
        lng: 80.20234882973027,
      },
      {
        lat: 12.914774402109169,
        lng: 80.22505620196956,
      },
      {
        lat: 12.9132805820582,
        lng: 80.21575816351655,
      },
      {
        lat: 12.934013266062342,
        lng: 80.1975397659846,
      },
      {
        lat: 12.924412124634397,
        lng: 80.2058888122274,
      },
      {
        lat: 12.92408049781614,
        lng: 80.20644966757573,
      },
      {
        lat: 12.921103564710295,
        lng: 80.21842605179249,
      },
      {
        lat: 12.924831350469926,
        lng: 80.2117291943219,
      },
      {
        lat: 12.910094775785968,
        lng: 80.20937551403111,
      },
      {
        lat: 12.922810873712121,
        lng: 80.21351809111299,
      },
      {
        lat: 12.921572876697436,
        lng: 80.22397274842183,
      },
      {
        lat: 12.909929479395451,
        lng: 80.21349584967783,
      },
      {
        lat: 12.938911581185016,
        lng: 80.20403390328546,
      },
      {
        lat: 12.91879282945718,
        lng: 80.21808862045273,
      },
      {
        lat: 12.92084111163655,
        lng: 80.21793159144751,
      },
      {
        lat: 12.928174789039012,
        lng: 80.20428801804246,
      },
      {
        lat: 12.933818760386796,
        lng: 80.1990784926002,
      },
      {
        lat: 12.909786418390574,
        lng: 80.21439527953643,
      },
      {
        lat: 12.920469186407514,
        lng: 80.22578781775573,
      },
      {
        lat: 12.91552204202636,
        lng: 80.21419128889117,
      },
      {
        lat: 12.920538742406444,
        lng: 80.21180329637585,
      },
      {
        lat: 12.930425924073042,
        lng: 80.19886034051481,
      },
      {
        lat: 12.92258950183407,
        lng: 80.21616270124672,
      },
      {
        lat: 12.922244162159846,
        lng: 80.21106525419874,
      },
      {
        lat: 12.917965140965533,
        lng: 80.20993607281183,
      },
      {
        lat: 12.930512224988078,
        lng: 80.20097283356809,
      },
      {
        lat: 12.932186886155097,
        lng: 80.19718542493368,
      },
      {
        lat: 12.925817906531908,
        lng: 80.20461320726504,
      },
      {
        lat: 12.911814298410107,
        lng: 80.2120344262352,
      },
      {
        lat: 12.923983155749658,
        lng: 80.21540780742697,
      },
      {
        lat: 12.921278838021097,
        lng: 80.21234375959475,
      },
      {
        lat: 12.918824265440858,
        lng: 80.2110593542783,
      },
      {
        lat: 12.923981191616564,
        lng: 80.21457902132082,
      },
      {
        lat: 12.92643335728649,
        lng: 80.21006406751702,
      },
      {
        lat: 12.936566578180086,
        lng: 80.19642815623057,
      },
      {
        lat: 12.926063093277921,
        lng: 80.20556546956524,
      },
      {
        lat: 12.935418222037818,
        lng: 80.2013910577782,
      },
      {
        lat: 12.922095764262153,
        lng: 80.21153678792139,
      },
      {
        lat: 12.934439640205555,
        lng: 80.20222090441746,
      },
      {
        lat: 12.931670794072717,
        lng: 80.2019846477117,
      },
      {
        lat: 12.929700889933315,
        lng: 80.21044414780931,
      },
      {
        lat: 12.928272584681244,
        lng: 80.19894876544676,
      },
      {
        lat: 12.930278452981161,
        lng: 80.20512272258449,
      },
      {
        lat: 12.926886463042152,
        lng: 80.19600350518245,
      },
      {
        lat: 12.935107737850933,
        lng: 80.2026989871471,
      },
      {
        lat: 12.928608875032664,
        lng: 80.19998624208911,
      },
      {
        lat: 12.934803728812192,
        lng: 80.1933297645936,
      },
      {
        lat: 12.926127241950114,
        lng: 80.19848563064812,
      },
      {
        lat: 12.928757272750428,
        lng: 80.19743181716518,
      },
      {
        lat: 12.925136664022338,
        lng: 80.21278743003495,
      },
      {
        lat: 12.919482536855183,
        lng: 80.22295661363984,
      },
      {
        lat: 12.90988494444503,
        lng: 80.21304054447874,
      },
      {
        lat: 12.91033151795362,
        lng: 80.21088049059888,
      },
      {
        lat: 12.926899990782967,
        lng: 80.20831391343427,
      },
      {
        lat: 12.931897769818308,
        lng: 80.19793561300145,
      },
      {
        lat: 12.915419345212078,
        lng: 80.21553783734645,
      },
      {
        lat: 12.925520091837404,
        lng: 80.2055693240147,
      },
      {
        lat: 12.934014694201588,
        lng: 80.20220194703495,
      },
      {
        lat: 12.917418878191816,
        lng: 80.22417336371424,
      },
      {
        lat: 12.914631209306028,
        lng: 80.20933886907703,
      },
      {
        lat: 12.916425298383258,
        lng: 80.22020897970079,
      },
      {
        lat: 12.911180866118604,
        lng: 80.21580739195001,
      },
      {
        lat: 12.928627381927154,
        lng: 80.20374062536153,
      },
      {
        lat: 12.937984678029979,
        lng: 80.19748547987415,
      },
      {
        lat: 12.918199681780523,
        lng: 80.22233791431202,
      },
      {
        lat: 12.922567319320526,
        lng: 80.20592191768102,
      },
      {
        lat: 12.92001255090105,
        lng: 80.21984065685747,
      },
      {
        lat: 12.921805022886256,
        lng: 80.21994329752725,
      },
      {
        lat: 12.931241698272268,
        lng: 80.19782773126437,
      },
      {
        lat: 12.91628937298904,
        lng: 80.2257000632937,
      },
      {
        lat: 12.915795939023804,
        lng: 80.22538996542131,
      },
      {
        lat: 12.919454574130617,
        lng: 80.21577621427672,
      },
      {
        lat: 12.918992799681522,
        lng: 80.21749980136731,
      },
      {
        lat: 12.936248007696712,
        lng: 80.19827483680598,
      },
      {
        lat: 12.939117016728314,
        lng: 80.19900108993565,
      },
      {
        lat: 12.929294275754028,
        lng: 80.19986950439646,
      },
      {
        lat: 12.921389637781456,
        lng: 80.21725231647619,
      },
      {
        lat: 12.922435252454811,
        lng: 80.21600964223731,
      },
      {
        lat: 12.928932440384395,
        lng: 80.20738166501722,
      },
      {
        lat: 12.937886004844124,
        lng: 80.19922840075908,
      },
      {
        lat: 12.92159507325665,
        lng: 80.2239687895072,
      },
      {
        lat: 12.91882158847314,
        lng: 80.21591818376659,
      },
      {
        lat: 12.92208957079905,
        lng: 80.21838831322653,
      },
      {
        lat: 12.928525436386819,
        lng: 80.1991124610409,
      },
      {
        lat: 12.938344260750489,
        lng: 80.20320543077582,
      },
      {
        lat: 12.919692333320866,
        lng: 80.22329270782906,
      },
      {
        lat: 12.917952513957472,
        lng: 80.22557997232266,
      },
      {
        lat: 12.9227213517327,
        lng: 80.21600384656563,
      },
      {
        lat: 12.930749874000407,
        lng: 80.21009309580039,
      },
      {
        lat: 12.928125045552465,
        lng: 80.19867833667581,
      },
      {
        lat: 12.925257243170517,
        lng: 80.19649212607278,
      },
      {
        lat: 12.919393607899103,
        lng: 80.21495895311426,
      },
      {
        lat: 12.924146156617404,
        lng: 80.21539135273346,
      },
      {
        lat: 12.91498746921345,
        lng: 80.21277389264007,
      },
      {
        lat: 12.928652253676859,
        lng: 80.20904836654064,
      },
      {
        lat: 12.939074591532954,
        lng: 80.199182936833,
      },
      {
        lat: 12.914115762183695,
        lng: 80.21705789055494,
      },
      {
        lat: 12.917747451456073,
        lng: 80.22346635236983,
      },
      {
        lat: 12.930840635068366,
        lng: 80.20220481121838,
      },
      {
        lat: 12.919193305749852,
        lng: 80.22423403315466,
      },
      {
        lat: 12.933627885976444,
        lng: 80.1963489147923,
      },
      {
        lat: 12.919686802070908,
        lng: 80.22497460754377,
      },
      {
        lat: 12.91858041526479,
        lng: 80.22636839918347,
      },
      {
        lat: 12.927921572962184,
        lng: 80.19993732030046,
      },
      {
        lat: 12.9248408514277,
        lng: 80.21540393192538,
      },
      {
        lat: 12.921843273520349,
        lng: 80.21586290469827,
      },
      {
        lat: 12.924422544805372,
        lng: 80.19802153445478,
      },
      {
        lat: 12.923311736923958,
        lng: 80.19761846791867,
      },
      {
        lat: 12.914566418849605,
        lng: 80.21123836693347,
      },
      {
        lat: 12.914468228970293,
        lng: 80.21762227157205,
      },
      {
        lat: 12.917331756709016,
        lng: 80.22586179335764,
      },
      {
        lat: 12.928899034087106,
        lng: 80.2051188818467,
      },
      {
        lat: 12.918046660579803,
        lng: 80.21324306560042,
      },
      {
        lat: 12.939894258613663,
        lng: 80.20088020555107,
      },
    ],
    cases: 128,
    coords: [
      {
        lat: 12.921522915444063,
        lng: 80.2275064930741,
      },
      {
        lat: 12.913701827089664,
        lng: 80.22626435461703,
      },
      {
        lat: 12.915476855973013,
        lng: 80.21938830096032,
      },
      {
        lat: 12.912604385667134,
        lng: 80.21722417907927,
      },
      {
        lat: 12.909308839029675,
        lng: 80.2140581418619,
      },
      {
        lat: 12.909425698106817,
        lng: 80.21123833863675,
      },
      {
        lat: 12.909497283351326,
        lng: 80.20879072900948,
      },
      {
        lat: 12.91212009002115,
        lng: 80.20922856669252,
      },
      {
        lat: 12.913560232119643,
        lng: 80.2093713383971,
      },
      {
        lat: 12.914202221913788,
        lng: 80.20943477618223,
      },
      {
        lat: 12.9142515184736,
        lng: 80.2089866967489,
      },
      {
        lat: 12.914319620859269,
        lng: 80.20877950704674,
      },
      {
        lat: 12.914352310700092,
        lng: 80.20845209260519,
      },
      {
        lat: 12.91426498361135,
        lng: 80.20834952500992,
      },
      {
        lat: 12.9141089258207,
        lng: 80.20835084576744,
      },
      {
        lat: 12.914056382960448,
        lng: 80.20826520156213,
      },
      {
        lat: 12.9140561735217,
        lng: 80.20823076777904,
      },
      {
        lat: 12.914176609328814,
        lng: 80.20807478849896,
      },
      {
        lat: 12.914243769239876,
        lng: 80.20771264677275,
      },
      {
        lat: 12.91422286902676,
        lng: 80.20712741921017,
      },
      {
        lat: 12.914291704447818,
        lng: 80.20704074774893,
      },
      {
        lat: 12.914725617187994,
        lng: 80.20710594654413,
      },
      {
        lat: 12.915246647578272,
        lng: 80.20723927915158,
      },
      {
        lat: 12.915856366409097,
        lng: 80.20769899894472,
      },
      {
        lat: 12.916188020851648,
        lng: 80.2080577654016,
      },
      {
        lat: 12.916553935925696,
        lng: 80.20834737079073,
      },
      {
        lat: 12.91669359643617,
        lng: 80.20850114880808,
      },
      {
        lat: 12.916936981315388,
        lng: 80.20860239564581,
      },
      {
        lat: 12.917353344862372,
        lng: 80.2086333074088,
      },
      {
        lat: 12.917787781199362,
        lng: 80.20878459066208,
      },
      {
        lat: 12.917962121218764,
        lng: 80.20893807517768,
      },
      {
        lat: 12.918119540361285,
        lng: 80.20916057401055,
      },
      {
        lat: 12.918380369714491,
        lng: 80.20927889098907,
      },
      {
        lat: 12.91862302155831,
        lng: 80.20925961958568,
      },
      {
        lat: 12.918812711663987,
        lng: 80.20908583641081,
      },
      {
        lat: 12.919054525752811,
        lng: 80.20892882987485,
      },
      {
        lat: 12.919297177596638,
        lng: 80.20890955847159,
      },
      {
        lat: 12.919800763512981,
        lng: 80.20902582093825,
      },
      {
        lat: 12.92061667444904,
        lng: 80.2091738756728,
      },
      {
        lat: 12.921381613315518,
        lng: 80.20949453957581,
      },
      {
        lat: 12.922441432728062,
        lng: 80.20982992560614,
      },
      {
        lat: 12.922754386064193,
        lng: 80.20996501922346,
      },
      {
        lat: 12.922944809205658,
        lng: 80.20991175428968,
      },
      {
        lat: 12.923065140293398,
        lng: 80.20973855811792,
      },
      {
        lat: 12.923185576100568,
        lng: 80.20958257883804,
      },
      {
        lat: 12.923341843329798,
        lng: 80.20961569186355,
      },
      {
        lat: 12.923498634156074,
        lng: 80.20973488934699,
      },
      {
        lat: 12.923829660282383,
        lng: 80.20999035445475,
      },
      {
        lat: 12.923848256669258,
        lng: 80.21019681040264,
      },
      {
        lat: 12.923952399915736,
        lng: 80.21021314678912,
      },
      {
        lat: 12.924090489635585,
        lng: 80.2101086714331,
      },
      {
        lat: 12.924175931775581,
        lng: 80.20990133498009,
      },
      {
        lat: 12.924156707072278,
        lng: 80.20959157768266,
      },
      {
        lat: 12.924242149212334,
        lng: 80.20938424122951,
      },
      {
        lat: 12.924275362650194,
        lng: 80.20914291124575,
      },
      {
        lat: 12.924170381648725,
        lng: 80.2089888397268,
      },
      {
        lat: 12.924147491767316,
        lng: 80.20807649122443,
      },
      {
        lat: 12.92402443797584,
        lng: 80.20780204821516,
      },
      {
        lat: 12.924002385849366,
        lng: 80.20702743484526,
      },
      {
        lat: 12.923863039497075,
        lng: 80.20692530750249,
      },
      {
        lat: 12.92330722487855,
        lng: 80.2067750515053,
      },
      {
        lat: 12.92295896371723,
        lng: 80.20653695004,
      },
      {
        lat: 12.921986785551544,
        lng: 80.20635578227986,
      },
      {
        lat: 12.921604892074981,
        lng: 80.206290143232,
      },
      {
        lat: 12.921499073318515,
        lng: 80.20599833658035,
      },
      {
        lat: 12.921547427403953,
        lng: 80.20539530512278,
      },
      {
        lat: 12.92173292873487,
        lng: 80.20453284628518,
      },
      {
        lat: 12.921836443664926,
        lng: 80.20444588132224,
      },
      {
        lat: 12.922460570107816,
        lng: 80.20442338140045,
      },
      {
        lat: 12.922755660092632,
        lng: 80.20447253731092,
      },
      {
        lat: 12.923035609429759,
        lng: 80.20488339469502,
      },
      {
        lat: 12.923226975045656,
        lng: 80.20498508178525,
      },
      {
        lat: 12.923747586558319,
        lng: 80.20504954682652,
      },
      {
        lat: 12.924112244999872,
        lng: 80.2051325495167,
      },
      {
        lat: 12.924475542089331,
        lng: 80.2049917326165,
      },
      {
        lat: 12.924821289985886,
        lng: 80.20481662868406,
      },
      {
        lat: 12.925203497620574,
        lng: 80.20493391840658,
      },
      {
        lat: 12.925637933957733,
        lng: 80.20508520165959,
      },
      {
        lat: 12.925742181923413,
        lng: 80.20511875493781,
      },
      {
        lat: 12.92575966600499,
        lng: 80.20382923827307,
      },
      {
        lat: 12.925929412052911,
        lng: 80.20310737322617,
      },
      {
        lat: 12.928105833401611,
        lng: 80.2030601364701,
      },
      {
        lat: 12.928131174077622,
        lng: 80.20245476211421,
      },
      {
        lat: 12.928216485269571,
        lng: 80.20216586872654,
      },
      {
        lat: 12.926114508543256,
        lng: 80.20013764152516,
      },
      {
        lat: 12.924743497307134,
        lng: 80.19899655924165,
      },
      {
        lat: 12.922064120079861,
        lng: 80.19747062725708,
      },
      {
        lat: 12.922847918025457,
        lng: 80.19746399377183,
      },
      {
        lat: 12.923651548214503,
        lng: 80.19763933576294,
      },
      {
        lat: 12.92388028642198,
        lng: 80.19754632823114,
      },
      {
        lat: 12.924307771371247,
        lng: 80.19619940331677,
      },
      {
        lat: 12.924419786390033,
        lng: 80.19576586491522,
      },
      {
        lat: 12.92483043505393,
        lng: 80.19542087076066,
      },
      {
        lat: 12.925722181721717,
        lng: 80.19500350120335,
      },
      {
        lat: 12.926203418201299,
        lng: 80.19495389254375,
      },
      {
        lat: 12.926938122281795,
        lng: 80.19510704995912,
      },
      {
        lat: 12.927235648122357,
        lng: 80.19501346025984,
      },
      {
        lat: 12.927717161552934,
        lng: 80.19500938508679,
      },
      {
        lat: 12.929071646707872,
        lng: 80.19527113671049,
      },
      {
        lat: 12.930404033505484,
        lng: 80.19566968284965,
      },
      {
        lat: 12.930702667150547,
        lng: 80.19575822709646,
      },
      {
        lat: 12.930740770939797,
        lng: 80.19448290136148,
      },
      {
        lat: 12.931497573377907,
        lng: 80.19449926426148,
      },
      {
        lat: 12.931528534172738,
        lng: 80.19581954131534,
      },
      {
        lat: 12.932330918081645,
        lng: 80.19578998261709,
      },
      {
        lat: 12.933088412897508,
        lng: 80.19592017923348,
      },
      {
        lat: 12.93359271706357,
        lng: 80.19589314326123,
      },
      {
        lat: 12.93384383057978,
        lng: 80.19570887470064,
      },
      {
        lat: 12.934089958974782,
        lng: 80.19470500338218,
      },
      {
        lat: 12.934403490247094,
        lng: 80.19347288246341,
      },
      {
        lat: 12.934629043516134,
        lng: 80.19285623983647,
      },
      {
        lat: 12.93481192330159,
        lng: 80.1927636204164,
      },
      {
        lat: 12.935018286200512,
        lng: 80.19276187391367,
      },
      {
        lat: 12.935248270688247,
        lng: 80.19287376707146,
      },
      {
        lat: 12.935640698311051,
        lng: 80.19330303624413,
      },
      {
        lat: 12.935963507447445,
        lng: 80.19359628712454,
      },
      {
        lat: 12.936011443003231,
        lng: 80.1939374001619,
      },
      {
        lat: 12.93610454460288,
        lng: 80.1941642913712,
      },
      {
        lat: 12.936334113663861,
        lng: 80.1942078842992,
      },
      {
        lat: 12.936633301211437,
        lng: 80.19438749551934,
      },
      {
        lat: 12.936729726225108,
        lng: 80.19516078856724,
      },
      {
        lat: 12.936640363466223,
        lng: 80.1955485994261,
      },
      {
        lat: 12.936527656069467,
        lng: 80.19586830411134,
      },
      {
        lat: 12.936597689982642,
        lng: 80.19607262263324,
      },
      {
        lat: 12.936827120568042,
        lng: 80.19609344881788,
      },
      {
        lat: 12.9372616677721,
        lng: 80.19590762781043,
      },
      {
        lat: 12.937445239935476,
        lng: 80.1959288421067,
      },
      {
        lat: 12.937699538390174,
        lng: 80.19626820864154,
      },
      {
        lat: 12.938046523017718,
        lng: 80.19676616615551,
      },
      {
        lat: 12.938391845938307,
        lng: 80.19699092275037,
      },
      {
        lat: 12.938712993367691,
        lng: 80.19701097271168,
      },
      {
        lat: 12.939082076352683,
        lng: 80.19737213571038,
      },
      {
        lat: 12.93926827955233,
        lng: 80.19782591812898,
      },
      {
        lat: 12.939707119499325,
        lng: 80.19834586616288,
      },
      {
        lat: 12.94072195923268,
        lng: 80.19931629766592,
      },
      {
        lat: 12.940841700686452,
        lng: 80.19937455485322,
      },
      {
        lat: 12.938816282019356,
        lng: 80.20541015827985,
      },
      {
        lat: 12.932182852302754,
        lng: 80.20363044907887,
      },
      {
        lat: 12.931021415563254,
        lng: 80.2116512588533,
      },
      {
        lat: 12.92960895328735,
        lng: 80.21156307563493,
      },
      {
        lat: 12.928633087177207,
        lng: 80.21140443922128,
      },
      {
        lat: 12.928330141171674,
        lng: 80.21134024496335,
      },
      {
        lat: 12.928395545158924,
        lng: 80.21103927967353,
      },
      {
        lat: 12.928427130579848,
        lng: 80.21070522151486,
      },
      {
        lat: 12.92825742870768,
        lng: 80.21043962507139,
      },
      {
        lat: 12.926944798025453,
        lng: 80.21018370153077,
      },
      {
        lat: 12.926744322785474,
        lng: 80.21038567271046,
      },
      {
        lat: 12.924859297276155,
        lng: 80.21550862606952,
      },
      {
        lat: 12.924355673017239,
        lng: 80.21561302562516,
      },
      {
        lat: 12.924296825441147,
        lng: 80.21681267478915,
      },
      {
        lat: 12.923677707902561,
        lng: 80.21680035293298,
      },
      {
        lat: 12.923648851305558,
        lng: 80.21787185526144,
      },
      {
        lat: 12.923012581798867,
        lng: 80.21794748660862,
      },
      {
        lat: 12.92260644418384,
        lng: 80.21805629350645,
      },
      {
        lat: 12.921522915444063,
        lng: 80.2275064930741,
      },
    ],
    properties: {
      Ward_No: 190,
      Zone_Name: "PERUNGUDI",
      fillColor: "#de4307",
      zname: "PERUNGUDI(190)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.963730879764423,
        lng: 80.20302299780097,
      },
      {
        lat: 12.944126960529204,
        lng: 80.2077866485302,
      },
      {
        lat: 12.95511985959018,
        lng: 80.1925367849377,
      },
      {
        lat: 12.948258710033963,
        lng: 80.20173073000251,
      },
      {
        lat: 12.956918756393513,
        lng: 80.19654371715448,
      },
      {
        lat: 12.958066023736908,
        lng: 80.20602291752803,
      },
      {
        lat: 12.951048686244652,
        lng: 80.20668025058126,
      },
      {
        lat: 12.945219613127788,
        lng: 80.20483577655888,
      },
      {
        lat: 12.95146382652942,
        lng: 80.20386190634062,
      },
      {
        lat: 12.94152024504448,
        lng: 80.20551153169752,
      },
      {
        lat: 12.942721966788513,
        lng: 80.20231544329076,
      },
      {
        lat: 12.961001224653621,
        lng: 80.20157024089548,
      },
      {
        lat: 12.94033843040173,
        lng: 80.20504772609925,
      },
      {
        lat: 12.964488245159332,
        lng: 80.2088017186444,
      },
      {
        lat: 12.96460635617006,
        lng: 80.20618995375729,
      },
      {
        lat: 12.953323506266239,
        lng: 80.20868577223708,
      },
      {
        lat: 12.961784378099148,
        lng: 80.21341307020786,
      },
      {
        lat: 12.952269901467835,
        lng: 80.20498022488304,
      },
      {
        lat: 12.958598256615309,
        lng: 80.20086427628691,
      },
      {
        lat: 12.948411986096243,
        lng: 80.2087947220309,
      },
      {
        lat: 12.948712502959712,
        lng: 80.20396388773015,
      },
      {
        lat: 12.95643340404175,
        lng: 80.1973228453301,
      },
      {
        lat: 12.958058584481108,
        lng: 80.20602665120163,
      },
      {
        lat: 12.957484063820612,
        lng: 80.1992560694177,
      },
      {
        lat: 12.955333313470673,
        lng: 80.1949622756624,
      },
    ],
    cases: 25,
    coords: [
      {
        lat: 12.967738154324746,
        lng: 80.21762851364103,
      },
      {
        lat: 12.965595280288143,
        lng: 80.21710940544288,
      },
      {
        lat: 12.96408052581002,
        lng: 80.21633684834276,
      },
      {
        lat: 12.961558250989338,
        lng: 80.21543002235555,
      },
      {
        lat: 12.95777375314446,
        lng: 80.21389129782172,
      },
      {
        lat: 12.954132845584153,
        lng: 80.21231565909791,
      },
      {
        lat: 12.950998520791126,
        lng: 80.2112712172556,
      },
      {
        lat: 12.945880981832184,
        lng: 80.20927968826804,
      },
      {
        lat: 12.944654927782604,
        lng: 80.20868318256207,
      },
      {
        lat: 12.943286151939514,
        lng: 80.2082663794887,
      },
      {
        lat: 12.942924462193567,
        lng: 80.20791245108083,
      },
      {
        lat: 12.94256146971086,
        lng: 80.20734434000931,
      },
      {
        lat: 12.942018500846313,
        lng: 80.20674205317633,
      },
      {
        lat: 12.941044326882313,
        lng: 80.20617911469212,
      },
      {
        lat: 12.938816282019356,
        lng: 80.20541015827985,
      },
      {
        lat: 12.940841700686452,
        lng: 80.19937455485322,
      },
      {
        lat: 12.941687894081227,
        lng: 80.1997862489285,
      },
      {
        lat: 12.94368647427757,
        lng: 80.20038406813731,
      },
      {
        lat: 12.946694216708329,
        lng: 80.20101888237458,
      },
      {
        lat: 12.948990184269542,
        lng: 80.2015003451407,
      },
      {
        lat: 12.95068999234507,
        lng: 80.20198685335934,
      },
      {
        lat: 12.951469308567326,
        lng: 80.20193472197337,
      },
      {
        lat: 12.952132870930033,
        lng: 80.20170142692075,
      },
      {
        lat: 12.952749605541626,
        lng: 80.20130915277693,
      },
      {
        lat: 12.953113011027833,
        lng: 80.20073687930142,
      },
      {
        lat: 12.95349422212833,
        lng: 80.19932204226892,
      },
      {
        lat: 12.953528310125469,
        lng: 80.19738648097898,
      },
      {
        lat: 12.953305884059018,
        lng: 80.19474728526188,
      },
      {
        lat: 12.952978920655108,
        lng: 80.19377103208326,
      },
      {
        lat: 12.952542157841698,
        lng: 80.19359258519839,
      },
      {
        lat: 12.95164861099126,
        lng: 80.19371398709328,
      },
      {
        lat: 12.951532857131708,
        lng: 80.19353282342622,
      },
      {
        lat: 12.951531195424701,
        lng: 80.19325962250697,
      },
      {
        lat: 12.951733957958377,
        lng: 80.19266594067922,
      },
      {
        lat: 12.952053582156383,
        lng: 80.1924355564645,
      },
      {
        lat: 12.952970612120058,
        lng: 80.19240502748681,
      },
      {
        lat: 12.95381774664614,
        lng: 80.19219294673063,
      },
      {
        lat: 12.954046207902442,
        lng: 80.19205440571231,
      },
      {
        lat: 12.954112226023847,
        lng: 80.19159848867929,
      },
      {
        lat: 12.954221194579777,
        lng: 80.19066408192577,
      },
      {
        lat: 12.95447258504698,
        lng: 80.19052534685176,
      },
      {
        lat: 12.955092366121486,
        lng: 80.19063394105994,
      },
      {
        lat: 12.956285515946188,
        lng: 80.19076045061482,
      },
      {
        lat: 12.956475734937666,
        lng: 80.19187446858835,
      },
      {
        lat: 12.956705442474231,
        lng: 80.19194082825956,
      },
      {
        lat: 12.957142343763165,
        lng: 80.19214204188779,
      },
      {
        lat: 12.95721294002109,
        lng: 80.19219378208838,
      },
      {
        lat: 12.957187136540972,
        lng: 80.19353050911454,
      },
      {
        lat: 12.956777851027951,
        lng: 80.1936122189537,
      },
      {
        lat: 12.957136659971535,
        lng: 80.19559662938207,
      },
      {
        lat: 12.958318949572897,
        lng: 80.19563357092838,
      },
      {
        lat: 12.958290570376288,
        lng: 80.19615023437672,
      },
      {
        lat: 12.9584337433719,
        lng: 80.19636811132555,
      },
      {
        lat: 12.958812746105636,
        lng: 80.19649009724664,
      },
      {
        lat: 12.962408322759584,
        lng: 80.19683524750722,
      },
      {
        lat: 12.962463597970373,
        lng: 80.19814931165332,
      },
      {
        lat: 12.9647961811689,
        lng: 80.19814521958664,
      },
      {
        lat: 12.964813051131678,
        lng: 80.19637518991142,
      },
      {
        lat: 12.966760842880674,
        lng: 80.19657564991637,
      },
      {
        lat: 12.96729088363157,
        lng: 80.19668067462953,
      },
      {
        lat: 12.967097962928074,
        lng: 80.19760219628625,
      },
      {
        lat: 12.96707750416581,
        lng: 80.19786519483951,
      },
      {
        lat: 12.967232437959765,
        lng: 80.19795149206307,
      },
      {
        lat: 12.967454609269277,
        lng: 80.19821243717509,
      },
      {
        lat: 12.967411693598608,
        lng: 80.19840991943549,
      },
      {
        lat: 12.967104889835452,
        lng: 80.19874104775205,
      },
      {
        lat: 12.966929630489215,
        lng: 80.19893965007127,
      },
      {
        lat: 12.96699660158543,
        lng: 80.19907049598025,
      },
      {
        lat: 12.967152068218416,
        lng: 80.19924439716272,
      },
      {
        lat: 12.96715393315502,
        lng: 80.19955101101876,
      },
      {
        lat: 12.966791214705331,
        lng: 80.20156907557356,
      },
      {
        lat: 12.966305821351835,
        lng: 80.20155128146635,
      },
      {
        lat: 12.965334901435313,
        lng: 80.20149379226197,
      },
      {
        lat: 12.965358424021998,
        lng: 80.20173451647243,
      },
      {
        lat: 12.96584608194114,
        lng: 80.20212462740508,
      },
      {
        lat: 12.965891928226528,
        lng: 80.20240896691857,
      },
      {
        lat: 12.96583588033041,
        lng: 80.20407400216702,
      },
      {
        lat: 12.96491432569326,
        lng: 80.20850602917983,
      },
      {
        lat: 12.964703077583843,
        lng: 80.21004096522519,
      },
      {
        lat: 12.96455720205338,
        lng: 80.21144393530302,
      },
      {
        lat: 12.963983579583028,
        lng: 80.21142688790154,
      },
      {
        lat: 12.963674111624611,
        lng: 80.21131999642364,
      },
      {
        lat: 12.963233365668804,
        lng: 80.21138943292225,
      },
      {
        lat: 12.962916626714263,
        lng: 80.21371373798004,
      },
      {
        lat: 12.968553260374847,
        lng: 80.21593536587697,
      },
      {
        lat: 12.968393595970388,
        lng: 80.21613115341376,
      },
      {
        lat: 12.967738154324746,
        lng: 80.21762851364103,
      },
    ],
    properties: {
      Ward_No: 188,
      Zone_Name: "PERUNGUDI",
      fillColor: "#8bc24c",
      zname: "PERUNGUDI(188)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.985443031813764,
        lng: 80.22827626453238,
      },
      {
        lat: 12.970032457089665,
        lng: 80.22324885669678,
      },
      {
        lat: 12.967543361869694,
        lng: 80.22226879427917,
      },
      {
        lat: 12.984542146350726,
        lng: 80.2261754554655,
      },
      {
        lat: 12.971948253221955,
        lng: 80.2264514557923,
      },
      {
        lat: 12.981429376979312,
        lng: 80.23044528469225,
      },
      {
        lat: 12.974651777628601,
        lng: 80.22189767254152,
      },
      {
        lat: 12.973464523787367,
        lng: 80.22398447340753,
      },
      {
        lat: 12.98375749002762,
        lng: 80.22941283937469,
      },
      {
        lat: 12.978503508714326,
        lng: 80.23167799686277,
      },
      {
        lat: 12.97601369944704,
        lng: 80.23234760165458,
      },
      {
        lat: 12.967668054454276,
        lng: 80.21990827009137,
      },
      {
        lat: 12.978736912564685,
        lng: 80.2217614808577,
      },
      {
        lat: 12.970299970026598,
        lng: 80.22011417247958,
      },
      {
        lat: 12.984439579404203,
        lng: 80.22571379559214,
      },
      {
        lat: 12.971290087164862,
        lng: 80.22278005129894,
      },
      {
        lat: 12.971167028487264,
        lng: 80.22367416876277,
      },
      {
        lat: 12.983764335640625,
        lng: 80.22679187610107,
      },
      {
        lat: 12.977796252306609,
        lng: 80.23222085012847,
      },
      {
        lat: 12.976782961734493,
        lng: 80.22776236957402,
      },
      {
        lat: 12.982186361205887,
        lng: 80.23031745446887,
      },
      {
        lat: 12.975077272163949,
        lng: 80.2207803825337,
      },
      {
        lat: 12.975569226407954,
        lng: 80.2289184851435,
      },
      {
        lat: 12.977022114931092,
        lng: 80.22363611487751,
      },
      {
        lat: 12.977634086155579,
        lng: 80.22955026331918,
      },
      {
        lat: 12.968960964490565,
        lng: 80.21905127089657,
      },
      {
        lat: 12.972975649133856,
        lng: 80.22366762097879,
      },
      {
        lat: 12.973948202313522,
        lng: 80.22507156763791,
      },
      {
        lat: 12.975482165045738,
        lng: 80.22748727755221,
      },
      {
        lat: 12.967650794229725,
        lng: 80.22202915009919,
      },
      {
        lat: 12.96892693459896,
        lng: 80.22352340362058,
      },
      {
        lat: 12.975307337159816,
        lng: 80.22902234705158,
      },
      {
        lat: 12.973864746579837,
        lng: 80.22645456003625,
      },
      {
        lat: 12.977409511212006,
        lng: 80.22187775131172,
      },
      {
        lat: 12.981819399677759,
        lng: 80.22272101144915,
      },
      {
        lat: 12.983258971416571,
        lng: 80.23185963101373,
      },
      {
        lat: 12.976386529007446,
        lng: 80.23087274765973,
      },
      {
        lat: 12.974760426134022,
        lng: 80.22229542301443,
      },
      {
        lat: 12.974320371155255,
        lng: 80.23035487478069,
      },
      {
        lat: 12.97864905081785,
        lng: 80.22708059654593,
      },
      {
        lat: 12.979698481317346,
        lng: 80.22714999315501,
      },
      {
        lat: 12.982934681470141,
        lng: 80.22895271740957,
      },
      {
        lat: 12.980465462267594,
        lng: 80.22269019430779,
      },
      {
        lat: 12.983094892994622,
        lng: 80.22899786912717,
      },
      {
        lat: 12.980843600424965,
        lng: 80.23032847714262,
      },
      {
        lat: 12.976116864563375,
        lng: 80.23208429681607,
      },
      {
        lat: 12.976463800413844,
        lng: 80.22271903660426,
      },
      {
        lat: 12.984447434110722,
        lng: 80.22631594167687,
      },
      {
        lat: 12.978477062168679,
        lng: 80.23274418368787,
      },
      {
        lat: 12.970835027951784,
        lng: 80.22408791084877,
      },
      {
        lat: 12.970905930953165,
        lng: 80.22055572859708,
      },
      {
        lat: 12.971702961446583,
        lng: 80.22001499093376,
      },
      {
        lat: 12.980355022074683,
        lng: 80.23071352571782,
      },
      {
        lat: 12.983920191664826,
        lng: 80.22847770251573,
      },
      {
        lat: 12.967324740609891,
        lng: 80.21985476661271,
      },
      {
        lat: 12.971618713908104,
        lng: 80.22694938558902,
      },
      {
        lat: 12.986532862020281,
        lng: 80.23027655103564,
      },
      {
        lat: 12.973477441453776,
        lng: 80.22947014583526,
      },
      {
        lat: 12.97207378654507,
        lng: 80.22116213798687,
      },
      {
        lat: 12.971830975690116,
        lng: 80.22516938583625,
      },
      {
        lat: 12.977056984764943,
        lng: 80.22568057659127,
      },
      {
        lat: 12.97496095538732,
        lng: 80.22195550311687,
      },
      {
        lat: 12.985042164438317,
        lng: 80.22887618373065,
      },
      {
        lat: 12.979884330701138,
        lng: 80.2287994442471,
      },
      {
        lat: 12.976842483254599,
        lng: 80.22854458982016,
      },
      {
        lat: 12.985593650746917,
        lng: 80.23040482311775,
      },
      {
        lat: 12.984051034653978,
        lng: 80.22951567841925,
      },
      {
        lat: 12.976137124284854,
        lng: 80.22789360420391,
      },
      {
        lat: 12.973124493585761,
        lng: 80.22021240262242,
      },
      {
        lat: 12.974742735511562,
        lng: 80.22741779532775,
      },
      {
        lat: 12.984700552819811,
        lng: 80.22863982162602,
      },
      {
        lat: 12.982961196574031,
        lng: 80.22920442430576,
      },
      {
        lat: 12.982251742995693,
        lng: 80.23429707522537,
      },
      {
        lat: 12.974125623764397,
        lng: 80.23021984247745,
      },
      {
        lat: 12.97903579778371,
        lng: 80.22558374235042,
      },
      {
        lat: 12.980433505765204,
        lng: 80.22489465771959,
      },
      {
        lat: 12.976045801284535,
        lng: 80.22406960412843,
      },
      {
        lat: 12.972964867970488,
        lng: 80.22171060047843,
      },
      {
        lat: 12.979238894150699,
        lng: 80.22405657296542,
      },
      {
        lat: 12.975349958964525,
        lng: 80.22686688950114,
      },
      {
        lat: 12.986870574940049,
        lng: 80.22903726913218,
      },
      {
        lat: 12.986596936979511,
        lng: 80.22803318377332,
      },
      {
        lat: 12.979573468159229,
        lng: 80.22320027840459,
      },
      {
        lat: 12.97089967056439,
        lng: 80.21942457217807,
      },
      {
        lat: 12.97189931824112,
        lng: 80.22200808152411,
      },
      {
        lat: 12.969832804210313,
        lng: 80.22158343946435,
      },
      {
        lat: 12.976249077849419,
        lng: 80.22749555098287,
      },
      {
        lat: 12.979946989547566,
        lng: 80.22501107063535,
      },
      {
        lat: 12.98702452586,
        lng: 80.22978917129751,
      },
      {
        lat: 12.982680498634819,
        lng: 80.22330245045183,
      },
      {
        lat: 12.97506983417933,
        lng: 80.22213239855556,
      },
      {
        lat: 12.982128236605027,
        lng: 80.22948793519829,
      },
      {
        lat: 12.978269570920869,
        lng: 80.2265423951842,
      },
      {
        lat: 12.969957211006516,
        lng: 80.22386140680732,
      },
      {
        lat: 12.975471347638779,
        lng: 80.22226550658324,
      },
      {
        lat: 12.982113676585483,
        lng: 80.22319771263409,
      },
      {
        lat: 12.972125316604453,
        lng: 80.2194451289892,
      },
      {
        lat: 12.9728145567448,
        lng: 80.22184916703712,
      },
      {
        lat: 12.983445682618267,
        lng: 80.22552223093844,
      },
      {
        lat: 12.985343178858788,
        lng: 80.22955255548052,
      },
      {
        lat: 12.983524991159117,
        lng: 80.22815647138303,
      },
      {
        lat: 12.979926430807152,
        lng: 80.2310730195029,
      },
      {
        lat: 12.984827769223577,
        lng: 80.22659247240759,
      },
      {
        lat: 12.97735925484769,
        lng: 80.22456491840008,
      },
      {
        lat: 12.971627630221656,
        lng: 80.22358052680416,
      },
      {
        lat: 12.982294993554456,
        lng: 80.22644185566598,
      },
      {
        lat: 12.973974559660695,
        lng: 80.229520851484,
      },
      {
        lat: 12.971266977792718,
        lng: 80.22502913153046,
      },
      {
        lat: 12.975588700672946,
        lng: 80.22953630715,
      },
      {
        lat: 12.97665668168434,
        lng: 80.2274867657208,
      },
      {
        lat: 12.973112783344641,
        lng: 80.22216172096668,
      },
      {
        lat: 12.983981957348012,
        lng: 80.23153425875293,
      },
    ],
    cases: 112,
    coords: [
      {
        lat: 12.967738154324746,
        lng: 80.21762851364103,
      },
      {
        lat: 12.971550614149237,
        lng: 80.21919254317437,
      },
      {
        lat: 12.973785040751954,
        lng: 80.22004927143946,
      },
      {
        lat: 12.975454884735788,
        lng: 80.22071619151457,
      },
      {
        lat: 12.975997190772755,
        lng: 80.22127103773605,
      },
      {
        lat: 12.978912168904431,
        lng: 80.22124636752015,
      },
      {
        lat: 12.979254367830663,
        lng: 80.22112185490231,
      },
      {
        lat: 12.98128779970958,
        lng: 80.22115329204084,
      },
      {
        lat: 12.982443383723279,
        lng: 80.22184888773128,
      },
      {
        lat: 12.98308130525027,
        lng: 80.22201375193526,
      },
      {
        lat: 12.984163698291372,
        lng: 80.22275861365975,
      },
      {
        lat: 12.984679437616615,
        lng: 80.2229731585234,
      },
      {
        lat: 12.98499950789644,
        lng: 80.22323800598468,
      },
      {
        lat: 12.985244611977485,
        lng: 80.22326025490521,
      },
      {
        lat: 12.985611750324594,
        lng: 80.22320850111834,
      },
      {
        lat: 12.986076131452574,
        lng: 80.22303430784099,
      },
      {
        lat: 12.98719715475215,
        lng: 80.22298890567376,
      },
      {
        lat: 12.98723512924132,
        lng: 80.22324182986632,
      },
      {
        lat: 12.98744329559198,
        lng: 80.22402765575265,
      },
      {
        lat: 12.98729877262255,
        lng: 80.22701767305557,
      },
      {
        lat: 12.987094219386536,
        lng: 80.23016975485142,
      },
      {
        lat: 12.986981896782531,
        lng: 80.23176607532642,
      },
      {
        lat: 12.986843463816852,
        lng: 80.23241347268645,
      },
      {
        lat: 12.986762850281085,
        lng: 80.23253532227082,
      },
      {
        lat: 12.986641315855984,
        lng: 80.23261712906887,
      },
      {
        lat: 12.986335883077553,
        lng: 80.23255913036,
      },
      {
        lat: 12.984909915990361,
        lng: 80.23218750214087,
      },
      {
        lat: 12.983482475011568,
        lng: 80.23157355173326,
      },
      {
        lat: 12.983182569325797,
        lng: 80.23242426123069,
      },
      {
        lat: 12.9828297571816,
        lng: 80.23460825913133,
      },
      {
        lat: 12.98138357529824,
        lng: 80.23425699655023,
      },
      {
        lat: 12.981280904602151,
        lng: 80.23409630903754,
      },
      {
        lat: 12.981522622385157,
        lng: 80.23371056676882,
      },
      {
        lat: 12.98170259036158,
        lng: 80.23320417977338,
      },
      {
        lat: 12.981661055350775,
        lng: 80.23306316940878,
      },
      {
        lat: 12.98137706561133,
        lng: 80.2331867402187,
      },
      {
        lat: 12.979207432912158,
        lng: 80.23330320443414,
      },
      {
        lat: 12.978752496790257,
        lng: 80.23314314704466,
      },
      {
        lat: 12.977059793816867,
        lng: 80.23303454210502,
      },
      {
        lat: 12.976563839716235,
        lng: 80.23291580876929,
      },
      {
        lat: 12.97614942312625,
        lng: 80.2326324777286,
      },
      {
        lat: 12.972948919964601,
        lng: 80.22888968888373,
      },
      {
        lat: 12.971081179238169,
        lng: 80.22714348901526,
      },
      {
        lat: 12.969275513772137,
        lng: 80.22542544762328,
      },
      {
        lat: 12.968237852343956,
        lng: 80.22445078382977,
      },
      {
        lat: 12.967567358937446,
        lng: 80.22277640516269,
      },
      {
        lat: 12.967352050875897,
        lng: 80.22130305868379,
      },
      {
        lat: 12.967259049080663,
        lng: 80.21958281564744,
      },
      {
        lat: 12.967457409938708,
        lng: 80.21826987581316,
      },
      {
        lat: 12.967738154324746,
        lng: 80.21762851364103,
      },
    ],
    properties: {
      Ward_No: 179,
      Zone_Name: "ADYAR",
      fillColor: "#f6d04d",
      zname: "ADYAR(179)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.983341231142004,
        lng: 80.25822236446841,
      },
      {
        lat: 12.974466345195916,
        lng: 80.26119301655679,
      },
      {
        lat: 12.974372406267662,
        lng: 80.26086517774601,
      },
      {
        lat: 12.981148309441156,
        lng: 80.26795348015165,
      },
      {
        lat: 12.982105052376363,
        lng: 80.25600977210682,
      },
      {
        lat: 12.983155032270826,
        lng: 80.26589246885112,
      },
      {
        lat: 12.98134169577889,
        lng: 80.26401524803221,
      },
      {
        lat: 12.97671308060763,
        lng: 80.26614645148015,
      },
      {
        lat: 12.979651342996837,
        lng: 80.25455276430505,
      },
      {
        lat: 12.974624462337134,
        lng: 80.26394124442024,
      },
      {
        lat: 12.978145405398028,
        lng: 80.2651868960155,
      },
      {
        lat: 12.985721604536586,
        lng: 80.2568646229301,
      },
      {
        lat: 12.97595122284279,
        lng: 80.26354265058791,
      },
      {
        lat: 12.97621797410679,
        lng: 80.26097741245238,
      },
      {
        lat: 12.977953001441765,
        lng: 80.26129504798939,
      },
      {
        lat: 12.976310734211726,
        lng: 80.26331870318248,
      },
      {
        lat: 12.984422117047322,
        lng: 80.25914823795556,
      },
      {
        lat: 12.985529851432105,
        lng: 80.25692216109145,
      },
      {
        lat: 12.97859254146212,
        lng: 80.25848993846736,
      },
      {
        lat: 12.979757107037031,
        lng: 80.2584753463274,
      },
      {
        lat: 12.976560594880223,
        lng: 80.26045010671734,
      },
      {
        lat: 12.982447775411055,
        lng: 80.26641202431644,
      },
      {
        lat: 12.981786818791482,
        lng: 80.26230876295489,
      },
      {
        lat: 12.985393267668059,
        lng: 80.2597251235064,
      },
      {
        lat: 12.981679783215501,
        lng: 80.2622647788145,
      },
      {
        lat: 12.98365505283974,
        lng: 80.25728797295123,
      },
      {
        lat: 12.980421856790839,
        lng: 80.25971925769572,
      },
      {
        lat: 12.977039751612045,
        lng: 80.25744361877138,
      },
      {
        lat: 12.978806807971838,
        lng: 80.26093677326072,
      },
      {
        lat: 12.975121072991874,
        lng: 80.26105725109042,
      },
      {
        lat: 12.979089551577015,
        lng: 80.25660564049463,
      },
      {
        lat: 12.978250015014831,
        lng: 80.25467465247486,
      },
      {
        lat: 12.983097049083565,
        lng: 80.26436180034304,
      },
      {
        lat: 12.978333115397787,
        lng: 80.2568075938269,
      },
      {
        lat: 12.98173182233697,
        lng: 80.26101243756868,
      },
      {
        lat: 12.976633889601588,
        lng: 80.25707956547872,
      },
      {
        lat: 12.981636600242114,
        lng: 80.25787940713248,
      },
      {
        lat: 12.980619301741507,
        lng: 80.2558450451514,
      },
      {
        lat: 12.98154146218245,
        lng: 80.26638333342888,
      },
      {
        lat: 12.978430193867023,
        lng: 80.26611451303357,
      },
      {
        lat: 12.975690565082193,
        lng: 80.26388169707903,
      },
      {
        lat: 12.985852594585982,
        lng: 80.25541818480066,
      },
      {
        lat: 12.97702846237894,
        lng: 80.26232549464967,
      },
      {
        lat: 12.984342572331789,
        lng: 80.25772311117761,
      },
      {
        lat: 12.976580338797044,
        lng: 80.26155850966786,
      },
      {
        lat: 12.984215489127118,
        lng: 80.25627007798482,
      },
      {
        lat: 12.983012321138535,
        lng: 80.25833096696178,
      },
      {
        lat: 12.979912008028267,
        lng: 80.2562558604857,
      },
      {
        lat: 12.97844624220237,
        lng: 80.25563818219392,
      },
      {
        lat: 12.975928071129575,
        lng: 80.2546323394258,
      },
      {
        lat: 12.979806419482026,
        lng: 80.26151785815034,
      },
      {
        lat: 12.981781627456364,
        lng: 80.26645882674266,
      },
      {
        lat: 12.976646926718763,
        lng: 80.25734851797294,
      },
      {
        lat: 12.979539830123436,
        lng: 80.26076150986611,
      },
      {
        lat: 12.98272824746952,
        lng: 80.25667297874621,
      },
      {
        lat: 12.97596780927282,
        lng: 80.25389184719691,
      },
      {
        lat: 12.982175231242566,
        lng: 80.26193261564411,
      },
      {
        lat: 12.981101783077051,
        lng: 80.266313241242,
      },
      {
        lat: 12.985922538708694,
        lng: 80.25933153867712,
      },
      {
        lat: 12.98462717885383,
        lng: 80.25997500665915,
      },
      {
        lat: 12.987070875787204,
        lng: 80.25835614428499,
      },
      {
        lat: 12.978447719133529,
        lng: 80.25764209784505,
      },
      {
        lat: 12.982426954316567,
        lng: 80.26274040378463,
      },
      {
        lat: 12.986766243764992,
        lng: 80.2595927606746,
      },
      {
        lat: 12.987337551675633,
        lng: 80.25825532434786,
      },
      {
        lat: 12.981591366049829,
        lng: 80.25995280890604,
      },
      {
        lat: 12.978410809197523,
        lng: 80.26089356202496,
      },
      {
        lat: 12.986216203020875,
        lng: 80.260174982132,
      },
      {
        lat: 12.981604361732623,
        lng: 80.25495719130053,
      },
      {
        lat: 12.97999093435266,
        lng: 80.26492219784454,
      },
      {
        lat: 12.978647334327846,
        lng: 80.2638265772125,
      },
      {
        lat: 12.981668898579839,
        lng: 80.25950046568306,
      },
      {
        lat: 12.97631076728228,
        lng: 80.26484925364305,
      },
      {
        lat: 12.977726666368218,
        lng: 80.25766428584582,
      },
      {
        lat: 12.981463264648168,
        lng: 80.25380653382975,
      },
      {
        lat: 12.97726309585421,
        lng: 80.26143732407473,
      },
      {
        lat: 12.982349476787942,
        lng: 80.2598594567621,
      },
    ],
    cases: 77,
    coords: [
      {
        lat: 12.983079688889706,
        lng: 80.26837256571544,
      },
      {
        lat: 12.978714116767822,
        lng: 80.26754358610404,
      },
      {
        lat: 12.975242687286405,
        lng: 80.26675342755262,
      },
      {
        lat: 12.974333313489755,
        lng: 80.2665152623709,
      },
      {
        lat: 12.97408296881566,
        lng: 80.26606663511524,
      },
      {
        lat: 12.973985480995879,
        lng: 80.26360884570187,
      },
      {
        lat: 12.974215389337079,
        lng: 80.2606975394623,
      },
      {
        lat: 12.974334455696438,
        lng: 80.25991797052124,
      },
      {
        lat: 12.974538797920438,
        lng: 80.25958842585635,
      },
      {
        lat: 12.974826172996433,
        lng: 80.25934013227872,
      },
      {
        lat: 12.97511529263747,
        lng: 80.25937866229219,
      },
      {
        lat: 12.975279364553131,
        lng: 80.25921336607718,
      },
      {
        lat: 12.975277619988095,
        lng: 80.25892654248621,
      },
      {
        lat: 12.974904470600586,
        lng: 80.25864286219036,
      },
      {
        lat: 12.974614852512458,
        lng: 80.25852238257944,
      },
      {
        lat: 12.97457084385058,
        lng: 80.25807200904845,
      },
      {
        lat: 12.974566607049777,
        lng: 80.25737543747029,
      },
      {
        lat: 12.974851739113753,
        lng: 80.25675837070406,
      },
      {
        lat: 12.97526216812634,
        lng: 80.25638610496513,
      },
      {
        lat: 12.975506780658687,
        lng: 80.25589231185032,
      },
      {
        lat: 12.975379739144351,
        lng: 80.25536068723191,
      },
      {
        lat: 12.97537525311997,
        lng: 80.25462314085492,
      },
      {
        lat: 12.975453550724177,
        lng: 80.25392587076662,
      },
      {
        lat: 12.975699907821388,
        lng: 80.25371890124265,
      },
      {
        lat: 12.97627764865647,
        lng: 80.25371401167216,
      },
      {
        lat: 12.977930579768676,
        lng: 80.25406881465928,
      },
      {
        lat: 12.979168595843799,
        lng: 80.25405833700819,
      },
      {
        lat: 12.980983355859472,
        lng: 80.25387907059174,
      },
      {
        lat: 12.981724171715738,
        lng: 80.25354498561114,
      },
      {
        lat: 12.982072689502854,
        lng: 80.25421966861664,
      },
      {
        lat: 12.982652971991802,
        lng: 80.2545973508048,
      },
      {
        lat: 12.987717790971628,
        lng: 80.25582182613256,
      },
      {
        lat: 12.987521563901984,
        lng: 80.25919509003813,
      },
      {
        lat: 12.987308175567625,
        lng: 80.25974687382354,
      },
      {
        lat: 12.986971325706227,
        lng: 80.25979754882981,
      },
      {
        lat: 12.98656135879589,
        lng: 80.25970537016872,
      },
      {
        lat: 12.986321271160795,
        lng: 80.25982696248933,
      },
      {
        lat: 12.986274998847234,
        lng: 80.26013821113516,
      },
      {
        lat: 12.986140399505224,
        lng: 80.2617653716785,
      },
      {
        lat: 12.986021155577129,
        lng: 80.26195767750544,
      },
      {
        lat: 12.985756404723412,
        lng: 80.26198383023986,
      },
      {
        lat: 12.98500929708296,
        lng: 80.26189450488828,
      },
      {
        lat: 12.984745564270316,
        lng: 80.26208803356211,
      },
      {
        lat: 12.98467870090277,
        lng: 80.26297334637925,
      },
      {
        lat: 12.98447142081517,
        lng: 80.26452938580047,
      },
      {
        lat: 12.983905681771057,
        lng: 80.26654278846019,
      },
      {
        lat: 12.983079688889706,
        lng: 80.26837256571544,
      },
    ],
    properties: {
      Ward_No: 182,
      Zone_Name: "ADYAR",
      fillColor: "#f6d04d",
      zname: "ADYAR(182)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.993957929281917,
        lng: 80.25824781174373,
      },
      {
        lat: 12.99101120579698,
        lng: 80.26546246692365,
      },
      {
        lat: 12.987646398988332,
        lng: 80.26107945245901,
      },
      {
        lat: 12.992963889897668,
        lng: 80.25982749069094,
      },
      {
        lat: 12.991559931817545,
        lng: 80.25747425974386,
      },
      {
        lat: 12.987988175924716,
        lng: 80.26130607487998,
      },
      {
        lat: 12.991996337608247,
        lng: 80.26458026754307,
      },
      {
        lat: 12.993864250718193,
        lng: 80.26525903993065,
      },
      {
        lat: 12.993143440682244,
        lng: 80.26976061992407,
      },
      {
        lat: 12.99445712017746,
        lng: 80.26413453051455,
      },
      {
        lat: 12.991418204174005,
        lng: 80.25869310133163,
      },
      {
        lat: 12.987451425756298,
        lng: 80.26131859026144,
      },
      {
        lat: 12.98981294072112,
        lng: 80.25907084880417,
      },
      {
        lat: 12.992339362428314,
        lng: 80.2625011962708,
      },
      {
        lat: 12.987355684335327,
        lng: 80.26482626378234,
      },
      {
        lat: 12.994173369170065,
        lng: 80.26688352687387,
      },
      {
        lat: 12.983763448218012,
        lng: 80.2670302061065,
      },
      {
        lat: 12.990642113198898,
        lng: 80.26686033439805,
      },
      {
        lat: 12.992496015354815,
        lng: 80.25795592527571,
      },
      {
        lat: 12.993552137797177,
        lng: 80.2692463423053,
      },
      {
        lat: 12.9884869667099,
        lng: 80.2570638658312,
      },
      {
        lat: 12.98844794082517,
        lng: 80.25891540758353,
      },
      {
        lat: 12.993438114946924,
        lng: 80.2699118301146,
      },
      {
        lat: 12.99085427499957,
        lng: 80.26924237156736,
      },
      {
        lat: 12.993577733237505,
        lng: 80.26513243442467,
      },
      {
        lat: 12.991503118190577,
        lng: 80.26117527676867,
      },
      {
        lat: 12.989380442842918,
        lng: 80.25985623532395,
      },
      {
        lat: 12.987251465860288,
        lng: 80.26523317482341,
      },
      {
        lat: 12.995851553327073,
        lng: 80.25996227351617,
      },
      {
        lat: 12.99363655004393,
        lng: 80.25620800609796,
      },
      {
        lat: 12.992430523770484,
        lng: 80.25861151771925,
      },
      {
        lat: 12.990726259496773,
        lng: 80.25685717857696,
      },
      {
        lat: 12.988115753170945,
        lng: 80.25636838882372,
      },
      {
        lat: 12.989514622709581,
        lng: 80.26485479442599,
      },
      {
        lat: 12.994796045369919,
        lng: 80.26730888371893,
      },
      {
        lat: 12.992798225632091,
        lng: 80.26921944691473,
      },
      {
        lat: 12.991536918814706,
        lng: 80.25749778431904,
      },
      {
        lat: 12.986186585510755,
        lng: 80.26189681332312,
      },
      {
        lat: 12.994826641965979,
        lng: 80.26070133946745,
      },
      {
        lat: 12.986459044230925,
        lng: 80.2681503840199,
      },
      {
        lat: 12.990948757321197,
        lng: 80.25901024157305,
      },
      {
        lat: 12.994725387810641,
        lng: 80.26757197886373,
      },
      {
        lat: 12.989228179677387,
        lng: 80.2675915822133,
      },
      {
        lat: 12.991253555636836,
        lng: 80.26136480300816,
      },
      {
        lat: 12.991213678629068,
        lng: 80.267798423831,
      },
      {
        lat: 12.990486837609124,
        lng: 80.26845221789151,
      },
      {
        lat: 12.995916341343106,
        lng: 80.25737261820096,
      },
    ],
    cases: 47,
    coords: [
      {
        lat: 12.996279378315421,
        lng: 80.27177166607636,
      },
      {
        lat: 12.995886807882416,
        lng: 80.27170082450239,
      },
      {
        lat: 12.99181501017578,
        lng: 80.27058793175905,
      },
      {
        lat: 12.984291918142162,
        lng: 80.26860275623957,
      },
      {
        lat: 12.983079688889706,
        lng: 80.26837256571544,
      },
      {
        lat: 12.983905681771057,
        lng: 80.26654278846019,
      },
      {
        lat: 12.98447142081517,
        lng: 80.26452938580047,
      },
      {
        lat: 12.98467870090277,
        lng: 80.26297334637925,
      },
      {
        lat: 12.984745564270316,
        lng: 80.26208803356211,
      },
      {
        lat: 12.98500929708296,
        lng: 80.26189450488828,
      },
      {
        lat: 12.985756404723412,
        lng: 80.26198383023986,
      },
      {
        lat: 12.986021155577129,
        lng: 80.26195767750544,
      },
      {
        lat: 12.986140399505224,
        lng: 80.2617653716785,
      },
      {
        lat: 12.986274998847234,
        lng: 80.26013821113516,
      },
      {
        lat: 12.986321271160795,
        lng: 80.25982696248933,
      },
      {
        lat: 12.98656135879589,
        lng: 80.25970537016872,
      },
      {
        lat: 12.986971325706227,
        lng: 80.25979754882981,
      },
      {
        lat: 12.987308175567625,
        lng: 80.25974687382354,
      },
      {
        lat: 12.987521563901984,
        lng: 80.25919509003813,
      },
      {
        lat: 12.987717790971628,
        lng: 80.25582182613256,
      },
      {
        lat: 12.988320409727754,
        lng: 80.25591237433096,
      },
      {
        lat: 12.990367481024403,
        lng: 80.25591896151515,
      },
      {
        lat: 12.99036835363109,
        lng: 80.25606242660601,
      },
      {
        lat: 12.993691888841337,
        lng: 80.25608212282417,
      },
      {
        lat: 12.994244744857076,
        lng: 80.25591005930518,
      },
      {
        lat: 12.996893853172299,
        lng: 80.2559115512938,
      },
      {
        lat: 12.996419992220789,
        lng: 80.26115230707447,
      },
      {
        lat: 12.995738996616074,
        lng: 80.26797301312479,
      },
      {
        lat: 12.995507344178709,
        lng: 80.26948143465701,
      },
      {
        lat: 12.995486025952538,
        lng: 80.26993594458584,
      },
      {
        lat: 12.995488498338132,
        lng: 80.27034242901007,
      },
      {
        lat: 12.995754848970757,
        lng: 80.27057929560878,
      },
      {
        lat: 12.995973036641685,
        lng: 80.27081656982321,
      },
      {
        lat: 12.996166706528724,
        lng: 80.27098231530015,
      },
      {
        lat: 12.996358485768017,
        lng: 80.27083721974661,
      },
      {
        lat: 12.996527783305288,
        lng: 80.2709553473343,
      },
      {
        lat: 12.996505301603493,
        lng: 80.27121857047541,
      },
      {
        lat: 12.996279378315421,
        lng: 80.27177166607636,
      },
    ],
    properties: {
      Ward_No: 181,
      Zone_Name: "ADYAR",
      fillColor: "#8bc24c",
      zname: "ADYAR(181)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.006806034853495,
        lng: 80.26756130147015,
      },
      {
        lat: 13.005003729643546,
        lng: 80.26837230563854,
      },
      {
        lat: 13.004383877907001,
        lng: 80.26048171466034,
      },
      {
        lat: 13.005214051931471,
        lng: 80.27384342177383,
      },
      {
        lat: 13.009684712735831,
        lng: 80.25900153611066,
      },
      {
        lat: 12.997965369982133,
        lng: 80.26693274814937,
      },
      {
        lat: 13.009114145247896,
        lng: 80.26191293097644,
      },
      {
        lat: 13.00676579004557,
        lng: 80.2622054038476,
      },
      {
        lat: 13.0043440557294,
        lng: 80.25753117528646,
      },
      {
        lat: 13.00421776668465,
        lng: 80.27154655943653,
      },
      {
        lat: 13.010510183568085,
        lng: 80.26014901783911,
      },
      {
        lat: 13.000498476999883,
        lng: 80.26021797607197,
      },
    ],
    cases: 12,
    coords: [
      {
        lat: 13.011360309396839,
        lng: 80.27642894656613,
      },
      {
        lat: 13.006977482815815,
        lng: 80.27501828883248,
      },
      {
        lat: 13.003038993540892,
        lng: 80.2737710929146,
      },
      {
        lat: 13.002543039440262,
        lng: 80.27365235957888,
      },
      {
        lat: 13.002210658807966,
        lng: 80.2732863804306,
      },
      {
        lat: 13.002083617293811,
        lng: 80.27275475581236,
      },
      {
        lat: 13.00207888204585,
        lng: 80.27197623463663,
      },
      {
        lat: 13.002036119502085,
        lng: 80.27173073509935,
      },
      {
        lat: 13.001621204464831,
        lng: 80.2713654544611,
      },
      {
        lat: 13.000256894546627,
        lng: 80.2709672318901,
      },
      {
        lat: 12.998439642295013,
        lng: 80.27073675031947,
      },
      {
        lat: 12.99794418664137,
        lng: 80.27069996658113,
      },
      {
        lat: 12.99753226228732,
        lng: 80.27082638352768,
      },
      {
        lat: 12.997369436489542,
        lng: 80.27119655373623,
      },
      {
        lat: 12.997207856809654,
        lng: 80.27177159793864,
      },
      {
        lat: 12.996754416029036,
        lng: 80.27185738934148,
      },
      {
        lat: 12.996279378315421,
        lng: 80.27177166607636,
      },
      {
        lat: 12.996505301603493,
        lng: 80.27121857047541,
      },
      {
        lat: 12.996527783305288,
        lng: 80.2709553473343,
      },
      {
        lat: 12.996358485768017,
        lng: 80.27083721974661,
      },
      {
        lat: 12.996166706528724,
        lng: 80.27098231530015,
      },
      {
        lat: 12.995973036641685,
        lng: 80.27081656982321,
      },
      {
        lat: 12.995754848970757,
        lng: 80.27057929560878,
      },
      {
        lat: 12.995488498338132,
        lng: 80.27034242901007,
      },
      {
        lat: 12.995486025952538,
        lng: 80.26993594458584,
      },
      {
        lat: 12.995507344178709,
        lng: 80.26948143465701,
      },
      {
        lat: 12.995738996616074,
        lng: 80.26797301312479,
      },
      {
        lat: 12.996419992220789,
        lng: 80.26115230707447,
      },
      {
        lat: 12.996893853172299,
        lng: 80.2559115512938,
      },
      {
        lat: 12.99867308838278,
        lng: 80.25603664045434,
      },
      {
        lat: 13.004746342203,
        lng: 80.25712656195246,
      },
      {
        lat: 13.00672934645944,
        lng: 80.25768043978336,
      },
      {
        lat: 13.007660270633037,
        lng: 80.25849684853415,
      },
      {
        lat: 13.008300565209959,
        lng: 80.2587767598102,
      },
      {
        lat: 13.010472437721768,
        lng: 80.25888519213652,
      },
      {
        lat: 13.011335263905963,
        lng: 80.25900470326143,
      },
      {
        lat: 13.011785228050984,
        lng: 80.26473920356095,
      },
      {
        lat: 13.011482611495117,
        lng: 80.27273101182487,
      },
      {
        lat: 13.011360309396839,
        lng: 80.27642894656613,
      },
    ],
    properties: {
      Ward_No: 176,
      Zone_Name: "ADYAR",
      fillColor: "#8bc24c",
      zname: "ADYAR(176)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.016728443453498,
        lng: 80.26098071982611,
      },
      {
        lat: 13.02047120333419,
        lng: 80.26777734357815,
      },
      {
        lat: 13.013074929181789,
        lng: 80.27429248959024,
      },
      {
        lat: 13.013541870385135,
        lng: 80.25446652076545,
      },
      {
        lat: 13.013553692532469,
        lng: 80.25522634438053,
      },
      {
        lat: 13.020107792068787,
        lng: 80.26324694491268,
      },
      {
        lat: 13.021584844678744,
        lng: 80.27333010164007,
      },
      {
        lat: 13.017368803666436,
        lng: 80.26386227408344,
      },
      {
        lat: 13.012184767365119,
        lng: 80.26254447823112,
      },
      {
        lat: 13.02442037184409,
        lng: 80.26806496951532,
      },
      {
        lat: 13.017539194717843,
        lng: 80.26319929044652,
      },
      {
        lat: 13.013545855202345,
        lng: 80.26331162021339,
      },
      {
        lat: 13.011843493334833,
        lng: 80.2677298934542,
      },
      {
        lat: 13.013163023797766,
        lng: 80.26351739197293,
      },
      {
        lat: 13.015604725464234,
        lng: 80.25617998079895,
      },
      {
        lat: 13.014468985972416,
        lng: 80.27385248088108,
      },
      {
        lat: 13.015532072307904,
        lng: 80.25994242702767,
      },
      {
        lat: 13.021823884531189,
        lng: 80.26654778263224,
      },
      {
        lat: 13.020695479685047,
        lng: 80.2680805289574,
      },
      {
        lat: 13.023197919246904,
        lng: 80.27290874964525,
      },
      {
        lat: 13.0154016927719,
        lng: 80.26580418292971,
      },
      {
        lat: 13.024267432059837,
        lng: 80.26612074251996,
      },
      {
        lat: 13.01795623719882,
        lng: 80.26261108381148,
      },
      {
        lat: 13.02373849550674,
        lng: 80.25818274687315,
      },
      {
        lat: 13.01785806983399,
        lng: 80.2567253753859,
      },
      {
        lat: 13.02468938864504,
        lng: 80.25797143615075,
      },
      {
        lat: 13.01960409520679,
        lng: 80.27699168844461,
      },
      {
        lat: 13.011608305459827,
        lng: 80.25922705389398,
      },
      {
        lat: 13.017086213122827,
        lng: 80.2630420019968,
      },
      {
        lat: 13.016903854604504,
        lng: 80.27676232530091,
      },
      {
        lat: 13.014458705225454,
        lng: 80.26537211840443,
      },
      {
        lat: 13.014827110854764,
        lng: 80.26243035266094,
      },
      {
        lat: 13.016843143350942,
        lng: 80.27158859500865,
      },
      {
        lat: 13.014260258577025,
        lng: 80.27632863636317,
      },
      {
        lat: 13.019999381641638,
        lng: 80.25318833851836,
      },
      {
        lat: 13.016570530033869,
        lng: 80.26472462496574,
      },
      {
        lat: 13.013357598878338,
        lng: 80.2764736867637,
      },
      {
        lat: 13.016230752267182,
        lng: 80.26176372178261,
      },
      {
        lat: 13.01764523152169,
        lng: 80.26438375136516,
      },
      {
        lat: 13.018317044003272,
        lng: 80.26457920264319,
      },
      {
        lat: 13.012847199260277,
        lng: 80.26852509726673,
      },
      {
        lat: 13.01734271021221,
        lng: 80.27513513387852,
      },
      {
        lat: 13.013548006606168,
        lng: 80.26440666715672,
      },
      {
        lat: 13.024133875996709,
        lng: 80.26660862699988,
      },
      {
        lat: 13.015542724895669,
        lng: 80.255108988747,
      },
      {
        lat: 13.015974811829006,
        lng: 80.27263064657005,
      },
      {
        lat: 13.0191320051772,
        lng: 80.26290365952403,
      },
      {
        lat: 13.016050597563584,
        lng: 80.25947521011062,
      },
      {
        lat: 13.020153611660946,
        lng: 80.27282248742402,
      },
      {
        lat: 13.013515030646973,
        lng: 80.26447333294082,
      },
      {
        lat: 13.013286838443669,
        lng: 80.26822235076118,
      },
      {
        lat: 13.017090301986581,
        lng: 80.25159954969969,
      },
      {
        lat: 13.022103358942605,
        lng: 80.27315454082978,
      },
      {
        lat: 13.02142113854149,
        lng: 80.26089977771126,
      },
      {
        lat: 13.01823242975729,
        lng: 80.2660357305468,
      },
      {
        lat: 13.013990176602382,
        lng: 80.26718437512328,
      },
      {
        lat: 13.021467081507048,
        lng: 80.25604668595597,
      },
      {
        lat: 13.015417737395586,
        lng: 80.26858359345768,
      },
      {
        lat: 13.01653004452138,
        lng: 80.25702783494134,
      },
      {
        lat: 13.01300513908891,
        lng: 80.26903203928165,
      },
      {
        lat: 13.02128154655094,
        lng: 80.2757320618976,
      },
      {
        lat: 13.019704808922661,
        lng: 80.27664404551972,
      },
      {
        lat: 13.024064267071575,
        lng: 80.27210782049941,
      },
      {
        lat: 13.015851905725299,
        lng: 80.25881424606693,
      },
      {
        lat: 13.012045964760983,
        lng: 80.26728445232385,
      },
      {
        lat: 13.015561819087079,
        lng: 80.27265711689255,
      },
      {
        lat: 13.014794798690927,
        lng: 80.25723466065737,
      },
      {
        lat: 13.01403985072768,
        lng: 80.2571790211522,
      },
      {
        lat: 13.021563722100868,
        lng: 80.27136533166949,
      },
      {
        lat: 13.021356439654403,
        lng: 80.26221405867628,
      },
      {
        lat: 13.016494466152244,
        lng: 80.2654102591104,
      },
      {
        lat: 13.021373332852741,
        lng: 80.27092078408118,
      },
      {
        lat: 13.016978830955864,
        lng: 80.27390829071668,
      },
      {
        lat: 13.018011090169637,
        lng: 80.25749280382504,
      },
      {
        lat: 13.018486578714201,
        lng: 80.26963789860925,
      },
      {
        lat: 13.019404058904868,
        lng: 80.25506579515431,
      },
      {
        lat: 13.01538994006593,
        lng: 80.27656875830922,
      },
      {
        lat: 13.015939581204172,
        lng: 80.27461831093977,
      },
      {
        lat: 13.022409510676303,
        lng: 80.2638938928853,
      },
      {
        lat: 13.016752288077237,
        lng: 80.26594779841173,
      },
      {
        lat: 13.015911670178346,
        lng: 80.25346720554988,
      },
      {
        lat: 13.019367761937925,
        lng: 80.27486874527614,
      },
      {
        lat: 13.01568180596837,
        lng: 80.26133500674992,
      },
      {
        lat: 13.015828682666472,
        lng: 80.26031854266057,
      },
      {
        lat: 13.019312881053715,
        lng: 80.2754936220698,
      },
      {
        lat: 13.015674160058376,
        lng: 80.25023559696555,
      },
      {
        lat: 13.02052555732784,
        lng: 80.27766766861224,
      },
      {
        lat: 13.012368512994449,
        lng: 80.26970031327123,
      },
      {
        lat: 13.016979573110838,
        lng: 80.26320249989017,
      },
      {
        lat: 13.020642754263346,
        lng: 80.27309429583345,
      },
      {
        lat: 13.014806360541035,
        lng: 80.26392166956786,
      },
      {
        lat: 13.024157318011643,
        lng: 80.25777875661602,
      },
      {
        lat: 13.023877682066981,
        lng: 80.26103705247682,
      },
      {
        lat: 13.017943223324576,
        lng: 80.26439892691509,
      },
      {
        lat: 13.023850221740137,
        lng: 80.27012351994284,
      },
      {
        lat: 13.011415204469676,
        lng: 80.27535588552614,
      },
      {
        lat: 13.017897404375585,
        lng: 80.25241182608934,
      },
      {
        lat: 13.02147923602753,
        lng: 80.26024134663052,
      },
      {
        lat: 13.01510483386585,
        lng: 80.25304047630803,
      },
      {
        lat: 13.021854678689792,
        lng: 80.26852490820181,
      },
      {
        lat: 13.02127609149609,
        lng: 80.26762338088878,
      },
      {
        lat: 13.019546896488317,
        lng: 80.25098514522236,
      },
      {
        lat: 13.022089325621085,
        lng: 80.27735250621515,
      },
      {
        lat: 13.01416538664724,
        lng: 80.2586554569882,
      },
      {
        lat: 13.02105776540726,
        lng: 80.27729164226088,
      },
      {
        lat: 13.018879573408645,
        lng: 80.27346441189124,
      },
      {
        lat: 13.016928659922987,
        lng: 80.25093887009339,
      },
      {
        lat: 13.016525833954121,
        lng: 80.25223240674364,
      },
      {
        lat: 13.016369743519386,
        lng: 80.25348116503407,
      },
      {
        lat: 13.015142170107698,
        lng: 80.25886135281728,
      },
      {
        lat: 13.012891272642266,
        lng: 80.26037536755463,
      },
      {
        lat: 13.020567803923212,
        lng: 80.27196879196222,
      },
      {
        lat: 13.016686327791424,
        lng: 80.25586967501893,
      },
      {
        lat: 13.017373391456037,
        lng: 80.2736388379603,
      },
      {
        lat: 13.012717931511746,
        lng: 80.26405243737082,
      },
      {
        lat: 13.013012000250297,
        lng: 80.26268355131248,
      },
      {
        lat: 13.020462368386463,
        lng: 80.26684034352964,
      },
      {
        lat: 13.019991305686123,
        lng: 80.26720862452319,
      },
      {
        lat: 13.020245798748318,
        lng: 80.27427237341807,
      },
      {
        lat: 13.018216628403671,
        lng: 80.25102347769867,
      },
      {
        lat: 13.021768954834732,
        lng: 80.27787316720952,
      },
      {
        lat: 13.018153100144652,
        lng: 80.25810247230616,
      },
      {
        lat: 13.022778052105714,
        lng: 80.26757108711348,
      },
      {
        lat: 13.017351580120911,
        lng: 80.26845136582278,
      },
      {
        lat: 13.022248558059912,
        lng: 80.26008584211296,
      },
      {
        lat: 13.014810563057367,
        lng: 80.26131238807585,
      },
      {
        lat: 13.018140115379135,
        lng: 80.26441672934745,
      },
      {
        lat: 13.022890944753566,
        lng: 80.2565590510869,
      },
      {
        lat: 13.012852636658252,
        lng: 80.26120060383484,
      },
      {
        lat: 13.01544611365209,
        lng: 80.25149737908556,
      },
      {
        lat: 13.021077150134866,
        lng: 80.2666948575564,
      },
      {
        lat: 13.024525217164324,
        lng: 80.27180696034003,
      },
      {
        lat: 13.023394839335149,
        lng: 80.25830151033446,
      },
      {
        lat: 13.015501521569828,
        lng: 80.26319276639572,
      },
      {
        lat: 13.013711207223855,
        lng: 80.26837994974782,
      },
      {
        lat: 13.01747732100032,
        lng: 80.27454615876287,
      },
      {
        lat: 13.017490062659991,
        lng: 80.2661554045088,
      },
      {
        lat: 13.015902246611528,
        lng: 80.2539877259302,
      },
      {
        lat: 13.019880253378474,
        lng: 80.26017693379308,
      },
      {
        lat: 13.015433864067333,
        lng: 80.27473052720359,
      },
      {
        lat: 13.015799709038395,
        lng: 80.26805497876883,
      },
      {
        lat: 13.01314558531197,
        lng: 80.26934480134678,
      },
      {
        lat: 13.01929074385926,
        lng: 80.26728845356303,
      },
      {
        lat: 13.025512236408062,
        lng: 80.26575457242168,
      },
      {
        lat: 13.017883486824095,
        lng: 80.27057062830674,
      },
      {
        lat: 13.011581312782765,
        lng: 80.27488322867654,
      },
      {
        lat: 13.020550329358478,
        lng: 80.27513645316398,
      },
      {
        lat: 13.022489941645523,
        lng: 80.27235643040149,
      },
      {
        lat: 13.017068609827332,
        lng: 80.26731388560272,
      },
      {
        lat: 13.022448317020695,
        lng: 80.25516953705386,
      },
      {
        lat: 13.022765441462006,
        lng: 80.2755246691491,
      },
      {
        lat: 13.019680909865436,
        lng: 80.27031032829574,
      },
      {
        lat: 13.023156620657916,
        lng: 80.27217139234838,
      },
      {
        lat: 13.021573353621879,
        lng: 80.26979285595228,
      },
      {
        lat: 13.023183999696734,
        lng: 80.25676921002147,
      },
      {
        lat: 13.02004538934156,
        lng: 80.26374965109389,
      },
      {
        lat: 13.01515948930449,
        lng: 80.27467049208225,
      },
      {
        lat: 13.017613541822785,
        lng: 80.26645064351995,
      },
      {
        lat: 13.018995754688753,
        lng: 80.2726415537986,
      },
      {
        lat: 13.01141936202014,
        lng: 80.25990154620948,
      },
      {
        lat: 13.012761626959817,
        lng: 80.26221816458188,
      },
      {
        lat: 13.01789875887733,
        lng: 80.25677316567233,
      },
      {
        lat: 13.017964469592217,
        lng: 80.27102151268517,
      },
      {
        lat: 13.012348877217315,
        lng: 80.26217035409498,
      },
      {
        lat: 13.020831517270903,
        lng: 80.2569720465251,
      },
      {
        lat: 13.011592997589716,
        lng: 80.27361944625294,
      },
    ],
    cases: 166,
    coords: [
      {
        lat: 13.011335263905963,
        lng: 80.25900470326143,
      },
      {
        lat: 13.015452267282676,
        lng: 80.24819071702564,
      },
      {
        lat: 13.015740390201191,
        lng: 80.24831509201341,
      },
      {
        lat: 13.0183580796489,
        lng: 80.25006547361876,
      },
      {
        lat: 13.020653242682858,
        lng: 80.25173234437884,
      },
      {
        lat: 13.022604654941004,
        lng: 80.25272760626281,
      },
      {
        lat: 13.022980961113339,
        lng: 80.2536748788481,
      },
      {
        lat: 13.023642993323287,
        lng: 80.25590744968608,
      },
      {
        lat: 13.024703258020102,
        lng: 80.25761543162588,
      },
      {
        lat: 13.025296822885203,
        lng: 80.2587448249841,
      },
      {
        lat: 13.025578446470382,
        lng: 80.25935563982874,
      },
      {
        lat: 13.025457735115813,
        lng: 80.25981656016397,
      },
      {
        lat: 13.024516954196542,
        lng: 80.26252259474862,
      },
      {
        lat: 13.024367603423109,
        lng: 80.26335167644564,
      },
      {
        lat: 13.02418625667878,
        lng: 80.2639970694431,
      },
      {
        lat: 13.024250808046139,
        lng: 80.26445642185183,
      },
      {
        lat: 13.024374503002386,
        lng: 80.26448603490412,
      },
      {
        lat: 13.024870774628718,
        lng: 80.2648497538077,
      },
      {
        lat: 13.025383930391206,
        lng: 80.26511893131108,
      },
      {
        lat: 13.02613006249221,
        lng: 80.26570654989865,
      },
      {
        lat: 13.026172185532497,
        lng: 80.26584955661014,
      },
      {
        lat: 13.026071050797396,
        lng: 80.26617809987594,
      },
      {
        lat: 13.02590741659902,
        lng: 80.26640476979838,
      },
      {
        lat: 13.02578378786722,
        lng: 80.26642629655889,
      },
      {
        lat: 13.025472785308967,
        lng: 80.26616268269349,
      },
      {
        lat: 13.025288151871969,
        lng: 80.26632808896228,
      },
      {
        lat: 13.025620527358319,
        lng: 80.26671440469426,
      },
      {
        lat: 13.02507011481568,
        lng: 80.26778404682138,
      },
      {
        lat: 13.025115476498343,
        lng: 80.26845951804493,
      },
      {
        lat: 13.025208566563478,
        lng: 80.27019956917391,
      },
      {
        lat: 13.02500679534569,
        lng: 80.27093857332274,
      },
      {
        lat: 13.02447825398396,
        lng: 80.27221283493371,
      },
      {
        lat: 13.024213485050254,
        lng: 80.27276804812196,
      },
      {
        lat: 13.023931578336105,
        lng: 80.27389685919019,
      },
      {
        lat: 13.022687370912115,
        lng: 80.2762012005947,
      },
      {
        lat: 13.022239712425648,
        lng: 80.27720853171192,
      },
      {
        lat: 13.022024066105498,
        lng: 80.27811455099413,
      },
      {
        lat: 13.019684717846706,
        lng: 80.27779961621873,
      },
      {
        lat: 13.015595900280816,
        lng: 80.27728103268471,
      },
      {
        lat: 13.011629388981342,
        lng: 80.27651555259307,
      },
      {
        lat: 13.011360309396839,
        lng: 80.27642894656613,
      },
      {
        lat: 13.011482611495117,
        lng: 80.27273101182487,
      },
      {
        lat: 13.011785228050984,
        lng: 80.26473920356095,
      },
      {
        lat: 13.011335263905963,
        lng: 80.25900470326143,
      },
    ],
    properties: {
      Ward_No: 173,
      Zone_Name: "ADYAR",
      fillColor: "#de4307",
      zname: "ADYAR(173)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.014468866696715,
        lng: 80.20456934260976,
      },
      {
        lat: 13.01077351315579,
        lng: 80.21256046433055,
      },
      {
        lat: 13.020994800970414,
        lng: 80.20350680238799,
      },
      {
        lat: 13.008254165522361,
        lng: 80.20746685945588,
      },
      {
        lat: 13.014701287204351,
        lng: 80.20756247821451,
      },
      {
        lat: 13.014960773759748,
        lng: 80.22045122501734,
      },
      {
        lat: 13.023613877909678,
        lng: 80.20627455521928,
      },
      {
        lat: 13.022916784718213,
        lng: 80.2073679522856,
      },
      {
        lat: 13.014266525872934,
        lng: 80.21426975164901,
      },
      {
        lat: 13.025460628382511,
        lng: 80.20071840334332,
      },
      {
        lat: 13.018736385843816,
        lng: 80.2089461656653,
      },
      {
        lat: 13.025904946943923,
        lng: 80.20749051303874,
      },
      {
        lat: 13.017858640824018,
        lng: 80.20151247563487,
      },
      {
        lat: 13.018912159802351,
        lng: 80.21005212606867,
      },
      {
        lat: 13.020420416863988,
        lng: 80.20837994619971,
      },
      {
        lat: 13.0282616480694,
        lng: 80.20080632510839,
      },
      {
        lat: 13.015926349315368,
        lng: 80.22170759637777,
      },
      {
        lat: 13.015288379461348,
        lng: 80.20451171536496,
      },
      {
        lat: 13.016507183354003,
        lng: 80.21891837324712,
      },
      {
        lat: 13.011396450713137,
        lng: 80.2147049528667,
      },
      {
        lat: 13.019765556113141,
        lng: 80.20620229368429,
      },
      {
        lat: 13.008616216190903,
        lng: 80.21088130502037,
      },
      {
        lat: 13.015105036631574,
        lng: 80.22341444719265,
      },
      {
        lat: 13.015748208011614,
        lng: 80.21669253617972,
      },
      {
        lat: 13.016326083472034,
        lng: 80.19904973535087,
      },
      {
        lat: 13.014370831831062,
        lng: 80.21421170137543,
      },
      {
        lat: 13.013634698217139,
        lng: 80.20345833196657,
      },
      {
        lat: 13.019006472931475,
        lng: 80.21711095381227,
      },
      {
        lat: 13.012246549580263,
        lng: 80.22109221381105,
      },
      {
        lat: 13.01037168572666,
        lng: 80.21223857242772,
      },
      {
        lat: 13.022394066475625,
        lng: 80.20645846306947,
      },
      {
        lat: 13.00876515204958,
        lng: 80.20735588488854,
      },
      {
        lat: 13.023188499836301,
        lng: 80.20006992199718,
      },
      {
        lat: 13.0182989626374,
        lng: 80.2104725721592,
      },
      {
        lat: 13.01435012995454,
        lng: 80.21039888361426,
      },
      {
        lat: 13.008096008394896,
        lng: 80.20564182554814,
      },
      {
        lat: 13.02230268902312,
        lng: 80.20662381651279,
      },
      {
        lat: 13.017682298531986,
        lng: 80.20639829114631,
      },
      {
        lat: 13.026243193298003,
        lng: 80.20869759223638,
      },
      {
        lat: 13.01194319088293,
        lng: 80.20912308545488,
      },
      {
        lat: 13.010424666344203,
        lng: 80.20612138747754,
      },
      {
        lat: 13.025775179532861,
        lng: 80.20924960590834,
      },
      {
        lat: 13.012049174927649,
        lng: 80.21697804387294,
      },
      {
        lat: 13.018196375940951,
        lng: 80.21872840373463,
      },
      {
        lat: 13.018122684110963,
        lng: 80.20081393803841,
      },
      {
        lat: 13.014863008799983,
        lng: 80.20620642256091,
      },
      {
        lat: 13.02191052235342,
        lng: 80.20149197462831,
      },
      {
        lat: 13.026798152588691,
        lng: 80.20428795648985,
      },
      {
        lat: 13.0202073901944,
        lng: 80.20106389672067,
      },
      {
        lat: 13.01221527166589,
        lng: 80.21864729680445,
      },
      {
        lat: 13.0246775107722,
        lng: 80.20766476723587,
      },
      {
        lat: 13.018435072197207,
        lng: 80.21561631022169,
      },
      {
        lat: 13.025126589888744,
        lng: 80.20371080870214,
      },
      {
        lat: 13.014241121433578,
        lng: 80.2012245352202,
      },
      {
        lat: 13.014039310482808,
        lng: 80.20369580076498,
      },
      {
        lat: 13.022752352745863,
        lng: 80.20805509367152,
      },
      {
        lat: 13.012202495179817,
        lng: 80.21352596308431,
      },
      {
        lat: 13.011994307842423,
        lng: 80.21279756677964,
      },
      {
        lat: 13.010618122936688,
        lng: 80.2148544021018,
      },
      {
        lat: 13.014666076240035,
        lng: 80.20487728626483,
      },
      {
        lat: 13.0109128219796,
        lng: 80.21738595248152,
      },
      {
        lat: 13.015663748005213,
        lng: 80.21934014273812,
      },
      {
        lat: 13.009986273018026,
        lng: 80.21408927651922,
      },
      {
        lat: 13.025436909120156,
        lng: 80.20807081190456,
      },
      {
        lat: 13.011849490516349,
        lng: 80.21383224663442,
      },
      {
        lat: 13.01001403626086,
        lng: 80.21238029514545,
      },
      {
        lat: 13.017920280641933,
        lng: 80.21537417174412,
      },
      {
        lat: 13.022143490047597,
        lng: 80.20833670774131,
      },
      {
        lat: 13.01627936504591,
        lng: 80.20327264061535,
      },
      {
        lat: 13.020183748730659,
        lng: 80.19980157719381,
      },
      {
        lat: 13.015791530929999,
        lng: 80.21306164794603,
      },
      {
        lat: 13.01741444474687,
        lng: 80.21919347078249,
      },
      {
        lat: 13.016345359792952,
        lng: 80.20432457008107,
      },
      {
        lat: 13.014876223452742,
        lng: 80.21933901353874,
      },
      {
        lat: 13.01964503447263,
        lng: 80.20491270191697,
      },
      {
        lat: 13.017680574109807,
        lng: 80.21421394059757,
      },
      {
        lat: 13.020590197948264,
        lng: 80.20376881249773,
      },
      {
        lat: 13.017520293144829,
        lng: 80.21026797423436,
      },
      {
        lat: 13.014360899179675,
        lng: 80.2021251735788,
      },
      {
        lat: 13.014895016891865,
        lng: 80.20066067232715,
      },
      {
        lat: 13.011807519253473,
        lng: 80.21314101825853,
      },
      {
        lat: 13.013604118142078,
        lng: 80.20580524291246,
      },
      {
        lat: 13.011702865394073,
        lng: 80.2180101649247,
      },
      {
        lat: 13.009230493550955,
        lng: 80.2073065447033,
      },
      {
        lat: 13.010117959480883,
        lng: 80.21274805497204,
      },
      {
        lat: 13.021140613337,
        lng: 80.20565817395105,
      },
      {
        lat: 13.018049242053984,
        lng: 80.20538885055802,
      },
      {
        lat: 13.01001259896668,
        lng: 80.20786016035235,
      },
      {
        lat: 13.011533520252952,
        lng: 80.20771475667352,
      },
      {
        lat: 13.015091592080903,
        lng: 80.22137915663085,
      },
      {
        lat: 13.00986084770297,
        lng: 80.20581715044484,
      },
      {
        lat: 13.023386338916062,
        lng: 80.20580035878444,
      },
    ],
    cases: 92,
    coords: [
      {
        lat: 13.017294705250194,
        lng: 80.2250007948206,
      },
      {
        lat: 13.016437118920917,
        lng: 80.22541323812703,
      },
      {
        lat: 13.01570260351425,
        lng: 80.22527376080785,
      },
      {
        lat: 13.015261096765528,
        lng: 80.22505895682359,
      },
      {
        lat: 13.01300602663222,
        lng: 80.22220059122331,
      },
      {
        lat: 13.012229734592113,
        lng: 80.2212237286175,
      },
      {
        lat: 13.010069981365694,
        lng: 80.21520475864466,
      },
      {
        lat: 13.008347620786678,
        lng: 80.21022015265676,
      },
      {
        lat: 13.007756920325772,
        lng: 80.20809435269366,
      },
      {
        lat: 13.00707471341081,
        lng: 80.20449415848641,
      },
      {
        lat: 13.007071971951465,
        lng: 80.20404343570054,
      },
      {
        lat: 13.007317581377947,
        lng: 80.2037135417804,
      },
      {
        lat: 13.0110723983583,
        lng: 80.20359980997463,
      },
      {
        lat: 13.012147339411845,
        lng: 80.20391852773362,
      },
      {
        lat: 13.012518993458057,
        lng: 80.20395635923703,
      },
      {
        lat: 13.012682317702986,
        lng: 80.20366813862566,
      },
      {
        lat: 13.012844146606456,
        lng: 80.20313406922223,
      },
      {
        lat: 13.013089257585788,
        lng: 80.20272222570468,
      },
      {
        lat: 13.013459167066792,
        lng: 80.20247323361706,
      },
      {
        lat: 13.013704278046118,
        lng: 80.20206139009949,
      },
      {
        lat: 13.014429642040625,
        lng: 80.19918686759816,
      },
      {
        lat: 13.01471402643365,
        lng: 80.19844687643588,
      },
      {
        lat: 13.015001650733387,
        lng: 80.19823955765703,
      },
      {
        lat: 13.015868261985933,
        lng: 80.19823222330128,
      },
      {
        lat: 13.017107524178712,
        lng: 80.19842661964371,
      },
      {
        lat: 13.02743578908832,
        lng: 80.20022414662606,
      },
      {
        lat: 13.028303895682317,
        lng: 80.2004626610626,
      },
      {
        lat: 13.028675549728483,
        lng: 80.20050049256598,
      },
      {
        lat: 13.027356239810175,
        lng: 80.20668502832743,
      },
      {
        lat: 13.02700938991815,
        lng: 80.20777968449156,
      },
      {
        lat: 13.026184099050043,
        lng: 80.20916636531314,
      },
      {
        lat: 13.025664409307975,
        lng: 80.2100292411995,
      },
      {
        lat: 13.025234367325108,
        lng: 80.21040079973535,
      },
      {
        lat: 13.02363099477054,
        lng: 80.21078228847182,
      },
      {
        lat: 13.02125830485985,
        lng: 80.21159952694957,
      },
      {
        lat: 13.020271728810897,
        lng: 80.21185315591758,
      },
      {
        lat: 13.020117902634682,
        lng: 80.2119464375333,
      },
      {
        lat: 13.020212585222065,
        lng: 80.212282895274,
      },
      {
        lat: 13.0207137052014,
        lng: 80.21344373093214,
      },
      {
        lat: 13.020596536873974,
        lng: 80.21448715966498,
      },
      {
        lat: 13.019689803151925,
        lng: 80.21771412465276,
      },
      {
        lat: 13.019543435980337,
        lng: 80.21903373973717,
      },
      {
        lat: 13.019484665341569,
        lng: 80.21952479576682,
      },
      {
        lat: 13.019362275711126,
        lng: 80.2197097910716,
      },
      {
        lat: 13.019022440912634,
        lng: 80.21968200726658,
      },
      {
        lat: 13.018681487263448,
        lng: 80.21947027344152,
      },
      {
        lat: 13.0174449106502,
        lng: 80.21923545959083,
      },
      {
        lat: 13.01667167731675,
        lng: 80.2190273842606,
      },
      {
        lat: 13.016425033304543,
        lng: 80.21909079150271,
      },
      {
        lat: 13.016334826120172,
        lng: 80.21949013384332,
      },
      {
        lat: 13.015708410657322,
        lng: 80.22311330531882,
      },
      {
        lat: 13.015897402881455,
        lng: 80.22372490412685,
      },
      {
        lat: 13.016210463012236,
        lng: 80.22442743266059,
      },
      {
        lat: 13.016614103277627,
        lng: 80.22479193552734,
      },
      {
        lat: 13.017294705250194,
        lng: 80.2250007948206,
      },
    ],
    properties: {
      Ward_No: 170,
      Zone_Name: "ADYAR",
      fillColor: "#f6d04d",
      zname: "ADYAR(170)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.02802506011425,
        lng: 80.23719673807008,
      },
      {
        lat: 13.013779215157095,
        lng: 80.2273048166271,
      },
      {
        lat: 13.026863020374433,
        lng: 80.22882518488281,
      },
      {
        lat: 13.016223247159955,
        lng: 80.23145849076082,
      },
      {
        lat: 13.029337775303047,
        lng: 80.23824947376463,
      },
      {
        lat: 13.022262090987153,
        lng: 80.22736341612155,
      },
      {
        lat: 13.016475438348612,
        lng: 80.23404948478836,
      },
      {
        lat: 13.01402151343274,
        lng: 80.23272280657785,
      },
      {
        lat: 13.026323629138759,
        lng: 80.22660719272153,
      },
      {
        lat: 13.018956701323708,
        lng: 80.22860285449848,
      },
      {
        lat: 13.02326937740937,
        lng: 80.23025890278645,
      },
      {
        lat: 13.016972251628781,
        lng: 80.22836952229876,
      },
      {
        lat: 13.014149999842187,
        lng: 80.22851820569959,
      },
      {
        lat: 13.019974191450311,
        lng: 80.23618970762685,
      },
      {
        lat: 13.008247077686336,
        lng: 80.23520792019605,
      },
      {
        lat: 13.015116844052232,
        lng: 80.23704136955956,
      },
      {
        lat: 13.02667751920011,
        lng: 80.22964350890989,
      },
      {
        lat: 13.013492625932258,
        lng: 80.2354853212979,
      },
      {
        lat: 13.019935864768296,
        lng: 80.2260793564067,
      },
      {
        lat: 13.013961872029544,
        lng: 80.23201284890294,
      },
      {
        lat: 13.011471354964732,
        lng: 80.22308159962223,
      },
      {
        lat: 13.028199384341077,
        lng: 80.22407686854643,
      },
      {
        lat: 13.010576653330821,
        lng: 80.23290607342865,
      },
      {
        lat: 13.014140229188575,
        lng: 80.22457437166621,
      },
      {
        lat: 13.025591552829763,
        lng: 80.23475771398553,
      },
      {
        lat: 13.01616712428622,
        lng: 80.2326925898141,
      },
      {
        lat: 13.014371723005322,
        lng: 80.23632664293663,
      },
      {
        lat: 13.008172226301864,
        lng: 80.23395801857279,
      },
      {
        lat: 13.016540587735935,
        lng: 80.23409363128442,
      },
      {
        lat: 13.015276518889632,
        lng: 80.23626829039803,
      },
      {
        lat: 13.027482982355865,
        lng: 80.22530571721755,
      },
      {
        lat: 13.023880178549735,
        lng: 80.23520432290407,
      },
      {
        lat: 13.02244345339096,
        lng: 80.23662448189673,
      },
      {
        lat: 13.007812486070573,
        lng: 80.24005605247211,
      },
      {
        lat: 13.018907266229762,
        lng: 80.23228050872859,
      },
      {
        lat: 13.022792936441475,
        lng: 80.23422546611536,
      },
      {
        lat: 13.008697955258446,
        lng: 80.23851490711733,
      },
      {
        lat: 13.027824676539819,
        lng: 80.23053076570241,
      },
      {
        lat: 13.029405500097003,
        lng: 80.22866462414387,
      },
      {
        lat: 13.019454813947807,
        lng: 80.23444329752742,
      },
      {
        lat: 13.008156288035707,
        lng: 80.23390597949854,
      },
      {
        lat: 13.01197478003692,
        lng: 80.22308174733901,
      },
      {
        lat: 13.025491680356055,
        lng: 80.23491615477327,
      },
      {
        lat: 13.027751958226453,
        lng: 80.22669565549747,
      },
      {
        lat: 13.017630311214642,
        lng: 80.23083798948922,
      },
      {
        lat: 13.015694699720793,
        lng: 80.23523373266136,
      },
      {
        lat: 13.011461069448382,
        lng: 80.23308912438425,
      },
      {
        lat: 13.026150100550085,
        lng: 80.22468690927342,
      },
      {
        lat: 13.013757614982536,
        lng: 80.23192483799957,
      },
      {
        lat: 13.013947822745854,
        lng: 80.23617229302172,
      },
      {
        lat: 13.011777401022824,
        lng: 80.22618853829061,
      },
      {
        lat: 13.013291455841843,
        lng: 80.22692776798301,
      },
      {
        lat: 13.028082235071983,
        lng: 80.23621897227488,
      },
      {
        lat: 13.014103305455725,
        lng: 80.23754210098026,
      },
      {
        lat: 13.018129072955894,
        lng: 80.22502336222074,
      },
      {
        lat: 13.027287497076827,
        lng: 80.22895349201434,
      },
      {
        lat: 13.029383963856779,
        lng: 80.2390123932664,
      },
      {
        lat: 13.027516915871905,
        lng: 80.23616603254817,
      },
      {
        lat: 13.027934698953915,
        lng: 80.22846942785596,
      },
      {
        lat: 13.019978659276633,
        lng: 80.22476541593731,
      },
      {
        lat: 13.02627268798562,
        lng: 80.22575880826989,
      },
      {
        lat: 13.02128121760411,
        lng: 80.23210738011167,
      },
      {
        lat: 13.018120796115886,
        lng: 80.23294190877891,
      },
      {
        lat: 13.021086680714763,
        lng: 80.23148177157363,
      },
      {
        lat: 13.01637068513704,
        lng: 80.23187409286437,
      },
      {
        lat: 13.015691153041711,
        lng: 80.23897508534242,
      },
      {
        lat: 13.015477842936912,
        lng: 80.236613338273,
      },
      {
        lat: 13.012269519132463,
        lng: 80.22895811720424,
      },
      {
        lat: 13.031188930161417,
        lng: 80.23758736926386,
      },
      {
        lat: 13.019670585555529,
        lng: 80.22736306259534,
      },
      {
        lat: 13.010678109115762,
        lng: 80.23687115968443,
      },
      {
        lat: 13.026953958606128,
        lng: 80.23549467308455,
      },
      {
        lat: 13.027998802056295,
        lng: 80.23652964661632,
      },
      {
        lat: 13.017380274329225,
        lng: 80.23284710401148,
      },
      {
        lat: 13.010647072324662,
        lng: 80.23813432558678,
      },
      {
        lat: 13.011465734390018,
        lng: 80.23932389030003,
      },
      {
        lat: 13.015968153420175,
        lng: 80.22931054549261,
      },
      {
        lat: 13.015751527415848,
        lng: 80.23797051430518,
      },
      {
        lat: 13.027977269111613,
        lng: 80.23471538475367,
      },
      {
        lat: 13.01869794951374,
        lng: 80.2345736392345,
      },
      {
        lat: 13.020227873118193,
        lng: 80.23019144070871,
      },
      {
        lat: 13.013980092833277,
        lng: 80.2256235184668,
      },
      {
        lat: 13.01863377234411,
        lng: 80.22480090920104,
      },
      {
        lat: 13.01208711447682,
        lng: 80.23029574907481,
      },
      {
        lat: 13.018726076088843,
        lng: 80.22904712610827,
      },
    ],
    cases: 85,
    coords: [
      {
        lat: 13.02679977701061,
        lng: 80.2393151809825,
      },
      {
        lat: 13.02443993430612,
        lng: 80.23833853266845,
      },
      {
        lat: 13.022949988186602,
        lng: 80.23794615332177,
      },
      {
        lat: 13.021485047971668,
        lng: 80.23772032258059,
      },
      {
        lat: 13.021149599399774,
        lng: 80.2377946302418,
      },
      {
        lat: 13.020958680359026,
        lng: 80.23796300627632,
      },
      {
        lat: 13.020793491683087,
        lng: 80.23841703924018,
      },
      {
        lat: 13.0206261296271,
        lng: 80.2385137472326,
      },
      {
        lat: 13.019976760979631,
        lng: 80.23825719120669,
      },
      {
        lat: 13.019809688707475,
        lng: 80.23840154252878,
      },
      {
        lat: 13.019380736657126,
        lng: 80.23888163068123,
      },
      {
        lat: 13.019165825955968,
        lng: 80.23905020976345,
      },
      {
        lat: 13.018973602887398,
        lng: 80.23900419081498,
      },
      {
        lat: 13.018876477109147,
        lng: 80.23881442968762,
      },
      {
        lat: 13.018708390593233,
        lng: 80.23879202935639,
      },
      {
        lat: 13.018709404837223,
        lng: 80.23895878100966,
      },
      {
        lat: 13.018712157785199,
        lng: 80.23941139263978,
      },
      {
        lat: 13.018644384671282,
        lng: 80.24010283006056,
      },
      {
        lat: 13.018337274519826,
        lng: 80.2408915846165,
      },
      {
        lat: 13.018147224831246,
        lng: 80.2412028906394,
      },
      {
        lat: 13.017835188351826,
        lng: 80.24118170859397,
      },
      {
        lat: 13.016537465300821,
        lng: 80.2408353481957,
      },
      {
        lat: 13.014988653512468,
        lng: 80.24057764307932,
      },
      {
        lat: 13.008164793467518,
        lng: 80.24045327803458,
      },
      {
        lat: 13.006844039216102,
        lng: 80.24042803249186,
      },
      {
        lat: 13.006765138059754,
        lng: 80.23951811454282,
      },
      {
        lat: 13.006433897233798,
        lng: 80.23933880077782,
      },
      {
        lat: 13.006102434878521,
        lng: 80.23912306545931,
      },
      {
        lat: 13.005989510606918,
        lng: 80.23865051659759,
      },
      {
        lat: 13.006165385940443,
        lng: 80.23741063155335,
      },
      {
        lat: 13.007033556988848,
        lng: 80.23539999543475,
      },
      {
        lat: 13.007766078633601,
        lng: 80.23521167877217,
      },
      {
        lat: 13.008515122595902,
        lng: 80.2317086902925,
      },
      {
        lat: 13.009154122164412,
        lng: 80.22820663314722,
      },
      {
        lat: 13.010711709049652,
        lng: 80.22495176575808,
      },
      {
        lat: 13.011107894699336,
        lng: 80.22374643959891,
      },
      {
        lat: 13.012229734592113,
        lng: 80.2212237286175,
      },
      {
        lat: 13.01300602663222,
        lng: 80.22220059122331,
      },
      {
        lat: 13.015261096765528,
        lng: 80.22505895682359,
      },
      {
        lat: 13.01570260351425,
        lng: 80.22527376080785,
      },
      {
        lat: 13.016437118920917,
        lng: 80.22541323812703,
      },
      {
        lat: 13.017294705250194,
        lng: 80.2250007948206,
      },
      {
        lat: 13.018558243006211,
        lng: 80.22459152227904,
      },
      {
        lat: 13.019112912321134,
        lng: 80.22440286847939,
      },
      {
        lat: 13.019513941934738,
        lng: 80.22433815463192,
      },
      {
        lat: 13.020040904206313,
        lng: 80.22467095387765,
      },
      {
        lat: 13.02218130868854,
        lng: 80.22627781456944,
      },
      {
        lat: 13.024105759803863,
        lng: 80.22791716284561,
      },
      {
        lat: 13.024666396322436,
        lng: 80.22870957582107,
      },
      {
        lat: 13.02482227372492,
        lng: 80.22895353590944,
      },
      {
        lat: 13.02497516752556,
        lng: 80.22870696261029,
      },
      {
        lat: 13.025186086064389,
        lng: 80.22784669993452,
      },
      {
        lat: 13.025360160279204,
        lng: 80.22600563180484,
      },
      {
        lat: 13.025292438835045,
        lng: 80.22502508767197,
      },
      {
        lat: 13.025319772928103,
        lng: 80.22444231795313,
      },
      {
        lat: 13.025504103274283,
        lng: 80.22428745834304,
      },
      {
        lat: 13.025843751597831,
        lng: 80.22428458381123,
      },
      {
        lat: 13.027448615953325,
        lng: 80.22414836176843,
      },
      {
        lat: 13.028250675180864,
        lng: 80.22401893407365,
      },
      {
        lat: 13.028498438043655,
        lng: 80.22413947685183,
      },
      {
        lat: 13.02856112465986,
        lng: 80.22429224589331,
      },
      {
        lat: 13.028533044666341,
        lng: 80.22475238226525,
      },
      {
        lat: 13.028657671998227,
        lng: 80.22493528700127,
      },
      {
        lat: 13.02899806622201,
        lng: 80.22505504581618,
      },
      {
        lat: 13.030265986143474,
        lng: 80.22536624418768,
      },
      {
        lat: 13.030606380367312,
        lng: 80.22548600300274,
      },
      {
        lat: 13.030669439933575,
        lng: 80.22570008871762,
      },
      {
        lat: 13.030579792174546,
        lng: 80.22619140606835,
      },
      {
        lat: 13.030345828470201,
        lng: 80.2283395802074,
      },
      {
        lat: 13.030319053802497,
        lng: 80.22901432493632,
      },
      {
        lat: 13.03035254157437,
        lng: 80.22944328032925,
      },
      {
        lat: 13.03054134732333,
        lng: 80.23002422080067,
      },
      {
        lat: 13.030697597676049,
        lng: 80.23032949756238,
      },
      {
        lat: 13.030729966597402,
        lng: 80.23057450293503,
      },
      {
        lat: 13.030421941294742,
        lng: 80.2306997494928,
      },
      {
        lat: 13.028231344029068,
        lng: 80.23099422831966,
      },
      {
        lat: 13.026318267895801,
        lng: 80.23122503858333,
      },
      {
        lat: 13.026134124024509,
        lng: 80.23141055653025,
      },
      {
        lat: 13.026229366037239,
        lng: 80.23183898928106,
      },
      {
        lat: 13.026543172068312,
        lng: 80.23266415116179,
      },
      {
        lat: 13.026854180972824,
        lng: 80.23302943799153,
      },
      {
        lat: 13.027101943835733,
        lng: 80.23314998076992,
      },
      {
        lat: 13.027414258066049,
        lng: 80.23372987595687,
      },
      {
        lat: 13.027970978607021,
        lng: 80.2338784638611,
      },
      {
        lat: 13.028281428086181,
        lng: 80.23415177568076,
      },
      {
        lat: 13.029431713492484,
        lng: 80.23542975694323,
      },
      {
        lat: 13.030674630259664,
        lng: 80.2367069542424,
      },
      {
        lat: 13.03098545268889,
        lng: 80.23704158273549,
      },
      {
        lat: 13.031235453253183,
        lng: 80.2375300255544,
      },
      {
        lat: 13.031641331219957,
        lng: 80.2382624284617,
      },
      {
        lat: 13.031738065033444,
        lng: 80.23893612790633,
      },
      {
        lat: 13.031555412963245,
        lng: 80.23936691254687,
      },
      {
        lat: 13.031094120909719,
        lng: 80.23967741573026,
      },
      {
        lat: 13.030693277771235,
        lng: 80.23977278791436,
      },
      {
        lat: 13.029920790338078,
        lng: 80.23968734593117,
      },
      {
        lat: 13.027232243170038,
        lng: 80.23934218082424,
      },
      {
        lat: 13.02679977701061,
        lng: 80.2393151809825,
      },
    ],
    properties: {
      Ward_No: 171,
      Zone_Name: "ADYAR",
      fillColor: "#f6d04d",
      zname: "ADYAR(171)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.002269095693306,
        lng: 80.25357953802649,
      },
      {
        lat: 13.000356860485756,
        lng: 80.24998414642774,
      },
      {
        lat: 13.007515252628897,
        lng: 80.24848574766217,
      },
      {
        lat: 13.005366008534995,
        lng: 80.25666859625929,
      },
      {
        lat: 13.00386127136646,
        lng: 80.24771310748332,
      },
      {
        lat: 12.990779092023683,
        lng: 80.25322471639086,
      },
      {
        lat: 13.009175115224345,
        lng: 80.25162193898132,
      },
      {
        lat: 13.013534676618043,
        lng: 80.25172969297493,
      },
      {
        lat: 13.003129376249374,
        lng: 80.25105242438427,
      },
      {
        lat: 13.006395856029416,
        lng: 80.25295861028434,
      },
      {
        lat: 12.991989269541824,
        lng: 80.25545958456769,
      },
      {
        lat: 12.9941080678806,
        lng: 80.25259643068084,
      },
      {
        lat: 13.003535181439075,
        lng: 80.24946135616942,
      },
      {
        lat: 13.002751465628673,
        lng: 80.25440994794367,
      },
      {
        lat: 12.99727725826524,
        lng: 80.25429953983476,
      },
      {
        lat: 12.997756872745661,
        lng: 80.2497535078731,
      },
      {
        lat: 13.012185499398752,
        lng: 80.25283348094668,
      },
      {
        lat: 13.003820089829633,
        lng: 80.24929486434016,
      },
      {
        lat: 13.001640591249657,
        lng: 80.24871710729253,
      },
      {
        lat: 13.002282536640136,
        lng: 80.25243347724017,
      },
      {
        lat: 12.996211401761768,
        lng: 80.25224175587012,
      },
      {
        lat: 13.014280734456017,
        lng: 80.24980085479555,
      },
      {
        lat: 13.000510000464905,
        lng: 80.25100010255838,
      },
      {
        lat: 13.009794739092825,
        lng: 80.2496701162694,
      },
      {
        lat: 13.002909513307182,
        lng: 80.24924884385827,
      },
      {
        lat: 12.996053629643285,
        lng: 80.25533075294445,
      },
      {
        lat: 12.996355856484334,
        lng: 80.25347927585925,
      },
      {
        lat: 13.001887760064523,
        lng: 80.25003213535652,
      },
      {
        lat: 13.014559719755592,
        lng: 80.24996345703471,
      },
      {
        lat: 13.003265850065095,
        lng: 80.25194081739382,
      },
      {
        lat: 13.009731196661214,
        lng: 80.24977056384796,
      },
      {
        lat: 13.00620987265991,
        lng: 80.25017283656638,
      },
      {
        lat: 13.010224821989278,
        lng: 80.25760790137274,
      },
    ],
    cases: 33,
    coords: [
      {
        lat: 12.990367481024403,
        lng: 80.25591896151515,
      },
      {
        lat: 12.990298422101969,
        lng: 80.25566462999397,
      },
      {
        lat: 12.990172315616011,
        lng: 80.25540079813877,
      },
      {
        lat: 12.989615501197393,
        lng: 80.25450485357194,
      },
      {
        lat: 12.989792707254608,
        lng: 80.25439739418037,
      },
      {
        lat: 12.990147334186329,
        lng: 80.25421779346274,
      },
      {
        lat: 12.990181830201822,
        lng: 80.25404090209604,
      },
      {
        lat: 12.990055831124504,
        lng: 80.25379472927364,
      },
      {
        lat: 12.990018005441417,
        lng: 80.25342419062439,
      },
      {
        lat: 12.990122352756702,
        lng: 80.25303478878645,
      },
      {
        lat: 12.990135197010273,
        lng: 80.25222232275955,
      },
      {
        lat: 12.990275007018964,
        lng: 80.25181496084983,
      },
      {
        lat: 12.990469890718506,
        lng: 80.25168969190588,
      },
      {
        lat: 12.994518652624915,
        lng: 80.25063114955964,
      },
      {
        lat: 12.994446934355553,
        lng: 80.24986189950644,
      },
      {
        lat: 12.997335299324018,
        lng: 80.24915531501445,
      },
      {
        lat: 13.000908889448155,
        lng: 80.24815571464855,
      },
      {
        lat: 13.004881947082081,
        lng: 80.24743995012123,
      },
      {
        lat: 13.006075109014123,
        lng: 80.24742985208238,
      },
      {
        lat: 13.006473266374593,
        lng: 80.24749828653245,
      },
      {
        lat: 13.00676382790142,
        lng: 80.24771123991177,
      },
      {
        lat: 13.008824743965754,
        lng: 80.24769379784463,
      },
      {
        lat: 13.014937362896843,
        lng: 80.24800108596023,
      },
      {
        lat: 13.015452267282676,
        lng: 80.24819071702564,
      },
      {
        lat: 13.011335263905963,
        lng: 80.25900470326143,
      },
      {
        lat: 13.010472437721768,
        lng: 80.25888519213652,
      },
      {
        lat: 13.008300565209959,
        lng: 80.2587767598102,
      },
      {
        lat: 13.007660270633037,
        lng: 80.25849684853415,
      },
      {
        lat: 13.00672934645944,
        lng: 80.25768043978336,
      },
      {
        lat: 13.004746342203,
        lng: 80.25712656195246,
      },
      {
        lat: 12.99867308838278,
        lng: 80.25603664045434,
      },
      {
        lat: 12.996893853172299,
        lng: 80.2559115512938,
      },
      {
        lat: 12.994244744857076,
        lng: 80.25591005930518,
      },
      {
        lat: 12.993691888841337,
        lng: 80.25608212282417,
      },
      {
        lat: 12.99036835363109,
        lng: 80.25606242660601,
      },
      {
        lat: 12.990367481024403,
        lng: 80.25591896151515,
      },
    ],
    properties: {
      Ward_No: 175,
      Zone_Name: "ADYAR",
      fillColor: "#8bc24c",
      zname: "ADYAR(175)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.980849045206883,
        lng: 80.24227083176834,
      },
      {
        lat: 12.985844390094098,
        lng: 80.24124232547842,
      },
      {
        lat: 12.989224529974436,
        lng: 80.25193417148013,
      },
      {
        lat: 12.984118722638895,
        lng: 80.2539185630826,
      },
      {
        lat: 12.982410006374488,
        lng: 80.24982440883363,
      },
      {
        lat: 12.985900479739472,
        lng: 80.24527071628692,
      },
      {
        lat: 12.994583448658066,
        lng: 80.2439421188887,
      },
      {
        lat: 12.985632367236626,
        lng: 80.2427578456272,
      },
      {
        lat: 12.990827086576944,
        lng: 80.24988345228704,
      },
      {
        lat: 12.983789352489529,
        lng: 80.24418321199349,
      },
      {
        lat: 12.982218097016315,
        lng: 80.25205064323497,
      },
      {
        lat: 12.991772328107979,
        lng: 80.24472520708474,
      },
      {
        lat: 12.988871439113046,
        lng: 80.24165868050154,
      },
      {
        lat: 12.991459103292188,
        lng: 80.24380072453604,
      },
      {
        lat: 12.988614688112653,
        lng: 80.25292580050221,
      },
      {
        lat: 12.982152577170247,
        lng: 80.24102979030191,
      },
      {
        lat: 12.98451147256032,
        lng: 80.24411237348234,
      },
      {
        lat: 12.99174695853117,
        lng: 80.2505746950024,
      },
      {
        lat: 12.99153978581682,
        lng: 80.24396332565671,
      },
      {
        lat: 12.986042428055123,
        lng: 80.2481205908078,
      },
      {
        lat: 12.988993566547043,
        lng: 80.24112128867111,
      },
      {
        lat: 12.985923668917952,
        lng: 80.25210115126372,
      },
      {
        lat: 12.98101116008179,
        lng: 80.23980384609915,
      },
      {
        lat: 12.992182618022442,
        lng: 80.24794806988807,
      },
      {
        lat: 12.981716696749096,
        lng: 80.24753555859886,
      },
      {
        lat: 12.983331246954934,
        lng: 80.24156271128051,
      },
      {
        lat: 12.982592748219295,
        lng: 80.23624841829034,
      },
      {
        lat: 12.985495417499973,
        lng: 80.24354990277479,
      },
      {
        lat: 12.989064732274059,
        lng: 80.24518229381604,
      },
      {
        lat: 12.987811436691475,
        lng: 80.24519233736079,
      },
      {
        lat: 12.993323943635502,
        lng: 80.24872664408595,
      },
      {
        lat: 12.991473545445709,
        lng: 80.24373022247951,
      },
      {
        lat: 12.982527706279592,
        lng: 80.24307050027352,
      },
      {
        lat: 12.99037953920321,
        lng: 80.2421599311964,
      },
      {
        lat: 12.989779654830022,
        lng: 80.25438899275107,
      },
      {
        lat: 12.981796381721445,
        lng: 80.23964063177742,
      },
      {
        lat: 12.981975470941627,
        lng: 80.24206537616907,
      },
      {
        lat: 12.980431760023897,
        lng: 80.24138506700706,
      },
      {
        lat: 12.982231822467282,
        lng: 80.25346821352032,
      },
      {
        lat: 12.993119802346158,
        lng: 80.24699320191922,
      },
      {
        lat: 12.979986887267357,
        lng: 80.23660680139585,
      },
      {
        lat: 12.987713849132906,
        lng: 80.2514369990232,
      },
      {
        lat: 12.981489810790638,
        lng: 80.2476400192825,
      },
      {
        lat: 12.982681537864247,
        lng: 80.25430113015918,
      },
      {
        lat: 12.983821179891468,
        lng: 80.24815293209696,
      },
      {
        lat: 12.994412112957265,
        lng: 80.24505461508153,
      },
      {
        lat: 12.984332649828813,
        lng: 80.24200487349086,
      },
      {
        lat: 12.979723107031793,
        lng: 80.24317017741552,
      },
      {
        lat: 12.984313419486615,
        lng: 80.24352167037924,
      },
      {
        lat: 12.988202728484001,
        lng: 80.2461063432205,
      },
      {
        lat: 12.984666135025845,
        lng: 80.24458952599535,
      },
      {
        lat: 12.978742633451898,
        lng: 80.24283303084128,
      },
      {
        lat: 12.986528486800925,
        lng: 80.25343863432178,
      },
      {
        lat: 12.98380956679771,
        lng: 80.23674857655251,
      },
      {
        lat: 12.989213790052053,
        lng: 80.2532034639774,
      },
      {
        lat: 12.988312008263854,
        lng: 80.25307838625876,
      },
      {
        lat: 12.983798253355214,
        lng: 80.2396221669915,
      },
      {
        lat: 12.991354834371139,
        lng: 80.24989763092972,
      },
      {
        lat: 12.988185517770326,
        lng: 80.25528278943612,
      },
      {
        lat: 12.98442280136525,
        lng: 80.25050388010084,
      },
      {
        lat: 12.980942645359388,
        lng: 80.24119924960152,
      },
      {
        lat: 12.980273304158326,
        lng: 80.23624435560677,
      },
      {
        lat: 12.991322644028218,
        lng: 80.24165209163328,
      },
      {
        lat: 12.981300404817945,
        lng: 80.24120120549368,
      },
      {
        lat: 12.98682352097952,
        lng: 80.24184978349014,
      },
      {
        lat: 12.993256306731288,
        lng: 80.24554444557572,
      },
      {
        lat: 12.988696281519802,
        lng: 80.25578588115769,
      },
      {
        lat: 12.995094602884487,
        lng: 80.24386025161256,
      },
      {
        lat: 12.991157913957473,
        lng: 80.24792463209053,
      },
      {
        lat: 12.993187354919538,
        lng: 80.24800175094477,
      },
      {
        lat: 12.986503855131184,
        lng: 80.25061654141419,
      },
      {
        lat: 12.995033437529006,
        lng: 80.24241264225458,
      },
      {
        lat: 12.99261776859763,
        lng: 80.24855747299532,
      },
      {
        lat: 12.990756746326346,
        lng: 80.24206926941218,
      },
      {
        lat: 12.984492559047876,
        lng: 80.24080518063153,
      },
      {
        lat: 12.983031268906942,
        lng: 80.25144868697177,
      },
      {
        lat: 12.990218232793755,
        lng: 80.24409423611546,
      },
      {
        lat: 12.989784488381103,
        lng: 80.25200647493911,
      },
      {
        lat: 12.983004516404167,
        lng: 80.23987397105213,
      },
    ],
    cases: 79,
    coords: [
      {
        lat: 12.995096667428317,
        lng: 80.24970295445685,
      },
      {
        lat: 12.994446934355553,
        lng: 80.24986189950644,
      },
      {
        lat: 12.994518652624915,
        lng: 80.25063114955964,
      },
      {
        lat: 12.990469890718506,
        lng: 80.25168969190588,
      },
      {
        lat: 12.990275007018964,
        lng: 80.25181496084983,
      },
      {
        lat: 12.990135197010273,
        lng: 80.25222232275955,
      },
      {
        lat: 12.990122352756702,
        lng: 80.25303478878645,
      },
      {
        lat: 12.990018005441417,
        lng: 80.25342419062439,
      },
      {
        lat: 12.990055831124504,
        lng: 80.25379472927364,
      },
      {
        lat: 12.990181830201822,
        lng: 80.25404090209604,
      },
      {
        lat: 12.990147334186329,
        lng: 80.25421779346274,
      },
      {
        lat: 12.989792707254608,
        lng: 80.25439739418037,
      },
      {
        lat: 12.989615501197393,
        lng: 80.25450485357194,
      },
      {
        lat: 12.990172315616011,
        lng: 80.25540079813877,
      },
      {
        lat: 12.990298422101969,
        lng: 80.25566462999397,
      },
      {
        lat: 12.990367481024403,
        lng: 80.25591896151515,
      },
      {
        lat: 12.988320409727754,
        lng: 80.25591237433096,
      },
      {
        lat: 12.987717790971628,
        lng: 80.25582182613256,
      },
      {
        lat: 12.982652971991802,
        lng: 80.2545973508048,
      },
      {
        lat: 12.982072689502854,
        lng: 80.25421966861664,
      },
      {
        lat: 12.981724171715738,
        lng: 80.25354498561114,
      },
      {
        lat: 12.981721181032816,
        lng: 80.25305328802658,
      },
      {
        lat: 12.981512602008182,
        lng: 80.25268626111315,
      },
      {
        lat: 12.980398387540545,
        lng: 80.25269569099912,
      },
      {
        lat: 12.979902682663493,
        lng: 80.25261793246213,
      },
      {
        lat: 12.979818902140774,
        lng: 80.2524137569786,
      },
      {
        lat: 12.980428938129224,
        lng: 80.25093342539898,
      },
      {
        lat: 12.980665824730524,
        lng: 80.2491694135236,
      },
      {
        lat: 12.98078140196015,
        lng: 80.24781619740074,
      },
      {
        lat: 12.98113623341454,
        lng: 80.24508822999006,
      },
      {
        lat: 12.981254552103515,
        lng: 80.24418573665307,
      },
      {
        lat: 12.981251810644169,
        lng: 80.24373501386718,
      },
      {
        lat: 12.981084000374857,
        lng: 80.24328568810124,
      },
      {
        lat: 12.980629562700113,
        lng: 80.24320758030926,
      },
      {
        lat: 12.979433312274844,
        lng: 80.24329965830269,
      },
      {
        lat: 12.979062156675838,
        lng: 80.24334377639676,
      },
      {
        lat: 12.978772040140328,
        lng: 80.24314134718844,
      },
      {
        lat: 12.978728031478509,
        lng: 80.24269097365742,
      },
      {
        lat: 12.979426728550598,
        lng: 80.23543214769268,
      },
      {
        lat: 12.97941725805468,
        lng: 80.23387510534111,
      },
      {
        lat: 12.979207432912158,
        lng: 80.23330320443414,
      },
      {
        lat: 12.98137706561133,
        lng: 80.2331867402187,
      },
      {
        lat: 12.981661055350775,
        lng: 80.23306316940878,
      },
      {
        lat: 12.98170259036158,
        lng: 80.23320417977338,
      },
      {
        lat: 12.981522622385157,
        lng: 80.23371056676882,
      },
      {
        lat: 12.981280904602151,
        lng: 80.23409630903754,
      },
      {
        lat: 12.98138357529824,
        lng: 80.23425699655023,
      },
      {
        lat: 12.9828297571816,
        lng: 80.23460825913133,
      },
      {
        lat: 12.982670378515545,
        lng: 80.23507684166312,
      },
      {
        lat: 12.982649906290636,
        lng: 80.2355927593949,
      },
      {
        lat: 12.98359683307975,
        lng: 80.23600671805609,
      },
      {
        lat: 12.983976288184444,
        lng: 80.23628482179467,
      },
      {
        lat: 12.984119795787644,
        lng: 80.23658836534837,
      },
      {
        lat: 12.983980137873065,
        lng: 80.23691774833604,
      },
      {
        lat: 12.983817298693383,
        lng: 80.23731765630393,
      },
      {
        lat: 12.983986554020769,
        lng: 80.23797262590489,
      },
      {
        lat: 12.984346392386858,
        lng: 80.23890729771774,
      },
      {
        lat: 12.984445248432797,
        lng: 80.23963319191758,
      },
      {
        lat: 12.985466994516218,
        lng: 80.24070291941659,
      },
      {
        lat: 12.985775622596286,
        lng: 80.24098162258247,
      },
      {
        lat: 12.986200727325825,
        lng: 80.24100146774136,
      },
      {
        lat: 12.986647159766683,
        lng: 80.24064604551094,
      },
      {
        lat: 12.987189739213344,
        lng: 80.24057112472913,
      },
      {
        lat: 12.992436071963242,
        lng: 80.24137066915088,
      },
      {
        lat: 12.993499475402059,
        lng: 80.24152576980511,
      },
      {
        lat: 12.994679212909343,
        lng: 80.24139857072828,
      },
      {
        lat: 12.995197755604542,
        lng: 80.24125352458434,
      },
      {
        lat: 12.99578812339184,
        lng: 80.2412719710791,
      },
      {
        lat: 12.995445394752785,
        lng: 80.2431503061168,
      },
      {
        lat: 12.995029842954219,
        lng: 80.24470105644946,
      },
      {
        lat: 12.994873562503303,
        lng: 80.24617928371028,
      },
      {
        lat: 12.994890244487332,
        lng: 80.24892196538963,
      },
      {
        lat: 12.994893381270652,
        lng: 80.24943768331227,
      },
      {
        lat: 12.995096667428317,
        lng: 80.24970295445685,
      },
    ],
    properties: {
      Ward_No: 180,
      Zone_Name: "ADYAR",
      fillColor: "#f6d04d",
      zname: "ADYAR(180)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.014821380118406,
        lng: 80.24306835595851,
      },
      {
        lat: 13.02403871772622,
        lng: 80.24056788300962,
      },
      {
        lat: 13.025823919272257,
        lng: 80.2393295325906,
      },
      {
        lat: 13.025367572519968,
        lng: 80.24061079572809,
      },
      {
        lat: 13.000317463820254,
        lng: 80.24824372847183,
      },
      {
        lat: 13.010367634192285,
        lng: 80.24714815945197,
      },
      {
        lat: 12.99978688287344,
        lng: 80.24382122143402,
      },
      {
        lat: 13.023237056931238,
        lng: 80.23971138692156,
      },
      {
        lat: 12.997152266644374,
        lng: 80.24484048300978,
      },
      {
        lat: 13.001286005095551,
        lng: 80.24367467013568,
      },
      {
        lat: 13.00427854973016,
        lng: 80.2454927428754,
      },
      {
        lat: 13.017355135501775,
        lng: 80.24649234131502,
      },
      {
        lat: 13.01977832098293,
        lng: 80.24069814664932,
      },
      {
        lat: 12.998521121391725,
        lng: 80.24727832439476,
      },
      {
        lat: 13.008032477721052,
        lng: 80.24702560444041,
      },
      {
        lat: 13.0156901471944,
        lng: 80.24652623168711,
      },
      {
        lat: 13.006329781085531,
        lng: 80.24687856068857,
      },
      {
        lat: 13.015768623744776,
        lng: 80.24223030980512,
      },
      {
        lat: 13.021138195529181,
        lng: 80.2420071599518,
      },
      {
        lat: 13.008081509562249,
        lng: 80.24651199891733,
      },
      {
        lat: 13.011779705341196,
        lng: 80.24216548405181,
      },
      {
        lat: 13.022733875877325,
        lng: 80.24255930800004,
      },
      {
        lat: 13.01656276125197,
        lng: 80.24180896231026,
      },
      {
        lat: 13.014406825793099,
        lng: 80.24432833639727,
      },
      {
        lat: 13.023095334987248,
        lng: 80.23893183074004,
      },
      {
        lat: 13.011966001713072,
        lng: 80.24434871455466,
      },
      {
        lat: 12.998147398739041,
        lng: 80.24342566214919,
      },
      {
        lat: 13.023856131156284,
        lng: 80.24149514941641,
      },
      {
        lat: 13.023362957713998,
        lng: 80.24206617888507,
      },
      {
        lat: 12.996125537896853,
        lng: 80.2470625532692,
      },
      {
        lat: 13.001380265798081,
        lng: 80.24753597344382,
      },
      {
        lat: 13.012148221331776,
        lng: 80.241748064138,
      },
      {
        lat: 13.008719020955455,
        lng: 80.24491255380731,
      },
      {
        lat: 12.999516231539674,
        lng: 80.2453781161316,
      },
      {
        lat: 12.999262184115205,
        lng: 80.24441310010076,
      },
      {
        lat: 12.996507386954676,
        lng: 80.24851139447243,
      },
      {
        lat: 13.017475878933071,
        lng: 80.24170872534648,
      },
      {
        lat: 13.006115368852763,
        lng: 80.24720136895388,
      },
      {
        lat: 12.995038600840124,
        lng: 80.24608382444062,
      },
      {
        lat: 12.998220701770478,
        lng: 80.24723787901421,
      },
      {
        lat: 13.018231969125761,
        lng: 80.24680129664965,
      },
      {
        lat: 13.00534644340803,
        lng: 80.24628814117678,
      },
      {
        lat: 13.004301017005389,
        lng: 80.2431485542228,
      },
      {
        lat: 13.018709212121589,
        lng: 80.24450342490644,
      },
      {
        lat: 13.0107377831368,
        lng: 80.24223609834503,
      },
      {
        lat: 13.017200240908409,
        lng: 80.2413556506759,
      },
      {
        lat: 13.006079968258838,
        lng: 80.24642232643914,
      },
      {
        lat: 13.008204723211913,
        lng: 80.2459107288613,
      },
      {
        lat: 13.015123642216373,
        lng: 80.24376735226096,
      },
      {
        lat: 13.018739984003064,
        lng: 80.2437368848466,
      },
      {
        lat: 13.01360105410557,
        lng: 80.24151869263068,
      },
      {
        lat: 13.016532664152257,
        lng: 80.24217191991268,
      },
      {
        lat: 13.021390837848662,
        lng: 80.2384505976279,
      },
      {
        lat: 13.008246067304283,
        lng: 80.24653611202423,
      },
      {
        lat: 13.017360102138916,
        lng: 80.24849414027527,
      },
      {
        lat: 13.00139606130553,
        lng: 80.24626821562211,
      },
      {
        lat: 13.018854288099595,
        lng: 80.24018991759259,
      },
      {
        lat: 13.019420224426428,
        lng: 80.24696371681841,
      },
      {
        lat: 13.019357756837154,
        lng: 80.24753488523564,
      },
      {
        lat: 13.013115931293243,
        lng: 80.24100534561794,
      },
      {
        lat: 13.014388234009425,
        lng: 80.24196745676299,
      },
      {
        lat: 12.999305742601072,
        lng: 80.24557736944234,
      },
      {
        lat: 13.023315419510777,
        lng: 80.24411505564791,
      },
      {
        lat: 13.019765801268992,
        lng: 80.24589770297905,
      },
      {
        lat: 13.018570147276321,
        lng: 80.24390462259252,
      },
      {
        lat: 13.002817900899032,
        lng: 80.24653738101387,
      },
      {
        lat: 12.997995455031843,
        lng: 80.24502789205489,
      },
      {
        lat: 13.022290361514967,
        lng: 80.24291695411168,
      },
      {
        lat: 12.998716872232276,
        lng: 80.24421387784444,
      },
      {
        lat: 13.022781154780985,
        lng: 80.2458405121466,
      },
      {
        lat: 13.00238900763851,
        lng: 80.24585088214396,
      },
      {
        lat: 12.995589562927552,
        lng: 80.24341140782087,
      },
      {
        lat: 13.008409203803703,
        lng: 80.24637076763166,
      },
      {
        lat: 13.01562765733886,
        lng: 80.24700772235028,
      },
      {
        lat: 13.019988199094882,
        lng: 80.24702771715987,
      },
      {
        lat: 13.021318956452433,
        lng: 80.24413334252372,
      },
      {
        lat: 13.021262087678961,
        lng: 80.24268446763979,
      },
      {
        lat: 12.999711577486192,
        lng: 80.24529606459755,
      },
      {
        lat: 13.009727008174828,
        lng: 80.246927250347,
      },
      {
        lat: 13.01943128849311,
        lng: 80.24609385659569,
      },
      {
        lat: 12.997327540517526,
        lng: 80.24878532289667,
      },
      {
        lat: 13.020044554250905,
        lng: 80.24317142800452,
      },
      {
        lat: 12.99897927364394,
        lng: 80.2458417150256,
      },
      {
        lat: 13.001981132715555,
        lng: 80.24579486467859,
      },
      {
        lat: 13.008836111432654,
        lng: 80.24282981624997,
      },
      {
        lat: 13.022892714301125,
        lng: 80.241027835728,
      },
      {
        lat: 13.001148233404452,
        lng: 80.24759858223754,
      },
      {
        lat: 13.014589086616123,
        lng: 80.24699118300282,
      },
      {
        lat: 13.016329878938217,
        lng: 80.2481945876766,
      },
      {
        lat: 13.021295341962608,
        lng: 80.24727713571382,
      },
      {
        lat: 13.016984013456742,
        lng: 80.24797264170961,
      },
      {
        lat: 13.023843720639734,
        lng: 80.23883249499676,
      },
      {
        lat: 13.009101799328134,
        lng: 80.24242242418059,
      },
      {
        lat: 12.998464890329933,
        lng: 80.2463621059192,
      },
      {
        lat: 13.000385841360988,
        lng: 80.24755431526613,
      },
      {
        lat: 13.020807575565765,
        lng: 80.23934963384278,
      },
      {
        lat: 13.024259563882701,
        lng: 80.24264860351126,
      },
      {
        lat: 13.002781320472229,
        lng: 80.24664918282569,
      },
      {
        lat: 13.021685798263931,
        lng: 80.24330621224476,
      },
      {
        lat: 12.999460481939618,
        lng: 80.24685345157185,
      },
      {
        lat: 13.011513123865235,
        lng: 80.24497028510996,
      },
      {
        lat: 13.012214845512727,
        lng: 80.24400414092601,
      },
      {
        lat: 13.016513648686072,
        lng: 80.24641289759835,
      },
      {
        lat: 13.007476920655614,
        lng: 80.24486034999971,
      },
      {
        lat: 13.017509735081783,
        lng: 80.2476508297293,
      },
      {
        lat: 13.018765758935741,
        lng: 80.24112379946735,
      },
      {
        lat: 13.02199276499789,
        lng: 80.24059642040034,
      },
      {
        lat: 13.017580586933047,
        lng: 80.24289814320906,
      },
      {
        lat: 13.021583362623506,
        lng: 80.24768309218656,
      },
      {
        lat: 13.023074754035681,
        lng: 80.23903534485889,
      },
      {
        lat: 13.014817200016607,
        lng: 80.24775442858923,
      },
      {
        lat: 13.018493597490837,
        lng: 80.24916216469241,
      },
      {
        lat: 13.012986368557929,
        lng: 80.24404514377262,
      },
      {
        lat: 13.004622339568764,
        lng: 80.24296185606481,
      },
      {
        lat: 13.01412660324247,
        lng: 80.24361901538703,
      },
      {
        lat: 13.021566399616086,
        lng: 80.24770682370854,
      },
      {
        lat: 13.02221809447397,
        lng: 80.24214780207353,
      },
    ],
    cases: 117,
    coords: [
      {
        lat: 12.99544584373754,
        lng: 80.24314784544157,
      },
      {
        lat: 12.997673382244304,
        lng: 80.2431478970918,
      },
      {
        lat: 12.99864639687965,
        lng: 80.24332224251735,
      },
      {
        lat: 12.999488231750801,
        lng: 80.24352378105122,
      },
      {
        lat: 13.000434026880976,
        lng: 80.24354185944193,
      },
      {
        lat: 13.001537428093267,
        lng: 80.24355860397148,
      },
      {
        lat: 13.004192208821019,
        lng: 80.24382304777491,
      },
      {
        lat: 13.004238715957364,
        lng: 80.24283150396894,
      },
      {
        lat: 13.00647575199929,
        lng: 80.24351680965563,
      },
      {
        lat: 13.006593606214853,
        lng: 80.24129876571611,
      },
      {
        lat: 13.006844039216102,
        lng: 80.24042803249186,
      },
      {
        lat: 13.008164793467518,
        lng: 80.24045327803458,
      },
      {
        lat: 13.014988653512468,
        lng: 80.24057764307932,
      },
      {
        lat: 13.016537465300821,
        lng: 80.2408353481957,
      },
      {
        lat: 13.017835188351826,
        lng: 80.24118170859397,
      },
      {
        lat: 13.018147224831246,
        lng: 80.2412028906394,
      },
      {
        lat: 13.018337274519826,
        lng: 80.2408915846165,
      },
      {
        lat: 13.018644384671282,
        lng: 80.24010283006056,
      },
      {
        lat: 13.018712157785199,
        lng: 80.23941139263978,
      },
      {
        lat: 13.018709404837223,
        lng: 80.23895878100966,
      },
      {
        lat: 13.018708390593233,
        lng: 80.23879202935639,
      },
      {
        lat: 13.018876477109147,
        lng: 80.23881442968762,
      },
      {
        lat: 13.018973602887398,
        lng: 80.23900419081498,
      },
      {
        lat: 13.019165825955968,
        lng: 80.23905020976345,
      },
      {
        lat: 13.019380736657126,
        lng: 80.23888163068123,
      },
      {
        lat: 13.019809688707475,
        lng: 80.23840154252878,
      },
      {
        lat: 13.019976760979631,
        lng: 80.23825719120669,
      },
      {
        lat: 13.0206261296271,
        lng: 80.2385137472326,
      },
      {
        lat: 13.020793491683087,
        lng: 80.23841703924018,
      },
      {
        lat: 13.020958680359026,
        lng: 80.23796300627632,
      },
      {
        lat: 13.021149599399774,
        lng: 80.2377946302418,
      },
      {
        lat: 13.021485047971668,
        lng: 80.23772032258059,
      },
      {
        lat: 13.022949988186602,
        lng: 80.23794615332177,
      },
      {
        lat: 13.02443993430612,
        lng: 80.23833853266845,
      },
      {
        lat: 13.02679977701061,
        lng: 80.2393151809825,
      },
      {
        lat: 13.025860301417037,
        lng: 80.24223582392443,
      },
      {
        lat: 13.02540534951762,
        lng: 80.24358871055621,
      },
      {
        lat: 13.024608138643028,
        lng: 80.2445152550058,
      },
      {
        lat: 13.022859704242748,
        lng: 80.24643096718388,
      },
      {
        lat: 13.021586378122551,
        lng: 80.24776931798856,
      },
      {
        lat: 13.0183580796489,
        lng: 80.25006547361876,
      },
      {
        lat: 13.015740390201191,
        lng: 80.24831509201341,
      },
      {
        lat: 13.015452267282676,
        lng: 80.24819071702564,
      },
      {
        lat: 13.014937362896843,
        lng: 80.24800108596023,
      },
      {
        lat: 13.008824743965754,
        lng: 80.24769379784463,
      },
      {
        lat: 13.00676382790142,
        lng: 80.24771123991177,
      },
      {
        lat: 13.006473266374593,
        lng: 80.24749828653245,
      },
      {
        lat: 13.006075109014123,
        lng: 80.24742985208238,
      },
      {
        lat: 13.004881947082081,
        lng: 80.24743995012123,
      },
      {
        lat: 13.000908889448155,
        lng: 80.24815571464855,
      },
      {
        lat: 12.997335299324018,
        lng: 80.24915531501445,
      },
      {
        lat: 12.995096667428317,
        lng: 80.24970295445685,
      },
      {
        lat: 12.994893381270652,
        lng: 80.24943768331227,
      },
      {
        lat: 12.994890244487332,
        lng: 80.24892196538963,
      },
      {
        lat: 12.994873562503303,
        lng: 80.24617928371028,
      },
      {
        lat: 12.995029842954219,
        lng: 80.24470105644946,
      },
      {
        lat: 12.995445394752785,
        lng: 80.2431503061168,
      },
      {
        lat: 12.99544584373754,
        lng: 80.24314784544157,
      },
    ],
    properties: {
      Ward_No: 172,
      Zone_Name: "ADYAR",
      fillColor: "#f6d04d",
      zname: "ADYAR(172)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.984454459128816,
        lng: 80.208479478279,
      },
      {
        lat: 12.982055915119734,
        lng: 80.21606144217533,
      },
      {
        lat: 12.979653416412331,
        lng: 80.21091680666945,
      },
      {
        lat: 12.990212597128458,
        lng: 80.20696965928876,
      },
      {
        lat: 12.98343239694318,
        lng: 80.2157837828019,
      },
      {
        lat: 12.989632415391537,
        lng: 80.21031641862413,
      },
      {
        lat: 12.983224759075148,
        lng: 80.21670669477783,
      },
      {
        lat: 12.981220869866869,
        lng: 80.20751566616593,
      },
      {
        lat: 12.979603813373389,
        lng: 80.21519221570422,
      },
      {
        lat: 12.995126649682591,
        lng: 80.21187757221253,
      },
      {
        lat: 12.990746229783893,
        lng: 80.20872903208068,
      },
      {
        lat: 12.993434465020972,
        lng: 80.21138202976115,
      },
      {
        lat: 12.982390225990317,
        lng: 80.2144981793492,
      },
      {
        lat: 12.991669868721178,
        lng: 80.21384518729673,
      },
      {
        lat: 12.987837998061922,
        lng: 80.21025646888663,
      },
      {
        lat: 12.979288540399683,
        lng: 80.21149757251348,
      },
      {
        lat: 12.990274911816751,
        lng: 80.21120942406876,
      },
      {
        lat: 12.98361150938029,
        lng: 80.20865248734034,
      },
      {
        lat: 12.994669322331859,
        lng: 80.207925841457,
      },
      {
        lat: 12.993178295881641,
        lng: 80.20680843820193,
      },
      {
        lat: 12.983968993684318,
        lng: 80.2091404745069,
      },
      {
        lat: 12.986158445801797,
        lng: 80.20845717891615,
      },
      {
        lat: 12.980442803294585,
        lng: 80.21004090308328,
      },
      {
        lat: 12.98367050089173,
        lng: 80.21337699343334,
      },
      {
        lat: 12.988201548023651,
        lng: 80.20437683967562,
      },
      {
        lat: 12.986066024591636,
        lng: 80.21161162020323,
      },
      {
        lat: 12.981608321825854,
        lng: 80.21800232921268,
      },
      {
        lat: 12.982641485557398,
        lng: 80.20545217273153,
      },
      {
        lat: 12.99326628209109,
        lng: 80.2116659830267,
      },
      {
        lat: 12.989348833427332,
        lng: 80.21289146739707,
      },
      {
        lat: 12.98193755532699,
        lng: 80.20494141252951,
      },
      {
        lat: 12.988207383989101,
        lng: 80.2041145141752,
      },
      {
        lat: 12.979715859864564,
        lng: 80.21155985841366,
      },
      {
        lat: 12.986830251292982,
        lng: 80.20715338689966,
      },
      {
        lat: 12.97938634003808,
        lng: 80.20860659630915,
      },
      {
        lat: 12.9950079162374,
        lng: 80.21024802130724,
      },
      {
        lat: 12.98195115065798,
        lng: 80.2074627227106,
      },
      {
        lat: 12.986341363929883,
        lng: 80.2093401122937,
      },
      {
        lat: 12.988951477590776,
        lng: 80.20812761286497,
      },
      {
        lat: 12.983330727291326,
        lng: 80.2171044256995,
      },
      {
        lat: 12.99465032728615,
        lng: 80.21075544786697,
      },
      {
        lat: 12.994234789242338,
        lng: 80.20831699513747,
      },
      {
        lat: 12.98487045356316,
        lng: 80.20553518638397,
      },
      {
        lat: 12.99177682669489,
        lng: 80.21294398530775,
      },
      {
        lat: 12.993202490613422,
        lng: 80.20926347481162,
      },
      {
        lat: 12.988309406665145,
        lng: 80.21166160247674,
      },
      {
        lat: 12.980554519534262,
        lng: 80.21282198220412,
      },
      {
        lat: 12.981709432655368,
        lng: 80.20659557965455,
      },
      {
        lat: 12.979129013810397,
        lng: 80.21363106482445,
      },
      {
        lat: 12.985016115988858,
        lng: 80.20503182547277,
      },
      {
        lat: 12.981423530265559,
        lng: 80.21406052983701,
      },
      {
        lat: 12.995393976000377,
        lng: 80.20891725988825,
      },
      {
        lat: 12.994567436847484,
        lng: 80.21344442507076,
      },
      {
        lat: 12.994249207299026,
        lng: 80.20921907645801,
      },
      {
        lat: 12.98343988885955,
        lng: 80.21403202219312,
      },
      {
        lat: 12.989503288730052,
        lng: 80.21070383336718,
      },
      {
        lat: 12.986713454007894,
        lng: 80.20661668712238,
      },
      {
        lat: 12.980899727703315,
        lng: 80.20845806782339,
      },
      {
        lat: 12.982151375713412,
        lng: 80.21329389518843,
      },
      {
        lat: 12.979565759144334,
        lng: 80.21222698392533,
      },
      {
        lat: 12.982264733858061,
        lng: 80.21555816617747,
      },
      {
        lat: 12.995270417396371,
        lng: 80.20735643117987,
      },
      {
        lat: 12.990774068748808,
        lng: 80.20671516104892,
      },
      {
        lat: 12.98357726407939,
        lng: 80.21454996432732,
      },
      {
        lat: 12.984655899102096,
        lng: 80.20933851851966,
      },
      {
        lat: 12.988607710798874,
        lng: 80.21253618964646,
      },
      {
        lat: 12.979468038920995,
        lng: 80.21189528736043,
      },
      {
        lat: 12.9843555437355,
        lng: 80.21770564613496,
      },
      {
        lat: 12.99497544268518,
        lng: 80.21113244426594,
      },
      {
        lat: 12.988508475896456,
        lng: 80.21529596257156,
      },
      {
        lat: 12.992412118663566,
        lng: 80.21147495568222,
      },
      {
        lat: 12.994937760262676,
        lng: 80.20747565126064,
      },
      {
        lat: 12.978932204924106,
        lng: 80.21223494961886,
      },
      {
        lat: 12.981152223504473,
        lng: 80.21325450101392,
      },
      {
        lat: 12.991863466746214,
        lng: 80.2105876116125,
      },
      {
        lat: 12.982568678396616,
        lng: 80.21090987898755,
      },
      {
        lat: 12.991170898421375,
        lng: 80.20981797473391,
      },
      {
        lat: 12.98703911942938,
        lng: 80.21351228478716,
      },
      {
        lat: 12.978714332641573,
        lng: 80.20797252359664,
      },
      {
        lat: 12.990666276437214,
        lng: 80.20642539847901,
      },
      {
        lat: 12.996282764617812,
        lng: 80.2080731580315,
      },
      {
        lat: 12.98143768682655,
        lng: 80.20953926324486,
      },
      {
        lat: 12.98138830158177,
        lng: 80.21797315414918,
      },
      {
        lat: 12.990590234929195,
        lng: 80.2120466440087,
      },
      {
        lat: 12.9837108657615,
        lng: 80.20448833511534,
      },
      {
        lat: 12.983250968284459,
        lng: 80.21447591678289,
      },
      {
        lat: 12.980609791579871,
        lng: 80.20709781041937,
      },
      {
        lat: 12.99110127928392,
        lng: 80.21390049339686,
      },
      {
        lat: 12.980584690058327,
        lng: 80.20862433345692,
      },
      {
        lat: 12.994381397832692,
        lng: 80.21315342765172,
      },
      {
        lat: 12.98533888906379,
        lng: 80.21148436429124,
      },
      {
        lat: 12.988331202152704,
        lng: 80.21199318002172,
      },
      {
        lat: 12.981669497650046,
        lng: 80.21573801062264,
      },
      {
        lat: 12.981043443034086,
        lng: 80.21574181651772,
      },
      {
        lat: 12.983328027354373,
        lng: 80.21669867981872,
      },
      {
        lat: 12.986424653272726,
        lng: 80.21731755591702,
      },
      {
        lat: 12.991794967768081,
        lng: 80.21375821288498,
      },
      {
        lat: 12.989031520698909,
        lng: 80.20697457601023,
      },
      {
        lat: 12.991199694008852,
        lng: 80.20981769984412,
      },
      {
        lat: 12.980041882958274,
        lng: 80.21733767271573,
      },
      {
        lat: 12.98970517941377,
        lng: 80.20729823617783,
      },
      {
        lat: 12.98293317230127,
        lng: 80.21029129223568,
      },
      {
        lat: 12.992943082901846,
        lng: 80.20952468368144,
      },
      {
        lat: 12.993186944168952,
        lng: 80.21182587567631,
      },
      {
        lat: 12.990634561776476,
        lng: 80.21189958642661,
      },
      {
        lat: 12.985589029803664,
        lng: 80.20714623490832,
      },
      {
        lat: 12.995019798813926,
        lng: 80.21107925920639,
      },
      {
        lat: 12.993507944980859,
        lng: 80.2084566400892,
      },
      {
        lat: 12.983252455224521,
        lng: 80.2137750555985,
      },
      {
        lat: 12.980624902257413,
        lng: 80.21432594545323,
      },
      {
        lat: 12.986958771387417,
        lng: 80.2129376687905,
      },
      {
        lat: 12.987817506628197,
        lng: 80.20509436326249,
      },
      {
        lat: 12.979651059108885,
        lng: 80.21385210668316,
      },
      {
        lat: 12.98352493297411,
        lng: 80.20632639214675,
      },
      {
        lat: 12.985256693684411,
        lng: 80.21651979677705,
      },
      {
        lat: 12.986259475770336,
        lng: 80.21338778633975,
      },
      {
        lat: 12.979786155662964,
        lng: 80.21046577823948,
      },
      {
        lat: 12.992156343646954,
        lng: 80.20849986996562,
      },
      {
        lat: 12.988121741203374,
        lng: 80.21447761856238,
      },
      {
        lat: 12.988750256745323,
        lng: 80.2105844919723,
      },
      {
        lat: 12.99239561293433,
        lng: 80.20906347693047,
      },
      {
        lat: 12.995896557584983,
        lng: 80.20839151144571,
      },
      {
        lat: 12.984459651336985,
        lng: 80.21114546835145,
      },
      {
        lat: 12.98567053674591,
        lng: 80.21744549573827,
      },
      {
        lat: 12.984681435712986,
        lng: 80.2148817244287,
      },
      {
        lat: 12.981733040184459,
        lng: 80.21437663216406,
      },
      {
        lat: 12.990941165182443,
        lng: 80.20938727882273,
      },
      {
        lat: 12.990015243189005,
        lng: 80.21073823814612,
      },
      {
        lat: 12.994987255053172,
        lng: 80.21458209472033,
      },
      {
        lat: 12.987644511947993,
        lng: 80.21743787530322,
      },
      {
        lat: 12.983706679344234,
        lng: 80.21616953532423,
      },
      {
        lat: 12.983499151697645,
        lng: 80.21507646079519,
      },
      {
        lat: 12.978782336449875,
        lng: 80.21160455353582,
      },
      {
        lat: 12.985353548499665,
        lng: 80.21184454828791,
      },
      {
        lat: 12.991303802035802,
        lng: 80.20647373249423,
      },
      {
        lat: 12.995717117586361,
        lng: 80.20725965164416,
      },
      {
        lat: 12.984904001604033,
        lng: 80.21481610976176,
      },
      {
        lat: 12.978121110360407,
        lng: 80.20762809114208,
      },
      {
        lat: 12.984352458414362,
        lng: 80.21789349058989,
      },
      {
        lat: 12.984788560367305,
        lng: 80.20877655907647,
      },
      {
        lat: 12.980514020085296,
        lng: 80.21011954768812,
      },
      {
        lat: 12.982492173356954,
        lng: 80.20762844840672,
      },
      {
        lat: 12.987706665182577,
        lng: 80.20441681507116,
      },
      {
        lat: 12.987042948104738,
        lng: 80.20528176664929,
      },
      {
        lat: 12.979075096634473,
        lng: 80.20746645885208,
      },
      {
        lat: 12.979958794243466,
        lng: 80.20750284174771,
      },
      {
        lat: 12.981014917563728,
        lng: 80.21682102792505,
      },
      {
        lat: 12.996570661472578,
        lng: 80.20768131970341,
      },
      {
        lat: 12.981624579702476,
        lng: 80.21624650838811,
      },
      {
        lat: 12.99014511285127,
        lng: 80.2097710610741,
      },
      {
        lat: 12.98123248576726,
        lng: 80.21688902510304,
      },
      {
        lat: 12.99452043135429,
        lng: 80.21102352989804,
      },
      {
        lat: 12.983444617052381,
        lng: 80.208614133187,
      },
      {
        lat: 12.99012546562437,
        lng: 80.21185851193185,
      },
      {
        lat: 12.98391874655985,
        lng: 80.20576361597497,
      },
      {
        lat: 12.985700757146258,
        lng: 80.2098174165041,
      },
      {
        lat: 12.987647530914671,
        lng: 80.20557108560534,
      },
      {
        lat: 12.986918084287376,
        lng: 80.20584454754858,
      },
      {
        lat: 12.991739372321767,
        lng: 80.21566618799282,
      },
      {
        lat: 12.977895217098238,
        lng: 80.2089957221418,
      },
      {
        lat: 12.990903063095056,
        lng: 80.21501079139327,
      },
      {
        lat: 12.994207300200742,
        lng: 80.20999210388146,
      },
      {
        lat: 12.985242042442737,
        lng: 80.21291971309483,
      },
      {
        lat: 12.991658053346029,
        lng: 80.21187009355546,
      },
      {
        lat: 12.986664748073451,
        lng: 80.21032108643607,
      },
      {
        lat: 12.979810034578096,
        lng: 80.21635818183937,
      },
    ],
    cases: 166,
    coords: [
      {
        lat: 12.9874702861271,
        lng: 80.2184335355386,
      },
      {
        lat: 12.986856083114873,
        lng: 80.21835763332372,
      },
      {
        lat: 12.985678370454762,
        lng: 80.21805139771246,
      },
      {
        lat: 12.98244435759167,
        lng: 80.21798147480222,
      },
      {
        lat: 12.981416577329123,
        lng: 80.21816043627256,
      },
      {
        lat: 12.979681382954642,
        lng: 80.21883185077036,
      },
      {
        lat: 12.97938743558001,
        lng: 80.21883433852321,
      },
      {
        lat: 12.979312617316744,
        lng: 80.2186160620303,
      },
      {
        lat: 12.979309066864486,
        lng: 80.21803233288068,
      },
      {
        lat: 12.979229218793858,
        lng: 80.21698710675922,
      },
      {
        lat: 12.979003580519972,
        lng: 80.21613770089748,
      },
      {
        lat: 12.977967454188478,
        lng: 80.21091696042106,
      },
      {
        lat: 12.977719879824065,
        lng: 80.20925005593222,
      },
      {
        lat: 12.977841936866628,
        lng: 80.20896218457592,
      },
      {
        lat: 12.977961501673487,
        lng: 80.2082645652325,
      },
      {
        lat: 12.978081315703749,
        lng: 80.2076079206879,
      },
      {
        lat: 12.978203372746028,
        lng: 80.20732004933163,
      },
      {
        lat: 12.978615297100358,
        lng: 80.20719363238508,
      },
      {
        lat: 12.97962742176693,
        lng: 80.20740462127456,
      },
      {
        lat: 12.979937592145307,
        lng: 80.20746927981493,
      },
      {
        lat: 12.980267729765465,
        lng: 80.20746648577465,
      },
      {
        lat: 12.980306753955585,
        lng: 80.2070973633312,
      },
      {
        lat: 12.980387543795675,
        lng: 80.2068098412301,
      },
      {
        lat: 12.981130353440552,
        lng: 80.20680355463944,
      },
      {
        lat: 12.981335941782612,
        lng: 80.2066788839681,
      },
      {
        lat: 12.981416731622415,
        lng: 80.20639136186684,
      },
      {
        lat: 12.981574572948654,
        lng: 80.20520169568377,
      },
      {
        lat: 12.981861449577442,
        lng: 80.20487145250863,
      },
      {
        lat: 12.982314391910728,
        lng: 80.20470371150836,
      },
      {
        lat: 12.983181501610424,
        lng: 80.20477832675,
      },
      {
        lat: 12.983551411091547,
        lng: 80.20452933466238,
      },
      {
        lat: 12.983713239995016,
        lng: 80.20399526525895,
      },
      {
        lat: 12.983793032940685,
        lng: 80.20354384396285,
      },
      {
        lat: 12.983957354079921,
        lng: 80.2034195225465,
      },
      {
        lat: 12.984411044083712,
        lng: 80.2033747059424,
      },
      {
        lat: 12.985134217021832,
        lng: 80.20353249317415,
      },
      {
        lat: 12.985589651590713,
        lng: 80.20377450016099,
      },
      {
        lat: 12.986703616834772,
        lng: 80.20372409547632,
      },
      {
        lat: 12.987693531247595,
        lng: 80.20363376375798,
      },
      {
        lat: 12.988106203272656,
        lng: 80.2036302712076,
      },
      {
        lat: 12.988313536179463,
        lng: 80.20379242412729,
      },
      {
        lat: 12.988234241681118,
        lng: 80.20432579502088,
      },
      {
        lat: 12.988277502672036,
        lng: 80.20465324415558,
      },
      {
        lat: 12.988444066823467,
        lng: 80.20489769592788,
      },
      {
        lat: 12.989105338957815,
        lng: 80.20505600704212,
      },
      {
        lat: 12.994272960542688,
        lng: 80.20652841771528,
      },
      {
        lat: 12.996764799418562,
        lng: 80.20708004250041,
      },
      {
        lat: 12.996704026266004,
        lng: 80.20839947229497,
      },
      {
        lat: 12.996469923588268,
        lng: 80.2100372125366,
      },
      {
        lat: 12.996410630267038,
        lng: 80.2103204381241,
      },
      {
        lat: 12.996329771082708,
        lng: 80.21040190067706,
      },
      {
        lat: 12.996186916443076,
        lng: 80.21032233147216,
      },
      {
        lat: 12.995942742174266,
        lng: 80.21030420342714,
      },
      {
        lat: 12.995862619935687,
        lng: 80.21050682707427,
      },
      {
        lat: 12.995405049082388,
        lng: 80.21218684769542,
      },
      {
        lat: 12.995406522973761,
        lng: 80.21242916988359,
      },
      {
        lat: 12.995488487576793,
        lng: 80.21252944897181,
      },
      {
        lat: 12.995733767263959,
        lng: 80.21272931865826,
      },
      {
        lat: 12.995736100925305,
        lng: 80.2131129954563,
      },
      {
        lat: 12.995537392044307,
        lng: 80.21388207027807,
      },
      {
        lat: 12.995419051050458,
        lng: 80.21448890848404,
      },
      {
        lat: 12.995175982200243,
        lng: 80.21465252208034,
      },
      {
        lat: 12.994992575144103,
        lng: 80.21459349063616,
      },
      {
        lat: 12.99491061054125,
        lng: 80.21449321154793,
      },
      {
        lat: 12.994727326309397,
        lng: 80.21445437361967,
      },
      {
        lat: 12.994566713359108,
        lng: 80.21479904036696,
      },
      {
        lat: 12.99400254143293,
        lng: 80.21567218097306,
      },
      {
        lat: 12.992901239325741,
        lng: 80.21517663769885,
      },
      {
        lat: 12.99267764832595,
        lng: 80.21519872456265,
      },
      {
        lat: 12.992496697755437,
        lng: 80.21554356343248,
      },
      {
        lat: 12.99209387572502,
        lng: 80.21619319838571,
      },
      {
        lat: 12.991870284725222,
        lng: 80.21621528524935,
      },
      {
        lat: 12.990747908052121,
        lng: 80.21559875300353,
      },
      {
        lat: 12.989180068919374,
        lng: 80.21530910370491,
      },
      {
        lat: 12.98879463672665,
        lng: 80.2154739221591,
      },
      {
        lat: 12.988371108602989,
        lng: 80.21606314868772,
      },
      {
        lat: 12.987728779626513,
        lng: 80.21746200919229,
      },
      {
        lat: 12.9874702861271,
        lng: 80.2184335355386,
      },
    ],
    properties: {
      Ward_No: 177,
      Zone_Name: "ADYAR",
      fillColor: "#de4307",
      zname: "ADYAR(177)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.985786214434565,
        lng: 80.21874583241909,
      },
      {
        lat: 12.982791507857751,
        lng: 80.21861181266073,
      },
      {
        lat: 12.977579244737738,
        lng: 80.21364367040414,
      },
      {
        lat: 12.977776743208105,
        lng: 80.21258861322328,
      },
      {
        lat: 12.987093788059,
        lng: 80.21845770455194,
      },
      {
        lat: 12.983734668476847,
        lng: 80.2190076793264,
      },
      {
        lat: 12.984896371141462,
        lng: 80.22292292393901,
      },
      {
        lat: 12.975361958844944,
        lng: 80.2171448659369,
      },
      {
        lat: 12.971883262898093,
        lng: 80.21695038494798,
      },
      {
        lat: 12.982973537046979,
        lng: 80.2200273818214,
      },
      {
        lat: 12.97003291507707,
        lng: 80.21639578898608,
      },
      {
        lat: 12.977287570673862,
        lng: 80.21206985826049,
      },
      {
        lat: 12.97070782237257,
        lng: 80.21378643668682,
      },
      {
        lat: 12.97726376386047,
        lng: 80.21227018587156,
      },
      {
        lat: 12.984708297357475,
        lng: 80.22197767555919,
      },
      {
        lat: 12.980928360308774,
        lng: 80.21836511332064,
      },
      {
        lat: 12.974296392324959,
        lng: 80.21585133380587,
      },
      {
        lat: 12.971493339791456,
        lng: 80.2129576932128,
      },
      {
        lat: 12.98353710819597,
        lng: 80.22131199226239,
      },
      {
        lat: 12.985414087192954,
        lng: 80.22274204902776,
      },
      {
        lat: 12.968385365577996,
        lng: 80.21681886725862,
      },
      {
        lat: 12.976847239186128,
        lng: 80.21382630307919,
      },
      {
        lat: 12.968963141401343,
        lng: 80.21555504948601,
      },
      {
        lat: 12.975613095674039,
        lng: 80.2172901598719,
      },
      {
        lat: 12.971877667697472,
        lng: 80.21870204055097,
      },
      {
        lat: 12.979253124897587,
        lng: 80.22084989108453,
      },
      {
        lat: 12.98145894757578,
        lng: 80.21861516150288,
      },
      {
        lat: 12.985347756489938,
        lng: 80.21974625455829,
      },
      {
        lat: 12.973266582063157,
        lng: 80.21822255800116,
      },
      {
        lat: 12.97400181145761,
        lng: 80.21143461408704,
      },
      {
        lat: 12.982807128872894,
        lng: 80.21856054049296,
      },
      {
        lat: 12.974745752472474,
        lng: 80.21354048943843,
      },
      {
        lat: 12.98420325424322,
        lng: 80.22219676054449,
      },
      {
        lat: 12.98271153347885,
        lng: 80.22181263500373,
      },
      {
        lat: 12.97690997373951,
        lng: 80.21202096812706,
      },
      {
        lat: 12.977943052110207,
        lng: 80.21144133341086,
      },
      {
        lat: 12.98453711036863,
        lng: 80.22068786768973,
      },
      {
        lat: 12.981680517127076,
        lng: 80.2201756329385,
      },
      {
        lat: 12.985700212731862,
        lng: 80.22189530977552,
      },
      {
        lat: 12.984532653196009,
        lng: 80.22056915225065,
      },
      {
        lat: 12.978240622025808,
        lng: 80.21428408742834,
      },
      {
        lat: 12.986575959941469,
        lng: 80.22173855069579,
      },
      {
        lat: 12.980140672433837,
        lng: 80.22063970843834,
      },
      {
        lat: 12.97694043081683,
        lng: 80.21210784939998,
      },
      {
        lat: 12.971624036885485,
        lng: 80.21495448422999,
      },
      {
        lat: 12.984785811032358,
        lng: 80.2182428838132,
      },
      {
        lat: 12.968065191594764,
        lng: 80.21719397284286,
      },
      {
        lat: 12.971802281250305,
        lng: 80.21397475451843,
      },
      {
        lat: 12.972138793988492,
        lng: 80.21371944473721,
      },
      {
        lat: 12.986965368478195,
        lng: 80.21985012849323,
      },
      {
        lat: 12.978492612776305,
        lng: 80.21740856731941,
      },
      {
        lat: 12.98714981549363,
        lng: 80.21997315068202,
      },
      {
        lat: 12.972628274224451,
        lng: 80.2169128978156,
      },
      {
        lat: 12.980633657970959,
        lng: 80.21847728604992,
      },
      {
        lat: 12.974453304479248,
        lng: 80.2132339157736,
      },
      {
        lat: 12.986096436692424,
        lng: 80.22045279219175,
      },
      {
        lat: 12.969300826899289,
        lng: 80.21615970029944,
      },
      {
        lat: 12.9712680443282,
        lng: 80.216591786782,
      },
      {
        lat: 12.988290115587237,
        lng: 80.2188232912715,
      },
      {
        lat: 12.972293061306083,
        lng: 80.21168787431849,
      },
      {
        lat: 12.986406428875371,
        lng: 80.21856671380432,
      },
      {
        lat: 12.976949414778682,
        lng: 80.21498067041979,
      },
      {
        lat: 12.972488920234879,
        lng: 80.2192570546916,
      },
      {
        lat: 12.984569837657387,
        lng: 80.22210444692324,
      },
      {
        lat: 12.980795517260638,
        lng: 80.21886399006254,
      },
      {
        lat: 12.974218077413383,
        lng: 80.21547217566454,
      },
      {
        lat: 12.974780610336326,
        lng: 80.21463898738233,
      },
      {
        lat: 12.976862765494921,
        lng: 80.20990886995234,
      },
      {
        lat: 12.975390738454886,
        lng: 80.21617528781816,
      },
      {
        lat: 12.971052342703544,
        lng: 80.21883069063333,
      },
      {
        lat: 12.97609520525059,
        lng: 80.2167028945396,
      },
      {
        lat: 12.987504034605676,
        lng: 80.21927074227149,
      },
      {
        lat: 12.974337793315218,
        lng: 80.21934679660639,
      },
      {
        lat: 12.982037237459913,
        lng: 80.22087715948797,
      },
      {
        lat: 12.976141991823203,
        lng: 80.21037454454157,
      },
      {
        lat: 12.981141341296212,
        lng: 80.21954049568363,
      },
      {
        lat: 12.98709555409357,
        lng: 80.22269054048209,
      },
      {
        lat: 12.973011869416418,
        lng: 80.21419154529006,
      },
      {
        lat: 12.986398518024252,
        lng: 80.22089156641259,
      },
      {
        lat: 12.969603227672465,
        lng: 80.21727321315961,
      },
      {
        lat: 12.984848339768194,
        lng: 80.22282912312613,
      },
      {
        lat: 12.97051423287452,
        lng: 80.21822990161782,
      },
      {
        lat: 12.977294521702627,
        lng: 80.21329922629567,
      },
      {
        lat: 12.972982104110262,
        lng: 80.213848752529,
      },
      {
        lat: 12.97004391337769,
        lng: 80.21479935638823,
      },
      {
        lat: 12.976773136774833,
        lng: 80.214778627518,
      },
      {
        lat: 12.969963259410981,
        lng: 80.21419024392746,
      },
      {
        lat: 12.974389525093747,
        lng: 80.2135275999268,
      },
      {
        lat: 12.977656805454222,
        lng: 80.21373234448683,
      },
      {
        lat: 12.972379082205814,
        lng: 80.21596252953893,
      },
      {
        lat: 12.97235385303912,
        lng: 80.21369456765177,
      },
      {
        lat: 12.970393900265611,
        lng: 80.21597144512148,
      },
      {
        lat: 12.982514672776277,
        lng: 80.2217799601707,
      },
      {
        lat: 12.984131862506953,
        lng: 80.22188202024482,
      },
      {
        lat: 12.977608392057828,
        lng: 80.21771900748904,
      },
      {
        lat: 12.976259515821274,
        lng: 80.21633406453674,
      },
      {
        lat: 12.971785801845053,
        lng: 80.21608065733639,
      },
      {
        lat: 12.976066438938126,
        lng: 80.21154323907346,
      },
      {
        lat: 12.983789073782763,
        lng: 80.21924938006242,
      },
      {
        lat: 12.982883465279041,
        lng: 80.21998347038256,
      },
      {
        lat: 12.974518597599749,
        lng: 80.21650708601624,
      },
      {
        lat: 12.974178313393548,
        lng: 80.21216406112083,
      },
      {
        lat: 12.972451590774169,
        lng: 80.21562150511818,
      },
      {
        lat: 12.975367285186543,
        lng: 80.21793947303031,
      },
      {
        lat: 12.981495541104561,
        lng: 80.22057669798522,
      },
      {
        lat: 12.980191395416515,
        lng: 80.22007747243893,
      },
      {
        lat: 12.974168114649935,
        lng: 80.21893516854202,
      },
      {
        lat: 12.98719535451964,
        lng: 80.21936596207327,
      },
      {
        lat: 12.974675038494572,
        lng: 80.21416793247876,
      },
      {
        lat: 12.975415908699247,
        lng: 80.21569291493651,
      },
      {
        lat: 12.975661019915123,
        lng: 80.217150915475,
      },
      {
        lat: 12.96848866685685,
        lng: 80.21687550700138,
      },
      {
        lat: 12.978118964915353,
        lng: 80.21947402903872,
      },
      {
        lat: 12.975618299650213,
        lng: 80.21948514970154,
      },
      {
        lat: 12.987858674655923,
        lng: 80.21926145389025,
      },
      {
        lat: 12.976336197532245,
        lng: 80.2133779245422,
      },
      {
        lat: 12.985839915057507,
        lng: 80.22151015721224,
      },
      {
        lat: 12.98614288777266,
        lng: 80.22283147001878,
      },
    ],
    cases: 118,
    coords: [
      {
        lat: 12.98719715475215,
        lng: 80.22298890567376,
      },
      {
        lat: 12.986076131452574,
        lng: 80.22303430784099,
      },
      {
        lat: 12.985611750324594,
        lng: 80.22320850111834,
      },
      {
        lat: 12.985244611977485,
        lng: 80.22326025490521,
      },
      {
        lat: 12.98499950789644,
        lng: 80.22323800598468,
      },
      {
        lat: 12.984679437616615,
        lng: 80.2229731585234,
      },
      {
        lat: 12.984163698291372,
        lng: 80.22275861365975,
      },
      {
        lat: 12.98308130525027,
        lng: 80.22201375193526,
      },
      {
        lat: 12.982443383723279,
        lng: 80.22184888773128,
      },
      {
        lat: 12.98128779970958,
        lng: 80.22115329204084,
      },
      {
        lat: 12.979254367830663,
        lng: 80.22112185490231,
      },
      {
        lat: 12.978912168904431,
        lng: 80.22124636752015,
      },
      {
        lat: 12.975997190772755,
        lng: 80.22127103773605,
      },
      {
        lat: 12.975454884735788,
        lng: 80.22071619151457,
      },
      {
        lat: 12.973785040751954,
        lng: 80.22004927143946,
      },
      {
        lat: 12.971550614149237,
        lng: 80.21919254317437,
      },
      {
        lat: 12.967738154324746,
        lng: 80.21762851364103,
      },
      {
        lat: 12.968393595970388,
        lng: 80.21613115341376,
      },
      {
        lat: 12.969335763367676,
        lng: 80.2149758261835,
      },
      {
        lat: 12.971629281057247,
        lng: 80.21208803199085,
      },
      {
        lat: 12.972862312660778,
        lng: 80.21125805836522,
      },
      {
        lat: 12.97393501070236,
        lng: 80.21120800293559,
      },
      {
        lat: 12.97455327106919,
        lng: 80.21107983971385,
      },
      {
        lat: 12.974841393815746,
        lng: 80.21095447053253,
      },
      {
        lat: 12.97492243287941,
        lng: 80.21070792322995,
      },
      {
        lat: 12.974918694525758,
        lng: 80.21009330124919,
      },
      {
        lat: 12.975122538302495,
        lng: 80.20968180698667,
      },
      {
        lat: 12.975327129749902,
        lng: 80.20939323712052,
      },
      {
        lat: 12.975656768922686,
        lng: 80.20930849348277,
      },
      {
        lat: 12.975947383905176,
        lng: 80.20959287228852,
      },
      {
        lat: 12.976197230132632,
        lng: 80.20995954994672,
      },
      {
        lat: 12.976486349773666,
        lng: 80.20999807996022,
      },
      {
        lat: 12.97673146075265,
        lng: 80.20958623644287,
      },
      {
        lat: 12.976977817850086,
        lng: 80.20937926691909,
      },
      {
        lat: 12.977389742204132,
        lng: 80.20925284997253,
      },
      {
        lat: 12.977719879824065,
        lng: 80.20925005593222,
      },
      {
        lat: 12.977967454188478,
        lng: 80.21091696042106,
      },
      {
        lat: 12.979003580519972,
        lng: 80.21613770089748,
      },
      {
        lat: 12.979229218793858,
        lng: 80.21698710675922,
      },
      {
        lat: 12.979309066864486,
        lng: 80.21803233288068,
      },
      {
        lat: 12.979312617316744,
        lng: 80.2186160620303,
      },
      {
        lat: 12.97938743558001,
        lng: 80.21883433852321,
      },
      {
        lat: 12.979681382954642,
        lng: 80.21883185077036,
      },
      {
        lat: 12.981416577329123,
        lng: 80.21816043627256,
      },
      {
        lat: 12.98244435759167,
        lng: 80.21798147480222,
      },
      {
        lat: 12.985678370454762,
        lng: 80.21805139771246,
      },
      {
        lat: 12.986856083114873,
        lng: 80.21835763332372,
      },
      {
        lat: 12.9874702861271,
        lng: 80.2184335355386,
      },
      {
        lat: 12.98818308543448,
        lng: 80.21858905937474,
      },
      {
        lat: 12.988488641037128,
        lng: 80.21866725159911,
      },
      {
        lat: 12.988550759316722,
        lng: 80.21884847687262,
      },
      {
        lat: 12.988451650524787,
        lng: 80.21927340131495,
      },
      {
        lat: 12.988272542318501,
        lng: 80.21992114292009,
      },
      {
        lat: 12.988172205283751,
        lng: 80.22014413220552,
      },
      {
        lat: 12.988234323563285,
        lng: 80.22032535747901,
      },
      {
        lat: 12.988621352471672,
        lng: 80.22042305472891,
      },
      {
        lat: 12.98876445276004,
        lng: 80.22054301096524,
      },
      {
        lat: 12.988786018623243,
        lng: 80.22074477399957,
      },
      {
        lat: 12.98846270471011,
        lng: 80.22109081772706,
      },
      {
        lat: 12.988261907816279,
        lng: 80.22151660278236,
      },
      {
        lat: 12.98794006779452,
        lng: 80.22210496869822,
      },
      {
        lat: 12.987716968091796,
        lng: 80.2222078296248,
      },
      {
        lat: 12.9875339295085,
        lng: 80.22220937872774,
      },
      {
        lat: 12.987331658723347,
        lng: 80.22239284159448,
      },
      {
        lat: 12.987171537070353,
        lng: 80.22281828240452,
      },
      {
        lat: 12.98719715475215,
        lng: 80.22298890567376,
      },
    ],
    properties: {
      Ward_No: 178,
      Zone_Name: "ADYAR",
      fillColor: "#f6d04d",
      zname: "ADYAR(178)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.005257615197436,
        lng: 80.21008434459335,
      },
      {
        lat: 13.003756120838359,
        lng: 80.20888227035915,
      },
      {
        lat: 13.005458928200655,
        lng: 80.20556546432923,
      },
      {
        lat: 13.004105782624368,
        lng: 80.20457285873904,
      },
      {
        lat: 13.005073866104606,
        lng: 80.20859111588622,
      },
      {
        lat: 13.001213267031346,
        lng: 80.20241839919908,
      },
      {
        lat: 13.001543956285305,
        lng: 80.2042598705011,
      },
      {
        lat: 13.00043295489318,
        lng: 80.2077557244424,
      },
      {
        lat: 13.007638742252123,
        lng: 80.2075873435202,
      },
      {
        lat: 13.006584455254544,
        lng: 80.2046339930521,
      },
      {
        lat: 13.003667840681647,
        lng: 80.20720507069633,
      },
      {
        lat: 12.999416275747903,
        lng: 80.206898490182,
      },
      {
        lat: 13.001153326917136,
        lng: 80.20686033535732,
      },
      {
        lat: 13.001137977931723,
        lng: 80.203230980976,
      },
      {
        lat: 12.999392833494285,
        lng: 80.20755419157992,
      },
      {
        lat: 13.004096193500596,
        lng: 80.20305585880571,
      },
      {
        lat: 13.00653153189488,
        lng: 80.21119780943806,
      },
      {
        lat: 13.005485825147083,
        lng: 80.2030737061897,
      },
      {
        lat: 13.003112872961479,
        lng: 80.20579129116882,
      },
      {
        lat: 13.007940060682722,
        lng: 80.21098143475109,
      },
      {
        lat: 13.004235293085076,
        lng: 80.20687552358108,
      },
      {
        lat: 13.006846588417897,
        lng: 80.2056740109806,
      },
      {
        lat: 13.003702750515838,
        lng: 80.20737826770538,
      },
      {
        lat: 13.004253656006513,
        lng: 80.20573959254372,
      },
      {
        lat: 13.005727044868372,
        lng: 80.20611493933927,
      },
      {
        lat: 13.001455438532357,
        lng: 80.20224953478814,
      },
      {
        lat: 13.001373865164984,
        lng: 80.20752856897789,
      },
      {
        lat: 13.0030015028802,
        lng: 80.20394109225987,
      },
      {
        lat: 13.00503967706468,
        lng: 80.20867003244561,
      },
      {
        lat: 13.005603153050554,
        lng: 80.2052370316836,
      },
      {
        lat: 13.002565987408948,
        lng: 80.20245224517046,
      },
      {
        lat: 13.003019154356693,
        lng: 80.20861448528346,
      },
      {
        lat: 13.00094413270992,
        lng: 80.20378329547997,
      },
      {
        lat: 13.003462711206518,
        lng: 80.2047749820593,
      },
      {
        lat: 13.005597504729527,
        lng: 80.20943066383352,
      },
      {
        lat: 12.999092927591223,
        lng: 80.20201542845143,
      },
      {
        lat: 13.002043227377403,
        lng: 80.20267480790817,
      },
      {
        lat: 13.008015490435213,
        lng: 80.20940902228125,
      },
      {
        lat: 13.002788579081342,
        lng: 80.20279555278212,
      },
      {
        lat: 13.004517116014712,
        lng: 80.20292464166744,
      },
      {
        lat: 13.00520016120842,
        lng: 80.20626511744909,
      },
      {
        lat: 13.003601477765402,
        lng: 80.2039897992744,
      },
      {
        lat: 13.003295443401393,
        lng: 80.20451523930095,
      },
      {
        lat: 13.002878060301668,
        lng: 80.20773273927466,
      },
      {
        lat: 13.001044679848548,
        lng: 80.2033268422029,
      },
      {
        lat: 13.001659149259165,
        lng: 80.20790050862234,
      },
      {
        lat: 13.003609607567444,
        lng: 80.20370159999837,
      },
      {
        lat: 13.007823796214815,
        lng: 80.20923109255571,
      },
      {
        lat: 13.0058539830307,
        lng: 80.21039490543049,
      },
      {
        lat: 13.00718515391864,
        lng: 80.20751600418527,
      },
      {
        lat: 13.006490699249122,
        lng: 80.20908734876629,
      },
      {
        lat: 13.00161021271195,
        lng: 80.20260970776383,
      },
      {
        lat: 13.000503054104195,
        lng: 80.20675657050724,
      },
      {
        lat: 13.00475046953294,
        lng: 80.20956469512467,
      },
      {
        lat: 13.002716250098771,
        lng: 80.2088201156612,
      },
      {
        lat: 13.003700316931099,
        lng: 80.20306163744173,
      },
      {
        lat: 12.998665317274794,
        lng: 80.20137046104009,
      },
      {
        lat: 13.00400357230255,
        lng: 80.2088583742599,
      },
    ],
    cases: 58,
    coords: [
      {
        lat: 12.999005180953823,
        lng: 80.20757600152683,
      },
      {
        lat: 12.999246230981091,
        lng: 80.20698629181717,
      },
      {
        lat: 12.999476087897122,
        lng: 80.20655566421519,
      },
      {
        lat: 12.99984008512209,
        lng: 80.2063382424806,
      },
      {
        lat: 13.000105658194085,
        lng: 80.20631950708733,
      },
      {
        lat: 13.001637291144732,
        lng: 80.20696605565492,
      },
      {
        lat: 13.002053609110364,
        lng: 80.20716038560049,
      },
      {
        lat: 13.00223605898063,
        lng: 80.20712586592111,
      },
      {
        lat: 13.002583351341697,
        lng: 80.2068920977847,
      },
      {
        lat: 13.002681474656093,
        lng: 80.20664395065127,
      },
      {
        lat: 13.002630457548944,
        lng: 80.20644652907,
      },
      {
        lat: 13.001694283210815,
        lng: 80.20541572205646,
      },
      {
        lat: 13.001173424043557,
        lng: 80.2044143756817,
      },
      {
        lat: 13.000270057756369,
        lng: 80.2033173398876,
      },
      {
        lat: 12.999852837275341,
        lng: 80.20297462756616,
      },
      {
        lat: 12.999067808948976,
        lng: 80.20222283361385,
      },
      {
        lat: 12.998582866496664,
        lng: 80.20166635330938,
      },
      {
        lat: 12.998431018528658,
        lng: 80.2012719317334,
      },
      {
        lat: 12.998429714895318,
        lng: 80.20105760163473,
      },
      {
        lat: 12.998661677680587,
        lng: 80.20097319957672,
      },
      {
        lat: 12.999658153307728,
        lng: 80.20099774170379,
      },
      {
        lat: 13.000330272100708,
        lng: 80.20116964141457,
      },
      {
        lat: 13.000412645393167,
        lng: 80.20165522930273,
      },
      {
        lat: 13.000937694727671,
        lng: 80.20170660911005,
      },
      {
        lat: 13.000977437472537,
        lng: 80.2020784289978,
      },
      {
        lat: 13.002907620000778,
        lng: 80.2020620933625,
      },
      {
        lat: 13.002986992317231,
        lng: 80.20278712628392,
      },
      {
        lat: 13.003138041143078,
        lng: 80.20297192603898,
      },
      {
        lat: 13.003400735570807,
        lng: 80.20302552622408,
      },
      {
        lat: 13.003512267998822,
        lng: 80.20287571979975,
      },
      {
        lat: 13.003510117699832,
        lng: 80.20252218956944,
      },
      {
        lat: 13.004652782813277,
        lng: 80.20243808765004,
      },
      {
        lat: 13.004670164365903,
        lng: 80.20221464680083,
      },
      {
        lat: 13.00540044430941,
        lng: 80.20211542719218,
      },
      {
        lat: 13.005570232771463,
        lng: 80.20230006834883,
      },
      {
        lat: 13.006235173928905,
        lng: 80.20378306574304,
      },
      {
        lat: 13.006424154721694,
        lng: 80.20404197571823,
      },
      {
        lat: 13.007071971951465,
        lng: 80.20404343570054,
      },
      {
        lat: 13.00707471341081,
        lng: 80.20449415848641,
      },
      {
        lat: 13.007756920325772,
        lng: 80.20809435269366,
      },
      {
        lat: 13.008347620786678,
        lng: 80.21022015265676,
      },
      {
        lat: 13.010069981365694,
        lng: 80.21520475864466,
      },
      {
        lat: 13.00886301432629,
        lng: 80.21353492029296,
      },
      {
        lat: 13.007283894794195,
        lng: 80.21174530084055,
      },
      {
        lat: 13.004403861488615,
        lng: 80.20980278372672,
      },
      {
        lat: 13.001382134433657,
        lng: 80.20831221179168,
      },
      {
        lat: 13.000513030945573,
        lng: 80.20790979816013,
      },
      {
        lat: 12.999005180953823,
        lng: 80.20757600152683,
      },
    ],
    properties: {
      Ward_No: 160,
      Zone_Name: "ALANDUR",
      fillColor: "#8bc24c",
      zname: "ALANDUR(160)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.998122227316816,
        lng: 80.20662620047953,
      },
      {
        lat: 13.00004523482584,
        lng: 80.20319613488786,
      },
      {
        lat: 13.000396015520334,
        lng: 80.20625711848406,
      },
      {
        lat: 12.999471943531812,
        lng: 80.20580528385645,
      },
      {
        lat: 12.993534780376654,
        lng: 80.19856068396138,
      },
      {
        lat: 12.999859848835408,
        lng: 80.2058415365021,
      },
      {
        lat: 12.99697528364228,
        lng: 80.20167075586453,
      },
      {
        lat: 12.993524298817263,
        lng: 80.19696920661538,
      },
      {
        lat: 13.00190517329147,
        lng: 80.20600368169436,
      },
      {
        lat: 12.993573213164849,
        lng: 80.19728031747896,
      },
      {
        lat: 12.997947424797216,
        lng: 80.20556577225047,
      },
      {
        lat: 12.997472895795585,
        lng: 80.2049306750023,
      },
      {
        lat: 12.995046293253612,
        lng: 80.19975134850075,
      },
      {
        lat: 12.992631193550269,
        lng: 80.20353180013963,
      },
      {
        lat: 12.99838259750416,
        lng: 80.2066622459634,
      },
      {
        lat: 12.994759004146026,
        lng: 80.20375571297916,
      },
      {
        lat: 12.995095056759036,
        lng: 80.20012357891646,
      },
      {
        lat: 12.999740844817623,
        lng: 80.20460428844854,
      },
      {
        lat: 12.996607550353573,
        lng: 80.20325009155933,
      },
      {
        lat: 12.998308534978632,
        lng: 80.1994091519413,
      },
      {
        lat: 12.992765568461627,
        lng: 80.19836379200784,
      },
      {
        lat: 12.993248571382113,
        lng: 80.19708537132576,
      },
      {
        lat: 12.998000050005071,
        lng: 80.20166670254032,
      },
      {
        lat: 12.994039561388446,
        lng: 80.19718747146429,
      },
      {
        lat: 12.993310731814713,
        lng: 80.2036711622012,
      },
      {
        lat: 12.996743710547003,
        lng: 80.20700071324245,
      },
      {
        lat: 12.99624493500682,
        lng: 80.19879565378878,
      },
      {
        lat: 12.998753081340121,
        lng: 80.20324603366782,
      },
      {
        lat: 12.998141144651477,
        lng: 80.20443392256958,
      },
      {
        lat: 12.999263368723687,
        lng: 80.2032200418703,
      },
      {
        lat: 12.997744237733462,
        lng: 80.200661994952,
      },
      {
        lat: 12.996203136802805,
        lng: 80.19961334042972,
      },
      {
        lat: 13.001609310868574,
        lng: 80.20637569168316,
      },
      {
        lat: 12.994859831205723,
        lng: 80.19844307626661,
      },
      {
        lat: 12.998033472764764,
        lng: 80.20202473015911,
      },
      {
        lat: 12.992274527616155,
        lng: 80.20595294996265,
      },
      {
        lat: 12.992482887221355,
        lng: 80.20501448073938,
      },
      {
        lat: 12.999450677363026,
        lng: 80.2054473765538,
      },
      {
        lat: 12.9945595080051,
        lng: 80.20556364945047,
      },
      {
        lat: 12.995260817542276,
        lng: 80.20653748912403,
      },
      {
        lat: 12.997937450535046,
        lng: 80.20520974340516,
      },
      {
        lat: 12.993142018648818,
        lng: 80.20361924371798,
      },
      {
        lat: 12.991996171687898,
        lng: 80.20584859646945,
      },
      {
        lat: 12.996600670993667,
        lng: 80.2022287918165,
      },
      {
        lat: 12.99294493364102,
        lng: 80.1979083274928,
      },
      {
        lat: 12.99505512707737,
        lng: 80.20337083218578,
      },
      {
        lat: 12.997881039279282,
        lng: 80.20328921372325,
      },
      {
        lat: 13.001792824272773,
        lng: 80.20586413379003,
      },
      {
        lat: 12.996879106371116,
        lng: 80.20575424472143,
      },
      {
        lat: 12.999319175560846,
        lng: 80.20302117646307,
      },
      {
        lat: 12.998906478296407,
        lng: 80.20313489760147,
      },
      {
        lat: 13.00039899590622,
        lng: 80.20640121510887,
      },
      {
        lat: 12.996699510073503,
        lng: 80.19883181663825,
      },
      {
        lat: 12.997852449341924,
        lng: 80.20680631997261,
      },
      {
        lat: 13.00117007450315,
        lng: 80.20552511755754,
      },
      {
        lat: 12.998109911339496,
        lng: 80.20240735522262,
      },
      {
        lat: 13.00158089677122,
        lng: 80.20592254948488,
      },
      {
        lat: 12.997753909602366,
        lng: 80.20290127560594,
      },
      {
        lat: 13.001815913387759,
        lng: 80.20692450262842,
      },
      {
        lat: 12.991398193708468,
        lng: 80.19696341542456,
      },
      {
        lat: 12.997738674131522,
        lng: 80.20432976422343,
      },
      {
        lat: 12.998981853773662,
        lng: 80.20095754289707,
      },
      {
        lat: 13.001489868447427,
        lng: 80.20524952582159,
      },
      {
        lat: 12.996699955946502,
        lng: 80.20023980884233,
      },
      {
        lat: 12.992671411772552,
        lng: 80.19849791957597,
      },
      {
        lat: 12.996373828876495,
        lng: 80.20208313257497,
      },
      {
        lat: 12.994523306233633,
        lng: 80.19763499370083,
      },
      {
        lat: 12.996594361175351,
        lng: 80.20193994419313,
      },
      {
        lat: 12.998409346014233,
        lng: 80.20388981702595,
      },
      {
        lat: 12.999499306986618,
        lng: 80.2038107989323,
      },
      {
        lat: 13.000476223505068,
        lng: 80.20419693200168,
      },
      {
        lat: 12.997267295469475,
        lng: 80.1990430910525,
      },
      {
        lat: 12.992467530610813,
        lng: 80.19828551570667,
      },
      {
        lat: 12.99116883053709,
        lng: 80.19805575400478,
      },
      {
        lat: 12.992862542384735,
        lng: 80.20398063693149,
      },
      {
        lat: 12.997163064272408,
        lng: 80.20500920484706,
      },
      {
        lat: 12.999491262699744,
        lng: 80.20332986527794,
      },
      {
        lat: 12.995425715243375,
        lng: 80.20188277806017,
      },
      {
        lat: 12.99897673066909,
        lng: 80.20474167377434,
      },
      {
        lat: 12.993675251057244,
        lng: 80.20565907274701,
      },
      {
        lat: 12.996951659174933,
        lng: 80.19952242805918,
      },
      {
        lat: 12.992715889678234,
        lng: 80.20591653738481,
      },
      {
        lat: 12.997303488765066,
        lng: 80.20629379792544,
      },
      {
        lat: 12.99519179296696,
        lng: 80.20566669475532,
      },
      {
        lat: 12.995289769448341,
        lng: 80.20359504804989,
      },
      {
        lat: 12.99713072123661,
        lng: 80.20253066104146,
      },
      {
        lat: 12.99720314668542,
        lng: 80.20316118005763,
      },
      {
        lat: 12.998690097281125,
        lng: 80.20624769462364,
      },
      {
        lat: 12.996300641978186,
        lng: 80.20120956528086,
      },
      {
        lat: 12.99519878569172,
        lng: 80.20625292613087,
      },
      {
        lat: 12.998423661005443,
        lng: 80.200719920387,
      },
      {
        lat: 12.996491906493878,
        lng: 80.19973103438988,
      },
      {
        lat: 12.992329006216734,
        lng: 80.19877693931569,
      },
      {
        lat: 13.001653318156476,
        lng: 80.20631307420238,
      },
      {
        lat: 12.997649430988691,
        lng: 80.20065406119495,
      },
      {
        lat: 12.991762527692476,
        lng: 80.19849641945873,
      },
      {
        lat: 12.997240958303518,
        lng: 80.1998095147504,
      },
      {
        lat: 12.994035995827652,
        lng: 80.20507812630758,
      },
      {
        lat: 12.99762300941358,
        lng: 80.20637011615611,
      },
      {
        lat: 12.998276761962352,
        lng: 80.20267158106195,
      },
      {
        lat: 12.998502113469316,
        lng: 80.20193913084374,
      },
      {
        lat: 12.997795703326455,
        lng: 80.2041984561093,
      },
      {
        lat: 12.997469773261676,
        lng: 80.20219915285377,
      },
      {
        lat: 12.997303711475803,
        lng: 80.20141839770007,
      },
      {
        lat: 12.996008256985743,
        lng: 80.1989688462356,
      },
      {
        lat: 12.999316819469325,
        lng: 80.20504994522669,
      },
      {
        lat: 12.996691336895834,
        lng: 80.20637576954056,
      },
      {
        lat: 12.994293150881328,
        lng: 80.20453820842236,
      },
      {
        lat: 13.001112580072977,
        lng: 80.20633655653255,
      },
      {
        lat: 12.993571144123989,
        lng: 80.20567511861528,
      },
      {
        lat: 12.998659346825717,
        lng: 80.20214903234769,
      },
      {
        lat: 12.995240774327545,
        lng: 80.19960442557422,
      },
      {
        lat: 12.995195695061874,
        lng: 80.20670597085525,
      },
      {
        lat: 12.996952660242735,
        lng: 80.20592173670612,
      },
      {
        lat: 12.996883027957889,
        lng: 80.19919107943171,
      },
      {
        lat: 13.002089310376377,
        lng: 80.20714023267864,
      },
      {
        lat: 12.997261887114396,
        lng: 80.20235445793409,
      },
      {
        lat: 12.998133055010538,
        lng: 80.20592265327856,
      },
      {
        lat: 12.997297570893762,
        lng: 80.20707832515633,
      },
      {
        lat: 12.993636020891195,
        lng: 80.20527608932963,
      },
      {
        lat: 13.00060004256122,
        lng: 80.20419698595288,
      },
      {
        lat: 12.993389599548479,
        lng: 80.19739347806127,
      },
      {
        lat: 12.998595921239865,
        lng: 80.20457398950987,
      },
      {
        lat: 12.9955124434523,
        lng: 80.20499862811616,
      },
      {
        lat: 12.995986257933959,
        lng: 80.20543672850758,
      },
      {
        lat: 12.995829983795455,
        lng: 80.20485445673636,
      },
      {
        lat: 12.99215278155579,
        lng: 80.19789397020529,
      },
      {
        lat: 12.993515344029202,
        lng: 80.19906345989071,
      },
      {
        lat: 12.99657444070135,
        lng: 80.20270106581086,
      },
      {
        lat: 12.998598329583487,
        lng: 80.20649991055122,
      },
      {
        lat: 12.995078990333417,
        lng: 80.19968540309696,
      },
      {
        lat: 12.996399843186378,
        lng: 80.20179882354745,
      },
      {
        lat: 13.000638509881922,
        lng: 80.20604654210173,
      },
      {
        lat: 12.993081924795232,
        lng: 80.1991792733903,
      },
      {
        lat: 12.993664165114533,
        lng: 80.19777383648268,
      },
      {
        lat: 12.9920507139728,
        lng: 80.19799756072712,
      },
      {
        lat: 12.999122887589667,
        lng: 80.20317482262402,
      },
      {
        lat: 12.993147754994368,
        lng: 80.20406275987335,
      },
      {
        lat: 12.995543397666655,
        lng: 80.20354587453735,
      },
      {
        lat: 12.997973452284022,
        lng: 80.20531932823461,
      },
      {
        lat: 12.998905224559994,
        lng: 80.20404685906963,
      },
      {
        lat: 12.997459650440716,
        lng: 80.2030947055753,
      },
      {
        lat: 12.992729169485141,
        lng: 80.20379951430355,
      },
      {
        lat: 12.991774541863451,
        lng: 80.1969075571758,
      },
      {
        lat: 12.998051721675479,
        lng: 80.20365487082722,
      },
      {
        lat: 12.998981166428422,
        lng: 80.20654416878038,
      },
      {
        lat: 12.994394256487979,
        lng: 80.20491770258748,
      },
      {
        lat: 12.99138380640385,
        lng: 80.19807174330015,
      },
      {
        lat: 12.998345453759438,
        lng: 80.20619976019259,
      },
      {
        lat: 12.993158874564408,
        lng: 80.20607571618613,
      },
      {
        lat: 12.996761558433427,
        lng: 80.1990438326682,
      },
      {
        lat: 12.998546529932138,
        lng: 80.1993423220565,
      },
      {
        lat: 12.995039076707647,
        lng: 80.20465132628675,
      },
      {
        lat: 12.999492653890275,
        lng: 80.20067041116263,
      },
      {
        lat: 12.9977161935591,
        lng: 80.19987865461324,
      },
      {
        lat: 13.000477463292514,
        lng: 80.20468216770617,
      },
      {
        lat: 12.993009356120359,
        lng: 80.20532017123926,
      },
      {
        lat: 12.996871217635597,
        lng: 80.20416501449297,
      },
      {
        lat: 12.995222980222541,
        lng: 80.20584639262692,
      },
      {
        lat: 12.993544359310047,
        lng: 80.19733491803281,
      },
      {
        lat: 12.997688457734522,
        lng: 80.1995561238766,
      },
      {
        lat: 12.99769502304601,
        lng: 80.2053359898628,
      },
      {
        lat: 12.997995554768927,
        lng: 80.2049715394556,
      },
      {
        lat: 12.994781830672979,
        lng: 80.20586405194427,
      },
      {
        lat: 12.992537320664221,
        lng: 80.20385385592674,
      },
    ],
    cases: 165,
    coords: [
      {
        lat: 12.991911855635838,
        lng: 80.20585566797769,
      },
      {
        lat: 12.992026622431554,
        lng: 80.20509792958933,
      },
      {
        lat: 12.992097521813898,
        lng: 80.20480875589834,
      },
      {
        lat: 12.992130558216184,
        lng: 80.20426740070693,
      },
      {
        lat: 12.992000780159666,
        lng: 80.20383563857558,
      },
      {
        lat: 12.991998476565731,
        lng: 80.20345690515441,
      },
      {
        lat: 12.99263398406451,
        lng: 80.20341545498327,
      },
      {
        lat: 12.992666471992047,
        lng: 80.20278392516771,
      },
      {
        lat: 12.995827052505097,
        lng: 80.20277521220846,
      },
      {
        lat: 12.9957711355945,
        lng: 80.20254121935632,
      },
      {
        lat: 12.995514102465297,
        lng: 80.20209249836437,
      },
      {
        lat: 12.995203346324534,
        lng: 80.20177048301674,
      },
      {
        lat: 12.995088221655074,
        lng: 80.20076144956795,
      },
      {
        lat: 12.99508668592579,
        lng: 80.2005089606205,
      },
      {
        lat: 12.994958882378358,
        lng: 80.20040182713579,
      },
      {
        lat: 12.994267787053998,
        lng: 80.20026338922926,
      },
      {
        lat: 12.993538609359511,
        lng: 80.19983669997251,
      },
      {
        lat: 12.992024556450067,
        lng: 80.19878539845652,
      },
      {
        lat: 12.990732854843298,
        lng: 80.19845364925152,
      },
      {
        lat: 12.990857038457458,
        lng: 80.19796563021723,
      },
      {
        lat: 12.990942371836967,
        lng: 80.19706311535909,
      },
      {
        lat: 12.991049598470013,
        lng: 80.19677363422096,
      },
      {
        lat: 12.991176524457906,
        lng: 80.19673648830704,
      },
      {
        lat: 12.991431144298224,
        lng: 80.19678844095299,
      },
      {
        lat: 12.992030982715756,
        lng: 80.19685550777764,
      },
      {
        lat: 12.99273969319104,
        lng: 80.19690361733673,
      },
      {
        lat: 12.994229549252779,
        lng: 80.19696315171126,
      },
      {
        lat: 12.994411953371424,
        lng: 80.19708785895024,
      },
      {
        lat: 12.994782685135739,
        lng: 80.1983111593684,
      },
      {
        lat: 12.995001745589933,
        lng: 80.19848966393484,
      },
      {
        lat: 12.99536490840275,
        lng: 80.19846855454061,
      },
      {
        lat: 12.995691195490055,
        lng: 80.19835757796909,
      },
      {
        lat: 12.995963978955865,
        lng: 80.19840937689159,
      },
      {
        lat: 12.997118413600107,
        lng: 80.19869787008973,
      },
      {
        lat: 12.998503433213466,
        lng: 80.19871421172174,
      },
      {
        lat: 12.99867501802733,
        lng: 80.19943699784835,
      },
      {
        lat: 12.998696926525266,
        lng: 80.1999578311682,
      },
      {
        lat: 12.998587997090711,
        lng: 80.20053559523977,
      },
      {
        lat: 12.998607755289594,
        lng: 80.20070289832934,
      },
      {
        lat: 12.998833083618319,
        lng: 80.20077542256531,
      },
      {
        lat: 12.998982434839863,
        lng: 80.20068111950711,
      },
      {
        lat: 13.000124873606055,
        lng: 80.2005598038793,
      },
      {
        lat: 13.000275243390115,
        lng: 80.20063296250893,
      },
      {
        lat: 13.000296020151682,
        lng: 80.2009677272866,
      },
      {
        lat: 13.000330272100708,
        lng: 80.20116964141457,
      },
      {
        lat: 12.999658153307728,
        lng: 80.20099774170379,
      },
      {
        lat: 12.998661677680587,
        lng: 80.20097319957672,
      },
      {
        lat: 12.998429714895318,
        lng: 80.20105760163473,
      },
      {
        lat: 12.998431018528658,
        lng: 80.2012719317334,
      },
      {
        lat: 12.998582866496664,
        lng: 80.20166635330938,
      },
      {
        lat: 12.999067808948976,
        lng: 80.20222283361385,
      },
      {
        lat: 12.999852837275341,
        lng: 80.20297462756616,
      },
      {
        lat: 13.000270057756369,
        lng: 80.2033173398876,
      },
      {
        lat: 13.001173424043557,
        lng: 80.2044143756817,
      },
      {
        lat: 13.001694283210815,
        lng: 80.20541572205646,
      },
      {
        lat: 13.002630457548944,
        lng: 80.20644652907,
      },
      {
        lat: 13.002681474656093,
        lng: 80.20664395065127,
      },
      {
        lat: 13.002583351341697,
        lng: 80.2068920977847,
      },
      {
        lat: 13.00223605898063,
        lng: 80.20712586592111,
      },
      {
        lat: 13.002053609110364,
        lng: 80.20716038560049,
      },
      {
        lat: 13.001637291144732,
        lng: 80.20696605565492,
      },
      {
        lat: 13.000105658194085,
        lng: 80.20631950708733,
      },
      {
        lat: 12.99984008512209,
        lng: 80.2063382424806,
      },
      {
        lat: 12.999476087897122,
        lng: 80.20655566421519,
      },
      {
        lat: 12.999246230981091,
        lng: 80.20698629181717,
      },
      {
        lat: 12.999005180953823,
        lng: 80.20757600152683,
      },
      {
        lat: 12.994272960542688,
        lng: 80.20652841771528,
      },
      {
        lat: 12.991911855635838,
        lng: 80.20585566797769,
      },
    ],
    properties: {
      Ward_No: 161,
      Zone_Name: "ALANDUR",
      fillColor: "#de4307",
      zname: "ALANDUR(161)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.983965180294513,
        lng: 80.19739243097335,
      },
      {
        lat: 12.985162195317171,
        lng: 80.20312532482438,
      },
      {
        lat: 12.981686933267001,
        lng: 80.19571232540707,
      },
      {
        lat: 12.98433581305717,
        lng: 80.19671999936968,
      },
      {
        lat: 12.98309392703652,
        lng: 80.19787463199987,
      },
      {
        lat: 12.985890934644868,
        lng: 80.19950382086186,
      },
      {
        lat: 12.986267431718089,
        lng: 80.19423810591452,
      },
      {
        lat: 12.981855408709196,
        lng: 80.20361641117505,
      },
      {
        lat: 12.983116138478923,
        lng: 80.19497524304057,
      },
      {
        lat: 12.985813651005948,
        lng: 80.20203808431611,
      },
      {
        lat: 12.983667267426465,
        lng: 80.1969815985193,
      },
      {
        lat: 12.979697892067133,
        lng: 80.20435577360885,
      },
      {
        lat: 12.986674028750219,
        lng: 80.1980135880871,
      },
      {
        lat: 12.984366289271724,
        lng: 80.19895733849259,
      },
      {
        lat: 12.983119469710472,
        lng: 80.19265199252811,
      },
      {
        lat: 12.979278648172135,
        lng: 80.20380471976273,
      },
      {
        lat: 12.986230579836672,
        lng: 80.20009207405894,
      },
      {
        lat: 12.984322343168968,
        lng: 80.19441041645342,
      },
      {
        lat: 12.988742461668366,
        lng: 80.20312525979195,
      },
      {
        lat: 12.983757018556753,
        lng: 80.19448566315988,
      },
      {
        lat: 12.978957701576096,
        lng: 80.20353979064535,
      },
      {
        lat: 12.981045156865584,
        lng: 80.20204381953504,
      },
      {
        lat: 12.98208245605967,
        lng: 80.19591154676078,
      },
      {
        lat: 12.981373368049224,
        lng: 80.19698639509096,
      },
      {
        lat: 12.981179080276695,
        lng: 80.19869334393135,
      },
      {
        lat: 12.981852580478632,
        lng: 80.19800271069116,
      },
      {
        lat: 12.98573811349148,
        lng: 80.19965552854947,
      },
      {
        lat: 12.982155466149775,
        lng: 80.19536595360704,
      },
      {
        lat: 12.980693825927844,
        lng: 80.20124595491774,
      },
      {
        lat: 12.983393534102934,
        lng: 80.19446658227834,
      },
      {
        lat: 12.983481665895987,
        lng: 80.20394830126003,
      },
      {
        lat: 12.982926717081533,
        lng: 80.198974332506,
      },
      {
        lat: 12.978424988804745,
        lng: 80.2040041552511,
      },
      {
        lat: 12.977561930457886,
        lng: 80.20312493798619,
      },
      {
        lat: 12.983294036817096,
        lng: 80.20456509989735,
      },
      {
        lat: 12.985664926766882,
        lng: 80.19738587204878,
      },
      {
        lat: 12.978341312562184,
        lng: 80.20535231057653,
      },
      {
        lat: 12.98210881347602,
        lng: 80.20425148151945,
      },
      {
        lat: 12.984863734459186,
        lng: 80.20277213390804,
      },
      {
        lat: 12.981733011616294,
        lng: 80.2015295790132,
      },
      {
        lat: 12.985598128609118,
        lng: 80.19396684379709,
      },
      {
        lat: 12.981589566254623,
        lng: 80.19924079825338,
      },
      {
        lat: 12.981046480739547,
        lng: 80.19790575109575,
      },
      {
        lat: 12.976763443144224,
        lng: 80.20489374493104,
      },
      {
        lat: 12.982335893027109,
        lng: 80.19284661959695,
      },
      {
        lat: 12.979546528988795,
        lng: 80.20247713947177,
      },
      {
        lat: 12.986517502845384,
        lng: 80.2032242224322,
      },
      {
        lat: 12.984449672980487,
        lng: 80.19836573558607,
      },
      {
        lat: 12.985020527978335,
        lng: 80.20233188142466,
      },
      {
        lat: 12.98887616083621,
        lng: 80.20384992582368,
      },
      {
        lat: 12.979525944037148,
        lng: 80.20507819955183,
      },
      {
        lat: 12.98272102688061,
        lng: 80.20292532885851,
      },
      {
        lat: 12.981170960285947,
        lng: 80.20385972569501,
      },
      {
        lat: 12.979787018469954,
        lng: 80.19922251493323,
      },
      {
        lat: 12.985089755194066,
        lng: 80.20029649720053,
      },
      {
        lat: 12.984882459713669,
        lng: 80.19904326618251,
      },
      {
        lat: 12.983369909293494,
        lng: 80.20157297862833,
      },
      {
        lat: 12.980873293728816,
        lng: 80.19851412513148,
      },
      {
        lat: 12.982580558018375,
        lng: 80.20010256036014,
      },
      {
        lat: 12.981840786620593,
        lng: 80.20428120846985,
      },
      {
        lat: 12.982802139111621,
        lng: 80.19834825672686,
      },
      {
        lat: 12.981400156138378,
        lng: 80.19529625419742,
      },
      {
        lat: 12.98703946879977,
        lng: 80.19776581986193,
      },
      {
        lat: 12.982502587157013,
        lng: 80.20077807344995,
      },
      {
        lat: 12.980427951664439,
        lng: 80.19951344541452,
      },
      {
        lat: 12.977786366876918,
        lng: 80.20232873814463,
      },
      {
        lat: 12.98272059225599,
        lng: 80.19248635130296,
      },
      {
        lat: 12.976841595360963,
        lng: 80.20474915824663,
      },
      {
        lat: 12.9857586505248,
        lng: 80.19314393930009,
      },
      {
        lat: 12.982542778654878,
        lng: 80.20177972829225,
      },
      {
        lat: 12.981699362550962,
        lng: 80.20247804300669,
      },
      {
        lat: 12.979667570803812,
        lng: 80.20202065761282,
      },
      {
        lat: 12.982113374499153,
        lng: 80.19556823184853,
      },
      {
        lat: 12.98407158016801,
        lng: 80.200723838388,
      },
      {
        lat: 12.978449307208452,
        lng: 80.20351421691966,
      },
      {
        lat: 12.9794788504571,
        lng: 80.2058678484256,
      },
      {
        lat: 12.988615278782504,
        lng: 80.20372060810406,
      },
      {
        lat: 12.98009453875613,
        lng: 80.1999743801901,
      },
      {
        lat: 12.982453964388228,
        lng: 80.19914117300716,
      },
      {
        lat: 12.98462054989461,
        lng: 80.19586469692875,
      },
      {
        lat: 12.982025993907136,
        lng: 80.19588297274149,
      },
      {
        lat: 12.979459530849557,
        lng: 80.20077966263548,
      },
      {
        lat: 12.977300616693595,
        lng: 80.20407464874556,
      },
      {
        lat: 12.988247899235079,
        lng: 80.20226592506366,
      },
      {
        lat: 12.980038103027676,
        lng: 80.20652092991556,
      },
      {
        lat: 12.980262544989213,
        lng: 80.19700678303897,
      },
      {
        lat: 12.98500303447905,
        lng: 80.19917896665706,
      },
      {
        lat: 12.981830717485725,
        lng: 80.20032885936162,
      },
      {
        lat: 12.985696758012129,
        lng: 80.19606729914102,
      },
      {
        lat: 12.98135134434562,
        lng: 80.20562430641378,
      },
      {
        lat: 12.98490309014919,
        lng: 80.19364080314146,
      },
      {
        lat: 12.983274915260123,
        lng: 80.19772503278342,
      },
      {
        lat: 12.988701198429464,
        lng: 80.20307333721904,
      },
      {
        lat: 12.979766350162343,
        lng: 80.19904796048738,
      },
      {
        lat: 12.982470957418988,
        lng: 80.19644483336697,
      },
      {
        lat: 12.979365709546615,
        lng: 80.2014663623268,
      },
      {
        lat: 12.979638018015994,
        lng: 80.20034521247608,
      },
      {
        lat: 12.984462849645086,
        lng: 80.19859374290496,
      },
      {
        lat: 12.984914879630965,
        lng: 80.19938229432152,
      },
      {
        lat: 12.981346141131052,
        lng: 80.1960727379488,
      },
      {
        lat: 12.983300921948036,
        lng: 80.19539809841237,
      },
      {
        lat: 12.981158855351559,
        lng: 80.1958590035446,
      },
      {
        lat: 12.983910581293589,
        lng: 80.19708455967105,
      },
      {
        lat: 12.985447460474894,
        lng: 80.19410635756986,
      },
      {
        lat: 12.982661237796664,
        lng: 80.20468040107488,
      },
      {
        lat: 12.978472232451994,
        lng: 80.20167291962835,
      },
      {
        lat: 12.983563577128978,
        lng: 80.19385479174241,
      },
      {
        lat: 12.984544323891614,
        lng: 80.19724777432413,
      },
    ],
    cases: 108,
    coords: [
      {
        lat: 12.988742586558743,
        lng: 80.20496916271065,
      },
      {
        lat: 12.988444066823467,
        lng: 80.20489769592788,
      },
      {
        lat: 12.988277502672036,
        lng: 80.20465324415558,
      },
      {
        lat: 12.988234241681118,
        lng: 80.20432579502088,
      },
      {
        lat: 12.988313536179463,
        lng: 80.20379242412729,
      },
      {
        lat: 12.988106203272656,
        lng: 80.2036302712076,
      },
      {
        lat: 12.987693531247595,
        lng: 80.20363376375798,
      },
      {
        lat: 12.986703616834772,
        lng: 80.20372409547632,
      },
      {
        lat: 12.985589651590713,
        lng: 80.20377450016099,
      },
      {
        lat: 12.985134217021832,
        lng: 80.20353249317415,
      },
      {
        lat: 12.984411044083712,
        lng: 80.2033747059424,
      },
      {
        lat: 12.983957354079921,
        lng: 80.2034195225465,
      },
      {
        lat: 12.983793032940685,
        lng: 80.20354384396285,
      },
      {
        lat: 12.983713239995016,
        lng: 80.20399526525895,
      },
      {
        lat: 12.983551411091547,
        lng: 80.20452933466238,
      },
      {
        lat: 12.983181501610424,
        lng: 80.20477832675,
      },
      {
        lat: 12.982314391910728,
        lng: 80.20470371150836,
      },
      {
        lat: 12.981861449577442,
        lng: 80.20487145250863,
      },
      {
        lat: 12.981574572948654,
        lng: 80.20520169568377,
      },
      {
        lat: 12.981416731622415,
        lng: 80.20639136186684,
      },
      {
        lat: 12.981335941782612,
        lng: 80.2066788839681,
      },
      {
        lat: 12.981130353440552,
        lng: 80.20680355463944,
      },
      {
        lat: 12.980387543795675,
        lng: 80.2068098412301,
      },
      {
        lat: 12.980306753955585,
        lng: 80.2070973633312,
      },
      {
        lat: 12.980267729765465,
        lng: 80.20746648577465,
      },
      {
        lat: 12.979937592145307,
        lng: 80.20746927981493,
      },
      {
        lat: 12.97962742176693,
        lng: 80.20740462127456,
      },
      {
        lat: 12.978537034781093,
        lng: 80.2066278107009,
      },
      {
        lat: 12.978466782431163,
        lng: 80.20647112908982,
      },
      {
        lat: 12.978525222259945,
        lng: 80.20631335832353,
      },
      {
        lat: 12.978701617877418,
        lng: 80.20601697261357,
      },
      {
        lat: 12.978691120628103,
        lng: 80.20591876384495,
      },
      {
        lat: 12.97849241524,
        lng: 80.20580248840905,
      },
      {
        lat: 12.97652785048494,
        lng: 80.20508188295973,
      },
      {
        lat: 12.976278811113941,
        lng: 80.20482841686021,
      },
      {
        lat: 12.976278153477939,
        lng: 80.20472029505567,
      },
      {
        lat: 12.97647590230453,
        lng: 80.20467930241225,
      },
      {
        lat: 12.976743006704687,
        lng: 80.20464755255566,
      },
      {
        lat: 12.976851242450364,
        lng: 80.2045385091581,
      },
      {
        lat: 12.976899424170197,
        lng: 80.20432184664321,
      },
      {
        lat: 12.977082670146366,
        lng: 80.20352408514896,
      },
      {
        lat: 12.977313260851147,
        lng: 80.20237205157034,
      },
      {
        lat: 12.977460795264982,
        lng: 80.20221352677342,
      },
      {
        lat: 12.977589547228614,
        lng: 80.20222226687316,
      },
      {
        lat: 12.97790322497697,
        lng: 80.20227225188485,
      },
      {
        lat: 12.978159031409056,
        lng: 80.20211712849911,
      },
      {
        lat: 12.978140158392264,
        lng: 80.20182836674832,
      },
      {
        lat: 12.97830935224594,
        lng: 80.20150402257686,
      },
      {
        lat: 12.978497005649695,
        lng: 80.20140046213093,
      },
      {
        lat: 12.978719820918366,
        lng: 80.20144956253081,
      },
      {
        lat: 12.979027491530822,
        lng: 80.20137897711022,
      },
      {
        lat: 12.979213904533676,
        lng: 80.2010714825896,
      },
      {
        lat: 12.97933020053945,
        lng: 80.2004926553904,
      },
      {
        lat: 12.97946526619567,
        lng: 80.2001855954357,
      },
      {
        lat: 12.979788088622216,
        lng: 80.19979196954147,
      },
      {
        lat: 12.979802930335893,
        lng: 80.19941794554879,
      },
      {
        lat: 12.979697961574363,
        lng: 80.19904493554341,
      },
      {
        lat: 12.97973074600428,
        lng: 80.19880672274549,
      },
      {
        lat: 12.979778578881543,
        lng: 80.19822847496728,
      },
      {
        lat: 12.979827238692776,
        lng: 80.19778618323927,
      },
      {
        lat: 12.980013548328657,
        lng: 80.19746169421235,
      },
      {
        lat: 12.980114072320095,
        lng: 80.19710394044945,
      },
      {
        lat: 12.980196653595456,
        lng: 80.19661037549209,
      },
      {
        lat: 12.980400079013608,
        lng: 80.19628574160987,
      },
      {
        lat: 12.980979121339224,
        lng: 80.19580497035477,
      },
      {
        lat: 12.981944487616472,
        lng: 80.19411425748483,
      },
      {
        lat: 12.982180077263898,
        lng: 80.19344944376708,
      },
      {
        lat: 12.982277706986604,
        lng: 80.19261584382964,
      },
      {
        lat: 12.982323738164718,
        lng: 80.19210824032147,
      },
      {
        lat: 12.98512805514573,
        lng: 80.19267621230189,
      },
      {
        lat: 12.987304440774501,
        lng: 80.19319724746445,
      },
      {
        lat: 12.985960194576732,
        lng: 80.1955013057027,
      },
      {
        lat: 12.985749610139697,
        lng: 80.19660896961456,
      },
      {
        lat: 12.985643907797638,
        lng: 80.19709537323168,
      },
      {
        lat: 12.985633014418152,
        lng: 80.19735644475398,
      },
      {
        lat: 12.985919601418196,
        lng: 80.19747819102304,
      },
      {
        lat: 12.986849849368392,
        lng: 80.19768318391466,
      },
      {
        lat: 12.98715386930138,
        lng: 80.19773382737058,
      },
      {
        lat: 12.987244810058542,
        lng: 80.1979991399919,
      },
      {
        lat: 12.987085971672245,
        lng: 80.19831978301427,
      },
      {
        lat: 12.986890325429298,
        lng: 80.19846334936666,
      },
      {
        lat: 12.98651495543953,
        lng: 80.19843104858334,
      },
      {
        lat: 12.986103317034969,
        lng: 80.19831036065692,
      },
      {
        lat: 12.98597880507253,
        lng: 80.19840010852609,
      },
      {
        lat: 12.985980315509698,
        lng: 80.19864843920101,
      },
      {
        lat: 12.986178335296534,
        lng: 80.19889510676637,
      },
      {
        lat: 12.98634008666904,
        lng: 80.19905338718866,
      },
      {
        lat: 12.986413702381745,
        lng: 80.1994075405284,
      },
      {
        lat: 12.986137688441886,
        lng: 80.20102410898411,
      },
      {
        lat: 12.985909118323946,
        lng: 80.20162916325933,
      },
      {
        lat: 12.985839170815732,
        lng: 80.20187809870137,
      },
      {
        lat: 12.985964869550465,
        lng: 80.20198346779104,
      },
      {
        lat: 12.988646376603945,
        lng: 80.20226233341502,
      },
      {
        lat: 12.989182203305779,
        lng: 80.20224005975615,
      },
      {
        lat: 12.989396577141921,
        lng: 80.20223824545481,
      },
      {
        lat: 12.989451249484688,
        lng: 80.20241517093297,
      },
      {
        lat: 12.988742586558743,
        lng: 80.20496916271065,
      },
    ],
    properties: {
      Ward_No: 165,
      Zone_Name: "ALANDUR",
      fillColor: "#f6d04d",
      zname: "ALANDUR(165)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.992329556575871,
        lng: 80.16585659156581,
      },
      {
        lat: 13.003692728379821,
        lng: 80.16733586043685,
      },
      {
        lat: 12.992664463787888,
        lng: 80.1737203249599,
      },
      {
        lat: 12.98780451747336,
        lng: 80.16916296666008,
      },
      {
        lat: 13.002566958413203,
        lng: 80.16800414469935,
      },
      {
        lat: 13.002716600162012,
        lng: 80.16464394944069,
      },
      {
        lat: 13.002336118635988,
        lng: 80.16837507377063,
      },
      {
        lat: 13.000511112928349,
        lng: 80.17275817014892,
      },
      {
        lat: 12.998246741612794,
        lng: 80.17031792783264,
      },
      {
        lat: 12.982823163491329,
        lng: 80.17595317461478,
      },
      {
        lat: 12.985888712942353,
        lng: 80.17193492750874,
      },
      {
        lat: 12.990094782992003,
        lng: 80.1729036374157,
      },
      {
        lat: 12.996007633431248,
        lng: 80.17305194470272,
      },
      {
        lat: 12.99724269785462,
        lng: 80.16465437219237,
      },
      {
        lat: 12.992426028194538,
        lng: 80.17474182574131,
      },
      {
        lat: 12.998307671035915,
        lng: 80.17655264272406,
      },
      {
        lat: 12.98895908101936,
        lng: 80.16650328549542,
      },
      {
        lat: 12.991732947591963,
        lng: 80.1740116244825,
      },
      {
        lat: 12.987053306442709,
        lng: 80.1682253157174,
      },
      {
        lat: 12.984391981271326,
        lng: 80.17269270057906,
      },
      {
        lat: 12.992326262672071,
        lng: 80.16943809867128,
      },
      {
        lat: 12.989507809874866,
        lng: 80.17374966626336,
      },
      {
        lat: 12.99347020831677,
        lng: 80.17263714125258,
      },
      {
        lat: 12.996690287303942,
        lng: 80.16234107611493,
      },
      {
        lat: 12.995766538465087,
        lng: 80.16442599019592,
      },
      {
        lat: 12.994690647881994,
        lng: 80.17317991975774,
      },
      {
        lat: 12.99706262529794,
        lng: 80.17444851974878,
      },
      {
        lat: 12.98799434555208,
        lng: 80.17443784874528,
      },
      {
        lat: 12.988236636039874,
        lng: 80.17529280686497,
      },
      {
        lat: 12.995407761770602,
        lng: 80.16802722460594,
      },
      {
        lat: 12.983287910419264,
        lng: 80.17464436030538,
      },
      {
        lat: 12.982293114141395,
        lng: 80.1676972800685,
      },
      {
        lat: 12.993412791442152,
        lng: 80.17084494606473,
      },
      {
        lat: 13.000899875645288,
        lng: 80.1720605274719,
      },
      {
        lat: 13.00281739640238,
        lng: 80.16817306481448,
      },
      {
        lat: 12.986366356844377,
        lng: 80.17624918356437,
      },
      {
        lat: 12.998473101451705,
        lng: 80.16133842943046,
      },
      {
        lat: 12.997189794973186,
        lng: 80.1701103026593,
      },
      {
        lat: 12.988941090743323,
        lng: 80.16968295114678,
      },
      {
        lat: 12.993294914439334,
        lng: 80.16704423527636,
      },
      {
        lat: 12.98229224852705,
        lng: 80.17341559776364,
      },
      {
        lat: 13.000408729226168,
        lng: 80.16599853636592,
      },
      {
        lat: 12.987370016035053,
        lng: 80.17192944580313,
      },
      {
        lat: 12.99043184424545,
        lng: 80.17100430207236,
      },
      {
        lat: 12.98521140895096,
        lng: 80.17261637945252,
      },
      {
        lat: 12.99047289093223,
        lng: 80.17117824176157,
      },
      {
        lat: 12.990677625262263,
        lng: 80.16579385007188,
      },
      {
        lat: 12.993441182666535,
        lng: 80.16766326393045,
      },
      {
        lat: 12.987409822856149,
        lng: 80.17459792693685,
      },
      {
        lat: 12.99253696762984,
        lng: 80.1648614846548,
      },
      {
        lat: 12.993168950802952,
        lng: 80.1728614758994,
      },
      {
        lat: 12.983305328099403,
        lng: 80.16756707293332,
      },
      {
        lat: 12.99865841599561,
        lng: 80.16177692908781,
      },
      {
        lat: 12.990071855636641,
        lng: 80.17267173191527,
      },
      {
        lat: 12.995803881153353,
        lng: 80.16693066431736,
      },
      {
        lat: 12.997032606049176,
        lng: 80.17175882319427,
      },
      {
        lat: 12.998629211253695,
        lng: 80.17339776619077,
      },
      {
        lat: 12.996080358764026,
        lng: 80.17309573761196,
      },
      {
        lat: 12.999159709363546,
        lng: 80.17580507812595,
      },
      {
        lat: 13.00015190898078,
        lng: 80.16308212811255,
      },
      {
        lat: 12.99123990576041,
        lng: 80.17141897779,
      },
      {
        lat: 12.992393733215685,
        lng: 80.17120084179696,
      },
      {
        lat: 12.998175074365918,
        lng: 80.17463165691258,
      },
      {
        lat: 12.982379492283656,
        lng: 80.17597212348998,
      },
      {
        lat: 12.995339550424404,
        lng: 80.1746943670645,
      },
      {
        lat: 12.990993810241811,
        lng: 80.16429154026822,
      },
      {
        lat: 12.997405705137067,
        lng: 80.17231953707406,
      },
      {
        lat: 12.997557040136888,
        lng: 80.17334455737253,
      },
      {
        lat: 12.992343128623187,
        lng: 80.16751802595016,
      },
      {
        lat: 12.987866862463854,
        lng: 80.17771977206739,
      },
      {
        lat: 12.988684769148268,
        lng: 80.17419740355479,
      },
      {
        lat: 12.982803196722765,
        lng: 80.16970222798247,
      },
      {
        lat: 13.000075957307521,
        lng: 80.16978878004824,
      },
      {
        lat: 13.005086952156434,
        lng: 80.16687664548894,
      },
      {
        lat: 12.994948735290675,
        lng: 80.17216393559583,
      },
      {
        lat: 12.994232323342523,
        lng: 80.16634985846406,
      },
      {
        lat: 13.00095409215997,
        lng: 80.16802086920018,
      },
      {
        lat: 12.999764865082394,
        lng: 80.16982168784074,
      },
      {
        lat: 12.983113421176121,
        lng: 80.17246856385495,
      },
      {
        lat: 12.999703107968209,
        lng: 80.16985839169018,
      },
      {
        lat: 12.989146451907448,
        lng: 80.1735916147519,
      },
      {
        lat: 12.985809649507711,
        lng: 80.16731835157678,
      },
      {
        lat: 12.998587588053669,
        lng: 80.16884145686106,
      },
      {
        lat: 12.980908451451485,
        lng: 80.16935943906327,
      },
      {
        lat: 12.997868458449403,
        lng: 80.17631268634412,
      },
      {
        lat: 12.994078009804516,
        lng: 80.16373501853153,
      },
      {
        lat: 12.999870291514982,
        lng: 80.16735286802728,
      },
      {
        lat: 12.98245776519373,
        lng: 80.1745247945512,
      },
      {
        lat: 12.998827112702001,
        lng: 80.16374119846583,
      },
      {
        lat: 12.99113521452743,
        lng: 80.16812011965736,
      },
      {
        lat: 12.98600352540371,
        lng: 80.16962643641891,
      },
      {
        lat: 12.997982910495747,
        lng: 80.16676112724615,
      },
      {
        lat: 13.001500744093146,
        lng: 80.1656352685339,
      },
      {
        lat: 12.984315914896104,
        lng: 80.1697858567162,
      },
      {
        lat: 13.003155314652824,
        lng: 80.16555680000623,
      },
      {
        lat: 12.999319390887495,
        lng: 80.16392982652287,
      },
      {
        lat: 12.999055759011842,
        lng: 80.16554541768491,
      },
      {
        lat: 12.995743866710932,
        lng: 80.17367500840247,
      },
      {
        lat: 12.990446953400776,
        lng: 80.1751770349676,
      },
      {
        lat: 12.9818578688968,
        lng: 80.17124116480296,
      },
      {
        lat: 12.999093454582592,
        lng: 80.1637891709118,
      },
      {
        lat: 12.983830222571939,
        lng: 80.16830752784898,
      },
      {
        lat: 12.988437820374829,
        lng: 80.17329992732498,
      },
      {
        lat: 12.997266886945448,
        lng: 80.16280145763349,
      },
      {
        lat: 12.98648833488372,
        lng: 80.16675595060306,
      },
      {
        lat: 12.99031872996848,
        lng: 80.17289136785011,
      },
      {
        lat: 12.991897292657223,
        lng: 80.16898114159139,
      },
      {
        lat: 12.99303445368707,
        lng: 80.17430672514371,
      },
      {
        lat: 13.001819913971849,
        lng: 80.16552317606806,
      },
      {
        lat: 13.000399360960966,
        lng: 80.17587467117895,
      },
      {
        lat: 13.000942075633306,
        lng: 80.17144775918077,
      },
      {
        lat: 12.999458250944889,
        lng: 80.17101228916867,
      },
      {
        lat: 12.98857900759408,
        lng: 80.16821610384599,
      },
      {
        lat: 13.000057662012585,
        lng: 80.16761816673399,
      },
      {
        lat: 12.995156119802616,
        lng: 80.17715983365994,
      },
      {
        lat: 12.988743347287782,
        lng: 80.16584540194611,
      },
      {
        lat: 12.992776402132753,
        lng: 80.1671478859334,
      },
      {
        lat: 12.994404746479447,
        lng: 80.16510652538368,
      },
      {
        lat: 13.001436507230364,
        lng: 80.16389991969893,
      },
      {
        lat: 12.990679542242841,
        lng: 80.16550629490402,
      },
      {
        lat: 12.99658122427349,
        lng: 80.17467199644035,
      },
      {
        lat: 12.998546414798383,
        lng: 80.17419176381539,
      },
      {
        lat: 12.999008048605809,
        lng: 80.16579942435632,
      },
      {
        lat: 12.99312992557268,
        lng: 80.1630828707191,
      },
      {
        lat: 12.986370039979342,
        lng: 80.16780263848517,
      },
      {
        lat: 12.995509752723272,
        lng: 80.1678165475014,
      },
      {
        lat: 12.98079577215489,
        lng: 80.1717305510273,
      },
      {
        lat: 12.998426248108109,
        lng: 80.16116596365936,
      },
      {
        lat: 12.982269393436237,
        lng: 80.17210427944005,
      },
    ],
    cases: 129,
    coords: [
      {
        lat: 13.000878841739645,
        lng: 80.17755842104432,
      },
      {
        lat: 12.999638686804927,
        lng: 80.17625387402569,
      },
      {
        lat: 12.995321735364175,
        lng: 80.1774690728171,
      },
      {
        lat: 12.99330453517675,
        lng: 80.17574621334978,
      },
      {
        lat: 12.991373828489039,
        lng: 80.17430325598734,
      },
      {
        lat: 12.989272595529336,
        lng: 80.17735188778312,
      },
      {
        lat: 12.988993557642797,
        lng: 80.17794358100905,
      },
      {
        lat: 12.989024380111788,
        lng: 80.17836427133338,
      },
      {
        lat: 12.988912355317773,
        lng: 80.1785335999014,
      },
      {
        lat: 12.988664407537772,
        lng: 80.17863122628621,
      },
      {
        lat: 12.987188519713438,
        lng: 80.17799412483329,
      },
      {
        lat: 12.986321255583398,
        lng: 80.17749842570659,
      },
      {
        lat: 12.985574811830364,
        lng: 80.17726801881473,
      },
      {
        lat: 12.983456480919557,
        lng: 80.17687167938026,
      },
      {
        lat: 12.982441115847836,
        lng: 80.17652518631967,
      },
      {
        lat: 12.982230715021608,
        lng: 80.17623106169788,
      },
      {
        lat: 12.982223876121203,
        lng: 80.17510667944194,
      },
      {
        lat: 12.982339477337378,
        lng: 80.17451389048145,
      },
      {
        lat: 12.982218656960375,
        lng: 80.17424859824679,
      },
      {
        lat: 12.979997463477654,
        lng: 80.17365160242872,
      },
      {
        lat: 12.979979016218786,
        lng: 80.17335437167668,
      },
      {
        lat: 12.97978863170022,
        lng: 80.17267783392171,
      },
      {
        lat: 12.979968517400229,
        lng: 80.17162826300374,
      },
      {
        lat: 12.98038606344643,
        lng: 80.16881965822151,
      },
      {
        lat: 12.980563324441635,
        lng: 80.16733856013548,
      },
      {
        lat: 12.980121781096084,
        lng: 80.16620177366501,
      },
      {
        lat: 12.982143159674473,
        lng: 80.16677034037492,
      },
      {
        lat: 12.983169464368201,
        lng: 80.1670699040506,
      },
      {
        lat: 12.983884585841983,
        lng: 80.16724880152726,
      },
      {
        lat: 12.987908782587265,
        lng: 80.1653344214001,
      },
      {
        lat: 12.993541233191086,
        lng: 80.16242003161653,
      },
      {
        lat: 12.996017762907616,
        lng: 80.16125854873724,
      },
      {
        lat: 12.996697341557224,
        lng: 80.16069794808162,
      },
      {
        lat: 12.997038068276511,
        lng: 80.16057176459961,
      },
      {
        lat: 12.99728660239902,
        lng: 80.16060048614688,
      },
      {
        lat: 13.001583143087467,
        lng: 80.16262939545014,
      },
      {
        lat: 13.002741120259165,
        lng: 80.16416084298126,
      },
      {
        lat: 13.003889521743034,
        lng: 80.16527680472583,
      },
      {
        lat: 13.004877966192433,
        lng: 80.1660969741006,
      },
      {
        lat: 13.005127692522915,
        lng: 80.16641195911808,
      },
      {
        lat: 13.005163797473584,
        lng: 80.16687443877636,
      },
      {
        lat: 13.005116978327088,
        lng: 80.16738720437405,
      },
      {
        lat: 13.004951934327716,
        lng: 80.16761999379624,
      },
      {
        lat: 13.004022319929271,
        lng: 80.16804106245678,
      },
      {
        lat: 13.002130906632443,
        lng: 80.16906528064447,
      },
      {
        lat: 13.001469926439935,
        lng: 80.16986422078944,
      },
      {
        lat: 13.001305284538072,
        lng: 80.17016311898345,
      },
      {
        lat: 13.001257761720927,
        lng: 80.17056019423046,
      },
      {
        lat: 13.001403287291826,
        lng: 80.17259191198652,
      },
      {
        lat: 13.001430789058714,
        lng: 80.17437670795297,
      },
      {
        lat: 13.001336145522,
        lng: 80.1752369672189,
      },
      {
        lat: 13.000878841739645,
        lng: 80.17755842104432,
      },
    ],
    properties: {
      Ward_No: 159,
      Zone_Name: "ALANDUR",
      fillColor: "#de4307",
      zname: "ALANDUR(159)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.024251041880675,
        lng: 80.14943735520953,
      },
      {
        lat: 13.018646707842997,
        lng: 80.17301356059376,
      },
      {
        lat: 13.020556565488517,
        lng: 80.1517555210608,
      },
      {
        lat: 13.018642842329498,
        lng: 80.16074793498271,
      },
      {
        lat: 13.019211969210403,
        lng: 80.16268883257565,
      },
      {
        lat: 13.018082132311704,
        lng: 80.15630830857941,
      },
      {
        lat: 13.021031028378408,
        lng: 80.1605230429288,
      },
      {
        lat: 13.019937599786642,
        lng: 80.16001348208243,
      },
      {
        lat: 13.018513864693624,
        lng: 80.16336761678775,
      },
      {
        lat: 13.017740904700794,
        lng: 80.15049605632822,
      },
      {
        lat: 13.021612314762788,
        lng: 80.16576827645387,
      },
      {
        lat: 13.021382328325789,
        lng: 80.14560195840373,
      },
      {
        lat: 13.02054472583029,
        lng: 80.14995572597606,
      },
      {
        lat: 13.020448440038994,
        lng: 80.16875249866847,
      },
      {
        lat: 13.022688302600702,
        lng: 80.17095989025042,
      },
      {
        lat: 13.022269649421531,
        lng: 80.1594478562886,
      },
      {
        lat: 13.022889425650598,
        lng: 80.17087195056818,
      },
      {
        lat: 13.020807283588265,
        lng: 80.16929685182495,
      },
      {
        lat: 13.020818012499548,
        lng: 80.16556878402614,
      },
      {
        lat: 13.018376036534,
        lng: 80.15936266182753,
      },
      {
        lat: 13.021003553996533,
        lng: 80.16820624948377,
      },
      {
        lat: 13.024313094405908,
        lng: 80.14808758769614,
      },
      {
        lat: 13.017366563431873,
        lng: 80.15586201600753,
      },
      {
        lat: 13.017987688565562,
        lng: 80.17073179573688,
      },
      {
        lat: 13.017662525933545,
        lng: 80.17183403641812,
      },
      {
        lat: 13.02127043155536,
        lng: 80.15574283675785,
      },
      {
        lat: 13.020628681900188,
        lng: 80.16679583237963,
      },
      {
        lat: 13.017795879495248,
        lng: 80.15239524432536,
      },
      {
        lat: 13.018148148033045,
        lng: 80.15976157661554,
      },
      {
        lat: 13.021268292868339,
        lng: 80.15935088355619,
      },
      {
        lat: 13.021544291258076,
        lng: 80.16220190434629,
      },
      {
        lat: 13.015374945757252,
        lng: 80.15695960687606,
      },
      {
        lat: 13.024605438300508,
        lng: 80.14561750477999,
      },
      {
        lat: 13.021510420859299,
        lng: 80.14710949150151,
      },
      {
        lat: 13.017132723448505,
        lng: 80.16350202945151,
      },
      {
        lat: 13.024772048413308,
        lng: 80.15011319686165,
      },
      {
        lat: 13.017339213846725,
        lng: 80.16492584080164,
      },
      {
        lat: 13.015627702742878,
        lng: 80.16320958146292,
      },
      {
        lat: 13.02507175537628,
        lng: 80.15372648324811,
      },
      {
        lat: 13.017116158975988,
        lng: 80.1694266040061,
      },
      {
        lat: 13.019051685197315,
        lng: 80.158424036803,
      },
      {
        lat: 13.01726114795469,
        lng: 80.1648860545698,
      },
      {
        lat: 13.018577711430662,
        lng: 80.17109233951136,
      },
      {
        lat: 13.022065097691602,
        lng: 80.17161216471987,
      },
      {
        lat: 13.017277682915646,
        lng: 80.15715245053497,
      },
      {
        lat: 13.02309086582678,
        lng: 80.16502668039648,
      },
      {
        lat: 13.017213223735416,
        lng: 80.16196574249277,
      },
      {
        lat: 13.01844746993654,
        lng: 80.16773069024036,
      },
      {
        lat: 13.022378051046173,
        lng: 80.14660909209019,
      },
      {
        lat: 13.018416386167567,
        lng: 80.16050421616637,
      },
      {
        lat: 13.022985256958354,
        lng: 80.1462295996166,
      },
      {
        lat: 13.02482861789639,
        lng: 80.15145613187292,
      },
      {
        lat: 13.02157032617983,
        lng: 80.1552317359524,
      },
      {
        lat: 13.019371873453668,
        lng: 80.15641473990883,
      },
      {
        lat: 13.019270857910481,
        lng: 80.15023428659045,
      },
      {
        lat: 13.015528449863405,
        lng: 80.15844347434339,
      },
      {
        lat: 13.023107997818954,
        lng: 80.1739889951704,
      },
      {
        lat: 13.022377358447098,
        lng: 80.15426114044733,
      },
      {
        lat: 13.02243142233085,
        lng: 80.14889921420128,
      },
      {
        lat: 13.018688771355004,
        lng: 80.15791269579061,
      },
      {
        lat: 13.017958566766845,
        lng: 80.16555786380322,
      },
      {
        lat: 13.014293328412654,
        lng: 80.15483756887197,
      },
      {
        lat: 13.02406110161746,
        lng: 80.16453077226647,
      },
      {
        lat: 13.018908264884448,
        lng: 80.16462073317489,
      },
      {
        lat: 13.021672879941656,
        lng: 80.16425998797526,
      },
      {
        lat: 13.017602632768986,
        lng: 80.16605487520036,
      },
      {
        lat: 13.02193050915368,
        lng: 80.15894904779013,
      },
      {
        lat: 13.022448902608383,
        lng: 80.14688276974177,
      },
      {
        lat: 13.017701876941798,
        lng: 80.15800236086638,
      },
      {
        lat: 13.01837410125095,
        lng: 80.16426468904206,
      },
      {
        lat: 13.021560623164136,
        lng: 80.16355836568198,
      },
      {
        lat: 13.019263679508748,
        lng: 80.16108675195974,
      },
      {
        lat: 13.024764751051514,
        lng: 80.17491309990086,
      },
      {
        lat: 13.021501648119452,
        lng: 80.16762586388019,
      },
      {
        lat: 13.022445203911106,
        lng: 80.15029063584444,
      },
      {
        lat: 13.018732882364539,
        lng: 80.15610713454528,
      },
      {
        lat: 13.01939056936968,
        lng: 80.15508952710219,
      },
      {
        lat: 13.016761923196462,
        lng: 80.1555524308793,
      },
      {
        lat: 13.016107542221038,
        lng: 80.16904702845397,
      },
      {
        lat: 13.024476788478076,
        lng: 80.15105333402316,
      },
      {
        lat: 13.014469776357917,
        lng: 80.15753918964037,
      },
      {
        lat: 13.021305304271662,
        lng: 80.16223960321328,
      },
      {
        lat: 13.019834615881804,
        lng: 80.15569005334513,
      },
      {
        lat: 13.017336464727082,
        lng: 80.15426227497615,
      },
      {
        lat: 13.024754958265623,
        lng: 80.15447455853145,
      },
      {
        lat: 13.02007482674181,
        lng: 80.16382415304075,
      },
      {
        lat: 13.01874584325969,
        lng: 80.15393361614765,
      },
      {
        lat: 13.02031436722157,
        lng: 80.15400666128626,
      },
      {
        lat: 13.022959442041984,
        lng: 80.17345811501907,
      },
      {
        lat: 13.02277669162656,
        lng: 80.15681002748809,
      },
      {
        lat: 13.013523145237755,
        lng: 80.1569363781099,
      },
      {
        lat: 13.02131679249523,
        lng: 80.1656687498527,
      },
      {
        lat: 13.021662857374409,
        lng: 80.15857216976146,
      },
      {
        lat: 13.017778779382883,
        lng: 80.1726910788368,
      },
      {
        lat: 13.019321913851346,
        lng: 80.15030549659465,
      },
      {
        lat: 13.02089800474323,
        lng: 80.16259786706536,
      },
      {
        lat: 13.022542468908185,
        lng: 80.1531332189948,
      },
      {
        lat: 13.020477291700024,
        lng: 80.15195224808635,
      },
      {
        lat: 13.02034474132776,
        lng: 80.1685848201378,
      },
      {
        lat: 13.018230832335755,
        lng: 80.16542913476512,
      },
      {
        lat: 13.014113356762692,
        lng: 80.15541917453645,
      },
      {
        lat: 13.022845819678148,
        lng: 80.16947685153727,
      },
      {
        lat: 13.02460099798519,
        lng: 80.1703850156727,
      },
      {
        lat: 13.01714081621366,
        lng: 80.15992265609219,
      },
      {
        lat: 13.018384803233772,
        lng: 80.16300364747629,
      },
      {
        lat: 13.018589274419158,
        lng: 80.16730553843558,
      },
      {
        lat: 13.020031693306445,
        lng: 80.17226028197027,
      },
      {
        lat: 13.020681906507747,
        lng: 80.17299098066172,
      },
      {
        lat: 13.022971799802592,
        lng: 80.16139272769043,
      },
      {
        lat: 13.018237954291214,
        lng: 80.1634935396208,
      },
      {
        lat: 13.021626425199047,
        lng: 80.16141008334857,
      },
      {
        lat: 13.02280509899756,
        lng: 80.14909017019004,
      },
      {
        lat: 13.021020219921837,
        lng: 80.16976593967739,
      },
      {
        lat: 13.020933060424055,
        lng: 80.16416922160663,
      },
      {
        lat: 13.02014542576091,
        lng: 80.16572344548293,
      },
      {
        lat: 13.015875847102079,
        lng: 80.16071508938815,
      },
      {
        lat: 13.01623654266654,
        lng: 80.17214424894597,
      },
      {
        lat: 13.02247221721864,
        lng: 80.17480641389571,
      },
      {
        lat: 13.018535986848008,
        lng: 80.1530764648074,
      },
      {
        lat: 13.022616943668229,
        lng: 80.16825789409374,
      },
      {
        lat: 13.016315325122427,
        lng: 80.15577188194419,
      },
      {
        lat: 13.02358714427233,
        lng: 80.14565121066266,
      },
      {
        lat: 13.018418333624822,
        lng: 80.15783474635285,
      },
      {
        lat: 13.021863079773805,
        lng: 80.15312852309455,
      },
      {
        lat: 13.02387229218961,
        lng: 80.1596319346154,
      },
      {
        lat: 13.022786627046049,
        lng: 80.15322182642328,
      },
      {
        lat: 13.019983605819508,
        lng: 80.14839849405251,
      },
      {
        lat: 13.024385425381174,
        lng: 80.14698629497481,
      },
      {
        lat: 13.018232646200596,
        lng: 80.14918936304537,
      },
      {
        lat: 13.022075626982884,
        lng: 80.1570068318398,
      },
      {
        lat: 13.022726026160708,
        lng: 80.15716222560097,
      },
      {
        lat: 13.023107403062898,
        lng: 80.15679963021617,
      },
      {
        lat: 13.01866133238249,
        lng: 80.1505592171495,
      },
      {
        lat: 13.01987112903693,
        lng: 80.15415387623314,
      },
      {
        lat: 13.02039306615746,
        lng: 80.158518474722,
      },
      {
        lat: 13.022887609756546,
        lng: 80.15786729112901,
      },
      {
        lat: 13.018971106425596,
        lng: 80.17056089000499,
      },
      {
        lat: 13.025238146815243,
        lng: 80.15226265360164,
      },
      {
        lat: 13.024873183378833,
        lng: 80.15474402386828,
      },
      {
        lat: 13.020557986205375,
        lng: 80.158530704352,
      },
      {
        lat: 13.022552553212916,
        lng: 80.14808733205426,
      },
      {
        lat: 13.022912781689351,
        lng: 80.16736212730905,
      },
      {
        lat: 13.02105174673517,
        lng: 80.14911999860776,
      },
      {
        lat: 13.018416686588303,
        lng: 80.17145044465599,
      },
      {
        lat: 13.02011352854389,
        lng: 80.17227276924848,
      },
      {
        lat: 13.02492659108497,
        lng: 80.15545962488073,
      },
      {
        lat: 13.019655791755367,
        lng: 80.16041627094924,
      },
    ],
    cases: 147,
    coords: [
      {
        lat: 13.023864246879151,
        lng: 80.17708691556763,
      },
      {
        lat: 13.023515134056622,
        lng: 80.17700033273469,
      },
      {
        lat: 13.023126560207237,
        lng: 80.17676962214662,
      },
      {
        lat: 13.019889306063058,
        lng: 80.17591116579348,
      },
      {
        lat: 13.019164079470132,
        lng: 80.17568330438483,
      },
      {
        lat: 13.018842124571977,
        lng: 80.17533503038838,
      },
      {
        lat: 13.018487012683435,
        lng: 80.17507060814717,
      },
      {
        lat: 13.017675426317933,
        lng: 80.17457057095237,
      },
      {
        lat: 13.017427228898235,
        lng: 80.17451587724757,
      },
      {
        lat: 13.017368190014755,
        lng: 80.1742134741681,
      },
      {
        lat: 13.017099660496754,
        lng: 80.17395070690466,
      },
      {
        lat: 13.016887751883619,
        lng: 80.17359280333743,
      },
      {
        lat: 13.016770595251547,
        lng: 80.17313944075302,
      },
      {
        lat: 13.016652056917188,
        lng: 80.17245891280676,
      },
      {
        lat: 13.015965467264847,
        lng: 80.1724268607505,
      },
      {
        lat: 13.015793416855493,
        lng: 80.17235259117247,
      },
      {
        lat: 13.01581075526582,
        lng: 80.1720684731133,
      },
      {
        lat: 13.01601494937576,
        lng: 80.1711580367431,
      },
      {
        lat: 13.016142420766332,
        lng: 80.17017252401169,
      },
      {
        lat: 13.01591006491305,
        lng: 80.16958761643909,
      },
      {
        lat: 13.015638771990922,
        lng: 80.16887051845183,
      },
      {
        lat: 13.015409524967462,
        lng: 80.1687967329437,
      },
      {
        lat: 13.015332341680617,
        lng: 80.16864593479522,
      },
      {
        lat: 13.015444316930116,
        lng: 80.16824742727233,
      },
      {
        lat: 13.015385508330318,
        lng: 80.16798288508659,
      },
      {
        lat: 13.015194392382837,
        lng: 80.16790877686506,
      },
      {
        lat: 13.014964454508169,
        lng: 80.16772140867597,
      },
      {
        lat: 13.014905415624868,
        lng: 80.1674190055964,
      },
      {
        lat: 13.0149987859037,
        lng: 80.16709638121729,
      },
      {
        lat: 13.015130402400292,
        lng: 80.16679236457189,
      },
      {
        lat: 13.015282120711571,
        lng: 80.16665856059136,
      },
      {
        lat: 13.01537641212506,
        lng: 80.16648737978691,
      },
      {
        lat: 13.015291168909673,
        lng: 80.16501145036041,
      },
      {
        lat: 13.015156904150727,
        lng: 80.16488006672883,
      },
      {
        lat: 13.014907900737992,
        lng: 80.16469285989629,
      },
      {
        lat: 13.014867581967078,
        lng: 80.1643335041197,
      },
      {
        lat: 13.014904561624665,
        lng: 80.1641438769383,
      },
      {
        lat: 13.015074654622984,
        lng: 80.16389632892015,
      },
      {
        lat: 13.015034335852073,
        lng: 80.16353697314352,
      },
      {
        lat: 13.014899955951115,
        lng: 80.16338665906505,
      },
      {
        lat: 13.014536674452275,
        lng: 80.16321935081876,
      },
      {
        lat: 13.014288016465223,
        lng: 80.16308893532674,
      },
      {
        lat: 13.014095633957515,
        lng: 80.16280659219008,
      },
      {
        lat: 13.01413146219672,
        lng: 80.16242766054039,
      },
      {
        lat: 13.014225753610207,
        lng: 80.16225647973587,
      },
      {
        lat: 13.014415718139471,
        lng: 80.16214128348913,
      },
      {
        lat: 13.014529535658275,
        lng: 80.16204566311545,
      },
      {
        lat: 13.014528384239883,
        lng: 80.16185635864696,
      },
      {
        lat: 13.014470496774743,
        lng: 80.16174326003592,
      },
      {
        lat: 13.014261119052469,
        lng: 80.16157985958128,
      },
      {
        lat: 13.0145526934536,
        lng: 80.1614407573276,
      },
      {
        lat: 13.014705847837867,
        lng: 80.1611003866281,
      },
      {
        lat: 13.014639636641101,
        lng: 80.16042279796194,
      },
      {
        lat: 13.014606343563875,
        lng: 80.16005318025992,
      },
      {
        lat: 13.015038700443235,
        lng: 80.15967962164136,
      },
      {
        lat: 13.015160811497111,
        lng: 80.15933951366964,
      },
      {
        lat: 13.015033263555052,
        lng: 80.15878574393565,
      },
      {
        lat: 13.014440253135142,
        lng: 80.15826673848728,
      },
      {
        lat: 13.013785718491041,
        lng: 80.15784072860203,
      },
      {
        lat: 13.013503703812404,
        lng: 80.15741156598355,
      },
      {
        lat: 13.013125746958812,
        lng: 80.15652084101085,
      },
      {
        lat: 13.013091141529264,
        lng: 80.15593545972477,
      },
      {
        lat: 13.013459724437771,
        lng: 80.1552850162393,
      },
      {
        lat: 13.014631121923115,
        lng: 80.15391880434152,
      },
      {
        lat: 13.015280969594473,
        lng: 80.15357422999784,
      },
      {
        lat: 13.015962610512121,
        lng: 80.15335268640298,
      },
      {
        lat: 13.016116702290905,
        lng: 80.15316643254938,
      },
      {
        lat: 13.016175601810454,
        lng: 80.15264190981816,
      },
      {
        lat: 13.016515016177427,
        lng: 80.1522999627519,
      },
      {
        lat: 13.017006835013218,
        lng: 80.15149435150973,
      },
      {
        lat: 13.0174025241951,
        lng: 80.15019635454411,
      },
      {
        lat: 13.017521260628778,
        lng: 80.1493014259275,
      },
      {
        lat: 13.01742625584828,
        lng: 80.1489939804192,
      },
      {
        lat: 13.01695929353926,
        lng: 80.14878215775146,
      },
      {
        lat: 13.016954231608882,
        lng: 80.14794992678412,
      },
      {
        lat: 13.017045486811272,
        lng: 80.14764090490932,
      },
      {
        lat: 13.017324126869655,
        lng: 80.14751524688285,
      },
      {
        lat: 13.018441499286917,
        lng: 80.14747496531434,
      },
      {
        lat: 13.019962809957844,
        lng: 80.14749291502359,
      },
      {
        lat: 13.0208623166253,
        lng: 80.14736200244201,
      },
      {
        lat: 13.021077370191563,
        lng: 80.14699028291776,
      },
      {
        lat: 13.02132196725694,
        lng: 80.14637171371255,
      },
      {
        lat: 13.021407598092294,
        lng: 80.14513799076282,
      },
      {
        lat: 13.021530271582865,
        lng: 80.1448903528985,
      },
      {
        lat: 13.021871560738866,
        lng: 80.14485663952392,
      },
      {
        lat: 13.02308356297882,
        lng: 80.14506215672567,
      },
      {
        lat: 13.023426164486967,
        lng: 80.14524420693542,
      },
      {
        lat: 13.023737722664949,
        lng: 80.14542651987277,
      },
      {
        lat: 13.024110430109316,
        lng: 80.14545419050881,
      },
      {
        lat: 13.02462504679949,
        lng: 80.1452608803428,
      },
      {
        lat: 13.024839838289395,
        lng: 80.1459334264501,
      },
      {
        lat: 13.024932973005,
        lng: 80.1465110496863,
      },
      {
        lat: 13.024822034458463,
        lng: 80.14668996134459,
      },
      {
        lat: 13.024465732182367,
        lng: 80.14704892233682,
      },
      {
        lat: 13.024357770343272,
        lng: 80.14771723388382,
      },
      {
        lat: 13.024405826054737,
        lng: 80.14825074544605,
      },
      {
        lat: 13.0251955204549,
        lng: 80.14915617243601,
      },
      {
        lat: 13.025466806308522,
        lng: 80.1495543151762,
      },
      {
        lat: 13.025535777863531,
        lng: 80.14984293718175,
      },
      {
        lat: 13.025337116796795,
        lng: 80.15033404358309,
      },
      {
        lat: 13.025250070801077,
        lng: 80.1507574655732,
      },
      {
        lat: 13.025319448270576,
        lng: 80.15111282392733,
      },
      {
        lat: 13.025724347477802,
        lng: 80.15137635629533,
      },
      {
        lat: 13.025838127426834,
        lng: 80.1516645990759,
      },
      {
        lat: 13.025772809103932,
        lng: 80.15197660420624,
      },
      {
        lat: 13.025112725324666,
        lng: 80.15396213758535,
      },
      {
        lat: 13.025027167682701,
        lng: 80.1546302595198,
      },
      {
        lat: 13.025075629308995,
        lng: 80.1552305074306,
      },
      {
        lat: 13.025057013648576,
        lng: 80.15585356962842,
      },
      {
        lat: 13.024770359452088,
        lng: 80.15661237987358,
      },
      {
        lat: 13.024485058304293,
        lng: 80.15759364461341,
      },
      {
        lat: 13.024420281200758,
        lng: 80.15799463154161,
      },
      {
        lat: 13.024444309056522,
        lng: 80.15826138732263,
      },
      {
        lat: 13.02437966725787,
        lng: 80.15868461970038,
      },
      {
        lat: 13.024359292634099,
        lng: 80.159018491055,
      },
      {
        lat: 13.024428399493942,
        lng: 80.15932935851004,
      },
      {
        lat: 13.024541773528485,
        lng: 80.1595508649421,
      },
      {
        lat: 13.024565124859812,
        lng: 80.159706393476,
      },
      {
        lat: 13.024409107309257,
        lng: 80.15984119346045,
      },
      {
        lat: 13.02409531324451,
        lng: 80.15982160258648,
      },
      {
        lat: 13.023624825104552,
        lng: 80.15982558444965,
      },
      {
        lat: 13.023335058895562,
        lng: 80.16007274935684,
      },
      {
        lat: 13.023159613855208,
        lng: 80.1606971388424,
      },
      {
        lat: 13.023096460410537,
        lng: 80.16136507116413,
      },
      {
        lat: 13.023099301813152,
        lng: 80.16183222560312,
      },
      {
        lat: 13.023146275085532,
        lng: 80.1621877735698,
      },
      {
        lat: 13.023528905400399,
        lng: 80.16247374099997,
      },
      {
        lat: 13.024112361660187,
        lng: 80.16262452922038,
      },
      {
        lat: 13.024493233011311,
        lng: 80.16262130580738,
      },
      {
        lat: 13.02460674235091,
        lng: 80.16286505768905,
      },
      {
        lat: 13.024541965247437,
        lng: 80.16326604461726,
      },
      {
        lat: 13.02459245644685,
        lng: 80.16419997427008,
      },
      {
        lat: 13.024394201294777,
        lng: 80.16475781701983,
      },
      {
        lat: 13.024329830106069,
        lng: 80.16522554029629,
      },
      {
        lat: 13.02421889155936,
        lng: 80.16540445195477,
      },
      {
        lat: 13.02397258069582,
        lng: 80.16542878314203,
      },
      {
        lat: 13.023389530350686,
        lng: 80.16534473126994,
      },
      {
        lat: 13.023008388389744,
        lng: 80.16530346378406,
      },
      {
        lat: 13.022516849101404,
        lng: 80.16553008975443,
      },
      {
        lat: 13.02215919377667,
        lng: 80.16566659625191,
      },
      {
        lat: 13.02200141726242,
        lng: 80.16551220539323,
      },
      {
        lat: 13.02173256689692,
        lng: 80.16551448074364,
      },
      {
        lat: 13.02160030659218,
        lng: 80.16587154561044,
      },
      {
        lat: 13.021535258879048,
        lng: 80.16622804163951,
      },
      {
        lat: 13.021760247984616,
        lng: 80.16638186366066,
      },
      {
        lat: 13.022455860534913,
        lng: 80.16655394926786,
      },
      {
        lat: 13.02299532022966,
        lng: 80.16683858941052,
      },
      {
        lat: 13.023445974965282,
        lng: 80.16725746069991,
      },
      {
        lat: 13.023850738867502,
        lng: 80.16749874761855,
      },
      {
        lat: 13.025060700817699,
        lng: 80.16751075399134,
      },
      {
        lat: 13.025307282291015,
        lng: 80.16753091370308,
      },
      {
        lat: 13.025442925217911,
        lng: 80.16772998507322,
      },
      {
        lat: 13.025244805370548,
        lng: 80.16831007327221,
      },
      {
        lat: 13.025224024832115,
        lng: 80.16857720827848,
      },
      {
        lat: 13.02518273436497,
        lng: 80.16915596918999,
      },
      {
        lat: 13.02480930478237,
        lng: 80.17038269232411,
      },
      {
        lat: 13.024683268502574,
        lng: 80.17176304786673,
      },
      {
        lat: 13.024603258360946,
        lng: 80.17334323322973,
      },
      {
        lat: 13.02492232931626,
        lng: 80.17423039663335,
      },
      {
        lat: 13.024925441328644,
        lng: 80.17474204197127,
      },
      {
        lat: 13.02485998770068,
        lng: 80.17503180165208,
      },
      {
        lat: 13.024436337528426,
        lng: 80.17536908603223,
      },
      {
        lat: 13.023990553768895,
        lng: 80.17575105092396,
      },
      {
        lat: 13.023747896137108,
        lng: 80.17637600924708,
      },
      {
        lat: 13.023571368657725,
        lng: 80.17682243513693,
      },
      {
        lat: 13.023864246879151,
        lng: 80.17708691556763,
      },
    ],
    properties: {
      Ward_No: 156,
      Zone_Name: "ALANDUR",
      fillColor: "#de4307",
      zname: "ALANDUR(156)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.015644078080745,
        lng: 80.18792238536346,
      },
      {
        lat: 13.009853609100062,
        lng: 80.18709285393771,
      },
      {
        lat: 13.009222343622563,
        lng: 80.1867636766447,
      },
      {
        lat: 13.014695758065454,
        lng: 80.18354414047597,
      },
      {
        lat: 13.012168519200277,
        lng: 80.18957709652307,
      },
      {
        lat: 13.01737107391803,
        lng: 80.19290766750635,
      },
      {
        lat: 13.017287145509584,
        lng: 80.18738075545356,
      },
      {
        lat: 13.014899604814088,
        lng: 80.1897315769939,
      },
      {
        lat: 13.018771333206963,
        lng: 80.19306831565262,
      },
      {
        lat: 13.019966708353284,
        lng: 80.19339840300016,
      },
      {
        lat: 13.010120953463339,
        lng: 80.18849757034852,
      },
      {
        lat: 13.018813947538153,
        lng: 80.1897617403599,
      },
      {
        lat: 13.024626466594361,
        lng: 80.19024934784844,
      },
      {
        lat: 13.013248050960721,
        lng: 80.18461322968695,
      },
      {
        lat: 13.013200688197447,
        lng: 80.19077916978618,
      },
      {
        lat: 13.013589335629582,
        lng: 80.18453737813661,
      },
      {
        lat: 13.02501029552693,
        lng: 80.19357104712459,
      },
      {
        lat: 13.008345162671072,
        lng: 80.18341647945162,
      },
      {
        lat: 13.01290888962916,
        lng: 80.18841672421935,
      },
      {
        lat: 13.022396551947308,
        lng: 80.193672989631,
      },
      {
        lat: 13.01338279619651,
        lng: 80.19296291601972,
      },
      {
        lat: 13.009773880196855,
        lng: 80.18877429196407,
      },
      {
        lat: 13.012940554011823,
        lng: 80.19327856380416,
      },
      {
        lat: 13.022092378882286,
        lng: 80.18997423814687,
      },
      {
        lat: 13.015083873561517,
        lng: 80.19366423535172,
      },
      {
        lat: 13.011450000747429,
        lng: 80.18563702000807,
      },
      {
        lat: 13.010519490382613,
        lng: 80.19022172927652,
      },
      {
        lat: 13.011171845360963,
        lng: 80.19111289056387,
      },
      {
        lat: 13.013750547113341,
        lng: 80.18715647327332,
      },
      {
        lat: 13.014522759911866,
        lng: 80.18739671839748,
      },
      {
        lat: 13.009238348839377,
        lng: 80.18437193943033,
      },
      {
        lat: 13.012354863363113,
        lng: 80.19088942643381,
      },
      {
        lat: 13.021472526390896,
        lng: 80.19262803372283,
      },
      {
        lat: 13.022270837727175,
        lng: 80.18945617099816,
      },
      {
        lat: 13.009857825799955,
        lng: 80.1874554900593,
      },
      {
        lat: 13.021630186496854,
        lng: 80.19351982905967,
      },
      {
        lat: 13.011167771156993,
        lng: 80.18658704295052,
      },
      {
        lat: 13.015644229566684,
        lng: 80.18623220257557,
      },
      {
        lat: 13.019441999037825,
        lng: 80.18844283574407,
      },
      {
        lat: 13.01441644323642,
        lng: 80.19133817897364,
      },
      {
        lat: 13.02021446968469,
        lng: 80.19251319830789,
      },
      {
        lat: 13.013165225729178,
        lng: 80.18566647399139,
      },
      {
        lat: 13.017607878142888,
        lng: 80.19068194181766,
      },
      {
        lat: 13.008236284041331,
        lng: 80.183591246088,
      },
      {
        lat: 13.014393807339218,
        lng: 80.1883968356721,
      },
      {
        lat: 13.015879608672346,
        lng: 80.18375879412277,
      },
      {
        lat: 13.02228440173664,
        lng: 80.19018130709134,
      },
      {
        lat: 13.01385096990695,
        lng: 80.1898988020051,
      },
      {
        lat: 13.016691857265647,
        lng: 80.19251042757394,
      },
      {
        lat: 13.016025678710442,
        lng: 80.19325439572212,
      },
      {
        lat: 13.024590542470893,
        lng: 80.19421709508777,
      },
      {
        lat: 13.016369321976118,
        lng: 80.1836668837034,
      },
      {
        lat: 13.009880558145177,
        lng: 80.19084827256498,
      },
      {
        lat: 13.006387932875503,
        lng: 80.1816369049592,
      },
      {
        lat: 13.027537335276358,
        lng: 80.19368227115812,
      },
      {
        lat: 13.016765562301238,
        lng: 80.18189827851313,
      },
      {
        lat: 13.00775454752428,
        lng: 80.18136892619974,
      },
      {
        lat: 13.010215308826414,
        lng: 80.18532945258036,
      },
      {
        lat: 13.013443496099427,
        lng: 80.19171106588287,
      },
      {
        lat: 13.010014498727502,
        lng: 80.18866275849204,
      },
      {
        lat: 13.014377911905054,
        lng: 80.1935271296092,
      },
      {
        lat: 13.009948825324011,
        lng: 80.19100464825456,
      },
      {
        lat: 13.017556128962928,
        lng: 80.18290719051204,
      },
      {
        lat: 13.00966624641105,
        lng: 80.19300656314738,
      },
      {
        lat: 13.024776090193514,
        lng: 80.19133534328374,
      },
      {
        lat: 13.013185814241329,
        lng: 80.19119846880615,
      },
      {
        lat: 13.02464543074482,
        lng: 80.19168432829886,
      },
      {
        lat: 13.014568256355949,
        lng: 80.18754939890879,
      },
      {
        lat: 13.011418610046004,
        lng: 80.18866617799902,
      },
      {
        lat: 13.012338175364269,
        lng: 80.18730952378958,
      },
      {
        lat: 13.013999938324558,
        lng: 80.18590526929236,
      },
      {
        lat: 13.009368905043848,
        lng: 80.19060741656271,
      },
      {
        lat: 13.010193086598328,
        lng: 80.18325933029205,
      },
      {
        lat: 13.008734305397317,
        lng: 80.18186977699004,
      },
      {
        lat: 13.00529200928211,
        lng: 80.18213446683374,
      },
      {
        lat: 13.01157442715165,
        lng: 80.19304496708872,
      },
      {
        lat: 13.013119977395466,
        lng: 80.19399931157079,
      },
      {
        lat: 13.015753629594231,
        lng: 80.18910273610796,
      },
      {
        lat: 13.012743466534115,
        lng: 80.18985854438958,
      },
      {
        lat: 13.011790514453635,
        lng: 80.18298808244782,
      },
      {
        lat: 13.016957707508398,
        lng: 80.1822701145067,
      },
      {
        lat: 13.021849770391668,
        lng: 80.1892707262066,
      },
      {
        lat: 13.018814609900398,
        lng: 80.18407160117697,
      },
      {
        lat: 13.014653728034281,
        lng: 80.19052556990032,
      },
      {
        lat: 13.011954652858273,
        lng: 80.19307744060528,
      },
      {
        lat: 13.02149787165771,
        lng: 80.19409120942757,
      },
      {
        lat: 13.017773794264446,
        lng: 80.18495526793917,
      },
      {
        lat: 13.015859597751271,
        lng: 80.18280772930666,
      },
      {
        lat: 13.022992748955824,
        lng: 80.19338139216433,
      },
      {
        lat: 13.01932795749077,
        lng: 80.19123647071923,
      },
      {
        lat: 13.011443280225244,
        lng: 80.18673376542785,
      },
      {
        lat: 13.010120089366398,
        lng: 80.18482023384672,
      },
      {
        lat: 13.018953818974225,
        lng: 80.1930525691903,
      },
      {
        lat: 13.013011045725172,
        lng: 80.18633466665968,
      },
      {
        lat: 13.01551130187029,
        lng: 80.19214545486369,
      },
      {
        lat: 13.014893866916173,
        lng: 80.18437739473616,
      },
      {
        lat: 13.021768616460646,
        lng: 80.19220480607837,
      },
      {
        lat: 13.016516767824742,
        lng: 80.18984683199122,
      },
      {
        lat: 13.014573084660721,
        lng: 80.18543961321268,
      },
      {
        lat: 13.018529321939521,
        lng: 80.19313482514555,
      },
      {
        lat: 13.014410418636688,
        lng: 80.18217992651638,
      },
      {
        lat: 13.011969086116101,
        lng: 80.19202617923875,
      },
      {
        lat: 13.011237232571329,
        lng: 80.18376278864831,
      },
      {
        lat: 13.021334834038594,
        lng: 80.18977737543892,
      },
      {
        lat: 13.014221307936474,
        lng: 80.19375213810572,
      },
      {
        lat: 13.022652152527211,
        lng: 80.19007350644117,
      },
      {
        lat: 13.0150664927113,
        lng: 80.18215619789265,
      },
      {
        lat: 13.013204333355059,
        lng: 80.18387884606447,
      },
      {
        lat: 13.019457593182167,
        lng: 80.19335624221078,
      },
    ],
    cases: 109,
    coords: [
      {
        lat: 13.005031323930721,
        lng: 80.18202910453596,
      },
      {
        lat: 13.006430753541947,
        lng: 80.18014411218533,
      },
      {
        lat: 13.00900087907954,
        lng: 80.18109358403181,
      },
      {
        lat: 13.010246368809485,
        lng: 80.18158177951345,
      },
      {
        lat: 13.011675787689734,
        lng: 80.18188467337163,
      },
      {
        lat: 13.01302510259583,
        lng: 80.18205699875324,
      },
      {
        lat: 13.016672208144172,
        lng: 80.18186863665288,
      },
      {
        lat: 13.017148681104254,
        lng: 80.18196960127219,
      },
      {
        lat: 13.0178651466883,
        lng: 80.18240977545577,
      },
      {
        lat: 13.018414788979948,
        lng: 80.18309096307502,
      },
      {
        lat: 13.019089456719831,
        lng: 80.18468636488161,
      },
      {
        lat: 13.019073937791246,
        lng: 80.1848680655898,
      },
      {
        lat: 13.018809371401884,
        lng: 80.18510139296956,
      },
      {
        lat: 13.018047613620205,
        lng: 80.185586522801,
      },
      {
        lat: 13.0179987478144,
        lng: 80.1857519994263,
      },
      {
        lat: 13.018265422439303,
        lng: 80.18586528663278,
      },
      {
        lat: 13.018500256069734,
        lng: 80.18620993160341,
      },
      {
        lat: 13.018988152847696,
        lng: 80.187163168162,
      },
      {
        lat: 13.019188836463679,
        lng: 80.18735954539824,
      },
      {
        lat: 13.019639370677673,
        lng: 80.18763633961436,
      },
      {
        lat: 13.021059273348966,
        lng: 80.18876325773304,
      },
      {
        lat: 13.02197606149005,
        lng: 80.1891681563705,
      },
      {
        lat: 13.022576004102177,
        lng: 80.18941067349249,
      },
      {
        lat: 13.023060861609679,
        lng: 80.18954132584541,
      },
      {
        lat: 13.023881884823659,
        lng: 80.18988286721111,
      },
      {
        lat: 13.024442888757926,
        lng: 80.18990280705566,
      },
      {
        lat: 13.024734749858307,
        lng: 80.19039205985517,
      },
      {
        lat: 13.02606277704568,
        lng: 80.19161012765561,
      },
      {
        lat: 13.027724430982731,
        lng: 80.19339904859817,
      },
      {
        lat: 13.027287658263978,
        lng: 80.19452429974481,
      },
      {
        lat: 13.02705390692634,
        lng: 80.19468164157192,
      },
      {
        lat: 13.026976147301845,
        lng: 80.1947599814362,
      },
      {
        lat: 13.024140612032507,
        lng: 80.19439997944944,
      },
      {
        lat: 13.018258314606951,
        lng: 80.19383236778985,
      },
      {
        lat: 13.01608041660022,
        lng: 80.19357016578368,
      },
      {
        lat: 13.014950951775951,
        lng: 80.19374810521047,
      },
      {
        lat: 13.013795955657319,
        lng: 80.19437527531034,
      },
      {
        lat: 13.012184838259442,
        lng: 80.19436084720245,
      },
      {
        lat: 13.010713154463192,
        lng: 80.1940365414977,
      },
      {
        lat: 13.009100159551792,
        lng: 80.19371343174576,
      },
      {
        lat: 13.008760159459566,
        lng: 80.19357599219379,
      },
      {
        lat: 13.008674348692141,
        lng: 80.19340833795947,
      },
      {
        lat: 13.009060632246728,
        lng: 80.19186158107118,
      },
      {
        lat: 13.009499173171166,
        lng: 80.18961279661046,
      },
      {
        lat: 13.009552625322305,
        lng: 80.18910720281174,
      },
      {
        lat: 13.009408412595057,
        lng: 80.18863134531425,
      },
      {
        lat: 13.008182042604362,
        lng: 80.18681760260928,
      },
      {
        lat: 13.00818050645684,
        lng: 80.18656504490032,
      },
      {
        lat: 13.00832079347381,
        lng: 80.18639547714184,
      },
      {
        lat: 13.00834683681712,
        lng: 80.18603043237184,
      },
      {
        lat: 13.00809008724668,
        lng: 80.18563971753944,
      },
      {
        lat: 13.007692879976197,
        lng: 80.1853905084978,
      },
      {
        lat: 13.007491801436654,
        lng: 80.18485900544613,
      },
      {
        lat: 13.007003833589838,
        lng: 80.18362834510825,
      },
      {
        lat: 13.00558372443109,
        lng: 80.18248976396067,
      },
      {
        lat: 13.005186175794497,
        lng: 80.18218443098375,
      },
      {
        lat: 13.005031323930721,
        lng: 80.18202910453596,
      },
    ],
    properties: {
      Ward_No: 158,
      Zone_Name: "ALANDUR",
      fillColor: "#f6d04d",
      zname: "ALANDUR(158)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.975609357247057,
        lng: 80.18385163081719,
      },
      {
        lat: 12.975741140381945,
        lng: 80.18615993117278,
      },
      {
        lat: 12.98266198169125,
        lng: 80.1878727318385,
      },
      {
        lat: 12.97140413729146,
        lng: 80.18495181720888,
      },
      {
        lat: 12.977357087712184,
        lng: 80.183602100739,
      },
      {
        lat: 12.977880186045354,
        lng: 80.18677084334466,
      },
      {
        lat: 12.976237051746944,
        lng: 80.18785942579417,
      },
      {
        lat: 12.974548378762156,
        lng: 80.18348448759207,
      },
      {
        lat: 12.972088399640441,
        lng: 80.18379470180297,
      },
      {
        lat: 12.976402751611811,
        lng: 80.1840836069587,
      },
      {
        lat: 12.970106889643946,
        lng: 80.1903877930994,
      },
      {
        lat: 12.981810816740575,
        lng: 80.18739341642306,
      },
      {
        lat: 12.984836906572074,
        lng: 80.18688298441492,
      },
      {
        lat: 12.969636487627854,
        lng: 80.18779224682478,
      },
      {
        lat: 12.986286759330278,
        lng: 80.18648544049512,
      },
      {
        lat: 12.975460463623701,
        lng: 80.18935938193351,
      },
      {
        lat: 12.97065843016434,
        lng: 80.18462575414063,
      },
      {
        lat: 12.974657051767911,
        lng: 80.18434017548469,
      },
      {
        lat: 12.970618271841348,
        lng: 80.18781363224531,
      },
      {
        lat: 12.974193447081284,
        lng: 80.18710082784182,
      },
      {
        lat: 12.979655777282067,
        lng: 80.18344285374178,
      },
      {
        lat: 12.969126810206774,
        lng: 80.1928204454764,
      },
      {
        lat: 12.972494806338489,
        lng: 80.18809569716713,
      },
      {
        lat: 12.969904139135602,
        lng: 80.18581121715432,
      },
      {
        lat: 12.970135701285432,
        lng: 80.1900151309959,
      },
      {
        lat: 12.980263566433948,
        lng: 80.18733517177687,
      },
      {
        lat: 12.979608012424903,
        lng: 80.18955454882695,
      },
      {
        lat: 12.97503247276531,
        lng: 80.1836052331121,
      },
      {
        lat: 12.973905871588396,
        lng: 80.18763598414233,
      },
      {
        lat: 12.983850958528157,
        lng: 80.18621845565609,
      },
      {
        lat: 12.973995470549525,
        lng: 80.18938214159566,
      },
      {
        lat: 12.969773992376005,
        lng: 80.18669562999106,
      },
      {
        lat: 12.980581636483738,
        lng: 80.1870407370063,
      },
      {
        lat: 12.974376051052454,
        lng: 80.18518362191824,
      },
      {
        lat: 12.980904961860404,
        lng: 80.18312503955741,
      },
      {
        lat: 12.983481826782498,
        lng: 80.18673254306653,
      },
      {
        lat: 12.975772388304318,
        lng: 80.18875283281312,
      },
      {
        lat: 12.978290023073582,
        lng: 80.18660855432621,
      },
      {
        lat: 12.970781540953913,
        lng: 80.18762225918815,
      },
      {
        lat: 12.982254312105017,
        lng: 80.1882269980975,
      },
      {
        lat: 12.981769059206448,
        lng: 80.18256397108837,
      },
      {
        lat: 12.98079439589007,
        lng: 80.18305067502689,
      },
      {
        lat: 12.982239157091055,
        lng: 80.1878235845553,
      },
      {
        lat: 12.980621795408004,
        lng: 80.1808041469499,
      },
      {
        lat: 12.972982719197486,
        lng: 80.18368094191989,
      },
      {
        lat: 12.977187696030262,
        lng: 80.1820004791718,
      },
      {
        lat: 12.970537865471792,
        lng: 80.18536137284804,
      },
      {
        lat: 12.979144408688345,
        lng: 80.18109273609456,
      },
      {
        lat: 12.981125220088938,
        lng: 80.18397471347326,
      },
      {
        lat: 12.979291072911654,
        lng: 80.18911519890868,
      },
      {
        lat: 12.976363754909064,
        lng: 80.19031928838942,
      },
      {
        lat: 12.976135445810707,
        lng: 80.18223840323378,
      },
      {
        lat: 12.976392192907056,
        lng: 80.18770751909713,
      },
      {
        lat: 12.975117874141572,
        lng: 80.18325564767586,
      },
      {
        lat: 12.974534858898659,
        lng: 80.1876900221689,
      },
      {
        lat: 12.981979749910689,
        lng: 80.17984810550563,
      },
      {
        lat: 12.979770138008721,
        lng: 80.18528434710478,
      },
      {
        lat: 12.975264178252289,
        lng: 80.18922128151247,
      },
      {
        lat: 12.973836311443698,
        lng: 80.18794060332016,
      },
      {
        lat: 12.98201989731019,
        lng: 80.18728971449104,
      },
      {
        lat: 12.975834910266943,
        lng: 80.18987995129915,
      },
      {
        lat: 12.97339931703405,
        lng: 80.18840864307941,
      },
      {
        lat: 12.982472625615223,
        lng: 80.18761873045527,
      },
      {
        lat: 12.977883933300195,
        lng: 80.18315959906809,
      },
      {
        lat: 12.984871511092138,
        lng: 80.18644014040432,
      },
      {
        lat: 12.973171221403804,
        lng: 80.1892660626171,
      },
      {
        lat: 12.981193477873889,
        lng: 80.185973866533,
      },
      {
        lat: 12.974508570320292,
        lng: 80.18919182633913,
      },
      {
        lat: 12.982131353024176,
        lng: 80.18271682556284,
      },
      {
        lat: 12.974653052008893,
        lng: 80.18626193310527,
      },
      {
        lat: 12.978280319647054,
        lng: 80.18302252800437,
      },
      {
        lat: 12.976195290996674,
        lng: 80.18258414550797,
      },
      {
        lat: 12.978201279291202,
        lng: 80.18804573479566,
      },
      {
        lat: 12.979766668834413,
        lng: 80.18159605022493,
      },
      {
        lat: 12.97495615888295,
        lng: 80.18956510012092,
      },
      {
        lat: 12.969278900173245,
        lng: 80.19314519929048,
      },
      {
        lat: 12.982322434836112,
        lng: 80.18704765139535,
      },
      {
        lat: 12.978247789765481,
        lng: 80.18429343819983,
      },
      {
        lat: 12.978025407920878,
        lng: 80.18624794780321,
      },
      {
        lat: 12.978024066414427,
        lng: 80.18875744081029,
      },
      {
        lat: 12.978478793788717,
        lng: 80.1870785852948,
      },
      {
        lat: 12.970758341694127,
        lng: 80.1880050941028,
      },
      {
        lat: 12.97533981855592,
        lng: 80.18322576464735,
      },
      {
        lat: 12.983050833998648,
        lng: 80.18630797753136,
      },
      {
        lat: 12.980002963205722,
        lng: 80.18735243407652,
      },
      {
        lat: 12.977179023410788,
        lng: 80.18677007523361,
      },
      {
        lat: 12.974469688663726,
        lng: 80.1868999471912,
      },
      {
        lat: 12.980964118663081,
        lng: 80.18135374474909,
      },
      {
        lat: 12.978111201944765,
        lng: 80.18233484915856,
      },
      {
        lat: 12.98055115191867,
        lng: 80.18265570778347,
      },
      {
        lat: 12.975402875221617,
        lng: 80.19042065741883,
      },
      {
        lat: 12.982273498522993,
        lng: 80.17964960181213,
      },
      {
        lat: 12.982273051752022,
        lng: 80.18091767593431,
      },
      {
        lat: 12.96949997131821,
        lng: 80.18924436479395,
      },
      {
        lat: 12.979141974846318,
        lng: 80.18921150303687,
      },
      {
        lat: 12.975576528919722,
        lng: 80.18395629382603,
      },
      {
        lat: 12.97731733626644,
        lng: 80.18392406324163,
      },
      {
        lat: 12.982634636305228,
        lng: 80.18109597048667,
      },
      {
        lat: 12.971836805470966,
        lng: 80.18718384468721,
      },
      {
        lat: 12.97550754977086,
        lng: 80.18261489493139,
      },
      {
        lat: 12.970042959234913,
        lng: 80.18344331520322,
      },
      {
        lat: 12.981638505623621,
        lng: 80.18641389966756,
      },
      {
        lat: 12.976258117027612,
        lng: 80.18895559332886,
      },
      {
        lat: 12.98416781715874,
        lng: 80.18651034350489,
      },
      {
        lat: 12.979294406235327,
        lng: 80.18669611398948,
      },
      {
        lat: 12.971957178314065,
        lng: 80.18363915145274,
      },
      {
        lat: 12.97941831746748,
        lng: 80.18833021886681,
      },
      {
        lat: 12.986097644646822,
        lng: 80.18657538368859,
      },
      {
        lat: 12.981046919680312,
        lng: 80.1826758270499,
      },
      {
        lat: 12.982462210974337,
        lng: 80.18157277674923,
      },
      {
        lat: 12.984012359892386,
        lng: 80.1864160069322,
      },
      {
        lat: 12.975128095981376,
        lng: 80.19000873050298,
      },
      {
        lat: 12.977422100722263,
        lng: 80.19008332054534,
      },
      {
        lat: 12.97622777538026,
        lng: 80.19029657413971,
      },
      {
        lat: 12.97049040708151,
        lng: 80.18611461173178,
      },
      {
        lat: 12.981930521676821,
        lng: 80.18693328045774,
      },
      {
        lat: 12.974789987618859,
        lng: 80.1886404844662,
      },
      {
        lat: 12.971646858009219,
        lng: 80.18585044839288,
      },
      {
        lat: 12.968901659265244,
        lng: 80.18378464617318,
      },
      {
        lat: 12.973079414371618,
        lng: 80.18876960129359,
      },
      {
        lat: 12.985429068301126,
        lng: 80.1868850786674,
      },
      {
        lat: 12.97489193514281,
        lng: 80.18725973981078,
      },
      {
        lat: 12.973042718458899,
        lng: 80.18694457709115,
      },
      {
        lat: 12.96900156932495,
        lng: 80.18324793188776,
      },
      {
        lat: 12.971274192940545,
        lng: 80.18856033868042,
      },
      {
        lat: 12.97700706874946,
        lng: 80.18929930818041,
      },
      {
        lat: 12.978084830520768,
        lng: 80.18211082237167,
      },
      {
        lat: 12.979868186820562,
        lng: 80.1858136576142,
      },
      {
        lat: 12.9715662954955,
        lng: 80.18864565439944,
      },
      {
        lat: 12.97956930258657,
        lng: 80.18685843653115,
      },
      {
        lat: 12.980003375692123,
        lng: 80.1896346555163,
      },
      {
        lat: 12.976220524656709,
        lng: 80.18319205943112,
      },
      {
        lat: 12.98137845349713,
        lng: 80.18556153932442,
      },
      {
        lat: 12.982504168197039,
        lng: 80.1873683488384,
      },
      {
        lat: 12.98487225451077,
        lng: 80.18787151729293,
      },
      {
        lat: 12.984076438499862,
        lng: 80.18783307842769,
      },
      {
        lat: 12.970749484237334,
        lng: 80.18745552605047,
      },
      {
        lat: 12.977883597172422,
        lng: 80.18717596859634,
      },
      {
        lat: 12.979263204183919,
        lng: 80.1848994591565,
      },
      {
        lat: 12.973424870959601,
        lng: 80.18746834814745,
      },
      {
        lat: 12.979431295978793,
        lng: 80.18814209798741,
      },
      {
        lat: 12.97118010113919,
        lng: 80.18814570383778,
      },
      {
        lat: 12.98076074342439,
        lng: 80.1848014049295,
      },
      {
        lat: 12.97261033248178,
        lng: 80.1871863502344,
      },
      {
        lat: 12.98391311836361,
        lng: 80.18620923910505,
      },
      {
        lat: 12.978241438089396,
        lng: 80.18211368318318,
      },
      {
        lat: 12.977713471937099,
        lng: 80.18751033769632,
      },
      {
        lat: 12.969999156459396,
        lng: 80.18837629171531,
      },
      {
        lat: 12.978661207790655,
        lng: 80.18815755026874,
      },
      {
        lat: 12.975963941505285,
        lng: 80.18427656042948,
      },
      {
        lat: 12.975265947362354,
        lng: 80.18558270174206,
      },
      {
        lat: 12.982619930281283,
        lng: 80.18758084790367,
      },
    ],
    cases: 152,
    coords: [
      {
        lat: 12.968078718610625,
        lng: 80.18193204365399,
      },
      {
        lat: 12.968848661389227,
        lng: 80.18197647535655,
      },
      {
        lat: 12.969281554988257,
        lng: 80.1821181553396,
      },
      {
        lat: 12.969399073791376,
        lng: 80.18209769246567,
      },
      {
        lat: 12.969496157543485,
        lng: 80.18194112456739,
      },
      {
        lat: 12.969672850172836,
        lng: 80.18197856573505,
      },
      {
        lat: 12.969693166816791,
        lng: 80.18209520348013,
      },
      {
        lat: 12.969518131886145,
        lng: 80.18233030422631,
      },
      {
        lat: 12.969421521762175,
        lng: 80.182564741243,
      },
      {
        lat: 12.969481169216607,
        lng: 80.18270051440287,
      },
      {
        lat: 12.969952428499578,
        lng: 80.18281333570341,
      },
      {
        lat: 12.972720218335766,
        lng: 80.18333502306729,
      },
      {
        lat: 12.972778918533798,
        lng: 80.18331505799065,
      },
      {
        lat: 12.973486281086286,
        lng: 80.1835621590597,
      },
      {
        lat: 12.973780374111708,
        lng: 80.18355967007417,
      },
      {
        lat: 12.974170958853817,
        lng: 80.1833032767924,
      },
      {
        lat: 12.974659574604669,
        lng: 80.18304605384883,
      },
      {
        lat: 12.974874769194976,
        lng: 80.18296635947436,
      },
      {
        lat: 12.975070712805003,
        lng: 80.18294523287113,
      },
      {
        lat: 12.976240335704663,
        lng: 80.1818256419936,
      },
      {
        lat: 12.976984068891376,
        lng: 80.18160519648825,
      },
      {
        lat: 12.977825714679609,
        lng: 80.1813644540415,
      },
      {
        lat: 12.978960743050964,
        lng: 80.18100441893179,
      },
      {
        lat: 12.979743451827007,
        lng: 80.18074470700263,
      },
      {
        lat: 12.9805252133467,
        lng: 80.18032925683688,
      },
      {
        lat: 12.981460509082426,
        lng: 80.17936739538426,
      },
      {
        lat: 12.982005456890084,
        lng: 80.17870086177274,
      },
      {
        lat: 12.982221006701767,
        lng: 80.17867956923693,
      },
      {
        lat: 12.982988490337064,
        lng: 80.17914031258424,
      },
      {
        lat: 12.983009043795061,
        lng: 80.17929588488836,
      },
      {
        lat: 12.982796809380831,
        lng: 80.17986226125205,
      },
      {
        lat: 12.982783478752829,
        lng: 80.18089419300154,
      },
      {
        lat: 12.982668683311863,
        lng: 80.18136240330546,
      },
      {
        lat: 12.982416052423881,
        lng: 80.18173443873808,
      },
      {
        lat: 12.982336343001284,
        lng: 80.18304060728877,
      },
      {
        lat: 12.981687107324102,
        lng: 80.18321454173218,
      },
      {
        lat: 12.981464722101775,
        lng: 80.18347054446822,
      },
      {
        lat: 12.981339273204517,
        lng: 80.18388455220233,
      },
      {
        lat: 12.981266349602587,
        lng: 80.18504459475851,
      },
      {
        lat: 12.981300658077688,
        lng: 80.18542548534529,
      },
      {
        lat: 12.981509946194807,
        lng: 80.18564606963898,
      },
      {
        lat: 12.982006469147334,
        lng: 80.18575304521322,
      },
      {
        lat: 12.982470036002377,
        lng: 80.18570147430805,
      },
      {
        lat: 12.98295066049641,
        lng: 80.18582446697447,
      },
      {
        lat: 12.984838946595941,
        lng: 80.18595142877514,
      },
      {
        lat: 12.985830157131762,
        lng: 80.18586362720582,
      },
      {
        lat: 12.986150058269713,
        lng: 80.18586091979952,
      },
      {
        lat: 12.986262796455158,
        lng: 80.18598702598314,
      },
      {
        lat: 12.986360891961118,
        lng: 80.18633561164476,
      },
      {
        lat: 12.986362340936953,
        lng: 80.18657383747444,
      },
      {
        lat: 12.986138989730739,
        lng: 80.1866710229908,
      },
      {
        lat: 12.98556297448559,
        lng: 80.18664413287821,
      },
      {
        lat: 12.985435690219159,
        lng: 80.18675638789458,
      },
      {
        lat: 12.985501409217685,
        lng: 80.187041717409,
      },
      {
        lat: 12.985552388938334,
        lng: 80.18753364467939,
      },
      {
        lat: 12.985558571235247,
        lng: 80.18855007488608,
      },
      {
        lat: 12.982935188707208,
        lng: 80.18854051217372,
      },
      {
        lat: 12.982102383166131,
        lng: 80.1883728524882,
      },
      {
        lat: 12.981286635264329,
        lng: 80.18837975637425,
      },
      {
        lat: 12.981126201703294,
        lng: 80.18830170146767,
      },
      {
        lat: 12.980950062880725,
        lng: 80.18827142709719,
      },
      {
        lat: 12.980839160064727,
        lng: 80.1884470736311,
      },
      {
        lat: 12.980632302811845,
        lng: 80.18976664075441,
      },
      {
        lat: 12.980548866520543,
        lng: 80.18979224681789,
      },
      {
        lat: 12.980223976759062,
        lng: 80.18984598258746,
      },
      {
        lat: 12.977982016025322,
        lng: 80.18989894764462,
      },
      {
        lat: 12.977657229630596,
        lng: 80.18996967792033,
      },
      {
        lat: 12.977453390745401,
        lng: 80.19022633377764,
      },
      {
        lat: 12.977284403625282,
        lng: 80.19058466696157,
      },
      {
        lat: 12.97709675022147,
        lng: 80.19068822740745,
      },
      {
        lat: 12.976805161723814,
        lng: 80.19058872291002,
      },
      {
        lat: 12.976000203126965,
        lng: 80.19051055857824,
      },
      {
        lat: 12.9755719984719,
        lng: 80.19046319644204,
      },
      {
        lat: 12.97517874894843,
        lng: 80.19053450613895,
      },
      {
        lat: 12.974939334731223,
        lng: 80.19057052312561,
      },
      {
        lat: 12.9745296929927,
        lng: 80.19076093922152,
      },
      {
        lat: 12.974357811603689,
        lng: 80.19064342623105,
      },
      {
        lat: 12.973960530777108,
        lng: 80.19005195018462,
      },
      {
        lat: 12.973769776370961,
        lng: 80.18964567544313,
      },
      {
        lat: 12.973700796408485,
        lng: 80.18956128233312,
      },
      {
        lat: 12.973272178286434,
        lng: 80.18944594217203,
      },
      {
        lat: 12.970959583722108,
        lng: 80.18914260201957,
      },
      {
        lat: 12.97066933899199,
        lng: 80.1892640261034,
      },
      {
        lat: 12.970094844803153,
        lng: 80.19049255563277,
      },
      {
        lat: 12.969795753630654,
        lng: 80.19197368507125,
      },
      {
        lat: 12.969540877499288,
        lng: 80.19228175901306,
      },
      {
        lat: 12.969411703747674,
        lng: 80.19355750582339,
      },
      {
        lat: 12.969259522309358,
        lng: 80.19386471063355,
      },
      {
        lat: 12.969208691796451,
        lng: 80.19395011773048,
      },
      {
        lat: 12.968489518844102,
        lng: 80.19390521813466,
      },
      {
        lat: 12.968146686366557,
        lng: 80.19382314270949,
      },
      {
        lat: 12.968043164739473,
        lng: 80.19368805579145,
      },
      {
        lat: 12.9685925839065,
        lng: 80.19115109413666,
      },
      {
        lat: 12.969283890935182,
        lng: 80.18942870993911,
      },
      {
        lat: 12.96961773050579,
        lng: 80.18803226332128,
      },
      {
        lat: 12.969743183054359,
        lng: 80.18614471428623,
      },
      {
        lat: 12.969790292364399,
        lng: 80.18544750496457,
      },
      {
        lat: 12.969410230686895,
        lng: 80.18487287856914,
      },
      {
        lat: 12.96915235691965,
        lng: 80.18468811182996,
      },
      {
        lat: 12.96892943828423,
        lng: 80.18462201692394,
      },
      {
        lat: 12.968979648596497,
        lng: 80.1844346427895,
      },
      {
        lat: 12.96902996227566,
        lng: 80.18426426316107,
      },
      {
        lat: 12.968653104967238,
        lng: 80.18421646645898,
      },
      {
        lat: 12.968396058134026,
        lng: 80.18416765576997,
      },
      {
        lat: 12.968274593790701,
        lng: 80.18389675765704,
      },
      {
        lat: 12.968083839384727,
        lng: 80.1834904829158,
      },
      {
        lat: 12.968078718610625,
        lng: 80.18193204365399,
      },
    ],
    properties: {
      Ward_No: 167,
      Zone_Name: "ALANDUR",
      fillColor: "#de4307",
      zname: "ALANDUR(167)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.984811608395676,
        lng: 80.17872510934635,
      },
      {
        lat: 12.986575210371623,
        lng: 80.17952770058828,
      },
      {
        lat: 12.972320867304159,
        lng: 80.17971438393329,
      },
      {
        lat: 12.974473780937862,
        lng: 80.18005292193568,
      },
      {
        lat: 12.973204086839083,
        lng: 80.1793995113292,
      },
      {
        lat: 12.987001065305249,
        lng: 80.18047790063166,
      },
      {
        lat: 12.973310667757703,
        lng: 80.17718272898388,
      },
      {
        lat: 12.981473878596784,
        lng: 80.17671072727548,
      },
      {
        lat: 12.982775925898094,
        lng: 80.17866037981312,
      },
      {
        lat: 12.985010913747905,
        lng: 80.17937193476479,
      },
      {
        lat: 12.974795668676364,
        lng: 80.17797619025511,
      },
      {
        lat: 12.984541909377285,
        lng: 80.18151181475947,
      },
      {
        lat: 12.976083716965972,
        lng: 80.17782793384775,
      },
      {
        lat: 12.985174612871942,
        lng: 80.17898726758487,
      },
      {
        lat: 12.977528665101776,
        lng: 80.17983998503654,
      },
      {
        lat: 12.97303188915121,
        lng: 80.179192959672,
      },
      {
        lat: 12.984871105064856,
        lng: 80.17807375422274,
      },
      {
        lat: 12.970684132183745,
        lng: 80.17829475432642,
      },
      {
        lat: 12.970238567730966,
        lng: 80.17945158458548,
      },
      {
        lat: 12.973348712624151,
        lng: 80.18195013455289,
      },
      {
        lat: 12.980316684944468,
        lng: 80.1765119325083,
      },
      {
        lat: 12.983039390275254,
        lng: 80.18099604446874,
      },
      {
        lat: 12.971459470257104,
        lng: 80.17779446346148,
      },
      {
        lat: 12.974339744926423,
        lng: 80.18308725759921,
      },
      {
        lat: 12.976072697424941,
        lng: 80.17940913290977,
      },
      {
        lat: 12.974105087891614,
        lng: 80.1802494329892,
      },
      {
        lat: 12.980692970507038,
        lng: 80.17434681110556,
      },
      {
        lat: 12.98340774807681,
        lng: 80.18046753117288,
      },
      {
        lat: 12.974642507504145,
        lng: 80.17980880093812,
      },
      {
        lat: 12.976966123070504,
        lng: 80.1778871813706,
      },
      {
        lat: 12.97881558804841,
        lng: 80.18045417745623,
      },
      {
        lat: 12.983481189982669,
        lng: 80.17843225463425,
      },
      {
        lat: 12.97257968424407,
        lng: 80.17684447499148,
      },
      {
        lat: 12.986855469892516,
        lng: 80.18097523542271,
      },
      {
        lat: 12.983053089079839,
        lng: 80.18159551432832,
      },
      {
        lat: 12.980822220853378,
        lng: 80.17972892239032,
      },
      {
        lat: 12.986112361053591,
        lng: 80.17827778963704,
      },
      {
        lat: 12.974087679475293,
        lng: 80.18165388967293,
      },
      {
        lat: 12.984765703828351,
        lng: 80.18284338402573,
      },
      {
        lat: 12.980011932817867,
        lng: 80.17719476596946,
      },
      {
        lat: 12.985311953489125,
        lng: 80.17881620998442,
      },
      {
        lat: 12.96957865302145,
        lng: 80.18186697610638,
      },
      {
        lat: 12.973202089042276,
        lng: 80.17882473126315,
      },
      {
        lat: 12.977545479840973,
        lng: 80.17923314324722,
      },
      {
        lat: 12.972249401727554,
        lng: 80.18107891439404,
      },
      {
        lat: 12.977932515323324,
        lng: 80.180196318988,
      },
      {
        lat: 12.984961768433394,
        lng: 80.17954013251833,
      },
      {
        lat: 12.976034038090187,
        lng: 80.18091922709662,
      },
      {
        lat: 12.973517150211904,
        lng: 80.18111809122585,
      },
      {
        lat: 12.974332682543379,
        lng: 80.18239962320045,
      },
      {
        lat: 12.974574417674319,
        lng: 80.17737853764947,
      },
      {
        lat: 12.981055475455845,
        lng: 80.1774763792623,
      },
      {
        lat: 12.983195513803672,
        lng: 80.18069085444029,
      },
      {
        lat: 12.984743353844646,
        lng: 80.18440585653931,
      },
      {
        lat: 12.976126064353094,
        lng: 80.18178123440924,
      },
      {
        lat: 12.978058333659291,
        lng: 80.17858664708088,
      },
      {
        lat: 12.982487172107405,
        lng: 80.17664633690518,
      },
      {
        lat: 12.972316604393878,
        lng: 80.1808429518694,
      },
      {
        lat: 12.971186791910107,
        lng: 80.18004731967946,
      },
      {
        lat: 12.983825838021724,
        lng: 80.18221127662488,
      },
      {
        lat: 12.977539791695703,
        lng: 80.17885099513272,
      },
      {
        lat: 12.980382098971843,
        lng: 80.17638868622737,
      },
      {
        lat: 12.978875099591058,
        lng: 80.17853517558476,
      },
      {
        lat: 12.971683495331488,
        lng: 80.17695141024248,
      },
      {
        lat: 12.986535182480631,
        lng: 80.17939928369645,
      },
      {
        lat: 12.972671952368813,
        lng: 80.17944532833052,
      },
      {
        lat: 12.981664886305163,
        lng: 80.17767763076868,
      },
      {
        lat: 12.986678726549085,
        lng: 80.17991488280377,
      },
      {
        lat: 12.970745980266317,
        lng: 80.18048168323769,
      },
      {
        lat: 12.985750405815958,
        lng: 80.1794353256653,
      },
      {
        lat: 12.972761618718332,
        lng: 80.17707678640431,
      },
      {
        lat: 12.98327999182508,
        lng: 80.17877047995353,
      },
      {
        lat: 12.974090328335201,
        lng: 80.1781059955866,
      },
      {
        lat: 12.983312774631548,
        lng: 80.18121774226995,
      },
      {
        lat: 12.986646038271246,
        lng: 80.17945238473945,
      },
      {
        lat: 12.984033030801477,
        lng: 80.17922707892953,
      },
      {
        lat: 12.9771412609357,
        lng: 80.17850332453493,
      },
      {
        lat: 12.980437421256703,
        lng: 80.17887331820052,
      },
      {
        lat: 12.974868561939955,
        lng: 80.17846856942637,
      },
      {
        lat: 12.970499906137508,
        lng: 80.17759644589378,
      },
      {
        lat: 12.973355453972196,
        lng: 80.17710953241271,
      },
      {
        lat: 12.984506349785233,
        lng: 80.1792661416359,
      },
      {
        lat: 12.985877864414393,
        lng: 80.18060640975675,
      },
      {
        lat: 12.972616354393635,
        lng: 80.18308352565275,
      },
      {
        lat: 12.985739842341191,
        lng: 80.17878665310594,
      },
      {
        lat: 12.985963460292336,
        lng: 80.1842630299246,
      },
      {
        lat: 12.973717444916751,
        lng: 80.17759856335658,
      },
      {
        lat: 12.976255336558143,
        lng: 80.17809601095507,
      },
      {
        lat: 12.970248423087424,
        lng: 80.1808130384985,
      },
      {
        lat: 12.983986958903229,
        lng: 80.1778477276431,
      },
      {
        lat: 12.970198570338031,
        lng: 80.18108827172968,
      },
      {
        lat: 12.979961030561146,
        lng: 80.17805822114049,
      },
      {
        lat: 12.979675681361028,
        lng: 80.17624835340666,
      },
      {
        lat: 12.985002030430476,
        lng: 80.17781990490742,
      },
      {
        lat: 12.982226505430612,
        lng: 80.17503104660899,
      },
    ],
    cases: 95,
    coords: [
      {
        lat: 12.982336343001284,
        lng: 80.18304060728877,
      },
      {
        lat: 12.982416052423881,
        lng: 80.18173443873808,
      },
      {
        lat: 12.982668683311863,
        lng: 80.18136240330546,
      },
      {
        lat: 12.982783478752829,
        lng: 80.18089419300154,
      },
      {
        lat: 12.982796809380831,
        lng: 80.17986226125205,
      },
      {
        lat: 12.983009043795061,
        lng: 80.17929588488836,
      },
      {
        lat: 12.982988490337064,
        lng: 80.17914031258424,
      },
      {
        lat: 12.982221006701767,
        lng: 80.17867956923693,
      },
      {
        lat: 12.982005456890084,
        lng: 80.17870086177274,
      },
      {
        lat: 12.981460509082426,
        lng: 80.17936739538426,
      },
      {
        lat: 12.9805252133467,
        lng: 80.18032925683688,
      },
      {
        lat: 12.979743451827007,
        lng: 80.18074470700263,
      },
      {
        lat: 12.978960743050964,
        lng: 80.18100441893179,
      },
      {
        lat: 12.977825714679609,
        lng: 80.1813644540415,
      },
      {
        lat: 12.976984068891376,
        lng: 80.18160519648825,
      },
      {
        lat: 12.976240335704663,
        lng: 80.1818256419936,
      },
      {
        lat: 12.975070712805003,
        lng: 80.18294523287113,
      },
      {
        lat: 12.974874769194976,
        lng: 80.18296635947436,
      },
      {
        lat: 12.974659574604669,
        lng: 80.18304605384883,
      },
      {
        lat: 12.974170958853817,
        lng: 80.1833032767924,
      },
      {
        lat: 12.973780374111708,
        lng: 80.18355967007417,
      },
      {
        lat: 12.973486281086286,
        lng: 80.1835621590597,
      },
      {
        lat: 12.972778918533798,
        lng: 80.18331505799065,
      },
      {
        lat: 12.972720218335766,
        lng: 80.18333502306729,
      },
      {
        lat: 12.969952428499578,
        lng: 80.18281333570341,
      },
      {
        lat: 12.969481169216607,
        lng: 80.18270051440287,
      },
      {
        lat: 12.969421521762175,
        lng: 80.182564741243,
      },
      {
        lat: 12.969518131886145,
        lng: 80.18233030422631,
      },
      {
        lat: 12.969693166816791,
        lng: 80.18209520348013,
      },
      {
        lat: 12.969672850172836,
        lng: 80.18197856573505,
      },
      {
        lat: 12.969496157543485,
        lng: 80.18194112456739,
      },
      {
        lat: 12.969399073791376,
        lng: 80.18209769246567,
      },
      {
        lat: 12.969281554988257,
        lng: 80.1821181553396,
      },
      {
        lat: 12.968848661389227,
        lng: 80.18197647535655,
      },
      {
        lat: 12.968078718610625,
        lng: 80.18193204365399,
      },
      {
        lat: 12.968078515736451,
        lng: 80.18187030164357,
      },
      {
        lat: 12.968907686671281,
        lng: 80.18038368627438,
      },
      {
        lat: 12.97078051967033,
        lng: 80.17694626590453,
      },
      {
        lat: 12.971088703228057,
        lng: 80.176573758197,
      },
      {
        lat: 12.971647858134029,
        lng: 80.17663067583571,
      },
      {
        lat: 12.973575169326498,
        lng: 80.17704591388315,
      },
      {
        lat: 12.975440018900196,
        lng: 80.17740003064777,
      },
      {
        lat: 12.976650521308752,
        lng: 80.17735896089607,
      },
      {
        lat: 12.977456523026937,
        lng: 80.17716718975939,
      },
      {
        lat: 12.97785821153394,
        lng: 80.17685554060711,
      },
      {
        lat: 12.97850937155756,
        lng: 80.17672672984753,
      },
      {
        lat: 12.979128925814367,
        lng: 80.17650571170822,
      },
      {
        lat: 12.979773524076561,
        lng: 80.17529808302812,
      },
      {
        lat: 12.980013434169601,
        lng: 80.17390892959389,
      },
      {
        lat: 12.979997463477654,
        lng: 80.17365160242872,
      },
      {
        lat: 12.982218656960375,
        lng: 80.17424859824679,
      },
      {
        lat: 12.982339477337378,
        lng: 80.17451389048145,
      },
      {
        lat: 12.982223876121203,
        lng: 80.17510667944194,
      },
      {
        lat: 12.982230715021608,
        lng: 80.17623106169788,
      },
      {
        lat: 12.982441115847836,
        lng: 80.17652518631967,
      },
      {
        lat: 12.983456480919557,
        lng: 80.17687167938026,
      },
      {
        lat: 12.985574811830364,
        lng: 80.17726801881473,
      },
      {
        lat: 12.986321255583398,
        lng: 80.17749842570659,
      },
      {
        lat: 12.987188519713438,
        lng: 80.17799412483329,
      },
      {
        lat: 12.988664407537772,
        lng: 80.17863122628621,
      },
      {
        lat: 12.988038274599484,
        lng: 80.17887775841933,
      },
      {
        lat: 12.987503340557792,
        lng: 80.17921904665123,
      },
      {
        lat: 12.9874209434513,
        lng: 80.17961263176994,
      },
      {
        lat: 12.98731762349341,
        lng: 80.18121312068821,
      },
      {
        lat: 12.987066042889127,
        lng: 80.18129487874259,
      },
      {
        lat: 12.986906671910376,
        lng: 80.1813915227776,
      },
      {
        lat: 12.986731016079577,
        lng: 80.18144065701695,
      },
      {
        lat: 12.986441752678052,
        lng: 80.18122074957485,
      },
      {
        lat: 12.985799052450426,
        lng: 80.18074971272816,
      },
      {
        lat: 12.985687473445651,
        lng: 80.18081418720833,
      },
      {
        lat: 12.985592758883318,
        lng: 80.18102146181577,
      },
      {
        lat: 12.985785375754888,
        lng: 80.18113100942588,
      },
      {
        lat: 12.986299439338637,
        lng: 80.18149195718149,
      },
      {
        lat: 12.98631659357619,
        lng: 80.18168240247496,
      },
      {
        lat: 12.98623980603852,
        lng: 80.18220717615178,
      },
      {
        lat: 12.98625458559207,
        lng: 80.18463707961469,
      },
      {
        lat: 12.985582503407135,
        lng: 80.18459512000206,
      },
      {
        lat: 12.983821018582086,
        lng: 80.18427649457506,
      },
      {
        lat: 12.983484929190428,
        lng: 80.18424757390774,
      },
      {
        lat: 12.983419403388567,
        lng: 80.18399400783723,
      },
      {
        lat: 12.983544176096872,
        lng: 80.18346882804927,
      },
      {
        lat: 12.983604292388941,
        lng: 80.18283301768902,
      },
      {
        lat: 12.98352383411248,
        lng: 80.18275428593059,
      },
      {
        lat: 12.983139759550179,
        lng: 80.18272577137421,
      },
      {
        lat: 12.98242172076066,
        lng: 80.18301773403388,
      },
      {
        lat: 12.982336343001284,
        lng: 80.18304060728877,
      },
    ],
    properties: {
      Ward_No: 166,
      Zone_Name: "ALANDUR",
      fillColor: "#f6d04d",
      zname: "ALANDUR(166)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.982800168094952,
        lng: 80.1904503905857,
      },
      {
        lat: 12.985711694007929,
        lng: 80.19009357089772,
      },
      {
        lat: 12.982986425545317,
        lng: 80.19121035359336,
      },
      {
        lat: 12.98609140894003,
        lng: 80.187986428658,
      },
      {
        lat: 12.98677500533175,
        lng: 80.18812136850501,
      },
      {
        lat: 12.982024552403548,
        lng: 80.18558730741218,
      },
      {
        lat: 12.987844718626045,
        lng: 80.18584569286269,
      },
      {
        lat: 12.985750548195554,
        lng: 80.18519690708465,
      },
      {
        lat: 12.986380843762575,
        lng: 80.18271191210623,
      },
      {
        lat: 12.98436698488887,
        lng: 80.18897906802567,
      },
      {
        lat: 12.986038828434962,
        lng: 80.18679104055052,
      },
      {
        lat: 12.988241023851021,
        lng: 80.18394788668947,
      },
      {
        lat: 12.990239299080573,
        lng: 80.1854215792146,
      },
      {
        lat: 12.987235403804204,
        lng: 80.19061519387981,
      },
      {
        lat: 12.982570393098053,
        lng: 80.18410875233185,
      },
      {
        lat: 12.984203392922122,
        lng: 80.18899991505839,
      },
      {
        lat: 12.983062817703415,
        lng: 80.18864396962225,
      },
      {
        lat: 12.981683476970158,
        lng: 80.18512670454467,
      },
      {
        lat: 12.982803344253885,
        lng: 80.18881162115485,
      },
      {
        lat: 12.988800168767812,
        lng: 80.18498153187191,
      },
      {
        lat: 12.98424816253785,
        lng: 80.18917289926824,
      },
      {
        lat: 12.988048486735723,
        lng: 80.18441364806019,
      },
      {
        lat: 12.985194109287713,
        lng: 80.1906827732239,
      },
      {
        lat: 12.98747000467789,
        lng: 80.19111953047283,
      },
      {
        lat: 12.98497315985404,
        lng: 80.1893123916156,
      },
      {
        lat: 12.982191452504296,
        lng: 80.1898495634364,
      },
      {
        lat: 12.981123055607938,
        lng: 80.18842139367523,
      },
      {
        lat: 12.984360746074668,
        lng: 80.18446664535355,
      },
      {
        lat: 12.986187916757295,
        lng: 80.19040615219852,
      },
      {
        lat: 12.983294480766077,
        lng: 80.18940146496537,
      },
      {
        lat: 12.98527241264378,
        lng: 80.19210101174704,
      },
      {
        lat: 12.987360618198448,
        lng: 80.19064495033187,
      },
      {
        lat: 12.984847073182664,
        lng: 80.1851721433326,
      },
      {
        lat: 12.982413548786546,
        lng: 80.18886986839777,
      },
      {
        lat: 12.984826520335075,
        lng: 80.19185694676341,
      },
      {
        lat: 12.988873723617244,
        lng: 80.18454279755557,
      },
      {
        lat: 12.984307702101844,
        lng: 80.19008806267188,
      },
      {
        lat: 12.985831805286008,
        lng: 80.18461685681669,
      },
      {
        lat: 12.988860007303364,
        lng: 80.18524956381295,
      },
      {
        lat: 12.987235204321554,
        lng: 80.18362590030334,
      },
      {
        lat: 12.981553357406465,
        lng: 80.18851863089732,
      },
      {
        lat: 12.988153160250347,
        lng: 80.18590449463382,
      },
      {
        lat: 12.987929429117218,
        lng: 80.18622276970781,
      },
      {
        lat: 12.983525990231708,
        lng: 80.18289167309342,
      },
      {
        lat: 12.984810100737038,
        lng: 80.18594550127122,
      },
      {
        lat: 12.984362050998392,
        lng: 80.19242921874053,
      },
      {
        lat: 12.98628439260635,
        lng: 80.1923952015383,
      },
      {
        lat: 12.982394258321142,
        lng: 80.18951326210022,
      },
      {
        lat: 12.982867109883694,
        lng: 80.18486570840571,
      },
      {
        lat: 12.983246940333157,
        lng: 80.18487906759731,
      },
      {
        lat: 12.986839127502755,
        lng: 80.18751382627896,
      },
      {
        lat: 12.991590459045817,
        lng: 80.18521084834937,
      },
      {
        lat: 12.98817358873718,
        lng: 80.19082559538269,
      },
      {
        lat: 12.982368795343742,
        lng: 80.18984372908456,
      },
      {
        lat: 12.985255013687604,
        lng: 80.18572468731408,
      },
      {
        lat: 12.98834975882518,
        lng: 80.1900791126447,
      },
      {
        lat: 12.987404856873296,
        lng: 80.1839236412021,
      },
      {
        lat: 12.985326729325271,
        lng: 80.19085955103279,
      },
      {
        lat: 12.98396655653141,
        lng: 80.18903053517684,
      },
      {
        lat: 12.989246016081763,
        lng: 80.18518288176492,
      },
      {
        lat: 12.986863875313771,
        lng: 80.18283579686015,
      },
      {
        lat: 12.987438688785947,
        lng: 80.19289493456778,
      },
      {
        lat: 12.987941548332627,
        lng: 80.18607299643374,
      },
      {
        lat: 12.987027805506957,
        lng: 80.18679535525968,
      },
      {
        lat: 12.986751757285841,
        lng: 80.19050746233984,
      },
      {
        lat: 12.984578521966531,
        lng: 80.19163324176196,
      },
      {
        lat: 12.986892801541426,
        lng: 80.18991251068695,
      },
      {
        lat: 12.991917541276667,
        lng: 80.18550601889937,
      },
      {
        lat: 12.987514171428602,
        lng: 80.18317397616995,
      },
      {
        lat: 12.987778451178713,
        lng: 80.18650517439961,
      },
      {
        lat: 12.985648281773095,
        lng: 80.19044489280236,
      },
      {
        lat: 12.985761348885934,
        lng: 80.19040732733231,
      },
      {
        lat: 12.992893856647175,
        lng: 80.18560012391576,
      },
      {
        lat: 12.982431036239706,
        lng: 80.18916795072172,
      },
      {
        lat: 12.985646019337459,
        lng: 80.19032718594639,
      },
      {
        lat: 12.981144094337708,
        lng: 80.18834557305264,
      },
      {
        lat: 12.9906612847841,
        lng: 80.18507284170845,
      },
      {
        lat: 12.985222948334192,
        lng: 80.18525080477994,
      },
      {
        lat: 12.98412116504263,
        lng: 80.1851310212457,
      },
      {
        lat: 12.982110705143537,
        lng: 80.1900831432235,
      },
      {
        lat: 12.981612657901124,
        lng: 80.18391536290311,
      },
      {
        lat: 12.985896607833173,
        lng: 80.18565430929613,
      },
      {
        lat: 12.984395870890234,
        lng: 80.1895832366718,
      },
      {
        lat: 12.983642061701708,
        lng: 80.18555596198934,
      },
      {
        lat: 12.983136982523853,
        lng: 80.1891274068499,
      },
      {
        lat: 12.986474726982289,
        lng: 80.18325638984915,
      },
      {
        lat: 12.984572577836955,
        lng: 80.18583219030816,
      },
      {
        lat: 12.981114586663448,
        lng: 80.18910132019293,
      },
      {
        lat: 12.983425423326164,
        lng: 80.18987993112177,
      },
      {
        lat: 12.98082648735317,
        lng: 80.18945232861776,
      },
      {
        lat: 12.992486549496222,
        lng: 80.1854789114146,
      },
      {
        lat: 12.983180189553822,
        lng: 80.1890912293712,
      },
      {
        lat: 12.986467901823719,
        lng: 80.1843388567014,
      },
      {
        lat: 12.982659380771693,
        lng: 80.19100768702371,
      },
      {
        lat: 12.982389767588202,
        lng: 80.18393686995738,
      },
      {
        lat: 12.983263202544512,
        lng: 80.18330531797795,
      },
      {
        lat: 12.982287779696835,
        lng: 80.18411808303975,
      },
      {
        lat: 12.984745808549754,
        lng: 80.19168513610857,
      },
      {
        lat: 12.986304435291526,
        lng: 80.19023588361405,
      },
      {
        lat: 12.981534073348193,
        lng: 80.18933664306277,
      },
      {
        lat: 12.987507219945025,
        lng: 80.1867155544031,
      },
      {
        lat: 12.98456315779225,
        lng: 80.19078269011975,
      },
      {
        lat: 12.981589646826245,
        lng: 80.18900331655064,
      },
      {
        lat: 12.986752926976179,
        lng: 80.18661434064485,
      },
      {
        lat: 12.983869365087978,
        lng: 80.19218266325899,
      },
      {
        lat: 12.98116094468505,
        lng: 80.18852885238438,
      },
      {
        lat: 12.984850119058928,
        lng: 80.19158692771823,
      },
      {
        lat: 12.986756154252637,
        lng: 80.19150543623017,
      },
      {
        lat: 12.982830164748615,
        lng: 80.1896453928733,
      },
    ],
    cases: 109,
    coords: [
      {
        lat: 12.982323738164718,
        lng: 80.19210824032147,
      },
      {
        lat: 12.982357910826801,
        lng: 80.19173140522861,
      },
      {
        lat: 12.98226964438038,
        lng: 80.1912902723428,
      },
      {
        lat: 12.982079716908373,
        lng: 80.19101995365136,
      },
      {
        lat: 12.981957839098065,
        lng: 80.19068107751372,
      },
      {
        lat: 12.981816674803815,
        lng: 80.18998546160041,
      },
      {
        lat: 12.981507453690158,
        lng: 80.18980112942724,
      },
      {
        lat: 12.980890561963873,
        lng: 80.18968738267434,
      },
      {
        lat: 12.980632302811845,
        lng: 80.18976664075441,
      },
      {
        lat: 12.980839160064727,
        lng: 80.1884470736311,
      },
      {
        lat: 12.980950062880725,
        lng: 80.18827142709719,
      },
      {
        lat: 12.981126201703294,
        lng: 80.18830170146767,
      },
      {
        lat: 12.981286635264329,
        lng: 80.18837975637425,
      },
      {
        lat: 12.982102383166131,
        lng: 80.1883728524882,
      },
      {
        lat: 12.982935188707208,
        lng: 80.18854051217372,
      },
      {
        lat: 12.985558571235247,
        lng: 80.18855007488608,
      },
      {
        lat: 12.985552388938334,
        lng: 80.18753364467939,
      },
      {
        lat: 12.985501409217685,
        lng: 80.187041717409,
      },
      {
        lat: 12.985435690219159,
        lng: 80.18675638789458,
      },
      {
        lat: 12.98556297448559,
        lng: 80.18664413287821,
      },
      {
        lat: 12.986138989730739,
        lng: 80.1866710229908,
      },
      {
        lat: 12.986362340936953,
        lng: 80.18657383747444,
      },
      {
        lat: 12.986360891961118,
        lng: 80.18633561164476,
      },
      {
        lat: 12.986262796455158,
        lng: 80.18598702598314,
      },
      {
        lat: 12.986150058269713,
        lng: 80.18586091979952,
      },
      {
        lat: 12.985830157131762,
        lng: 80.18586362720582,
      },
      {
        lat: 12.984838946595941,
        lng: 80.18595142877514,
      },
      {
        lat: 12.98295066049641,
        lng: 80.18582446697447,
      },
      {
        lat: 12.982470036002377,
        lng: 80.18570147430805,
      },
      {
        lat: 12.982006469147334,
        lng: 80.18575304521322,
      },
      {
        lat: 12.981509946194807,
        lng: 80.18564606963898,
      },
      {
        lat: 12.981300658077688,
        lng: 80.18542548534529,
      },
      {
        lat: 12.981266349602587,
        lng: 80.18504459475851,
      },
      {
        lat: 12.981339273204517,
        lng: 80.18388455220233,
      },
      {
        lat: 12.981464722101775,
        lng: 80.18347054446822,
      },
      {
        lat: 12.981687107324102,
        lng: 80.18321454173218,
      },
      {
        lat: 12.98242172076066,
        lng: 80.18301773403388,
      },
      {
        lat: 12.983139759550179,
        lng: 80.18272577137421,
      },
      {
        lat: 12.98352383411248,
        lng: 80.18275428593059,
      },
      {
        lat: 12.983604292388941,
        lng: 80.18283301768902,
      },
      {
        lat: 12.983544176096872,
        lng: 80.18346882804927,
      },
      {
        lat: 12.983419403388567,
        lng: 80.18399400783723,
      },
      {
        lat: 12.983484929190428,
        lng: 80.18424757390774,
      },
      {
        lat: 12.983821018582086,
        lng: 80.18427649457506,
      },
      {
        lat: 12.985582503407135,
        lng: 80.18459512000206,
      },
      {
        lat: 12.98625458559207,
        lng: 80.18463707961469,
      },
      {
        lat: 12.98623980603852,
        lng: 80.18220717615178,
      },
      {
        lat: 12.98631659357619,
        lng: 80.18168240247496,
      },
      {
        lat: 12.986299439338637,
        lng: 80.18149195718149,
      },
      {
        lat: 12.985785375754888,
        lng: 80.18113100942588,
      },
      {
        lat: 12.985592758883318,
        lng: 80.18102146181577,
      },
      {
        lat: 12.985687473445651,
        lng: 80.18081418720833,
      },
      {
        lat: 12.985799052450426,
        lng: 80.18074971272816,
      },
      {
        lat: 12.986441752678052,
        lng: 80.18122074957485,
      },
      {
        lat: 12.986731016079577,
        lng: 80.18144065701695,
      },
      {
        lat: 12.986906671910376,
        lng: 80.1813915227776,
      },
      {
        lat: 12.987066042889127,
        lng: 80.18129487874259,
      },
      {
        lat: 12.98731762349341,
        lng: 80.18121312068821,
      },
      {
        lat: 12.988207236370144,
        lng: 80.18342260122667,
      },
      {
        lat: 12.988608198667897,
        lng: 80.18428917355642,
      },
      {
        lat: 12.988920619269065,
        lng: 80.18453910016962,
      },
      {
        lat: 12.989376228499363,
        lng: 80.18509651247425,
      },
      {
        lat: 12.989575429525333,
        lng: 80.18531933388184,
      },
      {
        lat: 12.989970759282112,
        lng: 80.18525986127918,
      },
      {
        lat: 12.9906195956313,
        lng: 80.18505792612375,
      },
      {
        lat: 12.991326151207895,
        lng: 80.18505194636067,
      },
      {
        lat: 12.992940170217983,
        lng: 80.18554342791856,
      },
      {
        lat: 12.993619146303752,
        lng: 80.18564993521652,
      },
      {
        lat: 12.994044957163405,
        lng: 80.18595502900294,
      },
      {
        lat: 12.994092255905567,
        lng: 80.18609805047721,
      },
      {
        lat: 12.993481479705041,
        lng: 80.18599719774046,
      },
      {
        lat: 12.992719908784506,
        lng: 80.18584180676922,
      },
      {
        lat: 12.992311139269027,
        lng: 80.18562948450003,
      },
      {
        lat: 12.991767042861763,
        lng: 80.18549922570946,
      },
      {
        lat: 12.99114194717891,
        lng: 80.18545057060835,
      },
      {
        lat: 12.990788325526717,
        lng: 80.18537264522848,
      },
      {
        lat: 12.990463180077786,
        lng: 80.18551026063832,
      },
      {
        lat: 12.99005670725444,
        lng: 80.18567553706684,
      },
      {
        lat: 12.989757905366472,
        lng: 80.18567806590474,
      },
      {
        lat: 12.989431611571645,
        lng: 80.1856268819658,
      },
      {
        lat: 12.9888056956416,
        lng: 80.1854433701871,
      },
      {
        lat: 12.988506565654756,
        lng: 80.18539195635398,
      },
      {
        lat: 12.988290239487432,
        lng: 80.18555562352192,
      },
      {
        lat: 12.987862836734593,
        lng: 80.18674604059522,
      },
      {
        lat: 12.987756314145283,
        lng: 80.18709758753451,
      },
      {
        lat: 12.987680564451775,
        lng: 80.18804227396153,
      },
      {
        lat: 12.987572729466791,
        lng: 80.18817805021655,
      },
      {
        lat: 12.987057601411502,
        lng: 80.18834424622251,
      },
      {
        lat: 12.98714663910968,
        lng: 80.18958423797413,
      },
      {
        lat: 12.987473917201134,
        lng: 80.18979724992634,
      },
      {
        lat: 12.987935701937197,
        lng: 80.18979334172238,
      },
      {
        lat: 12.988098192636913,
        lng: 80.18971104834964,
      },
      {
        lat: 12.988342995007724,
        lng: 80.18976292197146,
      },
      {
        lat: 12.988373111705544,
        lng: 80.19024817611678,
      },
      {
        lat: 12.988026872278063,
        lng: 80.19138396083588,
      },
      {
        lat: 12.987707796658517,
        lng: 80.19251951566086,
      },
      {
        lat: 12.987575258607182,
        lng: 80.19306009184328,
      },
      {
        lat: 12.987304440774501,
        lng: 80.19319724746445,
      },
      {
        lat: 12.98512805514573,
        lng: 80.19267621230189,
      },
      {
        lat: 12.982323738164718,
        lng: 80.19210824032147,
      },
    ],
    properties: {
      Ward_No: 164,
      Zone_Name: "ALANDUR",
      fillColor: "#f6d04d",
      zname: "ALANDUR(164)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.992640940353766,
        lng: 80.19438395839516,
      },
      {
        lat: 12.99129769813808,
        lng: 80.18835068596636,
      },
      {
        lat: 12.991587350352452,
        lng: 80.18912835525185,
      },
      {
        lat: 12.993216248338092,
        lng: 80.19268222640211,
      },
      {
        lat: 12.99272423000071,
        lng: 80.19252181989924,
      },
      {
        lat: 12.990274387780875,
        lng: 80.19407664906217,
      },
      {
        lat: 12.993791871149215,
        lng: 80.19182465215083,
      },
      {
        lat: 12.995192848278094,
        lng: 80.1903899558486,
      },
      {
        lat: 12.990233578749265,
        lng: 80.18798403226751,
      },
      {
        lat: 12.993879527718102,
        lng: 80.18889630281728,
      },
      {
        lat: 12.995126389226519,
        lng: 80.18870348619836,
      },
      {
        lat: 12.9925599406702,
        lng: 80.18627845312749,
      },
      {
        lat: 12.991046309903027,
        lng: 80.19450157049324,
      },
      {
        lat: 12.996931634948359,
        lng: 80.19597789832716,
      },
      {
        lat: 12.987416064381426,
        lng: 80.19315714510809,
      },
      {
        lat: 12.994736432423071,
        lng: 80.19639020289696,
      },
      {
        lat: 12.987495417410953,
        lng: 80.19537524718837,
      },
      {
        lat: 12.989542624188607,
        lng: 80.19632968645952,
      },
      {
        lat: 12.992294556577534,
        lng: 80.19468067084374,
      },
      {
        lat: 12.994614704905004,
        lng: 80.19423937209365,
      },
      {
        lat: 12.991733855547754,
        lng: 80.19142953804503,
      },
      {
        lat: 12.99671341929761,
        lng: 80.19370574109479,
      },
      {
        lat: 12.995291154759125,
        lng: 80.19494776911691,
      },
      {
        lat: 12.994301519576913,
        lng: 80.19196259128489,
      },
      {
        lat: 12.986162770660213,
        lng: 80.19562659904213,
      },
      {
        lat: 12.987796730077608,
        lng: 80.1957550264752,
      },
      {
        lat: 12.991588505416031,
        lng: 80.19136597169874,
      },
      {
        lat: 12.990315140892761,
        lng: 80.18680810938017,
      },
      {
        lat: 12.990774410867191,
        lng: 80.19407002877136,
      },
      {
        lat: 12.987675562953472,
        lng: 80.19453229781249,
      },
      {
        lat: 12.992521519118123,
        lng: 80.18984790860205,
      },
      {
        lat: 12.994394788631535,
        lng: 80.19048635600701,
      },
      {
        lat: 12.995740005909374,
        lng: 80.19534754064233,
      },
      {
        lat: 12.992893394963476,
        lng: 80.19645590266502,
      },
    ],
    cases: 34,
    coords: [
      {
        lat: 12.990732854843298,
        lng: 80.19845364925152,
      },
      {
        lat: 12.988963306005004,
        lng: 80.19795738019357,
      },
      {
        lat: 12.987521327488777,
        lng: 80.1975919658966,
      },
      {
        lat: 12.986107333027912,
        lng: 80.19736117838315,
      },
      {
        lat: 12.985643907797638,
        lng: 80.19709537323168,
      },
      {
        lat: 12.985749610139697,
        lng: 80.19660896961456,
      },
      {
        lat: 12.985960194576732,
        lng: 80.1955013057027,
      },
      {
        lat: 12.987304440774501,
        lng: 80.19319724746445,
      },
      {
        lat: 12.987575258607182,
        lng: 80.19306009184328,
      },
      {
        lat: 12.987707796658517,
        lng: 80.19251951566086,
      },
      {
        lat: 12.988026872278063,
        lng: 80.19138396083588,
      },
      {
        lat: 12.988373111705544,
        lng: 80.19024817611678,
      },
      {
        lat: 12.988342995007724,
        lng: 80.18976292197146,
      },
      {
        lat: 12.988098192636913,
        lng: 80.18971104834964,
      },
      {
        lat: 12.987935701937197,
        lng: 80.18979334172238,
      },
      {
        lat: 12.987473917201134,
        lng: 80.18979724992634,
      },
      {
        lat: 12.98714663910968,
        lng: 80.18958423797413,
      },
      {
        lat: 12.987057601411502,
        lng: 80.18834424622251,
      },
      {
        lat: 12.987572729466791,
        lng: 80.18817805021655,
      },
      {
        lat: 12.987680564451775,
        lng: 80.18804227396153,
      },
      {
        lat: 12.987756314145283,
        lng: 80.18709758753451,
      },
      {
        lat: 12.987862836734593,
        lng: 80.18674604059522,
      },
      {
        lat: 12.988290239487432,
        lng: 80.18555562352192,
      },
      {
        lat: 12.988506565654756,
        lng: 80.18539195635398,
      },
      {
        lat: 12.9888056956416,
        lng: 80.1854433701871,
      },
      {
        lat: 12.989431611571645,
        lng: 80.1856268819658,
      },
      {
        lat: 12.989757905366472,
        lng: 80.18567806590474,
      },
      {
        lat: 12.99005670725444,
        lng: 80.18567553706684,
      },
      {
        lat: 12.990463180077786,
        lng: 80.18551026063832,
      },
      {
        lat: 12.990788325526717,
        lng: 80.18537264522848,
      },
      {
        lat: 12.99114194717891,
        lng: 80.18545057060835,
      },
      {
        lat: 12.991767042861763,
        lng: 80.18549922570946,
      },
      {
        lat: 12.992311139269027,
        lng: 80.18562948450003,
      },
      {
        lat: 12.992719908784506,
        lng: 80.18584180676922,
      },
      {
        lat: 12.993481479705041,
        lng: 80.18599719774046,
      },
      {
        lat: 12.994092255905567,
        lng: 80.18609805047721,
      },
      {
        lat: 12.994304096296663,
        lng: 80.18673861138251,
      },
      {
        lat: 12.99564744088989,
        lng: 80.18919682258144,
      },
      {
        lat: 12.99616674325481,
        lng: 80.19093235914643,
      },
      {
        lat: 12.996747861240488,
        lng: 80.19353733832773,
      },
      {
        lat: 12.997166233210157,
        lng: 80.19726623135837,
      },
      {
        lat: 12.997118413600107,
        lng: 80.19869787008973,
      },
      {
        lat: 12.995963978955865,
        lng: 80.19840937689159,
      },
      {
        lat: 12.995691195490055,
        lng: 80.19835757796909,
      },
      {
        lat: 12.99536490840275,
        lng: 80.19846855454061,
      },
      {
        lat: 12.995001745589933,
        lng: 80.19848966393484,
      },
      {
        lat: 12.994782685135739,
        lng: 80.1983111593684,
      },
      {
        lat: 12.994411953371424,
        lng: 80.19708785895024,
      },
      {
        lat: 12.994229549252779,
        lng: 80.19696315171126,
      },
      {
        lat: 12.99273969319104,
        lng: 80.19690361733673,
      },
      {
        lat: 12.992030982715756,
        lng: 80.19685550777764,
      },
      {
        lat: 12.991431144298224,
        lng: 80.19678844095299,
      },
      {
        lat: 12.991176524457906,
        lng: 80.19673648830704,
      },
      {
        lat: 12.991049598470013,
        lng: 80.19677363422096,
      },
      {
        lat: 12.990942371836967,
        lng: 80.19706311535909,
      },
      {
        lat: 12.990857038457458,
        lng: 80.19796563021723,
      },
      {
        lat: 12.990732854843298,
        lng: 80.19845364925152,
      },
    ],
    properties: {
      Ward_No: 162,
      Zone_Name: "ALANDUR",
      fillColor: "#8bc24c",
      zname: "ALANDUR(162)",
    },
  },
  {
    casecoords: [
      {
        lat: 12.987650739991743,
        lng: 80.20121635370063,
      },
      {
        lat: 12.995008330377763,
        lng: 80.20217708713575,
      },
      {
        lat: 12.990946429167817,
        lng: 80.20415807730976,
      },
      {
        lat: 12.988226086164927,
        lng: 80.20189431463986,
      },
      {
        lat: 12.99280835764183,
        lng: 80.20129317014401,
      },
      {
        lat: 12.989514088403444,
        lng: 80.1991896801502,
      },
      {
        lat: 12.987405258100107,
        lng: 80.20071645047324,
      },
      {
        lat: 12.989470368159418,
        lng: 80.20045430153495,
      },
      {
        lat: 12.992073608239558,
        lng: 80.20444451409715,
      },
      {
        lat: 12.99209822271076,
        lng: 80.20040216818923,
      },
      {
        lat: 12.991652418217713,
        lng: 80.20090691117376,
      },
      {
        lat: 12.992001036932072,
        lng: 80.20003731857001,
      },
      {
        lat: 12.990014769813556,
        lng: 80.20283961386934,
      },
      {
        lat: 12.990270502067826,
        lng: 80.2006588003194,
      },
      {
        lat: 12.994772747852634,
        lng: 80.20253234308747,
      },
      {
        lat: 12.991801792757197,
        lng: 80.20315222600108,
      },
      {
        lat: 12.989849547291225,
        lng: 80.20418265729927,
      },
      {
        lat: 12.994110763044516,
        lng: 80.20145073722995,
      },
      {
        lat: 12.994411322144577,
        lng: 80.20055860231695,
      },
      {
        lat: 12.991375657491322,
        lng: 80.20293745785273,
      },
      {
        lat: 12.990696575095122,
        lng: 80.20294170941635,
      },
      {
        lat: 12.987465327045152,
        lng: 80.20174449351933,
      },
      {
        lat: 12.99020008507478,
        lng: 80.20281319765586,
      },
      {
        lat: 12.991285167083285,
        lng: 80.20088820142405,
      },
      {
        lat: 12.991107601460431,
        lng: 80.20474061067796,
      },
      {
        lat: 12.990018014339997,
        lng: 80.20320834026126,
      },
      {
        lat: 12.988354069329151,
        lng: 80.19919984047817,
      },
      {
        lat: 12.98995476409669,
        lng: 80.2039970190672,
      },
      {
        lat: 12.991500043883702,
        lng: 80.20011018077204,
      },
      {
        lat: 12.990095382908578,
        lng: 80.20499975512128,
      },
      {
        lat: 12.989365440672653,
        lng: 80.20151478446826,
      },
      {
        lat: 12.990758572645158,
        lng: 80.20511836756523,
      },
      {
        lat: 12.988085397114537,
        lng: 80.19978955790738,
      },
      {
        lat: 12.986931047220951,
        lng: 80.19964670831943,
      },
      {
        lat: 12.986711527955434,
        lng: 80.19894515085083,
      },
      {
        lat: 12.991002082916724,
        lng: 80.19892713087992,
      },
      {
        lat: 12.987773755337757,
        lng: 80.19968356358311,
      },
      {
        lat: 12.990652388444882,
        lng: 80.19894039540202,
      },
      {
        lat: 12.988103044571659,
        lng: 80.2000834668729,
      },
      {
        lat: 12.987752376546567,
        lng: 80.2021492649181,
      },
      {
        lat: 12.99052910061993,
        lng: 80.20098611091488,
      },
      {
        lat: 12.993510190876323,
        lng: 80.19985228662865,
      },
      {
        lat: 12.992308861407135,
        lng: 80.20036638373902,
      },
      {
        lat: 12.991243873271477,
        lng: 80.20451438641823,
      },
      {
        lat: 12.989601605609815,
        lng: 80.20180405626334,
      },
      {
        lat: 12.992153524712558,
        lng: 80.20321326993529,
      },
      {
        lat: 12.990490846587889,
        lng: 80.20207223101652,
      },
      {
        lat: 12.99176728544267,
        lng: 80.2053424252876,
      },
      {
        lat: 12.994136750851641,
        lng: 80.20022038922266,
      },
      {
        lat: 12.987619282041884,
        lng: 80.19989608639351,
      },
      {
        lat: 12.988883083529844,
        lng: 80.19811968613952,
      },
      {
        lat: 12.994116941276554,
        lng: 80.20267398485436,
      },
      {
        lat: 12.99361020835403,
        lng: 80.20184929756522,
      },
      {
        lat: 12.986340504402461,
        lng: 80.19850282961008,
      },
      {
        lat: 12.994529564439185,
        lng: 80.2026848580315,
      },
      {
        lat: 12.991775140545865,
        lng: 80.20198871018687,
      },
      {
        lat: 12.988690793246214,
        lng: 80.19988717352061,
      },
      {
        lat: 12.990638039272383,
        lng: 80.20021601745593,
      },
      {
        lat: 12.987468180160556,
        lng: 80.19965226008172,
      },
      {
        lat: 12.993509644411953,
        lng: 80.20009164465661,
      },
      {
        lat: 12.993635889095746,
        lng: 80.20077860534755,
      },
      {
        lat: 12.98972641247428,
        lng: 80.20322303012539,
      },
      {
        lat: 12.990513739340475,
        lng: 80.19858296671342,
      },
      {
        lat: 12.98820469966535,
        lng: 80.19932170475755,
      },
      {
        lat: 12.993129310922612,
        lng: 80.20014203729977,
      },
      {
        lat: 12.989195847162106,
        lng: 80.20150094888926,
      },
      {
        lat: 12.98833224887811,
        lng: 80.1983550857378,
      },
      {
        lat: 12.98968567308468,
        lng: 80.19826695657227,
      },
      {
        lat: 12.993982491895329,
        lng: 80.20090205872759,
      },
      {
        lat: 12.993373304351971,
        lng: 80.1998042182045,
      },
    ],
    cases: 70,
    coords: [
      {
        lat: 12.988742586558743,
        lng: 80.20496916271065,
      },
      {
        lat: 12.989451249484688,
        lng: 80.20241517093297,
      },
      {
        lat: 12.989396577141921,
        lng: 80.20223824545481,
      },
      {
        lat: 12.989182203305779,
        lng: 80.20224005975615,
      },
      {
        lat: 12.988646376603945,
        lng: 80.20226233341502,
      },
      {
        lat: 12.985964869550465,
        lng: 80.20198346779104,
      },
      {
        lat: 12.985839170815732,
        lng: 80.20187809870137,
      },
      {
        lat: 12.985909118323946,
        lng: 80.20162916325933,
      },
      {
        lat: 12.986137688441886,
        lng: 80.20102410898411,
      },
      {
        lat: 12.986413702381745,
        lng: 80.1994075405284,
      },
      {
        lat: 12.98634008666904,
        lng: 80.19905338718866,
      },
      {
        lat: 12.986178335296534,
        lng: 80.19889510676637,
      },
      {
        lat: 12.985980315509698,
        lng: 80.19864843920101,
      },
      {
        lat: 12.98597880507253,
        lng: 80.19840010852609,
      },
      {
        lat: 12.986103317034969,
        lng: 80.19831036065692,
      },
      {
        lat: 12.98651495543953,
        lng: 80.19843104858334,
      },
      {
        lat: 12.986890325429298,
        lng: 80.19846334936666,
      },
      {
        lat: 12.987085971672245,
        lng: 80.19831978301427,
      },
      {
        lat: 12.987244810058542,
        lng: 80.1979991399919,
      },
      {
        lat: 12.98715386930138,
        lng: 80.19773382737058,
      },
      {
        lat: 12.986849849368392,
        lng: 80.19768318391466,
      },
      {
        lat: 12.985919601418196,
        lng: 80.19747819102304,
      },
      {
        lat: 12.985633014418152,
        lng: 80.19735644475398,
      },
      {
        lat: 12.985643907797638,
        lng: 80.19709537323168,
      },
      {
        lat: 12.986107333027912,
        lng: 80.19736117838315,
      },
      {
        lat: 12.987521327488777,
        lng: 80.1975919658966,
      },
      {
        lat: 12.988963306005004,
        lng: 80.19795738019357,
      },
      {
        lat: 12.990732854843298,
        lng: 80.19845364925152,
      },
      {
        lat: 12.992024556450067,
        lng: 80.19878539845652,
      },
      {
        lat: 12.993538609359511,
        lng: 80.19983669997251,
      },
      {
        lat: 12.994267787053998,
        lng: 80.20026338922926,
      },
      {
        lat: 12.994958882378358,
        lng: 80.20040182713579,
      },
      {
        lat: 12.99508668592579,
        lng: 80.2005089606205,
      },
      {
        lat: 12.995088221655074,
        lng: 80.20076144956795,
      },
      {
        lat: 12.995203346324534,
        lng: 80.20177048301674,
      },
      {
        lat: 12.995514102465297,
        lng: 80.20209249836437,
      },
      {
        lat: 12.9957711355945,
        lng: 80.20254121935632,
      },
      {
        lat: 12.995827052505097,
        lng: 80.20277521220846,
      },
      {
        lat: 12.992666471992047,
        lng: 80.20278392516771,
      },
      {
        lat: 12.99263398406451,
        lng: 80.20341545498327,
      },
      {
        lat: 12.991998476565731,
        lng: 80.20345690515441,
      },
      {
        lat: 12.992000780159666,
        lng: 80.20383563857558,
      },
      {
        lat: 12.992130558216184,
        lng: 80.20426740070693,
      },
      {
        lat: 12.992097521813898,
        lng: 80.20480875589834,
      },
      {
        lat: 12.992026622431554,
        lng: 80.20509792958933,
      },
      {
        lat: 12.991911855635838,
        lng: 80.20585566797769,
      },
      {
        lat: 12.989105338957815,
        lng: 80.20505600704212,
      },
      {
        lat: 12.988742586558743,
        lng: 80.20496916271065,
      },
    ],
    properties: {
      Ward_No: 163,
      Zone_Name: "ALANDUR",
      fillColor: "#f6d04d",
      zname: "ALANDUR(163)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.037847421859903,
        lng: 80.1553645964491,
      },
      {
        lat: 13.037777315078174,
        lng: 80.15264466091607,
      },
      {
        lat: 13.038200367884535,
        lng: 80.15959974912143,
      },
      {
        lat: 13.038314774220975,
        lng: 80.15007264083162,
      },
      {
        lat: 13.034618533785991,
        lng: 80.14876139641608,
      },
      {
        lat: 13.03484814656922,
        lng: 80.15593274183408,
      },
      {
        lat: 13.039121775279975,
        lng: 80.15073619646817,
      },
      {
        lat: 13.040599984123448,
        lng: 80.15948569470056,
      },
      {
        lat: 13.035819362288082,
        lng: 80.15193930323204,
      },
      {
        lat: 13.038366531872503,
        lng: 80.14774281454858,
      },
      {
        lat: 13.038746178678348,
        lng: 80.15899046398057,
      },
      {
        lat: 13.0348050121166,
        lng: 80.14898223788776,
      },
      {
        lat: 13.036969485228118,
        lng: 80.14929738262204,
      },
      {
        lat: 13.037622070748789,
        lng: 80.15138260602554,
      },
      {
        lat: 13.03501594501044,
        lng: 80.14769598473107,
      },
      {
        lat: 13.03500381204969,
        lng: 80.15595060290654,
      },
      {
        lat: 13.03502485282207,
        lng: 80.1485516191313,
      },
      {
        lat: 13.039348904942951,
        lng: 80.14964700660056,
      },
      {
        lat: 13.035565798589424,
        lng: 80.15232549658904,
      },
      {
        lat: 13.038301257905916,
        lng: 80.14796429995813,
      },
      {
        lat: 13.040192045042133,
        lng: 80.15829846807736,
      },
      {
        lat: 13.038996979046814,
        lng: 80.15627949794779,
      },
      {
        lat: 13.036736830264894,
        lng: 80.15169554397917,
      },
      {
        lat: 13.038503155076004,
        lng: 80.15564836445364,
      },
      {
        lat: 13.034985966790932,
        lng: 80.15002396887103,
      },
      {
        lat: 13.036618037653383,
        lng: 80.15405948647496,
      },
      {
        lat: 13.038094106465092,
        lng: 80.15020079611112,
      },
      {
        lat: 13.038209223970735,
        lng: 80.14972649661554,
      },
      {
        lat: 13.038059073893525,
        lng: 80.15898875305666,
      },
      {
        lat: 13.038726764403805,
        lng: 80.14978628203853,
      },
      {
        lat: 13.035263752039931,
        lng: 80.14757451506863,
      },
      {
        lat: 13.035159110388564,
        lng: 80.15367410652793,
      },
      {
        lat: 13.039114041939731,
        lng: 80.15899232003825,
      },
      {
        lat: 13.03765021384746,
        lng: 80.149669652978,
      },
      {
        lat: 13.036512264769659,
        lng: 80.15166072163602,
      },
      {
        lat: 13.037610287776419,
        lng: 80.14946123895584,
      },
      {
        lat: 13.036060560018297,
        lng: 80.1545881363776,
      },
      {
        lat: 13.038042555032579,
        lng: 80.1596816840415,
      },
      {
        lat: 13.038313142197909,
        lng: 80.15328350572454,
      },
      {
        lat: 13.037863394630653,
        lng: 80.15106911121619,
      },
      {
        lat: 13.039075304637587,
        lng: 80.14984138522341,
      },
      {
        lat: 13.036683219380532,
        lng: 80.1492020284927,
      },
      {
        lat: 13.038159501381275,
        lng: 80.15566086145445,
      },
      {
        lat: 13.036166036282237,
        lng: 80.15263220268993,
      },
      {
        lat: 13.03678569209293,
        lng: 80.15527032700975,
      },
      {
        lat: 13.034759443591165,
        lng: 80.15084843739301,
      },
      {
        lat: 13.038177046060007,
        lng: 80.15056663374486,
      },
      {
        lat: 13.037110125904274,
        lng: 80.15834247145288,
      },
      {
        lat: 13.040816780237812,
        lng: 80.14898492682144,
      },
      {
        lat: 13.040333178243417,
        lng: 80.14910603958117,
      },
      {
        lat: 13.036131484546113,
        lng: 80.15515145502735,
      },
      {
        lat: 13.035841392057655,
        lng: 80.15173831859862,
      },
      {
        lat: 13.038337401681853,
        lng: 80.15608121051905,
      },
      {
        lat: 13.038858174306249,
        lng: 80.15355845927812,
      },
      {
        lat: 13.035183666534078,
        lng: 80.15512191241493,
      },
      {
        lat: 13.040204495582909,
        lng: 80.1556791673855,
      },
      {
        lat: 13.03894026426396,
        lng: 80.15327353807523,
      },
      {
        lat: 13.038841401183682,
        lng: 80.15641000486356,
      },
      {
        lat: 13.041082286191802,
        lng: 80.15288900751206,
      },
      {
        lat: 13.034280361216156,
        lng: 80.15013269981569,
      },
      {
        lat: 13.03524205469695,
        lng: 80.14753160080615,
      },
      {
        lat: 13.03988208237403,
        lng: 80.1539647689446,
      },
      {
        lat: 13.037934969990124,
        lng: 80.15373429510355,
      },
      {
        lat: 13.038323071567904,
        lng: 80.15582311868327,
      },
      {
        lat: 13.040345480731428,
        lng: 80.15771153017825,
      },
      {
        lat: 13.040767226383199,
        lng: 80.16012513104754,
      },
      {
        lat: 13.040681617014663,
        lng: 80.14954278088814,
      },
      {
        lat: 13.039964802394174,
        lng: 80.15640365559203,
      },
      {
        lat: 13.036891599218942,
        lng: 80.15013514360083,
      },
      {
        lat: 13.040944924485126,
        lng: 80.1606100732202,
      },
      {
        lat: 13.039937407493312,
        lng: 80.14620274528315,
      },
      {
        lat: 13.039247044531402,
        lng: 80.15687828890293,
      },
      {
        lat: 13.040414005440462,
        lng: 80.15855021173353,
      },
      {
        lat: 13.036717531571915,
        lng: 80.15698300851669,
      },
      {
        lat: 13.037222802903472,
        lng: 80.15800500778472,
      },
      {
        lat: 13.036528557870959,
        lng: 80.15684078988895,
      },
      {
        lat: 13.03956562915049,
        lng: 80.15505606333552,
      },
      {
        lat: 13.041051703130767,
        lng: 80.15271516726136,
      },
      {
        lat: 13.040274959540872,
        lng: 80.14735449580425,
      },
      {
        lat: 13.040206734517106,
        lng: 80.14961770166661,
      },
      {
        lat: 13.038464670211072,
        lng: 80.15470584503615,
      },
      {
        lat: 13.039551098443079,
        lng: 80.15030323577601,
      },
      {
        lat: 13.039994514968727,
        lng: 80.15165830441013,
      },
      {
        lat: 13.04005624430169,
        lng: 80.15577368704412,
      },
      {
        lat: 13.038306160188263,
        lng: 80.14906348873964,
      },
      {
        lat: 13.037755373827107,
        lng: 80.1544778129384,
      },
      {
        lat: 13.036067911476842,
        lng: 80.14917116907316,
      },
      {
        lat: 13.040457444366563,
        lng: 80.15408531786866,
      },
      {
        lat: 13.03951285194472,
        lng: 80.14698111105785,
      },
      {
        lat: 13.036963904276497,
        lng: 80.15608002918475,
      },
      {
        lat: 13.040854361338114,
        lng: 80.15191095758397,
      },
      {
        lat: 13.036856622359903,
        lng: 80.15583609772885,
      },
      {
        lat: 13.035991601699534,
        lng: 80.15042081994947,
      },
      {
        lat: 13.036744417317918,
        lng: 80.15204325067765,
      },
      {
        lat: 13.035065652206576,
        lng: 80.15298886035862,
      },
      {
        lat: 13.035417252508523,
        lng: 80.1552784361601,
      },
      {
        lat: 13.038653204255361,
        lng: 80.14698705613388,
      },
      {
        lat: 13.038524489282393,
        lng: 80.15837328887532,
      },
      {
        lat: 13.038746577321662,
        lng: 80.15902913390639,
      },
      {
        lat: 13.03504515697472,
        lng: 80.15121060614814,
      },
      {
        lat: 13.037945096950093,
        lng: 80.15185693422885,
      },
      {
        lat: 13.035585368526055,
        lng: 80.15585917994758,
      },
      {
        lat: 13.039017454309976,
        lng: 80.15939363083098,
      },
      {
        lat: 13.03792177399085,
        lng: 80.14917489184984,
      },
      {
        lat: 13.03913456308914,
        lng: 80.15210398546294,
      },
      {
        lat: 13.040711329630323,
        lng: 80.15217943150031,
      },
      {
        lat: 13.04059126810237,
        lng: 80.14740812896763,
      },
      {
        lat: 13.040279968177678,
        lng: 80.16059589520586,
      },
      {
        lat: 13.03998140095814,
        lng: 80.15971326052805,
      },
      {
        lat: 13.040699439536,
        lng: 80.15478766848331,
      },
      {
        lat: 13.035454425119738,
        lng: 80.15134102317313,
      },
      {
        lat: 13.037840331617486,
        lng: 80.15009362159893,
      },
      {
        lat: 13.039192604683233,
        lng: 80.15390931369397,
      },
      {
        lat: 13.038336286804265,
        lng: 80.16060234030024,
      },
      {
        lat: 13.037782480955503,
        lng: 80.14933889443029,
      },
      {
        lat: 13.038580416072472,
        lng: 80.14984197162654,
      },
      {
        lat: 13.038886619730611,
        lng: 80.15163189282858,
      },
      {
        lat: 13.037857669554207,
        lng: 80.16022089464089,
      },
      {
        lat: 13.038390441136093,
        lng: 80.14937817612456,
      },
      {
        lat: 13.03669780421711,
        lng: 80.15150023356877,
      },
      {
        lat: 13.040625662253136,
        lng: 80.14949093434596,
      },
      {
        lat: 13.038672746649416,
        lng: 80.14945991485042,
      },
      {
        lat: 13.037508446622445,
        lng: 80.1549521510427,
      },
    ],
    cases: 123,
    coords: [
      {
        lat: 13.041056827266315,
        lng: 80.16075546247254,
      },
      {
        lat: 13.040086237979871,
        lng: 80.1608325466493,
      },
      {
        lat: 13.039532350732092,
        lng: 80.16100048673985,
      },
      {
        lat: 13.03782586334564,
        lng: 80.16089248988578,
      },
      {
        lat: 13.037740059582315,
        lng: 80.16030142611825,
      },
      {
        lat: 13.037446882945094,
        lng: 80.15940601915284,
      },
      {
        lat: 13.036780435134876,
        lng: 80.15796279443109,
      },
      {
        lat: 13.03613838596431,
        lng: 80.157151966264,
      },
      {
        lat: 13.035623739245795,
        lng: 80.1570134760034,
      },
      {
        lat: 13.035086927932923,
        lng: 80.15660988817888,
      },
      {
        lat: 13.034489456178411,
        lng: 80.15637006613858,
      },
      {
        lat: 13.034364784226813,
        lng: 80.15614664922076,
      },
      {
        lat: 13.034322689083803,
        lng: 80.1559837530837,
      },
      {
        lat: 13.03441973544084,
        lng: 80.15504423045999,
      },
      {
        lat: 13.034491390248794,
        lng: 80.15330906728582,
      },
      {
        lat: 13.034291784786538,
        lng: 80.15090278371126,
      },
      {
        lat: 13.033917979272193,
        lng: 80.14790305777844,
      },
      {
        lat: 13.033939971664674,
        lng: 80.14682587805265,
      },
      {
        lat: 13.034224709365027,
        lng: 80.14671010049632,
      },
      {
        lat: 13.03490990704237,
        lng: 80.14673264342757,
      },
      {
        lat: 13.035710482242571,
        lng: 80.14695260344648,
      },
      {
        lat: 13.036366447690801,
        lng: 80.14686202603032,
      },
      {
        lat: 13.037105455645843,
        lng: 80.14634561675197,
      },
      {
        lat: 13.037560760162881,
        lng: 80.1461150278974,
      },
      {
        lat: 13.038473954852888,
        lng: 80.146078957357,
      },
      {
        lat: 13.038844665469874,
        lng: 80.14601913606337,
      },
      {
        lat: 13.039729317438947,
        lng: 80.14598330708742,
      },
      {
        lat: 13.040243258791145,
        lng: 80.1460072994054,
      },
      {
        lat: 13.040387523788883,
        lng: 80.1462611558844,
      },
      {
        lat: 13.04047522047633,
        lng: 80.14660051692628,
      },
      {
        lat: 13.040506348852961,
        lng: 80.14702538253074,
      },
      {
        lat: 13.04079281032368,
        lng: 80.14719300975386,
      },
      {
        lat: 13.041078927040507,
        lng: 80.14730395602122,
      },
      {
        lat: 13.041166451350717,
        lng: 80.14761497658509,
      },
      {
        lat: 13.04122732908764,
        lng: 80.14823798397065,
      },
      {
        lat: 13.041258112710336,
        lng: 80.1486061686193,
      },
      {
        lat: 13.041060209811961,
        lng: 80.1489196048277,
      },
      {
        lat: 13.041004848140904,
        lng: 80.14920349273581,
      },
      {
        lat: 13.040864720192575,
        lng: 80.14962980772708,
      },
      {
        lat: 13.0398942676837,
        lng: 80.14963802091836,
      },
      {
        lat: 13.03978164819395,
        lng: 80.1498940514774,
      },
      {
        lat: 13.039755346374783,
        lng: 80.15026271925511,
      },
      {
        lat: 13.040012920370666,
        lng: 80.15037390708666,
      },
      {
        lat: 13.040755892997883,
        lng: 80.15050932880064,
      },
      {
        lat: 13.040984924272891,
        lng: 80.15062075819684,
      },
      {
        lat: 13.041014673633239,
        lng: 80.15081889997782,
      },
      {
        lat: 13.041113402452197,
        lng: 80.15297205160716,
      },
      {
        lat: 13.041199964370836,
        lng: 80.1578177902044,
      },
      {
        lat: 13.04109010291402,
        lng: 80.15852726841054,
      },
      {
        lat: 13.041263944894869,
        lng: 80.15895092619287,
      },
      {
        lat: 13.041295245648682,
        lng: 80.15940413227528,
      },
      {
        lat: 13.041126230225359,
        lng: 80.159774007875,
      },
      {
        lat: 13.041073454210181,
        lng: 80.16048300295223,
      },
      {
        lat: 13.041056827266315,
        lng: 80.16075546247254,
      },
    ],
    properties: {
      Ward_No: 150,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#de4307",
      zname: "VALASARAVAKKAM(150)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.026838908234032,
        lng: 80.15662663584114,
      },
      {
        lat: 13.027407506402767,
        lng: 80.15504320195578,
      },
      {
        lat: 13.031763675615384,
        lng: 80.15385950734974,
      },
      {
        lat: 13.028216228723924,
        lng: 80.1564498131887,
      },
      {
        lat: 13.027810368358534,
        lng: 80.14811922124503,
      },
      {
        lat: 13.023528411939411,
        lng: 80.16129744814423,
      },
      {
        lat: 13.028560304638175,
        lng: 80.15250383205716,
      },
      {
        lat: 13.029249107114373,
        lng: 80.14665887548404,
      },
      {
        lat: 13.029391060764022,
        lng: 80.16115477164321,
      },
      {
        lat: 13.031006429990358,
        lng: 80.15298671229465,
      },
      {
        lat: 13.03122737494829,
        lng: 80.14944168031181,
      },
      {
        lat: 13.0322568813132,
        lng: 80.14805953180701,
      },
      {
        lat: 13.030403879064469,
        lng: 80.16264295636954,
      },
      {
        lat: 13.031610538011401,
        lng: 80.14800118740365,
      },
      {
        lat: 13.026660958175007,
        lng: 80.15257212943129,
      },
      {
        lat: 13.028673546018013,
        lng: 80.14666191404437,
      },
      {
        lat: 13.025789414707539,
        lng: 80.1529289522186,
      },
      {
        lat: 13.03088361938335,
        lng: 80.15065219579148,
      },
      {
        lat: 13.033382189149483,
        lng: 80.15290866043269,
      },
      {
        lat: 13.026311277426098,
        lng: 80.14552363024836,
      },
      {
        lat: 13.027139801661848,
        lng: 80.16244943074018,
      },
      {
        lat: 13.025589157025324,
        lng: 80.14937868668949,
      },
      {
        lat: 13.027855644402862,
        lng: 80.16363541777551,
      },
      {
        lat: 13.025943094160572,
        lng: 80.16265239223253,
      },
      {
        lat: 13.03293368552369,
        lng: 80.14908106915107,
      },
      {
        lat: 13.024371569701275,
        lng: 80.15890788591634,
      },
      {
        lat: 13.025673160304713,
        lng: 80.16265840373364,
      },
      {
        lat: 13.025147077877518,
        lng: 80.15978151917828,
      },
      {
        lat: 13.030646370501369,
        lng: 80.15664116461323,
      },
      {
        lat: 13.033791021888174,
        lng: 80.15743182389693,
      },
      {
        lat: 13.03249420239987,
        lng: 80.16071006239282,
      },
      {
        lat: 13.028302777437439,
        lng: 80.16017791129549,
      },
      {
        lat: 13.026513996385138,
        lng: 80.1500381972867,
      },
      {
        lat: 13.031955902349015,
        lng: 80.14891807248028,
      },
      {
        lat: 13.024136618851987,
        lng: 80.16554571072618,
      },
      {
        lat: 13.021733258523492,
        lng: 80.16612529526468,
      },
      {
        lat: 13.031082355507529,
        lng: 80.15038453011361,
      },
      {
        lat: 13.03026423849171,
        lng: 80.14755775736002,
      },
      {
        lat: 13.028935803740074,
        lng: 80.15695841470878,
      },
      {
        lat: 13.034451487463876,
        lng: 80.1536999556045,
      },
      {
        lat: 13.032509155792662,
        lng: 80.15031389219727,
      },
      {
        lat: 13.028458813049138,
        lng: 80.15593041473916,
      },
      {
        lat: 13.027916122947307,
        lng: 80.14667836789123,
      },
      {
        lat: 13.029954176069813,
        lng: 80.14696970651671,
      },
      {
        lat: 13.032831676634222,
        lng: 80.15850182428065,
      },
      {
        lat: 13.027102871982589,
        lng: 80.15161150349033,
      },
      {
        lat: 13.025590422235567,
        lng: 80.15616038484124,
      },
      {
        lat: 13.032035680247297,
        lng: 80.15726887466298,
      },
      {
        lat: 13.030363885631376,
        lng: 80.16113259026501,
      },
      {
        lat: 13.02720855897369,
        lng: 80.15387963250909,
      },
      {
        lat: 13.028859566794608,
        lng: 80.1503908514296,
      },
      {
        lat: 13.02778550196409,
        lng: 80.16544839967311,
      },
      {
        lat: 13.027879621838357,
        lng: 80.1521741912628,
      },
      {
        lat: 13.027298342890438,
        lng: 80.14892418242005,
      },
      {
        lat: 13.030586993104524,
        lng: 80.16476915546173,
      },
      {
        lat: 13.029573303445831,
        lng: 80.15675342728711,
      },
      {
        lat: 13.029383682590852,
        lng: 80.15790563414993,
      },
      {
        lat: 13.03021365703368,
        lng: 80.14803136737838,
      },
      {
        lat: 13.02917239019364,
        lng: 80.16428280615473,
      },
      {
        lat: 13.031995457793679,
        lng: 80.15407014743437,
      },
      {
        lat: 13.031655590845432,
        lng: 80.15370531684967,
      },
      {
        lat: 13.026398237659736,
        lng: 80.15667917576026,
      },
      {
        lat: 13.033076878101873,
        lng: 80.15482520581736,
      },
      {
        lat: 13.028838649001578,
        lng: 80.15511804918503,
      },
      {
        lat: 13.02716784596759,
        lng: 80.16279511202639,
      },
      {
        lat: 13.02864470545891,
        lng: 80.1608703458389,
      },
      {
        lat: 13.033275529155606,
        lng: 80.16025431723057,
      },
      {
        lat: 13.031573725537127,
        lng: 80.15262384127946,
      },
      {
        lat: 13.028151715417193,
        lng: 80.15992618166358,
      },
      {
        lat: 13.029073426368038,
        lng: 80.15526169884221,
      },
      {
        lat: 13.02494400369282,
        lng: 80.16098627726467,
      },
      {
        lat: 13.02644532676307,
        lng: 80.16133487320478,
      },
      {
        lat: 13.030428498587268,
        lng: 80.16073898538998,
      },
      {
        lat: 13.027195563824575,
        lng: 80.14845534185243,
      },
      {
        lat: 13.024729572199359,
        lng: 80.16521316031596,
      },
      {
        lat: 13.028918899701615,
        lng: 80.14880629189969,
      },
      {
        lat: 13.027417740624713,
        lng: 80.14819606098801,
      },
      {
        lat: 13.027725491414774,
        lng: 80.15553664169639,
      },
      {
        lat: 13.030441221268509,
        lng: 80.15286399795217,
      },
      {
        lat: 13.025779791304554,
        lng: 80.15019560602353,
      },
      {
        lat: 13.02840966979259,
        lng: 80.15227870637658,
      },
      {
        lat: 13.029341046385907,
        lng: 80.14628513107657,
      },
      {
        lat: 13.027246851803694,
        lng: 80.15191638520689,
      },
      {
        lat: 13.028587955918082,
        lng: 80.1607477026457,
      },
      {
        lat: 13.033075449857517,
        lng: 80.15253830384701,
      },
      {
        lat: 13.026958455866996,
        lng: 80.16074431666951,
      },
      {
        lat: 13.030966525551449,
        lng: 80.16005488425196,
      },
      {
        lat: 13.026467412781003,
        lng: 80.1482738357797,
      },
      {
        lat: 13.029529465424115,
        lng: 80.15464555496771,
      },
      {
        lat: 13.029997479313272,
        lng: 80.16097856364374,
      },
      {
        lat: 13.030886766441899,
        lng: 80.1616642657466,
      },
      {
        lat: 13.027048239178907,
        lng: 80.15073900501034,
      },
      {
        lat: 13.033137286130932,
        lng: 80.14903597716697,
      },
      {
        lat: 13.031312415396183,
        lng: 80.15290983279256,
      },
      {
        lat: 13.027461131334602,
        lng: 80.1469683722825,
      },
      {
        lat: 13.030008480008554,
        lng: 80.15535532910162,
      },
      {
        lat: 13.029933274635106,
        lng: 80.15283942843895,
      },
      {
        lat: 13.029275876869661,
        lng: 80.14842264256633,
      },
      {
        lat: 13.024875890125232,
        lng: 80.15740624770456,
      },
      {
        lat: 13.032413839729282,
        lng: 80.15365065327505,
      },
      {
        lat: 13.0262993184468,
        lng: 80.16016987552932,
      },
      {
        lat: 13.024149570733586,
        lng: 80.16126934428041,
      },
      {
        lat: 13.025886550000964,
        lng: 80.16202164818887,
      },
      {
        lat: 13.03185542666637,
        lng: 80.16062509991542,
      },
      {
        lat: 13.031835285988945,
        lng: 80.15981469195435,
      },
      {
        lat: 13.027130420976315,
        lng: 80.15970853240782,
      },
      {
        lat: 13.023935737379299,
        lng: 80.16075607197219,
      },
      {
        lat: 13.027295285334233,
        lng: 80.1585518499761,
      },
      {
        lat: 13.033251910632636,
        lng: 80.16210207346549,
      },
      {
        lat: 13.03249504773128,
        lng: 80.1535435282745,
      },
      {
        lat: 13.026127254197553,
        lng: 80.16183194704735,
      },
      {
        lat: 13.031625511002837,
        lng: 80.15806559732468,
      },
      {
        lat: 13.028735329138811,
        lng: 80.14607577211265,
      },
      {
        lat: 13.03265439997405,
        lng: 80.14927477509504,
      },
      {
        lat: 13.026859912425001,
        lng: 80.15141645206003,
      },
      {
        lat: 13.028723456248665,
        lng: 80.15898300991768,
      },
      {
        lat: 13.03408599201972,
        lng: 80.15679054587936,
      },
      {
        lat: 13.026735039698412,
        lng: 80.15508704209866,
      },
      {
        lat: 13.029824962634384,
        lng: 80.15333144079173,
      },
      {
        lat: 13.024601999816278,
        lng: 80.16461668738344,
      },
      {
        lat: 13.028636420404126,
        lng: 80.15962667027597,
      },
      {
        lat: 13.027852794186098,
        lng: 80.15126265815242,
      },
      {
        lat: 13.031787463927245,
        lng: 80.16152854895813,
      },
      {
        lat: 13.031173514843488,
        lng: 80.16059424419444,
      },
      {
        lat: 13.027329469053491,
        lng: 80.15211572431559,
      },
      {
        lat: 13.026305878686877,
        lng: 80.15891768564131,
      },
      {
        lat: 13.029199729718192,
        lng: 80.1618893995779,
      },
      {
        lat: 13.033949268851778,
        lng: 80.15808621176559,
      },
      {
        lat: 13.03246350085536,
        lng: 80.15667810945094,
      },
      {
        lat: 13.02938583988605,
        lng: 80.1487410658774,
      },
      {
        lat: 13.030302123204375,
        lng: 80.1611432234912,
      },
      {
        lat: 13.02613326259969,
        lng: 80.15577348984887,
      },
      {
        lat: 13.026205823749734,
        lng: 80.15410896652705,
      },
      {
        lat: 13.030938123537112,
        lng: 80.16078680883868,
      },
      {
        lat: 13.026623339549753,
        lng: 80.16399211386398,
      },
      {
        lat: 13.03242752194037,
        lng: 80.16118649527844,
      },
      {
        lat: 13.025091654982518,
        lng: 80.15960286188306,
      },
      {
        lat: 13.030316622859823,
        lng: 80.15547855229038,
      },
      {
        lat: 13.033271584498596,
        lng: 80.16058770092489,
      },
      {
        lat: 13.029436394139164,
        lng: 80.15284947049759,
      },
      {
        lat: 13.03080540900827,
        lng: 80.14990567351316,
      },
      {
        lat: 13.027711197411055,
        lng: 80.14803440368915,
      },
      {
        lat: 13.031439733870885,
        lng: 80.1478568518973,
      },
      {
        lat: 13.032993144814439,
        lng: 80.15010710029117,
      },
      {
        lat: 13.02977773280136,
        lng: 80.16021600533969,
      },
      {
        lat: 13.033883029590134,
        lng: 80.15746493905773,
      },
      {
        lat: 13.03008009018853,
        lng: 80.16040676090076,
      },
      {
        lat: 13.028813982859429,
        lng: 80.16349273706415,
      },
      {
        lat: 13.030253571706753,
        lng: 80.15996972865963,
      },
      {
        lat: 13.027219052466116,
        lng: 80.1596383482609,
      },
      {
        lat: 13.031614552378082,
        lng: 80.15227755958935,
      },
      {
        lat: 13.0253886709421,
        lng: 80.15773755788639,
      },
      {
        lat: 13.03304013729812,
        lng: 80.16172294658116,
      },
      {
        lat: 13.027953366403057,
        lng: 80.16278070159888,
      },
      {
        lat: 13.029724075047776,
        lng: 80.15173487837598,
      },
      {
        lat: 13.025599544205674,
        lng: 80.15412346150677,
      },
      {
        lat: 13.024833891719993,
        lng: 80.16099218486973,
      },
      {
        lat: 13.031562719575822,
        lng: 80.152996744635,
      },
      {
        lat: 13.026676884681189,
        lng: 80.15499228665645,
      },
      {
        lat: 13.027900231857217,
        lng: 80.14836942931139,
      },
    ],
    cases: 160,
    coords: [
      {
        lat: 13.024373624868412,
        lng: 80.16750393618194,
      },
      {
        lat: 13.023850738867502,
        lng: 80.16749874761855,
      },
      {
        lat: 13.023445974965282,
        lng: 80.16725746069991,
      },
      {
        lat: 13.02299532022966,
        lng: 80.16683858941052,
      },
      {
        lat: 13.022455860534913,
        lng: 80.16655394926786,
      },
      {
        lat: 13.021760247984616,
        lng: 80.16638186366066,
      },
      {
        lat: 13.021535258879048,
        lng: 80.16622804163951,
      },
      {
        lat: 13.02160030659218,
        lng: 80.16587154561044,
      },
      {
        lat: 13.02173256689692,
        lng: 80.16551448074364,
      },
      {
        lat: 13.02200141726242,
        lng: 80.16551220539323,
      },
      {
        lat: 13.02215919377667,
        lng: 80.16566659625191,
      },
      {
        lat: 13.022516849101404,
        lng: 80.16553008975443,
      },
      {
        lat: 13.023008388389744,
        lng: 80.16530346378406,
      },
      {
        lat: 13.023389530350686,
        lng: 80.16534473126994,
      },
      {
        lat: 13.02397258069582,
        lng: 80.16542878314203,
      },
      {
        lat: 13.02421889155936,
        lng: 80.16540445195477,
      },
      {
        lat: 13.024329830106069,
        lng: 80.16522554029629,
      },
      {
        lat: 13.024394201294777,
        lng: 80.16475781701983,
      },
      {
        lat: 13.02459245644685,
        lng: 80.16419997427008,
      },
      {
        lat: 13.024541965247437,
        lng: 80.16326604461726,
      },
      {
        lat: 13.02460674235091,
        lng: 80.16286505768905,
      },
      {
        lat: 13.024493233011311,
        lng: 80.16262130580738,
      },
      {
        lat: 13.024112361660187,
        lng: 80.16262452922038,
      },
      {
        lat: 13.023528905400399,
        lng: 80.16247374099997,
      },
      {
        lat: 13.023146275085532,
        lng: 80.1621877735698,
      },
      {
        lat: 13.023099301813152,
        lng: 80.16183222560312,
      },
      {
        lat: 13.023096460410537,
        lng: 80.16136507116413,
      },
      {
        lat: 13.023159613855208,
        lng: 80.1606971388424,
      },
      {
        lat: 13.023335058895562,
        lng: 80.16007274935684,
      },
      {
        lat: 13.023624825104552,
        lng: 80.15982558444965,
      },
      {
        lat: 13.02409531324451,
        lng: 80.15982160258648,
      },
      {
        lat: 13.024409107309257,
        lng: 80.15984119346045,
      },
      {
        lat: 13.024565124859812,
        lng: 80.159706393476,
      },
      {
        lat: 13.024541773528485,
        lng: 80.1595508649421,
      },
      {
        lat: 13.024428399493942,
        lng: 80.15932935851004,
      },
      {
        lat: 13.024359292634099,
        lng: 80.159018491055,
      },
      {
        lat: 13.02437966725787,
        lng: 80.15868461970038,
      },
      {
        lat: 13.024444309056522,
        lng: 80.15826138732263,
      },
      {
        lat: 13.024420281200758,
        lng: 80.15799463154161,
      },
      {
        lat: 13.024485058304293,
        lng: 80.15759364461341,
      },
      {
        lat: 13.024770359452088,
        lng: 80.15661237987358,
      },
      {
        lat: 13.025057013648576,
        lng: 80.15585356962842,
      },
      {
        lat: 13.025075629308995,
        lng: 80.1552305074306,
      },
      {
        lat: 13.025027167682701,
        lng: 80.1546302595198,
      },
      {
        lat: 13.025112725324666,
        lng: 80.15396213758535,
      },
      {
        lat: 13.025772809103932,
        lng: 80.15197660420624,
      },
      {
        lat: 13.025838127426834,
        lng: 80.1516645990759,
      },
      {
        lat: 13.025724347477802,
        lng: 80.15137635629533,
      },
      {
        lat: 13.025319448270576,
        lng: 80.15111282392733,
      },
      {
        lat: 13.025250070801077,
        lng: 80.1507574655732,
      },
      {
        lat: 13.025337116796795,
        lng: 80.15033404358309,
      },
      {
        lat: 13.025535777863531,
        lng: 80.14984293718175,
      },
      {
        lat: 13.025466806308522,
        lng: 80.1495543151762,
      },
      {
        lat: 13.0251955204549,
        lng: 80.14915617243601,
      },
      {
        lat: 13.024405826054737,
        lng: 80.14825074544605,
      },
      {
        lat: 13.024357770343272,
        lng: 80.14771723388382,
      },
      {
        lat: 13.024465732182367,
        lng: 80.14704892233682,
      },
      {
        lat: 13.024822034458463,
        lng: 80.14668996134459,
      },
      {
        lat: 13.024932973005,
        lng: 80.1465110496863,
      },
      {
        lat: 13.024839838289395,
        lng: 80.1459334264501,
      },
      {
        lat: 13.02462504679949,
        lng: 80.1452608803428,
      },
      {
        lat: 13.026765233731851,
        lng: 80.14515774157535,
      },
      {
        lat: 13.027735858617614,
        lng: 80.14517786886212,
      },
      {
        lat: 13.028136318594772,
        lng: 80.14531618934944,
      },
      {
        lat: 13.03156213460454,
        lng: 80.14540056352742,
      },
      {
        lat: 13.031686475734501,
        lng: 80.14707168546748,
      },
      {
        lat: 13.031774000044487,
        lng: 80.14738270603141,
      },
      {
        lat: 13.032117202202945,
        lng: 80.14749316916966,
      },
      {
        lat: 13.033261324315609,
        lng: 80.14788027328274,
      },
      {
        lat: 13.033917979272193,
        lng: 80.14790305777844,
      },
      {
        lat: 13.034291784786538,
        lng: 80.15090278371126,
      },
      {
        lat: 13.034491390248794,
        lng: 80.15330906728582,
      },
      {
        lat: 13.03441973544084,
        lng: 80.15504423045999,
      },
      {
        lat: 13.034322689083803,
        lng: 80.1559837530837,
      },
      {
        lat: 13.034364784226813,
        lng: 80.15614664922076,
      },
      {
        lat: 13.034489456178411,
        lng: 80.15637006613858,
      },
      {
        lat: 13.034589630614072,
        lng: 80.15641027563356,
      },
      {
        lat: 13.034260715219785,
        lng: 80.15688369350168,
      },
      {
        lat: 13.034267735652522,
        lng: 80.15803792145213,
      },
      {
        lat: 13.034446843534631,
        lng: 80.15901877784235,
      },
      {
        lat: 13.03440155933552,
        lng: 80.15970682165292,
      },
      {
        lat: 13.034305165472114,
        lng: 80.16012514565539,
      },
      {
        lat: 13.034306957923024,
        lng: 80.16041984215326,
      },
      {
        lat: 13.03318162426505,
        lng: 80.16246778851416,
      },
      {
        lat: 13.031714505643084,
        lng: 80.16525540665963,
      },
      {
        lat: 13.03139536277846,
        lng: 80.16565105653913,
      },
      {
        lat: 13.031199139520458,
        lng: 80.16592286958968,
      },
      {
        lat: 13.03090219064263,
        lng: 80.16590082343932,
      },
      {
        lat: 13.030011194637954,
        lng: 80.16581012694701,
      },
      {
        lat: 13.028205216191354,
        lng: 80.16575173349386,
      },
      {
        lat: 13.026522754834842,
        lng: 80.16566773537814,
      },
      {
        lat: 13.025804891876028,
        lng: 80.16557557361571,
      },
      {
        lat: 13.02535954324477,
        lng: 80.16555478341095,
      },
      {
        lat: 13.02521323468407,
        lng: 80.1658998519376,
      },
      {
        lat: 13.024373624868412,
        lng: 80.16750393618194,
      },
    ],
    properties: {
      Ward_No: 153,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#de4307",
      zname: "VALASARAVAKKAM(153)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.044480837496998,
        lng: 80.17238896771242,
      },
      {
        lat: 13.050689309647018,
        lng: 80.18257524871709,
      },
      {
        lat: 13.04809936661724,
        lng: 80.18087259281786,
      },
      {
        lat: 13.047046738087804,
        lng: 80.1811880538261,
      },
      {
        lat: 13.04264973196281,
        lng: 80.17238233190503,
      },
      {
        lat: 13.047888558780821,
        lng: 80.17869731137205,
      },
      {
        lat: 13.04834529815333,
        lng: 80.17594177882893,
      },
      {
        lat: 13.045648234564773,
        lng: 80.1761062378205,
      },
      {
        lat: 13.051510124231756,
        lng: 80.18343927093632,
      },
      {
        lat: 13.044579067827623,
        lng: 80.18462438221789,
      },
      {
        lat: 13.044091300369379,
        lng: 80.1835729328666,
      },
      {
        lat: 13.051021282589174,
        lng: 80.18165279337443,
      },
      {
        lat: 13.051001573820427,
        lng: 80.17884831677758,
      },
      {
        lat: 13.048011162946866,
        lng: 80.17612247286624,
      },
      {
        lat: 13.048258735054867,
        lng: 80.18384327460373,
      },
      {
        lat: 13.044457560785805,
        lng: 80.17964550954859,
      },
      {
        lat: 13.04779842513803,
        lng: 80.17877809261265,
      },
      {
        lat: 13.046484163981939,
        lng: 80.17443450199582,
      },
      {
        lat: 13.04253276745032,
        lng: 80.1795479656782,
      },
      {
        lat: 13.049416262141788,
        lng: 80.17944931863497,
      },
      {
        lat: 13.045859256880444,
        lng: 80.17814744426248,
      },
      {
        lat: 13.048768801436413,
        lng: 80.1777250040404,
      },
      {
        lat: 13.045799258891867,
        lng: 80.18405311215696,
      },
      {
        lat: 13.051147602024058,
        lng: 80.17758100235996,
      },
      {
        lat: 13.05088613968435,
        lng: 80.18129000791134,
      },
      {
        lat: 13.047045668350579,
        lng: 80.17651699493797,
      },
      {
        lat: 13.044899626464032,
        lng: 80.18515472168667,
      },
      {
        lat: 13.044416739182054,
        lng: 80.1809519246909,
      },
      {
        lat: 13.047857431798773,
        lng: 80.17519925639996,
      },
      {
        lat: 13.047513699108148,
        lng: 80.1764502415842,
      },
      {
        lat: 13.048360145188196,
        lng: 80.18225542531584,
      },
      {
        lat: 13.046552816986422,
        lng: 80.18322119559403,
      },
      {
        lat: 13.042758023261678,
        lng: 80.17115689493424,
      },
      {
        lat: 13.045289156430837,
        lng: 80.18075403438132,
      },
      {
        lat: 13.044859400365825,
        lng: 80.1832589518576,
      },
      {
        lat: 13.043742758266811,
        lng: 80.17740063413245,
      },
      {
        lat: 13.042741646925533,
        lng: 80.17568462069728,
      },
      {
        lat: 13.049713960836847,
        lng: 80.17669475703354,
      },
      {
        lat: 13.051800966380313,
        lng: 80.18103799217974,
      },
      {
        lat: 13.043024943350062,
        lng: 80.17762199737848,
      },
      {
        lat: 13.048140363818543,
        lng: 80.1830839303332,
      },
      {
        lat: 13.04243778027751,
        lng: 80.17229039686791,
      },
      {
        lat: 13.045917666519847,
        lng: 80.1724243125435,
      },
      {
        lat: 13.047664981456569,
        lng: 80.18051138425557,
      },
      {
        lat: 13.042790565055379,
        lng: 80.1731684203004,
      },
      {
        lat: 13.045974760279847,
        lng: 80.17368927226599,
      },
      {
        lat: 13.043370519146679,
        lng: 80.1743952636679,
      },
      {
        lat: 13.05178103338369,
        lng: 80.17966491027349,
      },
      {
        lat: 13.048276741175908,
        lng: 80.18034638955025,
      },
      {
        lat: 13.04541294494642,
        lng: 80.17651616056698,
      },
      {
        lat: 13.051331052131612,
        lng: 80.17586063956038,
      },
      {
        lat: 13.049646297455505,
        lng: 80.1747401489681,
      },
      {
        lat: 13.050604266800226,
        lng: 80.17650038011755,
      },
      {
        lat: 13.04606991953378,
        lng: 80.17640112676204,
      },
      {
        lat: 13.04642240982529,
        lng: 80.18573456133385,
      },
      {
        lat: 13.049314221922831,
        lng: 80.18555939343793,
      },
      {
        lat: 13.04744407922677,
        lng: 80.18240834333064,
      },
      {
        lat: 13.047485910501399,
        lng: 80.17719657559736,
      },
      {
        lat: 13.042208789721412,
        lng: 80.17948352606238,
      },
      {
        lat: 13.04359719658223,
        lng: 80.18205134174818,
      },
      {
        lat: 13.042764520697059,
        lng: 80.17144827501731,
      },
      {
        lat: 13.048026196654254,
        lng: 80.18014656663034,
      },
      {
        lat: 13.051395674889513,
        lng: 80.17652513471435,
      },
      {
        lat: 13.044090787176534,
        lng: 80.18096696419508,
      },
      {
        lat: 13.044560718620033,
        lng: 80.18349179844954,
      },
      {
        lat: 13.049163424012956,
        lng: 80.18525370027986,
      },
      {
        lat: 13.046693157479579,
        lng: 80.17271700053259,
      },
      {
        lat: 13.049565583906817,
        lng: 80.1842405648073,
      },
      {
        lat: 13.047976285636329,
        lng: 80.17974978994157,
      },
      {
        lat: 13.042586338765364,
        lng: 80.18087517273496,
      },
      {
        lat: 13.045553593598262,
        lng: 80.17259512241708,
      },
      {
        lat: 13.043574510590567,
        lng: 80.1819947264126,
      },
      {
        lat: 13.048092163516321,
        lng: 80.18501330638317,
      },
      {
        lat: 13.043943655386823,
        lng: 80.18351584507046,
      },
    ],
    cases: 74,
    coords: [
      {
        lat: 13.045562047411893,
        lng: 80.18699434308041,
      },
      {
        lat: 13.045396277330331,
        lng: 80.18663836559453,
      },
      {
        lat: 13.045216925374607,
        lng: 80.18642771276005,
      },
      {
        lat: 13.044912285136851,
        lng: 80.18611203490434,
      },
      {
        lat: 13.043811484942031,
        lng: 80.18371674957952,
      },
      {
        lat: 13.043306030369727,
        lng: 80.18258945011024,
      },
      {
        lat: 13.042570601322728,
        lng: 80.18171162950074,
      },
      {
        lat: 13.04229705687411,
        lng: 80.18065309089513,
      },
      {
        lat: 13.042042286402634,
        lng: 80.17975352145281,
      },
      {
        lat: 13.041984351430715,
        lng: 80.17901141419296,
      },
      {
        lat: 13.042007166942302,
        lng: 80.17690719462726,
      },
      {
        lat: 13.041569173216477,
        lng: 80.17516049290413,
      },
      {
        lat: 13.041350767801779,
        lng: 80.17438438195809,
      },
      {
        lat: 13.041385089673541,
        lng: 80.1741719207465,
      },
      {
        lat: 13.042076303529985,
        lng: 80.17363564398275,
      },
      {
        lat: 13.04235915878086,
        lng: 80.1732973131055,
      },
      {
        lat: 13.042374814211671,
        lng: 80.17294356271519,
      },
      {
        lat: 13.042387136024239,
        lng: 80.17204173280159,
      },
      {
        lat: 13.04240160855806,
        lng: 80.17149350258032,
      },
      {
        lat: 13.042578272111868,
        lng: 80.17126215579944,
      },
      {
        lat: 13.042771666457059,
        lng: 80.17085385847423,
      },
      {
        lat: 13.042965065631545,
        lng: 80.17019207617219,
      },
      {
        lat: 13.043514830454166,
        lng: 80.17037420148213,
      },
      {
        lat: 13.044318433946081,
        lng: 80.17071431172559,
      },
      {
        lat: 13.044544992686824,
        lng: 80.17079402049973,
      },
      {
        lat: 13.044711139213218,
        lng: 80.17107830605708,
      },
      {
        lat: 13.044979420755975,
        lng: 80.17125969446988,
      },
      {
        lat: 13.04565934520544,
        lng: 80.17153963179145,
      },
      {
        lat: 13.04738712839068,
        lng: 80.17176988771298,
      },
      {
        lat: 13.0479018992229,
        lng: 80.1719287834731,
      },
      {
        lat: 13.048272067554747,
        lng: 80.17196646374231,
      },
      {
        lat: 13.048561024328313,
        lng: 80.17216808372513,
      },
      {
        lat: 13.049240204095911,
        lng: 80.17232558804982,
      },
      {
        lat: 13.049652964025343,
        lng: 80.17260778645394,
      },
      {
        lat: 13.049696052077499,
        lng: 80.17293392658705,
      },
      {
        lat: 13.04949314729478,
        lng: 80.17336418137056,
      },
      {
        lat: 13.049413673327276,
        lng: 80.17381379807686,
      },
      {
        lat: 13.049580440421888,
        lng: 80.17420011113182,
      },
      {
        lat: 13.050156864604835,
        lng: 80.17435848510365,
      },
      {
        lat: 13.051247439051844,
        lng: 80.17457372733811,
      },
      {
        lat: 13.051597800948628,
        lng: 80.17473401453367,
      },
      {
        lat: 13.05297509812338,
        lng: 80.17478357776017,
      },
      {
        lat: 13.053039978428904,
        lng: 80.17531359895852,
      },
      {
        lat: 13.05310473462073,
        lng: 80.17582321465743,
      },
      {
        lat: 13.052823721120586,
        lng: 80.17692754664175,
      },
      {
        lat: 13.05276529472453,
        lng: 80.17745861141674,
      },
      {
        lat: 13.052850229692408,
        lng: 80.17790683668771,
      },
      {
        lat: 13.052872021945777,
        lng: 80.17811071775317,
      },
      {
        lat: 13.052854077215363,
        lng: 80.17853940717184,
      },
      {
        lat: 13.051785543249007,
        lng: 80.17856885700175,
      },
      {
        lat: 13.051852285259187,
        lng: 80.17940496069264,
      },
      {
        lat: 13.051977205438076,
        lng: 80.17966918860941,
      },
      {
        lat: 13.051875442762636,
        lng: 80.17983330225242,
      },
      {
        lat: 13.052000859396095,
        lng: 80.18017915216716,
      },
      {
        lat: 13.052250947981216,
        lng: 80.18074841899958,
      },
      {
        lat: 13.05221270036116,
        lng: 80.18121809334667,
      },
      {
        lat: 13.052069711338214,
        lng: 80.18136214934907,
      },
      {
        lat: 13.051679364230312,
        lng: 80.18138585950777,
      },
      {
        lat: 13.05168693516257,
        lng: 80.18263059497654,
      },
      {
        lat: 13.051812600023318,
        lng: 80.18301725589015,
      },
      {
        lat: 13.051795275861183,
        lng: 80.18354797280631,
      },
      {
        lat: 13.051616521491058,
        lng: 80.18456981314554,
      },
      {
        lat: 13.051479638265928,
        lng: 80.1858266277666,
      },
      {
        lat: 13.051242894143178,
        lng: 80.18578318540317,
      },
      {
        lat: 13.050067028095519,
        lng: 80.18583411397043,
      },
      {
        lat: 13.048005163311847,
        lng: 80.18609742551455,
      },
      {
        lat: 13.047305116210962,
        lng: 80.18634921164266,
      },
      {
        lat: 13.04668909885632,
        lng: 80.1868461480528,
      },
      {
        lat: 13.045863754806312,
        lng: 80.18685313315353,
      },
      {
        lat: 13.045562047411893,
        lng: 80.18699434308041,
      },
    ],
    properties: {
      Ward_No: 149,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#f6d04d",
      zname: "VALASARAVAKKAM(149)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.037922920290757,
        lng: 80.1772418634129,
      },
      {
        lat: 13.042416030378279,
        lng: 80.18164976026009,
      },
      {
        lat: 13.041716199855593,
        lng: 80.17283467997173,
      },
      {
        lat: 13.037712501528521,
        lng: 80.17305407709259,
      },
      {
        lat: 13.038521298856205,
        lng: 80.17464812020671,
      },
      {
        lat: 13.042072198922734,
        lng: 80.18535196202394,
      },
      {
        lat: 13.04319250354396,
        lng: 80.18601647425193,
      },
      {
        lat: 13.036884387052357,
        lng: 80.17443387318396,
      },
      {
        lat: 13.042968678858857,
        lng: 80.18470896046307,
      },
      {
        lat: 13.040490814589,
        lng: 80.18189554194869,
      },
      {
        lat: 13.042545140753845,
        lng: 80.18265984441229,
      },
      {
        lat: 13.037272933247307,
        lng: 80.1769790932551,
      },
      {
        lat: 13.038191101910412,
        lng: 80.17946335632837,
      },
      {
        lat: 13.03652193569473,
        lng: 80.18085273247146,
      },
      {
        lat: 13.038666540354098,
        lng: 80.18355048507233,
      },
      {
        lat: 13.03828513099997,
        lng: 80.17180459612541,
      },
      {
        lat: 13.042663722655337,
        lng: 80.17080129707041,
      },
      {
        lat: 13.038029291840283,
        lng: 80.1802733889113,
      },
      {
        lat: 13.036789350562811,
        lng: 80.18064263855041,
      },
      {
        lat: 13.036120273109352,
        lng: 80.17480100168937,
      },
      {
        lat: 13.041256836600818,
        lng: 80.17686618046014,
      },
      {
        lat: 13.03893876194881,
        lng: 80.18108179052896,
      },
      {
        lat: 13.041285995824872,
        lng: 80.17323563552627,
      },
      {
        lat: 13.041136170474283,
        lng: 80.17271800459935,
      },
      {
        lat: 13.039892574013107,
        lng: 80.18152632612255,
      },
      {
        lat: 13.037063975776002,
        lng: 80.17204932938263,
      },
      {
        lat: 13.03808449243461,
        lng: 80.18621406963896,
      },
      {
        lat: 13.037305822962798,
        lng: 80.17118419900932,
      },
      {
        lat: 13.03830455038757,
        lng: 80.18105773141178,
      },
      {
        lat: 13.041186642533736,
        lng: 80.1780682046877,
      },
      {
        lat: 13.039295095765029,
        lng: 80.17929349701645,
      },
      {
        lat: 13.036640216947726,
        lng: 80.17954885377301,
      },
      {
        lat: 13.042867369019397,
        lng: 80.18405220123992,
      },
      {
        lat: 13.036980473011466,
        lng: 80.17757350639812,
      },
      {
        lat: 13.04181163927396,
        lng: 80.17854867369124,
      },
      {
        lat: 13.03624142923401,
        lng: 80.17914096367873,
      },
      {
        lat: 13.040313663508297,
        lng: 80.18010993264866,
      },
      {
        lat: 13.040495221001166,
        lng: 80.18721637120309,
      },
      {
        lat: 13.038579587840369,
        lng: 80.17502552532304,
      },
      {
        lat: 13.04098693839039,
        lng: 80.18427679218935,
      },
      {
        lat: 13.039255389362957,
        lng: 80.18465467766244,
      },
      {
        lat: 13.038538033466704,
        lng: 80.18505245531766,
      },
      {
        lat: 13.04022883674669,
        lng: 80.17048395526125,
      },
      {
        lat: 13.037058934524799,
        lng: 80.17841837933402,
      },
      {
        lat: 13.038605925294746,
        lng: 80.18213260473452,
      },
      {
        lat: 13.040115408562887,
        lng: 80.18526785470455,
      },
      {
        lat: 13.037465723363637,
        lng: 80.1734869273521,
      },
      {
        lat: 13.038195874009503,
        lng: 80.18657559262185,
      },
      {
        lat: 13.03715630081214,
        lng: 80.17242199219389,
      },
      {
        lat: 13.039324287217859,
        lng: 80.18420563914823,
      },
      {
        lat: 13.042726654589313,
        lng: 80.18439848207933,
      },
      {
        lat: 13.03994345144881,
        lng: 80.17675828122418,
      },
      {
        lat: 13.03922840207053,
        lng: 80.18086333445571,
      },
      {
        lat: 13.043953434013128,
        lng: 80.18722940900989,
      },
      {
        lat: 13.03857868008365,
        lng: 80.17023858783918,
      },
      {
        lat: 13.041508825424145,
        lng: 80.18185339667626,
      },
      {
        lat: 13.041692590117144,
        lng: 80.17217976084044,
      },
      {
        lat: 13.037183556095117,
        lng: 80.17953208748769,
      },
      {
        lat: 13.038081298425192,
        lng: 80.17229329741463,
      },
      {
        lat: 13.03955252497652,
        lng: 80.17122397681706,
      },
      {
        lat: 13.042184541827016,
        lng: 80.18635444778566,
      },
      {
        lat: 13.039108724397021,
        lng: 80.17030908657999,
      },
      {
        lat: 13.04158274582323,
        lng: 80.17883024489656,
      },
      {
        lat: 13.038658341809283,
        lng: 80.17076246892502,
      },
      {
        lat: 13.03738218608576,
        lng: 80.1820703957604,
      },
      {
        lat: 13.039588758165745,
        lng: 80.17418173641357,
      },
    ],
    cases: 66,
    coords: [
      {
        lat: 13.037996329060622,
        lng: 80.18872614194589,
      },
      {
        lat: 13.037898860134023,
        lng: 80.18783580405784,
      },
      {
        lat: 13.03777379441156,
        lng: 80.18688916225585,
      },
      {
        lat: 13.037496334224999,
        lng: 80.1837719970988,
      },
      {
        lat: 13.037290773334332,
        lng: 80.18266808650405,
      },
      {
        lat: 13.036888897904985,
        lng: 80.1819804562363,
      },
      {
        lat: 13.036484500743175,
        lng: 80.1808782284444,
      },
      {
        lat: 13.036200846334044,
        lng: 80.18001190384184,
      },
      {
        lat: 13.035538682514789,
        lng: 80.17903032012913,
      },
      {
        lat: 13.035815852469387,
        lng: 80.17883053681267,
      },
      {
        lat: 13.036033371542475,
        lng: 80.17863125833708,
      },
      {
        lat: 13.03605049327215,
        lng: 80.17817700705413,
      },
      {
        lat: 13.036187397094535,
        lng: 80.17780071704641,
      },
      {
        lat: 13.03618223354722,
        lng: 80.1769517792588,
      },
      {
        lat: 13.036119340438503,
        lng: 80.17641923014017,
      },
      {
        lat: 13.036018601395508,
        lng: 80.17620290140982,
      },
      {
        lat: 13.03602371487562,
        lng: 80.17377437620242,
      },
      {
        lat: 13.03601987223576,
        lng: 80.17314260854664,
      },
      {
        lat: 13.036136652266027,
        lng: 80.17272700134069,
      },
      {
        lat: 13.036171976467891,
        lng: 80.17199618342806,
      },
      {
        lat: 13.036530001838232,
        lng: 80.17201289712206,
      },
      {
        lat: 13.036848740284245,
        lng: 80.17210891833362,
      },
      {
        lat: 13.037027452763093,
        lng: 80.17206791833252,
      },
      {
        lat: 13.037145793865802,
        lng: 80.17190896673672,
      },
      {
        lat: 13.037433000682658,
        lng: 80.1700901105218,
      },
      {
        lat: 13.037570745082457,
        lng: 80.16985201968885,
      },
      {
        lat: 13.037788744485532,
        lng: 80.16973171217012,
      },
      {
        lat: 13.038384652886018,
        lng: 80.16962795006508,
      },
      {
        lat: 13.039186623030403,
        lng: 80.16949884401865,
      },
      {
        lat: 13.039400263091974,
        lng: 80.16969479488645,
      },
      {
        lat: 13.039606643056718,
        lng: 80.16983589408855,
      },
      {
        lat: 13.040222183655228,
        lng: 80.16966743220968,
      },
      {
        lat: 13.0414361889174,
        lng: 80.16990203636708,
      },
      {
        lat: 13.042794051998193,
        lng: 80.17013542301838,
      },
      {
        lat: 13.042965065631545,
        lng: 80.17019207617219,
      },
      {
        lat: 13.042771666457059,
        lng: 80.17085385847423,
      },
      {
        lat: 13.042578272111868,
        lng: 80.17126215579944,
      },
      {
        lat: 13.04240160855806,
        lng: 80.17149350258032,
      },
      {
        lat: 13.042387136024239,
        lng: 80.17204173280159,
      },
      {
        lat: 13.042374814211671,
        lng: 80.17294356271519,
      },
      {
        lat: 13.04235915878086,
        lng: 80.1732973131055,
      },
      {
        lat: 13.042076303529985,
        lng: 80.17363564398275,
      },
      {
        lat: 13.041385089673541,
        lng: 80.1741719207465,
      },
      {
        lat: 13.041350767801779,
        lng: 80.17438438195809,
      },
      {
        lat: 13.041569173216477,
        lng: 80.17516049290413,
      },
      {
        lat: 13.042007166942302,
        lng: 80.17690719462726,
      },
      {
        lat: 13.041984351430715,
        lng: 80.17901141419296,
      },
      {
        lat: 13.042042286402634,
        lng: 80.17975352145281,
      },
      {
        lat: 13.04229705687411,
        lng: 80.18065309089513,
      },
      {
        lat: 13.042570601322728,
        lng: 80.18171162950074,
      },
      {
        lat: 13.043306030369727,
        lng: 80.18258945011024,
      },
      {
        lat: 13.043811484942031,
        lng: 80.18371674957952,
      },
      {
        lat: 13.044912285136851,
        lng: 80.18611203490434,
      },
      {
        lat: 13.045216925374607,
        lng: 80.18642771276005,
      },
      {
        lat: 13.045396277330331,
        lng: 80.18663836559453,
      },
      {
        lat: 13.045562047411893,
        lng: 80.18699434308041,
      },
      {
        lat: 13.045328776515225,
        lng: 80.18710352226127,
      },
      {
        lat: 13.044691876335763,
        lng: 80.18755965850042,
      },
      {
        lat: 13.043909045606297,
        lng: 80.18777116833967,
      },
      {
        lat: 13.042713792075164,
        lng: 80.18802714552805,
      },
      {
        lat: 13.041107112636974,
        lng: 80.18849148926051,
      },
      {
        lat: 13.037996329060622,
        lng: 80.18872614194589,
      },
    ],
    properties: {
      Ward_No: 152,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#f6d04d",
      zname: "VALASARAVAKKAM(152)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.031792068977271,
        lng: 80.18590571508214,
      },
      {
        lat: 13.030683537572903,
        lng: 80.18310741462463,
      },
      {
        lat: 13.024539655180629,
        lng: 80.18839189519932,
      },
      {
        lat: 13.034445757511413,
        lng: 80.18605517631882,
      },
      {
        lat: 13.023288556189893,
        lng: 80.18883691237417,
      },
      {
        lat: 13.034445212894363,
        lng: 80.18205159730299,
      },
      {
        lat: 13.032071018541057,
        lng: 80.17835737671786,
      },
      {
        lat: 13.027690529506398,
        lng: 80.1831865366306,
      },
      {
        lat: 13.032865559210943,
        lng: 80.18073382604318,
      },
      {
        lat: 13.031919044475927,
        lng: 80.18023434123675,
      },
      {
        lat: 13.028077129275394,
        lng: 80.18593358114455,
      },
      {
        lat: 13.027262256610856,
        lng: 80.18639868158814,
      },
      {
        lat: 13.025440753316564,
        lng: 80.18456904759644,
      },
      {
        lat: 13.035891973711504,
        lng: 80.18002067596996,
      },
      {
        lat: 13.027920083750807,
        lng: 80.18377718661993,
      },
      {
        lat: 13.03392532952721,
        lng: 80.18488065770823,
      },
      {
        lat: 13.03672722973496,
        lng: 80.18366530446586,
      },
      {
        lat: 13.035920995827853,
        lng: 80.18376759146656,
      },
      {
        lat: 13.030814915446827,
        lng: 80.18846286193981,
      },
      {
        lat: 13.036905190921088,
        lng: 80.18468656041762,
      },
      {
        lat: 13.034477787816826,
        lng: 80.18580271986956,
      },
      {
        lat: 13.023713793645376,
        lng: 80.1870976098539,
      },
      {
        lat: 13.032294778753137,
        lng: 80.18541187913536,
      },
      {
        lat: 13.027722219810427,
        lng: 80.18466841262854,
      },
      {
        lat: 13.029830483453546,
        lng: 80.1798125416132,
      },
      {
        lat: 13.032170539873308,
        lng: 80.18073877521374,
      },
      {
        lat: 13.024617065466755,
        lng: 80.1872815298248,
      },
      {
        lat: 13.030023432386539,
        lng: 80.18337641519975,
      },
      {
        lat: 13.026433446886568,
        lng: 80.1840325792594,
      },
      {
        lat: 13.031161559722488,
        lng: 80.18148083240541,
      },
      {
        lat: 13.03273006844524,
        lng: 80.18623229489947,
      },
      {
        lat: 13.035727418262066,
        lng: 80.18326320095447,
      },
      {
        lat: 13.02957383141333,
        lng: 80.18612845116569,
      },
      {
        lat: 13.031123223764057,
        lng: 80.17950109575588,
      },
      {
        lat: 13.025317545119266,
        lng: 80.18456377868584,
      },
      {
        lat: 13.031582641029576,
        lng: 80.18711066460348,
      },
      {
        lat: 13.035391278563488,
        lng: 80.1835997220264,
      },
      {
        lat: 13.027787954596397,
        lng: 80.17850753744816,
      },
      {
        lat: 13.030309652910004,
        lng: 80.18723235604426,
      },
      {
        lat: 13.036698857742483,
        lng: 80.18680223387986,
      },
      {
        lat: 13.029940421031808,
        lng: 80.1796875818091,
      },
      {
        lat: 13.030651791454146,
        lng: 80.18361755730014,
      },
      {
        lat: 13.026265063096023,
        lng: 80.18601902297648,
      },
      {
        lat: 13.036707507098518,
        lng: 80.18588993368937,
      },
      {
        lat: 13.027946585545482,
        lng: 80.18790706170579,
      },
      {
        lat: 13.030952712955042,
        lng: 80.18415144598605,
      },
      {
        lat: 13.027317121659559,
        lng: 80.18167549754872,
      },
      {
        lat: 13.024621687262535,
        lng: 80.18541594305489,
      },
      {
        lat: 13.024660417149788,
        lng: 80.18593795491621,
      },
      {
        lat: 13.025745615415563,
        lng: 80.18501859481184,
      },
      {
        lat: 13.026552483935275,
        lng: 80.18684395353898,
      },
      {
        lat: 13.027692096865124,
        lng: 80.18366077973543,
      },
      {
        lat: 13.028389529261698,
        lng: 80.18360255435391,
      },
      {
        lat: 13.036483603461058,
        lng: 80.18173243456495,
      },
      {
        lat: 13.028636914946645,
        lng: 80.1838155208733,
      },
      {
        lat: 13.035548215734805,
        lng: 80.17968203860447,
      },
      {
        lat: 13.034308376386747,
        lng: 80.18591819332329,
      },
      {
        lat: 13.033502856942425,
        lng: 80.1790877597442,
      },
      {
        lat: 13.03579192318146,
        lng: 80.18638247403862,
      },
      {
        lat: 13.02650720797807,
        lng: 80.18455408137267,
      },
      {
        lat: 13.03224167162773,
        lng: 80.17901470057714,
      },
      {
        lat: 13.03325859581102,
        lng: 80.18059098530664,
      },
      {
        lat: 13.024712147871773,
        lng: 80.18458051099503,
      },
      {
        lat: 13.036855437428626,
        lng: 80.18208391414967,
      },
      {
        lat: 13.032603158177825,
        lng: 80.1782612143092,
      },
      {
        lat: 13.035199430332169,
        lng: 80.18181540415824,
      },
      {
        lat: 13.03149329632072,
        lng: 80.18812421674454,
      },
      {
        lat: 13.026735367811098,
        lng: 80.18596555207854,
      },
      {
        lat: 13.031464398702557,
        lng: 80.18648300421768,
      },
      {
        lat: 13.033171380343605,
        lng: 80.18834667008765,
      },
      {
        lat: 13.035892718827922,
        lng: 80.18080503552501,
      },
      {
        lat: 13.0377923776609,
        lng: 80.18854096066674,
      },
      {
        lat: 13.035392102944218,
        lng: 80.1814552470491,
      },
      {
        lat: 13.035464885322623,
        lng: 80.18420202046562,
      },
      {
        lat: 13.03383140853151,
        lng: 80.18619643354937,
      },
      {
        lat: 13.023641802337341,
        lng: 80.18843370778596,
      },
      {
        lat: 13.025922838272924,
        lng: 80.18494199567557,
      },
      {
        lat: 13.030280386182607,
        lng: 80.18703197017739,
      },
      {
        lat: 13.03594012658102,
        lng: 80.18033976142466,
      },
      {
        lat: 13.024993279180022,
        lng: 80.1829871731166,
      },
      {
        lat: 13.037606460516653,
        lng: 80.18603406516807,
      },
      {
        lat: 13.033368987239479,
        lng: 80.18430525335027,
      },
      {
        lat: 13.034375184387168,
        lng: 80.17868710240145,
      },
      {
        lat: 13.027152509694151,
        lng: 80.18829255244184,
      },
      {
        lat: 13.027935897098002,
        lng: 80.1809817261004,
      },
      {
        lat: 13.03607745799377,
        lng: 80.18291211491571,
      },
      {
        lat: 13.032436963817025,
        lng: 80.18786110699082,
      },
      {
        lat: 13.036137899313307,
        lng: 80.18050422972055,
      },
      {
        lat: 13.024696478767746,
        lng: 80.18446234631004,
      },
      {
        lat: 13.037339799831312,
        lng: 80.18565656757444,
      },
      {
        lat: 13.033463542190907,
        lng: 80.17895911122079,
      },
      {
        lat: 13.023912230260636,
        lng: 80.18925833751662,
      },
      {
        lat: 13.032105538823014,
        lng: 80.18695813200216,
      },
      {
        lat: 13.034507934103624,
        lng: 80.18679585415053,
      },
      {
        lat: 13.030513909867748,
        lng: 80.18179280636885,
      },
      {
        lat: 13.031794842724409,
        lng: 80.18448387108117,
      },
      {
        lat: 13.028421514462657,
        lng: 80.18198981165216,
      },
      {
        lat: 13.027617698866466,
        lng: 80.18389889073967,
      },
      {
        lat: 13.035235071697777,
        lng: 80.18455750811988,
      },
      {
        lat: 13.031076280739738,
        lng: 80.18440925649402,
      },
      {
        lat: 13.027239295296864,
        lng: 80.18819922502102,
      },
      {
        lat: 13.036979731761116,
        lng: 80.18312221581037,
      },
      {
        lat: 13.031121923926861,
        lng: 80.18004206981678,
      },
      {
        lat: 13.03364818647477,
        lng: 80.1805224466325,
      },
      {
        lat: 13.028019031106902,
        lng: 80.18003726443655,
      },
      {
        lat: 13.025856506204851,
        lng: 80.18567951866555,
      },
      {
        lat: 13.033479069114753,
        lng: 80.18863190273726,
      },
      {
        lat: 13.034104654041125,
        lng: 80.18573645585833,
      },
      {
        lat: 13.034258767070218,
        lng: 80.18489642029114,
      },
      {
        lat: 13.032205466474219,
        lng: 80.18736815663789,
      },
      {
        lat: 13.031550353953657,
        lng: 80.1802931436365,
      },
      {
        lat: 13.030394451304632,
        lng: 80.17988382999036,
      },
      {
        lat: 13.031048998198012,
        lng: 80.18430773504885,
      },
      {
        lat: 13.035141646041984,
        lng: 80.18324350171216,
      },
      {
        lat: 13.032101031397406,
        lng: 80.17884238894479,
      },
      {
        lat: 13.0246413370402,
        lng: 80.18479971074602,
      },
      {
        lat: 13.03053324439157,
        lng: 80.18640645443173,
      },
      {
        lat: 13.033993138836552,
        lng: 80.18156029484626,
      },
      {
        lat: 13.032776486808146,
        lng: 80.18570819649842,
      },
      {
        lat: 13.02595612637913,
        lng: 80.18584149288857,
      },
      {
        lat: 13.034015206004373,
        lng: 80.18606223536233,
      },
      {
        lat: 13.02848186437501,
        lng: 80.1881699945878,
      },
      {
        lat: 13.028997452742322,
        lng: 80.18439204121523,
      },
      {
        lat: 13.02971716265569,
        lng: 80.18642936235436,
      },
      {
        lat: 13.031971115334654,
        lng: 80.17898299200415,
      },
      {
        lat: 13.034852751580567,
        lng: 80.18038800831607,
      },
      {
        lat: 13.025757226121714,
        lng: 80.18813014020222,
      },
      {
        lat: 13.024377755787997,
        lng: 80.18784489219163,
      },
      {
        lat: 13.026590596362357,
        lng: 80.18768722358011,
      },
      {
        lat: 13.03188277845386,
        lng: 80.18601561497552,
      },
      {
        lat: 13.034309425907662,
        lng: 80.18534757618472,
      },
      {
        lat: 13.034779702165517,
        lng: 80.18464551596351,
      },
      {
        lat: 13.025867081455921,
        lng: 80.18659206252642,
      },
      {
        lat: 13.029411197928148,
        lng: 80.18350622119334,
      },
      {
        lat: 13.036839645174952,
        lng: 80.187984895843,
      },
      {
        lat: 13.030694618131884,
        lng: 80.17945673281925,
      },
      {
        lat: 13.029045244779995,
        lng: 80.18686367115036,
      },
      {
        lat: 13.03376966788788,
        lng: 80.18525248854897,
      },
      {
        lat: 13.035348189385468,
        lng: 80.181700619123,
      },
      {
        lat: 13.035982261713018,
        lng: 80.18316780663622,
      },
      {
        lat: 13.033219030818877,
        lng: 80.18105055667937,
      },
      {
        lat: 13.035249578478945,
        lng: 80.1816128354876,
      },
      {
        lat: 13.033062546650807,
        lng: 80.18647307473012,
      },
      {
        lat: 13.024982655810883,
        lng: 80.18791494430107,
      },
      {
        lat: 13.030759055437372,
        lng: 80.1796773408949,
      },
      {
        lat: 13.031694753485752,
        lng: 80.17858064144988,
      },
      {
        lat: 13.027343012091311,
        lng: 80.18793334100293,
      },
      {
        lat: 13.035005404795404,
        lng: 80.17888255641769,
      },
      {
        lat: 13.032900869995315,
        lng: 80.18825976168698,
      },
      {
        lat: 13.030599412793658,
        lng: 80.17952010300966,
      },
      {
        lat: 13.028432847996516,
        lng: 80.18231836498842,
      },
    ],
    cases: 151,
    coords: [
      {
        lat: 13.024133727819427,
        lng: 80.18318983402868,
      },
      {
        lat: 13.024566092567765,
        lng: 80.18291398506604,
      },
      {
        lat: 13.026912600730423,
        lng: 80.18293361347001,
      },
      {
        lat: 13.026932674455404,
        lng: 80.1796954676733,
      },
      {
        lat: 13.02789724550126,
        lng: 80.17808806006002,
      },
      {
        lat: 13.029746182656396,
        lng: 80.17803292451437,
      },
      {
        lat: 13.031658253085471,
        lng: 80.1785498235659,
      },
      {
        lat: 13.031916620155394,
        lng: 80.17852789318285,
      },
      {
        lat: 13.031993152601242,
        lng: 80.17803365158046,
      },
      {
        lat: 13.03220610853967,
        lng: 80.1770841490136,
      },
      {
        lat: 13.032403984150614,
        lng: 80.17692452429664,
      },
      {
        lat: 13.032622223718848,
        lng: 80.17684370225656,
      },
      {
        lat: 13.032666313942729,
        lng: 80.17755410430861,
      },
      {
        lat: 13.032807300569967,
        lng: 80.1778490674354,
      },
      {
        lat: 13.032947326537185,
        lng: 80.17798608864793,
      },
      {
        lat: 13.033565280049674,
        lng: 80.17823752756918,
      },
      {
        lat: 13.035538682514789,
        lng: 80.17903032012913,
      },
      {
        lat: 13.036200846334044,
        lng: 80.18001190384184,
      },
      {
        lat: 13.036484500743175,
        lng: 80.1808782284444,
      },
      {
        lat: 13.036888897904985,
        lng: 80.1819804562363,
      },
      {
        lat: 13.037290773334332,
        lng: 80.18266808650405,
      },
      {
        lat: 13.037496334224999,
        lng: 80.1837719970988,
      },
      {
        lat: 13.03777379441156,
        lng: 80.18688916225585,
      },
      {
        lat: 13.037898860134023,
        lng: 80.18783580405784,
      },
      {
        lat: 13.037996329060622,
        lng: 80.18872614194589,
      },
      {
        lat: 13.037435826955939,
        lng: 80.18876842175106,
      },
      {
        lat: 13.032939197224724,
        lng: 80.18905233934234,
      },
      {
        lat: 13.032320189187509,
        lng: 80.1890575781679,
      },
      {
        lat: 13.031906021820758,
        lng: 80.18881522192595,
      },
      {
        lat: 13.03120423015484,
        lng: 80.18878018446287,
      },
      {
        lat: 13.025961550872134,
        lng: 80.18853771626146,
      },
      {
        lat: 13.024682018371127,
        lng: 80.18850756836888,
      },
      {
        lat: 13.024436658168506,
        lng: 80.18887843708757,
      },
      {
        lat: 13.024442888757926,
        lng: 80.18990280705566,
      },
      {
        lat: 13.023881884823659,
        lng: 80.18988286721111,
      },
      {
        lat: 13.023060861609679,
        lng: 80.18954132584541,
      },
      {
        lat: 13.022485474826494,
        lng: 80.1889782860294,
      },
      {
        lat: 13.022260418754172,
        lng: 80.18830902500771,
      },
      {
        lat: 13.022192678856007,
        lng: 80.18785785214388,
      },
      {
        lat: 13.02237300892642,
        lng: 80.18758527826529,
      },
      {
        lat: 13.022824501362635,
        lng: 80.18701354770417,
      },
      {
        lat: 13.022912586115634,
        lng: 80.18653524198945,
      },
      {
        lat: 13.023105129645062,
        lng: 80.1861334944125,
      },
      {
        lat: 13.023662415378228,
        lng: 80.18585773026038,
      },
      {
        lat: 13.024142887802748,
        lng: 80.18577622169818,
      },
      {
        lat: 13.024285085972933,
        lng: 80.18564594790548,
      },
      {
        lat: 13.024258147016242,
        lng: 80.18549129149778,
      },
      {
        lat: 13.024062463438225,
        lng: 80.18537678431926,
      },
      {
        lat: 13.023866387354373,
        lng: 80.1851977452963,
      },
      {
        lat: 13.023904048247083,
        lng: 80.184978006997,
      },
      {
        lat: 13.024292118351934,
        lng: 80.18466495386086,
      },
      {
        lat: 13.024705871394225,
        lng: 80.18430005523014,
      },
      {
        lat: 13.024794976662935,
        lng: 80.18398953231097,
      },
      {
        lat: 13.024715572814417,
        lng: 80.18375787772761,
      },
      {
        lat: 13.024362102055015,
        lng: 80.18334784417787,
      },
      {
        lat: 13.024133727819427,
        lng: 80.18318983402868,
      },
    ],
    properties: {
      Ward_No: 155,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#de4307",
      zname: "VALASARAVAKKAM(155)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.026612180717125,
        lng: 80.17582310013768,
      },
      {
        lat: 13.0352322397727,
        lng: 80.17470014156852,
      },
      {
        lat: 13.028214396519083,
        lng: 80.17167990687834,
      },
      {
        lat: 13.024978581455558,
        lng: 80.17175323763834,
      },
      {
        lat: 13.024675806610029,
        lng: 80.17519187989137,
      },
      {
        lat: 13.031886041946766,
        lng: 80.17589454051685,
      },
      {
        lat: 13.02523987091111,
        lng: 80.17694827267854,
      },
      {
        lat: 13.035065891523795,
        lng: 80.17392030998842,
      },
      {
        lat: 13.025818231570065,
        lng: 80.17280542236098,
      },
      {
        lat: 13.031131693073569,
        lng: 80.17371781909492,
      },
      {
        lat: 13.032740549635244,
        lng: 80.17719593370902,
      },
      {
        lat: 13.029452434936863,
        lng: 80.17242628923968,
      },
      {
        lat: 13.034835889125056,
        lng: 80.1773467349784,
      },
      {
        lat: 13.025460589105743,
        lng: 80.17525677077174,
      },
      {
        lat: 13.034037038417443,
        lng: 80.17481665329093,
      },
      {
        lat: 13.032488276916157,
        lng: 80.17433613637267,
      },
      {
        lat: 13.02954092836587,
        lng: 80.17702717476996,
      },
      {
        lat: 13.027969765564684,
        lng: 80.17440798742656,
      },
      {
        lat: 13.029840146079554,
        lng: 80.17575177264209,
      },
      {
        lat: 13.034241455584834,
        lng: 80.17256411548004,
      },
      {
        lat: 13.029482825851119,
        lng: 80.17261284490499,
      },
      {
        lat: 13.03108721118383,
        lng: 80.17287154740504,
      },
      {
        lat: 13.025615155228971,
        lng: 80.17380141187861,
      },
      {
        lat: 13.028934433305288,
        lng: 80.17177520227368,
      },
      {
        lat: 13.026231878537848,
        lng: 80.18007380683298,
      },
      {
        lat: 13.035211761671254,
        lng: 80.17460298026315,
      },
      {
        lat: 13.03346712045225,
        lng: 80.17369786534839,
      },
      {
        lat: 13.029202143604822,
        lng: 80.1734292525702,
      },
      {
        lat: 13.025227105091426,
        lng: 80.17107995668547,
      },
      {
        lat: 13.03079197107982,
        lng: 80.17620645760275,
      },
      {
        lat: 13.028522651467407,
        lng: 80.17733574469736,
      },
      {
        lat: 13.03472210555835,
        lng: 80.1758727513965,
      },
      {
        lat: 13.035729340853306,
        lng: 80.17610173971438,
      },
      {
        lat: 13.029308458730343,
        lng: 80.17709178900662,
      },
      {
        lat: 13.024893812401656,
        lng: 80.17953014710905,
      },
      {
        lat: 13.02603221241689,
        lng: 80.17919272755795,
      },
      {
        lat: 13.03264416833229,
        lng: 80.17353934655429,
      },
      {
        lat: 13.028852292193617,
        lng: 80.17399910546062,
      },
      {
        lat: 13.031937077037345,
        lng: 80.17745748408194,
      },
      {
        lat: 13.031440820593918,
        lng: 80.17465924909929,
      },
      {
        lat: 13.029598738709902,
        lng: 80.17648943818736,
      },
      {
        lat: 13.02498604824365,
        lng: 80.17922362130518,
      },
      {
        lat: 13.035966713856437,
        lng: 80.17546288582315,
      },
      {
        lat: 13.03565511078641,
        lng: 80.17818466202927,
      },
      {
        lat: 13.025871713791865,
        lng: 80.17985266181272,
      },
      {
        lat: 13.035961588793445,
        lng: 80.17658800227989,
      },
      {
        lat: 13.025455535327259,
        lng: 80.18212870212172,
      },
      {
        lat: 13.028981947291463,
        lng: 80.17551619644904,
      },
      {
        lat: 13.027895074329141,
        lng: 80.17308774241371,
      },
      {
        lat: 13.028981964318236,
        lng: 80.17362992779849,
      },
      {
        lat: 13.030771646492418,
        lng: 80.17258485012445,
      },
      {
        lat: 13.030763585014897,
        lng: 80.17336314353089,
      },
      {
        lat: 13.033781471983906,
        lng: 80.17345427829004,
      },
      {
        lat: 13.033849988534895,
        lng: 80.17485043699286,
      },
      {
        lat: 13.026626050085728,
        lng: 80.1738802502756,
      },
      {
        lat: 13.025771358010017,
        lng: 80.17926219917786,
      },
      {
        lat: 13.02963797981316,
        lng: 80.17199140804217,
      },
      {
        lat: 13.033196253432257,
        lng: 80.17796932842954,
      },
      {
        lat: 13.031800293835602,
        lng: 80.1736886477519,
      },
      {
        lat: 13.029579873191985,
        lng: 80.17327755856341,
      },
      {
        lat: 13.026492589736133,
        lng: 80.17184084812627,
      },
      {
        lat: 13.025078470297535,
        lng: 80.17207193415989,
      },
      {
        lat: 13.026135105396415,
        lng: 80.17255387661181,
      },
      {
        lat: 13.02909873772151,
        lng: 80.17466597165591,
      },
      {
        lat: 13.03076733094129,
        lng: 80.17779756957977,
      },
      {
        lat: 13.03190499611589,
        lng: 80.17400284338586,
      },
      {
        lat: 13.03463662075156,
        lng: 80.1768579767997,
      },
      {
        lat: 13.02784592309116,
        lng: 80.17249791166792,
      },
      {
        lat: 13.02523945804206,
        lng: 80.17635590899133,
      },
      {
        lat: 13.028964577628921,
        lng: 80.1768144926335,
      },
      {
        lat: 13.031438919433628,
        lng: 80.17269184396312,
      },
      {
        lat: 13.02612244192729,
        lng: 80.17530967787827,
      },
      {
        lat: 13.025025266344567,
        lng: 80.18125996680259,
      },
      {
        lat: 13.026233279033416,
        lng: 80.17891073708216,
      },
      {
        lat: 13.026725273105942,
        lng: 80.1767083753638,
      },
      {
        lat: 13.033295344309686,
        lng: 80.17359044943385,
      },
      {
        lat: 13.034817074301264,
        lng: 80.17474774790944,
      },
      {
        lat: 13.031918598436434,
        lng: 80.17770910660522,
      },
      {
        lat: 13.03328590914332,
        lng: 80.17672930267922,
      },
      {
        lat: 13.034453348802451,
        lng: 80.1731660291205,
      },
      {
        lat: 13.027455690368534,
        lng: 80.17581172536231,
      },
      {
        lat: 13.035168216849048,
        lng: 80.17728108538186,
      },
      {
        lat: 13.026370289881473,
        lng: 80.1761484041177,
      },
      {
        lat: 13.026059184382003,
        lng: 80.17151891985121,
      },
      {
        lat: 13.025829004292527,
        lng: 80.1791422892108,
      },
      {
        lat: 13.027383086635579,
        lng: 80.17453874186951,
      },
      {
        lat: 13.025960673698604,
        lng: 80.17242273677509,
      },
      {
        lat: 13.029288369554791,
        lng: 80.17304162505927,
      },
      {
        lat: 13.028384989148627,
        lng: 80.17753452677269,
      },
      {
        lat: 13.024644096954944,
        lng: 80.17678990100754,
      },
      {
        lat: 13.027232830165293,
        lng: 80.17472831151376,
      },
      {
        lat: 13.02622487703171,
        lng: 80.17507589583232,
      },
      {
        lat: 13.03074107413445,
        lng: 80.17539586100132,
      },
      {
        lat: 13.026823814597464,
        lng: 80.17600816628182,
      },
      {
        lat: 13.026676229320962,
        lng: 80.17348386937815,
      },
      {
        lat: 13.030956813754734,
        lng: 80.17551721802413,
      },
      {
        lat: 13.025433356423575,
        lng: 80.17799190385554,
      },
      {
        lat: 13.024771506652023,
        lng: 80.18257101949455,
      },
      {
        lat: 13.026453037787299,
        lng: 80.17915303492667,
      },
      {
        lat: 13.028544910059795,
        lng: 80.1733943332031,
      },
      {
        lat: 13.03510295546847,
        lng: 80.17500897031346,
      },
      {
        lat: 13.033674015083063,
        lng: 80.17572198805432,
      },
      {
        lat: 13.025090636935968,
        lng: 80.17197453584711,
      },
      {
        lat: 13.02723239251769,
        lng: 80.17599418260096,
      },
      {
        lat: 13.025111571701066,
        lng: 80.18267178126156,
      },
      {
        lat: 13.02912329359882,
        lng: 80.17779438681156,
      },
      {
        lat: 13.034798456270272,
        lng: 80.17463889849287,
      },
      {
        lat: 13.025481017765706,
        lng: 80.17605348907202,
      },
      {
        lat: 13.02598052764019,
        lng: 80.17346240768929,
      },
      {
        lat: 13.034521896863374,
        lng: 80.17361340457569,
      },
      {
        lat: 13.03070580273398,
        lng: 80.17625266373183,
      },
      {
        lat: 13.035594292270819,
        lng: 80.17573646353154,
      },
      {
        lat: 13.02805964803092,
        lng: 80.17400634216534,
      },
      {
        lat: 13.031908367147672,
        lng: 80.1775217954154,
      },
      {
        lat: 13.032461617679834,
        lng: 80.17443449113446,
      },
      {
        lat: 13.027434470165234,
        lng: 80.17850025969585,
      },
      {
        lat: 13.030778938766018,
        lng: 80.17537470531035,
      },
      {
        lat: 13.026701916512334,
        lng: 80.17599819409376,
      },
      {
        lat: 13.03421382700538,
        lng: 80.17810185957147,
      },
      {
        lat: 13.027464963154715,
        lng: 80.17564068268152,
      },
      {
        lat: 13.027127965804555,
        lng: 80.17586086050098,
      },
      {
        lat: 13.03564549478881,
        lng: 80.1761005959619,
      },
      {
        lat: 13.025807113727469,
        lng: 80.17577012349626,
      },
      {
        lat: 13.028280283874764,
        lng: 80.17152666970809,
      },
      {
        lat: 13.02803519680084,
        lng: 80.17190263312263,
      },
      {
        lat: 13.035915951991104,
        lng: 80.17632155191978,
      },
      {
        lat: 13.024456546119742,
        lng: 80.17540884240182,
      },
      {
        lat: 13.025238221476677,
        lng: 80.17366645282378,
      },
      {
        lat: 13.034529948034253,
        lng: 80.17808759964035,
      },
      {
        lat: 13.026405530581515,
        lng: 80.17160539205629,
      },
      {
        lat: 13.027663467942814,
        lng: 80.1757097400928,
      },
      {
        lat: 13.028749685834587,
        lng: 80.17698731663683,
      },
      {
        lat: 13.027205067186125,
        lng: 80.17820799065545,
      },
      {
        lat: 13.025879121026415,
        lng: 80.17837057680214,
      },
      {
        lat: 13.032847565148183,
        lng: 80.17764193578961,
      },
      {
        lat: 13.029128905984322,
        lng: 80.1765793578882,
      },
      {
        lat: 13.024269165745135,
        lng: 80.18260623430687,
      },
      {
        lat: 13.033120401073383,
        lng: 80.17457283591874,
      },
      {
        lat: 13.035418121827584,
        lng: 80.17772995211699,
      },
      {
        lat: 13.031777596682453,
        lng: 80.17584010519235,
      },
      {
        lat: 13.03038380039307,
        lng: 80.17764754448511,
      },
      {
        lat: 13.03392562678098,
        lng: 80.17818299103084,
      },
      {
        lat: 13.024766210160694,
        lng: 80.17765707851363,
      },
      {
        lat: 13.030598252413741,
        lng: 80.1781996416179,
      },
      {
        lat: 13.033922463835314,
        lng: 80.17775742902899,
      },
      {
        lat: 13.028073322543925,
        lng: 80.17382023596151,
      },
      {
        lat: 13.026316265117583,
        lng: 80.17567661261575,
      },
    ],
    cases: 147,
    coords: [
      {
        lat: 13.02478234008351,
        lng: 80.1706780110343,
      },
      {
        lat: 13.026729048222426,
        lng: 80.17111815532108,
      },
      {
        lat: 13.029354810215064,
        lng: 80.17175903406925,
      },
      {
        lat: 13.030965461629291,
        lng: 80.17223658882212,
      },
      {
        lat: 13.031784199579013,
        lng: 80.17264716782675,
      },
      {
        lat: 13.032305540538013,
        lng: 80.17296202655677,
      },
      {
        lat: 13.03267698803426,
        lng: 80.17303256081723,
      },
      {
        lat: 13.032824342191551,
        lng: 80.1728593985811,
      },
      {
        lat: 13.033020266707734,
        lng: 80.1725384694475,
      },
      {
        lat: 13.035221529717555,
        lng: 80.17251983958782,
      },
      {
        lat: 13.035174004954865,
        lng: 80.1728395127758,
      },
      {
        lat: 13.035051682831874,
        lng: 80.17306158177064,
      },
      {
        lat: 13.03507805920413,
        lng: 80.17333151090287,
      },
      {
        lat: 13.035500168252222,
        lng: 80.1735980908469,
      },
      {
        lat: 13.035847031827462,
        lng: 80.1736933924731,
      },
      {
        lat: 13.036023445985366,
        lng: 80.17373016800924,
      },
      {
        lat: 13.03602371487562,
        lng: 80.17377437620242,
      },
      {
        lat: 13.036018601395508,
        lng: 80.17620290140982,
      },
      {
        lat: 13.036119340438503,
        lng: 80.17641923014017,
      },
      {
        lat: 13.03618223354722,
        lng: 80.1769517792588,
      },
      {
        lat: 13.036187397094535,
        lng: 80.17780071704641,
      },
      {
        lat: 13.03605049327215,
        lng: 80.17817700705413,
      },
      {
        lat: 13.036033371542475,
        lng: 80.17863125833708,
      },
      {
        lat: 13.035815852469387,
        lng: 80.17883053681267,
      },
      {
        lat: 13.035538682514789,
        lng: 80.17903032012913,
      },
      {
        lat: 13.033565280049674,
        lng: 80.17823752756918,
      },
      {
        lat: 13.032947326537185,
        lng: 80.17798608864793,
      },
      {
        lat: 13.032807300569967,
        lng: 80.1778490674354,
      },
      {
        lat: 13.032666313942729,
        lng: 80.17755410430861,
      },
      {
        lat: 13.032622223718848,
        lng: 80.17684370225656,
      },
      {
        lat: 13.032403984150614,
        lng: 80.17692452429664,
      },
      {
        lat: 13.03220610853967,
        lng: 80.1770841490136,
      },
      {
        lat: 13.031993152601242,
        lng: 80.17803365158046,
      },
      {
        lat: 13.031916620155394,
        lng: 80.17852789318285,
      },
      {
        lat: 13.031658253085471,
        lng: 80.1785498235659,
      },
      {
        lat: 13.029746182656396,
        lng: 80.17803292451437,
      },
      {
        lat: 13.02789724550126,
        lng: 80.17808806006002,
      },
      {
        lat: 13.026932674455404,
        lng: 80.1796954676733,
      },
      {
        lat: 13.026912600730423,
        lng: 80.18293361347001,
      },
      {
        lat: 13.024566092567765,
        lng: 80.18291398506604,
      },
      {
        lat: 13.024133727819427,
        lng: 80.18318983402868,
      },
      {
        lat: 13.024048804227105,
        lng: 80.18313107613324,
      },
      {
        lat: 13.02393048346629,
        lng: 80.18291265794707,
      },
      {
        lat: 13.023993984298933,
        lng: 80.18266688689098,
      },
      {
        lat: 13.024406469085632,
        lng: 80.18150939892521,
      },
      {
        lat: 13.024535752682871,
        lng: 80.18066293416986,
      },
      {
        lat: 13.02446556338388,
        lng: 80.18017410311894,
      },
      {
        lat: 13.024327484969202,
        lng: 80.17957461365827,
      },
      {
        lat: 13.024324508261705,
        lng: 80.17908521377001,
      },
      {
        lat: 13.024207075080627,
        lng: 80.1781963438536,
      },
      {
        lat: 13.024361198362717,
        lng: 80.17775010757627,
      },
      {
        lat: 13.02442665199068,
        lng: 80.17746034789555,
      },
      {
        lat: 13.024268740171546,
        lng: 80.17728371158734,
      },
      {
        lat: 13.023864246879151,
        lng: 80.17708691556763,
      },
      {
        lat: 13.023571368657725,
        lng: 80.17682243513693,
      },
      {
        lat: 13.023747896137108,
        lng: 80.17637600924708,
      },
      {
        lat: 13.023990553768895,
        lng: 80.17575105092396,
      },
      {
        lat: 13.024436337528426,
        lng: 80.17536908603223,
      },
      {
        lat: 13.02485998770068,
        lng: 80.17503180165208,
      },
      {
        lat: 13.024925441328644,
        lng: 80.17474204197127,
      },
      {
        lat: 13.02492232931626,
        lng: 80.17423039663335,
      },
      {
        lat: 13.024603258360946,
        lng: 80.17334323322973,
      },
      {
        lat: 13.024683268502574,
        lng: 80.17176304786673,
      },
      {
        lat: 13.02478234008351,
        lng: 80.1706780110343,
      },
    ],
    properties: {
      Ward_No: 154,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#de4307",
      zname: "VALASARAVAKKAM(154)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.033421919264372,
        lng: 80.16281189766086,
      },
      {
        lat: 13.035120106111952,
        lng: 80.15815498265462,
      },
      {
        lat: 13.035777655919714,
        lng: 80.16974564345992,
      },
      {
        lat: 13.038529700725245,
        lng: 80.16942474385034,
      },
      {
        lat: 13.037757664291716,
        lng: 80.16153041661111,
      },
      {
        lat: 13.029503291004499,
        lng: 80.17143085205713,
      },
      {
        lat: 13.03776365030295,
        lng: 80.16490974600713,
      },
      {
        lat: 13.028707183175767,
        lng: 80.17011408302106,
      },
      {
        lat: 13.027390125539057,
        lng: 80.16783481213436,
      },
      {
        lat: 13.025807078237948,
        lng: 80.16994894456764,
      },
      {
        lat: 13.036482884140963,
        lng: 80.1591529662609,
      },
      {
        lat: 13.035428406501074,
        lng: 80.15872737867521,
      },
      {
        lat: 13.027017334787175,
        lng: 80.16602704977784,
      },
      {
        lat: 13.03043049660315,
        lng: 80.16958364396302,
      },
      {
        lat: 13.035916298514048,
        lng: 80.16447665947894,
      },
      {
        lat: 13.029636282588248,
        lng: 80.16919320517879,
      },
      {
        lat: 13.03041416543852,
        lng: 80.16792541789238,
      },
      {
        lat: 13.027368169725227,
        lng: 80.17070359395515,
      },
      {
        lat: 13.026493202220943,
        lng: 80.17004405933841,
      },
      {
        lat: 13.035912836720643,
        lng: 80.16843889208255,
      },
      {
        lat: 13.034239956688067,
        lng: 80.16877403885881,
      },
      {
        lat: 13.027359461334488,
        lng: 80.17033008152207,
      },
      {
        lat: 13.026954189391338,
        lng: 80.16849790554832,
      },
      {
        lat: 13.029885978553356,
        lng: 80.16737322650717,
      },
      {
        lat: 13.027378851482183,
        lng: 80.16919957007025,
      },
      {
        lat: 13.031661688672981,
        lng: 80.16681052855569,
      },
      {
        lat: 13.036857788404795,
        lng: 80.17089908507586,
      },
      {
        lat: 13.032378780102285,
        lng: 80.16834526789734,
      },
      {
        lat: 13.028554022204325,
        lng: 80.16942673866687,
      },
      {
        lat: 13.038594201497867,
        lng: 80.16855020642701,
      },
      {
        lat: 13.035353565701104,
        lng: 80.1678978332071,
      },
      {
        lat: 13.035814653571205,
        lng: 80.17233833123287,
      },
      {
        lat: 13.037572807255968,
        lng: 80.15993520837914,
      },
      {
        lat: 13.0321698907644,
        lng: 80.16742805889754,
      },
      {
        lat: 13.034908832110379,
        lng: 80.1624616728631,
      },
      {
        lat: 13.031282521412376,
        lng: 80.17210614197636,
      },
      {
        lat: 13.036951002080249,
        lng: 80.17063527403126,
      },
      {
        lat: 13.03417515115226,
        lng: 80.16596380809573,
      },
      {
        lat: 13.035168909688162,
        lng: 80.16223319392958,
      },
      {
        lat: 13.03853530207931,
        lng: 80.16870592357246,
      },
      {
        lat: 13.032218118939632,
        lng: 80.17001563496048,
      },
      {
        lat: 13.029906297173369,
        lng: 80.17016367847803,
      },
      {
        lat: 13.037997169070653,
        lng: 80.16721210290153,
      },
      {
        lat: 13.036211796703807,
        lng: 80.16411702632058,
      },
      {
        lat: 13.030776163373453,
        lng: 80.16964776999883,
      },
      {
        lat: 13.035328232661382,
        lng: 80.16662889116219,
      },
      {
        lat: 13.037797343994281,
        lng: 80.16684544470375,
      },
      {
        lat: 13.029397424248373,
        lng: 80.17053591266024,
      },
      {
        lat: 13.038541474916745,
        lng: 80.16202110896602,
      },
      {
        lat: 13.0261760818324,
        lng: 80.16994794005997,
      },
      {
        lat: 13.02483057300377,
        lng: 80.16699886098083,
      },
      {
        lat: 13.034904537442879,
        lng: 80.16143600136743,
      },
      {
        lat: 13.03218164048614,
        lng: 80.16764284233984,
      },
      {
        lat: 13.034775039846446,
        lng: 80.15841264227403,
      },
      {
        lat: 13.034811279659566,
        lng: 80.172057896032,
      },
      {
        lat: 13.034829287414013,
        lng: 80.16144910291517,
      },
      {
        lat: 13.029841987129021,
        lng: 80.1704492738012,
      },
      {
        lat: 13.027310192514737,
        lng: 80.16614856211272,
      },
      {
        lat: 13.032527516263007,
        lng: 80.16962130322614,
      },
      {
        lat: 13.034819429872453,
        lng: 80.1607926532841,
      },
      {
        lat: 13.032014868218996,
        lng: 80.17136478811379,
      },
      {
        lat: 13.028258115694094,
        lng: 80.17015477401273,
      },
      {
        lat: 13.036904823486596,
        lng: 80.17140973093666,
      },
      {
        lat: 13.033875683265412,
        lng: 80.16201006875842,
      },
      {
        lat: 13.03787421288961,
        lng: 80.1655354647563,
      },
      {
        lat: 13.025120354825242,
        lng: 80.17036504176382,
      },
      {
        lat: 13.036409200583067,
        lng: 80.15752624722458,
      },
      {
        lat: 13.025506634124806,
        lng: 80.16782395226313,
      },
      {
        lat: 13.036243242951553,
        lng: 80.17120100953193,
      },
      {
        lat: 13.031648511644022,
        lng: 80.16657001503417,
      },
      {
        lat: 13.033866313137112,
        lng: 80.16836453715865,
      },
      {
        lat: 13.028172050571051,
        lng: 80.16634753813877,
      },
      {
        lat: 13.03310949427182,
        lng: 80.16592967604173,
      },
      {
        lat: 13.031735630883135,
        lng: 80.17200665852316,
      },
      {
        lat: 13.034534441543165,
        lng: 80.17126411602081,
      },
      {
        lat: 13.02877899528834,
        lng: 80.16806464532472,
      },
      {
        lat: 13.031473757653105,
        lng: 80.16611293864017,
      },
      {
        lat: 13.034062400850079,
        lng: 80.16611989215056,
      },
      {
        lat: 13.034734368131085,
        lng: 80.1643136410875,
      },
      {
        lat: 13.030852384119589,
        lng: 80.17035363430405,
      },
      {
        lat: 13.038587603794753,
        lng: 80.16806363755339,
      },
      {
        lat: 13.037963675243578,
        lng: 80.16313127611366,
      },
      {
        lat: 13.034004592875142,
        lng: 80.16195390008058,
      },
      {
        lat: 13.028426480353684,
        lng: 80.16621323409777,
      },
      {
        lat: 13.035109156684259,
        lng: 80.1654784711966,
      },
      {
        lat: 13.030764866805105,
        lng: 80.16923683772622,
      },
      {
        lat: 13.034076075703323,
        lng: 80.16822675538343,
      },
      {
        lat: 13.031075625398898,
        lng: 80.17180469662443,
      },
      {
        lat: 13.03260119175267,
        lng: 80.16716670534468,
      },
      {
        lat: 13.027222976212563,
        lng: 80.16970373405249,
      },
      {
        lat: 13.029009651822093,
        lng: 80.1709631926814,
      },
      {
        lat: 13.026021914257917,
        lng: 80.16612260550899,
      },
      {
        lat: 13.033552886943438,
        lng: 80.16843403663002,
      },
      {
        lat: 13.035254378119118,
        lng: 80.16561172902276,
      },
      {
        lat: 13.03591513782713,
        lng: 80.17033669138468,
      },
      {
        lat: 13.033550213427612,
        lng: 80.16717519605882,
      },
    ],
    cases: 96,
    coords: [
      {
        lat: 13.024373624868412,
        lng: 80.16750393618194,
      },
      {
        lat: 13.02521323468407,
        lng: 80.1658998519376,
      },
      {
        lat: 13.02535954324477,
        lng: 80.16555478341095,
      },
      {
        lat: 13.025804891876028,
        lng: 80.16557557361571,
      },
      {
        lat: 13.026522754834842,
        lng: 80.16566773537814,
      },
      {
        lat: 13.028205216191354,
        lng: 80.16575173349386,
      },
      {
        lat: 13.030011194637954,
        lng: 80.16581012694701,
      },
      {
        lat: 13.03090219064263,
        lng: 80.16590082343932,
      },
      {
        lat: 13.031199139520458,
        lng: 80.16592286958968,
      },
      {
        lat: 13.03139536277846,
        lng: 80.16565105653913,
      },
      {
        lat: 13.031714505643084,
        lng: 80.16525540665963,
      },
      {
        lat: 13.03318162426505,
        lng: 80.16246778851416,
      },
      {
        lat: 13.034306957923024,
        lng: 80.16041984215326,
      },
      {
        lat: 13.034305165472114,
        lng: 80.16012514565539,
      },
      {
        lat: 13.03440155933552,
        lng: 80.15970682165292,
      },
      {
        lat: 13.034446843534631,
        lng: 80.15901877784235,
      },
      {
        lat: 13.034267735652522,
        lng: 80.15803792145213,
      },
      {
        lat: 13.034260715219785,
        lng: 80.15688369350168,
      },
      {
        lat: 13.034589630614072,
        lng: 80.15641027563356,
      },
      {
        lat: 13.035086927932923,
        lng: 80.15660988817888,
      },
      {
        lat: 13.035623739245795,
        lng: 80.1570134760034,
      },
      {
        lat: 13.03613838596431,
        lng: 80.157151966264,
      },
      {
        lat: 13.036780435134876,
        lng: 80.15796279443109,
      },
      {
        lat: 13.037446882945094,
        lng: 80.15940601915284,
      },
      {
        lat: 13.037740059582315,
        lng: 80.16030142611825,
      },
      {
        lat: 13.03782586334564,
        lng: 80.16089248988578,
      },
      {
        lat: 13.038657228995518,
        lng: 80.16094510373772,
      },
      {
        lat: 13.038691120188341,
        lng: 80.16123207834076,
      },
      {
        lat: 13.038795737477646,
        lng: 80.16153729118608,
      },
      {
        lat: 13.03878387431577,
        lng: 80.16296585007957,
      },
      {
        lat: 13.038373472545512,
        lng: 80.16307135616572,
      },
      {
        lat: 13.038085881022255,
        lng: 80.16309419667728,
      },
      {
        lat: 13.038086873931404,
        lng: 80.16325744067315,
      },
      {
        lat: 13.038252399889574,
        lng: 80.16343969873303,
      },
      {
        lat: 13.03835912711076,
        lng: 80.16409180506948,
      },
      {
        lat: 13.038390600228333,
        lng: 80.16588731509489,
      },
      {
        lat: 13.03862584692413,
        lng: 80.16739540883339,
      },
      {
        lat: 13.038922126402438,
        lng: 80.168800953286,
      },
      {
        lat: 13.039069583516556,
        lng: 80.16939149526506,
      },
      {
        lat: 13.039186623030403,
        lng: 80.16949884401865,
      },
      {
        lat: 13.038384652886018,
        lng: 80.16962795006508,
      },
      {
        lat: 13.037788744485532,
        lng: 80.16973171217012,
      },
      {
        lat: 13.037570745082457,
        lng: 80.16985201968885,
      },
      {
        lat: 13.037433000682658,
        lng: 80.1700901105218,
      },
      {
        lat: 13.037145793865802,
        lng: 80.17190896673672,
      },
      {
        lat: 13.037027452763093,
        lng: 80.17206791833252,
      },
      {
        lat: 13.036848740284245,
        lng: 80.17210891833362,
      },
      {
        lat: 13.036530001838232,
        lng: 80.17201289712206,
      },
      {
        lat: 13.036171976467891,
        lng: 80.17199618342806,
      },
      {
        lat: 13.036136652266027,
        lng: 80.17272700134069,
      },
      {
        lat: 13.03601987223576,
        lng: 80.17314260854664,
      },
      {
        lat: 13.036023445985366,
        lng: 80.17373016800924,
      },
      {
        lat: 13.035847031827462,
        lng: 80.1736933924731,
      },
      {
        lat: 13.035500168252222,
        lng: 80.1735980908469,
      },
      {
        lat: 13.03507805920413,
        lng: 80.17333151090287,
      },
      {
        lat: 13.035051682831874,
        lng: 80.17306158177064,
      },
      {
        lat: 13.035174004954865,
        lng: 80.1728395127758,
      },
      {
        lat: 13.035221529717555,
        lng: 80.17251983958782,
      },
      {
        lat: 13.033020266707734,
        lng: 80.1725384694475,
      },
      {
        lat: 13.032824342191551,
        lng: 80.1728593985811,
      },
      {
        lat: 13.03267698803426,
        lng: 80.17303256081723,
      },
      {
        lat: 13.032305540538013,
        lng: 80.17296202655677,
      },
      {
        lat: 13.031784199579013,
        lng: 80.17264716782675,
      },
      {
        lat: 13.030965461629291,
        lng: 80.17223658882212,
      },
      {
        lat: 13.029354810215064,
        lng: 80.17175903406925,
      },
      {
        lat: 13.026729048222426,
        lng: 80.17111815532108,
      },
      {
        lat: 13.02478234008351,
        lng: 80.1706780110343,
      },
      {
        lat: 13.02480930478237,
        lng: 80.17038269232411,
      },
      {
        lat: 13.02518273436497,
        lng: 80.16915596918999,
      },
      {
        lat: 13.025224024832115,
        lng: 80.16857720827848,
      },
      {
        lat: 13.025244805370548,
        lng: 80.16831007327221,
      },
      {
        lat: 13.025442925217911,
        lng: 80.16772998507322,
      },
      {
        lat: 13.025307282291015,
        lng: 80.16753091370308,
      },
      {
        lat: 13.025060700817699,
        lng: 80.16751075399134,
      },
      {
        lat: 13.024373624868412,
        lng: 80.16750393618194,
      },
    ],
    properties: {
      Ward_No: 151,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#f6d04d",
      zname: "VALASARAVAKKAM(151)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.052429611044543,
        lng: 80.1644742670935,
      },
      {
        lat: 13.05113358424286,
        lng: 80.16473020294613,
      },
      {
        lat: 13.045027686072762,
        lng: 80.16319780575083,
      },
      {
        lat: 13.052274494313822,
        lng: 80.16302949753772,
      },
      {
        lat: 13.05034023314737,
        lng: 80.16497745420615,
      },
      {
        lat: 13.050657192224724,
        lng: 80.16248719646869,
      },
      {
        lat: 13.046458195960817,
        lng: 80.16467280513486,
      },
      {
        lat: 13.055365587340326,
        lng: 80.165443562284,
      },
      {
        lat: 13.063463039636584,
        lng: 80.16630316528592,
      },
      {
        lat: 13.053915145400902,
        lng: 80.16434905232671,
      },
      {
        lat: 13.057145204797175,
        lng: 80.16174828096338,
      },
      {
        lat: 13.058036535600003,
        lng: 80.16365678895076,
      },
      {
        lat: 13.059357608820653,
        lng: 80.16423273954085,
      },
      {
        lat: 13.05836038659546,
        lng: 80.16531989912167,
      },
      {
        lat: 13.058592744693506,
        lng: 80.16233530979216,
      },
      {
        lat: 13.053338186208057,
        lng: 80.1654124166612,
      },
      {
        lat: 13.056450360989585,
        lng: 80.16613932494188,
      },
      {
        lat: 13.051551299747494,
        lng: 80.16557061701181,
      },
      {
        lat: 13.055209107906407,
        lng: 80.16511289239685,
      },
      {
        lat: 13.060670559857147,
        lng: 80.16278841141423,
      },
      {
        lat: 13.061415657551628,
        lng: 80.16347445897281,
      },
      {
        lat: 13.052513478283275,
        lng: 80.16647124866117,
      },
      {
        lat: 13.049751164798053,
        lng: 80.16623984699838,
      },
      {
        lat: 13.064854385177686,
        lng: 80.16538714169283,
      },
      {
        lat: 13.058454167771291,
        lng: 80.16432995909722,
      },
      {
        lat: 13.054997101761165,
        lng: 80.16167128783226,
      },
      {
        lat: 13.038385621417053,
        lng: 80.1631957184734,
      },
      {
        lat: 13.045971963828068,
        lng: 80.16397692730192,
      },
      {
        lat: 13.050694772560933,
        lng: 80.16295730102465,
      },
      {
        lat: 13.047748771916625,
        lng: 80.1630340854765,
      },
      {
        lat: 13.053327959994514,
        lng: 80.16498923311535,
      },
      {
        lat: 13.046181286472347,
        lng: 80.16497381752457,
      },
      {
        lat: 13.040653528252196,
        lng: 80.16203729930034,
      },
      {
        lat: 13.064081907765196,
        lng: 80.16425273067176,
      },
      {
        lat: 13.050407530546186,
        lng: 80.16508560577752,
      },
      {
        lat: 13.05312828535357,
        lng: 80.16395193564851,
      },
      {
        lat: 13.056611393606138,
        lng: 80.16260053090478,
      },
      {
        lat: 13.057327882381731,
        lng: 80.16441275739625,
      },
      {
        lat: 13.047130006354967,
        lng: 80.16303174008092,
      },
      {
        lat: 13.050474978695807,
        lng: 80.16523437795766,
      },
      {
        lat: 13.052315968669596,
        lng: 80.1638301571332,
      },
      {
        lat: 13.062059240970628,
        lng: 80.16347530507507,
      },
      {
        lat: 13.05599315980713,
        lng: 80.16478130542987,
      },
      {
        lat: 13.043763596631006,
        lng: 80.1640298993935,
      },
      {
        lat: 13.044804035003448,
        lng: 80.16184466725696,
      },
      {
        lat: 13.055473591293385,
        lng: 80.16458769707498,
      },
      {
        lat: 13.061922218132283,
        lng: 80.1659646417062,
      },
      {
        lat: 13.059538788996498,
        lng: 80.16441731708558,
      },
      {
        lat: 13.041292321749506,
        lng: 80.1618777733429,
      },
      {
        lat: 13.05456707194211,
        lng: 80.16363235896198,
      },
      {
        lat: 13.051326711392386,
        lng: 80.16468258297111,
      },
      {
        lat: 13.05319121134455,
        lng: 80.16599172511617,
      },
      {
        lat: 13.062362711396291,
        lng: 80.16287069413417,
      },
    ],
    cases: 53,
    coords: [
      {
        lat: 13.038288698053778,
        lng: 80.16366148154147,
      },
      {
        lat: 13.038252399889574,
        lng: 80.16343969873303,
      },
      {
        lat: 13.038086873931404,
        lng: 80.16325744067315,
      },
      {
        lat: 13.038085881022255,
        lng: 80.16309419667728,
      },
      {
        lat: 13.038373472545512,
        lng: 80.16307135616572,
      },
      {
        lat: 13.03878387431577,
        lng: 80.16296585007957,
      },
      {
        lat: 13.038795737477646,
        lng: 80.16153729118608,
      },
      {
        lat: 13.038691120188341,
        lng: 80.16123207834076,
      },
      {
        lat: 13.038657228995518,
        lng: 80.16094510373772,
      },
      {
        lat: 13.039532350732092,
        lng: 80.16100048673985,
      },
      {
        lat: 13.040086237979871,
        lng: 80.1608325466493,
      },
      {
        lat: 13.041056827266315,
        lng: 80.16075546247254,
      },
      {
        lat: 13.041047497145124,
        lng: 80.16090835168555,
      },
      {
        lat: 13.04147667222027,
        lng: 80.16107477108642,
      },
      {
        lat: 13.042591389726825,
        lng: 80.16132041437436,
      },
      {
        lat: 13.04433249569838,
        lng: 80.16130567894298,
      },
      {
        lat: 13.04558854779268,
        lng: 80.1613233905853,
      },
      {
        lat: 13.0460754978177,
        lng: 80.16160268876892,
      },
      {
        lat: 13.046307287125648,
        lng: 80.162167565812,
      },
      {
        lat: 13.046594438104597,
        lng: 80.16244855494674,
      },
      {
        lat: 13.047508839434013,
        lng: 80.16261086775202,
      },
      {
        lat: 13.048678229103439,
        lng: 80.16245926122001,
      },
      {
        lat: 13.050217122750446,
        lng: 80.16204945004876,
      },
      {
        lat: 13.050415370402769,
        lng: 80.16179269479616,
      },
      {
        lat: 13.05069890146354,
        lng: 80.16147853389441,
      },
      {
        lat: 13.053952771639993,
        lng: 80.16145099554731,
      },
      {
        lat: 13.055780022905417,
        lng: 80.16152055685646,
      },
      {
        lat: 13.057092815687229,
        lng: 80.16148110441395,
      },
      {
        lat: 13.058406125600277,
        lng: 80.16152667340513,
      },
      {
        lat: 13.060405150321841,
        lng: 80.16167980676124,
      },
      {
        lat: 13.061204863636746,
        lng: 80.16175806439045,
      },
      {
        lat: 13.061832286364224,
        lng: 80.16166772853877,
      },
      {
        lat: 13.063002882673004,
        lng: 80.16171450535236,
      },
      {
        lat: 13.063489315566846,
        lng: 80.16190878210222,
      },
      {
        lat: 13.063577012254118,
        lng: 80.16224814314408,
      },
      {
        lat: 13.063827042421712,
        lng: 80.1627608674224,
      },
      {
        lat: 13.063817844310213,
        lng: 80.16306432717532,
      },
      {
        lat: 13.065533093971435,
        lng: 80.1662875607443,
      },
      {
        lat: 13.06576197272271,
        lng: 80.16715519087316,
      },
      {
        lat: 13.065914295994828,
        lng: 80.16769044317888,
      },
      {
        lat: 13.06423995910184,
        lng: 80.16813014640591,
      },
      {
        lat: 13.063774146600444,
        lng: 80.16813408869788,
      },
      {
        lat: 13.0623096714622,
        lng: 80.16631484140488,
      },
      {
        lat: 13.061691535688755,
        lng: 80.16578353139134,
      },
      {
        lat: 13.061464232309984,
        lng: 80.16517490794345,
      },
      {
        lat: 13.061271493292821,
        lng: 80.16412195765932,
      },
      {
        lat: 13.060798045032712,
        lng: 80.16411289240587,
      },
      {
        lat: 13.06045989356905,
        lng: 80.16419334842607,
      },
      {
        lat: 13.060278659068207,
        lng: 80.16437593529295,
      },
      {
        lat: 13.06028149065715,
        lng: 80.16484147626868,
      },
      {
        lat: 13.060053194185173,
        lng: 80.1658521324379,
      },
      {
        lat: 13.059586847990689,
        lng: 80.1662699147491,
      },
      {
        lat: 13.059041728693513,
        lng: 80.16658490486171,
      },
      {
        lat: 13.054639939177086,
        lng: 80.16667388778585,
      },
      {
        lat: 13.04974276661307,
        lng: 80.16668946910825,
      },
      {
        lat: 13.04950628999542,
        lng: 80.16635522912554,
      },
      {
        lat: 13.049427831514016,
        lng: 80.1663041637022,
      },
      {
        lat: 13.048983128578993,
        lng: 80.16599755071182,
      },
      {
        lat: 13.047781461887999,
        lng: 80.16543869691282,
      },
      {
        lat: 13.046764018597631,
        lng: 80.16518866061034,
      },
      {
        lat: 13.045018019162127,
        lng: 80.16507411386178,
      },
      {
        lat: 13.043740568268342,
        lng: 80.16490387222768,
      },
      {
        lat: 13.042822912097796,
        lng: 80.1639287792718,
      },
      {
        lat: 13.042509707414034,
        lng: 80.16382797112863,
      },
      {
        lat: 13.040684462944807,
        lng: 80.16353304201908,
      },
      {
        lat: 13.039745792756612,
        lng: 80.16338579791503,
      },
      {
        lat: 13.038288698053778,
        lng: 80.16366148154147,
      },
    ],
    properties: {
      Ward_No: 146,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#8bc24c",
      zname: "VALASARAVAKKAM(146)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.076522791949573,
        lng: 80.16654765283714,
      },
      {
        lat: 13.082747631371133,
        lng: 80.16266879674859,
      },
      {
        lat: 13.079035779029313,
        lng: 80.16204819948352,
      },
      {
        lat: 13.066787124222952,
        lng: 80.17007932828943,
      },
      {
        lat: 13.074587303953829,
        lng: 80.17171817934405,
      },
      {
        lat: 13.072680777021267,
        lng: 80.1683080898889,
      },
      {
        lat: 13.078121033154638,
        lng: 80.16399345439827,
      },
      {
        lat: 13.075616649689215,
        lng: 80.17482548360589,
      },
      {
        lat: 13.073645017936553,
        lng: 80.16296892601517,
      },
      {
        lat: 13.080240488384597,
        lng: 80.16210052083541,
      },
      {
        lat: 13.081865807748683,
        lng: 80.16688768039842,
      },
      {
        lat: 13.077798536985428,
        lng: 80.16218337944427,
      },
      {
        lat: 13.065998262944648,
        lng: 80.16784904322009,
      },
      {
        lat: 13.078688765252954,
        lng: 80.16238402140864,
      },
      {
        lat: 13.07264460118455,
        lng: 80.1647151793875,
      },
      {
        lat: 13.068865428801114,
        lng: 80.16445649926484,
      },
      {
        lat: 13.068667493882403,
        lng: 80.16767594552553,
      },
      {
        lat: 13.06857566788101,
        lng: 80.1721691919601,
      },
      {
        lat: 13.067128270127872,
        lng: 80.16362647322151,
      },
      {
        lat: 13.073855509357315,
        lng: 80.16433046920086,
      },
      {
        lat: 13.07527136806251,
        lng: 80.1689064171475,
      },
      {
        lat: 13.071889012212003,
        lng: 80.17484291825866,
      },
      {
        lat: 13.073016633405715,
        lng: 80.1770776328308,
      },
      {
        lat: 13.072388494410541,
        lng: 80.16382507352404,
      },
      {
        lat: 13.071707867036043,
        lng: 80.16321017670724,
      },
      {
        lat: 13.06880454783176,
        lng: 80.1687865923176,
      },
      {
        lat: 13.080134845349903,
        lng: 80.17062631068282,
      },
      {
        lat: 13.065310764489666,
        lng: 80.16437896208488,
      },
      {
        lat: 13.072457133784804,
        lng: 80.17354966654894,
      },
      {
        lat: 13.07338462115381,
        lng: 80.17623445064005,
      },
      {
        lat: 13.08048610060829,
        lng: 80.16882266720064,
      },
      {
        lat: 13.077453098176518,
        lng: 80.17070403644426,
      },
      {
        lat: 13.071019143555155,
        lng: 80.17249042927413,
      },
      {
        lat: 13.076657669642092,
        lng: 80.16044536019946,
      },
      {
        lat: 13.07584323890745,
        lng: 80.16970856712055,
      },
      {
        lat: 13.072784215435684,
        lng: 80.17496612932548,
      },
      {
        lat: 13.0716915550425,
        lng: 80.17142329568219,
      },
      {
        lat: 13.077742367372222,
        lng: 80.165745025491,
      },
      {
        lat: 13.068029812168426,
        lng: 80.16796557946779,
      },
      {
        lat: 13.071451733562288,
        lng: 80.1668178107166,
      },
      {
        lat: 13.07203221831198,
        lng: 80.17038333835494,
      },
      {
        lat: 13.073666379339762,
        lng: 80.16715570983827,
      },
      {
        lat: 13.073747008057785,
        lng: 80.16845078781165,
      },
      {
        lat: 13.074325703733473,
        lng: 80.17138558827341,
      },
      {
        lat: 13.076041918456061,
        lng: 80.16405703377174,
      },
      {
        lat: 13.07587273692093,
        lng: 80.17132608904092,
      },
      {
        lat: 13.07336886853235,
        lng: 80.17064655167077,
      },
      {
        lat: 13.06965713759479,
        lng: 80.17040657952033,
      },
      {
        lat: 13.074866139625545,
        lng: 80.16293453936383,
      },
      {
        lat: 13.070406359721183,
        lng: 80.17453287594597,
      },
      {
        lat: 13.068376790718252,
        lng: 80.16934508712494,
      },
      {
        lat: 13.080685546397024,
        lng: 80.1626336329888,
      },
      {
        lat: 13.072538414957043,
        lng: 80.17399552580046,
      },
      {
        lat: 13.077762901318188,
        lng: 80.17411637440452,
      },
      {
        lat: 13.073824980919936,
        lng: 80.16623892784342,
      },
      {
        lat: 13.078817171780937,
        lng: 80.1631297983715,
      },
      {
        lat: 13.070335166820412,
        lng: 80.17113023485578,
      },
      {
        lat: 13.080004014477883,
        lng: 80.17118271908231,
      },
      {
        lat: 13.080156866756031,
        lng: 80.16470147443472,
      },
      {
        lat: 13.068035286925594,
        lng: 80.17278592623809,
      },
      {
        lat: 13.076586166477254,
        lng: 80.16975352885166,
      },
      {
        lat: 13.066159339285832,
        lng: 80.16768020411021,
      },
      {
        lat: 13.071420489391208,
        lng: 80.17523316988785,
      },
      {
        lat: 13.073770849657212,
        lng: 80.16494628345092,
      },
      {
        lat: 13.078125492980039,
        lng: 80.16258418925463,
      },
      {
        lat: 13.068143182868189,
        lng: 80.16857273526006,
      },
      {
        lat: 13.072601653531947,
        lng: 80.16638967210937,
      },
      {
        lat: 13.064956322382507,
        lng: 80.16387734754632,
      },
      {
        lat: 13.07134391742475,
        lng: 80.17541970929759,
      },
    ],
    cases: 69,
    coords: [
      {
        lat: 13.072183657022464,
        lng: 80.17743471638707,
      },
      {
        lat: 13.071770099059899,
        lng: 80.17645606733674,
      },
      {
        lat: 13.071141510168152,
        lng: 80.17568133140121,
      },
      {
        lat: 13.069957555547969,
        lng: 80.17477363875608,
      },
      {
        lat: 13.06861437515947,
        lng: 80.1742802644219,
      },
      {
        lat: 13.06787221304951,
        lng: 80.17382768915235,
      },
      {
        lat: 13.067243903236482,
        lng: 80.17309883649308,
      },
      {
        lat: 13.066797645466986,
        lng: 80.17190958669995,
      },
      {
        lat: 13.06664938512816,
        lng: 80.1703277869543,
      },
      {
        lat: 13.066457286250877,
        lng: 80.16913638614879,
      },
      {
        lat: 13.065914295994828,
        lng: 80.16769044317888,
      },
      {
        lat: 13.06576197272271,
        lng: 80.16715519087316,
      },
      {
        lat: 13.065533093971435,
        lng: 80.1662875607443,
      },
      {
        lat: 13.063817844310213,
        lng: 80.16306432717532,
      },
      {
        lat: 13.063827042421712,
        lng: 80.1627608674224,
      },
      {
        lat: 13.06386605938077,
        lng: 80.1628408775361,
      },
      {
        lat: 13.064238838522288,
        lng: 80.16312114197754,
      },
      {
        lat: 13.066893483938083,
        lng: 80.16312701696177,
      },
      {
        lat: 13.067406218650872,
        lng: 80.16295262593411,
      },
      {
        lat: 13.067433727109453,
        lng: 80.16278234150202,
      },
      {
        lat: 13.067230997653613,
        lng: 80.16230224432854,
      },
      {
        lat: 13.067258161357907,
        lng: 80.1620752789406,
      },
      {
        lat: 13.06774338761229,
        lng: 80.162071172345,
      },
      {
        lat: 13.06820058827702,
        lng: 80.16215232874744,
      },
      {
        lat: 13.068660374597748,
        lng: 80.16265859231913,
      },
      {
        lat: 13.069433441339255,
        lng: 80.16304883677005,
      },
      {
        lat: 13.070346980783487,
        lng: 80.16306944718555,
      },
      {
        lat: 13.071058825034124,
        lng: 80.16278000329505,
      },
      {
        lat: 13.07171461810524,
        lng: 80.16266108540114,
      },
      {
        lat: 13.07191200387244,
        lng: 80.16226262775895,
      },
      {
        lat: 13.072110768655936,
        lng: 80.16209089394017,
      },
      {
        lat: 13.072453970814397,
        lng: 80.16220135707842,
      },
      {
        lat: 13.072737674252235,
        lng: 80.16191553665472,
      },
      {
        lat: 13.072791139775642,
        lng: 80.16131990348931,
      },
      {
        lat: 13.072958259051324,
        lng: 80.16063828263218,
      },
      {
        lat: 13.073470649010055,
        lng: 80.16040721064883,
      },
      {
        lat: 13.075211754981728,
        lng: 80.16039247521745,
      },
      {
        lat: 13.075725523956926,
        lng: 80.16038812705737,
      },
      {
        lat: 13.076781259874197,
        lng: 80.16032250821684,
      },
      {
        lat: 13.077694799318321,
        lng: 80.16034311863241,
      },
      {
        lat: 13.07837947986443,
        lng: 80.16028064012987,
      },
      {
        lat: 13.078864878495926,
        lng: 80.16030487401206,
      },
      {
        lat: 13.07909425452506,
        lng: 80.16047298436408,
      },
      {
        lat: 13.07915444275392,
        lng: 80.16098262983787,
      },
      {
        lat: 13.079327250472419,
        lng: 80.16123624475264,
      },
      {
        lat: 13.079498679174561,
        lng: 80.16126313584397,
      },
      {
        lat: 13.079766087459097,
        lng: 80.16121590466915,
      },
      {
        lat: 13.080069643788235,
        lng: 80.16137511310039,
      },
      {
        lat: 13.081374438172704,
        lng: 80.16159518101172,
      },
      {
        lat: 13.081979864078603,
        lng: 80.16163627928007,
      },
      {
        lat: 13.083006907062,
        lng: 80.16211291967056,
      },
      {
        lat: 13.083501159791034,
        lng: 80.16301006850712,
      },
      {
        lat: 13.083528792033238,
        lng: 80.16372627789416,
      },
      {
        lat: 13.083043957004163,
        lng: 80.16437749120729,
      },
      {
        lat: 13.082675355408837,
        lng: 80.16500460973454,
      },
      {
        lat: 13.082350492158065,
        lng: 80.16516913664351,
      },
      {
        lat: 13.082305910437071,
        lng: 80.16549306896437,
      },
      {
        lat: 13.08247136416009,
        lng: 80.16590766799071,
      },
      {
        lat: 13.082402664263965,
        lng: 80.16609313799542,
      },
      {
        lat: 13.082125615675913,
        lng: 80.16646525988858,
      },
      {
        lat: 13.081897646814383,
        lng: 80.1672529657118,
      },
      {
        lat: 13.081834569425592,
        lng: 80.16836283102973,
      },
      {
        lat: 13.081886460405947,
        lng: 80.16924061261568,
      },
      {
        lat: 13.0822148377238,
        lng: 80.16965383277739,
      },
      {
        lat: 13.082220179105764,
        lng: 80.17053200832504,
      },
      {
        lat: 13.08176663094877,
        lng: 80.17250028797908,
      },
      {
        lat: 13.081168233177037,
        lng: 80.17361468385216,
      },
      {
        lat: 13.080704142819192,
        lng: 80.1738497222937,
      },
      {
        lat: 13.078935539201344,
        lng: 80.17391091258972,
      },
      {
        lat: 13.078239122538962,
        lng: 80.1742172504865,
      },
      {
        lat: 13.077494891213522,
        lng: 80.17431599339893,
      },
      {
        lat: 13.076588157981584,
        lng: 80.17448544482454,
      },
      {
        lat: 13.075983575452124,
        lng: 80.17458300585295,
      },
      {
        lat: 13.075497334796037,
        lng: 80.17500312033788,
      },
      {
        lat: 13.074619779996167,
        lng: 80.17614298986173,
      },
      {
        lat: 13.074016603093197,
        lng: 80.1764716497188,
      },
      {
        lat: 13.073738008315631,
        lng: 80.1765895629008,
      },
      {
        lat: 13.07348367227621,
        lng: 80.17686904828203,
      },
      {
        lat: 13.073184051702206,
        lng: 80.17735691657008,
      },
      {
        lat: 13.072649153007651,
        lng: 80.17743077677382,
      },
      {
        lat: 13.072183657022464,
        lng: 80.17743471638707,
      },
    ],
    properties: {
      Ward_No: 143,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#f6d04d",
      zname: "VALASARAVAKKAM(143)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.068678721582362,
        lng: 80.1831022794109,
      },
      {
        lat: 13.071582399437636,
        lng: 80.18677300323301,
      },
      {
        lat: 13.064515711334385,
        lng: 80.18306617957128,
      },
      {
        lat: 13.06752330212554,
        lng: 80.18566529800341,
      },
      {
        lat: 13.071263620197927,
        lng: 80.1873436322577,
      },
      {
        lat: 13.063462769079683,
        lng: 80.18527445685775,
      },
      {
        lat: 13.059135553659079,
        lng: 80.18735519013298,
      },
      {
        lat: 13.06176500922266,
        lng: 80.18088243480096,
      },
      {
        lat: 13.070571177472317,
        lng: 80.18521957642172,
      },
      {
        lat: 13.063881707718656,
        lng: 80.18835221162145,
      },
      {
        lat: 13.070651219953284,
        lng: 80.18663703345307,
      },
      {
        lat: 13.069737111977178,
        lng: 80.18864833188171,
      },
      {
        lat: 13.06111630489015,
        lng: 80.18676853480545,
      },
      {
        lat: 13.065689303628947,
        lng: 80.18636508313747,
      },
      {
        lat: 13.065482897048822,
        lng: 80.18154870302362,
      },
      {
        lat: 13.066929253526478,
        lng: 80.18275526298919,
      },
      {
        lat: 13.065003114146553,
        lng: 80.18579865569146,
      },
      {
        lat: 13.065831711486407,
        lng: 80.18779731549097,
      },
      {
        lat: 13.060078946421317,
        lng: 80.18759031241208,
      },
      {
        lat: 13.071742951606259,
        lng: 80.19031960705816,
      },
      {
        lat: 13.072094501032232,
        lng: 80.18141520214245,
      },
      {
        lat: 13.061511268716284,
        lng: 80.1897430392431,
      },
      {
        lat: 13.06680646170493,
        lng: 80.18027933545831,
      },
      {
        lat: 13.066580663277378,
        lng: 80.18642309076293,
      },
      {
        lat: 13.065992741349952,
        lng: 80.18517618295284,
      },
      {
        lat: 13.069883295692255,
        lng: 80.181654990895,
      },
      {
        lat: 13.070787231899804,
        lng: 80.18944546408417,
      },
      {
        lat: 13.060009628113555,
        lng: 80.18964249101712,
      },
      {
        lat: 13.061860231998438,
        lng: 80.1832336030285,
      },
      {
        lat: 13.060575407369829,
        lng: 80.18733764429156,
      },
      {
        lat: 13.068094692151176,
        lng: 80.18702185868095,
      },
      {
        lat: 13.058496509736738,
        lng: 80.18525933376345,
      },
      {
        lat: 13.070655653470398,
        lng: 80.18321956038373,
      },
      {
        lat: 13.058418631316309,
        lng: 80.18283450618988,
      },
      {
        lat: 13.063192837439251,
        lng: 80.18129043099555,
      },
      {
        lat: 13.068227977099236,
        lng: 80.18669668737144,
      },
      {
        lat: 13.066936624696208,
        lng: 80.1858391973729,
      },
      {
        lat: 13.07160979628056,
        lng: 80.18979512649501,
      },
      {
        lat: 13.058610993383898,
        lng: 80.18294924863312,
      },
      {
        lat: 13.068251441958257,
        lng: 80.18757066543338,
      },
      {
        lat: 13.062361786693865,
        lng: 80.18283102269494,
      },
      {
        lat: 13.058456597082628,
        lng: 80.18396396937119,
      },
      {
        lat: 13.070827955137988,
        lng: 80.18421658756552,
      },
      {
        lat: 13.063044274422465,
        lng: 80.18636886158707,
      },
      {
        lat: 13.05897707003379,
        lng: 80.18693541819924,
      },
      {
        lat: 13.069288407995614,
        lng: 80.19098483244828,
      },
      {
        lat: 13.07274565964688,
        lng: 80.18194021293338,
      },
      {
        lat: 13.059697667335687,
        lng: 80.18361141855522,
      },
      {
        lat: 13.071720397982995,
        lng: 80.18409876426013,
      },
      {
        lat: 13.062614610791972,
        lng: 80.17874422011435,
      },
      {
        lat: 13.069786299158526,
        lng: 80.18835426384393,
      },
      {
        lat: 13.06979724906172,
        lng: 80.18098133354154,
      },
      {
        lat: 13.061632884047448,
        lng: 80.18306508394875,
      },
      {
        lat: 13.062477617327781,
        lng: 80.18941175253127,
      },
      {
        lat: 13.062207424710191,
        lng: 80.18573748393233,
      },
      {
        lat: 13.072830757750708,
        lng: 80.18999769878792,
      },
      {
        lat: 13.06930686434052,
        lng: 80.18564902394381,
      },
      {
        lat: 13.073334293679899,
        lng: 80.18792212156431,
      },
      {
        lat: 13.070816789910188,
        lng: 80.18964124628332,
      },
      {
        lat: 13.060761362921742,
        lng: 80.18619752332233,
      },
      {
        lat: 13.070636822763763,
        lng: 80.18755195662105,
      },
      {
        lat: 13.070757330578115,
        lng: 80.18148360446587,
      },
      {
        lat: 13.061201354382401,
        lng: 80.18864064915695,
      },
      {
        lat: 13.067685251191403,
        lng: 80.1870429674269,
      },
      {
        lat: 13.069265764655942,
        lng: 80.18483683152955,
      },
      {
        lat: 13.068664191262732,
        lng: 80.18210868636754,
      },
      {
        lat: 13.071829328048675,
        lng: 80.19085693843188,
      },
      {
        lat: 13.067149714028531,
        lng: 80.18710236538662,
      },
      {
        lat: 13.069462592134505,
        lng: 80.18479295280338,
      },
      {
        lat: 13.064043897101634,
        lng: 80.18984990220889,
      },
      {
        lat: 13.062261029637277,
        lng: 80.18375471156114,
      },
      {
        lat: 13.064436348550142,
        lng: 80.18478099332286,
      },
      {
        lat: 13.071455972346762,
        lng: 80.18448053225202,
      },
      {
        lat: 13.069237437406047,
        lng: 80.18336159111831,
      },
      {
        lat: 13.064524085939302,
        lng: 80.18284980134726,
      },
      {
        lat: 13.062692496285596,
        lng: 80.18198051330953,
      },
      {
        lat: 13.070841472905206,
        lng: 80.18224444492549,
      },
      {
        lat: 13.067610734919231,
        lng: 80.18601918337077,
      },
      {
        lat: 13.061235367490257,
        lng: 80.1857597604477,
      },
      {
        lat: 13.06834622741362,
        lng: 80.1878362036676,
      },
      {
        lat: 13.065689947730258,
        lng: 80.1807565425183,
      },
      {
        lat: 13.06749245523014,
        lng: 80.19130387616507,
      },
      {
        lat: 13.062602201170753,
        lng: 80.18784638018658,
      },
      {
        lat: 13.071190034391563,
        lng: 80.18297454415273,
      },
      {
        lat: 13.066724752067435,
        lng: 80.18567065844896,
      },
      {
        lat: 13.06323785399481,
        lng: 80.18288026083903,
      },
      {
        lat: 13.063438231871636,
        lng: 80.18936496753396,
      },
      {
        lat: 13.06498839972957,
        lng: 80.18693124984028,
      },
      {
        lat: 13.071362843761023,
        lng: 80.1909515127331,
      },
      {
        lat: 13.07223310102346,
        lng: 80.18519250512396,
      },
      {
        lat: 13.069844029369838,
        lng: 80.18816357047065,
      },
      {
        lat: 13.06189027521288,
        lng: 80.185940131049,
      },
      {
        lat: 13.068275641478794,
        lng: 80.18759111520504,
      },
      {
        lat: 13.066262677125874,
        lng: 80.18669380087837,
      },
      {
        lat: 13.071466996111175,
        lng: 80.18311858169335,
      },
      {
        lat: 13.067484980175765,
        lng: 80.18592715703804,
      },
      {
        lat: 13.06042361611955,
        lng: 80.18597720161742,
      },
      {
        lat: 13.072480199463811,
        lng: 80.19065360584291,
      },
      {
        lat: 13.064740160673223,
        lng: 80.18437625137746,
      },
      {
        lat: 13.065468089091992,
        lng: 80.18965661311987,
      },
      {
        lat: 13.068734855751455,
        lng: 80.1896293864878,
      },
      {
        lat: 13.063573586847898,
        lng: 80.18211840208122,
      },
      {
        lat: 13.072890468941672,
        lng: 80.18701394713935,
      },
      {
        lat: 13.068469006101584,
        lng: 80.19146834529904,
      },
      {
        lat: 13.072582847133546,
        lng: 80.18206438158496,
      },
      {
        lat: 13.072650008947383,
        lng: 80.18993334088702,
      },
      {
        lat: 13.065445368508994,
        lng: 80.1821080642288,
      },
      {
        lat: 13.07278493554468,
        lng: 80.1836177782121,
      },
      {
        lat: 13.060130773588256,
        lng: 80.18432813799242,
      },
      {
        lat: 13.059395018278343,
        lng: 80.18998866676147,
      },
      {
        lat: 13.069964162821597,
        lng: 80.18201392735496,
      },
      {
        lat: 13.063615502037814,
        lng: 80.18885796338635,
      },
      {
        lat: 13.070930109683822,
        lng: 80.18589088966958,
      },
      {
        lat: 13.062865097990937,
        lng: 80.18527121391504,
      },
      {
        lat: 13.069385170566687,
        lng: 80.1818189742651,
      },
      {
        lat: 13.070789766477803,
        lng: 80.1902501960485,
      },
      {
        lat: 13.071154687525691,
        lng: 80.18772390184446,
      },
      {
        lat: 13.069716968355458,
        lng: 80.18268566317211,
      },
      {
        lat: 13.070080480831216,
        lng: 80.18112299956947,
      },
      {
        lat: 13.063157572886503,
        lng: 80.19065629337528,
      },
      {
        lat: 13.06910352849122,
        lng: 80.18958808273212,
      },
      {
        lat: 13.069420688662792,
        lng: 80.19058477937448,
      },
      {
        lat: 13.067664066851618,
        lng: 80.18636745262499,
      },
      {
        lat: 13.063264365133966,
        lng: 80.17942511314916,
      },
      {
        lat: 13.068092085755653,
        lng: 80.18301011794807,
      },
      {
        lat: 13.069985934954683,
        lng: 80.18807900055681,
      },
      {
        lat: 13.061303047178436,
        lng: 80.18500213171598,
      },
      {
        lat: 13.07183632603432,
        lng: 80.18576304410102,
      },
      {
        lat: 13.06539153286236,
        lng: 80.18174285849246,
      },
      {
        lat: 13.063304381024103,
        lng: 80.19048835450832,
      },
      {
        lat: 13.058438766111987,
        lng: 80.18766487132942,
      },
      {
        lat: 13.059411786408203,
        lng: 80.18403794071556,
      },
      {
        lat: 13.071672908168958,
        lng: 80.18975800252397,
      },
      {
        lat: 13.066107406672597,
        lng: 80.18801727665443,
      },
      {
        lat: 13.064342124437324,
        lng: 80.18675916700639,
      },
      {
        lat: 13.072434807179416,
        lng: 80.18348331235863,
      },
      {
        lat: 13.062578453298425,
        lng: 80.18992306115041,
      },
    ],
    cases: 137,
    coords: [
      {
        lat: 13.062154743872666,
        lng: 80.178618329059,
      },
      {
        lat: 13.062878523833893,
        lng: 80.17865123979276,
      },
      {
        lat: 13.063215755181327,
        lng: 80.17875978116408,
      },
      {
        lat: 13.063328481802913,
        lng: 80.1788479434878,
      },
      {
        lat: 13.063533124154098,
        lng: 80.17929179333771,
      },
      {
        lat: 13.063602060951352,
        lng: 80.17955855898254,
      },
      {
        lat: 13.064141224599352,
        lng: 80.17966539134844,
      },
      {
        lat: 13.064972068237507,
        lng: 80.17976975515123,
      },
      {
        lat: 13.065398776268916,
        lng: 80.17983298107912,
      },
      {
        lat: 13.065824400280198,
        lng: 80.17971798346497,
      },
      {
        lat: 13.06645276960729,
        lng: 80.17973494450189,
      },
      {
        lat: 13.067037349110008,
        lng: 80.17993050886017,
      },
      {
        lat: 13.067532587431154,
        lng: 80.18019366660447,
      },
      {
        lat: 13.068386410001645,
        lng: 80.18038695228864,
      },
      {
        lat: 13.069060872696067,
        lng: 80.18060403503165,
      },
      {
        lat: 13.069374854105947,
        lng: 80.18057909863607,
      },
      {
        lat: 13.069802239650018,
        lng: 80.18075371427796,
      },
      {
        lat: 13.070253281638994,
        lng: 80.18112864151512,
      },
      {
        lat: 13.071017085515136,
        lng: 80.18127813087177,
      },
      {
        lat: 13.072049590448978,
        lng: 80.18133622978355,
      },
      {
        lat: 13.07238695729871,
        lng: 80.18146704909763,
      },
      {
        lat: 13.072657294667616,
        lng: 80.18142342495975,
      },
      {
        lat: 13.072746246565629,
        lng: 80.18191350310121,
      },
      {
        lat: 13.07302222925845,
        lng: 80.18519293967593,
      },
      {
        lat: 13.07291246170841,
        lng: 80.18628008907241,
      },
      {
        lat: 13.07308016443436,
        lng: 80.18706444622396,
      },
      {
        lat: 13.073740152011549,
        lng: 80.18842241385279,
      },
      {
        lat: 13.073789231738585,
        lng: 80.18883799778224,
      },
      {
        lat: 13.073652113071255,
        lng: 80.18925515755738,
      },
      {
        lat: 13.073644178029852,
        lng: 80.18972090202405,
      },
      {
        lat: 13.073826324033023,
        lng: 80.19067319256749,
      },
      {
        lat: 13.073041997961974,
        lng: 80.1906388536145,
      },
      {
        lat: 13.07203269606575,
        lng: 80.19093423395394,
      },
      {
        lat: 13.069931059421231,
        lng: 80.19144374354553,
      },
      {
        lat: 13.067332222558026,
        lng: 80.19162964580771,
      },
      {
        lat: 13.066340563580173,
        lng: 80.19143315393494,
      },
      {
        lat: 13.066008432171623,
        lng: 80.19110814958536,
      },
      {
        lat: 13.065962180497436,
        lng: 80.19028900286591,
      },
      {
        lat: 13.065753850696321,
        lng: 80.18996295075141,
      },
      {
        lat: 13.065216629393214,
        lng: 80.1898445666707,
      },
      {
        lat: 13.064473819747992,
        lng: 80.18985085326135,
      },
      {
        lat: 13.06385730394625,
        lng: 80.1902658400741,
      },
      {
        lat: 13.063120475667214,
        lng: 80.19125552183411,
      },
      {
        lat: 13.062750316962518,
        lng: 80.19146353912315,
      },
      {
        lat: 13.062336648043434,
        lng: 80.19130313247845,
      },
      {
        lat: 13.062046531507752,
        lng: 80.1911007032702,
      },
      {
        lat: 13.061550078959966,
        lng: 80.19090002033707,
      },
      {
        lat: 13.061094395167332,
        lng: 80.19061703855122,
      },
      {
        lat: 13.060391108159951,
        lng: 80.19033615229584,
      },
      {
        lat: 13.059730583696334,
        lng: 80.19030076557772,
      },
      {
        lat: 13.059274899903876,
        lng: 80.19001778379211,
      },
      {
        lat: 13.058942768495324,
        lng: 80.18969277944251,
      },
      {
        lat: 13.058364778436546,
        lng: 80.18965669421434,
      },
      {
        lat: 13.058086787970124,
        lng: 80.1896462116907,
      },
      {
        lat: 13.058054114192311,
        lng: 80.18869440121864,
      },
      {
        lat: 13.057937199698577,
        lng: 80.18843768535605,
      },
      {
        lat: 13.057916511802416,
        lng: 80.18740703908253,
      },
      {
        lat: 13.058709697385689,
        lng: 80.18742896007379,
      },
      {
        lat: 13.058727910834723,
        lng: 80.18568213640093,
      },
      {
        lat: 13.058309603382268,
        lng: 80.18565704271876,
      },
      {
        lat: 13.058276637702754,
        lng: 80.18260780852384,
      },
      {
        lat: 13.05870840570372,
        lng: 80.18247530168927,
      },
      {
        lat: 13.059227206501378,
        lng: 80.18242796005357,
      },
      {
        lat: 13.05999172945846,
        lng: 80.18247875755084,
      },
      {
        lat: 13.060713868011185,
        lng: 80.18267308339172,
      },
      {
        lat: 13.061218685801686,
        lng: 80.18269744491465,
      },
      {
        lat: 13.061244126598364,
        lng: 80.18213886803206,
      },
      {
        lat: 13.06108456647107,
        lng: 80.18198273183421,
      },
      {
        lat: 13.061119113992213,
        lng: 80.1805507431153,
      },
      {
        lat: 13.06227206270488,
        lng: 80.18045508363134,
      },
      {
        lat: 13.062305042849422,
        lng: 80.17876540283736,
      },
      {
        lat: 13.062154743872666,
        lng: 80.178618329059,
      },
    ],
    properties: {
      Ward_No: 145,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#de4307",
      zname: "VALASARAVAKKAM(145)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.06027322646602,
        lng: 80.17842533177536,
      },
      {
        lat: 13.070632051184475,
        lng: 80.18045250178021,
      },
      {
        lat: 13.065839745156424,
        lng: 80.1689947215033,
      },
      {
        lat: 13.072039657245485,
        lng: 80.1779015657108,
      },
      {
        lat: 13.06479990854119,
        lng: 80.1686777522255,
      },
      {
        lat: 13.063502922617802,
        lng: 80.1780084686748,
      },
      {
        lat: 13.071383305355091,
        lng: 80.18030804176601,
      },
      {
        lat: 13.071525393491827,
        lng: 80.17687033109284,
      },
      {
        lat: 13.063679772591671,
        lng: 80.17549207559607,
      },
      {
        lat: 13.06795483685382,
        lng: 80.17936290786677,
      },
      {
        lat: 13.067080779295601,
        lng: 80.17558019734545,
      },
      {
        lat: 13.062104458342752,
        lng: 80.1769709324789,
      },
      {
        lat: 13.063270468582674,
        lng: 80.17771846053816,
      },
      {
        lat: 13.05933873870458,
        lng: 80.17458952351456,
      },
      {
        lat: 13.065636792511622,
        lng: 80.17440616364149,
      },
      {
        lat: 13.068992180846436,
        lng: 80.1763697373798,
      },
      {
        lat: 13.070986157816831,
        lng: 80.17581834346421,
      },
      {
        lat: 13.068664217792664,
        lng: 80.18045819711162,
      },
      {
        lat: 13.068302282820852,
        lng: 80.17965297482436,
      },
      {
        lat: 13.065899255117577,
        lng: 80.17551464052312,
      },
      {
        lat: 13.056710126297402,
        lng: 80.17719309648177,
      },
      {
        lat: 13.063033823030343,
        lng: 80.17773658370356,
      },
      {
        lat: 13.069965721864412,
        lng: 80.17962343865155,
      },
      {
        lat: 13.058906637329061,
        lng: 80.17405148772643,
      },
      {
        lat: 13.071230270771487,
        lng: 80.17997065449116,
      },
      {
        lat: 13.06887369036938,
        lng: 80.18053023645359,
      },
      {
        lat: 13.068782538134759,
        lng: 80.1775941895838,
      },
      {
        lat: 13.063057619897267,
        lng: 80.17829593263653,
      },
      {
        lat: 13.05975330759884,
        lng: 80.17431796796978,
      },
      {
        lat: 13.057254694708442,
        lng: 80.17821552813842,
      },
      {
        lat: 13.05903447320493,
        lng: 80.1770357205283,
      },
      {
        lat: 13.06437853592107,
        lng: 80.17710595015913,
      },
      {
        lat: 13.071213538432144,
        lng: 80.17838625333374,
      },
      {
        lat: 13.064592809885536,
        lng: 80.17927545562499,
      },
      {
        lat: 13.065768948237498,
        lng: 80.17334875334204,
      },
      {
        lat: 13.064536244726959,
        lng: 80.17918975806502,
      },
      {
        lat: 13.060727630034933,
        lng: 80.17828109795582,
      },
      {
        lat: 13.062790041984334,
        lng: 80.1782532107235,
      },
      {
        lat: 13.066727065016694,
        lng: 80.171310005123,
      },
      {
        lat: 13.06753785636428,
        lng: 80.17360624477767,
      },
      {
        lat: 13.063267663316532,
        lng: 80.17808742475431,
      },
      {
        lat: 13.058281793785634,
        lng: 80.1747027890044,
      },
      {
        lat: 13.064392842442587,
        lng: 80.16819464975563,
      },
      {
        lat: 13.06704650992104,
        lng: 80.17260533085667,
      },
      {
        lat: 13.06658810471198,
        lng: 80.17503625979839,
      },
      {
        lat: 13.05689418583654,
        lng: 80.17794861610825,
      },
      {
        lat: 13.066435146289413,
        lng: 80.17628001654042,
      },
      {
        lat: 13.07129856738581,
        lng: 80.18088593316799,
      },
      {
        lat: 13.066153103036969,
        lng: 80.1749146248726,
      },
      {
        lat: 13.06712166529496,
        lng: 80.17983165511956,
      },
      {
        lat: 13.063319253599019,
        lng: 80.17839000394878,
      },
      {
        lat: 13.06920139016612,
        lng: 80.17818263911082,
      },
      {
        lat: 13.059142861354763,
        lng: 80.17457704156656,
      },
      {
        lat: 13.061471993845709,
        lng: 80.17626027574231,
      },
      {
        lat: 13.069524483929882,
        lng: 80.17787751076209,
      },
      {
        lat: 13.058533366532714,
        lng: 80.1732379138769,
      },
      {
        lat: 13.06781243706065,
        lng: 80.17838780219726,
      },
      {
        lat: 13.065713781987178,
        lng: 80.1759155315964,
      },
      {
        lat: 13.066142457003986,
        lng: 80.17024397403374,
      },
      {
        lat: 13.06561671775848,
        lng: 80.17529071247603,
      },
      {
        lat: 13.057738867458413,
        lng: 80.17726560621139,
      },
      {
        lat: 13.068058431536764,
        lng: 80.17832773810954,
      },
      {
        lat: 13.06495293194264,
        lng: 80.17603549620634,
      },
      {
        lat: 13.071747849038546,
        lng: 80.1796238922579,
      },
      {
        lat: 13.07047220098945,
        lng: 80.18095047393597,
      },
      {
        lat: 13.067905030005715,
        lng: 80.17542611399466,
      },
      {
        lat: 13.058818324453364,
        lng: 80.1767460049615,
      },
      {
        lat: 13.061390001233894,
        lng: 80.1755922295885,
      },
      {
        lat: 13.062040315379477,
        lng: 80.1763314991197,
      },
      {
        lat: 13.069788469486381,
        lng: 80.17688336247066,
      },
      {
        lat: 13.064174969482318,
        lng: 80.1762863103623,
      },
      {
        lat: 13.06688324833311,
        lng: 80.1776719065382,
      },
      {
        lat: 13.061426090354386,
        lng: 80.17741446324648,
      },
      {
        lat: 13.070804242544908,
        lng: 80.18023414240386,
      },
      {
        lat: 13.066625396861479,
        lng: 80.17597395071036,
      },
      {
        lat: 13.069889664527146,
        lng: 80.17664284216156,
      },
      {
        lat: 13.071129188210564,
        lng: 80.17921056513885,
      },
      {
        lat: 13.070996793348181,
        lng: 80.18088207108471,
      },
      {
        lat: 13.06521730520848,
        lng: 80.17507239147915,
      },
      {
        lat: 13.071649592078593,
        lng: 80.18049456971018,
      },
      {
        lat: 13.061644097981999,
        lng: 80.17696585211621,
      },
      {
        lat: 13.05794035697948,
        lng: 80.17700617838511,
      },
      {
        lat: 13.068340950963156,
        lng: 80.1785246522,
      },
      {
        lat: 13.069275501731974,
        lng: 80.1798950409909,
      },
      {
        lat: 13.05915038448055,
        lng: 80.17630640322092,
      },
      {
        lat: 13.064635280864826,
        lng: 80.17944401239893,
      },
      {
        lat: 13.059226682625068,
        lng: 80.17804631785219,
      },
      {
        lat: 13.070223673737349,
        lng: 80.18007714777856,
      },
      {
        lat: 13.067336278674075,
        lng: 80.17778612909038,
      },
      {
        lat: 13.058395633039318,
        lng: 80.17607406636566,
      },
      {
        lat: 13.063493702115657,
        lng: 80.17890519261454,
      },
      {
        lat: 13.066530701966233,
        lng: 80.17222000996667,
      },
    ],
    cases: 92,
    coords: [
      {
        lat: 13.062154743872666,
        lng: 80.178618329059,
      },
      {
        lat: 13.062072955391464,
        lng: 80.17853829564018,
      },
      {
        lat: 13.05985217508054,
        lng: 80.17849982282642,
      },
      {
        lat: 13.059664126479786,
        lng: 80.17840119558721,
      },
      {
        lat: 13.059260324493353,
        lng: 80.17839029610488,
      },
      {
        lat: 13.058957973693202,
        lng: 80.17846443979464,
      },
      {
        lat: 13.058770795857397,
        lng: 80.17850897481921,
      },
      {
        lat: 13.058409378274924,
        lng: 80.17835454699336,
      },
      {
        lat: 13.056577192384427,
        lng: 80.1783614254933,
      },
      {
        lat: 13.056584756732237,
        lng: 80.17646496179516,
      },
      {
        lat: 13.056449338222818,
        lng: 80.17618164792542,
      },
      {
        lat: 13.056533180607849,
        lng: 80.17426557466969,
      },
      {
        lat: 13.057506850146012,
        lng: 80.17420044226162,
      },
      {
        lat: 13.057536973037008,
        lng: 80.17287270754841,
      },
      {
        lat: 13.05958061121447,
        lng: 80.17287437570528,
      },
      {
        lat: 13.059680139834729,
        lng: 80.17353727325651,
      },
      {
        lat: 13.05979957417876,
        lng: 80.17433275031783,
      },
      {
        lat: 13.06031546015424,
        lng: 80.17436631223458,
      },
      {
        lat: 13.060908662226515,
        lng: 80.17455093177966,
      },
      {
        lat: 13.062266720789363,
        lng: 80.17488079012001,
      },
      {
        lat: 13.063394676501213,
        lng: 80.17506088390618,
      },
      {
        lat: 13.063775605327187,
        lng: 80.17488698403561,
      },
      {
        lat: 13.0643286499315,
        lng: 80.17474955549818,
      },
      {
        lat: 13.065340746838107,
        lng: 80.17472202586283,
      },
      {
        lat: 13.065311498576971,
        lng: 80.1730534416809,
      },
      {
        lat: 13.065349003225693,
        lng: 80.17293934028856,
      },
      {
        lat: 13.065462670571675,
        lng: 80.17278666631805,
      },
      {
        lat: 13.065404568159163,
        lng: 80.1726544100762,
      },
      {
        lat: 13.064846563936378,
        lng: 80.17197642872631,
      },
      {
        lat: 13.064383244055232,
        lng: 80.17116489806067,
      },
      {
        lat: 13.064366750440312,
        lng: 80.1684531861094,
      },
      {
        lat: 13.06423995910184,
        lng: 80.16813014640591,
      },
      {
        lat: 13.065914295994828,
        lng: 80.16769044317888,
      },
      {
        lat: 13.066457286250877,
        lng: 80.16913638614879,
      },
      {
        lat: 13.06664938512816,
        lng: 80.1703277869543,
      },
      {
        lat: 13.066797645466986,
        lng: 80.17190958669995,
      },
      {
        lat: 13.067243903236482,
        lng: 80.17309883649308,
      },
      {
        lat: 13.06787221304951,
        lng: 80.17382768915235,
      },
      {
        lat: 13.06861437515947,
        lng: 80.1742802644219,
      },
      {
        lat: 13.069957555547969,
        lng: 80.17477363875608,
      },
      {
        lat: 13.071141510168152,
        lng: 80.17568133140121,
      },
      {
        lat: 13.071770099059899,
        lng: 80.17645606733674,
      },
      {
        lat: 13.072183657022464,
        lng: 80.17743471638707,
      },
      {
        lat: 13.072360918010995,
        lng: 80.1797905455713,
      },
      {
        lat: 13.072657294667616,
        lng: 80.18142342495975,
      },
      {
        lat: 13.07238695729871,
        lng: 80.18146704909763,
      },
      {
        lat: 13.072049590448978,
        lng: 80.18133622978355,
      },
      {
        lat: 13.071017085515136,
        lng: 80.18127813087177,
      },
      {
        lat: 13.070253281638994,
        lng: 80.18112864151512,
      },
      {
        lat: 13.069802239650018,
        lng: 80.18075371427796,
      },
      {
        lat: 13.069374854105947,
        lng: 80.18057909863607,
      },
      {
        lat: 13.069060872696067,
        lng: 80.18060403503165,
      },
      {
        lat: 13.068386410001645,
        lng: 80.18038695228864,
      },
      {
        lat: 13.067532587431154,
        lng: 80.18019366660447,
      },
      {
        lat: 13.067037349110008,
        lng: 80.17993050886017,
      },
      {
        lat: 13.06645276960729,
        lng: 80.17973494450189,
      },
      {
        lat: 13.065824400280198,
        lng: 80.17971798346497,
      },
      {
        lat: 13.065398776268916,
        lng: 80.17983298107912,
      },
      {
        lat: 13.064972068237507,
        lng: 80.17976975515123,
      },
      {
        lat: 13.064141224599352,
        lng: 80.17966539134844,
      },
      {
        lat: 13.063602060951352,
        lng: 80.17955855898254,
      },
      {
        lat: 13.063533124154098,
        lng: 80.17929179333771,
      },
      {
        lat: 13.063328481802913,
        lng: 80.1788479434878,
      },
      {
        lat: 13.063215755181327,
        lng: 80.17875978116408,
      },
      {
        lat: 13.062878523833893,
        lng: 80.17865123979276,
      },
      {
        lat: 13.062154743872666,
        lng: 80.178618329059,
      },
    ],
    properties: {
      Ward_No: 144,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#f6d04d",
      zname: "VALASARAVAKKAM(144)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.057402422500427,
        lng: 80.18569992040523,
      },
      {
        lat: 13.05411987319317,
        lng: 80.18243812372147,
      },
      {
        lat: 13.05414500912349,
        lng: 80.18128291339458,
      },
      {
        lat: 13.060520402230024,
        lng: 80.1792428772022,
      },
      {
        lat: 13.058799359419593,
        lng: 80.18097327104626,
      },
      {
        lat: 13.05241546958778,
        lng: 80.17891612795123,
      },
      {
        lat: 13.05822907861963,
        lng: 80.18349979309066,
      },
      {
        lat: 13.052442278466204,
        lng: 80.18101143578488,
      },
      {
        lat: 13.056987224977185,
        lng: 80.17890013266455,
      },
      {
        lat: 13.054775432571043,
        lng: 80.1824020165503,
      },
      {
        lat: 13.056736904563525,
        lng: 80.17849665168916,
      },
      {
        lat: 13.055161945706526,
        lng: 80.18136894987371,
      },
      {
        lat: 13.052496885193886,
        lng: 80.17979146278438,
      },
      {
        lat: 13.053705844434246,
        lng: 80.18630812509515,
      },
      {
        lat: 13.057082685381083,
        lng: 80.17964227208549,
      },
      {
        lat: 13.056787048949358,
        lng: 80.18428838965818,
      },
      {
        lat: 13.061611835484001,
        lng: 80.1804108544226,
      },
      {
        lat: 13.054471398107824,
        lng: 80.18467749609809,
      },
      {
        lat: 13.05380788615069,
        lng: 80.18513291048706,
      },
      {
        lat: 13.055854899297595,
        lng: 80.18003217604166,
      },
      {
        lat: 13.05310602653387,
        lng: 80.18275119570721,
      },
      {
        lat: 13.056252259498017,
        lng: 80.18827587055644,
      },
      {
        lat: 13.052633970362713,
        lng: 80.18468649406209,
      },
      {
        lat: 13.055836713492857,
        lng: 80.18153272616935,
      },
      {
        lat: 13.0537826780855,
        lng: 80.17944547905005,
      },
      {
        lat: 13.054611528433394,
        lng: 80.1783400318145,
      },
      {
        lat: 13.053220463681225,
        lng: 80.18487957264867,
      },
      {
        lat: 13.05384192620152,
        lng: 80.18076822478324,
      },
      {
        lat: 13.055707869957567,
        lng: 80.18461870281317,
      },
      {
        lat: 13.05266800869145,
        lng: 80.17872092021759,
      },
      {
        lat: 13.057155762951469,
        lng: 80.17985950898122,
      },
      {
        lat: 13.055298820203534,
        lng: 80.17933469840438,
      },
      {
        lat: 13.055820426769271,
        lng: 80.18047633717238,
      },
      {
        lat: 13.057470869118433,
        lng: 80.18007811962572,
      },
      {
        lat: 13.052800328958908,
        lng: 80.18263696465822,
      },
      {
        lat: 13.058599657238746,
        lng: 80.18641679245242,
      },
      {
        lat: 13.055602672357562,
        lng: 80.18478160915365,
      },
      {
        lat: 13.057359202088005,
        lng: 80.18648280014486,
      },
      {
        lat: 13.058056544100564,
        lng: 80.18691342744813,
      },
      {
        lat: 13.058825641744663,
        lng: 80.18184510821818,
      },
      {
        lat: 13.055241810706612,
        lng: 80.18294984408898,
      },
      {
        lat: 13.057963015024374,
        lng: 80.17975528573432,
      },
      {
        lat: 13.056255243675581,
        lng: 80.18012180737853,
      },
      {
        lat: 13.052758020947163,
        lng: 80.18149765122313,
      },
      {
        lat: 13.052437920080928,
        lng: 80.18158718568672,
      },
      {
        lat: 13.059147259351787,
        lng: 80.18161239984269,
      },
      {
        lat: 13.056156657982802,
        lng: 80.18465110517764,
      },
      {
        lat: 13.052611343094098,
        lng: 80.18081432573143,
      },
      {
        lat: 13.060272997094668,
        lng: 80.18199326730455,
      },
      {
        lat: 13.056244724933114,
        lng: 80.18581440461429,
      },
      {
        lat: 13.05742616769899,
        lng: 80.18769231207472,
      },
      {
        lat: 13.055345558438807,
        lng: 80.18809193896527,
      },
      {
        lat: 13.057895031746298,
        lng: 80.18298294171649,
      },
      {
        lat: 13.060804502594756,
        lng: 80.18107712689114,
      },
      {
        lat: 13.060582697245515,
        lng: 80.18193694132295,
      },
      {
        lat: 13.05406598696573,
        lng: 80.18011354576221,
      },
      {
        lat: 13.058893290726228,
        lng: 80.18066356835149,
      },
      {
        lat: 13.060607499687732,
        lng: 80.17875325358855,
      },
      {
        lat: 13.058249645096332,
        lng: 80.18684090511087,
      },
      {
        lat: 13.056360680521076,
        lng: 80.18839054976085,
      },
      {
        lat: 13.057732452709823,
        lng: 80.18812806684433,
      },
      {
        lat: 13.055556303974427,
        lng: 80.17997124332503,
      },
      {
        lat: 13.055004851838769,
        lng: 80.17957372479614,
      },
      {
        lat: 13.052983542473275,
        lng: 80.1813641189117,
      },
      {
        lat: 13.057939694462148,
        lng: 80.18324288513683,
      },
      {
        lat: 13.060263462661094,
        lng: 80.17922574188097,
      },
      {
        lat: 13.060579017828529,
        lng: 80.18030698692475,
      },
      {
        lat: 13.059879238690785,
        lng: 80.17911821555317,
      },
      {
        lat: 13.052889743315006,
        lng: 80.17877123415967,
      },
      {
        lat: 13.05419823697942,
        lng: 80.18102250931784,
      },
      {
        lat: 13.059304971211107,
        lng: 80.18152366341141,
      },
      {
        lat: 13.056637617183993,
        lng: 80.18889848201802,
      },
      {
        lat: 13.055455792175396,
        lng: 80.1836179048943,
      },
      {
        lat: 13.05326839653746,
        lng: 80.1862321809203,
      },
      {
        lat: 13.056891710514229,
        lng: 80.18404101576196,
      },
      {
        lat: 13.059278829902901,
        lng: 80.1797495976632,
      },
      {
        lat: 13.055099824664895,
        lng: 80.18074625908712,
      },
      {
        lat: 13.057973887986815,
        lng: 80.18869505270027,
      },
      {
        lat: 13.056661495364239,
        lng: 80.1875534258673,
      },
      {
        lat: 13.052703130187012,
        lng: 80.17894376997212,
      },
      {
        lat: 13.056502129326674,
        lng: 80.18626273032478,
      },
      {
        lat: 13.053687968645455,
        lng: 80.18418845535174,
      },
      {
        lat: 13.061943373424047,
        lng: 80.17900047957716,
      },
      {
        lat: 13.058148935373108,
        lng: 80.1798621773391,
      },
      {
        lat: 13.05454071128441,
        lng: 80.18590282882002,
      },
    ],
    cases: 85,
    coords: [
      {
        lat: 13.052871595352052,
        lng: 80.17812090883844,
      },
      {
        lat: 13.05405858221091,
        lng: 80.1781620946949,
      },
      {
        lat: 13.054680573253338,
        lng: 80.17828668986257,
      },
      {
        lat: 13.055040907155854,
        lng: 80.17839726709754,
      },
      {
        lat: 13.056577192384427,
        lng: 80.1783614254933,
      },
      {
        lat: 13.058409378274924,
        lng: 80.17835454699336,
      },
      {
        lat: 13.058770795857397,
        lng: 80.17850897481921,
      },
      {
        lat: 13.058957973693202,
        lng: 80.17846443979464,
      },
      {
        lat: 13.059260324493353,
        lng: 80.17839029610488,
      },
      {
        lat: 13.059664126479786,
        lng: 80.17840119558721,
      },
      {
        lat: 13.05985217508054,
        lng: 80.17849982282642,
      },
      {
        lat: 13.062072955391464,
        lng: 80.17853829564018,
      },
      {
        lat: 13.062305042849422,
        lng: 80.17876540283736,
      },
      {
        lat: 13.06227206270488,
        lng: 80.18045508363134,
      },
      {
        lat: 13.061119113992213,
        lng: 80.1805507431153,
      },
      {
        lat: 13.06108456647107,
        lng: 80.18198273183421,
      },
      {
        lat: 13.061244126598364,
        lng: 80.18213886803206,
      },
      {
        lat: 13.061218685801686,
        lng: 80.18269744491465,
      },
      {
        lat: 13.060713868011185,
        lng: 80.18267308339172,
      },
      {
        lat: 13.05999172945846,
        lng: 80.18247875755084,
      },
      {
        lat: 13.059227206501378,
        lng: 80.18242796005357,
      },
      {
        lat: 13.05870840570372,
        lng: 80.18247530168927,
      },
      {
        lat: 13.058276637702754,
        lng: 80.18260780852384,
      },
      {
        lat: 13.058309603382268,
        lng: 80.18565704271876,
      },
      {
        lat: 13.058727910834723,
        lng: 80.18568213640093,
      },
      {
        lat: 13.058709697385689,
        lng: 80.18742896007379,
      },
      {
        lat: 13.057916511802416,
        lng: 80.18740703908253,
      },
      {
        lat: 13.057937199698577,
        lng: 80.18843768535605,
      },
      {
        lat: 13.058054114192311,
        lng: 80.18869440121864,
      },
      {
        lat: 13.058086787970124,
        lng: 80.1896462116907,
      },
      {
        lat: 13.056589790281892,
        lng: 80.18958976258341,
      },
      {
        lat: 13.055434807059052,
        lng: 80.18968149132188,
      },
      {
        lat: 13.055103672544641,
        lng: 80.18952038616736,
      },
      {
        lat: 13.05497837559575,
        lng: 80.18927558514014,
      },
      {
        lat: 13.055138709157756,
        lng: 80.1884956669442,
      },
      {
        lat: 13.055175490335852,
        lng: 80.18775777131215,
      },
      {
        lat: 13.054840119020808,
        lng: 80.18690009457919,
      },
      {
        lat: 13.054467468080512,
        lng: 80.18669836388094,
      },
      {
        lat: 13.053682394338507,
        lng: 80.1865411005318,
      },
      {
        lat: 13.052523672762009,
        lng: 80.18601820728935,
      },
      {
        lat: 13.051479638265928,
        lng: 80.1858266277666,
      },
      {
        lat: 13.051616521491058,
        lng: 80.18456981314554,
      },
      {
        lat: 13.051795275861183,
        lng: 80.18354797280631,
      },
      {
        lat: 13.051812600023318,
        lng: 80.18301725589015,
      },
      {
        lat: 13.05168693516257,
        lng: 80.18263059497654,
      },
      {
        lat: 13.051679364230312,
        lng: 80.18138585950777,
      },
      {
        lat: 13.052069711338214,
        lng: 80.18136214934907,
      },
      {
        lat: 13.05221270036116,
        lng: 80.18121809334667,
      },
      {
        lat: 13.052250947981216,
        lng: 80.18074841899958,
      },
      {
        lat: 13.052000859396095,
        lng: 80.18017915216716,
      },
      {
        lat: 13.051875442762636,
        lng: 80.17983330225242,
      },
      {
        lat: 13.051977205438076,
        lng: 80.17966918860941,
      },
      {
        lat: 13.051852285259187,
        lng: 80.17940496069264,
      },
      {
        lat: 13.051785543249007,
        lng: 80.17856885700175,
      },
      {
        lat: 13.052854077215363,
        lng: 80.17853940717184,
      },
      {
        lat: 13.052871595352052,
        lng: 80.17812090883844,
      },
    ],
    properties: {
      Ward_No: 148,
      Zone_Name: "VALASARAVAKKAM",
      fillColor: "#f6d04d",
      zname: "VALASARAVAKKAM(148)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.059765447754685,
        lng: 80.1910035192504,
      },
      {
        lat: 13.06106119454655,
        lng: 80.19659801425824,
      },
      {
        lat: 13.067852095153302,
        lng: 80.19182743482212,
      },
      {
        lat: 13.059284950724427,
        lng: 80.19448379925497,
      },
      {
        lat: 13.073995215424707,
        lng: 80.19308783452466,
      },
      {
        lat: 13.071826573071363,
        lng: 80.19161950931158,
      },
      {
        lat: 13.062211853032727,
        lng: 80.19460591963283,
      },
      {
        lat: 13.06179401706676,
        lng: 80.20459590255847,
      },
      {
        lat: 13.070111989606431,
        lng: 80.19931991565089,
      },
      {
        lat: 13.06357040037173,
        lng: 80.1956607409039,
      },
      {
        lat: 13.068349439668449,
        lng: 80.2041516499664,
      },
      {
        lat: 13.055651277947943,
        lng: 80.19049322346673,
      },
      {
        lat: 13.076371923907745,
        lng: 80.19802257898716,
      },
      {
        lat: 13.06539723750915,
        lng: 80.20340056885446,
      },
      {
        lat: 13.062747041314893,
        lng: 80.2028554612004,
      },
      {
        lat: 13.062219852214351,
        lng: 80.20080913645175,
      },
      {
        lat: 13.06929685188313,
        lng: 80.19986535459515,
      },
      {
        lat: 13.069000369852446,
        lng: 80.20010105765688,
      },
      {
        lat: 13.063002414862913,
        lng: 80.19178792115197,
      },
      {
        lat: 13.068561764578185,
        lng: 80.20524367555487,
      },
      {
        lat: 13.06230900209517,
        lng: 80.19341725066177,
      },
      {
        lat: 13.062010008510107,
        lng: 80.20664203834535,
      },
      {
        lat: 13.055951206220556,
        lng: 80.19211716522531,
      },
      {
        lat: 13.05875281537582,
        lng: 80.1927518200108,
      },
      {
        lat: 13.065537987560807,
        lng: 80.19940704295931,
      },
      {
        lat: 13.065918998266012,
        lng: 80.19375199161112,
      },
      {
        lat: 13.065107117057016,
        lng: 80.20501406523742,
      },
      {
        lat: 13.061739744859572,
        lng: 80.20090219511681,
      },
      {
        lat: 13.06290413939339,
        lng: 80.19184803221373,
      },
      {
        lat: 13.07630770051717,
        lng: 80.198773711081,
      },
      {
        lat: 13.064227098167622,
        lng: 80.19165662163577,
      },
      {
        lat: 13.07531020796103,
        lng: 80.20278447298905,
      },
      {
        lat: 13.065575535379622,
        lng: 80.19029200856261,
      },
      {
        lat: 13.070122962603794,
        lng: 80.20202424044534,
      },
      {
        lat: 13.070982781696468,
        lng: 80.20026256540699,
      },
      {
        lat: 13.068112776233738,
        lng: 80.19249878719229,
      },
      {
        lat: 13.062629719036066,
        lng: 80.19610678254108,
      },
      {
        lat: 13.062092156772495,
        lng: 80.19202568306464,
      },
      {
        lat: 13.067300295984857,
        lng: 80.2005362940514,
      },
      {
        lat: 13.05891094635182,
        lng: 80.19757031467358,
      },
      {
        lat: 13.06597829516117,
        lng: 80.20242086011623,
      },
      {
        lat: 13.070955007182752,
        lng: 80.20130802059656,
      },
      {
        lat: 13.063319686552523,
        lng: 80.20804038602266,
      },
      {
        lat: 13.07033093557896,
        lng: 80.19485125194063,
      },
      {
        lat: 13.069229839204073,
        lng: 80.2085115550686,
      },
      {
        lat: 13.070144031080188,
        lng: 80.19205770140223,
      },
      {
        lat: 13.070065115306265,
        lng: 80.2051542688453,
      },
      {
        lat: 13.067702811303688,
        lng: 80.20693993134854,
      },
      {
        lat: 13.066595902641502,
        lng: 80.19326905615897,
      },
      {
        lat: 13.068843564847068,
        lng: 80.1932155459906,
      },
      {
        lat: 13.060570142506393,
        lng: 80.19453698646795,
      },
      {
        lat: 13.072220491666542,
        lng: 80.20140451737774,
      },
      {
        lat: 13.067146059381368,
        lng: 80.19827503734669,
      },
      {
        lat: 13.07144852272415,
        lng: 80.19324792322865,
      },
      {
        lat: 13.069420395457072,
        lng: 80.20785469562408,
      },
      {
        lat: 13.057283563736286,
        lng: 80.19928363603525,
      },
      {
        lat: 13.073301904551485,
        lng: 80.20492932773968,
      },
      {
        lat: 13.07230354790174,
        lng: 80.20522126027328,
      },
      {
        lat: 13.070529653012734,
        lng: 80.19859683187923,
      },
      {
        lat: 13.061037881504895,
        lng: 80.19308933020942,
      },
      {
        lat: 13.071561308910217,
        lng: 80.20818074477275,
      },
      {
        lat: 13.06868526645685,
        lng: 80.19687120477842,
      },
      {
        lat: 13.076293215614763,
        lng: 80.199607219241,
      },
      {
        lat: 13.063656567051343,
        lng: 80.20402138640058,
      },
      {
        lat: 13.066875246522612,
        lng: 80.19769775358607,
      },
      {
        lat: 13.068375650032308,
        lng: 80.20820388698593,
      },
      {
        lat: 13.067422683881073,
        lng: 80.2007043388715,
      },
      {
        lat: 13.063606224423632,
        lng: 80.2009453685029,
      },
      {
        lat: 13.069908123121955,
        lng: 80.19350499649052,
      },
      {
        lat: 13.07011339266042,
        lng: 80.20347449606588,
      },
      {
        lat: 13.069229266146188,
        lng: 80.20136990066251,
      },
      {
        lat: 13.07930585034979,
        lng: 80.19968960589809,
      },
      {
        lat: 13.074141395041076,
        lng: 80.19775464250316,
      },
      {
        lat: 13.070020119900185,
        lng: 80.19979661395617,
      },
      {
        lat: 13.065331717017965,
        lng: 80.1918352254746,
      },
      {
        lat: 13.063791586996135,
        lng: 80.20652829468266,
      },
      {
        lat: 13.07436398592205,
        lng: 80.20104716894528,
      },
      {
        lat: 13.068063406252891,
        lng: 80.19333431839097,
      },
      {
        lat: 13.076113335932442,
        lng: 80.20084151182937,
      },
      {
        lat: 13.066436645408908,
        lng: 80.20665644189317,
      },
      {
        lat: 13.073074228954535,
        lng: 80.20601823324655,
      },
      {
        lat: 13.056401992245718,
        lng: 80.18980517717056,
      },
      {
        lat: 13.057929180348468,
        lng: 80.19410103975204,
      },
      {
        lat: 13.06820713414643,
        lng: 80.19873157084446,
      },
      {
        lat: 13.05889421797638,
        lng: 80.19586250371226,
      },
      {
        lat: 13.069090581803437,
        lng: 80.19533660997467,
      },
      {
        lat: 13.075301663155013,
        lng: 80.19641415610616,
      },
      {
        lat: 13.06854902975386,
        lng: 80.19846773086499,
      },
    ],
    cases: 88,
    coords: [
      {
        lat: 13.061101237615551,
        lng: 80.20729847839777,
      },
      {
        lat: 13.06180340819091,
        lng: 80.20368561578961,
      },
      {
        lat: 13.06175472001956,
        lng: 80.20270969324812,
      },
      {
        lat: 13.061533740728649,
        lng: 80.20152298219841,
      },
      {
        lat: 13.060970244630278,
        lng: 80.20025427129639,
      },
      {
        lat: 13.06062785691222,
        lng: 80.20019349501656,
      },
      {
        lat: 13.05990200958306,
        lng: 80.2003482107077,
      },
      {
        lat: 13.059282262857453,
        lng: 80.20037468045015,
      },
      {
        lat: 13.058982754285024,
        lng: 80.20033476593828,
      },
      {
        lat: 13.05857637057227,
        lng: 80.20029575593855,
      },
      {
        lat: 13.058464073674374,
        lng: 80.19940527039532,
      },
      {
        lat: 13.057138434663587,
        lng: 80.19935281562616,
      },
      {
        lat: 13.056410650992271,
        lng: 80.19918917772613,
      },
      {
        lat: 13.056326467855166,
        lng: 80.19879139709936,
      },
      {
        lat: 13.05537231812032,
        lng: 80.19883979918016,
      },
      {
        lat: 13.055046313978435,
        lng: 80.19866108734983,
      },
      {
        lat: 13.055058158440822,
        lng: 80.19726971029911,
      },
      {
        lat: 13.056215370942613,
        lng: 80.19721958964564,
      },
      {
        lat: 13.056273223897975,
        lng: 80.19671501422093,
      },
      {
        lat: 13.056800941822218,
        lng: 80.19667022114338,
      },
      {
        lat: 13.056984924662338,
        lng: 80.19687029836888,
      },
      {
        lat: 13.05716743588265,
        lng: 80.19682842686521,
      },
      {
        lat: 13.057143205286247,
        lng: 80.19618340211099,
      },
      {
        lat: 13.057038853298065,
        lng: 80.19572052633293,
      },
      {
        lat: 13.05689462456633,
        lng: 80.19537896863395,
      },
      {
        lat: 13.056867818635222,
        lng: 80.19431053360358,
      },
      {
        lat: 13.056744631990139,
        lng: 80.1940897784122,
      },
      {
        lat: 13.055461412238577,
        lng: 80.19345540880973,
      },
      {
        lat: 13.055277552033388,
        lng: 80.19327549397838,
      },
      {
        lat: 13.055284389539421,
        lng: 80.18960830946777,
      },
      {
        lat: 13.055434807059052,
        lng: 80.18968149132188,
      },
      {
        lat: 13.056589790281892,
        lng: 80.18958976258341,
      },
      {
        lat: 13.058364778436546,
        lng: 80.18965669421434,
      },
      {
        lat: 13.058942768495324,
        lng: 80.18969277944251,
      },
      {
        lat: 13.059274899903876,
        lng: 80.19001778379211,
      },
      {
        lat: 13.059730583696334,
        lng: 80.19030076557772,
      },
      {
        lat: 13.060391108159951,
        lng: 80.19033615229584,
      },
      {
        lat: 13.061094395167332,
        lng: 80.19061703855122,
      },
      {
        lat: 13.061550078959966,
        lng: 80.19090002033707,
      },
      {
        lat: 13.062046531507752,
        lng: 80.1911007032702,
      },
      {
        lat: 13.062336648043434,
        lng: 80.19130313247845,
      },
      {
        lat: 13.062750316962518,
        lng: 80.19146353912315,
      },
      {
        lat: 13.063120475667214,
        lng: 80.19125552183411,
      },
      {
        lat: 13.06385730394625,
        lng: 80.1902658400741,
      },
      {
        lat: 13.064473819747992,
        lng: 80.18985085326135,
      },
      {
        lat: 13.065216629393214,
        lng: 80.1898445666707,
      },
      {
        lat: 13.065753850696321,
        lng: 80.18996295075141,
      },
      {
        lat: 13.065962180497436,
        lng: 80.19028900286591,
      },
      {
        lat: 13.066008432171623,
        lng: 80.19110814958536,
      },
      {
        lat: 13.066340563580173,
        lng: 80.19143315393494,
      },
      {
        lat: 13.067332222558026,
        lng: 80.19162964580771,
      },
      {
        lat: 13.069931059421231,
        lng: 80.19144374354553,
      },
      {
        lat: 13.07203269606575,
        lng: 80.19093423395394,
      },
      {
        lat: 13.073041997961974,
        lng: 80.1906388536145,
      },
      {
        lat: 13.073826324033023,
        lng: 80.19067319256749,
      },
      {
        lat: 13.074242485188387,
        lng: 80.19124334719935,
      },
      {
        lat: 13.074496069769035,
        lng: 80.1922246468383,
      },
      {
        lat: 13.074910486359306,
        lng: 80.19250797787929,
      },
      {
        lat: 13.075529992844016,
        lng: 80.192584688651,
      },
      {
        lat: 13.075821604720478,
        lng: 80.19303296665163,
      },
      {
        lat: 13.075909372820714,
        lng: 80.19389273891491,
      },
      {
        lat: 13.075872093195636,
        lng: 80.19454868494941,
      },
      {
        lat: 13.075789309566703,
        lng: 80.19450840866078,
      },
      {
        lat: 13.075501934490765,
        lng: 80.19475670223852,
      },
      {
        lat: 13.075006977284273,
        lng: 80.1948018680975,
      },
      {
        lat: 13.074968202317505,
        lng: 80.19521196533985,
      },
      {
        lat: 13.07530232751484,
        lng: 80.19586476807939,
      },
      {
        lat: 13.07572347314173,
        lng: 80.19725441868533,
      },
      {
        lat: 13.075891283411044,
        lng: 80.19770374445123,
      },
      {
        lat: 13.07626368512759,
        lng: 80.19786450035078,
      },
      {
        lat: 13.07924958913423,
        lng: 80.19823956526339,
      },
      {
        lat: 13.079200481005355,
        lng: 80.19904428183668,
      },
      {
        lat: 13.0794198630235,
        lng: 80.19925895623442,
      },
      {
        lat: 13.079830875069376,
        lng: 80.1996073407454,
      },
      {
        lat: 13.07997045811701,
        lng: 80.20014748712934,
      },
      {
        lat: 13.079999033202892,
        lng: 80.20036377637557,
      },
      {
        lat: 13.079918081898901,
        lng: 80.20049979341522,
      },
      {
        lat: 13.079182606160524,
        lng: 80.20058721709395,
      },
      {
        lat: 13.078449928946663,
        lng: 80.20113474564268,
      },
      {
        lat: 13.078262085158066,
        lng: 80.2016235303537,
      },
      {
        lat: 13.078131226922192,
        lng: 80.20251782856491,
      },
      {
        lat: 13.077887056057477,
        lng: 80.20270935974507,
      },
      {
        lat: 13.076417421533236,
        lng: 80.20310072704133,
      },
      {
        lat: 13.074564197715677,
        lng: 80.20317054412621,
      },
      {
        lat: 13.073938577605352,
        lng: 80.20339236999604,
      },
      {
        lat: 13.073750898435907,
        lng: 80.20390821969937,
      },
      {
        lat: 13.073464722355709,
        lng: 80.20615715168456,
      },
      {
        lat: 13.072534392844037,
        lng: 80.21006258482717,
      },
      {
        lat: 13.072426841883226,
        lng: 80.21030709252898,
      },
      {
        lat: 13.072236364189207,
        lng: 80.21036283736223,
      },
      {
        lat: 13.072044898780566,
        lng: 80.21025619224137,
      },
      {
        lat: 13.072015665218254,
        lng: 80.20993164302563,
      },
      {
        lat: 13.071905480352017,
        lng: 80.20974311084983,
      },
      {
        lat: 13.071604817791528,
        lng: 80.20961032350708,
      },
      {
        lat: 13.071168523041576,
        lng: 80.20958694959717,
      },
      {
        lat: 13.070157832065982,
        lng: 80.20924364032439,
      },
      {
        lat: 13.069665539477931,
        lng: 80.20897714286856,
      },
      {
        lat: 13.067974218268416,
        lng: 80.20877492587388,
      },
      {
        lat: 13.067184226263713,
        lng: 80.20886281093799,
      },
      {
        lat: 13.066396374307164,
        lng: 80.20930254090263,
      },
      {
        lat: 13.065742672968236,
        lng: 80.20938927250324,
      },
      {
        lat: 13.06399584777784,
        lng: 80.2090251269397,
      },
      {
        lat: 13.063286477838943,
        lng: 80.20892286497924,
      },
      {
        lat: 13.06290469935531,
        lng: 80.20889902968402,
      },
      {
        lat: 13.061341931714374,
        lng: 80.20742360458523,
      },
      {
        lat: 13.061101237615551,
        lng: 80.20729847839777,
      },
    ],
    properties: {
      Ward_No: 127,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#f6d04d",
      zname: "KODAMBAKKAM(127)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.043125214087693,
        lng: 80.239227696058,
      },
      {
        lat: 13.046633371606232,
        lng: 80.23780360559954,
      },
      {
        lat: 13.040716475510498,
        lng: 80.2335913046786,
      },
      {
        lat: 13.042202007623121,
        lng: 80.23595509876645,
      },
      {
        lat: 13.045516777249338,
        lng: 80.23394296515842,
      },
      {
        lat: 13.040722509456472,
        lng: 80.23719560608956,
      },
      {
        lat: 13.046444486022832,
        lng: 80.23531896383666,
      },
      {
        lat: 13.041292940426587,
        lng: 80.23737105866904,
      },
      {
        lat: 13.037890582378209,
        lng: 80.22800330559566,
      },
      {
        lat: 13.046079221431945,
        lng: 80.23624806206995,
      },
      {
        lat: 13.037889945085078,
        lng: 80.23179774969452,
      },
      {
        lat: 13.044914152239812,
        lng: 80.2355331604089,
      },
      {
        lat: 13.038391349278138,
        lng: 80.23204535171543,
      },
      {
        lat: 13.038308331145803,
        lng: 80.23905697418098,
      },
      {
        lat: 13.040127468866933,
        lng: 80.23257236660777,
      },
      {
        lat: 13.034768771093121,
        lng: 80.23439826381076,
      },
      {
        lat: 13.03593172232097,
        lng: 80.2322631464556,
      },
      {
        lat: 13.043658408797983,
        lng: 80.23721001908143,
      },
      {
        lat: 13.036108255883144,
        lng: 80.23129371453761,
      },
      {
        lat: 13.041707292485086,
        lng: 80.23659493089076,
      },
      {
        lat: 13.036346993324408,
        lng: 80.2389184105229,
      },
      {
        lat: 13.037799899530125,
        lng: 80.23404709626621,
      },
      {
        lat: 13.036460026568266,
        lng: 80.23805336335577,
      },
      {
        lat: 13.035741322775223,
        lng: 80.23651414692247,
      },
      {
        lat: 13.037582309219799,
        lng: 80.23569443121517,
      },
      {
        lat: 13.042473263423775,
        lng: 80.23649973111165,
      },
      {
        lat: 13.04246566666011,
        lng: 80.23871626293455,
      },
      {
        lat: 13.047169214601352,
        lng: 80.23223443705162,
      },
      {
        lat: 13.042695355287943,
        lng: 80.24113682674827,
      },
      {
        lat: 13.03699402725257,
        lng: 80.23836536186042,
      },
      {
        lat: 13.040842162930963,
        lng: 80.23253077966604,
      },
      {
        lat: 13.047381656723537,
        lng: 80.23208712466361,
      },
      {
        lat: 13.035027419186651,
        lng: 80.2359088341702,
      },
      {
        lat: 13.039220433267152,
        lng: 80.23672015020301,
      },
      {
        lat: 13.042936186631842,
        lng: 80.23127160791623,
      },
      {
        lat: 13.042880092644753,
        lng: 80.23796802563616,
      },
      {
        lat: 13.041856014360262,
        lng: 80.24321623889483,
      },
      {
        lat: 13.045094731509252,
        lng: 80.23648420494693,
      },
      {
        lat: 13.037212822310583,
        lng: 80.23238033232987,
      },
      {
        lat: 13.040352520602879,
        lng: 80.23407018607105,
      },
      {
        lat: 13.041518635144946,
        lng: 80.22983018224643,
      },
      {
        lat: 13.045784097381164,
        lng: 80.23439982730784,
      },
      {
        lat: 13.044536496194464,
        lng: 80.23152858816786,
      },
      {
        lat: 13.042952725615539,
        lng: 80.23929252372706,
      },
      {
        lat: 13.046441607281562,
        lng: 80.23209221390364,
      },
      {
        lat: 13.044432776379399,
        lng: 80.23789707730305,
      },
      {
        lat: 13.043516285776377,
        lng: 80.23295730422682,
      },
      {
        lat: 13.039166121839934,
        lng: 80.22931262289386,
      },
      {
        lat: 13.03895505095854,
        lng: 80.231378862109,
      },
      {
        lat: 13.04335146309483,
        lng: 80.22975218761357,
      },
      {
        lat: 13.034953830913778,
        lng: 80.2335742631388,
      },
      {
        lat: 13.041799419600755,
        lng: 80.23018869974447,
      },
      {
        lat: 13.04056216936215,
        lng: 80.23941974377807,
      },
      {
        lat: 13.042607637699277,
        lng: 80.23395248160544,
      },
      {
        lat: 13.038409000373594,
        lng: 80.23009467766084,
      },
      {
        lat: 13.042267447951108,
        lng: 80.24137620251466,
      },
      {
        lat: 13.045210248894454,
        lng: 80.23149148193076,
      },
      {
        lat: 13.040643638280807,
        lng: 80.2291352961877,
      },
      {
        lat: 13.04164634122759,
        lng: 80.24149717682376,
      },
      {
        lat: 13.04667104637303,
        lng: 80.2363980762907,
      },
      {
        lat: 13.03781494658121,
        lng: 80.2302543123196,
      },
      {
        lat: 13.038946061638669,
        lng: 80.22960318021087,
      },
      {
        lat: 13.044007180819497,
        lng: 80.23477852446248,
      },
      {
        lat: 13.041081018668278,
        lng: 80.23421493480083,
      },
      {
        lat: 13.043156646974388,
        lng: 80.23414599175395,
      },
      {
        lat: 13.041499762844907,
        lng: 80.24034492389077,
      },
      {
        lat: 13.034531092914749,
        lng: 80.23611880141314,
      },
      {
        lat: 13.045786714090942,
        lng: 80.23521757713344,
      },
      {
        lat: 13.040710579711721,
        lng: 80.24331861346141,
      },
      {
        lat: 13.043174822091274,
        lng: 80.22940715371993,
      },
      {
        lat: 13.038666192509567,
        lng: 80.22935906938416,
      },
      {
        lat: 13.044615636184279,
        lng: 80.23422272582259,
      },
      {
        lat: 13.041583142515224,
        lng: 80.23883313827139,
      },
      {
        lat: 13.040651602968493,
        lng: 80.23310454686133,
      },
      {
        lat: 13.040144691472342,
        lng: 80.23051067623884,
      },
      {
        lat: 13.04283525346875,
        lng: 80.23762568166076,
      },
      {
        lat: 13.040341320183645,
        lng: 80.23680724284645,
      },
      {
        lat: 13.03863937230379,
        lng: 80.23629402887394,
      },
      {
        lat: 13.046255446058876,
        lng: 80.23596834907335,
      },
      {
        lat: 13.036430404655388,
        lng: 80.23342541091961,
      },
      {
        lat: 13.035424503920492,
        lng: 80.22783962275122,
      },
      {
        lat: 13.04234132044877,
        lng: 80.23157488612348,
      },
      {
        lat: 13.035578593214803,
        lng: 80.23572682888766,
      },
      {
        lat: 13.042570342941843,
        lng: 80.23610204619227,
      },
      {
        lat: 13.036318918403394,
        lng: 80.23897714043663,
      },
      {
        lat: 13.041994483566555,
        lng: 80.23989367082866,
      },
      {
        lat: 13.044221515092604,
        lng: 80.23839892593945,
      },
      {
        lat: 13.041703300758336,
        lng: 80.24336995100649,
      },
      {
        lat: 13.044790197467064,
        lng: 80.23851329744471,
      },
      {
        lat: 13.036053458389095,
        lng: 80.22717213879882,
      },
      {
        lat: 13.036879624230481,
        lng: 80.23356199752313,
      },
      {
        lat: 13.036396889451304,
        lng: 80.23550097760638,
      },
      {
        lat: 13.038054148749815,
        lng: 80.23323640812517,
      },
      {
        lat: 13.045830548788663,
        lng: 80.23770479834046,
      },
      {
        lat: 13.043303098975153,
        lng: 80.23893650578235,
      },
      {
        lat: 13.041097359250404,
        lng: 80.24119423920538,
      },
      {
        lat: 13.037859613194373,
        lng: 80.23631371439866,
      },
      {
        lat: 13.03750070774382,
        lng: 80.23653019185787,
      },
      {
        lat: 13.042558799760549,
        lng: 80.23865373055746,
      },
      {
        lat: 13.040719491919107,
        lng: 80.23150923993234,
      },
      {
        lat: 13.046654405103737,
        lng: 80.23619805914012,
      },
      {
        lat: 13.040868508563808,
        lng: 80.23877209435008,
      },
      {
        lat: 13.04299961617743,
        lng: 80.23892558866807,
      },
      {
        lat: 13.046380648374964,
        lng: 80.23407998040138,
      },
      {
        lat: 13.040992207211538,
        lng: 80.23560425969471,
      },
      {
        lat: 13.036124475409068,
        lng: 80.23787452471284,
      },
      {
        lat: 13.036421824549526,
        lng: 80.23345902027715,
      },
      {
        lat: 13.041786051229694,
        lng: 80.24002620058647,
      },
      {
        lat: 13.040470539154862,
        lng: 80.23522033208984,
      },
      {
        lat: 13.04500483397219,
        lng: 80.2373836335489,
      },
      {
        lat: 13.038556632096347,
        lng: 80.22954376942002,
      },
      {
        lat: 13.046394345931,
        lng: 80.2336157318637,
      },
      {
        lat: 13.04530879030975,
        lng: 80.23257623552306,
      },
      {
        lat: 13.043047410882705,
        lng: 80.23844014978022,
      },
      {
        lat: 13.044054932910322,
        lng: 80.24082196018364,
      },
      {
        lat: 13.043830793937506,
        lng: 80.23218781291452,
      },
      {
        lat: 13.036145710220746,
        lng: 80.23437564213219,
      },
      {
        lat: 13.039450792185402,
        lng: 80.23406859784613,
      },
      {
        lat: 13.044899143608003,
        lng: 80.23743526988272,
      },
      {
        lat: 13.039008126143804,
        lng: 80.23683334738699,
      },
      {
        lat: 13.043961443391874,
        lng: 80.23942975823077,
      },
      {
        lat: 13.03477381489317,
        lng: 80.23734165016411,
      },
      {
        lat: 13.037196333030925,
        lng: 80.23731982413837,
      },
      {
        lat: 13.043091377511438,
        lng: 80.23520405409272,
      },
      {
        lat: 13.041847151105284,
        lng: 80.24280323716377,
      },
      {
        lat: 13.03661358816889,
        lng: 80.23227395128569,
      },
      {
        lat: 13.038479671119553,
        lng: 80.23180296657127,
      },
      {
        lat: 13.04292370368089,
        lng: 80.23699484254979,
      },
      {
        lat: 13.041690936613751,
        lng: 80.24313691525425,
      },
      {
        lat: 13.035351610104534,
        lng: 80.22884237625244,
      },
      {
        lat: 13.045156301439789,
        lng: 80.2339030243219,
      },
      {
        lat: 13.038623809971373,
        lng: 80.23173297024564,
      },
      {
        lat: 13.03829177948496,
        lng: 80.23665347721092,
      },
      {
        lat: 13.041240418633587,
        lng: 80.23394991920193,
      },
      {
        lat: 13.044492431199295,
        lng: 80.23180139441872,
      },
      {
        lat: 13.045049057679051,
        lng: 80.23051293668715,
      },
      {
        lat: 13.035994093758157,
        lng: 80.23751161387736,
      },
      {
        lat: 13.043994601250258,
        lng: 80.23961198979204,
      },
      {
        lat: 13.044004644446341,
        lng: 80.23579974796336,
      },
      {
        lat: 13.03840012629757,
        lng: 80.22770125338556,
      },
      {
        lat: 13.038526321620278,
        lng: 80.22890722999524,
      },
      {
        lat: 13.044786984410594,
        lng: 80.22975270107011,
      },
      {
        lat: 13.043583648433179,
        lng: 80.22934005065466,
      },
      {
        lat: 13.039265649901703,
        lng: 80.23147935180157,
      },
      {
        lat: 13.040909761339796,
        lng: 80.23993816622789,
      },
      {
        lat: 13.043284235607569,
        lng: 80.23279507024307,
      },
      {
        lat: 13.040695911472664,
        lng: 80.23332043861502,
      },
      {
        lat: 13.046321895004924,
        lng: 80.22984693895651,
      },
      {
        lat: 13.038627415663928,
        lng: 80.23175026738186,
      },
      {
        lat: 13.045074380368169,
        lng: 80.23021134033465,
      },
      {
        lat: 13.045780754966316,
        lng: 80.229681349468,
      },
      {
        lat: 13.04179276711997,
        lng: 80.23884993306238,
      },
      {
        lat: 13.039317481414214,
        lng: 80.23745573556522,
      },
      {
        lat: 13.041850882186148,
        lng: 80.23315312456906,
      },
      {
        lat: 13.03646528800393,
        lng: 80.23685002857346,
      },
    ],
    cases: 155,
    coords: [
      {
        lat: 13.034469528525475,
        lng: 80.23742076765257,
      },
      {
        lat: 13.034250192066002,
        lng: 80.23700177663692,
      },
      {
        lat: 13.035121192705411,
        lng: 80.23078726095757,
      },
      {
        lat: 13.035202309814414,
        lng: 80.23054047621514,
      },
      {
        lat: 13.03525539003278,
        lng: 80.23021189601388,
      },
      {
        lat: 13.035116536051655,
        lng: 80.23002166143898,
      },
      {
        lat: 13.035346083726685,
        lng: 80.22701185149585,
      },
      {
        lat: 13.038737244659114,
        lng: 80.22763941316066,
      },
      {
        lat: 13.039537176074184,
        lng: 80.22785139711345,
      },
      {
        lat: 13.039951575999867,
        lng: 80.22806664391926,
      },
      {
        lat: 13.04017138078082,
        lng: 80.22798275091192,
      },
      {
        lat: 13.040362649744063,
        lng: 80.22773503392595,
      },
      {
        lat: 13.041276558121647,
        lng: 80.22857497095372,
      },
      {
        lat: 13.04260087500741,
        lng: 80.22897392662858,
      },
      {
        lat: 13.044034844820105,
        lng: 80.22928992153984,
      },
      {
        lat: 13.047949186619428,
        lng: 80.23014484151538,
      },
      {
        lat: 13.046763184890931,
        lng: 80.23784371765426,
      },
      {
        lat: 13.046423914557359,
        lng: 80.23907068271038,
      },
      {
        lat: 13.046118282369452,
        lng: 80.23949405157211,
      },
      {
        lat: 13.045236652544393,
        lng: 80.24022831868197,
      },
      {
        lat: 13.043855142309202,
        lng: 80.24111982812497,
      },
      {
        lat: 13.043485819607072,
        lng: 80.24444751239889,
      },
      {
        lat: 13.041319656866124,
        lng: 80.24424776903949,
      },
      {
        lat: 13.041108183361153,
        lng: 80.24558916947043,
      },
      {
        lat: 13.040950742723178,
        lng: 80.24549704072146,
      },
      {
        lat: 13.041131220744694,
        lng: 80.2442182100865,
      },
      {
        lat: 13.040187903267073,
        lng: 80.24388350252381,
      },
      {
        lat: 13.039998519753048,
        lng: 80.24369818290579,
      },
      {
        lat: 13.039900796338983,
        lng: 80.24310708896799,
      },
      {
        lat: 13.040324501263015,
        lng: 80.24054889663824,
      },
      {
        lat: 13.040322038041683,
        lng: 80.24014391890853,
      },
      {
        lat: 13.040163650010888,
        lng: 80.23989602949446,
      },
      {
        lat: 13.03903303328322,
        lng: 80.23971867577703,
      },
      {
        lat: 13.036238623818878,
        lng: 80.23939963448592,
      },
      {
        lat: 13.035610377095542,
        lng: 80.23928033655422,
      },
      {
        lat: 13.03526241607216,
        lng: 80.23881597538902,
      },
      {
        lat: 13.034469528525475,
        lng: 80.23742076765257,
      },
    ],
    properties: {
      Ward_No: 136,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#de4307",
      zname: "KODAMBAKKAM(136)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.042635140239074,
        lng: 80.1895584380537,
      },
      {
        lat: 13.043412774353897,
        lng: 80.19140096183406,
      },
      {
        lat: 13.041577556654861,
        lng: 80.18912106598222,
      },
      {
        lat: 13.051899807773216,
        lng: 80.18727724313494,
      },
      {
        lat: 13.050645974664803,
        lng: 80.19383215807379,
      },
      {
        lat: 13.051554219517863,
        lng: 80.19364277879934,
      },
      {
        lat: 13.047544031753771,
        lng: 80.19210350882572,
      },
      {
        lat: 13.040076027634543,
        lng: 80.18918523658388,
      },
      {
        lat: 13.047739324413119,
        lng: 80.18801071921925,
      },
      {
        lat: 13.05427229633271,
        lng: 80.18795670021149,
      },
      {
        lat: 13.043410926389285,
        lng: 80.1880741919446,
      },
      {
        lat: 13.035069886941896,
        lng: 80.19134584062134,
      },
      {
        lat: 13.055905164435314,
        lng: 80.19715505391682,
      },
      {
        lat: 13.054506693308644,
        lng: 80.19654242445002,
      },
      {
        lat: 13.042034021743925,
        lng: 80.18950890256335,
      },
      {
        lat: 13.039465833369858,
        lng: 80.18915199408083,
      },
      {
        lat: 13.052582618897826,
        lng: 80.19665623698833,
      },
      {
        lat: 13.03772591158629,
        lng: 80.19019088217173,
      },
      {
        lat: 13.041924997422141,
        lng: 80.18976782044871,
      },
      {
        lat: 13.052467098383868,
        lng: 80.18934802270377,
      },
      {
        lat: 13.052129008177054,
        lng: 80.19508426147036,
      },
      {
        lat: 13.048634484126113,
        lng: 80.19051582881197,
      },
      {
        lat: 13.05479190741779,
        lng: 80.19554749229049,
      },
      {
        lat: 13.036311763889989,
        lng: 80.19328481394078,
      },
      {
        lat: 13.034306772297732,
        lng: 80.19226855507101,
      },
      {
        lat: 13.046744099156982,
        lng: 80.19000713650236,
      },
      {
        lat: 13.050576678742873,
        lng: 80.18816258736861,
      },
      {
        lat: 13.054879893225184,
        lng: 80.19137315013866,
      },
      {
        lat: 13.050313663389908,
        lng: 80.1869878494576,
      },
      {
        lat: 13.04470265735069,
        lng: 80.18818641869962,
      },
      {
        lat: 13.052885409005299,
        lng: 80.19614120073639,
      },
      {
        lat: 13.051637592689524,
        lng: 80.18634199523225,
      },
      {
        lat: 13.0521578080313,
        lng: 80.18696409516552,
      },
      {
        lat: 13.043789971993421,
        lng: 80.19254172031401,
      },
      {
        lat: 13.049380121833265,
        lng: 80.18853530934554,
      },
      {
        lat: 13.054634932559274,
        lng: 80.1916313940287,
      },
      {
        lat: 13.034536508009944,
        lng: 80.19217430517493,
      },
      {
        lat: 13.049161309348994,
        lng: 80.18649188692946,
      },
      {
        lat: 13.049248125316016,
        lng: 80.19533587096092,
      },
      {
        lat: 13.053597602700115,
        lng: 80.19156734116116,
      },
      {
        lat: 13.052067674535422,
        lng: 80.18832749709748,
      },
      {
        lat: 13.050045064660836,
        lng: 80.19120153498244,
      },
      {
        lat: 13.054574495239194,
        lng: 80.19345624740212,
      },
      {
        lat: 13.053789491350345,
        lng: 80.18998650952909,
      },
      {
        lat: 13.050710063273346,
        lng: 80.19695890882807,
      },
      {
        lat: 13.0494178907233,
        lng: 80.19674833059722,
      },
      {
        lat: 13.044715409504139,
        lng: 80.18964341322604,
      },
      {
        lat: 13.048404805210048,
        lng: 80.186819841355,
      },
      {
        lat: 13.03677404583569,
        lng: 80.19119850721471,
      },
      {
        lat: 13.04954549303355,
        lng: 80.18895842569292,
      },
      {
        lat: 13.0531312334926,
        lng: 80.18849143843408,
      },
      {
        lat: 13.054238217218082,
        lng: 80.19547566503675,
      },
      {
        lat: 13.054053636085127,
        lng: 80.19194925694694,
      },
      {
        lat: 13.041052337874259,
        lng: 80.19256235657264,
      },
      {
        lat: 13.041286201862542,
        lng: 80.1921219985372,
      },
      {
        lat: 13.054101645610766,
        lng: 80.1925557324482,
      },
      {
        lat: 13.044019921326006,
        lng: 80.18837304906842,
      },
      {
        lat: 13.05331978708262,
        lng: 80.19489091267741,
      },
      {
        lat: 13.034777056352718,
        lng: 80.19403194977971,
      },
      {
        lat: 13.048684047017748,
        lng: 80.19452838076535,
      },
      {
        lat: 13.051244836568294,
        lng: 80.19014056108037,
      },
      {
        lat: 13.041926094968563,
        lng: 80.18943467569927,
      },
      {
        lat: 13.054054671285583,
        lng: 80.18963848252085,
      },
      {
        lat: 13.048107916025357,
        lng: 80.18916890482933,
      },
      {
        lat: 13.048747377304153,
        lng: 80.1970739557814,
      },
      {
        lat: 13.054960158215621,
        lng: 80.18882565006183,
      },
      {
        lat: 13.047109247247414,
        lng: 80.18793662971142,
      },
      {
        lat: 13.0516089243857,
        lng: 80.1899262819978,
      },
      {
        lat: 13.055079700150962,
        lng: 80.1948614345104,
      },
      {
        lat: 13.051946501400197,
        lng: 80.19408990247389,
      },
      {
        lat: 13.049390940949733,
        lng: 80.18906431909768,
      },
      {
        lat: 13.054212452069399,
        lng: 80.19460223830924,
      },
      {
        lat: 13.05237730262032,
        lng: 80.19513867208647,
      },
      {
        lat: 13.051561942978084,
        lng: 80.19068673756937,
      },
      {
        lat: 13.052503771389638,
        lng: 80.19368100264522,
      },
      {
        lat: 13.0505849205598,
        lng: 80.19281046397357,
      },
      {
        lat: 13.05004944411402,
        lng: 80.19510523595405,
      },
      {
        lat: 13.048330411405141,
        lng: 80.1946185696482,
      },
      {
        lat: 13.055373395027173,
        lng: 80.19724369258219,
      },
      {
        lat: 13.035958313468118,
        lng: 80.19286694450707,
      },
      {
        lat: 13.048792820933125,
        lng: 80.19178966913499,
      },
      {
        lat: 13.035502672391747,
        lng: 80.1937836219647,
      },
      {
        lat: 13.043589342685614,
        lng: 80.1887367652013,
      },
      {
        lat: 13.044128721535508,
        lng: 80.19222276328834,
      },
      {
        lat: 13.043080730566249,
        lng: 80.18885751063542,
      },
      {
        lat: 13.049661451471307,
        lng: 80.19147226752932,
      },
      {
        lat: 13.051280531791777,
        lng: 80.19143958928748,
      },
      {
        lat: 13.034814237579576,
        lng: 80.19206798728197,
      },
      {
        lat: 13.053207825277186,
        lng: 80.1917958070639,
      },
      {
        lat: 13.046171593149017,
        lng: 80.18825032405402,
      },
      {
        lat: 13.051856555353538,
        lng: 80.19464535225615,
      },
      {
        lat: 13.054734616474096,
        lng: 80.19698048859136,
      },
      {
        lat: 13.050662774881312,
        lng: 80.1925651828035,
      },
      {
        lat: 13.055682112084716,
        lng: 80.19377906769253,
      },
      {
        lat: 13.044757726357382,
        lng: 80.18760462866851,
      },
      {
        lat: 13.046795625535907,
        lng: 80.19372834631245,
      },
      {
        lat: 13.051797957223133,
        lng: 80.18933405978984,
      },
      {
        lat: 13.051413235348633,
        lng: 80.19017259938416,
      },
      {
        lat: 13.052954921011727,
        lng: 80.19505513285289,
      },
      {
        lat: 13.040848537565,
        lng: 80.18896785033111,
      },
      {
        lat: 13.035447500930601,
        lng: 80.191137024424,
      },
      {
        lat: 13.035951326490629,
        lng: 80.19023115250907,
      },
      {
        lat: 13.049244599467618,
        lng: 80.190804883951,
      },
      {
        lat: 13.052488434653563,
        lng: 80.18958089843385,
      },
      {
        lat: 13.051871413233725,
        lng: 80.18628847180115,
      },
      {
        lat: 13.046426056084009,
        lng: 80.18888775868038,
      },
      {
        lat: 13.04262795847737,
        lng: 80.18882982478996,
      },
      {
        lat: 13.050968484119998,
        lng: 80.19161831184934,
      },
      {
        lat: 13.04832152969813,
        lng: 80.19024300112338,
      },
      {
        lat: 13.036305909832498,
        lng: 80.19192552784261,
      },
      {
        lat: 13.052018384768946,
        lng: 80.18967201814706,
      },
      {
        lat: 13.051866207091207,
        lng: 80.18732742006118,
      },
      {
        lat: 13.055063733257564,
        lng: 80.19358649379157,
      },
      {
        lat: 13.04084915198538,
        lng: 80.1913406352231,
      },
      {
        lat: 13.04105621357061,
        lng: 80.18890384155004,
      },
      {
        lat: 13.04811569693182,
        lng: 80.194121845967,
      },
      {
        lat: 13.051667140303293,
        lng: 80.19289511395596,
      },
      {
        lat: 13.056176284324717,
        lng: 80.19677920573427,
      },
      {
        lat: 13.044009789933053,
        lng: 80.19362643654719,
      },
      {
        lat: 13.051099163338113,
        lng: 80.19447746417761,
      },
      {
        lat: 13.051778875696,
        lng: 80.19277140679365,
      },
      {
        lat: 13.052667679433853,
        lng: 80.19385130341642,
      },
      {
        lat: 13.053534165666361,
        lng: 80.1934344650545,
      },
      {
        lat: 13.04623017454706,
        lng: 80.19206651006301,
      },
      {
        lat: 13.03615182320209,
        lng: 80.19332841758296,
      },
      {
        lat: 13.053704978667618,
        lng: 80.19485703021444,
      },
      {
        lat: 13.03438976859819,
        lng: 80.19234588732098,
      },
      {
        lat: 13.053635074718859,
        lng: 80.19084723136801,
      },
      {
        lat: 13.044827392526418,
        lng: 80.19245734279184,
      },
      {
        lat: 13.04144706207943,
        lng: 80.19117463584878,
      },
      {
        lat: 13.052909194306517,
        lng: 80.18994688245893,
      },
      {
        lat: 13.039351067426358,
        lng: 80.19196797227461,
      },
      {
        lat: 13.048824629798904,
        lng: 80.18990377325642,
      },
      {
        lat: 13.043754237396001,
        lng: 80.18928945406435,
      },
      {
        lat: 13.045664780906954,
        lng: 80.18899169945158,
      },
      {
        lat: 13.051254604703036,
        lng: 80.19017186949341,
      },
      {
        lat: 13.049292201378227,
        lng: 80.19002989397733,
      },
      {
        lat: 13.034778652982649,
        lng: 80.19196820903254,
      },
      {
        lat: 13.049009039379634,
        lng: 80.1876522346463,
      },
      {
        lat: 13.046703237201326,
        lng: 80.18722563858171,
      },
      {
        lat: 13.054029944910269,
        lng: 80.19051253826885,
      },
      {
        lat: 13.048658885869392,
        lng: 80.1878053608399,
      },
      {
        lat: 13.048004335168487,
        lng: 80.19144620441737,
      },
      {
        lat: 13.054558559507784,
        lng: 80.19353971683103,
      },
      {
        lat: 13.04767618223545,
        lng: 80.18956070270731,
      },
      {
        lat: 13.044573269911075,
        lng: 80.19289364623556,
      },
      {
        lat: 13.036085809009396,
        lng: 80.1899579188034,
      },
      {
        lat: 13.051463293937248,
        lng: 80.19611720565575,
      },
      {
        lat: 13.047053337681884,
        lng: 80.18938422276798,
      },
      {
        lat: 13.044711343440499,
        lng: 80.18794363682103,
      },
      {
        lat: 13.042135052792926,
        lng: 80.19041938321864,
      },
      {
        lat: 13.053871944815867,
        lng: 80.19674054987335,
      },
      {
        lat: 13.051012669599071,
        lng: 80.19210131285527,
      },
      {
        lat: 13.036093953863437,
        lng: 80.1903997810512,
      },
      {
        lat: 13.052781414227754,
        lng: 80.19016019013982,
      },
      {
        lat: 13.049058165701531,
        lng: 80.18792141656395,
      },
      {
        lat: 13.044426263532431,
        lng: 80.19258106504898,
      },
      {
        lat: 13.054872488589602,
        lng: 80.1874742051373,
      },
      {
        lat: 13.051313499126964,
        lng: 80.19132046229285,
      },
      {
        lat: 13.04762810895672,
        lng: 80.19013299755159,
      },
      {
        lat: 13.03763195304892,
        lng: 80.18927270328945,
      },
      {
        lat: 13.053376297206512,
        lng: 80.1947958984604,
      },
      {
        lat: 13.046254236413867,
        lng: 80.1901832335815,
      },
      {
        lat: 13.046561188514389,
        lng: 80.19171914478157,
      },
      {
        lat: 13.039258653536788,
        lng: 80.19316672374985,
      },
      {
        lat: 13.052473676180641,
        lng: 80.19516571976149,
      },
      {
        lat: 13.053059751042296,
        lng: 80.18862938212283,
      },
      {
        lat: 13.055235745267062,
        lng: 80.19670890026632,
      },
      {
        lat: 13.036758029714337,
        lng: 80.19280915521837,
      },
    ],
    cases: 169,
    coords: [
      {
        lat: 13.055058158440822,
        lng: 80.19726971029911,
      },
      {
        lat: 13.052000823805697,
        lng: 80.19723957396701,
      },
      {
        lat: 13.051946233534743,
        lng: 80.19779651689122,
      },
      {
        lat: 13.05144355171793,
        lng: 80.19776239322388,
      },
      {
        lat: 13.051152977390544,
        lng: 80.19764971844785,
      },
      {
        lat: 13.050264030221632,
        lng: 80.19765724183829,
      },
      {
        lat: 13.049953780830482,
        lng: 80.1974871665865,
      },
      {
        lat: 13.04920022493914,
        lng: 80.19751273312238,
      },
      {
        lat: 13.049178565832179,
        lng: 80.19712913648911,
      },
      {
        lat: 13.047364124695088,
        lng: 80.19710490995867,
      },
      {
        lat: 13.047493997986834,
        lng: 80.1958186311763,
      },
      {
        lat: 13.047381185187705,
        lng: 80.19538207798082,
      },
      {
        lat: 13.047185924806952,
        lng: 80.19497356680792,
      },
      {
        lat: 13.046740161809494,
        lng: 80.19412966774442,
      },
      {
        lat: 13.046649897701874,
        lng: 80.19287259628936,
      },
      {
        lat: 13.046153881740116,
        lng: 80.19282210570614,
      },
      {
        lat: 13.044778812962978,
        lng: 80.1931345299919,
      },
      {
        lat: 13.044561003890605,
        lng: 80.19354653707849,
      },
      {
        lat: 13.044425143472607,
        lng: 80.19384847362262,
      },
      {
        lat: 13.04359917087554,
        lng: 80.1938828082904,
      },
      {
        lat: 13.040346364997058,
        lng: 80.19336345268086,
      },
      {
        lat: 13.038527030003854,
        lng: 80.19307806346261,
      },
      {
        lat: 13.03822427871404,
        lng: 80.19310796997271,
      },
      {
        lat: 13.03794889907872,
        lng: 80.19311030058199,
      },
      {
        lat: 13.037729593224725,
        lng: 80.1932762221091,
      },
      {
        lat: 13.037597890533291,
        lng: 80.19426172965187,
      },
      {
        lat: 13.037516607115286,
        lng: 80.19448117155433,
      },
      {
        lat: 13.037324173988601,
        lng: 80.19453748866076,
      },
      {
        lat: 13.03693764464466,
        lng: 80.19437669447386,
      },
      {
        lat: 13.034484271254062,
        lng: 80.19398729429717,
      },
      {
        lat: 13.034428197472565,
        lng: 80.19382370337931,
      },
      {
        lat: 13.034647503326331,
        lng: 80.1936577818522,
      },
      {
        lat: 13.034563226345192,
        lng: 80.19338505263552,
      },
      {
        lat: 13.034479614600308,
        lng: 80.19322169477857,
      },
      {
        lat: 13.03414882641974,
        lng: 80.19316980582973,
      },
      {
        lat: 13.034004650548612,
        lng: 80.19210460037633,
      },
      {
        lat: 13.03413452384035,
        lng: 80.19081832159394,
      },
      {
        lat: 13.03465129851686,
        lng: 80.18894423711824,
      },
      {
        lat: 13.037435826955939,
        lng: 80.18876842175106,
      },
      {
        lat: 13.041107112636974,
        lng: 80.18849148926051,
      },
      {
        lat: 13.042713792075164,
        lng: 80.18802714552805,
      },
      {
        lat: 13.043909045606297,
        lng: 80.18777116833967,
      },
      {
        lat: 13.044691876335763,
        lng: 80.18755965850042,
      },
      {
        lat: 13.045328776515225,
        lng: 80.18710352226127,
      },
      {
        lat: 13.045863754806312,
        lng: 80.18685313315353,
      },
      {
        lat: 13.04668909885632,
        lng: 80.1868461480528,
      },
      {
        lat: 13.047305116210962,
        lng: 80.18634921164266,
      },
      {
        lat: 13.048005163311847,
        lng: 80.18609742551455,
      },
      {
        lat: 13.050067028095519,
        lng: 80.18583411397043,
      },
      {
        lat: 13.051242894143178,
        lng: 80.18578318540317,
      },
      {
        lat: 13.052523672762009,
        lng: 80.18601820728935,
      },
      {
        lat: 13.053682394338507,
        lng: 80.1865411005318,
      },
      {
        lat: 13.054467468080512,
        lng: 80.18669836388094,
      },
      {
        lat: 13.054840119020808,
        lng: 80.18690009457919,
      },
      {
        lat: 13.055175490335852,
        lng: 80.18775777131215,
      },
      {
        lat: 13.055138709157756,
        lng: 80.1884956669442,
      },
      {
        lat: 13.05497837559575,
        lng: 80.18927558514014,
      },
      {
        lat: 13.055103672544641,
        lng: 80.18952038616736,
      },
      {
        lat: 13.055284389539421,
        lng: 80.18960830946777,
      },
      {
        lat: 13.055277552033388,
        lng: 80.19327549397838,
      },
      {
        lat: 13.055461412238577,
        lng: 80.19345540880973,
      },
      {
        lat: 13.056744631990139,
        lng: 80.1940897784122,
      },
      {
        lat: 13.056867818635222,
        lng: 80.19431053360358,
      },
      {
        lat: 13.05689462456633,
        lng: 80.19537896863395,
      },
      {
        lat: 13.057038853298065,
        lng: 80.19572052633293,
      },
      {
        lat: 13.057143205286247,
        lng: 80.19618340211099,
      },
      {
        lat: 13.05716743588265,
        lng: 80.19682842686521,
      },
      {
        lat: 13.056984924662338,
        lng: 80.19687029836888,
      },
      {
        lat: 13.056800941822218,
        lng: 80.19667022114338,
      },
      {
        lat: 13.056273223897975,
        lng: 80.19671501422093,
      },
      {
        lat: 13.056215370942613,
        lng: 80.19721958964564,
      },
      {
        lat: 13.055058158440822,
        lng: 80.19726971029911,
      },
    ],
    properties: {
      Ward_No: 128,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#de4307",
      zname: "KODAMBAKKAM(128)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.049226830766289,
        lng: 80.20588011780546,
      },
      {
        lat: 13.052426538442031,
        lng: 80.20509124791133,
      },
      {
        lat: 13.054300914433137,
        lng: 80.19953394278184,
      },
      {
        lat: 13.04736581494772,
        lng: 80.2017497482588,
      },
      {
        lat: 13.048131031899517,
        lng: 80.20464706332967,
      },
      {
        lat: 13.051665104403938,
        lng: 80.20538109651304,
      },
      {
        lat: 13.054345077741205,
        lng: 80.19975272256455,
      },
      {
        lat: 13.04881485246136,
        lng: 80.19974166133868,
      },
      {
        lat: 13.0591300387166,
        lng: 80.20465765970928,
      },
      {
        lat: 13.0583664691856,
        lng: 80.20619726029724,
      },
      {
        lat: 13.058076501827781,
        lng: 80.20442715174151,
      },
      {
        lat: 13.051955553447037,
        lng: 80.20283829206171,
      },
      {
        lat: 13.060147561150774,
        lng: 80.20539206472071,
      },
      {
        lat: 13.051186012544862,
        lng: 80.20687183887065,
      },
      {
        lat: 13.052035372821825,
        lng: 80.20546069003109,
      },
      {
        lat: 13.059433648716793,
        lng: 80.20880086205072,
      },
      {
        lat: 13.048057297111725,
        lng: 80.20665303897222,
      },
      {
        lat: 13.051606642968828,
        lng: 80.2073531386743,
      },
      {
        lat: 13.055621963707983,
        lng: 80.20797509002749,
      },
      {
        lat: 13.044537743549174,
        lng: 80.20528403545862,
      },
      {
        lat: 13.054551134583178,
        lng: 80.1998069928355,
      },
      {
        lat: 13.046738002232557,
        lng: 80.20788422237463,
      },
      {
        lat: 13.054934191317564,
        lng: 80.20017437870418,
      },
      {
        lat: 13.056724233352492,
        lng: 80.20714778061419,
      },
      {
        lat: 13.053259726234003,
        lng: 80.20274823891208,
      },
      {
        lat: 13.060389400092093,
        lng: 80.20706073012734,
      },
      {
        lat: 13.054997990754812,
        lng: 80.19839414535774,
      },
      {
        lat: 13.049247957613867,
        lng: 80.19886122541352,
      },
      {
        lat: 13.055465259615413,
        lng: 80.20543113536552,
      },
      {
        lat: 13.060624595050244,
        lng: 80.20229916728293,
      },
      {
        lat: 13.055757742158642,
        lng: 80.20403431662719,
      },
      {
        lat: 13.059823497295985,
        lng: 80.2081757274689,
      },
      {
        lat: 13.050589698052045,
        lng: 80.20422730326543,
      },
      {
        lat: 13.060892772064205,
        lng: 80.20464136435292,
      },
      {
        lat: 13.058367981286013,
        lng: 80.20654873911229,
      },
      {
        lat: 13.053964678021094,
        lng: 80.20397495521105,
      },
      {
        lat: 13.057010357685828,
        lng: 80.2056080295882,
      },
      {
        lat: 13.05112054019122,
        lng: 80.20490977159865,
      },
      {
        lat: 13.057098767720284,
        lng: 80.20090391351992,
      },
      {
        lat: 13.057417665842523,
        lng: 80.20685362597577,
      },
      {
        lat: 13.050222563984223,
        lng: 80.20258152543302,
      },
      {
        lat: 13.055675015510742,
        lng: 80.2088857880618,
      },
      {
        lat: 13.051862594651537,
        lng: 80.20249234918009,
      },
      {
        lat: 13.057575436993776,
        lng: 80.20679323131789,
      },
      {
        lat: 13.049784307195118,
        lng: 80.20356477506463,
      },
      {
        lat: 13.050223019079684,
        lng: 80.19806894078829,
      },
      {
        lat: 13.05939446174647,
        lng: 80.20845231565913,
      },
      {
        lat: 13.058374935170669,
        lng: 80.20725889050043,
      },
      {
        lat: 13.05149799118766,
        lng: 80.19854203819804,
      },
      {
        lat: 13.05970236896172,
        lng: 80.20057810070145,
      },
      {
        lat: 13.058844808360169,
        lng: 80.20754466214005,
      },
      {
        lat: 13.059347044116494,
        lng: 80.20517673325116,
      },
      {
        lat: 13.054165823333943,
        lng: 80.20713160524504,
      },
      {
        lat: 13.052521155928908,
        lng: 80.19911351024994,
      },
      {
        lat: 13.058453380765725,
        lng: 80.20335039583394,
      },
      {
        lat: 13.047592446435058,
        lng: 80.20501796434021,
      },
      {
        lat: 13.050539780583433,
        lng: 80.20404971861721,
      },
      {
        lat: 13.0533439389453,
        lng: 80.2015231884829,
      },
      {
        lat: 13.059794495099291,
        lng: 80.20842650300361,
      },
      {
        lat: 13.052246426466567,
        lng: 80.19791996747298,
      },
      {
        lat: 13.060909802184716,
        lng: 80.20435752578896,
      },
      {
        lat: 13.05108897448125,
        lng: 80.2050583281532,
      },
      {
        lat: 13.05231864806538,
        lng: 80.2072015352033,
      },
      {
        lat: 13.053245515944232,
        lng: 80.20069885927053,
      },
      {
        lat: 13.056640035427593,
        lng: 80.2092229994423,
      },
      {
        lat: 13.052969837165474,
        lng: 80.20366080789007,
      },
      {
        lat: 13.050846205797443,
        lng: 80.20187639132423,
      },
      {
        lat: 13.053315267463049,
        lng: 80.20385527780368,
      },
      {
        lat: 13.055467368093028,
        lng: 80.20333124449714,
      },
      {
        lat: 13.04982460148115,
        lng: 80.20736761253683,
      },
      {
        lat: 13.047567780624615,
        lng: 80.20842662816477,
      },
      {
        lat: 13.051586841329554,
        lng: 80.20570324975563,
      },
      {
        lat: 13.048058267421538,
        lng: 80.2013602064813,
      },
      {
        lat: 13.060441018677293,
        lng: 80.20384645171262,
      },
      {
        lat: 13.056116544654033,
        lng: 80.2074503148596,
      },
      {
        lat: 13.049907144001658,
        lng: 80.20086678896949,
      },
      {
        lat: 13.04865887651395,
        lng: 80.20260611498827,
      },
      {
        lat: 13.048785770288289,
        lng: 80.20816068603692,
      },
      {
        lat: 13.048631228666954,
        lng: 80.20059961956107,
      },
      {
        lat: 13.054609948056372,
        lng: 80.20166932732312,
      },
      {
        lat: 13.052908091694421,
        lng: 80.2048004831173,
      },
      {
        lat: 13.048203311768848,
        lng: 80.20368576552617,
      },
      {
        lat: 13.052589125497056,
        lng: 80.19813513197037,
      },
      {
        lat: 13.04953129525986,
        lng: 80.19796504131595,
      },
      {
        lat: 13.059795890863953,
        lng: 80.20662198639198,
      },
      {
        lat: 13.061116509697312,
        lng: 80.20352268511802,
      },
      {
        lat: 13.048699661549442,
        lng: 80.20046704055156,
      },
      {
        lat: 13.050739398139589,
        lng: 80.2005381503832,
      },
      {
        lat: 13.05324377201153,
        lng: 80.20277994044105,
      },
      {
        lat: 13.06029725225216,
        lng: 80.20161632409537,
      },
      {
        lat: 13.051528979262242,
        lng: 80.20732606703179,
      },
      {
        lat: 13.052329115416155,
        lng: 80.20662856174704,
      },
      {
        lat: 13.051718142133465,
        lng: 80.2034426087539,
      },
      {
        lat: 13.051734632490504,
        lng: 80.20691855076136,
      },
      {
        lat: 13.05482619569751,
        lng: 80.2038596273704,
      },
      {
        lat: 13.050742749362556,
        lng: 80.20363207116239,
      },
      {
        lat: 13.054990643308878,
        lng: 80.20271715349166,
      },
      {
        lat: 13.04849197100293,
        lng: 80.20460405656698,
      },
      {
        lat: 13.054481475929762,
        lng: 80.20166858954335,
      },
      {
        lat: 13.04900190443889,
        lng: 80.20496399164155,
      },
      {
        lat: 13.057203158923674,
        lng: 80.20097852647227,
      },
      {
        lat: 13.057976679485806,
        lng: 80.20862592339594,
      },
      {
        lat: 13.052872061397425,
        lng: 80.20604351218749,
      },
      {
        lat: 13.049305568815802,
        lng: 80.20001903631078,
      },
      {
        lat: 13.05600094273789,
        lng: 80.20645918998255,
      },
      {
        lat: 13.05748681814275,
        lng: 80.2033393218888,
      },
      {
        lat: 13.050652829194673,
        lng: 80.20138019599703,
      },
      {
        lat: 13.054433851704193,
        lng: 80.19795931716338,
      },
      {
        lat: 13.044823246169333,
        lng: 80.20375150153343,
      },
      {
        lat: 13.052452605536565,
        lng: 80.20423340006317,
      },
      {
        lat: 13.053324603644917,
        lng: 80.2006895754786,
      },
      {
        lat: 13.053756197172588,
        lng: 80.19979801164675,
      },
      {
        lat: 13.056071596212105,
        lng: 80.20109075360381,
      },
      {
        lat: 13.051996427100125,
        lng: 80.20308671839453,
      },
      {
        lat: 13.05385627529195,
        lng: 80.19886515718093,
      },
      {
        lat: 13.04903393150484,
        lng: 80.20098794826211,
      },
      {
        lat: 13.061343429609359,
        lng: 80.20465861803524,
      },
      {
        lat: 13.060949670465458,
        lng: 80.2066233753951,
      },
      {
        lat: 13.05259998830567,
        lng: 80.20685864796903,
      },
      {
        lat: 13.053546336377238,
        lng: 80.1994995764924,
      },
      {
        lat: 13.049016245017393,
        lng: 80.2065401702735,
      },
      {
        lat: 13.054476977883356,
        lng: 80.203597822882,
      },
      {
        lat: 13.053686060815197,
        lng: 80.20348587906865,
      },
      {
        lat: 13.056145568409866,
        lng: 80.20839080695275,
      },
      {
        lat: 13.047562944507158,
        lng: 80.20640392181198,
      },
      {
        lat: 13.050512351594577,
        lng: 80.20453643233611,
      },
      {
        lat: 13.050897677546926,
        lng: 80.20267611710028,
      },
      {
        lat: 13.054100866420306,
        lng: 80.20095347046625,
      },
      {
        lat: 13.059042102173363,
        lng: 80.20777133881383,
      },
      {
        lat: 13.050369024311731,
        lng: 80.20234917161152,
      },
      {
        lat: 13.058504795081134,
        lng: 80.20646934081773,
      },
      {
        lat: 13.05578581609852,
        lng: 80.2068851496353,
      },
      {
        lat: 13.054514374210868,
        lng: 80.20648554863548,
      },
      {
        lat: 13.044181211346412,
        lng: 80.20474607360809,
      },
      {
        lat: 13.046496438330033,
        lng: 80.20636894184283,
      },
      {
        lat: 13.051768586735935,
        lng: 80.19960447779697,
      },
      {
        lat: 13.056674292024166,
        lng: 80.19961774094081,
      },
      {
        lat: 13.050551939908257,
        lng: 80.20309465111434,
      },
      {
        lat: 13.045818188904626,
        lng: 80.20443117543458,
      },
      {
        lat: 13.060189945681076,
        lng: 80.20394652979239,
      },
      {
        lat: 13.050173009149301,
        lng: 80.20599377428498,
      },
      {
        lat: 13.052093704804422,
        lng: 80.2044185601562,
      },
      {
        lat: 13.054812184531041,
        lng: 80.20211133874346,
      },
      {
        lat: 13.051052530273147,
        lng: 80.20422511016494,
      },
      {
        lat: 13.055251619596964,
        lng: 80.20348293266231,
      },
      {
        lat: 13.0518603484678,
        lng: 80.20578580737188,
      },
    ],
    cases: 146,
    coords: [
      {
        lat: 13.060132415865722,
        lng: 80.20946250256563,
      },
      {
        lat: 13.057514677431403,
        lng: 80.20945154730518,
      },
      {
        lat: 13.057346910975577,
        lng: 80.20934235496652,
      },
      {
        lat: 13.057068309338996,
        lng: 80.20932627747929,
      },
      {
        lat: 13.057083511562437,
        lng: 80.20877308786983,
      },
      {
        lat: 13.056860832077742,
        lng: 80.20879340782903,
      },
      {
        lat: 13.056864307946537,
        lng: 80.20936487472606,
      },
      {
        lat: 13.056529784158014,
        lng: 80.20931239979261,
      },
      {
        lat: 13.056491306725558,
        lng: 80.2090915010575,
      },
      {
        lat: 13.055766897620396,
        lng: 80.20904232582474,
      },
      {
        lat: 13.055768691617194,
        lng: 80.2093372764811,
      },
      {
        lat: 13.054932886707396,
        lng: 80.20928904401994,
      },
      {
        lat: 13.055021904301528,
        lng: 80.20866148823188,
      },
      {
        lat: 13.055034864028972,
        lng: 80.20773961030146,
      },
      {
        lat: 13.052454082428163,
        lng: 80.20774301676045,
      },
      {
        lat: 13.052304994064505,
        lng: 80.20765210170903,
      },
      {
        lat: 13.050002365601244,
        lng: 80.20759784799101,
      },
      {
        lat: 13.049667841812605,
        lng: 80.20754537305756,
      },
      {
        lat: 13.049314752056711,
        lng: 80.20749305525284,
      },
      {
        lat: 13.049091960447223,
        lng: 80.20749494079604,
      },
      {
        lat: 13.04907417935339,
        lng: 80.20762413883688,
      },
      {
        lat: 13.049205710872759,
        lng: 80.20788112076121,
      },
      {
        lat: 13.049393164544016,
        lng: 80.20817450013176,
      },
      {
        lat: 13.049339372763322,
        lng: 80.20848835659017,
      },
      {
        lat: 13.049247103550009,
        lng: 80.2085813143134,
      },
      {
        lat: 13.048894910792278,
        lng: 80.20867647183677,
      },
      {
        lat: 13.048263555774207,
        lng: 80.20866337979307,
      },
      {
        lat: 13.047612401415682,
        lng: 80.2084476663017,
      },
      {
        lat: 13.046999724489835,
        lng: 80.20845285154539,
      },
      {
        lat: 13.046980699022354,
        lng: 80.20954108837691,
      },
      {
        lat: 13.046455111825246,
        lng: 80.20950206760521,
      },
      {
        lat: 13.046452335831953,
        lng: 80.20904566709937,
      },
      {
        lat: 13.046118060514349,
        lng: 80.2080704447298,
      },
      {
        lat: 13.045230228801726,
        lng: 80.20605665239474,
      },
      {
        lat: 13.044307080357862,
        lng: 80.2054341654301,
      },
      {
        lat: 13.044085419857506,
        lng: 80.20497961740044,
      },
      {
        lat: 13.044033182786022,
        lng: 80.20358905302068,
      },
      {
        lat: 13.04528135323661,
        lng: 80.20366542733511,
      },
      {
        lat: 13.047755409116515,
        lng: 80.20375316113942,
      },
      {
        lat: 13.047730744672522,
        lng: 80.20329694588123,
      },
      {
        lat: 13.047337820235816,
        lng: 80.201835687392,
      },
      {
        lat: 13.047364124695088,
        lng: 80.19710490995867,
      },
      {
        lat: 13.049178565832179,
        lng: 80.19712913648911,
      },
      {
        lat: 13.04920022493914,
        lng: 80.19751273312238,
      },
      {
        lat: 13.049953780830482,
        lng: 80.1974871665865,
      },
      {
        lat: 13.050264030221632,
        lng: 80.19765724183829,
      },
      {
        lat: 13.051152977390544,
        lng: 80.19764971844785,
      },
      {
        lat: 13.05144355171793,
        lng: 80.19776239322388,
      },
      {
        lat: 13.051946233534743,
        lng: 80.19779651689122,
      },
      {
        lat: 13.052000823805697,
        lng: 80.19723957396701,
      },
      {
        lat: 13.055058158440822,
        lng: 80.19726971029911,
      },
      {
        lat: 13.055046313978435,
        lng: 80.19866108734983,
      },
      {
        lat: 13.05537231812032,
        lng: 80.19883979918016,
      },
      {
        lat: 13.056326467855166,
        lng: 80.19879139709936,
      },
      {
        lat: 13.056410650992271,
        lng: 80.19918917772613,
      },
      {
        lat: 13.057138434663587,
        lng: 80.19935281562616,
      },
      {
        lat: 13.058464073674374,
        lng: 80.19940527039532,
      },
      {
        lat: 13.05857637057227,
        lng: 80.20029575593855,
      },
      {
        lat: 13.058982754285024,
        lng: 80.20033476593828,
      },
      {
        lat: 13.059282262857453,
        lng: 80.20037468045015,
      },
      {
        lat: 13.05990200958306,
        lng: 80.2003482107077,
      },
      {
        lat: 13.06062785691222,
        lng: 80.20019349501656,
      },
      {
        lat: 13.060970244630278,
        lng: 80.20025427129639,
      },
      {
        lat: 13.061533740728649,
        lng: 80.20152298219841,
      },
      {
        lat: 13.06175472001956,
        lng: 80.20270969324812,
      },
      {
        lat: 13.06180340819091,
        lng: 80.20368561578961,
      },
      {
        lat: 13.061101237615551,
        lng: 80.20729847839777,
      },
      {
        lat: 13.060737129460385,
        lng: 80.20783801082476,
      },
      {
        lat: 13.060399851709912,
        lng: 80.20923563759555,
      },
      {
        lat: 13.060271104962704,
        lng: 80.20938693346058,
      },
      {
        lat: 13.060132415865722,
        lng: 80.20946250256563,
      },
    ],
    properties: {
      Ward_No: 129,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#de4307",
      zname: "KODAMBAKKAM(129)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.02978461251742,
        lng: 80.2095332669655,
      },
      {
        lat: 13.03273983405111,
        lng: 80.2081142675031,
      },
      {
        lat: 13.029831528910684,
        lng: 80.20698780284941,
      },
      {
        lat: 13.028436704913624,
        lng: 80.2061534800461,
      },
      {
        lat: 13.029704215723472,
        lng: 80.2073811049781,
      },
      {
        lat: 13.035366154879611,
        lng: 80.20733652325312,
      },
      {
        lat: 13.030420213428622,
        lng: 80.19973651303042,
      },
      {
        lat: 13.031337778157864,
        lng: 80.20272959355478,
      },
      {
        lat: 13.031575170976545,
        lng: 80.20928960265199,
      },
      {
        lat: 13.034963689897003,
        lng: 80.20627496230365,
      },
      {
        lat: 13.030580754587742,
        lng: 80.2088353725655,
      },
      {
        lat: 13.035041651281649,
        lng: 80.20189551096611,
      },
      {
        lat: 13.035998429181415,
        lng: 80.20415514505532,
      },
      {
        lat: 13.028511082934937,
        lng: 80.20226854265249,
      },
      {
        lat: 13.034827538661128,
        lng: 80.20340079700408,
      },
      {
        lat: 13.032713475462087,
        lng: 80.20919678811636,
      },
      {
        lat: 13.031040230866385,
        lng: 80.20183686891372,
      },
      {
        lat: 13.030740386309715,
        lng: 80.20618167259666,
      },
      {
        lat: 13.029259993872822,
        lng: 80.20587723699745,
      },
      {
        lat: 13.03407573249533,
        lng: 80.20344090420905,
      },
      {
        lat: 13.031162023711518,
        lng: 80.20010339929115,
      },
      {
        lat: 13.026918269213668,
        lng: 80.20912838518751,
      },
      {
        lat: 13.029204713521985,
        lng: 80.20865654831698,
      },
      {
        lat: 13.034750430808215,
        lng: 80.20275649284187,
      },
      {
        lat: 13.031109828765578,
        lng: 80.20885919518598,
      },
      {
        lat: 13.034504633529055,
        lng: 80.20926931577183,
      },
      {
        lat: 13.02958362453785,
        lng: 80.20603383694491,
      },
      {
        lat: 13.031195978712832,
        lng: 80.20274482048346,
      },
      {
        lat: 13.033418300041337,
        lng: 80.20728355616201,
      },
      {
        lat: 13.032968290816138,
        lng: 80.20740354606049,
      },
      {
        lat: 13.030273605479827,
        lng: 80.20112166035034,
      },
      {
        lat: 13.028240011429096,
        lng: 80.20387808053445,
      },
      {
        lat: 13.029520356207447,
        lng: 80.20280477507036,
      },
    ],
    cases: 33,
    coords: [
      {
        lat: 13.02618712743631,
        lng: 80.20916127691929,
      },
      {
        lat: 13.02700938991815,
        lng: 80.20777968449156,
      },
      {
        lat: 13.027356239810175,
        lng: 80.20668502832743,
      },
      {
        lat: 13.028675549728483,
        lng: 80.20050049256598,
      },
      {
        lat: 13.028964420145938,
        lng: 80.20049804778074,
      },
      {
        lat: 13.029167516252055,
        lng: 80.1999636291221,
      },
      {
        lat: 13.02915490492567,
        lng: 80.19789020345705,
      },
      {
        lat: 13.030253668532547,
        lng: 80.19776439063757,
      },
      {
        lat: 13.030980037257297,
        lng: 80.19757182127383,
      },
      {
        lat: 13.031117442721,
        lng: 80.19701139262462,
      },
      {
        lat: 13.03205629741989,
        lng: 80.19702674959349,
      },
      {
        lat: 13.03233734439738,
        lng: 80.1969311600616,
      },
      {
        lat: 13.032619666930533,
        lng: 80.19704528439075,
      },
      {
        lat: 13.034545304075696,
        lng: 80.197238711881,
      },
      {
        lat: 13.03447886899859,
        lng: 80.19789175084784,
      },
      {
        lat: 13.035277483665475,
        lng: 80.19800150566279,
      },
      {
        lat: 13.035292648605614,
        lng: 80.20049477045798,
      },
      {
        lat: 13.03517615985483,
        lng: 80.20063557277032,
      },
      {
        lat: 13.03518026997879,
        lng: 80.20131131743443,
      },
      {
        lat: 13.036542962798551,
        lng: 80.20155611474765,
      },
      {
        lat: 13.035643650326756,
        lng: 80.20804188747843,
      },
      {
        lat: 13.035303112964261,
        lng: 80.20993229144428,
      },
      {
        lat: 13.034974988609685,
        lng: 80.21000497666452,
      },
      {
        lat: 13.034223734776532,
        lng: 80.20996472924128,
      },
      {
        lat: 13.032226702059654,
        lng: 80.20960878681319,
      },
      {
        lat: 13.031145906587968,
        lng: 80.20940820920877,
      },
      {
        lat: 13.030463780671935,
        lng: 80.2091576520815,
      },
      {
        lat: 13.030276179806194,
        lng: 80.20918254253581,
      },
      {
        lat: 13.030183442336336,
        lng: 80.20936974931409,
      },
      {
        lat: 13.029723156469496,
        lng: 80.2108650201688,
      },
      {
        lat: 13.02911228439676,
        lng: 80.21075367643942,
      },
      {
        lat: 13.028711630643402,
        lng: 80.21047743440057,
      },
      {
        lat: 13.028616058605289,
        lng: 80.21019861037603,
      },
      {
        lat: 13.026755864438512,
        lng: 80.20918903313814,
      },
      {
        lat: 13.02618712743631,
        lng: 80.20916127691929,
      },
    ],
    properties: {
      Ward_No: 138,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#8bc24c",
      zname: "KODAMBAKKAM(138)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.030402826483046,
        lng: 80.1896118280177,
      },
      {
        lat: 13.043496986107606,
        lng: 80.19818619446703,
      },
      {
        lat: 13.033288717003378,
        lng: 80.18915335203907,
      },
      {
        lat: 13.031284472736127,
        lng: 80.19183754048565,
      },
      {
        lat: 13.042037542678571,
        lng: 80.19631478952924,
      },
      {
        lat: 13.04662480848275,
        lng: 80.19323789361991,
      },
      {
        lat: 13.03242856088015,
        lng: 80.19165009877246,
      },
      {
        lat: 13.046835333496546,
        lng: 80.19504034259145,
      },
      {
        lat: 13.037847313784779,
        lng: 80.19577962599891,
      },
      {
        lat: 13.040445436101939,
        lng: 80.19620998799991,
      },
      {
        lat: 13.02862946028306,
        lng: 80.19236450002697,
      },
      {
        lat: 13.029354032276114,
        lng: 80.18876786125107,
      },
      {
        lat: 13.046045705432944,
        lng: 80.19384847533676,
      },
      {
        lat: 13.0412937959837,
        lng: 80.19819807535961,
      },
      {
        lat: 13.037848120262465,
        lng: 80.193939672037,
      },
      {
        lat: 13.03761069916052,
        lng: 80.19478363122973,
      },
      {
        lat: 13.045134373300934,
        lng: 80.19397600413562,
      },
      {
        lat: 13.042306926910973,
        lng: 80.19396439428435,
      },
      {
        lat: 13.041921976833754,
        lng: 80.20087604151846,
      },
      {
        lat: 13.032796606234294,
        lng: 80.19040544033442,
      },
      {
        lat: 13.028919926688712,
        lng: 80.1930079772657,
      },
      {
        lat: 13.028686924985942,
        lng: 80.1908920272252,
      },
      {
        lat: 13.027894984722604,
        lng: 80.19164583644907,
      },
      {
        lat: 13.030611310453912,
        lng: 80.19049305241,
      },
      {
        lat: 13.046101180831233,
        lng: 80.19607702541154,
      },
      {
        lat: 13.042492405506843,
        lng: 80.20207948117078,
      },
      {
        lat: 13.037491914240713,
        lng: 80.19796547805865,
      },
      {
        lat: 13.029537258321069,
        lng: 80.19357594193181,
      },
      {
        lat: 13.044324998916249,
        lng: 80.19411050830614,
      },
      {
        lat: 13.041149969181928,
        lng: 80.19869968626094,
      },
      {
        lat: 13.039371859688897,
        lng: 80.19858727853625,
      },
      {
        lat: 13.037328069603538,
        lng: 80.19509316532388,
      },
      {
        lat: 13.028312580672344,
        lng: 80.19267940974693,
      },
      {
        lat: 13.029280325006258,
        lng: 80.19095974796996,
      },
      {
        lat: 13.0413956963262,
        lng: 80.1997155463634,
      },
      {
        lat: 13.032367660256044,
        lng: 80.19567451937124,
      },
      {
        lat: 13.029851820066476,
        lng: 80.19645741459695,
      },
      {
        lat: 13.02496338755104,
        lng: 80.19028962937833,
      },
      {
        lat: 13.0284033991008,
        lng: 80.19117854548062,
      },
      {
        lat: 13.03216977538531,
        lng: 80.19200317328998,
      },
      {
        lat: 13.041440805951051,
        lng: 80.19468856187515,
      },
      {
        lat: 13.037467269854995,
        lng: 80.19869676924546,
      },
      {
        lat: 13.043337997709939,
        lng: 80.20034508726263,
      },
      {
        lat: 13.029169745003813,
        lng: 80.19624981477195,
      },
      {
        lat: 13.032649719495296,
        lng: 80.19662592305238,
      },
      {
        lat: 13.034038845976138,
        lng: 80.18914966306353,
      },
      {
        lat: 13.039532769754627,
        lng: 80.19468923310274,
      },
      {
        lat: 13.031868462060096,
        lng: 80.19421103361499,
      },
      {
        lat: 13.028640259818507,
        lng: 80.19425342796629,
      },
      {
        lat: 13.028578101586366,
        lng: 80.19442877051306,
      },
      {
        lat: 13.025190832144027,
        lng: 80.18976263095344,
      },
      {
        lat: 13.035068385348762,
        lng: 80.19447452862539,
      },
      {
        lat: 13.043731629177554,
        lng: 80.19597975050733,
      },
      {
        lat: 13.038828564739427,
        lng: 80.19803313756623,
      },
      {
        lat: 13.03906756471051,
        lng: 80.19919902290187,
      },
      {
        lat: 13.043966237442957,
        lng: 80.19738267985794,
      },
      {
        lat: 13.03111003287028,
        lng: 80.1922773188192,
      },
      {
        lat: 13.029631499792892,
        lng: 80.19251239066021,
      },
      {
        lat: 13.045652985852703,
        lng: 80.19520710541083,
      },
      {
        lat: 13.046131870063272,
        lng: 80.196008938913,
      },
      {
        lat: 13.029909823826715,
        lng: 80.19332681948703,
      },
      {
        lat: 13.026839353716213,
        lng: 80.19037478936826,
      },
      {
        lat: 13.041296630645105,
        lng: 80.19400334366567,
      },
      {
        lat: 13.043177038223403,
        lng: 80.1940522486611,
      },
      {
        lat: 13.03173926294201,
        lng: 80.1906033263601,
      },
      {
        lat: 13.041026079917692,
        lng: 80.1977131137707,
      },
      {
        lat: 13.040741073209157,
        lng: 80.19824468675363,
      },
      {
        lat: 13.045940863815929,
        lng: 80.19570808720253,
      },
      {
        lat: 13.038270539724458,
        lng: 80.19668402817688,
      },
      {
        lat: 13.040721158253293,
        lng: 80.198651277496,
      },
      {
        lat: 13.040144033847644,
        lng: 80.19512802143511,
      },
      {
        lat: 13.028108974113737,
        lng: 80.19117423107116,
      },
      {
        lat: 13.033031888331584,
        lng: 80.19171269906954,
      },
      {
        lat: 13.041396051731887,
        lng: 80.2011442669168,
      },
      {
        lat: 13.03846561181542,
        lng: 80.19859771537391,
      },
      {
        lat: 13.029894070355903,
        lng: 80.19697037523389,
      },
      {
        lat: 13.032385409223231,
        lng: 80.19401795187252,
      },
      {
        lat: 13.040436378855688,
        lng: 80.19554056357372,
      },
      {
        lat: 13.030118085977511,
        lng: 80.19028710136615,
      },
      {
        lat: 13.02582367248026,
        lng: 80.1900208281239,
      },
      {
        lat: 13.036735184685375,
        lng: 80.19487577658177,
      },
    ],
    cases: 81,
    coords: [
      {
        lat: 13.047364124695088,
        lng: 80.19710490995867,
      },
      {
        lat: 13.04707687989441,
        lng: 80.19715528225602,
      },
      {
        lat: 13.045768651457683,
        lng: 80.19603568153042,
      },
      {
        lat: 13.045147379403621,
        lng: 80.19641783038423,
      },
      {
        lat: 13.044198640906808,
        lng: 80.1964527805804,
      },
      {
        lat: 13.043699077234717,
        lng: 80.1990144822605,
      },
      {
        lat: 13.043205244234597,
        lng: 80.20251836260891,
      },
      {
        lat: 13.040817140643748,
        lng: 80.20216168288155,
      },
      {
        lat: 13.040733513898,
        lng: 80.2017854997689,
      },
      {
        lat: 13.040840486265722,
        lng: 80.20154230744778,
      },
      {
        lat: 13.040863831887862,
        lng: 80.2009229320143,
      },
      {
        lat: 13.036793342173022,
        lng: 80.20033820377061,
      },
      {
        lat: 13.03673486211764,
        lng: 80.19963875852024,
      },
      {
        lat: 13.036842161952508,
        lng: 80.19944940498016,
      },
      {
        lat: 13.037024655221805,
        lng: 80.19786419126065,
      },
      {
        lat: 13.034545304075696,
        lng: 80.197238711881,
      },
      {
        lat: 13.032619666930533,
        lng: 80.19704528439075,
      },
      {
        lat: 13.03233734439738,
        lng: 80.1969311600616,
      },
      {
        lat: 13.03205629741989,
        lng: 80.19702674959349,
      },
      {
        lat: 13.031117442721,
        lng: 80.19701139262462,
      },
      {
        lat: 13.030980037257297,
        lng: 80.19757182127383,
      },
      {
        lat: 13.030253668532547,
        lng: 80.19776439063757,
      },
      {
        lat: 13.02915490492567,
        lng: 80.19789020345705,
      },
      {
        lat: 13.02914820142486,
        lng: 80.19678808222092,
      },
      {
        lat: 13.028892872278886,
        lng: 80.19551995899074,
      },
      {
        lat: 13.028389192247147,
        lng: 80.19413100689445,
      },
      {
        lat: 13.027724430982731,
        lng: 80.19339904859817,
      },
      {
        lat: 13.02606277704568,
        lng: 80.19161012765561,
      },
      {
        lat: 13.024734749858307,
        lng: 80.19039205985517,
      },
      {
        lat: 13.024442888757926,
        lng: 80.18990280705566,
      },
      {
        lat: 13.024436658168506,
        lng: 80.18887843708757,
      },
      {
        lat: 13.024682018371127,
        lng: 80.18850756836888,
      },
      {
        lat: 13.025961550872134,
        lng: 80.18853771626146,
      },
      {
        lat: 13.03120423015484,
        lng: 80.18878018446287,
      },
      {
        lat: 13.031906021820758,
        lng: 80.18881522192595,
      },
      {
        lat: 13.032320189187509,
        lng: 80.1890575781679,
      },
      {
        lat: 13.032939197224724,
        lng: 80.18905233934234,
      },
      {
        lat: 13.03465129851686,
        lng: 80.18894423711824,
      },
      {
        lat: 13.03413452384035,
        lng: 80.19081832159394,
      },
      {
        lat: 13.034004650548612,
        lng: 80.19210460037633,
      },
      {
        lat: 13.03414882641974,
        lng: 80.19316980582973,
      },
      {
        lat: 13.034479614600308,
        lng: 80.19322169477857,
      },
      {
        lat: 13.034563226345192,
        lng: 80.19338505263552,
      },
      {
        lat: 13.034647503326331,
        lng: 80.1936577818522,
      },
      {
        lat: 13.034428197472565,
        lng: 80.19382370337931,
      },
      {
        lat: 13.034484271254062,
        lng: 80.19398729429717,
      },
      {
        lat: 13.03693764464466,
        lng: 80.19437669447386,
      },
      {
        lat: 13.037324173988601,
        lng: 80.19453748866076,
      },
      {
        lat: 13.037516607115286,
        lng: 80.19448117155433,
      },
      {
        lat: 13.037597890533291,
        lng: 80.19426172965187,
      },
      {
        lat: 13.037729593224725,
        lng: 80.1932762221091,
      },
      {
        lat: 13.03794889907872,
        lng: 80.19311030058199,
      },
      {
        lat: 13.03822427871404,
        lng: 80.19310796997271,
      },
      {
        lat: 13.038527030003854,
        lng: 80.19307806346261,
      },
      {
        lat: 13.040346364997058,
        lng: 80.19336345268086,
      },
      {
        lat: 13.04359917087554,
        lng: 80.1938828082904,
      },
      {
        lat: 13.044425143472607,
        lng: 80.19384847362262,
      },
      {
        lat: 13.044561003890605,
        lng: 80.19354653707849,
      },
      {
        lat: 13.044778812962978,
        lng: 80.1931345299919,
      },
      {
        lat: 13.046153881740116,
        lng: 80.19282210570614,
      },
      {
        lat: 13.046649897701874,
        lng: 80.19287259628936,
      },
      {
        lat: 13.046740161809494,
        lng: 80.19412966774442,
      },
      {
        lat: 13.047185924806952,
        lng: 80.19497356680792,
      },
      {
        lat: 13.047381185187705,
        lng: 80.19538207798082,
      },
      {
        lat: 13.047493997986834,
        lng: 80.1958186311763,
      },
      {
        lat: 13.047364124695088,
        lng: 80.19710490995867,
      },
    ],
    properties: {
      Ward_No: 137,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#f6d04d",
      zname: "KODAMBAKKAM(137)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.016982498727797,
        lng: 80.2233489039468,
      },
      {
        lat: 13.023496858576124,
        lng: 80.22434802446853,
      },
      {
        lat: 13.020946584204028,
        lng: 80.22244014065602,
      },
      {
        lat: 13.018075525023901,
        lng: 80.22382762306043,
      },
      {
        lat: 13.022493151046524,
        lng: 80.2200992150982,
      },
      {
        lat: 13.026547128039248,
        lng: 80.21960725924642,
      },
      {
        lat: 13.021960041870418,
        lng: 80.22483230255973,
      },
      {
        lat: 13.019287173678428,
        lng: 80.22145445572062,
      },
      {
        lat: 13.026585226896602,
        lng: 80.21661033166467,
      },
      {
        lat: 13.026341250265006,
        lng: 80.2173811108803,
      },
      {
        lat: 13.021383113456627,
        lng: 80.22189789464605,
      },
      {
        lat: 13.022499933935734,
        lng: 80.22118095228241,
      },
      {
        lat: 13.022354774650582,
        lng: 80.21720454160277,
      },
      {
        lat: 13.022293445060532,
        lng: 80.2129477540595,
      },
      {
        lat: 13.022475646292078,
        lng: 80.22530523077496,
      },
      {
        lat: 13.021968189374876,
        lng: 80.22476198769496,
      },
      {
        lat: 13.021800210590825,
        lng: 80.22528517595083,
      },
      {
        lat: 13.024808314235745,
        lng: 80.22676996119648,
      },
      {
        lat: 13.01799932643341,
        lng: 80.22436431166561,
      },
      {
        lat: 13.01841041853035,
        lng: 80.22392588074959,
      },
      {
        lat: 13.024156144841118,
        lng: 80.22101867978684,
      },
    ],
    cases: 21,
    coords: [
      {
        lat: 13.025319772928103,
        lng: 80.22444231795313,
      },
      {
        lat: 13.025292438835045,
        lng: 80.22502508767197,
      },
      {
        lat: 13.025360160279204,
        lng: 80.22600563180484,
      },
      {
        lat: 13.025186086064389,
        lng: 80.22784669993452,
      },
      {
        lat: 13.02497516752556,
        lng: 80.22870696261029,
      },
      {
        lat: 13.02482227372492,
        lng: 80.22895353590944,
      },
      {
        lat: 13.024666396322436,
        lng: 80.22870957582107,
      },
      {
        lat: 13.024105759803863,
        lng: 80.22791716284561,
      },
      {
        lat: 13.02218130868854,
        lng: 80.22627781456944,
      },
      {
        lat: 13.020040904206313,
        lng: 80.22467095387765,
      },
      {
        lat: 13.019513941934738,
        lng: 80.22433815463192,
      },
      {
        lat: 13.019112912321134,
        lng: 80.22440286847939,
      },
      {
        lat: 13.018558243006211,
        lng: 80.22459152227904,
      },
      {
        lat: 13.017294705250194,
        lng: 80.2250007948206,
      },
      {
        lat: 13.016614103277627,
        lng: 80.22479193552734,
      },
      {
        lat: 13.016210463012236,
        lng: 80.22442743266059,
      },
      {
        lat: 13.015897402881455,
        lng: 80.22372490412685,
      },
      {
        lat: 13.015708410657322,
        lng: 80.22311330531882,
      },
      {
        lat: 13.016334826120172,
        lng: 80.21949013384332,
      },
      {
        lat: 13.016425033304543,
        lng: 80.21909079150271,
      },
      {
        lat: 13.01667167731675,
        lng: 80.2190273842606,
      },
      {
        lat: 13.0174449106502,
        lng: 80.21923545959083,
      },
      {
        lat: 13.018681487263448,
        lng: 80.21947027344152,
      },
      {
        lat: 13.019022440912634,
        lng: 80.21968200726658,
      },
      {
        lat: 13.019362275711126,
        lng: 80.2197097910716,
      },
      {
        lat: 13.019484665341569,
        lng: 80.21952479576682,
      },
      {
        lat: 13.019543435980337,
        lng: 80.21903373973717,
      },
      {
        lat: 13.019689803151925,
        lng: 80.21771412465276,
      },
      {
        lat: 13.020596536873974,
        lng: 80.21448715966498,
      },
      {
        lat: 13.0207137052014,
        lng: 80.21344373093214,
      },
      {
        lat: 13.020212585222065,
        lng: 80.212282895274,
      },
      {
        lat: 13.020117902634682,
        lng: 80.2119464375333,
      },
      {
        lat: 13.020271728810897,
        lng: 80.21185315591758,
      },
      {
        lat: 13.02125830485985,
        lng: 80.21159952694957,
      },
      {
        lat: 13.022713792196747,
        lng: 80.21109820553785,
      },
      {
        lat: 13.02282345342277,
        lng: 80.21265576163115,
      },
      {
        lat: 13.022834271937429,
        lng: 80.21443443147729,
      },
      {
        lat: 13.02274208433262,
        lng: 80.215640816638,
      },
      {
        lat: 13.02274665214992,
        lng: 80.2163918105731,
      },
      {
        lat: 13.02344461548793,
        lng: 80.21660330770017,
      },
      {
        lat: 13.024121112121007,
        lng: 80.21655805430254,
      },
      {
        lat: 13.024261041364662,
        lng: 80.21665569012305,
      },
      {
        lat: 13.024460081671888,
        lng: 80.21665400559333,
      },
      {
        lat: 13.02469832901182,
        lng: 80.21655316916629,
      },
      {
        lat: 13.025613553807824,
        lng: 80.21648613133455,
      },
      {
        lat: 13.025971105126507,
        lng: 80.21636452119137,
      },
      {
        lat: 13.027185972234507,
        lng: 80.21647282354961,
      },
      {
        lat: 13.027366070156726,
        lng: 80.21662941145914,
      },
      {
        lat: 13.027546288284716,
        lng: 80.21680576236697,
      },
      {
        lat: 13.027544415111839,
        lng: 80.2197703805637,
      },
      {
        lat: 13.024957131529796,
        lng: 80.21983180544683,
      },
      {
        lat: 13.025195669399789,
        lng: 80.22305132118561,
      },
      {
        lat: 13.025319772928103,
        lng: 80.22444231795313,
      },
    ],
    properties: {
      Ward_No: 142,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#8bc24c",
      zname: "KODAMBAKKAM(142)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.030172697675713,
        lng: 80.21527185859485,
      },
      {
        lat: 13.029193329257845,
        lng: 80.21203415557551,
      },
      {
        lat: 13.022799939825463,
        lng: 80.21168567922157,
      },
      {
        lat: 13.030318333657608,
        lng: 80.21636080381893,
      },
      {
        lat: 13.029043251644389,
        lng: 80.21525338843301,
      },
      {
        lat: 13.028635451048677,
        lng: 80.21294857750512,
      },
      {
        lat: 13.030287108908547,
        lng: 80.21255701316204,
      },
      {
        lat: 13.02554510336475,
        lng: 80.21374943949613,
      },
      {
        lat: 13.02859984450357,
        lng: 80.2120363100132,
      },
      {
        lat: 13.027923685491665,
        lng: 80.2162089098652,
      },
      {
        lat: 13.02856175881173,
        lng: 80.21142955331396,
      },
      {
        lat: 13.025818264863961,
        lng: 80.21610453161375,
      },
      {
        lat: 13.026164984661815,
        lng: 80.21114956050876,
      },
      {
        lat: 13.025359915136464,
        lng: 80.21149945340322,
      },
      {
        lat: 13.028468215983429,
        lng: 80.21259506330591,
      },
      {
        lat: 13.024015373586595,
        lng: 80.21223845479939,
      },
      {
        lat: 13.028786781813876,
        lng: 80.21410673877389,
      },
      {
        lat: 13.025560104890442,
        lng: 80.21244893095738,
      },
      {
        lat: 13.028260881762153,
        lng: 80.21356963454963,
      },
      {
        lat: 13.029581326568595,
        lng: 80.21682217616761,
      },
      {
        lat: 13.030607401219113,
        lng: 80.20932222131927,
      },
      {
        lat: 13.028184945542865,
        lng: 80.21393210369885,
      },
      {
        lat: 13.027700192202225,
        lng: 80.21490491512063,
      },
      {
        lat: 13.024776144852147,
        lng: 80.21334162753075,
      },
      {
        lat: 13.02564325403636,
        lng: 80.21270288286317,
      },
      {
        lat: 13.023833954496693,
        lng: 80.21414853316381,
      },
      {
        lat: 13.024306846795254,
        lng: 80.21647096308891,
      },
      {
        lat: 13.02924074039496,
        lng: 80.21161726913557,
      },
      {
        lat: 13.023738071779375,
        lng: 80.21460800117919,
      },
      {
        lat: 13.02510961792258,
        lng: 80.21615033760494,
      },
      {
        lat: 13.028931784569867,
        lng: 80.21274570475725,
      },
      {
        lat: 13.02894389819555,
        lng: 80.21413087536332,
      },
      {
        lat: 13.02859090050785,
        lng: 80.21187291974637,
      },
    ],
    cases: 33,
    coords: [
      {
        lat: 13.027544415111839,
        lng: 80.2197703805637,
      },
      {
        lat: 13.027546288284716,
        lng: 80.21680576236697,
      },
      {
        lat: 13.027366070156726,
        lng: 80.21662941145914,
      },
      {
        lat: 13.027185972234507,
        lng: 80.21647282354961,
      },
      {
        lat: 13.025971105126507,
        lng: 80.21636452119137,
      },
      {
        lat: 13.025613553807824,
        lng: 80.21648613133455,
      },
      {
        lat: 13.02469832901182,
        lng: 80.21655316916629,
      },
      {
        lat: 13.024460081671888,
        lng: 80.21665400559333,
      },
      {
        lat: 13.024261041364662,
        lng: 80.21665569012305,
      },
      {
        lat: 13.024121112121007,
        lng: 80.21655805430254,
      },
      {
        lat: 13.02344461548793,
        lng: 80.21660330770017,
      },
      {
        lat: 13.02274665214992,
        lng: 80.2163918105731,
      },
      {
        lat: 13.02274208433262,
        lng: 80.215640816638,
      },
      {
        lat: 13.022834271937429,
        lng: 80.21443443147729,
      },
      {
        lat: 13.02282345342277,
        lng: 80.21265576163115,
      },
      {
        lat: 13.022713792196747,
        lng: 80.21109820553785,
      },
      {
        lat: 13.02363099477054,
        lng: 80.21078228847182,
      },
      {
        lat: 13.025234367325108,
        lng: 80.21040079973535,
      },
      {
        lat: 13.025664409307975,
        lng: 80.2100292411995,
      },
      {
        lat: 13.026184099050043,
        lng: 80.20916636531314,
      },
      {
        lat: 13.02618712743631,
        lng: 80.20916127691929,
      },
      {
        lat: 13.026755864438512,
        lng: 80.20918903313814,
      },
      {
        lat: 13.028616058605289,
        lng: 80.21019861037603,
      },
      {
        lat: 13.028711630643402,
        lng: 80.21047743440057,
      },
      {
        lat: 13.02911228439676,
        lng: 80.21075367643942,
      },
      {
        lat: 13.029723156469496,
        lng: 80.2108650201688,
      },
      {
        lat: 13.030183442336336,
        lng: 80.20936974931409,
      },
      {
        lat: 13.030276179806194,
        lng: 80.20918254253581,
      },
      {
        lat: 13.030463780671935,
        lng: 80.2091576520815,
      },
      {
        lat: 13.031145906587968,
        lng: 80.20940820920877,
      },
      {
        lat: 13.03051410244621,
        lng: 80.21385557067397,
      },
      {
        lat: 13.030460280434182,
        lng: 80.21482446294915,
      },
      {
        lat: 13.030469175657348,
        lng: 80.21628692482258,
      },
      {
        lat: 13.030593408070384,
        lng: 80.21707643403633,
      },
      {
        lat: 13.030075783066025,
        lng: 80.21706105081549,
      },
      {
        lat: 13.02991594979173,
        lng: 80.21696358344775,
      },
      {
        lat: 13.029816429638087,
        lng: 80.21696442571262,
      },
      {
        lat: 13.029777463016705,
        lng: 80.21710310360665,
      },
      {
        lat: 13.029744386475524,
        lng: 80.21821016841693,
      },
      {
        lat: 13.029592606984254,
        lng: 80.21943682193476,
      },
      {
        lat: 13.029517919295829,
        lng: 80.22024777867664,
      },
      {
        lat: 13.028999092234343,
        lng: 80.22003476547275,
      },
      {
        lat: 13.028182666357619,
        lng: 80.21998238304974,
      },
      {
        lat: 13.027544415111839,
        lng: 80.2197703805637,
      },
    ],
    properties: {
      Ward_No: 139,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#8bc24c",
      zname: "KODAMBAKKAM(139)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.033378836461564,
        lng: 80.22119468651472,
      },
      {
        lat: 13.032844456246579,
        lng: 80.22069243250264,
      },
      {
        lat: 13.028156153036937,
        lng: 80.22175770613859,
      },
      {
        lat: 13.029940904630788,
        lng: 80.22237635145262,
      },
      {
        lat: 13.02932258405715,
        lng: 80.22087540781311,
      },
      {
        lat: 13.031196056602298,
        lng: 80.22347046138599,
      },
      {
        lat: 13.026079843456575,
        lng: 80.22016029683266,
      },
      {
        lat: 13.031593362156979,
        lng: 80.22438547053746,
      },
      {
        lat: 13.025981301460023,
        lng: 80.22256692422407,
      },
      {
        lat: 13.03337333073659,
        lng: 80.22575809991343,
      },
      {
        lat: 13.030572692525483,
        lng: 80.22289789299444,
      },
      {
        lat: 13.033609704322565,
        lng: 80.22488541900475,
      },
      {
        lat: 13.030140937761537,
        lng: 80.22343604170052,
      },
      {
        lat: 13.03222004284488,
        lng: 80.22182025059305,
      },
      {
        lat: 13.03548677978308,
        lng: 80.2253217018088,
      },
      {
        lat: 13.030332082083484,
        lng: 80.21937187004983,
      },
      {
        lat: 13.026481980215989,
        lng: 80.22225480155038,
      },
      {
        lat: 13.03039419807848,
        lng: 80.22004013174995,
      },
      {
        lat: 13.027435452626527,
        lng: 80.22341253119389,
      },
      {
        lat: 13.031133966425632,
        lng: 80.21829665717225,
      },
      {
        lat: 13.03023875731681,
        lng: 80.21788415314036,
      },
      {
        lat: 13.030318609865802,
        lng: 80.21961367667998,
      },
      {
        lat: 13.032841752729885,
        lng: 80.22405740110922,
      },
      {
        lat: 13.03253325798576,
        lng: 80.22190916505355,
      },
      {
        lat: 13.030546535914885,
        lng: 80.22412485700978,
      },
      {
        lat: 13.030066923718262,
        lng: 80.21721502204298,
      },
      {
        lat: 13.02930978163484,
        lng: 80.22394103429002,
      },
    ],
    cases: 27,
    coords: [
      {
        lat: 13.03550325674584,
        lng: 80.22624947463308,
      },
      {
        lat: 13.031240031752308,
        lng: 80.22556744058748,
      },
      {
        lat: 13.030642359600021,
        lng: 80.22584850333419,
      },
      {
        lat: 13.030669439933575,
        lng: 80.22570008871762,
      },
      {
        lat: 13.030606380367312,
        lng: 80.22548600300274,
      },
      {
        lat: 13.030265986143474,
        lng: 80.22536624418768,
      },
      {
        lat: 13.02899806622201,
        lng: 80.22505504581618,
      },
      {
        lat: 13.028657671998227,
        lng: 80.22493528700127,
      },
      {
        lat: 13.028533044666341,
        lng: 80.22475238226525,
      },
      {
        lat: 13.02856112465986,
        lng: 80.22429224589331,
      },
      {
        lat: 13.028498438043655,
        lng: 80.22413947685183,
      },
      {
        lat: 13.028250675180864,
        lng: 80.22401893407365,
      },
      {
        lat: 13.027448615953325,
        lng: 80.22414836176843,
      },
      {
        lat: 13.025843751597831,
        lng: 80.22428458381123,
      },
      {
        lat: 13.025504103274283,
        lng: 80.22428745834304,
      },
      {
        lat: 13.025319772928103,
        lng: 80.22444231795313,
      },
      {
        lat: 13.025195669399789,
        lng: 80.22305132118561,
      },
      {
        lat: 13.024957131529796,
        lng: 80.21983180544683,
      },
      {
        lat: 13.027544415111839,
        lng: 80.2197703805637,
      },
      {
        lat: 13.028182666357619,
        lng: 80.21998238304974,
      },
      {
        lat: 13.028999092234343,
        lng: 80.22003476547275,
      },
      {
        lat: 13.029517919295829,
        lng: 80.22024777867664,
      },
      {
        lat: 13.029592606984254,
        lng: 80.21943682193476,
      },
      {
        lat: 13.029744386475524,
        lng: 80.21821016841693,
      },
      {
        lat: 13.029777463016705,
        lng: 80.21710310360665,
      },
      {
        lat: 13.029816429638087,
        lng: 80.21696442571262,
      },
      {
        lat: 13.02991594979173,
        lng: 80.21696358344775,
      },
      {
        lat: 13.030075783066025,
        lng: 80.21706105081549,
      },
      {
        lat: 13.030593408070384,
        lng: 80.21707643403633,
      },
      {
        lat: 13.031365373773918,
        lng: 80.21710858296436,
      },
      {
        lat: 13.031518846491013,
        lng: 80.218559471841,
      },
      {
        lat: 13.032274385124095,
        lng: 80.21858499373604,
      },
      {
        lat: 13.03231535972159,
        lng: 80.22003683471343,
      },
      {
        lat: 13.032829248607714,
        lng: 80.21996865310977,
      },
      {
        lat: 13.033037688396952,
        lng: 80.21988709849356,
      },
      {
        lat: 13.033520017311034,
        lng: 80.21991493263337,
      },
      {
        lat: 13.033505304963054,
        lng: 80.22013847064876,
      },
      {
        lat: 13.033361732161465,
        lng: 80.22031522492196,
      },
      {
        lat: 13.033378870959394,
        lng: 80.22049061905109,
      },
      {
        lat: 13.033540941370791,
        lng: 80.22071266090813,
      },
      {
        lat: 13.033846584583442,
        lng: 80.22075794849187,
      },
      {
        lat: 13.03434537288972,
        lng: 80.22084947576059,
      },
      {
        lat: 13.035084646246773,
        lng: 80.22084321909843,
      },
      {
        lat: 13.035043129768608,
        lng: 80.22194467986047,
      },
      {
        lat: 13.03484177521323,
        lng: 80.22319111633873,
      },
      {
        lat: 13.034864252200691,
        lng: 80.22424416118594,
      },
      {
        lat: 13.034721358805298,
        lng: 80.22453261645953,
      },
      {
        lat: 13.034690381181298,
        lng: 80.2247243759177,
      },
      {
        lat: 13.034948393262397,
        lng: 80.22486581525955,
      },
      {
        lat: 13.03535056049283,
        lng: 80.22492624404275,
      },
      {
        lat: 13.035576333195815,
        lng: 80.22505199812751,
      },
      {
        lat: 13.03564197664765,
        lng: 80.22527485607077,
      },
      {
        lat: 13.035548655543664,
        lng: 80.22578630530226,
      },
      {
        lat: 13.03550325674584,
        lng: 80.22624947463308,
      },
    ],
    properties: {
      Ward_No: 140,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#8bc24c",
      zname: "KODAMBAKKAM(140)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.030659233543828,
        lng: 80.22723951609352,
      },
      {
        lat: 13.032463083837028,
        lng: 80.2289356654786,
      },
      {
        lat: 13.029622812658411,
        lng: 80.23125147107346,
      },
      {
        lat: 13.032393293749593,
        lng: 80.22904549191524,
      },
      {
        lat: 13.031322194661376,
        lng: 80.2258472440945,
      },
      {
        lat: 13.032344940313388,
        lng: 80.235134717683,
      },
      {
        lat: 13.030671072913862,
        lng: 80.2318643903641,
      },
      {
        lat: 13.030966219325087,
        lng: 80.22961144188704,
      },
      {
        lat: 13.033020727982036,
        lng: 80.23695151098596,
      },
      {
        lat: 13.031619858856141,
        lng: 80.22668341139065,
      },
      {
        lat: 13.03187862618834,
        lng: 80.23157481727029,
      },
      {
        lat: 13.030921056635727,
        lng: 80.22725884547565,
      },
      {
        lat: 13.029332580816943,
        lng: 80.2311151368465,
      },
      {
        lat: 13.031142957577849,
        lng: 80.23325352353068,
      },
      {
        lat: 13.032278947618698,
        lng: 80.2365220801613,
      },
      {
        lat: 13.033909590000137,
        lng: 80.2273017726777,
      },
      {
        lat: 13.02826368929875,
        lng: 80.23219763784569,
      },
      {
        lat: 13.031245426592415,
        lng: 80.23075598463657,
      },
      {
        lat: 13.034008260425928,
        lng: 80.23511362381429,
      },
      {
        lat: 13.032204441120426,
        lng: 80.23428420996497,
      },
      {
        lat: 13.031129751710047,
        lng: 80.22815714755923,
      },
      {
        lat: 13.032795280025674,
        lng: 80.2310613120442,
      },
      {
        lat: 13.027280059824573,
        lng: 80.23283332230034,
      },
      {
        lat: 13.030755569255463,
        lng: 80.23602469171611,
      },
      {
        lat: 13.0315197663739,
        lng: 80.23369836181868,
      },
      {
        lat: 13.032511466300877,
        lng: 80.22843509343461,
      },
      {
        lat: 13.033378860863568,
        lng: 80.22608696181987,
      },
      {
        lat: 13.032663155012907,
        lng: 80.23476965028199,
      },
      {
        lat: 13.032513055759331,
        lng: 80.22609796301441,
      },
      {
        lat: 13.034503383562486,
        lng: 80.22882393751402,
      },
      {
        lat: 13.032384605045499,
        lng: 80.23808968772863,
      },
      {
        lat: 13.033662474305629,
        lng: 80.22746148973495,
      },
      {
        lat: 13.03082109021166,
        lng: 80.23250357831934,
      },
      {
        lat: 13.030377740977613,
        lng: 80.22919127233173,
      },
      {
        lat: 13.032693963005721,
        lng: 80.23647717067243,
      },
      {
        lat: 13.032332829950105,
        lng: 80.22647253690181,
      },
      {
        lat: 13.033555376577093,
        lng: 80.23155089595569,
      },
      {
        lat: 13.031502884422826,
        lng: 80.23468098035939,
      },
      {
        lat: 13.033176689743028,
        lng: 80.22958486898702,
      },
      {
        lat: 13.034862699978873,
        lng: 80.22833194963333,
      },
      {
        lat: 13.032082758276486,
        lng: 80.2357953202238,
      },
      {
        lat: 13.03053410887209,
        lng: 80.23428903885316,
      },
      {
        lat: 13.031710942430795,
        lng: 80.22921185586529,
      },
      {
        lat: 13.031971377714585,
        lng: 80.23902516765911,
      },
      {
        lat: 13.032150732394344,
        lng: 80.23861783383006,
      },
      {
        lat: 13.028480232167992,
        lng: 80.2311177611032,
      },
      {
        lat: 13.031832814537042,
        lng: 80.23808765738832,
      },
      {
        lat: 13.030877754866012,
        lng: 80.23273435634586,
      },
      {
        lat: 13.031991437177892,
        lng: 80.22919958638312,
      },
      {
        lat: 13.034093271660465,
        lng: 80.2344486194207,
      },
      {
        lat: 13.034564424605254,
        lng: 80.22937813096063,
      },
      {
        lat: 13.032531959327038,
        lng: 80.23275633572578,
      },
      {
        lat: 13.033488694469114,
        lng: 80.22907236303351,
      },
      {
        lat: 13.03370154017834,
        lng: 80.22858881369571,
      },
      {
        lat: 13.030548078438562,
        lng: 80.23470549293204,
      },
      {
        lat: 13.032241211201548,
        lng: 80.23567271700063,
      },
      {
        lat: 13.032939114732194,
        lng: 80.23842329215724,
      },
      {
        lat: 13.032093662871278,
        lng: 80.23783552304926,
      },
      {
        lat: 13.033949517095207,
        lng: 80.22947299250619,
      },
      {
        lat: 13.032391443419302,
        lng: 80.23065564303545,
      },
      {
        lat: 13.030675314263236,
        lng: 80.23346976538595,
      },
      {
        lat: 13.031366344739936,
        lng: 80.2284222160487,
      },
      {
        lat: 13.033602461283177,
        lng: 80.23425087760396,
      },
      {
        lat: 13.032684315849473,
        lng: 80.23445331755006,
      },
      {
        lat: 13.03035296582837,
        lng: 80.2334774359749,
      },
      {
        lat: 13.033478131727675,
        lng: 80.23162342536493,
      },
      {
        lat: 13.030549824753795,
        lng: 80.23657360218189,
      },
      {
        lat: 13.031123284537124,
        lng: 80.22900346542377,
      },
      {
        lat: 13.034154919139754,
        lng: 80.22897077070101,
      },
      {
        lat: 13.03358138376712,
        lng: 80.22730495852528,
      },
      {
        lat: 13.031660500060397,
        lng: 80.23373593513516,
      },
      {
        lat: 13.032723000171664,
        lng: 80.23489834785877,
      },
      {
        lat: 13.028373517437874,
        lng: 80.23416131572793,
      },
      {
        lat: 13.032885593620474,
        lng: 80.23627100371648,
      },
      {
        lat: 13.033547913843886,
        lng: 80.2329037160772,
      },
      {
        lat: 13.026931617428824,
        lng: 80.23194835407116,
      },
      {
        lat: 13.034421979756335,
        lng: 80.23296261106373,
      },
      {
        lat: 13.031924275199332,
        lng: 80.23879915614434,
      },
      {
        lat: 13.032013127983518,
        lng: 80.2378390125718,
      },
    ],
    cases: 79,
    coords: [
      {
        lat: 13.030642359600021,
        lng: 80.22584850333419,
      },
      {
        lat: 13.031240031752308,
        lng: 80.22556744058748,
      },
      {
        lat: 13.03550325674584,
        lng: 80.22624947463308,
      },
      {
        lat: 13.035346083726685,
        lng: 80.22701185149585,
      },
      {
        lat: 13.035116536051655,
        lng: 80.23002166143898,
      },
      {
        lat: 13.03525539003278,
        lng: 80.23021189601388,
      },
      {
        lat: 13.035202309814414,
        lng: 80.23054047621514,
      },
      {
        lat: 13.035121192705411,
        lng: 80.23078726095757,
      },
      {
        lat: 13.034250192066002,
        lng: 80.23700177663692,
      },
      {
        lat: 13.034469528525475,
        lng: 80.23742076765257,
      },
      {
        lat: 13.031555412963245,
        lng: 80.23936691254687,
      },
      {
        lat: 13.031738065033444,
        lng: 80.23893612790633,
      },
      {
        lat: 13.031641331219957,
        lng: 80.2382624284617,
      },
      {
        lat: 13.031235453253183,
        lng: 80.2375300255544,
      },
      {
        lat: 13.03098545268889,
        lng: 80.23704158273549,
      },
      {
        lat: 13.030674630259664,
        lng: 80.2367069542424,
      },
      {
        lat: 13.029431713492484,
        lng: 80.23542975694323,
      },
      {
        lat: 13.028281428086181,
        lng: 80.23415177568076,
      },
      {
        lat: 13.027970978607021,
        lng: 80.2338784638611,
      },
      {
        lat: 13.027414258066049,
        lng: 80.23372987595687,
      },
      {
        lat: 13.027101943835733,
        lng: 80.23314998076992,
      },
      {
        lat: 13.026854180972824,
        lng: 80.23302943799153,
      },
      {
        lat: 13.026543172068312,
        lng: 80.23266415116179,
      },
      {
        lat: 13.026229366037239,
        lng: 80.23183898928106,
      },
      {
        lat: 13.026134124024509,
        lng: 80.23141055653025,
      },
      {
        lat: 13.026318267895801,
        lng: 80.23122503858333,
      },
      {
        lat: 13.028231344029068,
        lng: 80.23099422831966,
      },
      {
        lat: 13.030421941294742,
        lng: 80.2306997494928,
      },
      {
        lat: 13.030729966597402,
        lng: 80.23057450293503,
      },
      {
        lat: 13.030697597676049,
        lng: 80.23032949756238,
      },
      {
        lat: 13.03054134732333,
        lng: 80.23002422080067,
      },
      {
        lat: 13.03035254157437,
        lng: 80.22944328032925,
      },
      {
        lat: 13.030319053802497,
        lng: 80.22901432493632,
      },
      {
        lat: 13.030345828470201,
        lng: 80.2283395802074,
      },
      {
        lat: 13.030579792174546,
        lng: 80.22619140606835,
      },
      {
        lat: 13.030642359600021,
        lng: 80.22584850333419,
      },
    ],
    properties: {
      Ward_No: 141,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#f6d04d",
      zname: "KODAMBAKKAM(141)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.049896937021304,
        lng: 80.21964137560141,
      },
      {
        lat: 13.050596409855311,
        lng: 80.22997448154955,
      },
      {
        lat: 13.051189925230398,
        lng: 80.22439141291454,
      },
      {
        lat: 13.048198837012297,
        lng: 80.22636291499424,
      },
      {
        lat: 13.051259295385881,
        lng: 80.22695800189476,
      },
      {
        lat: 13.053537199138892,
        lng: 80.22768491826378,
      },
      {
        lat: 13.048704493245237,
        lng: 80.21921131556458,
      },
      {
        lat: 13.04717768904305,
        lng: 80.22465017337318,
      },
      {
        lat: 13.051346108934704,
        lng: 80.22098016257553,
      },
      {
        lat: 13.051207983446584,
        lng: 80.22513171865221,
      },
      {
        lat: 13.044379409349181,
        lng: 80.22816439066992,
      },
      {
        lat: 13.052879623530123,
        lng: 80.2231812394673,
      },
      {
        lat: 13.0515182066887,
        lng: 80.21927077845949,
      },
      {
        lat: 13.046143827016659,
        lng: 80.22695763399258,
      },
      {
        lat: 13.052258249796719,
        lng: 80.22275776530874,
      },
      {
        lat: 13.05397554189302,
        lng: 80.22707115328225,
      },
      {
        lat: 13.05021735292605,
        lng: 80.2253617570485,
      },
      {
        lat: 13.052185758364816,
        lng: 80.22409395629671,
      },
      {
        lat: 13.050678312860057,
        lng: 80.22631154945216,
      },
      {
        lat: 13.051110656249158,
        lng: 80.22571719342024,
      },
      {
        lat: 13.05219365755186,
        lng: 80.21943070600432,
      },
      {
        lat: 13.045526542692105,
        lng: 80.2260334513154,
      },
      {
        lat: 13.047080111619085,
        lng: 80.22726160953746,
      },
      {
        lat: 13.050926256231477,
        lng: 80.22584376759156,
      },
      {
        lat: 13.047478053687255,
        lng: 80.2252394230717,
      },
      {
        lat: 13.05178813719066,
        lng: 80.22372043539018,
      },
    ],
    cases: 26,
    coords: [
      {
        lat: 13.0438953002991,
        lng: 80.22925917099109,
      },
      {
        lat: 13.045720859814848,
        lng: 80.22258693524465,
      },
      {
        lat: 13.04665260472986,
        lng: 80.22270349335709,
      },
      {
        lat: 13.047262517052213,
        lng: 80.22289388591437,
      },
      {
        lat: 13.047459997453675,
        lng: 80.22298110295168,
      },
      {
        lat: 13.047584457769558,
        lng: 80.22283782822753,
      },
      {
        lat: 13.047671164608733,
        lng: 80.22237487490595,
      },
      {
        lat: 13.047828837164602,
        lng: 80.22180465494509,
      },
      {
        lat: 13.047934853240987,
        lng: 80.22157264795399,
      },
      {
        lat: 13.047826674670905,
        lng: 80.22144911978604,
      },
      {
        lat: 13.047450806855469,
        lng: 80.22147007852598,
      },
      {
        lat: 13.047200048104015,
        lng: 80.22145442308918,
      },
      {
        lat: 13.047145039759238,
        lng: 80.22124155656267,
      },
      {
        lat: 13.04710674565906,
        lng: 80.22083299417565,
      },
      {
        lat: 13.047299144200563,
        lng: 80.22008470358934,
      },
      {
        lat: 13.047439453763557,
        lng: 80.21960351894116,
      },
      {
        lat: 13.047384121044725,
        lng: 80.21933732214067,
      },
      {
        lat: 13.047258038858581,
        lng: 80.21921394549578,
      },
      {
        lat: 13.046988403368829,
        lng: 80.21903845076041,
      },
      {
        lat: 13.046790922967196,
        lng: 80.2189512337231,
      },
      {
        lat: 13.046807853461104,
        lng: 80.21879109137856,
      },
      {
        lat: 13.04693317877452,
        lng: 80.21879003071795,
      },
      {
        lat: 13.047166033909837,
        lng: 80.2188058376777,
      },
      {
        lat: 13.047345502570495,
        lng: 80.21887542948006,
      },
      {
        lat: 13.047453032392463,
        lng: 80.21889229710024,
      },
      {
        lat: 13.047542009850023,
        lng: 80.21880265569581,
      },
      {
        lat: 13.047540820478492,
        lng: 80.21860711135828,
      },
      {
        lat: 13.04761221869392,
        lng: 80.21857095175058,
      },
      {
        lat: 13.047755880122201,
        lng: 80.21864084659884,
      },
      {
        lat: 13.047917228917353,
        lng: 80.21867503640823,
      },
      {
        lat: 13.048150192177191,
        lng: 80.21870862012585,
      },
      {
        lat: 13.048347564454144,
        lng: 80.21877806040519,
      },
      {
        lat: 13.048867093697865,
        lng: 80.21882699651347,
      },
      {
        lat: 13.049350815709326,
        lng: 80.21887623566788,
      },
      {
        lat: 13.05021062178548,
        lng: 80.21894006959828,
      },
      {
        lat: 13.050658860938572,
        lng: 80.21904294207222,
      },
      {
        lat: 13.05217409901909,
        lng: 80.219049519065,
      },
      {
        lat: 13.05381566117503,
        lng: 80.2255430934866,
      },
      {
        lat: 13.054219512928913,
        lng: 80.2285999098925,
      },
      {
        lat: 13.05423300698859,
        lng: 80.23081846556205,
      },
      {
        lat: 13.049836393669883,
        lng: 80.23005236373491,
      },
      {
        lat: 13.047949186619428,
        lng: 80.23014484151538,
      },
      {
        lat: 13.044034844820105,
        lng: 80.22928992153984,
      },
      {
        lat: 13.0438953002991,
        lng: 80.22925917099109,
      },
    ],
    properties: {
      Ward_No: 134,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#8bc24c",
      zname: "KODAMBAKKAM(134)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.03622097566509,
        lng: 80.2228777169472,
      },
      {
        lat: 13.036818576367487,
        lng: 80.22555657238439,
      },
      {
        lat: 13.04350178981507,
        lng: 80.223349354364,
      },
      {
        lat: 13.037113997039137,
        lng: 80.2248942614449,
      },
      {
        lat: 13.037439225612559,
        lng: 80.21977058639625,
      },
      {
        lat: 13.041696145816296,
        lng: 80.22452459071437,
      },
      {
        lat: 13.044002996993264,
        lng: 80.22786424056726,
      },
      {
        lat: 13.039621474283695,
        lng: 80.22771405009436,
      },
      {
        lat: 13.04351641819249,
        lng: 80.22569506988522,
      },
      {
        lat: 13.039506289154442,
        lng: 80.22149899110165,
      },
      {
        lat: 13.040322759183278,
        lng: 80.22393427854482,
      },
      {
        lat: 13.039430686844373,
        lng: 80.2203838710025,
      },
      {
        lat: 13.039594347547764,
        lng: 80.22290425958857,
      },
      {
        lat: 13.03898450752091,
        lng: 80.21978514317009,
      },
      {
        lat: 13.037730603141318,
        lng: 80.22170323399202,
      },
      {
        lat: 13.039286353808368,
        lng: 80.22243739270867,
      },
      {
        lat: 13.035749749523076,
        lng: 80.22190417746265,
      },
      {
        lat: 13.037432398475504,
        lng: 80.21955445078963,
      },
      {
        lat: 13.039796283928123,
        lng: 80.22420785531112,
      },
      {
        lat: 13.043036255613734,
        lng: 80.22603986503012,
      },
      {
        lat: 13.042525616355038,
        lng: 80.2284542588512,
      },
      {
        lat: 13.03613484963036,
        lng: 80.2203570411899,
      },
      {
        lat: 13.043880530781326,
        lng: 80.22377513034797,
      },
      {
        lat: 13.043594122722913,
        lng: 80.22662976226368,
      },
      {
        lat: 13.03604776293375,
        lng: 80.22548329448966,
      },
      {
        lat: 13.037197136939696,
        lng: 80.2241248895538,
      },
      {
        lat: 13.03825116583219,
        lng: 80.22393647114666,
      },
      {
        lat: 13.044865948447171,
        lng: 80.22276879123777,
      },
      {
        lat: 13.043656138117816,
        lng: 80.22514397742933,
      },
      {
        lat: 13.040358797749105,
        lng: 80.22072326951289,
      },
      {
        lat: 13.038482392290643,
        lng: 80.22199423789598,
      },
      {
        lat: 13.039119464224195,
        lng: 80.2236354381532,
      },
      {
        lat: 13.038782752166282,
        lng: 80.22070062995887,
      },
      {
        lat: 13.036444804913891,
        lng: 80.22647472835165,
      },
      {
        lat: 13.037527083624791,
        lng: 80.22645811893689,
      },
      {
        lat: 13.043733263359996,
        lng: 80.22575904662175,
      },
      {
        lat: 13.043999852697121,
        lng: 80.22743155094724,
      },
      {
        lat: 13.036999811823074,
        lng: 80.2247097539584,
      },
      {
        lat: 13.03972919261081,
        lng: 80.22738258011061,
      },
      {
        lat: 13.043045223132923,
        lng: 80.22525517383603,
      },
      {
        lat: 13.035930543576205,
        lng: 80.22595493497954,
      },
      {
        lat: 13.035566424424195,
        lng: 80.22266232608884,
      },
      {
        lat: 13.043621535087635,
        lng: 80.22506754175903,
      },
      {
        lat: 13.041562507456009,
        lng: 80.22204430120644,
      },
      {
        lat: 13.043612764120072,
        lng: 80.22236965718494,
      },
      {
        lat: 13.03707453495431,
        lng: 80.2265343886178,
      },
      {
        lat: 13.040044055022824,
        lng: 80.22085450587349,
      },
      {
        lat: 13.041848372560754,
        lng: 80.22467643484688,
      },
      {
        lat: 13.039874842186556,
        lng: 80.22346266698278,
      },
      {
        lat: 13.044569614938181,
        lng: 80.22323872095953,
      },
      {
        lat: 13.042650527256983,
        lng: 80.223171802328,
      },
      {
        lat: 13.039662250208924,
        lng: 80.22178818227368,
      },
      {
        lat: 13.041707740578783,
        lng: 80.22514711456073,
      },
      {
        lat: 13.035757974990437,
        lng: 80.22480996146318,
      },
      {
        lat: 13.034814555305397,
        lng: 80.22030025156005,
      },
    ],
    cases: 55,
    coords: [
      {
        lat: 13.03439460858176,
        lng: 80.22084905906617,
      },
      {
        lat: 13.034590641051295,
        lng: 80.21884908920035,
      },
      {
        lat: 13.035672907417492,
        lng: 80.2187399057717,
      },
      {
        lat: 13.036077206333097,
        lng: 80.21896153792261,
      },
      {
        lat: 13.037589258923262,
        lng: 80.21912378290313,
      },
      {
        lat: 13.037952145817936,
        lng: 80.21920654304085,
      },
      {
        lat: 13.038820442091989,
        lng: 80.2191991944243,
      },
      {
        lat: 13.039010000131825,
        lng: 80.21932879270298,
      },
      {
        lat: 13.039236626200449,
        lng: 80.2193456179303,
      },
      {
        lat: 13.039500320297845,
        lng: 80.2192496701062,
      },
      {
        lat: 13.039727060363676,
        lng: 80.21928523759114,
      },
      {
        lat: 13.040237282510562,
        lng: 80.21937463556141,
      },
      {
        lat: 13.041219518803464,
        lng: 80.21947878197605,
      },
      {
        lat: 13.041390314834706,
        lng: 80.21962728226498,
      },
      {
        lat: 13.041467642813759,
        lng: 80.21992651937839,
      },
      {
        lat: 13.041189974590347,
        lng: 80.22082854403709,
      },
      {
        lat: 13.041155414500063,
        lng: 80.2213536467584,
      },
      {
        lat: 13.041099584462682,
        lng: 80.22148532182003,
      },
      {
        lat: 13.041100610437521,
        lng: 80.22165400213953,
      },
      {
        lat: 13.041346226509376,
        lng: 80.22168940987193,
      },
      {
        lat: 13.04314241100244,
        lng: 80.222161532082,
      },
      {
        lat: 13.045720859814848,
        lng: 80.22258693524465,
      },
      {
        lat: 13.0438953002991,
        lng: 80.22925917099109,
      },
      {
        lat: 13.04260087500741,
        lng: 80.22897392662858,
      },
      {
        lat: 13.041276558121647,
        lng: 80.22857497095372,
      },
      {
        lat: 13.040362649744063,
        lng: 80.22773503392595,
      },
      {
        lat: 13.04017138078082,
        lng: 80.22798275091192,
      },
      {
        lat: 13.039951575999867,
        lng: 80.22806664391926,
      },
      {
        lat: 13.039537176074184,
        lng: 80.22785139711345,
      },
      {
        lat: 13.038737244659114,
        lng: 80.22763941316066,
      },
      {
        lat: 13.035346083726685,
        lng: 80.22701185149585,
      },
      {
        lat: 13.03550325674584,
        lng: 80.22624947463308,
      },
      {
        lat: 13.035548655543664,
        lng: 80.22578630530226,
      },
      {
        lat: 13.03564197664765,
        lng: 80.22527485607077,
      },
      {
        lat: 13.035576333195815,
        lng: 80.22505199812751,
      },
      {
        lat: 13.03535056049283,
        lng: 80.22492624404275,
      },
      {
        lat: 13.034948393262397,
        lng: 80.22486581525955,
      },
      {
        lat: 13.034690381181298,
        lng: 80.2247243759177,
      },
      {
        lat: 13.034721358805298,
        lng: 80.22453261645953,
      },
      {
        lat: 13.034864252200691,
        lng: 80.22424416118594,
      },
      {
        lat: 13.03484177521323,
        lng: 80.22319111633873,
      },
      {
        lat: 13.035043129768608,
        lng: 80.22194467986047,
      },
      {
        lat: 13.035084646246773,
        lng: 80.22084321909843,
      },
      {
        lat: 13.03439460858176,
        lng: 80.22084905906617,
      },
    ],
    properties: {
      Ward_No: 135,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#8bc24c",
      zname: "KODAMBAKKAM(135)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.03190205986744,
        lng: 80.21757946412639,
      },
      {
        lat: 13.031808001315767,
        lng: 80.21845743960036,
      },
      {
        lat: 13.033218135003567,
        lng: 80.21910405158287,
      },
      {
        lat: 13.039900483385665,
        lng: 80.21869554204613,
      },
      {
        lat: 13.04961922276266,
        lng: 80.21660442386136,
      },
      {
        lat: 13.046661474664438,
        lng: 80.21486878631333,
      },
      {
        lat: 13.037431758388598,
        lng: 80.21812509828312,
      },
      {
        lat: 13.03275924362833,
        lng: 80.21728390710147,
      },
      {
        lat: 13.051103702450163,
        lng: 80.21684364538861,
      },
      {
        lat: 13.045420991073003,
        lng: 80.2187910829349,
      },
      {
        lat: 13.03480106693896,
        lng: 80.21714740264508,
      },
      {
        lat: 13.04614916288563,
        lng: 80.2213525973723,
      },
      {
        lat: 13.049375864617344,
        lng: 80.21588935676915,
      },
      {
        lat: 13.041338545857917,
        lng: 80.2174900347603,
      },
      {
        lat: 13.046540025513163,
        lng: 80.21702497908682,
      },
      {
        lat: 13.04713019569411,
        lng: 80.2150551743123,
      },
      {
        lat: 13.03257818473549,
        lng: 80.21890338719754,
      },
      {
        lat: 13.04302961035614,
        lng: 80.21770507613282,
      },
      {
        lat: 13.045834699751829,
        lng: 80.21780928565205,
      },
      {
        lat: 13.045981191912249,
        lng: 80.21854647311325,
      },
      {
        lat: 13.044966314912996,
        lng: 80.21956485757559,
      },
      {
        lat: 13.047883439095349,
        lng: 80.2153377611149,
      },
      {
        lat: 13.045588717380213,
        lng: 80.22199789720166,
      },
      {
        lat: 13.04630171623816,
        lng: 80.21764516472759,
      },
      {
        lat: 13.036457810858531,
        lng: 80.21734096364378,
      },
      {
        lat: 13.048054115098216,
        lng: 80.21633658191456,
      },
      {
        lat: 13.03691968755372,
        lng: 80.21739572284648,
      },
    ],
    cases: 27,
    coords: [
      {
        lat: 13.031365373773918,
        lng: 80.21710858296436,
      },
      {
        lat: 13.031310435269171,
        lng: 80.21663981751949,
      },
      {
        lat: 13.031197740657017,
        lng: 80.21608010603457,
      },
      {
        lat: 13.03276481351771,
        lng: 80.2161753593451,
      },
      {
        lat: 13.033129645577604,
        lng: 80.21626270155562,
      },
      {
        lat: 13.03407666893589,
        lng: 80.21623660067557,
      },
      {
        lat: 13.035990249753496,
        lng: 80.21640126530252,
      },
      {
        lat: 13.037812980055438,
        lng: 80.21660287077239,
      },
      {
        lat: 13.039107501369203,
        lng: 80.21680894661185,
      },
      {
        lat: 13.040146365241496,
        lng: 80.21690867029206,
      },
      {
        lat: 13.040891823468591,
        lng: 80.21668532957666,
      },
      {
        lat: 13.04152634708063,
        lng: 80.21619163809325,
      },
      {
        lat: 13.041744366318134,
        lng: 80.21609936306108,
      },
      {
        lat: 13.042458236330447,
        lng: 80.21667207261841,
      },
      {
        lat: 13.042714003771495,
        lng: 80.2167965098225,
      },
      {
        lat: 13.043574246606896,
        lng: 80.21747649644321,
      },
      {
        lat: 13.043738943532913,
        lng: 80.21760170440082,
      },
      {
        lat: 13.043884326357945,
        lng: 80.21754621606087,
      },
      {
        lat: 13.044029159183813,
        lng: 80.21740030249714,
      },
      {
        lat: 13.044352833046224,
        lng: 80.21671029608245,
      },
      {
        lat: 13.044601560500478,
        lng: 80.2156772904191,
      },
      {
        lat: 13.0446331487156,
        lng: 80.21488124014618,
      },
      {
        lat: 13.04727336759631,
        lng: 80.21447233754047,
      },
      {
        lat: 13.047995554279318,
        lng: 80.2144444910111,
      },
      {
        lat: 13.049288559152446,
        lng: 80.21469436168965,
      },
      {
        lat: 13.050668060307261,
        lng: 80.21476962451847,
      },
      {
        lat: 13.0512609968847,
        lng: 80.21678037841674,
      },
      {
        lat: 13.052078730324686,
        lng: 80.21867226638994,
      },
      {
        lat: 13.05217409901909,
        lng: 80.219049519065,
      },
      {
        lat: 13.050658860938572,
        lng: 80.21904294207222,
      },
      {
        lat: 13.05021062178548,
        lng: 80.21894006959828,
      },
      {
        lat: 13.049350815709326,
        lng: 80.21887623566788,
      },
      {
        lat: 13.048867093697865,
        lng: 80.21882699651347,
      },
      {
        lat: 13.048347564454144,
        lng: 80.21877806040519,
      },
      {
        lat: 13.048150192177191,
        lng: 80.21870862012585,
      },
      {
        lat: 13.047917228917353,
        lng: 80.21867503640823,
      },
      {
        lat: 13.047755880122201,
        lng: 80.21864084659884,
      },
      {
        lat: 13.04761221869392,
        lng: 80.21857095175058,
      },
      {
        lat: 13.047540820478492,
        lng: 80.21860711135828,
      },
      {
        lat: 13.047542009850023,
        lng: 80.21880265569581,
      },
      {
        lat: 13.047453032392463,
        lng: 80.21889229710024,
      },
      {
        lat: 13.047345502570495,
        lng: 80.21887542948006,
      },
      {
        lat: 13.047166033909837,
        lng: 80.2188058376777,
      },
      {
        lat: 13.04693317877452,
        lng: 80.21879003071795,
      },
      {
        lat: 13.046807853461104,
        lng: 80.21879109137856,
      },
      {
        lat: 13.046790922967196,
        lng: 80.2189512337231,
      },
      {
        lat: 13.046988403368829,
        lng: 80.21903845076041,
      },
      {
        lat: 13.047258038858581,
        lng: 80.21921394549578,
      },
      {
        lat: 13.047384121044725,
        lng: 80.21933732214067,
      },
      {
        lat: 13.047439453763557,
        lng: 80.21960351894116,
      },
      {
        lat: 13.047299144200563,
        lng: 80.22008470358934,
      },
      {
        lat: 13.04710674565906,
        lng: 80.22083299417565,
      },
      {
        lat: 13.047145039759238,
        lng: 80.22124155656267,
      },
      {
        lat: 13.047200048104015,
        lng: 80.22145442308918,
      },
      {
        lat: 13.047450806855469,
        lng: 80.22147007852598,
      },
      {
        lat: 13.047826674670905,
        lng: 80.22144911978604,
      },
      {
        lat: 13.047934853240987,
        lng: 80.22157264795399,
      },
      {
        lat: 13.047828837164602,
        lng: 80.22180465494509,
      },
      {
        lat: 13.047671164608733,
        lng: 80.22237487490595,
      },
      {
        lat: 13.047584457769558,
        lng: 80.22283782822753,
      },
      {
        lat: 13.047459997453675,
        lng: 80.22298110295168,
      },
      {
        lat: 13.047262517052213,
        lng: 80.22289388591437,
      },
      {
        lat: 13.04665260472986,
        lng: 80.22270349335709,
      },
      {
        lat: 13.045720859814848,
        lng: 80.22258693524465,
      },
      {
        lat: 13.04314241100244,
        lng: 80.222161532082,
      },
      {
        lat: 13.041346226509376,
        lng: 80.22168940987193,
      },
      {
        lat: 13.041100610437521,
        lng: 80.22165400213953,
      },
      {
        lat: 13.041099584462682,
        lng: 80.22148532182003,
      },
      {
        lat: 13.041155414500063,
        lng: 80.2213536467584,
      },
      {
        lat: 13.041189974590347,
        lng: 80.22082854403709,
      },
      {
        lat: 13.041467642813759,
        lng: 80.21992651937839,
      },
      {
        lat: 13.041390314834706,
        lng: 80.21962728226498,
      },
      {
        lat: 13.041219518803464,
        lng: 80.21947878197605,
      },
      {
        lat: 13.040237282510562,
        lng: 80.21937463556141,
      },
      {
        lat: 13.039727060363676,
        lng: 80.21928523759114,
      },
      {
        lat: 13.039500320297845,
        lng: 80.2192496701062,
      },
      {
        lat: 13.039236626200449,
        lng: 80.2193456179303,
      },
      {
        lat: 13.039010000131825,
        lng: 80.21932879270298,
      },
      {
        lat: 13.038820442091989,
        lng: 80.2191991944243,
      },
      {
        lat: 13.037952145817936,
        lng: 80.21920654304085,
      },
      {
        lat: 13.037589258923262,
        lng: 80.21912378290313,
      },
      {
        lat: 13.036077206333097,
        lng: 80.21896153792261,
      },
      {
        lat: 13.035672907417492,
        lng: 80.2187399057717,
      },
      {
        lat: 13.034590641051295,
        lng: 80.21884908920035,
      },
      {
        lat: 13.03439460858176,
        lng: 80.22084905906617,
      },
      {
        lat: 13.03434537288972,
        lng: 80.22084947576059,
      },
      {
        lat: 13.033846584583442,
        lng: 80.22075794849187,
      },
      {
        lat: 13.033540941370791,
        lng: 80.22071266090813,
      },
      {
        lat: 13.033378870959394,
        lng: 80.22049061905109,
      },
      {
        lat: 13.033361732161465,
        lng: 80.22031522492196,
      },
      {
        lat: 13.033505304963054,
        lng: 80.22013847064876,
      },
      {
        lat: 13.033520017311034,
        lng: 80.21991493263337,
      },
      {
        lat: 13.033037688396952,
        lng: 80.21988709849356,
      },
      {
        lat: 13.032829248607714,
        lng: 80.21996865310977,
      },
      {
        lat: 13.03231535972159,
        lng: 80.22003683471343,
      },
      {
        lat: 13.032274385124095,
        lng: 80.21858499373604,
      },
      {
        lat: 13.031518846491013,
        lng: 80.218559471841,
      },
      {
        lat: 13.031365373773918,
        lng: 80.21710858296436,
      },
    ],
    properties: {
      Ward_No: 133,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#8bc24c",
      zname: "KODAMBAKKAM(133)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.043486847188671,
        lng: 80.20478012973561,
      },
      {
        lat: 13.045567557836767,
        lng: 80.19659339562364,
      },
      {
        lat: 13.03716752053078,
        lng: 80.20758124237732,
      },
      {
        lat: 13.045238354179574,
        lng: 80.2065923008485,
      },
      {
        lat: 13.039309214523179,
        lng: 80.20600735165972,
      },
      {
        lat: 13.0373298543357,
        lng: 80.20235175460142,
      },
      {
        lat: 13.042005105990116,
        lng: 80.20320209155159,
      },
      {
        lat: 13.043535783222275,
        lng: 80.20502943418516,
      },
      {
        lat: 13.04567371255989,
        lng: 80.19978498209869,
      },
      {
        lat: 13.045031987762263,
        lng: 80.19988497861362,
      },
      {
        lat: 13.037582407555444,
        lng: 80.202162668543,
      },
      {
        lat: 13.03879201426759,
        lng: 80.20554346595395,
      },
      {
        lat: 13.047301141575312,
        lng: 80.20330109019905,
      },
      {
        lat: 13.04112567037394,
        lng: 80.20771284188551,
      },
      {
        lat: 13.038541480870142,
        lng: 80.20367981572394,
      },
      {
        lat: 13.040621677789602,
        lng: 80.20228336324803,
      },
      {
        lat: 13.047333148775907,
        lng: 80.19868758935442,
      },
      {
        lat: 13.045224284460476,
        lng: 80.20068405524829,
      },
      {
        lat: 13.045075802881456,
        lng: 80.19800158578926,
      },
      {
        lat: 13.036352993144128,
        lng: 80.20657904128771,
      },
      {
        lat: 13.041746278432768,
        lng: 80.20784961700714,
      },
      {
        lat: 13.043053956797594,
        lng: 80.20489552862541,
      },
      {
        lat: 13.039141078621645,
        lng: 80.20363356981383,
      },
      {
        lat: 13.044130323831288,
        lng: 80.2079612907678,
      },
      {
        lat: 13.04491524495293,
        lng: 80.19836292082442,
      },
      {
        lat: 13.043837899336925,
        lng: 80.2038248683313,
      },
      {
        lat: 13.041141657397755,
        lng: 80.20727796026566,
      },
      {
        lat: 13.044952039628287,
        lng: 80.19807943483009,
      },
      {
        lat: 13.042156424488777,
        lng: 80.20588245664334,
      },
      {
        lat: 13.045987990994451,
        lng: 80.19989936052129,
      },
      {
        lat: 13.044513631299077,
        lng: 80.20318067043681,
      },
      {
        lat: 13.039041245601155,
        lng: 80.20204778824673,
      },
      {
        lat: 13.037560273575673,
        lng: 80.2016403470131,
      },
      {
        lat: 13.04556335072538,
        lng: 80.20062302110233,
      },
      {
        lat: 13.047242152205481,
        lng: 80.20088374943674,
      },
      {
        lat: 13.040620706111245,
        lng: 80.20631514173192,
      },
      {
        lat: 13.046430103015988,
        lng: 80.20183929824536,
      },
      {
        lat: 13.043926989266147,
        lng: 80.20248563617578,
      },
      {
        lat: 13.039005026336556,
        lng: 80.20313594172902,
      },
      {
        lat: 13.035587621605304,
        lng: 80.20063398192993,
      },
      {
        lat: 13.042172217195306,
        lng: 80.20726916207056,
      },
      {
        lat: 13.035324502374158,
        lng: 80.20122614821064,
      },
      {
        lat: 13.04571945258638,
        lng: 80.2074904944511,
      },
      {
        lat: 13.040385242383051,
        lng: 80.20758874590553,
      },
      {
        lat: 13.044834586081635,
        lng: 80.19927895544981,
      },
      {
        lat: 13.043758778895159,
        lng: 80.20522542552499,
      },
      {
        lat: 13.03814227824705,
        lng: 80.20528266159565,
      },
      {
        lat: 13.043982970941498,
        lng: 80.20298126646715,
      },
      {
        lat: 13.045305699019119,
        lng: 80.1995387389895,
      },
      {
        lat: 13.037002435365988,
        lng: 80.20473278080914,
      },
      {
        lat: 13.043342140459076,
        lng: 80.20703514493623,
      },
      {
        lat: 13.038657135328224,
        lng: 80.20600444061557,
      },
      {
        lat: 13.036094421168542,
        lng: 80.20078772360804,
      },
      {
        lat: 13.039722890110113,
        lng: 80.20327127441105,
      },
      {
        lat: 13.036180156419034,
        lng: 80.20775468171523,
      },
      {
        lat: 13.039802221341443,
        lng: 80.20414076925753,
      },
      {
        lat: 13.039968161963506,
        lng: 80.20318920461732,
      },
      {
        lat: 13.03985324564822,
        lng: 80.20212512237708,
      },
      {
        lat: 13.04576432983101,
        lng: 80.19768761720937,
      },
      {
        lat: 13.042769433999032,
        lng: 80.2027086612406,
      },
      {
        lat: 13.037412698352533,
        lng: 80.20731018702203,
      },
      {
        lat: 13.041818275819743,
        lng: 80.20473751238195,
      },
      {
        lat: 13.037444293515764,
        lng: 80.2021331745417,
      },
      {
        lat: 13.041725492146814,
        lng: 80.20272016915888,
      },
      {
        lat: 13.041658419886833,
        lng: 80.20431245383627,
      },
      {
        lat: 13.043208936214382,
        lng: 80.20258789740053,
      },
      {
        lat: 13.04020998688308,
        lng: 80.2032864463633,
      },
      {
        lat: 13.047602509374688,
        lng: 80.20367376610466,
      },
      {
        lat: 13.040561063269951,
        lng: 80.20209420616669,
      },
      {
        lat: 13.04054785831541,
        lng: 80.20826020239598,
      },
      {
        lat: 13.047250722667343,
        lng: 80.20313966808315,
      },
      {
        lat: 13.038400587407134,
        lng: 80.2062378475777,
      },
      {
        lat: 13.040208286578139,
        lng: 80.20128893625552,
      },
      {
        lat: 13.046143069470862,
        lng: 80.19779975345301,
      },
      {
        lat: 13.040583630289852,
        lng: 80.20492426423657,
      },
      {
        lat: 13.035945635014263,
        lng: 80.19962841092548,
      },
      {
        lat: 13.04450334325437,
        lng: 80.20721674251227,
      },
      {
        lat: 13.037224161390983,
        lng: 80.20613634451648,
      },
      {
        lat: 13.036513022889753,
        lng: 80.20742667155254,
      },
      {
        lat: 13.04287872117982,
        lng: 80.20675561715957,
      },
      {
        lat: 13.045957557922817,
        lng: 80.20343568213038,
      },
      {
        lat: 13.043649365129136,
        lng: 80.20190510575385,
      },
      {
        lat: 13.043608644871277,
        lng: 80.20530952638387,
      },
      {
        lat: 13.04427995614061,
        lng: 80.19830240690169,
      },
      {
        lat: 13.045727089056571,
        lng: 80.2006237622123,
      },
      {
        lat: 13.039504406097745,
        lng: 80.2059864110096,
      },
      {
        lat: 13.036284312172114,
        lng: 80.20606070876059,
      },
      {
        lat: 13.037474701448849,
        lng: 80.20522286341469,
      },
      {
        lat: 13.03755937754754,
        lng: 80.20612478841662,
      },
      {
        lat: 13.046478683310127,
        lng: 80.19827526433096,
      },
      {
        lat: 13.046278573536714,
        lng: 80.20269254988739,
      },
      {
        lat: 13.041020407687213,
        lng: 80.20701412197215,
      },
      {
        lat: 13.043666714696098,
        lng: 80.20694196785932,
      },
      {
        lat: 13.04501453286897,
        lng: 80.20817995616831,
      },
      {
        lat: 13.043544625573395,
        lng: 80.20830439137693,
      },
      {
        lat: 13.03668490654696,
        lng: 80.20353995818664,
      },
      {
        lat: 13.043810827935687,
        lng: 80.20527281174542,
      },
      {
        lat: 13.041494070527506,
        lng: 80.2088869473674,
      },
      {
        lat: 13.0433923981818,
        lng: 80.20448407466644,
      },
      {
        lat: 13.037256961927017,
        lng: 80.2037210090691,
      },
      {
        lat: 13.046885140423319,
        lng: 80.20276764274537,
      },
      {
        lat: 13.03733139326438,
        lng: 80.20813549553276,
      },
      {
        lat: 13.04038457847484,
        lng: 80.20716272161944,
      },
      {
        lat: 13.042731532841758,
        lng: 80.20790273933927,
      },
      {
        lat: 13.047219182247021,
        lng: 80.20254344144061,
      },
      {
        lat: 13.038325094561243,
        lng: 80.20777618919408,
      },
      {
        lat: 13.040627658174209,
        lng: 80.20306522440815,
      },
      {
        lat: 13.039274796166112,
        lng: 80.20395757521437,
      },
      {
        lat: 13.043207290827763,
        lng: 80.20736514037443,
      },
      {
        lat: 13.03635718096555,
        lng: 80.19968298012363,
      },
      {
        lat: 13.045080341685951,
        lng: 80.20229408427767,
      },
      {
        lat: 13.044480868990501,
        lng: 80.19780971259601,
      },
      {
        lat: 13.044898177130714,
        lng: 80.19670094491438,
      },
      {
        lat: 13.039784606754555,
        lng: 80.20857816306926,
      },
      {
        lat: 13.04671312547959,
        lng: 80.20121658447923,
      },
      {
        lat: 13.044459610651272,
        lng: 80.1976283715407,
      },
      {
        lat: 13.047460033391621,
        lng: 80.20327862962533,
      },
      {
        lat: 13.03768323564062,
        lng: 80.20702197748932,
      },
      {
        lat: 13.04436239877148,
        lng: 80.20314727550047,
      },
      {
        lat: 13.042429736420234,
        lng: 80.20668678817552,
      },
      {
        lat: 13.03671308184043,
        lng: 80.20496395545871,
      },
      {
        lat: 13.046504905239297,
        lng: 80.20113955015258,
      },
      {
        lat: 13.044032538590033,
        lng: 80.20092393141361,
      },
      {
        lat: 13.040053022067063,
        lng: 80.20485988355482,
      },
      {
        lat: 13.042279320781274,
        lng: 80.20480193218515,
      },
      {
        lat: 13.035558740704909,
        lng: 80.19855490019039,
      },
      {
        lat: 13.04316878057689,
        lng: 80.20742141681299,
      },
      {
        lat: 13.04064944097828,
        lng: 80.20676743714931,
      },
      {
        lat: 13.036940625989242,
        lng: 80.20542120367666,
      },
      {
        lat: 13.046920236440062,
        lng: 80.2017987164879,
      },
      {
        lat: 13.040600567777078,
        lng: 80.20246549672827,
      },
      {
        lat: 13.043696348002,
        lng: 80.19999516600261,
      },
      {
        lat: 13.04677333430304,
        lng: 80.20170277567078,
      },
      {
        lat: 13.039802895901408,
        lng: 80.2054750749812,
      },
      {
        lat: 13.042294991187166,
        lng: 80.20309541862164,
      },
      {
        lat: 13.045462769268829,
        lng: 80.20035020633726,
      },
      {
        lat: 13.040408828057332,
        lng: 80.20249412923263,
      },
      {
        lat: 13.042148821836003,
        lng: 80.20294960013217,
      },
      {
        lat: 13.044899734611827,
        lng: 80.19832894520579,
      },
      {
        lat: 13.035793434630424,
        lng: 80.1992607952924,
      },
      {
        lat: 13.04695767132731,
        lng: 80.20038499994781,
      },
      {
        lat: 13.045186575418846,
        lng: 80.20612099998768,
      },
      {
        lat: 13.045742605986199,
        lng: 80.20131565272068,
      },
      {
        lat: 13.037428981931317,
        lng: 80.20081067893852,
      },
      {
        lat: 13.044062657427016,
        lng: 80.20348120199866,
      },
      {
        lat: 13.035683866037324,
        lng: 80.19916554129848,
      },
    ],
    cases: 146,
    coords: [
      {
        lat: 13.046455111825246,
        lng: 80.20950206760521,
      },
      {
        lat: 13.044828904267753,
        lng: 80.20940331321657,
      },
      {
        lat: 13.041566503487628,
        lng: 80.20902629779194,
      },
      {
        lat: 13.037079743366066,
        lng: 80.20835022457987,
      },
      {
        lat: 13.035645316366093,
        lng: 80.20802987213108,
      },
      {
        lat: 13.036542962798551,
        lng: 80.20155611474765,
      },
      {
        lat: 13.03518026997879,
        lng: 80.20131131743443,
      },
      {
        lat: 13.03517615985483,
        lng: 80.20063557277032,
      },
      {
        lat: 13.035292648605614,
        lng: 80.20049477045798,
      },
      {
        lat: 13.035277483665475,
        lng: 80.19800150566279,
      },
      {
        lat: 13.03447886899859,
        lng: 80.19789175084784,
      },
      {
        lat: 13.034545304075696,
        lng: 80.197238711881,
      },
      {
        lat: 13.037024655221805,
        lng: 80.19786419126065,
      },
      {
        lat: 13.036842161952508,
        lng: 80.19944940498016,
      },
      {
        lat: 13.03673486211764,
        lng: 80.19963875852024,
      },
      {
        lat: 13.036793342173022,
        lng: 80.20033820377061,
      },
      {
        lat: 13.040863831887862,
        lng: 80.2009229320143,
      },
      {
        lat: 13.040840486265722,
        lng: 80.20154230744778,
      },
      {
        lat: 13.040733513898,
        lng: 80.2017854997689,
      },
      {
        lat: 13.040817140643748,
        lng: 80.20216168288155,
      },
      {
        lat: 13.043205244234597,
        lng: 80.20251836260891,
      },
      {
        lat: 13.043699077234717,
        lng: 80.1990144822605,
      },
      {
        lat: 13.044198640906808,
        lng: 80.1964527805804,
      },
      {
        lat: 13.045147379403621,
        lng: 80.19641783038423,
      },
      {
        lat: 13.045768651457683,
        lng: 80.19603568153042,
      },
      {
        lat: 13.04707687989441,
        lng: 80.19715528225602,
      },
      {
        lat: 13.047364124695088,
        lng: 80.19710490995867,
      },
      {
        lat: 13.047337820235816,
        lng: 80.201835687392,
      },
      {
        lat: 13.047730744672522,
        lng: 80.20329694588123,
      },
      {
        lat: 13.047755409116515,
        lng: 80.20375316113942,
      },
      {
        lat: 13.04528135323661,
        lng: 80.20366542733511,
      },
      {
        lat: 13.044033182786022,
        lng: 80.20358905302068,
      },
      {
        lat: 13.044085419857506,
        lng: 80.20497961740044,
      },
      {
        lat: 13.044307080357862,
        lng: 80.2054341654301,
      },
      {
        lat: 13.045230228801726,
        lng: 80.20605665239474,
      },
      {
        lat: 13.046118060514349,
        lng: 80.2080704447298,
      },
      {
        lat: 13.046452335831953,
        lng: 80.20904566709937,
      },
      {
        lat: 13.046455111825246,
        lng: 80.20950206760521,
      },
    ],
    properties: {
      Ward_No: 131,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#de4307",
      zname: "KODAMBAKKAM(131)",
    },
  },
  {
    casecoords: [
      {
        lat: 13.056101908735544,
        lng: 80.21645570463816,
      },
      {
        lat: 13.047566439623605,
        lng: 80.20861803282254,
      },
      {
        lat: 13.047842377102054,
        lng: 80.21271118630966,
      },
      {
        lat: 13.055324807571782,
        lng: 80.2153560087444,
      },
      {
        lat: 13.058666157931532,
        lng: 80.21048024459915,
      },
      {
        lat: 13.054355981375787,
        lng: 80.21825113886777,
      },
      {
        lat: 13.056128771044358,
        lng: 80.21211796728248,
      },
      {
        lat: 13.057568938658179,
        lng: 80.21262618410333,
      },
      {
        lat: 13.05215195127891,
        lng: 80.21272537803652,
      },
      {
        lat: 13.047720707574884,
        lng: 80.20898784437367,
      },
      {
        lat: 13.05049914377731,
        lng: 80.21346640255408,
      },
      {
        lat: 13.052274874636652,
        lng: 80.21492647355419,
      },
      {
        lat: 13.053950137739099,
        lng: 80.2133613260531,
      },
      {
        lat: 13.052717625682707,
        lng: 80.21629905833028,
      },
      {
        lat: 13.058467461634732,
        lng: 80.21544146462827,
      },
      {
        lat: 13.051747839640973,
        lng: 80.21214307602216,
      },
      {
        lat: 13.053721314951598,
        lng: 80.21360882901183,
      },
      {
        lat: 13.056327731659131,
        lng: 80.20925572219184,
      },
      {
        lat: 13.053250529370237,
        lng: 80.21247580474072,
      },
      {
        lat: 13.054610137311034,
        lng: 80.20864105557153,
      },
      {
        lat: 13.054694524754039,
        lng: 80.21619106918828,
      },
      {
        lat: 13.05193965063307,
        lng: 80.21115526736669,
      },
      {
        lat: 13.05884998116438,
        lng: 80.21033916098212,
      },
      {
        lat: 13.05537890930853,
        lng: 80.21316691592273,
      },
      {
        lat: 13.04978097627844,
        lng: 80.21339585864477,
      },
      {
        lat: 13.052020145091287,
        lng: 80.21168184666872,
      },
      {
        lat: 13.052801940579432,
        lng: 80.20784947317384,
      },
      {
        lat: 13.053178818023518,
        lng: 80.2097927658031,
      },
      {
        lat: 13.049465404704232,
        lng: 80.20972184403706,
      },
      {
        lat: 13.05381037729193,
        lng: 80.21559493505728,
      },
      {
        lat: 13.051008120186493,
        lng: 80.20988661604821,
      },
      {
        lat: 13.052534065352752,
        lng: 80.21559232901659,
      },
      {
        lat: 13.050106309552165,
        lng: 80.2113918109087,
      },
      {
        lat: 13.048256412178729,
        lng: 80.2112592288706,
      },
      {
        lat: 13.053485880896732,
        lng: 80.21192877217781,
      },
      {
        lat: 13.057672334586977,
        lng: 80.21694771523082,
      },
      {
        lat: 13.04892597239264,
        lng: 80.21432965317975,
      },
      {
        lat: 13.054251786582894,
        lng: 80.21207902294312,
      },
      {
        lat: 13.050884753522753,
        lng: 80.21515346133697,
      },
      {
        lat: 13.057432078506082,
        lng: 80.21510940698174,
      },
      {
        lat: 13.058336948806945,
        lng: 80.21683526508824,
      },
      {
        lat: 13.048240595220449,
        lng: 80.21067122951854,
      },
      {
        lat: 13.054538416856612,
        lng: 80.20805787724109,
      },
      {
        lat: 13.055606983196972,
        lng: 80.21432976761372,
      },
      {
        lat: 13.054165880754203,
        lng: 80.21577476575398,
      },
      {
        lat: 13.053928290451779,
        lng: 80.2129898208201,
      },
      {
        lat: 13.050406992415969,
        lng: 80.21281689809011,
      },
      {
        lat: 13.052195306795687,
        lng: 80.21534376507687,
      },
      {
        lat: 13.052140918655114,
        lng: 80.2080725342903,
      },
      {
        lat: 13.056679352155482,
        lng: 80.21486600595988,
      },
      {
        lat: 13.054764921165225,
        lng: 80.21280035797811,
      },
      {
        lat: 13.050618561671266,
        lng: 80.20935012381574,
      },
      {
        lat: 13.058197924468782,
        lng: 80.21565689723379,
      },
      {
        lat: 13.047148928232149,
        lng: 80.21096150476872,
      },
      {
        lat: 13.05334306320221,
        lng: 80.20814730076093,
      },
      {
        lat: 13.055642883614757,
        lng: 80.21166002855615,
      },
      {
        lat: 13.054411610650483,
        lng: 80.21704837881093,
      },
      {
        lat: 13.051524455285561,
        lng: 80.21525633231757,
      },
      {
        lat: 13.048578235684698,
        lng: 80.21331682722276,
      },
      {
        lat: 13.05613831680454,
        lng: 80.21163882038101,
      },
      {
        lat: 13.057964663621659,
        lng: 80.21161475489183,
      },
      {
        lat: 13.056310011867838,
        lng: 80.2164880080651,
      },
      {
        lat: 13.0556384442671,
        lng: 80.21466161899187,
      },
      {
        lat: 13.052649267895026,
        lng: 80.20957661559224,
      },
      {
        lat: 13.055482451819813,
        lng: 80.21462880862512,
      },
      {
        lat: 13.05023855354519,
        lng: 80.21165177947809,
      },
      {
        lat: 13.057483389965293,
        lng: 80.21156404385367,
      },
      {
        lat: 13.049274810666518,
        lng: 80.21148745547856,
      },
      {
        lat: 13.048480254746764,
        lng: 80.21349703145555,
      },
      {
        lat: 13.04946604110374,
        lng: 80.21136352106625,
      },
      {
        lat: 13.055305342324898,
        lng: 80.21275598075422,
      },
      {
        lat: 13.051703711363022,
        lng: 80.21596529236042,
      },
      {
        lat: 13.058420378958584,
        lng: 80.21253657221438,
      },
      {
        lat: 13.056501367465232,
        lng: 80.21204506714506,
      },
      {
        lat: 13.055525263256634,
        lng: 80.20939783325902,
      },
      {
        lat: 13.058753104054855,
        lng: 80.21141426025983,
      },
      {
        lat: 13.055531576217966,
        lng: 80.21317760787946,
      },
      {
        lat: 13.053540673302345,
        lng: 80.21473708937586,
      },
      {
        lat: 13.052323352909715,
        lng: 80.21541409684109,
      },
      {
        lat: 13.056503515406572,
        lng: 80.21301487113851,
      },
      {
        lat: 13.054105095746,
        lng: 80.21493777115857,
      },
      {
        lat: 13.057033794266216,
        lng: 80.2154966629523,
      },
      {
        lat: 13.057333503581487,
        lng: 80.21418543488903,
      },
      {
        lat: 13.049983795943561,
        lng: 80.21026635071478,
      },
      {
        lat: 13.055953836045804,
        lng: 80.21740407491447,
      },
      {
        lat: 13.053520724092259,
        lng: 80.21296325250631,
      },
      {
        lat: 13.05827957648937,
        lng: 80.21258671623998,
      },
      {
        lat: 13.048308154772776,
        lng: 80.21279543004466,
      },
      {
        lat: 13.052315554699671,
        lng: 80.21145111062988,
      },
      {
        lat: 13.052800250226083,
        lng: 80.21658386005814,
      },
      {
        lat: 13.057422295328701,
        lng: 80.21453379527992,
      },
      {
        lat: 13.05279185504396,
        lng: 80.2126749279832,
      },
      {
        lat: 13.054366557431562,
        lng: 80.21514190536392,
      },
      {
        lat: 13.052628313949716,
        lng: 80.21619947469493,
      },
      {
        lat: 13.054795917237646,
        lng: 80.21033128221352,
      },
      {
        lat: 13.053594212375291,
        lng: 80.21570207839804,
      },
      {
        lat: 13.058944772246027,
        lng: 80.2107147694173,
      },
      {
        lat: 13.052092206161449,
        lng: 80.21106356626774,
      },
      {
        lat: 13.054361882516279,
        lng: 80.21490936777587,
      },
      {
        lat: 13.049562012498916,
        lng: 80.21384200826361,
      },
      {
        lat: 13.055336984560197,
        lng: 80.21302820432363,
      },
      {
        lat: 13.054347607875547,
        lng: 80.21581844348799,
      },
      {
        lat: 13.053679190258997,
        lng: 80.21427617147873,
      },
      {
        lat: 13.055808412407416,
        lng: 80.21518342949898,
      },
      {
        lat: 13.056718541214982,
        lng: 80.21162172931896,
      },
      {
        lat: 13.050134535686373,
        lng: 80.21392038667875,
      },
      {
        lat: 13.048569979925926,
        lng: 80.21416525165888,
      },
      {
        lat: 13.04988970520339,
        lng: 80.20891273382762,
      },
      {
        lat: 13.056302892833656,
        lng: 80.21305946789441,
      },
      {
        lat: 13.054836575144169,
        lng: 80.21124195461844,
      },
      {
        lat: 13.05309003411443,
        lng: 80.21543371742504,
      },
      {
        lat: 13.047613880634543,
        lng: 80.20991511149833,
      },
      {
        lat: 13.055128196862887,
        lng: 80.21543778329172,
      },
      {
        lat: 13.050520081054758,
        lng: 80.2092505114829,
      },
      {
        lat: 13.0518870059451,
        lng: 80.20842383617499,
      },
      {
        lat: 13.054921287891574,
        lng: 80.2162172976346,
      },
      {
        lat: 13.048453692757727,
        lng: 80.21331295735021,
      },
      {
        lat: 13.058563406643172,
        lng: 80.21060884558862,
      },
      {
        lat: 13.052545459992077,
        lng: 80.21547651131908,
      },
      {
        lat: 13.055602322581782,
        lng: 80.20958076195485,
      },
      {
        lat: 13.052357127429314,
        lng: 80.21621348492005,
      },
      {
        lat: 13.050708913860976,
        lng: 80.21282904212312,
      },
      {
        lat: 13.048865713245881,
        lng: 80.21323152618187,
      },
      {
        lat: 13.054287545316395,
        lng: 80.20832466449315,
      },
      {
        lat: 13.055024767846065,
        lng: 80.2105822215966,
      },
      {
        lat: 13.04919932160587,
        lng: 80.21394289107968,
      },
      {
        lat: 13.051308046284241,
        lng: 80.21061320696806,
      },
      {
        lat: 13.056776603145371,
        lng: 80.21275802170946,
      },
      {
        lat: 13.052621916388699,
        lng: 80.21249648456222,
      },
      {
        lat: 13.047484800544312,
        lng: 80.21217920161435,
      },
      {
        lat: 13.055585593018565,
        lng: 80.212856501591,
      },
      {
        lat: 13.055375774810175,
        lng: 80.209977536842,
      },
      {
        lat: 13.056132785779042,
        lng: 80.21723475024253,
      },
      {
        lat: 13.054708542811468,
        lng: 80.2132440003487,
      },
      {
        lat: 13.052967978310296,
        lng: 80.21206812146555,
      },
      {
        lat: 13.057741569780628,
        lng: 80.21425540495643,
      },
      {
        lat: 13.053703435256548,
        lng: 80.21564866669856,
      },
      {
        lat: 13.048281276410098,
        lng: 80.21197699733047,
      },
    ],
    cases: 138,
    coords: [
      {
        lat: 13.046980699022354,
        lng: 80.20954108837691,
      },
      {
        lat: 13.046999724489835,
        lng: 80.20845285154539,
      },
      {
        lat: 13.047612401415682,
        lng: 80.2084476663017,
      },
      {
        lat: 13.048263555774207,
        lng: 80.20866337979307,
      },
      {
        lat: 13.048894910792278,
        lng: 80.20867647183677,
      },
      {
        lat: 13.049247103550009,
        lng: 80.2085813143134,
      },
      {
        lat: 13.049339372763322,
        lng: 80.20848835659017,
      },
      {
        lat: 13.049393164544016,
        lng: 80.20817450013176,
      },
      {
        lat: 13.049205710872759,
        lng: 80.20788112076121,
      },
      {
        lat: 13.04907417935339,
        lng: 80.20762413883688,
      },
      {
        lat: 13.049091960447223,
        lng: 80.20749494079604,
      },
      {
        lat: 13.049314752056711,
        lng: 80.20749305525284,
      },
      {
        lat: 13.049667841812605,
        lng: 80.20754537305756,
      },
      {
        lat: 13.050002365601244,
        lng: 80.20759784799101,
      },
      {
        lat: 13.052304994064505,
        lng: 80.20765210170903,
      },
      {
        lat: 13.052454082428163,
        lng: 80.20774301676045,
      },
      {
        lat: 13.055034864028972,
        lng: 80.20773961030146,
      },
      {
        lat: 13.055021904301528,
        lng: 80.20866148823188,
      },
      {
        lat: 13.054932886707396,
        lng: 80.20928904401994,
      },
      {
        lat: 13.055768691617194,
        lng: 80.2093372764811,
      },
      {
        lat: 13.055766897620396,
        lng: 80.20904232582474,
      },
      {
        lat: 13.056491306725558,
        lng: 80.2090915010575,
      },
      {
        lat: 13.056529784158014,
        lng: 80.20931239979261,
      },
      {
        lat: 13.056864307946537,
        lng: 80.20936487472606,
      },
      {
        lat: 13.056860832077742,
        lng: 80.20879340782903,
      },
      {
        lat: 13.057083511562437,
        lng: 80.20877308786983,
      },
      {
        lat: 13.057068309338996,
        lng: 80.20932627747929,
      },
      {
        lat: 13.057346910975577,
        lng: 80.20934235496652,
      },
      {
        lat: 13.057514677431403,
        lng: 80.20945154730518,
      },
      {
        lat: 13.060132415865722,
        lng: 80.20946250256563,
      },
      {
        lat: 13.059991087854714,
        lng: 80.20953950956573,
      },
      {
        lat: 13.059976133753368,
        lng: 80.21063399593218,
      },
      {
        lat: 13.059869780110615,
        lng: 80.21091385049097,
      },
      {
        lat: 13.059396186100711,
        lng: 80.21121827113552,
      },
      {
        lat: 13.058879502496515,
        lng: 80.21154451449414,
      },
      {
        lat: 13.058368039245597,
        lng: 80.21272903508773,
      },
      {
        lat: 13.05824111962196,
        lng: 80.2131807279851,
      },
      {
        lat: 13.058351780056165,
        lng: 80.21360895214534,
      },
      {
        lat: 13.058485225121272,
        lng: 80.21423010579201,
      },
      {
        lat: 13.05854084356334,
        lng: 80.21626814844268,
      },
      {
        lat: 13.058698508856756,
        lng: 80.21731825781558,
      },
      {
        lat: 13.05870177157752,
        lng: 80.21785468108753,
      },
      {
        lat: 13.058594504372898,
        lng: 80.21798433713023,
      },
      {
        lat: 13.058313704211757,
        lng: 80.21800817165024,
      },
      {
        lat: 13.057556047320178,
        lng: 80.21780000354296,
      },
      {
        lat: 13.056972445420042,
        lng: 80.21778348468165,
      },
      {
        lat: 13.056669774189936,
        lng: 80.21776458823138,
      },
      {
        lat: 13.056410845098103,
        lng: 80.2178311537217,
      },
      {
        lat: 13.056281967841839,
        lng: 80.21796099265572,
      },
      {
        lat: 13.056283794965461,
        lng: 80.21826138968814,
      },
      {
        lat: 13.056155439744748,
        lng: 80.21847705634572,
      },
      {
        lat: 13.05570136764503,
        lng: 80.2184379832047,
      },
      {
        lat: 13.054490682724264,
        lng: 80.2183623974037,
      },
      {
        lat: 13.054317671803181,
        lng: 80.2183424036045,
      },
      {
        lat: 13.05429462615457,
        lng: 80.21810656025635,
      },
      {
        lat: 13.054056263043565,
        lng: 80.21800128740811,
      },
      {
        lat: 13.053234689559206,
        lng: 80.21794386649135,
      },
      {
        lat: 13.053082375127785,
        lng: 80.2177734912846,
      },
      {
        lat: 13.05294736395671,
        lng: 80.2168948544673,
      },
      {
        lat: 13.052838530646126,
        lng: 80.21676702733944,
      },
      {
        lat: 13.0512609968847,
        lng: 80.21678037841674,
      },
      {
        lat: 13.050668060307261,
        lng: 80.21476962451847,
      },
      {
        lat: 13.049288559152446,
        lng: 80.21469436168965,
      },
      {
        lat: 13.047995554279318,
        lng: 80.2144444910111,
      },
      {
        lat: 13.04727336759631,
        lng: 80.21447233754047,
      },
      {
        lat: 13.047083058122821,
        lng: 80.21197448341776,
      },
      {
        lat: 13.046980699022354,
        lng: 80.20954108837691,
      },
    ],
    properties: {
      Ward_No: 130,
      Zone_Name: "KODAMBAKKAM",
      fillColor: "#de4307",
      zname: "KODAMBAKKAM(130)",
    },
  },
];
